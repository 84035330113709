import React from 'react';
import { useSelector } from 'react-redux';

import { Row, Col } from '../../../../styles/grid';
import px2vw from '../../../../utils/responsiveness/px2vw';

import { formatCurrencyBRL } from '../../../../utils/utils';

import {  TextBold, TextBold2, Hr } from '../styles';

import {
  TextRegular,
  TextRegular2,
  SchemaImage,
} from './styles';

export default function TotalSalesPerPaymentMethod() {
  const cashReportPaymentMethodTotals = useSelector(
    (state) => state.reports.cashReportPaymentMethodTotals
  );

  return (
    <Col>
      <Row justifyStart>
        <TextBold2>Total de vendas por pagamento</TextBold2>
      </Row>
      <Row margtop={px2vw(20)} margbottom={px2vw(20)}>
        <TextBold width={px2vw(95)} alignCenter>Bandeiras</TextBold>
        <TextBold width={px2vw(250)} alignCenter>Crédito</TextBold>
        <TextBold width={px2vw(100)} alignCenter>Volume</TextBold>
        <TextBold width={px2vw(250)} alignCenter>Débito</TextBold>
        <TextBold width={px2vw(100)} alignCenter>Volume</TextBold>
        <TextBold width={px2vw(250)} alignCenter>Voucher</TextBold>
        <TextBold width={px2vw(100)} alignCenter>Volume</TextBold>
      </Row>
      {cashReportPaymentMethodTotals && cashReportPaymentMethodTotals.map((paymentMethodTotal, idx) => {
        return (
          <Col key={`paymentMethodTotal-${idx}`}>
            <Row key={`paymentMethodTotal-${idx}-Brand`} height={px2vw(30)} margtop={px2vw(8)} margbottom={px2vw(8)}>
              <Col key={`paymentMethodTotal-${idx}-Image`} width={px2vw(95)} alignCenter>
                <SchemaImage src={paymentMethodTotal.image} />
                {paymentMethodTotal.voucher && (
                  paymentMethodTotal.brand.includes('_alimentacao') ? (
                    <TextRegular2>Alimentação</TextRegular2>
                  ) : (
                    <TextRegular2>Refeição</TextRegular2>
                  )
                )}
                {paymentMethodTotal.function === 'money_transfer'  && (
                  paymentMethodTotal.brand.includes('pix_copia_cola') ? (
                    <TextRegular2>Pix Automático</TextRegular2>
                  ) : (
                    <TextRegular2>Chave Pix</TextRegular2>
                  )
                )}
              </Col>
              <TextRegular width={px2vw(250)} alignCenter>
                {paymentMethodTotal.credit && (
                  !paymentMethodTotal.credit.orders_amount ? '-' : formatCurrencyBRL(paymentMethodTotal.credit.orders_amount, {})
                )}
                {!paymentMethodTotal.credit && (' ')}
              </TextRegular>
              <TextRegular width={px2vw(100)} alignCenter>
                {paymentMethodTotal.credit && (
                  paymentMethodTotal.credit.orders_qty === '0' ? '-' : paymentMethodTotal.credit.orders_qty
                )}
                {!paymentMethodTotal.credit && (' ')}
              </TextRegular>
              <TextRegular width={px2vw(250)} alignCenter>
                {paymentMethodTotal.debit && (
                  !paymentMethodTotal.debit.orders_amount ? '-' : formatCurrencyBRL(paymentMethodTotal.debit.orders_amount, {})
                )}
                {!paymentMethodTotal.debit && (' ')}
              </TextRegular>
              <TextRegular width={px2vw(100)} alignCenter>
                {paymentMethodTotal.debit && (
                  paymentMethodTotal.debit.orders_qty === '0' ? '-' : paymentMethodTotal.debit.orders_qty
                )}
                {!paymentMethodTotal.debit && (' ')}
              </TextRegular>
              <TextRegular width={px2vw(250)} alignCenter>
                {paymentMethodTotal.voucher && (
                  !paymentMethodTotal.voucher.orders_amount ? '-' : formatCurrencyBRL(paymentMethodTotal.voucher.orders_amount, {})
                )}
                {!paymentMethodTotal.voucher && (' ')}
              </TextRegular>
              <TextRegular width={px2vw(100)} alignCenter>
                {paymentMethodTotal.voucher && (
                  paymentMethodTotal.voucher.orders_qty === '0' ? '-' : paymentMethodTotal.voucher.orders_qty
                )}
                {!paymentMethodTotal.voucher && (' ')}
              </TextRegular>
            </Row>
            <Hr key={`paymentMethodTotal-${idx}-Separator`}/>
          </Col>
        )
      })}
    </Col>
  );
}