import React from 'react';

import WelcomeScreenWeb from './Web'
import WelcomeScreenMobile from './Mobile'

export default function WelcomeScreen({ isWeb, handler }) {
  if (isWeb) {
    return <WelcomeScreenWeb handler={handler} />
  } else {
    return <WelcomeScreenMobile handler={handler} />
  }
}
