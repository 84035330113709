import { takeLatest, call, put, all, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { apiAnunciante } from '../../../services/api';

import {
  getProductCategoriesRequest as getProductCategoriesRequestAction,
  getProductCategoriesSuccess,
  getProductCategoriesFailure,
  createNewCategorySuccess,
  createNewCategoryFailure,
  updateCategoryStatusSuccess,
  updateCategoryStatusFailure,
  updateProductStatusSuccess,
  updateProductStatusFailure,
  updateProductAmountSuccess,
  updateProductAmountFailure,
  updateComplCategoryStatusSuccess,
  updateComplCategoryStatusFailure,
  updateComplOptionStatusSuccess,
  updateComplOptionStatusFailure,
  updateComplOptionAmountSuccess,
  updateComplOptionAmountFailure,
  updateCategoryNameSuccess,
  updateCategoryNameFailure,
  createNewProductSuccess,
  createNewProductFailure,
  updateProductSuccess,
  updateProductFailure,
  deleteCategoryOrProductSuccess,
  deleteCategoryOrProductFailure,
  updateProductCategoriesOrdenationRequest as updateProductCategoriesOrdenationRequestAction,
  updateProductCategoriesOrdenationSuccess,
  updateProductCategoriesOrdenationFailure,
} from './actions';
import {
  logout
} from '../auth/actions';

import { sendProductUpdated } from '../../../services/vendors/mixpanel';

const userReducerData = (state) => state.user;
const userData = (state) => state.user.user;

export function* getProductCategoriesRequest() {
  try {
    const user = yield select(userData);

    const response = yield call(
      apiAnunciante.get,
      `/user-info/${user.advertiserInfo.id}/product-categories`
    );

    yield put(getProductCategoriesSuccess(response.data));
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(getProductCategoriesFailure());
    }
  }
}

export function* createNewCategoryRequest({ payload }) {
  try {
    const user = yield select(userData);

    yield call(
      apiAnunciante.post,
      `/user-info/${user.advertiserInfo.id}/product-categories`,
      {
        name: payload.categoryName,
      }
    );

    yield put(createNewCategorySuccess());
    toast.success('Categoria criada com sucesso!');
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(createNewCategoryFailure());
      toast.error(
        `Ocorreu um erro ao criar a categoria. Verifique se já não existe uma categoria com o mesmo nome e tente novamente!`,
        {
          autoClose: 6000,
        }
      );
    }
  }
}

export function* updateCategoryStatusRequest({ payload }) {
  try {
    const user = yield select(userData);

    yield call(
      apiAnunciante.put,
      `/user-info/${user.advertiserInfo.id}/product-category/${payload.categoryId}`,
      {
        is_active: payload.newStatus,
      }
    );

    yield put(updateCategoryStatusSuccess());
    yield put(getProductCategoriesRequestAction());
    toast.success('Status da categoria atualizado com sucesso!');
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(updateCategoryStatusFailure());
      toast.error(
        `Ocorreu um erro ao atualizar o status da categoria. Tente novamente!`,
        {
          autoClose: 6000,
        }
      );
    }
  }
}

export function* updateProductStatusRequest({ payload }) {
  try {
    const user = yield select(userData);

    yield call(
      apiAnunciante.put,
      `/user-info/${user.advertiserInfo.id}/product/${payload.productId}/quick-actions`,
      {
        is_active: payload.newStatus,
      }
    );

    yield put(updateProductStatusSuccess());
    yield put(getProductCategoriesRequestAction());
    toast.success('Status do produto atualizado com sucesso!');
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(updateProductStatusFailure());
      toast.error(
        `Ocorreu um erro ao atualizar o status do produto. Tente novamente!`,
        {
          autoClose: 6000,
        }
      );
    }
  }
}

export function* updateProductAmountRequest({ payload }) {
  try {
    const user = yield select(userData);

    yield call(
      apiAnunciante.put,
      `/user-info/${user.advertiserInfo.id}/product/${payload.productId}/quick-actions`,
      {
        amount: payload.newAmount,
      }
    );

    yield put(updateProductAmountSuccess());
    yield put(getProductCategoriesRequestAction());
    toast.success('Valor do produto atualizado com sucesso!');
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(updateProductAmountFailure());
      toast.error(
        `Ocorreu um erro ao atualizar o valor do produto. Tente novamente!`,
        {
          autoClose: 6000,
        }
      );
    }
  }
}

export function* updateComplCategoryStatusRequest({ payload }) {
  try {
    const user = yield select(userData);

    yield call(
      apiAnunciante.put,
      `/user-info/${user.advertiserInfo.id}/complement-category/${payload.complCategoryId}`,
      {
        is_active: payload.newStatus,
      }
    );

    yield put(updateComplCategoryStatusSuccess());
    yield put(getProductCategoriesRequestAction());
    toast.success('Status da categoria de complemento atualizado com sucesso!');
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(updateComplCategoryStatusFailure());
      toast.error(
        `Ocorreu um erro ao atualizar o status da categoria de complemento. Tente novamente!`,
        {
          autoClose: 6000,
        }
      );
    }
  }
}

export function* updateComplOptionStatusRequest({ payload }) {
  try {
    const user = yield select(userData);

    yield call(
      apiAnunciante.put,
      `/user-info/${user.advertiserInfo.id}/complement-option/${payload.complOptionId}/quick-actions`,
      {
        is_active: payload.newStatus,
      }
    );

    yield put(updateComplOptionStatusSuccess());
    yield put(getProductCategoriesRequestAction());
    toast.success('Status do complemento atualizado com sucesso!');
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(updateComplOptionStatusFailure());
      toast.error(
        `Ocorreu um erro ao atualizar o status do complemento. Tente novamente!`,
        {
          autoClose: 6000,
        }
      );
    }
  }
}

export function* updateComplOptionAmountRequest({ payload }) {
  try {
    const user = yield select(userData);

    yield call(
      apiAnunciante.put,
      `/user-info/${user.advertiserInfo.id}/complement-option/${payload.complOptionId}/quick-actions`,
      {
        amount: payload.newAmount,
      }
    );

    yield put(updateComplOptionAmountSuccess());
    yield put(getProductCategoriesRequestAction());
    toast.success('Valor do complemento atualizado com sucesso!');
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(updateComplOptionAmountFailure());
      toast.error(
        `Ocorreu um erro ao atualizar o valor do complemento. Tente novamente!`,
        {
          autoClose: 6000,
        }
      );
    }
  }
}

export function* updateCategoryNameRequest({ payload }) {
  try {
    const user = yield select(userData);

    yield call(
      apiAnunciante.put,
      `/user-info/${user.advertiserInfo.id}/product-category/${payload.categoryId}`,
      {
        name: payload.newName,
      }
    );

    yield put(updateCategoryNameSuccess());
    toast.success('Nome da categoria atualizado com sucesso!');
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(updateCategoryNameFailure());
      toast.error(
        `Ocorreu um erro ao atualizar o nome da categoria. Verifique se já não existe uma categoria com o mesmo nome e tente novamente!`,
        {
          autoClose: 6000,
        }
      );
    }
  }
}

export function* createNewProductRequest({ payload }) {
  try {
    const user = yield select(userData);

    // Create lightning coupon data
    let expirationDate = null;
    let usageLimit = null;
    if (payload.productLightningCoupon) {
      expirationDate = `${payload.productPromotionalDate.substring(
        6,
        payload.productPromotionalDate.length
      )}-${payload.productPromotionalDate.substring(
        3,
        5
      )}-${payload.productPromotionalDate.substring(0, 2)}T${
        payload.productPromotionalTime
      }`;
      usageLimit = payload.productPromotionalUseLimit;
    }

    // Create product availability
    const sundayJson = { is_active: false, horaries: [] };
    if (payload.productSunEnabled) {
      sundayJson.is_active = true;
      sundayJson.horaries = [
        {
          start_at:
            payload.productSunStartTime === ''
              ? '00:00'
              : payload.productSunStartTime,
          end_at:
            payload.productSunEndTime === ''
              ? '00:00'
              : payload.productSunEndTime,
        },
      ];
    }
    const mondayJson = { is_active: false, horaries: [] };
    if (payload.productMonEnabled) {
      mondayJson.is_active = true;
      mondayJson.horaries = [
        {
          start_at:
            payload.productMonStartTime === ''
              ? '00:00'
              : payload.productMonStartTime,
          end_at:
            payload.productMonEndTime === ''
              ? '00:00'
              : payload.productMonEndTime,
        },
      ];
    }
    const tuesdayJson = { is_active: false, horaries: [] };
    if (payload.productTueEnabled) {
      tuesdayJson.is_active = true;
      tuesdayJson.horaries = [
        {
          start_at:
            payload.productTueStartTime === ''
              ? '00:00'
              : payload.productTueStartTime,
          end_at:
            payload.productTueEndTime === ''
              ? '00:00'
              : payload.productTueEndTime,
        },
      ];
    }
    const wednesdayJson = { is_active: false, horaries: [] };
    if (payload.productWedEnabled) {
      wednesdayJson.is_active = true;
      wednesdayJson.horaries = [
        {
          start_at:
            payload.productWedStartTime === ''
              ? '00:00'
              : payload.productWedStartTime,
          end_at:
            payload.productWedEndTime === ''
              ? '00:00'
              : payload.productWedEndTime,
        },
      ];
    }
    const thursdayJson = { is_active: false, horaries: [] };
    if (payload.productThuEnabled) {
      thursdayJson.is_active = true;
      thursdayJson.horaries = [
        {
          start_at:
            payload.productThuStartTime === ''
              ? '00:00'
              : payload.productThuStartTime,
          end_at:
            payload.productThuEndTime === ''
              ? '00:00'
              : payload.productThuEndTime,
        },
      ];
    }
    const fridayJson = { is_active: false, horaries: [] };
    if (payload.productFriEnabled) {
      fridayJson.is_active = true;
      fridayJson.horaries = [
        {
          start_at:
            payload.productFriStartTime === ''
              ? '00:00'
              : payload.productFriStartTime,
          end_at:
            payload.productFriEndTime === ''
              ? '00:00'
              : payload.productFriEndTime,
        },
      ];
    }
    const saturdayJson = { is_active: false, horaries: [] };
    if (payload.productSatEnabled) {
      saturdayJson.is_active = true;
      saturdayJson.horaries = [
        {
          start_at:
            payload.productSatStartTime === ''
              ? '00:00'
              : payload.productSatStartTime,
          end_at:
            payload.productSatEndTime === ''
              ? '00:00'
              : payload.productSatEndTime,
        },
      ];
    }

    // Set product image data
    let imageData = null;
    if (payload.fileNewProduct.base64) {
      imageData = {
        base64Content: payload.fileNewProduct.base64,
        name: payload.fileNewProduct.name,
        type: payload.fileNewProduct.type,
        sub_type: payload.fileNewProduct.subtype,
      };
    }

    yield call(
      apiAnunciante.post,
      `/user-info/${user.advertiserInfo.id}/products`,
      {
        image: imageData,
        product_category_id: payload.productCategory,
        name: payload.productName,
        amount: payload.productAmount,
        description: payload.productDescription,
        sunday: sundayJson,
        monday: mondayJson,
        tuesday: tuesdayJson,
        wednesday: wednesdayJson,
        thursday: thursdayJson,
        friday: fridayJson,
        saturday: saturdayJson,
        coupon_active: payload.productActiveCoupon,
        coupon_short_display_name: payload.productCouponShortDisplayName,
        coupon_display_name: payload.productCouponDisplayName,
        coupon_description: payload.productCouponDescription,
        promotional_amount: payload.productPromotionalAmount,
        lightning_coupon: payload.productLightningCoupon,
        lightning_coupon_expiration_date_time: expirationDate,
        lightning_coupon_usage_limit: usageLimit,
        complements: payload.productComplements,
      }
    );

    yield put(createNewProductSuccess());
    toast.success('Produto criado com sucesso!');
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(createNewProductFailure());
      toast.error(
        `Ocorreu um erro ao criar o produto. Verifique se já não existe um produto com o mesmo nome e tente novamente!`,
        {
          autoClose: 6000,
        }
      );
    }
  }
}

export function* updateProductRequest({ payload }) {
  try {
    const user = yield select(userData);
    const userReducer = yield select(userReducerData);

    // Create lightning coupon data
    let expirationDate = null;
    let usageLimit = null;
    if (payload.productLightningCoupon) {
      expirationDate = `${payload.productPromotionalDate.substring(
        6,
        payload.productPromotionalDate.length
      )}-${payload.productPromotionalDate.substring(
        3,
        5
      )}-${payload.productPromotionalDate.substring(0, 2)}T${
        payload.productPromotionalTime
      }`;
      usageLimit = payload.productPromotionalUseLimit;
    }

    // Create product availability
    const sundayJson = { is_active: false, horaries: [] };
    if (payload.productSunEnabled) {
      sundayJson.is_active = true;
      sundayJson.horaries = [
        {
          start_at:
            payload.productSunStartTime === ''
              ? '00:00'
              : payload.productSunStartTime,
          end_at:
            payload.productSunEndTime === ''
              ? '00:00'
              : payload.productSunEndTime,
        },
      ];
    }
    const mondayJson = { is_active: false, horaries: [] };
    if (payload.productMonEnabled) {
      mondayJson.is_active = true;
      mondayJson.horaries = [
        {
          start_at:
            payload.productMonStartTime === ''
              ? '00:00'
              : payload.productMonStartTime,
          end_at:
            payload.productMonEndTime === ''
              ? '00:00'
              : payload.productMonEndTime,
        },
      ];
    }
    const tuesdayJson = { is_active: false, horaries: [] };
    if (payload.productTueEnabled) {
      tuesdayJson.is_active = true;
      tuesdayJson.horaries = [
        {
          start_at:
            payload.productTueStartTime === ''
              ? '00:00'
              : payload.productTueStartTime,
          end_at:
            payload.productTueEndTime === ''
              ? '00:00'
              : payload.productTueEndTime,
        },
      ];
    }
    const wednesdayJson = { is_active: false, horaries: [] };
    if (payload.productWedEnabled) {
      wednesdayJson.is_active = true;
      wednesdayJson.horaries = [
        {
          start_at:
            payload.productWedStartTime === ''
              ? '00:00'
              : payload.productWedStartTime,
          end_at:
            payload.productWedEndTime === ''
              ? '00:00'
              : payload.productWedEndTime,
        },
      ];
    }
    const thursdayJson = { is_active: false, horaries: [] };
    if (payload.productThuEnabled) {
      thursdayJson.is_active = true;
      thursdayJson.horaries = [
        {
          start_at:
            payload.productThuStartTime === ''
              ? '00:00'
              : payload.productThuStartTime,
          end_at:
            payload.productThuEndTime === ''
              ? '00:00'
              : payload.productThuEndTime,
        },
      ];
    }
    const fridayJson = { is_active: false, horaries: [] };
    if (payload.productFriEnabled) {
      fridayJson.is_active = true;
      fridayJson.horaries = [
        {
          start_at:
            payload.productFriStartTime === ''
              ? '00:00'
              : payload.productFriStartTime,
          end_at:
            payload.productFriEndTime === ''
              ? '00:00'
              : payload.productFriEndTime,
        },
      ];
    }
    const saturdayJson = { is_active: false, horaries: [] };
    if (payload.productSatEnabled) {
      saturdayJson.is_active = true;
      saturdayJson.horaries = [
        {
          start_at:
            payload.productSatStartTime === ''
              ? '00:00'
              : payload.productSatStartTime,
          end_at:
            payload.productSatEndTime === ''
              ? '00:00'
              : payload.productSatEndTime,
        },
      ];
    }

    // Set product image data
    let imageData = null;
    if (payload.fileEditProduct.base64) {
      imageData = {
        base64Content: payload.fileEditProduct.base64,
        name: payload.fileEditProduct.name,
        type: payload.fileEditProduct.type,
        sub_type: payload.fileEditProduct.subtype,
        image_width: payload.fileEditProduct.width,
        image_height: payload.fileEditProduct.height
      };
    }

    yield call(
      apiAnunciante.put,
      `/user-info/${user.advertiserInfo.id}/product/${payload.productId}`,
      {
        image: imageData,
        product_category_id: payload.productCategory,
        name: payload.productName,
        amount: payload.productAmount,
        description: payload.productDescription,
        sunday: sundayJson,
        monday: mondayJson,
        tuesday: tuesdayJson,
        wednesday: wednesdayJson,
        thursday: thursdayJson,
        friday: fridayJson,
        saturday: saturdayJson,
        coupon_active: payload.productActiveCoupon,
        coupon_short_display_name: payload.productCouponShortDisplayName,
        coupon_display_name: payload.productCouponDisplayName,
        coupon_description: payload.productCouponDescription,
        promotional_amount: payload.productPromotionalAmount,
        lightning_coupon: payload.productLightningCoupon,
        lightning_coupon_expiration_date_time: expirationDate,
        lightning_coupon_usage_limit: usageLimit,
        complements: payload.productComplements,
        deleted_complements_categories:
          payload.productDeletedComplementsCategories,
        deleted_complements: payload.productDeletedComplements,
      }
    );

    yield put(updateProductSuccess());

    //MixPanel Event
    sendProductUpdated({
      id: user.advertiserInfo.id,
      name: user.representative_name,
      email: user.email,
      product: payload.productId, 
      productName: payload.productName,
      amount: payload.productAmount,
      sunday: sundayJson,
      monday: mondayJson,
      tuesday: tuesdayJson,
      wednesday: wednesdayJson,
      thursday: thursdayJson,
      friday: fridayJson,
      saturday: saturdayJson,
      segment: user.advertiserInfo.advertiserCategory.name,
      hasFreeTrialValid: userReducer.hasFreeTrialValid
    })
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(updateProductFailure());
      toast.error(
        `Ocorreu um erro ao atualizar o produto. Verifique se já não existe um produto com o mesmo nome e tente novamente!`,
        {
          autoClose: 6000,
        }
      );
    }
  }
}

export function* deleteCategoryRequest({ payload }) {
  try {
    const user = yield select(userData);

    yield call(
      apiAnunciante.put,
      `/user-info/${user.advertiserInfo.id}/product-category/${payload.categoryId}/delete`
    );

    yield put(deleteCategoryOrProductSuccess());
    toast.success('Categoria excluída com sucesso!');
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(deleteCategoryOrProductFailure());
      toast.error(`Ocorreu um erro ao excluir a categoria. Tente novamente!`, {
        autoClose: 6000,
      });
    }
  }
}

export function* deleteProductRequest({ payload }) {
  try {
    const user = yield select(userData);

    yield call(
      apiAnunciante.put,
      `/user-info/${user.advertiserInfo.id}/product/${payload.productId}/delete`
    );

    yield put(deleteCategoryOrProductSuccess());
    toast.success('Produto excluído com sucesso!');
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(deleteCategoryOrProductFailure());
      toast.error(`Ocorreu um erro ao excluir o produto. Tente novamente!`, {
        autoClose: 6000,
      });
    }
  }
}

export function* formatCatalogUpdateOrdenation({ payload }) {
  function* getPayable() {
    yield* payload.catalog;
  }

  const sequence = getPayable();
  let hasIterable = true;
  const results = [];

  while (hasIterable) {
    const value = yield sequence.next().value;
    const category = { id: null, ordenation_index: null, products: [] };

    if (value) {
      category.id = value.id;
      category.ordenation_index = value.ordenation_index;

      value.products.map((currProduct) => {
        const product = { id: null, ordenation_index: null };

        product.id = currProduct.id;
        product.ordenation_index = currProduct.ordenation_index;

        category.products.push(product);

        return true;
      });

      results.push(category);
    } else {
      hasIterable = false;
    }
  }

  yield put(updateProductCategoriesOrdenationRequestAction(results));
}

export function* updateProductCategoriesOrdenationRequest({ payload }) {
  try {
    const user = yield select(userData);

    yield call(
      apiAnunciante.put,
      `/user-info/${user.advertiserInfo.id}/product-categories/update-ordenation`,
      {
        ordenated_catalog: payload.catalog,
      }
    );

    yield put(updateProductCategoriesOrdenationSuccess());
    toast.success('Catálogo ordenado com sucesso!');
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(updateProductCategoriesOrdenationFailure());
      toast.error(`Ocorreu um erro ao ordenar o catálogo. Tente novamente!`, {
        autoClose: 6000,
      });
    }
  }
}

export default all([
  takeLatest(
    '@productCategories/GET_PRODUCT_CATEGORIES_REQUEST',
    getProductCategoriesRequest
  ),
  takeLatest(
    '@productCategories/CREATE_NEW_CATEGORY_REQUEST',
    createNewCategoryRequest
  ),
  takeLatest(
    '@productCategories/UPDATE_CATEGORY_STATUS_REQUEST',
    updateCategoryStatusRequest
  ),
  takeLatest(
    '@productCategories/UPDATE_PRODUCT_STATUS_REQUEST',
    updateProductStatusRequest
  ),
  takeLatest(
    '@productCategories/UPDATE_PRODUCT_AMOUNT_REQUEST',
    updateProductAmountRequest
  ),
  takeLatest(
    '@productCategories/UPDATE_COMPL_CATEGORY_STATUS_REQUEST',
    updateComplCategoryStatusRequest
  ),
  takeLatest(
    '@productCategories/UPDATE_COMPL_OPTION_STATUS_REQUEST',
    updateComplOptionStatusRequest
  ),
  takeLatest(
    '@productCategories/UPDATE_COMPL_OPTION_AMOUNT_REQUEST',
    updateComplOptionAmountRequest
  ),
  takeLatest(
    '@productCategories/UPDATE_CATEGORY_NAME_REQUEST',
    updateCategoryNameRequest
  ),
  takeLatest(
    '@productCategories/CREATE_NEW_PRODUCT_REQUEST',
    createNewProductRequest
  ),
  takeLatest('@productCategories/UPDATE_PRODUCT_REQUEST', updateProductRequest),
  takeLatest(
    '@productCategories/DELETE_CATEGORY_REQUEST',
    deleteCategoryRequest
  ),
  takeLatest('@productCategories/DELETE_PRODUCT_REQUEST', deleteProductRequest),
  takeLatest(
    '@productCategories/UPDATE_PRODUCT_CATEGORIES_ORDENATION_REQUEST',
    updateProductCategoriesOrdenationRequest
  ),
  takeLatest(
    '@productCategories/FORMAT_CATALOG_UPDATE_ORDENATION',
    formatCatalogUpdateOrdenation
  ),
]);
