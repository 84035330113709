import React from 'react';
import styled, { css } from 'styled-components';
import { Input, Select, Form } from '@rocketseat/unform';
import { Link } from 'react-router-dom';
import {
  branco,
  cinzaKuppi5,
  cinzaKuppi6,
  verdeKuppi1,
  cinzaKuppi7,
  cinzaKuppi1,
  cinzaKuppi4,
  cinzaKuppi9,
  cinzaKuppi3,
  vermelhoKuppi1,
  verdeKuppi20,
  verdeKuppi21,
  verdeKuppi2,
  cinzaKuppi2,
  preto
} from './colors';
import bgimage from '../assets/images/mass_message/banner_bg.svg'


import px2vw from '../utils/responsiveness/px2vw';
import { keyframes } from 'styled-components';

const fadeIn = keyframes`
  to {
    opacity: 1;
  }
`;

/* Legacy common styles */
export const Button = styled.button`
  border: none !important;
  box-shadow: none !important;
  background: transparent !important;

  :focus {
    background: transparent !important;
  }
`;

export const SubmitButton = styled.button`
  font-family: AvenirNextLTPro;
  font-weight: 700;
  background: ${verdeKuppi1} !important;
  color: ${branco} !important;
  border-radius: 10px;
  border: none;
  padding: 12.5px 40px;
  transition: all 0.2s ease-in-out;

  ${(props) => props.padding && `padding: ${props.padding}`};

  ${(props) => props.height && `height: ${props.height}`};
  ${(props) => props.width && `width: ${props.width}`};

  :active {
    filter: brightness(95%);
  }
`;

export const Label = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
  text-align: left;
  font-size: 15px;
  font-family: AvenirNextLTPro;
  font-weight: 700;
`;

export const CustomForm = styled(Form)`
  width: 100%;
`;

export const Error = styled.div`
  margin-top: 5px;
  width: 100%;
  height: 21px;
  font-size: 14px;
  color: red;
  text-align: left;
  font-family: AvenirNextLTPro;
  font-weight: normal;
`;

export const ErrorText = styled.div`
  font-size: ${px2vw(18)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.4px;
  margin-left: ${px2vw(20)};
`;

export const InputCustom = styled(Input)`
  border: 1px solid ${(props) => (props.onError ? 'red' : cinzaKuppi5)} !important;
  padding: 7px 10px !important;
  height: auto !important;
  margin: 0 !important;
  border-radius: 5px !important;
  font-family: AvenirNextLTPro;
  font-weight: normal;

  box-sizing: inherit !important;
  font-size: 14px !important;

  ::placeholder {
    color: ${cinzaKuppi7} !important;
    font-size: 12px;
  }

  :focus {
    border: 1px solid ${(props) => (props.onError ? 'red' : cinzaKuppi5)} !important;
    box-shadow: none !important;
  }
`;

export const InputCustomInvisible = styled(Input)`
  height: auto !important;
  border: none !important;
  padding: 7px 10px 8px 10px !important;
  margin: 0 !important;
  font-family: AvenirNextLTPro;
  font-weight: normal;
  box-sizing: inherit !important;
  font-size: 14px !important;

  ${(props) => props.padding && `padding: ${props.padding} !important;`}

  ::placeholder {
    color: ${cinzaKuppi7} !important;
    font-size: 14px;
  }

  :focus {
    border: none !important;
    box-shadow: none !important;
  }
`;

export const SelectCustomInvisible = styled(Select)`
  border: none !important;
  height: auto !important;
  margin: 0 !important;
  display: inherit;
  background: ${branco} !important;
  color: ${cinzaKuppi7} !important;
  font-family: AvenirNextLTPro;
  font-weight: normal;

  box-sizing: inherit !important;
  font-size: 14px !important;

  ::placeholder {
    color: ${cinzaKuppi7} !important;
    font-size: 14px !important;
  }

  :focus {
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
  }

  option {
    color: ${cinzaKuppi7} !important;
  }
`;

export const TextareaCustomInvisible = styled(Input).attrs({
  multiline: true,
})`
  border: none !important;
  padding: 7px 10px !important;
  min-height: 65px;
  margin: 0 !important;
  font-family: AvenirNextLTPro;
  font-weight: normal;
  box-sizing: inherit !important;
  font-size: 14px !important;

  ::placeholder {
    color: ${cinzaKuppi7} !important;
    font-size: 14px;
  }

  :focus {
    border: none !important;
    box-shadow: none !important;
  }
`;

export const SelectCustom = styled(Select)`
  border: 1px solid ${(props) => (props.onError ? 'red' : cinzaKuppi5)} !important;
  padding: 5px 10px !important;
  height: auto !important;
  margin: 0 !important;
  border-radius: 5px !important;
  display: inherit;
  background: ${branco} !important;
  font-family: AvenirNextLTPro;
  font-weight: normal;

  box-sizing: inherit !important;
  font-size: 14px !important;

  ::placeholder {
    color: ${cinzaKuppi7} !important;
    font-size: 12px;
  }

  :focus {
    border: 1px solid ${(props) => (props.onError ? 'red' : cinzaKuppi5)} !important;
    box-shadow: none !important;
    border-radius: 5px !important;
    outline: none !important;
  }
`;

export const TextareaCustom = styled(Input).attrs({
  multiline: true,
})`
  border: 1px solid ${(props) => (props.onError ? 'red' : cinzaKuppi5)} !important;
  padding: 5px 10px !important;
  min-height: 100px;
  margin: 0 !important;
  font-family: AvenirNextLTPro;
  font-weight: normal;
  box-sizing: inherit !important;
  font-size: 14px !important;
  border-radius: 5px !important;

  ::placeholder {
    color: ${cinzaKuppi7} !important;
    font-size: 14px;
  }

  :focus {
    border: 1px solid ${(props) => (props.onError ? 'red' : cinzaKuppi5)} !important;
  }
`;

export const ContentDefault = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  background-image: url(${bgimage});
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: ${(props) => (props.height ? props.height : 'auto')};
  ${(props) => props.overflowY && `overflow-y: hidden; `};
  padding: 10px;
  border-radius: 20px;
  margin-bottom: 30px;
  font-family: AvenirNextLTPro;
  font-weight: normal;

  /* ****** RIGHT ****** */

  ${(props) => props.alignStart && `align-items: flex-start;`}
  ${(props) => props.justifyStart && `justify-content: flex-start;`}

  /* ****** CENTER ****** */

  ${(props) => props.alignCenter && `align-items: center;`}
  ${(props) => props.justifyCenter && `justify-content: center;`}

  /* ****** LEFT ****** */

  ${(props) => props.alignEnd && `align-items: flex-end;`}
  ${(props) => props.justifyEnd && `justify-content: flex-end;`}

  ${(props) =>
    props.groupTop &&
    `
    border-radius: 0px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    margin-bottom: 1px;
  `}

  ${(props) =>
    props.groupMiddle &&
    `
    border-radius: 0px;
    margin-top: 1px;
    margin-bottom: 1px;
  `}

  ${(props) =>
    props.groupBottom &&
    `
    margin-top: 1px;
    border-radius: 0px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    margin-bottom: 30px;

    box-shadow: 0px 8px 9px -2px ${cinzaKuppi4};
  `}

  border-radius: ${(props) => props.borderRadius && props.borderRadius};
  ${(props) => props.margin && `margin: ${props.margin};`}
  ${(props) => props.bgColor && `background: ${props.bgColor};`}
  ${(props) => props.padding && `padding: ${props.padding};`}
  ${(props) => props.column && `flex-direction: column;`}
  ${(props) => props.margleft && `margin-left: ${props.margleft};`}
  ${(props) => props.margbottom && `margin-bottom: ${props.margbottom};`}
  ${(props) => props.margtop && `margin-top: ${props.margtop};`}
  ${(props) => props.margleft && `margin-left: ${props.margleft};`}

  .MuiPaper-elevation1 {
    box-shadow: none;
  }
  .MuiTableCell-root {
    font-family: AvenirNextLTPro;
    font-weight: normal;
  }
  button.MuiButtonBase-root {
    :focus {
      background: transparent;
    }
  }

  .MuiTableRow-root.Mui-selected,
  .MuiTableRow-root.Mui-selected:hover {
    background-color: transparent;
  }

  .MuiTableRow-head {
    th {
      font-size: ${px2vw(11)};
      font-family: AvenirNextLTPro;
      font-weight: 700;
    }
  }

  .ml-auto {
    margin-left: auto;
  }
  .mr-auto {
    margin-right: auto;
  }
  .mt-auto {
    margin-top: auto;
  }
  .mb-auto {
    margin-bottom: auto;
  }
`;

export const SwitchTableButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  font-size: ${px2vw(16)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  cursor: pointer;

  .selected {
    margin-top: 5px;
    width: 100%;
    height: 4px;
    background: transparent;
    border-radius: 10px;
  }

  ${(props) =>
    props.selected &&
    `
    .selected {
      background: ${verdeKuppi1};
    }
  `}
`;

export const TextProductName = styled.div`
  font-family: AvenirNextLTPro;
  font-weight: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 20ch;
  ${(props) => props.size && `font-size: ${props.size};`}
  ${(props) => props.color && `color: ${props.color};`}
`;


export const Text = styled.div`
  ${(props) =>
    props.fadeIn &&
    css`
    opacity: 0;
      animation: ${fadeIn} 1s forwards;
    `}
  font-size: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: AvenirNextLTPro;
  font-weight: 700;
  ${(props) => props.background && `background-color: ${props.background};`}
  ${(props) => props.margleft && `margin-left: ${props.margleft};`}
  ${(props) => props.margright && `margin-right: ${props.margright};`}
  ${(props) => props.margbottom && `margin-bottom: ${props.margbottom};`}
  ${(props) => props.margtop && `margin-top: ${props.margtop};`}
  ${(props) => props.margleft && `margin-left: ${props.margleft};`}
  ${(props) => props.size && `font-size: ${props.size};`}
  ${(props) => props.color && `color: ${props.color};`}
  ${(props) => props.txtOverFlow && `text-overflow: ellipsis;`}
  ${(props) => props.margin && `margin: ${props.margin};`}
  ${(props) =>
    props.bold &&
    `font-family: AvenirNextLTPro;
  font-weight: 700;`}
  ${(props) =>
    props.regular &&
    `font-family: AvenirNextLTPro;
  font-weight: normal;`}
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : 'initial')};
  ${(props) => props.alignCenter && `text-align: center;`}
  ${(props) => props.weight && `font-weight: ${props.weight};`}
  ${(props) => props.justifyStart && `justify-content: flex-start;`}
  ${(props) =>
    props.wrap &&
    `
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  `}
  ${(props) =>
    props.fitLetter &&
    `
      font-style: normal;
      line-height: 150%;
      text-align: center;
      letter-spacing: 0.02em;
  `}
  ${(props) =>
    props.roboto &&
    `
      font-family: Roboto;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
      letter-spacing: 0.05em;
    `}

  ${(props) =>
    props.underline &&
    css`
      text-decoration: underline;
    `}

  width: ${(props) => props.width || 'auto'};
  ${props => props.zIndex && `z-index: ${props.zIndex};` }
  ${props => props.pointer && `cursor: pointer;` }
`;

export const Text2 = styled.span`
  font-size: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: AvenirNextLTPro;
  font-weight: 700;

  ${(props) => props.size && `font-size: ${props.size};`}
  ${(props) => props.color && `color: ${props.color};`}
  ${(props) => props.margin && `margin: ${props.margin};`}
  ${(props) =>
    props.bold &&
    `font-family: AvenirNextLTPro;
font-weight: 700;`}
  ${(props) =>
    props.regular &&
    `font-family: AvenirNextLTPro;
font-weight: normal;`}
`;

export const Vr = styled.div`
  height: 100%;
  width: 1px;
  background: ${cinzaKuppi1};
  background: ${(props) => props.color && props.color};
  width: ${(props) => props.size && props.size};
`;

export const Hr = styled.div`
  height: 1px;
  width: 100%;
  background: ${(props) => props.background || cinzaKuppi1};
  height: ${(props) => props.size && props.size};
`;

const StyledLink = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  text-decoration: none !important;

  ${(props) =>
    props.noStyle && `font-size: 14px; color: ${cinzaKuppi6} !important; `}

  ${(props) => props.size && `font-size: ${props.size};`}
  ${(props) => props.display && `display: ${props.display};`}
  ${(props) =>
    props.textDecoration && `text-decoration: ${props.textDecoration};`}
`;

export const CustonLink = ({ ...props }) => <StyledLink {...props} />;

const StyledLink2 = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: ${px2vw(16)};
  text-decoration: none !important;
  ${(props) => props.size && `font-size: ${props.size};`}
  ${(props) => props.width && `width: ${props.width};`}

  ${(props) =>
    props.noStyle &&
    `font-size: ${px2vw(12)}; color: ${cinzaKuppi6} !important; `}

  ${(props) =>
    props.custonStyle &&
    `padding: ${px2vw(5)} ${px2vw(
      10
    )}; border: 1px solid #464347; border-radius: ${px2vw(
      5
    )}; margin-top: ${px2vw(5)};`}
`;

export const CustonLink2 = ({ ...props }) => <StyledLink2 {...props} />;

export const LabelField = styled.div`
  font-size: ${px2vw(13)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  margin-bottom: 12px;
  ${(props) => props.margin && `margin: ${props.margin};`}
  ${(props) =>
    props.bold &&
    `font-family: AvenirNextLTPro;
font-weight: 700;`}
`;

export const BigText2 = styled.div`
  font-size: ${px2vw(28)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
`;

export const TextField = styled.div`
  font-size: 15px;
  font-family: AvenirNextLTPro;
  font-weight: normal;
`;

export const LoadingBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Image = styled.img`
 ${(props) => `
    width: ${props.width};
    height: ${props.height};
 `}
`;

export const ImageLogoPreview = styled.img`
  position: absolute;
  width: ${px2vw(70)};
  height: ${px2vw(70)};
  border: solid 1px ${cinzaKuppi9};
  border-radius: ${px2vw(35)};
`;

export const ImageCoverPreview = styled.img`
  position: absolute;
  width: ${px2vw(511)};
  height: ${px2vw(117)};
  object-fit: cover;
  border-radius: ${px2vw(5)};
`;

export const FormDataInput = styled.input`
  padding: 0.5em;
  margin-top: ${(props) => props.margtop || px2vw(5)};
  border: ${(props) => props.border || `1px solid ${cinzaKuppi4}`};
  border-radius: ${(props) => props.borderradius || px2vw(5)};
  font-size: ${(props) => props.fontsize || px2vw(14)};
  font-weight: normal;
  letter-spacing: 0.36px;
  width: ${px2vw(280)};
  height: ${(props) => props.height || px2vw(40)};
  padding-left: ${(props) => props.padleft};

  ::placeholder {
    color: ${cinzaKuppi4} !important;
  }

  :focus {
    border: 1px solid ${cinzaKuppi3} !important;
    box-shadow: none !important;
  }

  ${(props) => `
    width: ${props.width};
    height: ${props.height};
    margin-top: ${props.margtop};
  `};

  ${(props) =>
    props.disabled &&
    `
    background-color: ${cinzaKuppi4};
    color: ${cinzaKuppi3};
    border: 1px solid ${cinzaKuppi3} !important;
  `};

  ${(props) =>
    props.disabled &&
    `
    background-color: ${cinzaKuppi4};
    color: ${cinzaKuppi3};
    border: 1px solid ${cinzaKuppi3} !important;
  `};

  ${(props) =>
    props.empty &&
    `
    border: 1px solid ${vermelhoKuppi1} !important;
  `};
`;

export const GeneralText = styled.div`
  ${(props) => `
    color: ${props.color};
    width: ${props.width};
    height: ${props.height};
    font-size: ${props.fontsize};
    text-align: ${props.textalign || "center"};
    margin: ${props.marg};
    margin-left: ${props.margleft};
    margin-right: ${props.margright};
    margin-top: ${props.margtop};
    margin-bottom: ${props.margbottom};
    padding-left: ${props.padleft};
    padding-right: ${props.padright};
    padding-bottom: ${props.padbottom};
    padding-top: ${props.padtop};
    padding: ${props.pad};
    font-weight: ${props.fontweight || 'normal'};
    text-decoration: ${props.textdecoration};
    cursor: ${props.cursor};
    background: ${props.background};
    border-radius: ${props.borderradius};
    border-left: ${props.borderLeft};
    border-right: ${props.borderRight};
  `}

  ${(props) => props.sideBarSelectedPage && `
    background: ${`transparent linear-gradient(189deg, #2FFE5F 20%, #0CC75F 100%) 0% 0%`};
    border-radius: ${px2vw(30)};
    z-index: 0;
  `};

  ${(props) => props.whiteText && `
    color: ${branco};
  `};

  ${(props) => props.isActive && `
    font-weight: bold;
  `}

${(props) => props.invisible && `
    display: none;
  `}

  .green {
    color: ${verdeKuppi20};
  }

  .green2 {
    color: ${verdeKuppi21};
  }

  .green3 {
    color: ${verdeKuppi21};
    font-size: ${px2vw(18)};
  }

  .bold {
    font-weight: bold;
    color: ${cinzaKuppi6};
  }

  .TitleGreen {
    color: ${verdeKuppi21};
  }

  .black {
    font-weight: bolder;
  }

  a {
    text-decoration: none;
  }
`;

export const OutlinedDiv = styled.div`
  border: ${(props) => props.border || `${px2vw(1)} solid ${verdeKuppi2}`};
  border-radius: ${(props) => props.borderradius || `${px2vw(20)}`};
  width: ${(props) => props.width || 'auto'};
  height: auto;
  display: flex;
  flex-direction: ${(props) => props.flexdirection};
  margin-top: ${(props) => props.margtop};
  margin-bottom: ${px2vw(20)};
  margin-left: ${(props) => props.margleft};
  padding-left: ${(props) => props.padleft};
  padding-right: ${(props) => props.padright};
`;

export const OutlinedBox = styled.div`
  border: 2px solid ${verdeKuppi2};
  border-radius: ${px2vw(75)};
  width: ${(props) => props.width || '80%'};
  height: auto;
  display: flex;
  flex-direction: ${(props) => props.flexdirection};
  text-align: ${(props) => props.textalign};
  justify-content: ${(props) => props.justifycontent};
  min-height: ${(props) => props.minheight};
  margin-top: ${(props) => props.isRecommended ? px2vw(60) : px2vw(120)};
  margin-bottom: ${px2vw(20)};

  ${(props) => props.thinBorder && `
    border: 1px solid ${cinzaKuppi2};
    padding-left: ${px2vw(60)};
  `};
`;

export const GreenButton = styled.button`
  color: ${branco};
  display: inline-block;
  background: ${verdeKuppi2};
  border: 0px;
  height: ${(props) => props.height};
  width: ${(props) => props.width || '50%'};
  border-radius: ${px2vw(20)};
  font-size: ${(props) => props.fontsize || px2vw(60)};
  padding-top: ${px2vw(40)};
  padding-bottom: ${px2vw(40)};
  padding: ${(props) => props.padding};
  font-weight: bold;
  margin-top: ${(props) => props.margtop || px2vw(60)};
  cursor: pointer;

  ${(props) => props.block && `
    width: 100%;
    display: block;
    padding-top: 0;
    padding-bottom: 0;
  `};
`;

export const GreenBanner = styled.div`
  width: ${(props) => props.width};
  display: flex;
  justify-content: center;
  background: ${verdeKuppi2};
  height: ${px2vw(170)};
  border-radius: 24px 24px 0px 0px;
  font-size: ${px2vw(60)};
  border: 2px solid ${verdeKuppi2};
`;

export const Gr = styled.div`
  border-top: 2px solid ${cinzaKuppi4};
  height: 1px;
  width: ${(props) => props.width || '90%'};
  margin-left: ${px2vw(40)};
  margin-top: ${px2vw(40)};
`;

export const SimpleButton = styled.button`
  color: ${(props) => props.color};
  background: ${(props) => props.background};
  border: ${(props) => props.border || '0px'};
  width: ${(props) => props.width || '50%'};
  border-radius: ${px2vw(20)};
  font-size: ${(props) => props.fontsize || px2vw(60)};
  padding-top: ${px2vw(40)};
  padding-bottom: ${px2vw(40)};
  font-weight: bold;
  margin-top: ${px2vw(60)};
  cursor: pointer;
`;
