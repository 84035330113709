import React from 'react';

import { branco, preto, verdeKuppi1 } from '../../../../styles/colors';
import { Col } from '../../../../styles/grid';
import { ModalPrimaryButton, ModalSecundaryButton } from '../../../../components/Modal/styles';
import { NormalText } from '../../styles';

import RecoveryGoBackButton from '../RecoveryGoBackButton';

export default function RecoveryType({ handler }) {
  return (
    <Col alignCenter>
      <RecoveryGoBackButton handler={handler} goBackPage="main" />
      <NormalText center fontweight="normal">
        Clique em um dos botões abaixo para recuperar seus dados de
        acesso.
      </NormalText>

      <ModalPrimaryButton
        mobile
        borderColor={verdeKuppi1}
        background={verdeKuppi1}
        color={branco}
        onClick={() => handler.handleForgotPassword()}
      >
        <NormalText center>
          Recuperar minha senha
        </NormalText>
      </ModalPrimaryButton>
      <ModalSecundaryButton
        mobile
        background={branco}
        color={preto}
        onClick={() => handler.handleForgotEmail()}
      >
        <NormalText center>
          Recuperar meu e-mail
        </NormalText>
      </ModalSecundaryButton>
    </Col>
  )
}