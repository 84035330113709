/* ------------------------ Login ------------------------ */
/* Main Screen */
export const slugEmail = "E-mail";
export const slugPassword = "Senha";
export const credentialsRecoverySlug = "Esqueci meu e-mail ou senha";
export const placeholderEmail = "nome@email.com.br";
export const placeholderPassword = "Insira sua senha";
export const btnTextLogin = "Entrar";
/* Recovery Type */
export const recoveryText = "Clique em um dos botões abaixo para recuperar seus dados de acesso."
export const recoverEmail = "Recuperar meu e-mail";
export const recoverPassword = "Recuperar minha senha";
/* Recovery Password */
export const recoverPassword2 = "Esqueci minha senha";
export const recoveryText3 = "Enviaremos um link por e-mail com instruções para você redefinir sua senha."
export const recoverPasswordEmailSent = "Instruções enviadas";
export const recoverEmailFound3 = "Enviamos as instruções para troca de senha para seu e-mail";
/* Recovery Email */
export const recoverEmail2 = "Esqueci meu e-mail";
export const recoveryText2 = "Digite o nome e o CPF/CNPJ do estabelecimento cadastrado na plataforma Kuppi Anunciante.";
export const recoverEmailFound2 = "Seu e-mail de acesso a plataforma é:"; 
export const recoverEmailNotFound = "Não foi localizado nenhum usuário para os dados informados.";
export const recoverEmailFound = "E-mail recuperado";
export const placeholderDocument = "Digite o CPF/CNPJ do estabelecimento";
export const inputCompanyName = "Nome do Estabelecimento";
export const inputDocument = "CPF/CNPJ do Estabelecimento";
/* Recovery Common */
export const btnTextLogin2 = "Fazer login";
export const btnConfirm = "Ok";
