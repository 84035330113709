import produce from 'immer';

const INITIAL_STATE = {
  ordersMetrics: {
    lastOrdersList: [],
    perDayList: [],
    perHourList: [],
  },
  gamefication: {
   month_order_qty: 0,
   total_trophys: 0,
   target: 100,
   level: 'Aprendiz',
   level_slug: 'beginner',
   history: [],
   levels: []
  },
  error: false,
  getGameficationloading: false,
  ordersMetricsLoading: false,
};

export default function dashboardMetrics(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@dashboardMetrics/GET_ORDERS_METRICS_REQUEST': {
        draft.ordersMetricsLoading = action.payload.loading;
        draft.error = false;
        break;
      }
      case '@dashboardMetrics/GET_ORDERS_METRICS_SUCCESS': {
        draft.ordersMetricsLoading = false;
        draft.error = false;
        draft.ordersMetrics.perDayList = action.payload.ordersPerDay;
        draft.ordersMetrics.perHourList = action.payload.ordersPerHour;
        draft.ordersMetrics.lastOrdersList = action.payload.lastOrders;
        break;
      }
      case '@dashboardMetrics/GET_ORDERS_METRICS_FAILURE': {
        draft.ordersMetricsLoading = false;
        draft.metrics.orders = {
          total: 0,
        };
        draft.error = true;
        break;
      }
      case '@dashboardMetrics/GET_GAMEFICATION_REQUEST': {
        draft.getGameficationloading = true;
        break;
      }
      case '@dashboardMetrics/GET_GAMEFICATION_SUCCESS': {
        draft.getGameficationloading = false;
        draft.gamefication.month_order_qty = action.payload.gamefication.month_order_qty;
        draft.gamefication.total_trophys = action.payload.gamefication.award_qty;
        draft.gamefication.target = action.payload.gamefication.target_order_qty;
        draft.gamefication.level = action.payload.gamefication.level_name;
        draft.gamefication.level_slug = action.payload.gamefication.level_slug;
        draft.gamefication.history = action.payload.history;
        draft.gamefication.levels = action.payload.levels;
        break;
      }
      case '@dashboardMetrics/GET_GAMEFICATION_FAILURE': {
        draft.gamefication.getGamificationloading = false;
        break;
      }
      default:
    }
  });
}
