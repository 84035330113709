export function getLoyaltyCardRequest() {
  return {
    type: '@loyaltyCard/GET_LOYALTY_CARD_REQUEST',
  };
}

export function getLoyaltyCardSuccess(loyaltyCard) {
  return {
    type: '@loyaltyCard/GET_LOYALTY_CARD_SUCCESS',
    payload: { loyaltyCard },
  };
}

export function getLoyaltyCardFailure() {
  return {
    type: '@loyaltyCard/GET_LOYALTY_CARD_FAILURE',
  };
}

export function getLoyaltyCardMetricsRequest() {
  return {
    type: '@loyaltyCard/GET_LOYALTY_CARD_METRICS_REQUEST',
  };
}

export function getLoyaltyCardMetricsSuccess(loyaltyCardMetrics) {
  return {
    type: '@loyaltyCard/GET_LOYALTY_CARD_METRICS_SUCCESS',
    payload: { loyaltyCardMetrics },
  };
}

export function getLoyaltyCardMetricsFailure() {
  return {
    type: '@loyaltyCard/GET_LOYALTY_CARD_METRICS_FAILURE',
  };
}

export function getLoyaltyCardHistoryRequest() {
  return {
    type: '@loyaltyCard/GET_LOYALTY_CARD_HISTORY_REQUEST',
  };
}

export function getLoyaltyCardHistorySuccess(loyaltyCardHistory) {
  return {
    type: '@loyaltyCard/GET_LOYALTY_CARD_HISTORY_SUCCESS',
    payload: { loyaltyCardHistory },
  };
}

export function getLoyaltyCardHistoryFailure() {
  return {
    type: '@loyaltyCard/GET_LOYALTY_CARD_HISTORY_FAILURE',
  };
}

export function updateLoyaltyCardRequest(
  loyaltyCardActive,
  qtyOrdersActiveBenefit,
  daysToExpiration,
  isAcceptPhysical,
  isAcceptDelivery,
  hasMinimumTicket,
  minimumTicketInputValue,
  hasMinimumOrderAmount,
  minimumOrderAmountValue,
  creditBenefit,
  creditBenefitInputValue,
  discountBenefit,
  discountBenefitInputValue, 
) {
  return {
    type: '@loyaltyCard/UPDATE_LOYALTY_CARD_REQUEST',
    payload: {
      loyaltyCardActive,
      qtyOrdersActiveBenefit,
      daysToExpiration,
      isAcceptPhysical,
      isAcceptDelivery,
      hasMinimumTicket,
      minimumTicketInputValue,
      hasMinimumOrderAmount,
      minimumOrderAmountValue,
      creditBenefit,
      creditBenefitInputValue,
      discountBenefit,
      discountBenefitInputValue, 
    },
  };
}

export function updateLoyaltyCardSuccess(loyaltyCard) {
  return {
    type: '@loyaltyCard/UPDATE_LOYALTY_CARD_SUCCESS',
    payload: { loyaltyCard },
  };
}

export function updateLoyaltyCardFailure() {
  return {
    type: '@loyaltyCard/UPDATE_LOYALTY_CARD_FAILURE',
  };
}

export function clearSuccess() {
  return {
    type: '@loyaltyCard/CLEAR_SUCCESS',
  };
}
