
export function rangeTemplate1(
  couponImage,
  logoImage,
  bgImage,
) {
  return new Promise(async (resolve) => {
    const canvas = document.createElement('canvas'); // 1080 x 1080
    canvas.width = '1080';
    canvas.height = '1080';
    const context = canvas.getContext('2d');

    // Draw coupon info
    context.save();
    context.rotate((1.1 * Math.PI) / 180);
    context.drawImage(couponImage, 190, 257, 750, 750);
    context.restore();

    // Draw background

    context.drawImage(bgImage, 0, 0, context.canvas.width, context.canvas.height);

    // Draw logo
    context.beginPath();
    context.arc(195, 164, 125, 0, 2 * Math.PI);
    context.clip();
    context.drawImage(logoImage, 70, 39, 250, 250);

    await resolve(canvas.toDataURL());
  });
}

export function rangeTemplate2(
  couponImage,
  logoImage,
  bgImage,
) {
  return new Promise(async (resolve) => {
    const canvas = document.createElement('canvas'); // 1080 x 1080
    canvas.width = '1080';
    canvas.height = '1080';
    const context = canvas.getContext('2d');

    // Draw coupon info
    context.save();
    context.rotate((8.75 * Math.PI) / 180);
    context.drawImage(couponImage, 510, 190, 636, 643);
    context.restore();

    // Draw background
    context.drawImage(bgImage, 0, 0, context.canvas.width, context.canvas.height);

    // Draw logo
    context.beginPath();
    context.arc(223, 556.5, 152.3, 0, 2 * Math.PI);
    context.clip();
    context.drawImage(logoImage, 72, 404, 305, 305);

    await resolve(canvas.toDataURL());
  });
}

export function rangeTemplate3(
  couponImage,
  logoImage,
  bgImage,
) {
  return new Promise(async (resolve) => {
    const canvas = document.createElement('canvas'); // 1080 x 1080
    canvas.width = '1080';
    canvas.height = '1080';
    const context = canvas.getContext('2d');

    // Draw coupon info
    context.drawImage(couponImage, 0, 0, context.canvas.width, context.canvas.height);

    // Draw background
    context.drawImage(bgImage, 0, 0, context.canvas.width, context.canvas.height);

    // Draw logo
    context.beginPath();
    context.arc(256, 924, 124, 0, 2 * Math.PI);
    context.clip();
    context.drawImage(logoImage, 130, 780, 270, 270);

    await resolve(canvas.toDataURL());
  });
}

export function rangeTemplate4(
  couponImage,
  logoImage,
  bgImage,
) {
  return new Promise(async (resolve) => {
    const canvas = document.createElement('canvas'); // 1080 x 1080
    canvas.width = '1080';
    canvas.height = '1080';
    const context = canvas.getContext('2d');

    // Draw coupon info
    context.drawImage(couponImage, 0, 0, context.canvas.width, context.canvas.height);

    // Draw background
    context.drawImage(bgImage, 0, 0, context.canvas.width, context.canvas.height);

    // Draw logo
    context.beginPath();
    context.arc(255, 155, 124, 0, 2 * Math.PI);
    context.clip();
    context.drawImage(logoImage, 130, 32, 250, 250);

    await resolve(canvas.toDataURL());
  });
}

export function rangeTemplate5(
  couponImage,
  logoImage,
  bgImage,
) {
  return new Promise(async (resolve) => {
    const canvas = document.createElement('canvas'); // 1080 x 1080
    canvas.width = '1080';
    canvas.height = '1080';
    const context = canvas.getContext('2d');

    // Draw coupon info
    context.drawImage(couponImage, 175, 85, 730, 655);

    // Draw background
    context.drawImage(bgImage, 0, 0, context.canvas.width, context.canvas.height);

    // Draw logo
    context.beginPath();
    context.arc(877, 885, 119, 0, 2 * Math.PI);
    context.clip();
    context.drawImage(logoImage, 750, 750, 260, 260);

    await resolve(canvas.toDataURL());
  });
}
