import React from 'react';

import { Row, Col } from '../../../../styles/grid';
import { ActiveTextGreen, GoToDashboard } from '../styles';

import px2vw from '../../../../utils/responsiveness/px2vw';
import { formatCurrencyBRL } from '../../../../utils/utils';

import {
  SuccessPaymentImage,
  PageTitle,
  PageText,
  SectionBox,
  OrderSumaryTextBold,
  OrderSumaryText,
} from '../../styles';

import { dashboard } from '../../../../routes/routeMap';

import {
  PageTitleSuccess,
  PageTextRenewBlocked,
  PageTextFirstPayment,
  VigencyText,
  CreditCardPaymentText,
  AccessPlatformText,
  PlanActiveText,
} from '../constants';
import {
  SignatureMonthlyAmountText,
  SignatureTotalAmountText,
  SignatureMonthlyInstalmentsText,
} from '../../constants';

export default function PaymentCreditCardSuccessWeb({ handler }) {
  return (
    <>
      <Row alignCenter justifyCenter margbottom={px2vw(70)}>
        <SuccessPaymentImage
          size={px2vw(150)}
          margright={px2vw(80)}
          src={require('../../../../assets/images/success/success.png')}
        />
        <PageTitle fontsize={px2vw(70)}>
          {PageTitleSuccess}
        </PageTitle>
      </Row>
      <Row margbottom={px2vw(90)}>
        <PageText fontsize={px2vw(50)}>
          {(handler.areBlocked || handler.isRenewPayment) && PageTextRenewBlocked}
          {handler.isFirstPayment && PageTextFirstPayment}
        </PageText>
      </Row>
      <SectionBox>
        <Col 
          padtop={px2vw(40)}
          padbottom={px2vw(40)}
          padleft={px2vw(50)}
          padright={px2vw(50)}
          alignStart
        >
          <OrderSumaryTextBold fontSize={px2vw(50)}>
            {`${handler.signaturePaymentData?.subscription?.name}`}
          </OrderSumaryTextBold>
          <ActiveTextGreen fontSize={px2vw(50)}>
            {PlanActiveText}
          </ActiveTextGreen>
          <Row justifyStart margtop={px2vw(60)}>
            <OrderSumaryText fontSize={px2vw(50)}>
              {VigencyText.replace('$$VIGENCY$$', handler.vigencyInfo)}
            </OrderSumaryText>
          </Row>
          <Col justifyStart margtop={px2vw(60)}>
            <OrderSumaryText fontSize={px2vw(50)}>
              {SignatureMonthlyAmountText}
            </OrderSumaryText>
            <OrderSumaryTextBold fontSize={px2vw(50)}>
              {handler.signaturePaymentData?.subscription?.period > 1 &&
                `${handler.signaturePaymentData?.subscription?.period}${SignatureMonthlyInstalmentsText}`}
              {formatCurrencyBRL(handler.signaturePaymentData?.amount?.toFixed(2), {})}
            </OrderSumaryTextBold>
            {handler.signaturePaymentData?.subscription?.period > 1 && (
              <Col margtop={px2vw(10)}>
                <OrderSumaryText fontSize={px2vw(50)}>
                  {SignatureTotalAmountText}
                </OrderSumaryText>
                <OrderSumaryTextBold fontSize={px2vw(50)}>
                  {formatCurrencyBRL(
                    (
                      Math.round(
                        handler.signaturePaymentData?.amount *
                        handler.signaturePaymentData?.subscription?.period *
                          100
                      ) / 100
                    ).toFixed(2),
                    {}
                  )}
                </OrderSumaryTextBold>
              </Col>
            )}
          </Col>

          <Col justifyStart margtop={px2vw(60)}>
            <OrderSumaryText fontSize={px2vw(50)}>
              {CreditCardPaymentText}
            </OrderSumaryText>
            <OrderSumaryTextBold fontSize={px2vw(50)}>
              {handler.signaturePaymentData?.paymentCard !== null &&
                `${handler.signaturePaymentData?.paymentCard?.brand} ****${handler.signaturePaymentData?.paymentCard?.last_digits}`}
            </OrderSumaryTextBold>
          </Col>

          <Row justifyCenter alignCenter margtop={px2vw(140)}>
            <GoToDashboard 
              to={dashboard}
              fontSize={px2vw(60)}
              width={px2vw(1300)}
              height={px2vw(150)}
              borderradius={px2vw(30)}
            >
              {AccessPlatformText}
            </GoToDashboard>
          </Row>
        </Col>
      </SectionBox>
    </>
  );
}
