import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Spinner } from 'reactstrap';

import { SwitchInput } from '../styles';
import {
  NormalText,
  Image,
} from '../../../BaseScreen/styles';
import { Row, Col } from '../../../../../styles/grid';
import {
  branco,
  preto,
} from '../../../../../styles/colors';
import {
  ModalBackground,
} from '../../../Step2-DeliveryParams/Mobile/styles';

import px2vw from '../../../../../utils/responsiveness/px2vw';

import ButtonPrimary from '../../../../../components/ButtonPrimary';
import ButtonSecondary from '../../../../../components/ButtonSecondary';

import history from '../../../../../services/history';
import { onboarding } from '../../../../../routes/routeMap';

import {
  importIfoodCatalogRequest,
  importIfoodCatalogClearSuccess,
} from '../../../../../store/modules/importIfood/actions';

let ifoodCatalogURL = ''; 
    
export function ImportIfoodModal({ handler }) {
  const [importIfoodCatalogURL, setImportIfoodCatalogURL] = useState('');
  const dispatch = useDispatch();

  // Handle ifood catalog url validation
  function handleValidateImportIfoodURL() {
    ifoodCatalogURL = importIfoodCatalogURL.replace('?utm_medium=share', '');
    // Force remove ?utm_medium=share of ifood URL (the URL becomes with this when user get it trough the app)
    const urlRegex = /^https:\/\/(www|m)\.ifood\.com\.br\/[A-Za-z]+\/[A-Za-z0-9-]+\/[A-Za-z0-9-]+\/[A-Za-z0-9]{8}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{12}$/;

    if (ifoodCatalogURL === '') {
      toast.error('URL de importação do Ifood não informada.', {
        autoClose: 6000,
      });
    } else if (ifoodCatalogURL.match(urlRegex)) {
      if (handler.categories.length === 0) {
        // Import Ifood catalog
        dispatch(
          importIfoodCatalogRequest(ifoodCatalogURL)
        );
        handler.setImportIfoodCatalogStep('step2');
      } else {
        handler.setImportIfoodCatalogStep('step1-warn');
      }
    } else {
      toast.error('URL de importação do Ifood inválida.', {
        autoClose: 6000,
      });
    }
  }
  
  return (
    <ModalBackground isVisible={true}>
      <Col
        height={'auto'}
        borderRadius={px2vw(40)}
        width="90%"
        margtop={px2vw(200)}
        pad={px2vw(60)}
        background={branco}
        alignStart
      >
        <NormalText
          fontweight="bold"
          textalign="flex-start"
          color={preto}
          fontsize={px2vw(55)}
        >
          Importar cardápio do iFood
        </NormalText>
        <NormalText
          margtop={px2vw(60)}
          margbottom={px2vw(20)}
          fontweight="normal"
          textalign="flex-start"
          color={preto}
          fontsize={px2vw(42)}
        >
          Informe a url completa do seu restaurante no Ifood
        </NormalText>

        <SwitchInput
          name="importIfoodURL"
          placeholder="Ex: https://www.ifood.com.br/delivery/rio-de-janeiro-rj/b34eaf56-1234-ea43-a3de-b34eaf56b34eaf56"
          width="100%"
          margleft="0px"
          margright="0px"
          value={importIfoodCatalogURL}
          onChange={(t) => {
            setImportIfoodCatalogURL(t.target.value)
          }}
        />

        <Row 
          spaceBetween
          margtop={px2vw(60)}
        >
          <ButtonSecondary
            type="button"
            minWidth={530}
            fontSize={50}
            height={120}
            borderRadius={20}
            color={preto}
            onClick={() => 
              handler.setImportIfoodCatalogStep('')
            }
          >
            Cancelar
          </ButtonSecondary>
          <ButtonPrimary
            minWidth={530}
            fontSize={50}
            height={120}
            borderRadius={20}
            type="button"
            onClick={() => {
              // Validate Ifood URL
              handleValidateImportIfoodURL();
            }}
          >
            Importar
          </ButtonPrimary>
        </Row>
      </Col>
    </ModalBackground>
  );
}

export function ImportIfoodLoadingModal({ handler }) {
  const importIfoodCatalogModalSuccess = useSelector(
    (state) => state.importIfood.importIfoodCatalogModalSuccess
  );
  const dispatch = useDispatch();

  // Handle catalog import result
  useEffect(() => {
    if (importIfoodCatalogModalSuccess) {
      handler.setImportIfoodCatalogStep('step3-success');
    } else if (
      importIfoodCatalogModalSuccess !== null &&
      !importIfoodCatalogModalSuccess
    ) {
      handler.setImportIfoodCatalogStep('step3-failure');
    }
    // Clear success flag
    dispatch(importIfoodCatalogClearSuccess());
  }, [importIfoodCatalogModalSuccess]);

  return (
    <ModalBackground isVisible={true}>
      <Col
        height={'auto'}
        borderRadius={px2vw(40)}
        width="90%"
        margtop={px2vw(200)}
        pad={px2vw(60)}
        background={branco}
        alignStart
      >
        <NormalText
          fontweight="bold"
          textalign="flex-start"
          color={preto}
          fontsize={px2vw(55)}
        >
          Importar cardápio do iFood
        </NormalText>
        <Col 
          alignCenter 
          justifyCenter 
          margtop={px2vw(40)}
          margbottom={px2vw(40)}
        >
          <NormalText
            fontweight="normal"
            color={preto}
            fontsize={px2vw(45)}
            margbottom={px2vw(70)}
          >
            Estamos importando seu cardápio...
          </NormalText>
          <Spinner
            style={{
              width: '1.8rem',
              height: '1.8rem',
              color: preto,
            }}
          />
        </Col>
      </Col>
    </ModalBackground>
  );
}

export function ImportIfoodSuccessModal({ handler }) { 
  return (
    <ModalBackground isVisible={true}>
      <Col
        height={'auto'}
        borderRadius={px2vw(40)}
        width="90%"
        margtop={px2vw(200)}
        pad={px2vw(60)}
        background={branco}
        alignStart
      >
        <Col alignCenter justitifyCenter margtop={px2vw(30)} margbottom={px2vw(30)}>
          <Image
            src={require('../../../../../assets/images/onboarding/checked1@2x.png')}
            width={px2vw(300)}
            height={px2vw(300)}
          />
          <NormalText
            margtop={px2vw(60)}
            fontweight="bold"
            color={preto}
            fontsize={px2vw(60)}
          >
            Cardápio importado com sucesso!
          </NormalText>
        </Col>
        <Row 
          alignCenter justitifyCenter 
          margtop={px2vw(60)}
        >
          <ButtonPrimary
            minWidth={530}
            fontSize={50}
            height={120}
            borderRadius={20}
            type="button"
            onClick={() => {
              handler.setImportIfoodCatalogStep('step4');
            }}
          >
            Concluir
          </ButtonPrimary>
        </Row>
      </Col>
    </ModalBackground>
  );
}

export function ImportIfoodErrorModal({ handler }) { 
  return (
    <ModalBackground isVisible={true}>
      <Col
        height={'auto'}
        borderRadius={px2vw(40)}
        width="90%"
        margtop={px2vw(200)}
        pad={px2vw(60)}
        background={branco}
        alignStart
      >
        <Col alignCenter justitifyCenter margtop={px2vw(-60)} margbottom={px2vw(30)}>
          <Image
            src={require('../../../../../assets/images/error/error.png')}
            width={px2vw(300)}
            height={px2vw(420)}
          />
          <NormalText
            fontweight="bold"
            color={preto}
            fontsize={px2vw(55)}
          >
            Ocorreu um erro ao importar seu cardápio, verifique se a URL
            informada está correta, bem como se o cardápio não está vazio
            e tente novamente. Caso o erro persista, entre em contato com
            nosso suporte!
          </NormalText>
        </Col>
        <Row 
          alignCenter justitifyCenter 
          margtop={px2vw(60)}
        >
          <ButtonPrimary
            minWidth={530}
            fontSize={50}
            height={120}
            borderRadius={20}
            type="button"
            onClick={() => {
              handler.setImportIfoodCatalogStep('');
            }}
          >
            Entendi
          </ButtonPrimary>
        </Row>
      </Col>
    </ModalBackground>
  );
}

export function ImportIfoodSuccessInfoModal({ handler }) { 
  return (
    <ModalBackground isVisible={true}>
      <Col
        height={'auto'}
        borderRadius={px2vw(40)}
        width="90%"
        margtop={px2vw(200)}
        pad={px2vw(60)}
        background={branco}
        alignStart
      >
        <Col alignCenter justitifyCenter margbottom={px2vw(30)}>
          <Image
            src={require('../../../../../assets/images/attention/attention.png')}
            width={px2vw(370)}
            height={px2vw(370)}
          />
          <NormalText
            fontweight="bold"
            color={preto}
            fontsize={px2vw(50)}
            margtop={px2vw(40)}
          >
            Todas as categorias foram importadas pausadas, para que você
            possa fazer uma revisão de tudo que foi importado, antes do
            seu cardápio estar disponível para os usuários e assim
            garantir que está tudo ok.
          </NormalText>
        </Col>
        <Row 
          alignCenter justitifyCenter 
          margtop={px2vw(60)}
        >
          <ButtonPrimary
            minWidth={530}
            fontSize={50}
            height={120}
            borderRadius={20}
            type="button"
            onClick={() => {
              history.push(onboarding);
            }}
          >
            Revisar
          </ButtonPrimary>
        </Row>
      </Col>
    </ModalBackground>
  );
}

export function ImportIfoodMaintenanceModal({ handler }) { 
  return (
    <ModalBackground isVisible={true}>
      <Col
        height={'auto'}
        borderRadius={px2vw(40)}
        width="90%"
        margtop={px2vw(200)}
        pad={px2vw(60)}
        background={branco}
        alignStart
      >
        <Col alignCenter justitifyCenter margbottom={px2vw(30)}>
          <Image
            src={require('../../../../../assets/images/attention/attention.png')}
            width={px2vw(370)}
            height={px2vw(370)}
          />
          <NormalText
            fontweight="bold"
            color={preto}
            fontsize={px2vw(50)}
            margtop={px2vw(40)}
          >
            Importação de cardápio do ifood indisponível!<br /><br />
            Estamos trabalhando para reestabelecer a importação o mais breve possível.<br /><br />
            Tente importar novamente mais tarde.
          </NormalText>
        </Col>
        <Row 
          alignCenter justitifyCenter 
          margtop={px2vw(60)}
        >
          <ButtonPrimary
            minWidth={530}
            fontSize={50}
            height={120}
            borderRadius={20}
            type="button"
            onClick={() => {
              history.push(onboarding);
            }}
          >
            Entendi
          </ButtonPrimary>
        </Row>
      </Col>
    </ModalBackground>
  );
}

export function ImportIfoodWarnCategoriesModal({ handler }) { 
  const dispatch = useDispatch();

  return (
    <ModalBackground isVisible={true}>
      <Col
        height={'auto'}
        borderRadius={px2vw(40)}
        width="90%"
        margtop={px2vw(200)}
        pad={px2vw(60)}
        background={branco}
        alignStart
      >
        <Col alignCenter justitifyCenter margbottom={px2vw(30)}>
          <Image
            src={require('../../../../../assets/images/attention/attention.png')}
            width={px2vw(370)}
            height={px2vw(370)}
          />
          <NormalText
            fontweight="bold"
            color={preto}
            fontsize={px2vw(50)}
            margtop={px2vw(40)}
          >
            Ao realizar a importação do Ifood, seu cardápio atual será substituído.
          </NormalText>
        </Col>
        <Row 
          alignCenter justitifyCenter 
          margtop={px2vw(60)}
        >
          <ButtonPrimary
            minWidth={530}
            fontSize={50}
            height={120}
            borderRadius={20}
            type="button"
            onClick={() => {
              // Import Ifood catalog
              dispatch(
                importIfoodCatalogRequest(ifoodCatalogURL)
              );
              handler.setImportIfoodCatalogStep('step2');
            }}
          >
            Entendi
          </ButtonPrimary>
        </Row>
      </Col>
    </ModalBackground>
  );
}