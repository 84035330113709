import React from 'react';
import { Icon } from 'react-materialize';

import { Container, Modal, CloseButton, Text } from '../common';
import { Col, Row } from '../../../styles/grid';
import { Text as TextWrap } from '../../../styles/common';
import { CampaignStatsBox } from './styles';
import {
  Image,
  ImageBox,
  AdsCard,
  AdsFooter,
  AdsHeader,
} from '../../../components/KuppiBoost/Campaign/Step4-CampaignAds/styles';

import { formatCurrencyBRL, only2DecimalPlaces } from '../../../utils/utils';
import px2vw from '../../../utils/responsiveness/px2vw';

import AdsStats from './AdsStats';
import { statusConfig } from '../TableStatus/status';

export default function AdsDetail({ handleVisible, data }) {
  const campaignType = data.boost_type === 'conversion' ? 'Conversão' : data.boost_type === 'range' ? 'Engajamento' : 'Conversas';
  const principalMetric = data.boost_type === 'conversion' ? 'conversions' : data.boost_type === 'range' ? 'engagements' : 'messages';
  const campaignPrincipalMetric = data.advertiserBoostCampaignStats[principalMetric];
  const campaignSpendAmount = data.advertiserBoostCampaignStats.spend;
  const campaignPrincipalCost = campaignSpendAmount / campaignPrincipalMetric;

  return (
    <Container visible>
      <Modal padding="40px 30p" width={px2vw(580)} minHeight={px2vw(450)}>
        <Row alignCenter justifyCenter height="auto" margbottom={px2vw(20)}>
          <Col alignStart justifyCenter height="auto" width={px2vw(490)}>
            <Text size={px2vw(28)}>Campanha de {campaignType}</Text>
          </Col>
          <Row alignCenter justifyCenter className="ml-auto" width="auto">
            <CloseButton onClick={handleVisible}>
              <Icon>clear</Icon>
            </CloseButton>
          </Row>
        </Row>
        <Row>
          <AdsCard
            margin={`0 ${px2vw(20)} 0 ${px2vw(10)}`}
          >
            <AdsHeader>
              <TextWrap justifyStart size={px2vw(13)} regular wrap>
                {data.main_text}
              </TextWrap>
            </AdsHeader>
            <ImageBox
              className="my-auto"
              width={px2vw(228)}
              height={px2vw(228)}
            >
              <Image
                src={data.campaignImage.url}
              />
            </ImageBox>
            <AdsFooter>
              <Text justifyStart size={px2vw(13)} regular>
                {data.campaign_link.replace('https://', '').toUpperCase()}
              </Text>
              <Text justifyStart size={px2vw(12.5)} className="w-75" regular>
                {data.title}
              </Text>
            </AdsFooter>
          </AdsCard>
          <CampaignStatsBox>
            <AdsStats title="Status" value={statusConfig[data.status].text} />
            <AdsStats title="Pessoas Alcançadas" value={data.advertiserBoostCampaignStats.reach} />
            <AdsStats title="Frequência" value={only2DecimalPlaces(data.advertiserBoostCampaignStats.frequency)} />
            <AdsStats title="Impressões" value={data.advertiserBoostCampaignStats.impressions} />
            <AdsStats title={campaignType} value={campaignPrincipalMetric} />
            <AdsStats title="Cliques" value={data.advertiserBoostCampaignStats.clicks} />
            <AdsStats title="Valor Gasto" value={formatCurrencyBRL(data.advertiserBoostCampaignStats.spend, {})} />
            <AdsStats title={`Custo por ${campaignType}`} value={formatCurrencyBRL(campaignPrincipalCost || 0, {})} />
          </CampaignStatsBox>
        </Row>
      </Modal>
    </Container>
  );
}
