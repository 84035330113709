import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Text } from '../../../../../styles/common';

import px2vw from '../../../../../utils/responsiveness/px2vw';
import { Col, Row } from '../../../../../styles/grid';

import {
  TypeBox,
  TypeBoxContainer,
  RadioButton,
  ManualSegmentationButton,
  Input,
  SliderInputContainer,
} from '../styles';

import { setManualList } from '../../../../../store/modules/massMessage/actions';
import { useMemo } from 'react';

import { CustonInputRange } from '../ManualSegmentation/styles';
import InputRange from 'react-input-range';

export const SegmentationsTable = ({
  segmentations = {
    vip: [],
    recurrent: [],
    risk: [],
    potential: [],
    new: [],
    manual: [],
  },
  selectedSegmentations = [],
  onSelectSegmentation,
  onClickEditManualSegmentation,
  onCreateManualList,
}) => {
  const {
    contactsNewList,
    contactsPotentialList,
    contactsRecurrentList,
    contactsRiskList,
    contactsVipList,
    manualList,
  } = useMemo(() => {
    return {
      contactsNewList: segmentations.new || [],
      contactsVipList: segmentations.vip || [],
      contactsRiskList: segmentations.risk || [],
      contactsPotentialList: segmentations.potential || [],
      contactsRecurrentList: segmentations.recurrent || [],
      manualList: segmentations.manual || [],
    };
  }, [segmentations]);

  const [range, setRange] = useState(
    manualList.length ? manualList.length : contactsPotentialList?.length >= 1000 ? 1000 : contactsPotentialList?.length
  );
  const dispatch = useDispatch();

  const { finishedPagination: finishedOrders } = useSelector(
    (state) => state.orders
  );

  const handleChangeRange = (value) => {
    const newValue = value.toString().trim() === '' ? 0 : parseInt(value);
    setRange(newValue);
    handleCreateManualList(contactsPotentialList, newValue);
  };

  const handleCreateManualList = useCallback(
    (segmentationList = [], qty = 0) => {
      const list = [];

      list.push(
        ...[...segmentationList]
          .sort(() => 2 * Math.round(Math.random()) - 1)
          .slice(0, qty)
      );
      onCreateManualList();
      dispatch(
        setManualList(list, {
          potentialTotal: qty,
          isPotentialFilterActive: true,
        })
      );
    },
    []
  );

  const handleChangePotentialRange = (e) => {
    const value = e.target.value;
    if (value === '') {
      setRange(0);
    } else if (parseInt(value) > contactsPotentialList.length) {
      setRange(contactsPotentialList.length);
    } else {
      setRange(parseInt(value));
    }
    onSelectSegmentation('manual');
    handleCreateManualList(contactsPotentialList, parseInt(value));
  };

  if (Number(finishedOrders.total) < 5) {
    return (
      <TypeBoxContainer>
        <TypeBox
          fullSize
          disabled={!contactsPotentialList.length}
          padding={'2rem 2rem'}
          selected
          noCursor
        >
          <Row height="auto" alignCenter relative>
            <Text size={px2vw(18)}>Potenciais clientes</Text>{' '}
          </Row>
          <Col height="100%" pad={'2rem 2rem'}>
            <Text regular alignCenter size={px2vw(11)}>
              A Kuppi importou todos os números que já conversaram com o seu
              WhatsApp, mesmo sem você ter salvo os contatos.
            </Text>
            <SliderInputContainer>
              <Col height="auto">
                <CustonInputRange
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%',
                    margin: '1rem 0',
                  }}
                >
                  <Col
                    style={{
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: 'auto',
                      paddingRight: '0.5rem',
                      paddingTop: '0.5rem',
                    }}
                  >
                    <Input
                      value={range}
                      onChange={handleChangePotentialRange}
                      type="number"
                      max={contactsPotentialList?.length}
                      min={0}
                    />
                    <Text regular alignCenter size={px2vw(8)}>
                      Quantidade
                    </Text>
                  </Col>
                  <InputRange
                    maxValue={contactsPotentialList?.length}
                    minValue={0}
                    step={1}
                    value={range}
                    onChange={handleChangeRange}
                  />
                </CustonInputRange>
              </Col>
              <Text regular alignCenter size={px2vw(11)}>
                Existem{' '}
                <strong className="mx-1">
                  {range} usuário
                  {range === 1 ? '' : 's'}
                </strong>{' '}
                nessa lista
              </Text>
            </SliderInputContainer>
          </Col>
          <Row height="auto">
            <Text regular alignCenter size={px2vw(11)}>
              Conforme seus contatos forem realizando pedidos, eles serão
              divididos em segmentações de acordo com o número de pedidos que
              eles possuem.
            </Text>
          </Row>
        </TypeBox>
      </TypeBoxContainer>
    );
  }

  return (
    <TypeBoxContainer grid>
      <TypeBox
        disabled={!contactsNewList.length}
        selected={
          contactsNewList.length && selectedSegmentations.includes('new')
        }
        onClick={() =>
          !contactsNewList.length ? null : onSelectSegmentation('new')
        }
      >
        <Row height="auto" alignCenter relative>
          <Text size={px2vw(14)}>Novos Clientes</Text>{' '}
          <RadioButton
            selected={
              contactsNewList.length && selectedSegmentations.includes('new')
            }
          />
        </Row>
        <Row margtop={px2vw(7)} height="auto">
          <Text regular alignCenter size={px2vw(11)}>
            Tem apenas um pedido e esse pedido está dentro dos últimos 30 dias.
          </Text>
        </Row>
        <Row margtop="auto" height="auto" className="pb-2">
          <Text regular alignCenter size={px2vw(11)}>
            Existem{' '}
            <strong className="mx-1">{contactsNewList.length} usuários</strong>{' '}
            nessa lista
          </Text>
        </Row>
      </TypeBox>
      <TypeBox
        disabled={!contactsRecurrentList.length}
        selected={
          contactsRecurrentList.length &&
          selectedSegmentations.includes('recurrent')
        }
        onClick={() =>
          !contactsRecurrentList.length
            ? null
            : onSelectSegmentation('recurrent')
        }
      >
        <Row height="auto" alignCenter relative>
          <Text size={px2vw(14)}>Recorrentes</Text>
          <RadioButton
            selected={
              contactsRecurrentList.length &&
              selectedSegmentations.includes('recurrent')
            }
          />
        </Row>
        <Row margtop={px2vw(7)} height="auto">
          <Text regular alignCenter size={px2vw(11)}>
            Realizou mais de um pedido e não é VIP.
          </Text>
        </Row>
        <Row margtop="auto" height="auto" className="pb-2">
          <Text regular alignCenter size={px2vw(11)}>
            Existem{' '}
            <strong className="mx-1">
              {contactsRecurrentList.length} usuário
              {contactsRecurrentList.length === 1 ? '' : 's'}
            </strong>{' '}
            nessa lista
          </Text>
        </Row>
      </TypeBox>
      <TypeBox
        disabled={!contactsVipList.length}
        selected={
          contactsVipList.length && selectedSegmentations.includes('vip')
        }
        onClick={() =>
          !contactsVipList.length ? null : onSelectSegmentation('vip')
        }
      >
        <Row height="auto" alignCenter relative>
          <Text size={px2vw(14)}>VIPS</Text>
          <RadioButton
            selected={
              contactsVipList.length && selectedSegmentations.includes('vip')
            }
          />
        </Row>
        <Row margtop={px2vw(7)} height="auto">
          <Text regular alignCenter size={px2vw(11)}>
            Tem mais de 3 pedidos e os últimos 3 pedidos foram dentro dos 30
            dias.
          </Text>
        </Row>
        <Row margtop="auto" height="auto" className="pb-2">
          <Text regular alignCenter size={px2vw(11)}>
            Existem{' '}
            <strong className="mx-1">
              {contactsVipList.length} usuário
              {contactsVipList.length === 1 ? '' : 's'}
            </strong>{' '}
            nessa lista
          </Text>
        </Row>
      </TypeBox>
      <TypeBox
        disabled={!contactsRiskList.length}
        selected={
          contactsRiskList.length && selectedSegmentations.includes('risk')
        }
        onClick={() =>
          !contactsRiskList.length ? null : onSelectSegmentation('risk')
        }
      >
        <Row height="auto" alignCenter relative>
          <Text size={px2vw(14)}>Em risco</Text>{' '}
          <RadioButton
            selected={
              contactsRiskList.length && selectedSegmentations.includes('risk')
            }
          ></RadioButton>
        </Row>
        <Row margtop={px2vw(7)} height="auto">
          <Text regular alignCenter size={px2vw(11)}>
            Já pediu pelo menos 1 vez e o último pedido foi a mais de 30 dias.
          </Text>
        </Row>
        <Row margtop="auto" height="auto" className="pb-2">
          <Text regular alignCenter size={px2vw(11)}>
            Existem{' '}
            <strong className="mx-1">{contactsRiskList.length} usuários</strong>{' '}
            nessa lista
          </Text>
        </Row>
      </TypeBox>
      <TypeBox
        disabled={!contactsPotentialList.length}
        selected={
          contactsPotentialList.length &&
          selectedSegmentations.includes('potential')
        }
        onClick={() =>
          !contactsPotentialList.length
            ? null
            : onSelectSegmentation('potential')
        }
      >
        <Row height="auto" alignCenter relative>
          <Text size={px2vw(14)}>Potenciais clientes</Text>{' '}
          <RadioButton
            selected={
              contactsPotentialList.length &&
              selectedSegmentations.includes('potential')
            }
          />
        </Row>
        <Row margtop={px2vw(7)} height="auto">
          <Text regular alignCenter size={px2vw(11)}>
            Contatos do WhatsApp que nunca realizaram um pedido.
          </Text>
        </Row>
        <Row margtop="auto" height="auto" className="pb-2">
          <Text regular alignCenter size={px2vw(11)}>
            Existem{' '}
            <strong className="mx-1">
              {contactsPotentialList.length} usuário
              {contactsPotentialList.length === 1 ? '' : 's'}
            </strong>{' '}
            nessa lista
          </Text>
        </Row>
      </TypeBox>
      <TypeBox
        disabled={!contactsPotentialList.length}
        selected={manualList.length && selectedSegmentations.includes('manual')}
        onClick={() =>
          !manualList.length
            ? onClickEditManualSegmentation(true)
            : onSelectSegmentation('manual')
        }
      >
        <Row height="auto" alignCenter relative>
          <Text size={px2vw(13)}>Público manual</Text>{' '}
          <RadioButton
            selected={
              manualList.length && selectedSegmentations.includes('manual')
            }
          ></RadioButton>
        </Row>
        <Row margtop={px2vw(7)} height="auto">
          <Text regular alignCenter size={px2vw(11)}>
            Crie o seu público do disparo manualmente definindo quantos serão
            impactados.
          </Text>
        </Row>
        {manualList.length > 0 && (
          <Row margtop="auto" height="auto" className="pb-2">
            <Text regular alignCenter size={px2vw(11)}>
              Existem{' '}
              <strong className="mx-1">
                {manualList.length} usuário
                {manualList.length === 1 ? '' : 's'}
              </strong>{' '}
              nessa lista
            </Text>
          </Row>
        )}
        <Row margtop="auto" height="auto" className="pb-2">
          <ManualSegmentationButton
            onClick={() => onClickEditManualSegmentation(true)}
          >
            {manualList.length > 0 ? 'Editar' : 'Criar manualmente'}
          </ManualSegmentationButton>
        </Row>
      </TypeBox>
    </TypeBoxContainer>
  );
};
