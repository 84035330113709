import React from 'react';
import { Spinner } from 'reactstrap';
import Modal from '../../../../components/Modal';
import { 
  CloseButton, 
  CloseImage, 
  ModalHeader, 
  ModalPrimaryButton, 
  ModalPrimaryButtonText, 
  ModalText, 
  ModalText2,
  ModalTitleText 
} from '../../../../components/Modal/styles';
import { preto } from '../../../../styles/colors';
import { Col } from '../../../../styles/grid';
import { 
  btnConfirm, 
  inputCompanyName, 
  inputDocument, 
  placeholderDocument, 
  recoverEmail, 
  recoverEmail2, 
  recoverEmailFound2, 
  recoverEmailNotFound, 
  recoveryText2 
} from '../../constants';
import px2vw from '../../../../utils/responsiveness/px2vw';
import { LoadingBox } from '../../../_layouts/styles/common';
import { 
  ForgotEmailPasswordModal, 
  InputsBox, 
  LoginInput, 
  LoginInputBox, 
  LoginInputLabel 
} from '../../styles';

export default function RecoveryEmailWeb({ handler }) {
  return (
    <>
      {handler.recoverEmailModal && (
        <Modal
          isVisible={handler.recoverEmailModal}
          width={px2vw(480)}
          height={px2vw(420)}
        >
          <ForgotEmailPasswordModal>
            <ModalHeader>
              <ModalTitleText>{recoverEmail2}</ModalTitleText>
              <CloseButton onClick={() => handler.setRecoverEmailModal(false)}>
                <CloseImage
                  src={require('../../../../assets/images/close/close.png')}
                />
              </CloseButton>
            </ModalHeader>
            <ModalText paddingLR={px2vw(19)} margtop={px2vw(50)}>
              {recoveryText2}
            </ModalText>
            <InputsBox>
              <LoginInputBox margtop={px2vw(30)}>
                <Col alignStart margleft={px2vw(200)}>
                  <LoginInputLabel>{inputCompanyName}</LoginInputLabel>
                </Col>
                <LoginInput
                  name="name"
                  type="text"
                  width={px2vw(400)}
                  placeholder={inputCompanyName}
                  maxLength="100"
                  value={handler.completeNameInputValue}
                  onChange={handler.setCompleteNameInputValue}
                />
              </LoginInputBox>
              <LoginInputBox margtop={px2vw(10)}>
                <Col alignStart margleft={px2vw(200)}>
                  <LoginInputLabel>{inputDocument}</LoginInputLabel>
                </Col>
                <LoginInput
                  name="name"
                  type="text"
                  width={px2vw(400)}
                  placeholder={placeholderDocument}
                  maxLenght="18"
                  value={handler.cnpjInputValue}
                  onChange={handler.setCnpjInputValue}
                />
              </LoginInputBox>
            </InputsBox>
            <ModalPrimaryButton
              margtop="40px"
              width={px2vw(250)}
              onClick={() => handler.handleRecoverEmail()}
            >
              {handler.recoverEmailLoading ? (
                <LoadingBox>
                  <Spinner
                    style={{
                      width: '1.8rem',
                      height: '1.8rem',
                      color: preto,
                    }}
                  />
                </LoadingBox>
              ) : (
                <ModalPrimaryButtonText>
                  {recoverEmail}
                </ModalPrimaryButtonText>
              )}
            </ModalPrimaryButton>
          </ForgotEmailPasswordModal>
        </Modal>
      )}

      {/* Not found user with email modal */}
      {handler.notRecoveredEmailModal && (
        <Modal
          isVisible={handler.notRecoveredEmailModal}
          width={px2vw(430)}
          height={px2vw(220)}
        >
          <ForgotEmailPasswordModal>
            <ModalHeader>
              <ModalTitleText>{recoverEmail2}</ModalTitleText>
              <CloseButton onClick={() => handler.setNotRecoveredEmailModal(false)}>
                <CloseImage
                  src={require('../../../../assets/images/close/close.png')}
                />
              </CloseButton>
            </ModalHeader>
            <ModalText paddingLR={px2vw(22)}>
              {recoverEmailNotFound}
            </ModalText>

            <ModalPrimaryButton
              width={px2vw(250)}
              margtop={px2vw(40)}
              onClick={() => handler.setNotRecoveredEmailModal(false)}
            >
              <ModalPrimaryButtonText>{btnConfirm}</ModalPrimaryButtonText>
            </ModalPrimaryButton>
          </ForgotEmailPasswordModal>
        </Modal>
      )}

      {/* Found user with email modal */}
      {handler.recoveredEmailModal && (
        <Modal
          isVisible={handler.recoveredEmailModal}
          width={px2vw(430)}
          height={px2vw(220)}
        >
          <ForgotEmailPasswordModal>
            <ModalHeader>
              <ModalTitleText>{recoverEmail2}</ModalTitleText>
              <CloseButton onClick={() => handler.setRecoveredEmailModal(false)}>
                <CloseImage
                  src={require('../../../../assets/images/close/close.png')}
                />
              </CloseButton>
            </ModalHeader>
            <ModalText paddingLR={px2vw(22)}>
              {recoverEmailFound2}
            </ModalText>
            <ModalText2 paddingLR={px2vw(22)}>
              {handler.recoveredEmail}
            </ModalText2>

            <ModalPrimaryButton
              width={px2vw(250)}
              margtop={px2vw(40)}
              onClick={() => handler.setRecoveredEmailModal(false)}
            >
              <ModalPrimaryButtonText>{btnConfirm}</ModalPrimaryButtonText>
            </ModalPrimaryButton>
          </ForgotEmailPasswordModal>
        </Modal>
      )}
    </>
  )
}