export function importIfoodCatalogRequest(ifoodURL) {
  return {
    type: '@importIfood/IMPORT_IFOOD_CATALOG_REQUEST',
    payload: { ifoodURL },
  };
}

export function importIfoodCatalogSuccess() {
  return {
    type: '@importIfood/IMPORT_IFOOD_CATALOG_SUCCESS',
  };
}

export function importIfoodCatalogFailure() {
  return {
    type: '@importIfood/IMPORT_IFOOD_CATALOG_FAILURE',
  };
}

export function importIfoodCatalogClearSuccess() {
  return {
    type: '@importIfood/IMPORT_IFOOD_CATALOG_CLEAR_SUCCESS',
  };
}
