import styled from 'styled-components';

import px2vw from '../../utils/responsiveness/px2vw';
import {
  branco,
  cinzaKuppi1,
  cinzaKuppi4,
  verdeKuppi1,
} from '../../styles/colors';
import { Select } from '@rocketseat/unform';

export const ContentPage = styled.div`
  float: right;
  width: ${px2vw(1028)};
  height: auto;
  min-height: ${px2vw(620)};
  background-color: ${cinzaKuppi1};

  ${(props) => `
    min-height: ${props.minheight};
  `};

  ${(props) =>
    props.margleft &&
    `
    margin-left: ${props.margleft};
  `};
`;

export const PageTitle = styled.div`
  font-size: ${px2vw(25)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.72px;
  font-weight: bold;

  ${(props) =>
    props.isSubTitle &&
    `
    font-size: ${px2vw(20)};
    margin-bottom: ${px2vw(10)};
  `}
`;

export const BannerContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${branco};
  border-radius: ${px2vw(20)};
  width: 100%;
  height: ${px2vw(170)};
  margin-top: ${px2vw(30)};
  padding: ${px2vw(20)};
  position: relative;
  overflow: hidden;

  box-shadow: 0px 8px 9px -2px ${cinzaKuppi4};
`;

export const TextContent = styled.div`
  font-size: ${px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.72px;
  font-weight: normal;
  width: 70%;
  z-index: 99;
`;

export const Vector = styled.div`
  position: absolute;
  width: ${px2vw(295)};
  height: ${px2vw(170)};
  right: ${px2vw(0)};
  top: ${px2vw(0)};
  background: linear-gradient(0deg, #10a798, #10a798), #3e393e;
  border-radius: 0 ${px2vw(20)} ${px2vw(20)} 0;
`;

export const InvisibleVector = styled.div`
  display: inline-block;
  position: absolute;
  width: ${px2vw(295)};
  height: ${px2vw(170)};
  right: ${px2vw(165)};
  top: 0;
  z-index: 9;
  border-bottom: ${px2vw(170)} solid #fff;
  border-left: ${px2vw(150)} solid transparent;
  border-right: ${px2vw(130)} solid transparent;
`;

export const ImgBox = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  width: 20%;
  align-items: center;
  position: absolute;
  object-fit: contain;

  top: ${px2vw(0)};
  right: ${px2vw(0)};

  z-index: 99;
`;

export const ImgIcon = styled.img`
  width: 65%;
`;

export const OptionSelector = styled(Select)`
  margin-top: ${px2vw(5)};
  height: ${px2vw(39)};
  font-size: ${px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.36px;
  border-radius: 5px;
  background-color: white;
  ${(props) => `
    width: ${props.width};
    height: ${props.height};
    margin-top: ${props.margtop};
    margin-right: ${props.margright};
    padding-left: ${props.padleft};
  `};

  ${(props) =>
    props.greenSelector &&
    `
    border: 0px;
    color: ${verdeKuppi1};
    font-weight: bold;
    background-color: ${branco};
    font-size: ${px2vw(18)};
    text-decoration: underline;
    text-decoration-thickness: 3px;
    text-underline-offset: 3px;
  `};
`;

export const ConfigGroupTitle = styled.h3`
  font-family: AvenirNextLTPro;
  font-weight: 700;
  font-size: ${px2vw(18)};
  letter-spacing: 0.48px;

  ${(props) =>
    props.isSmaller &&
    `
    font-size: ${px2vw(14)};
    font-weight: normal;
  `}

  ${(props) =>
    props.isSmall &&
    `
    font-size: ${px2vw(16)};
  `}

  ${(props) =>
    props &&
    `
    width: ${props.width};
    margin-top: ${props.margtop};
`}
`;

export const SaveButton = styled.button`
  background-color: ${verdeKuppi1};
  height: ${px2vw(40)};
  width: ${px2vw(100)};
  line-height: ${px2vw(40)};
  border: none;
  border-radius: ${px2vw(8)};

  ${(props) => `
    margin-top: ${props.margtop};
    margin-left: ${props.margleft};
  `};

  ${(props) =>
    props.financialPageWidth &&
    `
    width: ${px2vw(120)};
  `};
`;

export const SaveButtonText = styled.div`
  font-size: ${px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.36px;
  color: ${branco};
`;