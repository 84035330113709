import styled from 'styled-components';
import { cinzaKuppi6 } from '../../../styles/colors';

export const SvgBox = styled.svg`
  height: 30px;
  width: 30px;
`;

export const Close = styled.path.attrs({
  d:
    'M14.5,2A12.5,12.5,0,1,0,27,14.5,12.5,12.5,0,0,0,14.5,2Zm0,22.5a10,10,0,1,1,10-10A10,10,0,0,1,14.5,24.5Z',
  transform: 'translate(0.5 0.5)',
})`
  fill: ${cinzaKuppi6};
`;

export const Close2 = styled.path.attrs({
  d:
    'M16.139,9.364a1.25,1.25,0,0,0-1.775,0l-1.612,1.625L11.139,9.364a1.255,1.255,0,0,0-1.775,1.775l1.625,1.612L9.364,14.364a1.255,1.255,0,1,0,1.775,1.775l1.613-1.625,1.612,1.625a1.255,1.255,0,1,0,1.775-1.775l-1.625-1.612,1.625-1.613a1.25,1.25,0,0,0,0-1.775Z',
  transform: 'translate(2.249 2.249)',
})`
  fill: ${cinzaKuppi6};
`;
