import React, { useEffect, useState } from 'react';

import { Row, Col } from '../../../../styles/grid';
import {
  branco,
  preto,
  cinzaKuppi1,
  cinzaKuppi3
} from '../../../../styles/colors';
import {
  NormalText,
  Image,
} from '../styles';

import px2vw from '../../../../utils/responsiveness/px2vw';

import WelcomeScreen from '../../Step0-WelcomeScreen';
import DeliveryParams from '../../Step2-DeliveryParams';
import Catalog from '../../../Catalog';
import MobileSideBar from './MobileSideBar';
import PrintConfigs from '../../Step4-PrintConfigs';
import StorePublicity from '../../Step5-StorePublicity';
import ProfileConfigs from '../../Step1-ProfileConfigs';

export default function BaseScreenMobile({ handler }) {
  const progressStep = handler.baseScreen.currOnboardingStepPage;
  const [percentage, setPercentage] = useState('0%');
  const [contentTitle, setContentTitle] = useState('Configuração do Perfil');

  function getTitle() {
    switch (progressStep) {
      case 'step1':
        setContentTitle('Configuração do Perfil')
        break;
      case 'step2':
        setContentTitle('Parâmetros do Delivery')
        break;
      case 'step3':
        setContentTitle('Catálogo')
        break;
      case 'step4':
        setContentTitle('Conf. de pedido')
        break;
      case 'step5':
        setContentTitle('Divulgação')
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    getTitle()
  }, [progressStep])

  function ProgressBar({ completed }) {
    const height = 40;

    useEffect(() => {
      if (completed === 'step5') {
        setPercentage('80%')
      } else if (completed === 'step4') {
        setPercentage('60%')
      } else if (completed === 'step3') {
        setPercentage('40%')
      } else if (completed === 'step2') {
        setPercentage('20%')
      } else if (completed === 'step1') {
        setPercentage('0%')
      } else {
        setPercentage('100%')
      }

    }, [completed])

    return (
      <Row>
        <Row
          background={handler.baseScreen.handleProgressBarColor('step1', cinzaKuppi3)}
          height={px2vw(height)}
          margtop={px2vw(40)}
          margbottom={px2vw(40)}
          margleft={px2vw(60)}
          width={px2vw(300)}
          borderRadius={`${px2vw(150)} 0 0  ${px2vw(150)}`}
        >
        </Row>
        <Row
          background={handler.baseScreen.handleProgressBarColor(
            'step2', cinzaKuppi3)}
          height={px2vw(height)}
          margtop={px2vw(40)}
          margbottom={px2vw(40)}
          margleft={px2vw(5)}
          width={px2vw(300)}
        >
        </Row>
        <Row
          background={handler.baseScreen.handleProgressBarColor(
            'step3', cinzaKuppi3)}
          height={px2vw(height)}
          margtop={px2vw(40)}
          margbottom={px2vw(40)}
          margleft={px2vw(5)}
          width={px2vw(300)}
        >
        </Row>
        <Row
          background={handler.baseScreen.handleProgressBarColor(
            'step4', cinzaKuppi3)}
          height={px2vw(height)}
          margtop={px2vw(40)}
          margbottom={px2vw(40)}
          margleft={px2vw(6)}
          width={px2vw(300)}
        >
        </Row>
        <Row
          background={handler.baseScreen.handleProgressBarColor(
            'step5', cinzaKuppi3)}
          height={px2vw(height)}
          margtop={px2vw(40)}
          margbottom={px2vw(40)}
          margleft={px2vw(5)}
          margright={px2vw(60)}
          width={px2vw(300)}

          borderRadius={`0 ${px2vw(150)} ${px2vw(150)} 0`}
        >
        </Row>
      </Row>
    )
  }

  function Content({ title }) {
    return (
      <Col height='auto' alignStart>
        <Row pad={px2vw(30)} height={px2vw(210)}>
          <Row 
            justifyStart width='auto'
            cursor="pointer"  
          >
            <Image
              width={px2vw(180)}
              height={px2vw(150)}
              alt="Menu"
              src={require('../../../../assets/images/onboarding/side_menu@2x.png')}
              onClick={() => handler.baseScreen.setSideMenuState(!handler.baseScreen.sideMenuState)}
            />
          </Row>
          <Row justifyCenter>
            <NormalText
              width="auto"
              padtop={px2vw(20)}
              fontweight='bold'
              fontsize={px2vw(75)}
            >
              {title}
            </NormalText>
          </Row>
        </Row>
        <Col
          background={preto}
          height='auto'
          alignStart
        >
          <Row spaceBetween>
            <NormalText
              color={branco}
              padtop={px2vw(20)}
              padleft={px2vw(60)}
              fontweight='bold'
              fontsize={px2vw(55)}
            >
              Seu progresso
            </NormalText>

            <NormalText
              color={branco}
              padtop={px2vw(20)}
              padleft={px2vw(60)}
              padright={px2vw(120)}
              fontweight='bold'
              fontsize={px2vw(55)}
            >
              {percentage}
            </NormalText>
          </Row>
          <ProgressBar completed={handler.baseScreen.currOnboardingStep} />
        </Col>
      </Col>
    )
  }

  if (progressStep === 'initial')  {
    return <WelcomeScreen isWeb={handler.isWeb} handler={handler.welcomeScreen} />
  } else {
    return (
      <Col height={handler.baseScreen.sideMenuState && px2vw(3000)}>
        {handler.baseScreen.sideMenuState && <MobileSideBar handler={handler.baseScreen} />}
        <Content title={contentTitle} />
        <Col background={cinzaKuppi1} >
          {progressStep === 'step1' && <ProfileConfigs handler={handler.profileConfigsScreen} />}
          {progressStep === 'step2' && <DeliveryParams isOnboarding handler={handler.deliveryParamsScreen} />}
          {progressStep === 'step3' && <Catalog isOnboarding handler={handler.catalogScreen} />}
          {progressStep === 'step4' && <PrintConfigs handler={handler.printConfigsScreen} />}
          {progressStep === 'step5' && <StorePublicity />}
        </Col>
      </Col>
    );
  }
}
