import React, { useState, useEffect } from 'react';
import { Progress } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import DeleteIcon from '@material-ui/icons/Delete';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import {
  Container,
  Span,
  DeliveryImg,
  ProgressBar,
  BackIcon,
  TotalBar,
  ModalFooter,
  ButtonPrimary,
} from './styles';
import {
  BtnControl,
  ManualOrderButtonPrimary,
  ManualOrderButtonSecondary
} from '../ProductDetails/styles';
import {
  cinzaKuppi1,
  preto,
  verdeKuppi5,
  cinzaKuppi10,
  vermelhoKuppi1,
} from '../../../styles/colors';
import { Row, Col } from '../../../styles/grid';

import px2vw from '../../../utils/responsiveness/px2vw';
import { formatCurrencyBRL } from '../../../utils/utils';
import { sendManualOrderInfo } from '../../../services/vendors/mixpanel';

import {
  cleanBag,
  setCurrStep,
  setDeliveryType,
  removeFromBag,
  updateInBag,
  setCurrProductViewDetails,
  setDeliveryFee,
} from '../../../store/modules/manualOrder/actions';

export default function ViewBag(props) {
  const hasFreeTrialValid = useSelector((state) => state.user.hasFreeTrialValid);
  const user = useSelector((state) => state.user.user);
  const manualOrderData = useSelector((state) => state?.manualOrder);
  const previousStep = useSelector(
    (state) => state.manualOrder?.previousStep
  );
  const { items_in_bag, priceOfTheWholeBag } = manualOrderData.bag;
  const [ selectedEditBagItem, setSelectedEditBagItem ] = useState(null);
  const [ selectedEditBagItemCount, setSelectedEditBagItemCount ] = useState(null);
  const dispatch = useDispatch();

  function handleClickEditBagItem(item) {
    setSelectedEditBagItem(item);
    setSelectedEditBagItemCount(item.productQuantity);
  }

  function handleOnChangeProductCount(newCount) {
    const itemNewCount = parseInt(selectedEditBagItemCount) + parseInt(newCount)
    setSelectedEditBagItemCount(itemNewCount);
  }

  function handleReviewItemBag() {
    dispatch(setCurrProductViewDetails(selectedEditBagItem));
    dispatch(setCurrStep('2-1', '2-2'));
  }

  function handleUpdateItemBag() {
    if (selectedEditBagItemCount === 0) {
      dispatch(removeFromBag(selectedEditBagItem));
    } else {
      dispatch(updateInBag(selectedEditBagItem.id, selectedEditBagItemCount));
    }
    setSelectedEditBagItem(null);
  }

  function handleBackToCatalog() {
    dispatch(setCurrStep('2'));
  }

  function handleCleanBag() {
    dispatch(cleanBag());
    dispatch(setCurrStep('2'));
  }

  function handleGoToPaymentMethod(type) {
    //MixPanel Event
    sendManualOrderInfo({
      id: user.advertiserInfo.id,
      name: user.advertiserInfo.name,
      email: user.email,
      currManualOrderStep: 'Bag',
      hasFreeTrialValid: hasFreeTrialValid
    });
    dispatch(setDeliveryType(type));

    if(type === 'take_away'){
      dispatch(setDeliveryFee(0))
    }

    if (previousStep) {
      dispatch(setCurrStep(previousStep))
    } else {
      const path = type === 'delivery' ? '4' : '3';
      dispatch(setCurrStep(path));
    }
  }

  useEffect(() => {
    if (items_in_bag.length === 0) {
      handleCleanBag();
    }
  }, [items_in_bag])

  return (
    <>
      <ProgressBar>
        <Progress
          value={40}
          bar={true}
          style={{
            backgroundColor: verdeKuppi5,
            borderRaduis: '0px',
            height: '10px',
          }}
        />
      </ProgressBar>
      <Row
        justifyStart
        alignCenter
        className="px-2 py-1"
        cursor="pointer"
        background={cinzaKuppi1}
        onClick={() => handleBackToCatalog()}
      >
        <BackIcon
          src={require('../../../assets/images/arrows/arrow_left.png')}
        />
        <Span padding={px2vw(3)} color={preto}>
          Voltar
        </Span>

        <Span className="mx-auto" padding={px2vw(3)} color={preto}>
          Sua Sacola
        </Span>

        <Span className="ml-auto" padding={px2vw(3)} color={preto}></Span>
      </Row>

      <Container>
        {items_in_bag.length > 0 &&
          items_in_bag?.map((item, index) => (
            <Col
              className="px-5"
              justifyStart
              alignEnd
              cursor="pointer"
              onClick={() => handleClickEditBagItem(item)}
            >
              <Row alignCenter className="mb-2 mt-3">
                <Span
                  fontSize={px2vw(11.5)}
                  color={cinzaKuppi10}
                  className="mr-auto"
                  cursor="pointer"
                >
                  {item.productQuantity}x {item.name}
                </Span>
                {item.priceWithComplements > 0 && (
                  <Span
                    fontSize={px2vw(15)}
                    color={cinzaKuppi10}
                    className="ml-auto"
                    cursor="pointer"
                  >
                    {formatCurrencyBRL(item.priceWithComplements, 2)}
                  </Span>
                )}
                <MoreVertIcon className="ml-2" fontSize="small" />
              </Row>
              {item?.selectedComplements?.length > 0 &&
                item.selectedComplements.map((complement) => (
                  <React.Fragment key={complement.id}>
                    {complement.options.map((option) => (
                      <React.Fragment key={option.id}>
                        {option?.quantity > 0 && !option.is_selected ? (
                          <Row
                            key={`${item.id}-${index}`}
                            className="pl-3"
                            justifyStart
                          >
                            <Span
                              fontSize={px2vw(11)}
                              color={cinzaKuppi10}
                              fontWeight="500"
                            >
                              {option.quantity}x {option.name}
                            </Span>

                            {option.amount > 0 && (
                              <Span
                                fontSize={px2vw(11)}
                                color={cinzaKuppi10}
                                fontWeight="bolder"
                                className="ml-auto"
                              >
                                {formatCurrencyBRL(option.amount, 2)}
                              </Span>
                            )}
                          </Row>
                        ) : (
                          option.quantity > 0 &&
                          option.is_selected && (
                            <Row className="pl-3" justifyStart>
                              <Span
                                fontSize={px2vw(11)}
                                color={cinzaKuppi10}
                                fontWeight="500"
                                key={`${item.id}-${index}`}
                              >
                                {option.quantity}x {option.name}
                              </Span>

                              {option.amount > 0 && (
                                <Span
                                  fontSize={px2vw(11)}
                                  color={cinzaKuppi10}
                                  fontWeight="bolder"
                                  className="ml-auto"
                                >
                                  {formatCurrencyBRL(option.amount, 2)}
                                </Span>
                              )}
                            </Row>
                          )
                        )}
                      </React.Fragment>
                    ))}
                  </React.Fragment>
                ))}
            </Col>
          ))}
      </Container>
      <TotalBar className="px-5 py-2 my-2">
        <Row>
          <Span
            fontSize={px2vw(12)}
            color={cinzaKuppi10}
            fontWeight="500"
            className="mr-auto"
            display="inline"
          >
            Subtotal:
          </Span>
          <Span
            fontSize={px2vw(12)}
            color={cinzaKuppi10}
            fontWeight="500"
            className="ml-auto"
            display="inline"
          >
            {formatCurrencyBRL(priceOfTheWholeBag, 2)}
          </Span>
        </Row>
        <Row>
          <Span
            fontSize={px2vw(17)}
            color={cinzaKuppi10}
            fontWeight="bolder"
            className="mr-auto"
          >
            Total:
          </Span>
          <Span
            fontSize={px2vw(17)}
            color={cinzaKuppi10}
            fontWeight="bolder"
            className="ml-auto"
          >
            {formatCurrencyBRL(priceOfTheWholeBag, 2)}
          </Span>
        </Row>
        <Span
          fontSize={px2vw(11)}
          color={vermelhoKuppi1}
          fontWeight="600"
          className="ml-auto mt-2"
          cursor="pointer"
          onClick={() => handleCleanBag()}
        >
          Limpar Sacola <DeleteIcon fontSize="small" />
        </Span>
      </TotalBar>
      {selectedEditBagItem && (
        <ModalFooter>
          <Row
            justifyCenter
            alignCenter
            className="mb-4"
            spaceAround
          >
            <Span
              fontSize={px2vw(16)}
              color={cinzaKuppi10}
            >
              {selectedEditBagItem.name}
            </Span>
            <Row alignEnd width="auto">
              <BtnControl
                fontWeight="bolder"
                id="remove"
                onClick={() => {
                  if (selectedEditBagItemCount >= 1) {
                    handleOnChangeProductCount(-1)
                  }
                }}
              >
                -
              </BtnControl>
              <Span className="mx-3 mb-1" fontWeight="bolder" color={preto}>
                {selectedEditBagItemCount}
              </Span>
              <BtnControl
                fontWeight="bolder"
                id="add"
                onClick={() => handleOnChangeProductCount(1)}
              >
                +
              </BtnControl>
            </Row>
          </Row>
          <Row spaceAround>
            <ManualOrderButtonSecondary
              margleft="0"
              onClick={() => handleReviewItemBag()}
            >
              <Span
                color={preto}
                display="inline-block"
                fontSize={px2vw(15)}
                cursor="pointer"
              >
                Revisar detalhes do item
              </Span>
            </ManualOrderButtonSecondary>
            <ManualOrderButtonPrimary
              margleft="0"
              onClick={() => handleUpdateItemBag()}
            >
              {selectedEditBagItemCount === 0 && 'Remover'}
              {selectedEditBagItemCount > 0 && 'Atualizar'}
              <Span
                color={preto}
                className="ml-3"
                display="inline-block"
                fontSize={px2vw(15)}
                cursor="pointer"
              >
                {formatCurrencyBRL(selectedEditBagItem.priceWithComplements * selectedEditBagItemCount, 2)}
              </Span>
            </ManualOrderButtonPrimary>
          </Row>
        </ModalFooter>
      )}
      {!selectedEditBagItem && (
        <ModalFooter>
          <Row className="mb-1">
            <Span
              fontSize={px2vw(17)}
              color={preto}
              fontWeight="bold"
              lineHeight="130%"
            >
              Escolha onde será entregue:
            </Span>
          </Row>
          <Row>
            <ButtonPrimary onClick={() => handleGoToPaymentMethod('take_away')}>
              <DeliveryImg
                src={require('../../../assets/images/delivery/retirada.png')}
              />
            </ButtonPrimary>
            <ButtonPrimary onClick={() => handleGoToPaymentMethod('delivery')}>
              <DeliveryImg
                src={require('../../../assets/images/delivery/delivery.png')}
              />
            </ButtonPrimary>
          </Row>
        </ModalFooter>
      )}
    </>
  );
}
