import styled from 'styled-components';

import Table from '@material-ui/core/Table';

import px2vw from '../../utils/responsiveness/px2vw';

import {
  preto,
  branco,
  verdeKuppi1,
  verdeKuppi4,
  cinzaKuppi2,
  cinzaKuppi3,
  cinzaKuppi4,
} from '../../styles/colors';

export const LoyaltyCardPrincipal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const LoyaltyCardHistory = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const SubtitleTextBox = styled.div`
  margin-top: ${px2vw(30)};
  width: ${px2vw(1028)};
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const SubtitleText = styled.div`
  font-size: ${px2vw(12)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.36px;
  margin-left: ${px2vw(5)};
`;

export const InfoBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: ${px2vw(30)};
  margin-left: ${px2vw(5)};
`;

export const InfoDataBox = styled.div`
  width: ${px2vw(170)};
  height: ${px2vw(100)};
  background-color: ${branco};
  margin-right: ${px2vw(20)};
  border-radius: ${px2vw(5)};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 8px 9px -2px ${cinzaKuppi4};
`;

export const InfoNumberText = styled.div`
  font-size: ${px2vw(32)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.36px;
  color: ${verdeKuppi1};
`;

export const InfoTypeText = styled.div`
  font-size: ${px2vw(12)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.36px;

  ${(props) => `
    margin-bottom: ${props.marginBottom};  
    margin-top: ${props.margtop};  
    margin-right: ${props.marginRight}; 
  `};
`;

export const SeeHistoryBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const SeeHistoryButton = styled.button`
  margin-left: ${px2vw(387)};
  height: ${px2vw(27)};
  width: ${px2vw(140)};
  margin-top: ${px2vw(70)};
  border: none;
  background-color: transparent;
`;

export const SeeHistoryButtonImage = styled.img`
  height: ${px2vw(16)};
  width: ${px2vw(16)};
  margin-right: ${px2vw(10)};
`;

export const SeeHistoryButtonText = styled.div`
  font-size: ${px2vw(11)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.32px;
  margin-top: ${px2vw(2)};
`;

export const SaveButton = styled.button`
  background-color: ${cinzaKuppi2};
  width: ${px2vw(100)};
  height: ${px2vw(40)};
  border: none;
  border-radius: ${px2vw(8)};
  padding: 0.3em;
  margin-top: ${px2vw(65)};
  margin-left: ${px2vw(20)};

  ${(props) =>
    props.hasChanges &&
    `
    background-color: ${verdeKuppi1};
  `};
`;

export const SaveButtonText = styled.div`
  font-size: ${px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.36px;
  color: ${cinzaKuppi3};

  ${(props) =>
    props.hasChanges &&
    `
    color: ${branco};
  `};
`;

export const LoyaltyCardBox = styled.div`
  width: ${px2vw(1028)};
  height: ${px2vw(480)};
  background-color: ${branco};
  margin-left: ${px2vw(5)};
  margin-top: ${px2vw(10)};
  border-radius: 20px;
  .MuiFormControlLabel-root {
    margin-left: 0;
    margin-right: 0;
  }
  box-shadow: 0px 8px 9px -2px ${cinzaKuppi4};
`;

export const ActiveLoyaltyCardBoxLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-top: ${px2vw(43)};
  margin-left: ${px2vw(53)};
  margin-bottom: ${px2vw(50)};
  float: left;
`;

export const ActiveLoyaltyCardBoxRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-top: ${px2vw(43)};
  margin-right: ${px2vw(100)};
  float: right;
`;

export const ObsBox = styled.div`
  font-size: ${px2vw(12)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.36px;
  width: ${px2vw(360)};
  margin-top: ${px2vw(40)};
`;

export const SwitchWithInputProductBox = styled.div`
  display: none;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  ${(props) =>
    props.isVisible &&
    `
    display: flex;
  `};
`;

export const BackButtonBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const BackButton = styled.button`
  height: ${px2vw(40)};
  width: ${px2vw(100)};
  margin-top: ${px2vw(5)};
  margin-bottom: ${px2vw(5)};
  margin-left: ${px2vw(5)};
  border: none;
  background-color: transparent;
`;

export const BackButtonImage = styled.img`
  height: ${px2vw(10)};
  width: ${px2vw(8)};
  margin-right: ${px2vw(10)};
  margin-top: ${px2vw(2)};
`;

export const BackButtonText = styled.div`
  font-size: ${px2vw(18)};
  font-family: AvenirNextLTPro;
  font-weight: 600;
  letter-spacing: 0.48px;
`;

export const PageHistoryTitle = styled.div`
  display: flex;
  flex-direction: row;
  font-size: ${px2vw(28)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.72px;
  padding-top: ${px2vw(10)};
  padding-left: ${px2vw(5)};
`;

export const InfoHistoryBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  margin-top: ${px2vw(30)};
`;

export const LoyaltyCardHistoryBox = styled.div`
  width: ${px2vw(1028)};
  background-color: ${branco};
  margin-left: ${px2vw(5)};
  border-radius: ${px2vw(20)};
  padding: 0px ${px2vw(25)};
  box-shadow: 0px 8px 9px -2px ${cinzaKuppi4};
`;

export const TableHistory = styled(Table)`
  min-width: ${px2vw(550)};
`;

export const HistoryHeaderBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;

  ${(props) => `
    width: ${props.width};
  `};
`;

export const SelectButtonOption = styled.button`
  background-color: ${branco};
  height: ${px2vw(35)};
  width: ${(props) => props.width || px2vw(90)};
  border: 1px solid ${preto};
  border-radius: ${px2vw(5)};
  padding: 0.3em;
  margin-top: ${px2vw(4)};
  margin-bottom: ${px2vw(4)};
  margin-right: ${px2vw(4)};

  ${(props) =>
    props.isChecked &&
    `
    background-color: ${verdeKuppi4};
    border: 1px solid ${verdeKuppi4};
  `};

  ${(props) =>
    props.orderType === 'delivery_take_away' &&
    `
    width: ${px2vw(220)};
  `};

  ${(props) =>
    props.orderType === 'take_away' &&
    `
    width: ${px2vw(160)};
  `};

`;

export const SelectButtonOptionText = styled.div`
  font-size: ${px2vw(13)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.36px;

  ${(props) =>
    props.isChecked &&
    `
    color: ${branco};
  `};
`;

export const ErrorText = styled.div`
  font-size: ${px2vw(18)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.4px;
  margin-left: ${px2vw(20)};
`;

export const QtyOrdersBenefitBox = styled.div`
  width: ${px2vw(30)};
  height: ${px2vw(30)};
  border-radius: ${px2vw(5)};
  font-size: ${px2vw(12)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.36px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: ${px2vw(5)};
  border: 1px solid ${cinzaKuppi4};
  background-color: ${cinzaKuppi2};
  color: ${cinzaKuppi4};

  ${(props) =>
    props.isActive &&
    `
    background-color: ${verdeKuppi4};
    color: ${branco};
    border: 1px solid ${verdeKuppi1}; 
    -webkit-box-shadow: -2px 1px 14px -5px rgba(0,0,0,0.75);
    -moz-box-shadow: -2px 1px 14px -5px rgba(0,0,0,0.75);
    box-shadow: -2px 1px 14px -5px rgba(0,0,0,0.75);

    .imageActive {
      filter: invert(200%) sepia(0%) saturate(7%) hue-rotate(152deg) brightness(200%) contrast(200%);
    }
  `};
`;