import React from 'react';

import px2vw from '../../../../utils/responsiveness/px2vw';

import { Row, Col } from '../../../../styles/grid';

import { branco, cinzaKuppi1 } from '../../../../styles/colors';

import {
  Box,
  NormalText,
  PageTitle,
  Image,
} from '../../BaseScreen/styles';

import WelcomeScreenCard from '../../../../components/WelcomeScreenCard';
import { MainText, SecondaryText, step1BtnText, step1HeaderText, step1TitleText, step1TimerAmountText, step1SubText, step2BtnText, step2HeaderText, step2TitleText, step2TimerAmountText, step2SubText, step3BtnText, step3HeaderText, step3TitleText, step3TimerAmountText, step3SubText, step4BtnText, step4HeaderText, step4TitleText, step4TimerAmountText, step4SubText, step5BtnText, step5HeaderText, step5TitleText, step5TimerAmountText, step5SubText, UserNameText } from '../constants';

export default function WelcomeScreenWeb({ handler }) {
  const configImg = require('../../../../assets/images/onboarding/cards/config@2x.png');
  const storeConfigsImg = require('../../../../assets/images/onboarding/cards/deliveryParameters@2x.png');
  const catalogConfigsImg = require('../../../../assets/images/onboarding/cards/catalog@2x.png');
  const printConfigsImg = require('../../../../assets/images/onboarding/cards/print@2x.png');
  const advertiseImg = require('../../../../assets/images/onboarding/cards/advertise@2x.png');

  return (
    <Col background={cinzaKuppi1}>
      <Row
        alignCenter spaceBetween
        height={px2vw(50)} 
        padleft={px2vw(60)} 
        padright={px2vw(60)}
        background={branco}
      >
        <Image
          width={px2vw(50)}
          height={px2vw(50)}
          src={require('../../../../assets/images/logo_kuppi/kuppi_logo@2x.png')}
        />
        <NormalText
          fontweight='bold'
          padright={px2vw(120)}
          padleft={px2vw(120)}
          fontsize={px2vw(25)}
        >
          {MainText}
        </NormalText>
        <Image
          width={px2vw(50)}
          height={px2vw(30)}
          alt="Logout"
          src={require('../../../../assets/images/onboarding/logout_mobile2@2x.png')}
          cursor="pointer"
          onClick={handler.handleLogout}
        />
      </Row>
      <Box
        height="auto"
        width={px2vw(1270)}
        margtop={px2vw(25)}
        margbot={px2vw(25)}
        margleft={px2vw(50)}
      >
        <Col width="auto" height="auto" justifyStart pad={px2vw(5)}>
          <PageTitle margbottom={px2vw(10)}>
            {UserNameText.replaceAll('@@name@@', handler.user.advertiserInfo.name)}
          </PageTitle>
          <NormalText fontsize={px2vw(24)}>
            {SecondaryText}
          </NormalText>
        </Col>
      </Box>
      <Row spaceBetween width="auto" margleft={px2vw(50)}>
        <WelcomeScreenCard
          headerStep={step1HeaderText}
          headerTimerAmount={step1TimerAmountText}
          mainImg={configImg}
          stepTitle={step1TitleText}
          stepSubText={step1SubText}
          stepBtnText={step1BtnText}
          onClick={handler.handleStartButtonClick}
          loading={handler.updateOnboardingStepLoading}
          
        />
        <WelcomeScreenCard
          opacity
          headerStep={step2HeaderText}
          headerTimerAmount={step2TimerAmountText}
          mainImg={storeConfigsImg}
          stepTitle={step2TitleText}
          stepSubText={step2SubText}
          stepBtnText={step2BtnText}
        />
        <WelcomeScreenCard
          opacity
          headerStep={step3HeaderText}
          headerTimerAmount={step3TimerAmountText}
          mainImg={catalogConfigsImg}
          stepTitle={step3TitleText}
          stepSubText={step3SubText}
          stepBtnText={step3BtnText}
        />
        <WelcomeScreenCard
          opacity
          headerStep={step4HeaderText}
          headerTimerAmount={step4TimerAmountText}
          mainImg={printConfigsImg}
          stepTitle={step4TitleText}
          stepSubText={step4SubText}
          stepBtnText={step4BtnText}
        />
        <WelcomeScreenCard
          opacity
          headerStep={step5HeaderText}
          headerTimerAmount={step5TimerAmountText}
          mainImg={advertiseImg}
          stepTitle={step5TitleText}
          stepSubText={step5SubText}
          stepBtnText={step5BtnText}
        />
      </Row>
    </Col>
  );
}
