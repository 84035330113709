import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Spinner } from 'reactstrap';
import { FormControlLabel } from '@material-ui/core/';

import {
  ContentPage,
  SwitchController,
  SwitchLabelRegularText,
  LoadingBox,
} from '../../_layouts/styles/common';
import { Row, Col } from '../../../styles/grid';
import {
  ModalPrimaryButton,
  ModalPrimaryButtonText,
  ModalActions,
  ModalSecundaryButton,
  ModalSecundaryButtonText,
} from '../../../components/Modal/styles';
import {
  BackButtonBox,
  BackButton,
  BackButtonImage,
  BackButtonText,
  PageCouponsTitle,
  CouponDiscountTypeBox,
  ItemInputLabel,
  ItemInput,
  SelectButtonOption,
  SelectButtonOptionText,
  DiscountPercentageText,
  CodeCouponsContent,
  FormCodeCoupon,
  ItemInputLabelRegular,
} from '../styles';
import { preto } from '../../../styles/colors';

import px2vw from '../../../utils/responsiveness/px2vw';
import { amountMask } from '../../../utils/masks';
import { onlyNumbers } from '../../../utils/utils';
import CouponHoraries from '../Components/CouponHoraries';

export default function CodeCoupon({ handler }) {
  const userInfo = useSelector((state) => state.user.user.advertiserInfo);
  const isEditing = handler.editCouponObj ? true : false;
  const pageTitle = isEditing ? 'Editar Cupom por Código' : 'Novo Cupom por Código';

  return (
    <ContentPage>
      <CodeCouponsContent>
        <BackButton 
          onClick={() => handler.handleManageCouponContent('back')}
          disabled={handler.updateCouponLoading}
        >
          <BackButtonBox>
            <BackButtonImage
              src={require('../../../assets/images/arrows/arrow_left.png')}
            />
            <BackButtonText>voltar</BackButtonText>
          </BackButtonBox>
        </BackButton>
        <PageCouponsTitle>{pageTitle}</PageCouponsTitle>
        <FormCodeCoupon
          onSubmit={() => {
            if (isEditing) {
              handler.handleEditCouponSubmit('code');
            } else {
              handler.handleNewCodeCouponSubmit();
            }
          }}
        >
          <CouponDiscountTypeBox height="auto">
            <Row height="auto">
              <Col>
                <Row spaceBetween>
                  <Col>
                    <ItemInputLabel>
                      Código do desconto
                    </ItemInputLabel>
                    <ItemInput
                      name="codeCouponDiscountCode"
                      width={px2vw(200)}
                      placeholder="Até 20 caracteres"
                      maxLength="20"
                      value={handler.codeCouponDiscountCode}
                      onChange={(t) =>
                        handler.setCodeCouponDiscountCode(
                          t.target.value.replace(/[^a-z0-9]/gi,'').toUpperCase()
                        )
                      }
                      disabled={isEditing}
                    />
                  </Col>
                  <Col margleft={px2vw(80)}>
                    <ItemInputLabel>Defina seu desconto</ItemInputLabel>
                    <Row
                      justifyStart
                      margtop={px2vw(10)}
                      margleft={px2vw(10)}
                    >
                      <Row width={px2vw(180)}>
                        <FormControlLabel
                          control={
                            <SwitchController
                              checked={handler.percentDiscountTotal}
                              type="checkbox"
                              onChange={() => {
                                handler.setPercentDiscountTotal(!handler.percentDiscountTotal);
                                handler.setFixedDiscountTotal(false);
                                handler.setCouponDiscountAmountInputValue('');
                                handler.setNewCouponType('percentage_cart');
                              }}
                            />
                          }
                          label={
                            <SwitchLabelRegularText>
                              Percentual
                            </SwitchLabelRegularText>
                          }
                        />
                        <Row justifyStart margtop={px2vw(-10)} >
                          <ItemInput
                            isNotVisible={!handler.percentDiscountTotal}
                            name="codeCouponDiscountPercent"
                            width={px2vw(45)}
                            placeholder="30"
                            maxLength="3"
                            value={handler.couponDiscountPercentInputValue}
                            onChange={(t) =>
                              handler.setCouponDiscountPercentInputValue(
                                onlyNumbers(t.target.value)
                              )
                            }
                          />
                          <DiscountPercentageText
                            isNotVisible={!handler.percentDiscountTotal}
                          >
                            %
                          </DiscountPercentageText>
                        </Row>
                      </Row>
                      <div style={{ marginRight: '20px' }} />
                      <Row width={px2vw(180)} justifyStart>
                        <FormControlLabel
                          control={
                            <SwitchController
                              checked={handler.fixedDiscountTotal}
                              type="checkbox"
                              onChange={() => {
                                handler.setFixedDiscountTotal(!handler.fixedDiscountTotal);
                                handler.setPercentDiscountTotal(false);
                                handler.setCouponDiscountPercentInputValue('');
                                handler.setNewCouponType('fixed_cart');
                              }}
                            />
                          }
                          label={
                            <SwitchLabelRegularText>
                              Valor
                            </SwitchLabelRegularText>
                          }
                        />
                        <ItemInput
                          isNotVisible={!handler.fixedDiscountTotal}
                          margleft={px2vw(-25)}
                          margtop={px2vw(-5)}
                          name="codeCouponDiscountAmount"
                          width={px2vw(85)}
                          placeholder="R$ 18,00"
                          maxLength="9"
                          value={handler.couponDiscountAmountInputValue}
                          onChange={(t) =>
                            handler.setCouponDiscountAmountInputValue(
                              amountMask(t.target.value)
                            )
                          }
                        />
                      </Row>
                    </Row>
                  </Col>
                  <Col margleft={px2vw(30)}>
                    {isEditing ? (
                      <ItemInputLabel>
                        Qtd Utilizada
                      </ItemInputLabel>
                    ) : (
                      <ItemInputLabel>
                        Limite de cupons
                      </ItemInputLabel>
                    )}                   
                    {isEditing ? (
                      <ItemInputLabelRegular fontsize={px2vw(14)}>
                        {handler.usedCoupons}
                      </ItemInputLabelRegular>
                    ) : (
                      <Row justifyStart>
                        <ItemInput
                          name="flashCouponUseLimit"
                          width={px2vw(80)}
                          placeholder="15"
                          min="1"
                          max="999999999"
                          type="text"
                          value={handler.couponUseLimitInputValue}
                          onChange={(t) => {
                            handler.setCouponUseLimitInputValue(
                              onlyNumbers(t.target.value.substring(0, 9))
                            );
                          }}
                        />
                      </Row>
                    )}
                  </Col>
                </Row>
                <Row margtop={px2vw(30)} height="auto" width="auto">
                  <Col width="auto">
                    <ItemInputLabel>
                      Desconto válido apenas para primeira compra?
                    </ItemInputLabel>
                    <Row
                      justifyStart
                      margtop={px2vw(10)}
                      margleft={px2vw(10)}
                    >
                      <FormControlLabel
                        control={
                          <SwitchController
                            checked={handler.firstOrderDiscountOnly}
                            type="checkbox"
                            onChange={() => handler.setFirstOrderDiscountOnly(!handler.firstOrderDiscountOnly)}
                          />
                        }
                        label={
                          <SwitchLabelRegularText>
                            Sim
                          </SwitchLabelRegularText>
                        }
                      />
                      <div style={{ marginRight: '20px' }} />
                      <FormControlLabel
                        control={
                          <SwitchController
                            checked={!handler.firstOrderDiscountOnly}
                            type="checkbox"
                            onChange={() => handler.setFirstOrderDiscountOnly(!handler.firstOrderDiscountOnly)}
                          />
                        }
                        label={
                          <SwitchLabelRegularText>
                            Não
                          </SwitchLabelRegularText>
                        }
                      />
                    </Row>
                  </Col>
                  <Col margleft={px2vw(90)} margright={px2vw(90)} width="auto">
                    <ItemInputLabel>
                      Valor mínimo do pedido para o desconto
                    </ItemInputLabel>
                    <ItemInput
                      name="codeCouponMinimumAmount"
                      width={px2vw(85)}
                      placeholder="R$ 18,00"
                      maxLength="9"
                      value={handler.minimumOrderAmountInputValue}
                      onChange={(t) =>
                        handler.setMinimumOrderAmountInputValue(
                          amountMask(t.target.value)
                        )
                      }
                    />
                  </Col>                   
                  {isEditing && (
                    <>
                      <Col width="auto">
                        <ItemInputLabel>
                          Qtd Disponível
                        </ItemInputLabel>
                        <ItemInputLabelRegular fontsize={px2vw(14)}>
                          {handler.couponUseLimitInputValue - handler.usedCoupons}
                        </ItemInputLabelRegular>
                      </Col>
                      <ModalPrimaryButton
                        margleft={px2vw(25)}
                        width={px2vw(100)}
                        type="button"
                        onClick={() => {
                          handler.setAvailableCouponsModalVisible(true);
                          handler.setActionCoupon(handler.editCouponObj);
                        }}
                      >
                        <ModalPrimaryButtonText>
                          Alterar Qtd
                        </ModalPrimaryButtonText>
                      </ModalPrimaryButton>
                    </>
                  )}
                </Row>
                <Row margtop={px2vw(30)} height="auto">
                  <Col>
                    <ItemInputLabel>
                      Condições de pagamento
                    </ItemInputLabel>
                    <Row
                      justifyStart
                      margtop={px2vw(10)}
                      margleft={px2vw(10)}
                    >
                      <FormControlLabel
                        control={
                          <SwitchController
                            checked={handler.acceptCard}
                            type="checkbox"
                            onChange={() => handler.setAcceptCard(!handler.acceptCard)}
                          />
                        }
                        label={
                          <SwitchLabelRegularText>
                            Cartão
                          </SwitchLabelRegularText>
                        }
                      />
                      <div style={{ marginRight: '20px' }} />
                      <FormControlLabel
                        control={
                          <SwitchController
                            checked={handler.acceptMoney}
                            type="checkbox"
                            onChange={() => handler.setAcceptMoney(!handler.acceptMoney)}
                          />
                        }
                        label={
                          <SwitchLabelRegularText>
                            Dinheiro
                          </SwitchLabelRegularText>
                        }
                      />
                      <div style={{ marginRight: '20px' }} />
                      <FormControlLabel
                        control={
                          <SwitchController
                            checked={handler.acceptPix}
                            type="checkbox"
                            onChange={() => handler.setAcceptPix(!handler.acceptPix)}
                          />
                        }
                        label={
                          <SwitchLabelRegularText>
                            Pix
                          </SwitchLabelRegularText>
                        }
                      />
                    </Row>
                  </Col>
                  <Col>
                    <ItemInputLabel>
                      Onde o cupom é aceito?
                    </ItemInputLabel>
                    <Row justifyStart margtop={px2vw(5)}>
                      {userInfo.is_using_take_away && (
                        <SelectButtonOption
                          isChecked={handler.acceptOnStore}
                          type="button"
                          onClick={() => handler.setAcceptOnStore(!handler.acceptOnStore)}
                        >
                          <SelectButtonOptionText isChecked={handler.acceptOnStore}>
                            Retirada
                          </SelectButtonOptionText>
                        </SelectButtonOption>
                      )}
                      {userInfo.is_using_delivery && (
                        <SelectButtonOption
                          isChecked={handler.acceptOnDelivery}
                          type="button"
                          onClick={() => handler.setAcceptOnDelivery(!handler.acceptOnDelivery)}
                        >
                          <SelectButtonOptionText isChecked={handler.acceptOnDelivery}>
                            Delivery
                          </SelectButtonOptionText>
                        </SelectButtonOption>
                      )}
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            <CouponHoraries handler={handler} />
            <ModalActions>
              <ModalSecundaryButton
                margleft={px2vw(190)}
                marginRight={px2vw(20)}
                margtop={px2vw(40)}
                width={px2vw(250)}
                type="button"
                onClick={() => handler.handleManageCouponContent('back')}
                disabled={handler.updateCouponLoading}
              >
                <ModalSecundaryButtonText>
                  Cancelar
                </ModalSecundaryButtonText>
              </ModalSecundaryButton>
              <ModalPrimaryButton
                margleft={px2vw(20)}
                margtop={px2vw(40)}
                width={px2vw(250)}
                type="submit"
                disabled={handler.updateCouponLoading}
              >
                <ModalPrimaryButtonText>
                  {!isEditing && 'Criar cupom'}
                  {isEditing && (
                    handler.updateCouponLoading ? (
                      <LoadingBox>
                        <Spinner
                          style={{
                            width: '1.8rem',
                            height: '1.8rem',
                            color: preto,
                          }}
                        />{' '}
                      </LoadingBox>
                    ) : (
                      'Editar cupom'
                    )
                  )}
                </ModalPrimaryButtonText>
              </ModalPrimaryButton>
            </ModalActions>
          </CouponDiscountTypeBox>
        </FormCodeCoupon>
      </CodeCouponsContent>
    </ContentPage>
  );
}
