import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'reactstrap';
import moment from 'moment';

import {
  getMassMessageData,
  setIsEditing,
  setSelectedCampaign,
} from '../../store/modules/massMessage/actions';
import { branco, cinzaKuppi4, preto, verdeKuppi14 } from '../../styles/colors';
import { ContentDefault, Text } from '../../styles/common';
import { Row, Col } from '../../styles/grid';
import px2vw from '../../utils/responsiveness/px2vw';
import { ContentPage } from '../_layouts/styles/common';
import MassMessageModal from './MassMessageModal';
import CampaignTable from './MassMessageModal/CampaignTable';
import {
  ButtonPrimary,
  MenuBox,
  Tab,
  TabBox,
  TabsBox,
  Title,
} from './styles';
import { toast } from 'react-toastify';
import { formatCurrencyBRL } from '../../utils/utils';

export default function MassMessage() {
  const dispatch = useDispatch();
  const [isCampaignModalVisible, setIsCampaignModalVisible] = useState(false);
  const { massMessageResquestLoading, campaigns, configs } =
    useSelector((state) => state.massMessage);
  const whatsappIsActive = useSelector(
    (state) =>
      state.user.user.advertiserInfo.advertiserWhatsappbotConfig.is_active
  );

  useEffect(() => {
    dispatch(getMassMessageData());
  }, []);

  function handleCreateNewCampaign() {
    const hasPendingPaymentCampaign = campaigns.find(
      (c) => c.status === 'pending_payment' && moment(new Date()).isBefore(moment(c.advertiserMassMessagePayment[0].pix_expiration_at).utcOffset('+03:00'))
    );

    if (hasPendingPaymentCampaign) {
      toast.error(
        'Você possui uma campanha pendente de pagamento. Pague ou Cancele-a para poder criar uma nova!'
      );
    } else if (!whatsappIsActive) {
      toast.error(
        'Para criar uma campanha é necessário que o bot esteja conectado!'
      );
    } else {
      setIsCampaignModalVisible(true);
    }
  }

  return (
    <ContentPage>
      {massMessageResquestLoading ? (
        <Row height="80vh">
          <Spinner
            style={{
              margin: 'auto',
              width: '1.8rem',
              height: '1.8rem',
              color: preto,
            }}
          />
        </Row>
      ) : (
        <>
          <Row alignStart justifyStart height="auto">
            <Title>Disparo em Massa</Title>
          </Row>
          <ContentDefault
            height={px2vw(175)}
            justifyStart
            alignStart
            overflowY
            className="mt-4 mb-3 p-0 position-relative"
          >
            <Col
              className="my-1"
              pad={px2vw(15)}
              width={px2vw(760)}
              justifyStart
            >
              {configs?.total_sales_qty > 0 ? (
                <>
                  <Text
                    size={px2vw(19)}
                    color={branco}
                    bold
                  >
                    Seus resultados com o Disparo em Massa
                  </Text>
                  <Text size={px2vw(15)} color={branco}  margtop={px2vw(20)} regular>
                    Você já realizou<strong style={{ margin: `0 ${px2vw(5)}`}}>{configs?.total_campaign_qty} campanha{configs?.total_campaign_qty > 1 ? 's' : ''}</strong>de
                    disparo em massa
                  </Text>
                  <Text
                    size={px2vw(15)}
                    color={branco}

                    regular
                  >
                    <p>Você recebeu<strong style={{ margin: `0 ${px2vw(5)}`}}>{configs?.total_sales_qty} pedido{configs?.total_sales_qty > 1 ? 's' : ''}</strong>vindo{configs?.total_sales_qty > 1 ? 's' : ''} de
                    disparo em massa, e um<strong style={{ margin: `0 ${px2vw(5)}`}}>faturamento de {formatCurrencyBRL(configs?.total_sales_amount, 2)}</strong></p>
                  </Text>
                  {!!configs?.balance && (
                    <Text size={px2vw(15)} color={branco} margtop={px2vw(20)} bold>
                      Você possui<strong style={{ margin: `0 ${px2vw(5)}`, color: verdeKuppi14 }}>{formatCurrencyBRL(configs?.balance, 2)} de
                      bônus</strong> para utilizar no seu próximo disparo em massa
                    </Text>
                  )}
                </>
              ) : (
                <>
                  <Text
                    size={px2vw(19)}
                    color={branco}
                    bold
                  >
                    Crie a sua campanha de Disparo em Massa agora!
                  </Text>
                  <Text
                    size={px2vw(16)}
                    regular
                    color={branco}
                    margtop={px2vw(20)}
                  >
                    Em média, cada{' '}
                    <strong style={{ margin: `0 ${px2vw(5)}` }}>
                      R$ 10 investidos
                    </strong>{' '}
                    retornam{' '}
                    <strong style={{ margin: `0 ${px2vw(5)}` }}>
                      R$ 75 em vendas
                    </strong>
                  </Text>
                  <Text size={px2vw(16)} regular color={branco}>
                    Avaliação de satisfação dos clientes é nota
                    <strong style={{ margin: `0 ${px2vw(5)}` }}>
                      9,2
                    </strong> de{' '}
                    <strong style={{ margin: `0 ${px2vw(5)}` }}>10</strong>
                  </Text>
                  {!!configs?.balance && (
                    <Text size={px2vw(15)} color={branco} margtop={px2vw(30)} bold>
                      Você possui <strong style={{ margin: `0 ${px2vw(5)}`, color: verdeKuppi14 }}>{formatCurrencyBRL(configs?.balance, 2)} de
                      bônus</strong> para utilizar no seu próximo disparo em massa
                    </Text>
                  )}
                </>
              )}
            </Col>
          </ContentDefault>
          <MenuBox>
            <Row
              height="auto"
              width="100%"
              className="pt-3 pb-4 pr-3"
              justifyStart
              alignCenter
              borderbottom={`2px solid ${cinzaKuppi4}`}
            >
              <TabsBox>
                <TabBox margL={px2vw(35)}>
                  <Tab selected>Campanhas</Tab>
                </TabBox>
              </TabsBox>
              <ButtonPrimary
                className="ml-auto"
                width={px2vw(150)}
                size={px2vw(13)}
                height={px2vw(30)}
                onClick={() => handleCreateNewCampaign()}
              >
                Criar campanha
              </ButtonPrimary>
            </Row>
            <CampaignTable
              onClickCreateCampaign={() => handleCreateNewCampaign()}
              data={campaigns || []}
            />
          </MenuBox>
          {isCampaignModalVisible && (
            <MassMessageModal
              height={'fit-content'}
              width={px2vw(780)}
              padding="none"
              onClose={() => {
                dispatch(setSelectedCampaign(null));
                dispatch(setIsEditing(false))
                setIsCampaignModalVisible(false);
              }}
              isVisible
            />
          )}
        </>
      )}
    </ContentPage>
  );
}
