import styled from 'styled-components';

import px2vw from '../../utils/responsiveness/px2vw';

import {
  branco,
  verdeKuppi2,
  verdeKuppi4,
  cinzaKuppi7,
  cinzaKuppi10,
} from '../../styles/colors';

export const Button = styled.button`
  background-color: ${cinzaKuppi7};
  width: ${(props) => px2vw(props.width || 190)};
  height: ${(props) => px2vw(props.height || 50)};
  border: 2px solid ${cinzaKuppi10};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.48px;
  font-size: ${(props) => px2vw(props.fontsize || 16)};
  color: ${branco};
  transition: all 0.4s ease;
  cursor: default;

  ${(props) =>
  props.active &&
  `
    background-color: ${verdeKuppi2};

    :hover {
      background-color: ${verdeKuppi4};
      transition: all 0.4s ease;
    };

    cursor: pointer;
  `};
`;

export const ButtonShadow = styled.div`
  width: ${(props) => px2vw(props.width || 110)};
  height: ${(props) => px2vw(props.height || 6)};

  background-color: ${cinzaKuppi10};

  ${(props) =>
  props.left &&
  `
    -moz-transform: skew(50deg, 0deg);
    -webkit-transform: skew(50deg, 0deg);
    -o-transform: skew(50deg, 0deg);
    -ms-transform: skew(50deg, 0deg);
    transform: skew(50deg, 0deg);
  `};

  ${(props) =>
  props.right &&
  `
    margin-left: ${px2vw(-37)};
    -moz-transform: skew(-50deg, 0deg);
    -webkit-transform: skew(-50deg, 0deg);
    -o-transform: skew(-50deg, 0deg);
    -ms-transform: skew(-50deg, 0deg);
    transform: skew(-50deg, 0deg);
  `};
`;
