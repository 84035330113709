import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'reactstrap';

import {
  ConfirmDeleteModal,
  ConfirmDeleteModalText,
} from '../styles';

import Modal from '../../../components/Modal';
import {
  ModalPrimaryButton,
  ModalPrimaryButtonText,
  ModalActions,
  ModalSecundaryButton,
  ModalSecundaryButtonText,
} from '../../../components/Modal/styles';

import {
  LoadingBox,
} from '../../_layouts/styles/common';

import {
  preto,
} from '../../../styles/colors';

import px2vw from '../../../utils/responsiveness/px2vw';

import {
  deleteCategoryRequest,
  deleteProductRequest,
} from '../../../store/modules/productCategories/actions';

export default function CatalogConfirmDeleteCategoryOrProductModal({ handler }) {
  const deleteCategoryProductModalLoading = useSelector(
    (state) => state.productCategories.deleteCategoryProductModalLoading
  );
  const dispatch = useDispatch();

  return (
    <Modal
      isVisible={handler.confirmDeleteCategoryOrProductModal}
      width={px2vw(280)}
      height={px2vw(170)}
    >
      <ConfirmDeleteModal>
        <ConfirmDeleteModalText>
          Tem certeza que deseja excluir?
        </ConfirmDeleteModalText>
        <ModalActions width="100%" justifyContent="space-around">
          <ModalSecundaryButton
            margtop={px2vw(20)}
            width={px2vw(100)}
            type="button"
            onClick={() => {
              handler.setConfirmDeleteCategoryOrProductModal(false);
            }}
            disabled={deleteCategoryProductModalLoading}
          >
            <ModalSecundaryButtonText>Não</ModalSecundaryButtonText>
          </ModalSecundaryButton>
          <ModalPrimaryButton
            margtop={px2vw(20)}
            width={px2vw(100)}
            type="button"
            onClick={() => {
              // Category Delete
              if (handler.confirmDeleteCategoryIdx) {
                dispatch(deleteCategoryRequest(handler.confirmDeleteCategoryIdx));
              } else if (handler.confirmDeleteProductIdx) {
                // Product Delete
                dispatch(deleteProductRequest(handler.confirmDeleteProductIdx));
              }
            }}
            disabled={deleteCategoryProductModalLoading}
          >
            {deleteCategoryProductModalLoading ? (
              <LoadingBox>
                <Spinner
                  style={{
                    width: '1.8rem',
                    height: '1.8rem',
                    color: preto,
                  }}
                />{' '}
              </LoadingBox>
            ) : (
              <ModalPrimaryButtonText>Sim</ModalPrimaryButtonText>
            )}
          </ModalPrimaryButton>
        </ModalActions>
      </ConfirmDeleteModal>
    </Modal>
  );
}
