import React from 'react';

import elipse from '../../../assets/images/onboarding/elipse@2x.png';
import elipseChecked from '../../../assets/images/onboarding/elipse_checked@2x.png';

import px2vw from '../../../utils/responsiveness/px2vw';

import {
  CheckboxContainer,
  HiddenCheckbox,
  Text,
} from '../styles';

import {
  StyledCheckbox,
} from './styles';

export function Checkbutton({ label, checked, imgWidth = 20, fontSize = 14, center = false }) {

  return (
    <>
      <CheckboxContainer checked={checked}>
        <HiddenCheckbox checked={checked}/>
        <StyledCheckbox type="checkbox" checked={checked}>
          <img

            style={{width: `${px2vw(imgWidth)}`}}
            alt="Check Icon"
            src={checked ? elipseChecked : elipse}
          />
        </StyledCheckbox>
        <Text fontSize={fontSize}>{label}</Text>
      </CheckboxContainer>
    </>
  );
}

export default Checkbutton
