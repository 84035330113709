import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Text } from '../../../../styles/common';

import px2vw from '../../../../utils/responsiveness/px2vw';
import { Col, Row } from '../../../../styles/grid';

import {
  ModalContent,
  CloseButton,
  ModalHeader,
  CloseImage,
  ModalContainer,
  GeneralInfosContainer,
} from './styles';
import {
  branco,
  cinzaKuppi7,
  verdeKuppi2,
  vermelhoKuppi3,
} from '../../../../styles/colors';
import {
  setCurrentClientList,
  setCurrentStep,
  setIsBlackTableOpen,
  setIsUsingMassMessageBonus,
  setSegmentations,
} from '../../../../store/modules/massMessage/actions';
import { formatCurrencyBRL } from '../../../../utils/utils';
import { useMemo } from 'react';
import DeleteBox from '../../DeleteBox';
import ManualSegmentation from './ManualSegmentation';
import Checkbutton from '../../../../components/Checkbox/Checkbutton';
import { SegmentationsTable } from './SegmentationsTable';
import { ContactListContent } from './ContactListContent';
import { SegmentationFooter } from './SegmentationFooter';

export default function ClientSegmentation(props) {
  const dispatch = useDispatch();
  const {
    blacklistConfig,
    contactsBlacklist,
    sendClientList,
    contactsPotentialList,
    contactsNewList,
    contactsRecurrentList,
    contactsVipList,
    contactsRiskList,
    manualList,
    segmentations,
    configs,
    isUsingBonus
  } = useSelector((state) => state.massMessage);

  const { finishedPagination: finishedOrders } = useSelector(
    (state) => state.orders
  );

  const [isClientTableOpenned, setIsClientTableOpenned] = useState(false);
  const [openManualList, setOpenManualList] = useState(false);
  const [isDeletedsClientTableOpenned, setIsDeletedsClientTableOpenned] =
    useState(false);
  const [selectedSegmentations, setSelectedSegmentations] = useState(
    Number(finishedOrders.total) < 5
      ? ['potential']
      : segmentations?.length >= 1
      ? segmentations
      : ['vip', 'new', 'risk', 'recurrent']
  );

  const [inputSearchData, setInputSearchData] = useState('');
  const [isEditingManualSegmentation, setIsEditingManualSegmentation] =
    useState(false);

  const [bonusSelected, setBonusSelected] = useState(Boolean(isUsingBonus));

  const currentClientsList = useMemo(() => {
    let newClientList = [];

    if (!selectedSegmentations.includes('manual')) {
      selectedSegmentations.forEach((s) => {
        switch (s) {
          case 'vip':
            newClientList.push(...contactsVipList);
            break;
          case 'new':
            newClientList.push(...contactsNewList);
            break;
          case 'potential':
            newClientList.push(...contactsPotentialList);
            break;
          case 'risk':
            newClientList.push(...contactsRiskList);
            break;
          case 'recurrent':
            newClientList.push(...contactsRecurrentList);
            break;
          default:
            break;
        }
      });
      dispatch(setCurrentClientList(newClientList));
      return newClientList;
    }
    newClientList = [...manualList];
    dispatch(setCurrentClientList(newClientList));
    return newClientList;
  }, [selectedSegmentations, contactsBlacklist, manualList]);

  function handleSelectedSegmentation(seg) {
    const isAllReadySelected = selectedSegmentations.includes(seg);

    if (isAllReadySelected && seg !== 'manual') {
      setSelectedSegmentations(selectedSegmentations.filter((s) => s !== seg));
    } else if (seg === 'manual') {
      setSelectedSegmentations([seg]);
    } else {
      setSelectedSegmentations([
        ...selectedSegmentations.filter((s) => s !== 'manual'),
        seg,
      ]);
    }
  }

  const totalAmount = currentClientsList.length * configs?.per_contact_amount;

  useEffect(() => {
    if (contactsVipList.length === 0 && selectedSegmentations.includes('vip')) {
      setSelectedSegmentations(
        selectedSegmentations.filter((s) => s !== 'vip')
      );
    }
    if (
      contactsPotentialList.length === 0 &&
      selectedSegmentations.includes('potential')
    ) {
      setSelectedSegmentations(
        selectedSegmentations.filter((s) => s !== 'potential')
      );
    }
    if (contactsNewList.length === 0 && selectedSegmentations.includes('new')) {
      setSelectedSegmentations(
        selectedSegmentations.filter((s) => s !== 'new')
      );
    }
    if (
      contactsRiskList.length === 0 &&
      selectedSegmentations.includes('risk')
    ) {
      setSelectedSegmentations(
        selectedSegmentations.filter((s) => s !== 'risk')
      );
    }
    if (
      contactsRecurrentList.length === 0 &&
      selectedSegmentations.includes('recurrent')
    ) {
      setSelectedSegmentations(
        selectedSegmentations.filter((s) => s !== 'recurrent')
      );
    }
  }, []);

  useEffect(() => {
    dispatch(setSegmentations(selectedSegmentations));
  }, [selectedSegmentations]);

  function handleGoToNextPage() {
    dispatch(setCurrentStep('5'));
  }

  function handleBacktoPreviousPage() {
    if (isEditingManualSegmentation) {
      handleSelectedSegmentation('manual');
    }
    if (!isEditingManualSegmentation && openManualList) {
      setIsEditingManualSegmentation(true);
      setInputSearchData('');
      setIsClientTableOpenned(false);
      setOpenManualList(false);
    } else if (isClientTableOpenned || isDeletedsClientTableOpenned) {
      setInputSearchData('');
      setIsClientTableOpenned(false);
      setIsDeletedsClientTableOpenned(false);
      dispatch(setIsBlackTableOpen(false));
    } else {
      dispatch(setCurrentStep('3'));
    }
  }

  function handleOpenDeleteTable() {
    setIsDeletedsClientTableOpenned(true);
    dispatch(setIsBlackTableOpen(true));
  }

  const ClientSegmentation = {
    risk: 'cliente em risco',
    vip: 'vip',
    potential: 'potencial cliente',
    new: 'cliente novo',
    recurrent: 'recorrente',
  };

  function handleClientListVisible() {
    if (openManualList) {
      if (inputSearchData.length === 0) {
        return manualList;
      }
      return manualList.filter(
        (c) =>
          c?.phone
            ?.toLowerCase()
            .includes(inputSearchData.toLowerCase().trim()) ||
          c?.name
            ?.toLowerCase()
            .includes(inputSearchData.toLowerCase().trim()) ||
          ClientSegmentation[c?.type_tag]
            ?.toLowerCase()
            .includes(inputSearchData.toLowerCase().trim())
      );
    }
    if (isDeletedsClientTableOpenned) {
      if (inputSearchData.length === 0) {
        return contactsBlacklist;
      }
      return contactsBlacklist.filter(
        (c) =>
          c?.phone
            ?.toLowerCase()
            .includes(inputSearchData.toLowerCase().trim()) ||
          c?.name
            ?.toLowerCase()
            .includes(inputSearchData.toLowerCase().trim()) ||
          ClientSegmentation[c?.type_tag]
            ?.toLowerCase()
            .includes(inputSearchData.toLowerCase().trim())
      );
    }
    if (inputSearchData.length === 0) {
      return sendClientList;
    }
    return sendClientList.filter(
      (c) =>
        c?.phone
          ?.toLowerCase()
          .includes(inputSearchData.toLowerCase().trim()) ||
        c?.name?.toLowerCase().includes(inputSearchData.toLowerCase().trim()) ||
        ClientSegmentation[c?.type_tag]
          ?.toLowerCase()
          .includes(inputSearchData.toLowerCase().trim())
    );
  }

  function handleSelectBonus() {
    setBonusSelected((o) => {
      dispatch(setIsUsingMassMessageBonus(!o));
      return !o;
    });
  }

  const evaluateTotal = useCallback(
    (value) => {
      if (bonusSelected) {
        if (value - configs?.balance > 0) {
          return value - configs?.balance;
        }

        return 0;
      }

      return value;
    },
    [bonusSelected]
  );

  if (!isEditingManualSegmentation) {
    return (
      <ModalContainer>
        <ModalHeader>
          {!isClientTableOpenned && !isDeletedsClientTableOpenned
            ? 'Escolha qual segmentação será impactada'
            : isDeletedsClientTableOpenned
            ? 'Lista de Excluídos'
            : 'Lista de disparo'}
          {!isClientTableOpenned && !isDeletedsClientTableOpenned && (
            <CloseButton onClick={props.onClose}>
              <CloseImage
                src={require('../../../../assets/images/close/close.png')}
              />
            </CloseButton>
          )}
        </ModalHeader>
        {!isClientTableOpenned && !isDeletedsClientTableOpenned ? (
          <ModalContent grid>
            <SegmentationsTable
              segmentations={{
                manual: manualList,
                new: contactsNewList,
                potential: contactsPotentialList,
                recurrent: contactsRecurrentList,
                vip: contactsVipList,
                risk: contactsRiskList,
              }}
              onSelectSegmentation={handleSelectedSegmentation}
              selectedSegmentations={selectedSegmentations}
              onCreateManualList={() => setSelectedSegmentations(['manual'])}
              onClickEditManualSegmentation={setIsEditingManualSegmentation}
            />
            <GeneralInfosContainer>
              <Col
                borderRadius="10px"
                width="100%"
                height={px2vw(55)}
                style={{
                  backgroundColor: branco,
                }}
                pad={px2vw(7)}
                border={`3px solid ${cinzaKuppi7}`}
              >
                <Text alignLeft size={px2vw(12)}>
                  {`Esse disparo será enviado para ${
                    currentClientsList.length
                  } usuário${currentClientsList.length === 1 ? '' : 's'}`}
                </Text>
                <Text
                  style={{ cursor: 'pointer', width: '100%' }}
                  underline
                  regular
                  alignCenter
                  margtop={px2vw(5)}
                  size={px2vw(11)}
                  onClick={() => {
                    setIsClientTableOpenned(true);
                    dispatch(setIsBlackTableOpen(false));
                  }}
                >
                  Visualizar lista do disparo
                </Text>
              </Col>
              <Col
                borderRadius="10px"
                width="100%"
                height={px2vw(55)}
                style={{
                  backgroundColor: branco,
                  marginTop: '1rem',
                }}
                pad={px2vw(7)}
                border={`3px solid ${cinzaKuppi7}`}
              >
                <Text alignCenter size={px2vw(12)}>
                  {`Valor do disparo: ${formatCurrencyBRL(totalAmount, 2)} `}
                </Text>
                <Text regular alignCenter margtop={px2vw(5)} size={px2vw(10)}>
                  {`É cobrado ${formatCurrencyBRL(configs?.per_contact_amount, 2)} por contato disparado`}
                </Text>
              </Col>
              {!!configs?.balance && (
                <>
                  <Row
                    borderRadius="10px"
                    width="100%"
                    height={px2vw(55)}
                    style={{
                      display: 'flex',
                      backgroundColor: branco,
                      marginTop: '1rem',
                      alignItems: 'center',
                    }}
                    pad={px2vw(7)}
                    border={`3px solid ${cinzaKuppi7}`}
                    onClick={handleSelectBonus}
                  >
                    <Checkbutton checked={bonusSelected} />

                    <Col width="100%" alignLeft>
                      <Text
                        alignLeft
                        style={{ textAlign: 'left' }}
                        size={px2vw(12)}
                      >
                        Utilizar bonus do disparo
                      </Text>
                      <Text
                        regular
                        alignCenter
                        margtop={px2vw(5)}
                        size={px2vw(10)}
                      >
                        {!bonusSelected
                          ? `Você possui um bônus de ${formatCurrencyBRL(
                              configs?.balance,
                              2
                            )} para aplicar*`
                          : `O seu bônus de ${formatCurrencyBRL(
                              configs?.balance,
                              2
                            )} está aplicado*`}
                      </Text>
                    </Col>
                  </Row>
                  <div
                    style={{
                      color: vermelhoKuppi3,
                      fontWeight: 'normal',
                      marginTop: 5,
                      padding: 5,
                    }}
                  >
                    *O seu bônus é de uso único. Você não consegue quebrá-lo
                    para outros disparos.
                  </div>
                </>
              )}
              <Col
                borderRadius="5px"
                width="100%"
                alignCenter
              >
                <Text alignCenter size={px2vw(22)} color={verdeKuppi2}>
                  Investimento Total
                </Text>
                <Text alignCenter size={px2vw(24)}>
                  {formatCurrencyBRL(evaluateTotal(totalAmount), 2)}
                </Text>
              </Col>

              <Text
                className="d-block"
                regular
                alignCenter
                margtop={px2vw(8)}
                size={px2vw(10)}
              >
                Obs.: Nas listas ao lado, só contabilizamos os usuários que já
                engajaram em algum momento no seu WhatsApp. Também retiramos
                usuários que você excluiu manualmente dos disparos e os clientes cujo DDD seja diferente do seu.<br/>
                {contactsBlacklist.length > 0 && (
                  <underline
                    style={{
                      textDecoration: 'underline',
                      margin: `0 ${px2vw(4)}`,
                      cursor: 'pointer',
                    }}
                    onClick={handleOpenDeleteTable}
                  >
                    {`Veja a lista com${
                      contactsBlacklist.length === 1 ? '' : ' os'
                    } ${contactsBlacklist.length} excluído${
                      contactsBlacklist.length === 1 ? '' : 's'
                    } aqui.`}
                  </underline>
                )}
              </Text>
            </GeneralInfosContainer>
          </ModalContent>
        ) : (
          <ContactListContent
            data={handleClientListVisible}
            onSearchChange={setInputSearchData}
          />
        )}

        <SegmentationFooter
          onGoBack={handleBacktoPreviousPage}
          onGoNext={handleGoToNextPage}
          showConfirm={isClientTableOpenned || isDeletedsClientTableOpenned}
          showBack={!isClientTableOpenned && !isDeletedsClientTableOpenned}
          showTermsAccept={
            !isClientTableOpenned && !isDeletedsClientTableOpenned
          }
          hasClients={!!currentClientsList.length}
        />

        {blacklistConfig?.isBoxVisible && (
          <DeleteBox
            isFromManualList={openManualList}
            onBlackListSend={() => setInputSearchData('')}
            currentClientsList={currentClientsList}
          />
        )}
      </ModalContainer>
    );
  }

  return (
    <ManualSegmentation
      onCreateManualListTab={() => setSelectedSegmentations(['manual'])}
      onClose={() => setIsEditingManualSegmentation(false)}
    />
  );
}
