import React from 'react';
import { Spinner } from 'reactstrap';
import { preto } from '../../../../styles/colors';
import { Col } from '../../../../styles/grid';
import { 
  slugEmail,
  btnTextLogin, 
  placeholderPassword,
  slugPassword,
  placeholderEmail,
  credentialsRecoverySlug,
 } from '../../constants';
import { LoadingBox } from '../../../_layouts/styles/common';
import { 
  ForgotPasswordButton, 
  FormLogin, 
  LoginBox, 
  LoginButton, 
  LoginButtonText, 
  LoginInput, 
  LoginInputBox, 
  LoginInputLabel, 
  LoginLeft, 
  LoginRight, 
  LogoImage, 
  LogoImageBox, 
  LogoLeftImage, 
  SeePasswordButton, 
  SeePasswordImage 
} from '../../styles';

export default function MainWeb({ handler }) {
  return (
    <>
      <LoginLeft>
        <LogoLeftImage
          src={require('../../../../assets/images/login/login@2x.png')}
        />
      </LoginLeft>
      {/* Form Login */}
      <LoginRight>
        <LogoImageBox>
          <LogoImage
            src={require('../../../../assets/images/logo_kuppi/logo_kuppi@2x.png')}
          />
        </LogoImageBox>
        <FormLogin onSubmit={handler.handleSubmit}>
          <LoginInputBox>
            <Col height="auto" width="80%">
              <LoginInputLabel >{slugEmail}</LoginInputLabel>
            </Col>
            <LoginBox>
              <LoginInput
                name="email"
                type="email"
                placeholder={placeholderEmail}
              />
            </LoginBox>
          </LoginInputBox>
          <LoginInputBox margtop="10px">
            <Col height="auto" width="80%">
              <LoginInputLabel>{slugPassword}</LoginInputLabel>
            </Col>
            <LoginBox>
              <LoginInput
                name="password"
                type={handler.passwordVisible ? 'text' : 'password'}
                placeholder={placeholderPassword}
              />
              <SeePasswordButton
                type="button"
                onClick={() => handler.setPasswordVisible(!handler.passwordVisible)}
              >
                <SeePasswordImage
                  src={require(handler.passwordVisible
                    ? '../../../../assets/images/eye-outline/eye-outline.png'
                    : '../../../../assets/images/eye-outline/eye-off-outline.png')}
                />
              </SeePasswordButton>
            </LoginBox>
          </LoginInputBox>
          <Col height="auto" alignEnd justifyEnd width="80%">
            <ForgotPasswordButton
              type="button"
              onClick={() => handler.handleForgotPasswordEmail()}
            >
              {credentialsRecoverySlug}
            </ForgotPasswordButton>
          </Col>
          <LoginBox>
            <LoginButton type="submit" disabled={handler.loading}>
              {handler.loading ? (
                <LoadingBox>
                  <Spinner
                    style={{ width: '1.8rem', height: '1.8rem', color: preto }}
                  />
                </LoadingBox>
              ) : (
                <LoginButtonText>{btnTextLogin}</LoginButtonText>
              )}
            </LoginButton>
          </LoginBox>
        </FormLogin>
      </LoginRight>
    </>
  )
}