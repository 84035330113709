import React from "react";

import { cinzaKuppi6 } from "../../styles/colors";
import { FormDataInput, GeneralText } from "../../styles/common";
import { Col } from "../../styles/grid";

import px2vw from "../../utils/responsiveness/px2vw";

export default function FieldOption({
  fieldName, fieldController, fieldValue, width, maxLength, placeHolder
}) {
  return (
    <Col
      margtop={px2vw(50)} padbottom={px2vw(20)}>
      <GeneralText
        fontsize={px2vw(45)}
        fontweight='bold'
        textalign='flex-end'
        margtop={px2vw(50)}
        color={cinzaKuppi6}
        padbottom={px2vw(20)}
      >
        {fieldName}
      </GeneralText>
      <FormDataInput
        maxLength={maxLength}
        fontsize={px2vw(50)}
        placeholder={placeHolder}
        borderradius={px2vw(30)}
        name={fieldName}
        type="text"
        value={fieldValue}
        height={px2vw(150)}
        width={width || '90%'}
        onChange={fieldController}
      />
    </Col>
  )
}
