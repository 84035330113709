import { takeLatest, call, put, all, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { apiAnunciante, apiRDStation } from '../../../services/api';
import history from '../../../services/history';

import { dashboard, onboarding } from '../../../routes/routeMap';

import {
  updateSurveyObjectivesSuccess,
  updateSurveyObjectivesFailure,
  updateSurveyFindOutAboutSuccess,
  updateSurveyFindOutAboutFailure,
  updateSurveyStoreDataSuccess,
  updateSurveyStoreDataFailure,
} from './actions';
import { updateUserInfoSuccess } from '../user/actions';
import {
  logout
} from '../auth/actions';

import { sendOnboardingInfo } from '../../../services/vendors/mixpanel';

const userReducerData = (state) => state.user;
const userData = (state) => state.user.user;
const firstStepsSurveyData = (state) => state.firstSteps.firstStepsSurveyData;

export function* updateSurveyObjectivesRequest({ payload }) {
  try {
    const user = yield select(userData);
    const userReducer = yield select(userReducerData);

    const response = yield call(
      apiAnunciante.put,
      `/user-info/${user.advertiserInfo.id}/onboarding/survey/objectives`,
      {
        survey_step: payload.surveyStep,
        objective: payload.objective
      }
    );

    //MixPanel Event
    sendOnboardingInfo({
      id: user.advertiserInfo.id,
      name: user.advertiserInfo.name,
      email: user.email,
      type: 'Survey',
      currOnboardingStep: '1step',
      hasFreeTrialValid: userReducer.hasFreeTrialValid,
      surveyStep1DataObjective: payload.objective
    });

    yield put(updateSurveyObjectivesSuccess(response.data));
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      toast.error('Ocorreu um erro ao salvar os dados. Tente novamente!', {
        autoClose: 6000,
      });
      yield put(updateSurveyObjectivesFailure());
    }
  }
}

export function* updateSurveyFindOutAboutRequest({ payload }) {
  try {
    const user = yield select(userData);
    const userReducer = yield select(userReducerData);

    const response = yield call(
      apiAnunciante.put,
      `/user-info/${user.advertiserInfo.id}/onboarding/survey/find-out-about`,
      {
        survey_step: payload.surveyStep,
        find_out_about: payload.findOutAbout,
        find_out_about_other_content: payload.findOutAboutOtherContent,
        has_computer: payload.deliveryHasComputer,
      }
    );

    //MixPanel Event
    sendOnboardingInfo({
      id: user.advertiserInfo.id,
      name: user.advertiserInfo.name,
      email: user.email,
      type: 'Survey',
      currOnboardingStep: '2step',
      hasFreeTrialValid: userReducer.hasFreeTrialValid,
      surveyStep2DataFindOutAbout: {
        find_out_about: payload.findOutAbout,
        find_out_about_other_content: payload.findOutAboutOtherContent,
      },
      surveyStep2DataHasComputer: payload.deliveryHasComputer
    });

    yield put(updateSurveyFindOutAboutSuccess(response.data));
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      toast.error('Ocorreu um erro ao salvar os dados. Tente novamente!', {
        autoClose: 6000,
      });
      yield put(updateSurveyFindOutAboutFailure());
    }
  }
}

export function* updateSurveyStoreDataRequest({ payload }) {
  try {
    const user = yield select(userData);
    const userReducer = yield select(userReducerData);
    const firstStepsSurveyReducer = yield select(firstStepsSurveyData);

    // Send to RD the survey was completed if user is in trial period
    if (userReducer.hasFreeTrialValid) {
      try {
        yield call(
          apiRDStation.post,
          `/platform/conversions?api_key=${process.env.REACT_APP_RDSTATION_KEY}`,
          {
            event_type: "CONVERSION",
            event_family: "CDP",
            payload: {
              conversion_identifier: process.env.REACT_APP_RDSTATION_TRIAL_SURVEY_COMPLETED_EVENT,
              name: user.advertiserInfo.representative_name,
              company_name: user.advertiserInfo.name,
              email: user.email,
              mobile_phone: user.advertiserInfo.phone_whatsapp_representative,
              city: user.address.city,
              cf_trial_survey_daily_orders_avg: payload.dailyOrdersAvg,
              cf_trial_survey_has_computer: firstStepsSurveyReducer.has_computer?.toString(),
              cf_trial_survey_is_at_ifood: firstStepsSurveyReducer.delivery_app_ifood?.toString(),
              cf_trial_survey_store_objective: firstStepsSurveyReducer.objective,
            },
          }
        );
      } catch(err) {
        console.error(err);
      }
    }

    const response = yield call(
      apiAnunciante.put,
      `/user-info/${user.advertiserInfo.id}/onboarding/survey/store-data`,
      {
        survey_step: payload.surveyStep,
        delivery_type: payload.deliveryType,
        daily_orders_avg: payload.dailyOrdersAvg,
        sales_own_channel_percentage: payload.salesOwnChannelPercentage,
        sales_marketplaces_percentage: payload.salesMarketplacesPercentage,
      }
    );

    //MixPanel Event
    sendOnboardingInfo({
      id: user.advertiserInfo.id,
      name: user.advertiserInfo.name,
      email: user.email,
      type: 'Survey',
      currOnboardingStep: '3step',
      hasFreeTrialValid: userReducer.hasFreeTrialValid,
      surveyStep3DataDeliveryType: payload.deliveryType,
      surveyStep3DataOrdersPerDay: payload.dailyOrdersAvg,
      surveyStep3DataSalesPercentage: {
        sales_own_channel_percentage: payload.salesOwnChannelPercentage,
        sales_marketplaces_percentage: payload.salesMarketplacesPercentage,
      }
    });

    yield put(updateSurveyStoreDataSuccess(response.data.surveyData));
    yield put(updateUserInfoSuccess(response.data.userData));
    
    if (!user.advertiserInfo.is_done_onboarding) {
      history.push(onboarding);
    } else {
      history.push(dashboard);
    }
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      toast.error('Ocorreu um erro ao salvar os dados. Tente novamente!', {
        autoClose: 6000,
      });
      yield put(updateSurveyStoreDataFailure());
    }
  }
}

export default all([
  takeLatest(
    '@firstSteps/UPDATE_SURVEY_OBJECTIVE_REQUEST',
    updateSurveyObjectivesRequest
  ),
  takeLatest(
    '@firstSteps/UPDATE_SURVEY_FIND_OUT_ABOUT_REQUEST',
    updateSurveyFindOutAboutRequest
  ),
  takeLatest(
    '@firstSteps/UPDATE_SURVEY_STORE_DATA_REQUEST',
    updateSurveyStoreDataRequest
  ),
]);
