import produce from 'immer';

import { paginateState } from '../../../utils/commonObjects';

const INITIAL_STATE = {
  cashReportPaymentMethodTotals: null,
  cashReportTotals: null,
  cashReportTopSoldProducts: null,
  cashReportLoading: false,
  cashReportSuccess: false,
  cashReportError: false,
  pixReportLoading: false,
  pixReportPaymentData: null,
  pixReportPaymentDataPaginate: paginateState,
  pixReportSuccess: false,
  pixReportError: false,
  orderPerWeekDay: null,
  orderPerHour: [],
  orderTypes: {},
  topSoldProducts: [],
  paymentMethodsTotals: [],
  ordersPerNeighborhood: [],
  ordersSuccess: false,
  ordersLoading: false,
  ordersFailure: false,
};

export default function reports(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@reports/GET_CASH_REPORT_REQUEST': {
        draft.cashReportLoading = true;
        draft.cashReportPaymentMethodTotals = null;
        draft.cashReportTotals = null;
        draft.cashReportTopSoldProducts = null;
        draft.cashReportSuccess = false;
        draft.cashReportError = false;
        break;
      }
      case '@reports/GET_CASH_REPORT_SUCCESS': {
        draft.cashReportLoading = false;
        draft.cashReportPaymentMethodTotals =
          action.payload.cashReport.payment_methods_totals;
        draft.cashReportTotals = action.payload.cashReport.totals;
        draft.cashReportTopSoldProducts =
          action.payload.cashReport.top_sold_products;
        draft.cashReportSuccess = true;
        draft.cashReportError = false;
        break;
      }
      case '@reports/GET_CASH_REPORT_FAILURE': {
        draft.cashReportLoading = false;
        draft.cashReportPaymentMethodTotals = null;
        draft.cashReportTotals = null;
        draft.cashReportTopSoldProducts = null;
        draft.cashReportSuccess = false;
        draft.cashReportError = true;
        break;
      }
      case '@reports/GET_PIX_REPORT_REQUEST': {
        draft.pixReportLoading = true;
        draft.pixReportSuccess = false;
        draft.pixReportError = false;
        break;
      }
      case '@reports/GET_PIX_REPORT_SUCCESS': {
        draft.pixReportLoading = false;
        draft.pixReportSuccess = true;
        draft.pixReportError = false;
        draft.pixReportPaymentData = action.payload.pixReport.data;
        draft.pixReportPaymentDataPaginate.total = action.payload.pixReport.total;
        draft.pixReportPaymentDataPaginate.perPage = action.payload.pixReport.perPage;
        draft.pixReportPaymentDataPaginate.page = action.payload.pixReport.page;
        draft.pixReportPaymentDataPaginate.lastPage = action.payload.pixReport.lastPage;
        break;
      }
      case '@reports/GET_PIX_REPORT_FAILURE': {
        draft.pixReportLoading = false;
        draft.pixReportSuccess = false;
        draft.pixReportError = true;
        draft.pixReportPaymentData = paginateState;
        break;
      }
      case '@reports/GET_ORDERS_REPORT_REQUEST': {
        draft.ordersLoading = true;
        draft.ordersSuccess = false;
        draft.ordersFailure = false;
        draft.orderPerWeekDay = null;
        draft.orderPerHour = [];
        draft.orderTypes = {};
        draft.topSoldProducts = [];
        draft.paymentMethodsTotals = [];
        draft.ordersPerNeighborhood = [];
        break;
      }
      case '@reports/GET_ORDERS_REPORT_SUCCESS': {
        draft.ordersLoading = false;
        draft.ordersSuccess = true;
        draft.ordersFailure = false;
        draft.orderPerWeekDay = action.payload.ordersReports.order_per_week_day;
        draft.orderPerHour = action.payload.ordersReports.order_per__hour;
        draft.orderTypes = action.payload.ordersReports.order_types;
        draft.topSoldProducts = action.payload.ordersReports.top_sold_products;
        draft.paymentMethodsTotals = action.payload.ordersReports.payment_methods_totals;
        draft.ordersPerNeighborhood = action.payload.ordersReports.orders_per_neighborhood;
        break;
      }
      case '@reports/GET_ORDERS_REPORT_FAILURE': {
        draft.ordersLoading = false;
        draft.ordersSuccess = false;
        draft.ordersFailure = true;
        draft.orderPerWeekDay = null;
        draft.orderPerHour = [];
        draft.orderTypes = {};
        draft.topSoldProducts = [];
        draft.paymentMethodsTotals = [];
        draft.ordersPerNeighborhood = [];
        break;
      }
      case '@reports/CLEAR_SUCCESS': {
        draft.cashReportSuccess = false;
        draft.ordersSuccess = false;
        break;
      }
      case '@reports/CLEAR_REPORT_ERRORS': {
        draft.cashReportError = false;
        draft.cashReportPaymentMethodTotals = null;
        draft.cashReportTotals = null;
        draft.cashReportTopSoldProducts = null;
        draft.pixReportError = false;
        draft.pixReportPaymentData = null;
        draft.pixReportPaymentDataPaginate = paginateState;
        draft.ordersFailure = false;
        draft.orderPerWeekDay = null;
        draft.orderPerHour = [];
        draft.orderTypes = {};
        draft.topSoldProducts = [];
        draft.paymentMethodsTotals = [];
        draft.ordersPerNeighborhood = [];
        break;
      }
      default:
    }
  });
}
