import styled from 'styled-components';

import px2vw from '../../utils/responsiveness/px2vw';

import {
  branco,
  cinzaKuppi4,
  cinzaKuppi7,
  preto
} from '../../styles/colors';


export const FAQTitle = styled.div`
  font-size: ${px2vw(20)};
  font-weight: bold;
  font-family: AvenirNextLTPro;
  margin-bottom: ${px2vw(30)};
`;

export const FAQAccordion = styled.div`
  width: 100%;
  background-color: ${branco};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-radius: ${px2vw(10)};
  padding-left: ${px2vw(20)};
  margin-bottom: ${px2vw(20)};

  box-shadow: 0px 8px 9px -2px ${cinzaKuppi4};
`;

export const FAQTitleContainerQuestion = styled.div`
  width: 100%;
  font-size: ${px2vw(14)};
  background-color: ${branco};
  border-radius: ${px2vw(10)};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: ${px2vw(10)};
  padding-bottom: ${px2vw(10)};
  padding-right: ${px2vw(10)};
`;

export const FAQTitleQuestion = styled.div`
  font-size: ${px2vw(16)};
  font-weight: bold;
  font-family: AvenirNextLTPro;
`;

export const FAQAnswerContainer = styled.div`
  width: 100%;
  background-color: ${branco};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: ${px2vw(10)};
`;

export const FAQAnswer = styled.p`
  font-size: ${px2vw(14)};
  margin: 0;
  letter-spacing: 1px;
`;

export const BlockTitle = styled.div`
  font-size: ${px2vw(18)};
  margin-left: ${(props) => props.margleft && props.margleft};
  margin-bottom: ${(props) => props.margbottom && props.margbottom};
  margin-top: ${(props) => props.margtop && props.margtop};
  font-family: AvenirNextLTPro;
  font-weight: bold;
  letter-spacing: 0.36px;
`;

export const BlockText = styled.div`
  font-size: ${(props) => props.fontsize || px2vw(14)};
  margin-left: ${(props) => props.margleft || px2vw(20)};
  font-family: AvenirNextLTPro;
  margin-bottom: ${(props) => props.margbottom && props.margbottom};
  margin-top: ${(props) => props.margtop && props.margtop};
  font-weight: normal;
  letter-spacing: 0.36px;
`;

export const ButtonDisconnectWpp = styled.div`
  width: auto;
  height: auto;
  background-color: ${branco};
  border-radius: ${px2vw(10)};
  padding: ${px2vw(10)};
  cursor: pointer;
  background-color: ${branco};
  border-color: ${preto};
  border-style: solid;
  border-width: 2px;
  margin-top: ${px2vw(-40)};
`;

export const TitleDisconnectWpp = styled.h1`
  color: ${preto};
  font-size: ${px2vw(16)};
  letter-spacing: 1px;
  font-weight: bold;
  margin: 0;
`;

export const ButtonNewQrCode = styled.div`
  width: auto;
  height: auto;
  background-color: ${cinzaKuppi4};
  border-radius: ${px2vw(10)};
  padding: ${px2vw(10)};
  cursor: pointer;
`;

export const TitleNewQrCode = styled.h1`
  font-size: ${px2vw(16)};
  letter-spacing: 1px;
  font-weight: bold;
  color: ${cinzaKuppi7};
  margin: 0;
`;

export const ContainerVideo = styled.div`
  margin-top: ${px2vw(20)};
  width: ${px2vw(500)};
  height: ${px2vw(270)};
`;

export const SubtitleText = styled.div`
  font-size: ${px2vw(12)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.36px;
  margin-left: ${px2vw(5)};
  margin-right: ${px2vw(30)};
  margin-top: ${(props) => props.margtop || px2vw(20)};
  width: ${px2vw(610)};
`;

export const WhatsappActionBox = styled.div`
  background-color: ${branco};
  border-radius: ${px2vw(10)};
  padding: ${px2vw(20)};
  width: ${px2vw(1000)};
  height: ${px2vw(634)};

  box-shadow: 0px 8px 9px -2px ${cinzaKuppi4};
`;

export const WhatsappFunctionsBox = styled.div`
  background-color: ${branco};
  border-radius: ${px2vw(20)};
  padding-left: ${px2vw(15)};
  padding-right: ${px2vw(15)};
  padding-top: ${px2vw(15)};
  padding-bottom: ${px2vw(5)};
  width: ${px2vw(355)};

  box-shadow: 0px 8px 9px -2px ${cinzaKuppi4};
`;

export const WhatsappImportantBox = styled.div`
  background-color: ${branco};
  border-radius: ${px2vw(10)};
  padding: ${px2vw(20)};
  width: ${px2vw(500)};
  height: ${px2vw(296)};
  margin-top: ${px2vw(20)};

  box-shadow: 0px 8px 9px -2px ${cinzaKuppi4};
`;
