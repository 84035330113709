import React, { useState } from 'react';
import { Spinner } from 'reactstrap';

import {
  Card,
  NormalText,
} from '../../BaseScreen/styles';
import {
  LoadingBox,
} from '../../../_layouts/styles/common';
import {
  SaveButton,
  SaveButtonText,
} from '../../../Catalog/styles';
import { Row, Col } from '../../../../styles/grid';
import {
  branco,
  preto,
} from '../../../../styles/colors';
import { ButtonBox, ButtonImage, ButtonText } from './styles';

import px2vw from '../../../../utils/responsiveness/px2vw';

import ButtonPrimary from '../../../../components/ButtonPrimary';
import Category from './Category';
import ProductComplementsModal from './ProductComplementsModal';
import { 
  ImportIfoodModal, 
  ImportIfoodWarnCategoriesModal,
  ImportIfoodLoadingModal, 
  ImportIfoodSuccessModal, 
  ImportIfoodErrorModal,
  ImportIfoodSuccessInfoModal,
  ImportIfoodMaintenanceModal
} from './ImportIfood';

export default function CatalogOnboarding({ handler }) {
  const [importIfoodCatalogStep, setImportIfoodCatalogStep] = useState('');
  const [currProductComplements, setCurrProductComplements] = useState(null);
  const [complementsModalState, setComplementsModalState] = useState(false);
  const [
    modalComplementsCategoryState,
    setModalComplementsCategoryState,
  ] = useState(true);

  const btnHeight = 120;
  const btnBorderRadius = 30;

  function handleOpenComplementModal(complements) {
    setCurrProductComplements(complements);
    setComplementsModalState(true);
  }

  const importIfoodHandler = {
    categories: handler.categories,
    setImportIfoodCatalogStep: (val) => setImportIfoodCatalogStep(val)
  }

  const complementHandler = {
    ...handler,
    currProductComplements: currProductComplements,
    complementsModalState: complementsModalState,
    modalComplementsCategoryState: modalComplementsCategoryState,
    setComplementsModalState: (val) => setComplementsModalState(val),
    setModalComplementsCategoryState: () => setModalComplementsCategoryState(),
    setCurrProductComplements: (val) => setCurrProductComplements(val),
  };

  const categoryHandler = {
    ...handler,
    handleOpenComplementModal: (val) => handleOpenComplementModal(val)
  }

  return (
    <Col height={handler.categories.length === 0 ? "85vh" : "100%"}>
      <Row 
        justifyStart
        margleft={px2vw(100)}
        height="auto"
      >
        <NormalText
          margtop={px2vw(80)}
          fontweight="bold"
          color={preto}
          fontsize={px2vw(60)}
        >
          Como deseja criar seu catálogo?
        </NormalText>
      </Row>

      <Col height="auto" margtop={px2vw(80)} padleft={px2vw(100)}>
        <ButtonPrimary
          background={preto}
          borderColor={preto}
          color={branco}
          minWidth={1200}
          height={btnHeight}
          borderRadius={btnBorderRadius}
          onClick={() => {
            if (handler.importIfoodCatalogMaintenance) {
              setImportIfoodCatalogStep('maintenance');
            } else {
              setImportIfoodCatalogStep('step1');
            }
          }}
        >
          <ButtonBox>
            <ButtonImage
              src={require('../../../../assets/images/onboarding/iFoodImport@2x.png')}
            />
            <ButtonText>Importar cardápio do iFood</ButtonText>
          </ButtonBox>
        </ButtonPrimary>
      </Col>

      <Card
        margtop={px2vw(120)}
        borderRadius={`${px2vw(80)} ${px2vw(80)} 0 0`}
        padright={px2vw(80)}
        padleft={px2vw(80)}
        width={1360}
        height="auto"
      >
        <NormalText
          margtop={px2vw(80)}
          fontweight="bold"
          color={preto}
          fontsize={px2vw(60)}
          margbottom={px2vw(80)}
        >
          Crie seu catálogo manualmente
        </NormalText>
        <NormalText
          color={preto}
          fontsize={px2vw(50)}
          margbottom={px2vw(120)}
        >
          Para criar o catálogo manualmente, acesse a plataforma do anunciante pelo computador
        </NormalText>
      </Card>
      {handler.categories.map((category) => {
        return <Category handler={categoryHandler} category={category} />
      })}
      {handler.pageLoading && (
        <Row alignCenter justifyCenter>
          <Spinner
            style={{
              width: '1.8rem',
              height: '1.8rem',
              color: preto,
            }}
          />
        </Row>
      )}
      <Row margbottom={px2vw(380)} margtop={px2vw(80)} margleft={px2vw(320)}>
        <SaveButton
          minWidth={px2vw(500)}
          height={px2vw(btnHeight)}
          borderRadius={px2vw(btnBorderRadius)}
          id="catalogOnboardingSaveButton"
          type="button"
          hasChanges={handler.isSaveButtonActive()}
          disabled={handler.isSaveButtonDisabled()}
          onClick={() => handler.handleCompleteCatalogStep()}
        >
          {handler.updateOnboardingStepLoading ? (
            <LoadingBox>
              <Spinner
                style={{
                  width: '1.8rem',
                  height: '1.8rem',
                  color: preto,
                }}
              />{' '}
            </LoadingBox>
          ) : (
            <SaveButtonText 
              fontSize={px2vw(50)}
              hasChanges={handler.isSaveButtonActive()}
            >
              Avançar
            </SaveButtonText>
          )}
        </SaveButton>
      </Row>
      {complementsModalState && (
        <ProductComplementsModal
          handler={complementHandler}
        />
      )}
      {importIfoodCatalogStep === 'step1' && <ImportIfoodModal handler={importIfoodHandler} />}
      {importIfoodCatalogStep === 'step1-warn' && <ImportIfoodWarnCategoriesModal handler={importIfoodHandler} />}
      {importIfoodCatalogStep === 'step2' && <ImportIfoodLoadingModal handler={importIfoodHandler} />}
      {importIfoodCatalogStep === 'step3-success' && <ImportIfoodSuccessModal handler={importIfoodHandler} />}
      {importIfoodCatalogStep === 'step3-failure' && <ImportIfoodErrorModal handler={importIfoodHandler} />}
      {importIfoodCatalogStep === 'step4' && <ImportIfoodSuccessInfoModal handler={importIfoodHandler} />}
      {importIfoodCatalogStep === 'maintenance' && <ImportIfoodMaintenanceModal handler={importIfoodHandler} />}
    </Col>
  );
}
