import React from 'react';

import { Row, Col } from '../../../../styles/grid';
import { ActiveTextGreen, GoToDashboard } from '../styles';

import px2vw from '../../../../utils/responsiveness/px2vw';
import { formatCurrencyBRL } from '../../../../utils/utils';

import {
  SuccessPaymentImage,
  PageTitle,
  PageText,
  SectionBox,
  Separator,
  OrderSumaryTextBold,
  OrderSumaryText,
} from '../../styles';

import { dashboard } from '../../../../routes/routeMap';

import {
  PageTitleSuccess,
  PageTextRenewBlocked,
  PageTextFirstPayment,
  VigencyText,
  CreditCardPaymentText,
  AccessPlatformText,
  PlanActiveText,
} from '../constants';
import {
  SignatureMonthlyAmountText,
  SignatureTotalAmountText,
  SignatureMonthlyInstalmentsText,
} from '../../constants';

export default function PaymentCreditCardSuccessWeb({ handler }) {
  return (
    <>
      <Row alignStart justifyStart>
        <SuccessPaymentImage
          src={require('../../../../assets/images/success/success.png')}
        />
        <PageTitle>{PageTitleSuccess}</PageTitle>
      </Row>
      <Separator />
      <Row
        justifyStart
        padleft={px2vw(25)}
        padright={px2vw(25)}
        margtop={px2vw(30)}
        margbottom={px2vw(30)}
      >
        <PageText>
          {(handler.areBlocked || handler.isRenewPayment) && PageTextRenewBlocked}
          {handler.isFirstPayment && PageTextFirstPayment}
        </PageText>
      </Row>
      <SectionBox>
        <Col
          padtop={px2vw(20)}
          padbottom={px2vw(20)}
          padleft={px2vw(50)}
          padright={px2vw(50)}
        >
          <Row margtop={px2vw(20)} padleft={px2vw(20)} padright={px2vw(20)}>
            <Col alignStart>
              <OrderSumaryTextBold>
                {`${handler.signaturePaymentData?.subscription?.name}`}
              </OrderSumaryTextBold>
              <ActiveTextGreen>
                {PlanActiveText}
              </ActiveTextGreen>
              <Row justifyStart margtop={px2vw(32)}>
                <OrderSumaryText>{VigencyText.replace('$$VIGENCY$$', handler.vigencyInfo)}</OrderSumaryText>
              </Row>
            </Col>
            <Col alignEnd>
              <OrderSumaryText>{SignatureMonthlyAmountText}</OrderSumaryText>
              <OrderSumaryTextBold>
                {handler.signaturePaymentData?.subscription?.period > 1 &&
                  `${handler.signaturePaymentData?.subscription?.period}${SignatureMonthlyInstalmentsText}`}
                {formatCurrencyBRL(handler.signaturePaymentData?.amount?.toFixed(2), {})}
              </OrderSumaryTextBold>
              {handler.signaturePaymentData?.subscription?.period > 1 && (
                <Col alignEnd margtop={px2vw(10)}>
                  <OrderSumaryText>{SignatureTotalAmountText}</OrderSumaryText>
                  <OrderSumaryTextBold>
                    {formatCurrencyBRL(
                      (
                        Math.round(
                          handler.signaturePaymentData?.amount *
                            handler.signaturePaymentData?.subscription?.period *
                            100
                        ) / 100
                      ).toFixed(2),
                      {}
                    )}
                  </OrderSumaryTextBold>
                </Col>
              )}
            </Col>
          </Row>
          <Row margtop={px2vw(30)} padleft={px2vw(20)} padright={px2vw(20)}>
            <Col alignStart>
              <OrderSumaryText>{CreditCardPaymentText}</OrderSumaryText>
              <OrderSumaryTextBold>
                {handler.signaturePaymentData?.paymentCard !== null &&
                  `${handler.signaturePaymentData?.paymentCard?.brand} ****${handler.signaturePaymentData?.paymentCard?.last_digits}`}
              </OrderSumaryTextBold>
            </Col>
            <Row justifyEnd margtop={px2vw(10)} width="100%">
              <GoToDashboard to={dashboard}>{AccessPlatformText}</GoToDashboard>
            </Row>
          </Row>
        </Col>
      </SectionBox>
    </>
  );
}
