import styled from 'styled-components';

import { rosaKuppi1 } from '../../styles/colors';

import px2vw from '../../utils/responsiveness/px2vw';

export const Container = styled.div`
  z-index: 2;
  height: ${px2vw(38)};
  width: ${px2vw(1358)};
  background-color: ${rosaKuppi1};
  margin-bottom: ${px2vw(-15)};
  text-align: center;
  padding-top: ${px2vw(10)};
  font-size: ${px2vw(11)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.36px;
`;
