import styled from 'styled-components';
import {
  cinzaKuppi1,
  laranjaKuppi4,
  verdeKuppi25,
} from '../../../styles/colors';
import px2vw from '../../../utils/responsiveness/px2vw';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  background-color: ${cinzaKuppi1};
  padding: ${px2vw(50)};
  border-bottom-left-radius: 60px;

  ${(props) => props.free && `
    border-top-left-radius: 60px;
  `}

  ${(props) => props.activationChallenge && `
    border-top-left-radius: 00px;
  `}

  ${(props) => props.wppChatPage && `
    border-radius: 0px;
    background: linear-gradient( ${verdeKuppi25} ${px2vw(210)}, ${laranjaKuppi4} ${px2vw(50)});
    background-attachment: fixed;
    padding-right: 0;
    padding-left: ${px2vw(10)};
    padding-top: ${px2vw(10)};
    padding-bottom: 0;
  `}
`;

export const Content = styled.div``;
