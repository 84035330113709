import styled from 'styled-components';

import { preto, branco, verdeKuppi1, verdeKuppi10, verdeKuppi11, cinzaKuppi4  } from '../../styles/colors';

import px2vw from '../../utils/responsiveness/px2vw';

export const Container = styled.div`
  height: 40px;
  background-color: ${branco};
  border: 1px solid ${preto};
  border-radius: 5px;
  width: 100%;
`;

export const Filler = styled.div`
  height: 100%;
  border-right: 1px solid ${preto};
  border-radius: 5px;
  transition: width 1s ease-in-out;
  ${(props) => `
    width: ${props.completed}%;
    background-color: ${props.bgcolor};
  `};
`;

export const Bar = styled.div`
  width: 100%;
  background-color: ${cinzaKuppi4};
  border-radius: ${px2vw(15)};
  position: relative;

  ${(props) => `
    height: ${props.height || px2vw(7)};
  `};
 

  span {
    display: block;
    position: relative;
    
    height: 100%;
    background-color: ${verdeKuppi10};
    border-radius: 15px;
    box-shadow: inset 0 2px 9px ${verdeKuppi11}, inset 0 -2px 6px ${verdeKuppi1};
    overflow: hidden;
    padding: ${px2vw(3)};
    
  }
`;
