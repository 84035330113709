import styled from 'styled-components';
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';
import { Row } from '../../../styles/grid';
import px2vw from '../../../utils/responsiveness/px2vw';
import { cinzaKuppi6, cinzaKuppi2, cinzaKuppi7, amareloKuppi7, verdeKuppi23, azulKuppi8 } from '../../../styles/colors';

export const Container = styled.div``;

export const Header = styled(Row)`
  justify-content: flex-start;
  align-items: flex-start;
  padding: 15px 0;
  padding: ${(props) => props.padding};
  color: ${cinzaKuppi2};
  font-family: AvenirNextLTPro;
  font-weight: 700;
`;

export const HeaderButton = styled.button`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  color: ${cinzaKuppi6};
  font-family: AvenirNextLTPro;
  font-weight: 700;

  background: transparent !important;
  border: none !important;
  box-shadow: none !important;

  ${(props) =>
    props.column && `flex-direction: column; align-items: flex-start;`}
  ${(props) => props.width && `width: ${props.width}`}
`;

export const Content = styled(Row)`
  justify-content: flex-start;
  align-items: center;
  display: flex;

  padding: 10px 0;
  padding: ${(props) => props.padding && props.padding};

  font-family: AvenirNextLTPro;
  font-weight: normal;

  .text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 20ch;
  }
`;

export const TableRow = styled(Row)`
  :hover {
    background: ${cinzaKuppi2};
  }
`;

export const Hr = styled.div`
  border-top: 2px solid ${cinzaKuppi2};
  height: 1px;
  width: 100%;
`;

export const TagType = styled.div`
  border-radius: ${px2vw(9)};
  background: ${(props) => (props.type === 'range' ? azulKuppi8 : props.type === 'whatsapp' ? amareloKuppi7 : verdeKuppi23)};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${px2vw(10)};
  height: ${px2vw(14)};
  width: ${px2vw(65)};
  margin-bottom:  ${px2vw(7)};
  .div{
    font-size: ${px2vw(10)};
  }
`;

export const PagButton = styled.button`
  width: ${px2vw(40)};
  height: ${px2vw(40)};
  border-radius: ${px2vw(40)};
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent !important;
  border: none !important;
  box-shadow: none !important;

  :hover {
    ${(props) =>
      props.disabled
        ? `background: transparent !important; cursor: not-allowed !important`
        : `background: ${cinzaKuppi2} !important`};
  }
`;

export const PrevIcon = styled(AiOutlineLeft).attrs({
  size: px2vw(12),
})`
  color: ${(props) => props.disabled && cinzaKuppi7};
`;
export const NextIcon = styled(AiOutlineRight).attrs({
  size: px2vw(12),
})`
  color: ${(props) => props.disabled && cinzaKuppi7};
`;

export const ImageBox = styled.div`
  position: relative;
  max-width: ${px2vw(66)};
  max-height:${px2vw(66)};
  width: ${px2vw(60)};
  height: ${px2vw(60)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  margin: 0 5px 0 10px;
`;
export const Image = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: AvenirNextLTPro;
  font-weight: normal;
  border-radius: ${(props) => props.borderRadius};
`;
