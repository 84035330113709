import styled from 'styled-components';

import px2vw from '../../utils/responsiveness/px2vw';

import {
  cinzaKuppi1,
  verdeKuppi2,
  cinzaKuppi4,
} from '../../styles/colors';

export const CheckboxContainer = styled.div`
  padding-left: ${px2vw(5)};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  ${(props) => `
    height: ${px2vw(props.height)};
    width: ${px2vw(props.insideWidth)};
    padding-top: ${px2vw(props.padtop)};
  `}

  cursor: pointer;
`;

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  display: none;
  cursor: pointer;
`;

export const Text = styled.label`
  width: 100%;
  font-family: AvenirNextLTPro;
  font-weight: normal;

  ${(props) => `
    font-size: ${px2vw(props.fontSize)};
    margin-top: ${props.marginTop || px2vw(7)};
    margin-left: ${px2vw(props.marginleft || 7)};
  `}

  cursor: pointer;
`;

export const StyledCheckbox = styled.label`
  margin-right: ${px2vw(20)};
  margin-top: ${px2vw(7)};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  ${(props) => `
    width: ${px2vw(props.width)};
    height: ${px2vw(props.height)};
  `};

  img{
    display: ${props => props.checked ? 'flex' : 'none'};
  }

  ${(props) => props.disabled && `
    background: ${cinzaKuppi4};
    border: 1px solid ${cinzaKuppi4};
  `};

  ${(props) => !props.disabled && `
    background: ${cinzaKuppi1};
    border: 1px solid ${verdeKuppi2};
  `};

  cursor: pointer;

`;




