import styled, { css } from 'styled-components';
import {
  cinzaKuppi11,
  cinzaKuppi4,
  verdeKuppi2,
  cinzaKuppi2,
  verdeKuppi1,
} from '../../../../styles/colors';

import { branco, preto } from '../../../../styles/colors';

import px2vw from '../../../../utils/responsiveness/px2vw';
import { keyframes } from 'styled-components';

const colorFadeIn = keyframes`
  0% {
  background-color: ${cinzaKuppi4};
  transform: scale(1.01);
}
  100% {
    background-color: ${verdeKuppi2};
    transform: initial;
  }
`;

const imgFadeIn = keyframes`
  to {
    opacity: 1;
    transform: initial;
  }
`;

export const AnimatedImg = styled.img`
  opacity: 0;
  transform: rotate(45deg) scale(0.5);
  animation: ${imgFadeIn} 0.7s forwards;
  width: ${px2vw(200)};
  height: ${px2vw(200)};
`;

export const ModalHeader = styled.div`
  width: 100%;
  background-color: ${branco};
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: ${px2vw(23)} ${px2vw(20)} ${px2vw(10)} ${px2vw(20)};
  font-family: AvenirNextLTPro;
  font-weight: bold;
  font-size: ${px2vw(20)};
  display: flex;
  align-items: space-between;
  justify-content: space-between;
  color: ${preto};
`;

export const ProgressBar = styled.div`
  background-color: ${cinzaKuppi11};
  width: 100%;
`;

export const CloseButton = styled.button`
  width: ${px2vw(18)};
  height: ${px2vw(18)};
  border: none;
  z-index: 10;
  background-color: transparent;

  :focus {
    background-color: transparent !important;
  }

  ${(props) =>
    props.absolute &&
    css`
      position: absolute;
      top: ${props.top || px2vw(10)};
      right: ${props.right || px2vw(10)};
    `}
`;

export const CloseImage = styled.img`
  width: ${px2vw(17)};
  height: ${px2vw(17)};
`;

export const Section = styled.section`
  width: 100%;
  height: ${(props) => (props.h ? props.h : '100%')};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: ${px2vw(20)};
  ${(props) => props.mt && `margin-top: ${props.mt};`}
  ${(props) => props.mb && `margin-bottom: ${props.mb};`}
  ${(props) => props.spaceBetween && `justify-content: space-between;`}
`;

export const ButtonPrimary = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${branco};
  border: none;
  border-radius: ${px2vw(10)};
  font-size: ${px2vw(18)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.03em;
  padding: ${px2vw(8)};
  box-shadow: 2px 2px 3px ${cinzaKuppi2};
  width: ${(props) => (props.width ? props.width : px2vw(320))};
  height: ${px2vw(40)};

  ${(props) =>
    props.complete
      ? css`
          animation: ${colorFadeIn} 0.7s forwards;
        `
      : `background-color: ${verdeKuppi2};`}

  margin-top: ${(props) => (props.margtop ? props.margtop : 0)};
  margin-right: ${(props) => (props.margright ? props.margright : 0)};

  &:active {
    box-shadow: 2px 2px 3px ${cinzaKuppi2};
    background: ${verdeKuppi1};
  }

  ${(props) =>
    props.disabled &&
    `
  background-color: ${cinzaKuppi4};
  color:${branco};

  &:active {
    box-shadow: 2px 2px 3px ${cinzaKuppi2};
    background: ${cinzaKuppi4};
  }

  `}
`;

export const PixCodeImg = styled.img`
  margin: ${px2vw(25)} auto;
  width: ${px2vw(200)};
  height: ${px2vw(200)};
`;

export const PixCodeBar = styled.div`
  width: 89%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  align-items: center;
  font-style: normal;
  font-weight: 700;
  font-size: ${px2vw(12)};
  line-height: ${px2vw(19)};
  text-align: center;
  letter-spacing: 0.005em;
  position: relative;
  vertical-align: middle;
`;
