import styled, { css } from 'styled-components';

import {
  branco,
  verdeKuppi1,
  verdeKuppi4,
  cinzaKuppi4,
  cinzaKuppi6,
  cinzaKuppi2,
  verdeKuppi2,
} from '../../../styles/colors';
import { Col } from '../../../styles/grid';

import px2vw from '../../../utils/responsiveness/px2vw';

export const ModalBackground = styled.div`
  display: none;
  position: fixed;
  z-index: 20;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: ${cinzaKuppi6}99;

  ${(props) =>
    props.isVisible &&
    `
    display: flex;
    justify-content: center;
    align-items: center;
  `};
`;

export const ModalContent = styled.div`
  background-color: ${branco};
  padding: ${(props) => props.padding || '20px'};
  border-radius: 20px;

  ${(props) => `
    width: ${props.width};
    min-height: ${props.height};
    max-height: ${props.maxheight};
    background-color: ${props.bgcolor};
    margin-top: ${props.margtop};
  `};
`;

export const ModalTitleText = styled.div`
  font-size: ${px2vw(18)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.48px;
  text-align: center;

  ${(props) => `
    padding-left: ${props.paddingLR};
    padding-right: ${props.paddingLR};
    margin-top: ${props.margtop};
    font-size: ${props.fontsize};
  `};
`;

export const ModalActions = styled.div`
  display: flex;
  flex-direction: row;

  ${(props) => `
    padding: ${props.padding};
    width: ${props.width};
    justify-content: ${props.justifyContent};
    margin-top: ${props.margtop};
  `};
`;

export const ChangeStatusButton = styled.div`
  background-color: ${verdeKuppi1};
  border: 1px solid ${verdeKuppi1};
  color: ${branco};
  border-radius: ${px2vw(10)};
  height: ${px2vw(40)};
  width: ${px2vw(200)};
  font-size: ${px2vw(13)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.36px;
  text-align: center;
  padding-top: ${px2vw(10)};
  cursor: pointer;

  :hover {
    background-color: ${verdeKuppi4};
    border: 1px solid ${verdeKuppi1};
  }

  &:focus,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
    color: ${branco};
  }
`;

export const ModalPrimaryButton = styled.button`
  background-color: ${verdeKuppi1};
  width: ${px2vw(2180)};
  height: ${px2vw(40)};
  border: 1px solid ${(props) => props.borderColor || verdeKuppi1};
  border-radius: ${px2vw(10)};
  padding: 0.3em;

  :focus {
    background-color: ${verdeKuppi1} !important;
  }

  :hover {
    background-color: ${verdeKuppi4};
    border: 1px solid ${verdeKuppi1};
  }

  ${(props) => `
    margin-top: ${props.margtop};
    margin-bottom: ${props.margbottom};
    width: ${props.width};
    margin-left: ${props.margleft};
    margin-right: ${props.marginRight};
  `};

  ${(props) =>
    props.disabled &&
    `
    background-color: ${cinzaKuppi4};
    border: 1px solid ${cinzaKuppi4};
  `}

  ${(props) =>
    props.mobile &&
    `
      width: ${props.width || px2vw(900)};
      background-color: ${props.background};
      color: ${props.color};
      height: ${px2vw(150)};
      border-radius: ${px2vw(30)};
      margin: 0.7rem;
      margin-top: ${props.margtop};

    `}
`;

export const CloseButton = styled.button`
  width: ${px2vw(18)};
  height: ${px2vw(18)};
  border: none;
  z-index: 10;
  background-color: transparent;

  :focus {
    background-color: transparent !important;
  }

  ${(props) =>
    props.absolute &&
    css`
      position: absolute;
      top: ${props.top || px2vw(10)};
      right: ${props.right || px2vw(10)};
    `}

  ${(props) =>
    props.mobile &&
    `
    width: ${px2vw(180)};
    height: ${px2vw(180)};
  `}
`;

export const CloseImage = styled.img`
  width: ${(props) => props.width || px2vw(20)};
  height: ${(props) => props.height || px2vw(20)};
  padding-right: ${(props) => props.margright};

  ${(props) =>
    props.isMobile &&
    `
    width: ${px2vw(70)};
    height: ${px2vw(70)};
  `};
`;

export const ModalHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  ${(props) =>
    props.isMobile &&
    `
    padding-right: ${px2vw(90)};
  `};
`;

export const PhoneImg = styled.img`
  width: 100%;
  max-height: ${px2vw(460)};
  position: abosulte;
  left: 0;
`;

export const MyMessage = styled.img`
  width: ${px2vw(55)};
  position: absolute;
`;

export const ConversationIndicatorImg = styled.img`
  position: absolute;
  left: ${px2vw(-10)};
  top: ${px2vw(0)};
`;
export const MessageContainer = styled(Col)`
  width: 100%;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 0em;
    border-radius: 7px;
  }
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    border-radius: px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: transparent;
    outline: 1px solid transparent;
    border-radius: 7px;
  }

`;

export const TextBox = styled.div`
  background: ${branco};
  font-size: ${px2vw(8)};
  width: auto;
  min-width: ${px2vw(150)};
  max-width: ${px2vw(240)};
  height: fit-content;
  padding: ${px2vw(9)} ${px2vw(10)};
  padding-left: ${px2vw(10)};
  z-index: 5;
  white-space: pre-wrap;
  word-wrap: break-word;
  position: relative;
  border-top-left-radius: ${(props) => (props.first ? 'none' : px2vw(8))};
  border-bottom-left-radius: ${px2vw(8)};
  border-top-right-radius: ${px2vw(8)};
  border-bottom-right-radius: ${px2vw(8)};
  margin-top: ${(props) => props.margtop || 0};
  padding-right: ${(props) => props.padright || 10};
  ${(props) =>
    props.me &&
    `
  background: ${branco};
  `}
`;

export const PixCodeImg = styled.img`
  margin: ${px2vw(25)} auto;
  width: ${px2vw(200)};
  height: ${px2vw(200)};
  position: sticky;
  top: 0;
  ${props => props.isExpired && `
    filter: blur(4px);
  `}
`;

export const PixCodeBar = styled.div`
  width: 96%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  align-items: center;
  font-style: normal;
  font-weight: 700;
  font-size: ${px2vw(12)};
  line-height: ${px2vw(19)};
  text-align: center;
  letter-spacing: 0.005em;
  position: relative;
  vertical-align: middle;
`;

export const ButtonPrimary = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${branco};
  border: none;
  border-radius: ${(props) => props.radius || px2vw(5)};
  font-size: ${(props) => props.size || px2vw(13)};
  font-family: AvenirNextLTPro;
  font-weight: ${(props) => props.fontWeight || '600'};
  letter-spacing: 0.015em;
  padding: ${px2vw(8)};
  width: ${(props) => (props.width ? props.width : px2vw(320))};
  height: ${(props) => (props.height ? props.height : px2vw(44))};

  margin-top: ${(props) => (props.margtop ? props.margtop : 0)};
  margin-right: ${(props) => (props.margright ? props.margright : 0)};
  background: ${verdeKuppi2};

  &:active {
    box-shadow: 0px 1px 1.5px ${cinzaKuppi2};
    background: ${verdeKuppi1};
  }

  ${(props) =>
    props.disabled &&
    `
  background-color: ${cinzaKuppi4};
  color:${branco};

  &:active {
    box-shadow: 2px 2px 3px ${cinzaKuppi2};
    background: ${cinzaKuppi4};
  }

  `}
`;
