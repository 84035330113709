import React, {
  Fragment,
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';

import moment from 'moment';
import { MdQrCodeScanner } from 'react-icons/md';

import px2vw from '../../../utils/responsiveness/px2vw';
import { screenAmountMask } from '../../../utils/masks';

import {
  Header,
  Content,
  Hr,
  TableRow,
  HeaderButton,
  PagButton,
  PrevIcon,
  NextIcon,
  SecundaryButton,
  SecundaryButtonText,
} from './styles';
import { SvgBox, Arrow, Arrow2 } from './icons';
import { sort, makePages } from './tableOptions';

import TablePerPage from '../../../components/TablePerPage';
import { Col, Row } from '../../../styles/grid';

import { ButtonPrimary } from '../styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  cancelPixPaymentRequest,
  setPixPaymentCode,
} from '../../../store/modules/kuppiBoost/actions';
import { Spinner } from 'reactstrap';
import { preto } from '../../../styles/colors';

export default function PaymentTable({ data, cb }) {
  const dispatch = useDispatch();
  const kuppiBoostData = useSelector((state) => state.kuppiBoost);
  const { cancel_payment_loading } = kuppiBoostData;
  const [paymentId, setPaymentId] = useState('');
  const [rows, setRows] = useState(data);
  const [rowsCurrentPage, setRowsCurrentPage] = useState();
  const [structure, setStructure] = useState(null);
  const [currentPage, setCurrentPage] = useState('1');
  const [lastPage, setLastPage] = useState();
  const [firstPage] = useState('1');
  const [sortedCol, setSortedCol] = useState('id');

  useEffect(() => {
    if (structure) {
      setLastPage(
        Object.keys(structure)[Object.keys(structure).length - 1].toString()
      );
    }
  }, [structure]);

  useMemo(() => {
    setStructure(makePages(rows.length, rowsCurrentPage));
  }, [rows, rowsCurrentPage]);

  useEffect(() => {
    setRows(data);
  }, [data]);

  useEffect(() => {
    if (rows.length < 5) {
      setRowsCurrentPage(rows.length);
    }
    if (rowsCurrentPage > 5) {
    } else {
      setRowsCurrentPage(5);
    }
  }, [rows]);

  const handleSort = useCallback(
    (col, inside) => {
      if (sortedCol !== col) {
        setSortedCol(col);
        if (inside) {
          setRows(sort(rows, col, 'asc', true));
        } else {
          setRows(sort(rows, col, 'asc'));
        }
      } else {
        setSortedCol('');
        if (inside) {
          setRows(sort(rows, col, 'desc', true));
        } else {
          setRows(sort(rows, col, 'desc'));
        }
      }
    },
    [rows]
  );

  function handlePerPage(size) {
    setRowsCurrentPage(size);
    setCurrentPage('1');
  }

  const handleGetCampaignStatus = useCallback(
    (status, processorTransactionId) => {
      if (processorTransactionId.includes('_bonus')) {
        switch (processorTransactionId) {
          case 'signature_bonus':
            return 'Pago (Bônus Assinatura)';
          case 'mgm_bonus':
            return 'Pago (Bônus Indicação)';
            case 'challenge_bonus':
            return 'Pago (Bônus Desafio)';
          default:
            return '-'
        }
      } else {
        switch (status) {
          case 'waiting_payment':
            return 'Pendente';
          case 'paid':
            return 'Pago';
          case 'canceled':
            return 'Cancelado';
          default:
            return '-'
        }
      }
    },
    [data]
  );

  function handleChangePage(direction) {
    if (direction === 'next') {
      const curr = +currentPage + 1;
      setCurrentPage(curr.toString());
    } else if (direction === 'prev') {
      const curr = +currentPage - 1;
      setCurrentPage(curr.toString());
    }
  }

  function handleOpenQRCodeModal(pixCode) {
    dispatch(setPixPaymentCode(pixCode));
    cb();
  }
  function handleCancelPixPayment(payment_id) {
    dispatch(cancelPixPaymentRequest(payment_id));
    setPaymentId(payment_id)
  }

  return (
    <Col padding="0 15px" alignCenter justifyCenter>
      <Row justifyStart padding="0 10px 0 10px">
        <Header
          width={px2vw(120)}
          padding={`15px 0 15px ${px2vw(35)}`}
          onClick={() => handleSort('id')}
        >
          <HeaderButton>
            ID
            <SvgBox sorted={sortedCol === 'id'}>
              <Arrow />
              <Arrow2 />
            </SvgBox>
          </HeaderButton>
        </Header>

        <Header width={px2vw(120)} onClick={() => handleSort('created_at')}>
          <HeaderButton>
            Data
            <SvgBox sorted={sortedCol === 'created_at'}>
              <Arrow />
              <Arrow2 />
            </SvgBox>
          </HeaderButton>
        </Header>

        <Header width={px2vw(120)} onClick={() => handleSort('status')}>
          <HeaderButton>
            Status
            <SvgBox sorted={sortedCol === 'status'}>
              <Arrow />
              <Arrow2 />
            </SvgBox>
          </HeaderButton>
        </Header>

        <Header width={px2vw(120)} onClick={() => handleSort('budget')}>
          <HeaderButton column>
            <div>
              Valor
              <SvgBox sorted={sortedCol === 'budget'}>
                <Arrow />
                <Arrow2 />
              </SvgBox>
            </div>
          </HeaderButton>
        </Header>

        <Header width={px2vw(120)} onClick={() => handleSort('processing_fee')}>
          <HeaderButton>
            Taxa
            <SvgBox sorted={sortedCol === 'processing_fee'}>
              <Arrow />
              <Arrow2 />
            </SvgBox>
          </HeaderButton>
        </Header>

        <Header width={px2vw(120)} onClick={() => handleSort('total_amount')}>
          <HeaderButton>
            Valor total pago
            <SvgBox sorted={sortedCol === 'total_amount'}>
              <Arrow />
              <Arrow2 />
            </SvgBox>
          </HeaderButton>
        </Header>
      </Row>
      <Row>
        <Hr />
      </Row>
      {structure ? (
        rows.map((item, index) =>
          structure[currentPage] &&
          structure[currentPage].start <= index + 1 &&
          structure[currentPage].end >= index + 1 ? (
            <Fragment key={item.id}>
              <TableRow alignCenter justifyStart padding="0 10px 0 10px" minheight={px2vw(35)}>
                <Content width={px2vw(120)} padding={`0 0 0 ${px2vw(35)}`}>
                  {item.id}
                </Content>
                <Content width={px2vw(120)}>
                  {moment(item.created_at).format('DD/MM/YYYY')}
                </Content>
                <Content width={px2vw(120)}>
                  {handleGetCampaignStatus(item.status, item.processor_transaction_id)}
                </Content>
                <Content width={px2vw(120)}>
                  {screenAmountMask(item.budget.toFixed(2))}
                </Content>
                <Content width={px2vw(120)}>
                  {item.processor_transaction_id.includes('_bonus') ? (
                    'R$ 0,00'
                  ) : (
                    screenAmountMask(item.processing_fee.toFixed(2))
                  )}
                </Content>
                <Content width={px2vw(120)}>
                  {item.processor_transaction_id.includes('_bonus') ? (
                    screenAmountMask(item.budget.toFixed(2))
                  ) : (
                    screenAmountMask(item.total_amount.toFixed(2))
                  )}
                </Content>
                <Content
                  width="auto"
                  padding={`${px2vw(10)} 0 ${px2vw(8)} ${px2vw(10)}`}
                >
                  {item.status === 'waiting_payment' && (
                    <>
                      <SecundaryButton
                        width={px2vw(140)}
                        onClick={() => handleCancelPixPayment(item.id)}
                        type="button"
                        disabled={cancel_payment_loading}
                      >
                        <SecundaryButtonText size={px2vw(11)}>
                          {cancel_payment_loading && item.id === paymentId ? (
                            <Spinner
                              style={{
                                width: px2vw(10),
                                height: px2vw(10),
                                color: preto,
                              }}
                              className="m-auto position-relative"
                            />
                          ) : (
                            'Cancelar pagamento'
                          )}
                        </SecundaryButtonText>
                      </SecundaryButton>
                      <ButtonPrimary
                        onClick={() => handleOpenQRCodeModal(item.pix_qr_code)}
                        radius={px2vw(5)}
                        width={px2vw(100)}
                        size={px2vw(13)}
                        height={px2vw(35)}
                        className="ml-3"
                      >
                        Qr code
                        <MdQrCodeScanner
                          style={{ fontSize: px2vw(25), marginLeft: px2vw(5) }}
                        />
                      </ButtonPrimary>
                    </>
                  )}
                </Content>
              </TableRow>
              <Row>
                <Hr />
              </Row>
            </Fragment>
          ) : null
        )
      ) : (
        <Row padding="15px 0 10px 0">Nenhum anúncio no momento.</Row>
      )}
      {structure && (
        <Row alignCenter justifyEnd>
          <Row alignCenter justifyEnd height={px2vw(40)} margin="10px">
            <TablePerPage response={handlePerPage} length={rows.length} />
            <Row alignCenter justifyCenter width="10%">
              {!structure[currentPage] && handleChangePage('prev')}
              {structure[currentPage] && structure[currentPage].start} -{' '}
              {structure[currentPage] && structure[currentPage].end} de{' '}
              {rows.length}
            </Row>
            <PagButton
              onClick={() => handleChangePage('prev')}
              disabled={currentPage === firstPage}
              title="Página anterior"
            >
              <PrevIcon disabled={currentPage === firstPage} />
            </PagButton>
            <PagButton
              onClick={() => handleChangePage('next')}
              title="Próxima página"
              disabled={currentPage === lastPage}
            >
              <NextIcon disabled={currentPage === lastPage} />
            </PagButton>
          </Row>
        </Row>
      )}
    </Col>
  );
}
