import styled from 'styled-components';
import px2vw from '../../utils/responsiveness/px2vw';
import { cinzaKuppi5, cinzaKuppi6, cinzaKuppi9 } from '../../styles/colors';

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: ${px2vw(10)};
  h2 {
    font-family: AvenirNextLTPro;
    font-weight: 700;
    font-size: ${px2vw(28)};
    letter-spacing: 0.72px;
  }
`;

export const ChangeImageTitle = styled.h2`
  font-size: ${px2vw(26)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  margin-bottom: ${px2vw(22)};
`;

export const DragImageBox = styled.div`
  width: 100%;
  max-height: ${px2vw(110)};
  border: 1px dashed ${cinzaKuppi5};
  border-radius: ${px2vw(5)};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 0;
  margin-bottom: ${px2vw(10)};
  position: relative;

  span {
    font-size: ${px2vw(12)};
    font-family: AvenirNextLTPro;
    font-weight: 700;
  }
`;

export const UploadImage = styled.img`
  margin-right: ${px2vw(20)};
  width: ${px2vw(25)};
  height: ${px2vw(25)};
`;

export const InputImage = styled.input`
  position: absolute;
  width: 100%;
  padding: 40px 0;
  max-height: ${px2vw(110)};
  opacity: 0;
  z-index: 2;
  cursor: pointer;
`;

export const InputImageGroup = styled.div`
  margin-bottom: ${px2vw(20)};
  h3 {
    font-family: AvenirNextLTPro;
    font-weight: 700;
    font-size: ${px2vw(18)};
  }
`;

export const SelectImageLabel = styled.label`
  cursor: pointer;
  text-align: left;
  font-size: ${px2vw(13)};
  padding: ${px2vw(5)} ${px2vw(10)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  border-radius: ${px2vw(8)};
  border: 2px solid ${cinzaKuppi6};
`;

export const ImageLogoPreview = styled.img`
  width: ${px2vw(70)};
  height: ${px2vw(70)};
  border: solid 1px ${cinzaKuppi9};
  border-radius: ${px2vw(35)};
`;

export const ImageCoverPreview = styled.img`
  width: 100%;
  max-height: ${px2vw(110)};
  object-fit: cover;
  border-radius: ${px2vw(8)};
`;
