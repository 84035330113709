import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import moment from 'moment';

import {
  ProductModal,
  ModalTitleText,
  Separator,
  TabsBox,
  TabBox,
  Tab,
  TabSelected,
  FormProduct,
} from '../styles';

import Modal from '../../../components/Modal';
import {
  ModalHeader,
  CloseButton,
  CloseImage,
} from '../../../components/Modal/styles';

import px2vw from '../../../utils/responsiveness/px2vw';
import { amountMask, timeMask, dateMask } from '../../../utils/masks';
import {
  isDateValid,
  isTimeValid,
} from '../../../utils/validations/validations';
import { onlyNumbersAmount } from '../../../utils/utils';

import {
  createNewProductRequest,
  updateProductRequest,
} from '../../../store/modules/productCategories/actions';

import TabDetails from './TabDetails';
import TabComplements from './TabComplements';
import TabDisponibility from './TabDisponibility';
import TabCoupons from './TabCoupons';

export default function CatalogCategoryModal({ handler }) {
  const productCategories = useSelector(
    (state) => state.productCategories.productCategories
  );
  const [categories, setCategories] = useState([]);
  const [productNameRemainingChars, setProductNameRemainingChars] = useState(0);
  const [
    productDescriptionRemainingChars,
    setProductDescriptionRemainingChars,
  ] = useState(0);
  const [productImageURL, setProductImageURL] = useState(null);
  const [productNameInputValue, setProductNameInputValue] = useState('');
  const [productDescriptionInputValue, setProductDescriptionInputValue] =
    useState('');
  const [productAmountInputValue, setProductAmountInputValue] = useState('');
  const [productAmountInputOldValue, setProductAmountInputOldValue] =
    useState('');
  const [fileProductData, setFileProductData] = useState({
    base64: null,
    name: '',
    size: 0,
    type: '',
    subtype: '',
    width: null,
    height: null,
  });
  const [currentTab, setCurrentTab] = useState('tabDetails');
  const [productSunday, setProductSunday] = useState(false);
  const [productMonday, setProductMonday] = useState(false);
  const [productTuesday, setProductTuesday] = useState(false);
  const [productWednesday, setProductWednesday] = useState(false);
  const [productThursday, setProductThursday] = useState(false);
  const [productFriday, setProductFriday] = useState(false);
  const [productSaturday, setProductSaturday] = useState(false);
  const [allDisponibilitiesChecked, setAllDisponibilitiesChecked] =
    useState(false);
  const [productSunSelectAvailability, setProductSunSelectAvailability] =
    useState('1');
  const [productMonSelectAvailability, setProductMonSelectAvailability] =
    useState('1');
  const [productTueSelectAvailability, setProductTueSelectAvailability] =
    useState('1');
  const [productWedSelectAvailability, setProductWedSelectAvailability] =
    useState('1');
  const [productThuSelectAvailability, setProductThuSelectAvailability] =
    useState('1');
  const [productFriSelectAvailability, setProductFriSelectAvailability] =
    useState('1');
  const [productSatSelectAvailability, setProductSatSelectAvailability] =
    useState('1');
  const [specificHourSunday, setSpecificHourSunday] = useState(false);
  const [productSunStartTimeInputValue, setProductSunStartTimeInputValue] =
    useState('');
  const [productSunEndTimeInputValue, setProductSunEndTimeInputValue] =
    useState('');
  const [specificHourMonday, setSpecificHourMonday] = useState(false);
  const [productMonStartTimeInputValue, setProductMonStartTimeInputValue] =
    useState('');
  const [productMonEndTimeInputValue, setProductMonEndTimeInputValue] =
    useState('');
  const [specificHourTuesday, setSpecificHourTuesday] = useState(false);
  const [productTueStartTimeInputValue, setProductTueStartTimeInputValue] =
    useState('');
  const [productTueEndTimeInputValue, setProductTueEndTimeInputValue] =
    useState('');
  const [specificHourWednesday, setSpecificHourWednesday] = useState(false);
  const [productWedStartTimeInputValue, setProductWedStartTimeInputValue] =
    useState('');
  const [productWedEndTimeInputValue, setProductWedEndTimeInputValue] =
    useState('');
  const [specificHourThursday, setSpecificHourThursday] = useState(false);
  const [productThuStartTimeInputValue, setProductThuStartTimeInputValue] =
    useState('');
  const [productThuEndTimeInputValue, setProductThuEndTimeInputValue] =
    useState('');
  const [specificHourFriday, setSpecificHourFriday] = useState(false);
  const [productFriStartTimeInputValue, setProductFriStartTimeInputValue] =
    useState('');
  const [productFriEndTimeInputValue, setProductFriEndTimeInputValue] =
    useState('');
  const [specificHourSaturday, setSpecificHourSaturday] = useState(false);
  const [productSatStartTimeInputValue, setProductSatStartTimeInputValue] =
    useState('');
  const [productSatEndTimeInputValue, setProductSatEndTimeInputValue] =
    useState('');
  const [activeCoupon, setActiveCoupon] = useState(false);
  const [flashCoupon, setFlashCoupon] = useState(false);
  const [
    productPromotionalAmountInputValue,
    setProductPromotionalAmountInputValue,
  ] = useState('');
  const [
    productPromotionalValidityDateInputValue,
    setProductPromotionalValidityDateInputValue,
  ] = useState('');
  const [
    productPromotionalValidityTimeInputValue,
    setProductPromotionalValidityTimeInputValue,
  ] = useState('');
  const [
    productPromotionalUseLimitInputValue,
    setProductPromotionalUseLimitInputValue,
  ] = useState('');
  let productCouponShortDisplayName = null;
  let productCouponDisplayName = null;
  let productCouponDescription = null;
  const productModalLoading = useSelector(
    (state) => state.productCategories.productModalLoading
  );
  const success = useSelector((state) => state.productCategories.success);
  const productModalSuccess = useSelector(
    (state) => state.productCategories.productModalSuccess
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (handler.handleProductTypeModal === 'edit') {
      const product = handler.editProductData;
      setProductImageURL(
        product.productImage ? product.productImage.url : null
      );
      setProductNameInputValue(product.name);
      setProductNameRemainingChars(product.name.length);
      handler.setProductCategoryInputValue(product.product_category_id);
      if (product.description) {
        setProductDescriptionInputValue(product.description);
        setProductDescriptionRemainingChars(product.description.length);
      }
      setProductAmountInputValue(amountMask(product.amount.toFixed(2)));
      setCurrentTab('tabDetails');
      setProductSunday(false);
      setProductMonday(false);
      setProductTuesday(false);
      setProductWednesday(false);
      setProductThursday(false);
      setProductFriday(false);
      setProductSaturday(false);

      if (product.productAvailability !== null) {
        if (product.productAvailability.sunday.is_active) {
          setProductSunday(true);
          if (
            product.productAvailability.sunday.horaries[0].start_at !==
              '00:00' ||
            product.productAvailability.sunday.horaries[0].end_at !== '00:00'
          ) {
            handleHourDisponibility('2', 'sunday');
            setProductSunStartTimeInputValue(
              product.productAvailability.sunday.horaries[0].start_at
            );
            setProductSunEndTimeInputValue(
              product.productAvailability.sunday.horaries[0].end_at
            );
          }
        }
        if (product.productAvailability.monday.is_active) {
          setProductMonday(true);
          if (
            product.productAvailability.monday.horaries[0].start_at !==
              '00:00' ||
            product.productAvailability.monday.horaries[0].end_at !== '00:00'
          ) {
            handleHourDisponibility('2', 'monday');
            setProductMonStartTimeInputValue(
              product.productAvailability.monday.horaries[0].start_at
            );
            setProductMonEndTimeInputValue(
              product.productAvailability.monday.horaries[0].end_at
            );
          }
        }
        if (product.productAvailability.tuesday.is_active) {
          setProductTuesday(true);
          if (
            product.productAvailability.tuesday.horaries[0].start_at !==
              '00:00' ||
            product.productAvailability.tuesday.horaries[0].end_at !== '00:00'
          ) {
            handleHourDisponibility('2', 'tuesday');
            setProductTueStartTimeInputValue(
              product.productAvailability.tuesday.horaries[0].start_at
            );
            setProductTueEndTimeInputValue(
              product.productAvailability.tuesday.horaries[0].end_at
            );
          }
        }
        if (product.productAvailability.wednesday.is_active) {
          setProductWednesday(true);
          if (
            product.productAvailability.wednesday.horaries[0].start_at !==
              '00:00' ||
            product.productAvailability.wednesday.horaries[0].end_at !== '00:00'
          ) {
            handleHourDisponibility('2', 'wednesday');
            setProductWedStartTimeInputValue(
              product.productAvailability.wednesday.horaries[0].start_at
            );
            setProductWedEndTimeInputValue(
              product.productAvailability.wednesday.horaries[0].end_at
            );
          }
        }
        if (product.productAvailability.thursday.is_active) {
          setProductThursday(true);
          if (
            product.productAvailability.thursday.horaries[0].start_at !==
              '00:00' ||
            product.productAvailability.thursday.horaries[0].end_at !== '00:00'
          ) {
            handleHourDisponibility('2', 'thursday');
            setProductThuStartTimeInputValue(
              product.productAvailability.thursday.horaries[0].start_at
            );
            setProductThuEndTimeInputValue(
              product.productAvailability.thursday.horaries[0].end_at
            );
          }
        }
        if (product.productAvailability.friday.is_active) {
          setProductFriday(true);
          if (
            product.productAvailability.friday.horaries[0].start_at !==
              '00:00' ||
            product.productAvailability.friday.horaries[0].end_at !== '00:00'
          ) {
            handleHourDisponibility('2', 'friday');
            setProductFriStartTimeInputValue(
              product.productAvailability.friday.horaries[0].start_at
            );
            setProductFriEndTimeInputValue(
              product.productAvailability.friday.horaries[0].end_at
            );
          }
        }
        if (product.productAvailability.saturday.is_active) {
          setProductSaturday(true);
          if (
            product.productAvailability.saturday.horaries[0].start_at !==
              '00:00' ||
            product.productAvailability.saturday.horaries[0].end_at !== '00:00'
          ) {
            handleHourDisponibility('2', 'saturday');
            setProductSatStartTimeInputValue(
              product.productAvailability.saturday.horaries[0].start_at
            );
            setProductSatEndTimeInputValue(
              product.productAvailability.saturday.horaries[0].end_at
            );
          }
        }
      }

      setActiveCoupon(false);
      setFlashCoupon(false);
      setProductPromotionalAmountInputValue('');
      setProductPromotionalValidityDateInputValue('');
      setProductPromotionalValidityTimeInputValue('');
      setProductPromotionalUseLimitInputValue('');

      if (product.coupon !== null) {
        setActiveCoupon(product.coupon.is_active);
        setProductPromotionalAmountInputValue(
          amountMask(product.coupon.benefits.by_value.toFixed(2))
        );

        if (product.coupon.mode === 'flash' && product.coupon.is_active) {
          setFlashCoupon(true);
        }
        setProductPromotionalUseLimitInputValue(product.coupon.usage_limit);

        if (product.coupon.validity_at !== null) {
          const date = moment(
            product.coupon.validity_at,
            'YYYY-MM-DD HH:mm:ss',
            true
          ).format('DD/MM/YYYY');
          const hour = moment(
            product.coupon.validity_at,
            'YYYY-MM-DD HH:mm:ss',
            true
          ).format('HH:mm');

          setProductPromotionalValidityDateInputValue(dateMask(date));
          setProductPromotionalValidityTimeInputValue(timeMask(hour));
        }
      }

      if (product.productComplementCategories.length > 0) {
        product.productComplementCategories.map((category, idx) => {
          handler.complementsArray.push({
            id: category.id,
            name: category.name,
            is_active: category.is_active,
            is_required: category.is_required,
            is_base_amount_more_expensive:
              category.is_base_amount_more_expensive,
            min_qty: category.min_qty,
            max_qty: category.max_qty,
            ordenation_idx: category.ordenation_index,
            complements: [],
          });

          category.productComplementOptions.map((complement) =>
            handler.complementsArray[idx].complements.push({
              id: complement.id,
              name: complement.name,
              description: complement.description,
              amount: complement.amount
                ? onlyNumbersAmount(amountMask(complement.amount.toFixed(2)))
                : '',
              is_active: complement.is_active,
              ordenation_idx: complement.ordenation_index,
            })
          );

          return true;
        });

        handler.setComplementsArray([...handler.complementsArray]);
        handler.setComplementActionHidden(false);
      }
    }
  }, [handler.editProductData, handler.handleCategoryTypeModal]);

  // Loads the categories to the select on new or edit product
  useEffect(() => {
    if (success) {
      categories.length = 0;
      productCategories.map((category) =>
        categories.push({ id: category.id, title: category.name })
      );
    }
  }, [success]);

  // Get the new catalog when update or create an product
  useEffect(() => {
    if (productModalSuccess) {
      handleCloseProductModal();
    }
  }, [productModalSuccess]);

  // Handle clean all files data
  const handleClearFileData = () => {
    setFileProductData({
      base64: null,
      name: '',
      size: 0,
      type: '',
      subtype: '',
      width: null,
      height: null,
    });
  };

  // Set the hour availability of product
  function handleHourDisponibility(selectedOption, dayOfWeek) {
    let specificHour = false;
    if (selectedOption === '2') {
      specificHour = true;
    }

    if (dayOfWeek === 'sunday') {
      setSpecificHourSunday(specificHour);
      setProductSunSelectAvailability(specificHour ? '2' : '1');
      setProductSunStartTimeInputValue('');
      setProductSunEndTimeInputValue('');
    } else if (dayOfWeek === 'monday') {
      setSpecificHourMonday(specificHour);
      setProductMonSelectAvailability(specificHour ? '2' : '1');
      setProductMonStartTimeInputValue('');
      setProductMonEndTimeInputValue('');
    } else if (dayOfWeek === 'tuesday') {
      setSpecificHourTuesday(specificHour);
      setProductTueSelectAvailability(specificHour ? '2' : '1');
      setProductTueStartTimeInputValue('');
      setProductTueEndTimeInputValue('');
    } else if (dayOfWeek === 'wednesday') {
      setSpecificHourWednesday(specificHour);
      setProductWedSelectAvailability(specificHour ? '2' : '1');
      setProductWedStartTimeInputValue('');
      setProductWedEndTimeInputValue('');
    } else if (dayOfWeek === 'thursday') {
      setSpecificHourThursday(specificHour);
      setProductThuSelectAvailability(specificHour ? '2' : '1');
      setProductThuStartTimeInputValue('');
      setProductThuEndTimeInputValue('');
    } else if (dayOfWeek === 'friday') {
      setSpecificHourFriday(specificHour);
      setProductFriSelectAvailability(specificHour ? '2' : '1');
      setProductFriStartTimeInputValue('');
      setProductFriEndTimeInputValue('');
    } else if (dayOfWeek === 'saturday') {
      setSpecificHourSaturday(specificHour);
      setProductSatSelectAvailability(specificHour ? '2' : '1');
      setProductSatStartTimeInputValue('');
      setProductSatEndTimeInputValue('');
    }
  }

  // Handle close new/edit product modal
  const handleCloseProductModal = () => {
    handleClearFileData();
    setProductNameInputValue('');
    handler.setProductCategoryInputValue('');
    setProductDescriptionInputValue('');
    setProductAmountInputValue('');
    setCurrentTab('tabDetails');
    setProductSunday(false);
    setProductMonday(false);
    setProductTuesday(false);
    setProductWednesday(false);
    setProductThursday(false);
    setProductFriday(false);
    setProductSaturday(false);
    setSpecificHourSunday(false);
    setProductSunStartTimeInputValue('');
    setProductSunEndTimeInputValue('');
    setSpecificHourMonday(false);
    setProductMonStartTimeInputValue('');
    setProductMonEndTimeInputValue('');
    setSpecificHourTuesday(false);
    setProductTueStartTimeInputValue('');
    setProductTueEndTimeInputValue('');
    setSpecificHourWednesday(false);
    setProductWedStartTimeInputValue('');
    setProductWedEndTimeInputValue('');
    setSpecificHourThursday(false);
    setProductThuStartTimeInputValue('');
    setProductThuEndTimeInputValue('');
    setSpecificHourFriday(false);
    setProductFriStartTimeInputValue('');
    setProductFriEndTimeInputValue('');
    setSpecificHourSaturday(false);
    setProductSatStartTimeInputValue('');
    setProductSatEndTimeInputValue('');
    setActiveCoupon(false);
    setFlashCoupon(false);
    setProductPromotionalAmountInputValue('');
    setProductPromotionalValidityDateInputValue('');
    setProductPromotionalValidityTimeInputValue('');
    setProductPromotionalUseLimitInputValue('');
    handler.setComplementsArray([]);
    handler.setDeletedComplementsCategoriesArray([]);
    handler.setDeletedComplementsArray([]);
    handler.setComplementActionHidden(true);
    handler.handleCloseProductModal();
  };

  // Validate if the coupom tab were successfully filled
  function validateProductCoupon() {
    // Validate if the new discount coupon tab were successfully filled
    if (activeCoupon) {
      if (productPromotionalAmountInputValue === '') {
        toast.error(
          'Foi ativado cupom de desconto, porém não foi informado o novo valor promocional.',
          {
            autoClose: 6000,
          }
        );
        return false;
      }
      if (productPromotionalAmountInputValue.length < 7) {
        toast.error('Valor promocional inválido.', {
          autoClose: 6000,
        });
        return false;
      }

      const amount = (1 * onlyNumbersAmount(productAmountInputValue)).toFixed(
        2
      );
      const promotionalAmount = (
        1 * onlyNumbersAmount(productPromotionalAmountInputValue)
      ).toFixed(2);

      productCouponShortDisplayName = `De R$${Math.trunc(
        parseFloat(amount)
      )} por R$${Math.trunc(parseFloat(promotionalAmount))}`;
      productCouponDisplayName = `De R$${amount} por R$${promotionalAmount}`;
      productCouponDescription = productNameInputValue;

      if (parseFloat(promotionalAmount) >= parseFloat(amount)) {
        toast.error('Valor promocional deve ser menor que o valor original.', {
          autoClose: 6000,
        });
        return false;
      }

      if (flashCoupon) {
        if (productPromotionalValidityDateInputValue === '') {
          toast.error('Data de validade é obrigatória para cupom relâmpago.', {
            autoClose: 6000,
          });

          return false;
        }
        if (productPromotionalValidityTimeInputValue === '') {
          toast.error('Hora de término é obrigatória para cupom relâmpago.', {
            autoClose: 6000,
          });

          return false;
        }
        if (
          !isDateValid(
            productPromotionalValidityDateInputValue,
            productPromotionalValidityTimeInputValue
          )
        ) {
          toast.error('Data/Hora de validade inválida.', {
            autoClose: 6000,
          });

          return false;
        }
        if (
          !productPromotionalUseLimitInputValue ||
          productPromotionalUseLimitInputValue === '' ||
          parseInt(productPromotionalUseLimitInputValue) === 0
        ) {
          toast.error('Limite de uso é obrigatório para cupom relâmpago.', {
            autoClose: 6000,
          });

          return false;
        }
      }

      return true;
    }
    return true;
  }

  // Handle form submit for new/edit product
  const handleProductSubmit = () => {
    if (validateProductCoupon()) {
      if (handler.handleProductTypeModal === 'new') {
        dispatch(
          createNewProductRequest(
            fileProductData,
            productNameInputValue,
            handler.productCategoryInputValue,
            productDescriptionInputValue,
            onlyNumbersAmount(productAmountInputValue),
            productSunday,
            productSunStartTimeInputValue,
            productSunEndTimeInputValue,
            productMonday,
            productMonStartTimeInputValue,
            productMonEndTimeInputValue,
            productTuesday,
            productTueStartTimeInputValue,
            productTueEndTimeInputValue,
            productWednesday,
            productWedStartTimeInputValue,
            productWedEndTimeInputValue,
            productThursday,
            productThuStartTimeInputValue,
            productThuEndTimeInputValue,
            productFriday,
            productFriStartTimeInputValue,
            productFriEndTimeInputValue,
            productSaturday,
            productSatStartTimeInputValue,
            productSatEndTimeInputValue,
            activeCoupon,
            productCouponShortDisplayName,
            productCouponDisplayName,
            productCouponDescription,
            onlyNumbersAmount(productPromotionalAmountInputValue),
            flashCoupon,
            productPromotionalValidityDateInputValue,
            productPromotionalValidityTimeInputValue,
            productPromotionalUseLimitInputValue,
            handler.complementsArray
          )
        );
      } else {
        dispatch(
          updateProductRequest(
            fileProductData,
            handler.editProductData.id,
            productNameInputValue,
            handler.productCategoryInputValue,
            productDescriptionInputValue,
            onlyNumbersAmount(productAmountInputValue),
            productSunday,
            productSunStartTimeInputValue,
            productSunEndTimeInputValue,
            productMonday,
            productMonStartTimeInputValue,
            productMonEndTimeInputValue,
            productTuesday,
            productTueStartTimeInputValue,
            productTueEndTimeInputValue,
            productWednesday,
            productWedStartTimeInputValue,
            productWedEndTimeInputValue,
            productThursday,
            productThuStartTimeInputValue,
            productThuEndTimeInputValue,
            productFriday,
            productFriStartTimeInputValue,
            productFriEndTimeInputValue,
            productSaturday,
            productSatStartTimeInputValue,
            productSatEndTimeInputValue,
            activeCoupon,
            productCouponShortDisplayName,
            productCouponDisplayName,
            productCouponDescription,
            onlyNumbersAmount(productPromotionalAmountInputValue),
            flashCoupon,
            productPromotionalValidityDateInputValue,
            productPromotionalValidityTimeInputValue,
            productPromotionalUseLimitInputValue,
            handler.complementsArray,
            handler.deletedComplementsCategoriesArray,
            handler.deletedComplementsArray
          )
        );
      }
    }
  };

  // Validate if the details tab were successfully filled
  function validateProductDetails(tab) {
    if (
      productNameInputValue === '' ||
      productAmountInputValue === '' ||
      handler.productCategoryInputValue === ''
    ) {
      toast.error('Os campos: Nome, Preço e Categoria são obrigatórios', {
        autoClose: 6000,
      });
      return false;
    } else if (productNameInputValue.length > 100) {
      toast.error('Nome do produto deve ter no máximo 100 caracteres.', {
        autoClose: 6000,
      });
      return false;
    } else if (productDescriptionInputValue.length > 1000) {
      toast.error('Descrição do produto deve ter no máximo 1000 caracteres.', {
        autoClose: 6000,
      });
      return false;
    } else if (productAmountInputValue.length < 7) {
      toast.error(
        'Preço inválido. O preço deve conter 2 casas decimais, exemplo: R$ 1,90',
        {
          autoClose: 6000,
        }
      );
      return false;
    } else {
      if (handler.handleProductTypeModal === 'edit') {
        setCurrentTab(tab);
      } else {
        setCurrentTab('tabComplements');
      }
    }
  }

  // Validate if the complements tab were successfully filled
  function validateProductComplements(tab) {
    if (handler.complementsArray.length > 0) {
      let goForward = true;

      const emptyCategoryName = handler.complementsArray.find(
        (complementCategory) => complementCategory.name === ''
      );

      const emptyMinQtyValue = handler.complementsArray.find(
        (complementCategory) => complementCategory.min_qty === ''
      );

      const emptyMaxQtyValue = handler.complementsArray.find(
        (complementCategory) => complementCategory.max_qty === ''
      );

      if (emptyCategoryName) {
        toast.error(
          'Alguma categoria de complemento não teve todos os dados obrigatórios preenchidos. Revise-os e tente novamente.',
          {
            autoClose: 6000,
          }
        );

        goForward = false;
      } else if (emptyMinQtyValue) {
        toast.error(
          'Alguma categoria de complemento teve a quantidade mínima não informada, quando não se quer informar quantidade mínima, deve-se colocar 0.',
          {
            autoClose: 6000,
          }
        );

        goForward = false;
      } else if (emptyMaxQtyValue) {
        toast.error(
          'Alguma categoria de complemento teve a quantidade máxima não informada, a quantidade máxima deve ser maior ou igual a 1.',
          {
            autoClose: 6000,
          }
        );

        goForward = false;
      } else {
        handler.complementsArray.forEach((category) => {
          if (category.complements.length > 0) {
            const emptyOptionName = category.complements.find(
              (complementOption) => complementOption.name === ''
            );

            const invalidOptionName = category.complements.find(
              (complementOption) => complementOption.name.length > 40
            );

            const invalidOptionDescription = category.complements.find(
              (complementOption) =>
                complementOption.description &&
                complementOption.description.length > 240
            );

            const invalidOptionValueZero = category.complements.find(
              (complementOption) =>
                parseFloat(complementOption.amount) === parseFloat(0.0)
            );

            const invalidOptionValue = category.complements.find(
              (complementOption) =>
                complementOption.amount !== '' &&
                complementOption.amount.length < 4
            );

            const invalidComplementAmountWithMoreExpensiveFlag =
              category.complements.find(
                (complementOption) =>
                  category.is_base_amount_more_expensive &&
                  (complementOption.amount === '' ||
                    parseFloat(complementOption.amount) === parseFloat(0.0))
              );

            if (emptyOptionName) {
              toast.error(
                'Algum complemento não teve o nome informado, que é obrigatório. Revise-os e tente novamente.',
                {
                  autoClose: 6000,
                }
              );

              goForward = false;
            } else if (invalidOptionName) {
              toast.error(
                'Algum complemento está com nome maior que o permitido, que são 40 caracteres. Revise-os e tente novamente.',
                {
                  autoClose: 6000,
                }
              );

              goForward = false;
            } else if (invalidOptionDescription) {
              toast.error(
                'Algum complemento está com a descrição maior do que o permitido, que são 240 caracteres. Revise-os e tente novamente.',
                {
                  autoClose: 6000,
                }
              );

              goForward = false;
            } else if (invalidComplementAmountWithMoreExpensiveFlag) {
              toast.error(
                'Alguma categoria de complemento foi marcada para o valor ser definido pelo complemento mais caro, porém algum complemento dessa categoria está sem valor ou com valor zero. Revise-os e tente novamente.',
                {
                  autoClose: 6000,
                }
              );

              goForward = false;
            } else if (invalidOptionValueZero) {
              toast.error(
                'Algum complemento está com valor zero. Para que um complemento não tenha valor, deixe o campo em branco. Revise-os e tente novamente.',
                {
                  autoClose: 6000,
                }
              );

              goForward = false;
            } else if (invalidOptionValue) {
              toast.error(
                'Algum complemento está com valor inválido. Revise-os e tente novamente.',
                {
                  autoClose: 6000,
                }
              );

              goForward = false;
            }
          } else {
            toast.error(
              'Não foi incluído nenhum complemento na categoria criada. Remova a categoria ou inclua complementos nela.',
              {
                autoClose: 6000,
              }
            );

            goForward = false;
          }

          return true;
        });
      }

      if (goForward) {
        setCurrentTab(tab);
      }
    } else {
      if (handler.handleProductTypeModal === 'edit') {
        setCurrentTab(tab);
      } else {
        setCurrentTab('tabDisponibility');
      }
    }
  }

  // Validate if the availability tab were successfully filled
  function validateProductAvailability(tab) {
    if (
      !productSunday &&
      !productMonday &&
      !productTuesday &&
      !productWednesday &&
      !productThursday &&
      !productFriday &&
      !productSaturday
    ) {
      toast.error(
        'Deve ser selecionado ao menos um dia para o produto estar disponível.',
        {
          autoClose: 6000,
        }
      );
    } else if (
      (specificHourSunday &&
        (productSunStartTimeInputValue === '' ||
          productSunEndTimeInputValue === '' ||
          !isTimeValid(productSunStartTimeInputValue) ||
          !isTimeValid(productSunEndTimeInputValue) ||
          productSunStartTimeInputValue >= productSunEndTimeInputValue)) ||
      (specificHourMonday &&
        (productMonStartTimeInputValue === '' ||
          productMonEndTimeInputValue === '' ||
          !isTimeValid(productMonStartTimeInputValue) ||
          !isTimeValid(productMonEndTimeInputValue) ||
          productMonStartTimeInputValue >= productMonEndTimeInputValue)) ||
      (specificHourTuesday &&
        (productTueStartTimeInputValue === '' ||
          productTueEndTimeInputValue === '' ||
          !isTimeValid(productTueStartTimeInputValue) ||
          !isTimeValid(productTueEndTimeInputValue) ||
          productTueStartTimeInputValue >= productTueEndTimeInputValue)) ||
      (specificHourWednesday &&
        (productWedStartTimeInputValue === '' ||
          productWedEndTimeInputValue === '' ||
          !isTimeValid(productWedStartTimeInputValue) ||
          !isTimeValid(productWedEndTimeInputValue) ||
          productWedStartTimeInputValue >= productWedEndTimeInputValue)) ||
      (specificHourThursday &&
        (productThuStartTimeInputValue === '' ||
          productThuEndTimeInputValue === '' ||
          !isTimeValid(productThuStartTimeInputValue) ||
          !isTimeValid(productThuEndTimeInputValue) ||
          productThuStartTimeInputValue >= productThuEndTimeInputValue)) ||
      (specificHourFriday &&
        (productFriStartTimeInputValue === '' ||
          productFriEndTimeInputValue === '' ||
          !isTimeValid(productFriStartTimeInputValue) ||
          !isTimeValid(productFriEndTimeInputValue) ||
          productFriStartTimeInputValue >= productFriEndTimeInputValue)) ||
      (specificHourSaturday &&
        (productSatStartTimeInputValue === '' ||
          productSatEndTimeInputValue === '' ||
          !isTimeValid(productSatStartTimeInputValue) ||
          !isTimeValid(productSatEndTimeInputValue) ||
          productSatStartTimeInputValue >= productSatEndTimeInputValue))
    ) {
      toast.error(
        'Algum horário foi marcado como horário especifico, porém não foi informado o horário corretamente. Revise os campos.',
        {
          autoClose: 6000,
        }
      );
    } else {
      if (handler.handleProductTypeModal === 'edit') {
        setCurrentTab(tab);
      } else {
        setCurrentTab('tabCoupons');
      }
    }
  }

  // Validate if user can change the tab when editing a product
  function validateChangeOfTabInEditModal(currTab, nextTab) {
    if (currTab === 'tabDetails') {
      validateProductDetails(nextTab);
    } else if (currTab === 'tabComplements') {
      validateProductComplements(nextTab);
    } else if (currTab === 'tabDisponibility') {
      validateProductAvailability(nextTab);
    } else if (currTab === 'tabCoupons') {
      if (validateProductCoupon()) {
        setCurrentTab(nextTab);
      }
    }
  }

  const tabDetailHandler = {
    ...handler,
    fileProductData: fileProductData,
    categories: categories,
    productNameInputValue: productNameInputValue,
    productNameRemainingChars: productNameRemainingChars,
    productAmountInputValue: productAmountInputValue,
    productDescriptionInputValue: productDescriptionInputValue,
    productDescriptionRemainingChars: productDescriptionRemainingChars,
    productImageURL: productImageURL,
    setFileProductData: (val) => setFileProductData(val),
    setProductImageURL: (val) => setProductImageURL(val),
    setProductNameInputValue: (val) => setProductNameInputValue(val),
    setProductNameRemainingChars: (val) => setProductNameRemainingChars(val),
    setProductDescriptionInputValue: (val) =>
      setProductDescriptionInputValue(val),
    setProductDescriptionRemainingChars: (val) =>
      setProductDescriptionRemainingChars(val),
    setProductAmountInputValue: (val) => setProductAmountInputValue(val),
    setCurrentTab: (val) => setCurrentTab(val),
    handleClearFileData: (val) => handleClearFileData(val),
    handleCloseProductModal: () => handleCloseProductModal(),
    validateProductDetails: (tab) => validateProductDetails(tab),
  };

  const tabComplementsHandler = {
    ...handler,
    productAmountInputOldValue: productAmountInputOldValue,
    setProductAmountInputValue: (val) => setProductAmountInputValue(val),
    setProductAmountInputOldValue: (val) => setProductAmountInputOldValue(val),
    setCurrentTab: (val) => setCurrentTab(val),
    handleCloseProductModal: () => handleCloseProductModal(),
    validateProductComplements: (tab) => validateProductComplements(tab),
  };

  const tabDisponibilityHandler = {
    ...handler,
    allDisponibilitiesChecked: allDisponibilitiesChecked,
    productSunday: productSunday,
    productMonday: productMonday,
    productTuesday: productTuesday,
    productWednesday: productWednesday,
    productThursday: productThursday,
    productFriday: productFriday,
    productSaturday: productSaturday,
    specificHourSunday: specificHourSunday,
    specificHourMonday: specificHourMonday,
    specificHourTuesday: specificHourTuesday,
    specificHourWednesday: specificHourWednesday,
    specificHourThursday: specificHourThursday,
    specificHourFriday: specificHourFriday,
    specificHourSaturday: specificHourSaturday,
    productSunStartTimeInputValue: productSunStartTimeInputValue,
    productMonStartTimeInputValue: productMonStartTimeInputValue,
    productTueStartTimeInputValue: productTueStartTimeInputValue,
    productWedStartTimeInputValue: productWedStartTimeInputValue,
    productThuStartTimeInputValue: productThuStartTimeInputValue,
    productFriStartTimeInputValue: productFriStartTimeInputValue,
    productSatStartTimeInputValue: productSatStartTimeInputValue,
    productSunEndTimeInputValue: productSunEndTimeInputValue,
    productMonEndTimeInputValue: productMonEndTimeInputValue,
    productTueEndTimeInputValue: productTueEndTimeInputValue,
    productWedEndTimeInputValue: productWedEndTimeInputValue,
    productThuEndTimeInputValue: productThuEndTimeInputValue,
    productFriEndTimeInputValue: productFriEndTimeInputValue,
    productSatEndTimeInputValue: productSatEndTimeInputValue,
    productSunSelectAvailability: productSunSelectAvailability,
    productMonSelectAvailability: productMonSelectAvailability,
    productTueSelectAvailability: productTueSelectAvailability,
    productWedSelectAvailability: productWedSelectAvailability,
    productThuSelectAvailability: productThuSelectAvailability,
    productFriSelectAvailability: productFriSelectAvailability,
    productSatSelectAvailability: productSatSelectAvailability,
    setProductSunday: (val) => setProductSunday(val),
    setProductMonday: (val) => setProductMonday(val),
    setProductTuesday: (val) => setProductTuesday(val),
    setProductWednesday: (val) => setProductWednesday(val),
    setProductThursday: (val) => setProductThursday(val),
    setProductFriday: (val) => setProductFriday(val),
    setProductSaturday: (val) => setProductSaturday(val),
    setSpecificHourSunday: (val) => setSpecificHourSunday(val),
    setSpecificHourMonday: (val) => setSpecificHourMonday(val),
    setSpecificHourTuesday: (val) => setSpecificHourTuesday(val),
    setSpecificHourWednesday: (val) => setSpecificHourWednesday(val),
    setSpecificHourThursday: (val) => setSpecificHourThursday(val),
    setSpecificHourFriday: (val) => setSpecificHourFriday(val),
    setSpecificHourSaturday: (val) => setSpecificHourSaturday(val),
    setProductSunSelectAvailability: (val) =>
      setProductSunSelectAvailability(val),
    setProductMonSelectAvailability: (val) =>
      setProductMonSelectAvailability(val),
    setProductTueSelectAvailability: (val) =>
      setProductTueSelectAvailability(val),
    setProductWedSelectAvailability: (val) =>
      setProductWedSelectAvailability(val),
    setProductThuSelectAvailability: (val) =>
      setProductThuSelectAvailability(val),
    setProductFriSelectAvailability: (val) =>
      setProductFriSelectAvailability(val),
    setProductSatSelectAvailability: (val) =>
      setProductSatSelectAvailability(val),
    setProductSunStartTimeInputValue: (val) =>
      setProductSunStartTimeInputValue(val),
    setProductMonStartTimeInputValue: (val) =>
      setProductMonStartTimeInputValue(val),
    setProductTueStartTimeInputValue: (val) =>
      setProductTueStartTimeInputValue(val),
    setProductWedStartTimeInputValue: (val) =>
      setProductWedStartTimeInputValue(val),
    setProductThuStartTimeInputValue: (val) =>
      setProductThuStartTimeInputValue(val),
    setProductFriStartTimeInputValue: (val) =>
      setProductFriStartTimeInputValue(val),
    setProductSatStartTimeInputValue: (val) =>
      setProductSatStartTimeInputValue(val),
    setProductSunEndTimeInputValue: (val) =>
      setProductSunEndTimeInputValue(val),
    setProductMonEndTimeInputValue: (val) =>
      setProductMonEndTimeInputValue(val),
    setProductTueEndTimeInputValue: (val) =>
      setProductTueEndTimeInputValue(val),
    setProductWedEndTimeInputValue: (val) =>
      setProductWedEndTimeInputValue(val),
    setProductThuEndTimeInputValue: (val) =>
      setProductThuEndTimeInputValue(val),
    setProductFriEndTimeInputValue: (val) =>
      setProductFriEndTimeInputValue(val),
    setProductSatEndTimeInputValue: (val) =>
      setProductSatEndTimeInputValue(val),
    setCurrentTab: (val) => setCurrentTab(val),
    setAllDisponibilitiesChecked: (val) => setAllDisponibilitiesChecked(val),
    handleHourDisponibility: (selectedOption, dayOfWeek) =>
      handleHourDisponibility(selectedOption, dayOfWeek),
    handleCloseProductModal: () => handleCloseProductModal(),
    validateProductAvailability: (tab) => validateProductAvailability(tab),
  };

  const tabCouponsHandler = {
    ...handler,
    activeCoupon: activeCoupon,
    flashCoupon: flashCoupon,
    productAmountInputValue: productAmountInputValue,
    productAmountInputOldValue: productAmountInputOldValue,
    fileProductData: fileProductData,
    productImageURL: productImageURL,
    productPromotionalAmountInputValue: productPromotionalAmountInputValue,
    productPromotionalValidityDateInputValue:
      productPromotionalValidityDateInputValue,
    productPromotionalValidityTimeInputValue:
      productPromotionalValidityTimeInputValue,
    productPromotionalUseLimitInputValue: productPromotionalUseLimitInputValue,
    productModalLoading: productModalLoading,
    setProductPromotionalUseLimitInputValue: (val) =>
      setProductPromotionalUseLimitInputValue(val),
    setProductPromotionalValidityTimeInputValue: (val) =>
      setProductPromotionalValidityTimeInputValue(val),
    setProductPromotionalValidityDateInputValue: (val) =>
      setProductPromotionalValidityDateInputValue(val),
    setProductPromotionalAmountInputValue: (val) =>
      setProductPromotionalAmountInputValue(val),
    setActiveCoupon: (val) => setActiveCoupon(val),
    setFlashCoupon: (val) => setFlashCoupon(val),
    setCurrentTab: (val) => setCurrentTab(val),
    handleCloseProductModal: () => handleCloseProductModal(),
  };

  return (
    <Modal
      isVisible={handler.handleProductModal}
      width={px2vw(830)}
      padding={px2vw(20)}
    >
      <ProductModal>
        <ModalHeader>
          <ModalTitleText>
            {handler.handleProductTypeModal === 'new'
              ? 'Criar produto'
              : 'Editar produto'}
          </ModalTitleText>
          <CloseButton
            onClick={handleCloseProductModal}
            disabled={productModalLoading}
          >
            <CloseImage
              src={require('../../../assets/images/close/close.png')}
            />
          </CloseButton>
        </ModalHeader>
        {/* Tabs navigator */}
        <TabsBox>
          <TabBox>
            <Tab
              onClick={() => {
                if (handler.handleProductTypeModal === 'edit') {
                  validateChangeOfTabInEditModal(currentTab, 'tabDetails');
                } else {
                  setCurrentTab('tabDetails');
                }
              }}
            >
              Detalhes
            </Tab>
            {currentTab === 'tabDetails' && <TabSelected />}
          </TabBox>
          <TabBox>
            <Tab
              onClick={() => {
                if (
                  handler.handleProductTypeModal === 'new' &&
                  (currentTab === 'tabDisponibility' ||
                    currentTab === 'tabCoupons')
                ) {
                  setCurrentTab('tabComplements');
                } else if (handler.handleProductTypeModal === 'edit') {
                  validateChangeOfTabInEditModal(currentTab, 'tabComplements');
                } else {
                  toast.error('Você deve avançar a etapa atual', {
                    autoClose: 6000,
                  });
                }
              }}
            >
              Complementos
            </Tab>
            {currentTab === 'tabComplements' && (
              <TabSelected width={px2vw(123)} />
            )}
          </TabBox>
          <TabBox>
            <Tab
              onClick={() => {
                if (
                  handler.handleProductTypeModal === 'new' &&
                  currentTab === 'tabCoupons'
                ) {
                  setCurrentTab('tabDisponibility');
                } else if (handler.handleProductTypeModal === 'edit') {
                  validateChangeOfTabInEditModal(
                    currentTab,
                    'tabDisponibility'
                  );
                } else {
                  toast.error('Você deve avançar a etapa atual', {
                    autoClose: 6000,
                  });
                }
              }}
            >
              Disponibilidade
            </Tab>
            {currentTab === 'tabDisponibility' && (
              <TabSelected width={px2vw(129)} />
            )}
          </TabBox>
          <TabBox>
            <Tab
              onClick={() => {
                if (handler.handleProductTypeModal === 'edit') {
                  validateChangeOfTabInEditModal(currentTab, 'tabCoupons');
                }
              }}
            >
              Cupom de desconto
            </Tab>
            {currentTab === 'tabCoupons' && <TabSelected width={px2vw(163)} />}
          </TabBox>
        </TabsBox>
        <Separator />
        <FormProduct
          initialData={{
            productSunAvailability: 1,
            productMonAvailability: 1,
            productTueAvailability: 1,
            productWedAvailability: 1,
            productThuAvailability: 1,
            productFriAvailability: 1,
            productSatAvailability: 1,
          }}
          onSubmit={handleProductSubmit}
        >
          {/* Details tab */}
          {currentTab === 'tabDetails' && (
            <TabDetails handler={tabDetailHandler} />
          )}
          {/* Complements tab */}
          {currentTab === 'tabComplements' && (
            <TabComplements handler={tabComplementsHandler} />
          )}
          {/* Disponibility tab */}
          {currentTab === 'tabDisponibility' && (
            <TabDisponibility handler={tabDisponibilityHandler} />
          )}
          {/* Coupons tab */}
          {currentTab === 'tabCoupons' && (
            <TabCoupons handler={tabCouponsHandler} />
          )}
        </FormProduct>
      </ProductModal>
    </Modal>
  );
}
