import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormControlLabel } from '@material-ui/core';

import {
  BoxContainer, Container, MediumText, ArrowImg,
  RoundedContainer, Separator, SmallText, TitleText
} from '../styles';
import { SwitchButtonBox, SwitchController } from '../../_layouts/styles/common';
import { Row } from '../../../styles/grid';
import { preto } from '../../../styles/colors';

import MessageConfigModal from './MessageConfigModal';

import px2vw from '../../../utils/responsiveness/px2vw';

import {
  getSalesRecoverDataRequest,
  updateSalesRecoverConfigsRequest
} from '../../../store/modules/salesRecover/actions';

export default function ConfigsPage() {
  const {
    salesRecoverData
  } = useSelector((state) => state.salesRecover);
  const [isSaleRecoverActive, setIsSaleRecoverActive] = useState(false);
  const [isMessageModalVisible, setIsMessageModalVisible] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSalesRecoverDataRequest());
  }, []);

  useEffect(() => {
    setIsSaleRecoverActive(salesRecoverData?.is_active);
  }, [salesRecoverData?.is_active]);

  const Card = ({ idx, title, text, actionText, actionOnClick = () => {} }) => {
    return (
      <BoxContainer>
        <RoundedContainer>
          <MediumText color={preto}>{idx}</MediumText>
        </RoundedContainer>
        <TitleText >{title}</TitleText>
        <Separator />
        <Row height={px2vw(100)} padleft={px2vw(6)} padright={px2vw(6)}>
          <SmallText fontSize={px2vw(12)} color={preto}>
            {text}
          </SmallText>
        </Row>
        {actionText && (
          <Row margtop={px2vw(10)}>
            <SmallText 
              fontSize={px2vw(12)} 
              color={preto} 
              link 
              onClick={actionOnClick}
            >
              {actionText}
            </SmallText>
          </Row>
        )}
      </BoxContainer>
    )
  }

  return (
    <Container margbottom={px2vw(-10)}>
      <Row padtop={px2vw(30)} justifyStart spacebetween width={'100%'}>
        <SwitchButtonBox
          width={px2vw(500)}
          margtop="-7px"
        >
          <FormControlLabel
            control={
              <SwitchController
                checked={isSaleRecoverActive}
                type="checkbox"
                onChange={() => {
                  dispatch(updateSalesRecoverConfigsRequest());
                }}
              />
            }
            label={
              <MediumText padTop={px2vw(30)} padLeft={px2vw(15)}>
                Ativar recuperador de vendas via Whatsappbot
              </MediumText>
            }
          />
        </SwitchButtonBox>
      </Row>
      <Row spaceAround alignCenter justifyCenter margleft={px2vw(-16)} margtop={px2vw(30)} margbottom={px2vw(30)}>
        <Card
          key={'salesRecoverCard#1'}
          idx={'1'}
          title="Gatilho da Mensagem"
          text={"Seu cliente te mandou mensagem no WhatsApp, mas não realizou um pedido dentro de 10 minutos."}
        />
        <ArrowImg
          src={require('../../../assets/images/sales_recover/right_arrow.svg')}
        />
        <Card
          key={'salesRecoverCard#2'}
          idx={'2'}
          title="Mensagem Disparada"
          text="Seu cliente recebe uma mensagem no WhatsApp estimulando-o a fazer um pedido."
          actionText="Clique para ver a mensagem!"
          actionOnClick={() => setIsMessageModalVisible(true)}
        />
        <ArrowImg
          src={require('../../../assets/images/sales_recover/right_arrow.svg')}
        />
        <Card
          key={'salesRecoverCard#3'}
          idx={'3'}
          title="Venda Recuperada"
          text={"Você recupera uma venda que possívelmente seria perdida e esquecida."}
        />
      </Row>
      {isMessageModalVisible && (
        <MessageConfigModal
          minheight="100%"
          width={px2vw(750)}
          padding="none"
          onClose={() => setIsMessageModalVisible(false)}
          isVisible={isMessageModalVisible}
        />
      )}
    </Container>
  )
}