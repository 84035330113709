import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { BannerContainer, BannerText, BannerTitle, Image, ImageContainer } from './styles';
import { Col } from '../../styles/grid';
import { Tab, TabBox, TabsBox, TabSelected } from '../MyStore/styles';
import { ContentPage, PageTitle } from '../_layouts/styles/common';

import px2vw from '../../utils/responsiveness/px2vw';

import RatingsPage from './RatingsPage';
import ConfigsPage from './ConfigsPage';

export default function Ratings() {
  const ratingsConfigIsRatingActive =  useSelector((state) => state.ratings.ratingsConfigIsRatingActive);
  const [currTabSelected, setCurrTabSelected] = useState('ratings');

  useEffect(() => {
    if (!ratingsConfigIsRatingActive) {
      setCurrTabSelected('config');
    } else {
      setCurrTabSelected('ratings');
    }
  }, [ratingsConfigIsRatingActive])

  return (
    <ContentPage>
      <PageTitle>Avaliações</PageTitle>

      <BannerContainer>
        <Col margleft={px2vw(20)} width="70%">
          <BannerTitle>Automatize as Avaliações do seu Delivery</BannerTitle>
          <BannerText>Configure o bot para enviar uma mensagem ao seu cliente, sempre que você concluir um pedido, pedindo por uma avaliação</BannerText>
          <BannerText>Todas as avaliações ficarão registradas aqui, na aba Avaliações</BannerText>
          <BannerText fontSize={px2vw(12)}><b>OBS:</b> O WhatsApp precisa estar conectado à plataforma para que a avaliação seja enviada</BannerText>
        </Col>
        <Col alignEnd width="30%">
          <Image src={require("../../assets/images/ratings/rating_banner_image.png")} />
        </Col>
      </BannerContainer>

      <TabsBox>
        <TabBox margleft={px2vw(20)}>
          <Tab onClick={() => setCurrTabSelected('ratings')}>
            Avaliações
          </Tab>
          {currTabSelected === 'ratings' && <TabSelected isVisible width="auto" />}
        </TabBox>
        <TabBox>
          <Tab onClick={() => setCurrTabSelected('config')}>
            Configurações
          </Tab>          
          {currTabSelected === 'config' && <TabSelected isVisible width="auto" />}
        </TabBox>
      </TabsBox>
      {currTabSelected === 'config' && <ConfigsPage />}
      {currTabSelected === 'ratings' && <RatingsPage />}
    </ContentPage>
  )
}