import React from 'react';
import { Link } from 'react-router-dom';

import { Row, Col } from '../../../../styles/grid';
import { preto, cinzaKuppi1 } from '../../../../styles/colors';
import { PageTitle, ImgBox, Img } from '../../BaseScreen/styles';
import { TabsBox, TabBox, Tab, TabSelected } from '../styles';

import px2vw from '../../../../utils/responsiveness/px2vw';

import ButtonPrimary from '../../../../components/ButtonPrimary';
import ButtonSecondary from '../../../../components/ButtonSecondary';
import Modal from '../../../../components/Modal';

import InstagramWeb from './InstagramWeb';
import WhatsappBot from '../../../Whatsappbot';

export default function StorePublicityWeb({ handler }) {
  return (
    <>
      <Col
        background={cinzaKuppi1}
        padbottom={px2vw(2)}
        borderRadius={!handler.hasFreeTrialValid && px2vw(60)}
      >
        <Row
          margtop={px2vw(28)}
          margleft={px2vw(35)}
          margbottom={px2vw(15)}
          justifyStart
          width="auto"
        >
          <PageTitle>Divulgação</PageTitle>
        </Row>
        <TabsBox margleft={px2vw(35)}>
          <TabBox>
            <Tab onClick={() => handler.setCurrTabSelected('tabInstagram')}>
              Instagram
            </Tab>
            <TabSelected
              isVisible={handler.currTabSelected === 'tabInstagram'}
              width="100%"
            />
          </TabBox>
        </TabsBox>
        {handler.currTabSelected === 'tabInstagram' && (
          <InstagramWeb handler={handler} />
        )}
      </Col>
      {/* {Modal} */}
      <Modal
        isVisible={handler.openModal}
        width={px2vw(850)}
        height={px2vw(550)}
        backgroundImg
      >
        <Col
          width={px2vw(590)}
          margleft={px2vw(125)}
          margtop={px2vw(35)}
          height="auto"
        >
          <PageTitle fontsize={px2vw(24)} textalign="center">
            Parabéns, seu cardápio digital está pronto. Falta pouco pro seu
            delivery rodar no automático!
          </PageTitle>
        </Col>

        <Row width="auto" height="auto" margtop={px2vw(10)}>
          <ImgBox width={px2vw(550)} height={px2vw(375)}>
            <iframe
              width="100%"
              height="100%"
              src={'https://www.youtube.com/embed/Ihn6YBP9Y3g'}
              // title={ourProductSec1VideoTitle}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </ImgBox>
        </Row>

        <Row width="auto" height="auto" margtop={px2vw(15)}>
          <ButtonPrimary
            minWidth={385}
            type="button"
            onClick={handler.accessPlatform}
          >
            Acessar a plataforma
          </ButtonPrimary>
        </Row>
      </Modal>
    </>
  );
}
