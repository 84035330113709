import React from 'react';
import { ImBlocked } from 'react-icons/im';
import { BrokenMedia } from '../style';

export default ({ type }) => {
  const getTypeText = () => {
    switch (type) {
      case 'image':
        return (
          <>
            Esta imagem não está disponivel. <br /> Tente baixá-la pelo celular.
          </>
        );
      case 'video':
        return (
          <>
            Este video não está disponivel. <br /> Tente baixá-lo pelo celular.
          </>
        );
      case 'sticker':
        return (
          <>
            Esta figurinha não está disponivel. <br /> Tente baixá-la pelo
            celular.
          </>
        );
      case 'audio':
        return (
          <>
            Este audio não está disponivel. <br /> Tente baixá-lo pelo celular.
          </>
        );
      case 'document':
        return (
          <>
            Este documento não está disponivel. <br /> Tente baixá-lo pelo
            celular.
          </>
        );
      default:
        return (
          <>
            Esta mensagem não está disponivel. <br /> Tente vê-la pelo
            celular.
          </>
        );
    }
  };

  return (
    <>
      <BrokenMedia type={type}>
      <ImBlocked
        size={32}
        style={{
          [["video", "image", "sticker"].includes(type) ? "marginBottom" : "marginRight"]: 10,
        }}
      />
      {getTypeText()}
    </BrokenMedia>
    </>
  );
};
