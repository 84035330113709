// import storage from 'redux-persist/lib/storage';
import storage from 'redux-persist/lib/storage/session';
import { persistReducer } from 'redux-persist';
import localforage from 'localforage';


export const forage = localforage.createInstance({
  driver: localforage.INDEXEDDB,
  name: 'chats_forage',
  version: 1.0,
  storeName: 'chats',
  size: 4980736,
  description: 'Advertiser Chat APP Kuppi',
});

export default (reducers) => {
  const persistedReducer = persistReducer(
    {
      key: 'kuppi-advertiser',
      storage: storage,
      whitelist: [
        'auth',
        'user',
        'firstSteps',
        'userAddressQuery',
        'orders',
        'manualOrder',
        'productCategories',
        'coupons',
        'myStore',
        'loyaltyCard',
        'clients',
        'dashboardMetrics',
        'kuppiBoost',
        'onboarding',
        'importIfood',
        'signaturePayment',
        'profileAccount',
        'reports',
        'kuppiBoost',
        'whatsappBot',
        'mgm',
        'whatsappChat',
        'ratings',
        'salesRecover',
        'massMessage',
        'insightsAi',
        'challenges',
        'massHandleComplements',
        'financial',
      ],
    },
    reducers
  );

  return persistedReducer;
};
