import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';

import BarChart from '../../../components/BarChart';
import 'react-circular-progressbar/dist/styles.css';

import { formatCurrencyBRL } from '../../../utils/utils';
import px2vw from '../../../utils/responsiveness/px2vw';
import { AdvertiserDataBox, Hr, SelectInput, MetricBox } from '../styles';

import { Col, Row } from '../../../styles/grid';

import {
  preto,
  verdeKuppi1,
  verdeKuppi4,
  verdeKuppi5,
} from '../../../styles/colors';
import { Spinner } from 'reactstrap';
import { Rating } from '@material-ui/lab';
import moment from 'moment';
import 'moment/locale/pt-br';
import { Text } from '../../../styles/common';

export default function Reports() {
  const userMetrics = useSelector((state) => state.dashboardMetrics.ordersMetrics);
  const [selectedFilterOptions, setSelectedFilterOptions] = useState('30');

  const ordersMetricsLoading = useSelector(
    (state) => state.dashboardMetrics.ordersMetricsLoading
  );

  function findDayInfoWithMostSales(ordersByDayOfWeek, returnQty = false) {
    const daysOfWeekPtBrFull = [
      'Domingo',
      'Segunda-feira',
      'Terça-feira',
      'Quarta-feira',
      'Quinta-feira',
      'Sexta-feira',
      'Sábado',
    ];

    let maxSales = -1;
    let dayWithMostSales = '';
    let maxSalesQty = 0;

    for (let i = 0; i < ordersByDayOfWeek.length; i++) {
      if (ordersByDayOfWeek[i] > maxSales) {
        maxSales = ordersByDayOfWeek[i];
        dayWithMostSales = daysOfWeekPtBrFull[i];
        maxSalesQty = ordersByDayOfWeek[i];
      }
    }

    if (returnQty) {
      return maxSalesQty;
    } else {
      return dayWithMostSales;
    }
  }

  const charOptionsOrderPerDay = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true,
        ticks: {
          color: preto,
        },
      },
      y: {
        callback: (yValue) => {
          return Math.floor(yValue);
        },
        max: 100,
        min: 0,
        ticks: {
          stepSize: 10,
        },
      },
    },
  };

  const charOptionsOrderPerWeekDay = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        ticks: {
          color: preto,
        },
        grid: {
          display: false,
        },
      },
      y: {
        callback: (yValue) => {
          return Math.floor(yValue);
        },
        max: 12,
        min: 0,
        ticks: {
          stepSize: 2,
        },
      },
    },
  };

  const charOptionsOrderPerHour = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true,
        ticks: {
          color: preto,
        },
        grid: {
          display: false,
        },
      },
      y: {
        callback: (yValue) => {
          return Math.floor(yValue);
        },
        max: 18,
        min: 0,
        ticks: {
          stepSize: 2,
        },
      },
    },
  };

  const dashMetrics = useMemo(() => {
    const today = moment();
    const dateArray = [];

    for (let i = selectedFilterOptions - 1; i >= 0; i--) {
      const previousDate = today.clone().subtract(i, 'days');
      dateArray.push(previousDate.format('YYYY-MM-DD'));
    }

    const orderList = userMetrics?.perDayList;
    const newArray = dateArray.map((date) => {
      const order = orderList?.find(
        (day) => moment(day.created_at).format('YYYY-MM-DD') === date
      );

      const qty_order = order ? parseInt(order.qty_order) : 0;
      return qty_order;
    });

    const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const ordersByDayOfWeek = [0, 0, 0, 0, 0, 0, 0];

    const cutoffDateWeek = new Date();
    cutoffDateWeek.setDate(cutoffDateWeek.getDate() - selectedFilterOptions);

    const orderListPerHour = userMetrics?.perHourList;
    (orderList || []).forEach((entry) => {
      const dayName = entry.week_day_name;
      const qty_order = parseInt(entry.qty_order);
      const dayIndex = daysOfWeek.indexOf(dayName);

      const orderDate = new Date(entry.created_at);
      if (orderDate >= cutoffDateWeek && orderDate <= new Date()) {
        if (dayIndex !== -1) {
          ordersByDayOfWeek[dayIndex] += qty_order;
        }
      }
    });

    const hoursArray = [
      '00',
      '01',
      '02',
      '03',
      '04',
      '05',
      '06',
      '07',
      '08',
      '09',
      '10',
      '11',
      '12',
      '13',
      '14',
      '15',
      '16',
      '17',
      '18',
      '19',
      '20',
      '21',
      '22',
      '23',
    ];

    let afterMidnightOrders = 0;

    const ordersByHourArray = new Array(hoursArray.length).fill(0);
    (
      orderListPerHour?.filter((order) => {
        const orderDate = new Date(order.created_at);
        const cutoffDate = new Date();
        cutoffDate.setHours(0);
        cutoffDate.setDate(cutoffDate.getDate() - selectedFilterOptions + 1);

        return orderDate >= cutoffDate && orderDate <= new Date();
      }) || []
    ).forEach((order) => {
      const dayHour = order.day_hour.replace('h', '');
      const qtyOrder = parseInt(order.qty_order);
      const hourIndex = hoursArray.indexOf(dayHour);
      if (hourIndex !== -1 && qtyOrder) {
        ordersByHourArray[hourIndex] += qtyOrder;
      } else if (hourIndex === -1 && qtyOrder) {
        afterMidnightOrders += qtyOrder;
      }
    });

    return {
      orderPayDayLabel: dateArray,
      orderPayDayValue: newArray,
      orderPayWeekDayValue: ordersByDayOfWeek,
      orderParHourValue: ordersByHourArray,
      afterMidnightOrders,
    };
  });

  const boxMetrics = useMemo(() => {
    const orders = userMetrics?.lastOrdersList || [];
    const days = selectedFilterOptions - 1;
    const currentDate = new Date();

    const cutoffDate = new Date();
    cutoffDate.setDate(cutoffDate.getDate() - days);

    let total = 0;
    let totalAmount = 0;
    let recurrents = 0;
    let news = 0;
    let totalRating = 0;
    let totalRatingOrdersQty = 0;
    const countedClientIds = new Set();

    for (const order of orders) {
      const createdAt = new Date(order.created_at);

      if (createdAt >= cutoffDate && createdAt <= currentDate) {
        total++;
        totalAmount += order.total_amount;

        if (order.type_tag === 'recurrent') {
          if (!countedClientIds.has(order.client_id)) {
            recurrents++;
            countedClientIds.add(order.client_id);
          }
        } else if (order.type_tag === 'new') {
          if (!countedClientIds.has(order.client_id)) {
            news++;
            countedClientIds.add(order.client_id);
          }
        }
        
        if (order.rating !== null) {
          totalRating += Number(order.rating);
          totalRatingOrdersQty += 1;
        }
      }
    }

    let ratingAvg = 0;
    if (totalRating > 0) {
      ratingAvg = Number(totalRating / totalRatingOrdersQty).toFixed(2);
    }

    return {
      total,
      ticket_avg: (totalAmount / total) || 0,
      recurrents,
      new: news,
      rating: ratingAvg,
    };
  }, [userMetrics, selectedFilterOptions]);

  const ordersPerDayData = {
    labels: dashMetrics.orderPayDayLabel.map((date) =>
      moment(date).format('DD/MM')
    ),
    datasets: [
      {
        label: 'Pedidos por dia',
        data: dashMetrics.orderPayDayValue,
        backgroundColor: verdeKuppi5,
        barPercentage: 0.95,
      },
    ],
  };

  const ordersPerWeekDayData = {
    labels: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
    datasets: [
      {
        label: 'Pedidos por dia da semana',
        data: dashMetrics.orderPayWeekDayValue,
        backgroundColor: verdeKuppi5,
      },
    ],
  };

  const ordersPerHourData = {
    labels: [
      '0h',
      '1h',
      '2h',
      '3h',
      '4h',
      '5h',
      '6h',
      '7h',
      '8h',
      '9h',
      '10h',
      '11h',
      '12h',
      '13h',
      '14h',
      '15h',
      '16h',
      '17h',
      '18h',
      '19h',
      '20h',
      '21h',
      '22h',
      '23h',
    ],
    datasets: [
      {
        label: 'Pedidos por hora do dia',
        data: dashMetrics.orderParHourValue,
        backgroundColor: verdeKuppi5,
      },
    ],
  };

  function mostSoldHour(salesArray, includeTotal = false) {
    let maxSales = -1;
    let startHour = -1;

    for (let i = 0; i < salesArray.length; i++) {
      if (salesArray[i] > maxSales) {
        maxSales = salesArray[i];
        startHour = i + 0;
      }
    }

    let endHour = (startHour + 1) % 24;

    if (includeTotal) {
      return maxSales;
    } else {
      return `${startHour}h - ${endHour}h`;
    }
  }

  const reportFilterOptions = [
    {
      id: '30',
      title: 'Últimos 30 dias',
    },
    {
      id: '15',
      title: 'Últimos 15 dias',
    },
    {
      id: '7',
      title: 'Últimos 7 dias',
    },
  ];

  return (
    <>
      {ordersMetricsLoading ? (
        <Col margtop={px2vw(120)} height="20vh">
          <Col alignCenter justifyCenter>
            <Spinner
              style={{
                width: px2vw(20),
                height: px2vw(20),
                color: preto,
              }}
            />
          </Col>
        </Col>
      ) : (
        <Row margtop={px2vw(20)} height="auto">
          <AdvertiserDataBox
            style={{ padding: px2vw(10) }}
            height="auto"
            width="100%"
          >
            <Row alignCenter justifyStart padtop={px2vw(10)}>
              <Text margleft={px2vw(25)} size={px2vw(21)} height="auto">
                Resultado de pedidos
              </Text>

              <SelectInput
                name="editPrinter"
                width={px2vw(135)}
                height={px2vw(32)}
                value={selectedFilterOptions}
                options={reportFilterOptions}
                onChange={(e) => setSelectedFilterOptions(e.target.value)}
              />
            </Row>
            <Row
              margleft={px2vw(25)}
              width="90%"
              alignCenter
              justifyStart
              padtop={px2vw(30)}
            >
              <MetricBox>
                <Text justifyStart size={px2vw(21)} height="auto">
                  {boxMetrics?.total}
                </Text>
                <Text justifyStart size={px2vw(12)} height="auto">
                  Total de pedidos
                </Text>
                <Hr />
              </MetricBox>
              <MetricBox>
                <Text
                  justifyStart
                  size={px2vw(
                    boxMetrics?.ticket_avg <= 100
                      ? 21
                      : boxMetrics?.ticket_avg < 1000
                      ? 19
                      : 17
                  )}
                  height="100%"
                >
                  {formatCurrencyBRL(boxMetrics?.ticket_avg, 2)}
                </Text>
                <Text
                  justifyStart
                  size={px2vw(12)}
                  className="mb-auto"
                  height="auto"
                >
                  Ticket médio
                </Text>
                <Hr />
              </MetricBox>
              <MetricBox>
                <Text justifyStart size={px2vw(21)} height="auto">
                  {boxMetrics?.new}
                </Text>
                <Text justifyStart size={px2vw(12)} height="auto">
                  Novos clientes
                </Text>
                <Hr />
              </MetricBox>
              <MetricBox>
                <Text justifyStart size={px2vw(21)} height="auto">
                  {boxMetrics?.recurrents}
                </Text>
                <Text justifyStart size={px2vw(12)} height="auto">
                  Clientes recorrentes
                </Text>
                <Hr />
              </MetricBox>
              <MetricBox style={{ position: 'relative' }}>
                <Text justifyStart size={px2vw(21)} height="auto">
                  {boxMetrics?.rating}
                </Text>
                <Text justifyStart size={px2vw(12)} height="auto">
                  Avaliação
                </Text>
                <Hr />
              </MetricBox>
            </Row>
            <Col padleft={px2vw(25)} padright={px2vw(10)}>
              <Row justifyStart margtop={px2vw(15)}>
                <Text justifyStart size={px2vw(20)} height="auto">
                  Pedidos por dia
                </Text>
              </Row>
              <BarChart
                chartData={ordersPerDayData}
                chartHeight={3900}
                chartWidth="95%"
                needOptions={charOptionsOrderPerDay}
              />
            </Col>
            <Row
              alignCenter
              spaceBetween
              padleft={px2vw(10)}
              padright={px2vw(10)}
              margtop={px2vw(20)}
            >
              <Col padleft={px2vw(20)} width="45%">
                <Col justifyStart margtop={px2vw(15)}>
                  <Text justifyStart size={px2vw(19)} height="auto">
                    Pedidos por dia da semana
                  </Text>
                  <Text
                    justifyStart
                    regular
                    margtop={px2vw(5)}
                    size={px2vw(12)}
                    height="auto"
                  >
                    Melhores dia para vendas
                  </Text>
                  <Text
                    justifyStart
                    margtop={px2vw(5)}
                    size={px2vw(18)}
                    height="auto"
                    color={verdeKuppi4}
                  >
                    {findDayInfoWithMostSales(dashMetrics.orderPayWeekDayValue)}
                  </Text>
                  <Text
                    justifyStart
                    regular
                    margtop={px2vw(5)}
                    size={px2vw(11)}
                    height="auto"
                  >
                    {findDayInfoWithMostSales(
                      dashMetrics.orderPayWeekDayValue,
                      true
                    ) || '-'}{' '}
                    pedidos no total
                  </Text>
                </Col>
                <BarChart
                  chartHeight={3900}
                  chartData={ordersPerWeekDayData}
                  chartWidth="95%"
                  needOptions={charOptionsOrderPerWeekDay}
                />
              </Col>
              <Col width="55%">
                <Col width="auto" justifyStart margtop={px2vw(15)}>
                  <Text justifyStart size={px2vw(19)} height="auto">
                    Pedido por hora do dia
                  </Text>
                  <Text
                    justifyStart
                    regular
                    margtop={px2vw(5)}
                    size={px2vw(12)}
                    height="auto"
                  >
                    Melhores horários
                  </Text>
                  <Text
                    justifyStart
                    margtop={px2vw(5)}
                    size={px2vw(18)}
                    height="auto"
                    color={verdeKuppi4}
                  >
                    {mostSoldHour(dashMetrics.orderParHourValue)}
                  </Text>
                  <Text
                    justifyStart
                    regular
                    margtop={px2vw(5)}
                    size={px2vw(11)}
                    height="auto"
                  >
                    {mostSoldHour(dashMetrics.orderParHourValue, true)} pedido
                    {mostSoldHour(dashMetrics.orderParHourValue, true) === 1
                      ? ''
                      : 's'}{' '}
                    no total
                  </Text>
                </Col>
                <BarChart
                  chartHeight={3900}
                  chartData={ordersPerHourData}
                  chartWidth="95%"
                  needOptions={charOptionsOrderPerHour}
                />
              </Col>
            </Row>
          </AdvertiserDataBox>
        </Row>
      )}
    </>
  );
}
