import styled from 'styled-components';
import { Input, Select } from '@rocketseat/unform';
import { IoArrowRedoOutline, IoSettingsOutline } from 'react-icons/io5';

import Table from '@material-ui/core/Table';

import px2vw from '../../utils/responsiveness/px2vw';

import {
  preto,
  branco,
  cinzaKuppi3,
  cinzaKuppi4,
  verdeKuppi1,
  cinzaKuppi6,
  cinzaKuppi10,
  vermelhoKuppi1,
} from '../../styles/colors';

export const OrdersBox = styled.div`
  background-color: ${branco};
  margin-left: ${px2vw(5)};
  border-radius: ${px2vw(20)};
  padding: 0 ${px2vw(25)};

  box-shadow: 0px 8px 9px -2px ${cinzaKuppi4};
`;

export const SearchBox = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: ${px2vw(40)};
  margin-right: ${px2vw(10)};
`;

export const SearchButton = styled.button`
  margin-top: ${px2vw(6)};
  margin-left: ${px2vw(-28)};
  width: ${px2vw(20)};
  height: ${px2vw(20)};
  border: none;
  z-index: 10;
  background-color: transparent;
`;

export const SearchImage = styled.img`
  width: ${px2vw(15)};
  height: ${px2vw(15)};
`;

export const SearchInput = styled.input`
  padding: 1em;
  margin-left: 0.5em;
  border: 1px solid ${cinzaKuppi4};
  border-radius: 5px;
  font-size: ${px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.32px;
  width: ${px2vw(250)};
  height: ${px2vw(35)};
`;

export const TabsBox = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: ${px2vw(20)};
  border-bottom: 1px solid ${cinzaKuppi4} || ${(props) => props.borderbot};

  .ml-auto {
    margin-left: auto;
  }
`;

export const TabBox = styled.div`
  display: block;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: ${(props) => props.margright};
`;

export const Tab = styled.button`
  height: ${px2vw(50)};
  font-size: ${px2vw(18)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.48px;
  border: none;
  background-color: transparent;
  color: ${cinzaKuppi6};
`;

export const TabSelected = styled.div`
  display: none;
  height: ${px2vw(5)};
  width: ${px2vw(95)};
  background-color: ${verdeKuppi1};
  border-radius: ${px2vw(5)};
  margin-top: ${px2vw(-7)};
  margin-bottom: ${px2vw(20)};

  ${(props) =>
    props.isVisible &&
    `
    display: block;
  `};

  ${(props) => `
    width: ${props.width};
  `};
`;

export const QtdOrders = styled.div`
  font-size: ${px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.4px;
  border-radius: ${px2vw(13)};
  width: ${px2vw(26)};
  height: ${px2vw(26)};
  background-color: transparent;
  color: transparent;
  margin-top: ${px2vw(10)};
  margin-left: ${px2vw(5)};
  padding-top: ${px2vw(3)};
  text-align: center;

  ${(props) =>
    props.isVisible &&
    `
    background-color: ${vermelhoKuppi1};
    color: ${branco};
  `};
`;

export const NextStatusIcon = styled(IoArrowRedoOutline).attrs({
  size: px2vw(20),
})`
  margin-top: ${px2vw(15)};
  margin-left: ${px2vw(20)};
  margin-right: ${px2vw(20)};
`;

export const SetOrderOrdersBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const SetOrderOrdersButton = styled.button`
  height: 40px;
  margin-top: 5px;
  border: none;
  background-color: transparent;

  ${(props) => `
    margin-left: ${props.margleft};
  `};
`;

export const SetOrderOrdersButtonText = styled.div`
  font-size: 16px;
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.36px;
  margin-top: 2px;
`;

export const TabContent = styled.div`
  display: none;

  ${(props) =>
    props.isVisible &&
    `
    display: block;
  `};
`;

export const TableOrders = styled(Table)`
  min-width: 650;
`;

export const StatusBox = styled.div`
  padding-top: 5.5px;
  border: none;
  height: 32px;
  width: 205px;
  border-radius: 10px;
  display: flex;
  justify-content: center;

  ${(props) => `
    background-color: ${props.backgroundColor}60;
    margin-left: ${props.margleft};
  `};
`;

export const OrdersRowText = styled.div`
  font-size: 16px;
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.36px;

  ${(props) =>
    props.isBold &&
    `
    font-family: AvenirNextLTPro;
    font-weight: 700;
  `};

  ${(props) => `
    margin-top: ${props.margtop};
    margin-left: ${props.margleft};
    font-size: ${props.fontSize};
  `};
`;

export const ErrorText = styled.div`
  font-size: 24px;
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.4px;
  margin-left: 20px;
`;

export const OrdersConfigsButton = styled.button`
  height: ${px2vw(35)};
  width: ${px2vw(125)};
  border: none;
  background-color: transparent;
`;

export const ButtonBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const ButtonImage = styled.img`
  height: ${px2vw(15)};
  width: ${px2vw(15)};
  margin-right: ${px2vw(10)};
`;

export const ButtonText = styled.div`
  font-size: ${px2vw(12)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.32px;
  margin-top: 2px;
`;

export const ChoiceButton = styled.div`
  border: 1px solid ${cinzaKuppi3};
  background-color: ${branco};
  width: ${px2vw(12)};
  height: ${px2vw(12)};
`;

export const ChoiceButtonSelected = styled.div`
  position: absolute;
  background-color: ${verdeKuppi1};
  width: ${px2vw(7.5)};
  height: ${px2vw(7.5)};
  margin-top: ${px2vw(2.3)};
  display: none;

  ${(props) =>
    props.isChecked &&
    `
      display: block;
    `};
`;

export const PrintTypeButtonText = styled.div`
  font-size: ${px2vw(12)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.32px;
  margin-left: ${px2vw(10)};

  ${(props) => `
  margin-top: ${props.margtop};
  `};
`;

export const PrintTypeBoxDetail = styled.div`
  border: 1px solid ${cinzaKuppi4};
  border-radius: 8px;
  width: 100%;
  height: ${px2vw(120)} || ${(props) => props.height};
  margin-top: ${px2vw(20)};
  padding: ${px2vw(10)};
`;

export const PrintTypeBoxDetailText = styled.div`
  font-size: ${px2vw(12)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  text-align: ${(props) => props.textalign};
  letter-spacing: 0.36px;
  width: 100%;
  height: 100%;
`;

export const PrintTypeInput = styled(Input)`
  padding: 0.5em;
  margin-top: 5px;
  border: 1px solid ${cinzaKuppi4};
  border-radius: 5px;
  font-size: ${px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.36px;
  width: ${px2vw(36)};
  height: ${px2vw(27)};
  margin-top: ${px2vw(11)};
  margin-left: ${px2vw(10)};

  ::placeholder {
    color: ${cinzaKuppi4} !important;
  }

  :focus {
    border: 1px solid ${cinzaKuppi3} !important;
    box-shadow: none !important;
  }
`;

export const OrdersConfigsIcon = styled(IoSettingsOutline).attrs({
  size: px2vw(20),
})`
  margin-right: ${px2vw(15)};
  color: ${preto};
`;

// QZ-Tray
export const SelectInput = styled(Select)`
  margin-top: 5px;
  margin-bottom: 5px;
  height: 40px;

  ${(props) => `
    width: ${props.width};
    height: ${props.height};
    margin-top: ${props.margtop};
    margin-right: ${props.margright};
  `};
`;

export const SearchPrinters = styled.button`
  width: ${px2vw(150)};
  height: ${px2vw(30)};
  font-size: ${px2vw(12)};
  font-family: AvenirNextLTPro;
  font-weight: bold;
  letter-spacing: 0.32px;
  border: 2px solid ${cinzaKuppi6};
  border-radius: ${px2vw(10)};
  background: ${branco};
  color: ${cinzaKuppi6};
`;

export const KnowHowButton = styled.button`
  width: ${px2vw(100)};
  height: ${px2vw(30)};
  font-size: ${px2vw(12)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.32px;
  border: none;
  border-radius: ${px2vw(10)};
  background: ${cinzaKuppi4};
  color: ${cinzaKuppi10};
`;

export const QzTrayImageBox = styled.div`
  width: ${px2vw(80)};
  height: ${px2vw(40)};
  margin-right: ${px2vw(20)} ;
`;

export const QzTrayImage = styled.img`
  width: 100%;
  height: 100%;
`;

export const DownloadQzButton = styled.a`
  text-decoration: none !important;
  width: ${px2vw(275)};
  height: ${px2vw(40)};
  font-size: ${px2vw(13)};
  font-family: AvenirNextLTPro;
  font-weight: bold;
  letter-spacing: 0.32px;
  border: none;
  border-radius: ${px2vw(10)};
  background: ${verdeKuppi1};
  color: ${branco};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover{
    color: ${branco};
  }
  
`;

export const DownloadImageBox = styled.div`
  margin-left: ${px2vw(15)};
  margin-bottom: ${px2vw(10)};
  width: ${px2vw(17)};
  height: ${px2vw(15)};
`;

export const DownloadImage = styled.img`
  width: 100%;
  height: 100%;
`;

export const ObsText = styled.div`
  font-size: ${px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: bold;
  text-align: ${(props) => props.textalign};
  letter-spacing: 0.36px;
  width: 100%;
  height: 100%;
`;

export const QzMaxCharLineText = styled.div`
  font-size: ${px2vw(12)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.36px;
`