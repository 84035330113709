import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { ModalBackground, ModalContent } from './styles';

import FacebookId from '../../components/KuppiBoost/Onboarding/Step1-FacebookId';
import PagePartner from './Onboarding/Step2-FacebookPartner';
import InstagramId from '../../components/KuppiBoost/Onboarding/Step3-InstagramId';
import InstagramPartner from '../../components/KuppiBoost/Onboarding/Step4-InstagramPartner';

import { setCurrentStep } from '../../store/modules/kuppiBoost/actions';

export default function KuppiBoostModal(props) {
  const dispatch = useDispatch()
  const kuppiBoostData = useSelector((state) => state.kuppiBoost);
  const currentStep = kuppiBoostData.onboardingCurrentStep;

  useEffect(() => {
    return () => {
      dispatch(setCurrentStep('1'))
    };
  }, []);

  return (
    <ModalBackground isVisible={props.isVisible}>
      <ModalContent
        width={props.width}
        height={props.height}
        bgcolor={props.bgcolor}
        margtop={props.margtop}
        padding={props.padding}
        backgroundImg={props.backgroundImg}
        backgroundImgSize={props.backgroundImgSize}
        maxheight={props.maxheight}
      >
        {currentStep === '1' && <FacebookId onClose={props.onClose} />}
        {currentStep === '2' && <PagePartner onClose={props.onClose} />}
        {currentStep === '3' && <InstagramId onClose={props.onClose} />}
        {currentStep === '4' && <InstagramPartner onClose={props.onClose} />}
      </ModalContent>
    </ModalBackground>
  );
}
