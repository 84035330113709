import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { preto } from '../../../../styles/colors';
import { Text } from '../../../../styles/common';

import px2vw from '../../../../utils/responsiveness/px2vw';
import { ModalPrimaryButton } from '../styles';
import { Col } from '../../../../styles/grid';

import {
  ModalContent,
  CloseButton,
  ModalHeader,
  CloseImage,
  CampaignTypeCard,
  CampaignTypeImg,
  ModalFooter,
  RecommendedTag,
} from './styles';
import {
  setCampaignType,
  setCurrentStep,
} from '../../../../store/modules/massMessage/actions';

export default function CampaignType(props) {
  const dispatch = useDispatch();
  const [selectedType, setSelectedType] = useState('massPromotion');

  function handleGoToNextPage() {
    dispatch(setCampaignType(selectedType));
    dispatch(setCurrentStep(selectedType === 'massPromotion' ? '2' : '3'));
  }

  return (
    <>
      <ModalHeader>
        Qual o objetivo da sua campanha?
        <CloseButton onClick={props.onClose}>
          <CloseImage
            src={require('../../../../assets/images/close/close.png')}
          />
        </CloseButton>
      </ModalHeader>
      <ModalContent>
        <Col width="auto" alignCenter>
          <CampaignTypeCard
            className="m-auto"
            onClick={() => {
              dispatch(setCampaignType('massTransmition'));
              setSelectedType('massTransmition');
            }}
            selected={selectedType === 'massTransmition'}
          >
            <CampaignTypeImg
              className="pt-2"
              src={require('../../../../assets/images/mass_message/location.svg')}
            />
            <div>
              <Text size={px2vw(16)} color={preto} alignCenter>
                Transmissão automática
              </Text>

              <Text
                size={px2vw(11)}
                color={preto}
                margtop={px2vw(7)}
                regular
                alignCenter
              >
                Envie mensagens para seus clientes comunicando novidades no
                cardápio, opções do dia e informações diversas.
              </Text>
            </div>
            <div style={{padding: '1.8rem'}} />
          </CampaignTypeCard>
        </Col>

        <Col width="auto" margleft="20px" alignCenter>
          <CampaignTypeCard
            className="m-auto"
            onClick={() => {
              dispatch(setCampaignType('massPromotion'));
              setSelectedType('massPromotion');
            }}
            selected={selectedType === 'massPromotion'}
          >
            <CampaignTypeImg
              className="pt-2"
              src={require('../../../../assets/images/kuppi_boost/wpp.png')}
            />
            <div>
              <Text size={px2vw(16)} color={preto} alignCenter>
                Promoção em massa
              </Text>

              <Text
                size={px2vw(11)}
                color={preto}
                margtop={px2vw(7)}
                regular
                alignCenter
              >
                Escolha o público envie campanhas de disparo vinculadas a alguma
                promoção para impulsionar suas vendas do dia.
              </Text>
            </div>

            <RecommendedTag>Recomendado</RecommendedTag>
          </CampaignTypeCard>
        </Col>
      </ModalContent>
      <ModalFooter>
        <ModalPrimaryButton
          onClick={handleGoToNextPage}
          disabled={!selectedType}
          className="ml-auto"
        >
          Avançar
        </ModalPrimaryButton>
      </ModalFooter>
    </>
  );
}
