import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setBalanceModalCurrentStep,
  setPixPaymentCode,
} from '../../../store/modules/kuppiBoost/actions';
import PaymentData from './Step1-PaymentData';
import PaymentConfirmation from './Step2-PaymentConfirmation';

import { ModalBackground, ModalContent } from './styles';

export default function AddBalance(props) {
  const dispatch = useDispatch();
  const kuppiBoostData = useSelector((state) => state.kuppiBoost);
  const { pix_payment_read_only } = kuppiBoostData
  const currentStep = kuppiBoostData?.balanceModalCurrentStep;

  useEffect(() => {
    if (pix_payment_read_only) {
      dispatch(setBalanceModalCurrentStep('2'));
    }

    return () => {
      dispatch(setBalanceModalCurrentStep('1'));
      dispatch(setPixPaymentCode(''));
    };
  }, []);

  return (
    <ModalBackground isVisible={props.isVisible}>
      <ModalContent
        width={props.width}
        height={props.height}
        bgcolor={props.bgcolor}
        margtop={props.margtop}
        padding={props.padding}
        backgroundImg={props.backgroundImg}
        backgroundImgSize={props.backgroundImgSize}
        maxheight={props.maxheight}
      >
        {currentStep === '1' && <PaymentData onClose={props.onClose} />}
        {currentStep === '2' && <PaymentConfirmation onClose={props.onClose} />}
      </ModalContent>
    </ModalBackground>
  );
}
