import React from 'react';
import { useSelector } from 'react-redux';
import px2vw from '../../../utils/responsiveness/px2vw';
import ChatBody from './ChatBody';
import ChatHeader from './ChatHeader';
import ChatTextBar from './ChatTextBar';
import { BgBanner, Span, Window } from './style';

export default function ChatWindow() {
  const { selectedConversation } = useSelector((state) => state.whatsappChat);

  if (!selectedConversation.id) {
    return (
      <BgBanner>
        <img
          alt="wppChatWindow"
          className="mx-auto"
          src={require('../../../assets/images/whatsapp_chat_window/wpp_chat_window.svg')}
        />
        <Span
          pb={px2vw(10)}
          className="mx-auto"
          mt={px2vw(10)}
          size={px2vw(19)}
        >
          Seu WhatsappWeb turbinado para Delivery
        </Span>
        <ul className="mx-auto">
          <li style={{ marginBottom: px2vw(12) }}>
            <Span size={px2vw(12)}>
              Envie e receba mensagens de uma forma completamente otimizada para
              sua operação de Delivery.
            </Span>
          </li>
          <li style={{ marginBottom: px2vw(12) }}>
            <Span size={px2vw(12)}>
              Crie pedidos manuais direto das conversas.
            </Span>
          </li>
          <li style={{ marginBottom: px2vw(12) }}>
            <Span size={px2vw(12)}>
              Todas as mensagens são recebidas e enviadas em tempo real.
            </Span>
          </li>
        </ul>
      </BgBanner>
    );
  }
  return (
    <Window justifyStart>
      <ChatHeader />
      <ChatBody />
      <ChatTextBar />
    </Window>
  );
}
