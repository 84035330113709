export function clearSuccess() {
  return {
    type: '@myStore/CLEAR_SUCCESS',
  };
}

export function updateStoreAvailabilityRequest(
  sunEnabled,
  sunHoraries,
  monEnabled,
  monHoraries,
  tueEnabled,
  tueHoraries,
  wedEnabled,
  wedHoraries,
  thuEnabled,
  thuHoraries,
  friEnabled,
  friHoraries,
  satEnabled,
  satHoraries,
) {
  return {
    type: '@myStore/UPDATE_STORE_AVAILABILITY_REQUEST',
    payload: {
      sunEnabled,
  sunHoraries,
  monEnabled,
  monHoraries,
  tueEnabled,
  tueHoraries,
  wedEnabled,
  wedHoraries,
  thuEnabled,
  thuHoraries,
  friEnabled,
  friHoraries,
  satEnabled,
  satHoraries,
    },
  };
}

export function updateStoreAvailabilitySuccess() {
  return {
    type: '@myStore/UPDATE_STORE_AVAILABILITY_SUCCESS',
  };
}

export function updateStoreAvailabilityFailure() {
  return {
    type: '@myStore/UPDATE_STORE_AVAILABILITY_FAILURE',
  };
}

export function updateStorePaymentMethodRequest(paymentMethods) {
  return {
    type: '@myStore/UPDATE_STORE_PAYMENT_METHOD_REQUEST',
    payload: {
      paymentMethods
    },
  };
}

export function updateStorePaymentMethodSuccess(advertiserPaymentMethods) {
  return {
    type: '@myStore/UPDATE_STORE_PAYMENT_METHOD_SUCCESS',
    payload: { advertiserPaymentMethods }
  };
}

export function updateStorePaymentMethodFailure() {
  return {
    type: '@myStore/UPDATE_STORE_PAYMENT_METHOD_FAILURE',
  };
}

export function updateStoreDeliveryTakeAwayRequest(
  orderMinimumAmount,
  orderMinimumAmountInputValue,
  takeAwayActive,
  takeAwayTimeAvgInputValue,
  deliveryActive,
  maxDistInputValue,
  useRadiusArea,
  useNeighborhoodArea,
  deliveryFee,
  deliveryFeeInputValue,
  dynamicDeliveryFee,
  dynamicDeliveryFeeAmountInputValue,
  dynamicMaxDistApplyInputValue,
  dynamicExceededFeeAmountInputValue,
  dynamicFreeDeliveryAtDistActiveInputValue,
  dynamicFreeDeliveryAtDistInputValue,
  dynamicFeeCalcTypeRouteInputValue,
  dynamicFeeCalcTypeRadiusInputValue,
  deliveryTimeAvg,
  deliveryTimeAvgInputValue
) {
  return {
    type: '@myStore/UPDATE_STORE_DELIVERY_TAKE_AWAY_REQUEST',
    payload: {
      orderMinimumAmount,
      orderMinimumAmountInputValue,
      takeAwayActive,
      takeAwayTimeAvgInputValue,
      deliveryActive,
      maxDistInputValue,
      deliveryFee,
      useRadiusArea,
      useNeighborhoodArea,
      deliveryFeeInputValue,
      dynamicDeliveryFee,
      dynamicDeliveryFeeAmountInputValue,
      dynamicMaxDistApplyInputValue,
      dynamicExceededFeeAmountInputValue,
      dynamicFreeDeliveryAtDistActiveInputValue,
      dynamicFreeDeliveryAtDistInputValue,
      dynamicFeeCalcTypeRouteInputValue,
      dynamicFeeCalcTypeRadiusInputValue,
      deliveryTimeAvg,
      deliveryTimeAvgInputValue,
    },
  };
}

export function updateStoreDeliveryTakeAwaySuccess() {
  return {
    type: '@myStore/UPDATE_STORE_DELIVERY_TAKE_AWAY_SUCCESS',
  };
}

export function updateStoreDeliveryTakeAwayFailure() {
  return {
    type: '@myStore/UPDATE_STORE_DELIVERY_TAKE_AWAY_FAILURE',
  };
}

export function getSelectedNeighborhoodsRequest() {
  return {
    type: '@myStore/GET_SELECTED_NEIGHBORHOODS_REQUEST',
  };
}

export function getSelectedNeighborhoodsSuccess(selectedNeighborhoods) {
  return {
    type: '@myStore/GET_SELECTED_NEIGHBORHOODS_SUCCESS',
    payload: { selectedNeighborhoods },
  };
}

export function getSelectedNeighborhoodsFailure() {
  return {
    type: '@myStore/GET_SELECTED_NEIGHBORHOODS_FAILURE',
  };
}

export function pauseNeighborhoodRequest(selectedNeighborhoodId, isActive) {
  return {
    type: '@myStore/PAUSE_NEIGHBORHOOD_REQUEST',
    payload: { selectedNeighborhoodId, isActive },
  };
}

export function pauseNeighborhoodSuccess(selectedNeighborhoods) {
  return {
    type: '@myStore/PAUSE_NEIGHBORHOOD_SUCCESS',
    payload: { selectedNeighborhoods },
  };
}

export function pauseNeighborhoodFailure() {
  return {
    type: '@myStore/PAUSE_NEIGHBORHOOD_FAILURE',
  };
}

export function updateNeighborhoodRequest(selectedNeighborhoodId, deliveryFee) {
  return {
    type: '@myStore/UPDATE_NEIGHBORHOOD_REQUEST',
    payload: { selectedNeighborhoodId, deliveryFee },
  };
}

export function updateNeighborhoodSuccess(selectedNeighborhoods) {
  return {
    type: '@myStore/UPDATE_NEIGHBORHOOD_SUCCESS',
    payload: { selectedNeighborhoods },
  };
}

export function updateNeighborhoodFailure() {
  return {
    type: '@myStore/UPDATE_NEIGHBORHOOD_FAILURE',
  };
}

export function deleteNeighborhoodRequest(selectedNeighborhoodId) {
  return {
    type: '@myStore/DELETE_NEIGHBORHOOD_REQUEST',
    payload: { selectedNeighborhoodId },
  };
}

export function deleteNeighborhoodSuccess(selectedNeighborhoods) {
  return {
    type: '@myStore/DELETE_NEIGHBORHOOD_SUCCESS',
    payload: { selectedNeighborhoods },
  };
}

export function deleteNeighborhoodFailure() {
  return {
    type: '@myStore/DELETE_NEIGHBORHOOD_FAILURE',
  };
}

export function getLocalitiesRequest(selectedState) {
  return {
    type: '@myStore/GET_LOCALITIES_REQUEST',
    payload: { selectedState },
  };
}

export function getLocalitiesSuccess(localities) {
  return {
    type: '@myStore/GET_LOCALITIES_SUCCESS',
    payload: { localities },
  };
}

export function getLocalitiesFailure() {
  return {
    type: '@myStore/GET_LOCALITIES_FAILURE',
  };
}

export function getDistrictsRequest(localityId) {
  return {
    type: '@myStore/GET_DISTRICTS_REQUEST',
    payload: { localityId },
  };
}

export function getDistrictsSuccess(districts) {
  return {
    type: '@myStore/GET_DISTRICTS_SUCCESS',
    payload: { districts },
  };
}

export function getDistrictsFailure() {
  return {
    type: '@myStore/GET_DISTRICTS_FAILURE',
  };
}

export function addNewNeighborhoodRequest(districtId, deliveryFee) {
  return {
    type: '@myStore/ADD_NEW_NEIGHBORHOOD_REQUEST',
    payload: { districtId, deliveryFee },
  };
}

export function addNewNeighborhoodSuccess(selectedNeighborhoods) {
  return {
    type: '@myStore/ADD_NEW_NEIGHBORHOOD_SUCCESS',
    payload: { selectedNeighborhoods },
  };
}

export function addNewNeighborhoodFailure() {
  return {
    type: '@myStore/ADD_NEW_NEIGHBORHOOD_FAILURE',
  };
}

export function updateStoreSchedulingAvailabilityRequest(
  sunEnabled,
  sunStartTime,
  sunEndTime,
  monEnabled,
  monStartTime,
  monEndTime,
  tueEnabled,
  tueStartTime,
  tueEndTime,
  wedEnabled,
  wedStartTime,
  wedEndTime,
  thuEnabled,
  thuStartTime,
  thuEndTime,
  friEnabled,
  friStartTime,
  friEndTime,
  satEnabled,
  satStartTime,
  satEndTime,
  schedulingMinTimeInputValue,
  schedulingMaxQtyInputValue,
  maxQtyAvailableSchedulesDaysInputValue,
  interval
) {
  return {
    type: '@myStore/UPDATE_STORE_SCHEDULING_AVAILABILITY_REQUEST',
    payload: {
      sunEnabled,
      sunStartTime,
      sunEndTime,
      monEnabled,
      monStartTime,
      monEndTime,
      tueEnabled,
      tueStartTime,
      tueEndTime,
      wedEnabled,
      wedStartTime,
      wedEndTime,
      thuEnabled,
      thuStartTime,
      thuEndTime,
      friEnabled,
      friStartTime,
      friEndTime,
      satEnabled,
      satStartTime,
      satEndTime,
      schedulingMinTimeInputValue,
      schedulingMaxQtyInputValue,
      maxQtyAvailableSchedulesDaysInputValue,
      interval
    },
  };
}

export function updateStoreSchedulingAvailabilitySuccess() {
  return {
    type: '@myStore/UPDATE_STORE_SCHEDULING_AVAILABILITY_SUCCESS',
  };
}

export function updateStoreSchedulingAvailabilityFailure() {
  return {
    type: '@myStore/UPDATE_STORE_SCHEDULING_AVAILABILITY_FAILURE',
  };
}

export function getAdvertiserPaymentMethodsRequest() {
  return {
    type: '@myStore/GET_ADVERTISER_PAYMENT_METHODS_REQUEST',
  };
}

export function getAdvertiserPaymentMethodsSuccess(advertiserPaymentMethods) {
  return {
    type: '@myStore/GET_ADVERTISER_PAYMENT_METHODS_SUCCESS',
    payload: { advertiserPaymentMethods },
  };
}

export function getAdvertiserPaymentMethodsFailure() {
  return {
    type: '@myStore/GET_ADVERTISER_PAYMENT_METHODS_FAILURE',
  };
}

export function clearSuccessDeliveryParamsOnboarding() {
  return {
    type: '@myStore/CLEAR_SUCCES_DELIVERY_PARAMS_ONBOARDING',
  };
}
