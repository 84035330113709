import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import PageOneWeb from './Web';
import PageOneMobile from './Mobile';

import { updateSurveyObjectivesRequest } from '../../../store/modules/firstSteps/actions';

export default function PageOne({ isWeb }) {
  const firstStepsSurveyData = useSelector(
    (state) => state.firstSteps.firstStepsSurveyData
  );
  const [objectiveIncreaseSales, setObjectiveIncreaseSales] = useState(false);
  const [
    objectiveReduceMarketplaceDependency,
    setObjectiveReduceMarketplaceDependency,
  ] = useState(false);
  const [
    objectiveOptimizeDeliveryOperation,
    setObjectiveOptimizeDeliveryOperation,
  ] = useState(false);
  const [
    objectiveGrowthMarketingStrategy,
    setObjectiveGrowthMarketingStrategy,
  ] = useState(false);
  const [objectiveFidelizeClients, setObjectiveFidelizeClients] = useState(false);
  const [
    objectiveNotSureExploringProduct,
    setObjectiveNotSureExploringProduct,
  ] = useState(false);

  const [ objective, setObjective ] = useState(firstStepsSurveyData.objective)
  const updateSurveyObjectivesLoading = useSelector(
    (state) => state.firstSteps.updateSurveyObjectiveLoading
  );

  const dispatch = useDispatch();

  const handleSurveyObjectivesSubmit = () => {
    if (
      !objectiveIncreaseSales &&
      !objectiveReduceMarketplaceDependency &&
      !objectiveOptimizeDeliveryOperation &&
      !objectiveGrowthMarketingStrategy &&
      !objectiveFidelizeClients &&
      !objectiveNotSureExploringProduct
    ) {
      toast.error('Escolha um objetivo!', {
        autoClose: 6000,
      });
    } else {
      dispatch(
        updateSurveyObjectivesRequest(
          'step2',
          objective
        )
      );
    }
  };

  useEffect(() => {
    setObjective(firstStepsSurveyData.objective);
    setObjectiveIncreaseSales(firstStepsSurveyData.objective === 'increase_sales')
    setObjectiveReduceMarketplaceDependency(firstStepsSurveyData.objective === 'reduce_marketplace_dependency')
    setObjectiveOptimizeDeliveryOperation(firstStepsSurveyData.objective === 'optimize_delivery_operation')
    setObjectiveGrowthMarketingStrategy(firstStepsSurveyData.objective === 'growth_marketing_strategy')
    setObjectiveFidelizeClients(firstStepsSurveyData.objective === 'fidelize_clients')
    setObjectiveNotSureExploringProduct(firstStepsSurveyData.objective === 'not_sure_exploring_product')  
  }, [firstStepsSurveyData]);

  function handleObjectiveOptionClick(selectedOption) {
    setObjective(selectedOption)
    setObjectiveIncreaseSales(selectedOption === 'increase_sales')
    setObjectiveReduceMarketplaceDependency(selectedOption === 'reduce_marketplace_dependency')
    setObjectiveOptimizeDeliveryOperation(selectedOption === 'optimize_delivery_operation')
    setObjectiveGrowthMarketingStrategy(selectedOption === 'growth_marketing_strategy')
    setObjectiveFidelizeClients(selectedOption === 'fidelize_clients')
    setObjectiveNotSureExploringProduct(selectedOption === 'not_sure_exploring_product')
  }

  const handler = {
    updateSurveyObjectivesLoading: updateSurveyObjectivesLoading,
    handleSurveyObjectivesSubmit: handleSurveyObjectivesSubmit,
    objectiveIncreaseSales: objectiveIncreaseSales,
    objectiveReduceMarketplaceDependency: objectiveReduceMarketplaceDependency,
    objectiveOptimizeDeliveryOperation: objectiveOptimizeDeliveryOperation,
    objectiveGrowthMarketingStrategy: objectiveGrowthMarketingStrategy,
    objectiveFidelizeClients: objectiveFidelizeClients,
    objectiveNotSureExploringProduct: objectiveNotSureExploringProduct,
    objectiveOptionClick: (clickedOption) => handleObjectiveOptionClick(clickedOption),
  }

  if (isWeb) {
    return <PageOneWeb handler={handler}/>
  } else {
    return <PageOneMobile handler={handler}/>
  }
}
