import styled  from 'styled-components';
import { branco, cinzaKuppi2, cinzaKuppi4, verdeKuppi1, verdeKuppi2 } from '../../styles/colors';

import px2vw from '../../utils/responsiveness/px2vw';

export const KuppiBoostBg = styled.img`
  position: absolute;
  border-radius: 0 20px 20px 0;
  height: ${px2vw(175)};
  right: 0;
  pointer-events: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  over-flow: hidden;
  z-index: 0;
`;

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: ${(props) => props.size || px2vw(28)};
  font-weight: bold;
  font-family: AvenirNextLTPro;
  font-weight: 700;
`;

export const MenuBox = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${branco};
  margin-left: ${px2vw(5)};
  border-radius: ${px2vw(20)} 0 ${px2vw(20)} ${px2vw(20)};
  min-height: ${px2vw(300)};
  box-shadow: 0px 8px 9px -2px ${cinzaKuppi4};
`;

export const TabsBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  .ml-auto {
    margin-left: auto;
  }
`;

export const TabBox = styled.div`
  display: block;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: ${(props) => (props.margL ? props.margL : px2vw(10))};
`;

export const Tab = styled.button`
  height: ${px2vw(35)};
  font-size: ${px2vw(15)};
  font-family: AvenirNextLTPro;
  letter-spacing: 0.48px;
  border: none;
  border-bottom: ${px2vw(5)} solid transparent;
  background-color: transparent;
  line-height: 1.5;
  font-weight: 700;

  ${(props) =>
    props.selected &&
    `
    border-bottom: ${px2vw(5)} solid ${verdeKuppi1};

  `};
`;

export const ButtonPrimary = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${branco};
  border: none;
  border-radius: ${(props) => props.radius || px2vw(5)};
  font-size: ${(props) => props.size || px2vw(13)};
  font-family: AvenirNextLTPro;
  font-weight: ${(props) => props.fontWeight || '600'};
  letter-spacing: 0.015em;
  padding: ${px2vw(8)};
  width: ${(props) => (props.width ? props.width : px2vw(320))};
  height: ${(props) => (props.height ? props.height : px2vw(44))};

  margin-top: ${(props) => (props.margtop ? props.margtop : 0)};
  margin-right: ${(props) => (props.margright ? props.margright : 0)};
  background: ${verdeKuppi2};

  &:active {
    box-shadow: 0px 1px 1.5px ${cinzaKuppi2};
    background: ${verdeKuppi1};
  }

  ${(props) =>
    props.disabled &&
    `
  background-color: ${cinzaKuppi4};
  color:${branco};

  &:active {
    box-shadow: 2px 2px 3px ${cinzaKuppi2};
    background: ${cinzaKuppi4};
  }

  `}
`;
