export const MainText = 'Configuração do seu Cardápio Digital';
export const UserNameText = 'Olá, @@name@@!';
export const SecondaryText = 'Você só precisa de 5 passos rápidos para configurar sua loja e começar a vender!';
// Step 1
export const step1HeaderText = '1º passo';
export const step1TimerAmountText = '1';
export const step1TitleText = 'Configuração do Perfil';
export const step1SubText = 'Deixe o cardápio digital com a cara da sua marca, do jeito que seus clientes gostam!';
export const step1BtnText = 'Configurar perfil';
// Step 2
export const step2HeaderText = '2º passo';
export const step2TimerAmountText = '2';
export const step2TitleText = 'Parâmetros do Delivery';
export const step2SubText = 'Configure os seus horários de funcionamento, formas de pagamento e área de entrega!';
export const step2BtnText = 'Configurar loja';
// Step 3
export const step3HeaderText = '3º passo';
export const step3TimerAmountText = '3';
export const step3TitleText = 'Catálogo';
export const step3SubText = 'Crie o seu cardápio ou importe ele do iFood em 15 segundos';
export const step3BtnText = 'Criar seu cardápio';
// Step 4
export const step4HeaderText = '4º passo';
export const step4TimerAmountText = '2';
export const step4TitleText = 'Conf. de Impressão';
export const step4SubText = 'Configure a sua impressora para imprimir pedidos da nossa plataforma';
export const step4BtnText = 'Configurar impressão';
// Step 5
export const step5HeaderText = '5º passo';
export const step5TimerAmountText = '1';
export const step5TitleText = 'Divulgue sua loja';
export const step5SubText = 'Divulgue sua loja no Instagram e Whatsapp, automatize seu atendimento';
export const step5BtnText = 'Divulgar loja';






