import React, { useState, useEffect } from 'react';
import { Spinner } from 'reactstrap';
import { FaFacebookSquare } from 'react-icons/fa';
import { FaInstagram } from 'react-icons/fa';
import { FaPen } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { Row, Col } from '../../styles/grid';
import { ContentDefault, Text } from '../../styles/common';
import {
  ButtonPrimary,
  KuppiBoostBg,
  NumberIndex,
  Title,
  VideoBox,
  MenuBox,
  TabsBox,
  Tab,
  TabBox,
  BalanceBox,
  BalanceIcon,
  MenuBar,
  StatusImg,
  MenuBarBox,
  Box,
  WarningImg,
} from './styles';
import { ContentPage } from '../_layouts/styles/common';
import { branco, cinzaKuppi4, preto, preto2 } from '../../styles/colors';
import {
  ModalSecundaryButton,
  ModalSecundaryButtonText,
} from '../../components/Modal/styles';

import px2vw from '../../utils/responsiveness/px2vw';
import { formatCurrencyBRL } from '../../utils/utils';

import {
  getKuppiBoost,
  getOnboardingData,
} from '../../store/modules/kuppiBoost/actions';

import Accordion from '../../components/Accordion';
import KuppiBoostModal from '../../components/KuppiBoost';
import AddBalanceModal from '../../components/KuppiBoost/addBalance';
import AccountLink from '../../components/KuppiBoost/AccountLink';
import Campaign from '../../components/KuppiBoost/Campaign';

import UnavailableContent from './UnavailableContent';
import AdsTable from './AdsTable';
import PaymentTable from './PaymentHistoryTable';
import StatusPage from './StatusPageContent';

export default function KuppiBoost() {
  const dispatch = useDispatch();
  const hasFreeTrialValid = useSelector((state) => state.user.hasFreeTrialValid);
  const kuppiBoostData = useSelector((state) => state.kuppiBoost);
  const didOnboardingFinished = kuppiBoostData.onboarding?.is_finished_onboarding;
  const {
    facebook_id,
    instagram_id,
    balance,
    campaigns,
    onboarding,
    onboarding_loading,
    payments_data,
    pix_payment_read_only,
  } = kuppiBoostData;
  const [selectedAccordion, setSelectedAccordion] = useState('');
  const [selectedTab, setSelectedTab] = useState('tabAdvertisement');
  const [isOnboardingModalVisible, setIsOnboardingModalVisible] =
    useState(false);
  const [isAccountLinkModalVisible, setIsAccountLinkModalVisible] =
    useState(false);
  const [isBalanceModalVisible, setIsBalanceModalVisible] = useState(false);
  const [isCampaignModalVisible, setIsCampaignModalVisible] = useState(false);

  useEffect(() => {
    dispatch(getOnboardingData());
    dispatch(getKuppiBoost());
  }, []);
  
  const handleTabSelected = (newTab) => {
    setSelectedTab(newTab);
  };

  const handleSelectAccordion = (newAccordionId) => {
    if (newAccordionId === selectedAccordion) {
      setSelectedAccordion('');
    } else {
      setSelectedAccordion(newAccordionId);
    }
  };

  const handleOpenAddBalanceModal = (type) => {
    if (type === 'addBalance') {
      const hasPendingPayment = payments_data.find((payment) => {
        return payment.status === 'waiting_payment' && payment.paid_at === null
      })
      if (hasPendingPayment) {
        toast.error('Você possui um pagamento pendente. Para adicionar mais saldo você deve: pagar ou cancelar o pagamento pendente na aba "Histórico de pagamento"', {
          autoClose: 6000,
        });
      } else {
        setIsBalanceModalVisible(true);
      }
    } else {
      setIsBalanceModalVisible(true);
    }
  }

  // Boost not available in Trial
  if (hasFreeTrialValid) {
    return <UnavailableContent hasFreeTrialValid={hasFreeTrialValid} />;
  }

  // Loading screen
  if (onboarding_loading || onboarding?.status === '') {
    return (
      <ContentPage>
        <Row height="100vh">
        <Spinner
          style={{
            width: '1.8rem',
            height: '1.8rem',
            color: preto,
          }}
          className="m-auto"
        />
        </Row>
      </ContentPage>
    );
  }

  // Onboarding pending
  if (
    onboarding?.status === 'pending_approval' ||
    onboarding?.status === 'bad_configuration' ||
    (onboarding?.status === 'ok' && !didOnboardingFinished)
  ) {
    return <StatusPage />;
  }

  // Onboarding start page / finished
  if (onboarding?.status === 'not_started' || onboarding?.status === 'ok') {
    return (
      <>
        <ContentPage minheight="auto">
          <Row alignStart justifyStart height="auto">
            <Title>Kuppi Boost</Title>
          </Row>

          <ContentDefault
            height={px2vw(didOnboardingFinished ? 110 : 250)}
            justifyStart
            alignStart
            overflowY
            className="mt-4 mb-3 p-0 position-relative"
          >
            <Col
              className="my-1"
              pad={px2vw(didOnboardingFinished ? 10 : 30)}
              width={px2vw(didOnboardingFinished ? 750 : 675)}
              justifyStart
            >
              {didOnboardingFinished ? (
                <>
                  <Text
                    size={px2vw(21)}
                    color={branco}
                    bold
                    className="ml-3"
                    margbottom={px2vw(15)}
                  >
                    Crie anúncios patrocinados e veja sua marca decolar no
                    digital.
                  </Text>
                  <Text
                    size={px2vw(15)}
                    margbottom={px2vw(10)}
                    color={branco}
                    regular
                    className="ml-3"
                  >
                    Os anúncios são veiculados no instagram e facebook, dentro
                    da sua área de entrega e do seu horário de funcionamento..
                  </Text>
                </>
              ) : (
                <>
                  <Text
                    size={px2vw(21)}
                    color={preto2}
                    bold
                    margbottom={px2vw(15)}
                  >
                    Fortaleça a presença da sua marca nas redes sociais e
                    aumente o engajamento e as vendas do seu delivery.
                  </Text>
                  <Text
                    size={px2vw(15)}
                    margtop={px2vw(15)}
                    margbottom={px2vw(15)}
                    color={preto2}
                    regular
                  >
                    Configure os seus acessos em 4 passos e passe a criar
                    anúncios patrocinados automaticamente.
                  </Text>
                  <ButtonPrimary
                    onClick={() => setIsOnboardingModalVisible(true)}
                    margtop={px2vw(16)}
                    width={px2vw(273)}
                    height={px2vw(37)}
                  >
                    Começar
                  </ButtonPrimary>
                </>
              )}
            </Col>
            <KuppiBoostBg
              isFinished={didOnboardingFinished}
              className="mr-auto"
              src={require('../../assets/images/kuppi_boost/kuppiboost_bg.png')}
            />
          </ContentDefault>

          {didOnboardingFinished && (
            <Row margbottom={px2vw(10)} height="auto" justifyStart>
              <Box width="100%" justifyContent>
                <Row height="auto" width="auto">
                  <WarningImg
                    src={require('../../assets/images/warning/warning.png')}
                  />
                </Row>
                <Col
                  className="ml-1"
                  height="auto"
                  alignCenter
                  justifyCenter
                  width="auto"
                  
                >
                  <Text size={px2vw(22)} margleft={px2vw(-100)} color={preto2} bold className="my-1">
                    Dica importante!
                  </Text>
                  <Text size={px2vw(16)} margleft={px2vw(-100)} color={preto2} regular>
                    O resultado do Kuppi Boost é diretamente relacionado a
                    qualidade do seu instagram.
                  </Text>
                </Col>
              </Box>
            </Row>
          )}

          {!didOnboardingFinished ? (
            <>
              <Row margbottom={px2vw(10)} height={px2vw(370)} justifyStart>
                <Box
                  pad={px2vw(30)}
                  height="100%"
                  className="d-flex flex-column"
                >
                  <Text
                    size={px2vw(21)}
                    className="mb-4"
                    color={preto2}
                    bold
                    justifyStart
                  >
                    Como funciona
                  </Text>

                  <Row justifyStart height="auto" margtop={px2vw(15)}>
                    <Text
                      size={px2vw(15)}
                      className="mb-4"
                      color={preto}
                      regular
                    >
                      <NumberIndex>1</NumberIndex> Configure os acessos.
                    </Text>
                  </Row>
                  <Row justifyStart height="auto" margtop={px2vw(15)}>
                    <Text
                      size={px2vw(15)}
                      className="mb-4"
                      color={preto}
                      regular
                    >
                      <NumberIndex>2</NumberIndex>Escolha o melhor template para
                      criarmos sua imagem.
                    </Text>
                  </Row>
                  <Row justifyStart height="auto" margtop={px2vw(15)}> 
                    <Text
                      size={px2vw(15)}
                      className="mb-4"
                      color={preto}
                      regular
                    >
                      <NumberIndex>3</NumberIndex> Crie o seu anúncio de forma
                      automática.
                    </Text>
                  </Row>
                  <Row justifyStart height="auto" margtop={px2vw(15)}>
                    <Text
                      size={px2vw(15)}
                      className="mb-4"
                      color={preto}
                      regular
                    >
                      <NumberIndex>4</NumberIndex> Veja sua marca bombar no
                      digital.
                    </Text>
                  </Row>
                </Box>

                <VideoBox className="position-relative">
                  <iframe
                    width="100%"
                    height="100%"
                    src="https://www.youtube.com/embed/zZcCc17khZM"
                    title="Kuppi Boost Video"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                </VideoBox>
              </Row>

              <Col margtop={px2vw(25)} height="auto">
                <Text size={px2vw(21)} className="mb-4" color={preto2} bold>
                  Perguntas Frequentes sobre o Kuppi Boost
                </Text>

                <Accordion
                  title="Como funciona o Kuppi Boost?"
                  selected={selectedAccordion === '1'}
                  onClick={() => handleSelectAccordion('1')}
                >
                  O Kuppi Boost é uma funcionalidade inovadora. Não existe no
                  mercado. Nós permitimos que você crie um anúncio patrocinado
                  no facebook e instagram de forma muito simples! Basta
                  selecionar o que você deseja impulsionar, e a gente monta uma
                  imagem e descrição do anúncio de forma automática. Em 1 minuto
                  você sobe uma campanha de tráfego pago.
                </Accordion>

                <Accordion
                  title="Vocês criam a imagem pra mim?"
                  selected={selectedAccordion === '2'}
                  onClick={() => handleSelectAccordion('2')}
                >
                  Sim, nosso robô cria uma imagem automaticamente em cima do
                  template que você escolher, e do produto que você definir
                  impulsionar. Além disso, você pode escolher as cores que mais
                  se encaixam com a sua identidade de marca.
                </Accordion>

                <Accordion
                  title="Como vocês definem a área em que a campanha será impulsionada?"
                  selected={selectedAccordion === '3'}
                  onClick={() => handleSelectAccordion('3')}
                >
                  Nós definimos essa área baseado na localização do seu
                  delivery, e do seu raio de entrega.
                </Accordion>

                <Accordion
                  title="A campanha roda o dia inteiro ou apenas no horário de funcionamento?"
                  selected={selectedAccordion === '4'}
                  onClick={() => handleSelectAccordion('4')}
                >
                  Nas configurações da campanha, nós pegamos o horário de
                  funcionamento do seu delivery! Então a campanha roda apenas
                  dentro do horário do seu delivery.
                </Accordion>
                <Accordion
                  title="Qual a diferença entre a campanha de Engajamento e Conversão?"
                  selected={selectedAccordion === '5'}
                  onClick={() => handleSelectAccordion('5')}
                >
                  Campanha de Engajamento: <br />
                  <br />
                  Serve para bombar o seu instagram. Ela é uma campanha mais
                  barata no facebook, pois o custo por engajamento costuma ser
                  de centavos. Para quem não tem muito capital para investir em
                  anúncio, é o tipo ideal de campanha, pois você aparece para
                  milhares de pessoas dentro da sua área de entrega. Os
                  seguidores de hoje são os clientes de amanhã.
                  <br />
                  <br />
                  Campanha de Conversão:
                  <br />
                  <br /> Essa é uma campanha que leva o cliente diretamente pro
                  seu cardápio digital, e é otimizada para compra. É um tipo de
                  campanha que é mais cara que a de engajamento, pois o custo
                  por compra costuma ser mais caro, dependendo do negócio. O
                  sucesso dessa campanha depende de outras variáveis, como: um
                  bom instagram, boas fotos, promoções e etc.
                </Accordion>
              </Col>
            </>
          ) : (
            <>
              <Row alignCenter spaceBetween height="auto">
                <MenuBar>
                  <StatusImg
                    className="ml-2"
                    src={require(`../../assets/images/kuppi_boost/${
                      facebook_id ? 'completed' : 'incomplete'
                    }.png`)}
                  />
                  <MenuBarBox
                    size={px2vw(15)}
                    color={preto}
                    justifyStart
                    regular
                    separator
                    width="auto"
                    className="pr-2"
                  >
                    <FaFacebookSquare size={px2vw(14)} className="mr-1 ml-2" />
                    ID: {facebook_id ? facebook_id : 'Não vinculado'}
                  </MenuBarBox>
                  <StatusImg
                    className="mr-2 ml-3"
                    src={require(`../../assets/images/kuppi_boost/${
                      instagram_id ? 'completed' : 'incomplete'
                    }.png`)}
                  />

                  <MenuBarBox
                    size={px2vw(15)}
                    color={preto}
                    justifyStart
                    regular
                    width="auto"
                  >
                    <FaInstagram size={px2vw(14)} className="mr-1 ml-2" />
                    ID: {instagram_id ? instagram_id : 'Não vinculado'}
                  </MenuBarBox>
                  <MenuBarBox
                    size={px2vw(15)}
                    height="100%"
                    cursor="pointer"
                    color={preto}
                    regular
                    width="17%"
                    flexEnd
                    className="ml-auto"
                    onClick={() => setIsAccountLinkModalVisible(true)}
                  >
                    <FaPen size={px2vw(12)} className="mx-2" />
                    Editar
                  </MenuBarBox>
                </MenuBar>
                <ModalSecundaryButton
                  width={px2vw(165)}
                  onClick={() => handleOpenAddBalanceModal('addBalance')}
                  type="button"
                  height={px2vw(38)}
                >
                  <ModalSecundaryButtonText>
                    + Adicionar saldo
                  </ModalSecundaryButtonText>
                </ModalSecundaryButton>
                <ButtonPrimary
                  disabled={!balance || (!facebook_id && !instagram_id)}
                  complete={balance && facebook_id && instagram_id}
                  onClick={() => setIsCampaignModalVisible(true)}
                  width={px2vw(150)}
                  size={px2vw(13)}
                  height={px2vw(38)}
                >
                  Criar anúncio
                </ButtonPrimary>
              </Row>
              <MenuBox>
                <Row
                  height="auto"
                  width="100%"
                  className="pt-3 pb-4 pr-3"
                  justifyStart
                  alignCenter
                  borderbottom={`2px solid ${cinzaKuppi4}`}
                >
                  <TabsBox>
                    <TabBox margL={px2vw(35)}>
                      <Tab
                        selected={selectedTab === 'tabAdvertisement'}
                        onClick={() => handleTabSelected('tabAdvertisement')}
                      >
                        Todos os anúncios
                      </Tab>
                    </TabBox>
                    <TabBox margL={px2vw(35)}>
                      <Tab
                        selected={selectedTab === 'tabHistoric'}
                        onClick={() => handleTabSelected('tabHistoric')}
                      >
                        Histórico de pagamento
                      </Tab>
                    </TabBox>
                  </TabsBox>
                  <BalanceBox className="ml-auto" balance={balance}>
                    {balance
                      ? `Você possui um saldo de ${formatCurrencyBRL(
                          balance,
                          2
                        )}`
                      : 'Você não possui saldo'}
                  </BalanceBox>
                </Row>
                <Col
                  className={
                    selectedTab === 'tabHistoric' ? 'mb-auto pb-3' : 'my-auto pl-3 pb-3'
                  }
                  alignCenter
                >
                  {selectedTab === 'tabHistoric' && (
                    <PaymentTable
                      cb={() => handleOpenAddBalanceModal('qrCode')}
                      data={payments_data}
                    />
                  )}

                  {selectedTab === 'tabAdvertisement' ? (
                    campaigns.length > 0 ? (
                      <AdsTable data={campaigns} />
                    ) : (
                      <>
                        <Row alignCenter>
                          <BalanceIcon
                            src={require(`../../assets/images/kuppi_boost/${
                              !facebook_id && !instagram_id
                                ? 'sad_id'
                                : balance
                                ? 'kuppiboost_happy'
                                : 'kuppiboost_sad'
                            }.png`)}
                          />
                          <Text size={px2vw(13)} color={preto} bold>
                            {!facebook_id && !instagram_id
                              ? 'Não é possível impulsionar sem um ID vinculado'
                              : balance
                              ? 'Você possui saldo e pode utilizar o Kuppi Boost'
                              : 'Você não possui saldo para utilizar o Kuppi Boost'}
                          </Text>
                        </Row>
                        {!balance && (
                          <Row margtop={px2vw(25)}>
                            <ButtonPrimary
                              width={px2vw(220)}
                              height={px2vw(40)}
                              size={px2vw(13)}
                              onClick={() => handleOpenAddBalanceModal('addBalance')}
                              flex
                            >
                              <BalanceIcon
                                height={px2vw(25)}
                                width={px2vw(25)}
                                src={require('../../assets/images/kuppi_boost/desk.png')}
                              />
                              Adicionar Saldo
                            </ButtonPrimary>
                          </Row>
                        )}
                      </>
                    )
                  ) : (
                    ''
                  )}
                </Col>
              </MenuBox>
            </>
          )}

          {isOnboardingModalVisible && (
            <KuppiBoostModal
              minheight="100%"
              width={px2vw(750)}
              padding="none"
              onClose={() => setIsOnboardingModalVisible(false)}
              isVisible
            />
          )}

          {isBalanceModalVisible && (
            <AddBalanceModal
              height={px2vw(450)}
              width={px2vw(500)}
              padding="none"
              readOnly={pix_payment_read_only}
              onClose={() => setIsBalanceModalVisible(false)}
              isVisible
            />
          )}

          {isCampaignModalVisible && (
            <Campaign
              height={px2vw(565)}
              width={px2vw(700)}
              padding="none"
              onClose={() => setIsCampaignModalVisible(false)}
              isVisible
            />
          )}

          {isAccountLinkModalVisible && (
            <AccountLink
              padding="none"
              onClose={() => setIsAccountLinkModalVisible(false)}
              isVisible
            />
          )}
        </ContentPage>
      </>
    );
  }
}
