import styled from 'styled-components';

import px2vw from '../../../../utils/responsiveness/px2vw';

export const TextRegular = styled.div`
  height: ${px2vw(40)};
  font-size: ${px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.36px;

  margin-left: ${(props) => props.margleft};
  width: ${(props) => props.width};
  ${(props) => props.alignCenter &&`
    text-align: center;
  `};
`;

export const SchemaImage = styled.img`
  width: ${px2vw(50)};
  height: ${px2vw(26)};
  ${(props) => `
    width: ${props.width};
    height: ${props.height};
    margin-left: ${props.margleft};
    margin-top: ${props.margtop};
  `};
`;

export const TextRegular2 = styled.div`
  font-size: ${px2vw(8)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.36px;
  text-align: center;

  margin-left: ${(props) => props.margleft};
`;
