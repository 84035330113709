import { takeLatest, call, put, all, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { apiAnunciante } from '../../../services/api';

import {
  getLoyaltyCardSuccess,
  getLoyaltyCardFailure,
  getLoyaltyCardMetricsSuccess,
  getLoyaltyCardMetricsFailure,
  getLoyaltyCardHistorySuccess,
  getLoyaltyCardHistoryFailure,
  updateLoyaltyCardSuccess,
  updateLoyaltyCardFailure,
} from './actions';
import {
  logout
} from '../auth/actions';

import { sendLoyaltyCard } from '../../../services/vendors/mixpanel';

const userReducerData = (state) => state.user;
const userData = (state) => state.user.user;

export function* getLoyaltyCardRequest() {
  try {
    const user = yield select(userData);

    const response = yield call(
      apiAnunciante.get,
      `/user-info/${user.advertiserInfo.id}/loyalty-card`
    );

    yield put(getLoyaltyCardSuccess(response.data));
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(getLoyaltyCardFailure());
    }
  }
}

export function* getLoyaltyCardMetricsRequest() {
  try {
    const user = yield select(userData);

    const response = yield call(
      apiAnunciante.get,
      `/user-info/${user.advertiserInfo.id}/loyalty-card/metrics`
    );

    yield put(getLoyaltyCardMetricsSuccess(response.data));
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(getLoyaltyCardMetricsFailure());
    }
  }
}

export function* getLoyaltyCardHistoryRequest() {
  try {
    const user = yield select(userData);

    const response = yield call(
      apiAnunciante.get,
      `/user-info/${user.advertiserInfo.id}/loyalty-card/use-history`
    );

    yield put(getLoyaltyCardHistorySuccess(response.data));
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(getLoyaltyCardHistoryFailure());
    }
  }
}

export function* updateLoyaltyCardRequest({ payload }) {
  try {
    let minimumTicketInputValue = 0;
    if (payload.hasMinimumTicket && payload.minimumTicketInputValue !== '') {
      minimumTicketInputValue = payload.minimumTicketInputValue;
    }

    let minimumOrderAmountValue = 0;
    if (payload.hasMinimumOrderAmount && payload.minimumOrderAmountValue !== '') {
      minimumOrderAmountValue = payload.minimumOrderAmountValue;
    }

    let discountPercentage = 0;
    if (payload.discountBenefit && payload.discountBenefitInputValue !== '') {
      discountPercentage = payload.discountBenefitInputValue;
    }

    let creditFixed = 0;
    if (payload.creditBenefit && payload.creditBenefitInputValue !== '') {
      creditFixed = payload.creditBenefitInputValue;
    }

    const user = yield select(userData);
    const userReducer = yield select(userReducerData);
    const response = yield call(
      apiAnunciante.put,
      `/user-info/${user.advertiserInfo.id}/loyalty-card`,
      {
        is_active: payload.loyaltyCardActive,
        amount_of_purchases: payload.qtyOrdersActiveBenefit,
        expiry_at: payload.daysToExpiration,
        is_accept_delivery: payload.isAcceptDelivery,
        is_accept_physical: payload.isAcceptPhysical,
        is_ticket_minimum_active: payload.hasMinimumTicket,
        ticket_minimum_amount: minimumTicketInputValue,
        is_ticket_minimum_retrieval: payload.hasMinimumOrderAmount,
        ticket_minimum_amount_retrieval: minimumOrderAmountValue,
        cart_discount_fixed: creditFixed,
        cart_discount_percentage: discountPercentage,
      }
    );

    yield put(updateLoyaltyCardSuccess(response.data));
    toast.success('Cartão fidelidade atualizado com sucesso!');
    //MixPanel Event
    sendLoyaltyCard({
      id: user.advertiserInfo.id,
      name: user.representative_name,
      email: user.email,
      cartDiscountPercentage: discountPercentage,
      cartDiscountFixed: creditFixed,
      isActive: payload.loyaltyCardActive,
      hasFreeTrialValid: userReducer.hasFreeTrialValid
    })

  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(updateLoyaltyCardFailure());
      toast.error(
        `Ocorreu um erro ao atualizar o cartão fidelidade. Tente novamente!`,
        {
          autoClose: 6000,
        }
      );
    }
  }
}

export default all([
  takeLatest('@loyaltyCard/GET_LOYALTY_CARD_REQUEST', getLoyaltyCardRequest),
  takeLatest(
    '@loyaltyCard/GET_LOYALTY_CARD_METRICS_REQUEST',
    getLoyaltyCardMetricsRequest
  ),
  takeLatest(
    '@loyaltyCard/GET_LOYALTY_CARD_HISTORY_REQUEST',
    getLoyaltyCardHistoryRequest
  ),
  takeLatest(
    '@loyaltyCard/UPDATE_LOYALTY_CARD_REQUEST',
    updateLoyaltyCardRequest
  ),
]);
