import React from 'react';

import styled from 'styled-components';

import { Input } from '@rocketseat/unform';

import { Switch } from '@material-ui/core/';
import { withStyles } from '@material-ui/core/styles';

import px2vw from '../../../utils/responsiveness/px2vw';
import px2em from '../../../utils/responsiveness/px2em';

import {
  preto,
  branco,
  verdeKuppi2,
  cinzaKuppi1,
  cinzaKuppi4,
  cinzaKuppi9,
  cinzaKuppi5,
  preto2,
  verdeKuppi1,
} from '../../../styles/colors';

export const Content = styled.div`
  width: 100vw;
  height: 100vh;
`;

export const ContentPage = styled.div`
  float: right;
  width: ${px2vw(1028)};
  height: auto;
  min-height: ${px2vw(620)};
  background-color: ${cinzaKuppi1};

  ${(props) => `
    min-height: ${props.minheight};
  `};

  ${(props) => props.margleft && `
    margin-left: ${props.margleft};
  `};
`;

export const PageTitle = styled.div`
  font-size: ${px2vw(25)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.72px;
  font-weight: bold;

  ${(props) =>
    props.dashboard &&
    `
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 17ch;
  `};

  ${(props) =>
    !props.dashboard &&
    `
    display: flex;
    flex-direction: row;
  `};
`;

export const ErrorText = styled.div`
  font-size: ${px2vw(18)};
  ${(props) => `font-size: ${props.fontSize}`};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.4px;
  margin-left: ${px2vw(20)};
`;

export const SubtitleBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
`;

export const SubtitleButton = styled.button`
  color: ${preto};
  font-size: ${px2vw(16)};
  font-family: AvenirNextLTPro;
  font-weight: 600;
  letter-spacing: 0.36px;
  border: none;
  background-color: transparent;

  ${(props) => `
    margin-left: ${props.margleft};
  `};
`;

export const SubtitleImage = styled.img`
  height: ${px2vw(12)};
  width: ${px2vw(8)};
  margin-left: ${px2vw(10)};
`;

export const SwitchButtonBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  padding-left: ${px2vw(12)};

  ${(props) => `
    width: ${props.width};
    margin-top: ${props.margtop};
    margin-left: ${props.margleft};
  `};
`;

export const SwitchLabelBoldText = styled.div`
  font-size: ${px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.36px;

  ${(props) => `
    width: ${props.width};
    margin-bottom: ${props.margboottom};
  `};
`;

export const SwitchLabelRegularText = styled.div`
  font-size: ${(props) => props.isMobileOnboarding ? px2vw(60) : px2vw(11)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.36px;
  min-width: ${px2vw(55)};

  ${(props) =>
    props.isChecked &&
    `
    color: ${branco};
  `};

  ${(props) => `
    width: ${props.width};
    margin-right: ${props.margright};
    margin-left: ${props.margleft};
    margin-top: ${props.margtop};
    font-size: ${props.fontSize};
  `};
`;

export const SwitchController = withStyles((theme) => ({
  root: {
    width: px2vw(33),
    height: px2vw(19),
    padding: 0,
    margin: 0,
    marginRight: px2vw(8),
  },
  switchBase: {
    padding: 1.5,
    '&$checked': {
      transform: `translateX(${px2vw(13)})`,
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: `${verdeKuppi2}`,
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: `${px2vw(6)} solid #fff`,
    },
  },
  thumb: {
    width: `${px2vw(16)}`,
    height: `${px2vw(16)}`,
  },
  track: {
    borderRadius: `${px2vw(15)}`,
    backgroundColor: theme.palette.grey[400],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

export const SwitchWithInput = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${px2vw(8)};
  min-height: ${px2vw(40)};

  ${(props) => `
    margin-left: ${props.margleft};
    margin-bottom: ${props.marginBottom};
    margin-top: ${props.margtop};
    min-width: ${props.minWidth};
    min-height: ${props.minHeight};
    flex-direction: ${props.flexDir};
  `};
`;

export const SwitchWithInputBox = styled.div`
  display: none;
  height: ${px2vw(40)};

  ${(props) =>
    props.isVisible &&
    `
    display: block;
  `};

  ${(props) => `
    margin-right: ${props.marginRight};
    margin-left: ${props.margleft};
  `};
`;

export const SwitchInput = styled(Input)`
  padding: ${px2em(8)} !important;
  margin-left: ${px2em(4)} !important;
  margin-right: ${px2em(4)} !important;
  margin-top: ${px2em(1)} !important;
  border: 1px solid ${cinzaKuppi4} !important;
  border-radius: ${px2vw(5)} !important;
  height: ${px2vw(40)} !important;
  font-size: ${px2vw(14)} !important;
  font-family: AvenirNextLTPro !important;
  font-weight: normal;
  letter-spacing: 0.36px !important;
  width: ${px2vw(60)} !important;

  ::placeholder {
    color: ${cinzaKuppi5} !important;
    font-size: ${px2vw(12)} !important;
  }

  :focus {
    border: 1px solid ${preto2} !important;
    box-shadow: none !important;
  }

  ${(props) => `
    width: ${props.width} !important;
    margin-top: ${props.margtop} !important;
    margin-right: ${props.margright} !important;
  `};

  ${(props) =>
    props.disabled &&
    `
      color: ${preto}60;
  `};
`;

export const LoadingBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const LogoImg = styled.img`
  width: ${px2vw(70)};
  height: ${px2vw(70)};
  border: solid 1px ${cinzaKuppi9};
  border-radius: ${px2vw(35)};
`;

export const PaginateContainer = styled.div`
  padding-top: ${px2vw(12)};

  .pagination {
    color: ${verdeKuppi1};
  }

  .page {
    text-align: center;
    border: 1px solid ${preto};
    border-radius: ${px2vw(5)};
    width: ${px2vw(28)};
    height: ${px2vw(27)};
    color: ${preto};
    padding-top: ${px2vw(3)};
    margin-left: ${px2vw(2)};
    margin-right: ${px2vw(2)};
    font-size: ${px2vw(13)};
    font-family: AvenirNextLTPro;
    font-weight: 700;
    letter-spacing: 0.36px;
  }

  .break_cn {
    cursor: default;
    margin-top: ${px2vw(-2)};
    margin-left: ${px2vw(2)};
    margin-right: ${px2vw(2)};
    font-size: ${px2vw(16)};
    font-family: AvenirNextLTPro;
    font-weight: 700;
    letter-spacing: 0.48px;
    color: ${preto};
  }

  .active {
    border: 1px solid ${verdeKuppi1};
    background-color: ${verdeKuppi1};
    color: ${branco};
  }

  .previous_cn, .next_cn {
    text-align: center;
    cursor: default;
    margin-left: ${px2vw(2)};
    margin-right: ${px2vw(2)};
    font-size: ${px2vw(15)};
    font-family: AvenirNextLTPro;
    font-weight: 700;
    letter-spacing: 0.48px;
    color: ${preto};
    padding-top: ${px2vw(1.2)};
    border: 1px solid ${preto};
    border-radius: ${px2vw(5)};
    width: ${px2vw(28)};
    height: ${px2vw(27)};
  }

  .previous_a_cn, .next_a_cn {
    text-decoration: none;
    color: ${preto};
    :hover {
      color: ${preto};
    }
  }

  .disabled_cn {
    color: ${cinzaKuppi4};
    border: 1px solid ${cinzaKuppi4};
    cursor: not-allowed;
  }

  .disabled_a_cn {
    color: ${cinzaKuppi4};
    cursor: not-allowed;
    text-decoration: none;
    :hover {
      color: ${cinzaKuppi4};
    }
  }
`;
