import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import 'moment/locale/pt-br';
import 'react-circular-progressbar/dist/styles.css';

import { sendCreateProfile } from '../../services/vendors/mixpanel';
import { AppCuesIdentify } from '../../services/vendors/appcues';
import { Text } from '../../styles/common';
import { branco } from '../../styles/colors';
import { ButtonPrimary } from './styles';
import { ContentPage } from '../_layouts/styles/common';
import { Col, Row } from '../../styles/grid';

import { massMessage } from '../../routes/routeMap';
import history from '../../services/history';
import px2vw from '../../utils/responsiveness/px2vw';

import {
  getOrdersRequest,
  getOrdersClearSuccess,
} from '../../store/modules/orders/actions';

import {
  getOrdersMetricsRequest,
  getGameficationRequest,
} from '../../store/modules/dashboardMetrics/actions';

import Gamefication from './Gamification';
import Reports from './Reports';
import TermsAndPoliciesModal from '../../components/TermsAndPoliciesModal';

export default function Dashboard() {
  const hasFreeTrialValid = useSelector(
    (state) => state.user.hasFreeTrialValid
  );
  const st = useSelector(
    (state) => state
  );
  const userData = useSelector((state) => state.user);
  const user = useSelector((state) => state.user.user);
  const advPlan = user.advertiserInfo.advertiserSubscription;
  const getOrdersSuccess = useSelector(
    (state) => state.orders.getOrdersSuccess
  );
  const dispatch = useDispatch();
  // Initialize Appcues
  useEffect(() => {
    AppCuesIdentify(userData);
  }, [window.Appcues]);

  useEffect(() => {
    dispatch(getOrdersMetricsRequest(true));
    dispatch(getGameficationRequest());

    //Mixpanel Event
    sendCreateProfile({
      id: user.advertiserInfo.id,
      email: user.email,
      name: user.advertiserInfo.name,
      created_at: user.created_at,
      city: user.address[0].city,
      state: user.address[0].state,
      advertiserCategory: user.advertiserInfo.advertiserCategory.name,
      advertiserPlan: advPlan[advPlan.length - 1].subscription.name,
      advertiserPlanAmount: advPlan[advPlan.length - 1].subscription.amount,
      hasFreeTrialValid: hasFreeTrialValid,
    });
  }, []);

  useEffect(() => {
    if (getOrdersSuccess) {
      dispatch(getOrdersClearSuccess());
    }
  }, [getOrdersSuccess, dispatch]);

  return (
    <>
      <ContentPage>
        <Row spaceBetween width="100%" height="auto">
          <Gamefication />
        </Row>
        <Col
          justifyStart
          pad={px2vw(21)}
          margtop={px2vw(20)}
          borderRadius={px2vw(10)}
          background="linear-gradient(172.89deg, #316135 -11.6%, #3E393E 105.34%, #379942 106.62%)"
          height={px2vw(140)}
          width="100%"
          relative
        >
          <img src={require('../../assets/images/dashboard_icon/mass_message.svg')} style={{position: 'absolute', right: px2vw(30), bottom: 0, height: px2vw(138)}} />
          <Col margbottom={px2vw(5)} height="auto" justifyStart>
            <Text size={px2vw(22)} margbottom={px2vw(10)} color={branco} bold>
              Aumente as suas vendas com o Disparo em Massa no WhatsApp
            </Text>
          </Col>

          <Col relative height="auto" width="80%" justifyStart>
            <Text size={px2vw(16)} regular margbottom={px2vw(1)} color={branco}>
              Em média, cada{' '}
              <strong style={{ margin: `0 ${px2vw(5)}` }}>
                R$ 10 investidos
              </strong>{' '}
              retornam{' '}
              <strong style={{ margin: `0 ${px2vw(5)}` }}>R$ 75 em vendas</strong>
            </Text>
            <Text size={px2vw(16)} regular color={branco}>
              Avaliação de satisfação dos clientes é nota
              <strong style={{ margin: `0 ${px2vw(5)}` }}>9,2</strong> de{' '}
              <strong style={{ margin: `0 ${px2vw(5)}` }}>10</strong>
            </Text>

            <ButtonPrimary
              width={px2vw(250)}
              size={px2vw(14)}
              height={px2vw(38)}
              onClick={() => history.push(massMessage)}
              style={{
                position: 'absolute',
                right: 0,
                top: '50%',
                transform: 'translateY(-50%)',
                fontWeight: 700,
                letterSpacing: '2%',
                borderRadius: px2vw(8)
              }}
            >
              Criar disparo em massa agora
            </ButtonPrimary>
          </Col>
        </Col>
        <Reports />
      </ContentPage>
      {/* Show terms and policies modal even the onboarding is done */}
      {!user.advertiserInfo.is_terms_accepted && (
        <TermsAndPoliciesModal
          isVisible={!user.advertiserInfo.is_terms_accepted}
        />
      )}
    </>
  );
}
