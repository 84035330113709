import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setCampaignCurrentStep,
  setCampaignType,
  whatsappCheckRequest,
} from '../../../../store/modules/kuppiBoost/actions';
import { toast } from 'react-toastify';

import { preto, vermelhoKuppi1 } from '../../../../styles/colors';
import { Text } from '../../../../styles/common';

import px2vw from '../../../../utils/responsiveness/px2vw';
import { ModalPrimaryButton } from '../styles';
import { Col } from '../../../../styles/grid';

import {
  ModalContent,
  CloseButton,
  ModalHeader,
  CloseImage,
  CampaignTypeCard,
  CampaignTypeImg,
  ModalFooter,
  ToolTipCustom,
} from './styles';

export default function CampaignType(props) {
  const dispatch = useDispatch();
  const kuppiBoostData = useSelector((state) => state.kuppiBoost);
  const { balance, has_whatsapp, new_page_experience, onboarding } = kuppiBoostData;
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [selectedType, setSelectedType] = useState(
    balance >= 500 ? 'conversion' : balance >= 100 ? 'range' : ''
  );
  const [enableWhatsappCampaing, setEnableWhatsappCampaing] = useState(false);
  const [wppStatus, setWppStatus] = useState(null);

  useEffect(() => {
    if (has_whatsapp !== null) {
      if (has_whatsapp || onboarding.whatsapp_fb_linked_status === 'ok') {
        setWppStatus('ok')
        setEnableWhatsappCampaing(true)
      } else {
        setEnableWhatsappCampaing(false);
        if (!new_page_experience || onboarding.whatsapp_fb_linked_status === 'not_linked') {
          setWppStatus('not_linked');
        } else {
          setWppStatus('pending_approval');
        }
      }
    }
  }, [has_whatsapp, new_page_experience]);

  function generateWhatsappValidationErrorMessage() {
    if (wppStatus === 'pending_approval') {
      return 'Nao foi possivel validar automaticamente que a sua página possui whatsapp vinculado. Nossa equipe fará essa validação manualmente. Por favor aguarde!'
    }

    return 'Para habilitar esse modo de campanha, é necessário vincular o seu whatsapp a página do facebook cadastrada no Kuppi Boost!'
  }

  const handleToggle = () => setTooltipOpen(!tooltipOpen);

  const handleHasWhatsapp = () => {
    if (wppStatus === null) {
      dispatch(whatsappCheckRequest());
    } else if (onboarding.whatsapp_fb_linked_status === 'not_validated') {
      toast.error(
        generateWhatsappValidationErrorMessage(),
        {
          autoClose: 6000,
        }
      );
    }
  }

  function handleGotoNextStep() {
    dispatch(setCampaignType(selectedType));
    dispatch(setCampaignCurrentStep('2'));
  }
  return (
    <>
      <ModalHeader>
        Qual o objetivo da sua campanha?
        <CloseButton onClick={props.onClose}>
          <CloseImage
            src={require('../../../../assets/images/close/close.png')}
          />
        </CloseButton>
      </ModalHeader>
      <ModalContent>
        <Col width="auto" alignCenter>
          <CampaignTypeCard
            onClick={() =>
              balance < 500 ? null : setSelectedType('conversion')
            }
            selected={selectedType === 'conversion'}
          >
            <CampaignTypeImg
              src={require('../../../../assets/images/kuppi_boost/conversao.png')}
            />
            <Text size={px2vw(16)} color={preto} alignCenter>
              Conversão
            </Text>
            <Text
              size={px2vw(11)}
              color={preto}
              margtop={px2vw(7)}
              regular
              alignCenter
            >
              Campanhas de conversão costumam ser mais "caras", pois o seu
              resultado é baseado em eventos de conversão do seu cardápio
              digital.
            </Text>
          </CampaignTypeCard>
          {balance < 500 && (
            <Text
              size={px2vw(10)}
              color={vermelhoKuppi1}
              margtop={px2vw(7)}
              regular
              alignCenter
            >
              * Saldo mínimo necessário: R$500.00
            </Text>
          )}
        </Col>
        <Col width="auto" alignCenter>
          <CampaignTypeCard
            disabled={balance < 100}
            onClick={() => setSelectedType('range')}
            selected={selectedType === 'range'}
          >
            <CampaignTypeImg
              className="pt-2"
              src={require('../../../../assets/images/kuppi_boost/maps.png')}
            />
            <Text size={px2vw(16)} color={preto} alignCenter>
              Engajamento
            </Text>
            <Text
              size={px2vw(11)}
              color={preto}
              margtop={px2vw(7)}
              regular
              alignCenter
            >
              Campanhas de Engajamento servem para bombar o instagram e facebook
              do seu delivery. Nessa campanha, o resultado em vendas costuma ser
              indireto. Não vem necessariamente durante o período da campanha.
            </Text>
          </CampaignTypeCard>
          {balance < 100 && (
            <Text
              size={px2vw(10)}
              color={vermelhoKuppi1}
              margtop={px2vw(7)}
              regular
              alignCenter
            >
              * Saldo mínimo necessário: R$100.00
            </Text>
          )}
        </Col>
        <Col width="auto" alignCenter>
          <CampaignTypeCard
            disabled={!enableWhatsappCampaing}
            onClick={() => !enableWhatsappCampaing ? handleHasWhatsapp() : setSelectedType('whatsapp')}
            style={{cursor: !enableWhatsappCampaing ? 'not-allowed' : 'pointer' }}
            selected={selectedType === 'whatsapp'}
            id="helpIcon"
          >
            <CampaignTypeImg
              className="pt-2"
              src={require('../../../../assets/images/kuppi_boost/wpp.png')}
            />
            <Text size={px2vw(16)} color={preto} alignCenter>
              Whatsapp
            </Text>
            {!enableWhatsappCampaing && (
              <ToolTipCustom
                placement="bottom"
                isOpen={tooltipOpen}
                target="helpIcon"
                toggle={handleToggle}
              >
                {generateWhatsappValidationErrorMessage()}
              </ToolTipCustom>
            )}

            <Text
              size={px2vw(11)}
              color={preto}
              margtop={px2vw(7)}
              regular
              alignCenter
            >
              Essa campanha é otimizada para recebimento de mensagens no
              whatsapp do seu delivery. Todos os usuários impactados pela
              campanha, quando clicarem no anúncio, serão jogados para o seu
              whatsapp com uma mensagem pronta.
            </Text>
          </CampaignTypeCard>
        </Col>
      </ModalContent>
      <ModalFooter>
        <ModalPrimaryButton onClick={handleGotoNextStep} className="ml-auto">
          Avançar
        </ModalPrimaryButton>
      </ModalFooter>
    </>
  );
}
