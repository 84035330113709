import React from 'react';

import styled, { css } from 'styled-components';

import { Input } from '@rocketseat/unform';

import { Switch } from '@material-ui/core/';
import { withStyles } from '@material-ui/core/styles';

import {
  preto,
  preto2,
  branco,
  cinzaKuppi4,
  cinzaKuppi7,
  verdeKuppi2,
  cinzaKuppi11,
  cinzaKuppi2,
  verdeKuppi1,
  verdeKuppi10,
} from '../../../styles/colors';

import px2vw from '../../../utils/responsiveness/px2vw';
import px2em from '../../../utils/responsiveness/px2em';

export const Container = styled.div`
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  min-height: fill-available;
  max-height: inherit;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 0.3em;
    border-radius: 3px;
  }
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${verdeKuppi2};
    outline: 1px solid ${verdeKuppi2};
  }
`;

export const ButtonPrimary = styled.button`
  background: ${verdeKuppi2};
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 ${px2vw(8)};
  color: ${branco};
  border-radius: ${px2vw(5)};
  font-size: ${px2vw(13)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.32px;
  padding: ${px2vw(8)};
  width: ${px2vw(320)};
  box-shadow: 2px 2px 3px ${cinzaKuppi2};
  &:hover {
    box-shadow: 2px 2px 3px ${cinzaKuppi2};
  }

  &:active {
    text-decoration: none;
    background: ${verdeKuppi1};
    box-shadow: 2px 2px 3px ${cinzaKuppi2};
  }

  &:disabled {
    text-decoration: none;
    background: ${cinzaKuppi2};
    box-shadow: none;
    color: ${branco};
  }
`;

export const ModalFooter = styled.footer`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${px2vw(26)};
  border: 1px solid ${cinzaKuppi4};

  margin-top: auto;
  min-height: ${px2vw(100)};
  width: 100%;
`;

export const Span = styled.span`
  cursor: ${(props) => (props.cursor ? props.cursor : 'initial')};
  padding: ${(props) => (props.padding ? props.padding : '0')};
  font-size: ${(props) => (props.fontSize ? props.fontSize : px2vw(13))};
  font-family: AvenirNextLTPro;
  letter-spacing: 0.36px;
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 'bold')};
  display: ${(props) => (props.display ? props.display : 'block')};
  color: ${(props) => (props.color ? props.color : verdeKuppi2)};
  @media (max-width: 500px) {
    font-size: ${px2vw(20)};
  }
  .badge {
    background: ${cinzaKuppi11};
    font-size: ${px2vw(13)};
  }
`;

export const ModalHeader = styled.header`
  display: flex;
  justify-content: space-between;
  background-color: ${verdeKuppi2};
  align-items: center;
  width: 100%;
  letter-spacing: 0.36px;
`;

export const ManualOrderHeader = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${verdeKuppi2};
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
  border-color: ${verdeKuppi2};
  padding: ${px2vw(13)} ${px2vw(20)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  color: ${branco};
  font-size: ${px2vw(15.5)};
  display: flex;
  align-items: space-between;
  justify-content: space-between;
`;

export const ProgressBar = styled.div`
  background-color: ${cinzaKuppi11};
  width: 100%;
`;

export const CloseButton = styled.button`
  width: ${px2vw(18)};
  height: ${px2vw(18)};
  border: none;
  z-index: 10;
  background-color: transparent;

  :focus {
    background-color: transparent !important;
  }

  ${(props) =>
    props.absolute &&
    css`
      position: absolute;
      top: ${props.top || px2vw(10)};
      right: ${props.right || px2vw(10)};
    `}
`;

export const CloseImage = styled.img`
  width: ${px2vw(17)};
  height: ${px2vw(17)};
`;

export const PinBtn = styled.img`
  position: absolute;
  top: ${px2vw(11)};
  right: ${px2vw(13)};
  width: ${px2vw(15)};
  height: ${px2vw(15)};
`;

export const PaymentImg = styled.img`
  height: ${px2vw(22)};
`;

export const BackIcon = styled.img`
  margin-left: ${px2vw(13)};
  background-color: ${cinzaKuppi4};
  border-radius: 2px;
  padding: ${px2vw(7)};
  cursor: pointer;
  :active {
    background-color: ${cinzaKuppi7};
  }
`;

export const StepTittle = styled.div`
  font-family: AvenirNextLTPro;
  font-weight: 700;
  color: ${preto};
  font-size: ${px2vw(17)};
  margin-right: ${px2vw(15.5)};
  white-space: nowrap;
  letter-spacing: 0.36px;
`;

export const PinIcon = styled.img`
  height: ${px2vw(20)};
  margin-right: ${px2vw(8)};
  cursor: ${(props) => (props.cursor ? props.cursor : 'initial')};
`;

export const iconMui = {
  color: verdeKuppi1,
  width: px2vw(20),
};

export const Box = styled.div`
  position: relative;
  min-height: ${props => props.h ? props.h : px2vw(63)};
  min-width: ${props => props.w ? props.w : px2vw(140)};
  width: ${props => props.width ? props.width : '100%'};
  padding: ${props => props.pad ? props.pad : px2vw(8)};
  cursor: pointer;
  box-sizing: border-box;
  border-radius: 8px;
  border: ${(props) =>
    props.selected
      ? `2px solid  ${verdeKuppi10}`
      : `2px solid  ${cinzaKuppi7}`};
  box-shadow: ${(props) =>
    props.selected ? `0px 0px 12px 1px ${verdeKuppi10}40` : 'none'};
    ${(props) =>
      props.delivery &&
      `:hover {
    border: 2px solid  ${verdeKuppi10};
    box-shadow: 2px 2px 12px 2px ${verdeKuppi10 + 40};`}
  }
`;

export const SideSlider = styled.div`
  display: flex;
  -webkit-overflow-scrolling: touch;
  align-items: center;
  flex-wrap: nowrap;
  overflow-x: auto;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const PaymentMethod = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: ${px2vw(3)};
  cursor: pointer;
`;

export const SwitchWithInput = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${px2vw(8)};
  min-height: ${px2vw(40)};

  ${(props) => `
    margin-left: ${props.margleft};
    margin-bottom: ${props.marginBottom};
    margin-top: ${props.margtop};
    min-width: ${props.minWidth};
    min-height: ${props.minHeight};
    flex-direction: ${props.flexDir};
  `};
`;

export const SwitchController = withStyles((theme) => ({
  root: {
    width: px2vw(33),
    height: px2vw(19),
    padding: 0,
    margin: 0,
    marginRight: px2vw(8),
  },
  switchBase: {
    padding: 1.5,
    '&$checked': {
      transform: `translateX(${px2vw(13)})`,
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: `${verdeKuppi2}`,
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: `${px2vw(6)} solid #fff`,
    },
  },
  thumb: {
    width: `${px2vw(16)}`,
    height: `${px2vw(16)}`,
  },
  track: {
    borderRadius: `${px2vw(15)}`,
    backgroundColor: theme.palette.grey[400],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

export const SwitchLabelRegularText = styled.div`
  font-size: ${(props) => props.size || px2vw(11)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.36px;
  min-width: ${px2vw(55)};

  ${(props) =>
    props.isChecked &&
    `
    color: ${branco};
  `};

  ${(props) => `
    width: ${props.width};
    margin-right: ${props.margright};
    margin-left: ${props.margleft};
    margin-top: ${props.margtop};
  `};
`;

export const SwitchWithInputBox = styled.div`
  display: none;
  height: ${px2vw(40)};

  ${(props) =>
    props.isVisible &&
    `
    display: block;
  `};

  ${(props) => `
    margin-right: ${props.marginRight};
    margin-left: ${props.margleft};
  `};
`;

export const SwitchInput = styled(Input)`
  padding: ${px2em(8)} !important;
  margin-left: ${px2em(4)} !important;
  margin-right: ${px2em(4)} !important;
  margin-top: ${px2em(1)} !important;
  border: 1px solid ${cinzaKuppi4} !important;
  border-radius: ${px2vw(5)} !important;
  height: ${px2vw(30)} !important;
  font-size: ${px2vw(12)} !important;
  font-family: font-family: AvenirNextLTPro !important;
  font-weight: normal;
  letter-spacing: 0.36px !important;
  width: ${px2vw(60)} !important;
  text-align:center;

  ::placeholder {
    color: ${cinzaKuppi4} !important;
    font-size: ${px2vw(12)} !important;
  }

  :focus {
    border: 1px solid ${preto2} !important;
    box-shadow: none !important;
  }

  ${(props) => `
    width: ${props.width} !important;
    margin-top: ${props.margtop} !important;
    margin-right: ${props.margright} !important;
  `};

  ${(props) =>
    props.disabled &&
    `
      color: ${preto}60;
  `};
`;
