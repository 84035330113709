import React from "react";
import { FormControlLabel } from "@material-ui/core";
import { Spinner } from 'reactstrap';

import {
  Card,
  NormalText,
} from '../../../BaseScreen/styles';

import {
  SwitchController,
  SwitchLabelRegularText,
  SwitchWithInput,
  SwitchWithInputBox,
  SwitchInput,
  StoreForm,
  SaveButton,
  SaveButtonText,
 } from '../styles';

import {
  LoadingBox,
} from '../../../../_layouts/styles/common';

import { Row, Col } from '../../../../../styles/grid';
import { preto } from '../../../../../styles/colors';

import { timeMask } from '../../../../../utils/masks';
import px2vw from '../../../../../utils/responsiveness/px2vw';

export default function StoreAvailabilitiesOnboardingMobile({ handler }){
  return(
    <StoreForm onSubmit={handler.handleStoreAvailabilitySubmit}>
      <Card
        width="auto"
        height="auto"
        padleft={px2vw(50)}
        padright={px2vw(50)}
        borderRadius={px2vw(60)}
        margtop={px2vw(60)}

      >
        <Row margbottom={px2vw(100)} >
          <NormalText
            textalign='flex-start'
            margtop={px2vw(80)}
            margleft={px2vw(20)}
            fontweight='bold'
            color={preto}
            fontsize={px2vw(60)} >
            Defina os horários de funcionamento da sua loja
          </NormalText>
        </Row>
        {handler.weekDays.map((day) => {
          return (
            <SwitchWithInput key={day.name} margleft={px2vw(60)} >
              <FormControlLabel
                control={
                  <SwitchController
                    checked={day.is_active}
                    type="checkbox"
                    onChange={() => handler.handleToggleDayActive(day.name)}
                  />
                }
                label={
                  <SwitchLabelRegularText>
                    {handler.translateDayName(day.name)}
                  </SwitchLabelRegularText>
                }
              />
              <Col alignEnd>
                <SwitchWithInputBox isVisible={day.is_active}>
                  <SwitchLabelRegularText>
                    <SwitchInput
                      name={`${day.name}StartTime`}
                      maxLength="5"
                      placeholder="11:00"
                      value={day.start_time}
                      onChange={(t) => {
                        handler.handleOnChangeDayInput({
                          dayName: day.name,
                          startTime: timeMask(t.target.value),
                        });
                      }}
                    />
                    às
                    <SwitchInput
                      name={`${day.name}EndTime`}
                      maxLength="5"
                      placeholder="11:00"
                      value={day.end_time}
                      onChange={(t) => {
                        handler.handleOnChangeDayInput({
                          dayName: day.name,
                          endTime: timeMask(t.target.value),
                        });
                      }}
                    />
                  </SwitchLabelRegularText>
                </SwitchWithInputBox>
              </Col>
            </SwitchWithInput>
          )
        })}

        <Row justifyEnd width='auto' margbottom={px2vw(90)} margright={px2vw(40)}>
          <SaveButton
            hasChanges={handler.isSaveButtonActive()}
            type="submit"
            disabled={handler.isSaveButtonDisabled()}
          >
            {handler.availabilityLoading ? (
              <LoadingBox>
                <Spinner
                  style={{
                    width: '1.8rem',
                    height: '1.8rem',
                    color: preto,
                  }}
                />{' '}
              </LoadingBox>
            ) : (
              <SaveButtonText hasChanges={handler.isSaveButtonActive()}>
                Salvar
              </SaveButtonText>
            )}
          </SaveButton>
        </Row>
      </Card>
    </StoreForm>
  )
}