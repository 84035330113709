import { takeLatest, call, put, all, select } from 'redux-saga/effects';

import { apiAnunciante } from '../../../services/api';

import {
  getUsedCouponsTraditionalSuccess,
  getUsedCouponsTraditionalFailure,
  getUsedCouponsFlashSuccess,
  getUsedCouponsFlashFailure,

} from './actions';
import {
  logout
} from '../auth/actions';

const userData = (state) => state.user.user;

export function* getUsedCouponsTraditionalRequest() {
  try {
    const user = yield select(userData);

    const response = yield call(
      apiAnunciante.get,
      `/user-info/${user.advertiserInfo.id}/used-coupons/normal`
    );

    yield put(getUsedCouponsTraditionalSuccess(response.data));
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(getUsedCouponsTraditionalFailure());
    }
  }
}

export function* getUsedCouponsFlashRequest() {
  try {
    const user = yield select(userData);

    const response = yield call(
      apiAnunciante.get,
      `/user-info/${user.advertiserInfo.id}/used-coupons/flash`
    );

    yield put(getUsedCouponsFlashSuccess(response.data));
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(getUsedCouponsFlashFailure());
    }
  }
}

export default all([
  takeLatest(
    '@coupons/GET_USED_COUPONS_TRADITIONAL_REQUEST',
    getUsedCouponsTraditionalRequest
  ),
  takeLatest(
    '@coupons/GET_USED_COUPONS_FLASH_REQUEST',
    getUsedCouponsFlashRequest
  )
]);
