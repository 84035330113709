import React from 'react';
import { Spinner } from 'reactstrap';
import { Form } from '@rocketseat/unform';
import { ModalPrimaryButton } from '../../../../components/Modal/styles';
import { branco, preto, verdeKuppi1 } from '../../../../styles/colors';
import { Col } from '../../../../styles/grid';
import px2vw from '../../../../utils/responsiveness/px2vw';
import { LoadingBox } from '../../../_layouts/styles/common';
import { InputsBox, LoginInput, LoginInputBox, LoginInputLabel, NormalText } from '../../styles';

import RecoveryGoBackButton from '../RecoveryGoBackButton';
import { 
  btnTextLogin2, 
  placeholderEmail, 
  recoverEmailFound3, 
  recoverPassword, 
  recoveryText3, 
  slugEmail 
} from '../../constants';

export default function RecoveryPassword({ handler }) {
  return (
    <Col width="100%" alignCenter>
      {!handler.sentInstructionsPasswordModal && (
        <>
          <RecoveryGoBackButton handler={handler} goBackPage="recovery_type" />
          <NormalText margtop={px2vw(50)} center fontweight="normal">
            {recoveryText3}
          </NormalText>
          <Form onSubmit={handler.handleRecoverPassword}>
            <InputsBox mobile>
              <LoginInputBox mobile margtop={px2vw(30)} margbottom={px2vw(30)} width={px2vw(1070)}>
                <Col alignStart>
                  <LoginInputLabel mobile>{slugEmail}</LoginInputLabel>
                </Col>
                <LoginInput
                  mobile
                  name="email"
                  type="email"
                  maxLength="100"
                  placeholder={placeholderEmail}
                />
              </LoginInputBox>
            </InputsBox>
            <ModalPrimaryButton
              borderColor={verdeKuppi1}
              mobile
              type="submit"
              width="78vw"
            >
              {handler.loading ? (
                <LoadingBox>
                  <Spinner
                    style={{ width: '1.8rem', height: '1.8rem', color: preto }}
                  />{' '}
                </LoadingBox>
              ) : (

                <NormalText center color={branco} >
                  {recoverPassword}
                </NormalText>

              )}
            </ModalPrimaryButton>
          </Form>
        </>
      )}

      {handler.sentInstructionsPasswordModal && (
        <>
          <NormalText center mobile margtop={px2vw(100)}>
            {recoverEmailFound3}
          </NormalText>
          <ModalPrimaryButton
            width={px2vw(1090)}
            margtop={px2vw(100)}
            color={branco}
            mobile
            onClick={() => {
              handler.setCurrLoginStep("main")
              handler.setSentInstructionsPasswordModal(false);
            }
            }
          >
            <NormalText center mobile>{btnTextLogin2}</NormalText>
          </ModalPrimaryButton>
        </>
      )}
    </Col>
  )
}