import styled from 'styled-components';
import { branco, preto } from '../../styles/colors';
import px2vw from '../../utils/responsiveness/px2vw';

export const Drawer = styled.div`
  display: flex;
  flex-direction: column;
  width: 0;
  height: 0;
  position: absolute;
  background: ${branco};
  z-index: 40;
  top: 0px;
  opacity: 0;
  animation: slideToRight 0.7s ease-in-out;
  box-shadow: 4px 1px 13px 5px ${preto}50;

  ${(props) => props.isOpen && `

    width: ${props.width};
    height: ${px2vw(3000)};
    opacity: 1;
    overflow: auto;

  `};

  @keyframes slideToRight {
    0%{
      transform: skewX(0deg) translateX(-500px);
    }
    100%{
      transform: skewX(0deg);
    }
  }

  @keyframes slideFromRight {
    0%{
      transform: skewX(0deg);
    }
    50%{
      transform: skewX(0deg) translateX(-250px);

    }
    100%{
      transform: skewX(0deg) translateX(-500px);

    }
  }

`;
