import { takeLatest, call, put, all, select } from 'redux-saga/effects';

import { apiAnunciante } from '../../../services/api';

import {
  importIfoodCatalogSuccess,
  importIfoodCatalogFailure,
} from './actions';
import {
  logout
} from '../auth/actions';

const userData = (state) => state.user.user;

export function* importIfoodCatalogRequest({ payload }) {
  try {
    const user = yield select(userData);

    yield call(
      apiAnunciante.post,
      `/user-info/${user.advertiserInfo.id}/import-ifood-catalog`,
      {
        ifood_url: payload.ifoodURL,
      }
    );

    yield put(importIfoodCatalogSuccess());
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(importIfoodCatalogFailure());
    }
  }
}

export default all([
  takeLatest(
    '@importIfood/IMPORT_IFOOD_CATALOG_REQUEST',
    importIfoodCatalogRequest
  ),
]);
