import React from "react";

import { Row, Col } from '../../../../styles/grid';
import {
  ActiveTextYellow,
  GoToPaymentLink,
  CopyQrCode,
  CopyQrCodeTextBold
} from '../styles';
import {
  SuccessPaymentImage,
  PageTitle,
  PageText,
  SectionBox,
  OrderSumaryTextBold,
  OrderSumaryText,
} from '../../styles';

import px2vw from "../../../../utils/responsiveness/px2vw";
import { formatCurrencyBRL } from '../../../../utils/utils';

import {
  PageTitleSuccess,
  PageTextBlocked,
  PageTextRenewNotBlocked,
  PageTextFirstPayment,
  PixAmountText,
  PixExpirationText,
  PixExpirationDateText,
  PaymentInfo1Mobile,
} from '../constants';
import {
  PlanPendingPaymentText,
  VigencyText,
  AdvertiserNameText,
  ChangePaymentMethodText,
  CopyPaymentCodeText
} from '../../constants';

export default function PixGeneratedMobileSuccess({ handler }) {
  return (
    <>
      <Row alignCenter justifyCenter margbottom={px2vw(70)}>
        <SuccessPaymentImage
          size={px2vw(150)}
          margright={px2vw(80)}
          src={require('../../../../assets/images/success/success.png')}
        />
        <PageTitle fontsize={px2vw(70)}>
          {PageTitleSuccess}
        </PageTitle>
      </Row>
      <Row margbottom={px2vw(90)}>
        <PageText fontsize={px2vw(50)}>
          {handler.areBlocked && PageTextBlocked}
          {handler.isRenewPayment && !handler.areBlocked && PageTextRenewNotBlocked}
          {handler.isFirstPayment && PageTextFirstPayment}
        </PageText>
      </Row>
      <SectionBox
        margbottom={px2vw(160)}
      >
        <Col 
          padtop={px2vw(40)}
          padbottom={px2vw(40)}
          padleft={px2vw(50)}
          padright={px2vw(50)}
          alignStart
        >
          <OrderSumaryText fontSize={px2vw(50)}>
            {AdvertiserNameText}
          </OrderSumaryText>
          <OrderSumaryTextBold
            fontSize={px2vw(50)}
            maxChar="33ch"
            advertiserName
          >
            {handler.signaturePaymentData?.advertiserInfo?.name}
          </OrderSumaryTextBold>
          <Col justifyStart margtop={px2vw(60)}>
            <OrderSumaryTextBold fontSize={px2vw(50)}>
              {`${handler.signaturePaymentData?.subscription?.name}`}
            </OrderSumaryTextBold>
            <ActiveTextYellow fontSize={px2vw(50)}>
              {PlanPendingPaymentText}
            </ActiveTextYellow>
          </Col>
          <Col justifyStart margtop={px2vw(60)}>
            <OrderSumaryText fontSize={px2vw(50)}>
              {VigencyText}
            </OrderSumaryText>
            <OrderSumaryTextBold fontSize={px2vw(50)}>
              {handler.vigencyInfo}
            </OrderSumaryTextBold>
          </Col>
          <Row alignStart margtop={px2vw(60)}>
            <Col alignStart>
              <OrderSumaryText fontSize={px2vw(50)}>
                {PixAmountText}
              </OrderSumaryText>
              <OrderSumaryTextBold fontSize={px2vw(50)}>
                {formatCurrencyBRL(
                  (
                    Math.round(
                      handler.signaturePaymentData?.amount *
                        handler.signaturePaymentData?.subscription?.period *
                        100
                    ) / 100
                  ).toFixed(2),
                  {}
                )}
              </OrderSumaryTextBold>
            </Col>
            <Col alignEnd>
              <OrderSumaryText fontSize={px2vw(50)}>
                {PixExpirationText}
              </OrderSumaryText>
              <OrderSumaryTextBold fontSize={px2vw(50)}>
                {PixExpirationDateText}
              </OrderSumaryTextBold>
            </Col>
          </Row>
          <Col alignCenter margtop={px2vw(60)}>
            <OrderSumaryTextBold fontSize={px2vw(50)}>
              {PaymentInfo1Mobile}
            </OrderSumaryTextBold>
            <Row className="copy" margtop={px2vw(70)} margbottom={px2vw(75)}>
              <OrderSumaryText pixQrCode id="pixQrCodeCopy" fontSize={px2vw(50)}>
                {handler.signaturePaymentData?.pix_qr_code}
              </OrderSumaryText>
            </Row>
            <CopyQrCode
              onClick={() => handler.handleCopyPaymentCode('pixQrCodeCopy')}
              width={px2vw(1000)}
              height={px2vw(150)}
              borderradius={px2vw(30)}
            >
              <CopyQrCodeTextBold fontSize={px2vw(60)}>
                {CopyPaymentCodeText}
              </CopyQrCodeTextBold>
            </CopyQrCode>
          </Col>
          <Row justifyCenter margtop={px2vw(55)}>
            <GoToPaymentLink
              width={px2vw(1000)}
              height={px2vw(150)}
              fontSize={px2vw(60)}
              borderradius={px2vw(30)}
              onClick={() => handler.handleChangePaymentMethod() }
            >
              {ChangePaymentMethodText}
            </GoToPaymentLink>
          </Row>
        </Col>
      </SectionBox>
    </>
  )
}
