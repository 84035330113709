import React from 'react';
import { useSelector } from 'react-redux';

import { Row, Col } from '../../../../styles/grid';
import px2vw from '../../../../utils/responsiveness/px2vw';

import { formatCurrencyBRL } from '../../../../utils/utils';

import {
  TextBold,
  TextRegular,
  Hr,
} from '../styles';

export default function TotalsMetrics() {
  const cashReportTotals = useSelector((state) => state.reports.cashReportTotals);
  
  return (
    <Col>
      <Row>
        <Col>
          <TextBold>
            Total de
            <br />
            vendas
          </TextBold>
          <Hr />
          <TextRegular>
            {`${cashReportTotals.total_orders_qty} venda`}{cashReportTotals.total_orders_qty > 1 && 's'}
          </TextRegular>
        </Col>
        <Col>
          <TextBold>
            Total de vendas
            <br />
            com taxa de entrega
          </TextBold>
          <Hr />
          <TextRegular>
            {formatCurrencyBRL(cashReportTotals.total_orders_amount, {})}
          </TextRegular>
        </Col>
        <Col>
          <TextBold>
            Total de taxa
            <br />
            de entrega
          </TextBold>
          <Hr />
          <TextRegular>
            {formatCurrencyBRL(
              cashReportTotals.total_delivery_fee_amount,
              {}
            )}
          </TextRegular>
        </Col>        
      </Row>
      <Row margtop={px2vw(30)}>
        <Col>
          <TextBold>
            Ticket
            <br />
            médio
          </TextBold>
          <Hr />
          <TextRegular>
            {formatCurrencyBRL(
              cashReportTotals.total_avg_ticket_amount,
              {}
            )}
          </TextRegular>
        </Col>
        <Col>
          <TextBold>
            Total de vendas
            <br />
            no dinheiro
          </TextBold>
          <Hr />
          <TextRegular>
            {formatCurrencyBRL(
              cashReportTotals.total_cash_orders_amount,
              {}
            )}
          </TextRegular>
        </Col>
        <Col>
          <TextBold>
            Total de vendas
            <br />
            no crédito
          </TextBold>
          <Hr />
          <TextRegular>
            {formatCurrencyBRL(
              cashReportTotals.total_credit_orders_amount,
              {}
            )}
          </TextRegular>
        </Col>
      </Row>
      <Row margtop={px2vw(30)}>
        <Col>
          <TextBold>
            Total de vendas
            <br />
            no débito
          </TextBold>
          <Hr />
          <TextRegular>
            {formatCurrencyBRL(
              cashReportTotals.total_debit_orders_amount,
              {}
            )}
          </TextRegular>
        </Col>
        <Col>
          <TextBold>
            Total de vendas
            <br />
            em voucher
          </TextBold>
          <Hr />
          <TextRegular>
            {formatCurrencyBRL(
              cashReportTotals.total_voucher_orders_amount,
              {}
            )}
          </TextRegular>
        </Col>
        <Col>
          <TextBold>
            Total de vendas
            <br />
            no pix
          </TextBold>
          <Hr />
          <TextRegular>
            {formatCurrencyBRL(
              cashReportTotals.total_pix_orders_amount,
              {}
            )}
          </TextRegular>
        </Col>
      </Row>
    </Col>
  );
}