import styled from 'styled-components';

import { Form, Input } from '@rocketseat/unform';

import px2vw from '../../../utils/responsiveness/px2vw';

import { branco, cinzaKuppi4, cinzaKuppi3 } from '../../../styles/colors';

export const ProfileDataBox = styled.div`
  border-radius: ${px2vw(20)};
  background-color: ${branco};
  width: ${px2vw(1020)};
  height: ${px2vw(210)};
  padding-left: ${px2vw(30)};
  padding-right: ${px2vw(30)};

  box-shadow: 0px 8px 9px -2px ${cinzaKuppi4};
`;

export const DataTitleText = styled.div`
  font-size: ${px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.36px;
`;

export const DataText = styled.div`
  font-size: ${px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.36px;

  ${(props) => `
    width: ${props.width};
  `};
`;

export const EditButtonBox = styled.div`
  ${(props) => `margin-top: ${props.margtop}`};
  ${(props) => `margin-right: ${props.margright}`};
  ${(props) => `margin-left: ${props.margleft}`};
`;

export const EditPersonalDataModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const EditAddressDataModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ModalTitleText = styled.div`
  font-size: ${px2vw(28)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.72px;
  margin-left: ${px2vw(-405)};
  margin-top: ${px2vw(-45)};
`;

export const ModalInputLabelText = styled.div`
  font-size: ${px2vw(12)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.32px;
`;

export const ModalInput = styled(Input)`
  padding: 0.5em;
  margin-top: ${px2vw(5)};
  border: 1px solid ${cinzaKuppi4};
  border-radius: ${px2vw(5)};
  font-size: ${px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.36px;
  width: ${px2vw(280)};
  height: ${px2vw(40)};

  ::placeholder {
    color: ${cinzaKuppi4} !important;
  }

  :focus {
    border: 1px solid ${cinzaKuppi3} !important;
    box-shadow: none !important;
  }

  ${(props) => `
    width: ${props.width};
    height: ${props.height};
    margin-top: ${props.margtop};
  `};
`;

export const FormUserData = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const FormAddressData = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
