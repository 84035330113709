import styled from 'styled-components';

import {
  branco,
  cinzaKuppi6,
} from '../../../styles/colors';

import px2vw from '../../../utils/responsiveness/px2vw';

export const ModalBackground = styled.div`
  display: none;
  position: fixed;
  z-index: 20;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: ${cinzaKuppi6}99;

  ${(props) =>
    props.isVisible &&
    `
    display: flex;
    justify-content: center;
    align-items: center;
  `};
`;

export const ModalContent = styled.div`
  background-color: ${branco};
  padding: ${(props) => props.padding || '20px'};
  border-radius: 20px;

  ${(props) => `
    width: ${props.width};
    min-height: ${props.height};
    max-height: ${props.maxheight};
    background-color: ${props.bgcolor};
    margin-top: ${props.margtop};
  `};

  ${(props) => props.backgroundImg && `
    background-size: 100% ${props.backgroundImgSize || px2vw(250)}, cover;
    background-repeat: no-repeat;
  `};
`;





