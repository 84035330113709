import React, {
  Fragment,
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import { Icon } from 'react-materialize';
import moment from 'moment';

import { Row, Col } from '../../../../styles/grid';
import {
  Header,
  Content,
  Hr,
  TableRow,
  HeaderButton,
  PagButton,
  PrevIcon,
  NextIcon,
  ErrorText,
  DetailsButton,
} from './styles';

import { SvgBox, Arrow, Arrow2 } from './icons';
import { sort, makePages } from './tableOptions';

import px2vw from '../../../../utils/responsiveness/px2vw';
import { formatCurrencyBRL } from '../../../../utils/utils';

import TablePerPage from '../../../../components/TablePerPage';

import TransferDetailsModal from '../TransferDetailModal';

import { getTransferTransactionsRequest } from '../../../../store/modules/financial/actions';

export default function TransferTable({ data = [] }) {
  const dispatch = useDispatch();
  const [transferDetailModal, setTransferDetailModal] = useState(false);
  const [rows, setRows] = useState([]);
  const [rowsCurrentPage, setRowsCurrentPage] = useState();
  const [structure, setStructure] = useState(null);
  const [currentPage, setCurrentPage] = useState('1');
  const [lastPage, setLastPage] = useState();
  const [firstPage] = useState('1');
  const [sortedCol, setSortedCol] = useState('');

  useEffect(() => {
    if (structure) {
      setLastPage(
        Object.keys(structure)[Object.keys(structure).length - 1].toString()
      );
    }
  }, [structure]);

  useMemo(() => {
    setStructure(makePages(rows.length, rowsCurrentPage));
  }, [rows, rowsCurrentPage]);

  useEffect(() => {
    setRows(data);
  }, [data]);

  useEffect(() => {
    if (rows.length < 5) {
      setRowsCurrentPage(rows.length);
    }
    if (rowsCurrentPage > 5) {
    } else {
      setRowsCurrentPage(5);
    }
  }, [rows]);

  const handleSort = useCallback(
    (col) => {
      if (sortedCol !== col) {
        setSortedCol(col);
        setRows(sort(rows, col, 'asc'));
      } else {
        setSortedCol('');
        setRows(sort(rows, col, 'desc'));
      }
    },
    [rows]
  );

  function handlePerPage(size) {
    setRowsCurrentPage(size);
  }

  function handleChangePage(direction) {
    if (direction === 'next') {
      const curr = +currentPage + 1;
      setCurrentPage(curr.toString());
    } else if (direction === 'prev') {
      const curr = +currentPage - 1;
      setCurrentPage(curr.toString());
    }
  }

  const handleGetTransferDetails = (transferId) => {
    dispatch(getTransferTransactionsRequest(transferId));
    setTransferDetailModal(true);
  };

  return (
    <>
      <Col padding={`0 ${px2vw(15)}`} alignCenter justifyCenter>
        <Row>
          <Header onClick={() => handleSort('date')} width="40%">
            <HeaderButton>
              Data
              <SvgBox sorted={sortedCol === 'date'}>
                <Arrow />
                <Arrow2 />
              </SvgBox>
            </HeaderButton>
          </Header>

          <Header onClick={() => handleSort('amount')} width="40%">
            <HeaderButton>
              Total de repasse
              <SvgBox sorted={sortedCol === 'amount'}>
                <Arrow />
                <Arrow2 />
              </SvgBox>
            </HeaderButton>
          </Header>

          <Header width="20%"/>
        </Row>
        <Row>
          <Hr />
        </Row>
        {structure ? (
          rows.map((transfer, index) =>
            structure[currentPage] &&
              structure[currentPage].start <= index + 1 &&
              structure[currentPage].end >= index + 1 ? (
              <Fragment key={`transfer-${index}`}>
                <TableRow alignCenter justifyCenter>
                  <Content width="40%">
                    {moment(new Date(transfer.date)).format('DD/MM/YYYY')}
                  </Content>
                  <Content width="40%">{formatCurrencyBRL(transfer.amount.toFixed(2), 2)}</Content>
                  <Content width="20%">
                    <DetailsButton 
                      onClick={() => handleGetTransferDetails(transfer.id)}
                    >
                      Detalhes
                    </DetailsButton>
                  </Content>
                </TableRow>
                <Row>
                  <Hr />
                </Row>
              </Fragment>
            ) : null
          )
        ) : (
          <Col height="20vh">
            <Row alignCenter justifyCenter>
              {/* TODO: Ajustar tamanho do icone */}
              <Icon large>sentiment_very_dissatisfied</Icon>
              <ErrorText>Você não tem repasses a serem exibidos.</ErrorText>
            </Row>
          </Col>
        )}
        {structure && (
          <Row alignCenter justifyEnd>
            <Row alignCenter justifyEnd height={px2vw(40)} margin={px2vw(10)}>
              <TablePerPage response={handlePerPage} length={rows.length} />
              <Row alignCenter justifyCenter width="10%">
                {!structure[currentPage] && handleChangePage('prev')}
                {structure[currentPage] && structure[currentPage].start} -{' '}
                {structure[currentPage] && structure[currentPage].end} de{' '}
                {rows.length}
              </Row>
              <PagButton
                onClick={() => handleChangePage('prev')}
                disabled={currentPage === firstPage}
                title="Página anterior"
              >
                <PrevIcon disabled={currentPage === firstPage} />
              </PagButton>
              <PagButton
                onClick={() => handleChangePage('next')}
                title="Próxima página"
                disabled={currentPage === lastPage}
              >
                <NextIcon disabled={currentPage === lastPage} />
              </PagButton>
            </Row>
          </Row>
        )}
      </Col>

      {transferDetailModal && (
        <TransferDetailsModal
          visible={transferDetailModal}
          onClose={() => setTransferDetailModal(false)}
        />
      )}
    </>
  );
}
