import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Form, Input, Select } from '@rocketseat/unform';

import Table from '@material-ui/core/Table';

import px2vw from '../../utils/responsiveness/px2vw';

import {
  branco,
  preto,
  cinzaKuppi2,
  cinzaKuppi3,
  cinzaKuppi4,
  cinzaKuppi5,
  cinzaKuppi7,
  cinzaKuppi8,
  cinzaKuppi9,
  verdeKuppi1,
  verdeKuppi4,
  verdeKuppi6,
  amareloKuppi2,
} from '../../styles/colors';

export const NewCouponModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: ${px2vw(20)};
`;

export const ManageCouponsContent = styled.div`
  ${(props) => props.isHidden && `display: none;`};
`;

export const TraditionalCouponsContent = styled.div`
  margin-top: ${px2vw(-30)};
`;

export const FlashCouponsContent = styled.div`
  margin-top: ${px2vw(-30)};
`;

export const CodeCouponsContent = styled.div`
  margin-top: ${px2vw(-30)};
`;

export const ActionsMenuBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  height: ${px2vw(50)};
`;

export const CouponsBox = styled.div`
  background-color: ${branco};
  margin-top: ${px2vw(30)};
  margin-left: ${px2vw(5)};
  border-radius: ${px2vw(20)};
  padding: 0 ${px2vw(25)};
  box-shadow: 0px 8px 9px -2px ${cinzaKuppi4};
`;

export const SearchBox = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 20px;
`;

export const SearchButton = styled.button`
  margin-top: 6px;
  margin-right: -38px;
  width: 20px;
  height: 20px;
  border: none;
  z-index: 10;
  background-color: transparent;
`;

export const SearchImage = styled.img`
  width: 20px;
  height: 20px;
`;

export const SearchInput = styled.input`
  padding: 1em;
  padding-left: 42px;
  margin-left: 0.5em;
  margin-right: 11px;
  margin-bottom: 12px;
  border: 1px solid ${cinzaKuppi4};
  border-radius: 5px;
  font-size: 14px;
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.32px;
  width: 250px;
  height: 35px;
`;

export const TabsBox = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: ${px2vw(20)};
  border-bottom: 1px solid ${cinzaKuppi4};
`;

export const TabBox = styled.div`
  display: block;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: auto;

  ${(props) => props.margrightTab && `
    margin-right: ${px2vw(40)};
  `};

`;

export const Tab = styled.button`
  height: ${px2vw(50)};
  font-size: ${px2vw(20)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.48px;
  border: none;
  background-color: transparent;
`;

export const TabSelected = styled.div`
  display: none;
  height: ${px2vw(5)};
  width: ${px2vw(120)};
  background-color: ${verdeKuppi1};
  border-radius: ${px2vw(5)};
  margin-top: ${px2vw(-7)};
  margin-right: ${px2vw(40)};
  margin-bottom: ${px2vw(20)};

  ${(props) =>
    props.isVisible &&
    `
    display: block;
  `};

  ${(props) => `
    width: ${props.width};
  `};
`;

export const NewCouponButton = styled.button`
  background-color: ${verdeKuppi1};
  width: ${px2vw(150)};
  height: ${px2vw(40)};
  border: none;
  border-radius: ${px2vw(10)};
  padding: 0.3em;
  margin-top: ${px2vw(5)};
  margin-left: ${px2vw(430)};
`;

export const NewCouponButtonText = styled.div`
  font-size: ${px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.36px;
  width: ${px2vw(150)};
  color: ${branco};
`;

export const SetOrderOrdersBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const SetOrderOrdersButton = styled.button`
  height: 40px;
  margin-top: 5px;
  border: none;
  background-color: transparent;

  ${(props) => `
    margin-left: ${props.margleft};
  `};
`;

export const SetOrderOrdersButtonText = styled.div`
  font-size: 16px;
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.36px;
  margin-top: 2px;
`;

export const DiscountPercentageText = styled.div`
  font-size: ${px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.36px;
  margin-left: ${px2vw(10)};
  margin-top: ${px2vw(12)};

  ${(props) =>
    props.isNotVisible &&
    `
    display: none;
  `};
`;

export const TabContent = styled.div`
  display: none;

  ${(props) =>
    props.isVisible &&
    `
    display: block;
  `};
`;

export const TableCoupons = styled(Table)`
  min-width: 650;
`;

export const CouponsRowText = styled.div`
  font-size: 16px;
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.36px;

  ${(props) =>
    props.isBold &&
    `
    font-family: AvenirNextLTPro;
    font-weight: 700;
  `};

  ${(props) => `
    margin-top: ${props.margtop};
    margin-left: ${props.margleft};
    font-size: ${props.fontSize};
  `};
`;

export const CouponsHeaderText = styled.text`
  font-size: 16px;
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.36px;

  ${(props) => `
    margin-left: ${props.margleft};
  `};
`;

export const HeaderOrderButton = styled.button`
  height: 21px;
  width: 21px;
  margin-left: 10px;
  border: none;
  background-color: transparent;
`;

export const CouponsHeaderImage = styled.img`
  height: 20px;
  width: 20px;
  margin-bottom: -4px;
`;

export const Separator = styled.img`
  height: ${px2vw(350)};
  width: 2px;
  margin-bottom: 40px;
  margin-top: 40px;
  background-color: ${branco};
`;

export const NewCoupon = styled.div`
  height: ${px2vw(400)};
  width: ${px2vw(350)};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;  
`;

export const CouponImage = styled.img`
  width: ${px2vw(60)};
  height: ${px2vw(60)};
  margin-top: ${px2vw(10)};

  ${(props) => props.code && `
    width: ${px2vw(55)};
    height: ${px2vw(55)};
  `};
`;

export const CouponTitle = styled.div`
  font-size: ${px2vw(28)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.72px;
  margin-top: ${px2vw(20)};
`;

export const CouponText = styled.div`
  font-size: ${px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.36px;
  margin-top: ${px2vw(30)};
  width: ${px2vw(270)};
  text-align: center;
`;

export const CouponButton = styled.button`
  background-color: transparent;
  width: ${px2vw(170)};
  height: ${px2vw(35)};
  border: 2px solid ${verdeKuppi1};
  color: ${branco};
  background-color: ${verdeKuppi1};
  border-radius: ${px2vw(10)};
  padding: 0.3em;
  margin-top: ${px2vw(40)};
  font-size: ${px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.36px;

  :hover {
    background-color: ${branco};
    color: ${preto};
  }   
`;

export const PauseButton = styled.button`
  height: 40px;
  width: 160px;
  border: none;
  background-color: transparent;

  ${(props) => `
    margin-left: ${props.margleft};
    margin-top: ${props.margtop};
    width: ${props.width};
  `};
`;

export const ButtonTextRegular = styled.div`
  font-size: 16px;
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.32px;
  margin-top: 2px;

  ${(props) => props.greenColor && `color: ${verdeKuppi1}`};
  ${(props) =>
    props.yellowColor &&
    `
    color: ${amareloKuppi2};
    margin-right: ${props.margright};
  `};
`;

export const BackButtonBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const BackButton = styled.button`
  height: ${px2vw(40)};
  width: ${px2vw(100)};
  margin-top: ${px2vw(5)};
  margin-left: ${px2vw(5)};
  border: none;
  background-color: transparent;
`;

export const BackButtonImage = styled.img`
  height: ${px2vw(10)};
  width: ${px2vw(6)};
  margin-right: ${px2vw(10)};
  margin-top: ${px2vw(2)};
`;

export const BackButtonText = styled.div`
  font-size: ${px2vw(18)};
  font-family: AvenirNextLTPro;
font-weight: 600;
  letter-spacing: 0.48px;
  color: ${preto};
`;

export const PageCouponsTitle = styled.div`
  display: flex;
  flex-direction: row;
  font-size: ${px2vw(28)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.72px;
  padding-left: ${px2vw(15)};
`;

export const ChooseCouponTypeText = styled.div`
  display: flex;
  flex-direction: row;
  font-size: ${px2vw(20)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.48px;
  margin-bottom: ${px2vw(10)};
  padding-left: ${px2vw(15)};
`;

export const CouponsTypeBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding-left: ${px2vw(15)};
`;

export const CouponTypeButton = styled.button`
  border: none;
  background-color: transparent;
  width: ${px2vw(180)};
  height: ${px2vw(150)};
`;

export const CouponTypeBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${branco};
  border-radius: ${px2vw(20)};
  border: 1px solid ${cinzaKuppi4};
  width: ${px2vw(150)};
  height: ${px2vw(120)};

  ${(props) =>
    props.isActive &&
    `
    background-color: ${verdeKuppi4};
    color: ${branco};
    border: 1px solid ${verdeKuppi1}; 
    -webkit-box-shadow: -2px 1px 14px -5px rgba(0,0,0,0.75);
    -moz-box-shadow: -2px 1px 14px -5px rgba(0,0,0,0.75);
    box-shadow: -2px 1px 14px -5px rgba(0,0,0,0.75);

    .imageActive {
      filter: invert(200%) sepia(0%) saturate(7%) hue-rotate(152deg) brightness(200%) contrast(200%);
    }
  `};
`;

export const CouponTypeText = styled.div`
  margin-top: ${px2vw(10)};
  font-size: ${px2vw(12)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.36px;
  width: ${px2vw(126)};
  text-align: center;
  line-height: ${px2vw(20)};
`;

export const CouponDiscountTypeBox = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${branco};
  border-radius: ${px2vw(20)};
  border: none;
  width: ${px2vw(1010)};
  //height: ${px2vw(300)};
  margin-left: ${px2vw(10)};
  margin-top: ${px2vw(30)};
  padding: ${px2vw(40)};

  ${(props) => `
    height: ${props.height};
  `};
`;

export const DragImageBox = styled.div`
  width: ${px2vw(150)};
  height: ${px2vw(150)};
  border: 1px dashed ${cinzaKuppi5};
  border-radius: ${px2vw(5)};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: ${px2vw(-100)};
`;

export const ImageCoverPreview = styled.img`
  position: absolute;
  width: ${px2vw(148)};
  height: ${px2vw(148)};
  border: solid 1px ${cinzaKuppi9};
  border-radius: ${px2vw(5)};
  object-fit: cover;
`;

export const DragImage = styled.img`
  height: ${px2vw(20)};
  width: ${px2vw(20)};
`;

export const ModalText2 = styled.div`
  font-size: ${px2vw(12)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.32px;
  margin-left: ${px2vw(20)};
  margin-right: ${px2vw(20)};
  margin-top: ${px2vw(20)};
  text-align: center;
`;

export const ChooseImageBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const ChooseImageButton = styled.button`
  height: 40px;
  width: 140px;
  border: none;
  background-color: transparent;

  ${(props) => `
    width: ${props.width};
    height: ${props.height};
    margin-left: ${props.margleft};
    margin-top: ${props.margtop};
  `};

  ${(props) =>
    props.isHidden &&
    `
    display: none;
  `};
`;

export const ChooseImageButtonImage = styled.img`
  height: 18px;
  width: 18px;
  margin-right: 10px;
`;

export const ChooseImageButtonText = styled.div`
  font-size: 14px;
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.36px;
  margin-top: 2px;
`;

export const ItemInputLabel = styled.div`
  font-size: ${px2vw(12)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.32px;

  ${(props) => `
    margin-top: ${props.margtop};
    margin-right: ${props.margright};
    margin-left: ${props.margleft};
    padding-bottom: ${props.padbottom};
    font-size: ${props.fontSize};
    width: ${props.width};
  `};
`;

export const ItemInputLabelRegular = styled.div`
  font-size: ${(props) => props.fontsize ? props.fontsize : px2vw(12)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.32px;
  color: ${cinzaKuppi5};
  line-height: 2;

  ${(props) => `
    margin-top: ${props.margtop};
    padding: ${props.pad};
    margin-left: ${props.margleft};
    margin-right: ${props.margright};
    width: ${props.width};
  `};
`;

export const ItemInput = styled(Input)`
  padding: 0.5em;
  margin-top: ${px2vw(5)};
  border: 1px solid ${cinzaKuppi4};
  border-radius: ${px2vw(5)};
  font-size: ${px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.36px;
  width: ${px2vw(280)};
  height: ${px2vw(35)};

  ::placeholder {
    color: ${cinzaKuppi4} !important;
  }

  :focus {
    border: 1px solid ${cinzaKuppi3} !important;
    box-shadow: none !important;
  }

  ${(props) => `
    width: ${props.width};
    height: ${props.height};
    margin-top: ${props.margtop};
    margin-left: ${props.margleft};
  `};

  ${(props) =>
    props.isNotVisible &&
    `
    display: none;
  `};

   ${(props) =>
    props.disabled &&
    `
    color: ${cinzaKuppi5};
  `};
`;

export const RemainingCharactersItem = styled.div`
  font-size: ${px2vw(12)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.32px;
  color: ${cinzaKuppi5};
  margin-top: ${px2vw(5)};
  margin-left: ${(props) => props.margleft};

  ${(props) => `
    margin-top: ${props.margtop};
  `}
`;

export const SelectButtonOption = styled.button`
  background-color: ${branco};
  height: ${px2vw(32)};
  width: ${px2vw(100)};
  border: 1px solid ${preto};
  border-radius: ${px2vw(5)};
  padding: 0.3em;
  margin-top: ${px2vw(7)};
  margin-bottom: ${px2vw(4)};
  margin-right: ${px2vw(4)};
  margin-left: ${px2vw(4)};

  ${(props) =>
    props.isChecked &&
    `
    background-color: ${verdeKuppi4};
    border: 1px solid ${verdeKuppi4};
  `};

  ${(props) =>
    props.orderType === 'delivery_take_away' &&
    `
    width: ${px2vw(240)};
  `};

  ${(props) =>
    props.orderType === 'take_away' &&
    `
    width: ${px2vw(160)};
  `};
`;

export const SelectButtonOptionText = styled.div`
  font-size: ${px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.36px;

  ${(props) =>
    props.isChecked &&
    `
    color: ${branco};
  `};
`;

export const DisponibilityLabel = styled.div`
  font-size: ${px2vw(12)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.36px;
  margin-top: ${px2vw(-5)};
  margin-left: ${px2vw(22)};
`;

export const HourDisponibilityBox = styled.div`
  display: none;
  flex-direction: row;

  ${(props) =>
    props.isVisible &&
    `
    display: flex;
  `};

  ${(props) => `
    margin-top: ${props.margtop};
  `};
`;

export const SpecificHourBox = styled.div`
  display: none;
  flex-direction: row;

  ${(props) =>
    props.isVisible &&
    `
    display: flex;
  `};

  ${(props) => `
    margin-top: ${props.margtop};
  `};
`;

export const ConfirmNewCouponContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ConfirmNewCouponContentTitle = styled.div`
  font-size: ${px2vw(18)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.48px;
  margin-top: ${px2vw(20)};
  margin-bottom: ${px2vw(30)};
  text-align: center;
`;

export const ConfirmNewCouponContentText = styled.div`
  font-size: ${px2vw(18)};
  font-family: AvenirNextLTPro;
font-weight: 600;
  letter-spacing: 0.48px;
  margin-top: ${px2vw(30)};
  text-align: center;
`;

export const SwitchInputBox = styled.div`
  ${(props) => props.isHidden && `display: none;`};
`;

export const AddItemButton = styled.button`
  height: 40px;
  width: 160px;
  border: none;
  background-color: transparent;
`;

export const AddItemButtonText = styled.div`
  font-size: 10px;
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.28px;

  ${(props) => `
    margin-top: ${props.margtop};
    margin-right: ${props.margright};
    margin-left: ${props.margleft};
  `};
`;

export const RemainingCharactersItemSmall = styled.div`
  font-size: 10px;
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.28px;
  color: ${cinzaKuppi5};
  margin-top: ${(props) => props.margtop};
  margin-left: ${(props) => props.margleft};
`;

export const ChooseImageButtonDottedBox = styled.div`
  border: 1px dashed ${cinzaKuppi3};
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  height: 35px;
  width: 140px;
  padding: 5px;
  margin-top: 28px;
`;

export const ChooseImageButtonDotted = styled.button`
  height: 35px;
  width: 140px;
  border: none;
  background-color: transparent;
`;

export const ChooseImageButtonDottedText = styled.div`
  font-size: 14px;
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.36px;

  ${(props) => `
    margin-top: ${props.margtop};
    margin-right: ${props.margright};
    margin-left: ${props.margleft};
  `};
`;

export const ErrorText = styled.div`
  font-size: ${px2vw(18)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.4px;
  margin-left: ${px2vw(20)};
`;

export const ItemSelectInput = styled(Select)`
  margin-top: 5px;
  height: 40px;

  ${(props) => `
    width: ${props.width};
    height: ${props.height};
    margin-top: ${props.margtop};
    margin-right: ${props.margright};
  `};
`;

export const FormEditTraditionalCouponPercentCart = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ImportProductToCouponContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ImportProductToCouponContentTitle = styled.div`
  font-size: ${px2vw(24)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.72px;
  margin-left: ${px2vw(-110)};
  margin-top: ${px2vw(-40)};
`;

export const ImportProductToCouponContentText = styled.div`
  font-size: ${px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.36px;
  margin-left: ${px2vw(30)};
  margin-right: ${px2vw(25)};
  margin-top: ${px2vw(15)};
  text-align: left;
`;

export const ImportProductToCouponContentProductsBox = styled.div`
  min-height: ${px2vw(350)};
  max-height: ${px2vw(350)};
  width: ${px2vw(360)};
  margin-top: ${px2vw(20)};
  overflow-y: auto;
`;

export const ImportProductToCouponContentProductButton = styled.button`
  font-size: ${px2vw(12)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.32px;
  height: ${px2vw(40)};
  margin-bottom: ${px2vw(5)};
  width: 100%;
  border: 1px solid ${cinzaKuppi4};
  background-color: ${cinzaKuppi2};
  text-align: left;
  padding-left: ${px2vw(30)};

  .active {
    background-color: ${verdeKuppi4};
    color: ${branco};
  }
`;

export const FormEditTraditionalCouponDiscountAmount = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SelectProductBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const SelectProductButton = styled.button`
  height: ${px2vw(33)};
  width: ${px2vw(170)};
  border: 1px solid ${preto};
  border-radius: ${px2vw(5)};
  background-color: transparent;
  margin-top: ${px2vw(6)};
  margin-left: ${px2vw(20)};
`;

export const SelectProductButtonImage = styled.img`
  height: 15px;
  width: 15px;
  margin-right: 8px;
`;

export const SelectProductButtonText = styled.div`
  font-size: ${px2vw(11)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.36px;
  margin-top: ${px2vw(2)};
`;

export const FormEditLightningCouponDiscountAmount = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const FormEditLightningCouponPercentCart = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const FormTraditionalCouponDiscountAmount = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const FormTraditionalCouponPercentCart = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const FormFlashCouponDiscountAmount = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const FormFlashCouponPercentCart = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const FormCodeCoupon = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CouponPreviewBox = styled.div`
  border-radius: ${px2vw(20)};
  box-shadow: 0 1.2px 12.3px rgba(0, 0, 0, 0.049),
    0 2.9px 23.7px rgba(0, 0, 0, 0.062), 0 6.1px 37.3px rgba(0, 0, 0, 0.073),
    0 16px 72px rgba(0, 0, 0, 0.11);
  position: initial;
`;

export const ProductImageBox = styled.div`
  width: ${px2vw(290)};
  height: ${px2vw(100)};
  border-radius: ${px2vw(20)};
  ${(props) => `
    background-image: url(${props.image});
  `};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  ${(props) =>
    props.blur &&
    `
    filter: blur(4px);
    -moz-filter: blur(4px);
    -o-filter: blur(4px);
    -ms-filter: blur(4px);
  `};
`;

export const AdvertiserInfoBox = styled.div`
  padding: ${px2vw(10)};
  width: 100%;
  height: ${px2vw(95)};
  border-top-left-radius: ${px2vw(20)};
  border-top-right-radius: ${px2vw(20)};
  background-color: ${cinzaKuppi8};
  z-index: 2;
  margin-top: ${px2vw(-50)};
  position: relative;
`;

export const AdvertiserName = styled.div`
  width: auto;
  font-size: ${px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.36px;
`;

export const AdvertiserNameBackButton = styled.img`
  height: ${px2vw(10)};
  width: ${px2vw(6)};
  margin-left: ${px2vw(10)};
  margin-top: ${px2vw(6)};
`;

export const AdvertiserCategory = styled.div`
  margin-top: ${px2vw(7)};
  font-size: ${px2vw(12)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
`;

export const AcceptedPlaces = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${cinzaKuppi4};
  font-size: ${px2vw(8)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  color: ${cinzaKuppi7};
  background-color: transparent;
  height: ${px2vw(20)};
  padding-left: ${px2vw(7)};
  padding-right: ${px2vw(7)};
  margin-right: ${px2vw(5)};
`;

export const AdvertiserLogo = styled.img`
  height: ${px2vw(60)};
  width: ${px2vw(60)};
  border: solid 1px ${cinzaKuppi9};
  border-radius: ${px2vw(30)};
  margin-right: ${px2vw(8)};
`;

export const CouponNameText = styled.div`
  font-size: ${px2vw(12)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.32px;
`;

export const CouponDescriptionText = styled.div`
  font-size: ${px2vw(12)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  margin-top: ${px2vw(10)};
`;

export const CouponExpirationTitleText = styled.div`
  font-size: ${px2vw(12)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.32px;
  margin-top: ${px2vw(50)};
`;

export const CouponExpirationText = styled.div`
  font-size: ${px2vw(10)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.84px;
  margin-right: ${px2vw(10)};
`;

export const CouponExpirationDateText = styled.div`
  font-size: ${px2vw(12)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.84px;
  color: ${verdeKuppi6};
`;

export const NoProductsToCreateCouponText = styled.div`
  font-size: ${px2vw(16)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.32px;
  padding: ${px2vw(60)};
  text-align: center;
`;

export const GoToCatalog = styled(Link)`
  font-size: ${px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.36px;
  color: ${branco};
  margin-top: ${px2vw(10)};
  width: ${px2vw(150)};
  height: ${px2vw(30)};
  border-radius: ${px2vw(10)};
  border: none;
  background-color: ${verdeKuppi1};
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
    color: ${branco};
  }
`;

export const SubtitleTextBox = styled.div`
  margin-top: ${px2vw(30)};
  width: ${px2vw(1028)};
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const SubtitleText = styled.div`
  font-size: ${px2vw(12)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.36px;
  margin-left: ${px2vw(5)};
`;
