import styled from 'styled-components';

import { Input } from '@rocketseat/unform';

import {
  AiOutlineLeft,
  AiOutlineRight,
} from 'react-icons/ai';

import { Row } from '../../styles/grid';
import px2vw from '../../utils/responsiveness/px2vw';

import {
  cinzaKuppi1,
  cinzaKuppi3,
  cinzaKuppi4,
  cinzaKuppi7,
  verdeKuppi1,
  amareloKuppi2,
} from '../../styles/colors';

export const Container = styled.div``;

export const Header = styled(Row)`
  justify-content: flex-start;
  align-items: flex-start;
  padding: ${px2vw(25)} 0;
  padding: ${(props) => props.padding};
  font-size: ${px2vw(13)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.36px;

  ${(props) => `
    width: ${props.width};
  `};
`;

export const HeaderButton = styled.button`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: AvenirNextLTPro;
  font-weight: 700;
  background: transparent !important;
  border: none !important;
  box-shadow: none !important;
`;

export const Content = styled(Row)`
  justify-content: flex-start;
  align-items: flex-start;
  padding: ${px2vw(20)} 0;
  font-size: ${px2vw(12)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.36px;
  height: auto;

  ${(props) => `
    width: ${props.width};
  `};
`;

export const TableRow = styled(Row)`
  height: ${px2vw(60)};
  :hover {
    background: ${cinzaKuppi1};
  }
`;

export const Hr = styled.div`
  border-top: 2px solid ${cinzaKuppi1};
  height: 1px;
  width: 100%;
`;

export const PagButton = styled.button`
  width: ${px2vw(40)};
  height: ${px2vw(40)};
  border-radius: ${px2vw(40)};
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent !important;
  border: none !important;
  box-shadow: none !important;

  :hover {
    ${(props) =>
      props.disabled
        ? `background: transparent !important; cursor: not-allowed !important`
        : `background: ${cinzaKuppi1} !important`};
  }
`;

export const NavText = styled(Row)`
  justify-content: flex-start;
  align-items: flex-start;
  padding: ${px2vw(20)} 0;
  font-size: ${px2vw(12)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.36px;
  height: auto;

  ${(props) => `
    width: ${props.width};
  `};
`;

export const PrevIcon = styled(AiOutlineLeft).attrs({
  size: px2vw(12),
})`
  color: ${(props) => props.disabled && cinzaKuppi7};
`;
export const NextIcon = styled(AiOutlineRight).attrs({
  size: px2vw(12),
})`
  color: ${(props) => props.disabled && cinzaKuppi7};
`;

export const PauseButton = styled.button`
  height: 40px;
  width: 160px;
  border: none;
  background-color: transparent;

  ${(props) => `
    margin-left: ${props.margleft};
    margin-top: ${props.margtop};
    width: ${props.width};
  `};
`;

export const ButtonBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  ${(props) => `
    width: ${props.width};
  `};
`;

export const ButtonImage = styled.img`
  height: 20px;
  width: 20px;
  margin-right: 10px;

  ${(props) =>
    props.active &&
    `
    filter: invert(40%) sepia(79%) saturate(2498%) hue-rotate(4deg) brightness(103%) contrast(104%);
  `};
`;

export const ButtonTextRegular = styled.div`
  font-size: 16px;
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.32px;
  margin-top: 2px;

  ${(props) => props.greenColor && `color: ${verdeKuppi1}`};
  ${(props) =>
    props.yellowColor &&
    `
    color: ${amareloKuppi2};
    margin-right: ${props.margright};
  `};
`;

export const ErrorText = styled.div`
  font-size: ${px2vw(16)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.4px;
  margin-left: ${px2vw(20)};
`;

export const ConfirmDeleteModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ConfirmDeleteModalText = styled.div`
  font-size: ${px2vw(20)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.48px;
  margin-left: ${px2vw(35)};
  margin-right: ${px2vw(35)};
  margin-top: ${px2vw(20)};
  text-align: center;
`;

export const UpdateModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const UpdateModalText = styled.div`
  font-size: ${px2vw(16)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.48px;
  margin-left: ${px2vw(35)};
  margin-right: ${px2vw(35)};
  text-align: center;

  ${(props) => `
    margin-top: ${props.margtop};
  `};
`;

export const ModalInputLabelText = styled.div`
  font-size: ${px2vw(12)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.32px;
  margin-right: ${px2vw(10)};
`;

export const ModalInput = styled(Input)`
  padding: 0.5em;
  margin-top: ${px2vw(5)};
  border: 1px solid ${cinzaKuppi4};
  border-radius: ${px2vw(5)};
  font-size: ${px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.36px;
  width: ${px2vw(280)};
  height: ${px2vw(40)};

  ::placeholder {
    color: ${cinzaKuppi4} !important;
  }

  :focus {
    border: 1px solid ${cinzaKuppi3} !important;
    box-shadow: none !important;
  }

  ${(props) => `
    width: ${props.width};
    height: ${props.height};
    margin-top: ${props.margtop};
  `};
`;

export const LoadingBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
