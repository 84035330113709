import React, { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import moment from 'moment';
import { verdeKuppi15, verdeKuppi10 } from '../../styles/colors';

import RealTimeDimensions from '../../utils/responsiveness/RealTimeDimensions';
import { sendCreateProfile } from '../../services/vendors/mixpanel';

import { logout } from '../../store/modules/auth/actions';
import {
  updateOnboardingStepRequest,
  clearSuccess,
} from '../../store/modules/onboarding/actions';

import BaseScreenWeb from './BaseScreen/Web';
import BaseScreenMobile from './BaseScreen/Mobile';

import { SocketContext, connectWS } from '../../context/ws';

export const steps = ['step1', 'step2', 'step3', 'step4', 'step5', 'finished'];

export default function Onboarding() {
  const [notificationWS] = useContext(SocketContext);
  const [width] = RealTimeDimensions();
  const isWeb = width >= 800 ? true : false;

  // See if needs to show challenge activation bar
  const onboardingCreatedAt = useSelector(
    (state) =>
      state.user.user.advertiserInfo.advertiserOnboardingSurvey
        .onboarding_created_at
  );
  let onboardingCreatedAtFormated;
  if (onboardingCreatedAt !== null) {
    let formatedDate = moment(onboardingCreatedAt).format(
      'YYYY-MM-DD HH:mm:ss'
    );
    onboardingCreatedAtFormated = new Date(formatedDate.replace(/-/g, '/'));
    //21 hours offset for GMT-0 from database
    onboardingCreatedAtFormated.setHours(onboardingCreatedAtFormated.getHours() + 21);
  }

  const user = useSelector((state) => state.user.user);
  const paymentToken = useSelector((state) => state.user.paymentToken);
  const remainingFreeDays = useSelector(
    (state) => state.user.remainingFreeDays
  );
  const hasFreeTrialValid = useSelector(
    (state) => state.user.hasFreeTrialValid
  );
  const advPlan = user.advertiserInfo.advertiserSubscription;
  const minimumSubscriptionAmount = useSelector(
    (state) => state.user.minimumSubscriptionAmount
  );
  const currOnboardingStep = useSelector(
    (state) => state.onboarding.currOnboardingStep
  );
  const updateOnboardingStepLoading = useSelector(
    (state) => state.onboarding.updateOnboardingStepLoading
  );
  const updateOnboardingStepSuccess = useSelector(
    (state) => state.onboarding.updateOnboardingStepSuccess
  );

  const [currOnboardingStepPage, setCurrOnboardingStepPage] = useState(
    currOnboardingStep === 'finished' ? 'step5' : currOnboardingStep
  );
  const finishedStepImage = require('../../assets/images/onboarding/doneStep@2x.png');
  const [sideMenuState, setSideMenuState] = useState(false);
  const doneStepColor = verdeKuppi10;
  const dispatch = useDispatch();

  useEffect(() => {
    //Mixpanel Event
    sendCreateProfile({
      id: user.advertiserInfo.id,
      email: user.email,
      name: user.advertiserInfo.name,
      created_at: user.created_at,
      city: user.address[0].city,
      state: user.address[0].state,
      advertiserCategory: user.advertiserInfo.advertiserCategory.name,
      advertiserPlan: advPlan[advPlan.length - 1].subscription.name,
      advertiserPlanAmount: advPlan[advPlan.length - 1].subscription.amount,
      hasFreeTrialValid: hasFreeTrialValid,
    });
  }, []);

  function handleLogout() {
    dispatch(logout());
  }

  // If not connected to WS server, connects
  useEffect(() => {
    if (!notificationWS.connected) {
      connectWS(true);
    }
  }, []);

  function handleSideBarMenuClick(selectedStep, isMobile = false) {
    if (
      steps.indexOf(currOnboardingStepPage) >= steps.indexOf(selectedStep) ||
      steps.indexOf(currOnboardingStep) >= steps.indexOf(selectedStep)
    ) {
      setCurrOnboardingStepPage(selectedStep);

      if (isMobile) {
        setSideMenuState(!sideMenuState);
      }
    } else {
      toast.error('Você deve finalizar o passo atual antes de avançar', {
        autoClose: 6000,
      });
    }
  }

  function handleStartButtonClick() {
    dispatch(updateOnboardingStepRequest('step1'));
  }

  // Go to the next pending onboarding step
  useEffect(() => {
    if (updateOnboardingStepSuccess) {
      if (currOnboardingStep === 'finished') {
        setCurrOnboardingStepPage('step5');
      } else {
        setCurrOnboardingStepPage(currOnboardingStep);
      }
      dispatch(clearSuccess());
    }
  }, [updateOnboardingStepSuccess]);

  function handleProgressBarColor(step, progressStep) {
    if (currOnboardingStep === step) {
      return `${verdeKuppi15}`;
    } else if (steps.indexOf(currOnboardingStep) > steps.indexOf(step)) {
      return doneStepColor;
    } else {
      return progressStep;
    }
  }

  function handleSideMenuImgs(step, pendingStepImage, currentStepImage) {
    if (currOnboardingStep === step) {
      return currentStepImage;
    } else if (steps.indexOf(currOnboardingStep) > steps.indexOf(step)) {
      return finishedStepImage;
    } else {
      return pendingStepImage;
    }
  }

  const handler = {
    isWeb: isWeb,
    baseScreen: {
      currOnboardingStep: currOnboardingStep,
      currOnboardingStepPage: currOnboardingStepPage,
      user: user,
      hasFreeTrialValid: hasFreeTrialValid,
      remainingFreeDays: remainingFreeDays,
      minimumSubscriptionAmount: minimumSubscriptionAmount,
      paymentToken: paymentToken,
      sideMenuState: sideMenuState,
      logoutButtonClick: () => handleLogout(),
      handleSideBarMenuClick: (selectedStep, isMobile) =>
        handleSideBarMenuClick(selectedStep, isMobile),
      handleSideMenuImgs: (step, pendingImg, currentImg) =>
        handleSideMenuImgs(step, pendingImg, currentImg),
      handleProgressBarColor: (step, progressStep) =>
        handleProgressBarColor(step, progressStep),
      setSideMenuState: () => setSideMenuState(!sideMenuState),
      setCurrOnboardingStepPage: (selectedStep) =>
        setCurrOnboardingStepPage(selectedStep),
      handleLogout: () => handleLogout(),
    },
    welcomeScreen: {
      user: user,
      updateOnboardingStepLoading: updateOnboardingStepLoading,
      handleLogout: () => handleLogout(),
      handleStartButtonClick: () => handleStartButtonClick(),
    },
    profileConfigsScreen: {
      updateOnboardingStepLoading: updateOnboardingStepLoading,
      currOnboardingStep: currOnboardingStep,
      setCurrOnboardingStepPage: (selectedStep) =>
        setCurrOnboardingStepPage(selectedStep),
    },
    deliveryParamsScreen: {
      currOnboardingStep: currOnboardingStep,
      setCurrOnboardingStepPage: (selectedStep) =>
        setCurrOnboardingStepPage(selectedStep),
    },
    catalogScreen: {
      currOnboardingStep: currOnboardingStep,
      setCurrOnboardingStepPage: (selectedStep) =>
        setCurrOnboardingStepPage(selectedStep),
    },
    printConfigsScreen: {
      currOnboardingStep: currOnboardingStep,
      hasFreeTrialValid: hasFreeTrialValid,
      setCurrOnboardingStepPage: (selectedStep) =>
        setCurrOnboardingStepPage(selectedStep),
    },
  };

  if (isWeb) {
    return <BaseScreenWeb handler={handler} />;
  } else {
    return <BaseScreenMobile handler={handler} />;
  }
}
