import styled, { css, keyframes } from 'styled-components';

import {
  cinzaKuppi8,
  cinzaKuppi11,
  verdeKuppi2,
  branco,
  verdeKuppi1,
  cinzaKuppi2,
  preto2,
  cinzaKuppi4,
} from '../../../styles/colors';

import px2vw from '../../../utils/responsiveness/px2vw';

export const Container = styled.div`
  background: ${branco};
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: ${px2vw(10)} ${px2vw(50)};
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 0.3em;
    border-radius: 3px;
  }
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${verdeKuppi2};
    outline: 1px solid ${verdeKuppi2};
  }
`;

export const Paragraph = styled.p`
  font-size: ${px2vw(12)};
  margin: ${px2vw(30)} 0;
  margin-top: ${(props) => (props.margtop ? props.margtop : px2vw(30))};
  margin-bottom: ${(props) =>
    props.margbottom ? props.margbottom : px2vw(30)};
  font-family: AvenirNextLTPro;
  letter-spacing: 0.36px;
  font-weight: 500;
  color: ${(props) => (props.color ? props.color : verdeKuppi2)};
  @media(max-width: 500px) {
    font-size: ${px2vw(20)};

`;

export const Label = styled.label`
  font-size: ${px2vw(15)};
  margin-top: ${px2vw(15)};
  margin-bottom: ${px2vw(3)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.36px;
  display: block;
  @media (max-width: 500px) {
    font-size: ${px2vw(20)};
  }
`;

export const CountryCodeInput = styled.input`
  background-color: ${cinzaKuppi8};
  height: ${px2vw(45)};
  font-size: ${px2vw(17)};
  border-left: none;
  border-bottom: none;
  border-top: none;
  border-right-width: 2px;
  border-right-color: #9b999b;
  padding-left: ${px2vw(15)};
  padding-right: ${px2vw(9)};
  width: 15%;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;

  @media (max-width: 900px) {
    padding-left: ${px2vw(3)};
    padding-right: ${px2vw(3)};
  }
`;

export const TelInput = styled.input`
  background-color: ${cinzaKuppi8};
  height: ${px2vw(45)};
  font-size: ${px2vw(17)};
  border: none;
  padding-left: ${px2vw(15)};
  flex-grow: 1;
  display: inline-block;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
`;

export const InputText = styled.input`
  background-color: ${cinzaKuppi8};
  height: ${px2vw(45)};
  font-size: ${px2vw(17)};
  border: none;
  padding-left: ${px2vw(15)};
  flex-grow: 1;
  display: inline-block;
  border-radius: 10px;
`;

export const InputBox = styled.div`
  display: flex;
  width: 100%;
`;

export const ModalFooter = styled.footer`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: auto;
  border-top: 4px solid ${cinzaKuppi8};
  height: ${px2vw(110)};
  width: 100%;
`;

export const ManualOrderButtonPrimary = styled.button`
  background-color: ${verdeKuppi2};
  border: 1px solid ${verdeKuppi2};
  color: ${branco};
  border-radius: ${px2vw(5)};
  font-size: ${px2vw(12)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.32px;
  padding: ${px2vw(8)};
  margin: 0 auto;
  width: 65%;
  box-shadow: 2px 2px 3px ${cinzaKuppi2};

  &:focus,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
    color: ${branco};
  }

  &:active {
    box-shadow: 2px 2px 3px ${cinzaKuppi2};
    background: ${verdeKuppi1};
  
    &:disabled  {
      background-color: ${cinzaKuppi4};
      border: 1px solid ${cinzaKuppi4};
    }
  }

  &:disabled  {
    background-color: ${cinzaKuppi4};
    border: 1px solid ${cinzaKuppi4};
  }


`;

export const ModalHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const transicao = keyframes`
to {
  opacity: initial;
  transform: initial;
  bottom:${px2vw(0)};
}
`

export const ManualOrderHeader = styled.div`
  width: 100%;
  background-color: ${(props) => (props.currStep ? cinzaKuppi8 : verdeKuppi2)};
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: ${px2vw(13)} ${px2vw(20)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  font-size: ${px2vw(15.5)};
  display: flex;
  color: ${(props) => (props.currStep ? preto2 : branco)};
  align-items: space-between;
  justify-content: space-between;
`;

export const MinimizedManualOrderHeader = styled.div`
  width: ${px2vw(537)};
  background-color: ${(props) => (props.currStep ? cinzaKuppi8 : verdeKuppi2)};
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: ${px2vw(13)} ${px2vw(20)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  font-size: ${px2vw(15.5)};
  display: flex;
  color: ${(props) => (props.currStep ? preto2 : branco)};
  align-items: space-between;
  justify-content: space-between;
  position: fixed;
  bottom:${px2vw(2)};
  left: ${px2vw(400)};
  opacity: 0;
  animation: ${transicao} 0.4s forwards;
  z-index: 19;

  -webkit-box-shadow: 7px -5px 12px 0px rgba(0,0,0,0.2);
  -moz-box-shadow: 7px -5px 12px 0px rgba(0,0,0,0.2);
  box-shadow: 7px -5px 12px 0px rgba(0,0,0,0.2);

  ${(props) =>
    props.isWhatsappwebPage &&
    `
    position: absolute;
    bottom:${px2vw(0)};
    left: ${px2vw(0)};
    width: ${px2vw(535)};
    z-index: 10;
  `}

`;

export const ProgressBar = styled.div`
  background-color: ${cinzaKuppi11};
  width: 100%;
`;

export const CloseButton = styled.button`
  width: ${px2vw(18)};
  height: ${px2vw(18)};
  border: none;
  z-index: 10;
  background-color: transparent;

  :focus {
    background-color: transparent !important;
  }

  ${(props) =>
    props.absolute &&
    css`
      position: absolute;
      top: ${props.top || px2vw(10)};
      right: ${props.right || px2vw(10)};
    `}
`;

export const CloseImage = styled.img`
  width: ${px2vw(17)};
  height: ${px2vw(17)};
`;

export const MinimizeButton = styled.button`
  border: none;
  width: ${px2vw(17)};
  z-index: 10;
  background-color: transparent;
  margin-right: ${px2vw(30)};
  :focus {
    background-color: transparent !important;
  }

  ${(props) =>
    props.absolute &&
    css`
      position: absolute;
      top: ${props.top || px2vw(10)};
      right: ${props.right || px2vw(10)};
    `}
`;

export const MinimizeImage = styled.img`
  width: ${px2vw(17)};
`;

export const iconMui = {
  color: verdeKuppi1,
  width: px2vw(20),
};
