import styled from 'styled-components';

import { Input } from '@rocketseat/unform';

import {
  branco,
  cinzaKuppi3,
  cinzaKuppi4,
  verdeKuppi1,
} from '../../styles/colors';

import px2vw from '../../utils/responsiveness/px2vw';

export const PasswordRequiredBox = styled.div`
  flex-direction: column;
  border: none;
  border-radius: ${px2vw(20)};
  background-color: ${branco};
  width: ${px2vw(600)};
  height: ${px2vw(360)};
  padding: ${px2vw(30)};

  ${(props) => `
    width: ${props.width};
    height: ${props.height};
    padding: ${props.pad};
  `};
`;

export const LogoImageBox = styled.div`
  position: relative;
  width: 100%;
  height: 100vw;
  max-width: ${px2vw(159)};
  max-height: ${px2vw(46)};

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-family: AvenirNextLTPro;
  font-weight: normal;
`;

export const LogoImage = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: AvenirNextLTPro;
  font-weight: normal;
`;

export const InformationText = styled.div`
  margin-top: ${px2vw(20)};
  font-size: ${px2vw(16)};
  width: ${px2vw(440)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.48px;
  text-align: center;
`;

export const PasswordInputBox = styled.div`
  width: ${px2vw(540)};
  height: ${px2vw(80)};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: ${px2vw(30)};
`;

export const PasswordInputLabel = styled.div`
  font-size: ${px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.36px;
  margin-bottom: ${px2vw(2)};
`;

export const PasswordBox = styled.div`
  display: flex;
  flex-direction: row;
  width: 80%;
`;

export const PasswordInput = styled(Input)`
  padding: 10px 10px !important;
  margin: 0 !important;
  border: 1px solid ${cinzaKuppi4} !important;
  border-radius: 5px !important;
  font-size: ${px2vw(16)} !important;
  font-family: font-family: AvenirNextLTPro !important;
  font-weight: normal;
  letter-spacing: 0.36px !important;
  width: 100%;
  height: auto;

  ::placeholder {
    color: ${cinzaKuppi3} !important;
  }

  :focus {
    border: 1px solid ${cinzaKuppi3} !important;
    box-shadow: none !important;
  }

  ${(props) => `
    width: ${props.width} !important;
  `};
`;

export const SeePasswordButton = styled.button`
  margin-left: -30px;
  margin-top: 18px;
  width: 20px;
  height: 20px;
  border: none;
  z-index: 10;
  background-color: transparent;

  :focus {
    background-color: transparent !important;
  }
`;

export const SeePasswordImage = styled.img`
  width: 20px;
  height: 20px;
`;

export const AccessButton = styled.button`
  background-color: ${verdeKuppi1};
  width: ${px2vw(300)};
  height: 55px;
  border: 1px solid ${verdeKuppi1};
  border-radius: 5px;
  padding: 0.3em;
  margin-top: ${px2vw(10)};

  :focus {
    background-color: ${verdeKuppi1} !important;
  }
`;

export const AccessButtonText = styled.div`
  font-size: 16px;
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.36px;
  color: ${branco};
`;
