import React from 'react';
import { Row, Col } from '../../../../../styles/grid';
import {
  FormBox,
  PageTitle,
  NormalText,
  ImgBox,
  Img,
} from '../../../BaseScreen/styles';
import { FormDataInput, CopyButton, InputDataTextArea } from '../../styles';

import px2vw from '../../../../../utils/responsiveness/px2vw';

import ButtonPrimary from '../../../../../components/ButtonPrimary';
import { LoadingBox } from '../../../../../styles/common';
import { Spinner } from 'reactstrap';
import { preto } from '../../../../../styles/colors';


export default function InstagramWeb({ handler }) {
  return (
    <Col>
      <FormBox
        webs
        width={px2vw(1000)}
        height={px2vw(450)}
        maxheight={px2vw(450)}
        minheight={px2vw(450)}
        margleft={px2vw(35)}
        flexDirection="column"
      >
        <Row justifyStart height="auto" margbottom={px2vw(20)}>
          <PageTitle fontsize={px2vw(19)}>
            Aumente seus pedidos vindos das redes sociais
          </PageTitle>
        </Row>
        <Row width="auto" height="auto" spaceBetween justifyStart>
          <Col width={px2vw(470)} height="auto" alignStart>
            <NormalText textalign="start" margbottom={px2vw(20)}>
              Insira o link do seu cardápio web na bio do seu instagram para
              melhorar a sua conversão nas redes sociais.
            </NormalText>
            <NormalText textalign="start" fontweight="bold">
              Site
            </NormalText>
            <FormDataInput
              name="site"
              type="text"
              value={handler.webMenuDomain}
              height={px2vw(40)}
              width={px2vw(470)}
              id="site"
            />
            <Row margtop={px2vw(20)} margbottom={px2vw(20)} justifyEnd>
              <CopyButton onClick={() => handler.handleCopy("site")} type="button">Copiar Site</CopyButton>
            </Row>
            <NormalText textalign="start" fontweight="bold" margleft={px2vw(5)}>
              Bio
            </NormalText>
            <InputDataTextArea
              name="Text"
              maxLength="100"
              value="Peça no nosso site e tenha acesso aos melhores preços:  "
              id="bio"
            />
            <Row margtop={px2vw(20)} margbottom={px2vw(20)} justifyEnd>
              <CopyButton onClick={() => handler.handleCopy("bio")} type="button">Copiar Bio</CopyButton>
            </Row>
          </Col>
          <Col
            width="auto"
            height="auto"
            margright={px2vw(35)}
            margtop={px2vw(120)}
          >
            <ImgBox>
              <Img
                width={px2vw(370)}
                height={px2vw(420)}
                src={require('../../../../../assets/images/onboarding/store_publicity/insta1@2x.png')}
              />
            </ImgBox>
          </Col>
        </Row>
      </FormBox>
      <Row
        height="auto"
        width={px2vw(1000)}
        justifyEnd
        margtop={px2vw(20)}
        margbottom={px2vw(10)}
      >
        <ButtonPrimary
          minWidth={150}
          type="button"
          onClick={handler.handleFinishStep}
        >
          {handler.updateOnboardingStepLoading ? (
            <LoadingBox>
              <Spinner
                style={{
                  width: '1.8rem',
                  height: '1.8rem',
                  color: preto,
                }}
              />{' '}
            </LoadingBox>
          ) : ( 
            'Concluir'
          )}
        </ButtonPrimary>
      </Row>
    </Col>
  );
}
