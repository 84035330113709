export function queryZipcodeRequest(zipcode) {
  return {
    type: '@userAddressQuery/QUERY_ZIPCODE_REQUEST',
    payload: { zipcode },
  };
}

export function queryZipcodeSuccess(addressData) {
  return {
    type: '@userAddressQuery/QUERY_ZIPCODE_SUCCESS',
    payload: { addressData },
  };
}

export function queryZipcodeFailure() {
  return {
    type: '@userAddressQuery/QUERY_ZIPCODE_FAILURE',
  };
}

export function clearSuccess() {
  return {
    type: '@userAddressQuery/CLEAR_SUCCESS',
  };
}
