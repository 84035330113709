import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from 'reactstrap';
import { toast } from 'react-toastify';
import { FormControlLabel } from "@material-ui/core";

import { branco, preto, cinzaKuppi9 } from "../../../../../../styles/colors";
import { Col, Row } from "../../../../../../styles/grid";
import {
  Image,
  Hr,
  NormalText,
} from '../../../../BaseScreen/styles';
import {
  ModalBackground,
  OptionBox,
  ProductSelectInput,
  SwitchInput,
  SwitchController,
  SwitchWithInput,
  FadingCol
} from '../../styles';
import {
  LoadingBox,
} from '../../../../../_layouts/styles/common';

import px2vw from "../../../../../../utils/responsiveness/px2vw";
import { statesOptions } from '../../../../../../utils/commonObjects';
import { amountMask } from "../../../../../../utils/masks";
import { onlyNumbersAmount } from "../../../../../../utils/utils";

import ButtonPrimary from '../../../../../../components/ButtonPrimary';
import ButtonSecondary from '../../../../../../components/ButtonSecondary';

import {
  pauseNeighborhoodRequest,
  updateNeighborhoodRequest,
  deleteNeighborhoodRequest,
} from '../../../../../../store/modules/myStore/actions';

export default function NeighborhoodModal({ handler }) {
  const selectedNeighborhoods = useSelector(
    (state) => state.myStore.selectedNeighborhoods
  );
  const [currEditNeighborhoodId, setCurrEditNeighborhoodId] = useState(null);
  const [currEditNeighborhoodAmount, setCurrEditNeighborhoodAmount] = useState(null);
  const [confirmDeleteNeighborhood, setConfirmDeleteNeighborhood] = useState(false);
  const pauseNeighborhoodLoading = useSelector(
    (state) => state.myStore.pauseNeighborhoodLoading
  );
  const pauseNeighborhoodSuccess = useSelector(
    (state) => state.myStore.pauseNeighborhoodSuccess
  );
  const updateNeighborhoodModalLoading = useSelector(
    (state) => state.myStore.updateNeighborhoodModalLoading
  );
  const updateNeighborhoodModalSuccess = useSelector(
    (state) => state.myStore.updateNeighborhoodModalSuccess
  );
  const deleteNeighborhoodModalLoading = useSelector(
    (state) => state.myStore.deleteNeighborhoodModalLoading
  );
  const deleteNeighborhoodModalSuccess = useSelector(
    (state) => state.myStore.deleteNeighborhoodModalSuccess
  );
  const cardActionLoading = pauseNeighborhoodLoading || updateNeighborhoodModalLoading || deleteNeighborhoodModalLoading;
  const [addDeliveryState, setAddDeliveryState] = useState(false || selectedNeighborhoods.length === 0);
  const dispatch = useDispatch();

  useEffect(() => {
    if (pauseNeighborhoodSuccess) {
      setCurrEditNeighborhoodId(null);
    }
  }, [pauseNeighborhoodSuccess]);

  useEffect(() => {
    if (deleteNeighborhoodModalSuccess) {
      setCurrEditNeighborhoodId(null);
    }
  }, [deleteNeighborhoodModalSuccess]);

  useEffect(() => {
    if (updateNeighborhoodModalSuccess) {
      setCurrEditNeighborhoodId(null);
      setCurrEditNeighborhoodAmount(null);
    }
  }, [updateNeighborhoodModalSuccess]);

  return (
    <ModalBackground isVisible={true} >
      <Col
        background={branco} borderRadius={px2vw(20)}
        width='90%'
        height='auto'
        maxheight='80%'
        justifyStart
        padleft={px2vw(80)}
        overflowY
      >
        <Row padtop={px2vw(100)} >
          <NormalText
            fontweight='bold'
            textalign='flex-start'
            color={preto}
            margleft={px2vw(20)}
            fontsize={px2vw(60)}>
            Configurações da área de entrega
          </NormalText>
          {/* Close modal button */}
          <Image src={require('../../../../../../assets/images/onboarding/closeSubMenu@2x.png')}
            width={px2vw(150)}
            height={px2vw(150)}
            margright={px2vw(60)}
            cursor="pointer"
            onClick={() => {
              handler.setNeighborhoodAreaModal(false)
              handler.setLocalitiesSelect([]);
              handler.setDistrictsSelect([]);
              handler.setNewCitySelectedObj(null);
              handler.setNewNeighborhoodSelectedObj(null);
              handler.setNewDeliveryFee(null);
            }}
          />
        </Row>
        {/* State modal select and Delivery fee modal input*/}
        <FadingCol isActive={addDeliveryState}>
          <Row spaceBetween>
            {/* State modal select */}
            <Col
              margtop={px2vw(80)}
              
            >
              <NormalText
                fontweight='bold'
                textalign='flex-start'
                color={preto}
                fontsize={px2vw(50)}
                padbottom={px2vw(50)}
                margleft={px2vw(20)}
              >
                Estado
              </NormalText>
              <OptionBox isVisible={true}>
                <ProductSelectInput
                  background={branco}
                  width={px2vw(600)}
                  height={px2vw(120)}
                  fontsize={px2vw(40)}

                  id="newState"
                  name="newState"
                  placeholder="Selecione o estado"
                  value={handler.newStateSelected}
                  options={statesOptions}
                  onChange={(t) => {
                    // Set the current state selected
                    handler.setNewStateSelected(t.target.value);
                    // Clear the localities select
                    handler.localitiesSelect.length = 0;
                    handler.localitiesSelect.push({
                      id: '',
                      title: 'Selecione a cidade',
                    });
                    // Clear the current locality selected
                    handler.setNewCitySelectedObj(null);
                    // Clear the districts select
                    handler.districtsSelect.length = 0;
                    handler.districtsSelect.push({
                      id: '',
                      title: 'Selecione o bairro',
                    });
                    // Clear the current district selected
                    handler.setNewNeighborhoodSelectedObj(null);
                    // Get all localities from the current state
                    handler.handleGetLocalitiesRequest(t.target.value);
                  }}
                  disabled={handler.localitiesLoading}
                />
              </OptionBox>
            </Col>
            {/* Delivery fee modal input */}
            <Col
              margtop={px2vw(80)}
              margleft={px2vw(30)}
            >
              <NormalText
                fontweight='bold'
                textalign='flex-start'
                color={preto}
                fontsize={px2vw(50)}
                padbottom={px2vw(50)}
              >
                Taxa de entrega
              </NormalText>
              <SwitchInput
                name="newDeliveryFee"
                maxLength="9"
                placeholder="R$ 4.50"
                width={px2vw(400)}
                margleft="0px"
                value={amountMask(handler.newDeliveryFee)}
                onChange={(t) => {
                  handler.setNewDeliveryFee(amountMask(t.target.value));
                }}
              />
            </Col>
          </Row>
          {/* City modal select */}
          <Col>
            <NormalText
              fontweight='bold'
              textalign='flex-start'
              color={preto}
              margleft={px2vw(30)}
              margtop={px2vw(80)}
              fontsize={px2vw(50)}
              padbottom={px2vw(50)}>
              Cidade
            </NormalText>

            <OptionBox isVisible={true}>
              <ProductSelectInput
                id="newCity"
                name="newCity"
                placeholder={
                  handler.localitiesSelect.length === 1 &&
                    handler.localitiesSelect[0].title === 'Selecione a cidade'
                    ? 'Sem cidades disponíveis'
                    : 'Selecione a cidade'
                }
                background={branco}
                width={px2vw(1030)}
                height={px2vw(120)}
                fontsize={px2vw(40)}
                options={handler.localitiesSelect}
                onChange={(t) => {
                  // Set the current locality selected
                  handler.setNewCitySelectedObj(
                    t.target.value !== '' ? handler.localities[t.target.value] : null
                  );
                  // Clear the districts select
                  handler.districtsSelect.length = 0;
                  handler.districtsSelect.push({
                    id: '',
                    title: 'Selecione o bairro',
                  });
                  // Clear the current district selected
                  handler.setNewNeighborhoodSelectedObj(null);
                  if (t.target.value !== '') {
                    // Get all districts from the current locality
                    handler.handleGetDistrictsRequest(handler.localities[t.target.value].id);
                  }
                }}
                disabled={
                  handler.localitiesLoading ||
                  (handler.localitiesSelect.length === 1 &&
                    handler.localitiesSelect[0].title === 'Selecione a cidade')
                }
              />
            </OptionBox>
          </Col>
          {/* Neighborhood modal select */}
          <Col>
            <NormalText
              fontweight='bold'
              textalign='flex-start'
              color={preto}
              margleft={px2vw(20)}
              margtop={px2vw(80)}
              fontsize={px2vw(50)}
              padbottom={px2vw(50)}>
              Bairro
            </NormalText>

            <OptionBox isVisible={true}>
              <ProductSelectInput
                id="newNeighborhood"
                name="newNeighborhood"
                placeholder={
                  handler.districtsSelect.length === 1 &&
                    handler.districtsSelect[0].title === 'Selecione o bairro'
                    ? 'Sem bairros disponíveis'
                    : 'Selecione o bairro'
                }
                background={branco}
                width={px2vw(1030)}
                height={px2vw(120)}
                fontsize={px2vw(40)}
                options={handler.districtsSelect}
                onChange={(t) => {
                  handler.setNewNeighborhoodSelectedObj(handler.districts[t.target.value]);
                }}
                disabled={
                  !handler.newCitySelectedObj ||
                  handler.districtsLoading ||
                  (handler.districtsSelect.length === 1 &&
                    handler.districtsSelect[0].title === 'Selecione o bairro')
                }
              />
            </OptionBox>
          </Col>
        </FadingCol>
        <Row margtop={px2vw(70)} margbottom={addDeliveryState ? px2vw(100) : px2vw(60)} >
          <ButtonPrimary
            minWidth={addDeliveryState ? px2vw(500) : px2vw(700)}
            height={130}
            fontSize={50}
            borderRadius={20}
            disabled={handler.addNewNeighborhoodDataLoading}
            type="button"
            onClick={() => {
              if (!addDeliveryState) {
                setAddDeliveryState(true)
              } else {
                if (!handler.newCitySelectedObj) {
                  toast.error('Não foi selecionada a cidade', {
                    autoClose: 6000,
                  });
                } else if (!handler.newNeighborhoodSelectedObj) {
                  toast.error('Não foi selecionado o bairro', {
                    autoClose: 6000,
                  });
                } else if (!handler.newDeliveryFee || handler.newDeliveryFee.length < 7) {
                  toast.error('Taxa de entrega inválida', {
                    autoClose: 6000,
                  });
                } else {
                  handler.handleAddNewNeighborhoodRequest(
                    handler.newNeighborhoodSelectedObj.id,
                    onlyNumbersAmount(handler.newDeliveryFee)
                  );
                  setAddDeliveryState(false)
                }
              }

            }}
          >
            {handler.addNewNeighborhoodDataLoading ? (
              <LoadingBox>
                <Spinner
                  style={{
                    width: '1.8rem',
                    height: '1.8rem',
                    color: preto,
                  }}
                />{' '}
              </LoadingBox>
            ) : (
              `${addDeliveryState ? 'Incluir' : 'Adicionar novo bairro'}`
            )}
          </ButtonPrimary>
        </Row>
        {selectedNeighborhoods && selectedNeighborhoods.map((neighborhood, idx) => {
          return (
            <Col relative>
              <Col
                key={idx}
                borderRadius={`0 0 ${px2vw(20)} ${px2vw(20)}`}
                borderbottom={`outset ${cinzaKuppi9}50`}
                margleft={px2vw(-80)}
                width="107%"
                alignCenter
                padleft={px2vw(30)}
                opacity={cardActionLoading && currEditNeighborhoodId === neighborhood.id ? 0.4 : 1}
              >
                <Row>
                  <Col>
                    <NormalText
                      fontweight='bold'
                      textalign='flex-start'
                      color={preto}
                      margleft={px2vw(20)}
                      margtop={px2vw(80)}
                      fontsize={px2vw(50)}>
                      Estado/Cidade
                    </NormalText>
                    <NormalText
                      fontweight='normal'
                      textalign='flex-start'
                      color={preto}
                      margleft={px2vw(20)}
                      margtop={px2vw(20)}
                      fontsize={px2vw(40)}
                      padbottom={px2vw(50)}>
                      {`${neighborhood.uf} / ${neighborhood.city}`}
                    </NormalText>
                  </Col>

                  <SwitchWithInput key={`neighborhood${idx}`}
                    marginBottom={px2vw(20)}
                    margtop={px2vw(130)}
                    margleft={px2vw(200)}
                    margright={px2vw(-40)}
                  >
                    <FormControlLabel
                      control={
                        <SwitchController
                          type="checkbox"
                          checked={neighborhood.is_active}
                          onChange={() => {
                            setConfirmDeleteNeighborhood(false);
                            setCurrEditNeighborhoodId(neighborhood.id);
                            dispatch(
                              pauseNeighborhoodRequest(
                                neighborhood.id,
                                neighborhood.is_active
                              )
                            )
                          }}
                        />
                      }
                    />
                  </SwitchWithInput>
                  {currEditNeighborhoodId !== neighborhood.id && (
                    <>
                      <Image
                        width={px2vw(75)}
                        height={px2vw(70)}
                        margtop={px2vw(145)}
                        margright={px2vw(50)}
                        src={require('../../../../../../assets/images/onboarding/editPencil.png')}
                        cursor="pointer"
                        onClick={() => {
                          setConfirmDeleteNeighborhood(false);
                          setCurrEditNeighborhoodId(neighborhood.id);
                          setCurrEditNeighborhoodAmount(amountMask(neighborhood.delivery_fee.toFixed(2)));
                        }}
                      />
                      <Image
                        width={px2vw(90)}
                        height={px2vw(95)}
                        margtop={px2vw(130)}
                        margright={px2vw(60)}
                        src={require('../../../../../../assets/images/delete/delete_black@2x.png')}
                        cursor="pointer"
                        onClick={() => {
                          setCurrEditNeighborhoodId(neighborhood.id);
                          setConfirmDeleteNeighborhood(true);
                        }}
                      />
                    </>
                  )}
                  {currEditNeighborhoodId === neighborhood.id && (
                    <>
                      <Image
                        width={px2vw(75)}
                        height={px2vw(70)}
                        margtop={px2vw(145)}
                        margright={px2vw(54)}
                        src={require('../../../../../../assets/images/onboarding/confirm_edit@2x.png')}
                        cursor="pointer"
                        onClick={() => {
                          if (!cardActionLoading) {
                            if (currEditNeighborhoodAmount.length < 7) {
                              toast.error('Taxa de entrega inválida', {
                                autoClose: 6000,
                              });
                            } else {
                              dispatch(
                                updateNeighborhoodRequest(
                                  currEditNeighborhoodId,
                                  onlyNumbersAmount(currEditNeighborhoodAmount)
                                )
                              );
                            }
                          }
                        }}
                      />
                      <Image
                        width={px2vw(73)}
                        height={px2vw(73)}
                        margtop={px2vw(150)}
                        margright={px2vw(65)}
                        src={require('../../../../../../assets/images/onboarding/cancel_edit@2x.png')}
                        cursor="pointer"
                        onClick={() => {
                          if (!cardActionLoading) {
                            setCurrEditNeighborhoodId(null)
                          }
                        }}
                      />
                    </>
                  )}
                </Row>
                <Hr width='90%' margleft={px2vw(-30)} />
                <Row>
                  <Col>
                    <NormalText
                      fontweight='bold'
                      textalign='flex-start'
                      color={preto}
                      margleft={px2vw(20)}
                      margtop={px2vw(20)}
                      fontsize={px2vw(50)}
                    >
                      Bairro
                    </NormalText>
                    <NormalText
                      fontweight='normal'
                      textalign='flex-start'
                      color={preto}
                      margleft={px2vw(20)}
                      margtop={px2vw(20)}
                      fontsize={px2vw(40)}
                    >
                      {neighborhood.neighborhood}
                    </NormalText>
                  </Col>
                  <Hr vertical height={px2vw(200)} margtop={px2vw(20)}></Hr>
                  <Col margleft={px2vw(30)}>
                    <Col>
                      <NormalText
                        fontweight='bold'
                        textalign='flex-start'
                        color={preto}
                        margleft={px2vw(20)}
                        margtop={px2vw(20)}
                        fontsize={px2vw(50)}
                      >
                        Taxa de Entrega
                      </NormalText>
                      <SwitchInput
                        name="editDeliveryFee"
                        maxLength="9"
                        placeholder="R$ 4.50"
                        width={px2vw(430)}
                        margleft="0px"
                        padleft={px2vw(35)}
                        margbottom={px2vw(35)}
                        margtop={px2vw(10)}
                        value={currEditNeighborhoodId !== neighborhood.id ? amountMask(neighborhood.delivery_fee.toFixed(2)) : currEditNeighborhoodAmount}
                        onChange={(t) => setCurrEditNeighborhoodAmount(amountMask(t.target.value))}
                        readonly={currEditNeighborhoodId !== neighborhood.id}
                        onClick={() => {
                          if (currEditNeighborhoodId !== neighborhood.id) {
                            setConfirmDeleteNeighborhood(false);
                            setCurrEditNeighborhoodId(neighborhood.id);
                            setCurrEditNeighborhoodAmount(amountMask(neighborhood.delivery_fee.toFixed(2)));
                          }
                        }}
                        cursor="pointer"
                        disabled={cardActionLoading && currEditNeighborhoodId === neighborhood.id}
                      />
                    </Col>
                  </Col>
                </Row>
              </Col>
              {cardActionLoading && currEditNeighborhoodId === neighborhood.id && (
                <Row alignCenter justifyCenter absolute top={px2vw(40)}>
                  <LoadingBox>
                    <Spinner
                      style={{
                        width: '1.8rem',
                        height: '1.8rem',
                        color: preto,
                      }}
                    />
                  </LoadingBox>
                </Row>
              )}
              {confirmDeleteNeighborhood && currEditNeighborhoodId === neighborhood.id && (
                <Col
                  alignCenter
                  justifyCenter
                  absolute
                  background={cinzaKuppi9}
                  padtop={px2vw(80)}
                  padbottom={px2vw(80)}
                  height="auto"
                  margleft={px2vw(-40)}
                  borderRadius={px2vw(20)}
                >
                  <NormalText
                    fontweight='bold'
                    textalign='center'
                    color={preto}
                    margleft={px2vw(20)}
                    margtop={px2vw(20)}
                    fontsize={px2vw(50)}
                  >
                    Tem certeza que deseja excluir a entrega nesse bairro?
                  </NormalText>
                  <Row spaceBetween margtop={px2vw(40)} margleft={px2vw(160)} padright={px2vw(160)}>
                    <ButtonSecondary
                      minWidth={px2vw(450)}
                      height={130}
                      fontSize={50}
                      borderRadius={20}
                      marginRight={px2vw(80)}
                      disabled={deleteNeighborhoodModalLoading}
                      type="button"
                      onClick={() => {
                        setConfirmDeleteNeighborhood(false);
                        setCurrEditNeighborhoodId(null);
                      }}
                    >
                      Não
                    </ButtonSecondary>
                    <ButtonPrimary
                      minWidth={px2vw(450)}
                      height={130}
                      fontSize={50}
                      borderRadius={20}
                      disabled={handler.addNewNeighborhoodDataLoading}
                      type="button"
                      onClick={() =>
                        dispatch(
                          deleteNeighborhoodRequest(
                            neighborhood.id
                          )
                        )
                      }
                    >
                      {deleteNeighborhoodModalLoading ? (
                        <LoadingBox>
                          <Spinner
                            style={{
                              width: '1.8rem',
                              height: '1.8rem',
                              color: preto,
                            }}
                          />{' '}
                        </LoadingBox>
                      ) : (
                        'Sim'
                      )}
                    </ButtonPrimary>
                  </Row>
                </Col>
              )}
            </Col>
          )
        }
        )}
      </Col>
    </ModalBackground>
  )
}