import React, { useEffect, useState } from 'react';
import VCard from 'vcard-parser';
import { Col } from '../../../../../../styles/grid';

import {
  OtherVCards,
  SeeMore,
  VCardContainer,
  VCardImage,
  VCardItem,
  VCardTel,
  VCardTitle,
} from '../styles';

export const ContactMessage = ({ vcard = [] }) => {
  const [contact, setContact] = useState([]);
  const [seeMoreText, setSeeMoreText] = useState('Exibir');

  useEffect(() => {
    if (vcard.length > 0) {
      const vcards = vcard?.map((v) => VCard.parse(v));
      setContact(vcards);
    }
  }, [vcard]);

  const getVCardTitle = (contact) => {
    if (!contact.fn) return;

    const value = contact.fn[0].value;
    if (value.length > 21) {
      return `${value.substring(0, 18)}...`;
    }
    return value;
  };

  return (
    <VCardContainer>
      <VCardItem>
        {contact.length && (
          <>
            <VCardImage size={50} />
            <Col>
              <VCardTitle>{getVCardTitle(contact[0])}</VCardTitle>
              {contact[0].tel &&
                contact[0].tel.map((phone) => {
                  return <VCardTel>{phone.value}</VCardTel>;
                })}
            </Col>
          </>
        )}
      </VCardItem>
      {contact.length > 1 && (
        <SeeMore
          onToggle={() =>
            setSeeMoreText((txt) => (txt === 'Exibir' ? 'Ocultar' : 'Exibir'))
          }
        >
          <summary>
            {seeMoreText} Contatos ({contact.length - 1})
          </summary>

          {contact.forEach(({ tel, fn }, index) => {
            if (index === 0) {
              return;
            }
            return (
              <OtherVCards>
                <Col>
                  <VCardTitle>{getVCardTitle({ fn })}</VCardTitle>
                  {tel &&
                    tel.forEach((phone) => {
                      return <VCardTel>{phone.value}</VCardTel>;
                    })}
                </Col>
              </OtherVCards>
            );
          })}
        </SeeMore>
      )}
    </VCardContainer>
  );
};
