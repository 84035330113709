import styled from 'styled-components';
import { Form, Input } from '@rocketseat/unform';

import px2vw from '../../utils/responsiveness/px2vw';

import {
  cinzaKuppi1,
  branco,
  cinzaKuppi3,
  cinzaKuppi4,
  verdeKuppi1,
} from '../../styles/colors';

export const LoginLeft = styled.div`
  float: left;
  height: 100vh;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${cinzaKuppi1};
`;

export const LoginRight = styled.div`
  float: right;
  height: 100vh;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const FormLogin = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const LogoImageBox = styled.div`
  position: relative;
  width: ${px2vw(303.6)};
  height: ${px2vw(173.6)};
  margin-bottom: ${px2vw(-50)};

  ${(props) => props.mobile && `
    width: ${props.width || px2vw(700)};
    height: ${px2vw(200)};
    margin-top: ${px2vw(50)};
    margin-left: ${px2vw(300)};
    margin-bottom: ${px2vw(200)};
  `};
`;

export const LogoImage = styled.img`
  position: absolute;
  width: ${(props) => props.width || '100%'};
  height: ${(props) => props.height || '100%'};
`;

export const LogoLeftImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const LoginInputBox = styled.div`
  width: ${px2vw(600)};
  height: ${px2vw(80)};
  margin-top: ${px2vw(100)};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${(props) => `
    margin-top: ${props.margtop};
    margin-left: ${props.margleft};
    margin-bottom: ${props.margbottom};
  `};

  ${(props) => props.mobile && `
    width: ${props.width || px2vw(1000)};
    height: ${px2vw(120)};
    margin-top: ${px2vw(140)};
  `};
`;

export const LoginInputLabel = styled.div`
  font-size: ${px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.36px;
  margin-bottom: ${px2vw(2)};

  ${(props) => props.mobile && `
    margin-left: ${props.margleft} !important;
    font-size: ${px2vw(45)} !important;
  `};
`;

export const LoginInput = styled(Input)`
  padding: 10px 10px !important;
  margin: 0 !important;
  border: 1px solid ${cinzaKuppi4} !important;
  border-radius: 5px !important;
  font-size: ${px2vw(16)} !important;
  font-family: font-family: AvenirNextLTPro !important;
  font-weight: normal;
  letter-spacing: 0.36px !important;
  width: 100%;
  height: auto;

  ::placeholder {
    color: ${cinzaKuppi3} !important;
  }

  :focus {
    border: 1px solid ${cinzaKuppi3} !important;
    box-shadow: none !important;
  }

  ${(props) => `
    width: ${props.width} !important;
  `};

  ${(props) => props.mobile && `
    width: ${(props) => props.width || px2vw(1000)};
    height: ${px2vw(140)};
    font-size: ${px2vw(45)} !important;
    
  `};

`;

export const SeePasswordButton = styled.button`
  margin-left: -30px;
  margin-top: 18px;
  width: 20px;
  height: 20px;
  border: none;
  z-index: 10;
  background-color: transparent;

  :focus {
    background-color: transparent !important;
  }

  ${(props) => props.mobile && `
    margin-top: ${px2vw(30)}
  `}
`;

export const SeePasswordImage = styled.img`
  width: 20px;
  height: 20px;
`;

export const LoginBox = styled.div`
  display: flex;
  flex-direction: row;
  width: 80%;
`;

export const ForgotPasswordButton = styled.button`
  border: none;
  background-color: transparent;
  font-size: 14px;
  font-family: AvenirNextLTPro;
  font-weight: 600;
  letter-spacing: 0.36px;
  color: ${verdeKuppi1};

  :focus {
    background-color: transparent !important;
  }
`;

export const LoginButton = styled.button`
  background-color: ${verdeKuppi1};
  width: 100%;
  height: 55px;
  border: 1px solid ${verdeKuppi1};
  border-radius: 5px;
  padding: 0.3em;
  margin-top: 60px;

  :focus {
    background-color: ${verdeKuppi1} !important;
  }
`;

export const LoginButtonText = styled.div`
  font-size: 16px;
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.36px;
  color: ${branco};
`;

export const ForgotEmailPasswordModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const InputsBox = styled.div`
    ${(props) => props.mobile && `
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    
  `};
`;


export const NormalText = styled.div`
  width: ${px2vw(775)};
  display: flex;
  font-size: ${px2vw(50)};
  font-family: AvenirNextLTPro;
  letter-spacing: 0.36px;
  color: ${(props) => props.color};
  margin-top: ${(props => props.margtop)};
  padding-top: ${(props) => props.padtop};

  ${(props) =>
    props.bell &&
    `
    margin-top: ${px2vw(50)};
  `}

  ${(props) =>
    props.menu &&
    `
    margin-top: ${px2vw(40)};
  `}

  ${(props) =>
    props.notInterested &&
    `
    text-align: flex-end;
    margin-top: ${px2vw(40)};
    cursor: pointer;
  `}

  ${(props) => props.center && `
    text-align: center;
    align-items: center;
    justify-content: center;
    width: auto;
    max-width: ${props.maxWidth || px2vw(1100)};
    font-weight: ${props.fontweight || 'bold'};
  `}

  ${(props) =>
      props.passwordAndEmail &&
      `
        text-align: center;
        margin-top: ${px2vw(70)};
        margin-left: ${px2vw(410)};
        align-items: center;
        justify-content: center;
        cursor: pointer;
        color: ${verdeKuppi1}
    `}
`;
