import styled from 'styled-components';

import { Form, Input, Select } from '@rocketseat/unform';

import px2vw from '../../utils/responsiveness/px2vw';

import {
  preto,
  branco,
  verdeKuppi1,
  cinzaKuppi3,
  cinzaKuppi4,
  cinzaKuppi5,
  cinzaKuppi2,
  cinzaKuppi8,
  cinzaKuppi9,
  amareloKuppi2,
} from '../../styles/colors';

export const SubtitleTextBox = styled.div`
  margin-top: ${px2vw(20)};
  width: ${px2vw(1028)};
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const SubtitleText = styled.div`
  font-size: ${px2vw(12)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.36px;
  margin-left: ${px2vw(5)};
`;

export const InfoBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: ${px2vw(25)};
  margin-left: ${px2vw(5)};
  width: 100%;
`;

export const NewCategoryButton = styled.button`
  background-color: ${verdeKuppi1};
  min-width: ${px2vw(180)};
  height: ${px2vw(40)};
  border: none;
  border-radius: ${px2vw(10)};
  padding: 0.3em;
  margin-top: ${px2vw(20)};
  margin-right: auto;
`;

export const NewCategoryButtonText = styled.div`
  font-size: ${px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.36px;
  color: ${branco};
`;

export const CategoryModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
`;

export const ProductModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const ModalTitleText = styled.div`
  font-size: ${px2vw(23)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.48px;

  ${(props) => `
    margin-top: ${props.margtop};
  `};
`;

export const CategoryInputBox = styled.div`
  margin-top: ${px2vw(30)};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const CategoryInputLabel = styled.div`
  font-size: ${px2vw(13)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.36px;
`;

export const CategoryInput = styled(Input)`
  padding: ${px2vw(3)};
  margin-top: ${px2vw(5)};
  border: 1px solid ${cinzaKuppi4};
  border-radius: ${px2vw(5)};
  font-size: ${px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.36px;
  width: ${px2vw(313)};
  height: ${px2vw(35)};

  ::placeholder {
    color: ${cinzaKuppi4} !important;
  }

  :focus {
    border: 1px solid ${cinzaKuppi3} !important;
    box-shadow: none !important;
  }
`;

export const RemainingCharacters = styled.div`
  font-size: ${px2vw(12)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.32px;
  color: ${cinzaKuppi5};
  margin-top: ${px2vw(5)};
  margin-left: auto;
`;

export const ButtonBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  ${(props) => `
    width: ${props.width};
    height: ${props.height};
    margin-top: ${props.margtop};
    margin-left: ${props.margleft};
    padding-left: ${props.padleft};
  `};

  ${(props) =>
    props.border &&
    `
    border: 1px solid ${preto}60;
    border-radius: 5px;
  `};

  ${(props) =>
    props.confirm &&
    `
    border: 1px solid ${verdeKuppi1};
    border-radius: 5px;
    cursor: pointer;
    background-color: ${verdeKuppi1};
  `};
`;

export const IfoodImportButton = styled.button`
  margin-left: ${px2vw(10)};
  margin-top: ${px2vw(30)};
  height: ${px2vw(40)};
  min-width: ${px2vw(160)};
  border: none;
  background-color: transparent;
`;

export const ReorderButton = styled.button`
  margin-left: ${px2vw(10)};
  margin-top: ${px2vw(30)};
  height: ${px2vw(40)};
  min-width: ${px2vw(160)};
  border: none;
  background-color: transparent;
`;

export const ButtonImage = styled.img`
  height: ${px2vw(17)};
  width: ${px2vw(17)};
  margin-right: ${px2vw(10)};
  margin-top: ${(props) => props.margtop};

  ${(props) =>
    props.active &&
    `
    filter: invert(40%) sepia(79%) saturate(2498%) hue-rotate(4deg) brightness(103%) contrast(104%);
  `};
`;

export const ButtonText = styled.div`
  font-size: ${px2vw(13)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.32px;
`;

export const CategoryDataBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: ${px2vw(10)};
  margin-left: ${px2vw(5)};
  margin-bottom: ${px2vw(20)};
  width: ${px2vw(1055)};
  height: auto;
  background-color: ${branco};
`;

export const CategoryHeaderBox = styled.div`
  background-color: ${cinzaKuppi2};
  width: ${px2vw(1055)};
  height: ${px2vw(50)};
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding-left: ${px2vw(25)};
`;

export const CategoryHeaderTitle = styled.div`
  font-size: ${px2vw(18)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.48px;
  padding-top: ${px2vw(13)};
`;

export const CategoryFooterBox = styled.div`
  background-color: ${cinzaKuppi2};
  width: ${px2vw(1055)};
  height: ${px2vw(50)};
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: ${px2vw(6)};
`;

export const ProductNameBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding-left: ${px2vw(10)};
  height: ${px2vw(40)};
`;

export const ProductNameImage = styled.img`
  height: ${px2vw(17)};
  width: ${px2vw(19)};
  margin-right: ${px2vw(15)};
  margin-top: ${px2vw(10)};
  ${(props) =>
    props.noImage &&
    `width: ${px2vw(18.5)}; height: ${px2vw(15.5)}; margin-right: ${px2vw(16.5)}; margin-top: ${px2vw(11.5)};`};
`;

export const ProductNameText = styled.div`
  font-size: ${px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.32px;
`;

export const ProductAmountText = styled.div`
  font-size: ${px2vw(15)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.36px;
`;

export const ButtonTextRegular = styled.div`
  font-size: ${px2vw(13)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.32px;
  margin-top: ${px2vw(1)};

  ${(props) => props.greenColor && `color: ${verdeKuppi1}`};
  ${(props) =>
    props.yellowColor &&
    `
    color: ${amareloKuppi2};
    margin-right: ${props.margright};
  `};
`;

export const ButtonTextRegularComplement = styled.div`
  font-size: ${px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.32px;
  margin-top: ${px2vw(4)};

  ${(props) => props.greenColor && `color: ${verdeKuppi1}`};
  ${(props) =>
    props.yellowColor &&
    `
    color: ${amareloKuppi2};
    margin-right: ${props.margright};
  `};
`;

export const ButtonTextBoldGreen = styled.div`
  font-size: ${px2vw(15)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.32px;
  margin-top: ${px2vw(2)};
  color: ${verdeKuppi1};
`;

export const ButtonTextBoldGreenComplement = styled.div`
  font-size: ${px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.32px;
  margin-top: ${px2vw(4)};
  color: ${verdeKuppi1};
`;

export const PauseButton = styled.button`
  height: ${px2vw(40)};
  width: ${px2vw(160)};
  border: none;
  background-color: transparent;
  z-index: 0;

  ${(props) => `
    margin-left: ${props.margleft};
    margin-top: ${props.margtop};
    width: ${props.width};
  `};
`;

export const DuplicateButton = styled.button`
  height: ${px2vw(40)};
  width: ${px2vw(120)};
  border: none;
  background-color: transparent;

  ${(props) => `
    margin-left: ${props.margleft};
    margin-top: ${props.margtop};
    width: ${props.width};
  `};
`;

export const EditButton = styled.button`
  height: ${px2vw(40)};
  width: ${px2vw(90)};
  border: none;
  background-color: transparent;

  ${(props) => `
    margin-left: ${props.margleft};
    margin-right: ${props.marginRight};
    margin-top: ${props.margtop};
    width: ${props.width};
  `};
`;

export const DeleteButton = styled.button`
  height: ${px2vw(40)};
  width: ${px2vw(100)};
  border: none;
  background-color: transparent;

  ${(props) => `
    margin-left: ${props.margleft};
    margin-right: ${props.marginRight};
    margin-top: ${props.margtop};
    width: ${props.width};
  `};
`;

export const AddProductButton = styled.button`
  height: ${px2vw(40)};
  width: ${px2vw(190)};
  border: none;
  background-color: transparent;
  margin-left: ${px2vw(20)};
`;

export const TabsBox = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: ${px2vw(20)};
`;

export const TabBox = styled.div`
  display: block;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Tab = styled.button`
  height: ${px2vw(50)};
  margin-left: ${px2vw(40)};
  font-size: ${px2vw(16)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.36px;
  border: none;
  background-color: transparent;
`;

export const TabSelected = styled.div`
  display: block;
  height: ${px2vw(5)};
  width: ${px2vw(72)};
  background-color: ${verdeKuppi1};
  border-radius: ${px2vw(5)};
  margin-top: ${px2vw(-7)};
  margin-left: ${px2vw(40)};
  margin-bottom: ${px2vw(20)};

  ${(props) => `
    width: ${props.width};
  `};
`;

export const Separator = styled.hr`
  width: ${px2vw(815)};
  opacity: 8%;
  background-color: ${preto};
  margin-top: ${px2vw(-23)};
  margin-left: ${px2vw(22)};
`;

export const TabContent = styled.div`
  display: block;
  margin-left: ${px2vw(22)};
  margin-right: ${px2vw(22)};
  margin-top: ${px2vw(17)};

  ${(props) => `
  margin-top: ${props.margtop};
`};
`;

export const DragImageBox = styled.div`
  width: ${px2vw(150)};
  height: ${px2vw(150)};
  border: 1px dashed ${cinzaKuppi5};
  border-radius: ${px2vw(5)};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;

export const DragImage = styled.img`
  height: ${px2vw(30)};
  width: ${px2vw(30)};
  margin-right: ${px2vw(10)};
`;

export const ModalText2 = styled.div`
  font-size: ${px2vw(12)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.32px;
  margin-left: ${px2vw(25)};
  margin-right: ${px2vw(25)};
  margin-top: ${px2vw(10)};
  text-align: center;
`;

export const ChooseImageBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const ChooseImageButton = styled.button`
  height: ${px2vw(40)};
  width: ${px2vw(140)};
  border: none;
  background-color: transparent;
  z-index: 0;

  ${(props) => `
    width: ${props.width};
    height: ${props.height};
    margin-left: ${props.margleft};
    margin-top: ${props.margtop};
  `};

  ${(props) =>
    props.isHidden &&
    `
    display: none;
  `};
`;

export const ChooseImageButtonImage = styled.img`
  height: ${px2vw(18)};
  width: ${px2vw(18)};
  margin-right: ${px2vw(10)};
`;

export const ChooseImageButtonText = styled.div`
  font-size: ${px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.36px;
  margin-top: ${px2vw(2)};
`;

export const ProductInputLabel = styled.div`
  font-size: ${px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.32px;

  ${(props) => `
    margin-top: ${props.margtop};
    margin-right: ${props.margright};
  `};
`;

export const ProductInputLabelRegular = styled.div`
  font-size: ${px2vw(13)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.32px;
  color: ${cinzaKuppi5};
  margin-bottom: ${px2vw(-3)};

  ${(props) => `
    margin-top: ${props.margtop};
    padding: ${props.pad};
    margin-left: ${props.margleft};
    margin-right: ${props.margright};
    width: ${props.width};
  `};
`;

export const ProductInput = styled(Input)`
  padding: ${px2vw(10)};
  margin-top: ${px2vw(5)};
  border: 1px solid ${cinzaKuppi4};
  border-radius: ${px2vw(5)};
  font-size: ${px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.36px;
  width: ${px2vw(280)};
  height: ${px2vw(40)};

  ::placeholder {
    color: ${cinzaKuppi4} !important;
  }

  :focus {
    border: 1px solid ${cinzaKuppi3} !important;
    box-shadow: none !important;
  }

  ${(props) => `
    width: ${props.width};
    height: ${props.height};
    margin-top: ${props.margtop};
  `};
`;

export const ProductSelectInput = styled(Select)`
  margin-top: ${px2vw(5)};
  height: ${px2vw(39)};
  font-size: ${px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.36px;

  ${(props) => `
    width: ${props.width};
    height: ${props.height};
    margin-top: ${props.margtop};
    margin-right: ${props.margright};
  `};
`;

export const ProductTextAreaInput = styled.textarea`
  padding: ${px2vw(10)};
  margin-top: ${px2vw(5)};
  border: 1px solid ${cinzaKuppi4};
  border-radius: ${px2vw(5)};
  font-size: ${px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.36px;
  width: ${px2vw(280)};
  height: ${px2vw(93)};
  resize: none;

  ::placeholder {
    color: ${cinzaKuppi4} !important;
  }

  :focus {
    border: 1px solid ${cinzaKuppi3} !important;
    box-shadow: none !important;
  }

  ${(props) => `
    width: ${props.width};
    height: ${props.height};
  `};
`;

export const RemainingCharactersProduct = styled.div`
  font-size: ${px2vw(12)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.32px;
  color: ${cinzaKuppi5};
  margin-top: ${px2vw(5)};
  margin-left: ${(props) => props.margleft};
`;

export const CopyComplementSubtitle = styled.div`
margin-top: ${px2vw(10)};
margin-left: ${px2vw(35)};
margin-right: ${px2vw(35)};
margin-bottom: ${px2vw(20)};
font-size: ${px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.36px;
`;

export const CopyComplementsBox = styled.div`
  width: ${px2vw(515)};
  height: ${px2vw(380)};
  margin-left: ${px2vw(12)};
  overflow-x: hidden;
  overflow-y: visible;
`;

export const CopyComplementBox = styled.div`
  align-items: center;
  background-color: ${cinzaKuppi2};
  border: 1px solid ${cinzaKuppi4};
  width: ${px2vw(490)};
  height: ${px2vw(45)};
  margin-bottom: ${px2vw(8)};
  margin-left: ${px2vw(15)};
  cursor: pointer;

  ${(props) =>
    props.isVisible &&
    `
    background-color: ${cinzaKuppi4};
  `};
`;

export const CopyComplementName = styled.div`
  margin-top: ${px2vw(12)};
  margin-left: ${px2vw(25)};
  font-size: ${px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.32px;
`;

export const ComplementsBox = styled.div`
  overflow-x: hidden;
  overflow-y: visible;
  height: ${px2vw(285)};
  width: ${px2vw(750)};
`;

export const ComplementCategoryBox = styled.div`
  display: block;
  background-color: ${cinzaKuppi2};
  border: 1px solid ${cinzaKuppi4};
  width: 100%;
  height: ${px2vw(205)};
  border-bottom: none;
  margin-top: ${px2vw(10)};
`;

export const ComplementCategoryOptionsBox = styled.div`
  background-color: transparent;
  border: 1px solid ${cinzaKuppi4};
  width: 100%;
  height: ${px2vw(190)};
  border-bottom: none;
`;

export const ComplementCategoryOptionAddBox = styled.div`
  display: block;
  background-color: transparent;
  border: 1px solid ${cinzaKuppi4};
  width: 100%;
  height: ${px2vw(45)};
  cursor: pointer;
`;

export const DisponibilityLabel = styled.div`
  font-size: ${px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.36px;
  margin-top: ${px2vw(-5)};
  margin-left: ${px2vw(22)};
`;

export const HourDisponibilityBox = styled.div`
  display: none;
  flex-direction: row;

  ${(props) =>
    props.isVisible &&
    `
    display: flex;
  `};

  ${(props) => `
    margin-top: ${props.margtop};
  `};
`;

export const SpecificHourBox = styled.div`
  display: none;
  flex-direction: row;

  ${(props) =>
    props.isVisible &&
    `
    display: flex;
  `};

  ${(props) => `
    margin-top: ${props.margtop};
  `};
`;

export const ProdutCouponBox = styled.div`
  background-color: ${cinzaKuppi2};
  border: 1px solid ${cinzaKuppi4};
  width: ${px2vw(700)};
  height: ${px2vw(230)};
  margin-left: ${px2vw(20)};
  margin-top: ${px2vw(5)};
`;

export const CouponOptionBox = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: ${px2vw(30)};

  ${(props) => `
    margin-top: ${props.margtop};
  `};

  ${(props) =>
    props.isHidden &&
    `
    display: none;
  `};
`;

export const ErrorText = styled.div`
  font-size: ${px2vw(18)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.4px;
  margin-left: ${px2vw(20)};
`;

export const FormCategory = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const FormEditCategory = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const FormProduct = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const FormEditProduct = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SelectImageLabel = styled.label`
  cursor: pointer;
  text-align: left;
  font-size: ${px2vw(14)};
  padding: ${px2vw(10)} 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: AvenirNextLTPro;
  font-weight: 700;
`;

export const InputImage = styled.input`
  margin-top: ${px2vw(10)};
  position: absolute;
  width: ${px2vw(200)};
  height: ${px2vw(200)};
  opacity: 0;
  z-index: 2;
  cursor: pointer;
`;

export const ImageCoverPreview = styled.img`
  position: absolute;
  width: ${px2vw(150)};
  height: ${px2vw(150)};
  border: 1px solid ${cinzaKuppi9};
  border-radius: ${px2vw(5)};
  object-fit: cover;
`;

export const ConfirmDeleteModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ConfirmDeleteModalText = styled.div`
  font-size: ${px2vw(20)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.48px;
  margin-left: ${px2vw(35)};
  margin-top: ${px2vw(10)};
  margin-right: ${px2vw(35)};
  text-align: center;
`;

export const ReorderComplementSubtitle = styled.div`
  margin-top: ${px2vw(10)};
  margin-left: ${px2vw(35)};
  margin-right: ${px2vw(35)};
  margin-bottom: ${px2vw(20)};
  font-size: ${px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.36px;
`;

export const ReorderComplementsBox = styled.div`
  min-height: ${px2vw(310)};
  height: ${px2vw(310)};
  margin-left: ${px2vw(6)};
  overflow-x: hidden;
  overflow-y: visible;
  transition: 200ms;

  .highlight {
    background: ${cinzaKuppi9};
  }

  .over {
    background: ${cinzaKuppi8};
  }

  .is-dragging {
    opacity: 0.5;
  }
`;

export const ReorderComplementHeaderBox = styled.div`
  align-items: center;
  background-color: ${cinzaKuppi2};
  border: 1px solid ${cinzaKuppi4};
  width: ${px2vw(300)};
  height: ${px2vw(45)};
  margin-left: ${px2vw(15)};

  ${(props) =>
    props.isVisible &&
    `
    background-color: ${cinzaKuppi4};
  `};
`;

export const ReorderComplementHeaderName = styled.div`
  margin-top: ${px2vw(12)};
  margin-left: ${px2vw(25)};
  font-size: ${px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.32px;
`;

export const ReorderComplementBox = styled.div`
  align-items: center;
  border: 1px solid ${cinzaKuppi4};
  width: ${px2vw(300)};
  min-height: ${px2vw(45)};
  height: ${px2vw(45)};
  margin-bottom: ${px2vw(8)};
  margin-left: ${px2vw(5)};
  cursor: pointer;

  ${(props) =>
    !props.isComplement &&
    `
    margin-left: 15px;
  `};
`;

export const ReorderComplementName = styled.div`
  margin-left: ${px2vw(12)};
  font-size: ${px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.32px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ImportIfoodCatalogSubtitle = styled.div`
  margin-top: ${px2vw(30)};
  margin-bottom: ${px2vw(10)};
  font-size: ${px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.36px;
`;

export const ImportIfoodCatalogSubtitleBold = styled.div`
  margin-top: ${px2vw(20)};
  margin-bottom: ${px2vw(30)};
  width: ${px2vw(400)};
  font-size: ${px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.36px;

  ${(props) =>
    props.maintenance &&
    `
    padding-left: ${px2vw(20)};
    padding-right: ${px2vw(20)};
  `};
`;

export const ImportIfoodCatalogSuccessBold = styled.div`
  margin-top: ${px2vw(30)};
  margin-bottom: ${px2vw(40)};
  width: ${px2vw(400)};
  font-size: ${px2vw(20)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  text-align: center;
  letter-spacing: 0.36px;
`;

export const ImportIfoodCatalogErrorBold = styled.div`
  margin-top: ${px2vw(10)};
  margin-bottom: ${px2vw(40)};
  width: ${px2vw(400)};
  font-size: ${px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.36px;
`;

export const ImportIfoodCatalogLoadingText = styled.div`
  font-size: ${px2vw(16)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.36px;

  ${(props) => `
    margin-left: ${props.margleft};
    width: ${props.width};
`};
`;

export const ImportIfoodCatalogInput = styled(Input)`
  padding: ${px2vw(3)};
  border: 1px solid ${cinzaKuppi4};
  border-radius: ${px2vw(5)};
  font-size: ${px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.36px;
  width: 100%;
  height: ${px2vw(35)};
  margin-bottom: ${px2vw(20)};

  ::placeholder {
    color: ${cinzaKuppi4} !important;
  }

  :focus {
    border: 1px solid ${cinzaKuppi3} !important;
    box-shadow: none !important;
  }
`;

export const AttentionImage = styled.img`
  width: ${px2vw(60)};
  height: ${px2vw(60)};
  margin-right: ${px2vw(12)};
`;

export const SuccessImage = styled.img`
  width: ${px2vw(60)};
  height: ${px2vw(60)};
`;

export const ErrorImage = styled.img`
  width: ${px2vw(80)};
  height: ${px2vw(110)};
`;

export const SaveButton = styled.button`
  background-color: ${cinzaKuppi2};
  height: ${(props) => props.height || px2vw(40)};
  width: ${px2vw(100)};
  line-height: ${px2vw(40)};
  border: none;
  border-radius: ${(props) => props.borderRadius || px2vw(8)};

  ${(props) => `
    margin-top: ${props.margtop};
    min-width: ${props.minWidth};
  `};

  ${(props) =>
    props.hasChanges &&
    `
    background-color: ${verdeKuppi1};
  `};
`;

export const SaveButtonText = styled.div`
  font-size: ${(props) => props.fontSize || px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.36px;
  color: ${cinzaKuppi3};

  ${(props) =>
    props.hasChanges &&
    `
    color: ${branco};
  `};
`;

export const MassEditionButton = styled.button`
  margin-left: ${px2vw(10)};
  margin-top: ${px2vw(30)};
  height: ${px2vw(40)};
  min-width: ${px2vw(160)};
  border: none;
  background-color: transparent;
`;
