import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { cinzaKuppi4, verdeKuppi1, branco } from '../../styles/colors';

import px2vw from '../../utils/responsiveness/px2vw';

export const PageTitle = styled.div`
  font-size: ${(props) => props.fontsize || px2vw(25)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.64px;
`;

export const PageText = styled.div`
  font-size: ${(props) => props.fontsize || px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.36px;
  ${(props) => props.alignCenter && `text-align: center`};
`;

export const Separator = styled.hr`
  margin-top: ${px2vw(20)};
  margin-bottom: ${px2vw(20)};
`;

export const TermsLink = styled(Link)``;

export const SectionBox = styled.div`
  border: 1px solid ${cinzaKuppi4};
  border-radius: ${px2vw(20)};
  width: 100%;
  height: 100%;
  padding-bottom: ${px2vw(25)};
  margin-bottom: ${(props) => props.margbottom || '0'};
`;

export const SectionTitle = styled.div`
  font-size: ${(props) => props.fontsize || px2vw(22)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.64px;
  margin-bottom: ${px2vw(25)};
`;

export const SectionText = styled.div`
  font-size: ${px2vw(12)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.32px;
  padding: 10px;
`;

export const SectionButton = styled.button`
  width: ${px2vw(150)};
  height: ${px2vw(35)};
  border-radius: ${px2vw(10)};
  border: none;
  background-color: ${verdeKuppi1};

  ${(props) => `
    margin-left: ${props.margleft};
  `};
`;

export const SectionButtonText = styled.div`
  font-size: ${px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.36px;
  color: ${branco};
`;

export const SuccessPaymentImage = styled.img`
  width: ${(props) => props.size || px2vw(40)};
  height: ${(props) => props.size || px2vw(40)};
  margin-right: ${(props) => props.margright || px2vw(20)};
`;

export const OrderSumaryTextBold = styled.span`
  font-size: ${(props) => props.fontSize || px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.36px;

  ${(props) =>
    props.advertiserName &&
    `
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: ${props.maxChar ? props.maxChar : '23ch'};
  `};
`;

export const OrderSumaryText = styled.span`
  font-size: ${(props) => props.fontSize || px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.36px;

  ${(props) =>
    props.pixQrCode &&
    `
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 35ch;
  `};
`;

export const BlockedImage = styled.img`
  width: ${(props) => props.size || px2vw(120)};
  height: ${(props) => props.size || px2vw(120)};
  margin-top: ${(props) => props.margtop || px2vw(40)};

`;
