import styled from 'styled-components';
import px2vw from '../../utils/responsiveness/px2vw';

import {
  branco,
  preto,
  verdeKuppi1,
  verdeKuppi2,
  verdeKuppi4,
  vermelhoKuppi1,
} from '../../styles/colors';

export const ManualOrderButtonPrimary = styled.button`
  background-color: ${verdeKuppi2};
  border: 1px solid ${verdeKuppi2};
  color: ${branco};
  border-radius: ${px2vw(5)};
  font-size: ${px2vw(12)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.32px;
  padding: ${px2vw(8)};
  cursor: pointer;
  width: auto;

  :hover {
    background-color: ${verdeKuppi4};
    border: 1px solid ${verdeKuppi1};
  }

  &:focus,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
    color: ${branco};
  }
`;

export const Bar = styled.div`
  width: auto;

  .badge {
    font-size: ${px2vw(11)};
    font-family: AvenirNextLTPro;
    font-weight: 700;
    margin-left: ${px2vw(5)};
    background: ${vermelhoKuppi1};
    padding: 0.3em 0.4em !important;
  }
`;

export const ManualOrderText = styled.span`
  font-weight: 600;
  vertical-align: baseline;
  font-size: ${px2vw(12)};
  margin-right: ${px2vw(15)};
  margin-left: ${px2vw(10)};
  font-style: normal;
  line-height: ${px2vw(19)};
  color: ${preto};
  font-family: AvenirNextLTPro;
  letter-spacing: 0.32px;
`;
