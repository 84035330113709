import styled from 'styled-components';
import px2vw from '../../utils/responsiveness/px2vw';

export const ChartContainer = styled.div`
  width: ${(props) => props.width};
`;

export const ChartOptionColorBox = styled.div`
  background-color: ${(props) => props.bgcolor};
  width: ${px2vw(5)};
  height: ${px2vw(20)};
  margin-top: ${(props) => props.margtop};
`;

export const ChartOptionName = styled.div`
  font-size: ${px2vw(12)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.32px;
  margin-top: ${(props) => props.margtop};
`;