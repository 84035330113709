import React from 'react';

import px2vw from '../../../../utils/responsiveness/px2vw';

import ButtonPrimary from '../../../../components/ButtonPrimary';

import { DataText, ImgBox, Img, Hr } from '../../BaseScreen/styles';
import { AdvertiserName } from '../../styles';
import { Row, Col } from '../../../../styles/grid';

export default function WelcomeScreenMobile({ handler }) {
  return (
    <Col
      padright={px2vw(120)}
      padleft={px2vw(120)}
      margtop={px2vw(150)}
    >
      <Row justifyCenter alignCenter width='auto'>
        <ImgBox
          isMobile
          margbottom={px2vw(350)}
          margtop={px2vw(200)}>
          <Img
            width={px2vw(900)}
            height={px2vw(630)}
            alt="computer"
            src={require('../../../../assets/images/onboarding/computerImg@2x.png')}
          />
        </ImgBox>
      </Row>
      <Row justifyStart>
        <AdvertiserName isMobile>
          {`Olá, `}
          <span className='green'> {handler.user.advertiserInfo.name}!</span>
        </AdvertiserName>
      </Row>
      <Col>
        <DataText
          margtop={px2vw(15)}
          fontSize={px2vw(60)}
        >
          Você está na etapa de Configuração do seu Cardápio Digital. Fica
          tranquilo, aqui é tudo bem fácil e rápido!
        </DataText>
        <Hr margtop={px2vw(60)} />
      </Col>
      <Col alignCenter margtop={px2vw(80)}>
        <ButtonPrimary
          borderRadius={40}
          minWidth='100%'
          height={200}
          fontSize={80}
          onClick={handler.startButtonClick}
        >
          Começar
        </ButtonPrimary>
        <Row alignCenter justifyCenter margtop={px2vw(60)} width={'auto'}>
          <Img
            width={px2vw(600)}
            height={px2vw(80)}
            alt="timer"
            src={require('../../../../assets/images/onboarding/timer@2x.png')}
          />
        </Row>
      </Col>
    </Col>
  );
}
