export function sort(arr, col, direction) {
  const currentArr = [...arr];

  if (currentArr && currentArr.length) {
    if (direction === 'asc') {
      currentArr.sort((a, b) => {
        if (a[col] > b[col]) return 1;
        if (a[col] < b[col]) return -1;
        return 0;
      });
    }
    if (direction === 'desc') {
      currentArr.sort((a, b) => {
        if (a[col] < b[col]) return 1;
        if (a[col] > b[col]) return -1;
        return 0;
      });
    }
  }
  return currentArr;
}

export function sortByColumnValue(arr, col, direction) {
  const currentArr = [...arr];

  if (currentArr && currentArr.length) {
    if (direction === 'asc') {
      currentArr.sort((a, b) => {
        if (col === 'plan') {
          const planA =
            a.payment_type === 'free' ? 'Gratuito' : a.subscription.name;
          const planB =
            b.payment_type === 'free' ? 'Gratuito' : b.subscription.name;

          if (planA > planB) return 1;
          if (planA < planB) return -1;
          return 0;
        }
        if (col === 'vigency') {
          const vigencyA = new Date(a.start_validity_at);
          const vigencyB = new Date(b.start_validity_at);

          if (vigencyA - vigencyB === 0) {
            // Changed the return for Chrome, to avoid error when order the array
            return navigator.userAgent.indexOf('Chrome') !== -1 ? -1 : 1;
          }
          return vigencyA - vigencyB;
        }
        if (col === 'payment_method') {
          const paymentMethodA =
            a.payment_type === 'free'
              ? '-'
              : a.payment_type === 'boleto'
              ? 'Boleto'
              : a.payment_type === 'credit_card' && a.paymentCard
              ? `Cartão de Crédito ${a.paymentCard.brand.toUpperCase()} ****${
                  a.paymentCard.last_digits
                }`
              : a.payment_type === 'credit_card' && !a.paymentCard
              ? `Cartão de Crédito`
              : 'Não definida';
          const paymentMethodB =
            b.payment_type === 'free'
              ? '-'
              : b.payment_type === 'boleto'
              ? 'Boleto'
              : b.payment_type === 'credit_card' && b.paymentCard
              ? `Cartão de Crédito ${b.paymentCard.brand.toUpperCase()} ****${
                  b.paymentCard.last_digits
                }`
              : b.payment_type === 'credit_card' && !b.paymentCard
              ? `Cartão de Crédito`
              : 'Não definida';

          if (paymentMethodA > paymentMethodB) return 1;
          if (paymentMethodA < paymentMethodB) return -1;
          return 0;
        }
        if (col === 'period') {
          const periodA = new Date(a.subscription.period);
          const periodB = new Date(b.subscription.period);

          if (periodA > periodB) return 1;
          if (periodA < periodB) return -1;
          return 0;
        }
        if (col === 'amount') {
          const amountA = (
            Math.round(a.amount.toFixed(2) * a.subscription.period * 100) / 100
          ).toFixed(2);
          const amountB = (
            Math.round(b.amount.toFixed(2) * b.subscription.period * 100) / 100
          ).toFixed(2);

          if (amountA - amountB === 0) {
            // Changed the return for Chrome, to avoid error when order the array
            return navigator.userAgent.indexOf('Chrome') !== -1 ? -1 : 1;
          }
          return amountA - amountB;
        }
      });
    }
    if (direction === 'desc') {
      currentArr.sort((a, b) => {
        if (col === 'plan') {
          const planA =
            a.payment_type === 'free' ? 'Gratuito' : a.subscription.name;
          const planB =
            b.payment_type === 'free' ? 'Gratuito' : b.subscription.name;

          if (planA < planB) return 1;
          if (planA > planB) return -1;
          return 0;
        }
        if (col === 'vigency') {
          const vigencyA = new Date(a.start_validity_at);
          const vigencyB = new Date(b.start_validity_at);

          if (vigencyB - vigencyA === 0) {
            // Changed the return for Chrome, to avoid error when order the array
            return navigator.userAgent.indexOf('Chrome') !== -1 ? -1 : 1;
          }
          return vigencyB - vigencyA;
        }
        if (col === 'payment_method') {
          const paymentMethodA =
            a.payment_type === 'free'
              ? '-'
              : a.payment_type === 'boleto'
              ? 'Boleto'
              : a.payment_type === 'credit_card' && a.paymentCard
              ? `Cartão de Crédito ${a.paymentCard.brand.toUpperCase()} ****${
                  a.paymentCard.last_digits
                }`
              : a.payment_type === 'credit_card' && !a.paymentCard
              ? `Cartão de Crédito`
              : 'Não definida';
          const paymentMethodB =
            b.payment_type === 'free'
              ? '-'
              : b.payment_type === 'boleto'
              ? 'Boleto'
              : b.payment_type === 'credit_card' && b.paymentCard
              ? `Cartão de Crédito ${b.paymentCard.brand.toUpperCase()} ****${
                  b.paymentCard.last_digits
                }`
              : b.payment_type === 'credit_card' && !b.paymentCard
              ? `Cartão de Crédito`
              : 'Não definida';

          if (paymentMethodA < paymentMethodB) return 1;
          if (paymentMethodA > paymentMethodB) return -1;
          return 0;
        }
        if (col === 'period') {
          const periodA = new Date(a.subscription.period);
          const periodB = new Date(b.subscription.period);

          if (periodA < periodB) return 1;
          if (periodA > periodB) return -1;
          return 0;
        }
        if (col === 'amount') {
          const amountA = (
            Math.round(a.amount.toFixed(2) * a.subscription.period * 100) / 100
          ).toFixed(2);
          const amountB = (
            Math.round(b.amount.toFixed(2) * b.subscription.period * 100) / 100
          ).toFixed(2);

          if (amountB - amountA === 0) {
            // Changed the return for Chrome, to avoid error when order the array
            return navigator.userAgent.indexOf('Chrome') !== -1 ? -1 : 1;
          }
          return amountB - amountA;
        }
      });
    }
  }
  return currentArr;
}

export function makePages(length, perPage) {
  if (length > 0 && perPage > 0) {
    const countKeys = Math.ceil(length / perPage);
    const keys = Array.from(Array(countKeys), (_, i) => i + 1);
    const pages = {};
    let start = 1;
    let end = perPage;

    keys.forEach((item, index) => {
      if (index === keys.length - 1) {
        pages[item] = {
          start,
          end: length,
        };
        start = start + perPage - 1;
        end = end + perPage - 1;
      } else {
        pages[item] = { start, end };
        start += perPage;
        end += perPage;
      }
    });

    return pages;
  }
}
