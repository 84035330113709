import produce from 'immer';

const INITIAL_STATE = {
  loyaltyCard: {},
  loyaltyCardMetrics: {},
  loyaltyCardHistory: [],
  getLoyaltyCardLoading: false,
  getLoyaltyCardMetricsLoading: false,
  getLoyaltyCardHistoryLoading: false,
  error: false,
  updateLoyaltyCardLoading: false,
  updateLoyaltyCardSuccess: false,
};

export default function loyaltyCard(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@loyaltyCard/GET_LOYALTY_CARD_REQUEST': {
        draft.getLoyaltyCardLoading = true;
        draft.loyaltyCard = {};
        draft.error = false;
        break;
      }
      case '@loyaltyCard/GET_LOYALTY_CARD_SUCCESS': {
        draft.getLoyaltyCardLoading = false;
        if (action.payload.loyaltyCard !== '') {
          draft.loyaltyCard = action.payload.loyaltyCard;
        }
        draft.error = false;
        break;
      }
      case '@loyaltyCard/GET_LOYALTY_CARD_FAILURE': {
        draft.getLoyaltyCardLoading = false;
        draft.loyaltyCard = {};
        draft.error = true;
        break;
      }
      case '@loyaltyCard/GET_LOYALTY_CARD_METRICS_REQUEST': {
        draft.getLoyaltyCardMetricsLoading = true;
        draft.loyaltyCardMetrics = {};
        draft.error = false;
        break;
      }
      case '@loyaltyCard/GET_LOYALTY_CARD_METRICS_SUCCESS': {
        draft.getLoyaltyCardMetricsLoading = false;
        if (action.payload.loyaltyCardMetrics !== '') {
          draft.loyaltyCardMetrics = action.payload.loyaltyCardMetrics;
        }
        draft.error = false;
        break;
      }
      case '@loyaltyCard/GET_LOYALTY_CARD_METRICS_FAILURE': {
        draft.getLoyaltyCardMetricsLoading = false;
        draft.loyaltyCardMetrics = {};
        draft.error = true;
        break;
      }
      case '@loyaltyCard/GET_LOYALTY_CARD_HISTORY_REQUEST': {
        draft.getLoyaltyCardHistoryLoading = true;
        draft.loyaltyCardHistory = [];
        draft.error = false;
        break;
      }
      case '@loyaltyCard/GET_LOYALTY_CARD_HISTORY_SUCCESS': {
        draft.getLoyaltyCardHistoryLoading = false;
        if (action.payload.loyaltyCardHistory !== '') {
          draft.loyaltyCardHistory = action.payload.loyaltyCardHistory;
        }
        draft.error = false;
        break;
      }
      case '@loyaltyCard/GET_LOYALTY_CARD_HISTORY_FAILURE': {
        draft.getLoyaltyCardHistoryLoading = false;
        draft.loyaltyCardHistory = [];
        draft.error = true;
        break;
      }
      case '@loyaltyCard/UPDATE_LOYALTY_CARD_REQUEST': {
        draft.updateLoyaltyCardLoading = true;
        draft.updateLoyaltyCardSuccess = false;
        break;
      }
      case '@loyaltyCard/UPDATE_LOYALTY_CARD_SUCCESS': {
        draft.updateLoyaltyCardLoading = false;
        draft.updateLoyaltyCardSuccess = true;
        draft.loyaltyCard = action.payload.loyaltyCard;
        break;
      }
      case '@loyaltyCard/UPDATE_LOYALTY_CARD_FAILURE': {
        draft.updateLoyaltyCardLoading = false;
        draft.updateLoyaltyCardSuccess = false;
        break;
      }
      case '@loyaltyCard/CLEAR_SUCCESS': {
        draft.getLoyaltyCardSuccess = false;
        draft.updateLoyaltyCardSuccess = false;
        break;
      }
      case '@auth/LOGIN_SUCCESS': {
        draft.loyaltyCard = action.payload.user.advertiserInfo.loyaltyCard;
        break;
      }
      default:
    }
  });
}
