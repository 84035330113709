import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import RealTimeDimensions from '../../utils/responsiveness/RealTimeDimensions';
import { sendCreateProfile } from '../../services/vendors/mixpanel';

import BaseScreenMobile from './BaseScreen/Mobile';
import BaseScreenWeb from './BaseScreen/Web';

import { logout } from '../../store/modules/auth/actions';
import { setCurrSurveyPageStep } from '../../store/modules/firstSteps/actions';
import { AppCuesIdentify } from '../../services/vendors/appcues';

export default function FirstSteps() {
  const [width] = RealTimeDimensions();
  const isWeb = width >= 800 ? true : false;

  const userData = useSelector((state) => state.user);
  const user = useSelector((state) => state.user.user);
  const hasFreeTrialValid = useSelector((state) => state.user.hasFreeTrialValid);
  const advPlan = user.advertiserInfo.advertiserSubscription;
  const firstStepsSurveyData = useSelector(
    (state) => state.firstSteps.firstStepsSurveyData
  );
  const currSurveyPageStep = useSelector(
    (state) => state.firstSteps.currSurveyPageStep
  );
  const [progress, setProgress] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    //Mixpanel Event
    sendCreateProfile({
      id: user.advertiserInfo.id,
      email: user.email,
      name: user.advertiserInfo.name,
      created_at: user.created_at,
      city: user.address[0].city,
      state: user.address[0].state,
      advertiserCategory: user.advertiserInfo.advertiserCategory.name,
      advertiserPlan: advPlan[advPlan.length - 1].subscription.name,
      advertiserPlanAmount: advPlan[advPlan.length - 1].subscription.amount,
      hasFreeTrialValid: hasFreeTrialValid
    });
  }, []);

  function handleLogout() {
    dispatch(logout());
  }

  // Initialize Appcues
  useEffect(() => {
    AppCuesIdentify(userData);
  }, [window.Appcues]);

  const handler = {
    isWeb: isWeb,
    baseScreen: {
      currSurveyPageStep: currSurveyPageStep,
      progress: progress,
      logoutButtonClick: () => handleLogout(),
    },
    welcomeScreen: {
      user: user,
      startButtonClick: () => dispatch(setCurrSurveyPageStep(firstStepsSurveyData.survey_step))
    },
    goBackButtonClick: {
      questionOne: () => dispatch(setCurrSurveyPageStep('initial')),
      questionTwo: () => dispatch(setCurrSurveyPageStep('step1')),
      questionThree: () => dispatch(setCurrSurveyPageStep('step2')),
    }
  }

  useEffect(() => {
    if(firstStepsSurveyData.survey_step === 'step1'){
      setProgress(0)
    }
    else if(firstStepsSurveyData.survey_step === 'step2'){
      setProgress(333)
    }
    else if(firstStepsSurveyData.survey_step === 'step3'){
      setProgress(666)
    }
    else if(firstStepsSurveyData.survey_step === 'finished'){
      setProgress(1000)
    }
  },[firstStepsSurveyData]);

  if (isWeb) {
    return <BaseScreenWeb handler={handler} />
  } else {
    return <BaseScreenMobile handler={handler} />
  }
}
