import React, { useState } from 'react';
import { Icon } from 'react-materialize';

import { Container, ChangeButton, ModalEdit, ButtonEdit } from './styles';
import { SvgBox, Close, Close2 } from './icons';
import px2vw from '../../../utils/responsiveness/px2vw';

export default function CreditCardManageButton({
  handleMakeDefaultCoupon,
  handleDeleteCoupon,
}) {
  const [visible, setVisible] = useState(false);
  const handleVisible = () => setVisible(!visible);

  const handleMakeDefault = () => {
    handleMakeDefaultCoupon();
    handleVisible();
  };

  const handleDelete = () => {
    handleDeleteCoupon();
    handleVisible();
  };

  return (
    <Container>
      <ModalEdit visible={visible} width={px2vw(125)}>
        <ButtonEdit onClick={handleMakeDefault}>Definir como padrão</ButtonEdit>
        <ButtonEdit onClick={handleDelete}>Excluir</ButtonEdit>
      </ModalEdit>
      {!visible && (
        <ChangeButton onClick={handleVisible} visible={visible}>
          <Icon>more_vert</Icon>
        </ChangeButton>
      )}
      {visible && (
        <ChangeButton zi="1" onClick={handleVisible} visible={visible}>
          <SvgBox>
            <Close />
            <Close2 />
          </SvgBox>
        </ChangeButton>
      )}
    </Container>
  );
}
