import React from 'react';

import CodeCouponTable from './CodeCoupon';
import TraditionalLightningCouponTable from './TraditionalLightningCoupon';
  
export default function CouponTable({ data, selectedTab, editCoupon, deleteCoupon, updateAvailableCoupons }) {
  if (selectedTab === 'code') {
    return <CodeCouponTable data={data} editCoupon={editCoupon} deleteCoupon={deleteCoupon} updateAvailableCoupons={updateAvailableCoupons} />
  }

  return <TraditionalLightningCouponTable data={data} editCoupon={editCoupon} deleteCoupon={deleteCoupon} />
}
