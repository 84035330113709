import styled from 'styled-components';

import px2vw from '../../../../utils/responsiveness/px2vw';

import { 
  cinzaKuppi1, 
} from '../../../../styles/colors';

export const TableTextBold = styled.div`
  font-size: ${px2vw(11)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.36px;
  padding-left: ${px2vw(4.1)};
  padding-top: ${px2vw(3)};
`;

export const TableTextBackground = styled.div`
  width: ${px2vw(90)};
  height: 100%;
  margin-left: ${(props) => props.margleft};
  background-color: ${cinzaKuppi1};
  display: flex;
  align-items: center;
  ${(props) => props.justifyCenter && `justify-content: center;`}
`;

export const TableTextRegular = styled.div`
  font-size: ${px2vw(11)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.36px; 
`;

export const HrTable = styled.div`
  margin-top: ${(props) => props.margtop};
  margin-bottom: ${(props) => props.margbottom};
  border-top: 3px solid ${cinzaKuppi1};
  height: 2px;
  width: 100%;
`;

export const ChartName = styled.div`
  font-size: ${px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.36px;
  margin-top: ${px2vw(10)};
`;

export const ChartOptionColorBox = styled.div`
  background-color: ${(props) => props.bgcolor};
  width: ${px2vw(5)};
  height: ${px2vw(20)};
  margin-top: ${(props) => props.margtop};
`;

export const ChartOptionName = styled.div`
  font-size: ${px2vw(12)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.32px;
  margin-top: ${(props) => props.margtop};
`;

export const TableHeader = styled.div`
  width: ${px2vw(261)};
  height: ${px2vw(30)};
  font-size: ${px2vw(14)};
  border-top-left-radius: ${px2vw(22)};
  border-top-right-radius: ${px2vw(22)};
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;

  ${(props) => `
    background-color: ${props.backgroundColor};
    color: ${props.color};
    
  `};
`;
