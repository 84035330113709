export const firstStepsPageTwoQuestionTwoTitleText = '2 - Como você ficou sabendo da Kuppi?';
export const firstStepsPageTwoQuestionTwoAnswerOneText = 'Vi uma propaganda na rede social';
export const firstStepsPageTwoQuestionTwoAnswerTwoText = 'Um amigo me indicou';
export const firstStepsPageTwoQuestionTwoAnswerThreeText = 'Recebi um whatsapp de um funcionário da Kuppi me apresentando';
export const firstStepsPageTwoQuestionTwoAnswerFourText = 'Conheci pesquisando no Google';
export const firstStepsPageTwoQuestionTwoAnswerFiveText = 'Vi em um grupo de Whatsapp';
export const firstStepsPageTwoQuestionTwoAnswerSixText = 'Outro';
export const firstStepsPageTwoQuestionThreeTitleText = '3 - Você possui um computador ou notebook?';
export const firstStepsPageTwoQuestionThreeAnswerOneText = 'Sim';
export const firstStepsPageTwoQuestionThreeAnswerTwoText = 'Não, apenas celular';
export const firstStepsPageTwoForwardButtonText = 'Avançar';