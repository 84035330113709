import React from 'react';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Bagde } from './style';

function WppMessageCounter() {
  const { newMessagesCount } = useSelector((state) => state.whatsappChat);

  const totalMessages = useMemo(() => {
    const total = newMessagesCount.length;

    return total > 99 ? '99+' : total || 0;
  }, [newMessagesCount]);

  if(!totalMessages){
    return null
  }

  return <Bagde>{totalMessages}</Bagde>;
}

export default WppMessageCounter;
