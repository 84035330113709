import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import PrintConfigsWeb from './Web';
import PrintConfigsMobile from './Mobile'

import { steps } from '../';

import { updateOnboardingStepRequest } from '../../../store/modules/onboarding/actions';
import { changeOrdersConfigsRequest, changeOrdersConfigsClearSuccess } from '../../../store/modules/orders/actions';

export default function PrintConfigs ({ isWeb, handler }) {
  const user = useSelector((state) => state.user.user);
  const printType = useSelector(
    (state) => state.user.user.advertiserInfo.advertiserPrintConfig.print_type
  );
  const [selectedPrintType, setSelectedPrintType] = useState(printType);
  const maxCharLine = useSelector(
    (state) =>
      state.user.user.advertiserInfo.advertiserPrintConfig.max_line_chars
  );
  const [selectedMaxCharLine, setSelectedMaxCharLine] = useState(maxCharLine);
  const alwaysPrintOrder = useSelector(
    (state) =>
      state.user.user.advertiserInfo.advertiserOrdersConfig
        .is_print_order_invoice_accept_order
  );
  const [selectedAlwaysPrintOrder, setSelectedAlwaysPrintOrder] = useState(
    alwaysPrintOrder
  );
  const alwaysSendWhatsappMsg = useSelector(
    (state) =>
      state.user.user.advertiserInfo.advertiserOrdersConfig
        .is_send_whatsapp_msg_update_status
  );
  const hasFreeTrialValid = useSelector((state) => state.user.hasFreeTrialValid);
  const [
    selectedAlwaysSendWhatsappMsg,
    setSelectedAlwaysSendWhatsappMsg,
  ] = useState(alwaysSendWhatsappMsg);
  const printConfigsLoading = useSelector((state) => state.orders.changeOrdersConfigsLoading);
  const changeOrdersConfigsSuccess = useSelector((state) => state.orders.changeOrdersConfigsSuccess);
  const updateOnboardingStepLoading = useSelector((state) => state.onboarding.updateOnboardingStepLoading);
  const dispatch = useDispatch()

  function handleFinishPrintConfigsStep() {
    dispatch(changeOrdersConfigsRequest(
      selectedPrintType,
      selectedMaxCharLine ? selectedMaxCharLine : '47',
      selectedAlwaysSendWhatsappMsg,
      selectedAlwaysPrintOrder
    ))
  }

  useEffect(() => {
    if (changeOrdersConfigsSuccess) {
      dispatch(changeOrdersConfigsClearSuccess());
      if (steps.indexOf(handler.currOnboardingStep) < steps.indexOf('step5')) {
        dispatch(updateOnboardingStepRequest('step5'));
      } else {
        handler.setCurrOnboardingStepPage(handler.currOnboardingStep);
      }
    }
  }, [changeOrdersConfigsSuccess])

  const handlerPrintConfigs = {
    user: user,
    updateOnboardingStepLoading: updateOnboardingStepLoading,
    selectedPrintType: selectedPrintType,
    selectedMaxCharLine: selectedMaxCharLine,
    selectedAlwaysPrintOrder: selectedAlwaysPrintOrder,
    selectedAlwaysSendWhatsappMsg: selectedAlwaysSendWhatsappMsg,
    setSelectedPrintType: (v) => setSelectedPrintType(v),
    setSelectedMaxCharLine: (t) => setSelectedMaxCharLine(t),
    setSelectedAlwaysPrintOrder: () => setSelectedAlwaysPrintOrder(!selectedAlwaysPrintOrder),
    handleFinishPrintConfigsStep: () => handleFinishPrintConfigsStep(),
    setSelectedAlwaysSendWhatsappMsg: () => setSelectedAlwaysSendWhatsappMsg(!selectedAlwaysSendWhatsappMsg),
    printConfigsLoading: printConfigsLoading,
    hasFreeTrialValid: hasFreeTrialValid,
  };

  if (isWeb) {
    return <PrintConfigsWeb handler={handlerPrintConfigs} />;
  } else {
    return <PrintConfigsMobile handler={handlerPrintConfigs} />;
  }
}
