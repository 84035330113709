import styled, { css } from 'styled-components';

import {
  branco,
  cinzaKuppi11,
  cinzaKuppi2,
  cinzaKuppi4,
  cinzaKuppi7,
  cinzaKuppi8,
  verdeKuppi1,
  verdeKuppi2,
} from '../../../../styles/colors';
import px2vw from '../../../../utils/responsiveness/px2vw';
import { keyframes } from 'styled-components';

const slide = keyframes`
  to {
    opacity: initial;
    transform: initial;
  }
`;

export const Container = styled.div`
  background: ${branco};
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  width: 100%;
  height: 100%;
  transform: translateX(${px2vw(-10)});
  opacity: 0;
  animation: ${slide} 0.3s forwards;
`;


export const ModalHeader = styled.div`
  width: 100%;
  background-color: ${cinzaKuppi8};
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: ${px2vw(13)} ${px2vw(20)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  font-size: ${px2vw(17)};
  display: flex;
  align-items: space-between;
  justify-content: space-between;
`;

export const ProgressBar = styled.div`
  background-color: ${cinzaKuppi11};
  width: 100%;
`;

export const CloseButton = styled.button`
  width: ${px2vw(18)};
  height: ${px2vw(18)};
  border: none;
  z-index: 10;
  background-color: transparent;

  :focus {
    background-color: transparent !important;
  }

  ${(props) =>
    props.absolute &&
    css`
      position: absolute;
      top: ${props.top || px2vw(10)};
      right: ${props.right || px2vw(10)};
    `}
`;

export const CloseImage = styled.img`
  width: ${px2vw(17)};
  height: ${px2vw(17)};
`;

export const StepImg = styled.img`
  width: 96%;
  margin-left: auto;
  pointer-events: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export const Section = styled.section`
  width: 100%;
  height: ${px2vw(535)};
  display: flex;
`;

export const ArrowImg = styled.img`
  position: absolute;
  top: ${px2vw(230)};
  ${(props) => props.left && `left: ${px2vw(-20)};`}
  ${(props) => props.right && `right: ${px2vw(-20)};`}
width: ${px2vw(40)};
  height: ${px2vw(40)};
  transition: width 0.1s, height 0.1s;
  :hover {
    width: ${px2vw(39.5)};
    height: ${px2vw(39.5)};
  }
`;

export const ButtonPrimary = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${verdeKuppi2};
  color: ${branco};
  border: none;
  border-radius: ${px2vw(3)};
  font-size: ${px2vw(13)};
  font-family: AvenirNextLTPro;
  font-weight: 600;
  letter-spacing: 0.015em;
  padding: ${px2vw(8)};
  box-shadow: 2px 2px 3px ${cinzaKuppi2};
  width: ${(props) => (props.width ? props.width : px2vw(320))};
  height: ${px2vw(35)};

  margin-top: ${(props) => (props.margtop ? props.margtop : 0)};

  &:active {
    box-shadow: 2px 2px 3px ${cinzaKuppi2};
    background: ${verdeKuppi1};
  }
`;

export const Ellipse = styled.div`
  height: ${px2vw(10)};
  width: ${px2vw(10)};
  background: ${(props) =>
    props.selected ? `${cinzaKuppi7}` : `${cinzaKuppi4}`};
  margin: 0 ${px2vw(5)};
  border-radius: 50%;
`;

export const ArrowButton = styled.button`
  border: none;
  z-index: 10;
  background-color: transparent;

  :focus {
    background-color: transparent !important;
  }

  ${(props) =>
    props.absolute &&
    css`
      position: absolute;
      top: ${props.top || px2vw(10)};
      right: ${props.right || px2vw(10)};
    `}
`;
