import styled from 'styled-components';

const Default = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 100%;

  /* ****** BACKGROUND ****** */
  ${(props) => props.background && `background: ${props.background};`}
  ${(props) => props.backgroundColor && `background-color: ${props.backgroundColor};`}

  /* ****** BORDER ****** */
  ${(props) => props.borderRadius && `border-radius: ${props.borderRadius};`}
  border: ${(props) => props.border};
  border-bottom: ${(props) => props.borderbottom};
  border-top: ${(props) => props.bordertop};
  border-left: ${(props) => props.borderLeft};
  border-right: ${(props) => props.borderRight};
  /* ****** CURSOR ****** */
  cursor: ${(props) => props.cursor};

  /* ****** TOP ****** */
  top: ${(props) => props.top};

  /* ****** BOTTOM ****** */
  bottom: ${(props) => props.bottom};

  /* ****** LEFT ****** */
  left: ${(props) => props.left};

  /* ****** RIGHT ****** */
  right: ${(props) => props.right};

  /* ****** MARGIN ****** */
  margin: ${(props) => props.marg};
  margin-top: ${(props) => props.margtop};
  margin-bottom: ${(props) => props.margbottom};
  margin-left: ${(props) => props.margleft};
  margin-right: ${(props) => props.margright};

  /* ****** PADDING ****** */
  padding: ${(props) => props.pad};
  padding-top: ${(props) => props.padtop};
  padding-bottom: ${(props) => props.padbottom};
  padding-left: ${(props) => props.padleft};
  padding-right: ${(props) => props.padright};

  /* ****** WIDTH ****** */
  width: ${(props) => props.width};
  max-width: ${(props) => props.maxwidth};
  min-width: ${(props) => props.minwidth};

  /* ****** HEIGHT ****** */
  height: ${(props) => props.height};
  max-height: ${(props) => props.maxheight};
  min-height: ${(props) => props.minheight};

  /* ****** NOWRAP ****** */
  ${(props) => props.noWrap && `flex-wrap: nowrap;`}

  /* ****** LEFT ****** */
  ${(props) => props.alignStart && `align-items: flex-start;`}
  ${(props) => props.justifyStart && `justify-content: flex-start;`}

  /* ****** CENTER ****** */
  ${(props) => props.alignCenter && `align-items: center;`}
  ${(props) => props.justifyCenter && `justify-content: center;`}

  /* ****** RIGHT ****** */
  ${(props) => props.alignEnd && `align-items: flex-end;`}
  ${(props) => props.justifyEnd && `justify-content: flex-end;`}

  /* ****** FLEX ****** */
  ${(props) => props.flexOn && `flex: 1;`}

  /* ****** Z-INDEX ****** */
  z-index: ${(props) => props.zIndex};

  /* ****** POSITION ****** */
  ${(props) => props.relative && `position: relative;`}
  ${(props) => props.absolute && `position: absolute;`}
  ${(props) => props.fixed && `position: fixed;`}
  ${(props) => props.sticky && `position: sticky;`}

  /* ****** OVERFLOW ****** */
  ${(props) => props.overflowY && `overflow-x: hidden; overflow-y: scroll;`}
  ${(props) => props.overflowX && `overflow-y: hidden; overflow-x: scroll;`}
  ${(props) => props.overflow && `overflow: scroll;`}

  /* ****** SPACE BETWEEN ****** */
  ${(props) => props.spaceBetween && `justify-content: space-between;`}

  /* ****** SPACE EVENLY ****** */
  ${(props) => props.spaceEvenly && `justify-content: space-evenly;`}

  /* ****** SPACE AROUND ****** */
  ${(props) => props.spaceAround && `justify-content: space-around;`}

  /* ****** OPACITY ****** */
  opacity: ${(props) => props.opacity};

  .ml-auto {
    margin-left: auto;
  }
  .mr-auto {
    margin-right: auto;
  }
  .mt-auto {
    margin-top: auto;
  }
  .mb-auto {
    margin-bottom: auto;
  }
`;

export const Row = styled(Default)`
  flex-direction: row;
`;

export const Col = styled(Default)`
  flex-direction: column;
`;
