import React from 'react';
import { Spinner } from 'reactstrap';

import { Content } from '../../_layouts/styles/common';
import { Col, Row } from '../../../styles/grid';
import px2vw from '../../../utils/responsiveness/px2vw';

import DefaultContentWeb from '../../../components/SignaturePayment/DefaultContent/Web';
import PaymentCreditCardSuccessWeb from '../../../components/SignaturePayment/PaymentCreditCardSuccess/Web';
import BoletoGeneratedSuccessWeb from '../../../components/SignaturePayment/BoletoGeneratedSuccess/Web';
import PixGeneratedSuccessWeb from '../../../components/SignaturePayment/PixGeneratedSuccess/Web';

import { Container, PageContent, LogoKuppi, ErrorMessage } from '../styles';
import { preto } from '../../../styles/colors';

export default function SignaturePaymentWeb({ handler }) {
  const loadingContent = (
    <Content>
      <Row>
        <Col height="90vh" alignCenter justifyCenter margleft={px2vw(165)}>
          <Col alignCenter justifyCenter>
            <Spinner
              style={{
                width: '1.8rem',
                height: '1.8rem',
                color: preto,
              }}
            />
          </Col>
        </Col>
        <Row justifyEnd width="auto">
          <LogoKuppi
            src={require('../../../assets/images/logo_kuppi/logo_kuppi@2x.png')}
          />
        </Row>
      </Row>
    </Content>
  );

  const signaturePaymentContent = (
    <Container>
      <Row>
        <Row justifyCenter margleft={px2vw(165)}>
          <PageContent>
            {!handler.boletoGeneratedSuccess && !handler.creditCardPaidSuccess && !handler.pixGeneratedSuccess && (
              <DefaultContentWeb handler={handler} />
            )}
            {handler.pixGeneratedSuccess && (
              <PixGeneratedSuccessWeb handler={handler} />
            )}
            {handler.boletoGeneratedSuccess && (
              <BoletoGeneratedSuccessWeb handler={handler} />
            )}
            {handler.creditCardPaidSuccess && (
              <PaymentCreditCardSuccessWeb handler={handler} />
            )}
          </PageContent>
        </Row>
        <Row justifyEnd width="auto">
          <LogoKuppi
            src={require('../../../assets/images/logo_kuppi/logo_kuppi@2x.png')}
          />
        </Row>
      </Row>
    </Container>
  );

  const errorContent = (
    <Content>
      <Row>
        <Col margleft={px2vw(150)} height="90vh">
          <Col alignCenter justifyCenter>
            <ErrorMessage>
              Ocorreu um erro, recarregue a página e tente novamente.
              <br />
              Caso persista, entre em contato com o suporte.
            </ErrorMessage>
          </Col>
        </Col>
        <Row justifyEnd width="auto">
          <LogoKuppi
            src={require('../../../assets/images/logo_kuppi/logo_kuppi@2x.png')}
          />
        </Row>
      </Row>
    </Content>
  );

  if (handler.loading) {
    return loadingContent;
  }
  if (handler.success) {
    return signaturePaymentContent;
  }
  return errorContent;
}
