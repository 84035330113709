export function verifyTokenRequest(token, changePaymentMethod) {
  return {
    type: '@signaturePayment/VERIFY_TOKEN_REQUEST',
    payload: { token, changePaymentMethod },
  };
}

export function verifyTokenSuccess(signaturePaymentData) {
  return {
    type: '@signaturePayment/VERIFY_TOKEN_SUCCESS',
    payload: { signaturePaymentData },
  };
}

export function verifyTokenFailure() {
  return {
    type: '@signaturePayment/VERIFY_TOKEN_FAILURE',
  };
}

export function processPaymentBoletoRequest(selectedPlan, boletoExpirationDate, isRenewPayment) {
  return {
    type: '@signaturePayment/PROCESS_PAYMENT_BOLETO_REQUEST',
    payload: { selectedPlan, boletoExpirationDate, isRenewPayment },
  };
}

export function processPaymentBoletoSuccess(signaturePaymentData) {
  return {
    type: '@signaturePayment/PROCESS_PAYMENT_BOLETO_SUCCESS',
    payload: { signaturePaymentData },
  };
}

export function processPaymentBoletoFailure() {
  return {
    type: '@signaturePayment/PROCESS_PAYMENT_BOLETO_FAILURE',
  };
}

export function processPaymentCreditCardRequest(
  selectedPlan,
  cardNumber,
  cardValidity,
  cardCVV,
  cardName,
  captchaValue,
  cpfCnpj,
  isRenewPayment
) {
  return {
    type: '@signaturePayment/PROCESS_PAYMENT_CREDIT_CARD_REQUEST',
    payload: { selectedPlan, cardNumber, cardValidity, cardCVV, cardName, captchaValue, cpfCnpj, isRenewPayment },
  };
}

export function processPaymentCreditCardSuccess(creditCardPaymentSuccessData) {
  return {
    type: '@signaturePayment/PROCESS_PAYMENT_CREDIT_CARD_SUCCESS',
    payload: { creditCardPaymentSuccessData },
  };
}

export function processPaymentCreditCardFailure() {
  return {
    type: '@signaturePayment/PROCESS_PAYMENT_CREDIT_CARD_FAILURE',
  };
}

export function processPaymentPixRequest(selectedPlan, isRenewPayment) {
  return {
    type: '@signaturePayment/PROCESS_PAYMENT_PIX_REQUEST',
    payload: { selectedPlan, isRenewPayment }
  };
}

export function processPaymentPixSuccess(signaturePaymentData) {
  return {
    type: '@signaturePayment/PROCESS_PAYMENT_PIX_SUCCESS',
    payload: { signaturePaymentData },
  };
}

export function processPaymentPixFailure() {
  return {
    type: '@signaturePayment/PROCESS_PAYMENT_PIX_FAILURE',
  };
}

export function setChangePaymentMethod() {
  return {
    type: '@signaturePayment/SET_CHANGE_PAYMENT_METHOD',
  };
}
