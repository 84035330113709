import React from 'react';
import { useSelector } from 'react-redux';
import { Spinner } from 'reactstrap';
import { FormControlLabel } from '@material-ui/core/';

import {
  ContentPage,
  SwitchController,
  SwitchLabelRegularText,
  LoadingBox,
} from '../../_layouts/styles/common';
import { Row, Col } from '../../../styles/grid';
import {
  ModalPrimaryButton,
  ModalPrimaryButtonText,
  ModalActions,
  ModalSecundaryButton,
  ModalSecundaryButtonText,
} from '../../../components/Modal/styles';
import {
  FlashCouponsContent,
  BackButtonBox,
  BackButton,
  BackButtonImage,
  BackButtonText,
  PageCouponsTitle,
  ChooseCouponTypeText,
  CouponTypeButton,
  CouponsTypeBox,
  CouponTypeBox,
  CouponTypeText,
  CouponDiscountTypeBox,
  DragImageBox,
  DragImage,
  ModalText2,
  ItemInputLabel,
  ItemInput,
  RemainingCharactersItem,
  SelectButtonOption,
  SelectButtonOptionText,
  DiscountPercentageText,
  SelectProductBox,
  SelectProductButton,
  SelectProductButtonText,
  FormFlashCouponDiscountAmount,
  FormFlashCouponPercentCart,
  ImageCoverPreview,
} from '../styles';
import { preto } from '../../../styles/colors';

import px2vw from '../../../utils/responsiveness/px2vw';
import { amountMask, timeMask, dateMask } from '../../../utils/masks';
import { onlyNumbers } from '../../../utils/utils';

export default function FlashCoupon({ handler }) {
  const userInfo = useSelector((state) => state.user.user.advertiserInfo);
  const isEditing = handler.editCouponObj ? true : false;
  const pageTitle = isEditing ? 'Editar Cupom Relâmpago' : 'Novo Cupom Relâmpago';
  const productImageUrl = isEditing && handler.editCouponObj.couponImage ? handler.editCouponObj.couponImage.url : handler.productAssociatedToCoupon && handler.productAssociatedToCoupon.url ? handler.productAssociatedToCoupon.url : null;

  return (
    <ContentPage>
      <FlashCouponsContent>
        <BackButton 
          onClick={() => handler.handleManageCouponContent('back')}
          disabled={handler.updateCouponLoading}
        >
          <BackButtonBox>
            <BackButtonImage
              src={require('../../../assets/images/arrows/arrow_left.png')}
            />
            <BackButtonText>voltar</BackButtonText>
          </BackButtonBox>
        </BackButton>
        <PageCouponsTitle>{pageTitle}</PageCouponsTitle>
        {!isEditing && (
          <>
            <ChooseCouponTypeText>Escolha o tipo de cupom</ChooseCouponTypeText>
            <CouponsTypeBox>
              <CouponTypeButton
                onClick={() => {
                  handler.clearCouponState();
                  handler.setCouponDiscountType('flashProductDiscount');
                }}
              >
                <CouponTypeBox isActive={handler.couponDiscountType === 'flashProductDiscount'}>
                  <Col width={px2vw(30)} height={px2vw(20)}>
                    <img
                      width="100%"
                      height="100%"
                      alt="Desconto em combo ou item específico"
                      className="imageActive"
                      src={require('../../../assets/images/cifrao/cifrao.png')}
                    />
                  </Col>
                  <CouponTypeText>
                    Desconto em combo ou item específico
                  </CouponTypeText>
                </CouponTypeBox>
              </CouponTypeButton>
              <CouponTypeButton
                onClick={() => {
                  handler.clearCouponState();
                  handler.setCouponDiscountType('flashCartPercentageDiscount');
                }}
              >
                <CouponTypeBox isActive={handler.couponDiscountType === 'flashCartPercentageDiscount'}>
                  <img
                    alt="Desconto no valor total da compra"
                    className="imageActive"
                    src={require('../../../assets/images/percent/percent.png')}
                  />
                  <CouponTypeText>
                    Desconto no valor total da compra
                  </CouponTypeText>
                </CouponTypeBox>
              </CouponTypeButton>
            </CouponsTypeBox>
          </>
        )}
        {handler.couponDiscountType === 'flashProductDiscount' && (
          <FormFlashCouponDiscountAmount
            onSubmit={() => {
              if (isEditing) {
                handler.handleEditCouponSubmit('flash');
              } else {
                handler.handleNewCouponDiscountAmountSubmit('flash');
              }
            }}
          >
            <CouponDiscountTypeBox height="auto">
              <Row margtop={px2vw(95)} height="auto">
                <Col>
                  <DragImageBox>
                    {productImageUrl && (
                      <ImageCoverPreview
                        src={`${productImageUrl
                          }?nocache=${new Date().getTime()}`}
                      />
                    )}
                    {!productImageUrl && (
                      <>
                        <DragImage
                          src={require('../../../assets/images/image_icon/image_icon@2x.png')}
                        />
                        <ModalText2>Imagem do Produto</ModalText2>
                      </>
                    )}
                  </DragImageBox>
                </Col>
                <Col margleft={px2vw(15)}>
                  <Col margtop={px2vw(-100)}>
                    <Row justifyStart>
                      <ItemInputLabel>
                        Combo ou produto
                      </ItemInputLabel>
                    </Row>
                    <Row>
                      <ItemInput
                        name="flashCouponProductName"
                        width={px2vw(580)}
                        placeholder="X-Burguer com batata frita e refrigerante"
                        value={handler.couponProductNameInputValue}
                        disabled
                      />
                      {isEditing && <Row width={px2vw(170)} margleft={px2vw(20)} />}
                      {!isEditing && (
                        <SelectProductButton
                          type="button"
                          onClick={() => handler.setImportProductToCoupon(true)}
                        >
                          <SelectProductBox>
                            <SelectProductButtonText>
                              + importar do seu catálogo
                            </SelectProductButtonText>
                          </SelectProductBox>
                        </SelectProductButton>
                      )}
                    </Row>
                    <RemainingCharactersItem margleft={px2vw(480)}>
                      {handler.remainingCharsProductName}/100 caracteres
                    </RemainingCharactersItem>
                  </Col>
                  <Col margtop={px2vw(10)}>
                    <ItemInputLabel>
                      Defina seu desconto
                    </ItemInputLabel>
                    <Row justifyStart margtop={px2vw(5)}>
                      <ItemInputLabel
                        margtop={px2vw(13)}
                        margright={px2vw(10)}
                      >
                        Valor Original
                      </ItemInputLabel>
                      <ItemInput
                        name="flashCouponProductOriginalAmount"
                        width={px2vw(85)}
                        placeholder="R$ 20,00"
                        value={handler.couponProductOriginalAmountInputValue}
                        disabled
                      />
                      <ItemInputLabel
                        margleft={px2vw(70)}
                        margtop={px2vw(13)}
                        margright={px2vw(10)}
                      >
                        Valor Promocional
                      </ItemInputLabel>
                      <ItemInput
                        name="flashCouponProductPromotionalAmount"
                        width={px2vw(85)}
                        placeholder="R$ 18,00"
                        maxLength="9"
                        value={handler.couponProductPromotionalAmountInputValue}
                        onChange={(t) =>
                          handler.setCouponProductPromotionalAmountInputValue(
                            amountMask(t.target.value)
                          )
                        }
                      />
                    </Row>
                  </Col>
                  <Row margtop={px2vw(30)}>
                    <Col>
                      <ItemInputLabel>
                        Onde o cupom é aceito?
                      </ItemInputLabel>
                      <Row justifyStart margtop={px2vw(5)} disabled>
                        {userInfo.is_using_take_away && (
                          <SelectButtonOption
                            isChecked={true} //handler.acceptOnStore
                            type="button"
                            onClick={() => {} /*handler.setAcceptOnStore(!handler.acceptOnStore)*/}
                          >
                            <SelectButtonOptionText isChecked={true} /*handler.acceptOnStore*/> 
                              Retirada
                            </SelectButtonOptionText>
                          </SelectButtonOption>
                        )}
                        {userInfo.is_using_delivery && (
                          <SelectButtonOption
                            isChecked={true} //handler.acceptOnDelivery
                            type="button"
                            onClick={() => {} /*handler.setAcceptOnDelivery(!handler.acceptOnDelivery)*/}
                          >
                            <SelectButtonOptionText isChecked={true} /*handler.acceptOnDelivery*/>
                              Delivery
                            </SelectButtonOptionText>
                          </SelectButtonOption>
                        )}
                      </Row>
                    </Col>
                    <Col>
                      <ItemInputLabel>
                        Condições de pagamento
                      </ItemInputLabel>
                      <Row
                        justifyStart
                        margtop={px2vw(10)}
                        margleft={px2vw(10)}
                      >
                        <FormControlLabel
                          control={
                            <SwitchController
                              checked={handler.acceptCard}
                              type="checkbox"
                              onChange={() => handler.setAcceptCard(!handler.acceptCard)}
                            />
                          }
                          label={
                            <SwitchLabelRegularText>
                              Cartão
                            </SwitchLabelRegularText>
                          }
                        />
                        <div style={{ marginRight: '20px' }} />
                        <FormControlLabel
                          control={
                            <SwitchController
                              checked={handler.acceptMoney}
                              type="checkbox"
                              onChange={() => handler.setAcceptMoney(!handler.acceptMoney)}
                            />
                          }
                          label={
                            <SwitchLabelRegularText>
                              Dinheiro
                            </SwitchLabelRegularText>
                          }
                        />
                       <div style={{ marginRight: '20px' }} />
                        <FormControlLabel
                          control={
                            <SwitchController
                              checked={handler.acceptPix}
                              type="checkbox"
                              onChange={() => handler.setAcceptPix(!handler.acceptPix)}
                            />
                          }
                          label={
                            <SwitchLabelRegularText>
                              Pix
                            </SwitchLabelRegularText>
                          }
                        />
                      </Row>
                    </Col>
                  </Row>
                  <Row justifyStart margtop={px2vw(20)}>
                    <ItemInputLabel
                      margtop={px2vw(13)}
                      margright={px2vw(10)}
                    >
                      Validade do cupom
                    </ItemInputLabel>
                    <ItemInput
                      name="flashCouponValidityDate"
                      width={px2vw(100)}
                      maxLength="10"
                      placeholder="00/00/0000"
                      value={handler.couponValidityDateInputValue}
                      onChange={(t) =>
                        handler.setCouponValidityDateInputValue(
                          dateMask(t.target.value)
                        )
                      }
                    />
                    <ItemInputLabel
                      margleft={px2vw(50)}
                      margtop={px2vw(13)}
                      margright={px2vw(10)}
                    >
                      Horário de expiração
                    </ItemInputLabel>
                    <ItemInput
                      name="flashCouponValidityTime"
                      width={px2vw(60)}
                      maxLength="5"
                      placeholder="00:00"
                      value={handler.couponValidityTimeInputValue}
                      onChange={(t) =>
                        handler.setCouponValidityTimeInputValue(
                          timeMask(t.target.value)
                        )
                      }
                    />
                    <ItemInputLabel
                      margleft={px2vw(50)}
                      margtop={px2vw(13)}
                      margright={px2vw(10)}
                    >
                      Limite de cupons
                    </ItemInputLabel>
                    <ItemInput
                      name="flashCouponUseLimit"
                      width={px2vw(80)}
                      placeholder="15"
                      min="1"
                      max="9999"
                      type="text"
                      value={handler.couponUseLimitInputValue}
                      onChange={(t) => {
                        handler.setCouponUseLimitInputValue(
                          onlyNumbers(t.target.value.substring(0, 4))
                        );
                      }}
                    />
                  </Row>
                </Col>
              </Row>
              <ModalActions>
                <ModalSecundaryButton
                  margleft={px2vw(190)}
                  marginRight={px2vw(20)}
                  margtop={px2vw(50)}
                  width={px2vw(250)}
                  type="button"
                  onClick={() => handler.handleManageCouponContent('back')}
                  disabled={handler.updateCouponLoading}
                >
                  <ModalSecundaryButtonText>
                    Cancelar
                  </ModalSecundaryButtonText>
                </ModalSecundaryButton>
                <ModalPrimaryButton
                  margleft={px2vw(20)}
                  margtop={px2vw(50)}
                  width={px2vw(250)}
                  type="submit"
                  disabled={handler.updateCouponLoading}
                >
                  <ModalPrimaryButtonText>
                    {!isEditing && 'Criar cupom'}
                    {isEditing && (
                      handler.updateCouponLoading ? (
                        <LoadingBox>
                          <Spinner
                            style={{
                              width: '1.8rem',
                              height: '1.8rem',
                              color: preto,
                            }}
                          />{' '}
                        </LoadingBox>
                      ) : (
                        'Editar cupom'
                      )
                    )}
                  </ModalPrimaryButtonText>
                </ModalPrimaryButton>
              </ModalActions>
            </CouponDiscountTypeBox>
          </FormFlashCouponDiscountAmount>
        )}
        {handler.couponDiscountType === 'flashCartPercentageDiscount' && (
          <FormFlashCouponPercentCart
            onSubmit={() => {
              if (isEditing) {
                handler.handleEditCouponSubmit('flash');
              } else {
                handler.handleNewCouponPercentCartSubmit('flash');
              }
            }}
          >
            <CouponDiscountTypeBox height="auto">
              <Row height="auto">
                <Col>
                  <Row spaceBetween>
                    <Col width="auto">
                      <ItemInputLabel>
                        Defina seu desconto
                      </ItemInputLabel>
                      <Row justifyStart margtop={px2vw(5)}>
                        <ItemInput
                          name="flashCouponDiscountPercent"
                          width={px2vw(45)}
                          placeholder="30"
                          maxLength="3"
                          value={handler.couponDiscountPercentInputValue}
                          onChange={(t) =>
                            handler.setCouponDiscountPercentInputValue(
                              onlyNumbers(t.target.value)
                            )
                          }
                        />
                        <DiscountPercentageText>%</DiscountPercentageText>
                      </Row>
                    </Col>
                    <Col width="auto">
                      <ItemInputLabel>
                        Onde o cupom é aceito?
                      </ItemInputLabel>
                      <Row justifyStart margtop={px2vw(5)}>
                        {userInfo.is_using_take_away && (
                          <SelectButtonOption
                            isChecked={true} //handler.acceptOnStore
                            type="button"
                            onClick={() => {} /*handler.setAcceptOnStore(!handler.acceptOnStore)*/}
                          >
                            <SelectButtonOptionText isChecked={true} /*handler.acceptOnStore*/> 
                              Retirada
                            </SelectButtonOptionText>
                          </SelectButtonOption>
                        )}
                        {userInfo.is_using_delivery && (
                          <SelectButtonOption
                            isChecked={true} //handler.acceptOnDelivery
                            type="button"
                            onClick={() => {} /*handler.setAcceptOnDelivery(!handler.acceptOnDelivery)*/}
                          >
                            <SelectButtonOptionText isChecked={true} /*handler.acceptOnDelivery*/>
                              Delivery
                            </SelectButtonOptionText>
                          </SelectButtonOption>
                        )}
                      </Row>
                    </Col>
                    <Col width="auto">
                      <ItemInputLabel>
                        Validade do cupom
                      </ItemInputLabel>
                      <Row
                        justifyStart
                        margtop={px2vw(5)}
                        margleft={px2vw(10)}
                      >
                        <ItemInput
                          name="flashCouponValidityDate"
                          width={px2vw(100)}
                          maxLength="10"
                          placeholder="00/00/0000"
                          value={handler.couponValidityDateInputValue}
                          onChange={(t) =>
                            handler.setCouponValidityDateInputValue(
                              dateMask(t.target.value)
                            )
                          }
                        />
                      </Row>
                    </Col>
                    <Col width="auto">
                      <ItemInputLabel>
                        Horário de expiração
                      </ItemInputLabel>
                      <Row 
                        justifyStart
                        margtop={px2vw(5)}
                      >
                        <ItemInput
                          name="flashCouponValidityTime"
                          width={px2vw(60)}
                          maxLength="5"
                          placeholder="00:00"
                          value={handler.couponValidityTimeInputValue}
                          onChange={(t) =>
                            handler.setCouponValidityTimeInputValue(
                              timeMask(t.target.value)
                            )
                          }
                        />
                      </Row>
                    </Col>
                  </Row>
                  <Row margtop={px2vw(30)} height="auto">
                    <Col>
                      <ItemInputLabel>
                        Condições de pagamento
                      </ItemInputLabel>
                      <Row
                        justifyStart
                        margtop={px2vw(10)}
                        margleft={px2vw(10)}
                      >
                        <FormControlLabel
                          control={
                            <SwitchController
                              checked={handler.acceptCard}
                              type="checkbox"
                              onChange={() => handler.setAcceptCard(!handler.acceptCard)}
                            />
                          }
                          label={
                            <SwitchLabelRegularText>
                              Cartão
                            </SwitchLabelRegularText>
                          }
                        />
                        <div style={{ marginRight: '20px' }} />
                        <FormControlLabel
                          control={
                            <SwitchController
                              checked={handler.acceptMoney}
                              type="checkbox"
                              onChange={() => handler.setAcceptMoney(!handler.acceptMoney)}
                            />
                          }
                          label={
                            <SwitchLabelRegularText>
                              Dinheiro
                            </SwitchLabelRegularText>
                          }
                        />
                       <div style={{ marginRight: '20px' }} />
                        <FormControlLabel
                          control={
                            <SwitchController
                              checked={handler.acceptPix}
                              type="checkbox"
                              onChange={() => handler.setAcceptPix(!handler.acceptPix)}
                            />
                          }
                          label={
                            <SwitchLabelRegularText>
                              Pix
                            </SwitchLabelRegularText>
                          }
                        />
                      </Row>
                    </Col>
                    <Col>
                      <ItemInputLabel>
                        Limite de cupons
                      </ItemInputLabel>
                      <Row justifyStart margtop={px2vw(5)}>
                        <ItemInput
                          name="flashCouponUseLimit"
                          width={px2vw(80)}
                          placeholder="15"
                          min="1"
                          max="9999"
                          type="text"
                          value={handler.couponUseLimitInputValue}
                          onChange={(t) => {
                            handler.setCouponUseLimitInputValue(
                              onlyNumbers(t.target.value.substring(0, 4))
                            );
                          }}
                        />
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <ModalActions>
                <ModalSecundaryButton
                  margleft={px2vw(190)}
                  marginRight={px2vw(20)}
                  margtop={px2vw(40)}
                  width={px2vw(250)}
                  type="button"
                  onClick={() => handler.handleManageCouponContent('back')}
                  disabled={handler.updateCouponLoading}
                >
                  <ModalSecundaryButtonText>
                    Cancelar
                  </ModalSecundaryButtonText>
                </ModalSecundaryButton>
                <ModalPrimaryButton
                  margleft={px2vw(20)}
                  margtop={px2vw(40)}
                  width={px2vw(250)}
                  type="submit"
                  disabled={handler.updateCouponLoading}
                >
                  <ModalPrimaryButtonText>
                    {!isEditing && 'Criar cupom'}
                    {isEditing && (
                      handler.updateCouponLoading ? (
                        <LoadingBox>
                          <Spinner
                            style={{
                              width: '1.8rem',
                              height: '1.8rem',
                              color: preto,
                            }}
                          />{' '}
                        </LoadingBox>
                      ) : (
                        'Editar cupom'
                      )
                    )}
                  </ModalPrimaryButtonText>
                </ModalPrimaryButton>
              </ModalActions>
            </CouponDiscountTypeBox>
          </FormFlashCouponPercentCart>
        )}
      </FlashCouponsContent>
    </ContentPage>
  );
}
