export function getClientsRequest() {
  return {
    type: '@clients/GET_CLIENTS_REQUEST',
  };
}

export function getClientsSuccess(clients) {
  return {
    type: '@clients/GET_CLIENTS_SUCCESS',
    payload: { clients },
  };
}

export function getClientsFailure() {
  return {
    type: '@clients/GET_CLIENTS_FAILURE',
  };
}

export function getClientsMetricsRequest() {
  return {
    type: '@clients/GET_CLIENTS_METRICS_REQUEST',
  };
}

export function getClientsMetricsSuccess(clientsMetrics) {
  return {
    type: '@clients/GET_CLIENTS_METRICS_SUCCESS',
    payload: { clientsMetrics },
  };
}

export function getClientsMetricsFailure() {
  return {
    type: '@clients/GET_CLIENTS_METRICS_FAILURE',
  };
}

export function getClientDetailsRequest(clientId) {
  return {
    type: '@clients/GET_CLIENTS_DETAILS_REQUEST',
    payload: { clientId },
  };
}

export function getClientDetailsSuccess(clientDetails) {
  return {
    type: '@clients/GET_CLIENTS_DETAILS_SUCCESS',
    payload: { clientDetails },
  };
}

export function getClientDetailsFailure() {
  return {
    type: '@clients/GET_CLIENTS_DETAILS_FAILURE',
  };
}
