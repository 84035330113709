import printAtWordWrap from './txtWarper';
import { branco, preto, amareloKuppi5 } from '../../styles/colors';
import { onlyNumbers } from '../utils';

export function conversionTemplate1(
  couponImage,
  logoImage,
  bgImage,
  couponText,
  productName,
  bgColor
) {
  return new Promise(async (resolve) => {
    const canvas = document.createElement('canvas'); // 1080 x 1080
    canvas.width = '1080';
    canvas.height = '1080';
    const context = canvas.getContext('2d');

    const txtPrice = couponText.split(' ');
    let w = context.measureText(txtPrice[1]).width;
    let color;
    let priceColor;

    if (bgColor === 'default' || bgColor === 'green') {
      color = preto;
      priceColor = preto;
    } else if (bgColor === 'blue') {
      color = branco;
      priceColor = branco;
    } else {
      color = branco;
      priceColor = amareloKuppi5;
    }

    // Draw coupon info
    context.save();
    context.rotate((1.1 * Math.PI) / 180);
    context.drawImage(couponImage, 284, 257, 650, 655);
    context.restore();

    // Draw background
    context.drawImage(bgImage, 0, 0, context.canvas.width, context.canvas.height);

    context.fillStyle = color;
    context.font = '700 50px AvenirNextLTPro';
    printAtWordWrap(
      context,
      productName.toUpperCase(),
      349,
      productName.length < 19 ? 155 : 112,
      67,
      727
    );

    context.fillStyle = color;
    context.font = '600 48px AvenirNextLTPro';
    context.textAlign = 'center';
    context.fillText(txtPrice[0], 85, 840);
    context.fillText(txtPrice[1], 205, 840);
    context.fillText(txtPrice[2], w / 1.75 + 315, 840);
    context.fillStyle = priceColor;
    context.font = '700 65px AvenirNextLTPro';
    context.fillText(txtPrice[3].substring(0, 2), 85, 960);
    context.font = '700 120px AvenirNextLTPro';
    context.fillText(txtPrice[3].substring(2), 250, 960);

    // Draw logo
    context.strokeStyle = color;
    context.lineWidth = 5;
    w = context.measureText(txtPrice[1]).width;
    context.beginPath();
    context.moveTo(132, 827);
    context.lineTo(txtPrice[1].length > 4 ? w / 1.4 : w / 1.15, 827);
    context.stroke();
    context.beginPath();
    context.arc(179, 175, 125, 0, 2 * Math.PI);
    context.clip();
    context.drawImage(logoImage, 45, 40, 270, 270);

    await resolve(canvas.toDataURL());
  });
}

export function conversionTemplate2(
  couponImage,
  logoImage,
  bgImage,
  couponText,
  productName,
  bgColor
) {
  return new Promise(async (resolve) => {
    const canvas = document.createElement('canvas'); // 1080 x 1080
    canvas.width = '1080';
    canvas.height = '1080';
    const context = canvas.getContext('2d');

    const txtPrice = couponText.split(' ');
    let w = context.measureText(txtPrice[1]).width;
    let color = branco;
    let priceColor = branco;

    if (bgColor === 'gray') {
      color = preto;
      priceColor = preto;
    }

    // Draw coupon info
    context.save();
    context.rotate((8.75 * Math.PI) / 180);
    context.drawImage(couponImage, 530, 181, 586, 593);
    context.restore();

    // Draw background
    context.drawImage(bgImage, 0, 0, context.canvas.width, context.canvas.height);

    context.fillStyle = color;
    context.font = '700 50px AvenirNextLTPro';
    printAtWordWrap(
      context,
      productName.toUpperCase(),
      50,
      100,
      67,
      980
    );

    context.fillStyle = color;
    context.font = '600 48px AvenirNextLTPro';
    context.textAlign = 'center';
    context.fillText(txtPrice[0], 85, 840);
    context.fillText(txtPrice[1], 205, 840);
    context.fillText(txtPrice[2], w / 1.75 + 315, 840);
    context.fillStyle = priceColor;
    context.font = '700 65px AvenirNextLTPro';
    context.fillText(txtPrice[3].substring(0, 2), 85, 960);
    context.font = '700 120px AvenirNextLTPro';
    context.fillText(txtPrice[3].substring(2), 250, 960);

    // Draw logo
    context.strokeStyle = color;
    context.lineWidth = 5;
    w = context.measureText(txtPrice[1]).width;
    context.beginPath();
    context.moveTo(132, 827);
    context.lineTo(txtPrice[1].length > 4 ? w / 1.4 : w / 1.15, 827);
    context.stroke();
    context.beginPath();
    context.arc(223, 532.5, 153, 0, 2 * Math.PI);
    context.clip();
    context.drawImage(logoImage, 65, 375, 320, 320);

    await resolve(canvas.toDataURL());
  });
}

export function conversionTemplate3(
  couponImage,
  logoImage,
  bgImage,
  couponText,
  productName,
  bgColor
) {
  return new Promise(async (resolve) => {
    const canvas = document.createElement('canvas'); // 1080 x 1080
    canvas.width = '1080';
    canvas.height = '1080';
    const context = canvas.getContext('2d');
   
    let color;  

    if (bgColor === 'gray' || bgColor === 'green') {
      color = preto;      
    } else if (bgColor === 'blue') {
      color = branco;      
    } else {
      color = branco;
    }

    context.fillStyle = color;
    context.font = '700 48px AvenirNextLTPro';
    context.textAlign = 'center';

    // Draw coupon info
    context.drawImage(couponImage, 0, 0, context.canvas.width, context.canvas.height);

    // Draw logo
    context.drawImage(logoImage, 135, 760, 270, 270);

    // Draw background
    context.drawImage(bgImage, 0, 0, context.canvas.width, context.canvas.height);

    context.fillText(couponText, 675, 1000);
    context.font = '700 50px AvenirNextLTPro';
    const textSize = productName.length <= 15;
    printAtWordWrap(
      context,
      productName.toUpperCase(),
      675,
      textSize ? 820 : 835,
      55,
      450
    );

    await resolve(canvas.toDataURL());
  });
}

export function conversionTemplate4(
  couponImage,
  logoImage,
  bgImage,
  couponText,  
  bgColor
) {
  return new Promise(async (resolve) => {
    const canvas = document.createElement('canvas'); // 1080 x 1080
    canvas.width = '1080';
    canvas.height = '1080';
    const context = canvas.getContext('2d');

    const txtPrice = couponText.split(' ');
    const price = onlyNumbers(txtPrice[1]);
    const priceWithDiscount = onlyNumbers(txtPrice[3]);
    const percentageDiscount = Math.round((((priceWithDiscount/price) * 100) - 100) * -1);
   
    let color;

    if (bgColor === 'gray' || bgColor === 'green') {
      color = preto;
    } else if (bgColor === 'blue') {
      color = branco;
    } else {
      color = branco;
    }

    context.fillStyle = color;
    context.font = '700 48px AvenirNextLTPro';
    context.textAlign = 'center';

    // Draw coupon info
    context.drawImage(couponImage, 0, 0, context.canvas.width, context.canvas.height);

    // Draw background
    context.drawImage(bgImage, 0, 0, context.canvas.width, context.canvas.height);

    context.fillText(`${percentageDiscount}% OFF`, 534, 347);

    // Draw logo
    context.beginPath();
    context.arc(531.5, 170, 107, 0, 2 * Math.PI);
    context.clip();
    context.drawImage(logoImage, 415, 55, 235, 235);

    await resolve(canvas.toDataURL());
  });
}

export function conversionTemplate5(
  couponImage,
  logoImage,
  bgImage,
  couponText,
  productName,
  bgColor,
  userName,
) {
  return new Promise(async (resolve) => {
    const canvas = document.createElement('canvas'); // 1080 x 1080
    canvas.width = '1080';
    canvas.height = '1080';
    const context = canvas.getContext('2d');

    const txtPrice = couponText.split(' ');
    let w = context.measureText(txtPrice[1]).width;
    let color;

    if (bgColor === 'default' || bgColor === 'green') {
      color = preto;
    }
    if (bgColor === 'red' || bgColor === 'orange') {
      color = amareloKuppi5;
    }
    if (bgColor === 'blue') {
      color = branco;
    }

    // Draw coupon info
    context.save();
    context.rotate((-8.4 * Math.PI) / 180);
    context.drawImage(couponImage, -108, 140, 635, 625);
    context.restore();

    // Draw background
    context.drawImage(bgImage, 0, 0, context.canvas.width, context.canvas.height);

    context.fillStyle = color;
    context.font = '700 65px AvenirNextLTPro';
    printAtWordWrap(context, productName, 575, 165, 67, 350);

    context.fillStyle = color;
    context.font = '600 48px AvenirNextLTPro';
    context.textAlign = 'center';
    context.fillText(txtPrice[0], 665, 480);
    context.fillText(txtPrice[1], 785, 480);
    context.fillText(txtPrice[2], w / 1.75 + 895, 480);

    context.font = '700 65px AvenirNextLTPro';
    context.fillText(txtPrice[3].substring(0, 2), 680, 630);
    context.font = '700 120px AvenirNextLTPro';
    context.fillText(txtPrice[3].substring(2), 840, 630);

    context.fillStyle =
      bgColor === 'orange' || bgColor === 'red' ? branco : color;
    context.font = '700 51px AvenirNextLTPro';
    context.textAlign = 'center';
    context.fillText(userName.toUpperCase(), canvas.width / 2, 1025);

    // Draw logo
    context.strokeStyle = color;
    context.lineWidth = 5;
    w = context.measureText(txtPrice[1]).width;
    context.beginPath();
    context.moveTo(712, 467);
    context.lineTo(txtPrice[1].length > 4 ? 694 + w : 720 + w, 467);
    context.stroke();
    context.beginPath();
    context.arc(860, 810, 140, 0, 2 * Math.PI);
    context.clip();
    context.drawImage(logoImage, 701.5, 648, 322, 322);

    await resolve(canvas.toDataURL());
  });
}
