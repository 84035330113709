import React, { useEffect, useState } from 'react';
import { Spinner } from 'reactstrap';

import { preto, verdeKuppi7 } from '../../../../styles/colors';
import { Row, Col } from '../../../../styles/grid';
import { LoadingBox } from '../../../../styles/common';
import {
  QuestionTitleText,
  FormData,
  CheckboxContainer,
  CheckbuttomContainer,
  InputDataTextArea,
} from '../../BaseScreen/styles';

import ButtonPrimary from '../../../../components/ButtonPrimary';
import Checkbox from '../../../../components/Checkbox';
import Checkbutton from '../../../../components/Checkbox/Checkbutton';

import px2vw from '../../../../utils/responsiveness/px2vw';
import { scrollTop } from '../../../../utils/utils';
import { firstStepsPageTwoQuestionTwoTitleText, firstStepsPageTwoQuestionThreeTitleText, firstStepsPageTwoQuestionTwoAnswerOneText, firstStepsPageTwoQuestionTwoAnswerTwoText, firstStepsPageTwoQuestionTwoAnswerThreeText, firstStepsPageTwoQuestionTwoAnswerFourText, firstStepsPageTwoQuestionTwoAnswerFiveText, firstStepsPageTwoQuestionTwoAnswerSixText, firstStepsPageTwoQuestionThreeAnswerOneText, firstStepsPageTwoQuestionThreeAnswerTwoText, firstStepsPageTwoForwardButtonText } from '../constants';

export default function PageTwoMobile({ handler }) {
  const checkboxSpaceBetween = 60;

  const [scroll, setScroll] = useState(true)
  useEffect(() => {
    if (scroll) {
      scrollTop()
      setScroll(false)
    }
  }, [scroll])
  return (
    <Col>
      <Row justifyStart>
        <FormData onSubmit={handler.handleSurveyFindOutAboutSubmit}>
          <Row justifyCenter padleft={px2vw(20)} margbottom={px2vw(70)}>
            <QuestionTitleText fontsize={px2vw(70)}>
              {firstStepsPageTwoQuestionTwoTitleText}
            </QuestionTitleText>
          </Row>
          <Row margtop={px2vw(10)}>
            <Col width="auto">
              <CheckboxContainer
                margbottom={checkboxSpaceBetween}
                padding={px2vw(25)}
                width={'100%'}
                backgroundColor={verdeKuppi7}
                justifyContent={'flex-start'}
                onClick={() => handler.findOutAboutOptionClick('social_media_advertising')}
              >
                <Checkbox
                  label={firstStepsPageTwoQuestionTwoAnswerOneText}
                  name={firstStepsPageTwoQuestionTwoAnswerOneText}
                  checked={handler.mediaAdvertising}
                  height={120}
                  styledBoxWidth={120}
                  styledBoxHeight={100}
                  imgWidth={55}
                  labelmarginleft={50}
                  fontSize={60}
                  insideWidth={1200}
                />
              </CheckboxContainer>
              <CheckboxContainer
                margbottom={checkboxSpaceBetween}
                padding={px2vw(25)}
                width={'100%'}
                backgroundColor={verdeKuppi7}
                justifyContent={'flex-start'}
                onClick={() => handler.findOutAboutOptionClick('member_get_member')}
              >
                <Checkbox
                  name={firstStepsPageTwoQuestionTwoAnswerTwoText}
                  label={firstStepsPageTwoQuestionTwoAnswerTwoText}
                  checked={handler.memberGetMember}
                  height={120}
                  styledBoxWidth={120}
                  styledBoxHeight={100}
                  imgWidth={55}
                  labelmarginleft={50}
                  fontSize={60}
                  insideWidth={1200}
                />
              </CheckboxContainer>
              <CheckboxContainer
                margbottom={checkboxSpaceBetween}
                padding={px2vw(25)}
                width={'100%'}
                backgroundColor={verdeKuppi7}
                justifyContent={'flex-start'}
                onClick={() => handler.findOutAboutOptionClick('kuppi_wpp_employee_contact')}
              >
                <Checkbox
                  name={firstStepsPageTwoQuestionTwoAnswerThreeText}
                  label={firstStepsPageTwoQuestionTwoAnswerThreeText}
                  checked={handler.wppEmployeeContact}
                  height="auto"
                  styledBoxWidth={120}
                  styledBoxHeight={100}
                  imgWidth={55}
                  labelmarginleft={50}
                  fontSize={60}
                  insideWidth={1200} />
              </CheckboxContainer>
              <CheckboxContainer
                margbottom={checkboxSpaceBetween}
                padding={px2vw(25)}
                width={'100%'}
                backgroundColor={verdeKuppi7}
                justifyContent={'flex-start'}
                onClick={() => handler.findOutAboutOptionClick('google_search')}
              >
                <Checkbox
                  name={firstStepsPageTwoQuestionTwoAnswerFourText}
                  label={firstStepsPageTwoQuestionTwoAnswerFourText}
                  checked={handler.googleSearch}
                  height={120}
                  styledBoxWidth={120}
                  styledBoxHeight={100}
                  imgWidth={55}
                  labelmarginleft={50}
                  fontSize={60}
                  insideWidth={1200} />
              </CheckboxContainer>

              <CheckboxContainer
                margbottom={checkboxSpaceBetween}
                padding={px2vw(25)}
                width={'100%'}
                backgroundColor={verdeKuppi7}
                justifyContent={'flex-start'}
                onClick={() => handler.findOutAboutOptionClick('wpp_group')}
              >
                <Checkbox
                  name={firstStepsPageTwoQuestionTwoAnswerFiveText}
                  height={120}
                  styledBoxWidth={120}
                  styledBoxHeight={100}
                  imgWidth={55}
                  labelmarginleft={50}
                  fontSize={60}
                  insideWidth={1200}
                  label={firstStepsPageTwoQuestionTwoAnswerFiveText}
                  checked={handler.wppGroup}
 />
              </CheckboxContainer>
              <CheckboxContainer
                padding={px2vw(25)}
                width={'100%'}
                backgroundColor={verdeKuppi7}
                justifyContent={'flex-start'}
              >
                <Checkbox
                  margbottom={checkboxSpaceBetween}
                  name={firstStepsPageTwoQuestionTwoAnswerSixText}
                  height={120}
                  styledBoxWidth={120}
                  styledBoxHeight={100}
                  imgWidth={55}
                  labelmarginleft={50}
                  fontSize={60}
                  insideWidth={1200}
                  label={firstStepsPageTwoQuestionTwoAnswerSixText}
                  boxshadow={`0px ${px2vw(4)} ${px2vw(4)} rgba(0, 0, 0, 0.15)`}
                  checked={handler.other}
                  onClick={() => handler.findOutAboutOptionClick('other')}
                />
                <InputDataTextArea
                  width={1200}
                  height={300}
                  fontsize={px2vw(80)}
                  type="text"
                  name="Text"
                  maxLength="40"
                  disabled={!handler.other}
                  value={handler.otherContent}
                  onChange={handler.otherContentChange} />
              </CheckboxContainer>
            </Col>
          </Row>
          <Row 
            justifyCenter 
            padleft={px2vw(20)} 
            margtop={px2vw(70)} 
            margbottom={px2vw(70)}
          >
            <QuestionTitleText fontsize={px2vw(70)}>
              {firstStepsPageTwoQuestionThreeTitleText}
            </QuestionTitleText>
          </Row>
          <Row margtop={px2vw(10)} margbottom={px2vw(50)}>
            <Col width="100%" margright={px2vw(15)} >
              <CheckbuttomContainer
                padleft={px2vw(50)}
                width={'100%'}
                height={px2vw(170)}
                backgroundColor={verdeKuppi7}
                justifyContent={'flex-start'}
                onClick={() => handler.hasComputerClick(true)}
              >
                <Checkbutton
                  label={firstStepsPageTwoQuestionThreeAnswerOneText}
                  fontSize={60}
                  checked={handler.hasComputer !== null && handler.hasComputer}
                  imgWidth={90} 
                />
              </CheckbuttomContainer>
            </Col>
            <Col width="100%" margleft={px2vw(20)}>
              <CheckbuttomContainer
                padleft={px2vw(50)}
                width={'100%'}
                height={px2vw(170)}
                backgroundColor={verdeKuppi7}
                justifyContent={'flex-start'}
                onClick={() => handler.hasComputerClick(false)}
              >
                <Checkbutton
                  label={firstStepsPageTwoQuestionThreeAnswerTwoText}
                  checked={handler.hasComputer !== null && !handler.hasComputer}
                  imgWidth={90}
                  fontSize={60} 
                />
              </CheckbuttomContainer>
            </Col>
          </Row>
          <Row justifyEnd margtop={px2vw(20)} height={px2vw(400)}>
            <ButtonPrimary
              borderRadius={40}
              minWidth='60%'
              height={150}
              fontSize={60}
              type='submit'
            >
              {handler.updateSurveyFindOutAboutLoading ? (
                <LoadingBox>
                  <Spinner
                    style={{
                      width: '1.8rem',
                      height: '1.8rem',
                      color: preto,
                    }}
                  />{' '}
                </LoadingBox>
              ) : (
                firstStepsPageTwoForwardButtonText
              )}
            </ButtonPrimary>
          </Row>
        </FormData>
      </Row>
    </Col>
  )
}
