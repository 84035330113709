import React from 'react';

import { Col } from '../../../styles/grid';
import { LogoImageBox, LoginImage, LogoImage } from './styles';
import { cinzaKuppi8 } from '../../../styles/colors';

import Main from './Main';
import RecoveryType from './RecoveryType';
import RecoveryPassword from './RecoveryPassword';
import RecoveryEmail from './RecoveryEmail';

export default function LoginMobile({ handler }) {
  return (
    <Col>
      <LoginImage 
        src={require('../../../assets/images/login/login_mobile.png')}
        background={cinzaKuppi8}
        mobile
      />
      <LogoImageBox mobile>
        <LogoImage
          src={require('../../../assets/images/logo_kuppi/logo_kuppi_wo_slogan.png')}
        />
      </LogoImageBox>

      {/* Login Screen */}
      {handler.currLoginStep === 'main' && (
        <Main handler={handler} />
      )}
      {/* Recovery Type */}
      {handler.currLoginStep === 'recovery_type' && (
        <RecoveryType handler={handler} />
      )}
      {/* Recovery by Email */}
      {handler.currLoginStep === 'recovery_email' && (
        <RecoveryEmail handler={handler} />
      )}
      {/* Recovery by Password */}
      {handler.currLoginStep === 'recovery_password' && (
        <RecoveryPassword handler={handler} />
      )}
    </Col>
  )
}