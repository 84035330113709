import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  cinzaKuppi4,
  cinzaKuppi7,
  preto,
  verdeKuppi14,
  verdeKuppi28,
} from '../../../../styles/colors';
import { Text, TextProductName } from '../../../../styles/common';

import px2vw from '../../../../utils/responsiveness/px2vw';
import { ModalPrimaryButton } from '../styles';
import { Col, Row } from '../../../../styles/grid';

import {
  ModalContent,
  CloseButton,
  ModalHeader,
  CloseImage,
  ModalFooter,
  CouponCard,
  Badge,
} from './styles';
import {
  setCurrentStep,
  setSelectedCoupon as selectCoupon,
} from '../../../../store/modules/massMessage/actions';
import { Icon } from 'react-materialize';
import { ErrorText } from '../../../_layouts/styles/common';
import { manageCoupons } from '../../../../routes/routeMap';
import history from '../../../../services/history';

export default function Coupon(props) {
  const dispatch = useDispatch();
  const [selectedCoupon, setSelectedCoupon] = useState();
  const { availableCoupons } = useSelector((state) => state.massMessage);
  const user = useSelector((state) => state.user.user);
  function handleSelectCoupon(c) {
    dispatch(selectCoupon(c));
    setSelectedCoupon(c.id);
  }

  function returnCoupons(c) {
    if (c.mode === 'code') {
      return (
        <CouponCard
          key={c.id}
          selected={selectedCoupon === c.id}
          onClick={() => handleSelectCoupon(c)}
        >
          <Col spaceBetween>
            <Badge bg={verdeKuppi14} color={verdeKuppi28}>
              CUPOM POR CÓDIGO
            </Badge>
            <Text
              size={px2vw(16)}
              color={preto}
              margtop={px2vw(6)}
              justifyStart
            >
              {c.display_name}
            </Text>

            <Text
              size={px2vw(16)}
              color={preto}
              margtop={px2vw(6)}
              justifyStart
              regular
            >
              {c.code}
            </Text>

            <Row margtop={px2vw(15)} height="auto" justifyStart>
              <img
                alt="thunder"
                style={{
                  height: px2vw(33),
                }}
                src={require('../../../../assets/images/mass_message/thunder_icon.svg')}
              />
              <Row
                margleft={px2vw(4)}
                pad={px2vw(5)}
                width="auto"
                border={'3px solid ' + cinzaKuppi4}
                borderRadius={px2vw(8)}
              >
                <Text size={px2vw(12)} color={cinzaKuppi7} justifyStart>
                  {c.usage_limit} CUPONS
                </Text>
              </Row>
            </Row>
          </Col>
          <Row width={px2vw(120)}>
            {c?.url ? (
              <img
                alt="couponImg"
                style={{
                  borderRadius: px2vw(5),
                  width: px2vw(140),
                  height: px2vw(90),
                  margin: 'auto 0',
                }}
                src={c?.url}
              />
            ) : (
              <img
                alt="couponImg"
                style={{
                  borderRadius: px2vw(5),
                  width: px2vw(140),
                  height: px2vw(90),
                  margin: 'auto 0',
                  filter: 'blur(4px)',
                }}
                src={user.avatar.url}
              />
            )}
          </Row>
        </CouponCard>
      );
    }

    if (c.mode === 'flash') {
      return (
        <CouponCard
          key={c.id}
          selected={selectedCoupon === c.id}
          onClick={() => handleSelectCoupon(c)}
        >
          <Col spaceBetween>
            <Badge bg={verdeKuppi14} color={verdeKuppi28}>
              CUPOM RELÂMPAGO
            </Badge>
            <Text
              size={px2vw(16)}
              color={preto}
              margtop={px2vw(6)}
              justifyStart
            >
              {c.display_name}
            </Text>

            <TextProductName
              size={px2vw(16)}
              color={preto}
              margtop={px2vw(6)}
              justifyStart
              regular
            >
              {c.type === 'percentage_cart'
                ? 'No total do pedido'
                : c.description}
            </TextProductName>

            <Row margtop={px2vw(15)} height="auto" justifyStart>
              <img
                alt="thunder"
                style={{
                  height: px2vw(33),
                }}
                src={require('../../../../assets/images/mass_message/thunder_icon.svg')}
              />
              <Row
                margleft={px2vw(4)}
                pad={px2vw(5)}
                width="auto"
                border={'3px solid ' + cinzaKuppi4}
                borderRadius={px2vw(8)}
              >
                <Text size={px2vw(12)} color={cinzaKuppi7} justifyStart>
                  {c.usage_limit} CUPONS
                </Text>
              </Row>
            </Row>
          </Col>
          <Row width={px2vw(120)}>
            {c?.url ? (
              <img
                alt="couponImg"
                style={{
                  borderRadius: px2vw(5),
                  width: px2vw(140),
                  height: px2vw(90),
                  margin: 'auto 0',
                }}
                src={c?.url}
              />
            ) : (
              <img
                alt="couponImg"
                style={{
                  borderRadius: px2vw(5),
                  width: px2vw(140),
                  height: px2vw(90),
                  margin: 'auto 0',
                  filter: 'blur(4px)',
                }}
                src={user.avatar.url}
              />
            )}
          </Row>
        </CouponCard>
      );
    }

    if (c.mode === 'normal') {
      return (
        <CouponCard
          key={c.id}
          selected={selectedCoupon === c.id}
          onClick={() => handleSelectCoupon(c)}
        >
          <Col justifyStart>
            <Badge>DESCONTO NO PEDIDO</Badge>

            <Text
              size={px2vw(16)}
              color={preto}
              margtop={px2vw(6)}
              justifyStart
            >
              {c.display_name}
            </Text>
            <Text
              size={px2vw(16)}
              color={preto}
              margtop={px2vw(6)}
              justifyStart
              regular
            >
              {c.type === 'percentage_cart'
                ? 'No total do pedido'
                : c.description}
            </Text>
          </Col>
          <Row width={px2vw(120)}>
            {c?.url ? (
              <img
                alt="couponUrl"
                style={{
                  borderRadius: px2vw(5),
                  width: px2vw(140),
                  height: px2vw(90),
                  margin: 'auto 0',
                }}
                src={c?.url}
              />
            ) : (
              <img
                alt="couponUrl"
                style={{
                  borderRadius: px2vw(5),
                  width: px2vw(140),
                  height: px2vw(90),
                  margin: 'auto 0',
                  filter: 'blur(4px)',
                }}
                src={user.avatar.url}
              />
            )}
          </Row>
        </CouponCard>
      );
    }
  }

  function handleGoToNextPage() {
    dispatch(setCurrentStep('3'));
  }

  return (
    <>
      <ModalHeader>
        Escolha a promoção que será disparada
        <CloseButton onClick={props.onClose}>
          <CloseImage
            src={require('../../../../assets/images/close/close.png')}
          />
        </CloseButton>
      </ModalHeader>
      <ModalContent>
        {availableCoupons?.length > 0 ? (
          availableCoupons.map(returnCoupons)
        ) : (
          <Col
            alignCenter
            justifyCenter
            width={px2vw(500)}
            height="auto"
            margleft={px2vw(110)}
          >
            <Row alignCenter justifyCenter height="auto">
              {/* TODO: Ajustar tamanho do icone */}
              <Icon large>sentiment_very_dissatisfied</Icon>
              <ErrorText>
                Você não possui cupons cadastrados <br />
                e/ou ativos para efetuar o disparo.
              </ErrorText>
            </Row>
            <Row
              alignCenter
              justifyCenter
              height="auto"
              margtop={px2vw(80)}
              margbottom={px2vw(30)}
            >
              <ErrorText>
                Crie agora uma promoção, clicando no botão abaixo!
              </ErrorText>
            </Row>
            <ModalPrimaryButton
              onClick={() => history.push(manageCoupons)}
              className="mx-auto"
            >
              Criar Promoção
            </ModalPrimaryButton>
          </Col>
        )}
      </ModalContent>
      {availableCoupons?.length > 0 ? (
        <ModalFooter>
          <ModalPrimaryButton
            onClick={() => handleGoToNextPage()}
            className="ml-auto"
            disabled={!selectedCoupon}
          >
            Avançar
          </ModalPrimaryButton>
        </ModalFooter>
      ) : (
        <></>
      )}
    </>
  );
}
