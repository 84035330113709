import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Progress } from 'reactstrap';
import { preto, verdeKuppi5 } from '../../../../styles/colors';
import { Text } from '../../../../styles/common';
import { Row } from '../../../../styles/grid';
import px2vw from '../../../../utils/responsiveness/px2vw';
import QRCode from 'qrcode';
import { MdContentCopy } from 'react-icons/md';
import { toast } from 'react-toastify';
import {
  CloseButton,
  ModalHeader,
  CloseImage,
  ProgressBar,
  Section,
  PixCodeImg,
  ButtonPrimary,
  PixCodeBar,
  AnimatedImg,
} from './style';

export default function PaymentConfirmation(props) {
  const global = useSelector((state) => state);
  const kuppiBoostData = global.kuppiBoost;
  const { pix_payment_read_only } = kuppiBoostData;
  const pixCode = kuppiBoostData?.pix_payment_code;
  const [paymentStatus, setPaymentStatus] = useState('pending_payment');
  const [qrCodePix, setQrCodePix] = useState('');

  function copyPixPaymentCode() {
    var copyText = document.getElementById('pixCode');
    var textArea = document.createElement('textarea');
    textArea.value = copyText.textContent;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();

    toast.success('A chave Pix foi copiada com sucesso!');
  }

  useEffect(() => {
    // Set QR code data
    QRCode.toDataURL(pixCode)
      .then((text) => {
        setQrCodePix(text);
      })
      .catch((err) => {});
  }, []);

  function addToBalance() {
    props.onClose();
  }

  const pageContent = (
    <>
      <ModalHeader>
        {pix_payment_read_only ? 'QR Code' : 'Adicionar Saldo'}
        <CloseButton onClick={props.onClose}>
          <CloseImage
            src={require('../../../../assets/images/close/close.png')}
          />
        </CloseButton>
      </ModalHeader>
      <ProgressBar>
        <Progress
          value={0}
          bar={true}
          style={{
            backgroundColor: verdeKuppi5,
            borderRaduis: '0px',
            height: '4px',
          }}
        />
      </ProgressBar>
      <Section>
        <Text
          lineHeight={'130%'}
          size={px2vw(17)}
          color={preto}
          bold
          justifyStart
        >
          Para pagar via Pix, utilize o QR Code ou copie e cole o código abaixo
        </Text>
        <PixCodeImg className="mx-auto" src={qrCodePix} />
        <Row
          className="mx-auto"
          border={`${px2vw(2)} dashed ${preto}`}
          height={px2vw(43)}
          width="85%"
          alignCenter
          cursor="pointer"
          relative
          onClick={copyPixPaymentCode}
          margbottom={px2vw(25)}
          spaceBetween
          borderRadius={px2vw(8)}
        >
          <PixCodeBar id="pixCode" className="mx-auto">
            {pixCode}
          </PixCodeBar>
          <MdContentCopy
            className="mx-auto"
            style={{
              fontSize: px2vw(21),
            }}
          />
        </Row>
        {!pix_payment_read_only && (
          <Row height="auto">
            <ButtonPrimary
              onClick={() => setPaymentStatus('success')}
              width={px2vw(220)}
            >
              Confirmar pagamento
            </ButtonPrimary>
          </Row>
        )}
      </Section>
    </>
  );

  const successPage = (
    <>
      <Section spaceBetween mt={px2vw(15)} h={px2vw(500)}>
        <Text lineHeight="130%" size={px2vw(20)} color={preto} bold>
          O pagamento está sendo processado
        </Text>
        <AnimatedImg
          className="mx-auto"
          src={require('../../../../assets/images/success/checkmark.png')}
        />
        <Row height="auto" margtop={px2vw(30)}>
          <ButtonPrimary onClick={addToBalance} width={px2vw(220)}>
            Prosseguir
          </ButtonPrimary>
        </Row>
      </Section>
    </>
  );

  if (paymentStatus === 'success') {
    return successPage;
  }

  return pageContent;
}
