import { takeLatest, call, put, all, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import moment from 'moment';

import { apiAnunciante } from '../../../services/api';

import {
  getCouponsTraditionalRequest as getCouponsTraditionalRequestAction,
  getCouponsTraditionalSuccess,
  getCouponsTraditionalFailure,
  getCouponsFlashRequest as getCouponsFlashRequestAction,
  getCouponsFlashSuccess,
  getCouponsFlashFailure,
  getCouponsCodeRequest as getCouponsCodeRequestAction,
  getCouponsCodeSuccess,
  getCouponsCodeFailure,
  getProductsWithoutCouponsRequest as getProductsWithoutCouponsRequestAction,
  getProductsWithoutCouponsSuccess,
  getProductsWithoutCouponsFailure,
  newCouponSuccess,
  newCouponFailure,
  updateCouponSuccess,
  updateCouponFailure,
  updateCouponStatusSuccess,
  updateCouponStatusFailure,
  deleteCouponSuccess,
  deleteCouponFailure,
  updateAvailableCouponsSuccess,
  updateAvailableCouponsFailure, 
} from './actions';
import {
  logout
} from '../auth/actions';

import { sendCouponInfo, sendCouponStatus } from '../../../services/vendors/mixpanel';

const userReducerData = (state) => state.user;
const userData = (state) => state.user.user;

export function* formatCouponHoraries({ payload }) {
  const sundayJson = { is_active: false, horaries: [] };
  if (payload.sunday.active) {   
    sundayJson.is_active = true;
    sundayJson.horaries = [
      {
        start_at:
          payload.sunday.specificHourStart === ''
            ? '00:00'
            : payload.sunday.specificHourStart,
        end_at:
          payload.sunday.specificHourEnd === ''
            ? '00:00'
            : payload.sunday.specificHourEnd,
      },
    ];
  }
  const mondayJson = { is_active: false, horaries: [] };
  if (payload.monday.active) {
    mondayJson.is_active = true;
    mondayJson.horaries = [
      {
        start_at:
          payload.monday.specificHourStart === ''
            ? '00:00'
            : payload.monday.specificHourStart,
        end_at:
          payload.monday.specificHourEnd === ''
            ? '00:00'
            : payload.monday.specificHourEnd,
      },
    ];
  }
  const tuesdayJson = { is_active: false, horaries: [] };
  if (payload.tuesday.active) {
    tuesdayJson.is_active = true;
    tuesdayJson.horaries = [
      {
        start_at:
          payload.tuesday.specificHourStart === ''
            ? '00:00'
            : payload.tuesday.specificHourStart,
        end_at:
          payload.tuesday.specificHourEnd === ''
            ? '00:00'
            : payload.tuesday.specificHourEnd,
      },
    ];
  }
  const wednesdayJson = { is_active: false, horaries: [] };
  if (payload.wednesday.active) {
    wednesdayJson.is_active = true;
    wednesdayJson.horaries = [
      {
        start_at:
          payload.wednesday.specificHourStart === ''
            ? '00:00'
            : payload.wednesday.specificHourStart,
        end_at:
          payload.wednesday.specificHourEnd === ''
            ? '00:00'
            : payload.wednesday.specificHourEnd,
      },
    ];
  }
  const thursdayJson = { is_active: false, horaries: [] };
  if (payload.thursday.active) {
    thursdayJson.is_active = true;
    thursdayJson.horaries = [
      {
        start_at:
          payload.thursday.specificHourStart === ''
            ? '00:00'
            : payload.thursday.specificHourStart,
        end_at:
          payload.thursday.specificHourEnd === ''
            ? '00:00'
            : payload.thursday.specificHourEnd,
      },
    ];
  }
  const fridayJson = { is_active: false, horaries: [] };
  if (payload.friday.active) {
    fridayJson.is_active = true;
    fridayJson.horaries = [
      {
        start_at:
          payload.friday.specificHourStart === ''
            ? '00:00'
            : payload.friday.specificHourStart,
        end_at:
          payload.friday.specificHourEnd === ''
            ? '00:00'
            : payload.friday.specificHourEnd,
      },
    ];
  }
  const saturdayJson = { is_active: false, horaries: [] };
  if (payload.saturday.active) {
    saturdayJson.is_active = true;
    saturdayJson.horaries = [
      {
        start_at:
          payload.saturday.specificHourStart === ''
            ? '00:00'
            : payload.saturday.specificHourStart,
        end_at:
          payload.saturday.specificHourEnd === ''
            ? '00:00'
            : payload.saturday.specificHourEnd,
      },
    ];
  }

  return {
    sunday: sundayJson,
    monday: mondayJson,
    tuesday: tuesdayJson,
    wednesday: wednesdayJson,
    thursday: thursdayJson,
    friday: fridayJson,
    saturday: saturdayJson,
  };
}

export function* getTraditionalCouponsRequest() {
  try {
    const user = yield select(userData);

    const response = yield call(
      apiAnunciante.get,
      `/user-info/${user.advertiserInfo.id}/coupons/normal`
    );

    yield put(getCouponsTraditionalSuccess(response.data));
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(getCouponsTraditionalFailure());
    }
  }
}

export function* getFlashCouponsRequest() {
  try {
    const user = yield select(userData);

    const response = yield call(
      apiAnunciante.get,
      `/user-info/${user.advertiserInfo.id}/coupons/flash`
    );

    yield put(getCouponsFlashSuccess(response.data));
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(getCouponsFlashFailure());
    }
  }
}

export function* getCodeCouponsRequest() {
  try {
    const user = yield select(userData);

    const response = yield call(
      apiAnunciante.get,
      `/user-info/${user.advertiserInfo.id}/coupons/code`
    );
    yield put(getCouponsCodeSuccess(response.data));
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(getCouponsCodeFailure());
    }
  }
}

export function* getProductsWithoutCouponsRequest() {
  try {
    const user = yield select(userData);

    const response = yield call(
      apiAnunciante.get,
      `/user-info/${user.advertiserInfo.id}/products-without-coupons`
    );

    yield put(getProductsWithoutCouponsSuccess(response.data));
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(getProductsWithoutCouponsFailure());
    }
  }
}

export function* newTraditionalCouponRequest({ payload }) {
  try {
    const user = yield select(userData);
    const userReducer = yield select(userReducerData);

    const expirationDate = moment(
      payload.couponValidityDate,
      'DD/MM/YYYY',
      true
    ).format('YYYY-MM-DD');

    let cartDiscountPercentage = payload.couponDiscountPercent;
    if (cartDiscountPercentage === '' || payload.couponType === 'from_value_to_value') {
      cartDiscountPercentage = 0;
    }

    let promotionalAmount = payload.couponPromotionalAmount;
    if (promotionalAmount === '' || payload.couponType === 'percentage_cart') {
      promotionalAmount = 0.0;
    }

    let originalAmount = payload.couponOriginalAmount;
    if (originalAmount === '' || payload.couponType === 'percentage_cart') {
      originalAmount = 0.0;
    }

    const formattedHoraries = yield call(formatCouponHoraries, { payload: payload.couponHoraries });

    yield call(
      apiAnunciante.post,
      `/user-info/${user.advertiserInfo.id}/coupons/normal`,
      {
        product_id: payload.couponType === 'from_value_to_value' ? payload.productId : null,
        type: payload.couponType,
        short_display_name: payload.couponShortDisplayName,
        display_name: payload.couponDisplayName,
        description: payload.couponDescription,
        is_accept_delivery: payload.couponAcceptedDelivery,
        is_accept_physical: payload.couponAcceptedStore,
        validity_at: expirationDate,
        is_accept_credit_card:  payload.couponAcceptCard,
        is_accept_money: payload.couponAcceptMoney,
        is_accept_pix: payload.couponAcceptPix,
        benefits: {
          cart_discount_percentage: cartDiscountPercentage,
          from_value: originalAmount,
          by_value: promotionalAmount,
        },
        horaries: formattedHoraries,
      }
    );

    yield put(getCouponsTraditionalRequestAction());
    yield put(getProductsWithoutCouponsRequestAction());
    yield put(newCouponSuccess());
    toast.success('Cupom criado com sucesso!');

    //MixPanel Event
    sendCouponInfo('Created', {
      id: user.advertiserInfo.id,
      name: user.representative_name,
      email: user.email,
      couponDisplayName: payload.couponDisplayName,
      couponMode: 'normal',
      cartDiscountPercentage: cartDiscountPercentage,
      promotionalAmount: promotionalAmount,
      hasFreeTrialValid: userReducer.hasFreeTrialValid
    })
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(newCouponFailure());
      toast.error(`Ocorreu um erro ao criar o cupom. Tente novamente!`, {
        autoClose: 6000,
      });
    }
  }
}

export function* newFlashCouponRequest({ payload }) {
  try {
    const user = yield select(userData);
    const userReducer = yield select(userReducerData);

    const expirationDate = moment(
      `${payload.couponValidityDate} ${payload.couponValidityTime}`,
      'DD/MM/YYYY HH:mm',
      true
    ).format('YYYY-MM-DD HH:mm');

    let cartDiscountPercentage = payload.couponDiscountPercent;
    if (cartDiscountPercentage === '' || payload.couponType === 'from_value_to_value') {
      cartDiscountPercentage = 0;
    }

    let promotionalAmount = payload.couponPromotionalAmount;
    if (promotionalAmount === '' || payload.couponType === 'percentage_cart') {
      promotionalAmount = 0.0;
    }

    let originalAmount = payload.couponOriginalAmount;
    if (originalAmount === '' || payload.couponType === 'percentage_cart') {
      originalAmount = 0.0;
    }

    yield call(
      apiAnunciante.post,
      `/user-info/${user.advertiserInfo.id}/coupons/flash`,
      {
        product_id: payload.couponType === 'from_value_to_value' ? payload.productId : null,
        type: payload.couponType,
        short_display_name: payload.couponShortDisplayName,
        display_name: payload.couponDisplayName,
        description: payload.couponDescription,
        is_accept_delivery: payload.couponAcceptedDelivery,
        is_accept_physical: payload.couponAcceptedStore,
        validity_at: expirationDate,
        usage_limit: payload.couponUseLimit,
        is_accept_credit_card:  payload.couponAcceptCard,
        is_accept_money: payload.couponAcceptMoney,
        is_accept_pix: payload.couponAcceptPix,
        benefits: {
          cart_discount_percentage: cartDiscountPercentage,
          from_value: originalAmount,
          by_value: promotionalAmount,
        },
      }
    );

    yield put(getCouponsFlashRequestAction());
    yield put(getProductsWithoutCouponsRequestAction());
    yield put(newCouponSuccess());
    toast.success('Cupom criado com sucesso!');

    //MixPanel Event
    sendCouponInfo('Created', {
      id: user.advertiserInfo.id,
      name: user.representative_name,
      email: user.email,
      couponDisplayName: payload.couponDisplayName,
      couponMode: 'flash',
      cartDiscountPercentage: cartDiscountPercentage,
      promotionalAmount: promotionalAmount,
      hasFreeTrialValid: userReducer.hasFreeTrialValid
    })
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(newCouponFailure());
      toast.error(`Ocorreu um erro ao criar o cupom. Tente novamente!`, {
        autoClose: 6000,
      });
    }
  }
}

export function* newCodeCouponRequest({ payload }) {
  try {
    const user = yield select(userData);
    const userReducer = yield select(userReducerData);

    let cartDiscountPercentage = payload.couponDiscountPercent;
    if (cartDiscountPercentage === '' || payload.couponType === 'fixed_cart') {
      cartDiscountPercentage = 0;
    }

    let cartDiscountFixed = payload.couponDiscountFixed;
    if (cartDiscountFixed === '' || payload.couponType === 'percentage_cart') {
      cartDiscountFixed = 0;
    }

    const formattedHoraries = yield call(formatCouponHoraries, { payload: payload.couponHoraries });

    yield call(
      apiAnunciante.post,
      `/user-info/${user.advertiserInfo.id}/coupons/code`,
      {
        code: payload.couponCode,
        type: payload.couponType,
        short_display_name: payload.couponShortDisplayName,
        display_name: payload.couponDisplayName,
        description: payload.couponDescription,
        is_accept_delivery: payload.couponAcceptedDelivery,
        is_accept_physical: payload.couponAcceptedStore,
        usage_limit: payload.couponUseLimit,
        is_accept_credit_card:  payload.couponAcceptCard,
        is_accept_money: payload.couponAcceptMoney,
        is_accept_pix: payload.couponAcceptPix,
        is_first_order_discount: payload.couponIsFirstOrderDiscount,
        order_minimum_amount: payload.couponOrderMinimumAmount,
        benefits: {
          cart_discount_percentage: cartDiscountPercentage,
          cart_discount_fixed: cartDiscountFixed,
        },
        horaries: formattedHoraries
      }
    );

    yield put(getCouponsCodeRequestAction());
    yield put(getProductsWithoutCouponsRequestAction());
    yield put(newCouponSuccess());
    toast.success('Cupom criado com sucesso!');

    //MixPanel Event
    sendCouponInfo('Created', {
      id: user.advertiserInfo.id,
      name: user.representative_name,
      email: user.email,
      couponDisplayName: payload.couponDisplayName,
      couponMode: 'code',
      cartDiscountPercentage: cartDiscountPercentage,
      cartDiscountFixed: cartDiscountFixed,
      hasFreeTrialValid: userReducer.hasFreeTrialValid
    })
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else if (err.response?.status === 409) {
      yield put(newCouponFailure());
      toast.error(`Já existe um cupom usando esse código. Altere o código e tente novamente!`, {
        autoClose: 6000,
      });
    } else {
      yield put(newCouponFailure());
      toast.error(`Ocorreu um erro ao criar o cupom. Tente novamente!`, {
        autoClose: 6000,
      });
    }
  }
}

export function* updateTraditionalCouponRequest({ payload }) {
  try {
    const user = yield select(userData);
    const userReducer = yield select(userReducerData);

    const expirationDate = moment(
      payload.couponValidityDate,
      'DD/MM/YYYY',
      true
    ).format('YYYY-MM-DD');

    let cartDiscountPercentage = payload.couponDiscountPercent;
    if (cartDiscountPercentage === '') {
      cartDiscountPercentage = 0;
    }

    let promotionalAmount = payload.couponPromotionalAmount;
    if (promotionalAmount === '') {
      promotionalAmount = 0;
    }

    const formattedHoraries = yield call(formatCouponHoraries, { payload: payload.couponHoraries });

    yield call(
      apiAnunciante.put,
      `/user-info/${user.advertiserInfo.id}/coupons/normal/${payload.couponId}`,
      {
        short_display_name: payload.couponShortDisplayName,
        display_name: payload.couponDisplayName,
        description: payload.couponDescription,
        is_accept_delivery: payload.couponAcceptedDelivery,
        is_accept_physical: payload.couponAcceptedStore,
        validity_at: expirationDate,
        is_accept_credit_card:  payload.couponAcceptCard,
        is_accept_money: payload.couponAcceptMoney,
        is_accept_pix: payload.couponAcceptPix,
        benefits: {
          cart_discount_percentage: cartDiscountPercentage,
          by_value: promotionalAmount,
        },
        horaries: formattedHoraries,
      }
    );

    yield put(getCouponsTraditionalRequestAction());
    yield put(updateCouponSuccess());
    toast.success('Cupom atualizado com sucesso!');

    //MixPanel Event
    sendCouponInfo('Update', {
      id: user.advertiserInfo.id,
      name: user.representative_name,
      email: user.email,
      couponDisplayName: payload.couponDisplayName,
      couponMode: 'normal',
      cartDiscountPercentage: cartDiscountPercentage,
      promotionalAmount: promotionalAmount,
      hasFreeTrialValid: userReducer.hasFreeTrialValid
    })
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(updateCouponFailure());
      toast.error(`Ocorreu um erro ao atualizar o cupom. Tente novamente!`, {
        autoClose: 6000,
      });
    }
  }
}

export function* updateFlashCouponRequest({ payload }) {
  try {
    const user = yield select(userData);
    const userReducer = yield select(userReducerData);

    const expirationDate = moment(
      `${payload.couponValidityDate} ${payload.couponValidityTime}`,
      'DD/MM/YYYY HH:mm',
      true
    ).format('YYYY-MM-DD HH:mm');

    let cartDiscountPercentage = payload.couponDiscountPercent;
    if (cartDiscountPercentage === '') {
      cartDiscountPercentage = 0;
    }

    let promotionalAmount = payload.couponPromotionalAmount;
    if (promotionalAmount === '') {
      promotionalAmount = 0;
    }

    yield call(
      apiAnunciante.put,
      `/user-info/${user.advertiserInfo.id}/coupons/flash/${payload.couponId}`,
      {
        short_display_name: payload.couponShortDisplayName,
        display_name: payload.couponDisplayName,
        description: payload.couponDescription,
        is_accept_delivery: payload.couponAcceptedDelivery,
        is_accept_physical: payload.couponAcceptedStore,
        validity_at: expirationDate,
        usage_limit: payload.couponUseLimit,
        is_accept_credit_card:  payload.couponAcceptCard,
        is_accept_money: payload.couponAcceptMoney,
        is_accept_pix: payload.couponAcceptPix,
        benefits: {
          cart_discount_percentage: cartDiscountPercentage,
          by_value: promotionalAmount,
        }
      }
    );

    yield put(getCouponsFlashRequestAction());
    yield put(updateCouponSuccess());
    toast.success('Cupom atualizado com sucesso!');

    //MixPanel Event
    sendCouponInfo('Update', {
      id: user.advertiserInfo.id,
      name: user.representative_name,
      email: user.email,
      couponDisplayName: payload.couponDisplayName,
      couponMode: 'flash',
      cartDiscountPercentage: cartDiscountPercentage,
      promotionalAmount: promotionalAmount,
      hasFreeTrialValid: userReducer.hasFreeTrialValid
    })
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(updateCouponFailure());
      toast.error(`Ocorreu um erro ao atualizar o cupom. Tente novamente!`, {
        autoClose: 6000,
      });
    }
  }
}

export function* updateCodeCouponRequest({ payload }) {
  try {
    const user = yield select(userData);
    const userReducer = yield select(userReducerData);

    let cartDiscountPercentage = payload.couponDiscountPercent;
    if (cartDiscountPercentage === '' || payload.couponType === 'fixed_cart') {
      cartDiscountPercentage = 0;
      
    }

    let cartDiscountFixed = payload.couponDiscountFixed;
    if (cartDiscountFixed === '' || payload.couponType === 'percentage_cart') {
      cartDiscountFixed = 0;
    }

    const formattedHoraries = yield call(formatCouponHoraries, { payload: payload.couponHoraries });

    yield call(
      apiAnunciante.put,
      `/user-info/${user.advertiserInfo.id}/coupons/code/${payload.couponId}`,
      {
        code: payload.couponCode,
        type: payload.couponType,
        short_display_name: payload.couponShortDisplayName,
        display_name: payload.couponDisplayName,
        description: payload.couponDescription,
        is_accept_delivery: payload.couponAcceptedDelivery,
        is_accept_physical: payload.couponAcceptedStore,
        is_accept_credit_card:  payload.couponAcceptCard,
        is_accept_money: payload.couponAcceptMoney,
        is_accept_pix: payload.couponAcceptPix,
        is_first_order_discount: payload.couponIsFirstOrderDiscount,
        order_minimum_amount: payload.couponOrderMinimumAmount,
        benefits: {
          cart_discount_percentage: cartDiscountPercentage,
          cart_discount_fixed: cartDiscountFixed,
        },
        horaries: formattedHoraries
      }
    );

    yield put(getCouponsCodeRequestAction());
    yield put(updateCouponSuccess());
    toast.success('Cupom atualizado com sucesso!');

    //MixPanel Event
    sendCouponInfo('Update', {
      id: user.advertiserInfo.id,
      name: user.representative_name,
      email: user.email,
      couponDisplayName: payload.couponDisplayName,
      couponMode: 'code',
      cartDiscountPercentage: cartDiscountPercentage,
      cartDiscountFixed: cartDiscountFixed,
      hasFreeTrialValid: userReducer.hasFreeTrialValid
    })
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(updateCouponFailure());
      toast.error(`Ocorreu um erro ao atualizar o cupom. Tente novamente!`, {
        autoClose: 6000,
      });
    }
  }
}

export function* updateCouponStatusRequest({ payload }) {
  try {
    const user = yield select(userData);
    const userReducer = yield select(userReducerData);

    yield call(
      apiAnunciante.put,
      `/user-info/${user.advertiserInfo.id}/coupons/${payload.couponMode}/${payload.couponId}/status`,
      {
        is_active: payload.newStatus,
      }
    );

    yield put(updateCouponStatusSuccess());
    if (payload.couponMode === 'normal') {
      yield put(getCouponsTraditionalRequestAction());
    } else if (payload.couponMode === 'flash') {
      yield put(getCouponsFlashRequestAction());
    } else if (payload.couponMode === 'code') {
      yield put(getCouponsCodeRequestAction());
    }
    toast.success('Status do cupom atualizado com sucesso!');

    //MixPanel Event
    sendCouponStatus({
      id: user.advertiserInfo.id,
      name: user.representative_name,
      email: user.email,
      couponId: payload.couponId,
      couponStatus: payload.newStatus,
      hasFreeTrialValid: userReducer.hasFreeTrialValid
    })
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(updateCouponStatusFailure());
      toast.error(
        `Ocorreu um erro ao atualizar o status do cupom. Tente novamente!`,
        {
          autoClose: 6000,
        }
      );
    }
  }
}

export function* deleteCouponRequest({ payload }) {
  try {
    const user = yield select(userData);

    yield call(
      apiAnunciante.put,
      `/user-info/${user.advertiserInfo.id}/coupons/${payload.couponMode}/${payload.couponId}/delete`
    );

    yield put(deleteCouponSuccess());
    if (payload.couponMode === 'normal') {
      yield put(getCouponsTraditionalRequestAction());
    } else if (payload.couponMode === 'flash') {
      yield put(getCouponsFlashRequestAction());
    } else if (payload.couponMode === 'code') {
      yield put(getCouponsCodeRequestAction());
    }
    toast.success('Cupom excluído com sucesso!');
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(deleteCouponFailure());
      toast.error(`Ocorreu um erro ao excluir o cupom. Tente novamente!`, {
        autoClose: 6000,
      });
    }
  }
}

export function* updateAvailableCouponsRequest({ payload }) {
  try {
    const user = yield select(userData);

    yield call(
      apiAnunciante.put,
      `/user-info/${user.advertiserInfo.id}/coupons/${payload.couponMode}/${payload.couponId}/available-coupons/update`,
      {
        available_coupons: payload.availableCoupons
      }
    );

    yield put(updateAvailableCouponsSuccess());
    yield put(getCouponsCodeRequestAction());
    toast.success('Quantidade de cupons disponível atualizada com sucesso!');
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(updateAvailableCouponsFailure());
      toast.error(`Ocorreu um erro ao atualizar a quantidade de cupons disponíveis. Tente novamente!`, {
        autoClose: 6000,
      });
    }
  }
}

export default all([
  takeLatest(
    '@coupons/GET_COUPONS_TRADITIONAL_REQUEST',
    getTraditionalCouponsRequest
  ),
  takeLatest('@coupons/GET_COUPONS_FLASH_REQUEST', getFlashCouponsRequest),
  takeLatest(
    '@coupons/GET_COUPONS_CODE_REQUEST',
    getCodeCouponsRequest
  ),
  takeLatest('@coupons/GET_PRODUCTS_WITHOUT_COUPONS_REQUEST', getProductsWithoutCouponsRequest),
  takeLatest('@coupons/NEW_TRADITIONAL_COUPON_REQUEST', newTraditionalCouponRequest),
  takeLatest('@coupons/NEW_FLASH_COUPON_REQUEST', newFlashCouponRequest),
  takeLatest('@coupons/NEW_CODE_COUPON_REQUEST', newCodeCouponRequest),
  takeLatest('@coupons/UPDATE_TRADITIONAL_COUPON_REQUEST', updateTraditionalCouponRequest),
  takeLatest('@coupons/UPDATE_FLASH_COUPON_REQUEST', updateFlashCouponRequest),
  takeLatest('@coupons/UPDATE_CODE_COUPON_REQUEST', updateCodeCouponRequest),  
  takeLatest(
    '@coupons/UPDATE_COUPON_STATUS_REQUEST',
    updateCouponStatusRequest
  ),
  takeLatest('@coupons/DELETE_COUPON_REQUEST', deleteCouponRequest),
  takeLatest('@coupons/UPDATE_AVAILABLE_COUPONS_REQUEST', updateAvailableCouponsRequest),
]);
