import React from 'react';

import { Row } from '../../styles/grid';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import px2vw from '../../utils/responsiveness/px2vw';

import { ContentBox, Text, TextContent } from './style';
import { preto, preto2 } from '../../styles/colors';

function Accordion(props) {
  const rotateValue = props.selected ? '180deg' : 0;
  const styleClass = { transform: `rotate(${rotateValue})` };

  return (
    <ContentBox
      className="pl-4 pr-5 mb-5"
      width="100%"
      column
      radius={px2vw(6)}
      padding={px2vw(6)}
      onClick={props.onClick}
    >
      <Row>
        <Text id="1" size={px2vw(15)} color={preto2} justifyStart>
          {props.title}
        </Text>
        <ExpandMoreIcon
          fontSize="medium"
          style={styleClass}
          className="ml-auto"
        />
      </Row>
      <Row justifyStart>
        {props.selected && (
          <TextContent
            margtop={px2vw(10)}
            size={px2vw(15)}
            color={preto}
            className="pb-3"
            justifyStart
            regular
          >
            {props.children}
          </TextContent>
        )}
      </Row>
    </ContentBox>
  );
}

export default Accordion;
