import React, { useState, useEffect, useCallback } from 'react';

import {
  CategoryModal,
  ModalTitleText,
  ReorderComplementSubtitle,
  ReorderComplementsBox,
  ReorderComplementBox,
  ReorderComplementName,
  ReorderComplementHeaderBox,
  ReorderComplementHeaderName,
  ButtonImage,
} from '../styles';

import Modal from '../../../components/Modal';
import {
  ModalHeader,
  CloseButton,
  CloseImage,
  ModalPrimaryButton,
  ModalPrimaryButtonText,
  ModalActions,
  ModalSecundaryButton,
  ModalSecundaryButtonText,
} from '../../../components/Modal/styles';

import {
  branco,
  cinzaKuppi2,
} from '../../../styles/colors';

import { Row, Col } from '../../../styles/grid';

import px2vw from '../../../utils/responsiveness/px2vw';

import DragAndDrop from '../../../utils/dragAndDrop/DragAndDrop';

export default function CatalogReorderCategoryComplementModal({ handler }) {
  const [
    complementsCategoryOrdenationNewIndexChanges,
    setComplementsCategoryOrdenationNewIndexChanges,
  ] = useState([]);
  const [
    complementsOrdenationNewIndexChanges,
    setComplementsOrdenationNewIndexChanges,
  ] = useState([]);

  // Set complement category ordenation changed after Drag And Drop
  useEffect(() => {
    if (complementsCategoryOrdenationNewIndexChanges.length > 0) {
      const currentComplementArray = [...handler.reorderComplementsCategoryArray];

      handler.reorderComplementsCategoryArray.forEach(
        (complementCategory, complementCategoryIdx) => {
          complementsCategoryOrdenationNewIndexChanges.forEach(
            (newComplementCategory, newComplementCategoryIdx) => {
              if (
                parseInt(newComplementCategory, 10) ===
                parseInt(complementCategoryIdx, 10)
              ) {
                currentComplementArray[complementCategoryIdx].ordenation_idx =
                  parseInt(newComplementCategoryIdx, 10) + 1;
              }
            }
          );
        }
      );

      handler.setReorderComplementsCategoryArray([...currentComplementArray]);
      setComplementsCategoryOrdenationNewIndexChanges([]);
    }
  }, [complementsCategoryOrdenationNewIndexChanges]);

  // Set complement ordenation changed after Drag And Drop
  useEffect(() => {
    if (complementsOrdenationNewIndexChanges.length > 0) {
      const currentComplementArray = [...handler.reorderComplementsCategoryArray];

      handler.reorderComplementsCategoryArray[
        handler.reorderCategoryComplementIdx
      ].complements.forEach((complement, complementIdx) => {
        complementsOrdenationNewIndexChanges.forEach(
          (newComplement, newComplementIdx) => {
            if (parseInt(newComplement, 10) === parseInt(complementIdx, 10)) {
              currentComplementArray[handler.reorderCategoryComplementIdx].complements[
                complementIdx
              ].ordenation_idx = parseInt(newComplementIdx, 10) + 1;
            }
          }
        );
      });

      handler.setReorderComplementsCategoryArray([...currentComplementArray]);
      setComplementsOrdenationNewIndexChanges([]);
    }
  }, [complementsOrdenationNewIndexChanges]);

  // Receive return after complement category Drag And Drop
  function handleComplementCategoryDragAndDrop(complementCategoryBox) {
    setComplementsCategoryOrdenationNewIndexChanges(complementCategoryBox);
  }

  // Receive return after complement Drag And Drop
  function handleComplementDragAndDrop(complementBox) {
    setComplementsOrdenationNewIndexChanges(complementBox);
  }

  // Call complement category Drag And Drop handler
  const currentCategoryDragAndDrop = useCallback(() => {
    DragAndDrop(
      { id: '#complementCategory', classNameBox: '.reorderCategoryBox' },
      handleComplementCategoryDragAndDrop
    );
  }, []);

  // Call complement Drag And Drop handler
  const currentComplementDragAndDrop = useCallback(() => {
    DragAndDrop(
      {
        id: '#complement',
        classNameBox: '.reorderComplementBox',
      },
      handleComplementDragAndDrop
    );
  }, []);

  // Handle complement category Drag And Drop
  useEffect(() => {
    currentCategoryDragAndDrop();
  }, [handler.reorderCategoryComplementModal]);

  // Handle complement Drag And Drop
  useEffect(() => {
    currentComplementDragAndDrop();
  }, [handler.reorderCategoryComplementIdx]);

  const handleCloseReorderCategoryComplementModal = () => {
    handler.setReorderCategoryComplementIdx(null);
    handler.setReorderCategoryComplementModal(false);
  }

  // Accept complements categories ordenation changes
  function handleReorderComplements() {
    // Sort the complements categories array using the new ordenation index value
    handler.reorderComplementsCategoryArray.sort(function sort(a, b) {
      if (a.ordenation_idx - b.ordenation_idx === 0) {
        // Changed the return for Chrome, to avoid error when order the array
        return navigator.userAgent.indexOf('Chrome') !== -1 ? -1 : 1;
      }
      return a.ordenation_idx - b.ordenation_idx;
    });

    // Sort the complements array using the new ordenation index value
    handler.reorderComplementsCategoryArray.forEach((complementCategory) => {
      complementCategory.complements.sort(function sort(a, b) {
        if (a.ordenation_idx - b.ordenation_idx === 0) {
          // Changed the return for Chrome, to avoid error when order the array
          return navigator.userAgent.indexOf('Chrome') !== -1 ? -1 : 1;
        }
        return a.ordenation_idx - b.ordenation_idx;
      });
    });

    // Update the category complements array
    handler.setComplementsArray([...handler.reorderComplementsCategoryArray]);

    // Close reorder complements modal
    handler.handleCloseReorderCategoryComplementModal();
  }

  return (
    <Modal
      isVisible={handler.reorderCategoryComplementModal}
      width={px2vw(700)}
      height={px2vw(580)}
      padding={px2vw(25)}
    >
      <CategoryModal>
        <ModalHeader>
          <ModalTitleText>Reordenar Complementos</ModalTitleText>
          <CloseButton
            onClick={handleCloseReorderCategoryComplementModal}
          >
            <CloseImage
              src={require('../../../assets/images/close/close.png')}
            />
          </CloseButton>
        </ModalHeader>
        <ReorderComplementSubtitle>
          Para alterar a ordem dos complementos ou das categorias de
          complementos: clique, arraste e solte o item na nova posição.
        </ReorderComplementSubtitle>
        <Row alignStart margleft={px2vw(-9)} height="auto">
          <ReorderComplementHeaderBox>
            <ReorderComplementHeaderName>
              Categorias do complementos
            </ReorderComplementHeaderName>
          </ReorderComplementHeaderBox>
          <ReorderComplementHeaderBox>
            <ReorderComplementHeaderName>
              Complementos
            </ReorderComplementHeaderName>
          </ReorderComplementHeaderBox>
        </Row>
        <ReorderComplementsBox>
          <Row width={px2vw(640)}>
            <Col
              justifyStart
              id="complementCategory"
              className="dropzoneComplementCategory"
              padtop={px2vw(10)}
            >
              {handler.reorderComplementsCategoryArray.map((category, idx) => (
                <ReorderComplementBox
                  draggable="true"
                  className={`reorderCategoryBox reorderCategoryBox${idx}`}
                  id={idx}
                  onClick={() => {
                    // Unmark all of others category complements box
                    const elements = document.getElementsByClassName(
                      'reorderCategoryBox'
                    );
                    for (let i = 0; i < elements.length; i++) {
                      elements[i].style.backgroundColor = branco;
                    }

                    // Mark the selected one
                    document.querySelector(
                      `.dropzoneComplementCategory .reorderCategoryBox${idx}`
                    ).style.backgroundColor = cinzaKuppi2;

                    handler.setReorderCategoryComplementIdx(idx);
                  }}
                >
                  <Row alignStart justifyStart>
                    <ReorderComplementName
                      title={
                        category.name.length >= 33 ? category.name : null
                      }
                    >
                      {category.name.length >= 33
                        ? `${category.name.substring(0, 29)}...`
                        : category.name}
                    </ReorderComplementName>
                    <Row alignCenter justifyEnd width={px2vw(20)}>
                      <ButtonImage
                        src={require('../../../assets/images/reorder/reorder.png')}
                      />
                    </Row>
                  </Row>
                </ReorderComplementBox>
              ))}
            </Col>
            <Col
              justifyStart
              id="complement"
              className="dropzoneComplement"
              padtop={px2vw(10)}
            >
              {handler.reorderCategoryComplementIdx !== null &&
                handler.reorderComplementsCategoryArray[
                  handler.reorderCategoryComplementIdx
                ].complements.map((complement, idxComp) => (
                  <ReorderComplementBox
                    className={`reorderComplementBox reorderComplementBox${idxComp}`}
                    id={idxComp}
                    isComplement
                    draggable="true"
                  >
                    <Row alignStart justifyStart>
                      <ReorderComplementName
                        title={
                          complement.name.length >= 33
                            ? complement.name
                            : null
                        }
                      >
                        {complement.name.length >= 33
                          ? `${complement.name.substring(0, 29)}...`
                          : complement.name}
                      </ReorderComplementName>
                      <Row alignCenter justifyEnd width={px2vw(20)}>
                        <ButtonImage
                          src={require('../../../assets/images/reorder/reorder.png')}
                        />
                      </Row>
                    </Row>
                  </ReorderComplementBox>
                ))}
            </Col>
          </Row>
        </ReorderComplementsBox>
        <ModalActions>
          <ModalSecundaryButton
            margleft={px2vw(80)}
            marginRight={px2vw(20)}
            margtop={px2vw(30)}
            width={px2vw(220)}
            onClick={handleCloseReorderCategoryComplementModal}
          >
            <ModalSecundaryButtonText>Cancelar</ModalSecundaryButtonText>
          </ModalSecundaryButton>
          <ModalPrimaryButton
            margleft={px2vw(40)}
            margtop={px2vw(30)}
            width={px2vw(220)}
            onClick={handleReorderComplements}
          >
            <ModalPrimaryButtonText>Salvar</ModalPrimaryButtonText>
          </ModalPrimaryButton>
        </ModalActions>
      </CategoryModal>
    </Modal>
  );
}
