import React from "react";
import { Link } from "react-router-dom";

import {
  Image,
  NormalText
} from '../../BaseScreen/styles';
import { Row, Col } from '../../../../styles/grid';
import { branco, preto, verdeKuppi1 } from '../../../../styles/colors';
import { TabSelected } from "../styles";

import px2vw from '../../../../utils/responsiveness/px2vw';

import Modal from "../../../../components/Modal";
import ButtonPrimary from "../../../../components/ButtonPrimary";

import InstagramConfigs from "./InstagramConfigs";
import WhatsappConfigs from "./WhatsappConfigs";

export default function StorePublicity({ handler }) {
  function HeaderTabs() {
    const tabs = ['tabInstagram'/*, 'tabWhatsapp'*/]

    return (
      <Row
        justifyStart
        overflowXY
        margtop={px2vw(60)}
      >
        {tabs.map((tabName) => {
          return (
            <Col
              width={px2vw(300)}
              margleft={px2vw(100)}
              onClick={() => handler.setCurrTabSelected(tabName)}
              cursor="pointer"
            >
              <NormalText
                fontweight='bold'
                color={preto}
                margbottom={px2vw(10)}
                fontsize={px2vw(60)}
              >
                {tabName === 'tabInstagram' ? 'Instagram' : 'Whatsapp'}
              </NormalText>
              <TabSelected
                isVisible={tabName === handler.currTabSelected}
                width="100%"
                height={px2vw(10)}
              />
            </Col>
          )
        })}
      </Row>
    )
  }

  return (
    <>
      <HeaderTabs />
      {handler.currTabSelected === 'tabInstagram' && <InstagramConfigs handler={handler} />}
      {handler.currTabSelected === 'tabWhatsapp' && <WhatsappConfigs handler={handler} />}
      <Modal
        isVisible={handler.openModal}
        backgroundImg
        backgroundImgSize={px2vw(800)}
        maxheight={px2vw(2092)}
      >
        <Col
          margbottom={px2vw(30)}
        >
          <Col
            alignCenter
            width='100%'
            borderRadius={px2vw(150)}
          >
            <Row
              margtop={px2vw(150)}
              margbottom={px2vw(30)}
            >
              <NormalText
                fontsize={px2vw(70)}
                fontweight="bold"
              >
                Parabéns, seu cardápio digital está pronto. Falta pouco pro seu delivery rodar no automático!
              </NormalText>
            </Row>
            <Col
              alignCenter
              margtop={px2vw(50)}
              margbottom={px2vw(100)}
              padleft={px2vw(100)}
              padright={px2vw(100)}
            >
              <Row margbottom={px2vw(50)}>
                <ButtonPrimary
                  background={verdeKuppi1}
                  height={140}
                  minWidth={1000}
                  fontSize={60}
                  borderRadius={20}
                  borderColor={verdeKuppi1}
                  color={branco}
                  width='100%'
                  type="button"
                  onClick={handler.accessPlatform}
                >
                  Acessar a plataforma
                </ButtonPrimary>
              </Row>
              <Row 
                margbottom={px2vw(50)} 
                onClick={() => handler.sendMixPanelFinishedOnboardingEvent('finished-AccessWebMenu')}
              > 
                <Link
                  to={{ pathname: handler.webMenuDomain }}
                  target="_blank"
                  style={{
                    textDecoration: "none",
                    color: preto
                  }}
                >
                  <ButtonPrimary
                    margbottom={px2vw(50)}
                    background={branco}
                    height={140}
                    minWidth={1000}
                    fontSize={60}
                    borderRadius={20}
                    borderColor={preto}
                    color={preto}
                    width='100%'
                    type='button'
                  >
                    Abrir Cardápio
                  </ButtonPrimary>
                </Link>
              </Row>
            </Col>
            <Image
              src={require("../../../../assets/images/onboarding/store_publicity/modalImage@2x.png")}
              width={px2vw(1000)}
              height={px2vw(1000)}
            />
          </Col>
        </Col>
      </Modal>
    </>
  )
}
