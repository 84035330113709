import { toast } from 'react-toastify';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { apiAnunciante, apiGlobal } from '../../../services/api';
import {
  blacklistRequestFailure,
  blacklistRequestSuccess,
  createCampaignFailure,
  createCampaignSuccess,
  setCampaigns,
  setCurrentPayment,
  setMassMessageData,
  setRemoveFromBlackList,
  setInsertBlackList,
  setSelectedCampaign,
  cancelMassMessagePaymentSuccess,
  cancelMassMessagePaymentFailure,
  setIsEditing,
  updateMassMessageCampaignSuccess,
  updateMassMessageCampaignFailure,
  updateMassMessageCampaignClear,
  cancelMassMessageCampaignClear,
  cancelMassMessageCampaignSuccess,
} from './actions';
import { logout } from '../auth/actions';

const userData = (state) => state.user.user;
const massMessage = (state) => state.massMessage;

export function* getMassMessageData() {
  try {
    const user = yield select(userData);

    const response = yield call(
      apiAnunciante.get,
      `/user-info/${user.advertiserInfo.id}/mass-message`
    );

    yield put(setMassMessageData(response.data));
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      toast.error('Erro ao buscar os dados da mensagem em massa');
    }
  }
}

export function* updateBlackList() {
  try {
    const user = yield select(userData);
    const data = yield select(massMessage);

    const arrContactsId =
      data.blacklistConfig.deleteType === 'individual'
        ? [data.blacklistConfig.contact]
        : data.blacklistConfig.contact;

    const request = [...(arrContactsId || [])?.map((c) => c.id)];
    
    if (request.length === 0) {
      return
    }

    yield call(
      apiAnunciante.put,
      `/user-info/${user.advertiserInfo.id}/mass-message/blacklist`,
      { blacklist: request }
    );

    yield put(
      setInsertBlackList([
        ...data.contactsBlacklist,
        ...data.sendClientList.filter((c) => request.includes(c.id)),
      ])
    );
    yield put(blacklistRequestSuccess());
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(blacklistRequestFailure());
      toast.error('Erro ao adicionar cliente na blacklist, tente novamente');
    }
  }
}

export function* removeFromBlackList({ payload }) {
  try {
    const user = yield select(userData);

    yield call(
      apiAnunciante.delete,
      `/user-info/${user.advertiserInfo.id}/mass-message/blacklist/${payload.client_id}`
    );

    yield put(setRemoveFromBlackList(payload.client_id));
    yield put(blacklistRequestSuccess());
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(blacklistRequestFailure());
      toast.error('Erro ao remover cliente na blacklist, tente novamente');
    }
  }
}

export function* createCampaign() {
  const user = yield select(userData);
  const data = yield select(massMessage);

  try {
    const requestData = {
      coupon_id: data?.selectedCoupon?.id,
      schedule_at: data.selected_schedule,
      message: data.message,
      processor: {
        name: user.advertiserInfo.name,
        email: user.email,
        document_number: user.advertiserInfo.cpf_cnpj,
        phone: user.advertiserInfo.phone_whatsapp_representative
          ? user.advertiserInfo.phone_whatsapp_representative
          : user.address[0].phone_1,
        address: {
          street: user.address[0].street,
          street_number: user.address[0].number || 'S/N',
          neighborhood: user.address[0].neighborhood,
          city: user.address[0].city,
          state: user.address[0].state,
          zipcode: user.address[0].zipcode,
        },
      },
      is_using_bonus: data.isUsingBonus,
      send_catalog_link: data.sendCatalogLink,
      send_list: data.sendClientList,
      image: data.image,
      show_unsubscribe_message: data.showUnsubscribeMessage,
    };

    const response = yield call(
      apiGlobal.post,
      `/user-info/${user.advertiserInfo.id}/mass-message`,
      requestData
    );

    const { advertiserMassMessageCampaigns, currPayment } = response.data;

    yield put(setCampaigns(advertiserMassMessageCampaigns));
    yield put(setCurrentPayment(currPayment));

    yield put(createCampaignSuccess());
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(createCampaignFailure());
      toast.error('Erro ao criar a campanha, tente novamente!');
    }
  }
}

export function* getCampaignDetails({ payload }) {
  try {
    const user = yield select(userData);

    const response = yield call(
      apiAnunciante.get,
      `/user-info/${user.advertiserInfo.id}/mass-message/${payload}`
    );

    yield put(setSelectedCampaign(response.data));
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      toast.error(
        'Erro ao buscar os detalhes da campanha de mensagem em massa'
      );
    }
  }
}

export function* cancelMassMessagePaymentRequest({ payload }) {
  try {
    const user = yield select(userData);

    const response = yield call(
      apiAnunciante.put,
      `/user-info/${user.advertiserInfo.id}/mass-message/${payload}/cancel-payment`
    );

    yield put(cancelMassMessagePaymentSuccess(response.data));
    toast.success('Pagamento cancelado com sucesso!');
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(cancelMassMessagePaymentFailure());
      toast.error(
        'Erro ao cancelar o pagamento da campanha de disparo em massa'
      );
    }
  }
}

export function* updateCampaign({ payload }) {
  const user = yield select(userData);
  const data = yield select(massMessage);

  try {
    yield call(
      apiGlobal.put,
      `/user-info/${user.advertiserInfo.id}/mass-message/${data?.selectedCampaign?.campaignDetails?.id}/edit`,
      {
        message: payload.message,
        image: payload.image,
      }
    );

    yield put(updateMassMessageCampaignSuccess())
    toast.success('Campanha editada com sucesso')
  } catch (err) {
    yield put(updateMassMessageCampaignFailure())
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      toast.error('Erro ao atualizar a campanha de disparo em massa');
    }
  }
}

export function* cancelMassMessageCampaign({ payload }) {
  const user = yield select(userData);

  try {
    yield call(
      apiGlobal.delete,
      `/user-info/${user.advertiserInfo.id}/mass-message/${payload}/cancel`
    );
    yield put(cancelMassMessageCampaignSuccess())
    toast.success('Campanha cancelada com sucesso')
  } catch (err) {
    yield put(cancelMassMessageCampaignClear())
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      toast.error('Erro ao cancelar a campanha de disparo em massa');
    }
  }
}

export default all([
  takeLatest('@massMessage/GET_MASS_MESSAGE_DATA', getMassMessageData),
  takeLatest('@massMessage/CREATE_CAMPAIGN_REQUEST', createCampaign),
  takeLatest('@massMessage/UPDATE_BLACK_LIST_REQUEST', updateBlackList),
  takeLatest('@massMessage/GET_CAMPAIGN_DETAILS', getCampaignDetails),
  takeLatest(
    '@massMessage/REMOVE_FROM_BLACK_LIST_REQUEST',
    removeFromBlackList
  ),
  takeLatest(
    '@massMessage/CANCEL_MASS_MESSAGE_PAYMENT_REQUEST',
    cancelMassMessagePaymentRequest
  ),
  takeLatest(
    '@massMessage/UPDATE_MASS_MESSAGE_CAMPAIGN_REQUEST',
    updateCampaign
  ),
  takeLatest(
    '@massMessage/CANCEL_MASS_MESSAGE_CAMPAIGN_REQUEST',
    cancelMassMessageCampaign
  ),
]);
