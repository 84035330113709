import addHours from 'date-fns/addHours';
import addMinutes from 'date-fns/addMinutes';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import format from 'date-fns/format';
import isToday from 'date-fns/isToday';
import setMinutes from 'date-fns/setMinutes';

import { formatDate } from './formatDate';

export const makeId = (timeTo, timeFrom, type) => {
  const timeToFormatted = formatDate(timeTo, "yyyy-MM-dd'T'HH:mm");
  const timeFromFormatted = timeFrom
    ? formatDate(timeFrom, "yyyy-MM-dd'T'HH:mm")
    : '';
  const id = `${timeToFormatted}-${timeFromFormatted}@${type}`;
  return id;
};

export const makeSchedulesForHoraries = ({
  date,
  lastHorary,
  minSchedulingTime,
  isAcceptScheduled,
  isAcceptImmediate,
  intervalBetweenSchedulesInMinutes,
}) => {
  const { startAt, endAt } = getStartAndEndDatesForHorary({
    baseDate: date,
    horary: lastHorary,
    minSchedulingTimeInMinutes: minSchedulingTime,
  });
  // Has at least 1 minute passed? Skip one horary
  let baseStartAt =
    startAt.getMinutes() >= 1 ? setMinutes(startAt, 0) : startAt;
  if (intervalBetweenSchedulesInMinutes === 30) {
    baseStartAt =
      startAt.getMinutes() > 30
        ? setMinutes(addMinutes(startAt, intervalBetweenSchedulesInMinutes), 0)
        : startAt;
  }
  const hoursBetweenNowAndClose = endAt.getHours() - baseStartAt.getHours();

  let maxSchedulesOfToday = Math.floor(
    (hoursBetweenNowAndClose * 60) / intervalBetweenSchedulesInMinutes
  );

  if (isAcceptImmediate) {
    maxSchedulesOfToday += 1;
  }

  const now = new Date();
  if (isAcceptScheduled && maxSchedulesOfToday >= 1) {
    const schedules = Array.from(Array(maxSchedulesOfToday).keys()).map(
      (idx) => {
        if (idx === 0 && isToday(baseStartAt) && isAcceptImmediate) {
          const timeFrom =
            now.getMinutes() > 30
              ? setMinutes(addHours(now, 1), 0)
              : setMinutes(now, 0);
          return {
            id: makeId(timeFrom, null, 'immediate'),
            timeFrom,
            timeTo: null,
            type: 'immediate',
          };
        }
        const offset =
          isToday(baseStartAt) && isAcceptImmediate ? idx - 1 : idx;
        const timeFrom = addMinutes(
          baseStartAt,
          offset * intervalBetweenSchedulesInMinutes
        );
        const timeTo = addMinutes(timeFrom, intervalBetweenSchedulesInMinutes);

        return {
          id: makeId(timeFrom, timeTo, 'scheduled'),
          timeFrom,
          timeTo,
          type: 'scheduled',
        };
      }
    );

    const lastSchedule = schedules[schedules.length - 1];

    if (
      lastSchedule.type === 'scheduled' &&
      differenceInMinutes(lastSchedule.timeTo, endAt) > 0
    ) {
      schedules.pop();
    }
    return schedules;
  } else if (isAcceptImmediate && !isAcceptScheduled && isToday(baseStartAt)) {
    baseStartAt = new Date();
    return [
      {
        id: makeId(baseStartAt, null, 'immediate'),
        timeFrom: baseStartAt,
        timeTo: null,
        type: 'immediate',
      },
    ];
  } else if (
    isAcceptImmediate &&
    maxSchedulesOfToday < 1 &&
    isToday(baseStartAt)
  ) {
    baseStartAt = new Date();
    return [
      {
        id: makeId(baseStartAt, null, 'immediate'),
        timeFrom: baseStartAt,
        timeTo: null,
        type: 'immediate',
      },
    ];
  } else {
    return [];
  }
};

const buildDateFromHorary = (horary, baseDate) =>
  horary.match(/\d{2}:\d{2}/)
    ? addHours(new Date(`${format(baseDate, 'yyyy-MM-dd')}T${horary}:00Z`), 3)
    : null;

export const getStartAndEndDatesForHorary = ({
  baseDate,
  horary,
  minSchedulingTimeInMinutes,
}) => {
  const now = new Date();

  let startAt = buildDateFromHorary(horary.start_at, baseDate);
  let endAt = buildDateFromHorary(horary.end_at, baseDate);
  let nowWithMinSchedulingTime = addMinutes(now, minSchedulingTimeInMinutes);

  // Date with min scheduling time is not today and startAt is before
  if (startAt < nowWithMinSchedulingTime) {
    // Start at and now with scheduling are same day
    if (startAt.toISOString().substring(0, 10) === nowWithMinSchedulingTime.toISOString().substring(0, 10)) {
      return {
        startAt: nowWithMinSchedulingTime,
        endAt,
      };
    }
    // Return no scheduling for this day
    return {
      startAt,
      endAt: startAt,
    };
  }

  return {
    startAt,
    endAt,
  };
};
