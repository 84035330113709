import React from 'react';
import { useDispatch } from 'react-redux';
import { Spinner } from 'reactstrap';

import { 
  ModalActions, 
  ModalPrimaryButton, 
  ModalPrimaryButtonText, 
  ModalSecundaryButton, 
  ModalSecundaryButtonText 
} from '../../../../../components/Modal/styles';
import { LoadingBox } from '../../../../../styles/common';
import { preto } from '../../../../../styles/colors';

import Modal from '../../../../../components/Modal';

import px2vw from '../../../../../utils/responsiveness/px2vw';
import { ConfirmDeleteCoupomContent, ConfirmDeleteCoupomContentText } from '../../../../../components/CuponTable/styles';
import { deleteCouponRequest } from '../../../../../store/modules/coupons/actions';

export default function ConfirmDeleteCouponModal({ handler }) {
  const dispatch = useDispatch();

  return (
    <Modal
      isVisible
      width={px2vw(390)}
      height={px2vw(230)}
    >
      <ConfirmDeleteCoupomContent>
        <ConfirmDeleteCoupomContentText>
          Tem certeza que deseja excluir esse cupom?
        </ConfirmDeleteCoupomContentText>
        <ModalActions>
          <ModalSecundaryButton
            marginRight={px2vw(20)}
            margtop={px2vw(45)}
            width={px2vw(100)}
            type="button"
            onClick={() => handler.handleCloseDeleteCoupon()}
            disabled={handler.deleteCouponModalLoading}
          >
            <ModalSecundaryButtonText>Não</ModalSecundaryButtonText>
          </ModalSecundaryButton>
          <ModalPrimaryButton
            margleft={px2vw(20)}
            margtop={px2vw(45)}
            width={px2vw(100)}
            type="button"
            onClick={() => dispatch(deleteCouponRequest(handler.actionCoupon.id, handler.actionCoupon.mode))}
            disabled={handler.deleteCouponModalLoading}
          >
            {handler.deleteCouponModalLoading ? (
              <LoadingBox>
                <Spinner
                  style={{
                    width: '1.8rem',
                    height: '1.8rem',
                    color: preto,
                  }}
                />{' '}
              </LoadingBox>
            ) : (
              <ModalPrimaryButtonText>Sim</ModalPrimaryButtonText>
            )}
          </ModalPrimaryButton>
        </ModalActions>
      </ConfirmDeleteCoupomContent>
    </Modal>
  )
}
