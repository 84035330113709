import produce from 'immer';

const INITIAL_STATE = {
  loading: false,
  isDoneOnboarding: false,
  updateOnboardingStepLoading: false,
  updateOnboardingStepSuccess: false,
  onboardingData: {},
  currOnboardingStep: 'initial',
  onboardingStoreConfigsLoading: false, 
  onboardingStoreConfigsSuccess: false,
};

export default function onboarding(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@onboarding/UPDATE_ONBOARDING_STEP_REQUEST': {
        draft.updateOnboardingStepLoading = true;
        draft.updateOnboardingStepSuccess = false;
        break;
      }
      case '@onboarding/UPDATE_ONBOARDING_STEP_SUCCESS': {
        draft.onboardingData = action.payload.advertiserOnboardingSurvey;
        draft.currOnboardingStep = action.payload.advertiserOnboardingSurvey.onboarding_step;
        draft.updateOnboardingStepLoading = false;
        draft.updateOnboardingStepSuccess = true;
        break;
      }
      case '@onboarding/UPDATE_ONBOARDING_STEP_FAILURE': {
        draft.updateOnboardingStepLoading = false;
        draft.updateOnboardingStepSuccess = false;
        break;
      }
      case '@onboarding/UPDATE_ONBOARDING_STORE_CONFIGS_REQUEST': {
        draft.onboardingStoreConfigsLoading = true;
        draft.onboardingStoreConfigsSuccess = false;
        break;
      }
      case '@onboarding/UPDATE_ONBOARDING_STORE_CONFIGS_SUCCESS': {
        draft.onboardingStoreConfigsLoading = false;
        draft.onboardingStoreConfigsSuccess = true;
        break;
      }
      case '@onboarding/UPDATE_ONBOARDING_STORE_CONFIGS_FAILURE': {
        draft.onboardingStoreConfigsLoading = false;
        draft.onboardingStoreConfigsSuccess = false;
        break;
      }
      case '@auth/LOGIN_SUCCESS': {
        draft.onboardingData = action.payload.user.advertiserInfo.advertiserOnboardingSurvey;
        draft.currOnboardingStep = action.payload.user.advertiserInfo.advertiserOnboardingSurvey.onboarding_step;
        break;
      }
      case '@onboarding/CLEAR_SUCCESS': {
        draft.updateOnboardingStepSuccess = false;
        draft.onboardingStoreConfigsSuccess = false;
        break;
      }
      default:
        break;
      }
    }
  );
}
