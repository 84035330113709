import styled from 'styled-components';

import { IoPrintOutline } from 'react-icons/io5';

import px2vw from '../../../utils/responsiveness/px2vw';

import {
  branco,
  preto,
  verdeKuppi1,
  amareloKuppi2,
} from '../../../styles/colors';

export const ActiveTextYellow = styled.div`
  color: ${amareloKuppi2};
  font-size: ${(props) => props.fontSize || px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.36px;
`;

export const PrintBoletoButton = styled.button`
  width: ${(props) => props.width || px2vw(230)};
  height: ${(props) => props.height || px2vw(35)};
  border-radius: ${(props) => props.borderradius || px2vw(10)};
  color: ${preto}80;
  border: 2px solid ${preto}80;
  background-color: ${branco};
`;

export const PrintButtonBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const PrintIcon = styled(IoPrintOutline).attrs((props) => ({
  size: props.size || px2vw(20)
}))`
  margin-right: ${(props) => props.margright || px2vw(15)};
  color: ${preto}80;
`;

export const CopyBoletoCode = styled.div`
  font-size: ${(props) => props.fontSize || px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.36px;
  color: ${branco};
  width: ${(props) => props.width || px2vw(230)};
  height: ${(props) => props.height || px2vw(35)};
  border-radius: ${(props) => props.borderradius || px2vw(10)};
  border: none;
  background-color: ${verdeKuppi1};
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const CopyBoletoCodeTextBold = styled.span`
  font-size: ${(props) => props.fontSize || px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.36px;
`;

export const AlertPaymentImage = styled.img`
  width: ${(props) => props.width || px2vw(60)};
  height: ${(props) => props.height || px2vw(62)};
  margin-right: ${(props) => props.margright || px2vw(20)};
  margin-top: ${(props) => props.margtop || px2vw(-13)};
`;

export const GoToPaymentLink = styled.div`
  font-size: ${(props) => props.fontSize || px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.36px;
  width: ${(props) => props.width || px2vw(230)};
  height: ${(props) => props.height || px2vw(35)};
  border-radius: ${(props) => props.borderradius || px2vw(10)};
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: ${preto}80;
  border: 2px solid ${preto}80;
  background-color: ${branco};

  ${(props) => props.expired && `
    color: ${branco};
    border: none;
    background-color: ${verdeKuppi1};
  `};
`;
