import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { toast } from 'react-toastify';
import DatePicker, { registerLocale } from 'react-datepicker'
import { Spinner } from 'reactstrap';
import { Form } from '@rocketseat/unform';
import { Icon } from 'react-materialize';
import ptBR from 'date-fns/locale/pt-BR';

import '../../../styles/react-datepicker.css';
import {
  preto,
} from '../../../styles/colors';
import { Col, Row } from '../../../styles/grid';
import {
  ContentPage,
  ErrorText,
  LoadingBox,
} from '../../_layouts/styles/common';
import { BordersCol, DateDiv, FilterButton, FilterButtonText, SelectInputBox, TextFormBold, TextFormRegular, TransferFormBox } from './styles';

import px2vw from '../../../utils/responsiveness/px2vw';

import { clearTransfersData, getTransfersDataRequest } from '../../../store/modules/financial/actions';

import TransferTable from './TransferTable';
import { ConfigGroupTitle } from '../styles';
import { formatCurrencyBRL } from '../../../utils/utils';

export default function Transfers() {
  const dispatch = useDispatch();
  const {
    transfersData,
    transfersLoading,
    transfersError,
  } = useSelector((state) => state.financial);
  registerLocale('pt-BR', ptBR);
  const [startDate, setStartDate] = useState(moment(new Date()).toDate());
  const [endDate, setEndDate] = useState(moment(new Date()).toDate());
  const [daysInterval, setDaysInterval] = useState('0');
  const [selectOptions] = useState([
    { id: 0, title: 'Selecione um período' },
    { id: 1, title: 'Últimos 30 dias' },
    { id: 2, title: 'Últimos 15 dias' },
    { id: 3, title: 'Últimos 7 dias' },
  ]);

  useEffect(() =>  {
    dispatch(clearTransfersData());
  }, []);

  // Sets the inputs of startDate and endDate according to the daysInterval input value
  useEffect(() => {
    let today = moment(new Date());
    if (daysInterval !== '0') {
      setEndDate(moment(today.subtract(1, 'days')).toDate());
    }

    if (daysInterval === '1') {
      setStartDate(moment(today.subtract(30, 'days')).toDate());
    } else if (daysInterval === '2') {
      setStartDate(moment(today.subtract(15, 'days')).toDate());
    } else if (daysInterval === '3') {
      setStartDate(moment(today.subtract(7, 'days')).toDate());
    }
  }, [daysInterval]);

  function handleSubmit() {
    if (startDate === null) {
      toast.error('Data de início é obrigatória.', {
        autoClose: 6000,
      });

      return false;
    }

    if (endDate === null) {
      toast.error('Data de fim é obrigatória.', {
        autoClose: 6000,
      });

      return false;
    }

    // Start Date - DDMMAAAA
    const startDateDay = startDate.getDate().toString();
    const startDateMonth = (startDate.getMonth() + 1).toString();
    const startDateYear = startDate.getFullYear();

    const newStartDate = new Date(
      `${startDateYear}-${startDateMonth}-${startDateDay}`
    );
    try {
      newStartDate.toISOString();
    } catch (e) {
      toast.error('Data de início inválida.', {
        autoClose: 6000,
      });

      return false;
    }

    // End Date - DDMMAAAA
    const endDateDay = endDate.getDate().toString();
    const endDateMonth = (endDate.getMonth() + 1).toString();
    const endDateYear = endDate.getFullYear();

    const newEndDate = new Date(`${endDateYear}-${endDateMonth}-${endDateDay}`);
    try {
      newEndDate.toISOString();
    } catch (e) {
      toast.error('Data de fim inválida.', {
        autoClose: 6000,
      });

      return false;
    }

    if (newEndDate < newStartDate) {
      toast.error('Data de fim não pode ser menor que a data de início.', {
        autoClose: 6000,
      });

      return false;
    }

    if (newEndDate > new Date()) {
      toast.error('Data de fim não pode ser maior que a data atual.', {
        autoClose: 6000,
      });

      return false;
    }

    const diffStartDates = moment(newStartDate)
    const diffEndDates = moment(newEndDate);
    if (diffEndDates.diff(diffStartDates, 'days') > 31) {
      toast.error('Intervalo de pesquisa não pode ser maior que 31 dias.', {
        autoClose: 6000,
      });

      return false;
    }

    dispatch(
      getTransfersDataRequest(
        `${startDateYear}-${startDateMonth}-${startDateDay}`,
        `${endDateYear}-${endDateMonth}-${endDateDay}`
      )
    );
  }

  const contentPage = (
    <>
      <TransferFormBox>
        <TextFormBold>Escolha o período que deseja visualizar</TextFormBold>
        <Form onSubmit={handleSubmit}>
          <Row justifyStart width="auto" margtop={px2vw(20)}>
            <Col alignStart width="auto" margright={px2vw(10)}>
              <SelectInputBox
                value={daysInterval}
                name="daysInterval"
                options={selectOptions}
                onChange={(t) => setDaysInterval(t.target.value)}
              />
            </Col>
            <Col width="auto" alignStart>
              <DateDiv>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={startDate}
                  onChange={(date) => {
                    setStartDate(date);
                    setDaysInterval('0');
                  }}
                  locale="pt-BR"
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  className="date"
                  onChangeRaw={(e) => {
                    e.preventDefault();
                  }}
                />
              </DateDiv>
            </Col>
            <Row
              width="auto"
              margtop={px2vw(4)}
              margleft={px2vw(8)}
              margright={px2vw(8)}
            >
              <TextFormRegular>até</TextFormRegular>
            </Row>
            <Col width="auto" alignStart>
              <DateDiv>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={endDate}
                  onChange={(date) => {
                    setEndDate(date);
                    setDaysInterval('0');
                  }}
                  locale="pt-BR"
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  className="date"
                  onChangeRaw={(e) => {
                    e.preventDefault();
                  }}
                />
              </DateDiv>
            </Col>
            <FilterButton type="submit" disabled={transfersLoading}>
              {transfersLoading ? (
                <LoadingBox>
                  <Spinner
                    style={{
                      width: '1.4rem',
                      height: '1.4rem',
                      color: preto,
                    }}
                  />
                </LoadingBox>
              ) : (
                <FilterButtonText>
                  Filtrar
                </FilterButtonText>
              )}
            </FilterButton>
          </Row>
        </Form>
      </TransferFormBox>
      {transfersData?.transfers?.length === 0 && (
        <Col alignCenter height="60vh">
          <Row height="auto" alignCenter justifyCenter>
            {/* TODO: Ajustar tamanho do icone */}
            <Icon large>sentiment_very_dissatisfied</Icon>
            <ErrorText>Não existem repasses para o período solicitado.</ErrorText>
          </Row>
        </Col>
      )}
      {transfersData?.transfers?.length > 0 && (
        <TransferFormBox>
          <Row width={px2vw(400)} height={px2vw(80)}>
             <BordersCol
              borderRadius={`${px2vw(5)} ${px2vw(5)} ${px2vw(5)} ${px2vw(5)} `}
              width="80%"
             >
              <ConfigGroupTitle isSmaller>
                Total de Repasses no período
              </ConfigGroupTitle>
              <ConfigGroupTitle isSmall>{formatCurrencyBRL(transfersData?.total_amount_pix.toFixed(2), {})}</ConfigGroupTitle>
             </BordersCol>
           </Row>
 
          <TransferTable
            data={transfersData?.transfers}
           />
        </TransferFormBox>
      )} 
    </>
  );

  const errorPage = (
    <ContentPage minheight={px2vw(450)}>
      <Col alignCenter height="60vh">
        <Row height="auto" alignCenter justifyCenter>
          {/* TODO: Ajustar tamanho do icone */}
          <Icon large>sentiment_very_dissatisfied</Icon>
          <ErrorText>
            Ocorreu um erro ao carregar os repasses do período. Tente novamente.
          </ErrorText>
        </Row>
      </Col>
    </ContentPage>
  );

  if (transfersError) {
    return errorPage;
  }
  return contentPage;
}
