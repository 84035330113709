import React from 'react';

import px2vw from '../../../../utils/responsiveness/px2vw';
import { Row } from '../../../../styles/grid';

import NewProgressBar from '../../../../components/ProgressBar/NewProgressBar';

import {
  Content,
  LogoImage,
  Img,
  OptionText,
  Box,
  GoBackButton,
} from '../styles';

import WelcomeScreen from '../../WelcomeScreen';
import PageOne from '../../PageOne';
import PageTwo from '../../PageTwo';
import PageThree from '../../PageThree';

export default function BaseScreenWeb({ handler }) {
  return (
    <>
      <Content>
        <Row alignCenter spaceBetween height={px2vw(60)} padleft={px2vw(40)} padright={px2vw(40)} >
          <LogoImage
            margtop={px2vw(12)}
            width={px2vw(155)}
            height={px2vw(90)}
            src={require('../../../../assets/images/logo_kuppi/logo_kuppi_wo_slogan.png')}
          />
          <Row 
            width="100%" 
            alignCenter 
            justifyStart
            margleft={px2vw(10)}
          >
            <OptionText
              fontsize={px2vw(15)}
            >
              A 1ª Gerente Virtual de Delivery
            </OptionText>
          </Row>
          <Box 
            cursor="pointer"
            onClick={handler.baseScreen.logoutButtonClick}
          >
            <OptionText>Sair</OptionText>
            <Img
              width={px2vw(25)}
              height={px2vw(20)}
              alt="Logout"
              src={require('../../../../assets/images/logout/logout@2x.png')}
            />
          </Box>

        </Row>
        <NewProgressBar count={handler.baseScreen.progress} />
        {handler.baseScreen.currSurveyPageStep === 'initial' && <WelcomeScreen isWeb={handler.isWeb} handler={handler.welcomeScreen} />}
        {handler.baseScreen.currSurveyPageStep === 'step1' && (
          <>
            <Row justifyStart>
              <GoBackButton
                margtop={px2vw(15)}
                padleft={px2vw(70)}
                onClick={handler.goBackButtonClick.questionOne}
              >
                {'< voltar'}
              </GoBackButton>
            </Row>
            <PageOne isWeb={handler.isWeb} />
          </>
        )}
        {handler.baseScreen.currSurveyPageStep === 'step2' && (
          <>
            <Row justifyStart>
              <GoBackButton
                margtop={px2vw(15)}
                padleft={px2vw(70)}
                onClick={handler.goBackButtonClick.questionTwo}
              >
                {'< voltar'}
              </GoBackButton>
            </Row>
            <PageTwo isWeb={handler.isWeb} />
          </>
        )}
        {handler.baseScreen.currSurveyPageStep === 'step3' && (
          <>
            <Row justifyStart>
              <GoBackButton
                margtop={px2vw(15)}
                padleft={px2vw(70)}
                onClick={handler.goBackButtonClick.questionThree}
              >
                {'< voltar'}
              </GoBackButton>
            </Row>
            <PageThree isWeb={handler.isWeb} />
          </>
        )}
      </Content>
    </>
  );
}