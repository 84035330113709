import styled, { css } from 'styled-components';

import {
  preto,
  branco,
  cinzaKuppi4,
  cinzaKuppi7,
  verdeKuppi2,
  cinzaKuppi10,
  cinzaKuppi11,
  cinzaKuppi3,
  cinzaKuppi5,
  cinzaKuppi2,
  cinzaKuppi8,
} from '../../../styles/colors';

import px2vw from '../../../utils/responsiveness/px2vw';

export const Container = styled.div`
  background: ${branco};
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  max-height: inherit;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 0.3em;
    border-radius: 3px;
  }
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${verdeKuppi2};
    outline: 1px solid ${verdeKuppi2};
  }
`;

export const ComplementItem = styled.div`
  width: 100%;
  display: flex;
  padding: ${px2vw(3)} ${px2vw(30)};
  border-bottom: 2px dashed ${cinzaKuppi4};
`;

export const ComplementMenu = styled.div`
  width: 100%;
`;

export const ComplementMenuHeader = styled.div`
  width: 100%;
  border-bottom: 2px dashed ${cinzaKuppi4};
  padding: ${px2vw(10)} ${px2vw(30)};
`;

export const ManualOrderButtonPrimary = styled.button`
  background: linear-gradient(
      231.72deg,
      #2ffe5f 7.51%,
      rgba(47, 254, 95, 0) 91.54%
    ),
    #2cfe89;
  border: none;
  color: ${preto};
  border-radius: ${px2vw(5)};
  font-size: ${px2vw(13)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.32px;
  padding: ${px2vw(8)};
  margin-left: ${(props) => (props.margleft ? props.margleft : 'auto')};
  width: ${px2vw(200)};
  &:hover {
    text-decoration: none;
    box-shadow: 2px 2px 3px ${cinzaKuppi2};
  }

  &:active {
    text-decoration: none;
    background: linear-gradient(
        5.72deg,
        #2ffe5f 15.51%,
        rgba(47, 254, 95, 0) 80.54%
      ),
      #2cfe89;
    box-shadow: none;
  }

  &:disabled {
    text-decoration: none;
    background: ${cinzaKuppi2};
    box-shadow: none;
  }
`;

export const ManualOrderButtonSecondary = styled.button`
  border: none;
  color: ${preto};
  border-radius: ${px2vw(5)};
  font-size: ${px2vw(13)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.32px;
  padding: ${px2vw(8)};
  margin-left: ${(props) => (props.margleft ? props.margleft : 'auto')};
  width: ${px2vw(230)};
  &:hover {
    text-decoration: none;
    box-shadow: 2px 2px 3px ${cinzaKuppi2};
  }

  &:disabled {
    text-decoration: none;
    background: ${cinzaKuppi2};
    box-shadow: none;
  }
`;


export const ModalFooter = styled.footer`
  display: flex;
  justify-content: center;
  border-radius: 20px 20px 0px 0px;
  box-shadow: -0.1px -4px 2px ${cinzaKuppi8};
  align-items: center;
  margin-top: auto;
  min-height: ${px2vw(95)};
  max-height: ${px2vw(95)};
  width: 100%;
  z-index: 5;
`;

export const Span = styled.span`
  margin-left: ${px2vw(8)};
  padding: ${(props) => (props.padding ? props.padding : '0')};
  font-size: ${(props) => (props.fontSize ? props.fontSize : px2vw(14))};
  font-family: AvenirNextLTPro;
  letter-spacing: 0.36px;
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 'bold')};
  display: ${(props) => (props.display ? props.display : 'block')};
  color: ${(props) => (props.color ? props.color : verdeKuppi2)};
  @media (max-width: 500px) {
    font-size: ${px2vw(20)};
  }
  .badge {
    background: ${cinzaKuppi10};
    font-size: ${px2vw(13)};
  }
`;

export const BtnControl = styled.span`
  width: ${px2vw(26)};
  border-radius: ${px2vw(4)};
  font-family: AvenirNextLTPro;
  text-align: center;
  background-color: ${cinzaKuppi2};
  font-size: ${(props) => (props.fontSize ? props.fontSize : px2vw(20))};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 'bold')};
  color: ${(props) => (props.color ? props.color : preto)};
  cursor: pointer;
  :hover {
    background-color: ${cinzaKuppi3};
  }
  :active {
    background-color: ${cinzaKuppi5};
  }
  @media (max-width: 500px) {
    font-size: ${px2vw(20)};
  }
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export const ModalHeader = styled.header`
  display: flex;
  justify-content: space-between;
  background-color: ${verdeKuppi2};
  align-items: center;
  width: 100%;
  letter-spacing: 0.36px;
`;

export const ManualOrderHeader = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${verdeKuppi2};
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
  border-color: ${verdeKuppi2};
  padding: ${px2vw(13)} ${px2vw(20)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  color: ${branco};
  font-size: ${px2vw(15.5)};
  display: flex;
  align-items: space-between;
  justify-content: space-between;
`;

export const ProgressBar = styled.div`
  background-color: ${cinzaKuppi11};
  width: 100%;
`;

export const CloseButton = styled.button`
  width: ${px2vw(18)};
  height: ${px2vw(18)};
  border: none;
  z-index: 10;
  background-color: transparent;

  :focus {
    background-color: transparent !important;
  }

  ${(props) =>
    props.absolute &&
    css`
      position: absolute;
      top: ${props.top || px2vw(10)};
      right: ${props.right || px2vw(10)};
    `}
`;

export const CloseImage = styled.img`
  width: ${px2vw(17)};
  height: ${px2vw(17)};
`;

export const BackIcon = styled.img`
  margin-left: ${px2vw(13)};
  background-color: ${cinzaKuppi4};
  border-radius: 2px;
  padding: ${px2vw(7)};
  cursor: pointer;
  :active {
    background-color: ${cinzaKuppi7};
  }
`;

export const BookIcon = styled.img`
  width: ${px2vw(29)};
  height: ${px2vw(26)};
  margin-right: ${px2vw(13)};
`;

export const StepTittle = styled.div`
  font-family: AvenirNextLTPro;
  font-weight: 700;
  color: ${preto};
  font-size: ${px2vw(17)};
  margin-right: ${px2vw(15.5)};
  white-space: nowrap;
  letter-spacing: 0.36px;
`;

export const ProductItem = styled.div`
  width: 100%;
  padding: ${px2vw(20)} ${px2vw(15)} ${px2vw(10)} ${px2vw(15)};
  display: flex;
  border-bottom: 1px solid ${cinzaKuppi4};
`;

export const ProductDescription = styled.span`
  font-family: AvenirNextLTPro;
  font-weight: 700;
  color: ${preto};
  margin-left: ${px2vw(14)};
  margin-right: auto;
  font-size: ${px2vw(13)};
  white-space: nowrap;
  letter-spacing: 0.36px;
  padding: 0;
`;

export const ProductPrice = styled.span`
  font-family: AvenirNextLTPro;
  font-weight: 700;
  margin-left: auto;
  color: ${verdeKuppi2};
  font-size: ${px2vw(14)};
  white-space: nowrap;
  letter-spacing: 0.36px;
`;
export const ProductImg = styled.img`
  width: ${px2vw(19)};
  height: ${px2vw(19)};
`;

export const ChatImg = styled.img`
  width: ${px2vw(20)};
  height: ${px2vw(20)};
`;

export const TxtArea = styled.textarea`
  width: 100%;
  border-radius: 2px;
  border: 1px solid ${cinzaKuppi3};
  resize: none;
  padding: ${px2vw(5)};
`;
