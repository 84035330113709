import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format } from '@flasd/whatsapp-formatting';

import { cinzaKuppi1 } from '../../../../styles/colors';
import { Text } from '../../../../styles/common';

import px2vw from '../../../../utils/responsiveness/px2vw';
import { ModalPrimaryButton } from '../styles';
import { Col, Row } from '../../../../styles/grid';
import { AiOutlineLeft } from 'react-icons/ai';
import { BsTrash } from 'react-icons/bs';

import {
  ModalContent,
  CloseButton,
  ModalHeader,
  CloseImage,
  ModalFooter,
  ConversationIndicatorImg,
  TextBox,
  PhoneImg,
  TextArea,
  UploadIcon,
  ModalSecundaryButtonText,
  ModalSecundaryButton,
  InfoBox,
  OverFlowBox,
  WhatsImg,
  Thumb,
  MessageContainer,
  CheckBox,
  HeaderInput,
} from './styles';
import { getURL } from '../../../../utils/utils';
import {
  setCurrentStep,
  setImage,
  setMessage as setGlobalMessage,
  setShowUnsubscribeMessage,
  setSendCatalogLink,
} from '../../../../store/modules/massMessage/actions';
import { MassMessagePreview } from '../Preview';

export default function Message({ ...props }) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const {
    selectedCoupon,
    showUnsubscribeMessage,
    sendCatalogLink,
    campaignType,
  } = useSelector((state) => state.massMessage);

  const couponProductFromValue = `*${selectedCoupon?.description}*: *${selectedCoupon?.display_name}*`;
  const couponProductPercentage = `*${selectedCoupon?.display_name}* no cardápio todo`;
  const defaultMessage = `Estamos com uma promoção bombástica hoje no *${user?.advertiserInfo?.name
    .toString()
    .trim()}* e vim te avisar de primeira mão! 🤩

💥 ${
    selectedCoupon?.type === 'from_value_to_value'
      ? couponProductFromValue
      : couponProductPercentage
  }${
    selectedCoupon?.mode === 'flash'
      ? `

Restam apenas *${selectedCoupon?.usage_limit} cupons*!!`
      : ''
  }
${
  selectedCoupon?.code
    ? `
Utilize o código *${selectedCoupon?.code}* na hora de fazer seu pedido!
`
    : ''
}
Bora fazer seu pedido?`;
  const [messageHeader, setMessageHeader] = useState('Fala,');
  const messageFooter =
    'Clique no link abaixo para acessar no cardápio digital 👇';
  const advertiserUrl = getURL('cardapio_web').replace(
    '$$advertiser_slug$$',
    user.advertiserInfo.slug
  );
  const isPromotionalCampaign = campaignType === 'massPromotion';

  const [message, setMessage] = useState(
    isPromotionalCampaign ? defaultMessage : ''
  );
  const [selectedMsgBox, setSelectedMsgBox] = useState('');
  const [fileItemData, setFileItemData] = useState({
    base64: null,
    name: '',
    size: 0,
    type: '',
    sub_type: '',
    width: null,
    height: null,
  });

  const handleCleanAll = () => {
    setFileItemData({
      base64: null,
      name: '',
      size: 0,
      type: '',
      sub_type: '',
      width: null,
      height: null,
    });
  };

  async function handleFileItem(event) {
    const f = event.target.files[0];

    if (f) {
      const fname = f.name;
      const fsize = (f.size / 1000000).toFixed(1);
      const ftype = f.type.split('/')[0];
      const fsubtype = f.type.split('/')[1];

      const itemFileReader = new FileReader();
      itemFileReader.onload = function () {
        setFileItemData({
          ...fileItemData,
          base64: itemFileReader.result,
          name: fname,
          size: fsize,
          type: ftype,
          sub_type: fsubtype,
        });
      };
      itemFileReader.readAsDataURL(f);
    } else {
      handleCleanAll();
    }
  }

  useEffect(() => {
    if (selectedCoupon?.url) {
      setFileItemData({
        ...fileItemData,
        base64: selectedCoupon?.url,
        name: selectedCoupon?.image_name,
        size: '',
        type: selectedCoupon?.image_type,
        sub_type: selectedCoupon?.image_subtype,
        localImage: true,
      });
    }
  }, []);

  function handleUploadImage() {
    document.getElementById('itemImage').click();
  }

  function handleGoToNextPage() {
    const sendMessage = `${messageHeader} <nome_do_cliente>!

${message}${sendCatalogLink ? `${messageFooter}` : ''}`;

    dispatch(setGlobalMessage(sendMessage));
    if (fileItemData.name) {
      dispatch(setImage(fileItemData));
    }

    dispatch(setCurrentStep('4'));
  }

  function handleBacktoPreviousPage() {
    dispatch(setCurrentStep(isPromotionalCampaign ? '2' : '1'));
  }

  return (
    <>
      <ModalHeader>
        Monte a sua mensagem
        <CloseButton onClick={props.onClose}>
          <CloseImage
            src={require('../../../../assets/images/close/close.png')}
          />
        </CloseButton>
      </ModalHeader>
      <ModalContent>
        <Row>
          <Col width="55%">
            <Row justifyStart alignEnd>
              <Text size={px2vw(14)}>Defina a imagem que será utilizada:</Text>
            </Row>
            <Row
              justifyStart
              margtop={px2vw(11)}
              alignCenter
              margbottom={px2vw(16)}
            >
              <input
                className="d-none"
                onChange={handleFileItem}
                type="file"
                id="itemImage"
                name="itemImage"
              />

              <ModalSecundaryButton
                width={fileItemData.name ? px2vw(205) : px2vw(150)}
                height={px2vw(40)}
                radius={px2vw(5)}
                onClick={() => handleUploadImage()}
                type="button"
              >
                <ModalSecundaryButtonText
                  center
                  size={px2vw(12)}
                  className={'mr-auto'}
                >
                  {fileItemData.name ? (
                    <Thumb className="mr-2" src={fileItemData.base64} />
                  ) : (
                    <UploadIcon
                      className={fileItemData.name ? `mr-2` : ''}
                      src={require('../../../../assets/images/kuppi_boost/upload.png')}
                    />
                  )}
                  {!fileItemData.name
                    ? 'Importar imagem'
                    : fileItemData.name.length > 10
                    ? `${fileItemData.name
                        .substring(0, 10)
                        .replace('_', '')}...`
                    : fileItemData.name}
                </ModalSecundaryButtonText>
                {fileItemData.name && (
                  <Text underline regular size={px2vw(13)} margright={px2vw(5)}>
                    Alterar
                  </Text>
                )}
              </ModalSecundaryButton>
              {fileItemData.name && (
                <Text
                  onClick={handleCleanAll}
                  pointer
                  margleft={px2vw(100)}
                  underline
                  regular
                  size={px2vw(13)}
                >
                  <BsTrash
                    fontSize={px2vw(15)}
                    style={{ marginRight: px2vw(5) }}
                  />
                  Excluir
                </Text>
              )}
            </Row>
            <Row justifyStart alignEnd margbottom={px2vw(11)}>
              <Text size={px2vw(14)}>Defina o texto da mensagem:</Text>
              <Text
                className="mx-auto"
                size={px2vw(11)}
                regular
                margbottom={px2vw(1)}
              >
                Máx. 400 caracteres
              </Text>
            </Row>
            <OverFlowBox>
              <label>
                <InfoBox active h="auto" mb={px2vw(10)}>
                  <HeaderInput
                    chars={messageHeader?.length + 2}
                    value={messageHeader.substring(0, 30)}
                    onChange={(e) => {
                      setMessageHeader(e.target.value);
                    }}
                  />
                  <span style={{marginLeft: -15}}>{' <nome_do_cliente>!'}</span>
                  {/* <Text size={px2vw(14)} regular justifyStart>
                  {messageHeader}
                </Text> */}
                </InfoBox>
              </label>

              <TextArea
                h={px2vw(170)}
                maxLength="400"
                onChange={(e) => setMessage(e.target.value)}
                value={message}
              />

              {sendCatalogLink && (
                <InfoBox h="auto" mb={px2vw(10)}>
                  <Text size={px2vw(14)} regular justifyStart>
                    {messageFooter}
                  </Text>
                </InfoBox>
              )}

              <Row alignCenter justifyStart padtop={px2vw(5)}>
                <CheckBox
                  checked={showUnsubscribeMessage}
                  onClick={() =>
                    dispatch(setShowUnsubscribeMessage(!showUnsubscribeMessage))
                  }
                />
                <Text regular alignCenter size={px2vw(11)}>
                  Habilitar mensagem de descadastro
                </Text>
              </Row>

              <Row alignCenter justifyStart padtop={px2vw(5)}>
                <CheckBox
                  checked={sendCatalogLink}
                  onClick={() => dispatch(setSendCatalogLink(!sendCatalogLink))}
                />
                <Text regular alignCenter size={px2vw(11)}>
                  Enviar link do cardapio digital
                </Text>
              </Row>

              {selectedMsgBox === '1' && (
                <Col margtop={px2vw(13)}>
                  <Text size={px2vw(14)} margbottom={px2vw(8)} justifyStart>
                    Mensagem padrão
                  </Text>
                  <TextArea
                    bg={cinzaKuppi1}
                    w="90%"
                    h={px2vw(90)}
                    maxLength="400"
                    value="Aqui está o link do cardápio digital pra você montar o pedido:"
                  />
                </Col>
              )}
              {selectedMsgBox === '2' && (
                <Col margtop={px2vw(13)}>
                  <Text size={px2vw(14)} margbottom={px2vw(8)} justifyStart>
                    Mensagem padrão
                  </Text>
                  <TextArea
                    bg={cinzaKuppi1}
                    w="90%"
                    h={px2vw(90)}
                    maxLength="200"
                    value="Obrigado pela resposta, não enviaremos mais mensagens. Quando quiser saber das novidades e promoções exclusivas basta consultar o nosso cardápio digital!:"
                  />
                </Col>
              )}
            </OverFlowBox>
          </Col>
          <MassMessagePreview
            footer={sendCatalogLink ? messageFooter : ''}
            header={messageHeader}
            message={message}
            image={fileItemData?.base64}
            link={sendCatalogLink ? advertiserUrl : ''}
          />
        </Row>
      </ModalContent>
      <ModalFooter>
        <Row
          onClick={handleBacktoPreviousPage}
          justifyStart
          alignCenter
          width="auto"
          cursor="pointer"
        >
          <AiOutlineLeft fontSize={px2vw(15)} className="mr-1" />
          <Text regular alignCenter size={px2vw(15)}>
            Voltar
          </Text>
        </Row>
        <ModalPrimaryButton
          disabled={!message?.trim().length}
          onClick={handleGoToNextPage}
          className="ml-auto"
        >
          Avançar
        </ModalPrimaryButton>
      </ModalFooter>
    </>
  );
}
