import styled from 'styled-components';

import px2vw from '../../../utils/responsiveness/px2vw';

import {
  branco,
  verdeKuppi1,
  cinzaKuppi1,
  cinzaKuppi2,
  cinzaKuppi3,
  cinzaKuppi4,
} from '../../../styles/colors';

export const CashReportBox = styled.div`
  border-radius: ${px2vw(20)};
  background-color: ${branco};
  width: ${px2vw(1020)};
  height: auto;
  padding: ${px2vw(30)};
  margin-top: ${px2vw(5)};

  box-shadow: 0px 8px 9px -2px ${cinzaKuppi4};
`;

export const TextBold = styled.div`
  font-size: ${px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.36px;
  border-right: ${(props) =>
    props.separator ? `1px solid ${cinzaKuppi4}` : 'none'};
  width: ${(props) => props.width};
  height: ${(props) => props.height};

  ${(props) =>
    props.alignCenter &&
    `
    text-align: center;
  `};

  ${(props) =>
    props.flexCenter &&    `
    display: flex;
    align-items: center;
    justify-content: center;
  `};
`;

export const TextBold2 = styled.div`
  font-size: ${px2vw(18)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.36px;
`;

export const TextRegular = styled.div`
  font-size: ${px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.36px;
`;

export const FilterButton = styled.button`
  background-color: ${verdeKuppi1};
  width: ${px2vw(100)};
  height: ${px2vw(30)};
  border: none;
  border-radius: 10px;
  padding: 0.3em;
  margin-left: ${px2vw(30)};
`;

export const FilterButtonText = styled.div`
  font-size: ${px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.36px;
  color: ${branco};
`;

export const DateDiv = styled.div`
  .date {
    width: ${px2vw(100)};
    padding: 0.5em;
    border: 1px solid ${cinzaKuppi4};
    border-radius: ${px2vw(5)};
    font-size: ${px2vw(14)};
    font-family: AvenirNextLTPro;
    font-weight: normal;
    letter-spacing: 0.36px;
    height: ${px2vw(32)};

    ::placeholder {
      color: ${cinzaKuppi4} !important;
    }

    :focus {
      border: 1px solid ${cinzaKuppi3} !important;
      box-shadow: none !important;
    }
  }
`;

export const Hr = styled.div`
  border-top: 3px solid ${cinzaKuppi1};
  height: 2px;
  width: ${(props) => props.width ? props.width : '100%'};
`;

export const HrSection = styled.div`
  margin-top: ${px2vw(40)};
  margin-bottom: ${px2vw(40)};
  border-top: 3px solid ${cinzaKuppi2};
  height: 2px;
  width: 100%;
`;
