export function setConversations(data) {
  return {
    type: '@whatsappChatConversations/SET_CONVERSATIONS_ARRAY',
    payload: data,
  };
}

export function setChatBarItems(data) {
  return {
    type: '@whatsappChatConversations/SET_CHAT_BAR_ITEMS',
    payload: data,
  };
}

export function updateConversations() {
  return {
    type: '@whatsappChatConversations/UPATE_CONVERSATIONS',
  };
}

export function setIsHistorySyncComplete(state) {
  return {
    type: '@whatsappChatConversations/SET_IS_HISTORY_SYNC',
    payload: state,
  };
}

export function processReceivedMessageAction(data) {
  return {
    type: '@whatsappChatConversations/PROCESS_RECEIVED_MESSAGE',
    payload: data,
  };
}

export function watchReceivedMessageAction() {
  return {
    type: '@whatsappChatConversations/WATCH_RECEIVED_MESSAGE',
  };
}
