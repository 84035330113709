export const PageTitleExpired = 'Boleto já vencido!';
export const PageTitleSuccess = 'Boleto emitido com sucesso!';
export const PageTextExpired = `O boleto que você gerou, venceu em: $$BOLETO-EXPIRATION$$. 
Caso você tenha pago, lembre-se que o pagamento pode demorar até 3 dias para ser identificado e sendo assim, aguarde que entraremos em contato informando sobre o pagamento.
Caso você não tenha pago, clique no botão "Trocar forma de pagamento", para trocar a forma de pagamento para cartão de crédito, PIX, ou gerar um novo boleto com vencimento atualizado.`;
export const PageTextNotExpiredBlocked = `Enviamos os dados do boleto para seu e-mail. Você ainda precisa
pagá-lo até a data de vencimento para re-ativar a seu acesso. Assim
que o pagamento do boleto for identificado, você receberá um email confirmando o pagamento e o acesso a plataforma da Kuppi será liberado.`;
export const PageTextNotExpiredRenewNotBlocked = `Enviamos os dados do boleto para seu e-mail. Você ainda precisa
pagá-lo até a data de vencimento para renovar a sua assinatura. Assim
que o pagamento do boleto for identificado, você receberá um email confirmando o pagamento.`;
export const PageTextNotExpiredFirstPayment = `Enviamos os dados do boleto para seu e-mail. Você ainda precisa
pagá-lo até a data de vencimento para ativar a sua assinatura. Assim
que o pagamento do boleto for identificado, a confirmação de pagamento, bem como os seus dados de acesso a
plataforma da Kuppi e demais instruções serão enviadas para o seu email.`;
export const BoletoAmountText = 'Valor do boleto';
export const BoletoExpirationText = 'Vencimento do boleto';
export const BoletoPrintText = 'Imprimir boleto';
export const BoletoPaymentInfo = 'Código de pagamento do boleto';
