import styled from 'styled-components';

import px2vw from '../../utils/responsiveness/px2vw';

import { branco, verdeKuppi1 } from '../../styles/colors';

export const Title = styled.div`
  width: ${px2vw(900)};
  display: flex;
  font-size: ${px2vw(80)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.36px;
  text-align: center;
`;

export const NormalText = styled.div`
  width: ${px2vw(775)};
  display: flex;
  font-size: ${px2vw(50)};
  font-family: AvenirNextLTPro;
  letter-spacing: 0.36px;
  color: ${(props) => props.color};
  margin-top: ${(props => props.margtop)};
  padding-top: ${(props) => props.padtop};

  ${(props) =>
    props.bell &&
    `
    margin-top: ${px2vw(50)};
  `}

  ${(props) =>
    props.menu &&
    `
    margin-top: ${px2vw(40)};
  `}

  ${(props) =>
    props.notInterested &&
    `
    text-align: flex-end;
    margin-top: ${px2vw(40)};
    cursor: pointer;
  `}

  ${(props) => props.center && `
    text-align: center;
    align-items: center;
    justify-content: center;
    width: auto;
    max-width: ${props.maxWidth || px2vw(1100)};
    font-weight: ${props.fontweight || 'bold'};
  `}

  ${(props) =>
      props.passwordAndEmail &&
      `
        text-align: center;
        margin-top: ${px2vw(70)};
        margin-left: ${px2vw(410)};
        align-items: center;
        justify-content: center;
        cursor: pointer;
        color: ${verdeKuppi1}
    `}
`;

export const ImageBox = styled.div`
  width: ${px2vw(150)};
  height: ${px2vw(100)};
  margin-right: ${px2vw(30)};
  margin-top: ${px2vw(30)};

  ${(props) =>
    props.bell &&
    `
    width: ${px2vw(100)};
    height: ${px2vw(150)};
    margin-top: ${px2vw(50)};
  `}

  ${(props) =>
    props.app &&
    `
      width: ${px2vw(800)};
      height: ${px2vw(1700)};
    `}
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
`;

export const WhiteBox = styled.div`
  width: 100%;
  height: ${px2vw(700)};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  -webkit-box-shadow: 0px -4px 10px -1px rgba(0, 0, 0, 0.5);
  box-shadow: 0px -4px 10px -1px rgba(0, 0, 0, 0.5);
  z-index: 2;
  background-color: ${branco};
`;

export const GoToStoreButton = styled.button`
  width: ${px2vw(800)};
  height: ${px2vw(350)};
  padding: ${px2vw(20)} ${px2vw(75)};
  background: linear-gradient(
      231.72deg,
      #2ffe5f 7.51%,
      rgba(47, 254, 95, 0) 91.54%
    ),
    #2cfe89;
  border-radius: ${px2vw(30)};
  border: none;

  font-size: ${px2vw(50)};
  font-family: AvenirNextLTPro;
  letter-spacing: 0.36px;
  font-weight: 700;
`;
