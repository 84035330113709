import React from 'react';

import px2vw from '../../utils/responsiveness/px2vw';

import { Row } from '../../styles/grid';

import {
  Button,
  ButtonShadow,
} from './styles'

export default function ButtonOnboarding(props) {
  return (
    <>
      <Button 
        fontsize={props.fontsize}
        width={props.btnWidth} height={props.btnHeight}
        onClick={props.onClick}
        active
      >{props.children}</Button>
      <Row height="auto" margtop={px2vw(-1)}>
        <ButtonShadow width={props.btnShadowWidth} height={props.btnShadowHeight} left />
        <ButtonShadow width={props.btnShadowWidth} height={props.btnShadowHeight} right />
      </Row>
    </>
  );
}
