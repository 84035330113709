import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format } from '@flasd/whatsapp-formatting';

import { cinzaKuppi1, preto } from '../../../../styles/colors';
import { Text } from '../../../../styles/common';

import px2vw from '../../../../utils/responsiveness/px2vw';
import { ModalPrimaryButton } from '../styles';
import { Col, Row } from '../../../../styles/grid';

import {
  ModalContent,
  CloseButton,
  ModalHeader,
  CloseImage,
  ModalFooter,
  ConversationIndicatorImg,
  TextBox,
  PhoneImg,
  TextArea,
  UploadIcon,
  ModalSecundaryButtonText,
  ModalSecundaryButton,
  InfoBox,
  OverFlowBox,
  WhatsImg,
  Thumb,
  MessageContainer,
} from './styles';
import { getURL } from '../../../../utils/utils';
import {
  setImage,
  setMessage as setGlobalMessage,
  updateMassMessageCampaign,
  updateMassMessageCampaignClear,
} from '../../../../store/modules/massMessage/actions';
import { Spinner } from 'reactstrap';
import { MassMessagePreview } from '../Preview';

export default function EditCampaign({ ...props }) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const {
    selectedCoupon,
    selectedCampaign,
    isEditingSuccess,
    isEditingLoading,
  } = useSelector((state) => state.massMessage);

  const clientNameRegex = /^(.*?<nome_do_cliente>!)/

  const messageHeader = selectedCampaign?.campaignDetails?.main_text.match(clientNameRegex)[0];
  const messageFooter =
    'Clique no link abaixo para acessar no cardápio digital 👇';
  const advertiserUrl = getURL('cardapio_web').replace(
    '$$advertiser_slug$$',
    user.advertiserInfo.slug
  );

  const [message, setMessage] = useState(
    `${selectedCampaign?.campaignDetails?.main_text}`
      .replace(messageHeader, '')
      .replace(messageFooter, '')
      .trim()
  );
  const [selectedMsgBox, setSelectedMsgBox] = useState('');
  const [fileItemData, setFileItemData] = useState({
    base64: selectedCampaign?.campaignDetails?.url,
    name: selectedCampaign?.campaignDetails?.original_name,
    size: 0,
    type: '',
    sub_type: '',
    width: null,
    height: null,
  });

  const handleCleanAll = () => {
    setFileItemData({
      base64: null,
      name: '',
      size: 0,
      type: '',
      sub_type: '',
      width: null,
      height: null,
    });
  };

  async function handleFileItem(event) {
    const f = event.target.files[0];

    if (f) {
      const fname = f.name;
      const fsize = (f.size / 1000000).toFixed(1);
      const ftype = f.type.split('/')[0];
      const fsubtype = f.type.split('/')[1];

      const itemFileReader = new FileReader();
      itemFileReader.onload = function () {
        setFileItemData({
          ...fileItemData,
          base64: itemFileReader.result,
          name: fname,
          size: fsize,
          type: ftype,
          sub_type: fsubtype,
        });
      };
      itemFileReader.readAsDataURL(f);
    } else {
      handleCleanAll();
    }
  }

  useEffect(() => {
    if (selectedCoupon?.url) {
      setFileItemData({
        ...fileItemData,
        base64: selectedCoupon?.url,
        name: selectedCoupon?.image_name,
        size: '',
        type: selectedCoupon?.image_type,
        sub_type: selectedCoupon?.image_subtype,
        localImage: true,
      });
    }
  }, []);

  useEffect(() => {
    if (isEditingSuccess) {
      dispatch(updateMassMessageCampaignClear());
      props.onClose();
    }
  }, [isEditingSuccess]);

  function handleUploadImage() {
    document.getElementById('itemImage').click();
  }

  function handleSave() {
    const sendMessage = `${messageHeader}

${message}${selectedCampaign?.campaignDetails?.send_catalog_link ? `
${messageFooter}` : ''}`;

    dispatch(setGlobalMessage(sendMessage));
    if (fileItemData.name) {
      dispatch(setImage(fileItemData));
    }

    dispatch(
      updateMassMessageCampaign(
        sendMessage,
        fileItemData.name !=
          `${selectedCampaign?.campaignDetails?.original_name}`
          ? fileItemData
          : null
      )
    );
  }

  return (
    <>
      <ModalHeader>
        Editar campanha
        <CloseButton onClick={props.onClose}>
          <CloseImage
            src={require('../../../../assets/images/close/close.png')}
          />
        </CloseButton>
      </ModalHeader>
      <ModalContent>
        <Row>
          <Col width="55%">
            <Row justifyStart alignEnd>
              <Text size={px2vw(14)}>Selecionar nova imagem:</Text>
            </Row>
            <Row
              justifyStart
              margtop={px2vw(11)}
              alignCenter
              margbottom={px2vw(16)}
            >
              <input
                className="d-none"
                onChange={handleFileItem}
                type="file"
                id="itemImage"
                name="itemImage"
              />

              <ModalSecundaryButton
                width={fileItemData.name ? px2vw(205) : px2vw(150)}
                height={px2vw(40)}
                radius={px2vw(5)}
                onClick={() => handleUploadImage()}
                type="button"
              >
                <ModalSecundaryButtonText
                  center
                  size={px2vw(12)}
                  className={'mr-auto'}
                >
                  {fileItemData.original_name ? (
                    <Thumb className="mr-2" src={fileItemData.base64} />
                  ) : (
                    <UploadIcon
                      className={fileItemData.original_name ? `mr-2` : ''}
                      src={
                        fileItemData.base64 ||
                        require('../../../../assets/images/kuppi_boost/upload.png')
                      }
                    />
                  )}
                  {!fileItemData.name
                    ? 'Importar imagem'
                    : fileItemData.name.length > 10
                    ? `${fileItemData.name
                        .substring(0, 10)
                        .replace('_', '')}...`
                    : fileItemData.name}
                </ModalSecundaryButtonText>
                {fileItemData?.name && (
                  <Text underline regular size={px2vw(13)} margright={px2vw(5)}>
                    Alterar
                  </Text>
                )}
              </ModalSecundaryButton>
            </Row>
            <Row justifyStart alignEnd margbottom={px2vw(11)}>
              <Text size={px2vw(14)}>Defina o texto da mensagem:</Text>
              <Text
                className="mx-auto"
                size={px2vw(11)}
                regular
                margbottom={px2vw(1)}
              >
                Máx. 400 caracteres
              </Text>
            </Row>
            <OverFlowBox>
              <InfoBox h="auto" mb={px2vw(10)}>
                <Text size={px2vw(14)} regular justifyStart>
                  {messageHeader}
                </Text>
              </InfoBox>
              <TextArea
                h={px2vw(170)}
                maxLength="400"
                onChange={(e) => setMessage(e.target.value)}
                value={message}
              />
              {selectedCampaign?.campaignDetails?.send_catalog_link && (
                <InfoBox h="auto" mb={px2vw(10)}>
                  <Text size={px2vw(14)} regular justifyStart>
                    {messageFooter}
                  </Text>
                </InfoBox>
              )}

              {selectedMsgBox === '1' && (
                <Col margtop={px2vw(13)}>
                  <Text size={px2vw(14)} margbottom={px2vw(8)} justifyStart>
                    Mensagem padrão
                  </Text>
                  <TextArea
                    bg={cinzaKuppi1}
                    w="90%"
                    h={px2vw(90)}
                    maxLength="400"
                    value="Aqui está o link do cardápio digital pra você montar o pedido:"
                  />
                </Col>
              )}
              {selectedMsgBox === '2' && (
                <Col margtop={px2vw(13)}>
                  <Text size={px2vw(14)} margbottom={px2vw(8)} justifyStart>
                    Mensagem padrão
                  </Text>
                  <TextArea
                    bg={cinzaKuppi1}
                    w="90%"
                    h={px2vw(90)}
                    maxLength="200"
                    value="Obrigado pela resposta, não enviaremos mais mensagens. Quando quiser saber das novidades e promoções exclusivas basta consultar o nosso cardápio digital!:"
                  />
                </Col>
              )}
            </OverFlowBox>
          </Col>
          <Col width="45%" relative>
            <MessageContainer
              padleft={px2vw(35)}
              padtop={px2vw(85)}
              bottom={px2vw(10)}
              justifyStart
              absolute
            >
              <TextBox first>
                {fileItemData.base64 && (
                  <>
                    <WhatsImg src={fileItemData.base64} /> <br />
                  </>
                )}
                {messageHeader}
                <br />
                <br />
                {!message ? (
                  <br />
                ) : (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: format(message),
                    }}
                  />
                )}
                {selectedCampaign?.campaignDetails?.send_catalog_link && (
                  <>
                    <br />
                    {messageFooter}
                  </>
                )}
                <ConversationIndicatorImg
                  src={require('../../../../assets/images/sales_retriever/conversation_indicator.png')}
                />

                {selectedCampaign?.campaignDetails?.send_catalog_link && (
                  <>
                    <br />
                    <br />
                    <a href={advertiserUrl}>{advertiserUrl}</a>
                  </>
                )}

                {selectedCampaign?.campaignDetails
                  ?.show_unsubscribe_message && (
                  <>
                    <br />
                    <br />
                    Caso não queira mais receber mensagens promocionais, digite
                    "N"
                  </>
                )}
              </TextBox>
            </MessageContainer>
            <PhoneImg
              src={require('../../../../assets/images/sales_retriever/phone.svg')}
            />
          </Col>
        </Row>
      </ModalContent>
      <ModalFooter>
        <ModalPrimaryButton
          disabled={!message?.trim().length || isEditingLoading}
          onClick={handleSave}
          className="ml-auto"
        >
          {isEditingLoading ? (
            <Row height="80vh">
              <Spinner
                style={{
                  margin: 'auto',
                  width: '1.8rem',
                  height: '1.8rem',
                  color: preto,
                }}
              />
            </Row>
          ) : (
            'Salvar'
          )}
        </ModalPrimaryButton>
      </ModalFooter>
    </>
  );
}
