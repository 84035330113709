import produce from 'immer';

const INITIAL_STATE = {
  refers: [],
  historic: [],
  rewards: {},
  mgm_loading: false
};

export default function mgm(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@mgm/GET_MGM_DATA': {
        draft.mgm_loading = true;
        break;
      }
      case '@mgm/GET_MGM_DATA_FAILURE': {
        draft.mgm_loading = false;
        break;
      }
      case '@mgm/SET_MGM_DATA': {
        draft.refers = action.payload.refers;
        draft.historic = action.payload.historic;
        draft.rewards = action.payload.rewards;
        draft.mgm_loading = false;
        break;
      }
      default:
    }
  });
}
