import styled from 'styled-components';
import px2vw from '../../../utils/responsiveness/px2vw';

import { Icon } from 'react-materialize';

export const CloseModal = styled(Icon)`
  position: absolute;
  right: ${px2vw(10)};
  top: ${px2vw(10)};
  cursor: pointer;
`;

export const Button = styled.button`
  margin-top: ${px2vw(20)};
  padding: 0.5rem 1rem;
  border: none;
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background-color: #0005;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 999;
`;

export const DetailsImage = styled.img`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 20rem;
`;

export const ImageContainer = styled.div`
  display: flex;
  margin-top: ${px2vw(10)};
  width: 90%;
  justify-content: center;
  align-items: center;
`;

export const Card = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 1rem;
  padding: 2rem 1rem;
  max-width: 30rem;
`;
