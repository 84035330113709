import { takeLatest, call, put, all, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { apiAnunciante } from '../../../services/api';

import {
  getFinancialStatusSuccess,
  createSellerFailure,
  createSellerSuccess,
  updateSellerInfoFailure,
  updateSellerInfoSuccess,
  updateSellerBankFailure,
  updateSellerBankSuccess,
  getTransfersDataFailure,
  getTransfersDataSuccess,
  getTransferTransactionsSuccess,
  getTransferTransactionsFailure,
  setBankList,
} from './actions';

const userData = (state) => state.user.user;
const financialData = (state) => state.financial;

export function* getFinancialStatus() {
  try {
    const user = yield select(userData);

    const response = yield call(
      apiAnunciante.get,
      `/user-info/${user.advertiserInfo.id}/financial`
    );

    const isCPF = user.advertiserInfo.cpf_cnpj.length === 11;

    yield put(
      getFinancialStatusSuccess({
        ...response.data,
        representative_cpf:
          isCPF && !response.data?.representative_cpf
            ? user.advertiserInfo?.cpf_cnpj
            : response.data?.representative_cpf || '',
      })
    );

    const responseBanks = yield call(apiAnunciante.get, `/financial/banks`);

    yield put(setBankList(responseBanks.data));
  } catch (err) {
    toast.error(`Falha ao buscar os dados bancários. Verifique seus dados.`, {
      autoClose: 6000,
    });
  }
}

export function* createSellerRequest() {
  try {
    const user = yield select(userData);
    const financial = yield select(financialData);

    const response = yield call(
      apiAnunciante.post,
      `/user-info/${user.advertiserInfo.id}/financial/seller`,
      {
        representative_full_name:  financial.storeData.representativeFullName,
        owner_cpf: financial.storeData.representativeCPF,
        birthdate: financial.storeData.representativeBirthdate,
        bank_id: financial.storeData.bank,
        agency: financial.storeData.agency,
        account_full_name: financial.storeData.accountFullName,
        account_number: financial.storeData.accountNumber,
        account_digit: financial.storeData.accountDigitNumber,
        account_type: financial.storeData.accountType,
      }
    );

    yield put(createSellerSuccess(response.data));
  } catch (err) {
    yield put(createSellerFailure());

    toast.error(`Falha ao criar o vendedor. Verifique seus dados.`, {
      autoClose: 6000,
    });
  }
}

export function* updateSellerInfo() {
  try {
    const user = yield select(userData);
    const financial = yield select(financialData);

    yield call(
      apiAnunciante.put,
      `/user-info/${user.advertiserInfo.id}/financial/seller`,
      {
        representative_full_name:  financial.storeData.representativeFullName,
        birthdate: financial.storeData.representativeBirthdate
      }
    );

    toast.success(`Dados bancários atualizados com sucesso.`, {
      autoClose: 6000,
    });

    yield put(updateSellerInfoSuccess());
  } catch (err) {
    yield put(updateSellerInfoFailure());
    toast.error(
      `Falha ao atualizar os dados bancários. Verifique seus dados.`,
      {
        autoClose: 6000,
      }
    );
  }
}

export function* updateBankInfo() {
  try {
    const user = yield select(userData);
    const financial = yield select(financialData);

    yield call(
      apiAnunciante.put,
      `/user-info/${user.advertiserInfo.id}/financial/bank`,
      {
        representative_full_name:  financial.storeData.representativeFullName,
        owner_cpf: financial.storeData.representativeCPF,
        bank_id: financial.storeData.bank,
        agency: financial.storeData.agency,
        birthdate: financial.storeData.representativeBirthdate,
        account_full_name: financial.storeData.accountFullName,
        account_number: financial.storeData.accountNumber,
        account_digit: financial.storeData.accountDigitNumber,
        account_type: financial.storeData.accountType,
      }
    );

    toast.success(`Dados bancários atualizados com sucesso.`, {
      autoClose: 6000,
    });

    yield put(updateSellerBankSuccess());
  } catch (err) {
    yield put(updateSellerBankFailure());
    toast.error(
      `Falha ao atualizar os dados bancários. Verifique seus dados.`,
      {
        autoClose: 6000,
      }
    );
  }
}

export function* getTransfersDataRequest({ payload }) {
  try {
    const user = yield select(userData);

    const response = yield call(
      apiAnunciante.get,
      `/user-info/${user.advertiserInfo.id}/financial/transfers?start_date=${payload.startDate}&end_date=${payload.endDate}`
    );

    yield put(getTransfersDataSuccess(response.data));
  } catch (err) {
    yield put(getTransfersDataFailure());
  }
}

export function* getTransferTransactionsRequest({ payload }) {
  try {
    const user = yield select(userData);

    const response = yield call(
      apiAnunciante.get,
      `/user-info/${user.advertiserInfo.id}/financial/transfer/${payload.transferId}/transactions`
    );

    yield put(getTransferTransactionsSuccess(response.data));
  } catch (err) {
    yield put(getTransferTransactionsFailure());
  }
}

export default all([
  takeLatest('@financial/GET_FINANCIAL_STATUS_REQUEST', getFinancialStatus),
  takeLatest('@financial/CREATE_SELLER_REQUEST', createSellerRequest),
  takeLatest('@financial/UPDATE_SELLER_BANK_REQUEST', updateBankInfo),
  takeLatest('@financial/UPDATE_SELLER_INFO_REQUEST', updateSellerInfo),
  takeLatest(
    '@financial/GET_FINANCIAL_TRANSFERS_REQUEST',
    getTransfersDataRequest
  ),
  takeLatest(
    '@financial/GET_FINANCIAL_TRANSFERS_TRANSACTIONS_REQUEST',
    getTransferTransactionsRequest
  ),
]);
