import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'reactstrap';
import { toast } from 'react-toastify';
import sjcl from 'sjcl';

import { Form } from '@rocketseat/unform';

import { Row, Col } from '../../styles/grid';

import { LoadingBox } from '../../pages/_layouts/styles/common';

import { preto } from '../../styles/colors';

import {
  PasswordRequiredBox,
  LogoImageBox,
  LogoImage,
  InformationText,
  PasswordInputBox,
  PasswordInputLabel,
  PasswordBox,
  PasswordInput,
  SeePasswordButton,
  SeePasswordImage,
  AccessButton,
  AccessButtonText,
} from './styles';

import { adminAccessRequest } from '../../store/modules/auth/actions';

export default function PasswordRequired(props) {
  const { width, height, pad, infoText } = props;

  const [passwordVisible, setPasswordVisible] = useState(false);
  const adminAccessLoading = useSelector(
    (state) => state.auth.adminAccessLoading
  );
  const dispatch = useDispatch();

  const handleSubmit = ({ password }) => {
    if (password.trim() === '') {
      toast.error('Senha não informada', {
        autoClose: 6000,
      });
    } else {
      dispatch(
        adminAccessRequest(
          sjcl.codec.hex.fromBits(sjcl.hash.sha256.hash(password))
        )
      );
    }
  };

  return (
    <PasswordRequiredBox width={width} height={height} pad={pad}>
      <Row alignCenter height="auto">
        <LogoImageBox>
          <LogoImage
            src={require('../../assets/images/logo_kuppi/logo_kuppi_wo_slogan.png')}
          />
        </LogoImageBox>
      </Row>

      <Row alignCenter height="auto">
        <InformationText>{infoText}</InformationText>
      </Row>

      <Form onSubmit={handleSubmit}>
        <PasswordInputBox>
          <Col height="auto" width="80%">
            <PasswordInputLabel>Senha</PasswordInputLabel>
          </Col>
          <PasswordBox>
            <PasswordInput
              name="password"
              type={passwordVisible ? 'text' : 'password'}
              placeholder="Insira sua senha de administrador"
            />
            <SeePasswordButton
              type="button"
              onClick={() => setPasswordVisible(!passwordVisible)}
            >
              <SeePasswordImage
                src={require(passwordVisible
                  ? '../../assets/images/eye-outline/eye-outline.png'
                  : '../../assets/images/eye-outline/eye-off-outline.png')}
              />
            </SeePasswordButton>
          </PasswordBox>
        </PasswordInputBox>

        <Row alignCenter height="auto">
          <AccessButton type="submit" disabled={adminAccessLoading}>
            {adminAccessLoading ? (
              <LoadingBox>
                <Spinner
                  style={{ width: '1.8rem', height: '1.8rem', color: preto }}
                />{' '}
              </LoadingBox>
            ) : (
              <AccessButtonText>Autorizar</AccessButtonText>
            )}
          </AccessButton>
        </Row>
      </Form>
    </PasswordRequiredBox>
  );
}
