import React from 'react';

import px2vw from '../../utils/responsiveness/px2vw';

import {
  ModalBackground,
  ModalContent,
  NavigationButton,
  ArrowImage,
} from './styles';

export default function Modal(props) {
  return (
    <ModalBackground isVisible={props.isVisible} zIndex={props.zIndex}>
      {props.hasNavigation && (
        <NavigationButton
          margright={px2vw(10)}
          onClick={props.clickLeftButton}
          disabled={props.disabledLeftButton}
        >
          <ArrowImage
            disabled={props.disabledLeftButton}
            src={require('../../assets/images/arrows/arrow_left@2x.png')}
          />
        </NavigationButton>
      )}
      <ModalContent
        width={props.width}
        height={props.height}
        bgcolor={props.bgcolor}
        margtop={props.margtop}
        padding={props.padding}
        backgroundImg={props.backgroundImg}
        backgroundImgSize={props.backgroundImgSize}
        maxheight={props.maxheight}
      >
        {props.children}
      </ModalContent>
      {props.hasNavigation && (
        <NavigationButton
          margleft={px2vw(10)}
          onClick={props.clickRightButton}
          disabled={props.disabledRightButton}
        >
          <ArrowImage
            disabled={props.disabledRightButton}
            src={require('../../assets/images/arrows/arrow_right@2x.png')}
          />
        </NavigationButton>
      )}
    </ModalBackground>
  );
}
