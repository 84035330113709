import styled from 'styled-components';
import { Input, Select } from '@rocketseat/unform';

import px2vw from '../../utils/responsiveness/px2vw';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import './custom.css';

import {
  branco,
  cinzaKuppi1,
  cinzaKuppi2,
  cinzaKuppi4,
  cinzaKuppi7,
  cinzaKuppi8,
  cinzaKuppi9,
  preto,
  verdeKuppi1,
  verdeKuppi2,
  verdeKuppi4,
  verdeKuppi5,
} from '../../styles/colors';
import { Col } from '../../styles/grid';

export const AdvertiserDataBox = styled.div`
  background-color: ${branco};
  border-radius: ${px2vw(20)};
  border: none;
  box-shadow: 0px 8px 9px -2px ${cinzaKuppi4};

  ${(props) => `width: ${props.width}`};
  ${(props) => `height: ${props.height ? props.height : px2vw(140)}`};
`;

export const LogoImg = styled.img`
  width: ${px2vw(44)};
  height: ${px2vw(44)};
  border: solid 1px ${cinzaKuppi9};
  border-radius: ${px2vw(35)};
`;

export const ProgressContainer = styled(Col)``;

export const Hr = styled.hr`
  width: 100%;
  background-color: ${cinzaKuppi4};
  margin: ${px2vw(5)} 0;
  height: ${px2vw(2)};
  border: none;
`;

export const ButtonSecondary = styled.div`
  background-color: ${branco};
  border: 2px solid ${preto};
  color: ${preto};
  border-radius: ${px2vw(5)};
  height: ${px2vw(28)};
  width: ${px2vw(86)};
  margin-left: ${px2vw(8)};
  font-size: ${px2vw(11)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.36px;
  text-align: center;
  padding-top: ${px2vw(5)};
  cursor: pointer;

  :hover {
    background-color: ${branco};
    border: 2px solid ${verdeKuppi4};
    color: ${verdeKuppi1};
  }
`;

export const TrophyCircle = styled.div`
  border-radius: 50%;
  min-width: ${px2vw(55)};
  min-height: ${px2vw(55)};
  background: ${cinzaKuppi1};
  border: ${px2vw(3)} solid
    ${(props) => (props.complete ? verdeKuppi5 : cinzaKuppi8)};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  position: relative;
`;

export const Circle = styled.div`
  border-radius: 50%;
  min-width: ${px2vw(15)};
  min-height: ${px2vw(15)};
  background: ${(props) => (props.complete ? verdeKuppi5 : '#e9ecef')};
  border: ${px2vw(3)} solid
    ${(props) => (props.complete ? verdeKuppi5 : '#e9ecef')};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  position: relative;
`;

export const KuppiBoostBg = styled.img`
  position: absolute;
  border-radius: 0 20px 20px 0;
  height: ${px2vw(150)};
  right: 0;
  bottom: 0;
  pointer-events: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  over-flow: hidden;
`;

export const ButtonPrimary = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${branco};
  border: none;
  cursor: pointer;
  border-radius: ${(props) => props.radius || px2vw(5)};
  font-size: ${(props) => props.size || px2vw(13)};
  font-family: AvenirNextLTPro;
  font-weight: ${(props) => props.fontWeight || '600'};
  letter-spacing: 0.015em;
  padding: ${px2vw(8)};
  width: ${(props) => (props.width ? props.width : px2vw(320))};
  height: ${(props) => (props.height ? props.height : px2vw(39))};

  margin-top: ${(props) => (props.margtop ? props.margtop : 0)};
  margin-right: ${(props) => (props.margright ? props.margright : 0)};
  background: ${verdeKuppi2};

  &:active {
    box-shadow: 0px 1px 1.5px ${cinzaKuppi2};
    background: ${verdeKuppi1};
  }

  ${(props) =>
    props.disabled &&
    `
  background-color: ${cinzaKuppi4};
  color:${branco};

  &:active {
    box-shadow: 2px 2px 3px ${cinzaKuppi2};
    background: ${cinzaKuppi4};
  }

  `}
`;

export const ProgressBarStyle = {
  backgroundColor: verdeKuppi5,
  borderRadius: 0,
  backgroundImage: `linear-gradient(45deg, ${verdeKuppi4}10 25%, ${verdeKuppi4}10 25%, ${verdeKuppi4}10 50%, ${verdeKuppi4} 50%, ${verdeKuppi4}10 75%, ${verdeKuppi4}10 75%, ${verdeKuppi4}10)`,
  backgroundSize: '1rem 1rem',
};

export const CircleProgressBar = styled(CircularProgressbar)``;

export const SelectInput = styled(Select)`
  border-radius: ${px2vw(7)};
  padding: ${px2vw(6)};
  font-size: ${px2vw(12)};
  border: ${px2vw(2)} solid ${cinzaKuppi7};
  margin-left: ${px2vw(25)};
  margin-right: ${px2vw(10)};
  ${(props) => `
    width: ${props.width};
    height: ${props.height};
    margin-top: ${props.margtop};
    margin-right: ${props.margright};
  `};
`;

export const MetricBox = styled.div`
  border: ${px2vw(2)} solid ${verdeKuppi2};
  width: ${px2vw(145)};
  height: ${px2vw(95)};
  border-radius: ${px2vw(16)};
  margin-right: ${px2vw(25)};
  padding-left: ${px2vw(8)};
  padding-right: ${px2vw(8)};
  padding-top: ${px2vw(20)};
  hr {
    background: ${verdeKuppi5};
    height: ${px2vw(4)};
  }
`;
