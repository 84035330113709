import React from 'react';

import {
  CloseButton,
  CloseImage,
  Hr,
  ModalBackground,
  ModalContent,
  ModalHeader,
} from './styles';
import { Text } from '../../../../styles/common';
import px2vw from '../../../../utils/responsiveness/px2vw';
import { Col, Row } from '../../../../styles/grid';

export default function MedalsInfoModal(props) {
  return (
    <ModalBackground isVisible={props.isVisible}>
      <ModalContent
        width={props.width}
        height={props.height}
        bgcolor={props.bgcolor}
        margtop={props.margtop}
        padding={props.padding}
        backgroundImg={props.backgroundImg}
        backgroundImgSize={props.backgroundImgSize}
        maxheight={props.maxheight}
      >
        <ModalHeader>
          Níveis e Condições
          <CloseButton onClick={props.onClose}>
            <CloseImage
              src={require('../../../../assets/images/close/close.png')}
            />
          </CloseButton>
        </ModalHeader>
        <Col>
          <Text margtop={px2vw(2)} regular size={px2vw(11)} height="auto">
            Entenda as regras e condições para evoluir de nível na Plataforma da
            Kuppi
          </Text>
          {props.levels.map((level, idx) => {
            return (
              <>
                {idx === 0 && (<Hr />)}
                <Row relative height={px2vw(45)} justifyStart alignCenter>
                  <img
                    style={{ width: px2vw(38), left: px2vw(38) }}
                    src={require(`../../../../assets/images/gamification_medals/${level.slug}.png`)}
                  />
                  <Text
                    className="justify-content-start"
                    margleft={px2vw(7)}
                    weight="bolder"
                    size={px2vw(13)}
                    width={px2vw(80)}
                  >
                    {level.name}
                  </Text>
                  <Text margleft={px2vw(10)} regular size={px2vw(11)}>
                    {level.slug === 'beginner' && 'Nível inicial na plataforma da Kuppi'}
                    {level.slug !== 'beginner' && `Conquiste ${level.award_target} troféus para atingir esse nível`}
                  </Text>
                </Row>
                {idx < props.levels.length -1 && (<Hr />)}
              </>
            )
          })}
        </Col>
      </ModalContent>
    </ModalBackground>
  );
}
