import React from "react";

import {
  Card,
  NormalText,
  InputDataTextArea,
  Image
} from '../../../BaseScreen/styles';
import { Row, Col } from '../../../../../styles/grid';
import { branco, cinzaKuppi1, preto } from '../../../../../styles/colors';
import { StoreForm } from "../../../Step2-DeliveryParams/Mobile/styles";

import px2vw from '../../../../../utils/responsiveness/px2vw';

import ButtonPrimary from '../../../../../components/ButtonPrimary';

export default function InstagramConfigs({ handler }) {
  const webDomainAddress = handler.webMenuDomain;
  const bio = 'Peça no nosso site e tenha acesso aos melhores preços:';

  return (
    <StoreForm>
      <Card
        padleft={px2vw(30)}
        padright={px2vw(30)}
        width={1360}
        height='auto'
        borderRadius={px2vw(60)}
        margtop={px2vw(60)}
      >
        <Col alignCenter margtop={px2vw(50)} >
          <Row justifyStart width={px2vw(1000)}>
            <NormalText
              margtop={px2vw(90)}
              fontweight='bold'
              color={preto}
              fontsize={px2vw(55)}
              textalign='flex-start'
            >
              Aumente seus pedidos vindos das redes sociais
            </NormalText>
          </Row>
          <Row justifyStart width={px2vw(1000)}>
            <NormalText
              margtop={px2vw(90)}
              fontweight='normal'
              color={preto}
              fontsize={px2vw(40)}
              textalign='flex-start'
              margbottom={px2vw(80)}
            >
              Insira o link do seu cardápio web na bio do seu instagram para melhorar a sua conversão nas redes sociais.
            </NormalText>
          </Row>
          <Image src={require('../../../../../assets/images/onboarding/store_publicity/insta1@2x.png')}
            height={px2vw(1000)}
          />
          <Row justifyStart width={px2vw(1000)}>
            <NormalText
              margtop={px2vw(90)}
              fontweight='bold'
              color={preto}
              fontsize={px2vw(55)}
              textalign='flex-start'
            >
              Site
            </NormalText>
          </Row>
          <InputDataTextArea value={webDomainAddress} id="site"
            backgroundcolor={cinzaKuppi1}
            padtop={px2vw(30)}
            padleft={px2vw(30)}
            width={px2vw(1000)}
            height={px2vw(200)}
            border={`1px solid ${cinzaKuppi1}`}
            fontsize={px2vw(40)}>        
            </InputDataTextArea>
          <Row margtop={px2vw(55)} margbottom={px2vw(55)}>
            <ButtonPrimary
              color={preto}
              background={branco}
              borderColor={preto}
              minWidth={1000}
              height={100}
              fontSize={50}
              borderRadius={25}
              type='button'
              onClick={() => handler.handleCopy("site")}
            >
              Copiar site
            </ButtonPrimary>
          </Row>
          <Row justifyStart width={px2vw(1000)}>
            <NormalText
              margtop={px2vw(90)}
              fontweight='bold'
              color={preto}
              fontsize={px2vw(55)}
              textalign='flex-start'>Bio
            </NormalText>
          </Row>
          <InputDataTextArea 
            value={bio} 
            id="bio"
            backgroundcolor={cinzaKuppi1}
            padtop={px2vw(30)}
            padleft={px2vw(30)}
            width={px2vw(1000)}
            height={px2vw(200)}
            border={`1px solid ${cinzaKuppi1}`}
            fontsize={px2vw(40)} 
          />
          <Row margtop={px2vw(55)} margbottom={px2vw(55)}>
            <ButtonPrimary
              color={preto}
              background={branco}
              borderColor={preto}
              minWidth={1000}
              height={100}
              fontSize={50}
              borderRadius={25}
              type='button' onClick={() => handler.handleCopy("bio")}
            >
              Copiar bio
            </ButtonPrimary>
          </Row>
        </Col>
      </Card>
      <Row margtop={px2vw(55)} margbottom={px2vw(55)} margright={px2vw(220)} justifyEnd>
        <ButtonPrimary
          minWidth={500}
          height={150}
          fontSize={50}
          borderRadius={25}
          type='button'
          onClick={handler.handleFinishStep}
        >
          Concluir
        </ButtonPrimary>
      </Row>
    </StoreForm>
  )
}
