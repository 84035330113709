export function getCouponsTraditionalRequest() {
  return {
    type: '@coupons/GET_COUPONS_TRADITIONAL_REQUEST',
  };
}

export function getCouponsTraditionalSuccess(coupons) {
  return {
    type: '@coupons/GET_COUPONS_TRADITIONAL_SUCCESS',
    payload: { coupons },
  };
}

export function getCouponsTraditionalFailure() {
  return {
    type: '@coupons/GET_COUPONS_TRADITIONAL_FAILURE',
  };
}

export function getCouponsFlashRequest() {
  return {
    type: '@coupons/GET_COUPONS_FLASH_REQUEST',
  };
}

export function getCouponsFlashSuccess(coupons) {
  return {
    type: '@coupons/GET_COUPONS_FLASH_SUCCESS',
    payload: { coupons },
  };
}

export function getCouponsFlashFailure() {
  return {
    type: '@coupons/GET_COUPONS_FLASH_FAILURE',
  };
}

export function getCouponsCodeRequest() {
  return {
    type: '@coupons/GET_COUPONS_CODE_REQUEST',
  };
}

export function getCouponsCodeSuccess(coupons) {
  return {
    type: '@coupons/GET_COUPONS_CODE_SUCCESS',
    payload: { coupons },
  };
}

export function getCouponsCodeFailure() {
  return {
    type: '@coupons/GET_COUPONS_CODE_FAILURE',
  };
}

export function getProductsWithoutCouponsRequest() {
  return {
    type: '@coupons/GET_PRODUCTS_WITHOUT_COUPONS_REQUEST',
  };
}

export function getProductsWithoutCouponsSuccess(products) {
  return {
    type: '@coupons/GET_PRODUCTS_WITHOUT_COUPONS_SUCCESS',
    payload: { products },
  };
}

export function getProductsWithoutCouponsFailure() {
  return {
    type: '@coupons/GET_PRODUCTS_WITHOUT_COUPONS_FAILURE',
  };
}

export function clearSuccess() {
  return {
    type: '@coupons/CLEAR_SUCCESS',
  };
}

export function newTraditionalCouponRequest(
  productId,
  couponType,
  couponShortDisplayName,
  couponDisplayName,
  couponDescription,
  couponOriginalAmount,
  couponPromotionalAmount,
  couponDiscountPercent,
  couponValidityDate,
  couponAcceptedStore,
  couponAcceptedDelivery,
  couponAcceptCard,
  couponAcceptMoney,
  couponAcceptPix,
  couponHoraries
) {

  let payload = {
    productId,
    couponType,
    couponShortDisplayName,
    couponDisplayName,
    couponDescription,
    couponOriginalAmount,
    couponPromotionalAmount,
    couponDiscountPercent,
    couponValidityDate,
    couponAcceptedStore,
    couponAcceptedDelivery,
    couponAcceptCard,
    couponAcceptMoney,
    couponAcceptPix,
    couponHoraries
  }
  return {
    type: '@coupons/NEW_TRADITIONAL_COUPON_REQUEST',
    payload: payload,
  };
}

export function newFlashCouponRequest(
  productId,
  couponType,
  couponShortDisplayName,
  couponDisplayName,
  couponDescription,
  couponOriginalAmount,
  couponPromotionalAmount,
  couponDiscountPercent,
  couponValidityDate,
  couponValidityTime,
  couponUseLimit,
  couponAcceptedStore,
  couponAcceptedDelivery,
  couponAcceptCard,
  couponAcceptMoney,
  couponAcceptPix
) {

  let payload = {
    productId,
    couponType,
    couponShortDisplayName,
    couponDisplayName,
    couponDescription,
    couponOriginalAmount,
    couponPromotionalAmount,
    couponDiscountPercent,
    couponValidityDate,
    couponValidityTime,
    couponUseLimit,
    couponAcceptedStore,
    couponAcceptedDelivery,
    couponAcceptCard,
    couponAcceptMoney,
    couponAcceptPix
  }
  return {
    type: '@coupons/NEW_FLASH_COUPON_REQUEST',
    payload: payload,
  };
}

export function newCodeCouponRequest( 
  couponCode,
  couponType,
  couponShortDisplayName,
  couponDisplayName,
  couponDescription,
  couponDiscountPercent,
  couponDiscountFixed,
  couponOrderMinimumAmount,
  couponIsFirstOrderDiscount,
  couponUseLimit,
  couponAcceptedStore,
  couponAcceptedDelivery,
  couponAcceptCard,
  couponAcceptMoney,
  couponAcceptPix,
  couponHoraries
) {
  let payload = {
    couponCode,
    couponType,
    couponShortDisplayName,
    couponDisplayName,
    couponDescription,
    couponDiscountPercent,
    couponDiscountFixed,
    couponOrderMinimumAmount,
    couponIsFirstOrderDiscount,
    couponUseLimit,
    couponAcceptedStore,
    couponAcceptedDelivery,
    couponAcceptCard,
    couponAcceptMoney,
    couponAcceptPix,
    couponHoraries
  }
  return {
    type: '@coupons/NEW_CODE_COUPON_REQUEST',
    payload: payload,
  }
}

export function newCouponSuccess() {
  return {
    type: '@coupons/NEW_COUPON_SUCCESS',
  };
}

export function newCouponFailure() {
  return {
    type: '@coupons/NEW_COUPON_FAILURE',
  };
}

export function updateTraditionalCouponRequest(
  couponId,
  couponShortDisplayName,
  couponDisplayName,
  couponDescription,
  couponPromotionalAmount,
  couponDiscountPercent,
  couponValidityDate,
  couponAcceptedStore,
  couponAcceptedDelivery,
  couponAcceptCard,
  couponAcceptMoney,
  couponAcceptPix,
  couponHoraries
) {
  return {
    type: '@coupons/UPDATE_TRADITIONAL_COUPON_REQUEST',
    payload: {
      couponId,
      couponShortDisplayName,
      couponDisplayName,
      couponDescription,
      couponPromotionalAmount,
      couponDiscountPercent,
      couponValidityDate,
      couponAcceptedStore,
      couponAcceptedDelivery,
      couponAcceptCard,
      couponAcceptMoney,
      couponAcceptPix,
      couponHoraries
    },
  };
}

export function updateFlashCouponRequest(
  couponId,
  couponShortDisplayName,
  couponDisplayName,
  couponDescription,
  couponPromotionalAmount,
  couponDiscountPercent,
  couponValidityDate,
  couponValidityTime,
  couponUseLimit,
  couponAcceptedStore,
  couponAcceptedDelivery,
  couponAcceptCard,
  couponAcceptMoney,
  couponAcceptPix,
) {
  return {
    type: '@coupons/UPDATE_FLASH_COUPON_REQUEST',
    payload: {
      couponId,
      couponShortDisplayName,
      couponDisplayName,
      couponDescription,
      couponPromotionalAmount,
      couponDiscountPercent,
      couponValidityDate,
      couponValidityTime,
      couponUseLimit,
      couponAcceptedStore,
      couponAcceptedDelivery,
      couponAcceptCard,
      couponAcceptMoney,
      couponAcceptPix
    },
  };
}

export function updateCodeCouponRequest(
  couponId,
  couponType,
  couponCode,
  couponShortDisplayName,
  couponDisplayName,
  couponDescription,
  couponDiscountPercent,
  couponDiscountFixed,
  couponOrderMinimumAmount,
  couponIsFirstOrderDiscount,
  couponAcceptedStore,
  couponAcceptedDelivery,
  couponAcceptCard,
  couponAcceptMoney,
  couponAcceptPix,
  couponHoraries
) {
  return {
    type: '@coupons/UPDATE_CODE_COUPON_REQUEST',
    payload: {
      couponId,
      couponType,
      couponCode,
      couponShortDisplayName,
      couponDisplayName,
      couponDescription,
      couponDiscountPercent,
      couponDiscountFixed,
      couponOrderMinimumAmount,
      couponIsFirstOrderDiscount,
      couponAcceptedStore,
      couponAcceptedDelivery,
      couponAcceptCard,
      couponAcceptMoney,
      couponAcceptPix,
      couponHoraries
    },
  };
}

export function updateCouponSuccess() {
  return {
    type: '@coupons/UPDATE_COUPON_SUCCESS',
  };
}

export function updateCouponFailure() {
  return {
    type: '@coupons/UPDATE_COUPON_FAILURE',
  };
}

export function updateCouponStatusRequest(couponId, couponMode, newStatus) {
  return {
    type: '@coupons/UPDATE_COUPON_STATUS_REQUEST',
    payload: { couponId, couponMode, newStatus },
  };
}

export function updateCouponStatusSuccess() {
  return {
    type: '@coupons/UPDATE_COUPON_STATUS_SUCCESS',
  };
}

export function updateCouponStatusFailure() {
  return {
    type: '@coupons/UPDATE_COUPON_STATUS_FAILURE',
  };
}

export function deleteCouponRequest(couponId, couponMode) {
  return {
    type: '@coupons/DELETE_COUPON_REQUEST',
    payload: { couponId, couponMode },
  };
}

export function deleteCouponSuccess() {
  return {
    type: '@coupons/DELETE_COUPON_SUCCESS',
  };
}

export function deleteCouponFailure() {
  return {
    type: '@coupons/DELETE_COUPON_FAILURE',
  };
}

export function deleteCouponModalClearSuccess() {
  return {
    type: '@coupons/DELETE_COUPON_MODAL_CLEAR_SUCCESS',
  };
}

export function updateAvailableCouponsRequest(couponId, couponMode, availableCoupons) {
  return {
    type: '@coupons/UPDATE_AVAILABLE_COUPONS_REQUEST',
    payload: { couponId, couponMode, availableCoupons },
  };
}

export function updateAvailableCouponsSuccess() {
  return {
    type: '@coupons/UPDATE_AVAILABLE_COUPONS_SUCCESS',
  };
}

export function updateAvailableCouponsFailure() {
  return {
    type: '@coupons/UPDATE_AVAILABLE_COUPONS_FAILURE',
  };
}

export function updateAvailableCouponsClearSuccess() {
  return {
    type: '@coupons/UPDATE_AVAILABLE_COUPONS_MODAL_CLEAR_SUCCESS',
  };
}
