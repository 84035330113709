import React from 'react';
import { Spinner } from 'reactstrap';

import { Col, Row } from '../../../../styles/grid';
import { LoginBox, LoginButton, LoginButtonText } from '../styles';
import { preto } from '../../../../styles/colors';
import { 
  FormLogin, 
  LoginInput, 
  LoginInputBox, 
  LoginInputLabel, 
  SeePasswordButton, 
  SeePasswordImage, 
  NormalText 
} from '../../styles';
import { LoadingBox } from '../../../../styles/common';

import px2vw from '../../../../utils/responsiveness/px2vw';
import { 
  btnTextLogin, 
  credentialsRecoverySlug, 
  placeholderEmail, 
  placeholderPassword, 
  slugEmail, 
  slugPassword 
} from '../../constants';

export default function Main({ handler }) {
  return (
      <Col alignCenter margbottom={px2vw(40)}>
        <FormLogin onSubmit={handler.handleSubmit}>
          <LoginInputBox mobile width={px2vw(1400)}>
            <Col height="auto" width="80%">
              <LoginInputLabel mobile>{slugEmail}</LoginInputLabel>
            </Col>
            <LoginBox>
              <LoginInput
                name="email"
                type="email"
                placeholder={placeholderEmail}
                mobile
              />
            </LoginBox>
          </LoginInputBox>
          <LoginInputBox mobile width={px2vw(1400)}>
            <Col height="auto" width="80%">
              <LoginInputLabel mobile>{slugPassword}</LoginInputLabel>
            </Col>
            <LoginBox>
              <LoginInput
                name="password"
                type={handler.passwordVisible ? 'text' : 'password'}
                placeholder={placeholderPassword}
                mobile
              />
              <SeePasswordButton mobile
                type="button"
                onClick={() => handler.setPasswordVisible(!handler.passwordVisible)}
              >
                <SeePasswordImage
                  src={require(handler.passwordVisible
                    ? '../../../../assets/images/eye-outline/eye-outline.png'
                    : '../../../../assets/images/eye-outline/eye-off-outline.png')}
                />
              </SeePasswordButton>
            </LoginBox>
          </LoginInputBox>
          <Row height="auto" alignEnd>
            <NormalText
              passwordAndEmail
              onClick={() => handler.handleForgotPasswordEmail()}
            >
              {credentialsRecoverySlug}
            </NormalText>
          </Row>
          <LoginBox>
            <LoginButton type="submit" disabled={handler.loading}>
              {handler.loading ? (
                <LoadingBox>
                  <Spinner
                    style={{ width: '1.8rem', height: '1.8rem', color: preto }}
                  />{' '}
                </LoadingBox>
              ) : (
                <LoginButtonText>{btnTextLogin}</LoginButtonText>
              )}
            </LoginButton>
          </LoginBox>
        </FormLogin>
      </Col>
  )
}