export function getComplementsRequest() {
  return {
    type: '@massHandleComplements/GET_COMPLEMENTS_REQUEST',
  };
}

export function setComplements(list) {
  return {
    type: '@massHandleComplements/SET_COMPLEMENTS',
    payload: list,
  };
}

export function setIsEditingAmount(data) {
  return {
    type: '@massHandleComplements/SET_IS_EDITING_AMOUNT',
    payload: data,
  };
}

export function updateComplementAmountRequest(amount) {
  return {
    type: '@massHandleComplements/UPDATE_COMPLEMENT_AMOUNT_REQUEST',
    payload: amount,
  };
}

export function updateComplementAmountSuccess(id, amount) {
  return {
    type: '@massHandleComplements/UPDATE_COMPLEMENT_AMOUNT_SUCCESS',
    payload: { id, amount },
  };
}

export function updateComplementAmountFailure() {
  return {
    type: '@massHandleComplements/UPDATE_COMPLEMENT_AMOUNT_FAILURE',
  };
}

export function updateComplementStatusRequest(list, status) {
  return {
    type: '@massHandleComplements/UPDATE_COMPLEMENT_STATUS_REQUEST',
    payload: { list, status },
  };
}

export function updateComplementStatusSuccess(list, status) {
  return {
    type: '@massHandleComplements/UPDATE_COMPLEMENT_STATUS_SUCCESS',
    payload: { list, status },
  };
}

export function updateComplementStatusFailure() {
  return {
    type: '@massHandleComplements/UPDATE_COMPLEMENT_STATUS_FAILURE',
  };
}

export function deleteComplementsRequest(list) {
  return {
    type: '@massHandleComplements/DELETE_COMPLEMENTS_REQUEST',
    payload: { list },
  };
}

export function deleteComplementsSuccess(list) {
  return {
    type: '@massHandleComplements/DELETE_COMPLEMENTS_SUCCESS',
    payload: { list },
  };
}

export function deleteComplementsFailure() {
  return {
    type: '@massHandleComplements/DELETE_COMPLEMENTS_FAILURE',
  };
}
