import React, { useState, useEffect } from 'react';

import { FormControlLabel } from '@material-ui/core';
import { Spinner } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  ConfigGroup,
  SaveButton,
  SaveButtonText,
  ConfigGroupTitle,
  ConfigGroupContent,
  StoreForm,
  CloseButton,
  CloseImage,
  ButtonPrimary,
  Span,
} from '../styles';

import WorkShiftDeleteModal from '../WorkShiftDeleteModal';

import {
  SwitchController,
  SwitchLabelRegularText,
  SwitchWithInput,
  SwitchWithInputBox,
  SwitchInput,
  LoadingBox,
} from '../../_layouts/styles/common';

import { Row, Col } from '../../../styles/grid';

import px2vw from '../../../utils/responsiveness/px2vw';
import { timeMask } from '../../../utils/masks';
import { isTimeValid } from '../../../utils/validations/validations';

import { cinzaKuppi4, preto } from '../../../styles/colors';
import {
  clearSuccess,
  updateStoreAvailabilityRequest,
} from '../../../store/modules/myStore/actions';

import StoreAvailabilitiesOnboardingMobile from '../../Onboarding/Step2-DeliveryParams/Mobile/StoreAvailabilitiesOnboardingMobile';

function StoreAvailabilities(props) {
  const [weekDays, setWeekDays] = useState([
    {
      name: 'sunday',
      is_active: false,
      start_time: '',
      end_time: '',
    },
    {
      name: 'monday',
      is_active: false,
      start_time: '',
      end_time: '',
    },
    {
      name: 'tuesday',
      is_active: false,
      start_time: '',
      end_time: '',
    },
    {
      name: 'wednesday',
      is_active: false,
      start_time: '',
      end_time: '',
    },
    {
      name: 'thursday',
      is_active: false,
      start_time: '',
      end_time: '',
    },
    {
      name: 'friday',
      is_active: false,
      start_time: '',
      end_time: '',
    },
    {
      name: 'saturday',
      is_active: false,
      start_time: '',
      end_time: '',
    },
  ]);
  const [weekDaysWorkShiftTwo, setWeekDaysWorkShiftTwo] = useState([
    {
      name: 'sunday',
      is_active: false,
      start_time: '',
      end_time: '',
    },
    {
      name: 'monday',
      is_active: false,
      start_time: '',
      end_time: '',
    },
    {
      name: 'tuesday',
      is_active: false,
      start_time: '',
      end_time: '',
    },
    {
      name: 'wednesday',
      is_active: false,
      start_time: '',
      end_time: '',
    },
    {
      name: 'thursday',
      is_active: false,
      start_time: '',
      end_time: '',
    },
    {
      name: 'friday',
      is_active: false,
      start_time: '',
      end_time: '',
    },
    {
      name: 'saturday',
      is_active: false,
      start_time: '',
      end_time: '',
    },
  ]);
  const [weekDaysWorkShiftThree, setWeekDaysWorkShiftThree] = useState([
    {
      name: 'sunday',
      is_active: false,
      start_time: '',
      end_time: '',
    },
    {
      name: 'monday',
      is_active: false,
      start_time: '',
      end_time: '',
    },
    {
      name: 'tuesday',
      is_active: false,
      start_time: '',
      end_time: '',
    },
    {
      name: 'wednesday',
      is_active: false,
      start_time: '',
      end_time: '',
    },
    {
      name: 'thursday',
      is_active: false,
      start_time: '',
      end_time: '',
    },
    {
      name: 'friday',
      is_active: false,
      start_time: '',
      end_time: '',
    },
    {
      name: 'saturday',
      is_active: false,
      start_time: '',
      end_time: '',
    },
  ]);

  const [hasChanges, setHasChanges] = useState(false);
  const [isWorkShiftOneActive, setIsWorkShiftOneActive] = useState(false);
  const [isWorkShiftTwoActive, setIsWorkShiftTwoActive] = useState(false);
  const [isWorkShiftThreeActive, setIsWorkShiftThreeActive] = useState(true);
  const [isDeletingWorkShift, setIsDeletingWorkShift] = useState('');

  const storeAvailabilities = useSelector(
    (state) => state.user.user.advertiserInfo.advertiserAvailability
  );
  const availabilityLoading = useSelector(
    (state) => state.myStore.availabilityLoading
  );
  const availabilitySuccess = useSelector(
    (state) => state.myStore.availabilitySuccess
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (availabilitySuccess) {
      setHasChanges(false);
      dispatch(clearSuccess());
      if (props.onSuccess) {
        props.onSuccess();
      }
    }
  }, [availabilitySuccess, dispatch]);

  useEffect(() => {
    // Get only the week days from store
    const storeAvailabilitiesWeekDays = Object.keys(storeAvailabilities).filter(
      (k) =>
        k !== 'id' &&
        k !== 'advertiser_info_id' &&
        k !== 'created_at' &&
        k !== 'updated_at'
    );
    // List with changed days
    const listWithChangedDay = [];

    storeAvailabilitiesWeekDays.forEach((dayName) => {
      if (storeAvailabilities[dayName].is_active && !hasChanges && props.isOnboarding) {
        onTimeChanges();
      }

      weekDays.forEach((day, index) => {
        const weekDay = day;
        // the day before each work shift
        const theDayBeforeWorkShift1 =
          index > 0 ? weekDays[index - 1] : weekDays[weekDays.length - 1];
        const theDayBeforeWorkShift2 =
          index > 0
            ? weekDaysWorkShiftTwo[index - 1]
            : weekDaysWorkShiftTwo[weekDays.length - 1];
        const theDayBeforeWorkShift3 =
          index > 0
            ? weekDaysWorkShiftThree[index - 1]
            : weekDaysWorkShiftThree[weekDays.length - 1];

        if (day.name === dayName) {
          weekDay.is_active = storeAvailabilities[dayName].is_active;

          if (storeAvailabilities[dayName].horaries.length >= 3) {
            storeAvailabilities[dayName].horaries.forEach((horary, idx) => {
              let workShiftTwoData = [...weekDaysWorkShiftTwo];
              let workShiftThreeData = [...weekDaysWorkShiftThree];
              const firstWorkShift = storeAvailabilities[dayName].horaries[0];
              const nextWorkShift =
                storeAvailabilities[dayName].horaries.length - 1 === idx
                  ? storeAvailabilities[dayName].horaries[idx - 1]
                  : storeAvailabilities[dayName].horaries[idx + 1];

              if (idx === 0) {
                if (horary.start_at === '00:00') {
                  weekDay.start_time = nextWorkShift.start_at;
                  weekDay.end_time = nextWorkShift.end_at;

                  const theDayBeforeLength =
                    storeAvailabilities[getTheDayBeforeName(dayName)].horaries
                      .length;
                  const DayBeforeFirstHorary =
                    storeAvailabilities[getTheDayBeforeName(dayName)]
                      .horaries[0]?.start_at;

                  if (
                    theDayBeforeLength === 1 ||
                    (theDayBeforeLength === 2 &&
                      DayBeforeFirstHorary === '00:00')
                  ) {
                    theDayBeforeWorkShift1.end_time =
                      storeAvailabilities[dayName].horaries[0]?.end_at;
                  } else if (
                    theDayBeforeLength === 2 ||
                    (theDayBeforeLength === 3 &&
                      DayBeforeFirstHorary === '00:00')
                  ) {
                    theDayBeforeWorkShift2.end_time =
                      storeAvailabilities[dayName].horaries[0]?.end_at;
                  } else if (
                    theDayBeforeLength === 3 ||
                    (theDayBeforeLength === 4 &&
                      DayBeforeFirstHorary === '00:00')
                  ) {
                    theDayBeforeWorkShift3.end_time =
                      storeAvailabilities[dayName].horaries[0]?.end_at;
                  }
                } else {
                  weekDay.start_time = horary.start_at;
                  weekDay.end_time = horary.end_at;
                }
              } else if (idx === 1) {
                if (firstWorkShift.start_at === '00:00') {
                  const isOverMidnight =
                    dayName === 'saturday' &&
                    storeAvailabilities['sunday'].horaries[0]?.start_at ===
                      '00:00' &&
                    nextWorkShift.end_at === '23:59';
                  workShiftTwoData[index].start_time = nextWorkShift.start_at;
                  workShiftTwoData[index].end_time = isOverMidnight
                    ? storeAvailabilities['sunday'].horaries[0]?.end_at
                    : nextWorkShift.end_at;
                  workShiftTwoData[index].is_active = true;
                  setIsWorkShiftTwoActive(true);
                  setWeekDaysWorkShiftTwo(workShiftTwoData);
                  workShiftThreeData[index].start_time = '';
                  workShiftThreeData[index].end_time = '';
                  workShiftThreeData[index].is_active = false;
                  setWeekDaysWorkShiftThree(workShiftThreeData);
                } else {
                  workShiftTwoData[index].start_time = horary.start_at;
                  workShiftTwoData[index].end_time = horary.end_at;
                  workShiftTwoData[index].is_active = true;
                  setIsWorkShiftTwoActive(true);
                  setWeekDaysWorkShiftTwo(workShiftTwoData);
                }
              } else if (idx === 2 && firstWorkShift.start_at !== '00:00') {
                const isOverMidnight =
                  dayName === 'saturday' &&
                  storeAvailabilities['sunday'].horaries[0]?.start_at ===
                    '00:00' &&
                  horary.end_at === '23:59';
                workShiftThreeData[index].start_time = horary.start_at;
                workShiftThreeData[index].end_time = isOverMidnight
                  ? storeAvailabilities['sunday'].horaries[0]?.end_at
                  : horary.end_at;
                workShiftThreeData[index].is_active = true;
                setIsWorkShiftThreeActive(true);
                setWeekDaysWorkShiftThree(workShiftThreeData);
              } else if (idx === 3 && firstWorkShift.start_at === '00:00') {
                workShiftThreeData[index].start_time = horary.start_at;
                workShiftThreeData[index].end_time =
                  dayName === 'saturday'
                    ? storeAvailabilities['sunday'].horaries[0]?.end_at
                    : nextWorkShift.end_at;
                workShiftThreeData[index].is_active = true;
                setIsWorkShiftThreeActive(true);
                setWeekDaysWorkShiftThree(workShiftThreeData);
              }
            });
          } else if (storeAvailabilities[dayName].horaries.length === 2) {
            storeAvailabilities[dayName].horaries.forEach((horary, idx) => {
              let workShiftTwoData = [...weekDaysWorkShiftTwo];
              let workShiftThreeData = [...weekDaysWorkShiftThree];
              const firstWorkShift = storeAvailabilities[dayName].horaries[0];
              const nextWorkShift =
                storeAvailabilities[dayName].horaries.length - 1 === idx
                  ? storeAvailabilities[dayName].horaries[idx - 1]
                  : storeAvailabilities[dayName].horaries[idx + 1];

              if (idx === 0) {
                if (horary.start_at === '00:00') {
                  const isOverMidnight =
                    dayName === 'saturday' &&
                    storeAvailabilities['sunday'].horaries[0]?.start_at ===
                      '00:00' &&
                    nextWorkShift.end_at === '23:59';
                  weekDay.start_time = nextWorkShift.start_at;
                  weekDay.end_time = isOverMidnight
                    ? storeAvailabilities['sunday'].horaries[0]?.end_at
                    : nextWorkShift.end_at;

                  workShiftTwoData[index].start_time = '';
                  workShiftTwoData[index].end_time = '';
                  workShiftTwoData[index].is_active = false;
                  setWeekDaysWorkShiftTwo(workShiftTwoData);
                  workShiftThreeData[index].start_time = '';
                  workShiftThreeData[index].end_time = '';
                  workShiftThreeData[index].is_active = false;
                  setWeekDaysWorkShiftThree(workShiftThreeData);

                  const theDayBeforeLength =
                    storeAvailabilities[getTheDayBeforeName(dayName)].horaries
                      .length;
                  const DayBeforeFirstHorary =
                    storeAvailabilities[getTheDayBeforeName(dayName)]
                      .horaries[0]?.start_at;

                  if (
                    theDayBeforeLength === 1 ||
                    (theDayBeforeLength === 2 &&
                      DayBeforeFirstHorary === '00:00')
                  ) {
                    theDayBeforeWorkShift1.end_time =
                      storeAvailabilities[dayName].horaries[0]?.end_at;
                  } else if (
                    theDayBeforeLength === 2 ||
                    (theDayBeforeLength === 3 &&
                      DayBeforeFirstHorary === '00:00')
                  ) {
                    theDayBeforeWorkShift2.end_time =
                      storeAvailabilities[dayName].horaries[0]?.end_at;
                  } else if (
                    theDayBeforeLength === 3 ||
                    (theDayBeforeLength === 4 &&
                      DayBeforeFirstHorary === '00:00')
                  ) {
                    theDayBeforeWorkShift3.end_time =
                      storeAvailabilities[dayName].horaries[0]?.end_at;
                  }
                } else {
                  weekDay.start_time = horary.start_at;
                  weekDay.end_time = horary.end_at;
                }
              } else if (idx === 1 && firstWorkShift?.start_at !== '00:00') {
                const isOverMidnight =
                  dayName === 'saturday' &&
                  storeAvailabilities['sunday'].horaries[0]?.start_at ===
                    '00:00' &&
                  horary.end_at === '23:59';
                workShiftTwoData[index].start_time = horary.start_at;
                workShiftTwoData[index].end_time = isOverMidnight
                  ? storeAvailabilities['sunday'].horaries[0]?.end_at
                  : horary.end_at;
                workShiftTwoData[index].is_active = true;
                setIsWorkShiftTwoActive(true);
                setWeekDaysWorkShiftTwo(workShiftTwoData);

                workShiftThreeData[index].is_active = false;
                setWeekDaysWorkShiftThree(workShiftThreeData);

                if (workShiftTwoData[index].end_time === '23:59') {
                  handleToggleDayActive(
                    workShiftThreeData[index].name,
                    3,
                    true
                  );
                }
              }
            });
          } else if (storeAvailabilities[dayName].horaries.length === 1) {
            let workShiftTwoData = [...weekDaysWorkShiftTwo];
            let workShiftThreeData = [...weekDaysWorkShiftThree];

            if (
              storeAvailabilities[dayName].horaries.length &&
              storeAvailabilities[dayName].horaries[0]?.start_at !== '00:00'
            ) {
              storeAvailabilities[dayName].horaries.forEach((horary) => {
                const isOverMidnight =
                  dayName === 'saturday' &&
                  storeAvailabilities['sunday'].horaries[0]?.start_at ===
                    '00:00' &&
                  horary.end_at === '23:59';
                weekDay.start_time = horary.start_at;
                weekDay.end_time = isOverMidnight
                  ? storeAvailabilities['sunday'].horaries[0]?.end_at
                  : horary.end_at;
              });
            } else {
              weekDay.is_active = false;
              const theDayBeforeLength =
                storeAvailabilities[getTheDayBeforeName(dayName)].horaries
                  .length;
              const DayBeforeFirstHorary =
                storeAvailabilities[getTheDayBeforeName(dayName)].horaries[0]?.start_at;
              if (
                theDayBeforeLength === 1 ||
                (theDayBeforeLength === 2 && DayBeforeFirstHorary === '00:00')
              ) {
                theDayBeforeWorkShift1.end_time =
                  storeAvailabilities[dayName].horaries[0]?.end_at;
              } else if (
                theDayBeforeLength === 2 ||
                (theDayBeforeLength === 3 && DayBeforeFirstHorary === '00:00')
              ) {
                theDayBeforeWorkShift2.end_time =
                  storeAvailabilities[dayName].horaries[0]?.end_at;
              } else if (
                theDayBeforeLength === 3 ||
                (theDayBeforeLength === 4 && DayBeforeFirstHorary === '00:00')
              ) {
                theDayBeforeWorkShift3.end_time =
                  storeAvailabilities[dayName].horaries[0]?.end_at;
              }
            }

            workShiftTwoData[index].is_active = false;
            setWeekDaysWorkShiftTwo(workShiftTwoData);

            workShiftThreeData[index].is_active = false;
            setWeekDaysWorkShiftThree(workShiftThreeData);
          }
        }

        // Since I'm using forEach and I'm changing the day before,
        // I use this array to store the changes
        listWithChangedDay[index] = weekDay;
      });
    });
    setWeekDays(listWithChangedDay);
  }, [storeAvailabilities]);

  useEffect(() => {
    const isAtLeastOneDayActiveWorkShiftOne = weekDays.some(
      (day) => day.is_active
    );
    const isAtLeastOneDayActiveWorkShiftTwo = weekDaysWorkShiftTwo.some(
      (day) => day.is_active
    );
    const isAtLeastOneDayActiveWorkShiftThree = weekDaysWorkShiftThree.some(
      (day) => day.is_active
    );

    if (!isAtLeastOneDayActiveWorkShiftOne) {
      setIsWorkShiftOneActive(false);
    } else {
      setIsWorkShiftOneActive(true);
    }

    if (!isAtLeastOneDayActiveWorkShiftTwo) {
      setIsWorkShiftTwoActive(false);
    }

    if (!isAtLeastOneDayActiveWorkShiftThree) {
      setIsWorkShiftThreeActive(false);
    }
  }, [weekDays, weekDaysWorkShiftTwo, weekDaysWorkShiftThree]);

  const onTimeChanges = () => setHasChanges(true);

  const handleToggleDayActive = (dayName, workShift, isLastHorary) => {
    onTimeChanges();
    if (workShift === 1 || !workShift) {
      const listWithChangedDay = weekDays.map((day) => {
        const weekDay = day;
        if (day.name === dayName) {
          weekDay.is_active = !day.is_active;
          weekDay.start_time = '';
          weekDay.end_time = '';
        }
        return weekDay;
      });

      setWeekDays(listWithChangedDay);
    } else if (workShift === 2) {
      const listWithChangedDay = weekDaysWorkShiftTwo.map((day) => {
        const weekDay = day;
        if (day.name === dayName) {
          weekDay.is_active = isLastHorary ? false : !day.is_active;
          weekDay.start_time = '';
          weekDay.end_time = '';
        }
        return weekDay;
      });

      setWeekDaysWorkShiftTwo(listWithChangedDay);
    } else if (workShift === 3) {
      const listWithChangedDay = weekDaysWorkShiftThree.map((day) => {
        const weekDay = day;
        if (day.name === dayName) {
          weekDay.is_active = isLastHorary ? false : !day.is_active;
          weekDay.start_time = '';
          weekDay.end_time = '';
        }
        return weekDay;
      });

      setWeekDaysWorkShiftThree(listWithChangedDay);
    }
  };

  const handleOnChangeDayInput = (options, workShift) => {
    onTimeChanges();

    const { dayName, startTime, endTime } = options;

    if (workShift === 1 || !workShift) {
      const listWithChangedDay = weekDays.map((day) => {
        const weekDay = day;
        if (day.name === dayName) {
          if (startTime || startTime === '') {
            weekDay.start_time = startTime;
          }
          if (endTime || endTime === '') {
            weekDay.end_time = endTime;
          }

          const start = startTime || weekDay?.start_time;
          const end = endTime || weekDay?.end_time;

          if (start?.length >= 5 && end?.length >= 5) {
            if (
              parseInt(start?.substring(0, 2), 10) >
              parseInt(end?.substring(0, 2), 10)
            ) {
              weekDay.end_time = end;
              handleToggleDayActive(day.name, 2, true);
              handleToggleDayActive(day.name, 3, true);
            }
          }
        }
        return weekDay;
      });

      setWeekDays(listWithChangedDay);
    } else if (workShift === 2) {
      const listWithChangedDay = weekDaysWorkShiftTwo.map((day) => {
        const weekDay = day;

        if (day.name === dayName) {
          if (startTime || startTime === '') {
            weekDay.start_time = startTime;
          }
          if (endTime || endTime === '') {
            weekDay.end_time = endTime;
          }

          const start = startTime || weekDay?.start_time;
          const end = endTime || weekDay?.end_time;
          if (start?.length >= 5 && end?.length >= 5) {
            if (
              parseInt(start?.substring(0, 2), 10) >
              parseInt(end?.substring(0, 2), 10)
            ) {
              weekDay.end_time = end;
              handleToggleDayActive(day.name, 3, true);
            }
          }
        }
        return weekDay;
      });

      setWeekDaysWorkShiftTwo(listWithChangedDay);
    } else if (workShift === 3) {
      const listWithChangedDay = weekDaysWorkShiftThree.map((day) => {
        const weekDay = day;
        if (day.name === dayName) {
          if (startTime || startTime === '') {
            weekDay.start_time = startTime;
          }
          if (endTime || endTime === '') {
            weekDay.end_time = endTime;
          }
        }
        return weekDay;
      });

      setWeekDaysWorkShiftThree(listWithChangedDay);
    }
  };

  const handleStoreAvailabilitySubmit = () => {
    const isAtLeastOneDayActive = weekDays.some((day) => day.is_active);
    const isAtLeastOneDayActiveWorkShiftTwo = weekDaysWorkShiftTwo.some(
      (day) => day.is_active
    );
    const isAtLeastOneDayActiveWorkShiftThree = weekDaysWorkShiftThree.some(
      (day) => day.is_active
    );

    const isAtLeastOneHoraryValid = weekDays.every((day) => {
      const startTime = day.start_time;
      const endTime = day.end_time;
      const isActive = day.is_active;
      // Validate the horaries only if this day is active
      if (isActive) {
        return isTimeValid(startTime) && isTimeValid(endTime);
      }
      return true;
    });

    const isAtLeastOneHoraryValidWorkShiftTwo = weekDaysWorkShiftTwo.every((day) => {
      const startTime = day.start_time;
      const endTime = day.end_time;
      const isActive = day.is_active;
      // Validate the horaries only if this day is active
      if (isActive) {
        return isTimeValid(startTime) && isTimeValid(endTime);
      }
      return true;
    });

    const isAtLeastOneHoraryValidWorkShiftThree = weekDaysWorkShiftThree.every((day) => {
      const startTime = day.start_time;
      const endTime = day.end_time;
      const isActive = day.is_active;
      // Validate the horaries only if this day is active
      if (isActive) {
        return isTimeValid(startTime) && isTimeValid(endTime);
      }
      return true;
    });

    if (
      !isAtLeastOneDayActive &&
      !isAtLeastOneDayActiveWorkShiftTwo &&
      !isAtLeastOneDayActiveWorkShiftThree
    ) {
      toast.error(
        'Deve ser selecionado ao menos um dia para a loja estar aberta.',
        {
          autoClose: 6000,
        }
      );
      return;
    }
    if (!isAtLeastOneHoraryValid || !isAtLeastOneHoraryValidWorkShiftTwo || !isAtLeastOneHoraryValidWorkShiftThree) {
      toast.error(
        'Algum dia foi selecionado, porém o horário não foi informado corretamente. Revise os campos.',
        {
          autoClose: 6000,
        }
      );
      return;
    }

    let areTheTimesInAscendingOrder = true;
    let areTheMinutesInAscendingOrder = true;
    let areHoursAndMinutesEquals = true;
    let closingTimeIsShorterThanOpeningTime = true;
    const updateStoreAvailabilityArgs = [];
    weekDays.forEach((day, idx) => {
      const dayHorariesData = [];

      if (day.is_active) {
        dayHorariesData.push({
          start_time: day.start_time,
          end_time: day.end_time,
        });
      }

      if (weekDaysWorkShiftTwo[idx].is_active) {
        dayHorariesData.push({
          start_time: weekDaysWorkShiftTwo[idx]?.start_time,
          end_time: weekDaysWorkShiftTwo[idx]?.end_time,
        });
        day.is_active = true;
      }

      if (weekDaysWorkShiftThree[idx].is_active) {
        dayHorariesData.push({
          start_time: weekDaysWorkShiftThree[idx]?.start_time,
          end_time: weekDaysWorkShiftThree[idx]?.end_time,
        });
        day.is_active = true;
      }



      // Checking if shifts are in ascending order
      if (dayHorariesData.length === 3) {
        const firstStartTime = parseInt(
          dayHorariesData[0].start_time.substring(0, 2),
          10
        );
        const firstEndTime = parseInt(
          dayHorariesData[0].end_time.substring(0, 2),
          10
        );
        const secondStartTime = parseInt(
          dayHorariesData[1].start_time.substring(0, 2),
          10
        );
        const secondEndTime = parseInt(
          dayHorariesData[1].end_time.substring(0, 2),
          10
        );
        const thirdStartTime = parseInt(
          dayHorariesData[2].start_time.substring(0, 2),
          10
        );
        const thirdEndTime = parseInt(
          dayHorariesData[2].end_time.substring(0, 2),
          10
        );

        // Checking if the hour and minute are the same
        if (
          firstStartTime >= secondStartTime ||
          secondStartTime >= thirdStartTime ||
          secondEndTime === thirdEndTime ||
          (secondEndTime > thirdEndTime && secondStartTime >= thirdStartTime) ||
          thirdStartTime < secondEndTime ||
          firstEndTime > secondStartTime
        ) {
          areTheTimesInAscendingOrder = false;
        }
      } else if (dayHorariesData.length === 2) {
        const firstStartTime = parseInt(
          dayHorariesData[0]?.start_time.substring(0, 2),
          10
        );
        const firstEndTime = parseInt(
          dayHorariesData[0]?.end_time.substring(0, 2),
          10
        );
        const secondStartTime = parseInt(
          dayHorariesData[1]?.start_time.substring(0, 2),
          10
        );

        if (
          firstStartTime >= secondStartTime ||
          firstEndTime > secondStartTime
        ) {
          areTheTimesInAscendingOrder = false;
        }
      }

      // Checking if the opening time is greater than the closing time
      if (dayHorariesData.length === 3) {
        const thirdStartTime = dayHorariesData[2].start_time ? parseInt(
          dayHorariesData[2]?.start_time.substring(0, 2),
          10
        ) : null;
        const thirdEndTime = dayHorariesData[2].end_time ? parseInt(
          dayHorariesData[2]?.end_time.substring(0, 2),
          10
        ) : null;

        const dayAfter = weekDays[day.name === 'saturday' ? 0 : idx + 1];
        const dayAfterStartTime = parseInt(
          dayAfter?.start_time.substring(0, 2),
          10
        );
        const timeCondition = (thirdStartTime > thirdEndTime) && (thirdEndTime > dayAfterStartTime);

        if (timeCondition) {
          closingTimeIsShorterThanOpeningTime = false;
        }
      } else if (dayHorariesData.length === 2) {
        const secondStartTime =dayHorariesData[1].start_time ? parseInt(
          dayHorariesData[1]?.start_time.substring(0, 2),
          10
        ) : null;
        const secondEndTime = dayHorariesData[1].end_time ? parseInt(
          dayHorariesData[1]?.end_time.substring(0, 2),
          10
        ) : null;

        const dayAfter = weekDays[day.name === 'saturday' ? 0 : idx + 1];
        const dayAfterStartTime = parseInt(
          dayAfter?.start_time.substring(0, 2),
          10
        );
        const timeCondition = (secondStartTime > secondEndTime) && (secondEndTime > dayAfterStartTime);

        if (timeCondition) {
          closingTimeIsShorterThanOpeningTime = false;
        }
      } else if (dayHorariesData.length === 1) {
        const firstStartTime = dayHorariesData[0].start_time ? parseInt(
          dayHorariesData[0]?.start_time.substring(0, 2),
          10
        ) : null;
        const firstEndTime = dayHorariesData[0].end_time ? parseInt(
          dayHorariesData[0]?.end_time.substring(0, 2),
          10
        ) : null;

        const dayAfter = weekDays[day.name === 'saturday' ? 0 : idx + 1];
        const dayAfterStartTime = parseInt(
          dayAfter?.start_time.substring(0, 2),
          10
        );
        const timeCondition = (firstStartTime > firstEndTime) && (firstEndTime > dayAfterStartTime);

        if (timeCondition) {
          closingTimeIsShorterThanOpeningTime = false;
        }

      }

      // Checking if shifts are in ascending order
      if (dayHorariesData.length === 3) {
        const firstStartTime = dayHorariesData[0]?.start_time;
        const firstEndTime = dayHorariesData[0]?.end_time;
        const secondStartTime = dayHorariesData[1]?.start_time;
        const secondEndTime = dayHorariesData[1]?.end_time;
        const thirdStartTime = dayHorariesData[2]?.start_time;
        const thirdEndTime = dayHorariesData[2]?.end_time;

        if (
          firstStartTime === firstEndTime &&
          secondStartTime === secondEndTime &&
          thirdStartTime === thirdEndTime
        ) {
          areHoursAndMinutesEquals = false;
        }
      } else if (dayHorariesData.length === 2) {
        const firstStartTime = dayHorariesData[0]?.start_time;
        const firstEndTime = dayHorariesData[0]?.end_time;
        const secondStartTime = dayHorariesData[1]?.start_time;
        const secondEndTime = dayHorariesData[1]?.end_time;

        if (
          firstStartTime === firstEndTime &&
          secondStartTime === secondEndTime
        ) {
          areHoursAndMinutesEquals = false;
        }
      } else if (dayHorariesData.length === 1) {
        const firstStartTime = dayHorariesData[0]?.start_time;
        const firstEndTime = dayHorariesData[0]?.end_time;

        if (firstStartTime === firstEndTime) {
          areHoursAndMinutesEquals = false;
        }
      }

      updateStoreAvailabilityArgs.push(day.is_active, dayHorariesData);
    });

    [...weekDays, ...weekDaysWorkShiftTwo, ...weekDaysWorkShiftThree].forEach((day)=>{
      if(day.is_active){
        const startTime = parseInt(day.start_time.substring(0, 2))
        const endTime = parseInt(day.end_time.substring(0, 2))
        const minutesStartTime = parseInt(day.start_time.substring(3))
        const minutesEndTime = parseInt(day.end_time.substring(3))
        if(startTime === endTime && minutesStartTime > minutesEndTime){
          areTheMinutesInAscendingOrder = false
        }
      }
    })

    if (!areTheTimesInAscendingOrder) {
      toast.error(
        'É necessário que os turnos estejam em ordem crescente. Revise os campos.',
        {
          autoClose: 6000,
        }
      );
      return;
    }

    if (!areTheMinutesInAscendingOrder) {
      toast.error(
        'É necessário que os minutos estejam em ordem crescente. Revise os campos.',
        {
          autoClose: 6000,
        }
      );
      return;
    }

    if (!closingTimeIsShorterThanOpeningTime) {
      toast.error(
        'O horário de abertura não pode ser menor que horário de fechamento do dia anterior. Revise os campos.',
        {
          autoClose: 6000,
        }
      );
      return;
    }

    if (!areHoursAndMinutesEquals) {
      toast.error(
        'Os horários de abertura e fechamento não podem ser iguais.',
        {
          autoClose: 6000,
        }
      );
      return;
    }

    dispatch(updateStoreAvailabilityRequest(...updateStoreAvailabilityArgs));
  };

  const translateDayName = (dayName) => {
    switch (dayName) {
      case 'sunday':
        return 'Domingo';
      case 'monday':
        return 'Segunda';
      case 'tuesday':
        return 'Terça';
      case 'wednesday':
        return 'Quarta';
      case 'thursday':
        return 'Quinta';
      case 'friday':
        return 'Sexta';
      case 'saturday':
        return 'Sábado';
      default:
        return '';
    }
  };

  const getTheDayBeforeName = (dayName) => {
    switch (dayName) {
      case 'sunday':
        return 'saturday';
      case 'monday':
        return 'sunday';
      case 'tuesday':
        return 'monday';
      case 'wednesday':
        return 'tuesday';
      case 'thursday':
        return 'wednesday';
      case 'friday':
        return 'thursday';
      case 'saturday':
        return 'friday';
      default:
        return '';
    }
  };

  const isSaveButtonDisabled = () => {
    return !hasChanges || availabilityLoading;
  };

  const isSaveButtonActive = () => {
    return hasChanges;
  };

  const isLastHoraryOverMidnight = (workShift, idx) => {
    if (
      workShift === 3 &&
      (weekDaysWorkShiftTwo[idx].end_time === '23:59' ||
        parseInt(weekDays[idx]?.start_time?.substring(0, 2), 10) >
          parseInt(weekDays[idx]?.end_time?.substring(0, 2), 10) ||
        parseInt(weekDaysWorkShiftTwo[idx]?.start_time?.substring(0, 2), 10) >
          parseInt(weekDaysWorkShiftTwo[idx]?.end_time?.substring(0, 2), 10))
    ) {
      toast.error(
        'Para ativar um novo turno nesse dia, o horário de fechamento do turno anterior não pode ser após a meia noite.',
        {
          autoClose: 10000,
        }
      );
    } else if (
      (workShift === 2 && weekDays[idx].end_time === '23:59') ||
      parseInt(weekDays[idx]?.start_time?.substring(0, 2), 10) >
        parseInt(weekDays[idx]?.end_time?.substring(0, 2), 10)
    ) {
      toast.error(
        'Para ativar um novo turno nesse dia, o horário de fechamento do turno anterior não pode ser após a meia noite.',
        {
          autoClose: 10000,
        }
      );
    }
  };

  const HandleDeleteWorkShift = (workShift) => {
    const oldWorkShiftArr =
      workShift === '2' ? weekDaysWorkShiftTwo : weekDaysWorkShiftThree;
    const newWorkShiftArr = oldWorkShiftArr.map((day) => {
      day.is_active = false;
      day.start_time = '';
      day.end_time = '';

      return day;
    });

    if (workShift === '2') {
      setWeekDaysWorkShiftTwo(newWorkShiftArr);
      setIsWorkShiftTwoActive(false);
    } else {
      setWeekDaysWorkShiftThree(newWorkShiftArr);
      setIsWorkShiftThreeActive(false);
    }
  };

  const HandleAddNewWorkShift = (e) => {
    e.preventDefault();
    if (isWorkShiftThreeActive && !isWorkShiftTwoActive) {
      setIsWorkShiftTwoActive(true);
    } else if (!isWorkShiftTwoActive) {
      setIsWorkShiftTwoActive(true);
    } else if (isWorkShiftTwoActive && !isWorkShiftThreeActive) {
      setIsWorkShiftThreeActive(true);
    }
  };

  const handler = {
    availabilityLoading: availabilityLoading,
    hasChanges: hasChanges,
    weekDays: weekDays,
    handleStoreAvailabilitySubmit: () => handleStoreAvailabilitySubmit(),
    isSaveButtonDisabled: () => isSaveButtonDisabled(),
    isSaveButtonActive: () => isSaveButtonActive(),
    handleToggleDayActive: (dayName, workShift, isLastHorary) =>
      handleToggleDayActive(dayName, workShift, isLastHorary),
    translateDayName: (dayName) => translateDayName(dayName),
    handleOnChangeDayInput: (options, workShift) =>
      handleOnChangeDayInput(options, workShift),
  };

  // MyStore openned through onboarding at mobile
  if (props.isOnboarding && props.isMobile) {
    return <StoreAvailabilitiesOnboardingMobile handler={handler} />;
  }
  // MyStore openned through onboarding or platform at web
  else {
    return (
      <ConfigGroup>
        <StoreForm onSubmit={handleStoreAvailabilitySubmit}>
          <ConfigGroupContent backgroundColor={props.backgroundColor}>
            <Row>
              <Row justifyStart>
                <ConfigGroupTitle>
                  Defina os horários de funcionamento da sua loja
                </ConfigGroupTitle>
              </Row>
              <SaveButton
                id="myStoreAvailabilityHorariesSaveButton"
                hasChanges={isSaveButtonActive()}
                type="submit"
                disabled={isSaveButtonDisabled()}
              >
                {availabilityLoading ? (
                  <LoadingBox>
                    <Spinner
                      style={{
                        width: '1.8rem',
                        height: '1.8rem',
                        color: preto,
                      }}
                    />{' '}
                  </LoadingBox>
                ) : (
                  <SaveButtonText hasChanges={isSaveButtonActive()}>
                    Salvar
                  </SaveButtonText>
                )}
              </SaveButton>
            </Row>
            <Row justifyStart margtop={px2vw(6)}>
              <Col id="myStoreAvailabilityHoraries" width={px2vw(280)}>
                {weekDays.map((day) => (
                  <SwitchWithInput
                    margleft={px2vw(19)}
                    width="auto"
                    key={day.name}
                    marginBottom={px2vw(5)}
                  >
                    <FormControlLabel
                      control={
                        <SwitchController
                          checked={day.is_active}
                          type="checkbox"
                          onChange={() => handleToggleDayActive(day.name, 1)}
                        />
                      }
                      label={
                        <SwitchLabelRegularText>
                          {translateDayName(day.name)}
                        </SwitchLabelRegularText>
                      }
                    />
                    <SwitchWithInputBox isVisible={true}>
                      <SwitchLabelRegularText>
                        <SwitchInput
                          name={`${day.name}StartTime`}
                          disabled={!day.is_active}
                          maxLength="5"
                          placeholder="11:00"
                          value={day.start_time}
                          onChange={(t) => {
                            handleOnChangeDayInput(
                              {
                                dayName: day.name,
                                startTime: timeMask(t.target.value),
                              },
                              1
                            );
                          }}
                        />
                        <Span
                          display="inline"
                          fontSize={px2vw(11)}
                          fontWeight="500"
                          color={day.is_active ? preto : cinzaKuppi4}
                        >
                          às
                        </Span>
                        <SwitchInput
                          name={`${day.name}EndTime`}
                          disabled={!day.is_active}
                          maxLength="5"
                          placeholder="11:00"
                          value={day.end_time}
                          onChange={(t) => {
                            handleOnChangeDayInput(
                              {
                                dayName: day.name,
                                endTime: timeMask(t.target.value),
                              },
                              1
                            );
                          }}
                        />
                      </SwitchLabelRegularText>
                    </SwitchWithInputBox>
                  </SwitchWithInput>
                ))}
              </Col>

              {isWorkShiftTwoActive && (
                <Col
                  id="myStoreAvailabilityHoraries2"
                  borderLeft={`2px solid ${cinzaKuppi4}`}
                  width={px2vw(260)}
                >
                  {weekDaysWorkShiftTwo.map((day, idx) => (
                    <SwitchWithInput
                      margleft={px2vw(19)}
                      width="auto"
                      key={day.name}
                      marginBottom={px2vw(5)}
                    >
                      <FormControlLabel
                        onClick={() => isLastHoraryOverMidnight(2, idx)}
                        control={
                          <SwitchController
                            checked={day.is_active}
                            disabled={
                              weekDays[idx].end_time === '23:59' ||
                              parseInt(
                                weekDays[idx]?.start_time?.substring(0, 2),
                                10
                              ) >
                                parseInt(
                                  weekDays[idx]?.end_time?.substring(0, 2),
                                  10
                                )
                            }
                            type="checkbox"
                            onChange={() =>
                              isWorkShiftTwoActive
                                ? handleToggleDayActive(day.name, 2)
                                : null
                            }
                          />
                        }
                      />
                      <SwitchWithInputBox isVisible={true}>
                        <SwitchLabelRegularText>
                          <SwitchInput
                            name={`${day.name}StartTime`}
                            disabled={
                              !isWorkShiftTwoActive ||
                              !day.is_active ||
                              weekDays[idx].end_time === '23:59'
                            }
                            maxLength="5"
                            placeholder="11:00"
                            value={day.start_time}
                            onChange={(t) => {
                              handleOnChangeDayInput(
                                {
                                  dayName: day.name,
                                  startTime: timeMask(t.target.value),
                                },
                                2
                              );
                            }}
                          />
                          <Span
                            display="inline"
                            fontSize={px2vw(11)}
                            fontWeight="500"
                            color={day.is_active ? preto : cinzaKuppi4}
                          >
                            às
                          </Span>
                          <SwitchInput
                            name={`${day.name}EndTime`}
                            disabled={
                              !isWorkShiftTwoActive ||
                              !day.is_active ||
                              weekDays[idx].end_time === '23:59'
                            }
                            maxLength="5"
                            placeholder="11:00"
                            value={day.end_time}
                            onChange={(t) => {
                              handleOnChangeDayInput(
                                {
                                  dayName: day.name,
                                  endTime: timeMask(t.target.value),
                                },
                                2
                              );
                            }}
                          />
                        </SwitchLabelRegularText>
                      </SwitchWithInputBox>
                      {idx === 0 && (
                        <CloseButton
                          onClick={(e) => {
                            e.preventDefault();
                            setIsDeletingWorkShift('2');
                          }}
                        >
                          <CloseImage
                            src={require('../../../assets/images/close/close.png')}
                          />
                        </CloseButton>
                      )}
                    </SwitchWithInput>
                  ))}
                </Col>
              )}

              {isWorkShiftThreeActive && (
                <Col
                  id="myStoreAvailabilityHoraries3"
                  borderLeft={`2px solid ${cinzaKuppi4}`}
                  width={px2vw(260)}
                >
                  {weekDaysWorkShiftThree.map((day, idx) => (
                    <SwitchWithInput
                      margleft={px2vw(19)}
                      width="auto"
                      key={day.name}
                      marginBottom={px2vw(5)}
                    >
                      <FormControlLabel
                        onClick={() => isLastHoraryOverMidnight(3, idx)}
                        control={
                          <SwitchController
                            checked={
                              day.is_active &&
                              weekDaysWorkShiftTwo[idx].end_time !== '23:59'
                            }
                            disabled={
                              weekDaysWorkShiftTwo[idx].end_time === '23:59' ||
                              parseInt(
                                weekDays[idx]?.start_time?.substring(0, 2),
                                10
                              ) >
                                parseInt(
                                  weekDays[idx]?.end_time?.substring(0, 2),
                                  10
                                ) ||
                              parseInt(
                                weekDaysWorkShiftTwo[
                                  idx
                                ]?.start_time?.substring(0, 2),
                                10
                              ) >
                                parseInt(
                                  weekDaysWorkShiftTwo[
                                    idx
                                  ]?.end_time?.substring(0, 2),
                                  10
                                )
                            }
                            type="checkbox"
                            onChange={() => handleToggleDayActive(day.name, 3)}
                          />
                        }
                      />
                      <SwitchWithInputBox isVisible={true}>
                        <SwitchLabelRegularText>
                          <SwitchInput
                            name={`${day.name}StartTime`}
                            disabled={
                              !day.is_active ||
                              weekDaysWorkShiftTwo[idx]?.end_time === '23:59' ||
                              parseInt(
                                weekDaysWorkShiftTwo[
                                  idx
                                ]?.start_time?.substring(0, 2),
                                10
                              ) >
                                parseInt(
                                  weekDaysWorkShiftTwo[
                                    idx
                                  ]?.end_time?.substring(0, 2),
                                  10
                                )
                            }
                            maxLength="5"
                            placeholder="11:00"
                            value={day.start_time}
                            onChange={(t) => {
                              handleOnChangeDayInput(
                                {
                                  dayName: day.name,
                                  startTime: timeMask(t.target.value),
                                },
                                3
                              );
                            }}
                          />
                          <Span
                            display="inline"
                            fontSize={px2vw(11)}
                            fontWeight="500"
                            color={day.is_active ? preto : cinzaKuppi4}
                          >
                            às
                          </Span>
                          <SwitchInput
                            name={`${day.name}EndTime`}
                            disabled={
                              !day.is_active ||
                              weekDaysWorkShiftTwo[idx]?.end_time === '23:59' ||
                              parseInt(
                                weekDaysWorkShiftTwo[
                                  idx
                                ]?.start_time?.substring(0, 2),
                                10
                              ) >
                                parseInt(
                                  weekDaysWorkShiftTwo[
                                    idx
                                  ]?.end_time?.substring(0, 2),
                                  10
                                )
                            }
                            maxLength="5"
                            placeholder="11:00"
                            value={day.end_time}
                            onChange={(t) => {
                              handleOnChangeDayInput(
                                {
                                  dayName: day.name,
                                  endTime: timeMask(t.target.value),
                                },
                                3
                              );
                            }}
                          />
                        </SwitchLabelRegularText>
                      </SwitchWithInputBox>
                      {idx === 0 && (
                        <CloseButton
                          onClick={(e) => {
                            e.preventDefault();
                            setIsDeletingWorkShift('3');
                          }}
                        >
                          <CloseImage
                            src={require('../../../assets/images/close/close.png')}
                          />
                        </CloseButton>
                      )}
                    </SwitchWithInput>
                  ))}
                </Col>
              )}

              {!(
                isWorkShiftOneActive &&
                isWorkShiftTwoActive &&
                isWorkShiftThreeActive
              ) && (
                <ButtonPrimary onClick={(e) => HandleAddNewWorkShift(e)}>
                  Adicionar Turno
                </ButtonPrimary>
              )}
            </Row>
          </ConfigGroupContent>
        </StoreForm>
        {isDeletingWorkShift && (
          <WorkShiftDeleteModal
            width={px2vw(410)}
            height={px2vw(210)}
            isVisible={true}
          >
            <Col>
              <Row justifyEnd>
                <CloseButton
                  className="ml-auto"
                  onClick={() => setIsDeletingWorkShift('')}
                >
                  <CloseImage
                    height={px2vw(18)}
                    width={px2vw(18)}
                    src={require('../../../assets/images/close/close.png')}
                  />
                </CloseButton>
              </Row>
              <Row>
                <Span
                  fontSize={px2vw(25)}
                  fontWeight="700"
                  color={preto}
                  className="mx-auto"
                >
                  Exclusão de turno
                </Span>
              </Row>
              <Row margtop={px2vw(5)}>
                <Span
                  fontSize={px2vw(13)}
                  fontWeight="400"
                  color={preto}
                  className="mx-auto text-center"
                >
                  Tem certeza que deseja excluir o turno inteiro? <br />
                  Se sim, basta clicar no botão abaixo
                </Span>
              </Row>
              <Row margtop={px2vw(20)}>
                <ButtonPrimary
                  width={px2vw(140)}
                  onClick={() => {
                    HandleDeleteWorkShift(isDeletingWorkShift);
                    setIsDeletingWorkShift('');
                    setHasChanges(true);
                  }}
                >
                  Excluir
                </ButtonPrimary>
              </Row>
            </Col>
          </WorkShiftDeleteModal>
        )}
      </ConfigGroup>
    );
  }
}

export default StoreAvailabilities;
