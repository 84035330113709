export function sort(arr, col, direction) {
  const currentArr = [...arr];

  if (currentArr && currentArr.length) {
    if (direction === 'asc') {
      currentArr.sort((a, b) => {
        if (a[col] > b[col]) return 1;
        if (a[col] < b[col]) return -1;
        return 0;
      });
    }
    if (direction === 'desc') {
      currentArr.sort((a, b) => {
        if (a[col] < b[col]) return 1;
        if (a[col] > b[col]) return -1;
        return 0;
      });
    }
  }
  return currentArr;
}

export function sortByColumnValue(arr, col, direction) {
  const currentArr = [...arr];

  if (currentArr && currentArr.length) {
    if (direction === 'asc') {
      currentArr.sort((a, b) => {
        if (col === 'expiration_date') {
          // Dates in american format MM/dd/yyyy
          const vigencyA = new Date(
            `${a.expiration_date.substring(
              0,
              2
            )}/01/20${a.expiration_date.substring(2, 4)}`
          );
          const vigencyB = new Date(
            `${b.expiration_date.substring(
              0,
              2
            )}/01/20${b.expiration_date.substring(2, 4)}`
          );

          if (vigencyA - vigencyB === 0) {
            // Changed the return for Chrome, to avoid error when order the array
            return navigator.userAgent.indexOf('Chrome') !== -1 ? -1 : 1;
          }
          return vigencyA - vigencyB;
        }
      });
    }
    if (direction === 'desc') {
      currentArr.sort((a, b) => {
        if (col === 'expiration_date') {
          // Dates in american format MM/dd/yyyy
          const vigencyA = new Date(
            `${a.expiration_date.substring(
              0,
              2
            )}/01/20${a.expiration_date.substring(2, 4)}`
          );
          const vigencyB = new Date(
            `${b.expiration_date.substring(
              0,
              2
            )}/01/20${b.expiration_date.substring(2, 4)}`
          );

          if (vigencyB - vigencyA === 0) {
            // Changed the return for Chrome, to avoid error when order the array
            return navigator.userAgent.indexOf('Chrome') !== -1 ? -1 : 1;
          }
          return vigencyB - vigencyA;
        }
      });
    }
  }
  return currentArr;
}

export function makePages(length, perPage) {
  if (length > 0 && perPage > 0) {
    const countKeys = Math.ceil(length / perPage);
    const keys = Array.from(Array(countKeys), (_, i) => i + 1);
    const pages = {};
    let start = 1;
    let end = perPage;

    keys.forEach((item, index) => {
      if (index === keys.length - 1) {
        pages[item] = {
          start,
          end: length,
        };
        start = start + perPage - 1;
        end = end + perPage - 1;
      } else {
        pages[item] = { start, end };
        start += perPage;
        end += perPage;
      }
    });

    return pages;
  }
}
