import styled from 'styled-components';

import px2vw from '../../utils/responsiveness/px2vw';

import { branco, cinzaKuppi4 } from '../../styles/colors';

export const AcceptingOrdersBox = styled.div`
  background-color: ${branco};
  border-radius: ${px2vw(20)};
  padding-left: ${px2vw(15)};
  padding-right: ${px2vw(15)};
  padding-top: ${px2vw(15)};
  padding-bottom: ${px2vw(5)};

  box-shadow: 0px 8px 9px -2px ${cinzaKuppi4};
`;
