import styled from 'styled-components';
import Table from '@material-ui/core/Table';

import px2vw from '../../utils/responsiveness/px2vw';

import { branco, cinzaKuppi4, cinzaKuppi8, preto } from '../../styles/colors';



export const ChartName = styled.div`
  font-size: ${px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.36px;
  margin-top: ${px2vw(10)};
`;

export const ChartOptionColorBox = styled.div`
  background-color: ${(props) => props.bgcolor};
  width: ${px2vw(5)};
  height: ${px2vw(20)};
  margin-top: ${(props) => props.margtop};
`;

export const ChartOptionName = styled.div`
  font-size: ${px2vw(12)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.32px;
  margin-top: ${(props) => props.margtop};
`;

export const ActionsMenuBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: ${px2vw(20)};
  height: ${px2vw(40)};
`;

export const SearchBox = styled.div`
  display: flex;
  flex-direction: row;
`;

export const SearchButton = styled.button`
  margin-top: ${px2vw(6)};
  margin-left: ${px2vw(-40)};
  width: ${px2vw(20)};
  height: ${px2vw(20)};
  border: none;
  z-index: 10;
  background-color: transparent;

  ${(props) => props.hasActiveSearch && `
    margin-left: ${px2vw(-60)};
  `};
`;

export const SearchCloseButton = styled.button`
  margin-top: ${px2vw(6)};
  margin-left: ${px2vw(2)};
  width: ${px2vw(20)};
  height: ${px2vw(20)};
  border: none;
  z-index: 10;
  background-color: transparent;
`;

export const SearchImage = styled.img`
  width: ${px2vw(20)};
  height: ${px2vw(20)};

  ${(props) => props.hasActiveSearch && `
    width: ${px2vw(15)};
    height: ${px2vw(15)};
  `};
`;

export const SearchInput = styled.input`
  padding: 1em;
  padding-left: ${px2vw(10)};
  margin-left: 0.5em;
  margin-right: ${px2vw(10)};
  margin-bottom: ${px2vw(10)};
  border: 1px solid ${cinzaKuppi4};
  border-radius: 5px;
  font-size: ${px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.32px;
  width: ${px2vw(320)};
  height: ${px2vw(30)};
`;

export const TableClients = styled(Table)`
  min-width: 650;
`;

export const ClientsHeaderBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;

  ${(props) => `
    width: ${props.width};
  `};
`;

export const ClientsHeaderText = styled.text`
  font-size: ${px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.36px;

  ${(props) => `
    margin-left: ${props.margleft};
  `};
`;

export const HeaderOrderButton = styled.button`
  height: ${px2vw(21)};
  width: ${px2vw(21)};
  margin-left: ${px2vw(10)};
  border: none;
  background-color: transparent;
`;

export const ClientsHeaderImage = styled.img`
  height: ${px2vw(20)};
  width: ${px2vw(20)};
  margin-bottom: ${px2vw(-4)};
`;

export const ClientsRowText = styled.div`
  font-size: ${px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.36px;

  ${(props) => `
    margin-left: ${props.margleft};
  `};
`;

export const ClientsDataBox = styled.div`
  width: ${px2vw(1020)};
  background-color: ${branco};
  margin-left: ${px2vw(5)};
  margin-top: ${px2vw(30)};
  border-radius: ${px2vw(20)};
  padding: 0 ${px2vw(25)};
  padding-top: ${px2vw(15)};
  box-shadow: 0px 8px 9px -2px ${cinzaKuppi4};
`;

export const ErrorText = styled.div`
  font-size: ${px2vw(20)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.4px;
  margin-left: ${px2vw(20)};
`;

export const ChartBox = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${branco};
  border-radius: ${px2vw(20)};
  width: ${px2vw(450)};
  height: ${px2vw(150)};
  padding-top: ${(props) => props.isTitleBox ? '' : px2vw(17)};
  box-shadow: 0px 8px 9px -2px ${cinzaKuppi4};

  ${(props) => `
    margin-left: ${props.margleft};
  `}

  ${(props) => props.isTitleBox && `
    padding: ${px2vw(20)} 0 0 ${px2vw(20)} ;
    width: ${px2vw(380)};
    height: ${px2vw(240)};
    flex-direction: column;
  
  `}

  ${(props) => props.isTagBox && `
    padding: ${px2vw(5)};
    width: ${px2vw(200)};
    height: ${px2vw(114)};
    flex-direction: column;
    align-items: center;
    justify-items: center;
    margin-left: ${px2vw(10)};

  `}
`;

export const TitleStyleText = styled.div`
  font-size: ${px2vw(24)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.36px;
  margin-right: ${(props) => props.margright};
  
  ${(props) => props.hugeNumber && `
    font-size: ${px2vw(35)};
  `}

  ${(props) => props.isTagBox && `
    font-size: ${px2vw(15)};
  `}

  ${(props) => props.isModalTitle && `
    font-size: ${px2vw(18)};
  `}

  ${(props) => props.isModalSubTitle && `
    font-size: ${px2vw(15)};
  `}

  ${(props) => props.isModalSubText && `
    font-size: ${px2vw(12)};
    font-weight: normal;
  `}

  ${(props) => props.isModalSmallText && `
    font-size: ${px2vw(10)};
    font-weight: normal;
    text-align: center;
  `}
`;

export const RegularStyleText = styled.div`
  font-size: ${px2vw(16)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.36px;
  
  width: 70%;
  margin-left: ${px2vw(40)};

  ${(props) => props.isTagBox && `
    margin-top: ${px2vw(10)};
    font-size: ${px2vw(10)};
    margin-left: ${px2vw(2)};
    width: 100%;
    text-align: center;
  `}
`;

export const BoxSubtitle = styled.div`
  font-size: ${px2vw(15)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.36px;
`;

export const IconBox = styled.div`
  height: ${px2vw(20)};
  width: ${px2vw(20)};
  margin-left: ${px2vw(20)};
  margin-top: ${px2vw(-10)};
`;

export const BoxIcon = styled.img`
`;

export const ColoredBar = styled.div`
  height: 100%;
  width: ${px2vw(8)};
  margin-right: ${px2vw(7)};
  background-color: ${(props) => props.bgColor};
`;

export const DetailsButton = styled.button`
  height: ${px2vw(30)};
  width: ${px2vw(70)};
  border-radius: ${px2vw(10)};
  border: 2px solid ${preto};
  color: ${preto};
  background-color: ${branco};
  font-family: AvenirNextLTPro;
  font-weight: 700;

  ${(props) => props.disabled && `
    border: 2px solid ${preto}40;
    color: ${preto}40;
  `}
`;

export const FidelityBar = styled.div`
  height: ${px2vw(3)};
  width: 100%;
  margin-right: ${px2vw(7)};
  border-radius: 50px;
  background-color: ${(props) => props.bgColor};
`;