import styled from 'styled-components';

import px2vw from '../../utils/responsiveness/px2vw';

export const ConfirmPreparingSchedulingOrderModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ConfirmPreparingSchedulingOrderText = styled.div`
  font-size: ${px2vw(20)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.48px;
  margin-left: ${px2vw(15)};
  margin-right: ${px2vw(15)};
  margin-top: ${px2vw(20)};
  text-align: center;
`;
