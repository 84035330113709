export const PageTitleSuccess = 'Pix emitido com sucesso!';
export const PageTextBlocked = `Enviamos os dados do pix para seu e-mail. Você ainda precisa
pagá-lo até a data de expiração para re-ativar a seu acesso. Assim
que o crédito do pix for identificado, você receberá um email confirmando o pagamento e o acesso a plataforma da Kuppi será liberado.`;
export const PageTextRenewNotBlocked = `Enviamos os dados do pix para seu e-mail. Você ainda precisa
pagá-lo até a data de expiração para renovar a sua assinatura. Assim
que o crédito do pix for identificado, você receberá um email confirmando o pagamento.`;
export const PageTextFirstPayment = `Enviamos os dados do pix para seu e-mail. Você ainda precisa
pagá-lo até a data de expiração para ativar a sua assinatura. Assim
que o crédito do pix for identificado, a confirmação de pagamento, bem como os seus dados de acesso a
plataforma da Kuppi e demais instruções serão enviadas para o seu email.`;
export const PixAmountText = 'Valor do pix';
export const PixExpirationText = 'Data de expiração';
export const PixExpirationDateText = 'Hoje às 23:59:57';
export const PaymentInfo1 = 'Para pagar, escaneie o QR Code no aplicativo do seu banco:';
export const PaymentInfo2 = 'Ou';
export const PaymentInfo3 = 'clique no botão abaixo para copiar o código:';
export const PaymentInfo1Mobile = 'Clique no botão abaixo para copiar o código de pagamento:';
