import React from 'react';
import WhatsappPhone from '../../../assets/images/Advertiser_activation_bar/whatsapp_phone.svg';
import { Text } from '../../../styles/common';
import px2vw from '../../../utils/responsiveness/px2vw';
import { verdeKuppi5 } from '../../../styles/colors';
import { useChallenge } from '../Context';
import { massMessage } from '../../../routes/routeMap';
import history from '../../../services/history';
import { Button, Card, CloseModal, Container, DetailsImage, ImageContainer } from './styled';

export const MassMessageModal = () => {
  const { showMassMessageModal, setShowMassMessageModal } = useChallenge();

  return (
    <>
      {showMassMessageModal && (
        <Container>
          <Card>
            <CloseModal
              onClick={() => {
                setShowMassMessageModal(false);
              }}
            >
              clear
            </CloseModal>
            <ImageContainer>
              <DetailsImage src={WhatsappPhone} />
            </ImageContainer>
            <Text margtop={px2vw(20)} size={px2vw(14)} alignCenter>
              Essa é uma ferramenta poderosa para alavancar suas vendas!
            </Text>
            <Text margtop={px2vw(10)} regular alignCenter fontsize={px2vw(11)}>
              Você consegue enviar promoções em massa para todos os números que
              já falaram com o WhatsApp da sua loja, até os que você não tem o
              contato salvo!
            </Text>
            <Text margtop={px2vw(10)} regular alignCenter fontsize={px2vw(11)}>
              Você acabou de ganhar um disparo teste (até 1000 mensagens) para
              ver a mágica funcionando e divulgar seu cardápio novo da Kuppi!
            </Text>

            <Button
              onClick={() => history.push(massMessage)}
              style={{
                fontWeight: 700,
                backgroundColor: verdeKuppi5,
                borderRadius: px2vw(3),
              }}
            >
              <Text size={px2vw(11)} alignCenter>
                Programar meu disparo teste
              </Text>
            </Button>
          </Card>
        </Container>
      )}
    </>
  );
};
