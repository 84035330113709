import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { Col, Row } from '../../styles/grid';
import { amareloKuppi6 } from '../../styles/colors';

import ButtonPrimary from '../ButtonPrimary';

import icon_clock from '../../assets/images/onboarding/icon_clock@2x.png';
import px2vw from '../../utils/responsiveness/px2vw';
import { Image } from '../../styles/common';
import { Header, NormalText } from './styles';
import { clearSuccess } from '../../store/modules/challenges/actions';
import { MassMessageModal } from './MassMessageModal';
import { useChallenge } from './Context';
import { ProgressModal } from './ProgressModal';

const leftPad = (value) => `${value}`.padStart(2, '0');

export default function ActivationChallengeBar({
  initial,
  countDown = [0, 0, 0],
  award = '',
}) {
  const challengesSuccess = useSelector(
    (state) => state.challenges.challengesSuccess
  );
  const isFinishedOnboarding = useSelector(
    (state) => state.user.user.advertiserInfo.is_done_onboarding
  );
  const [counter, setCounter] = useState('00:00:00');

  const dispatch = useDispatch();

  const [hours, minutes, seconds] = countDown;

  const { setShowChallengeDetails, showChallengeDetails } = useChallenge();

  useEffect(() => {
    if (hours + minutes + seconds >= 0) {
      setCounter(`${leftPad(hours)}:${leftPad(minutes)}:${leftPad(seconds)}`);
    } else {
      setCounter('00:00:00');
    }
  }, [hours, minutes, seconds]);

  useEffect(() => {
    if (challengesSuccess) {
      dispatch(clearSuccess());
    }
  }, [challengesSuccess]);

  return (
    <>
      <Header initial={initial}>
        <Row width="auto" alignCenter>
          <NormalText
            color={amareloKuppi6}
            padleft={px2vw(50)}
            fontsize={px2vw(20)}
            fontweight="bold"
          >
            Desafio da Ativação
          </NormalText>
        </Row>

        <Col width="auto">
          <NormalText color={amareloKuppi6} fontsize={px2vw(13)}>
            {`Conclua seu desafio hoje e receba ${award}`}
          </NormalText>
        </Col>

        <Row width="auto" alignCenter>
          <NormalText
            color={amareloKuppi6}
            padright={px2vw(15)}
            fontsize={px2vw(25)}
            fontweight="bold"
          >
            {counter}
          </NormalText>
          <Image src={icon_clock} height={px2vw(20)} width={px2vw(25)} />
        </Row>

        <Row width="auto" alignCenter>
          <Col margright={px2vw(50)} width="auto" height="auto">
            <ButtonPrimary
              height="30"
              onClick={() => {
                if (!isFinishedOnboarding) {
                  toast.error(
                    'Conclua as configurações para realizar a tarefa',
                    {
                      autoClose: 6000,
                    }
                  );
                } else {
                  setShowChallengeDetails(!showChallengeDetails);
                }
              }}
            >
              Ver desafio
            </ButtonPrimary>
          </Col>
        </Row>
      </Header>

      <MassMessageModal />
      
    </>
  );
}
