import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'reactstrap';

import {
  BigText, Container, FilterButton,
  ImgContainer, MediumText, SearchInput,
  SmallCardContainer, SmallText
} from '../styles';
import { preto } from '../../../styles/colors';
import { LoadingBox } from '../../../styles/common';
import { Col, Row } from '../../../styles/grid';

import RatingsTable from './RatingsTable';

import px2vw from '../../../utils/responsiveness/px2vw';
import { onlyNumbers } from '../../../utils/utils';

import {
  getRatingsRequest,
  getRatingByOrderIdRequest
} from '../../../store/modules/ratings/actions';

export default function ReportsPage() {
  const fullStar = require("../../../assets/images/ratings/star.png");
  const halfStar = require("../../../assets/images/ratings/star_half_full.png");
  const emptyStar = require("../../../assets/images/ratings/star_empty.png")
  const [orderNumberSearchInputValue, setOrderNumberSearchInputValue] = useState('');
  const {
    ratingsList,
    ratingsPagination,
    ratingsData,
    getRatingByOrderIdLoading,
  } = useSelector((state) => state.ratings)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getRatingsRequest());
  }, [])

  const HandleStarRating = ({ stars, miniBox }) => {
    let arr = []
    for (let i = 0; i < Math.floor(stars); i++) {
      arr.push(<ImgContainer miniBox={miniBox} key={`#imgKey${i}Id`} src={fullStar} />)
    }
    let leftovers = stars?.toString().split(".")[1];
    if (leftovers > 0) {
      arr.push(<ImgContainer miniBox={miniBox} key={`#imgKey${stars}Id`} src={halfStar} />)
    }
    while (arr.length < 5) {
      arr.push(<ImgContainer height={px2vw(28)} width={px2vw(32)} miniBox={miniBox} key={`#imgKey${stars}Id`} src={emptyStar} />)
    }

    return (
      <>
        {arr.map((item) => {
          return item
        })}
      </>
    )
  }

  const VotesSummary = ({ title, subtotalratingsQty, subtotalPercentage, stars, miniBox = false }) => {
    return (
      <SmallCardContainer>
        <SmallText color={preto}>{title}</SmallText>
        <MediumText underline>{subtotalPercentage?.toString().replace(".", ",")}%</MediumText>
        <SmallText color={preto}>{subtotalratingsQty} respostas</SmallText>
        <Row>
          <HandleStarRating stars={stars} miniBox={miniBox} />
        </Row>
      </SmallCardContainer>
    )
  }

  return (
    <>
      <Container margbottom={px2vw(-10)}>
        <Col padtop={px2vw(20)}>
          <Row alignCenter width={px2vw(400)}>
            <Col width={px2vw(300)}>
              <MediumText>Sua avaliação geral é</MediumText>
              <Row alignCenter margleft={px2vw(35)}>
                <Col>
                  <BigText margleft={px2vw(5)}>{(Math.trunc(ratingsData?.rating * 100) / 100).toString().replace(".", ",")} de 5</BigText>
                  <MediumText underline padLeft={px2vw(5)} >{ratingsData?.total_ratings} respostas</MediumText>
                </Col>
              </Row>
            </Col>
            <Row>
              <HandleStarRating stars={ratingsData?.rating} />
            </Row>
          </Row>
          <Row spaceAround margleft={px2vw(-15)} margbottom={px2vw(10)}>
            <VotesSummary
              title={'Muito Ruim'}
              subtotalPercentage={ratingsData?.very_bad?.percent}
              subtotalratingsQty={ratingsData?.very_bad?.rating_qty}
              stars={1}
              miniBox
            />
            <VotesSummary
              title={'Ruim'}
              subtotalPercentage={ratingsData?.bad?.percent}
              subtotalratingsQty={ratingsData?.bad?.rating_qty}
              stars={2}
              miniBox
            />
            <VotesSummary
              title={'Bom'}
              subtotalPercentage={ratingsData?.good?.percent}
              subtotalratingsQty={ratingsData?.good?.rating_qty}
              stars={3}
              miniBox
            />
            <VotesSummary
              title={'Muito Bom'}
              subtotalPercentage={ratingsData?.very_good?.percent}
              subtotalratingsQty={ratingsData?.very_good?.rating_qty}
              stars={4}
              miniBox
            />
            <VotesSummary
              title={'Excelente'}
              subtotalPercentage={ratingsData?.excellent?.percent}
              subtotalratingsQty={ratingsData?.excellent?.rating_qty}
              stars={5}
              miniBox
            />
          </Row>
        </Col>
      </Container>
      <Container>
        <Row justifyStart padtop={px2vw(20)}>
          <SearchInput
            maxLength="10"
            placeholder="Busque pelo número do pedido"
            value={orderNumberSearchInputValue}
            onChange={(t) => {
              setOrderNumberSearchInputValue(onlyNumbers(t.target.value))
            }}
          />
          <FilterButton
            onClick={() => orderNumberSearchInputValue?.length > 0 && dispatch(getRatingByOrderIdRequest(orderNumberSearchInputValue))}
            disabled={getRatingByOrderIdLoading}
            isActive={orderNumberSearchInputValue?.length > 0}
          >
            {getRatingByOrderIdLoading ?
              <LoadingBox>
                <Spinner
                  style={{
                    width: '1.8rem',
                    height: '1.8rem',
                    color: preto,
                  }}
                />
              </LoadingBox> : 'Consultar'}
          </FilterButton>
          <FilterButton
            onClick={() => {
              setOrderNumberSearchInputValue('');
              dispatch(getRatingsRequest());
            }}
            isActive={ratingsList?.length === 0 || ratingsList?.length === 1}
            disabled={!(ratingsList?.length === 0 || ratingsList?.length === 1)}
          >
            Ver Todas
          </FilterButton>
        </Row>
        <RatingsTable
          data={ratingsList}
          pagination={ratingsPagination}
        />
      </Container>
    </>
  )
}
