import styled, { css } from 'styled-components';

import {
  azulKuppi11,
  branco,
  cinzaKuppi1,
  cinzaKuppi2,
  cinzaKuppi4,
  cinzaKuppi7,
  cinzaKuppi8,
  preto,
  verdeKuppi2,
} from '../../../../styles/colors';
import { Col } from '../../../../styles/grid';

import px2vw from '../../../../utils/responsiveness/px2vw';

export const ModalHeader = styled.div`
  width: 100%;
  background-color: ${branco};
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: ${px2vw(33)} ${px2vw(28)} ${px2vw(15)} ${px2vw(28)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  font-size: ${px2vw(25)};
  display: flex;
  align-items: space-between;
  justify-content: space-between;
`;

export const CloseButton = styled.button`
  width: ${px2vw(18)};
  height: ${px2vw(18)};
  border: none;
  z-index: 10;
  background-color: transparent;

  :focus {
    background-color: transparent !important;
  }

  ${(props) =>
    props.absolute &&
    css`
      position: absolute;
      top: ${props.top || px2vw(10)};
      right: ${props.right || px2vw(10)};
    `}
`;

export const CloseImage = styled.img`
  width: ${px2vw(15)};
  height: ${px2vw(15)};
`;

export const ModalContent = styled.div`
  background-color: ${branco};
  padding: ${(props) => props.padding || '20px'};
  padding-right: ${px2vw(20)};
  padding-bottom: 0;
  display: flex;
  justify-content: center;
  ${(props) => `
    width: ${props.width};
    min-height: ${props.height || px2vw(400)};
    max-height: ${props.maxheight || px2vw(600)};
    background-color: ${props.bgcolor};
    margin-top: ${props.margtop};
  `};
`;

export const ModalFooter = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: ${px2vw(90)};
  margin-top: auto;
  background-color: ${branco};
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: ${px2vw(15)} ${px2vw(32)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  font-size: ${px2vw(22)};
`;

export const TextArea = styled.textarea`
  width: ${(props) => props.w || '100%'};
  font-size: ${px2vw(11)};
  min-height: ${(props) => props.h || px2vw(170)};
  resize: none;
  border-radius: 8px;
  border-color: ${cinzaKuppi4};
  padding: ${px2vw(10)} ${px2vw(15)};
  ${(props) =>
    props.bg &&
    `
    background:${props.bg};
  `}
`;

export const MessageContainer = styled(Col)`
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 0em;
    border-radius: 7px;
  }
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    border-radius: px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: transparent;
    outline: 1px solid transparent;
    border-radius: 7px;
  }

`;

export const TextBox = styled.div`
  background: ${branco};
  font-size: ${px2vw(8)};
  width: auto;
  min-width: ${px2vw(150)};
  max-width: ${px2vw(240)};
  height: fit-content;
  padding: ${px2vw(9)} ${px2vw(10)};
  padding-left: ${px2vw(10)};
  z-index: 5;
  white-space: pre-wrap;
  word-wrap: break-word;
  position: relative;
  border-top-left-radius: ${(props) => (props.first ? 'none' : px2vw(8))};
  border-bottom-left-radius: ${px2vw(8)};
  border-top-right-radius: ${px2vw(8)};
  border-bottom-right-radius: ${px2vw(8)};
  margin-top: ${(props) => props.margtop || 0};
  padding-right: ${(props) => props.padright || 0};
  ${(props) =>
    props.me &&
    `
  background: ${branco};

  `}
`;

export const PhoneImg = styled.img`
  width: 100%;
  max-height: ${px2vw(460)};
  position: abosulte;
  left: 0;
`;

export const MyMessage = styled.img`
  width: ${px2vw(55)};
  position: absolute;
`;

export const ConversationIndicatorImg = styled.img`
  position: absolute;
  left: ${px2vw(-10)};
  top: ${px2vw(0)};
`;

export const UploadIcon = styled.img`
  width: ${px2vw(15)};
  margin-right: ${px2vw(5)};
`;

export const ModalSecundaryButtonText = styled.div`
  padding: 0 ${px2vw(5)};
  font-size: ${(props) => (props.size ? props.size : px2vw(17))};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.36px;
  ${(props) =>
    props.center &&
    `
    display: flex;
    align-items:center;
    justify-content:center;
  `}
`;

export const ModalSecundaryButton = styled.button`
  background-color: ${branco};
  width: ${px2vw(2180)};
  height: ${(props) => (props.height ? props.height : px2vw(35))};
  border: 2px solid ${preto};
  border-radius: ${px2vw(5)};
  padding: 0.3em;
  display: flex;
  align-items: center;
  :focus {
    background-color: ${branco} !important;
  }

  :hover {
    background-color: ${cinzaKuppi2};
  }

  ${(props) => `
    margin-top: ${props.margtop};
    width: ${props.width};
    margin-right: ${props.marginRight};
    margin-left: ${props.margleft};
  `};

  ${(props) =>
    props.disabled &&
    `
  border: 2px solid ${cinzaKuppi4};
  .button{
    color:${cinzaKuppi4};
  }

  `};
`;

export const InfoBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: ${px2vw(11)};
  height: ${(props) => props.h || px2vw(110)};
  border-radius: 8px;
  border: 2px solid ${cinzaKuppi4};
  padding: ${px2vw(10)} ${px2vw(10)};
  background: ${cinzaKuppi1};
  margin-top: ${px2vw(10)};
  margin-bottom: ${(props) => props.mb || 0};
  position: relative;
`;

export const MsgBox = styled.div`
  width: ${(props) => props.w || 'auto'};
  font-size: ${px2vw(10)};
  border-radius: 8px;
  border: 2px solid ${cinzaKuppi4};
  padding: ${px2vw(8)} ${px2vw(8)};
  background: ${cinzaKuppi8};
  margin-top: ${px2vw(10)};
  display: flex;
  ${(props) =>
    props.selected &&
    `
    color: ${azulKuppi11};
  `}
  cursor: pointer;
`;

export const OverFlowBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-right: ${px2vw(8)};
  max-height: ${px2vw(400)};
`;

export const WhatsImg = styled.img`
  height: ${px2vw(135)};
  max-width: ${px2vw(210)};
  margin-bottom: ${px2vw(10)};
`;

export const Thumb = styled.img`
  height: ${px2vw(20)};
`;

export const CheckBox = styled.div`
  border-radius: 4px;
  width: ${px2vw(15)};
  height: ${px2vw(15)};
  border: 3px solid ${cinzaKuppi7};
  padding: 10px;
  margin-right: ${px2vw(6)};
  cursor: pointer;
  ${(props) => props.checked && `background: ${verdeKuppi2};`}
`;