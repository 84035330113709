import styled from 'styled-components';

import px2vw from '../../../utils/responsiveness/px2vw';

import {
  cinzaKuppi1,
} from '../../../styles/colors';

export const CampaignStatsBox = styled.div`
  background-color: ${cinzaKuppi1};
  border-radius: ${px2vw(10)};
  border: none;
  width: ${px2vw(300)};
  height: ${px2vw(380)};
  padding: ${px2vw(15)};
`;
