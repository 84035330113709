import { takeLatest, call, put, all, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import {
  dashboard,
  login as loginRoute,
  firstSteps,
  blockedSignaturePayment,
  onboarding,
  askDownloadApp
} from '../../../routes/routeMap';

import {
  apiGlobal,
  apiAnunciante,
} from '../../../services/api';
import history from '../../../services/history';

import {
  clearFields,
  loginSuccess,
  loginFailure,
  recoverEmailSuccess,
  recoverEmailFailure,
  adminAccessSuccess,
  adminAccessFailure,
  verifyResetAdminPasswordTokenSuccess,
  verifyResetAdminPasswordTokenFailure,
  changeAdminPasswordSuccess,
  changeAdminPasswordFailure,
  logoutSuccessFailure,
  logout as logoutAction,
} from './actions';
import {
  updateTrialInfo
} from '../user/actions';
import { setBalance, setFacebookId, setInstagramId, setKuppiBoost, setOnboardingData } from '../kuppiBoost/actions';

export function* loginRequest({ payload }) {
  try {
    const { email, password, isMobile } = payload;

    const response = yield call(apiGlobal.post, '/auth', {
      email,
      password,
    });

    const { token, user, advertiserFreeTrialData } = response.data;

    // Verify if the user is active
    if (!user.is_active) {
      toast.error('O Usuário não está ativo. Entre em contato com suporte.', {
        autoClose: 6000,
      });
      yield put(loginFailure());
      return;
    }

    // Verify if the user has the advertiser permission access
    const userRoles = [];

    user.roles.forEach((role) => {
      userRoles.push(role.slug);
    });

    const userIsAdvertiser = userRoles.includes('advertiser');

    if (!userIsAdvertiser) {
      toast.error(
        'O Usuário não possui permissões para acesso. Entre em contato com suporte.',
        {
          autoClose: 6000,
        }
      );
      yield put(loginFailure());
      return;
    }

    // Set kuppi boost data and them remove it from user object
    // Boost campaigns and payments
    yield put(setKuppiBoost(user.advertiserInfo.advertiserBoostCampaign, [], [], user.advertiserInfo.advertiserBoostPayment));
    delete user.advertiserInfo.advertiserBoostCampaign;
    delete user.advertiserInfo.advertiserBoostPayment;
    // Boost configs
    const advertiserBoostConfig = user.advertiserInfo.advertiserBoostConfig[0];
    delete advertiserBoostConfig.created_at;
    delete advertiserBoostConfig.updated_at;
    yield put(setBalance(advertiserBoostConfig.balance));
    yield put(setOnboardingData(advertiserBoostConfig));
    if (advertiserBoostConfig.facebook_page_id || advertiserBoostConfig.instagram_page_id) {
      yield put(setFacebookId(advertiserBoostConfig.facebook_page_id || null));
      yield put(setInstagramId(advertiserBoostConfig.instagram_page_id || null));
    }
    delete user.advertiserInfo.advertiserBoostConfig;

    // Set login sucess
    yield put(loginSuccess(token, user));
    yield put(updateTrialInfo(advertiserFreeTrialData))

    apiGlobal.defaults.headers.common.Authorization = `Bearer ${token.token}`;
    apiAnunciante.defaults.headers.common.Authorization = `Bearer ${token.token}`;

    // Force waiting to redirect to the destine page
    setTimeout(() => {
      // Blocked user
      if (user.advertiserInfo.is_overdue_payment) {
        history.push(blockedSignaturePayment);
      }
      // Need to answer survey
      else if (
        user.is_initial_validations &&
        !user.advertiserInfo.is_finished_first_steps
      ) {
        history.push(firstSteps);
      }
      // Need to finish onboarding
      else if (!user.advertiserInfo.is_done_onboarding) {
        history.push(onboarding);
      }
      // If user is in mobile, go to app download page instead of dashboard
      else if (isMobile) {
        history.push(askDownloadApp);
      }
      // Directs to dashboard
      else {
        history.push(dashboard);
      }
    }, 50);
  } catch (err) {
    if (err.response?.status === 403) {
      toast.error('O Usuário não está ativo. Entre em contato com suporte.', {
        autoClose: 6000,
      }); 
    } else {    
      toast.error(`Falha na autenticação. Verifique seus dados.`, {
        autoClose: 6000,
      });
    }
    
    yield put(loginFailure());     
  }
}

export function* loginAdminRequest({ payload }) {
  try {
    const { email, password, advertiserToken } = payload;

    const response = yield call(apiGlobal.post, '/auth/admin', {
      email,
      password,
      advertiser_token: advertiserToken
    });

    const { token, user, advertiserFreeTrialData } = response.data;

    // Verify if the user is active
    if (!user.is_active) {
      toast.error('O Usuário não está ativo. Entre em contato com suporte.', {
        autoClose: 6000,
      });
      yield put(loginFailure());
      return;
    }

    // Verify if the user has the advertiser permission access
    const userRoles = [];

    user.roles.forEach((role) => {
      userRoles.push(role.slug);
    });

    const userIsAdvertiser = userRoles.includes('advertiser');

    if (!userIsAdvertiser) {
      toast.error(
        'O Usuário não possui permissões para acesso. Entre em contato com suporte.',
        {
          autoClose: 6000,
        }
      );
      yield put(loginFailure());
      return;
    }

    // Set kuppi boost data and them remove it from user object
    // Boost campaigns and payments
    yield put(setKuppiBoost(user.advertiserInfo.advertiserBoostCampaign, [], [], user.advertiserInfo.advertiserBoostPayment));
    delete user.advertiserInfo.advertiserBoostCampaign;
    delete user.advertiserInfo.advertiserBoostPayment;
    // Boost configs
    const advertiserBoostConfig = user.advertiserInfo.advertiserBoostConfig[0];
    delete advertiserBoostConfig.created_at;
    delete advertiserBoostConfig.updated_at;
    yield put(setBalance(advertiserBoostConfig.balance));
    yield put(setOnboardingData(advertiserBoostConfig));
    if (advertiserBoostConfig.facebook_page_id || advertiserBoostConfig.instagram_page_id) {
      yield put(setFacebookId(advertiserBoostConfig.facebook_page_id || null));
      yield put(setInstagramId(advertiserBoostConfig.instagram_page_id || null));
    }
    delete user.advertiserInfo.advertiserBoostConfig;

    // Set login sucess
    yield put(loginSuccess(token, user));
    yield put(updateTrialInfo(advertiserFreeTrialData))

    apiGlobal.defaults.headers.common.Authorization = `Bearer ${token.token}`;
    apiAnunciante.defaults.headers.common.Authorization = `Bearer ${token.token}`;

    // Force waiting to redirect to the destine page
    setTimeout(() => {
      // Blocked user
      if (user.advertiserInfo.is_overdue_payment) {
        history.push(blockedSignaturePayment);
      }
      // Need to answer survey
      else if (
        user.is_initial_validations &&
        !user.advertiserInfo.is_finished_first_steps
      ) {
        history.push(firstSteps);
      }
      // Need to finish onboarding
      else if (!user.advertiserInfo.is_done_onboarding) {
        history.push(onboarding);
      }
      // Directs to dashboard
      else {
        history.push(dashboard);
      }
    }, 50);
  } catch (err) {
    toast.error(`Falha na autenticação. Verifique seus dados.`, {
      autoClose: 6000,
    });
    
    yield put(loginFailure());     
  }
}

export function setToken({ payload }) {
  if (!payload) return;

  const { token } = payload.auth;

  if (token) {
    apiGlobal.defaults.headers.common.Authorization = `Bearer ${token}`;
    apiAnunciante.defaults.headers.common.Authorization = `Bearer ${token}`;

  }
}

export function* logout({ payload }) {
  try {
    if (!payload.invalidSession) {
      const user = yield select((state) => state.user.user);

      yield call(
        apiGlobal.delete,
        `/user/${user.id}/logout`
      );
    }

    yield put(logoutSuccessFailure())

    history.push(`${loginRoute}${payload.invalidSession ? '?erro=sessao-invalida' : ''}`);
  } catch (err) {
    yield put(logoutSuccessFailure())

    history.push(loginRoute)
  }
}

export function* recoverPasswordRequest({ payload }) {
  const { email } = payload;

  yield call(apiGlobal.post, '/forgot-password', { email });

  yield put(clearFields());
}

export function* recoverEmailRequest({ payload }) {
  try {
    const response = yield call(apiGlobal.post, '/forgot-email', {
      name: payload.name,
      cpf_cnpj: payload.cnpj,
    });

    yield put(recoverEmailSuccess(response.data.email));
  } catch (err) {
    yield put(recoverEmailFailure());
  }
}

export function* adminAccessRequest({ payload }) {
  try {
    const user = yield select((state) => state.user.user);

    yield call(
      apiAnunciante.post,
      `/user-info/${user.advertiserInfo.id}/confirm-admin-access`,
      {
        password: payload.password,
      }
    );

    yield put(adminAccessSuccess());
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logoutAction(true))
    } else {
      yield put(adminAccessFailure());

      toast.error(`Falha na autenticação. Verifique seus dados.`, {
        autoClose: 6000,
      });
    }
  }
}

export function* verifyResetAdminPasswordTokenRequest({ payload }) {
  try {
    yield call(apiAnunciante.post, `/verify-administrator-token`, {
      token: payload.token,
    });

    yield put(verifyResetAdminPasswordTokenSuccess());
  } catch (err) {
    yield put(verifyResetAdminPasswordTokenFailure());
  }
}

export function* changeAdminPasswordRequest({ payload }) {
  try {
    yield call(apiAnunciante.put, `/forgot-administrator-password`, {
      token: payload.token,
      password: payload.newPassword,
    });

    yield put(changeAdminPasswordSuccess());
  } catch (err) {
    yield put(changeAdminPasswordFailure());

    toast.error(
      `Ocorreu um erro ao redefinir a senha. Tente novamente e caso o erro persista, entre em contato com o suporte!`,
      {
        autoClose: 6000,
      }
    );
  }
}

export default all([
  takeLatest('persist/REHYDRATE', setToken),
  takeLatest('@auth/LOGIN_REQUEST', loginRequest),
  takeLatest('@auth/LOG_OUT_REQUEST', logout),
  takeLatest('@auth/RECOVER_PASSWORD_REQUEST', recoverPasswordRequest),
  takeLatest('@auth/RECOVER_EMAIL_REQUEST', recoverEmailRequest),
  takeLatest('@auth/ADMIN_ACCESS_REQUEST', adminAccessRequest),
  takeLatest(
    '@auth/VERIFY_RESET_ADMIN_PASSWORD_TOKEN_REQUEST',
    verifyResetAdminPasswordTokenRequest
  ),
  takeLatest('@auth/CHANGE_ADMIN_PASSWORD_REQUEST', changeAdminPasswordRequest),
  takeLatest('@auth/LOGIN_ADMIN_REQUEST', loginAdminRequest),
]);
