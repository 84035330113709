import React, {
  Fragment,
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';

import px2vw from '../../../../../utils/responsiveness/px2vw';
import { BsChevronDown } from 'react-icons/bs';
import {
  Header,
  Content,
  Hr,
  TableRow,
  HeaderButton,
  PagButton,
  PrevIcon,
  NextIcon,
  Container,
} from './styles';

import { sort, makePages } from './tableOptions';

import TablePerPage from '../../../../../components/TablePerPage';
import { Col, Row } from '../../../../../styles/grid';
import { Text } from '../../../../../styles/common';
import { branco, cinzaKuppi11 } from '../../../../../styles/colors';
import moment from 'moment';

export default function HistoryTable({ data }) {
  const [rows, setRows] = useState(data);
  const [rowsCurrentPage, setRowsCurrentPage] = useState();
  const [structure, setStructure] = useState(null);
  const [currentPage, setCurrentPage] = useState('1');
  const [lastPage, setLastPage] = useState();
  const [firstPage] = useState('1');
  const [sortedCol, setSortedCol] = useState('id');

  useEffect(() => {
    if (structure) {
      setLastPage(
        Object.keys(structure)[Object.keys(structure).length - 1].toString()
      );
    }
  }, [structure]);

  useMemo(() => {
    setStructure(makePages(rows.length, rowsCurrentPage));
  }, [rows, rowsCurrentPage]);

  useEffect(() => {
    setRows(data);
  }, [data]);

  useEffect(() => {
    if (rows.length < 5) {
      setRowsCurrentPage(rows.length);
    }
    if (rowsCurrentPage > 5) {
    } else {
      setRowsCurrentPage(5);
    }
  }, [rows]);

  const handleSort = useCallback(
    (col, inside) => {
      if (sortedCol !== col) {
        setSortedCol(col);
        if (inside) {
          setRows(sort(rows, col, 'asc', true));
        } else {
          setRows(sort(rows, col, 'asc'));
        }
      } else {
        setSortedCol('');
        if (inside) {
          setRows(sort(rows, col, 'desc', true));
        } else {
          setRows(sort(rows, col, 'desc'));
        }
      }
    },
    [rows]
  );

  function handlePerPage(size) {
    setRowsCurrentPage(size);
    setCurrentPage('1');
  }

  function handleChangePage(direction) {
    if (direction === 'next') {
      const curr = +currentPage + 1;
      setCurrentPage(curr.toString());
    } else if (direction === 'prev') {
      const curr = +currentPage - 1;
      setCurrentPage(curr.toString());
    }
  }

  function renderTrophy(number) {
    if (number === 0) {
      return 'Sem troféus';
    }

    const trophyPic = (
      <img
        style={{ width: px2vw(18), margin: `0 ${px2vw(1.5)}` }}
        src={require('../../../../../assets/images/gamification_medals/trophy.png')}
        alt="Troféu"
      />
    );

    const trophys = [];
    for (let i = 0; i < number; i++) {
      trophys.push(trophyPic);
    }

    return trophys;
  }

  function formatMonthDisplay(referenceDate) {
    const formattedReferenceDate = moment(referenceDate.substring(0, 10), 'YYYY-MM-DD').format('MMMM/YYYY');
    return formattedReferenceDate.charAt(0).toUpperCase() + formattedReferenceDate.slice(1);
  }

  moment.locale('pt-br');

  return (
    <Col
      borderRadius={px2vw(12)}
      background={branco}
      style={{ boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)' }}
      padding={`0 ${px2vw(15)}`}
      padbottom={px2vw(15)}
      alignCenter
      justifyCenter
    >
      <Row
        height={px2vw(45)}
        borderRadius={`${px2vw(12)} ${px2vw(12)} 0 0`}
        background={cinzaKuppi11 + '34'}
        alignCenter
      >
        <Header width="25%" onClick={() => handleSort('reference_month_at')}>
          <HeaderButton>
            Mês
            <BsChevronDown
              style={{
                fontSize: px2vw(15),
                fontWeight: 'bolder',
                marginLeft: px2vw(7),
                transform:
                  sortedCol === 'reference_month_at' ? 'rotate(180deg)' : '',
                transition: 'all 0.2s ease-in-out',
              }}
            />
          </HeaderButton>
        </Header>

        <Header width="30%" onClick={() => handleSort('month_order_qty')}>
          <HeaderButton>
            Nº total de pedidos
            <BsChevronDown
              style={{
                fontSize: px2vw(15),
                fontWeight: 'bolder',
                marginLeft: px2vw(7),
                transform: sortedCol === 'month_order_qty' ? 'rotate(180deg)' : '',
                transition: 'all 0.2s ease-in-out',
              }}
            />
          </HeaderButton>
        </Header>

        <Header width="45%" onClick={() => handleSort('award_qty')}>
          <HeaderButton>
            Quantidade de troféus
            <BsChevronDown
              style={{
                fontSize: px2vw(15),
                fontWeight: 'bolder',
                marginLeft: px2vw(7),
                transform: sortedCol === 'award_qty' ? 'rotate(180deg)' : '',
                transition: 'all 0.2s ease-in-out',
              }}
            />
          </HeaderButton>
        </Header>
      </Row>
      <Row>
        <Hr />
      </Row>
      <Container>
        {structure ? (
          rows.map((item, index) =>
            structure[currentPage] &&
            structure[currentPage].start <= index + 1 &&
            structure[currentPage].end >= index + 1 ? (
              <Fragment key={index}>
                <TableRow
                  relative
                  alignCenter
                  width="100%"
                  minheight={px2vw(65)}
                  className="py-1"
                >
                  <Content justifyStart width="30%">
                    {formatMonthDisplay(item.reference_month_at)}
                  </Content>
                  <Content alignEnd width="30%">
                    {item.month_order_qty}
                  </Content>
                  <Content alignCenter width="50%">
                    {renderTrophy(item.award_qty)}
                  </Content>
                </TableRow>
                <Row>
                  <Hr />
                </Row>
              </Fragment>
            ) : null
          )
        ) : (
          <>
            <Row padding="15px 0 10px 0" alignCenter margtop={px2vw(80)}>
              <Text size={px2vw(15)}>Ainda não há registros</Text>
            </Row>
          </>
        )}
      </Container>
      {structure && (
        <Row alignCenter justifyEnd relative>
          <Row
            absolute
            style={{ bottom: px2vw(-60) }}
            alignCenter
            justifyEnd
            height={px2vw(40)}
            margin={px2vw(10)}
          >
            <TablePerPage response={handlePerPage} length={rows.length} />
            <Row
              style={{ fontSize: px2vw(13) }}
              alignCenter
              justifyCenter
              width="15%"
            >
              {!structure[currentPage] && handleChangePage('prev')}
              {structure[currentPage] && structure[currentPage].start} -{' '}
              {structure[currentPage] && structure[currentPage].end} de{' '}
              {rows.length}
            </Row>
            <PagButton
              onClick={() => handleChangePage('prev')}
              disabled={currentPage === firstPage}
              title="Página anterior"
            >
              <PrevIcon disabled={currentPage === firstPage} />
            </PagButton>
            <PagButton
              onClick={() => handleChangePage('next')}
              title="Próxima página"
              disabled={currentPage === lastPage}
            >
              <NextIcon disabled={currentPage === lastPage} />
            </PagButton>
          </Row>
        </Row>
      )}
    </Col>
  );
}
