import React, {
  Fragment,
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';

import px2vw from '../../../../utils/responsiveness/px2vw';
import { ddiPhoneMask } from '../../../../utils/masks';
import { BsTrash } from 'react-icons/bs';
import { TiPlus } from 'react-icons/ti';
import {
  Header,
  Content,
  Hr,
  TableRow,
  HeaderButton,
  PagButton,
  PrevIcon,
  NextIcon,
  Container,
} from './styles';
import { SvgBox, Arrow, Arrow2 } from './icons';
import { sort, makePages } from './tableOptions';

import TablePerPage from './TablePerPage';
import { Col, Row } from '../../../../styles/grid';

import { useDispatch, useSelector } from 'react-redux';

import {
  removeFromBlackListRequest,
  setDeleteConfig,
  setIsboxVisible,
} from '../../../../store/modules/massMessage/actions';
import { cinzaKuppi14, cinzaKuppi4 } from '../../../../styles/colors';

export default function ClientListTable({ data, cb }) {
  const dispatch = useDispatch();
  const [rows, setRows] = useState(data);
  const [rowsCurrentPage, setRowsCurrentPage] = useState();
  const [structure, setStructure] = useState(null);
  const [currentPage, setCurrentPage] = useState('1');
  const [lastPage, setLastPage] = useState();
  const [firstPage] = useState('1');
  const [sortedCol, setSortedCol] = useState('name');
  const [blacklist, setBlacklist] = useState([]);
  const [isAllClientsSelected, setIsAllClientsSelected] = useState(false);
  const { isBlacklistTableOpen, blacklistRequestLoading } = useSelector(
    (state) => state.massMessage
  );

  useEffect(() => {
    if (structure) {
      setLastPage(
        Object.keys(structure)[Object.keys(structure).length - 1].toString()
      );
    }
  }, [structure]);

  useMemo(() => {
    setStructure(makePages(rows.length, rowsCurrentPage));
  }, [rows, rowsCurrentPage]);

  useEffect(() => {
    setRows(data);
  }, [data]);

  useEffect(() => {
    if (rows.length < 7) {
      setRowsCurrentPage(rows.length);
    }
    if (rowsCurrentPage > 7) {
    } else {
      setRowsCurrentPage(7);
    }
  }, [rows]);

  const handleSort = useCallback(
    (col, inside) => {
      if (sortedCol !== col) {
        setSortedCol(col);
        if (inside) {
          setRows(sort(rows, col, 'asc', true));
        } else {
          setRows(sort(rows, col, 'asc'));
        }
      } else {
        setSortedCol('');
        if (inside) {
          setRows(sort(rows, col, 'desc', true));
        } else {
          setRows(sort(rows, col, 'desc'));
        }
      }
    },
    [rows]
  );

  function handlePerPage(size) {
    setRowsCurrentPage(size);
    setCurrentPage('1');
  }

  function handleChangePage(direction) {
    if (direction === 'next') {
      const curr = +currentPage + 1;
      setCurrentPage(curr.toString());
    } else if (direction === 'prev') {
      const curr = +currentPage - 1;
      setCurrentPage(curr.toString());
    }
  }

  function getClientType(type) {
    switch (type) {
      case 'new':
        return 'Cliente Novo';
      case 'vip':
        return 'VIP';
      case 'potential':
        return 'Potencial Cliente';
      case 'risk':
        return 'Cliente em risco';
      case 'recurrent':
        return 'Recorrente';
      default:
        return ''
    }
  }

  function addToBlackList(contact) {
    const allReadyInBlackList = blacklist?.find((c) => c.id === contact.id);
    if (allReadyInBlackList) {
      setBlacklist(blacklist.filter((c) => c.id !== contact.id));
    } else {
      setBlacklist([...blacklist, contact]);
    }
  }

  function addAllToBlackList() {
    if (rows.length === blacklist.length) {
      setBlacklist([]);
      setIsAllClientsSelected(false);
    } else {
      setBlacklist(rows.map((c) => c));
      setIsAllClientsSelected(true);
    }
  }

  function handleOpenDeleteBox(contact, type) {
    if (type === 'individual') {
      dispatch(setDeleteConfig('individual', contact));
      dispatch(setIsboxVisible(true));
    } else {
      dispatch(setDeleteConfig('multiple', blacklist));
      dispatch(setIsboxVisible(true));
    }
  }

  function handleRemoveFromBlacklist(id) {
    dispatch(removeFromBlackListRequest(id));
  }

  return (
    <Col padding="0 15px" alignCenter justifyCenter>
      <Row justifyStart pad={`0 ${px2vw(10)} 0 ${px2vw(25)}`}>
        {!isBlacklistTableOpen && (
          <Header
            width={px2vw(110)}
            padding={`15px 0 15px ${px2vw(35)}`}
            fontW="500"
          >
            <input
              style={{ cursor: 'pointer' }}
              checked={isAllClientsSelected}
              onClick={addAllToBlackList}
              className="my-auto mr-2"
              type="checkbox"
            />{' '}
            Todos
          </Header>
        )}

        <Header
          width={px2vw(isBlacklistTableOpen ? 137 : 115)}
          onClick={() => handleSort('name')}
        >
          <HeaderButton>
            Nome
            <SvgBox sorted={sortedCol === 'name'}>
              <Arrow style={{ fontSize: px2vw(5) }} />
              <Arrow2 style={{ fontSize: px2vw(5) }} />
            </SvgBox>
          </HeaderButton>
        </Header>

        <Header
          width={px2vw(isBlacklistTableOpen ? 170 : 135)}
          onClick={() => handleSort('phone')}
        >
          <HeaderButton>
            WhatsApp
            <SvgBox sorted={sortedCol === 'phone'}>
              <Arrow style={{ fontSize: px2vw(12) }} />
              <Arrow2 style={{ fontSize: px2vw(12) }} />
            </SvgBox>
          </HeaderButton>
        </Header>

        <Header width={px2vw(120)} onClick={() => handleSort('type_tag')}>
          <HeaderButton column>
            <div>
              Segmentação
              <SvgBox sorted={sortedCol === 'type_tag'}>
                <Arrow style={{ fontSize: px2vw(12) }} />
                <Arrow2 style={{ fontSize: px2vw(12) }} />
              </SvgBox>
            </div>
          </HeaderButton>
        </Header>
        {blacklist.length > 1 && (
          <Header
            style={{ cursor: 'pointer' }}
            className="ml-auto"
            fontW="500"
            width={px2vw(120)}
            onClick={handleOpenDeleteBox}
          >
            <BsTrash fontSize={px2vw(15)} style={{ marginRight: px2vw(4) }} />
            Ação em Massa
          </Header>
        )}
      </Row>
      <Row>
        <Hr />
      </Row>
      <Container>
        {structure ? (
          rows.map((item, index) =>
            structure[currentPage] &&
            structure[currentPage].start <= index + 1 &&
            structure[currentPage].end >= index + 1 ? (
              <Fragment key={item?.id}>
                <TableRow
                  alignCenter
                  justifyStart
                  pad={`0 ${px2vw(10)} 0 ${px2vw(25)}`}
                  minheight={px2vw(35)}
                >
                  {!isBlacklistTableOpen && (
                    <Content
                      width={px2vw(147)}
                      justifyCenter
                      padding={`0 0 0 ${px2vw(35)}`}
                    >
                      <input
                        style={{ cursor: 'pointer' }}
                        checked={blacklist.find((c) => c.id === item.id)}
                        onClick={() => addToBlackList(item)}
                        type="checkbox"
                      />
                    </Content>
                  )}
                  <Content justifyCenter width={px2vw(170)}>
                    {item?.name?.length > 15
                      ? item?.name?.substring(0, 15) + '...'
                      : item?.name}
                  </Content>
                  <Content justifyCenter width={px2vw(225)}>
                    {ddiPhoneMask(item?.phone)}
                  </Content>
                  <Content justifyCenter width={px2vw(170)}>
                    {getClientType(item?.type_tag)}
                  </Content>
                  <Content justifyCenter width={px2vw(120)}></Content>
                  {isBlacklistTableOpen ? (
                    <Content
                      justifyCenter
                      onClick={() => handleRemoveFromBlacklist(item?.id)}
                      disabled={blacklistRequestLoading}
                      width={px2vw(120)}
                      style={{ cursor: 'pointer' }}
                    >
                      <TiPlus
                        fontSize={px2vw(15)}
                        style={{ marginRight: px2vw(4) }}
                      />
                      Retirar
                    </Content>
                  ) : (
                    <Content
                      justifyCenter
                      onClick={() =>
                        blacklist.length > 1
                          ? null
                          : handleOpenDeleteBox(item, 'individual')
                      }
                      disabled={blacklistRequestLoading}
                      width={px2vw(120)}
                      style={{
                        cursor:
                          blacklist.length > 1 ? 'not-allowed' : 'pointer',
                        color: blacklist.length > 1 ? cinzaKuppi4 : 'initial',
                      }}
                    >
                      <BsTrash
                        fontSize={px2vw(15)}
                        style={{
                          marginRight: px2vw(4),
                          color:
                            blacklist.length > 1 ? cinzaKuppi4 : 'initial',
                        }}
                      />
                      Excluir
                    </Content>
                  )}
                  <Content
                    width="auto"
                    padding={`${px2vw(10)} 0 ${px2vw(8)} ${px2vw(10)}`}
                  ></Content>
                </TableRow>
                <Row>
                  <Hr />
                </Row>
              </Fragment>
            ) : null
          )
        ) : (
          <Row>
            <Content margtop={px2vw(20)} width="auto" className="mx-auto">
              Não existem clientes na sua lista.
            </Content>
          </Row>
        )}
      </Container>
      {structure && (
        <Row alignCenter justifyEnd>
          <Row alignCenter justifyEnd height={px2vw(40)} margin={px2vw(10)}>
            <TablePerPage response={handlePerPage} length={rows.length} />
            <Row
              style={{ fontSize: px2vw(12) }}
              alignCenter
              justifyCenter
              width="12%"
            >
              {!structure[currentPage] && handleChangePage('prev')}
              {structure[currentPage] && structure[currentPage].start} -{' '}
              {structure[currentPage] && structure[currentPage].end} de{' '}
              {rows.length}
            </Row>
            <PagButton
              onClick={() => handleChangePage('prev')}
              disabled={currentPage === firstPage}
              title="Página anterior"
            >
              <PrevIcon disabled={currentPage === firstPage} />
            </PagButton>
            <PagButton
              onClick={() => handleChangePage('next')}
              title="Próxima página"
              disabled={currentPage === lastPage}
            >
              <NextIcon disabled={currentPage === lastPage} />
            </PagButton>
          </Row>
        </Row>
      )}
    </Col>
  );
}
