import React from 'react';
import { Col } from '../../../../styles/grid';
import { ChallengeContainer, NormalText, RuleContainer } from '../../styles';
import { preto, verdeKuppi1 } from '../../../../styles/colors';
import px2vw from '../../../../utils/responsiveness/px2vw';
import { StepIcon } from './StepIcon';
import { LoadingBox } from '../../../../styles/common';
import { Spinner } from 'reactstrap';

export const ChallengeStep = ({
  step = 1,
  isCompleted = false,
  loading = false,
  rule = '',
  onClick = () => {},
}) => {
  return (
    <ChallengeContainer completed={isCompleted} margtop={px2vw(step === 1 ? 30 : 15)} onClick={onClick}>
      {loading ? (
        <LoadingBox>
          <Spinner
            style={{
              width: '1.8rem',
              height: '1.8rem',
              color: preto,
            }}
          />{' '}
        </LoadingBox>
      ) : (
        <>
          <RuleContainer>
            <StepIcon step={step} done={isCompleted} />

            <NormalText fontsize={px2vw(13)} margleft={px2vw(15)}>
              {rule}
            </NormalText>
          </RuleContainer>

          {!isCompleted && (
            <Col width={px2vw(175)} alignEnd>
              <NormalText
                fontsize={px2vw(13)}
                textdecoration="underline"
                color={verdeKuppi1}
                fontweight="bold"
              >
                Realizar agora
              </NormalText>
            </Col>
          )}
        </>
      )}
    </ChallengeContainer>
  );
};
