import produce from 'immer';

const INITIAL_STATE = {
  challengesLoading: false,
  challengesSuccess: false,
  challenges: {},
};

export default function challenges(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@challenges/GET_CHALLENGES_STATUS_REQUEST': {
        draft.challengesLoading = true;
        draft.challengesSuccess = false;
        break;
      }
      case '@challenges/GET_CHALLENGES_STATUS_SUCCESS': {
        draft.challengesLoading = false;
        draft.challengesSuccess = true;
        draft.challenges = action.payload.advertiserChallengesStatus;
        break;
      }
      case '@challenges/GET_CHALLENGES_STATUS_FAILURE': {
        draft.challengesLoading = false;
        draft.challengesSuccess = false;
        break;
      }
      case '@challenges/CLEAR_SUCCESS' : {
        draft.challengesSuccess = false;
      }
      default:
        break;
    }
  });
}
