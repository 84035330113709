import styled from 'styled-components';
import {
  branco,
  cinzaKuppi1,
  cinzaKuppi2,
  cinzaKuppi4,
  preto,
  verdeKuppi1,
  verdeKuppi2,
  verdeKuppi4,
} from '../../../../styles/colors';

import px2vw from '../../../../utils/responsiveness/px2vw';

export const WindowHeader = styled.header`
  width: 100%;
  height: ${px2vw(160)};
  display: flex;
  background: white;
  border-bottom: ${px2vw(2)} solid ${cinzaKuppi4};
  padding: ${px2vw(15)} ${px2vw(15)};
  justify-content: space-between;
`;

export const Span = styled.span`
  font-size: ${px2vw(11)};
  font-weight: 600;
  text-align: center;
  ${(props) => props.size && `font-size: ${props.size};`}
  ${(props) => props.textAlign && `text-align: ${props.textAlign};`}
  ${(props) => props.color && `color: ${props.color};`}
  ${(props) => props.letterSpacing && `letter-spacing: ${props.letterSpacing};`}
  ${(props) => props.lineHeight && `line-height: ${props.lineHeight};`}
  ${(props) => props.fontWeight && `font-weight: ${props.fontWeight};`}
  ${(props) => props.p && `padding: ${props.p};`}
  ${(props) => props.pb && `padding-bottom: ${props.pb};`}
  ${(props) => props.pl && `padding-left: ${props.pl};`}
  ${(props) => props.pr && `padding-right: ${props.pr};`}
  ${(props) => props.pt && `padding-top: ${props.pt};`}
  ${(props) => props.m && `margin: ${props.m};`}
  ${(props) => props.mb && `margin-bottom: ${props.mb};`}
  ${(props) => props.ml && `margin-left: ${props.ml};`}
  ${(props) => props.mr && `margin-right: ${props.mr};`}
  ${(props) => props.mt && `margin-top: ${props.mt};`}
  ${(props) => props.maxWidth && `max-width: ${props.maxWidth};`}
  ${(props) => props.overflow && `overflow: ${props.overflow};`}
  ${(props) => props.textOverflow && `text-overflow: ${props.textOverflow};`}
  ${(props) => props.whiteSpace && `white-space: ${props.whiteSpace};`}
`;
export const ImgContainer = styled.div`
  width: ${px2vw(55)};
  height: ${px2vw(55)};
  border-radius: 50%;
  background: ${verdeKuppi2};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${branco};
  span {
    font-size: ${px2vw(17)};
  }
`;

export const Bagde = styled.div`
  border-radius: ${px2vw(10)};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  padding: 0px ${px2vw(8)};
  min-width: ${(props) => props.w || px2vw(70)};
  height: ${(props) => props.h || px2vw(17)};
  color: ${(props) => props.color || 'black'};
  font-size: ${(props) => props.fontSize || px2vw(9)};
  background: ${(props) => props.bg || cinzaKuppi4};
  ${(props) => props.p && `padding: ${props.p};`}
  ${(props) => props.pb && `padding-bottom: ${props.pb};`}
  ${(props) => props.pl && `padding-left: ${props.pl};`}
  ${(props) => props.pr && `padding-right: ${props.pr};`}
  ${(props) => props.pt && `padding-top: ${props.pt};`}
  ${(props) => props.m && `margin: ${props.m};`}
  ${(props) => props.mb && `margin-bottom: ${props.mb};`}
  ${(props) => props.ml && `margin-left: ${props.ml};`}
  ${(props) => props.mr && `margin-right: ${props.mr};`}
  ${(props) => props.mt && `margin-top: ${props.mt};`}
`;

export const ManualOrderButtonPrimary = styled.button`
  background-color: ${verdeKuppi2};
  border: 1px solid ${verdeKuppi2};
  color: ${branco};
  border-radius: ${px2vw(6)};
  font-size: ${px2vw(12)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.36px;
  padding: ${px2vw(8)};
  cursor: pointer;
  width: ${px2vw(180)};

  :hover {
    background-color: ${verdeKuppi4};
    border: 1px solid ${verdeKuppi1};
  }

  &:focus,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
    color: ${branco};
  }
`;

export const ModalSecundaryButtonText = styled.div`
  font-size: ${(props) => props.fontSize || px2vw(12)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  color: ${(props) => (props.disabled ? cinzaKuppi1 : preto)}
`;

export const ModalSecundaryButton = styled.button`
  background-color: ${branco};
  width: ${px2vw(2180)};
  padding: ${px2vw(7.7)};
  border: 1px solid ${(props) => (props.disabled ? cinzaKuppi1 : preto)};
  border-radius: ${(props) => props.radius || px2vw(8)};

  :focus {
    background-color: ${branco} !important;
  }

  :hover {
    background-color: ${cinzaKuppi2};
  }

  ${(props) => `
    margin-top: ${props.margtop};
    width: ${props.width};
    margin-right: ${props.marginRight};
    margin-left: ${props.margleft};
  `};
`;
