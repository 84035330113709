import React from 'react';
import { useSelector } from 'react-redux';
import {
  ConversationIndicatorImg,
  MessageContainer,
  PhoneImg,
  TextBox,
  WhatsImg,
} from '../Step3-Message/styles';
import { Col } from '../../../../styles/grid';
import px2vw from '../../../../utils/responsiveness/px2vw';
import { format } from '@flasd/whatsapp-formatting';

export const MassMessagePreview = ({
  image,
  message,
  footer,
  header,
  link,
}) => {
  const {
    selectedCoupon,
    showUnsubscribeMessage,
    isEditing,
    sendCatalogLink,
    campaignType,
  } = useSelector((state) => state.massMessage);

  return (
    <Col width="45%" relative>
      <MessageContainer
        padleft={px2vw(35)}
        padright={px2vw(35)}
        padtop={px2vw(85)}
        bottom={px2vw(10)}
        justifyStart
        absolute
      >
        <TextBox first>
          {image && (
            <>
              <WhatsImg src={image} /> <br />
            </>
          )}

          <>
            {header}{' <nome_do_cliente>!'}
            <br />
            <br />
          </>

          {!message ? (
            <br />
          ) : (
            <div
              style={{
                paddingRight: '1rem',
              }}
              dangerouslySetInnerHTML={{
                __html: format(message),
              }}
            />
          )}
          {!!footer && (
            <>
              <br />
              {footer}
            </>
          )}
          <ConversationIndicatorImg
            src={require('../../../../assets/images/sales_retriever/conversation_indicator.png')}
          />
          {sendCatalogLink && (
            <>
              <br />
              <br />
              <a href={link}>{link}</a>
              <br/>
            </>
          )}

          {showUnsubscribeMessage && (
            <>
              <br />
              Caso não queira mais receber mensagens promocionais, digite "N"
            </>
          )}
        </TextBox>
      </MessageContainer>
      <PhoneImg
        src={require('../../../../assets/images/sales_retriever/phone.svg')}
      />
    </Col>
  );
};
