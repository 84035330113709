import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import {
  ModalTitleText,
  ImportIfoodCatalogSubtitle,
  ImportIfoodCatalogInput,
  AttentionImage,
  ImportIfoodCatalogSubtitleBold,
  ImportIfoodCatalogLoadingText,
  ImportIfoodCatalogSuccessBold,
  SuccessImage,
  ErrorImage,
  ImportIfoodCatalogErrorBold,
} from '../styles';

import {
  verdeKuppi1,
} from '../../../styles/colors';

import { Row, Col } from '../../../styles/grid';

import ProgressBar from '../../../components/ProgressBar';

import Modal from '../../../components/Modal';
import {
  ModalHeader,
  ModalPrimaryButton,
  ModalPrimaryButtonText,
  ModalActions,
  ModalSecundaryButton,
  ModalSecundaryButtonText,
} from '../../../components/Modal/styles';

import px2vw from '../../../utils/responsiveness/px2vw';

import history from '../../../services/history';

import { catalog } from '../../../routes/routeMap';

import {
  importIfoodCatalogRequest,
  importIfoodCatalogClearSuccess,
} from '../../../store/modules/importIfood/actions';

import {
  getProductCategoriesRequest,
} from '../../../store/modules/productCategories/actions';

export default function CatalogImportIfoodCatalogModal({ handler }) {
  const [importIfoodCatalogURL, setImportIfoodCatalogURL] = useState('');
  const [importIfoodCatalogStep, setImportIfoodCatalogStep] = useState(handler.importIfoodCatalogMaintenance ? 'maintenance' : 'step1');
  const [importIfoodCatalogPercLoad, setImportIfoodCatalogPercLoad] = useState(
    0
  );
  const importIfoodCatalogModalSuccess = useSelector(
    (state) => state.importIfood.importIfoodCatalogModalSuccess
  );
  const dispatch = useDispatch();
  
  // Handle process ifood catalog import
  function handleProcessIfoodCatalogImport() {
    // Import Ifood catalog
    dispatch(importIfoodCatalogRequest(importIfoodCatalogURL));
    // Start the loading bar
    setImportIfoodCatalogPercLoad(5);
    // Go to step 3
    setImportIfoodCatalogStep('step3');
  }

  // Handle ifood catalog url validation
  function handleValidateImportIfoodURL() {
    // Force remove ?utm_medium=share of ifood URL (the URL becomes with this when user get it trough the app)
    const ifoodCatalogURL = importIfoodCatalogURL.replace('?utm_medium=share', '');
    const urlRegex = /^https:\/\/(www|m)\.ifood\.com\.br\/[A-Za-z]+\/[A-Za-z0-9-]+\/[A-Za-z0-9-]+\/[A-Za-z0-9]{8}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{12}$/;

    if (ifoodCatalogURL === '') {
      toast.error('URL de importação do Ifood não informada.', {
        autoClose: 6000,
      });
    } else if (ifoodCatalogURL.match(urlRegex)) {
      setImportIfoodCatalogURL(importIfoodCatalogURL.replace('?utm_medium=share', ''))

      if (handler.isOnboarding && handler.productCategories.length === 0) {
        handleProcessIfoodCatalogImport();
      } else {
        setImportIfoodCatalogStep('step2');
      }
    } else {
      toast.error('URL de importação do Ifood inválida.', {
        autoClose: 6000,
      });
    }
  }

  // Handle ifood catalog import loading bar
  useEffect(() => {
    if (importIfoodCatalogStep === 'step3' && importIfoodCatalogPercLoad < 80) {
      setTimeout(() => {
        setImportIfoodCatalogPercLoad(
          parseInt(importIfoodCatalogPercLoad, 10) +
            parseInt(Math.floor(Math.random() * (15 - 1)) + 1, 10)
        );
      }, 1000);
    }
  }, [importIfoodCatalogPercLoad]);

  // Handle catalog import result
  useEffect(() => {
    if (importIfoodCatalogModalSuccess) {
      // Put the progress bar at 100%
      setImportIfoodCatalogPercLoad(100);
      // Go to the step4 (successfully finished import)
      setTimeout(() => {
        setImportIfoodCatalogStep('step4-success');
      }, 500);
    } else if (
      importIfoodCatalogModalSuccess !== null &&
      !importIfoodCatalogModalSuccess
    ) {
      // Put the progress bar at initial value
      setImportIfoodCatalogPercLoad(0);
      // Go to the step4 (failure finished import)
      setImportIfoodCatalogStep('step4-failure');
    }
    // Clear success flag
    dispatch(importIfoodCatalogClearSuccess());
  }, [importIfoodCatalogModalSuccess, dispatch]);

  return (
    <Modal
      isVisible={handler.importIfoodCatalogModal}
      width={px2vw(450)}
      height={px2vw(250)}
      padding={px2vw(25)}
    >
      <Col alignStart justifyStart>
        <ModalHeader>
          {(importIfoodCatalogStep === 'step1' ||
            importIfoodCatalogStep === 'step3') && (
            <ModalTitleText>Importar cardápio Ifood</ModalTitleText>
          )}

          {(importIfoodCatalogStep === 'step2' ||
            importIfoodCatalogStep === 'step5-success' ||
            importIfoodCatalogStep === 'maintenance') && (
            <div style={{ display: 'flex', alignProducts: 'center' }}>
              <AttentionImage
                src={require('../../../assets/images/attention/attention.png')}
              />
              <ModalTitleText margtop={px2vw(15)}>Atenção!</ModalTitleText>
            </div>
          )}
        </ModalHeader>
        {importIfoodCatalogStep === 'step1' && (
          <Col>
            <ImportIfoodCatalogSubtitle>
              Informe a url completa do seu restaurante no Ifood
            </ImportIfoodCatalogSubtitle>
            <ImportIfoodCatalogInput
              id="importIfoodCatalogURL"
              name="importIfoodCatalogURL"
              placeholder="Ex: https://www.ifood.com.br/delivery/rio-de-janeiro-rj/b34eaf56-1234-ea43-a3de-b34eaf56b34eaf56"
              onChange={(t) => setImportIfoodCatalogURL(t.target.value)}
            />
            <ModalActions
              width="100%"
              justifyContent="space-between"
              margtop={px2vw(15)}
            >
              <ModalSecundaryButton
                width={px2vw(180)}
                type="button"
                onClick={() => {
                  // Close the modal
                  handler.setImportIfoodCatalogModal(false);
                }}
              >
                <ModalSecundaryButtonText>
                  Cancelar
                </ModalSecundaryButtonText>
              </ModalSecundaryButton>
              <ModalPrimaryButton
                width={px2vw(180)}
                type="button"
                onClick={() => {
                  // Validate Ifood URL
                  handleValidateImportIfoodURL();
                }}
              >
                <ModalPrimaryButtonText>Importar</ModalPrimaryButtonText>
              </ModalPrimaryButton>
            </ModalActions>
          </Col>
        )}
        {importIfoodCatalogStep === 'step2' && (
          <Col>
            <ImportIfoodCatalogSubtitleBold>
              {handler.isOnboarding && `Ao realizar a importação do Ifood, seu cardápio atual será
              substituído.`}
              {!handler.isOnboarding && `Ao realizar a importação do Ifood, seu cardápio atual será
              substituído e seus cupons de desconto devem ser criados
              novamente no fim da importação. Em caso de campanhas ativas no
              Kuppi Boost, o produto e o cupom relacionado ao
              impulsionamento serão mantidos.`}
            </ImportIfoodCatalogSubtitleBold>
            <ModalActions width="100%" justifyContent="space-between">
              <ModalSecundaryButton
                width={px2vw(180)}
                type="button"
                onClick={() => {
                  // Close the modal
                  handler.setImportIfoodCatalogModal(false);
                }}
              >
                <ModalSecundaryButtonText>
                  Cancelar
                </ModalSecundaryButtonText>
              </ModalSecundaryButton>
              <ModalPrimaryButton
                width={px2vw(180)}
                type="button"
                onClick={() => {
                  handleProcessIfoodCatalogImport();
                }}
              >
                <ModalPrimaryButtonText>Importar</ModalPrimaryButtonText>
              </ModalPrimaryButton>
            </ModalActions>
          </Col>
        )}
        {importIfoodCatalogStep === 'step3' && (
          <Col>
            <Row alignCenter margtop={px2vw(50)}>
              <Col alignStart>
                <ImportIfoodCatalogLoadingText width={px2vw(280)}>
                  Estou importando seu cardápio...
                </ImportIfoodCatalogLoadingText>
              </Col>
              <Col alignEnd>
                <ImportIfoodCatalogLoadingText>
                  {importIfoodCatalogPercLoad}%
                </ImportIfoodCatalogLoadingText>
              </Col>
            </Row>
            <Row width="100%">
              <ProgressBar
                bgcolor={verdeKuppi1}
                completed={importIfoodCatalogPercLoad}
              />
            </Row>
          </Col>
        )}
        {importIfoodCatalogStep === 'step4-success' && (
          <Col>
            <Row>
              <SuccessImage
                src={require('../../../assets/images/success/success.png')}
              />
            </Row>
            <ImportIfoodCatalogSuccessBold>
              Cardápio importado com sucesso!
            </ImportIfoodCatalogSuccessBold>
            <ModalActions width="100%" justifyContent="center">
              <ModalPrimaryButton
                width={px2vw(220)}
                type="button"
                onClick={() => {
                  // Close the modal
                  handler.setImportIfoodCatalogModal(false);
                  // Retrieve the catalog
                  dispatch(getProductCategoriesRequest());
                  // Go to step 5 success
                  // setImportIfoodCatalogStep('step5-success');
                }}
              >
                <ModalPrimaryButtonText>Continuar</ModalPrimaryButtonText>
              </ModalPrimaryButton>
            </ModalActions>
          </Col>
        )}
        {importIfoodCatalogStep === 'step4-failure' && (
          <Col>
            <Row justifyCenter margtop={px2vw(-10)}>
              <ErrorImage
                src={require('../../../assets/images/error/error.png')}
              />
            </Row>
            <ImportIfoodCatalogErrorBold>
              Ocorreu um erro ao importar seu cardápio, verifique se a URL
              informada está correta, bem como se o cardápio não está vazio
              e tente novamente. Caso o erro persista, entre em contato com
              nosso suporte!
            </ImportIfoodCatalogErrorBold>
            <ModalActions width="100%" justifyContent="center">
              <ModalPrimaryButton
                width={px2vw(220)}
                type="button"
                onClick={() => {
                  // Close the modal
                  handler.setImportIfoodCatalogModal(false);
                  // Reload catalog page avoid erros with the loading bar
                  history.push(catalog);
                }}
              >
                <ModalPrimaryButtonText>Entendi</ModalPrimaryButtonText>
              </ModalPrimaryButton>
            </ModalActions>
          </Col>
        )}
        {/* {importIfoodCatalogStep === 'step5-success' && (
          <Col>
            <ImportIfoodCatalogSubtitleBold>
              Todas as categorias foram importadas pausadas, para que você
              possa fazer uma revisão de tudo que foi importado, antes do
              seu cardápio estar disponível para os usuários e assim
              garantir que está tudo ok.
            </ImportIfoodCatalogSubtitleBold>
            <ModalActions width="100%" justifyContent="center">
              <ModalPrimaryButton
                width={px2vw(220)}
                type="button"
                onClick={() => {
                  // Close the modal
                  handler.setImportIfoodCatalogModal(false);
                  // Retrieve the catalog
                  dispatch(getProductCategoriesRequest());
                }}
              >
                <ModalPrimaryButtonText>Revisar</ModalPrimaryButtonText>
              </ModalPrimaryButton>
            </ModalActions>
          </Col>
        )} */}
        {importIfoodCatalogStep === 'maintenance' && (
          <Col>
            <ImportIfoodCatalogSubtitleBold maintenance>
              Importação de cardápio do ifood indisponível!<br /><br />
              Estamos trabalhando para reestabelecer a importação o mais breve possível.<br /><br />
              Tente importar novamente mais tarde.
            </ImportIfoodCatalogSubtitleBold>
            <ModalActions width="100%" justifyContent="center">
              <ModalPrimaryButton
                width={px2vw(140)}
                type="button"
                onClick={() => {
                  // Close the modal
                  handler.setImportIfoodCatalogModal(false);
                }}
              >
                <ModalPrimaryButtonText>Entendi</ModalPrimaryButtonText>
              </ModalPrimaryButton>
            </ModalActions>
          </Col>
        )}
      </Col>
    </Modal>
  );
}
