import styled from 'styled-components';
import { Select } from '@rocketseat/unform';
import px2vw from '../../../utils/responsiveness/px2vw';
import {
  branco,
  verdeKuppi1,
  cinzaKuppi3,
  cinzaKuppi4,
  cinzaKuppi7
} from '../../../styles/colors';

export const BordersCol = styled.div`
  width: ${(props) => props.width};
  height: 100%;
  display: flex;
  flex-direction: column;
  border: 2px solid ${cinzaKuppi7};
  border-radius: ${(props) => props.borderRadius};
  margin-left: ${px2vw(-1)};
  align-items: center;
  justify-content: center;
`;

export const TransferFormBox = styled.div`
  border-radius: ${px2vw(20)};
  background-color: ${branco};
  width: ${px2vw(1020)};
  height: auto;
  padding: ${px2vw(30)};
  margin-top: ${px2vw(5)};
  margin-bottom: ${px2vw(25)};

  box-shadow: 0px 8px 9px -2px ${cinzaKuppi4};
`;

export const TextFormBold = styled.div`
  font-size: ${px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.36px;
  border-right: ${(props) =>
    props.separator ? `1px solid ${cinzaKuppi4}` : 'none'};
  width: ${(props) => props.width};
  height: ${(props) => props.height};

  ${(props) =>
    props.alignCenter &&
    `
    text-align: center;
  `};

  ${(props) =>
    props.flexCenter &&    `
    display: flex;
    align-items: center;
    justify-content: center;
  `};
`;

export const TextFormRegular = styled.div`
  font-size: ${px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.36px;
`;

export const DateDiv = styled.div`
  .date {
    width: ${px2vw(100)};
    padding: 0.5em;
    border: 1px solid ${cinzaKuppi4};
    border-radius: ${px2vw(5)};
    font-size: ${px2vw(14)};
    font-family: AvenirNextLTPro;
    font-weight: normal;
    letter-spacing: 0.36px;
    height: ${px2vw(32)};

    ::placeholder {
      color: ${cinzaKuppi4} !important;
    }

    :focus {
      border: 1px solid ${cinzaKuppi3} !important;
      box-shadow: none !important;
    }
  }
`;

export const FilterButton = styled.button`
  background-color: ${verdeKuppi1};
  width: ${px2vw(100)};
  height: ${px2vw(30)};
  border: none;
  border-radius: 10px;
  padding: 0.3em;
  margin-left: ${px2vw(30)};
`;

export const FilterButtonText = styled.div`
  font-size: ${px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.36px;
  color: ${branco};
`;

export const SelectInputBox = styled(Select)`
  width: ${(props) => props.width || px2vw(200)};
  height: ${(props) => props.height || px2vw(32)};
  font-size: ${(props) => props.ftsize || px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.36px;
  background: ${branco};
  border-radius:${px2vw(5)};
  padding-left: ${px2vw(5)};
  border: ${px2vw(1)} solid ${cinzaKuppi4};
  

  ${(props) => `
    margin-left: ${props.margleft};
    margin-bottom: ${props.margbottom};
    padding-left: ${props.padleft};
    padding-right: ${props.padright};
  `};
`;
