import React from 'react';

import { Header, NormalText } from '../../pages/Onboarding/BaseScreen/styles';
import { Col, Row } from '../../styles/grid';
import { amareloKuppi2, branco } from '../../styles/colors';

import ButtonPrimary from '../ButtonPrimary';

import px2vw from '../../utils/responsiveness/px2vw';
import { formatCurrencyBRL, getURL } from '../../utils/utils';
import { useChallenge } from '../ActivationChallengeBar/Context';
import { useSelector } from 'react-redux';

export default function FreeTrialBar({
  isOnboardingInitial = false,
  isChallengeComplete,
}) {
  const { setShowChallengeDetails } = useChallenge();
  // When the advertiser is Free, open payment page
  function openPaymentLink() {
    window.open(
      getURL('anunciante') + process.env.REACT_APP_PAYMENT_LINK + paymentToken,
      '_blank'
    );
  }

  const paymentToken = useSelector((state) => state.user.paymentToken);
  const remainingFreeDays = useSelector(
    (state) => state.user.remainingFreeDays
  );
  const minimumSubscriptionAmount = useSelector(
    (state) => state.user.minimumSubscriptionAmount
  );

  return (
    <Header initial={isOnboardingInitial}>
      <Row width="auto" alignCenter>
        <NormalText color={branco} padleft={px2vw(50)} fontsize={px2vw(13)}>
          Você possui
          <NormalText
            fontweight="700"
            color={amareloKuppi2}
            margleft={px2vw(7)}
            margright={px2vw(7)}
            fontsize={px2vw(14)}
          >
            {`${remainingFreeDays} ${
              remainingFreeDays > 1 ? 'dias restantes' : 'dia restante'
            }`}
          </NormalText>
          de teste.
        </NormalText>
      </Row>

      {isChallengeComplete && (
        <Row width="auto" alignCenter>
          <NormalText color={branco} margright={px2vw(10)} fontsize={px2vw(13)}>
            <div
              style={{
                marginRight: '5px',
              }}
            >
              Parabéns por ter concluído o
            </div>{' '}
            <u
              style={{ cursor: 'pointer' }}
              onClick={() => setShowChallengeDetails(true)}
            >
              desafio da ativação
            </u>
            !
          </NormalText>
        </Row>
      )}

      <Row width="auto" alignCenter>
        <NormalText color={branco} margright={px2vw(10)} fontsize={px2vw(13)}>
          A partir de
          <NormalText
            fontweight="700"
            color={branco}
            margleft={px2vw(7)}
            margright={px2vw(7)}
            fontsize={px2vw(14)}
          >
            {formatCurrencyBRL(minimumSubscriptionAmount, {})}
          </NormalText>
          /mês
        </NormalText>
        <Col margright={px2vw(50)} width="auto" height="auto">
          <ButtonPrimary height="30" onClick={() => openPaymentLink()}>
            Assine agora
          </ButtonPrimary>
        </Col>
      </Row>
    </Header>
  );
}
