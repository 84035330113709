import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Icon } from 'react-materialize';
import { toast } from 'react-toastify';
import { Form } from '@rocketseat/unform';
import { Spinner } from 'reactstrap';
import moment from 'moment';

import DatePicker, { registerLocale } from 'react-datepicker';
import '../../../styles/react-datepicker.css';
import ptBR from 'date-fns/locale/pt-BR';

import DeliveryTakeAwayMetrics from './DeliveryTakeAwayMetrics';
import TotalMetrics from './TotalMetrics';
import TotalSalesPerPaymentMethod from './TotalSalesPerPaymentMethod';
import TopSoldProducts from './TopSoldProducts';

import {
  ContentPage,
  ErrorText,
  LoadingBox,
} from '../../_layouts/styles/common';

import { Row, Col } from '../../../styles/grid';
import { preto } from '../../../styles/colors';
import px2vw from '../../../utils/responsiveness/px2vw';

import {
  CashReportBox,
  TextBold,
  TextRegular,
  FilterButton,
  FilterButtonText,
  DateDiv,
  HrSection,
} from './styles';

import { clearReportErrors, getCashReportRequest } from '../../../store/modules/reports/actions';

export default function CashReport() {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const cashReportTotals = useSelector((state) => state.reports.cashReportTotals);
  const cashReportLoading = useSelector(
    (state) => state.reports.cashReportLoading
  );
  const cashReportError = useSelector((state) => state.reports.cashReportError);
  registerLocale('pt-BR', ptBR);
  const dispatch = useDispatch();

  useEffect(() =>  {
    dispatch(clearReportErrors());
  }, []);

  const handleCashReportSubmit = () => {
    if (startDate === null) {
      toast.error('Data de início é obrigatória.', {
        autoClose: 6000,
      });

      return false;
    }

    if (endDate === null) {
      toast.error('Data de fim é obrigatória.', {
        autoClose: 6000,
      });

      return false;
    }

    // Start Date - DDMMAAAA
    const startDateDay = startDate.getDate().toString();
    const startDateMonth = (startDate.getMonth() + 1).toString();
    const startDateYear = startDate.getFullYear()

    const newStartDate = new Date(`${startDateYear}-${startDateMonth}-${startDateDay}`);
    try {
      newStartDate.toISOString();
    } catch (e) {
      toast.error('Data de início inválida.', {
        autoClose: 6000,
      });

      return false;
    }

    // End Date - DDMMAAAA
    const endDateDay = endDate.getDate().toString();
    const endDateMonth = (endDate.getMonth() + 1).toString();
    const endDateYear = endDate.getFullYear()

    const newEndDate = new Date(`${endDateYear}-${endDateMonth}-${endDateDay}`);
    try {
      newEndDate.toISOString();
    } catch (e) {
      toast.error('Data de fim inválida.', {
        autoClose: 6000,
      });

      return false;
    }

    if (newEndDate < newStartDate) {
      toast.error('Data de fim não pode ser menor que a data de início.', {
        autoClose: 6000,
      });

      return false;
    }

    if (newEndDate > new Date()) {
      toast.error('Data de fim não pode ser maior que a data atual.', {
        autoClose: 6000,
      });

      return false;
    }

    const diffStartDates = moment(newStartDate)
    const diffEndDates = moment(newEndDate);
    if (diffEndDates.diff(diffStartDates, 'days') > 31) {
      toast.error('Intervalo de pesquisa não pode ser maior que 31 dias.', {
        autoClose: 6000,
      });

      return false;
    }

    dispatch(getCashReportRequest(`${startDateYear}-${startDateMonth}-${startDateDay}`, `${endDateYear}-${endDateMonth}-${endDateDay}`));
  };

  const contentPage = (
    <CashReportBox>
      <TextBold>Escolha o período que deseja visualizar</TextBold>
      <Form onSubmit={handleCashReportSubmit}>
        <Row justifyStart width="auto" margtop={px2vw(20)}>
          <Col width="auto" alignStart>
            <DateDiv>
              <DatePicker
                dateFormat="dd/MM/yyyy"
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                locale="pt-BR"
                selectsStart
                startDate={startDate}
                endDate={endDate}
                className="date"
                onChangeRaw={(e) => {
                  e.preventDefault();
                }}
              />
            </DateDiv>
          </Col>
          <Row
            width="auto"
            margtop={px2vw(4)}
            margleft={px2vw(8)}
            margright={px2vw(8)}
          >
            <TextRegular>até</TextRegular>
          </Row>
          <Col width="auto" alignStart>
            <DateDiv>
              <DatePicker
                dateFormat="dd/MM/yyyy"
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                locale="pt-BR"
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                className="date"
                onChangeRaw={(e) => {
                  e.preventDefault();
                }}
              />
            </DateDiv>
          </Col>
          <FilterButton type="submit" disabled={cashReportLoading}>
            {cashReportLoading ? (
              <LoadingBox>
                <Spinner
                  style={{
                    width: '1.4rem',
                    height: '1.4rem',
                    color: preto,
                  }}
                />
              </LoadingBox>
            ) : (
              <FilterButtonText>Filtrar</FilterButtonText>
            )}
          </FilterButton>
        </Row>
      </Form>
      {cashReportTotals &&
        parseInt(cashReportTotals.total_orders_qty, 10) === parseInt(0) && (
        <Col alignCenter height="60vh">
          <Row height="auto" alignCenter justifyCenter>
            {/* TODO: Ajustar tamanho do icone */}
            <Icon large>sentiment_very_dissatisfied</Icon>
            <ErrorText>Não existem vendas para o período solicitado.</ErrorText>
          </Row>
        </Col>
      )}
      {cashReportTotals &&
        parseInt(cashReportTotals.total_orders_qty, 10) > parseInt(0) && (
          <>
            <DeliveryTakeAwayMetrics />
            <HrSection />
            <TotalMetrics />
            <HrSection />
            <TotalSalesPerPaymentMethod />
            <HrSection />
            <TopSoldProducts />
          </>
        )}
    </CashReportBox>
  );

  const errorPage = (
    <ContentPage minheight={px2vw(450)}>
      <Col alignCenter height="60vh">
        <Row height="auto" alignCenter justifyCenter>
          {/* TODO: Ajustar tamanho do icone */}
          <Icon large>sentiment_very_dissatisfied</Icon>
          <ErrorText>
            Ocorreu um erro ao carregar o relatório de caixa. Tente novamente.
          </ErrorText>
        </Row>
      </Col>
    </ContentPage>
  );

  if (cashReportError) {
    return errorPage;
  }
  return contentPage;
}
