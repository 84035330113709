import React from 'react';

import {
  ConfirmDeleteModal,
  ConfirmDeleteModalText,
} from '../styles';

import Modal from '../../../components/Modal';
import {
  ModalPrimaryButton,
  ModalPrimaryButtonText,
  ModalActions,
  ModalSecundaryButton,
  ModalSecundaryButtonText,
} from '../../../components/Modal/styles';

import px2vw from '../../../utils/responsiveness/px2vw';

export default function CatalogConfirmDeleteCompCategoryOrComplementModal({ handler }) {
  return (
    <Modal
      isVisible={handler.confirmDeleteCompCategoryOrComplementModal}
      width={px2vw(280)}
      height={px2vw(170)}
    >
      <ConfirmDeleteModal>
        <ConfirmDeleteModalText>
          Tem certeza que deseja excluir?
        </ConfirmDeleteModalText>
        <ModalActions width="100%" justifyContent="space-around">
          <ModalSecundaryButton
            margtop={px2vw(20)}
            width={px2vw(100)}
            type="button"
            onClick={() => {
              handler.setConfirmDeleteCompCategoryOrComplementModal(false);
            }}
          >
            <ModalSecundaryButtonText>Não</ModalSecundaryButtonText>
          </ModalSecundaryButton>
          <ModalPrimaryButton
            margtop={px2vw(20)}
            width={px2vw(100)}
            type="button"
            onClick={() => {
              handler.setConfirmDeleteCompCategoryOrComplementModal(false);

              // Delete all complements and categories
              if (
                handler.confirmDeleteComplementIdx === -1 &&
                handler.confirmDeleteCompCategoryIdx === -1
              ) {
                handler.complementsArray.map((category) => {
                  // Include the complement category at the deleted list
                  handler.deletedComplementsCategoriesArray.push(category.id);

                  // Include all of complements behind a category at the deleted list
                  if (category.complements) {
                    category.complements.map((complement) =>
                      handler.deletedComplementsArray.push(complement.id)
                    );
                  }

                  return true;
                });

                handler.setComplementsArray([]);
                handler.setComplementActionHidden(true);
              } else if (handler.confirmDeleteComplementIdx !== null) {
                // Include the complement at the deleted list
                handler.deletedComplementsArray.push(
                  handler.complementsArray[handler.confirmDeleteCompCategoryIdx]
                    .complements[handler.confirmDeleteComplementIdx].id
                );

                handler.complementsArray[
                  handler.confirmDeleteCompCategoryIdx
                ].complements.splice(handler.confirmDeleteComplementIdx, 1);
                handler.setComplementsArray([...handler.complementsArray]);
              } else if (handler.confirmDeleteCompCategoryIdx !== null) {
                // Include the complement category at the deleted list
                handler.deletedComplementsCategoriesArray.push(
                  handler.complementsArray[handler.confirmDeleteCompCategoryIdx].id
                );

                // Include all of complements behind a category at the deleted list
                handler.complementsArray[
                  handler.confirmDeleteCompCategoryIdx
                ].complements.map((complement) =>
                  handler.deletedComplementsArray.push(complement.id)
                );

                handler.complementsArray.splice(handler.confirmDeleteCompCategoryIdx, 1);
                handler.setComplementsArray([...handler.complementsArray]);
                if (handler.complementsArray.length === 0) {
                  handler.setComplementActionHidden(true);
                }
              }

              handler.setConfirmDeleteCompCategoryIdx(null);
              handler.setConfirmDeleteComplementIdx(null);
            }}
          >
            <ModalPrimaryButtonText>Sim</ModalPrimaryButtonText>
          </ModalPrimaryButton>
        </ModalActions>
      </ConfirmDeleteModal>
    </Modal>
  );
}
