import produce from 'immer';

const INITIAL_STATE = {
  isNewWppMessagePlaying: false,
};

export default function notifications(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@notifications/NEW_MESSAGE_NOTIFICATION':
        draft.isNewWppMessagePlaying = action.payload
        break;
      default:
        break;
    }
  });
}
