import React from "react";
import { Spinner } from 'reactstrap';

import {
  Card,
  NormalText,
} from '../../BaseScreen/styles';
import {
  ChoiceButton,
  ChoiceButtonSelected,
} from '../styles';
import { Row, Col } from '../../../../styles/grid';
import { preto } from '../../../../styles/colors';
import { StoreForm } from "../../Step2-DeliveryParams/Mobile/styles";
import { LoadingBox } from "../../../../styles/common";

import px2vw from '../../../../utils/responsiveness/px2vw';

import ButtonPrimary from '../../../../components/ButtonPrimary';

export default function PrintConfigs({ handler }) {
  return (
    <StoreForm 
      onSubmit={handler.handleFinishPrintConfigsStep}
    >
      <Card
        padleft={px2vw(30)}
        padright={px2vw(30)}
        width={1360}
        height='auto'
        borderRadius={px2vw(60)}
        margtop={px2vw(60)}
      >
        <Col alignCenter margtop={px2vw(50)} height="auto">
          <Row justifyStart width={px2vw(1000)}>
            <NormalText
              margtop={px2vw(50)}
              fontweight='bold'
              textalign='flex-start'
              color={preto}
              fontsize={px2vw(55)}
            >
              Configurações de pedidos
            </NormalText>
          </Row>
          <Row
            margtop={px2vw(90)} justifyStart
            relative width="auto" height="auto" 
            onClick={handler.setSelectedAlwaysPrintOrder}>
            <ChoiceButton isMobile />
            <ChoiceButtonSelected
              margtop={px2vw(15)}
              margleft={px2vw(15)}
              isMobile
              isChecked={handler.selectedAlwaysPrintOrder}
            />
            <NormalText
              width={px2vw(900)}
              margleft={px2vw(50)}
              fontweight='normal'
              textalign='flex-start'
              color={preto}
              fontsize={px2vw(45)}
            >
              Sempre imprimir o pedido ao aceitá-lo
            </NormalText>
          </Row>
          <Row 
            margtop={px2vw(50)} 
            justifyStart 
            relative 
            width="auto" 
            height="auto" 
            onClick={handler.setSelectedAlwaysSendWhatsappMsg}
          >
            <ChoiceButton isMobile />
            <ChoiceButtonSelected
              margtop={px2vw(15)}
              margleft={px2vw(15)}
              isMobile
              isChecked={handler.selectedAlwaysSendWhatsappMsg}
            />
            <NormalText
              width={px2vw(900)}
              margleft={px2vw(50)}
              fontweight='normal'
              textalign='flex-start'
              color={preto}
              margbottom={px2vw(55)}
              fontsize={px2vw(45)}
            >
              Sempre enviar mensagem sobre o status do pedido no whatsapp, ao aceitar o pedido e sair para entrega/ficar pronto para retirada
            </NormalText>
          </Row>
        </Col>
      </Card>
      <Col width="auto" height="auto" alignEnd margtop={px2vw(55)}>
        <Row width="auto" height="auto" >
          <ButtonPrimary
            fontSize={50}
            type="submit"
            height={150}
            minWidth={500}
            borderRadius={25}
            onClick={handler.handleFinishPrintConfigsStep}>
            {handler.printConfigsLoading ? (
              <LoadingBox>
                <Spinner
                  style={{
                    width: '1.8rem',
                    height: '1.8rem',
                    color: preto,
                  }}
                />{' '}
              </LoadingBox>
            ) : (
              'Concluir'
            )}
          </ButtonPrimary>
        </Row>
      </Col>
    </StoreForm>
  )
}