import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ModalBackground, ModalContent } from './styles';

import ClientInfo from './Step1-ClientInfo';
import CatalogData from './Step2-CatalogData';
import ProductDetails from './ProductDetails';
import Bag from './Bag';
import PaymentMethod from './Step3-PaymentMethod';
import AddressesData from './Step4-AddressesData';
import OrderReview from './Step5-OrderReview';
import Schedules from './Schedules';
import { FiX } from 'react-icons/fi';
import { FiChevronDown } from 'react-icons/fi';
import { FiChevronUp } from 'react-icons/fi';

import {
  getProductData,
  cleanManualOrderData,
  setIsMinimized,
} from '../../store/modules/manualOrder/actions';
import {
  ManualOrderHeader,
  MinimizedManualOrderHeader,
} from './Step1-ClientInfo/styles';

import px2vw from '../../utils/responsiveness/px2vw';
import { ddiPhoneMask } from '../../utils/masks';

export default function ManualOrderModal(props) {
  const { isMinimized } = useSelector((state) => state?.manualOrder);
  const isWhatsappwebPage = window.location.pathname.includes('whatsappchat');
  const manualOrderCurrStep = useSelector(
    (state) => state?.manualOrder?.currStep
  );
  const clientData = useSelector((state) => state?.manualOrder?.clientData);
  const dispatch = useDispatch();

  function onClose() {
    dispatch(cleanManualOrderData());
  }

  function onMinimize(state) {
    dispatch(setIsMinimized(state));
  }

  function getManualOrderHeaderTitle() {
    const phoneHeaderText = clientData.phone ? ` - ${ddiPhoneMask(clientData.phone).substring(4)}` : ""
    return manualOrderCurrStep === '1' && !isMinimized
      ? 'Informações do cliente'
      : `Criar Pedido Manual${phoneHeaderText}`;
  }

  useEffect(() => {
    dispatch(getProductData());
  }, []);

  if (isMinimized) {
    return (
      <MinimizedManualOrderHeader
        isWhatsappwebPage={isWhatsappwebPage}
        className="mx-auto"
      >
        {getManualOrderHeaderTitle()}
        <FiChevronUp
          className="my-auto ml-auto"
          style={{ marginRight: px2vw(15), cursor: 'pointer' }}
          onClick={() => onMinimize(false)}
        />
        <FiX
          className="my-auto cursor-pointer"
          style={{ cursor: 'pointer' }}
          onClick={() => onClose()}
        />
      </MinimizedManualOrderHeader>
    );
  }

  if (isWhatsappwebPage) {
    return (
      <ModalContent
        width={props.width}
        height={props.height}
        bgcolor={props.bgcolor}
        margtop={props.margtop}
        padding={props.padding}
        isWhatsappwebPage
      >
        <ManualOrderHeader currStep={manualOrderCurrStep === '1'}>
          {getManualOrderHeaderTitle()}
          <FiChevronDown
            className="my-auto ml-auto"
            style={{ marginRight: px2vw(15), cursor: 'pointer' }}
            onClick={() => onMinimize(true)}
          />
          <FiX
            className="my-auto cursor-pointer"
            style={{ cursor: 'pointer' }}
            onClick={() => onClose()}
          />
        </ManualOrderHeader>
        {manualOrderCurrStep === '1' && <ClientInfo onClose={onClose} />}
        {manualOrderCurrStep === '2' && <CatalogData onClose={onClose} />}
        {manualOrderCurrStep === '2-1' && <ProductDetails onClose={onClose} />}
        {manualOrderCurrStep === '2-2' && <Bag onClose={onClose} />}
        {manualOrderCurrStep === '3' && <PaymentMethod onClose={onClose} />}
        {manualOrderCurrStep === '4' && <AddressesData onClose={onClose} />}
        {manualOrderCurrStep === '5' && <OrderReview onClose={onClose} />}
        {manualOrderCurrStep === '5-1' && <Schedules onClose={onClose} />}
      </ModalContent>
    );
  }

  return (
    <ModalBackground isVisible={props.isVisible}>
      <ModalContent
        width={props.width}
        height={props.height}
        bgcolor={props.bgcolor}
        margtop={props.margtop}
        padding={props.padding}
        minimized={isMinimized}
      >
        <ManualOrderHeader currStep={manualOrderCurrStep === '1'}>
          {manualOrderCurrStep === '1'
            ? 'Informações do cliente'
            : `Criar Pedido Manual`}
          <FiChevronDown
            className="my-auto ml-auto"
            style={{ marginRight: px2vw(15), cursor: 'pointer' }}
            onClick={() => onMinimize(true)}
          />
          <FiX
            className="my-auto cursor-pointer"
            style={{ cursor: 'pointer' }}
            onClick={() => onClose()}
          />
        </ManualOrderHeader>
        {manualOrderCurrStep === '1' && <ClientInfo onClose={onClose} />}
        {manualOrderCurrStep === '2' && <CatalogData onClose={onClose} />}
        {manualOrderCurrStep === '2-1' && <ProductDetails onClose={onClose} />}
        {manualOrderCurrStep === '2-2' && <Bag onClose={onClose} />}
        {manualOrderCurrStep === '3' && <PaymentMethod onClose={onClose} />}
        {manualOrderCurrStep === '4' && <AddressesData onClose={onClose} />}
        {manualOrderCurrStep === '5' && <OrderReview onClose={onClose} />}
        {manualOrderCurrStep === '5-1' && <Schedules onClose={onClose} />}
      </ModalContent>
    </ModalBackground>
  );
}
