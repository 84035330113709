import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { IoClose } from 'react-icons/io5';
import { ImBlocked } from 'react-icons/im';
import { BsCheck2All } from 'react-icons/bs';

import {
  Body,
  DateTag,
  MessageContent,
  MessageDate,
  MessageItem,
  SelectedImg,
  ShowMoreTag,
} from './style';
import px2vw from '../../../../utils/responsiveness/px2vw';
import { Col, Row } from '../../../../styles/grid';
import { useMemo } from 'react';
import { useEffect } from 'react';
import {
  branco,
  preto,
  verdeKuppi2,

} from '../../../../styles/colors';
import {
  setSelectedMedia,
} from '../../../../store/modules/whatsappChat/actions';

import { MessageContainer } from './Messages/MessageContainer';

export default function ChatBody() {
  const bodyRef = useRef(null);
  const dispatch = useDispatch();
  const { isManualOrderModalVisible, isMinimized } = useSelector(
    (state) => state.manualOrder
  );

  const { id } = useSelector(
    (state) => state.whatsappChat.selectedConversation
  );

  const { selectedMedia, revokedMessages } = useSelector(
    (state) => state.whatsappChat
  );

  const { conversations } = useSelector(
    (state) => state.whatsappChatConversations
  );

  const messagesOfSelectedConversation = useMemo(() => {
    let messages = [];
    if (conversations[id]) {
      messages = conversations[id];
    }

    messages = messages?.sort((a, b) => {
      const timestampDifference = a?.messageTimestamp - b?.messageTimestamp;
      // Order history messages by orderId when messages were sent in the same seconds
      if (timestampDifference === 0 && a?.orderId && b?.orderId) {
        if (a?.orderId > b?.orderId) return 1;
        if (a?.orderId < b?.orderId) return -1;
        return navigator.userAgent.indexOf('Chrome') !== -1 ? -1 : 1;
      }

      return timestampDifference;
    });

    return messages;
  }, [id, conversations]);

  //scroll the conversation page to bottom 0
  useEffect(() => {
    if (messagesOfSelectedConversation && id) {
      if (messagesOfSelectedConversation?.length) {
        scrollToItem();
      }
    }
  }, [messagesOfSelectedConversation, selectedMedia, id, bodyRef]);

  const scrollToItem = () => {
    const element = bodyRef.current;
    if (element) {
      element.scrollBy({
        top: element.scrollHeight,
      });
    }
  };

  const getWeekDay = (dayName) => {
    switch (dayName) {
      case 'sunday':
        return 'Domingo';
      case 'monday':
        return 'Segunda';
      case 'tuesday':
        return 'Terça';
      case 'wednesday':
        return 'Quarta';
      case 'thursday':
        return 'Quinta';
      case 'friday':
        return 'Sexta';
      case 'saturday':
        return 'Sábado';
      default:
        return '';
    }
  };

  const handleFormatMessageTime = (date) => {
    const isMoreThanOneWeek = moment(date).isSameOrBefore(
      moment().subtract(6, 'days')
    );
    const isMoreThanTwoDays = moment(date).isSameOrBefore(
      moment().subtract(2, 'days')
    );

    if (isMoreThanOneWeek) {
      return moment(date).format('DD/MM/YYYY');
    } else if (isMoreThanTwoDays) {
      return getWeekDay(
        moment(date).format('dddd').toLowerCase()
      ).toUpperCase();
    } else if (moment(date).format('DD') !== moment().format('DD')) {
      return 'ONTEM';
    } else {
      return 'HOJE';
    }
  };

  const renderMessage = (
    { message, messageTimestamp, fromMe, id: messageId },
    index
  ) => {
    const tagDate = moment(new Date(messageTimestamp * 1000));
    const show =
      moment(
        messagesOfSelectedConversation[index - 1 > 0 ? index - 1 : 0]
          ?.messageTimestamp * 1000
      ).format('DD/MM/yyyy') !== tagDate.format('DD/MM/yyyy') || index === 0;

    const dateFormated = moment(new Date(messageTimestamp * 1000)).format(
      'HH:mm'
    );

    if (message?.protocol) {
      return;
    }

    if (revokedMessages.includes(messageId)) {
      return (
        <MessageItem key={index} me={fromMe}>
          <MessageContent me={fromMe}>
            <Row
              alignCenter
              pad={px2vw(5)}
              padbottom={0}
              style={{ color: preto + '90' }}
            >
              <ImBlocked
                color={preto + '90'}
                style={{ fontSize: px2vw(20), paddingRight: px2vw(5) }}
              />
              Mensagem apagada
            </Row>
            <Row height="auto" alignCenter>
              <MessageDate>{dateFormated} </MessageDate>
              {fromMe && (
                <BsCheck2All
                  style={{ fontSize: px2vw(14), marginTop: px2vw(3) }}
                />
              )}
            </Row>
          </MessageContent>
        </MessageItem>
      );
    }

    return (
      <React.Fragment key={index}>
        {show && <DateTag>{handleFormatMessageTime(tagDate)}</DateTag>}
        <MessageContainer
          message={message}
          fromMe={fromMe}
          messageId={messageId}
          time={dateFormated}
        />
      </React.Fragment>
    );
  };

  if (selectedMedia) {
    return (
      <Row
        relative
        height="100%"
        zIndex={9999}
        marginbottom={px2vw(-10)}
        width="100%"
        background={preto + '80'}
      >
        <SelectedImg src={selectedMedia.base64} className="m-auto" />
        <Col
          absolute
          borderRadius="999px"
          width={px2vw(25)}
          height={px2vw(25)}
          alignCenter
          right="9px"
          top="9px"
          bottom="0px"
          background={verdeKuppi2}
          onClick={() => {
            dispatch(setSelectedMedia(null));
          }}
          cursor="pointer"
        >
          <IoClose fontSize={px2vw(20)} color={branco} />
        </Col>
      </Row>
    );
  }

  return (
    <>
      <Body
        style={{
          display: selectedMedia ? 'hidden' : 'flex',
        }}
        ref={bodyRef}
        relative
        isManualOrderModalVisible={isManualOrderModalVisible}
        isMinimized={isMinimized}
        justifyStart
      >
        <ShowMoreTag>
          Para ver mensagens anteriores a{' '}
          {moment(
            messagesOfSelectedConversation[0]?.messageTimestamp * 1000
          ).format(`DD/MM/yyyy [às] HH:mm:ss`)}
          , utilize o Whatsapp no celular.
        </ShowMoreTag>
        {(messagesOfSelectedConversation || []).map(renderMessage)}
      </Body>
    </>
  );
}
