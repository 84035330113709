import styled from 'styled-components';

import { Input } from '@rocketseat/unform';
import {
  branco,
  cinzaKuppi3,
  cinzaKuppi4,
  verdeKuppi1,
} from '../../../styles/colors';

import px2vw from '../../../utils/responsiveness/px2vw';

export const ChoiceButton = styled.div`
  border: 1px solid ${cinzaKuppi3};
  background-color: ${branco};
  width: ${px2vw(12)};
  height: ${px2vw(12)};

  ${(props) => props.isMobile && `
    width: ${px2vw(80)};
    height: ${px2vw(80)};
  `};
`;

export const ChoiceButtonSelected = styled.div`
  position: absolute;
  background-color: ${verdeKuppi1};
  width: ${px2vw(7.5)};
  height: ${px2vw(7.5)};
  margin-top: ${px2vw(2.3)};
  display: none;

  ${(props) =>
    props.isChecked &&
    `
      display: block;
    `};

  ${(props) => props.isMobile && `
    width: ${px2vw(50)};
    height: ${px2vw(50)};
    margin-left: ${props.margleft};
    margin-top: ${props.margtop};
  `};
`;

export const PrintTypeButtonText = styled.div`
  font-size: ${px2vw(12)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.32px;
  margin-left: ${px2vw(10)};

  ${(props) => `
  margin-top: ${props.margtop};
  `};

  ${(props) => props.isMobile && `
    font-size: ${px2vw(50)};
    margin-top: ${px2vw(120)};
    text-align: flex-start;
  `};
`;

export const PrintTypeBoxDetail = styled.div`
  border: 1px solid ${cinzaKuppi4};
  border-radius: 8px;
  width: 100%;
  min-height: ${px2vw(120)};
  margin-top: ${px2vw(20)};
  padding: ${px2vw(10)};
`;

export const PrintTypeBoxDetailText = styled.div`
  font-size: ${px2vw(12)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.36px;
  width: 100%;
  height: 100%;
`;

export const PrintTypeInput = styled(Input)`
  padding: 0.5em;
  margin-top: 5px;
  border: 1px solid ${cinzaKuppi4};
  border-radius: 5px;
  font-size: ${px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.36px;
  width: ${px2vw(36)};
  height: ${px2vw(27)};
  margin-top: ${px2vw(11)};
  margin-left: ${px2vw(10)};

  ::placeholder {
    color: ${cinzaKuppi4} !important;
  }

  :focus {
    border: 1px solid ${cinzaKuppi3} !important;
    box-shadow: none !important;
  }

  ${(props) => props.isMobile && `
    width:${px2vw(120)};
    height:${px2vw(120)};
    font-size: ${px2vw(45)};

  `};
`;