import React from 'react';
import styled from 'styled-components';
import { Form, Input, Select } from '@rocketseat/unform';
import { Switch } from '@material-ui/core/';
import { withStyles } from '@material-ui/core/styles';

import px2vw from '../../../../utils/responsiveness/px2vw';
import px2em from '../../../../utils/responsiveness/px2em';

import {
  preto,
  branco,
  cinzaKuppi2,
  cinzaKuppi3,
  cinzaKuppi4,
  cinzaKuppi6,
  verdeKuppi1,
  verdeKuppi2,
  verdeKuppi4
} from '../../../../styles/colors';

export const OptionBox = styled.div`
  display: none;
  flex-direction: row;
  
  ${(props) =>
    props.isVisible &&
    `
    display: flex;
  `};

  ${(props) => `
    margin-top: ${props.margtop};
  `};
`;

export const FadingCol = styled.div`
  display: flex;
  flex-direction: column;
  opacity: 0;
  justify-content: center;
  align-items: flex-start;
  width: auto;
  height: 0;
  transform: scale(0.1);
  transition: ${`all ${1/3}s ease-in-out`};

  ${(props) => props.isActive && `
    opacity: 1;
    height: 100%;
    transform: scale(1);
  `}
`;

export const ProductSelectInput = styled(Select)`
  margin-top: ${px2vw(5)};
  height: ${px2vw(39)};
  font-size: ${px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.36px;

  ${(props) => `
    width: ${props.width};
    height: ${props.height};
    margin-top: ${props.margtop};
    margin-right: ${props.margright};
    margin-left: ${props.margleft};
    font-size: ${props.fontsize};
    background-color: ${props.background};
    border: 1px solid ${cinzaKuppi4};
    border-radius: ${px2vw(25)};
    padding-left: ${px2vw(30)};
  `};
`;

export const ModalBackground = styled.div`
  display: none;
  position: fixed;
  z-index: 20;
  left: 0;
  top: 0;
  width: 100%;
  height: ${(props) => props.height || `100%`};
  overflow: 'auto';
  background-color: ${cinzaKuppi6}99;
  max-height: ${(props) => props.maxheight};

  ${(props) =>
    props.isVisible &&
    `
    display: flex;
    justify-content: center;
    align-items: center;
  `};
`;

export const ModalContent = styled.div`
  background-color: ${branco};
  padding: ${(props) => props.padding || '20px'};
  border-radius: 20px;

  ${(props) => `
    width: ${props.width};
    min-height: ${props.height};
    background-color: ${props.bgcolor};
    margin-top: ${props.margtop};
  `};
`;

export const ModalTitleText = styled.div`
  font-size: ${px2vw(18)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.48px;
  text-align: center;

  ${(props) => `
    padding-left: ${props.paddingLR};
    padding-right: ${props.paddingLR};
    margin-top: ${props.margtop};
    font-size: ${props.fontsize};
  `};
`;

export const ModalActions = styled.div`
  display: flex;
  flex-direction: row;

  ${(props) => `
    padding: ${props.padding};
    width: ${props.width};
    justify-content: ${props.justifyContent};
    margin-top: ${props.margtop};
  `};
`;

export const SwitchButtonBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  padding-left: ${px2vw(30)};

  ${(props) => `
    width: ${props.width};
    margin-top: ${props.margtop};
    margin-left: ${props.margleft};
  `};
`;

export const SwitchLabelBoldText = styled.div`
  font-size: ${px2vw(60)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.36px;
`;

export const SwitchLabelRegularText = styled.div`
  font-size: ${(props) => props.fontsize || px2vw(50)};
  font-family: AvenirNextLTPro;
  font-weight: ${(props) => props.fontweight || 'normal'};
  letter-spacing: 0.36px;
  min-width: ${px2vw(55)};

  ${(props) =>
    props.isChecked &&
    `
    color: ${branco};
  `};

  ${(props) => `
    width: ${props.width};
    margin-right: ${props.margright};
    margin-left: ${props.margleft};
    margin-top: ${props.margtop};
  `};

  ${(props) =>
    props.disabled &&
    `
    color: ${preto}60;
  `};
`;

export const SwitchController = withStyles((theme) => ({
  root: {
    width: px2vw(180),
    height: px2vw(100),
    padding: 0,
    margin: 0,
    marginRight: px2vw(30),
  },
  switchBase: {
    padding: 1.5,
    '&$checked': {
      transform: `translateX(${px2vw(80)})`,
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: `${verdeKuppi2}`,
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: `${px2vw(30)} solid #fff`,
    },
  },
  thumb: {
    width: `${px2vw(80)}`,
    height: `${px2vw(80)}`,
  },
  track: {
    borderRadius: `${px2vw(120)}`,
    backgroundColor: theme.palette.grey[400],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

export const SwitchWithInput = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: ${px2vw(80)};
  min-height: ${px2vw(40)};

  ${(props) => `
    margin-left: ${props.margleft};
    margin-right: ${props.margright};
    margin-bottom: ${props.marginBottom};
    margin-top: ${props.margtop};
    min-width: ${props.minWidth};
    min-height: ${props.minHeight};
    flex-direction: ${props.flexDir};
    padding-left: ${props.padleft};
    padding-right: ${props.padright};
    
  `};
`;

export const SwitchWithInputBox = styled.div`
  display: none;
  height: ${(props) => props.height || px2vw(10)};  
  
  ${(props) =>
    props.isVisible &&
    `
    display: block;
  `};

  ${(props) => `
    margin-right: ${props.marginRight};
    margin-left: ${props.margleft};
    margin-top: ${props.margtop};
    width: ${props.width}; 
  `};
`;

export const SwitchInput = styled(Input)`
  padding: ${px2em(8)} !important;
  margin-left: ${(props) => props.margleft || px2vw(40)} !important;
  margin-right: ${(props) => props.margright || px2vw(40)} !important;
  border: 1px solid ${cinzaKuppi4} !important;
  border-radius: ${(props) => props.borderradius || px2vw(15)} !important;
  height: ${(props) => props.height || px2vw(120)} !important;
  font-size: ${px2vw(40)} !important;
  font-family: font-family: AvenirNextLTPro !important;
  font-weight: normal;
  letter-spacing: 0.36px !important;
  width: ${(props) => props.width || px2vw(190)} !important;
  cursor: ${(props) => props.cursor};

  ::placeholder {
    color: ${cinzaKuppi4} !important;
  }

  :focus {
    border: 1px solid ${cinzaKuppi3} !important;
    box-shadow: none !important;
  }

  ${(props) => `
    width: ${props.width} !important;
    margin-top: ${props.margtop} !important;
    margin-right: ${props.margright} !important;
    padding-left: ${props.padleft} !important;
    margin-bottom: ${props.margbottom} !important;
    pointer-events: ${props.pointerEvents} !important;
  `};

  ${(props) =>
    (props.disabled || props.readonly) &&
    `
      color: ${preto}60;
  `};
`;

export const StoreForm = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: ${px2vw(320)}
`;

export const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: ${px2vw(50)};
`;

export const ButtonOption = styled.button`
  background-color: ${branco};
  height: ${px2vw(120)};
  width: 'auto';
  min-width: ${px2vw(420)};
  border: 1px solid;
  border-radius: ${px2vw(25)};
  padding-left: ${px2vw(25)};
  padding-right: ${px2vw(25)};
  margin-bottom: ${px2vw(2)};
  margin-right: ${px2vw(2)};
  margin-left: ${px2vw(35)};
  font-size: ${px2vw(50)};

  ${(props) =>
    props.isChecked &&
    `
    background-color: ${verdeKuppi4};
    border: 1px solid ${verdeKuppi4};
    color: ${branco};
  `};

  ${(props) =>
    props.disabled &&
    `
    opacity: 0.2;
  `};
`;

export const TabSelected = styled.div`
  display: none;
  height: ${px2vw(5)};
  width: ${px2vw(125)};
  background-color: ${verdeKuppi1};
  border-radius: ${px2vw(5)};
  margin-top: ${px2vw(-7)};
  margin-bottom: ${px2vw(20)};

  ${(props) =>
    props.isVisible &&
    `
    display: block;
  `};

  ${(props) => `
    width: ${props.width};
    height: ${props.height};
  `};
`;

export const SaveButton = styled.button`
  background-color: ${cinzaKuppi2};
  height: ${px2vw(170)};
  width: ${px2vw(400)};
  line-height: ${px2vw(40)};
  border: none;
  border-radius: ${px2vw(20)};

  ${(props) => `
    margin-top: ${props.margtop};
  `};

  ${(props) =>
    props.hasChanges &&
    `
    background-color: ${verdeKuppi1};
  `};
`;

export const SaveButtonText = styled.div`
  font-size: ${px2vw(60)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.36px;
  color: ${cinzaKuppi3};

  ${(props) =>
    props.hasChanges &&
    `
    color: ${branco};
  `};
`;