import styled, { css } from 'styled-components';

import { Link } from 'react-router-dom';

import px2vw from '../../../../../utils/responsiveness/px2vw';

import {
  preto,
  branco,
  verdeKuppi1,
  verdeKuppi3,
  verdeKuppi4,
  cinzaKuppi2,
  cinzaKuppi4,
  cinzaKuppi5,
  cinzaKuppi10
} from '../../../../../styles/colors';

export const SeeProfileButton = styled(Link)`
  text-decoration: none !important;
  font-size: ${px2vw(16)};
  font-family: AvenirNextLTPro;
  font-weight: 600;
  letter-spacing: 0.36px;
  border: none;
  background-color: transparent;
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
    color: ${preto};
  }
`;

export const LastOrdersTitle = styled.div`
  font-size: ${px2vw(16)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.48px;
  margin-top: ${px2vw(2)};
`;

export const NewPaymentsDataBox = styled.div`
  background-color: ${branco};
  border-radius: ${px2vw(20)};
  border: none;
  margin-right: 20px;
  ${(props) => `width: ${props.width}`};
  ${(props) => `heigth: ${props.heigth}`};
`;

export const BoxRow = styled.div`
  flex-direction: row;
`;

export const AmountLabel = styled.span`
  padding-left: 5px;
`;

export const NewPaymentsOptionBox = styled.div`
  background-color: ${branco};
  border-radius: ${px2vw(20)};
  border: solid;
  border-color: ${(props) => props.selectedPlan ? verdeKuppi1 : cinzaKuppi4 };
  height: ${(props) => props.showBoostDiscount ? px2vw(255) : px2vw(220)};
  width: auto;  
  cursor: pointer;
  
  :hover {
    background-color:  ${cinzaKuppi2};
  }

  &:active,
  &:focus,
  &:hover {
    border-color: ${verdeKuppi1};

  }
`;

export const NewPaymentsTitle = styled.div`
  font-size: ${px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.48px;
`;

export const NewPaymentsBoxBoldTitle = styled.div`
  font-size: ${px2vw(34)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.48px;
  margin-top: ${px2vw(-22)};
`;

export const NewPaymentsSubtitle = styled.div`
  font-size: ${px2vw(16)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.36px;
  color: ${cinzaKuppi5};
`;

export const NewPaymentsBoxTitle = styled.div`
  font-size: ${px2vw(12)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.36px;
  color: ${cinzaKuppi5};
`;

export const NewPaymentsObs = styled.div`
  font-size: ${px2vw(10)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.36px;
  color: ${preto};
  ${(props) => props.alignCenter && `text-align: center`};
`;

export const NewPaymentsObsMonthlyDiscount = styled.div`
  font-size: ${px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: bold;
  letter-spacing: 0.36px;
  color: ${verdeKuppi4};
  margin-top: ${px2vw(9)};
`;

export const NewPaymentsBoxPlan = styled.div`
  font-size: ${px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: bold;
  letter-spacing: 0.36px;
  color: ${cinzaKuppi5}
`;

export const NewPaymentsBoxPlanType = styled.div`
  font-size: ${px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: 800;
  letter-spacing: 0.36px;
  color: ${preto};
  margin-bottom: 18px;
`;

export const ConfirmPlanButton = styled.button`
  background-color: ${cinzaKuppi4};
  border: 1px solid ${cinzaKuppi4};
  color: ${preto};
  border-radius: ${px2vw(10)};
  height: ${px2vw(35)};
  min-width: ${px2vw(175)};
  line-height: ${px2vw(35)};
  font-size: ${px2vw(9)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.36px;
  text-align: center;
  padding: 0 ${px2vw(20)};

  &:focus,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
    color: ${preto};
  }

  ${(props) =>
    props.isActive &&
    `
      background-color: ${verdeKuppi1};
      border: 1px solid ${verdeKuppi1};
      color: ${branco};
    
      ${(props) =>
        props.minWidth &&
        css`
          min-width: ${props.minWidth};
        `}
    
      &:focus,
      &:visited,
      &:link,
      &:active {
        text-decoration: none;
        color: ${branco};
      }
    `}

  ${(props) =>
    props.isRecommended && !props.isActive &&
    `
      background-color: ${verdeKuppi4};
      border: 1px solid ${verdeKuppi4};
      color: ${branco};
    
      &:focus,
      &:visited,
      &:link,
      &:active {
        text-decoration: none;
        color: ${branco};
      }
    `}   
`;

export const ImageBox = styled.div`
  display: flex;
  margin-top: ${px2vw(-7)};
  ${(props) => `
    width: ${props.width};
    height: ${props.height};
    margin-left: ${props.margleft};
    margin-right: ${props.margright};
  `}
`;

export const Image = styled.img`
  filter: blur(0);
  -webkit-filter: blur(0);
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
`;

export const MostRecommendedTextBox = styled.div`
  display: flex;
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.36px;
  font-size: ${(props) => props.isMobile ? px2vw(50) : px2vw(10)};
  width: ${(props) => props.isMobile ? px2vw(620) : px2vw(120)};
  text-align: center;
`;

export const BoostCredits = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: ${(props) => props.isMobile ? px2vw(50) : px2vw(9)};
  color: ${branco};
  width: 100%;
  height: ${(props) => props.isMobile ? px2vw(180) : px2vw(40)};
  background: ${cinzaKuppi10};
  border-radius: ${(props) => props.isMobile ? `${px2vw(70)} ${px2vw(70)} 0px 0px` : `${px2vw(18)} ${px2vw(18)} 0px 0px`};
  z-index: 1;

  .valueColor {
    font-size: ${(props) => props.isMobile ? px2vw(81) : px2vw(15)};
    color: ${verdeKuppi3};
  }
`;