import React, { useState } from 'react';
import {
  ExpandMore,
  ForwaredBadge,
  MessageButton,
  MessageContent,
  MessageDate,
  MessageItem,
  MessageText,
  PopoverMenuItem,
} from '../style';
import { branco } from '../../../../../styles/colors';
import { setIsDeletingMessage } from '../../../../../store/modules/whatsappChat/actions';
import px2vw from '../../../../../utils/responsiveness/px2vw';
import DOMPurify from 'dompurify';
import { Col, Row } from '../../../../../styles/grid';
import { IoMdShareAlt } from 'react-icons/io';
import { useDispatch } from 'react-redux';
import { WhatsappText } from '../WhatsappText';
import { ImageMessage } from './media/ImageMessage';
import { DocumentMessage } from './media/DocumentMessage';
import { ContactMessage } from './media/ContactMessage';
import { BsCheck2All } from 'react-icons/bs';
import { AudioMessage } from './media/AudioMessage';
import { StickerMessage } from './media/StickerMessage';
import { VideoMessage } from './media/VideoMessage';
import { QuotedMessage } from './media/QuotedMessage';

export const MessageContainer = ({ message, messageId, fromMe, time }) => {
  const [selectedMessage, setSelectedMessage] = useState('');
  const dispatch = useDispatch();
  const { isForwarded, buttons } = message;

  const buttonsArr = buttons?.buttons || [];

  const text =
    message?.conversation ||
    message?.image?.caption ||
    message?.document?.caption ||
    message?.buttons?.conversation ||
    message?.video?.caption;

  const sanitizedText = DOMPurify.sanitize(text);

  const parseToLink = (text) => {
    const replaced = text.replace(
      /((http|https)?:?\/?\/?([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-]))/,
      '<a target="_blank" href="http://$3$4" >$1</a>'
    );
    return replaced;
  };

  if (!sanitizedText.length > 0 && message?.conversation) return null;

  return (
    <MessageItem me={fromMe}>
      {selectedMessage === messageId && (
        <Col
          absolute
          top={px2vw(7)}
          right={px2vw(16)}
          zIndex="10"
          background={branco}
          width={px2vw(180)}
          pad={`${px2vw(5)} 0`}
          height="auto"
          borderRadius="7px"
          fontSize={px2vw(16)}
          onMouseLeave={() => setSelectedMessage('')}
        >
          <PopoverMenuItem
            onClick={() => dispatch(setIsDeletingMessage(selectedMessage))}
            alignCenter
            justifyCenter
            height={px2vw(23)}
          >
            Apagar mensagem
          </PopoverMenuItem>
        </Col>
      )}
      <MessageContent me={fromMe}>
        {fromMe && (
          <ExpandMore
            onClick={() => setSelectedMessage(messageId)}
            className="expandMoreIcon"
          />
        )}
        {isForwarded && (
          <ForwaredBadge>
            <IoMdShareAlt />
            <div>Encaminhada</div>
          </ForwaredBadge>
        )}

        {message?.image && <ImageMessage image={message.image} />}
        {message?.document && <DocumentMessage document={message.document} />}
        {message?.contact && <ContactMessage vcard={message.contact?.vcard} />}
        {message?.audio && <AudioMessage audio={message.audio} />}
        {message?.sticker && <StickerMessage sticker={message.sticker} />}
        {message?.video && <VideoMessage video={message.video} />}
        {message?.quoted && <QuotedMessage quoted={message.quoted} />}

        {sanitizedText.length > 0 && (
          <MessageText>
            <WhatsappText>{parseToLink(sanitizedText)}</WhatsappText>
          </MessageText>
        )}
        <Row height={'auto'} alignCenter>
          <MessageDate>{time} </MessageDate>{' '}
          {fromMe && (
            <BsCheck2All style={{ fontSize: px2vw(14), marginTop: px2vw(3) }} />
          )}
        </Row>
      </MessageContent>
      {buttonsArr.length > 0 && (
        <Col margtop={px2vw(3)} spaceBetween height="auto" maxwidth="100%">
          {buttonsArr?.map((button, idx) => (
            <MessageButton key={idx}>
              <MessageText isButton>
                {button?.buttonText?.displayText}
              </MessageText>
            </MessageButton>
          ))}
        </Col>
      )}
    </MessageItem>
  );
};
