import { onlyNumbers } from './utils';

export function amountMask(value) {
  if (value !== null && value !== undefined) {
    const amount = onlyNumbers(value);

    // 9999,99
    return amount.replace(/(\d{1,4})(\d{2})/, 'R$ $1.$2');
  }
  return '';
}

export function screenAmountMask(value) {
  if (value !== null && value !== undefined) {
    const amount = onlyNumbers(value);

    if (amount.length === 6) {
      // 9.999,99
      return amount.replace(/(\d{1,1})(\d{1,3})(\d{2})/, 'R$ $1.$2,$3');
    } else {
      // 999,99
      return amount.replace(/(\d{1,3})(\d{2})/, 'R$ $1,$2');
    }
  }
  return '';
}

export function screenAmountMaskWithoutSymbol(value) {
  if (value !== null && value !== undefined) {
    const amount = onlyNumbers(value);

    if (amount.length === 6) {
      // 9.999,99
      return amount.replace(/(\d{1,1})(\d{1,3})(\d{2})/, '$1.$2,$3');
    } else {
      // 999,99
      return amount.replace(/(\d{1,3})(\d{2})/, '$1,$2');
    }
  }
  return '';
}

export function dateMask(value) {
  if (value !== null && value !== undefined) {
    const date = onlyNumbers(value);

    // DD/MM/YYYY
    return date
      .replace(/(\d{2})(\d)/, '$1/$2')
      .replace(/(\d{2})(\d)/, '$1/$2')
      .replace(/(:\d{2})\d+?$/, '$1');
  }
  return '';
}

export function dateTimeMask(value) {
  if (value !== null && value !== undefined) {
    const dateTime = onlyNumbers(value);

    // DD/MM/YYYY HH:MM
    return dateTime
      .replace(/(\d{2})(\d)/, '$1/$2')
      .replace(/(\d{2})(\d)/, '$1/$2')
      .replace(/(\d{4})(\d{2})(\d)/, '$1 $2:$3')
      .replace(/(:\d{2})\d+?$/, '$1');
  }
  return '';
}

export function timeMask(value) {
  if (value !== null && value !== undefined) {
    const time = onlyNumbers(value);
    // HH:MM
    return time.replace(/(\d{2})(\d)/, '$1:$2').replace(/(-\d{2})\d+?$/, '$1');
  }
  return '';
}

export function cpfCnpjMask(value) {
  if (value !== null && value !== undefined) {
    const cpfCnpj = onlyNumbers(value);

    if (cpfCnpj.length === 11) {
      // 000.000.000-00
      return cpfCnpj
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d{1,2})/, '$1-$2')
        .replace(/(-\d{2})\d+?$/, '$1');
    }
    // 00.000.000/0000-00
    return cpfCnpj
      .replace(/(\d{2})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1/$2')
      .replace(/(\d{4})(\d)/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1');
  }
  return '';
}

export function cpfMask(value) {
  if (value !== null && value !== undefined) {
    const cpf = onlyNumbers(value);

    // 000.000.000-00
    return cpf
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1');
  }
  return '';
}

export function cnpjMask(value) {
  if (value !== null && value !== undefined) {
    const cnpj = onlyNumbers(value);

    // 00.000.000/0000-00
    return cnpj
      .replace(/(\d{2})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1/$2')
      .replace(/(\d{4})(\d)/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1');
  }
  return '';
}

export function cepMask(value) {
  if (value !== null && value !== undefined) {
    const cep = onlyNumbers(value);

    // 00.000-000
    return cep
      .replace(/(\d{2})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1-$2')
      .replace(/(-\d{3})\d+?$/, '$1');
  }
  return '';
}

export function dddPhoneMask(value) {
  if (value !== null && value !== undefined) {
    let phone = onlyNumbers(value);

    if (phone.length >= 12) {
      phone = phone.substring(2, phone.length);
    }

    // 00 0000-0000
    if (phone.length === 10) {
      return phone
        .replace(/(\d{2})(\d)/, '$1 $2')
        .replace(/(\d{4})(\d)/, '$1-$2')
        .replace(/(-\d{4})\d+?$/, '$1');
    }
    // 00 00000-0000
    return phone
      .replace(/(\d{2})(\d)/, '$1 $2')
      .replace(/(\d{5})(\d)/, '$1-$2')
      .replace(/(-\d{4})\d+?$/, '$1');
  }
  return '';
}

export function ddiPhoneMask(value) {
  if (value !== null && value !== undefined) {
    const phone = onlyNumbers(value);

    // +00 (00) 0000-0000
    if (phone.length === 12) {
      return phone
        .replace(/(\d{2})(\d)/, '+$1 $2')
        .replace(/(\d{2})(\d)/, '($1) $2')
        .replace(/(\d{4})(\d)/, '$1-$2')
        .replace(/(-\d{4})\d+?$/, '$1');
    }
    // +00 (00) 00000-0000
    return phone
      .replace(/(\d{2})(\d)/, '+$1 $2')
      .replace(/(\d{2})(\d)/, '($1) $2')
      .replace(/(\d{5})(\d)/, '$1-$2')
      .replace(/(-\d{4})\d+?$/, '$1');
  }
  return '';
}

export function cardValidityMask(value) {
  if (value !== null && value !== undefined) {
    const cardValidity = onlyNumbers(value);

    // 00/00
    return cardValidity
      .replace(/(\d{2})(\d)/, '$1/$2')
      .replace(/(\/\d{2})\d+?$/, '$1');
  }
  return '';
}

export function formatAddress(address) {
  if (!address) {
    return '';
  } else if (
    address.street.indexOf(address.number) > 0 &&
    !address.complement
  ) {
    return `${address.street}, ${
      address.neighborhood ? '' + address.neighborhood : ''
    } - ${address.city} - ${address.state} ${
      address.zipcode ? address.zipcode : ''
    }`;
  } else if (address.street.indexOf(address.number) > 0) {
    return `${address.street}${
      address.complement ? ' - ' + address.complement + ', ' : ','
    }${address.neighborhood ? '' + address.neighborhood : ''} - ${
      address.city
    } - ${address.state} ${address.zipcode ? address.zipcode : ''}`;
  } else if (!address.number) {
    return `${address.street}, ${'S/N'}${
      address.complement ? ', - ' + address.complement : ''
    }${address.neighborhood ? ', ' + address.neighborhood : ''} - ${
      address.city
    } - ${address.state} ${address.zipcode ? address.zipcode : ''}`;
  } else {
    return `${address.street}, ${address.number}${
      address.complement ? ' - ' + address.complement : ''
    }${address.neighborhood ? ', ' + address.neighborhood : ''} - ${
      address.city
    } - ${address.state} ${address.zipcode ? address.zipcode : ''}`;
  }
}

export function moneyMask(value) {
  if (value !== null && value !== undefined) {
    const amount = onlyNumbers(value);

    // 9999,99
    return amount.replace(/(\d{1,4})(\d{2})/, '$1.$2');
  }
  return '';
}

export function bankAccountMask(value) {
  if (value !== null && value !== undefined) {
    const cpf = onlyNumbers(value);
    // dash before last character
    
    return cpf
      .replace(/(\d{-1})(\d)/, '$1-$2')
  }
  return '';
}