import React from 'react';
import px2vw from '../../../utils/responsiveness/px2vw';
import { Award, Description, Hashtag, LeftContainer, Title } from './styles';

export const LeftSection = ({hashtag='#DesafioDosCampeões', title='Conclua o desafio da ativação', description='Complete as 4 tarefas do desafio da ativação em 48 horas.', award='Ganhe 15% de desconto em todos os planos.' }) => {
  return (
    <LeftContainer>
      <Hashtag regular size={px2vw(16)}>{hashtag}</Hashtag>
      <Title size={px2vw(20)}>{title}</Title>
      <Description regular size={px2vw(14)}>
        {description}
      </Description>
      <Award size={px2vw(14)} bold>{award}</Award>
    </LeftContainer>
  );
};


