import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { Spinner } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Icon } from 'react-materialize';
import moment from 'moment';
import ReactPaginate from 'react-paginate';
import { Form } from '@rocketseat/unform';
import { toast } from 'react-toastify';

import { Col, Row } from '../../../styles/grid';
import '../../../styles/react-datepicker.css';
import {
  ContentPage,
  ErrorText,
  LoadingBox,
} from '../../_layouts/styles/common';
import { PaginateContainer } from '../../Orders/OrderTable/styles';
import {
  DateDiv,
  FilterButton,
  FilterButtonText,
  Hr,
} from '../CashReport/styles';
import { PixReportBox, TextRegular, TextBold, TitleKeyPix } from './styles';
import { preto, verdeKuppi2 } from '../../../styles/colors';

import { formatCurrencyBRL } from '../../../utils/utils';
import { ddiPhoneMask } from '../../../utils/masks';
import px2vw from '../../../utils/responsiveness/px2vw';

import { clearReportErrors, getPixReportRequest } from '../../../store/modules/reports/actions';
import { updatePixKeyPaymentRequest, clearUpdatePixKeyPaymentSuccess } from '../../../store/modules/orders/actions';

export default function PixKeyReport() {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [tableRows, setTableRows] = useState([]);
  const [currOrderId, setCurrOrderId] = useState(-1);
  const storePaymentMethods = useSelector(
    (state) => state.user.user.advertiserInfo.advertiserPaymentMethod
  );
  const pixReportPaymentData = useSelector(
    (state) => state.reports.pixReportPaymentData
  );
  const pixReportPaymentDataPaginate = useSelector(
    (state) => state.reports.pixReportPaymentDataPaginate
  );
  const pixReportLoading = useSelector(
    (state) => state.reports.pixReportLoading
  );
  const pixKeyRequestSuccess = useSelector((state) => state.orders.pixKeyRequestSuccess);
  const pixReportError = useSelector(
    (state) => state.reports.pixReportError
  );
  const [pixKey, setPixKey] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearReportErrors());

    // Get current user pix key
    storePaymentMethods.filter((pm) => {
      if (pm.paymentMethod.function === 'money_transfer' && pm.paymentMethod.slug === 'pix_chave') {
        setPixKey(pm.external_processor_key);
      }
      return pm.paymentMethod.slug === 'pix_chave';
    });
  }, []);

  useEffect(() => {
    setTableRows(pixReportPaymentData);
  }, [pixReportPaymentData])

  useEffect(() => {
    if (pixKeyRequestSuccess) {   
      setTableRows(tableRows.map((item)=>{
        if (item.order_id === currOrderId) {
          return {...item, is_confirmed: !item.is_confirmed}
        }
        return item
      }));
      setCurrOrderId(-1);
      dispatch(clearUpdatePixKeyPaymentSuccess());
    }
  }, [pixKeyRequestSuccess])


  function handleChangePage(pageSelected) {
    dispatch(
      getPixReportRequest(
        pageSelected.selected + 1, 
        moment(startDate).format('YYYY-MM-DD'),
        moment(endDate).format('YYYY-MM-DD')       
      )
    );
  }

  function handleSelectPaymentConfirmingRequest(orderPaymentData) {
    setCurrOrderId(orderPaymentData.order_id);
    dispatch(updatePixKeyPaymentRequest({ order_id: orderPaymentData.order_id, paid_at: orderPaymentData.is_confirmed }));
  }

  const handlePixKeyReportSubmit = () => {
    if (startDate === null) {
      toast.error('Data de início é obrigatória.', {
        autoClose: 6000,
      });

      return false;
    }

    if (endDate === null) {
      toast.error('Data de fim é obrigatória.', {
        autoClose: 6000,
      });

      return false;
    }

    // Start Date - DDMMAAAA
    const startDateDay = startDate.getDate().toString();
    const startDateMonth = (startDate.getMonth() + 1).toString();
    const startDateYear = startDate.getFullYear()

    const newStartDate = new Date(`${startDateYear}-${startDateMonth}-${startDateDay}`);
    try {
      newStartDate.toISOString();
    } catch (e) {
      toast.error('Data de início inválida.', {
        autoClose: 6000,
      });

      return false;
    }

    // End Date - DDMMAAAA
    const endDateDay = endDate.getDate().toString();
    const endDateMonth = (endDate.getMonth() + 1).toString();
    const endDateYear = endDate.getFullYear()

    const newEndDate = new Date(`${endDateYear}-${endDateMonth}-${endDateDay}`);
    try {
      newEndDate.toISOString();
    } catch (e) {
      toast.error('Data de fim inválida.', {
        autoClose: 6000,
      });

      return false;
    }

    if (newEndDate < newStartDate) {
      toast.error('Data de fim não pode ser menor que a data de início.', {
        autoClose: 6000,
      });

      return false;
    }

    if (newEndDate > new Date()) {
      toast.error('Data de fim não pode ser maior que a data atual.', {
        autoClose: 6000,
      });

      return false;
    }

    const diffStartDates = moment(newStartDate)
    const diffEndDates = moment(newEndDate);
    if (diffEndDates.diff(diffStartDates, 'days') > 31) {
      toast.error('Intervalo de pesquisa não pode ser maior que 31 dias.', {
        autoClose: 6000,
      });

      return false;
    }

    dispatch(getPixReportRequest(1, `${startDateYear}-${startDateMonth}-${startDateDay}`, `${endDateYear}-${endDateMonth}-${endDateDay}`));
  };

  const contentPage = (
    <PixReportBox>
      <Row spaceBetween>
        <Row justifyStart width="auto">
          <TextBold>Escolha o período que deseja visualizar</TextBold>
        </Row>
        <Row justifyEnd width="auto">
          <TitleKeyPix weight="bold" className="mr-1">CHAVE PIX: </TitleKeyPix>
          <TitleKeyPix weight="normal">{pixKey || 'Não Cadastrada'}</TitleKeyPix>
        </Row>
      </Row>
      <Form onSubmit={handlePixKeyReportSubmit}>
        <Row justifyStart width="auto" margtop={px2vw(18.5)}>
          <Col width="auto" alignStart>
            <DateDiv>
              <DatePicker
                dateFormat="dd/MM/yyyy"
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                locale="pt-BR"
                selectsStart
                startDate={startDate}
                endDate={endDate}
                className="date"
                onChangeRaw={(e) => {
                  e.preventDefault();
                }}
              />
            </DateDiv>
          </Col>
          <Row
            width="auto"
            margtop={px2vw(4)}
            margleft={px2vw(8)}
            margright={px2vw(8)}
          >
            <TextRegular>até</TextRegular>
          </Row>
          <Col width="auto" alignStart>
            <DateDiv>
              <DatePicker
                dateFormat="dd/MM/yyyy"
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                locale="pt-BR"
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                className="date"
                onChangeRaw={(e) => {
                  e.preventDefault();
                }}
              />
            </DateDiv>
          </Col>
          <FilterButton type="submit" disabled={false}>
            {pixReportLoading ? (
              <LoadingBox>
                <Spinner
                  style={{
                    width: '1.4rem',
                    height: '1.4rem',
                    color: preto,
                  }}
                />
              </LoadingBox>
            ) : (
              <FilterButtonText>Filtrar</FilterButtonText>
            )}
          </FilterButton>
        </Row>
      </Form>
      {!pixReportLoading && pixReportPaymentData?.length > 0 && (
        <Col>
          <Row
            margtop={px2vw(20)}
            margbottom={px2vw(8)}
            height={px2vw(30)}
            spaceBetween
          >
            <TextBold flexStart height={px2vw(39)} width={px2vw(177)}>
              Cliente
            </TextBold>
            <TextBold flexStart height={px2vw(39)} width={px2vw(177)}>
              Telefone
            </TextBold>
            <TextBold flexCenter height={px2vw(39)} width={px2vw(177)}>
              ID do pedido
            </TextBold>
            <TextBold flexCenter height={px2vw(39)} width={px2vw(177)}>
              Valor do pedido
            </TextBold>
            <TextBold flexCenter height={px2vw(39)} width={px2vw(177)}>
              Conferido
            </TextBold>
          </Row>
          <Hr width={px2vw(930)} />
          {tableRows?.map((item) => (
            <Col alignStart>
              <Row
                margtop={px2vw(20)}
                margbottom={px2vw(8)}
                height={px2vw(39)}
                spaceBetween
                justifyStart
              >
                <TextRegular
                  height={px2vw(39)}
                  width={px2vw(177)}
                  verticalCenter
                >
                  {item.name}
                </TextRegular>
                <TextRegular
                  height={px2vw(39)}
                  width={px2vw(177)}
                  verticalCenter
                >
                  {ddiPhoneMask(item.phone)}
                </TextRegular>
                <TextRegular
                  flexCenter
                  height={px2vw(39)}
                  width={px2vw(177)}
                  verticalCenter
                >
                  {item.order_id}
                </TextRegular>
                <TextRegular
                  flexCenter
                  height={px2vw(39)}
                  width={px2vw(177)}
                  verticalCenter
                >
                  {formatCurrencyBRL(item.amount, {})}
                </TextRegular>
                <TextRegular
                  flexCenter
                  height={px2vw(39)}
                  width={px2vw(177)}
                  verticalCenter
                >
                  {item.order_id !== currOrderId && (
                    <img
                      alt="confirmPayment"
                      className="size-sm"
                      style={{ cursor: 'pointer' }}
                      src={require(`../../../assets/images/checkbox/${item?.is_confirmed ? 'checked' : 'checkedfalse'}.png`)}
                      onClick={()=> handleSelectPaymentConfirmingRequest(item)}
                    />
                  )}
                  {item.order_id === currOrderId && (
                    <LoadingBox>
                      <Spinner
                        style={{
                          width: '1.2rem',
                          height: '1.2rem',
                          color: verdeKuppi2,
                        }}
                      />
                    </LoadingBox>
                  )}
                </TextRegular>
              </Row>
              <Hr width={px2vw(930)} />
            </Col>
          ))}
        </Col>
      )}
      {!pixReportLoading && pixReportPaymentData?.length === 0 && (
        <Col alignCenter height="60vh">
          <Row height="auto" alignCenter justifyCenter>
            {/* TODO: Ajustar tamanho do icone */}
            <Icon large>sentiment_very_dissatisfied</Icon>
            <ErrorText>
              Não existem vendas para o período solicitado.
            </ErrorText>
          </Row>
        </Col>
      )}
      {/* Pagination Controls */}
      {pixReportPaymentData?.length > 0 && (
        <Row justifyEnd>
          <PaginateContainer>
            <ReactPaginate
              previousLabel={'<'}
              nextLabel={'>'}
              breakLabel={'...'}
              pageCount={pixReportPaymentDataPaginate?.lastPage}
              marginPagesDisplayed={3}
              pageRangeDisplayed={2}
              onPageChange={handleChangePage}
              containerClassName="pagination"
              pageClassName="page"
              activeClassName="active"
              breakClassName="break_cn"
              previousClassName="previous_cn"
              nextClassName="next_cn"
              disabledClassName="disabled_cn"
              previousLinkClassName="previous_a_cn"
              nextLinkClassName="next_a_cn"
              disabledLinkClassName="disabled_a_cn"
            />
          </PaginateContainer>
        </Row>
      )}
    </PixReportBox>
  );

  const errorPage = (
    <ContentPage minheight={px2vw(450)}>
      <Col alignCenter height="60vh">
        <Row height="auto" alignCenter justifyCenter>
          {/* TODO: Ajustar tamanho do icone */}
          <Icon large>sentiment_very_dissatisfied</Icon>
          <ErrorText>
            Ocorreu um erro ao carregar o relatório de caixa. Tente novamente.
          </ErrorText>
        </Row>
      </Col>
    </ContentPage>
  );

  if (pixReportError) {
    return errorPage;
  }
  return contentPage;
}