import React from 'react';

import { Row, Col } from '../../../../../../styles/grid';
import { SectionBox, SectionTitle } from '../../../../styles';
import {
  PlanDetailText2,
  PlanDetailTextBold,
  PlanDetailTextBold2,
} from '../styles';

import px2vw from '../../../../../../utils/responsiveness/px2vw';
import { cpfCnpjMask } from '../../../../../../utils/masks';
import { formatCurrencyBRL } from '../../../../../../utils/utils';

import { 
  AdvertiserNameText, 
  CPFCNPJText,
  SignatureMonthlyAmountText,
  SignatureTotalAmountText,
  SignatureMonthlyInstalmentsText
} from '../../../../constants';
import { 
  SectionTitleText, 
  EmailText, 
  VigencyText,
  SaveAmountText
} from "../constants";

export default function PlanDetailWeb({ handler }) {
  return (
    <>
      <Row width={px2vw(800)} justifyStart>
        <SectionTitle>
          {SectionTitleText}
        </SectionTitle>
      </Row>
      <SectionBox>
        <Col margleft={px2vw(25)} margtop={px2vw(20)}>
          <Row>
            <Col>
              <PlanDetailTextBold2>
                {handler.selectedPlan ? handler.selectedPlan?.name : handler.recurringPaymentData?.subscription?.name}
              </PlanDetailTextBold2>
              <PlanDetailText2>
                {VigencyText.replace('$$VIGENCY$$', handler.vigencyInfo)}
              </PlanDetailText2>
            </Col>
            <Col>
              <PlanDetailText2>
                {AdvertiserNameText}
              </PlanDetailText2>
              <PlanDetailTextBold2
                advertiserName
                title={handler.signaturePaymentData?.advertiserInfo?.name}
              >
                {handler.signaturePaymentData?.advertiserInfo?.name}
              </PlanDetailTextBold2>
            </Col>
          </Row>
          <Row margtop={px2vw(20)}>
            <Col>
              <PlanDetailText2>
                {EmailText}
              </PlanDetailText2>
              <PlanDetailTextBold
                title={handler.signaturePaymentData?.advertiserInfo?.user?.email}
              >
                {handler.signaturePaymentData?.advertiserInfo?.user?.email}
              </PlanDetailTextBold>
            </Col>
            <Col>
              <PlanDetailText2>
                {CPFCNPJText}
              </PlanDetailText2>
              <PlanDetailTextBold>
                {cpfCnpjMask(handler.signaturePaymentData?.advertiserInfo?.cpf_cnpj)}
              </PlanDetailTextBold>
            </Col>
          </Row>
          <Row margtop={px2vw(20)}>
            <Col>
              <PlanDetailText2>
                {SignatureMonthlyAmountText}
              </PlanDetailText2>
              <PlanDetailTextBold2>
                {handler.selectedPlan?.period > 1 && (`${handler.selectedPlan?.period}${SignatureMonthlyInstalmentsText}`)}
                {formatCurrencyBRL(handler.selectedPlan?.new_subscription_amount.toFixed(2), {})}
              </PlanDetailTextBold2>
              {handler.selectedPlan?.discount_percentage !== 0 && (
                <PlanDetailTextBold2>
                  {`${SaveAmountText}${formatCurrencyBRL(handler.selectedPlan?.saved_amount, {})}`}
                </PlanDetailTextBold2> 
              )}                        
            </Col>
            {handler.selectedPlan?.period > 1 && (
              <Col>
                <PlanDetailText2>
                  {SignatureTotalAmountText}
                </PlanDetailText2>
                <PlanDetailTextBold2>
                  
                  {formatCurrencyBRL(
                    (
                      Math.round(
                        (handler.selectedPlan?.new_subscription_amount ? handler.selectedPlan?.new_subscription_amount : handler.newPlanOptions && handler.newPlanOptions[0] ? handler.newPlanOptions[0].new_subscription_amount : formatCurrencyBRL(handler.recurringPaymentData?.subscription?.amount.toFixed(2), {})) 
                          *
                          handler.selectedPlan?.period *
                          100                         
                      ) / 100
                    ).toFixed(2),
                    {}
                  )}
                </PlanDetailTextBold2>
              </Col>
            )}
          </Row>
        </Col>
      </SectionBox>
    </>
  );
}
