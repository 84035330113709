import styled from 'styled-components';
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';

import { Row } from '../../../../styles/grid';
import px2vw from '../../../../utils/responsiveness/px2vw';
import {
  preto,
  branco,
  amareloKuppi2,
  cinzaKuppi1,
  cinzaKuppi7,
  verdeKuppi1,
  verdeKuppi2,
} from '../../../../styles/colors';

export const Container = styled.div``;

export const Header = styled(Row)`
  justify-content: flex-start;
  align-items: flex-start;
  padding: ${px2vw(25)} 0;
  padding: ${(props) => props.padding};
  font-size: ${px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.36px;

  ${(props) => `
    width: ${props.width};
  `};
`;

export const HeaderButton = styled.button`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: AvenirNextLTPro;
  font-weight: 700;
  background: transparent !important;
  border: none !important;
  box-shadow: none !important;
`;

export const Content = styled(Row)`
  justify-content: flex-start;
  align-items: flex-start;
  padding: ${px2vw(20)} 0;
  font-size: ${px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.36px;

  ${(props) => props.isCashOutHighlight && `
    font-size: ${px2vw(12)};
    font-weight: bold;
    color: ${props.isOpen ? `${cinzaKuppi7}` : `${verdeKuppi2}`};
    padding: 0;
  `}

  ${(props) => props.isCashOutHighlightUnder && `
    font-size: ${px2vw(12)};
    color: ${props.isOpen ? `${cinzaKuppi7}` : `${verdeKuppi2}`};
    padding: 0;
  `}

  ${(props) => props.isClickable && `
    cursor: pointer;
  `}
`;

export const TableRow = styled(Row)`
  :hover {
    background: ${cinzaKuppi1};
  }
`;

export const Hr = styled.div`
  border-top: 2px solid ${cinzaKuppi1};
  height: 1px;
  width: 100%;
`;

export const PagButton = styled.button`
  width: ${px2vw(40)};
  height: ${px2vw(40)};
  border-radius: ${px2vw(40)};
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent !important;
  border: none !important;
  box-shadow: none !important;

  :hover {
    ${(props) =>
      props.disabled
        ? `background: transparent !important; cursor: not-allowed !important`
        : `background: ${cinzaKuppi1} !important`};
  }
`;

export const PrevIcon = styled(AiOutlineLeft).attrs({
  size: px2vw(12),
})`
  color: ${(props) => props.disabled && cinzaKuppi7};
`;
export const NextIcon = styled(AiOutlineRight).attrs({
  size: px2vw(12),
})`
  color: ${(props) => props.disabled && cinzaKuppi7};
`;

export const PauseButton = styled.button`
  height: 40px;
  width: 160px;
  border: none;
  background-color: transparent;

  ${(props) => `
    margin-left: ${props.margleft};
    margin-top: ${props.margtop};
    width: ${props.width};
  `};
`;

export const ButtonBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  ${(props) => `
    width: ${props.width};
  `};
`;

export const ButtonImage = styled.img`
  height: 20px;
  width: 20px;
  margin-right: 10px;

  ${(props) =>
    props.active &&
    `
    filter: invert(40%) sepia(79%) saturate(2498%) hue-rotate(4deg) brightness(103%) contrast(104%);
  `};
`;

export const ButtonTextRegular = styled.div`
  font-size: 16px;
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.32px;
  margin-top: 2px;

  ${(props) => props.greenColor && `color: ${verdeKuppi1}`};
  ${(props) =>
    props.yellowColor &&
    `
    color: ${amareloKuppi2};
    margin-right: ${props.margright};
  `};
`;

export const ErrorText = styled.div`
  font-size: ${px2vw(16)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.4px;
  margin-left: ${px2vw(20)};
`;

export const DetailsButton = styled.button`
  height: ${px2vw(30)};
  width: ${px2vw(70)};
  border-radius: ${px2vw(10)};
  border: 2px solid ${preto};
  color: ${preto};
  background-color: ${branco};
  font-family: AvenirNextLTPro;
  font-weight: 700;

  ${(props) => props.disabled && `
    border: 2px solid ${preto}40;
    color: ${preto}40;
  `}
`;