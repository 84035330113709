import { takeLatest, call, put, all, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { apiAnunciante, apiGlobal } from '../../../services/api';

import {
  cancelPixPaymentFailure,
  cancelPixPaymentSuccess,
  createPixPaymentFailure,
  createPixPaymentSuccess,
  finishOnboardingFailure,
  finishOnboardingSuccess,
  setBalance,
  setBalanceModalCurrentStep,
  setCreateCampaignFailure,
  setCreateCampaignSuccess,
  setFacebookId,
  setGenAds,
  setInstagramId,
  setKuppiBoost,
  setOnboardingData,
  setOnboardingUpdateLoader,
  whatsappCheckRequestFailure,
  whatsappCheckRequestSuccess,
} from './actions';
import { logout } from '../auth/actions';

const userData = (state) => state.user.user;
const kuppiBoostData = (state) => state.kuppiBoost;

export function* getGenAds({ payload }) {
  try {
    const user = yield select(userData);

    const data = {
      budget: payload.balance,
      max_radius_length: payload.rangeArea,
      automatic: true,
      latitude: user.address[0].latitude,
      longitude: user.address[0].longitude,
    };

    const response = yield call(
      apiAnunciante.post,
      `/kuppi-boost/estimated-audience`,
      data
    );

    yield put(setGenAds(response.data));
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      if (
        err.response?.data?.error?.message ===
        'Não é possível criar uma campanha com os dados informados!'
      ) {
        toast.error(
          'Não será possível criar uma campanha efetiva com esses dados. Alterar o raio de alcance e tente novamente.',
          {
            autoClose: 6000,
          }
        );
      } else {
        toast.error(
          err.response?.data?.error?.message ||
            'Ocorreu um erro ao gerar a campanha automaticamente. Recarregue a página e tente novamente!',
          {
            autoClose: 6000,
          }
        );
      }
      yield put(
        setGenAds({
          campaign_period: 0,
          campaign_max_period: 0,
          campaign_end_date: null,
          campaign_radius: 0,
          campaign_estimated_reach: 0,
          campaign_budget: 0,
        })
      );
    }
  }
}

export function* getKuppiBoost() {
  try {
    const user = yield select(userData);
    const userInfoId = user.advertiserInfo.id;

    const response = yield call(
      apiAnunciante.get,
      `/user-info/${userInfoId}/kuppi-boost`
    );

    const { campaigns, available_coupons, available_products, payments_data } =
      response.data;

    yield put(
      setKuppiBoost(
        campaigns,
        available_coupons,
        available_products,
        payments_data
      )
    );
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      toast.error(
        'Ocorreu um erro ao carregar as informações. Recarregue a página e tente novamente!',
        {
          autoClose: 6000,
        }
      );
    }
  }
}

export function* getOnboardingData() {
  try {
    const user = yield select(userData);
    const userInfoId = user.advertiserInfo.id;

    const response = yield call(
      apiAnunciante.get,
      `/user-info/${userInfoId}/kuppi-boost/config`
    );

    const data = response.data[0];

    yield put(setBalance(data.balance));
    yield put(setOnboardingData(data));

    if (data.facebook_page_id || data.instagram_page_id) {
      yield put(setFacebookId(data.facebook_page_id || null));
      yield put(setInstagramId(data.instagram_page_id || null));
    }
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      toast.error(
        'Ocorreu um erro ao carregar as informações. Recarregue a página e tente novamente!',
        {
          autoClose: 6000,
        }
      );
    }
  }
}

export function* updateOnboaringRequest({ payload }) {
  try {
    const user = yield select(userData);
    const userInfoId = user.advertiserInfo.id;

    const response = yield call(
      apiAnunciante.put,
      `/user-info/${userInfoId}/kuppi-boost/config`,
      {
        facebook_page_id: payload.facebook_id,
        instagram_page_id: payload.instagram_id,
      }
    );

    yield put(setOnboardingData(response.data));
    if (response.data.facebook_page_id || response.data.instagram_page_id) {
      yield put(setFacebookId(response.data.facebook_page_id || null));
      yield put(setInstagramId(response.data.instagram_page_id || null));
    }

    payload.cbClose();

    yield put(setOnboardingUpdateLoader(false));
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(setOnboardingUpdateLoader(false));

      toast.error(
        'Ocorreu um erro ao enviar sua solicitação. Recarregue a página e tente novamente!',
        {
          autoClose: 8000,
        }
      );
    }
  }
}

export function* createCampaign({ payload }) {
  try {
    const user = yield select(userData);
    const kuppiBoost = yield select(kuppiBoostData);
    const userInfoId = user.advertiserInfo.id;
    const { gen_ads, campaign } = kuppiBoost;
    const { type, main_text, title, selected_coupon, selected_product } =
      campaign;
    const isTypeRangeOrConversion = type === 'conversion' || type === 'range';
    const isTypeRangeOrWhatsapp = type === 'whatsapp' || type === 'range';
    const image = isTypeRangeOrWhatsapp
      ? selected_product?.imgTemplate
      : selected_coupon?.imgTemplate;

    const base64Content = image.replace(/^data:image\/\w+;base64,/, '');
    const productId =
      type === 'whatsapp'
        ? null
        : type === 'range'
        ? selected_product?.id
        : selected_coupon?.product_id;
    const couponId = type === 'conversion' ? selected_coupon?.coupon_id : null;

    const data = {
      main_text: main_text,
      title: title,
      radius_length: payload.rangeArea,
      coupon_id: couponId,
      product_id: productId,
      recommended_period: gen_ads.campaign_period,
      call_to_action: isTypeRangeOrConversion
        ? 'LEARN_MORE'
        : 'WHATSAPP_MESSAGE',
      boost_type: type,
      budget: payload.campaign_budget.toFixed(2),
      campaign_image: {
        base64Content,
        name: `KuppiBoostImage${new Date().getTime()}`,
        type: 'image',
        sub_type: 'jpeg',
      },

    };

    const response = yield call(
      apiAnunciante.post,
      `/user-info/${userInfoId}/kuppi-boost`,
      data
    );

    const { campaigns, available_coupons, available_products, payments_data } =
      response.data;

    yield put(
      setKuppiBoost(
        campaigns,
        available_coupons,
        available_products,
        payments_data
      )
    );
    yield put(setBalance(response.data.balance));
    yield put(setCreateCampaignSuccess());
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      toast.error(
        'Ocorreu um erro na criação da campanha. Recarregue a página e tente novamente!',
        {
          autoClose: 6000,
        }
      );
      yield put(setCreateCampaignFailure());
    }
  }
}

export function* createPixPaymentRequest({ payload }) {
  try {
    const user = yield select(userData);
    const userInfoId = user.advertiserInfo.id;

    const data = {
      total_amount: payload.total_amount * 100,
      budget: payload.budget * 100,
      processing_fee: payload.processing_fee * 100,
      name: user.advertiserInfo.name,
      email: user.email,
      document_number: user.advertiserInfo.cpf_cnpj,
      phone: user.advertiserInfo.phone_whatsapp_representative
        ? user.advertiserInfo.phone_whatsapp_representative
        : user.address[0].phone_1,
      address: {
        street: user.address[0].street,
        street_number: user.address[0].number || 'S/N',
        neighborhood: user.address[0].neighborhood,
        city: user.address[0].city,
        state: user.address[0].state,
        zipcode: user.address[0].zipcode,
      },
    };

    const response = yield call(
      apiGlobal.post,
      `/anunciante/${userInfoId}/kuppi-boost/payment/pix/process`,
      data
    );

    yield put(createPixPaymentSuccess(response.data));
    yield put(setBalanceModalCurrentStep('2'));
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(createPixPaymentFailure());
      toast.error(
        'Ocorreu um erro ao gerar o pagamento pix. Recarregue a página e tente novamente!',
        {
          autoClose: 6000,
        }
      );
    }
  }
}

export function* cancelPixPaymentRequest({ payload }) {
  try {
    const user = yield select(userData);
    const kuppiBoost = yield select(kuppiBoostData);
    const userInfoId = user.advertiserInfo.id;
    const { campaigns, available_coupons, available_products } = kuppiBoost;

    const response = yield call(
      apiGlobal.put,
      `/anunciante/${userInfoId}/kuppi-boost/payment/${payload.payment_id}/cancel`
    );

    toast.success('O pagamento foi cancelado com sucesso!', {
      autoClose: 6000,
    });

    yield put(
      setKuppiBoost(
        campaigns,
        available_coupons,
        available_products,
        response.data
      )
    );
    yield put(cancelPixPaymentSuccess());
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(cancelPixPaymentFailure());
      toast.error('Ocorreu um erro ao cancelar o pagamento!', {
        autoClose: 6000,
      });
    }
  }
}

export function* finishOnboardingRequest() {
  try {
    const user = yield select(userData);
    const userInfoId = user.advertiserInfo.id;

    const response = yield call(
      apiAnunciante.put,
      `/user-info/${userInfoId}/kuppi-boost/finish-onboarding`
    );

    const data = response.data;

    yield put(setBalance(data.balance));
    yield put(setOnboardingData(data));

    if (data.facebook_page_id || data.instagram_page_id) {
      yield put(setFacebookId(data.facebook_page_id || null));
      yield put(setInstagramId(data.instagram_page_id || null));
    }

    yield put(finishOnboardingSuccess());
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      toast.error('Ocorreu um erro na finalização do onboarding!', {
        autoClose: 6000,
      });
      yield put(finishOnboardingFailure());
    }
  }
}

export function* whatsappCheckRequest() {
  try {
    const user = yield select(userData);
    const userInfoId = user.advertiserInfo.id;

    const response = yield call(
      apiAnunciante.get,
      `/user-info/${userInfoId}/kuppi-boost/whatsapp-check`
    );

    yield put(whatsappCheckRequestSuccess(response.data));
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      toast.error('Ocorreu um erro na verificação do whatsapp!', {
        autoClose: 6000,
      });
      yield put(whatsappCheckRequestFailure());
    }
  }
}

export default all([
  takeLatest('@kuppiBoost/GET_KUPPI_BOOST', getKuppiBoost),
  takeLatest(
    '@kuppiBoost/CREATE_PIX_PAYMENT_RESQUEST',
    createPixPaymentRequest
  ),
  takeLatest(
    '@kuppiBoost/CANCEL_PIX_PAYMENT_RESQUEST',
    cancelPixPaymentRequest
  ),
  takeLatest('@kuppiBoost/FINISH_ONBOARDING_REQUEST', finishOnboardingRequest),
  takeLatest('@kuppiBoost/GET_GEN_ADS', getGenAds),
  takeLatest('@kuppiBoost/GET_KUPPI_BOOST_ONBOARDING', getOnboardingData),
  takeLatest('@kuppiBoost/CREATE_CAMPAIGN_REQUEST', createCampaign),
  takeLatest(
    '@kuppiBoost/UPDATE_KUPPI_BOOST_ONBOARDING',
    updateOnboaringRequest
  ),
  takeLatest('@kuppiBoost/WHATSAPP_CHECK_REQUEST', whatsappCheckRequest),
]);
