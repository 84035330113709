import React, {
  Fragment,
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { Icon } from 'react-materialize';
import { Row, Col } from '../../../../../styles/grid';
import px2vw from '../../../../../utils/responsiveness/px2vw';

import {
  Header,
  Content,
  Hr,
  TableRow,
  HeaderButton,
  PagButton,
  PrevIcon,
  NextIcon,
  ErrorText,
  TableIndexText,
} from './styles';

import { SvgBox, Arrow, Arrow2 } from './icons';
import { sort, makePages } from './tableOptions';

import TablePerPage from '../../../../../components/TablePerPage';
import { formatCurrencyBRL } from '../../../../../utils/utils';

export default function TransferDetailsTable({ data }) {
  const [rows, setRows] = useState([]);
  const [rowsCurrentPage, setRowsCurrentPage] = useState();
  const [structure, setStructure] = useState(null);
  const [currentPage, setCurrentPage] = useState('1');
  const [lastPage, setLastPage] = useState();
  const [firstPage] = useState('1');
  const [sortedCol, setSortedCol] = useState('');

  useEffect(() => {
    if (structure) {
      setLastPage(
        Object.keys(structure)[Object.keys(structure).length - 1].toString()
      );
    }
  }, [structure]);

  useMemo(() => {
    setStructure(makePages(rows.length, rowsCurrentPage));
  }, [rows, rowsCurrentPage]);

  useEffect(() => {
    setRows(data);
  }, [data]);

  useEffect(() => {
    if (rows.length < 5) {
      setRowsCurrentPage(rows.length);
    }
    if (rowsCurrentPage > 5) {
    } else {
      setRowsCurrentPage(5);
    }
  }, [rows]);

  const handleSort = useCallback(
    (col) => {
      if (sortedCol !== col) {
        setSortedCol(col);
        setRows(sort(rows, col, 'asc'));
      } else {
        setSortedCol('');
        setRows(sort(rows, col, 'desc'));
      }
    },
    [rows]
  );

  function handlePerPage(size) {
    setRowsCurrentPage(size);
  }

  function handleChangePage(direction) {
    if (direction === 'next') {
      const curr = +currentPage + 1;
      setCurrentPage(curr.toString());
    } else if (direction === 'prev') {
      const curr = +currentPage - 1;
      setCurrentPage(curr.toString());
    }
  }

  return (
    <Col alignCenter justifyCenter>
      <Row>
        <Header onClick={() => handleSort('date')} width="17%">
          <HeaderButton>
            Data
            <SvgBox sorted={sortedCol === 'date'}>
              <Arrow />
              <Arrow2 />
            </SvgBox>
          </HeaderButton>
        </Header>

        <Header onClick={() => handleSort('order_id')} width="22%">
          <HeaderButton>
            Pedido
            <SvgBox sorted={sortedCol === 'order_id'}>
              <Arrow />
              <Arrow2 />
            </SvgBox>
          </HeaderButton>
        </Header>

        <Header onClick={() => handleSort('amount')} width="20%">
          <HeaderButton>
            Valor
            <SvgBox sorted={sortedCol === 'amount'}>
              <Arrow />
              <Arrow2 />
            </SvgBox>
          </HeaderButton>
        </Header>

        <Header onClick={() => handleSort('fee')} width="25%">
          <HeaderButton>
            Taxa Gateway
            <SvgBox sorted={sortedCol === 'fee'}>
              <Arrow />
              <Arrow2 />
            </SvgBox>
          </HeaderButton>
        </Header>

        <Header onClick={() => handleSort('received_amount')} width="20%">
          <HeaderButton>
            Valor Recebido
            <SvgBox sorted={sortedCol === 'received_amount'}>
              <Arrow />
              <Arrow2 />
            </SvgBox>
          </HeaderButton>
        </Header>
      </Row>
      <Row>
        <Hr />
      </Row>
      {structure ? (
        rows.map((order, index) =>
          structure[currentPage] &&
            structure[currentPage].start <= index + 1 &&
            structure[currentPage].end >= index + 1 ? (
            <Fragment key={`order-${index}`}>
              <TableRow alignCenter justifyCenter>
                <Content width="19%" isGray>
                  {order.date}
                </Content>
                <Content width="25%" isGray>
                  {`#${order.order_id}`}
                </Content>
                <Content width="23%" isGray>
                  {formatCurrencyBRL(order.amount.toFixed(2), 2)}
                </Content>
                <Content width="28%" isGray>
                   {formatCurrencyBRL((order.fee * -1).toFixed(2), 2) }
                </Content>
                <Content width="22%" isGreen={order.received_amount >= 0} isRed={order.received_amount < 0}>
                   {formatCurrencyBRL(order.received_amount.toFixed(2), 2)}
                </Content>
              </TableRow>
              <Row>
                <Hr />
              </Row>
            </Fragment>
          ) : null
        )
      ) : (
        <Col height="20vh">
          <Row alignCenter justifyCenter>
            {/* TODO: Ajustar tamanho do icone */}
            <Icon large>sentiment_very_dissatisfied</Icon>
            <ErrorText>Repasse sem transações a exibir.</ErrorText>
          </Row>
        </Col>
      )}
      {structure && (
        <Row alignCenter justifyEnd>
          <Row alignCenter justifyEnd height={px2vw(40)} margright={px2vw(65)}>
            <TablePerPage response={handlePerPage} length={rows.length} />
            <Row alignCenter justifyCenter width="10%" font>
              <TableIndexText>
                {!structure[currentPage] && handleChangePage('prev')}
                {structure[currentPage] && structure[currentPage].start} -{' '}
                {structure[currentPage] && structure[currentPage].end} de{' '}
                {rows.length}
              </TableIndexText>

            </Row>
            <PagButton
              onClick={() => handleChangePage('prev')}
              disabled={currentPage === firstPage}
              title="Página anterior"
            >
              <PrevIcon disabled={currentPage === firstPage} />
            </PagButton>
            <PagButton
              onClick={() => handleChangePage('next')}
              title="Próxima página"
              disabled={currentPage === lastPage}
            >
              <NextIcon disabled={currentPage === lastPage} />
            </PagButton>
          </Row>
        </Row>
      )}
    </Col>
  );
}
