export function initiateWhatsappbotRequest() {
  return {
    type: '@whatsappBot/INITIATE_WHATSAPPBOT_REQUEST'
  };
}

export function disconnectWhatsappbotRequest() {
  return {
    type: '@whatsappBot/DISCONNECT_WHATSAPPBOT_REQUEST'
  };
}

export function updateWhatsappbotStatus(status, isActive, phone, qrCodeImage = null) {
  return {
    type: '@whatsappBot/UPDATE_WHATSAPPBOT_STATUS',
    payload: { status, isActive, qrCodeImage, phone},
  };
}

export function changeWhatsappbotFunctionsRequest(isSendingOrderStatusMsg, isUsingChatbot) {
  return {
    type: '@whatsappBot/CHANGE_WHATSAPPBOT_FUNCTIONS_REQUEST',
    payload: { isSendingOrderStatusMsg, isUsingChatbot},
  };
}

export function sendWhatsappbotOrderStatusMessageRequest(clientPhone, orderStatusMessage) {
  return {
    type: '@whatsappBot/SEND_WHATSAPPBOT_ORDER_STATUS_MESSAGE_REQUEST',
    payload: { clientPhone, orderStatusMessage},
  };
}

export function sendWhatsappBotChatMessageRequest(clientPhone, chatMessage, type, media) {
  return {
    type: '@whatsappBot/SEND_WHATSAPPBOT_BOT_CHAT_MESSAGE_REQUEST',
    payload: { clientPhone, chatMessage, type, media},
  };
}

export function whatsappBotChatDeleteMessageRequest(clientPhone, id) {
  return {
    type: '@whatsappBot/WHATSAPPBOT_BOT_CHAT_DELETE_MESSAGE_REQUEST',
    payload: { clientPhone, id},
  };
}

export function setWhatsappPlatform(platform) {
  return {
    type: '@whatsappBot/SET_WHATSAPP_PLATFORM',
    payload: {
      platform
    }
  };
}
