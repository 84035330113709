export function getPromptOrdersPerDayAndPerHourRequest(key, advertiserCategory, weekdayText, perHoursText) {
  return {
    type: '@insightsAi/GET_PROMPT_ORDERS_PER_DAY_AND_PER_HOUR_REQUEST',
    payload: { key, advertiserCategory, weekdayText, perHoursText }
  };
}

export function getPromptOrdersPerDayAndPerHourSuccess(key, response) {
  return {
    type: '@insightsAi/GET_PROMPT_ORDERS_PER_DAY_AND_PER_HOUR_SUCCESS',
    payload: { key, response }
  };
}

export function getPromptOrdersPerDayAndPerHourFailure() {
  return {
    type: '@insightsAi/GET_PROMPT_ORDERS_PER_DAY_AND_PER_HOUR_FAILURE'
  };
}

export function getPromptOrderTypesRequest(key, advertiserCategory, orderOrigins, orderTypes) {
  return {
    type: '@insightsAi/GET_PROMPT_ORDER_TYPES_REQUEST',
    payload: { key, advertiserCategory, orderOrigins, orderTypes }
  };
}

export function getPromptOrderTypesSuccess(key, response) {
  return {
    type: '@insightsAi/GET_PROMPT_ORDER_TYPES_SUCCESS',
    payload: { key, response }
  };
}

export function getPromptOrderTypesFailure() {
  return {
    type: '@insightsAi/GET_PROMPT_ORDER_TYPES_FAILURE'
  };
}

export function getPromptTopSoldProductsRequest(key, advertiserCategory, products) {
  return {
    type: '@insightsAi/GET_PROMPT_TOP_SELLING_PRODUCTS_REQUEST',
    payload: { key, advertiserCategory, products }
  };
}

export function getPromptTopSoldProductsSuccess(key, response) {
  return {
    type: '@insightsAi/GET_PROMPT_TOP_SELLING_PRODUCTS_SUCCESS',
    payload: { key, response }
  };
}

export function getPromptTopSoldProductsFailure() {
  return {
    type: '@insightsAi/GET_PROMPT_TOP_SELLING_PRODUCTS_FAILURE'
  };
}

export function getPromptOrdersPerNeighborhoodRequest(key, advertiserCategory, neighborhoods) {
  return {
    type: '@insightsAi/GET_PROMPT_ORDERS_PER_NEIGHBORHOOD_REQUEST',
    payload: { key, advertiserCategory, neighborhoods }
  };
}

export function getPromptOrdersPerNeighborhoodSuccess(key, response) {
  return {
    type: '@insightsAi/GET_PROMPT_ORDERS_PER_NEIGHBORHOOD_SUCCESS',
    payload: { key, response }
  };
}

export function getPromptOrdersPerNeighborhoodFailure() {
  return {
    type: '@insightsAi/GET_PROMPT_ORDERS_PER_NEIGHBORHOOD_FAILURE'
  };
}