import styled, { css } from 'styled-components';

import {
  preto,
  branco,
  cinzaKuppi4,
  cinzaKuppi5,
  cinzaKuppi7,
  verdeKuppi2,
  cinzaKuppi11,
  cinzaKuppi2,
  verdeKuppi1,
  cinzaKuppi8,
  cinzaKuppi1,
  verdeKuppi10,
} from '../../../styles/colors';

import px2vw from '../../../utils/responsiveness/px2vw';

export const Container = styled.div`
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  min-height: fill-available;
  max-height: inherit;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 0.3em;
    border-radius: 3px;
  }
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${verdeKuppi2};
    outline: 1px solid ${verdeKuppi2};
  }
`;

export const ButtonPrimary = styled.button`
  background: ${(props) => (props.color ? props.color : verdeKuppi2)};
  border: none;
  margin: 0 ${px2vw(8)};
  color: ${(props) => (props.fontColor ? props.fontColor : branco)};
  border-radius: ${px2vw(5)};
  font-size: ${px2vw(13)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.32px;
  padding: ${px2vw(8)};
  width: ${(props) => (props.width ? props.width : px2vw(320))};
  box-shadow: 2px 2px 3px ${cinzaKuppi2};

  &:active {
    box-shadow: 2px 2px 3px ${cinzaKuppi2};
    background: ${verdeKuppi1};
  }

  &:disabled {
    text-decoration: none;
    background: ${cinzaKuppi2};
    box-shadow: 2px 2px 3px ${cinzaKuppi2};
    color: ${branco};
  }
`;

export const ModalFooter = styled.footer`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${px2vw(26)};
  border: 1px solid ${cinzaKuppi4};
  box-shadow: -0.1px -3px 3px ${cinzaKuppi4};
  margin-top: auto;
  min-height: ${px2vw(100)};
  width: 100%;
  z-index: 5;
`;

export const AddressBox = styled.footer`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${cinzaKuppi4};
  box-shadow: -0.1px -3px 3px ${cinzaKuppi4};
  margin-top: auto;

  width: 100%;
  z-index: 5;
`;

export const Span = styled.span`
  cursor: ${(props) => (props.cursor ? props.cursor : 'initial')};
  padding: ${(props) => (props.padding ? props.padding : '0')};
  font-size: ${(props) => (props.fontSize ? props.fontSize : px2vw(14))};
  font-family: AvenirNextLTPro;
  letter-spacing: 0.36px;
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 'bold')};
  display: ${(props) => (props.display ? props.display : 'block')};
  color: ${(props) => (props.color ? props.color : verdeKuppi2)};
  @media (max-width: 500px) {
    font-size: ${px2vw(20)};
  }
  .badge {
    background: ${cinzaKuppi11};
    font-size: ${px2vw(13)};
  }
`;

export const ModalHeader = styled.header`
  display: flex;
  justify-content: space-between;
  background-color: ${verdeKuppi2};
  align-items: center;
  width: 100%;
  letter-spacing: 0.36px;
`;

export const ManualOrderHeader = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${verdeKuppi2};
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
  border-color: ${verdeKuppi2};
  padding: ${px2vw(13)} ${px2vw(20)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  color: ${branco};
  font-size: ${px2vw(15.5)};
  display: flex;
  align-items: space-between;
  justify-content: space-between;
`;

export const ProgressBar = styled.div`
  background-color: ${cinzaKuppi11};
  width: 100%;
`;

export const CloseButton = styled.button`
  width: ${px2vw(18)};
  height: ${px2vw(18)};
  border: none;
  z-index: 10;
  background-color: transparent;

  :focus {
    background-color: transparent !important;
  }

  ${(props) =>
    props.absolute &&
    css`
      position: absolute;
      top: ${props.top || px2vw(10)};
      right: ${props.right || px2vw(10)};
    `}
`;

export const CloseImage = styled.img`
  width: ${px2vw(17)};
  height: ${px2vw(17)};
`;

export const BackIcon = styled.img`
  margin-left: ${px2vw(13)};
  background-color: ${cinzaKuppi4};
  border-radius: 2px;
  padding: ${px2vw(7)};
  cursor: pointer;
  :active {
    background-color: ${cinzaKuppi7};
  }
`;

export const StepTittle = styled.div`
  font-family: AvenirNextLTPro;
  font-weight: 700;
  color: ${preto};
  font-size: ${px2vw(17)};
  margin-right: ${px2vw(15.5)};
  white-space: nowrap;
  letter-spacing: 0.36px;
`;

export const Select = styled.select`
  width: ${(props) => (props.w ? props.w : px2vw(150))};
  background: ${cinzaKuppi8};
  border-radius: 7px;
  border: none;
  font-size: ${px2vw(15)};
  height: ${px2vw(42)};
  padding: 0 ${px2vw(20)};
  font-family: AvenirNextLTPro;
  letter-spacing: 0.36px;
  font-weight: 500;
  cursor: pointer;

  ${(props) =>
    props.disabled &&
    `
     background: ${cinzaKuppi1};
     border-radius: 10px;
     color: ${cinzaKuppi5};
     cursor: initial;
  `}
`;

export const Option = styled.option`
  font-family: AvenirNextLTPro;
  letter-spacing: 0.36px;
  background: ${cinzaKuppi8};
  font-weight: 500;
  width: 100%;
  &:disabled {
    color: ${preto};
  }

  &:hover {
    background-color: ${verdeKuppi2};
  }
`;

export const Input = styled.input`
  width: ${(props) => (props.w ? props.w : px2vw(150))};
  background: ${cinzaKuppi8};
  border-radius: 7px;
  border: none;
  font-size: ${px2vw(16)};
  height: ${(props) => (props.h ? props.h : px2vw(43))};
  padding: 0 ${px2vw(19)};
  font-family: AvenirNextLTPro;
  letter-spacing: 0.36px;
  font-weight: 500;
  text-overflow-mode: ellipsis;

  ${(props) =>
    props.disabled &&
    `
     background: ${cinzaKuppi1};
     border-radius: 10px;
     color: ${cinzaKuppi5};
  `}

  ::placeholder {
    color: ${cinzaKuppi7};
  }
`;

export const inputMui = {
  width: '100%',
  background: cinzaKuppi8,
  borderRadius: '7px',
  border: 'none',
  fontSize: px2vw(15),
  height: px2vw(45),
  paddingLeft: px2vw(40),
  fontFamily: 'AvenirNextLTPro',
  letterSpacing: '0.36px',
  fontWeight: 500,
};

export const formMui = {
  width: '100%',
  background: cinzaKuppi8,
  borderRadius: '7px',
  border: 'none',
  position: 'relative',
  boxShadow: 'none',
};

export const iconMui = {
  color: cinzaKuppi7,
  width: px2vw(25),
};

export const IconBox = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: ${px2vw(9)};
  z-index: 10;
  height: 100%;
`;
export const Box = styled.div`
  margin-top: ${px2vw(9)};
  display: flex;
  cursor: pointer;
  align-items: center;
  flex-direction: column;
  border: ${(props) =>
    props.selected
      ? `2px solid  ${verdeKuppi10}`
      : `2px solid  ${cinzaKuppi7}`};
  box-shadow: ${(props) =>
    props.selected ? `0px 0px 12px 1px ${verdeKuppi10}40` : 'none'};
  width: 100%;
  padding: ${px2vw(9)};
  border-radius: ${px2vw(9)};
`;
export const Icon = styled.img`
  height: ${px2vw(17)};
  margin-right: ${px2vw(8)};
  z-index: 10;
  cursor:pointer;
`;

export const predictionItem = {
  borderBottom: `2px solid ${cinzaKuppi7}`,
};
