import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { cepMask, cpfCnpjMask, dddPhoneMask } from '../../../utils/masks';
import { onlyNumbers } from '../../../utils/utils';

import { steps } from '../';

import { updateOnboardingStepRequest, updateOnboardingStoreConfigsRequest, clearSuccess } from '../../../store/modules/onboarding/actions';
import { getAdvertiserCategoriesRequest } from '../../../store/modules/user/actions';

import ProfileConfigsWeb from './Web';
import ProfileConfigsMobile from './Mobile';
import { queryZipcodeRequest } from '../../../store/modules/userAddressQuery/actions';

export default function ProfileConfigs({ isWeb, handler }) {
  const user = useSelector((state) => state.user.user);
  const userSuccess = useSelector((state) => state.user.success);
  const userAddressQuery = useSelector((state) => state.userAddressQuery);
  const onboardingStoreConfigsLoading = useSelector(
    (state) => state.onboarding.onboardingStoreConfigsLoading
  );
  const onboardingStoreConfigsSuccess = useSelector(
    (state) => state.onboarding.onboardingStoreConfigsSuccess
  );
  const advertiserCategories = useSelector(
    (state) => state.user.advertiserCategories
  );
  // For logo image
  const [fileLogoData, setFileLogoData] = useState({
    base64: null,
    name: '',
    size: 0,
    type: '',
    subtype: '',
    width: null,
    height: null
  });
  // For cover image
  const [fileCoverData, setFileCoverData] = useState({
    base64: null,
    name: '',
    size: 0,
    type: '',
    subtype: '',
    width: null,
    height: null
  });
  // For inputs
  const [storeName, setStoreName] = useState(user.advertiserInfo.name);
  const [cpfCnpj, setCpfCnpj] = useState(cpfCnpjMask(user.advertiserInfo.cpf_cnpj));
  const [password, setPassword] = useState('••••••••••');
  const [changePassword, setChangePassword] = useState(false);
  const [advertiserCategoryId, setAdvertiserCategoryId] = useState(user.advertiserInfo.advertiser_category_id);
  const [whatsappDelivery, setWhatsappDelivery] = useState(dddPhoneMask(userAddressQuery.addressAdditionalData.whatsapp));
  const [zipcode, setZipcode] = useState(cepMask(userAddressQuery.address.zipcode));
  const [street, setStreet] = useState(userAddressQuery.address.street);
  const [number, setNumber] = useState(userAddressQuery.addressAdditionalData.number);
  const [complement, setComplement] = useState(userAddressQuery.addressAdditionalData.complement);
  const [neighborhood, setNeighborhood] = useState(userAddressQuery.address.neighborhood);
  const [city, setCity] = useState(userAddressQuery.address.city);
  const [state, setState] = useState(userAddressQuery.address.state);
  const [representativeName, setRepresentativeName] = useState(user.advertiserInfo.representative_name);
  const [email, setEmail] = useState(user.email);
  const hasFreeTrialValid = useSelector((state) => state.user.hasFreeTrialValid);
  const [
    phoneWhatsappRepresentative,
    setPhoneWhatsappRepresentative,
  ] = useState(dddPhoneMask(user.advertiserInfo.phone_whatsapp_representative));

  useEffect(() => {
    dispatch(getAdvertiserCategoriesRequest())
  }, [])

  useEffect(() => {
    setStreet(userAddressQuery.address.street);
    setNeighborhood(userAddressQuery.address.neighborhood);
    setCity(userAddressQuery.address.city);
    setState(userAddressQuery.address.state);
  }, [userAddressQuery]);

  const dispatch = useDispatch();

  const handleCleanAll = () => {
    setFileLogoData({
      base64: null,
      name: '',
      size: 0,
      type: '',
      subtype: '',
      width: null,
      height: null
    });
    setFileCoverData({
      base64: null,
      name: '',
      size: 0,
      type: '',
      subtype: '',
      width: null,
      height: null
    });
  };

  async function handleFileLogo(event) {
    const f = event.target.files[0];

    if (f) {
      const fname = f.name;
      const fsize = (f.size / 1000000).toFixed(1);
      const ftype = f.type.split('/')[0];
      const fsubtype = f.type.split('/')[1];

      const logoFileReader = new FileReader();
      logoFileReader.onload = function () {
        setFileLogoData({
          ...fileLogoData,
          base64: logoFileReader.result,
          name: fname,
          size: fsize,
          type: ftype,
          subtype: fsubtype
        });
      };
      logoFileReader.readAsDataURL(f);
    } else {
      handleCleanAll();
    }
  }

  async function handleFileCover(event) {
    const f = event.target.files[0];

    if (f) {
      const fname = f.name;
      const fsize = (f.size / 1000000).toFixed(1);
      const ftype = f.type.split('/')[0];
      const fsubtype = f.type.split('/')[1];

      const coverFileReader = new FileReader();
      coverFileReader.onload = function () {
        setFileCoverData({
          ...fileCoverData,
          base64: coverFileReader.result,
          name: fname,
          size: fsize,
          type: ftype,
          subtype: fsubtype
        });
      };
      coverFileReader.readAsDataURL(f);
    } else {
      handleCleanAll();
    }
  }

  function handleStoreConfigsSubmit() {
    if (!fileLogoData.base64 && !user.avatar) {
      toast.error('Por gentileza adicionar um logo para sua loja!', {
        autoClose: 6000,
      });
    } else if (
      storeName === '' ||
      cpfCnpj === '' ||
      advertiserCategoryId === '' ||
      whatsappDelivery === '' ||
      zipcode === '' ||
      street === '' ||
      number === '' ||
      neighborhood === '' ||
      city === '' ||
      state === '' ||
      representativeName === '' ||
      email === '' ||
      phoneWhatsappRepresentative === ''
    ) {
      toast.error('Por gentileza preencher todos os campos obrigatórios!', {
        autoClose: 6000,
      });
    } else if (
      storeName === '' ||
      cpfCnpj === '' ||
      advertiserCategoryId === '' ||
      whatsappDelivery === ''
    ) {
      toast.error('Por gentileza preencher os dados da loja!', {
        autoClose: 6000,
      });
    } else if (
      zipcode === '' ||
      street === '' ||
      number === '' ||
      neighborhood === '' ||
      city === '' ||
      state === ''
    ) {
      toast.error(
        'Por gentileza preencher os dados de endereço obrigatórios!',
        {
          autoClose: 6000,
        }
      );
    } else if (
      representativeName === '' &&
      email === '' &&
      phoneWhatsappRepresentative === ''
    ) {
      toast.error('Por gentileza preencher todos os dados do responsável!', {
        autoClose: 6000,
      });
    } else {
      dispatch(
        updateOnboardingStoreConfigsRequest(
          fileLogoData,
          fileCoverData,
          storeName,
          onlyNumbers(cpfCnpj),
          representativeName,
          email,
          onlyNumbers(phoneWhatsappRepresentative),
          street,
          onlyNumbers(number),
          complement,
          neighborhood,
          city,
          state,
          onlyNumbers(zipcode),
          onlyNumbers(whatsappDelivery),
          advertiserCategoryId
        )
      );
    }
  }

  useEffect(() => {
    if (onboardingStoreConfigsSuccess) {
      dispatch(clearSuccess());
      if (steps.indexOf(handler.currOnboardingStep) < steps.indexOf('step2')) {
        dispatch(updateOnboardingStepRequest('step2'));
      } else {
        handler.setCurrOnboardingStepPage(handler.currOnboardingStep);
      }
    }
  }, [onboardingStoreConfigsSuccess])

  useEffect(() => {
    const supportedFormats = ['png', 'jpg', 'jpeg'];

    if (fileLogoData.base64 && fileLogoData.size && fileLogoData.size > 2) {
      toast.error('A logo precisa ser menor que 2mb.', {
        autoClose: 6000,
      });
      setFileLogoData({
        base64: null,
        name: '',
        size: 0,
        type: '',
        subtype: '',
        width: null,
        height: null
      });
    } else if (fileCoverData.base64 && fileCoverData.size && fileCoverData.size > 2) {
      toast.error('A foto de capa precisa ser menor que 2mb.', {
        autoClose: 6000,
      });
      setFileCoverData({
        base64: null,
        name: '',
        size: 0,
        type: '',
        subtype: '',
        width: null,
        height: null
      });
    } else if (
      fileLogoData.base64 &&
      fileLogoData.subtype &&
      !supportedFormats.includes(fileLogoData.subtype)
    ) {
      toast.error('A logo precisa ser do tipo .png, .jpg ou .jpeg.', {
        autoClose: 6000,
      });
      setFileLogoData({
        base64: null,
        name: '',
        size: 0,
        type: '',
        subtype: '',
        width: null,
        height: null
      });
    } else if (
      fileCoverData.base64 &&
      fileCoverData.subtype &&
      !supportedFormats.includes(fileCoverData.subtype)
    ) {
      toast.error('A foto de capa precisa ser do tipo .png, .jpg ou .jpeg.', {
        autoClose: 6000,
      });
      setFileCoverData({
        base64: null,
        name: '',
        size: 0,
        type: '',
        subtype: '',
        width: null,
        height: null
      });
    } else {
      // Get the real width and height of the logo image
      if (fileLogoData.base64) {
        const image = new Image();
        image.src = fileLogoData.base64;

        image.onload = () => {
          setFileLogoData({
            ...fileLogoData,
            width: image.naturalWidth,
            height: image.naturalHeight
          });
        }
      }

      // Get the real width and height of the cover image
      if (fileCoverData.base64) {
        const image = new Image();
        image.src = fileCoverData.base64;

        image.onload = () => {
          setFileCoverData({
            ...fileCoverData,
            width: image.naturalWidth,
            height: image.naturalHeight
          });
        }
      }
    }
  }, [fileLogoData.base64, fileCoverData.base64]);

  // Close change password modal when successfully change the password
  useEffect(() => {
    if (userSuccess) {
      setChangePassword(false);
    }
  }, [userSuccess]);

  const handlerProfileConfigs = {
    user: user,
    advertiserCategories: advertiserCategories,
    updateOnboardingStepLoading: handler.updateOnboardingStepLoading,
    hasFreeTrialValid: hasFreeTrialValid,
    onboardingStoreConfigsLoading: onboardingStoreConfigsLoading,
    handleStoreConfigsSubmit: () => handleStoreConfigsSubmit(),
    fileLogoData: fileLogoData,
    setFileLogoData: (val) => setFileLogoData(val),
    handleFileLogo: (event) => handleFileLogo(event),
    fileCoverData: fileCoverData,
    setFileCoverData: (val) => setFileCoverData(val),
    handleFileCover: (event) => handleFileCover(event),
    storeName: storeName,
    cpfCnpj: cpfCnpj,
    password: password,
    changePassword: changePassword,
    advertiserCategoryId: advertiserCategoryId,
    whatsappDelivery: whatsappDelivery,
    zipcode: zipcode,
    street: street,
    number: number,
    complement: complement,
    neighborhood: neighborhood,
    city: city,
    state: state,
    representativeName: representativeName,
    email: email,
    phoneWhatsappRepresentative: phoneWhatsappRepresentative,
    setStoreName: (e) => setStoreName(e.target.value),
    setCpfCnpj: (e) => setCpfCnpj(cpfCnpjMask(e.target.value)),
    setPassword: (e) => {
      setPassword(e.target.value);
    },
    setChangePassword: () => setChangePassword(!changePassword),
    setAdvertiserCategoryId: (e) => setAdvertiserCategoryId(e.target.value),
    setWhatsappDelivery: (e) => setWhatsappDelivery(dddPhoneMask(e.target.value)),
    setZipcode: (t) => {
      setZipcode(cepMask(t.target.value));
      if (t.target.value.length === 10) {
        dispatch(
          queryZipcodeRequest(onlyNumbers(t.target.value))
        );
      } else {
        setNumber('');
        setComplement('');
        setStreet('');
        setNeighborhood('');
        setCity('');
        setState('');
      }
    },
    setStreet: (e) => setStreet(e.target.value),
    setNumber: (e) => setNumber(onlyNumbers(e.target.value)),
    setComplement: (e) => setComplement(e.target.value),
    setNeighborhood: (e) => setNeighborhood(e.target.value),
    setCity: (e) => setCity(e.target.value),
    setState: (e) => setState(e.target.value),
    setRepresentativeName: (e) => setRepresentativeName(e.target.value),
    setEmail: (e) => setEmail(e.target.value),
    setPhoneWhatsappRepresentative: (e) =>
      setPhoneWhatsappRepresentative(dddPhoneMask(e.target.value)),
  };

  if (isWeb) {
    return <ProfileConfigsWeb handler={handlerProfileConfigs} />;
  } else {
    return <ProfileConfigsMobile handler={handlerProfileConfigs} />;
  }
}
