import { toast } from 'react-toastify';
import { takeLatest, call, put, all, select, actionChannel, take } from 'redux-saga/effects';

import { setChatBarItems, setConversations, updateConversations as updateConversationAction } from './actions';
import { getAllClientsData, addToRevokedList, setMessageCount } from '../whatsappChat/actions';
import { newWppMessageNotification } from '../notifications/actions';

import { forage } from '../../persistReducers';
import { WorkerSocket} from '../../../context/WebSocket';

const isConnected = (state) => state.whatsappChat.isConnected;
const clientsData = (state) => state.whatsappChat.allClientsData;
const advertiserData = (state) => state.user.user.advertiserInfo;
const tokenData = (state) => state.auth.token;

export function* updateConversations() {
  try {
    const getConversations = async () => {
      const conversations = [];
      const chatBarItems = [];

      await forage.iterate((value, key) => {
        const messages = value
          .filter((item) => item.message)
          .sort((m1, m2) => {
            return m1.messageTimestamp - m2.messageTimestamp;
          });
        if (messages.length) {
          let chatBarFiltered = messages.filter(m => m.status !== "MASS_MESSAGE_SENT").sort((m1, m2) => {
            return m1.messageTimestamp - m2.messageTimestamp;
          });
          if (!chatBarFiltered?.length) {
            return;
          }
          conversations[key] = messages;
          chatBarItems[key] = JSON.stringify(chatBarFiltered[chatBarFiltered.length - 1]);
        }
      });

      return [conversations, chatBarItems];
    };

    const wppIsConnected = yield select(isConnected);
    if (wppIsConnected) {
      const [conversations, chatBarItems] = yield call(getConversations);
      yield put(setChatBarItems(chatBarItems));
      yield put(setConversations(conversations));
    }
  } catch (err) {
    toast.error(`Ocorreu um erro ao buscar as informações dos contatos!`, {
      autoClose: 4000,
    });
  }
}

export function* watchReceivedMessage() {
  const requestChan = yield actionChannel('@whatsappChatConversations/PROCESS_RECEIVED_MESSAGE')
  while (true) {
    const { payload } = yield take(requestChan)
    yield call(processReceivedMessage, payload)
  } 
}

export function* processReceivedMessage(data) {
  try {
    const conversationId = data.body.id;
    const messages = JSON.parse(data.body.messages);
    const allClientsData = yield select(clientsData);
    const clientData = allClientsData.find(
      (item) => item.phone === conversationId
    );
    const advertiserInfoId = yield select(advertiserData);
    const accessToken = yield select(tokenData);

    const saveMessageOnForage = async () => { 
      const stored = await forage.getItem(conversationId);
      if (stored) {
        messages.forEach((message) => {
          const exists = stored.find((storedMsg) => message.id === storedMsg.id);
          if (!exists) {
            stored.push(message);
          }
        });
    
        await forage.setItem(conversationId, [...new Set(stored)]);
      } else {
        await forage.setItem(conversationId, messages);
      }
    }
  
    if (!clientData) {
      yield put(getAllClientsData([conversationId], false));
    }
  
    yield call(saveMessageOnForage);
  
    const messagesIds = messages.map((msg) => msg.id);
    WorkerSocket.emit('confirm-messagesChannel', {
      body: {
        advertiserInfoId: `${advertiserInfoId.id}`,
        messageIds: messagesIds,
        token: `${accessToken}`,
      },
    });
    
    yield put(updateConversationAction());
    if (messages[0]?.message?.protocol?.type === 'REVOKE') {
      yield put(addToRevokedList(messages[0]?.message?.protocol?.messageId));
      return;
    }
  
    if (
      messages[0] &&
      !messages[0]?.fromMe &&
      messages.length === 1 &&
      !messages[0]?.isHistoryMessage
    ) {
      yield put(setMessageCount(conversationId));
      yield put(newWppMessageNotification(true));
    }
  } catch(err) {
    console.error('Error when processing received message: ', err)
  }
};

export default all([
  takeLatest(
    '@whatsappChatConversations/UPATE_CONVERSATIONS',
    updateConversations
  ),  
  takeLatest(
    '@whatsappChatConversations/WATCH_RECEIVED_MESSAGE',
    watchReceivedMessage ),
]);
