import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { apiGlobal } from '../../../services/api';

import { queryZipcodeSuccess, queryZipcodeFailure } from './actions';

export function* queryZipcodeRequest({ payload }) {
  try {
    const { zipcode } = payload;
    const response = yield call(apiGlobal.post, '/query-zipcode', {
      zipcode,
    });

    if (response.status !== 200 || response.data === {}) {
      toast.error(
        `Ocorreu um erro ao consultar o CEP. Verifique o CEP informado!`,
        {
          autoClose: 6000,
        }
      );
      yield put(queryZipcodeFailure());
    } else {
      yield put(queryZipcodeSuccess(response.data));
    }
  } catch (err) {
    if (err.message === 'Network Error') {
      toast.error(
        `Nossa consulta automática de endereço, pelo CEP não está funcionando no momento. Preencha manualmente o endereço. Obrigado!`,
        {
          autoClose: 6000,
        }
      );
    } else {
      toast.error(
        `Ocorreu um erro ao consultar o CEP. Verifique o CEP informado!`,
        {
          autoClose: 6000,
        }
      );
    }
    yield put(queryZipcodeFailure());
  }
}

export default all([
  takeLatest('@userAddressQuery/QUERY_ZIPCODE_REQUEST', queryZipcodeRequest),
]);
