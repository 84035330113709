import React from 'react';
import { useSelector } from 'react-redux';
import { Col } from 'reactstrap';

import {
  cinzaKuppi7,
  preto,
  verdeKuppi17,
} from '../../../styles/colors';
import {
  ContentBox,
  GradientButton,
  ImgBox,
  ImgTrial,
  Title,
  Ul,
} from '../styles';
import { Text } from '../common';
import { Row } from '../grid';

import { ContentPage } from '../../_layouts/styles/common';

import px2vw from '../../../utils/responsiveness/px2vw';
import { getURL } from '../../../utils/utils';

export default function UnavailableContent({ hasFreeTrialValid }) {
  const paymentToken = useSelector((state) => state.user.paymentToken);

  function openPaymentLink() {
    window.open(
      getURL('anunciante') + process.env.REACT_APP_PAYMENT_LINK + paymentToken,
      '_blank'
    );
  }

  return (
    <ContentPage>
      <Title size={px2vw(21)}>Kuppi Boost</Title>
      <ContentBox width="100%" padding={px2vw(15)} className="mt-5">
        <Col width="100%" margtop={px2vw(10)}>
          <Text
            size={px2vw(17)}
            color={cinzaKuppi7}
            margbottom={px2vw(5)}
            regular
            textAlign='center'
          >
            Este recurso está desabilitado.
          </Text>
          <Text 
            size={px2vw(22)} 
            color={verdeKuppi17} 
            bold 
            textAlign='center'
          >
            {`Esta funcionalidade só está disponível ${hasFreeTrialValid ? 'no plano pago' : 'nos planos: trimestral e semestral'}.`} 
          </Text>
          <Row width="100%" margtop={px2vw(20)}>
            <ImgBox>
              <ImgTrial
                src={require('../../../assets/images/kuppi_boost/kuppi_boost_trial@2x.png')}
              />
            </ImgBox>
            <Col
              width="40%"
            >
              <Text
                justifyStart
                size={px2vw(20)}
                color={preto}
                bold
                margtop={px2vw(80)}
              >
                Simplificamos o impulsionamento pro seu delivery.
              </Text>

              <Ul>
                <li>Configure em 3 etapas</li>
                <li>Crie uma imagem de forma automática pra sua campanha.</li>
                <li>Segmentamos dentro da sua área de entrega.</li>
                <li>
                  A campanha roda dentro do horário de funcionamento do seu
                  delivery.
                </li>
              </Ul>
              <Text
                lineHeight={px2vw(34)}
                justifyStart
                size={px2vw(16)}
                color={preto}
                regular
              >
                Faça a sua marca aparecer na palma da mão de milhares de
                potenciais clientes nas redes sociais!
              </Text>
              {hasFreeTrialValid && (
                <Row>
                  <GradientButton 
                    width={px2vw(260)}
                    marg={`${px2vw(30)} 0 0 0`} 
                    onClick={() => openPaymentLink()}
                  >
                    CONTRATE AGORA
                  </GradientButton>
                </Row>
              )}
            </Col>
          </Row>
        </Col>
      </ContentBox>
    </ContentPage>
  );
}
