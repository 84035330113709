import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Progress } from 'reactstrap';
import { addDays, addMinutes, isToday } from 'date-fns';
import { Radio } from '@material-ui/core';

import {
  Container,
  Span,
  ProgressBar,
  BackIcon,
  ScheduleDay,
  ScheduleItem,
  SideSlider,
} from './styles';
import {
  cinzaKuppi1,
  preto,
  verdeKuppi5,
  cinzaKuppi10,
  cinzaKuppi7,
} from '../../../styles/colors';
import { Row, Col } from '../../../styles/grid';

import { formatDate } from '../../../utils/formatDate';
import { makeId, makeSchedulesForHoraries } from '../../../utils/makeSchedules';
import { weekdays } from '../../../utils/getCurrentDay';

import px2vw from '../../../utils/responsiveness/px2vw';

import {
  setCurrStep,
  setSchedule,
} from '../../../store/modules/manualOrder/actions';

export default function ScheduleData(props) {
  const dispatch = useDispatch();

  const globalState = useSelector((state) => state.manualOrder);
  const deliveryTimeAvg = globalState?.advertiser?.delivery_time_avg;
  const takeAwayTimeAvg = globalState?.advertiser?.take_away_time_avg;
  const maxPossibleScheduleDays = 721;

  const eachDayFromNow = Array.from(
    Array(maxPossibleScheduleDays).keys()
  ).map((_, idx) => addDays(new Date(), idx));

  const schedulesAvailability = useSelector(
    (state) => state.manualOrder?.advertiser?.advertiserSchedulingAvailability
  );

  const schedulingConfig = useSelector(
    (state) => state.manualOrder?.advertiser?.advertiserSchedulingConfig
  );
  const ordersConfig = useSelector(
    (state) => state.manualOrder?.advertiser?.advertiserOrdersConfig
  );

  const maxAvailableScheduleDays =
    schedulingConfig?.max_available_schedule_days;
  const intervalBetweenSchedulesInMinutes =
    schedulingConfig?.interval_between_scheduling;
  const minSchedulingTime = schedulingConfig?.min_scheduling_time;
  const isAcceptScheduled = ordersConfig?.is_accepting_scheduled_order;
  const isAcceptImmediate = ordersConfig?.is_accepting_immediate_order;

  const availabilities = useMemo(
    () =>
      weekdays.map((day) =>
        schedulesAvailability ? schedulesAvailability[day] : null
      ),
    [schedulesAvailability]
  );

  const scheduleDays = useMemo(
    () =>
      eachDayFromNow
        .map((date) => {
          const currentAvailability = availabilities[date.getDay()];
          const lastHorary = currentAvailability
            ? [...currentAvailability?.horaries].pop()
            : null;
          if (lastHorary && currentAvailability?.is_active) {
            const schedules = makeSchedulesForHoraries({
              date,
              lastHorary,
              minSchedulingTime,
              intervalBetweenSchedulesInMinutes,
              isAcceptScheduled,
              isAcceptImmediate,
            });
            const scheduleDay = {
              id: formatDate(date, 'yyyy-MM-dd-cccccc'),
              date,
              schedules,
              shortName: formatDate(date, 'cccccc'),
              shortMonthName: formatDate(date, 'MMM'),
            };
            return scheduleDay;
          } else if (
            isToday(date) &&
            !currentAvailability?.is_active &&
            isAcceptImmediate
          ) {
            const now = new Date();
            const scheduleDay = {
              id: formatDate(date, 'yyyy-MM-dd-cccccc'),
              date,
              schedules: [
                {
                  id: makeId(now, null, 'immediate'),
                  timeFrom: now,
                  timeTo: null,
                  type: 'immediate',
                },
              ],
              shortName: formatDate(date, 'cccccc'),
              shortMonthName: formatDate(date, 'MMM'),
            };
            return scheduleDay;
          } else {
            const scheduleDay = {
              id: formatDate(date, 'yyyy-MM-dd-cccccc'),
              date,
              schedules: [],
              shortName: formatDate(date, 'cccccc'),
              shortMonthName: formatDate(date, 'MMM'),
            };
            return scheduleDay;
          }
        }) // Remove days with no availables horaries
        .filter((item) => {
          if (item.schedules.length) {
            return true;
          } else {
            return false;
          }
        })
        // Get the specific number of availables days defined by user
        .slice(0, maxAvailableScheduleDays),
    [
      availabilities,
      intervalBetweenSchedulesInMinutes,
      isAcceptScheduled,
      isAcceptImmediate,
      minSchedulingTime,
    ]
  );

  const [selectedScheduleDay, setSelectedScheduleDay] = useState(
    scheduleDays.find((day) => day.schedules.length > 0)
  );

  const nextAvailableScheduleDay = useMemo(
    () =>
      scheduleDays?.find((scheduleDay) => {
        if (isToday(scheduleDay.date) && scheduleDay.schedules.length) {
          // Force only return the schedule immediate, if the user accepts immediate orders
          if (
            scheduleDay.schedules[0].type === 'immediate' &&
            isAcceptImmediate
          ) {
            return true;
          }
          // Force only return the schedule scheduled, if the user accepts scheduled orders
          else if (
            scheduleDay.schedules[0].type === 'scheduled' &&
            isAcceptScheduled
          ) {
            return true;
          }
        } else {
          return scheduleDay.schedules.length;
        }
      }),
    [scheduleDays]
  );

  const [selectedSchedule, setSelectedSchedule] = useState(null);

  useEffect(() => {
    if (
      selectedSchedule &&
      selectedSchedule.type === 'immediate' &&
      ordersConfig?.is_accepting_immediate_order
    ) {
      return;
    } else if (
      selectedSchedule &&
      selectedSchedule.type === 'scheduled' &&
      ordersConfig?.is_accepting_scheduled_order
    ) {
      return;
    }
    const firstSchedule = nextAvailableScheduleDay?.schedules[0];
    if (firstSchedule && !globalState.selectedSchedule) {
      setSelectedSchedule(firstSchedule);
      dispatch(setSchedule(firstSchedule));
    }
    if (globalState?.selectedSchedule) {
      setSelectedSchedule(globalState?.selectedSchedule);
      dispatch(setSchedule(globalState?.selectedSchedule));
    }
  }, [nextAvailableScheduleDay, selectedSchedule, ordersConfig]);

  const selectSchedule = useCallback(
    (id, scheduleDayId) => {
      const scheduleDay = scheduleDays.find(
        (scheduleDay) => scheduleDay.id === scheduleDayId
      );
      const foundSchedule = scheduleDay.schedules.find(
        (schedule) => schedule.id === id
      );
      if (foundSchedule) {
        setSelectedSchedule(foundSchedule);
        dispatch(setSchedule(foundSchedule));
      } else {
        setSelectedSchedule(nextAvailableScheduleDay);
        dispatch(setSchedule(nextAvailableScheduleDay));
      }
    },
    [scheduleDays]
  );

  const immediateSchedules = useMemo(
    () =>
      selectedScheduleDay?.schedules?.filter(
        (schedule) => schedule.type === 'immediate'
      ),
    [selectedScheduleDay?.schedules]
  );

  const scheduledSchedules = useMemo(
    () =>
      selectedScheduleDay?.schedules?.filter(
        (schedule) => schedule.type === 'scheduled'
      ),
    [selectedScheduleDay?.schedules]
  );

  const formatSchedule = useCallback(
    (schedule) => {
      if (!schedule) return '';
      const timeFrom = schedule?.timeFrom;
      const timeTo = schedule?.timeTo;
      const type = schedule?.type;
      const timeFromImmediate = addMinutes(
        timeFrom,
        globalState.bag.deliveryType === 'delivery'
          ? deliveryTimeAvg
          : takeAwayTimeAvg
      );

      if (isToday(timeFrom) && type === 'immediate') {
        return `hoje, ${formatDate(timeFrom, 'HH:mm')} - ${formatDate(
          timeFromImmediate,
          'HH:mm'
        )}`;
      } else if (isToday(timeFrom) && timeTo) {
        return `hoje, ${formatDate(timeFrom, 'HH:mm')} - ${formatDate(
          timeTo,
          'HH:mm'
        )}`;
      } else {
        return `${formatDate(
          new Date(timeFrom),
          'cccccc dd/MM, HH:mm'
        )} - ${formatDate(new Date(timeTo), 'HH:mm')}`;
      }
    },
    [globalState.bag.deliveryType, deliveryTimeAvg, takeAwayTimeAvg]
  );

  function handleBackToOrderReview() {
    dispatch(setCurrStep('5'));
  }

  return (
    <>
      <ProgressBar>
        <Progress
          value={75}
          bar={true}
          style={{
            backgroundColor: verdeKuppi5,
            borderRaduis: '0px',
            height: '10px',
          }}
        />
      </ProgressBar>
      <Row
        justifyStart
        alignCenter
        className="py-1 px-2"
        cursor="pointer"
        background={cinzaKuppi1}
        onClick={() => handleBackToOrderReview()}
      >
        <BackIcon
          src={require('../../../assets/images/arrows/arrow_left.png')}
        />
        <Span className="ml-auto" padding={px2vw(3)} color={preto}>
          Selecione uma opção
        </Span>
        <Span className="ml-auto"></Span>
      </Row>

      <Container className="px-5 py-2">
        <SideSlider className="my-2">
          {scheduleDays?.map((scheduleDay, idx) =>
            scheduleDay?.schedules?.length ? (
              <ScheduleDay
                selected={selectedScheduleDay?.id === scheduleDay?.id}
                onClick={() => setSelectedScheduleDay(scheduleDay)}
                key={`${scheduleDay.id}-${idx}`}
              >
                <Span
                  fontSize={px2vw(10)}
                  fontWeight="200"
                  color={cinzaKuppi7}
                  cursor="pointer"
                  alignCenter
                >
                  {`${
                    scheduleDay.shortMonthName
                  }/${scheduleDay.date.getFullYear().toString().substr(2, 2)}`}
                </Span>
                <Span
                  alignCenter
                  fontSize={px2vw(10)}
                  fontWeight="200"
                  color={cinzaKuppi7}
                  cursor="pointer"
                >
                  {isToday(scheduleDay.date)
                    ? 'hoje'
                    : scheduleDay.shortName.substr(0, 3)}
                </Span>
                <Span
                  fontSize={px2vw(13)}
                  fontWeight="700"
                  color={preto}
                  alignCenter
                  cursor="pointer"
                >
                  {scheduleDay.date.getDate()}
                </Span>
              </ScheduleDay>
            ) : null
          )}
        </SideSlider>
        {immediateSchedules?.length > 0 && (
          <>
            <Row justifyStart className="mb-2 mt-4">
              <Span fontSize={px2vw(14)} fontWeight="400" color={preto}>
                Peça agora
              </Span>
            </Row>
            <Col>
              {immediateSchedules?.map((schedule, idx) => (
                <ScheduleItem
                  key={`${schedule.id}-${idx}`}
                  onClick={() => {
                    selectSchedule(schedule.id, selectedScheduleDay.id)
                    handleBackToOrderReview();
                  }}
                >
                  <Row spaceBetWeen alignCenter>
                    <Span
                      fontSize={px2vw(14)}
                      fontWeight="400"
                      className="mx-0"
                      color={cinzaKuppi10}
                      cursor="pointer"
                    >
                      {formatSchedule(schedule)}
                    </Span>
                    <Radio
                      className="ml-auto"
                      checked={selectedSchedule?.id === schedule?.id}
                      color="default"
                    />
                  </Row>
                </ScheduleItem>
              ))}
            </Col>
          </>
        )}
        {scheduledSchedules?.length > 0 && (
          <>
            <Row justifyStart className="mb-2 mt-4">
              <Span fontSize={px2vw(14)} fontWeight="400" color={preto}>
                Agendar
              </Span>
            </Row>
            <Col>
              {scheduledSchedules?.map((schedule) => (
                <ScheduleItem
                  key={schedule?.id}
                  onClick={() => {
                    selectSchedule(schedule.id, selectedScheduleDay.id);
                    handleBackToOrderReview();
                  }}
                >
                  <Row spaceBetWeen alignCenter>
                    <Span
                      fontSize={px2vw(14)}
                      fontWeight="500"
                      className="mx-0"
                      color={cinzaKuppi10}
                      cursor="pointer"
                    >
                      {formatSchedule(schedule)}
                    </Span>
                    <Radio
                      className="ml-auto"
                      checked={selectedSchedule?.id === schedule?.id}
                      color="default"
                    />
                  </Row>
                </ScheduleItem>
              ))}
            </Col>
          </>
        )}
      </Container>
    </>
  );
}
