import { formatInvoiceCommonData } from '../common';
import { formatOrderNumber } from '../../../utils';

export function EscPosPrint({ orderStatusData, advertiserData, maxLineCharsAutomatic }) {
  // ESC/POS COMANDS
  const init = '\x1B' + '\x40';
  const centerAlign = '\x1B' + '\x61' + '\x31';
  const lineBreak = '\x0A';
  const boldOn = '\x1B' + '\x45' + '\x0D';
  const boldOff = '\x1B' + '\x45' + '\x0A';
  const leftAlign = '\x1B' + '\x61' + '\x30';
  const normalText = '\x1B' + '\x4D' + '\x30';
  const cutPaper = '\x1B' + '\x69'; 
  const cmds = [init];
  const replace_A = /[ÁÀÃÂÄÅ]/g;
  const replace_a = /[àáâãäå]/g;
  const replace_E = /[ÈÉÊË]/g;
  const replace_e = /[éèêë]/g;
  const replace_I = /[ÍÌÎÏ]/g;
  const replace_i = /[íìîï]/g;
  const replace_O = /[ÓÒÕÔÖ]/g;
  const replace_o = /[óòõôö]/g;
  const replace_U = /[ÚÙÛÜ]/g;
  const replace_u = /[úùûü]/g;
  const replace_ç = /[ç]/g;
  const headerAligns = ['L', 'C', 'R',]
  const textAligns = ['L', 'L', 'R'];
  const complementsTextAlign = ['R', 'L', 'R'];
  const totalToPayAligns = ['L', 'R'];
  const refQtyWidth = 6;
  const refAmountWidth = 10; 
  const refHeaderQtyWidth = 3;
  const refHeaderAmountWidth = 10;
  const totalToPayWidth = [12, maxLineCharsAutomatic - 12];
  const discountWidth = [9, maxLineCharsAutomatic - 9];
  const feeDeliveryWidth = [16, maxLineCharsAutomatic - 16];
  const totalAmountWith = [14, maxLineCharsAutomatic - 14];
  
  let headerWidths = [
    refHeaderQtyWidth, 
    maxLineCharsAutomatic - refHeaderQtyWidth - refHeaderAmountWidth,  
    refHeaderAmountWidth, 
  ]
  let productsWidths = [
    refQtyWidth, 
    maxLineCharsAutomatic - refQtyWidth - refAmountWidth, 
    refAmountWidth
  ]

  function printColumn(columnsWidth, columnAlign, columnValues) {
    let str = ''

    for (let i = 0; i < columnsWidth.length; i++) {
      const val = columnValues[i]
      const width = columnsWidth[i]
      const tam = width - val.length

      switch (columnAlign[i]) {
        case 'L':
          str = str + val + ' '.repeat(tam)
          break;
        case 'C':
          const tam2 = tam / 2 
          str = str + ' '.repeat(tam2) + val + ' '.repeat(tam2)
          break;
        case 'R':
          str = str + ' '.repeat(tam) + val
          break; 
        default:
          break;
      }
    }

    return str
  }

  // Format Invoice Common Data
  const { advertiser, client, order } = formatInvoiceCommonData(
    orderStatusData,
    advertiserData
  );

  let advertiserAddressData = '';
  if (advertiserData.address[0].is_visible_on_web_menu) {
    advertiserAddressData = `${advertiser.address}`;
  }

  // Scheduling data
  let schedulingData = '';
  if (orderStatusData.order.order_type === 'scheduled') {
    schedulingData = `${order.order_type} ${orderStatusData.order.scheduling_start_at}`;
  }
  
  // Replace especial characters
  function especialCharMask(string){
    let newString = '';

    newString = string.replace(replace_A, 'A');
    newString = newString.replace(replace_a, 'a');
    newString = newString.replace(replace_E, 'E');
    newString = newString.replace(replace_e, 'e');
    newString = newString.replace(replace_I, 'I');
    newString = newString.replace(replace_i, 'i');
    newString = newString.replace(replace_O, 'O');
    newString = newString.replace(replace_o, 'o');
    newString = newString.replace(replace_U, 'U');
    newString = newString.replace(replace_u, 'u');
    newString = newString.replace(replace_ç, 'c');

    return newString
  };

  cmds.push(lineBreak);
  cmds.push(centerAlign);
  cmds.push(boldOn);
  cmds.push(`${especialCharMask(advertiser.name)}`);
  cmds.push(boldOff);
  cmds.push(lineBreak);
  cmds.push(lineBreak);
  cmds.push(leftAlign);
  cmds.push(boldOn);
  cmds.push('CNPJ: ');
  cmds.push(boldOff);
  cmds.push(normalText);
  cmds.push(`${advertiser.cnpj}`);
  cmds.push(lineBreak);
  cmds.push(leftAlign);
  cmds.push(boldOn);
  cmds.push('Telefone: ');
  cmds.push(boldOff);
  cmds.push(normalText);
  cmds.push(`${advertiser.phone}`);
  cmds.push(lineBreak);
  cmds.push(lineBreak);
  cmds.push(leftAlign);
  cmds.push(especialCharMask(advertiserAddressData));
  cmds.push(lineBreak);
  cmds.push(lineBreak);
  cmds.push(centerAlign);
  cmds.push(boldOn);
  cmds.push('DADOS DO CLIENTE');
  cmds.push(boldOff);
  cmds.push(lineBreak);
  cmds.push(lineBreak);
  cmds.push(leftAlign);
  cmds.push(boldOn);
  cmds.push('Nome: ');
  cmds.push(boldOff);
  cmds.push(normalText);
  cmds.push(`${especialCharMask(client.name)}`);
  cmds.push(lineBreak);
  cmds.push(leftAlign);
  cmds.push(boldOn);
  cmds.push('Telefone: ');
  cmds.push(boldOff);
  cmds.push(normalText);
  cmds.push(`${client.phone}`);
  cmds.push(lineBreak);
  cmds.push(leftAlign);
  cmds.push(boldOn);
  cmds.push('Endereco: ');
  cmds.push(boldOff);
  cmds.push(normalText);
  cmds.push(`${especialCharMask(client.address)}`);
  cmds.push(lineBreak);
  cmds.push(lineBreak);
  cmds.push(centerAlign);
  cmds.push(boldOn);
  cmds.push(`PEDIDO ${formatOrderNumber(orderStatusData.order.id)}`);
  cmds.push(boldOff);
  cmds.push(lineBreak);
  cmds.push(`${order.delivery_type} - Realizado em ${orderStatusData.new_at}`);
  cmds.push(lineBreak);
  cmds.push(lineBreak);

  // Scheduling Data
  if(schedulingData.length > 0){
    cmds.push(centerAlign);
    cmds.push(boldOn);
    cmds.push(`${schedulingData}`);
    cmds.push(boldOff);
    cmds.push(lineBreak);
    cmds.push(lineBreak);
  }

  cmds.push(centerAlign);
  cmds.push(boldOn);
  cmds.push(printColumn(headerWidths, headerAligns, ['Qtd', 'Item', 'Total (R$)']));
  cmds.push(boldOff);
  cmds.push(lineBreak);

  // Mount products and complements detailing
  orderStatusData.order.orderProduct.forEach((orderProduct) => {
    const productNameSliced = [];
    const productName = especialCharMask(orderProduct.product.name);
    const productAmount = Number(orderProduct.product.amount).toFixed(2);
    const productAmountTotalAmount = (
      productAmount * orderProduct.quantity
    ).toFixed(2);
    const tamItemCol = productsWidths[1] - 1; 
    let namePosition = 0;
    while ((namePosition + tamItemCol) < productName.length) {
      productNameSliced.push(productName.slice(namePosition, namePosition + tamItemCol))
      namePosition += tamItemCol
    }
    if (namePosition < productName.length) {
      productNameSliced.push(productName.slice(namePosition, productName.length))
    }

    cmds.push(boldOn);
    cmds.push(printColumn(productsWidths, textAligns, [`${orderProduct.quantity}x`, productNameSliced[0], `${productAmountTotalAmount}`]));
    cmds.push(lineBreak);
    for (let i = 1; i < productNameSliced.length; i++) {
      cmds.push(printColumn(productsWidths, textAligns, ['', productNameSliced[i], '']));
      cmds.push(lineBreak);
    }
    cmds.push(boldOff);

     // If product has complements, include them
    if (orderProduct.orderProductComplements) {
      orderProduct.orderProductComplements.forEach((productComplement) => {
        if (productComplement.complements) {
          productComplement.complements.forEach((complement) => {
            const complementAmount = complement.amount
              ? Number(complement.amount).toFixed(2)
              : Number(0).toFixed(2);
            const complementTotalAmount = (
              complementAmount *
              complement.quantity *
              orderProduct.quantity
            ).toFixed(2);
            const complementNameSliced = [];
            const complementName = `${complement.quantity}x ${especialCharMask(complement.name)}`;
            const tamItemCol = productsWidths[1] - 1;
            let complementNamePosition = 0;

            while ((complementNamePosition + tamItemCol) < complementName.length) {
              complementNameSliced.push(complementName.slice(complementNamePosition, complementNamePosition + tamItemCol))
              complementNamePosition += tamItemCol
            }
            if (complementNamePosition < complementName.length) {
              complementNameSliced.push(complementName.slice(complementNamePosition, complementName.length))
            }
            cmds.push(printColumn(productsWidths, complementsTextAlign, ['', complementNameSliced[0], complementTotalAmount]));
            cmds.push(lineBreak);
            for (let j = 1; j < complementNameSliced.length; j++) {
              cmds.push(printColumn(productsWidths, complementsTextAlign, ['', complementNameSliced[j], '']));
              cmds.push(lineBreak);
            }
          })
        }
      })
    }
   
    cmds.push(lineBreak);

    // If product has observation, include them
    if (orderProduct.obs) {
      const obsTextSliced = [];
      const obsText = `Obs: ${especialCharMask(orderProduct.obs)}`;
      const tamObsCol = productsWidths[1] - 1;
      let obsTextPosition = 0;
      while ((obsTextPosition + tamObsCol) < obsText.length) {
        obsTextSliced.push(obsText.slice(obsTextPosition, obsTextPosition + tamObsCol))
        obsTextPosition += tamObsCol
      }
      if (obsTextPosition < obsText.length) {
        obsTextSliced.push(obsText.slice(obsTextPosition, obsText.length))
      }
      cmds.push(printColumn(productsWidths, complementsTextAlign, ['', obsTextSliced[0], '']));
      cmds.push(lineBreak);
        for (let k = 1; k < obsTextSliced.length; k++) {
          cmds.push(printColumn(productsWidths, complementsTextAlign, ['', obsTextSliced[k], '']));
          cmds.push(lineBreak);
        }
      cmds.push(lineBreak);
    }
  });

  cmds.push(printColumn(totalToPayWidth, totalToPayAligns, ['Valor total:', `R$ ${Number(orderStatusData.order.amount).toFixed(2)}`]));
  cmds.push(lineBreak);
  cmds.push(printColumn(discountWidth, totalToPayAligns, ['Desconto:', `R$ ${Number(orderStatusData.order.discount).toFixed(2)}`]));
  cmds.push(lineBreak);

  // Delivery fee date only for order type delivery
  if (
    orderStatusData.order.delivery_type === 'delivery' &&
    orderStatusData.order.paymentMethod.slug !== 'pix'
  ) {
    cmds.push(printColumn(feeDeliveryWidth, totalToPayAligns, ['Taxa de entrega:', `R$ ${Number(orderStatusData.order.delivery_fee).toFixed(2)}`]));
    cmds.push(lineBreak);
  }

  cmds.push(boldOn);
  cmds.push(printColumn(totalAmountWith, totalToPayAligns, ['Valor a pagar:', `R$ ${Number(orderStatusData.order.total_amount).toFixed(2)}`]));
  cmds.push(boldOff);
  cmds.push(lineBreak);
  cmds.push(lineBreak);

  //Payment Method 
  const paymentMethodWidth = [11, maxLineCharsAutomatic - 11];
  const paymentMethodAligns = ['L', 'R']
  const paymentMethodNameSliced = [];
  const paymentMethodName = especialCharMask(orderStatusData.order.paymentMethod.name);
  const tamPaymentMethodNameCol = paymentMethodWidth[1]; 
  let namePosition = 0;
  while ((namePosition + tamPaymentMethodNameCol) < paymentMethodName.length) {
    paymentMethodNameSliced.push(paymentMethodName.slice(namePosition, namePosition + tamPaymentMethodNameCol))
    namePosition += tamPaymentMethodNameCol
  }
  if (namePosition < paymentMethodName.length) {
    paymentMethodNameSliced.push(paymentMethodName.slice(namePosition, paymentMethodName.length))
  }
  cmds.push(printColumn(paymentMethodWidth, paymentMethodAligns, ['Pagamento:', paymentMethodNameSliced[0]])) 
  cmds.push(lineBreak)
  for (let i = 1; i < paymentMethodNameSliced.length; i++) {
    cmds.push(printColumn(paymentMethodWidth, paymentMethodAligns, ['', paymentMethodNameSliced[i]]))
    cmds.push(lineBreak)
  }
  cmds.push(lineBreak)

  // Additional data for online payment or offline payment change.
  // Change data only for cash payment
  if (
    orderStatusData.order.paymentMethod.type === 'offline' &&
    orderStatusData.order.paymentMethod.slug === 'cash'
   ) {
    const changeForWidth = [11, maxLineCharsAutomatic - 11];
    const changeforAligns = ['L', 'R'];
    let change = '';
   
    if(order.change !== 'Não precisa'){
      change = `R$ ${Number(order.paid_amount).toFixed(2)}`
    } else {
      change = 'Não precisa'
    }
    cmds.push(printColumn(changeForWidth, changeforAligns, ['Troco para:', `${change}`]));
    cmds.push(lineBreak);
   
      if (order.change !== 'Não precisa') {
        const changeValueWidth = [15, maxLineCharsAutomatic - 15]
        cmds.push(printColumn(changeValueWidth, changeforAligns, ['Valor do troco:', `${order.change}`]));
        cmds.push(lineBreak);
      }
    } else if (orderStatusData.order.paymentMethod.type === 'online') {
      // If payment method is online.
      cmds.push(leftAlign);
      cmds.push('Pedido pago online.');
      cmds.push(lineBreak);
      cmds.push('Não precisa cobrar o cliente.');
      cmds.push(lineBreak);
    }

  cmds.push(centerAlign);
  cmds.push(`CONSUMIDOR ${especialCharMask(client.name)}`);
  cmds.push(lineBreak);
  cmds.push(centerAlign);
  cmds.push(boldOn);
  cmds.push('Kuppi kuppi.com.br');
  cmds.push(boldOff);
  cmds.push(lineBreak);
  cmds.push(lineBreak);
  cmds.push(lineBreak);
  cmds.push(lineBreak);
  cmds.push(cutPaper);

  return cmds;

}
