import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Spinner } from 'reactstrap';

import px2vw from '../../utils/responsiveness/px2vw';
import { preto } from '../../styles/colors';

import Modal from '../Modal';
import { ModalActions, CloseButton, CloseImage } from '../Modal/styles';
import ButtonPrimary from '../ButtonPrimary';
import ButtonSecondary from '../ButtonSecondary';

import {
  Header,
  ChangeImageTitle,
  DragImageBox,
  InputImage,
  InputImageGroup,
  SelectImageLabel,
  ImageLogoPreview,
  UploadImage,
  ImageCoverPreview,
} from './styles';
import { LoadingBox } from '../../styles/common';
import {
  updateUserImagesRequest,
  clearSuccess,
} from '../../store/modules/user/actions';
import uploadImg from '../../assets/images/upload_image/upload_image.png';
import advertiserLogoImg from '../../assets/images/advertiser_logo/advertiser_logo.png';
import closeImg from '../../assets/images/close/close.png';

function ChangeImagesModal(props) {
  const {
    isVisible,
    onClose,
    onConfirm,
    title,
    hideCancelButton,
    hideCloseButton,
  } = props;
  const user = useSelector((state) => state.user.user);
  const [fileLogoData, setFileLogoData] = useState({
    base64: null,
    name: '',
    size: 0,
    type: '',
    subtype: '',
    width: null,
    height: null
  });
  const [fileCoverData, setFileCoverData] = useState({
    base64: null,
    name: '',
    size: 0,
    type: '',
    subtype: '',
    width: null,
    height: null
  });
  const updateImagesLoading = useSelector(
    (state) => state.user.updateImagesLoading
  );
  const updateImagesSuccess = useSelector(
    (state) => state.user.updateImagesSuccess
  );
  const dispatch = useDispatch();

  const handleCleanAll = () => {
    setFileLogoData({
      base64: null,
      name: '',
      size: 0,
      type: '',
      subtype: '',
      width: null,
      height: null
    });
    setFileCoverData({
      base64: null,
      name: '',
      size: 0,
      type: '',
      subtype: '',
      width: null,
      height: null
    });
  };

  async function handleFileLogo(event) {
    const f = event.target.files[0];

    if (f) {
      const fname = f.name;
      const fsize = (f.size / 1000000).toFixed(1);
      const ftype = f.type.split('/')[0];
      const fsubtype = f.type.split('/')[1];

      const logoFileReader = new FileReader();
      logoFileReader.onload = function () {
        setFileLogoData({
          ...fileLogoData, 
          base64: logoFileReader.result, 
          name: fname, 
          size: fsize, 
          type: ftype, 
          subtype: fsubtype 
        })
      };
      logoFileReader.readAsDataURL(f);
    } else {
      handleCleanAll();
    }
  }

  async function handleFileCover(event) {
    const f = event.target.files[0];

    if (f) {
      const fname = f.name;
      const fsize = (f.size / 1000000).toFixed(1);
      const ftype = f.type.split('/')[0];
      const fsubtype = f.type.split('/')[1];

      const coverFileReader = new FileReader();
      coverFileReader.onload = function () {
        setFileCoverData({
          ...fileCoverData, 
          base64: coverFileReader.result, 
          name: fname, 
          size: fsize, 
          type: ftype, 
          subtype: fsubtype 
        })
      };
      coverFileReader.readAsDataURL(f);
    } else {
      handleCleanAll();
    }
  }

  function uploadImages() {
    if (!fileLogoData.base64 && !user.avatar) {
      toast.error('Logo é obrigatória.', {
        autoClose: 6000,
      });
    } else {
      dispatch(
        updateUserImagesRequest(
          fileLogoData,
          fileCoverData
        )
      );
    }
  }

  useEffect(() => {
    const supportedFormats = ['png', 'jpg', 'jpeg'];

    if (fileLogoData.base64 && fileLogoData.size && fileLogoData.size > 2) {
      toast.error('A logo precisa ser menor que 2mb.', {
        autoClose: 6000,
      });
      setFileLogoData({...fileLogoData, base64: null });
    } else if (fileCoverData.base64 && fileCoverData.size && fileCoverData.size > 2) {
      toast.error('A foto de capa precisa ser menor que 2mb.', {
        autoClose: 6000,
      });
      setFileCoverData({...fileCoverData, base64: null });
    } else if (
      fileLogoData.base64 &&
      fileLogoData.subtype &&
      !supportedFormats.includes(fileLogoData.subtype)
    ) {
      toast.error('A logo precisa ser do tipo .png, .jpg ou .jpeg.', {
        autoClose: 6000,
      });
      setFileLogoData({...fileLogoData, base64: null });
    } else if (
      fileCoverData.base64 &&
      fileCoverData.subtype &&
      !supportedFormats.includes(fileCoverData.subtype)
    ) {
      toast.error('A foto de capa precisa ser do tipo .png, .jpg ou .jpeg.', {
        autoClose: 6000,
      });
      setFileCoverData({...fileCoverData, base64: null });
    } else {
      // Get the real width and height of the logo image
      if (fileLogoData.base64) {
        const image = new Image();
        image.src = fileLogoData.base64;

        image.onload = () => {
          setFileLogoData({
            ...fileLogoData, 
            width: image.naturalWidth,
            height: image.naturalHeight
          });
        }
      }

      // Get the real width and height of the cover image
      if (fileCoverData.base64) {
        const image = new Image();
        image.src = fileCoverData.base64;

        image.onload = () => {
          setFileCoverData({
            ...fileCoverData, 
            width: image.naturalWidth,
            height: image.naturalHeight
          });
        }
      }
    }
  }, [fileLogoData.base64, fileCoverData.base64]);

  useEffect(() => {
    if (updateImagesSuccess) {
      dispatch(clearSuccess());
      if (onConfirm) {
        onConfirm();
      } else if (onClose) {
        onClose();
      }
    }
  }, [updateImagesSuccess, dispatch]);

  return (
    <Modal
      isVisible={isVisible}
      padding="40px 20px"
      width={px2vw(700)}
      height="auto"
    >
      <Header>
        <ChangeImageTitle>{title || 'Alterar imagens'}</ChangeImageTitle>
        {!hideCloseButton && (
          <CloseButton onClick={() => onClose()}>
            <CloseImage src={closeImg} />
          </CloseButton>
        )}
      </Header>
      <InputImageGroup>
        <h3>Insira seu logo</h3>
        <DragImageBox>
          {!fileLogoData.base64 && !user.avatar && (
            <>
              <UploadImage alt="Enviar logo" src={uploadImg} />
              <span>Arraste e solte a foto aqui</span>
            </>
          )}
          {fileLogoData.base64 && <ImageLogoPreview src={fileLogoData.base64} />}
          {user.avatar && !fileLogoData.base64 && (
            <ImageLogoPreview
              src={
                user.avatar
                  ? `${user.avatar.url}?nocache=${new Date().getTime()}`
                  : advertiserLogoImg
              }
            />
          )}
          <InputImage
            onChange={handleFileLogo}
            type="file"
            id="logoImage"
            name="logoImage"
          />
        </DragImageBox>
        <SelectImageLabel htmlFor="logoImage">+ Escolher Foto</SelectImageLabel>
      </InputImageGroup>

      <InputImageGroup>
        <h3>Insira sua capa</h3>
        <DragImageBox>
          {!fileCoverData.base64 && !user.advertiserInfo.coverImage && (
            <>
              <UploadImage alt="Enviar logo" src={uploadImg} />
              <span>Arraste e solte a foto aqui</span>
            </>
          )}
          {fileCoverData.base64 && <ImageCoverPreview src={fileCoverData.base64} />}
          {user.advertiserInfo.coverImage && !fileCoverData.base64 && (
            <ImageCoverPreview
              src={`${
                user.advertiserInfo.coverImage.url
              }?nocache=${new Date().getTime()}`}
            />
          )}
          <InputImage
            onChange={handleFileCover}
            type="file"
            id="coverImage"
            name="coverImage"
          />
        </DragImageBox>
        <SelectImageLabel htmlFor="coverImage">
          + Escolher Foto
        </SelectImageLabel>
      </InputImageGroup>
      <ModalActions padding="0" justifyContent="center">
        {!hideCancelButton && (
          <ButtonSecondary
            marginRight={px2vw(40)}
            minWidth={125}
            type="button"
            onClick={() => onClose()}
          >
            Cancelar
          </ButtonSecondary>
        )}
        <ButtonPrimary
          disabled={updateImagesLoading}
          type="button"
          minWidth={125}
          onClick={() => uploadImages()}
        >
          {updateImagesLoading ? (
            <LoadingBox>
              <Spinner
                style={{
                  width: '1.8rem',
                  height: '1.8rem',
                  color: preto,
                }}
              />{' '}
            </LoadingBox>
          ) : (
            'Confirmar'
          )}
        </ButtonPrimary>
      </ModalActions>
    </Modal>
  );
}

export default ChangeImagesModal;
