import L from 'leaflet';
import toNumber from 'lodash/toNumber';
import { apiGlobal } from '../services/api';

/**
 * Retorna a distância em metros (linha reta) entre dois endereços, desde que os dois endereços
 * possuam latitude e longitude.
 * @param firstAddress Endereço 1
 * @param secondAddress Endereço 2
 */
export const getDistanceBetweenAddresses = (firstAddress, secondAddress) => {
  if (
    !firstAddress &&
    !secondAddress &&
    !firstAddress.latitude &&
    !firstAddress.longitude &&
    !secondAddress.lat &&
    !secondAddress.lng
  ) {
    throw new Error(
      'Ocorreu um erro ao validar os endereços: um dos endereços é inválido.'
    );
  }

  let lat1, lng1, lat2, lng2;

  lat1 = toNumber(firstAddress.latitude);
  lng1 = toNumber(firstAddress.longitude);

  lat2 = toNumber(secondAddress.latitude);
  lng2 = toNumber(secondAddress.longitude);

  const distanceInMeters = L.latLng(lat1, lng1).distanceTo({
    lat: lat2,
    lng: lng2,
  });
  return distanceInMeters;
};

/**
 * Retorna a distância em metros (rota) entre dois endereços, desde que os dois endereços
 * possuam latitude e longitude.
 * @param firstAddress Endereço 1
 * @param secondAddress Endereço 2
 */
export const getRouteDistanceBetweenAddresses = async (
  startLocation,
  endLocation
) => {
  if (
    !startLocation &&
    !endLocation &&
    !startLocation.lat &&
    !startLocation.lng &&
    !endLocation.lat &&
    !endLocation.lng
  ) {
    throw new Error(
      'Ocorreu um erro ao validar os endereços: um dos endereços é inválido.'
    );
  }

  try {
    const response = await apiGlobal.post('/get-route-distance', {
      start_location: startLocation,
      end_location: endLocation,
    });

    return Number(response.data.distance_in_meters);
  } catch (err) {
    throw new Error('Ocorreu um erro ao calcular sua taxa de entrega.');
  }
};
