import { formatInvoiceCommonData } from '../common'
import { formatOrderNumber } from '../../../utils';

export const createHTMLOrderInvoice = ({ orderStatusData, advertiserData }) => {
  // Format Invoice Common Data
  const {advertiser, client, order} = formatInvoiceCommonData(orderStatusData, advertiserData);

  // Advertiser address data only if he wants
  let advertiserAddressData = '';
  if (advertiserData.address[0].is_visible_on_web_menu) {
    advertiserAddressData = `
    <div class="space">
        ${advertiser.address}
    </div>
    `;
  }

  // Scheduling data
  let schedulingData = '';
  if (orderStatusData.order.order_type === 'scheduled') {
    schedulingData = `
        <div class="center">
            ${order.order_type} ${orderStatusData.order.scheduling_start_at}
        </div>`;
  }

  // Additional data for online payment or offline payment change.
  let paymentMethodAdditionalData = '';
  // Change data only for cash payment
  if (
    orderStatusData.order.paymentMethod.type === 'offline' &&
    orderStatusData.order.paymentMethod.slug === 'cash'
  ) {
    paymentMethodAdditionalData = `
        <tr>
            <td style="width:50px; text-align: left;">Troco para: </td>
            <td style="width:50px; text-align: right;">${
              order.change !== 'Não precisa'
                ? `R$ ${order.paid_amount}`
                : 'Não precisa'
            }</td>
        </tr>
    `;

    if (order.change !== 'Não precisa') {
      paymentMethodAdditionalData += `
        <tr>
            <td style="width:50px; text-align: left;">Valor do troco: </td>
            <td style="width:50px; text-align: right;">R$ ${order.change}</td>
        </tr>`;
    }
  } else if (orderStatusData.order.paymentMethod.type === 'online') {
    // If payment method is online.
    paymentMethodAdditionalData = `
    <tr>
      <td style="width:50px; text-align: left;">Pedido pago online.</td>
    </tr>
    <tr>
      <td style="width:50px; text-align: left;">Não precisa cobrar o cliente.</td>
    </tr>
    `;
  }

  // Delivery fee date only for order type delivery
  let deliveryFeeData = '';
  if (
    orderStatusData.order.delivery_type === 'delivery' &&
    orderStatusData.order.paymentMethod.slug !== 'pix'
  ) {
    deliveryFeeData = `
    <tr>
      <td style="width:50px; text-align: left;">Taxa de entrega: </td>
      <td style="width:50px; text-align: right;">R$ ${Number(
        orderStatusData.order.delivery_fee
      ).toFixed(2)}</td>
    </tr>`;
  }

  // HTML for the order products details
  let orderProducts = '';

  // Mount products and complements detailing
  orderStatusData.order.orderProduct.forEach((orderProduct) => {
    const productAmount = Number(orderProduct.product.amount).toFixed(2);
    const productAmountTotalAmount = (
      productAmount * orderProduct.quantity
    ).toFixed(2);
    // Add product data
    let productData = `
        <tr>
          <td style="width:20px; max-width:30px; font-size: 10px; text-align: left; font-weight: bold;">
            ${orderProduct.quantity}x
          </td>
          <td style="width:190px; max-width:190px; font-size: 12px; word-wrap: break-word; text-align: left; font-weight: bold;">
            ${orderProduct.product.name}
          </td>
          <td style="font-size: 12px; text-align: right; font-weight: bold;">
            ${productAmountTotalAmount}
          </td>
        </tr>
    `;

    // If product has complements, include them
    if (orderProduct.orderProductComplements) {
      orderProduct.orderProductComplements.forEach((productComplement) => {
        if (productComplement.complements) {
          productComplement.complements.forEach((complement) => {
            const complementAmount = complement.amount
              ? Number(complement.amount).toFixed(2)
              : Number(0).toFixed(2);
            const complementTotalAmount = (
              complementAmount *
              complement.quantity *
              orderProduct.quantity
            ).toFixed(2);
            // Add complement data
            productData += `
                <tr>
                  <td style="font-size: 10px; text-align: right; width: 60px">${complement.quantity}x</td>
                  <td style="width:100px; max-width:190px; font-size: 10px; word-wrap: break-word;" >
                  ${complement.name}
                  </td>
                  <td style="font-size: 10px; text-align: right;">${complementTotalAmount}</td>
                </tr>
            `;
          });
        }
      });
    }

    // If product has observation, include them
    if (orderProduct.obs) {
      productData += `
            <tr>
              <td colspan="2" style="font-size: 10px; word-wrap: anywhere;">    Obs:<br> ${orderProduct.obs}</td>
            </tr>
      `;
    }

    orderProducts += productData;
  });

  // Mount Invoice HTML
  const invoiceHTML = `
    <html>
        <head>
            <style type="text/css">
                * {
                    font-size: 12px;
                    font-family: Arial;
                }

                table {
                    width: 100%;
                }

                thead {
                    display: table-row-group;
                }

                .coupon-data {
                    display: flex;
                    align-items: flex-start;
                    margin-top: 4mm;
                    padding: 0;
                    width: 80mm;
                    max-width: 80mm;
                    height: auto;
                }

                .row {
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                    height: auto;
                }

                .col {
                    display: flex;
                    flex-direction: column;
                    width: 95%;
                    height: auto;
                }

                .advertiser-name {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    font-size: 13px;
                    font-weight: bold;
                    margin-bottom: 3mm;
                }

                .title {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    font-size: 12px;
                    font-weight: bold;
                }

                .center {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin-left: 10px;
                }

                .separator {
                    margin-top: 0.5mm;
                    margin-bottom: 0.5mm;
                }

                .space {
                    margin-top: 2mm;
                }

                .footer-data {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin-top: 5mm;
                    margin-bottom: 3mm;
                }
            </style>
        </head>
        <body>
            <!-- Invoice Data -->
            <div class="coupon-data col">
                <!-- Advertiser Data -->
                <div class="col">
                    <div class="advertiser-name">
                        ${advertiser.name}
                    </div>
                    <div>
                        <b style="margin-left: 10px;">CNPJ: </b>${advertiser.cnpj}
                    </div>
                    <div>
                        <b style="margin-left: 10px;">Telefone: </b>${advertiser.phone}
                    </div>
                    <div style="margin-left: 10px;">
                      ${advertiserAddressData}
                    </div>
                </div>
                <!-- Client Data -->
                <div class="col">
                    <div class="separator">
                        <hr />
                    </div>
                    <div class="title">
                        DADOS DO CLIENTE
                    </div>
                    <div class="separator">
                        <hr />
                    </div>
                    <div>
                        <b style="margin-left: 10px;">Nome: </b>${client.name}
                    </div>
                    <div>
                        <b style="margin-left: 10px;">Telefone: </b>${client.phone}
                    </div>
                    <div style="margin-left: 10px;">
                        <b>Endereço: </b>${client.address}
                    </div>
                </div>
                <!-- Order Data -->
                <div class="col">
                    <div class="separator">
                        <hr />
                    </div>
                    <div class="title">
                        PEDIDO ${formatOrderNumber(orderStatusData.order.id)}
                    </div>
                    <div class="center">
                        ${order.delivery_type} - Realizado em ${
    orderStatusData.new_at
  }
                    </div>
                    ${schedulingData}
                    <div class="separator">
                        <hr />
                    </div>
                    <!-- Order Products -->
                        <table style="margin-left: 10px;">
                            <thead>
                              <tr>
                                <th style="font-size: 12px;" >Qtd</th>
                                <th style="font-size: 12px;">Item</th>
                                <th style="font-size: 12px;">Vl Total (R$)</th>
                              </tr>
                            </thead>
                            <tbody>
                              ${orderProducts}
                            </tbody>
                        </table>
                    <div class="separator">
                        <hr />
                    </div>
                    <!-- Amounts Order Data -->
                    <table style="margin-left: 10px;">
                        <tbody>
                            <tr>
                                <td style="width:50px; text-align: left;">Valor total: </td>
                                <td style="width:50px; text-align: right;">R$ ${Number(
                                  orderStatusData.order.amount
                                ).toFixed(2)}</td>
                            </tr>
                            <tr>
                                <td style="width:50px; text-align: left;">Desconto: </td>
                                <td style="width:50px; text-align: right;">R$ ${Number(
                                  orderStatusData.order.discount
                                ).toFixed(2)}</td>
                            </tr>
                            ${deliveryFeeData}
                            <tr>
                                <td style="width:50px; text-align: left;"><b>Valor a pagar: </b></td>
                                <td style="width:50px; text-align: right;"><b>R$ ${Number(
                                  orderStatusData.order.total_amount
                                ).toFixed(2)}</b></td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="separator">
                        <hr />
                    </div>
                    <!-- Payment Order Data -->
                    <table style="margin-left: 10px;">
                        <tbody>
                            <tr>
                                <td style="width:50px; text-align: left;">Forma de pagamento:</td>
                                <td style="width:50px; text-align: right;">${
                                  orderStatusData.order.paymentMethod.name
                                }</td>
                            </tr>
                            ${paymentMethodAdditionalData}
                        </tbody>
                    </table>
                    <div class="separator">
                      <hr />
                    </div>
                    <!-- Footer Data -->
                    <div class="col footer-data">
                        <div><b>CONSUMIDOR</b> ${client.name}</div>
                        <div><b>Kuppi kuppi.com.br</b></div>
                    </div>
                    </div>
                </div>
            </div>		
        </body>	
    </html>
  `;

  return invoiceHTML;
};