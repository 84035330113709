import styled from 'styled-components';

import { branco, cinzaKuppi2 } from '../../styles/colors';

export const SelectButtonBox = styled.div`
  z-index: 2;
  position: relative;
  transition: all 0.2s ease-in-out;
  top: -30px;
  margin-left: 10px;
  margin-right: 155px;
  margin-bottom: 15px;

  ${(props) =>
    props.isHidden &&
    `
    display: none;
  `};

  ${(props) => `
    margin-left: ${props.margleft};
    margin-right: ${props.margright};
    margin-bottom: ${props.margbottom};
    margin-top: ${props.margtop};
  `};
`;

export const SelectButton = styled.div`
  background: ${branco};
  position: absolute;
  display: flex;
  padding: 5px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  border-radius: 5px;

  ${(props) =>
    props.hasShadow &&
    `
    box-shadow: 0px 1px 10px 3px rgba(0,0,0,0.15) !important;
  `}

  .options {
    transition: all 0.2s ease-in-out;

    ${(props) =>
      !props.openedAllOptions
        ? `
          opacity: 0;
          height: 0;
          padding: 0;
      `
        : `
          height: 100%;
          opacity: 1;
          padding: 5px;
    `};

    :hover {
      background: ${cinzaKuppi2} !important;
    }
  }
`;

export const OptionText = styled.div`
  font-size: 14px;
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.32px;
`;

export const OptionBox = styled.button`
  padding: 2px 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 130px;
  background: ${branco};
  border: none;
`;

export const IconBox = styled.div`
  margin-right: 10px;

  ${(props) =>
    props.openedAllOptions &&
    `
    transition: all 0.2s ease-in-out;
    transform: rotate(180deg);
  `}
`;

export const IconImage = styled.img`
  width: 12px;
  height: 8px;
`;
