import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingBox } from '../../pages/_layouts/styles/common';

import {
  ModalHeader,
  ModalTitleText,
  ModalActions,
  ModalSecundaryButton,
  ModalSecundaryButtonText,
  ModalPrimaryButton,
  ModalPrimaryButtonText,
} from '../Modal/styles';

import { preto } from '../../styles/colors';

import Modal from '../Modal';

import px2vw from '../../utils/responsiveness/px2vw';

import { Spinner } from 'reactstrap';
import { updateMultipleOrdersStatusesRequest } from '../../store/modules/orders/actions';
import {
  printAutomaticallyOrderInvoice,
  sendWhatsappMessage,
} from '../../utils/ordersConfigs/ordersConfigs';

export default function OrdersMassActionsPopUp(props) {
  const {
    ordersMassActionsPopUpVisible,
    setOrdersMassActionsPopUpVisible,
    currOrdersListFromSelectedTab,
    cancelOrders,
  } = props;
  const idsListToMassAction = useSelector(
    (state) => state.orders.idsListToMassAction
  );
  const listToMassActionLoading = useSelector(
    (state) => state.orders.listToMassActionLoading
  );
  const whatsappIsActive = useSelector(
    (state) =>
      state.user.user.advertiserInfo.advertiserWhatsappbotConfig.is_active
  );
  const alwaysSendWhatsappMsg = useSelector(
    (state) =>
      state.user.user.advertiserInfo.advertiserOrdersConfig
        .is_send_whatsapp_msg_update_status
  );
  const currOrderConfigs = useSelector(
    (state) => state.orders.currOrderConfigs
  );
  const user = useSelector((state) => state.user.user);
  const advertiserUser = useSelector((state) => state.user.user.advertiserInfo);
  const pixKey =
    advertiserUser.advertiserPaymentMethod.find(
      (pm) => pm.payment_method_slug === 'pix_chave'
    ).external_processor_key || null;
  const printMethod = advertiserUser.advertiserOrdersConfig.print_method;
  const printerName = advertiserUser.advertiserPrintConfig.printer_name;
  const maxLineCharsAutomatic =
    advertiserUser.advertiserPrintConfig.max_line_chars_automatic;

  const dispatch = useDispatch();

  const returnModalAction = useCallback(() => {
    setOrdersMassActionsPopUpVisible(false);
  });

  function closeModal() {
    returnModalAction();
  }

  async function handleConfirmMassActionButton() {
    dispatch(
      updateMultipleOrdersStatusesRequest(idsListToMassAction, cancelOrders)
    );

    if (alwaysSendWhatsappMsg && whatsappIsActive) {
      currOrdersListFromSelectedTab.forEach((order) => {
        if (idsListToMassAction.includes(order.order_id)) {
          let currOrder = order
          if(cancelOrders) {
            currOrder = {...order, status: "canceled"}
          }
          sendWhatsappMessage(
            alwaysSendWhatsappMsg,
            currOrder,
            whatsappIsActive,
            pixKey,
          );
        }
      });
    }

    if (printMethod === 'automatic') {
      const printOrders = currOrdersListFromSelectedTab.map(async (order) => {
        if (idsListToMassAction.includes(order.order_id)) {
          await printAutomaticallyOrderInvoice(
            currOrderConfigs.printOrder,
            order,
            user,
            printMethod,
            printerName,
            maxLineCharsAutomatic,
            false
          );
        }
      });

      await Promise.allSettled(printOrders)
    }
  }

  return (
    <Modal
      isVisible={ordersMassActionsPopUpVisible}
      width={px2vw(350)}
      height="auto"
    >
      <ModalHeader>
        <ModalTitleText textalign="center">
          {cancelOrders && 'Deseja cancelar todos os pedidos selecionados?'}
          {!cancelOrders && 'Deseja avançar de etapa todos os pedidos selecionados?'}
        </ModalTitleText>
      </ModalHeader>
      <ModalActions>
        <ModalSecundaryButton
          marginRight={px2vw(15)}
          margtop={px2vw(25)}
          width={px2vw(145)}
          type="button"
          onClick={closeModal}
          disabled={listToMassActionLoading}
        >
          <ModalSecundaryButtonText>Não</ModalSecundaryButtonText>
        </ModalSecundaryButton>
        <ModalPrimaryButton
          margleft={px2vw(15)}
          margtop={px2vw(25)}
          width={px2vw(145)}
          type="button"
          onClick={handleConfirmMassActionButton}
          disabled={listToMassActionLoading}
        >
          {listToMassActionLoading ? (
            <LoadingBox>
              <Spinner
                style={{
                  width: '1.8rem',
                  height: '1.8rem',
                  color: preto,
                }}
              />
            </LoadingBox>
          ) : (
            <ModalPrimaryButtonText>Sim</ModalPrimaryButtonText>
          )}
        </ModalPrimaryButton>
      </ModalActions>
    </Modal>
  );
}
