import React from 'react';
import { Form } from '@rocketseat/unform';
import Modal from '../../../../components/Modal';
import { 
  CloseButton, 
  CloseImage, 
  ModalHeader, 
  ModalPrimaryButton, 
  ModalPrimaryButtonText, 
  ModalText, 
  ModalTitleText 
} from '../../../../components/Modal/styles';
import { Col } from '../../../../styles/grid';
import { 
  btnTextLogin2, 
  placeholderEmail, 
  recoverEmailFound3, 
  recoverPassword, 
  recoverPassword2, 
  recoverPasswordEmailSent, 
  recoveryText3, 
  slugEmail 
} from '../../constants';
import px2vw from '../../../../utils/responsiveness/px2vw';
import { 
  ForgotEmailPasswordModal, 
  InputsBox, 
  LoginInput, 
  LoginInputBox, 
  LoginInputLabel 
} from '../../styles';

export default function RecoveryPasswordWeb({ handler }) {
  return (
    <>
      {/* Recovered successfully Email Modal */}
      {handler.recoverPasswordModal && (
        <Modal
          isVisible={handler.recoverPasswordModal}
          width={px2vw(480)}
          height={px2vw(360)}
        >
          <ForgotEmailPasswordModal>
            <ModalHeader>
              <ModalTitleText>{recoverPassword2}</ModalTitleText>
              <CloseButton onClick={() => handler.setRecoverPasswordModal(false)}>
                <CloseImage
                  src={require('../../../../assets/images/close/close.png')}
                />
              </CloseButton>
            </ModalHeader>
            <ModalText margtop={px2vw(50)} paddingLR={px2vw(19)}>
              {recoveryText3}
            </ModalText>
            <Form onSubmit={handler.handleRecoverPassword}>
              <InputsBox>
                <LoginInputBox margtop={px2vw(30)}>
                  <Col alignStart margleft={px2vw(200)}>
                    <LoginInputLabel>{slugEmail}</LoginInputLabel>
                  </Col>
                  <LoginInput
                    name="email"
                    type="email"
                    width={px2vw(400)}
                    maxLength="100"
                    placeholder={placeholderEmail}
                  />
                </LoginInputBox>
              </InputsBox>
              <ModalPrimaryButton
                margleft={px2vw(190)}
                margtop={px2vw(40)}
                type="submit"
                width={px2vw(250)}
              >
                <ModalPrimaryButtonText>
                  {recoverPassword}
                </ModalPrimaryButtonText>
              </ModalPrimaryButton>
            </Form>
          </ForgotEmailPasswordModal>
        </Modal>
      )}

      {/* Instructions successfully send password Modal */}
      {handler.sentInstructionsPasswordModal && (
        <Modal
          isVisible={handler.sentInstructionsPasswordModal}
          width={px2vw(450)}
          height={px2vw(220)}
        >
          <ForgotEmailPasswordModal>
            <ModalHeader>
              <ModalTitleText>{recoverPasswordEmailSent}</ModalTitleText>
              <CloseButton
                onClick={() => handler.setSentInstructionsPasswordModal(false)}
              >
                <CloseImage
                  src={require('../../../../assets/images/close/close.png')}
                />
              </CloseButton>
            </ModalHeader>
            <ModalText paddingLR={px2vw(30)}>
              {recoverEmailFound3}
            </ModalText>
            <ModalPrimaryButton
              width={px2vw(250)}
              margtop={px2vw(40)}
              onClick={() => handler.setSentInstructionsPasswordModal(false)}
            >
              <ModalPrimaryButtonText>{btnTextLogin2}</ModalPrimaryButtonText>
            </ModalPrimaryButton>
          </ForgotEmailPasswordModal>
        </Modal>
      )}
    </>
  )
}