import React from 'react';
import { FormControlLabel } from '@material-ui/core/';
import { toast } from 'react-toastify';

import {
  ButtonBox,
  ButtonImage,
  PauseButton,
  TabContent,
  ChooseImageBox,
  ChooseImageButton,
  ChooseImageButtonImage,
  ChooseImageButtonText,
  ProductInput,
  ProductTextAreaInput,
  RemainingCharactersProduct,
  ComplementCategoryBox,
  ProductInputLabelRegular,
  ComplementsBox,
  ComplementCategoryOptionsBox,
  ComplementCategoryOptionAddBox,
  ButtonTextBoldGreenComplement,
  ButtonTextRegularComplement,
} from '../../styles';

import {
  SwitchController,
  SwitchButtonBox,
} from '../../../_layouts/styles/common';

import { Row, Col } from '../../../../styles/grid';

import {
  ModalPrimaryButton,
  ModalPrimaryButtonText,
  ModalActions,
  ModalSecundaryButton,
  ModalSecundaryButtonText,
} from '../../../../components/Modal/styles';

import px2vw from '../../../../utils/responsiveness/px2vw';
import {
  amountMask,
} from '../../../../utils/masks';
import { onlyNumbersAmount } from '../../../../utils/utils';

export default function TabComplements({ handler }) {
  // Handle category add at array
  function addCategory() {
    handler.setComplementsArray([
      ...handler.complementsArray,
      {
        id: null,
        name: '',
        is_active: true,
        is_required: false,
        is_base_amount_more_expensive: false,
        min_qty: 0,
        max_qty: 1,
        ordenation_idx: handler.complementsArray.length + 1,
        complements: [],
      },
    ]);
  }

  // Handle complement add at category
  function addComplement(idx) {
    handler.complementsArray[idx].complements.push({
      id: null,
      name: '',
      amount: '',
      is_active: true,
      ordenation_idx: handler.complementsArray[idx].complements.length + 1,
    });
    handler.setComplementsArray([...handler.complementsArray]);
  }

  // Handle check define base amount more expensive that the complements
  function checkBaseAmountComplementCategory(categoryIdx) {
    // Find if has any complement category that has the flag true
    const hasCheckedBaseAmount = handler.complementsArray.find(
      (complementCategory, idx) =>
        complementCategory.is_base_amount_more_expensive === true &&
        idx !== categoryIdx
    );

    if (hasCheckedBaseAmount) {
      toast.error(
        'Já existe uma categoria de complemento com essa opção marcada, desmarque a outra para poder marcar essa.',
        {
          autoClose: 6000,
        }
      );
    } else {
      // If the expensive flag is true
      if (handler.complementsArray[categoryIdx].is_base_amount_more_expensive) {
        // Set the product value to the old value
        handler.setProductAmountInputValue(handler.productAmountInputOldValue ? handler.productAmountInputOldValue : amountMask(parseFloat(0).toFixed(2)));

        // Set at the complements category the flag more expensive to false
        handler.complementsArray[categoryIdx].is_base_amount_more_expensive = false;

        // Set at the complements category is not required
        handler.complementsArray[categoryIdx].is_required = false;

        // Set the min qty and max qty to default values
        handler.complementsArray[categoryIdx].min_qty = 0;
        handler.complementsArray[categoryIdx].max_qty = 1;
      } else {
        // Set the product value to 0
        handler.setProductAmountInputOldValue(handler.productAmountInputValue ? handler.productAmountInputValue : amountMask(parseFloat(0).toFixed(2)));
        handler.setProductAmountInputValue(amountMask(parseFloat(0).toFixed(2)));

        // Set at the complements category the flag more expensive to true
        handler.complementsArray[categoryIdx].is_base_amount_more_expensive = true;

        // Set at the complements category is required, because more expensive, need to be required
        handler.complementsArray[categoryIdx].is_required = true;

        // Set the min qty and max qty force minimum 2
        handler.complementsArray[categoryIdx].min_qty = 2;
        handler.complementsArray[categoryIdx].max_qty = 2;
      }

      handler.setComplementsArray([...handler.complementsArray]);
    }
  }

  return (
    <TabContent margtop="0px">
      <Row>
        <Col width="auto" margright={px2vw(20)}>
          <ChooseImageButton width={px2vw(150)} type="button">
            <ChooseImageBox
              onClick={() => {
                const lastCategoryIdx = handler.complementsArray.length
                  ? handler.complementsArray.length - 1
                  : null;
                const lastComplementIdx =
                  handler.complementsArray.length &&
                  handler.complementsArray[lastCategoryIdx].complements
                    .length
                    ? handler.complementsArray[lastCategoryIdx].complements
                        .length - 1
                    : null;

                if (
                  lastCategoryIdx !== null &&
                  lastCategoryIdx >= 0 &&
                  !handler.complementsArray[lastCategoryIdx].name
                ) {
                  toast.error(
                    'Antes de adicionar uma nova categoria, deve-se informar o nome da categoria anterior.',
                    {
                      autoClose: 6000,
                    }
                  );
                } else if (
                  lastComplementIdx !== null &&
                  lastComplementIdx >= 0 &&
                  !handler.complementsArray[lastCategoryIdx].complements[
                    lastComplementIdx
                  ].name
                ) {
                  toast.error(
                    'Antes de adicionar outra categoria, deve-se finalizar o preenchimento do complemento anterior.',
                    {
                      autoClose: 6000,
                    }
                  );
                } else if (
                  lastCategoryIdx !== null &&
                  (lastComplementIdx === null ||
                    lastComplementIdx < 0)
                ) {
                  toast.error(
                    'Antes de adicionar outra categoria, deve-se incluir algum complemento na categoria anterior.',
                    {
                      autoClose: 6000,
                    }
                  );
                } else {
                  addCategory();
                  handler.setComplementActionHidden(false);

                  // Force scroll to the end of the complements list, after 500ms
                  setTimeout(() => {
                    const complementsBoxDiv = document.getElementById(
                      'complementsBoxId'
                    );
                    complementsBoxDiv.scrollTop =
                      complementsBoxDiv.scrollHeight;
                  }, 500);
                }
              }}
            >
              <ChooseImageButtonImage
                src={require('../../../../assets/images/plus/plus.png')}
              />
              <ChooseImageButtonText>
                Nova categoria
              </ChooseImageButtonText>
            </ChooseImageBox>
          </ChooseImageButton>
        </Col>
        {handler.complementActionHidden && (
          <Col width="auto">
            <ChooseImageButton
              width={px2vw(330)}
              type="button"
              onClick={() => {
                // Validate if has products with complements to be copied
                let qtyItensWithComplements = 0;

                handler.productCategories.map((category) =>
                  category.products.map((product) => {
                    qtyItensWithComplements +=
                      product.productComplementCategories.length;
                    return true;
                  })
                );

                if (qtyItensWithComplements > 0) {
                  handler.setCopyProductComplementModal(true);
                } else {
                  toast.error(
                    'Não existem itens com complementos para serem copiados.',
                    {
                      autoClose: 6000,
                    }
                  );
                }
              }}
            >
              <ChooseImageBox>
                <ChooseImageButtonImage
                  src={require('../../../../assets/images/duplicate/duplicate.png')}
                />
                <ChooseImageButtonText>
                  Copiar complementos de outro produto
                </ChooseImageButtonText>
              </ChooseImageBox>
            </ChooseImageButton>
          </Col>
        )}
        {!handler.complementActionHidden && (
          <Col width="auto">
            <ChooseImageButton
              type="button"
              width={px2vw(215)}
              onClick={() => {
                if (
                  handler.complementsArray.length > 1 ||
                  (handler.complementsArray[0].complements &&
                    handler.complementsArray[0].complements.length > 1)
                ) {
                  handler.setReorderComplementsCategoryArray(
                    JSON.parse(JSON.stringify(handler.complementsArray))
                  );
                  handler.setReorderCategoryComplementModal(true);
                } else {
                  toast.error(
                    'Não existem complementos para serem ordenados.',
                    {
                      autoClose: 6000,
                    }
                  );
                }
              }}
            >
              <ChooseImageBox>
                <ChooseImageButtonImage
                  src={require('../../../../assets/images/arrows/arrow_reorder.png')}
                />
                <ChooseImageButtonText>
                  Reordenar complementos
                </ChooseImageButtonText>
              </ChooseImageBox>
            </ChooseImageButton>
          </Col>
        )}
        <Col width="100%" alignEnd margright={px2vw(9)}>
          <ChooseImageButton
            isHidden={handler.complementActionHidden}
            onClick={() => {
              handler.setConfirmDeleteCompCategoryIdx(-1);
              handler.setConfirmDeleteComplementIdx(-1);
              handler.setConfirmDeleteCompCategoryOrComplementModal(true);
            }}
            width={px2vw(140)}
            type="button"
          >
            <ChooseImageBox>
              <ChooseImageButtonImage
                src={require('../../../../assets/images/delete/delete_black.png')}
              />
              <ChooseImageButtonText>
                Excluir todos
              </ChooseImageButtonText>
            </ChooseImageBox>
          </ChooseImageButton>
        </Col>
      </Row>
      <ComplementsBox id="complementsBoxId">
        {handler.complementsArray.length > 0 &&
          handler.complementsArray.map((category, idx) => (
            <>
              <ComplementCategoryBox>
                <Col width="100%" height="100%" pad={px2vw(15)}>
                  <Row width="auto">
                    {/* Complement Category Name */}
                    <Col zIndex="0">
                      <ProductInputLabelRegular>
                        Nome da categoria
                      </ProductInputLabelRegular>
                      <ProductInput
                        name="productComplement"
                        width={px2vw(500)}
                        placeholder="Nome da Categoria de Complemento. Ex. Sabor da Pizza"
                        maxLength="40"
                        value={handler.complementsArray[idx].name}
                        onChange={(e) => {
                          handler.complementsArray[idx].name =
                            e.target.value;
                          handler.setComplementsArray([
                            ...handler.complementsArray,
                          ]);
                        }}
                      />
                      <Row
                        width={px2vw(500)}
                        alignStart
                        justifyEnd
                        height="auto"
                      >
                        <RemainingCharactersProduct>
                          {handler.complementsArray[idx].name.length}/40
                          caracteres
                        </RemainingCharactersProduct>
                      </Row>
                    </Col>
                    {/* Complement Category Pause Button */}
                    <Col
                      justifyCenter
                      alignCenter
                      margleft={px2vw(10)}
                    >
                      <PauseButton
                        width={px2vw(160)}
                        onClick={() => {
                          handler.complementsArray[
                            idx
                          ].is_active = !handler.complementsArray[idx]
                            .is_active;
                          handler.setComplementsArray([
                            ...handler.complementsArray,
                          ]);
                        }}
                        type="button"
                      >
                        <ButtonBox>
                          <ButtonImage
                            active={
                              !handler.complementsArray[idx].is_active
                            }
                            src={require('../../../../assets/images/pause/pause_green.png')}
                          />

                          {handler.complementsArray[idx].is_active ? (
                            <ButtonTextRegularComplement greenColor>
                              Pausar Categoria
                            </ButtonTextRegularComplement>
                          ) : (
                            <ButtonTextRegularComplement
                              yellowColor
                            >
                              Despausar Categoria
                            </ButtonTextRegularComplement>
                          )}
                        </ButtonBox>
                      </PauseButton>
                    </Col>
                    {/* Complement Category Delete Button */}
                    <Col
                      justifyCenter
                      alignCenter
                      margleft={px2vw(15)}
                    >
                      <ChooseImageButton
                        onClick={() => {
                          handler.setConfirmDeleteCompCategoryIdx(idx);
                          handler.setConfirmDeleteComplementIdx(null);
                          handler.setConfirmDeleteCompCategoryOrComplementModal(true);
                        }}
                        width={px2vw(20)}
                        height={px2vw(20)}
                        type="button"
                      >
                        <ChooseImageButtonImage
                          src={require('../../../../assets/images/delete/delete_red.png')}
                        />
                      </ChooseImageButton>
                    </Col>
                  </Row>
                  <Row height="auto" margtop={px2vw(15)}>
                    {/* Switch Actions Buttons */}
                    <Col width="100%">
                      <SwitchButtonBox>
                        <FormControlLabel
                          control={
                            <SwitchController
                              checked={
                                handler.complementsArray[idx].is_required
                              }
                              onChange={() => {
                                if (
                                  !handler.complementsArray[idx]
                                    .is_base_amount_more_expensive
                                ) {
                                  handler.complementsArray[
                                    idx
                                  ].is_required = !handler.complementsArray[
                                    idx
                                  ].is_required;
                                  handler.setComplementsArray([
                                    ...handler.complementsArray,
                                  ]);

                                  // Force min qty and max qty when complement was required
                                  if (
                                    handler.complementsArray[idx]
                                      .is_required
                                  ) {
                                    handler.complementsArray[
                                      idx
                                    ].min_qty = 1;
                                    handler.complementsArray[
                                      idx
                                    ].max_qty = 1;
                                  } else {
                                    handler.complementsArray[
                                      idx
                                    ].min_qty = 0;
                                    handler.complementsArray[
                                      idx
                                    ].max_qty = 1;
                                  }
                                } else {
                                  toast.error(
                                    'O complemento deve ser obrigatório, quando a flag de definir o valor baseado no complemento mais caro estiver habilitada.',
                                    {
                                      autoClose: 6000,
                                    }
                                  );
                                }
                              }}
                            />
                          }
                          label={
                            <ProductInputLabelRegular>
                              Complemento obrigatório
                            </ProductInputLabelRegular>
                          }
                        />
                      </SwitchButtonBox>
                      <SwitchButtonBox>
                        <FormControlLabel
                          control={
                            <SwitchController
                              checked={
                                handler.complementsArray[idx]
                                  .is_base_amount_more_expensive
                              }
                              onChange={() => {
                                checkBaseAmountComplementCategory(
                                  idx
                                );
                              }}
                            />
                          }
                          label={
                            <ProductInputLabelRegular>
                              Definir o valor baseado no complemento
                              mais caro
                            </ProductInputLabelRegular>
                          }
                        />
                      </SwitchButtonBox>
                    </Col>
                    {/* Complement Category Min Qty */}
                    <Col width="auto">
                      <ProductInputLabelRegular>
                        Quantidade mínima
                      </ProductInputLabelRegular>
                      <ProductInput
                        name="productComplementMinQty"
                        type="number"
                        width={px2vw(125)}
                        min="0"
                        max="99"
                        value={handler.complementsArray[idx].min_qty}
                        placeholder="0"
                        onChange={(e) => {
                          if (
                            !handler.complementsArray[idx]
                              .is_base_amount_more_expensive
                          ) {
                            if (e.target.value >= 1) {
                              handler.complementsArray[idx].min_qty =
                                e.target.value;

                              handler.complementsArray[
                                idx
                              ].is_required = true;
                              handler.complementsArray[idx].max_qty =
                                handler.complementsArray[idx].min_qty;
                            } else {
                              handler.complementsArray[idx].min_qty =
                                e.target.value;

                              handler.complementsArray[
                                idx
                              ].is_required = false;
                            }
                          } else if (
                            e.target.value !== '' &&
                            e.target.value <= 1
                          ) {
                            toast.error(
                              'A quantidade mínima deve ser pelo menos 2, quando a flag de definir o valor baseado no complemento mais caro estiver habilitada.',
                              {
                                autoClose: 6000,
                              }
                            );
                          } else {
                            handler.complementsArray[idx].min_qty =
                              e.target.value;
                          }

                          if (
                            handler.complementsArray[idx].min_qty >
                            handler.complementsArray[idx].max_qty
                          ) {
                            handler.complementsArray[idx].max_qty =
                              handler.complementsArray[idx].min_qty;
                          }

                          handler.setComplementsArray([
                            ...handler.complementsArray,
                          ]);
                        }}
                      />
                    </Col>
                    {/* Complement Category Max Qty */}
                    <Col width="auto" margleft={px2vw(20)}>
                      <ProductInputLabelRegular>
                        Quantidade máxima
                      </ProductInputLabelRegular>
                      <ProductInput
                        name="productComplementMaxQty"
                        type="number"
                        width={px2vw(130)}
                        min="0"
                        max="99"
                        value={handler.complementsArray[idx].max_qty}
                        placeholder="1"
                        onBlur={(e) => {
                          if (parseInt(handler.complementsArray[idx].max_qty, 10) === 0) {
                            toast.error(
                              'A quantidade máxima tem que ser maior ou igual 1.',
                              {
                                autoClose: 6000,
                              }
                            );
                            handler.complementsArray[idx].max_qty = ""
                            handler.setComplementsArray([
                              ...handler.complementsArray,
                            ]);
                            setTimeout(() => {
                              const maxComplInput = document.getElementById('productComplementMaxQty');
                              if (maxComplInput) {
                                maxComplInput.focus();
                              }
                            }, 5);                                          
                          } else if (parseInt(handler.complementsArray[idx].max_qty, 10) < parseInt(handler.complementsArray[idx].min_qty, 10) || handler.complementsArray[idx].max_qty === "") {
                            toast.error(
                              'A quantidade máxima tem que ser maior ou igual a quantidade mínima.',
                              {
                                autoClose: 6000,
                              }
                            );
                            handler.complementsArray[idx].max_qty = ""
                            handler.setComplementsArray([
                              ...handler.complementsArray,
                            ]);
                            setTimeout(() => {
                              const maxComplInput = document.getElementById('productComplementMaxQty');
                              if (maxComplInput) {
                                maxComplInput.focus();
                              }
                            }, 5);  
                          }
                        }}
                        onChange={(e) => {
                          handler.complementsArray[idx].max_qty =
                            e.target.value;
                          handler.setComplementsArray([
                            ...handler.complementsArray,
                          ]);
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
              </ComplementCategoryBox>

              {handler.complementsArray[idx].complements.length > 0 &&
                handler.complementsArray[idx].complements.map(
                  (comp, idxComp) => (
                    <ComplementCategoryOptionsBox>
                      <Row
                        width="100%"
                        height="100%"
                        pad={px2vw(15)}
                      >
                        <Col width="auto">
                          {/* Complement Name */}
                          <Col width="auto">
                            <ProductInputLabelRegular>
                              Nome do complemento
                            </ProductInputLabelRegular>
                            <ProductInput
                              name="productComplementName"
                              width={px2vw(340)}
                              maxLength="40"
                              placeholder="Batata Frita"
                              value={
                                handler.complementsArray[idx].complements[
                                  idxComp
                                ].name
                              }
                              onChange={(e) => {
                                handler.complementsArray[idx].complements[
                                  idxComp
                                ].name = e.target.value;
                                handler.setComplementsArray([
                                  ...handler.complementsArray,
                                ]);
                              }}
                            />
                            <Row
                              alignStart
                              justifyEnd
                              height="auto"
                            >
                              <RemainingCharactersProduct>
                                {
                                  handler.complementsArray[idx].complements[
                                    idxComp
                                  ].name.length
                                }
                                /40 caracteres
                              </RemainingCharactersProduct>
                            </Row>
                          </Col>
                          <Row width="auto" height="auto">
                            {/* Complement Amount */}
                            <Col width="auto">
                              <ProductInputLabelRegular>
                                Preço
                              </ProductInputLabelRegular>
                              <ProductInput
                                name="productComplementAmount"
                                width={px2vw(100)}
                                maxLength="9"
                                placeholder="R$ 000,00"
                                value={amountMask(
                                  handler.complementsArray[idx].complements[
                                    idxComp
                                  ].amount
                                )}
                                onChange={(e) => {
                                  e.target.value = onlyNumbersAmount(
                                    amountMask(e.target.value)
                                  );

                                  handler.complementsArray[idx].complements[
                                    idxComp
                                  ].amount = e.target.value;
                                  handler.setComplementsArray([
                                    ...handler.complementsArray,
                                  ]);
                                }}
                              />
                            </Col>
                            {/* Complement Action Buttons */}
                            <Row
                              alignCenter
                              margtop={px2vw(10)}
                              margleft={px2vw(5)}
                            >
                              <PauseButton
                                width={px2vw(210)}
                                onClick={() => {
                                  handler.complementsArray[idx].complements[
                                    idxComp
                                  ].is_active = !handler.complementsArray[
                                    idx
                                  ].complements[idxComp].is_active;
                                  handler.setComplementsArray([
                                    ...handler.complementsArray,
                                  ]);
                                }}
                                type="button"
                              >
                                <ButtonBox>
                                  <ButtonImage
                                    active={
                                      !handler.complementsArray[idx]
                                        .complements[idxComp]
                                        .is_active
                                    }
                                    src={require('../../../../assets/images/pause/pause_green.png')}
                                  />
                                  {handler.complementsArray[idx]
                                    .complements[idxComp]
                                    .is_active ? (
                                    <ButtonTextRegularComplement
                                      greenColor
                                    >
                                      Pausar Complemento
                                    </ButtonTextRegularComplement>
                                  ) : (
                                    <ButtonTextRegularComplement
                                      yellowColor
                                    >
                                      Despausar Complemento
                                    </ButtonTextRegularComplement>
                                  )}
                                </ButtonBox>
                              </PauseButton>
                              <ChooseImageButton
                                margleft={px2vw(10)}
                                width={px2vw(20)}
                                height={px2vw(20)}
                                onClick={() => {
                                  handler.setConfirmDeleteCompCategoryIdx(idx);
                                  handler.setConfirmDeleteComplementIdx(idxComp);
                                  handler.setConfirmDeleteCompCategoryOrComplementModal(true);
                                }}
                                type="button"
                              >
                                <ChooseImageButtonImage
                                  src={require('../../../../assets/images/delete/delete_red.png')}
                                />
                              </ChooseImageButton>
                            </Row>
                          </Row>
                        </Col>
                        {/* Complement Description */}
                        <Col
                          width="auto"
                          margtop={px2vw(5)}
                          margleft={px2vw(15)}
                        >
                          <ProductInputLabelRegular>
                            Descrição
                          </ProductInputLabelRegular>
                          <ProductTextAreaInput
                            name="productComplementDescription"
                            width={px2vw(330)}
                            height={px2vw(270)}
                            maxLength="240"
                            placeholder="Bem fritinha"
                            value={
                              handler.complementsArray[idx].complements[
                                idxComp
                              ].description &&
                              handler.complementsArray[idx].complements[
                                idxComp
                              ].description
                            }
                            onChange={(e) => {
                              handler.complementsArray[idx].complements[
                                idxComp
                              ].description = e.target.value;
                              handler.setComplementsArray([
                                ...handler.complementsArray,
                              ]);
                            }}
                          />
                          <Row alignStart justifyEnd height="auto">
                            <RemainingCharactersProduct>
                              {handler.complementsArray[idx].complements[
                                idxComp
                              ].description
                                ? handler.complementsArray[idx].complements[
                                    idxComp
                                  ].description.length
                                : '0'}
                              /240 caracteres
                            </RemainingCharactersProduct>
                          </Row>
                        </Col>
                      </Row>
                    </ComplementCategoryOptionsBox>
                  )
                )}

              <ComplementCategoryOptionAddBox
                onClick={() => {
                  if (!handler.complementsArray[idx].name) {
                    toast.error(
                      'Antes de adicionar um complemento, deve-se informar o nome da categoria desse complemento.',
                      {
                        autoClose: 6000,
                      }
                    );
                  } else if (
                    handler.complementsArray[idx].complements.length > 0 &&
                    !handler.complementsArray[idx].complements[
                      handler.complementsArray[idx].complements.length - 1
                    ].name
                  ) {
                    toast.error(
                      'Antes de adicionar outro complemento, deve-se finalizar o preenchimento do complemento anterior.',
                      {
                        autoClose: 6000,
                      }
                    );
                  } else {
                    addComplement(idx);
                  }
                }}
              >
                <Row alignCenter justifyCenter width="100%">
                  <ButtonImage
                    src={require('../../../../assets/images/plus/plus_green.png')}
                  />
                  <ButtonTextBoldGreenComplement>
                    Adicionar complemento
                  </ButtonTextBoldGreenComplement>
                </Row>
              </ComplementCategoryOptionAddBox>
            </>
          ))}
      </ComplementsBox>
      <ModalActions>
        <ModalSecundaryButton
          margleft={px2vw(60)}
          marginRight={px2vw(20)}
          margtop={px2vw(30)}
          width={px2vw(290)}
          onClick={handler.handleCloseProductModal}
        >
          <ModalSecundaryButtonText>
            Cancelar
          </ModalSecundaryButtonText>
        </ModalSecundaryButton>
        <ModalPrimaryButton
          margleft={px2vw(20)}
          margtop={px2vw(30)}
          width={px2vw(290)}
          type="button"
          onClick={() => {
            handler.validateProductComplements('tabDisponibility');
          }}
        >
          <ModalPrimaryButtonText>Avançar</ModalPrimaryButtonText>
        </ModalPrimaryButton>
      </ModalActions>
    </TabContent>
  );
}
