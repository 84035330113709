import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AiOutlineLeft } from 'react-icons/ai';

import { Text } from '../../../../styles/common';
import { ModalPrimaryButton } from '../styles';
import {
  ModalContent,
  CloseButton,
  ModalHeader,
  CloseImage,
  ModalFooter,
  Image,
  ImageBox,
  AdsCard,
  AdsFooter,
  AdsHeader,
  TxtArea,
} from './styles';
import { Row, Col } from '../../../../styles/grid';

import {
  setCampaignCurrentStep,
  setTitle,
  setMainText,
} from '../../../../store/modules/kuppiBoost/actions';

import { formatCurrencyBRL, getURL } from '../../../../utils/utils';
import px2vw from '../../../../utils/responsiveness/px2vw';

export default function CampaignAds(props) {
  const dispatch = useDispatch();
  const kuppiBoostData = useSelector((state) => state.kuppiBoost);
  const advertiserSlug = useSelector(
    (state) => state.user.user.advertiserInfo.slug
  );
  const { type, selected_coupon, selected_product } = kuppiBoostData.campaign;
  const wasImgUploadedLocally =
    selected_coupon?.was_image_uploaded_locally ||
    selected_product?.was_image_uploaded_locally;
  const [adsTitle, setAdsTitle] = useState('Faça o seu pedido agora!');
  const [adsMainText, setAdsMainText] = useState(
    !wasImgUploadedLocally
      ? `Opaaaa! Essa foto deu até fome né?\nAproveite o nosso desconto hoje:\n${
          type === 'conversion'
            ? `${selected_coupon.product_name}: ${selected_coupon.coupon_short_display_name}`
            : `${selected_product.product_name}: ${formatCurrencyBRL(
                selected_product.amount,
                2
              )}`.trim()
        }\nFaça seu pedido agora e não perca tempo!`
      : `Essa foto deu até fome né?\nAproveite nossos preços exclusivos!`
  );
  const isTypeRangeOrWhatsApp = type === 'range' || type === 'whatsapp';
  const inputsValidation = adsMainText.length > 20 && adsTitle.length > 9;

  function handleBacktoPreviousStep() {
    dispatch(setCampaignCurrentStep('3'));
  }

  function handleGotoNextStep() {
    dispatch(setTitle(adsTitle));
    dispatch(setMainText(adsMainText));
    dispatch(setCampaignCurrentStep('5'));
  }

  return (
    <>
      <ModalHeader>
        Escolha o template ideal
        <CloseButton onClick={props.onClose}>
          <CloseImage
            src={require('../../../../assets/images/close/close.png')}
          />
        </CloseButton>
      </ModalHeader>
      <ModalContent>
        <Row justifyStart>
          <AdsCard>
            <AdsHeader>
              <Text justifyStart size={px2vw(13)} regular wrap>
                {adsMainText}
              </Text>
            </AdsHeader>

            <ImageBox
              className="my-auto"
              width={px2vw(228)}
              height={px2vw(228)}
            >
              <Image
                src={
                  isTypeRangeOrWhatsApp
                    ? selected_product.imgTemplate
                    : selected_coupon.imgTemplate
                }
              />
            </ImageBox>

            <AdsFooter>
              <Text justifyStart size={px2vw(13)} regular>
                {getURL('cardapio_web')
                  .replace('$$advertiser_slug$$', advertiserSlug)
                  .replace('https://', '')
                  .toUpperCase()}
              </Text>
              <Text justifyStart size={px2vw(12.5)} className="w-75" regular>
                {adsTitle}
              </Text>
            </AdsFooter>
          </AdsCard>
          <Col>
            <Text justifyStart size={px2vw(17)}>
              Texto principal:
            </Text>
            <TxtArea
              onChange={(e) => setAdsMainText(e.target.value)}
              value={adsMainText}
              maxLength="1200"
            />
            <Text justifyStart size={px2vw(17)}>
              Título:
            </Text>
            <TxtArea
              onChange={(e) => setAdsTitle(e.target.value)}
              height={px2vw(100)}
              value={adsTitle}
              maxLength="45"
            />
          </Col>
        </Row>
      </ModalContent>
      <ModalFooter>
        <Row className="my-auto" alignCenter>
          <Row
            onClick={handleBacktoPreviousStep}
            justifyStart
            alignCenter
            width="auto"
            cursor="pointer"
          >
            <AiOutlineLeft className="mr-2" /> Voltar
          </Row>
          <Row>
            <ModalPrimaryButton
              disabled={!inputsValidation}
              className="ml-auto"
              onClick={() => handleGotoNextStep()}
            >
              Avançar
            </ModalPrimaryButton>
          </Row>
        </Row>
      </ModalFooter>
    </>
  );
}
