import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { FormControlLabel } from '@material-ui/core/';

import { Col } from '../../styles/grid';

import {
  SwitchController,
  SwitchLabelBoldText,
  SwitchButtonBox,
} from '../../pages/_layouts/styles/common';

import { changeAcceptingOrdersRequest } from '../../store/modules/orders/actions';
import px2vw from '../../utils/responsiveness/px2vw';

export default function AcceptingOrdersButtons() {
  const userLogo = useSelector((state) => state.user.user.file_id);
  const acceptingOrders = useSelector(
    (state) => state.user.user.advertiserInfo.is_accepting_orders
  );
  const acceptingImmediateOrders = useSelector(
    (state) =>
      state.user.user.advertiserInfo.advertiserOrdersConfig
        .is_accepting_immediate_order
  );
  const acceptingScheduledOrders = useSelector(
    (state) =>
      state.user.user.advertiserInfo.advertiserOrdersConfig
        .is_accepting_scheduled_order
  );
  const dispatch = useDispatch();

  function handleChangeAcceptingOrders(
    isAcceptingImmediateOrders,
    isAcceptingScheduledOrders
  ) {
    if (userLogo) {
      dispatch(
        changeAcceptingOrdersRequest(
          isAcceptingImmediateOrders,
          isAcceptingScheduledOrders
        )
      );
    } else {
      toast.info(
        'Para começar a aceitar pedidos, obrigatoriamente você deve ter cadastrado a sua logo!',
        {
          autoClose: 8000,
        }
      );
    }
  }

  return (
    <Col height="auto" alignStart>
      <SwitchLabelBoldText margboottom={px2vw(15)}>
        Ative umas das opções abaixo para abrir a loja:
      </SwitchLabelBoldText>
      <SwitchButtonBox>
        <FormControlLabel
          control={
            <SwitchController
              checked={acceptingOrders && acceptingImmediateOrders}
              onChange={() =>
                handleChangeAcceptingOrders(
                  !acceptingImmediateOrders,
                  acceptingScheduledOrders
                )
              }
            />
          }
          label={
            <SwitchLabelBoldText>
              Aceitando pedidos de pronta entrega
            </SwitchLabelBoldText>
          }
        />
      </SwitchButtonBox>
      <SwitchButtonBox>
        <FormControlLabel
          control={
            <SwitchController
              checked={acceptingOrders && acceptingScheduledOrders}
              onChange={() =>
                handleChangeAcceptingOrders(
                  acceptingImmediateOrders,
                  !acceptingScheduledOrders
                )
              }
            />
          }
          label={
            <SwitchLabelBoldText>
              Aceitando pedidos por agendamento
            </SwitchLabelBoldText>
          }
        />
      </SwitchButtonBox>
    </Col>
  );
}
