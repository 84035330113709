import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Progress } from 'reactstrap';
import { CircularProgress } from '@material-ui/core';
import { toast } from 'react-toastify';

import {
  Container,
  Paragraph,
  Label,
  CountryCodeInput,
  TelInput,
  InputBox,
  InputText,
  ModalFooter,
  ManualOrderButtonPrimary,
  ProgressBar,
  iconMui
} from './styles';
import { preto, vermelhoKuppi1, verdeKuppi5 } from '../../../styles/colors';

import { dddPhoneMask } from '../../../utils/masks';
import debounce from '../../../utils/debounce/debounce';
import { onlyNumbers } from '../../../utils/utils';
import { useConstant } from '../../../utils/hooks';
import px2vw from '../../../utils/responsiveness/px2vw';
import { sendManualOrderInfo } from '../../../services/vendors/mixpanel';

import {
  verifyUserExistsByPhoneRequest,
  createNewUserRequest,
  setUpdatedClientName,
  setCurrStep,
  clearClientInfo,
} from '../../../store/modules/manualOrder/actions';

export default function ClientInfo() {
  const hasFreeTrialValid = useSelector(
    (state) => state.user.hasFreeTrialValid
  );
  const user = useSelector((state) => state.user.user);
  const [countryCode, setCountryCode] = useState('55');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [clientName, setClientName] = useState('');
  const { selectedConversation } = useSelector((state) => state?.whatsappChat);
  const clientData = useSelector((state) => state?.manualOrder?.clientData);
  const isNewClient = useSelector((state) => state?.manualOrder?.isNewClient);
  const isGettingClientInfoLoading = useSelector(
    (state) => state?.manualOrder?.isGettingClientInfoLoading
  );
  const isCreatingUserLoading = useSelector(
    (state) => state?.manualOrder?.isCreatingUserLoading
  );
  const isCreatingUserSuccess = useSelector(
    (state) => state?.manualOrder?.isCreatingUserSuccess
  );
  const namePatern = /.{3,}/gi;
  const phonePatern = /(\(?\d{2}\)?) ?9?\d{4}-?\d{4}/;
  const isNameValid = namePatern.test(clientName);
  const isPhoneValid = phonePatern.test(onlyNumbers(phoneNumber));
  const dispatch = useDispatch();
  const mixPanelFillClientData = {
    id: user.advertiserInfo.id,
    name: user.advertiserInfo.name,
    email: user.email,
    currManualOrderStep: 'FillClientData',
    hasFreeTrialValid: hasFreeTrialValid,
  };

  function requestPhoneInfoData(phone) {
    if (phone.length >= 12) {
      dispatch(verifyUserExistsByPhoneRequest(phone));
    }
  }

  const debouncedOnChange = useConstant(() => debounce(requestPhoneInfoData));

  useEffect(() => {
    if (!isNewClient) {
      setClientName(clientData?.name);
    }
    if (isNewClient && !selectedConversation.name.includes("+55")) {
      setClientName(selectedConversation.name);
    }
    if (clientData?.phone) {
      setPhoneNumber(clientData?.phone);
    }
  }, [clientData]);

  useEffect(() => {
    if (isCreatingUserSuccess) {
      //MixPanel Event
      sendManualOrderInfo(mixPanelFillClientData);
      dispatch(setCurrStep('2'));
    }
  }, [isCreatingUserSuccess]);

  function handleStartManualOrderCreation() {
    if (isNewClient) {
      if (isNameValid) {
        dispatch(
          createNewUserRequest(
            onlyNumbers(
              countryCode + dddPhoneMask(phoneNumber)
            ),
            clientName
          )
        );
      } else {
        if (clientName.trim() === '') {
          toast.error('O nome é obrigatório para novos clientes', {
            autoClose: 6000,
          });
        } else {
          toast.error('O nome informado é inválido. Utilize apenas letras, números e caracteres especiais', {
            autoClose: 6000,
          });
        }
      }
    } else {
      dispatch(setUpdatedClientName(clientName));
      //MixPanel Event
      sendManualOrderInfo(mixPanelFillClientData);
      dispatch(setCurrStep('2'));
    }
  }

  return (
    <>
      <ProgressBar>
        <Progress
          value={isPhoneValid && isNameValid ? 20 : 0}
          bar={true}
          style={{
            backgroundColor: verdeKuppi5,
            borderRaduis: '0px',
            height: '10px',
          }}
        />
      </ProgressBar>
      <Container>
        <Paragraph color={preto}>
          Digite as informações do seu cliente
        </Paragraph>
        <Label>Telefone</Label>
        <InputBox>
          <CountryCodeInput
            name="country_code"
            value={`+${countryCode}`}
            onChange={(e) => {
              setCountryCode(onlyNumbers(e.target.value));
            }}
            maxLength="3"
            type="tel"
            placeholder="+55"
          />
          <TelInput
            name="phone"
            value={dddPhoneMask(phoneNumber)}
            onChange={(e) => {
              dispatch(clearClientInfo());
              setPhoneNumber(e.target.value);
              debouncedOnChange(onlyNumbers(countryCode + e.target.value));
            }}
            maxLength="16"
            type="tel"
            placeholder="(11) 9 9999-9999"
          />
        </InputBox>
        <Label for="name">Nome</Label>
        <InputBox>
          <InputText
            name="client_name"
            value={clientName}
            onChange={(e) => {
              setClientName(e.target.value);
            }}
            maxLength="40"
            type="text"
          />
        </InputBox>
        {!isNewClient && clientData?.total_orders === 0 && (
          <Paragraph
            color={vermelhoKuppi1}
            margtop={px2vw(10)}
            margbottom={px2vw(10)}
          >
            Novo Cliente!
          </Paragraph>
        )}
        {isNewClient && (
          <Paragraph
            color={vermelhoKuppi1}
            margtop={px2vw(10)}
            margbottom={px2vw(10)}
          >
            {`Novo Cliente! ${clientName.trim() === '' ? ' Informe o nome para prosseguir' : !isNameValid ? ' Informe um nome válido para prosseguir' : '' }`}
          </Paragraph>
        )}
        {!isGettingClientInfoLoading && clientData?.total_orders > 0 && (
          <>
            <Paragraph margtop={px2vw(10)} margbottom={px2vw(10)}>
              {`Cliente já cadastrado, já realizou ${clientData?.total_orders} pedidos.`}
            </Paragraph>
            <Paragraph margtop={px2vw(10)} margbottom={px2vw(10)}>
              {`Data do último pedido: ${clientData?.last_order}.`}
            </Paragraph>
          </>
        )}
      </Container>
      <ModalFooter margtop="auto">
        <ManualOrderButtonPrimary
          disabled={
            isPhoneValid && isNameValid && isNewClient
              ? false
              : !clientData?.phone ||
                isGettingClientInfoLoading ||
                isCreatingUserLoading
          }
          onClick={() => handleStartManualOrderCreation()}
        >
          {!isGettingClientInfoLoading || isCreatingUserLoading ? (
            'Criar pedido manual'
          ) : (
            <CircularProgress style={iconMui} size="sm" />
          )}
        </ManualOrderButtonPrimary>
      </ModalFooter>
    </>
  );
}
