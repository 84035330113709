import styled, { css } from 'styled-components';
import {
  branco,
  cinzaKuppi2,
  cinzaKuppi4,
  cinzaKuppi6,
  verdeKuppi1,
  verdeKuppi2,
} from '../../../../styles/colors';
import px2vw from '../../../../utils/responsiveness/px2vw';

export const ModalBackground = styled.div`
  display: none;
  position: fixed;
  z-index: 60;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: ${cinzaKuppi6}99;

  ${(props) =>
    props.isVisible &&
    `
    display: flex;
    justify-content: center;
    align-items: center;
  `};
`;

export const ModalContent = styled.div`
  position: relative;
  background-color: ${branco};
  padding: ${(props) => props.padding || '20px'};
  border-radius: 20px;
  box-shadow: 2px 2px 3px transparent;
  ${(props) => `
    width: ${props.width};
    min-height: ${props.height};
    max-height: ${props.maxheight};
    background-color: ${props.bgcolor};
    margin-top: ${props.margtop};
  `};
`;

export const ModalPrimaryButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${branco};
  border: none;
  background-color: ${verdeKuppi2};
  border-radius: ${px2vw(7)};
  font-size: ${(props) => props.fontSize || px2vw(13)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.015em;
  padding: ${px2vw(8)};
  box-shadow: 2px 2px 3px ${cinzaKuppi2};
  width: ${(props) => (props.width ? props.width : px2vw(155))};
  height: ${(props) => (props.height ? props.height : px2vw(37))};

  margin-top: ${(props) => (props.margtop ? props.margtop : 0)};
  margin-right: ${(props) => (props.margright ? props.margright : 0)};

  &:active {
    box-shadow: 2px 2px 3px ${cinzaKuppi2};
    background: ${verdeKuppi1};
  }
  ${(props) =>
    props.disabled &&
    `
  background-color: ${cinzaKuppi4};
  color:${branco};

  &:active {
    box-shadow: 2px 2px 3px ${cinzaKuppi2};
    background: ${cinzaKuppi4};
  }

  `}
`;

export const ModalHeader = styled.div`
  width: 100%;
  background-color: ${branco};
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  font-family: AvenirNextLTPro;
  font-weight: 700;
  font-size: ${px2vw(18)};
  padding-top: ${px2vw(5)};
  display: flex;
  align-items: space-between;
  justify-content: space-between;
`;

export const CloseButton = styled.button`
  width: ${px2vw(22)};
  height: ${px2vw(22)};
  border: none;
  z-index: 10;
  background-color: transparent;

  :focus {
    background-color: transparent !important;
  }

  ${(props) =>
    props.absolute &&
    css`
      position: absolute;
      top: ${props.top || px2vw(10)};
      right: ${props.right || px2vw(10)};
    `}
`;

export const CloseImage = styled.img`
  width: ${px2vw(15)};
  height: ${px2vw(15)};
`;

export const Hr = styled.hr`
  width: 100%;
  background-color: ${cinzaKuppi4};
  margin: ${px2vw(5)} 0;
  height: ${px2vw(2)};
  border: none;
`;
