import React from "react";
import { FormControlLabel } from "@material-ui/core";

import { branco, preto } from "../../../../../../styles/colors";
import { Col, Row } from "../../../../../../styles/grid";
import {
  Image,
  NormalText,
} from '../../../../BaseScreen/styles';
import {
  ModalBackground,
  SwitchInput,
  SwitchController,
  SwitchWithInput,
  SwitchLabelRegularText,
  SwitchWithInputBox,
} from '../../styles';

import px2vw from "../../../../../../utils/responsiveness/px2vw";
import { amountMask } from "../../../../../../utils/masks";
import { onlyNumbers } from "../../../../../../utils/utils";

import ButtonPrimary from '../../../../../../components/ButtonPrimary';
import ButtonSecondary from '../../../../../../components/ButtonSecondary';

export default function DynamicDeliveryModal({ handler }) {
  function ModalCheckboxHandler({ checked, type }) {
    return (
      <Image src={checked ? require('../../../../../../assets/images/onboarding/filledCheckbox@2x.png') : require('../../../../../../assets/images/onboarding/emptyCheckbox@2x.png')}
        width={px2vw(70)}
        height={px2vw(70)}
        margright={px2vw(20)}
        onClick={() => {
          if (type === 'route') {
            handler.setDynamicFeeCalcTypeRouteInputValue(true);
            handler.setDynamicFeeCalcTypeRadiusInputValue(false);
          } else {
            handler.setDynamicFeeCalcTypeRouteInputValue(false);
            handler.setDynamicFeeCalcTypeRadiusInputValue(true);
          }
        }}
      />
    )
  }

  return (
    <ModalBackground isVisible={true}>
      <Col 
        background={branco} 
        borderRadius={px2vw(20)}
        width='90%' 
        height='auto' 
        pad={px2vw(80)}

      >
        {/* Header */}
        <Row spaceBetween padbottom={px2vw(100)}>
          <NormalText
            fontweight='bold'
            textalign='flex-start'
            color={preto}
            fontsize={px2vw(60)}>
            Configurações da taxa de entrega dinâmica
          </NormalText>
          <Image src={require('../../../../../../assets/images/onboarding/closeSubMenu@2x.png')}
            width={px2vw(150)}
            height={px2vw(150)}
            cursor="pointer"
            onClick={() => handler.setDynamicDeliveryFeeModal(false)} 
          />
        </Row>
        {/* Delivery fee amount */}
        <Row margbottom={px2vw(120)} >
          <NormalText
            width={px2vw(700)}
            fontweight='normal'
            textalign='flex-start'
            color={preto}
            fontsize={px2vw(50)}
            padright={px2vw(60)}
          >
            Defina o valor mínimo da taxa de entrega 
          </NormalText>
          <SwitchWithInputBox isVisible={true} margleft={px2vw(10)}>
            <Row>
              <SwitchInput
                name="dynamicDeliveryFeeAmount"
                maxLength="9"
                placeholder='R$ 18,00'
                width={px2vw(260)}
                margleft={px2vw(10)}
                value={handler.dynamicDeliveryFeeAmountInputValue}
                onChange={(t) => {
                  handler.setDynamicDeliveryFeeAmountInputValue(amountMask(t.target.value))
                }}
              />
            </Row>
          </SwitchWithInputBox>
        </Row>
        {/* Max dist apply */}
        <Row margbottom={px2vw(120)} width="auto" margleft={px2vw(20)}>
          <NormalText
            width={px2vw(700)}
            fontweight='normal'
            textalign='flex-start'
            color={preto}
            fontsize={px2vw(50)}
          >
            Até qual distância o valor deve ser aplicado?
          </NormalText>
          <SwitchWithInputBox isVisible={true} margleft={px2vw(-15)}>
            <Row>
              <SwitchInput
                name='dynamicMaxDistApply'
                maxLength="2"
                placeholder="10"
                width={px2vw(200)}
                value={handler.dynamicMaxDistApplyInputValue}
                onChange={(t) => {
                  handler.setDynamicMaxDistApplyInputValue(onlyNumbers(t.target.value));
                }}
              />
              <NormalText
                fontweight='normal'
                textalign='flex-start'
                color={preto}
                fontsize={px2vw(50)}
                margtop={px2vw(25)}
              >
                km
              </NormalText>
            </Row>
          </SwitchWithInputBox>
        </Row>
        {/* Exceeded fee amount */}
        <Row margbottom={px2vw(120)} >
          <NormalText
            width={px2vw(700)}
            fontweight='normal'
            textalign='flex-start'
            color={preto}
            fontsize={px2vw(50)}
            padright={px2vw(60)}
          >
            Defina o valor por km excedente
          </NormalText>
          <SwitchWithInputBox isVisible={true} margleft={px2vw(10)}>
            <Row>
              <SwitchInput
                name="dynamicExceededFeeAmount"
                maxLength="9"
                placeholder='R$ 18,00'
                width={px2vw(260)}
                margleft={px2vw(10)}
                value={handler.dynamicExceededFeeAmountInputValue}
                onChange={(t) => {
                  handler.setDynamicExceededFeeAmountInputValue(amountMask(t.target.value))
                }}
              />
            </Row>
          </SwitchWithInputBox>
        </Row>
        {/* Free delivery at dist */}
        <Row spaceBetween width="auto">
          <SwitchWithInput key={'free-distance'} margleft={px2vw(20)}>
            <FormControlLabel
              control={
                <SwitchController
                  checked={handler.dynamicFreeDeliveryAtDistActiveInputValue}
                  type="checkbox"
                  onChange={() => {
                    handler.setDynamicFreeDeliveryAtDistActiveInputValue(!handler.dynamicFreeDeliveryAtDistActiveInputValue)
                    handler.setDynamicFreeDeliveryAtDistInputValue('');
                  }}
                />
              }
              label={
                <SwitchLabelRegularText
                  fontsize={px2vw(50)}
                  width={px2vw(500)}
                >
                  Grátis até distância determinada
                </SwitchLabelRegularText>
              }
            />
          </SwitchWithInput>
          <SwitchWithInputBox 
            isVisible={handler.dynamicFreeDeliveryAtDistActiveInputValue}
            margleft={px2vw(-45)}
          >
            <Row> 
              <SwitchInput
                name="dynamicFreeDeliveryAtDist"
                maxLength="2"
                placeholder="10"
                width={px2vw(200)}
                value={handler.dynamicFreeDeliveryAtDistInputValue}
                onChange={(t) => {
                  handler.setDynamicFreeDeliveryAtDistInputValue(
                    onlyNumbers(t.target.value)
                  );
                }}
              />
              <NormalText
                fontweight='normal'
                textalign='flex-start'
                color={preto}
                fontsize={px2vw(50)}
                margtop={px2vw(25)}
              >
                km
              </NormalText>
            </Row>
          </SwitchWithInputBox>
        </Row>
        {/* Dynamic delivery fee calculate way */}
        <NormalText
          fontweight='normal'
          textalign='flex-start'
          color={preto}
          fontsize={px2vw(50)}
          margtop={px2vw(50)}
          margbottom={px2vw(50)}
          margleft={px2vw(20)}
        >
          Defina a base para o cálculo da taxa de entrega dinâmica:
        </NormalText>
        <Row>
          <ModalCheckboxHandler type="route" checked={handler.dynamicFeeCalcTypeRouteInputValue} />
          <NormalText
            fontweight='normal'
            textalign='flex-start'
            color={preto}
            fontsize={px2vw(50)}
            margright={px2vw(200)}
          >
            Rota
          </NormalText>
          <ModalCheckboxHandler type="radius" checked={handler.dynamicFeeCalcTypeRadiusInputValue} />
          <NormalText
            fontweight='normal'
            textalign='flex-start'
            color={preto}
            fontsize={px2vw(50)}
          >
            Linha Reta
          </NormalText>
        </Row>
        <Row 
          spaceBetween 
          padleft={px2vw(50)} 
          padright={px2vw(50)} 
          margtop={px2vw(250)} 
          margbottom={px2vw(50)}
        >
          <ButtonSecondary
            type="button"
            minWidth={450}
            fontSize={50}
            height={130}
            borderRadius={20}
            color={preto}
            onClick={() => handler.handleCancelDynamicDeliveryFeeModal()}
          >
            Cancelar
          </ButtonSecondary>
          <ButtonPrimary
            minWidth={450}
            fontSize={50}
            height={130}
            borderRadius={20}
            type="button"
            onClick={() => handler.handleConfirmDynamicDeliveryFeeModal()}
          >
            Confirmar
          </ButtonPrimary>
        </Row>
      </Col>
    </ModalBackground>
  )
}