import React, { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import { FormControlLabel } from "@material-ui/core";

import {
  NormalText,
  Image,
} from '../../../../BaseScreen/styles';
import { Row, Col } from '../../../../../../styles/grid';
import { branco, preto, cinzaKuppi2 } from '../../../../../../styles/colors';
import { SwitchController, SwitchWithInput } from "../../../../Step2-DeliveryParams/Mobile/styles";
import { SwitchInput } from "../../styles";

import px2vw from '../../../../../../utils/responsiveness/px2vw';
import { amountMask } from "../../../../../../utils/masks";
import { onlyNumbersAmount } from "../../../../../../utils/utils";

export default function Complement({ complement, handler, idx }) {
  const [currComplementAmount, setCurrComplementAmount] = useState(complement.amount);

  useEffect(() => {
    if (handler.updateComplOptionAmountSuccess && handler.currEditComplOptionId === complement.id) {
      handler.handleComplClearSuccess();
      const currCategories = JSON.parse(JSON.stringify(handler.currProductComplCategories));
      currCategories[handler.opennedComplCategoryArrIdx].productComplementOptions[idx].amount = parseFloat(onlyNumbersAmount(currComplementAmount));
      handler.setCurrProductComplCategories(currCategories);
      handler.clearCurrEditComplOptionData();
    }
  }, [handler.updateComplOptionAmountSuccess]);

  return (
    <Row 
      background={branco}
      bordertop={`2px solid ${cinzaKuppi2}`}
      height={px2vw(330)}
      width="auto"
      spaceBetween
    >
      <Col width="auto" padleft={px2vw(60)} justifyCenter>
        <NormalText
          textalign='flex-start'
          margtop={px2vw(30)}
          fontweight='bold'
          color={preto}
          fontsize={px2vw(45)}
        >
          {complement.name}
        </NormalText>
        <NormalText
          textalign='flex-start'
          margtop={px2vw(20)}
          fontweight='normal'
          color={preto}
          fontsize={px2vw(45)}
          complDescription
          height={px2vw(140)}
          width={px2vw(730)}
        >
          {complement.description}
        </NormalText>
      </Col>
      <Col width="auto" padleft={px2vw(60)} padtop={px2vw(70)}>
        <Row
          onClick={() => {
            if (handler.currEditComplOptionId !== complement.id) {
              handler.setCurrEditComplOptionArrIdx(idx);
              handler.setCurrEditComplOptionId(complement.id);
              setCurrComplementAmount(amountMask(complement.amount.toFixed(2)));
            }
          }}
        >
          <SwitchInput
            name={`editProductAmount-${complement.id}`}
            maxLength="10"
            placeholder="R$ 4.50"
            width={px2vw(430)}
            margleft="0px"
            padleft={px2vw(35)}
            margbottom={px2vw(25)}
            value={handler.currEditComplOptionId !== complement.id ? amountMask(complement.amount.toFixed(2)) : currComplementAmount}
            onChange={(t) => setCurrComplementAmount(amountMask(t.target.value))}
            readonly={handler.currEditComplOptionId !== complement.id}
            cursor="pointer"
            disabled={handler.updateComplOptionAmountLoading && handler.currEditComplOptionId === complement.id}
          />
          <Row margleft={px2vw(-190)} margtop={px2vw(30)} cursor="pointer">
            <Image src={require('../../../../../../assets/images/onboarding/editButton@2x.png')}
              width={px2vw(60)}
              height={px2vw(60)}
              cursor="pointer"
            />
          </Row>
        </Row>
        {handler.currEditComplOptionId !== complement.id && (
          <Row 
            width={px2vw(430)}
            margright={px2vw(55)}
          >
            <Row padtop={px2vw(20)} width="auto" justifyEnd>
              <SwitchWithInput 
                key={'pause-product'}
                marginBottom={px2vw(40)}
                margleft={px2vw(290)}
              >
                <FormControlLabel
                  control={
                    <SwitchController
                      checked={complement.is_active}
                      type="checkbox"
                      onChange={() => {
                        handler.setCurrEditComplOptionArrIdx(idx);
                        handler.handlePauseComplOption(complement);
                      }}
                    />
                  }
                />
              </SwitchWithInput>
            </Row>
          </Row>
        )}
        {handler.currEditComplOptionId === complement.id && (
          <Row 
            width={px2vw(430)} 
            minheight={px2vw(190)} 
            margright={px2vw(55)}
            padtop={px2vw(20)}
            spaceAround
          >
            <Image
              width={px2vw(75)}
              height={px2vw(70)}
              src={require('../../../../../../assets/images/onboarding/confirm_edit@2x.png')}
              cursor="pointer"
              onClick={() => {
                if (!handler.updateComplOptionAmountLoading) {
                  if (currComplementAmount.length < 7) {
                    toast.error('Valor do produto inválido', {
                      autoClose: 6000,
                    });
                  } else {
                    handler.handleUpdateComplOptionAmount(complement.id, onlyNumbersAmount(currComplementAmount));
                  }
                }
              }}
            />
            <Image
              width={px2vw(73)}
              height={px2vw(73)}
              src={require('../../../../../../assets/images/onboarding/cancel_edit@2x.png')}
              cursor="pointer"
              onClick={() => {
                if (!handler.updateComplOptionAmountLoading) {
                  handler.clearCurrEditComplOptionData();
                }
              }}
            />
          </Row>
        )}
      </Col>
    </Row>
  )
}