import React, { useState, useEffect } from 'react';
import { Icon } from 'react-materialize';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'reactstrap';
import { toast } from 'react-toastify';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';

import px2vw from '../../utils/responsiveness/px2vw';
import RealTimeDimensions from '../../utils/responsiveness/RealTimeDimensions';

import LoadingScreenModal from '../../components/LoadingScreenModal';
import AcceptingOrdersButtons from '../../components/AcceptingOrdersButtons';
import { AcceptingOrdersBox } from '../../components/AcceptingOrdersButtons/styles';

import { PageTitle, ContentPage, LoadingBox } from '../_layouts/styles/common';

import { Row, Col } from '../../styles/grid';

import { ExpansiveCol, Image } from '../Onboarding/BaseScreen/styles';

import {
  SubtitleTextBox,
  SubtitleText,
  InfoBox,
  NewCategoryButton,
  NewCategoryButtonText,
  ButtonBox,
  IfoodImportButton,
  ButtonText,
  ButtonImage,
  ReorderButton,
  CategoryDataBox,
  CategoryHeaderBox,
  CategoryHeaderTitle,
  CategoryFooterBox,
  ProductNameBox,
  ProductNameImage,
  ProductNameText,
  ProductAmountText,
  ButtonTextRegular,
  PauseButton,
  DuplicateButton,
  EditButton,
  DeleteButton,
  ButtonTextBoldGreen,
  AddProductButton,
  ErrorText,
  SaveButton,
  SaveButtonText,
  MassEditionButton,
} from './styles';

import { preto } from '../../styles/colors';

import {
  getProductCategoriesRequest,
  categoryModalClearSuccess,
  updateCategoryStatusRequest,
  updateProductStatusRequest,
  updateProductAmountRequest,
  updateComplCategoryStatusRequest,
  updateComplOptionStatusRequest,
  updateComplOptionAmountRequest,
  updateComplClearSuccess,
  productModalClearSuccess,
  deleteCategoryProductModalClearSuccess,
  updateProductCategoriesOrdenationClearSuccess,
} from '../../store/modules/productCategories/actions';

import { updateOnboardingStepRequest } from '../../store/modules/onboarding/actions';

import { screenAmountMask } from '../../utils/masks';

import { steps } from '../Onboarding';

import CatalogOnboarding from '../Onboarding/Step3-CatalogOnboarding/Mobile';
import CatalogCategoryModal from './CatalogCategoryModal';
import CatalogProductModal from './CatalogProductModal';
import CatalogConfirmDeleteCompCategoryOrComplementModal from './CatalogConfirmDeleteCompCategoryOrComplementModal';
import CatalogCopyProductComplementModal from './CatalogCopyProductComplementModal';
import CatalogReorderCategoryComplementModal from './CatalogReorderCategoryComplementModal';
import CatalogReorderProductCategoryModal from './CatalogReorderProductCategoryModal';
import CatalogConfirmDeleteCategoryOrProductModal from './CatalogConfirmDeleteCategoryOrProductModal';
import CatalogImportIfoodCatalogModal from './CatalogImportIfoodCatalogModal';
import CatalogComplementMassEditionModal from './CatalogComplementMassEditionModal';
import { getComplementsRequest } from '../../store/modules/massHandleComplements/actions';

export default function Catalog(props) {
  const { isOnboarding = false, handler } = props;
  const [width] = RealTimeDimensions();
  const isWeb = width >= 800 ? true : false;
  const isMobileOnboarding = !isWeb && isOnboarding;
  const [opennedCategory, setOpennedCategory] = useState(-1);
  const [hasChanges, setHasChanges] = useState(false);
  const productCategories = useSelector(
    (state) => state.productCategories.productCategories
  );
  const [importIfoodCatalogMaintenance, setImportIfoodCatalogMaintenance] =
    useState(false);
  const [importIfoodCatalogModal, setImportIfoodCatalogModal] = useState(false);
  const [productCategoryInputValue, setProductCategoryInputValue] =
    useState('');
  const [handleCategoryTypeModal, setHandleCategoryTypeModal] = useState(null);
  const [handleCategoryModal, setHandleCategoryModal] = useState(false);
  const [editCategoryData, setEditCategoryData] = useState('');
  const [handleProductTypeModal, setHandleProductTypeModal] = useState(null);
  const [handleProductModal, setHandleProductModal] = useState(false);
  const [editProductData, setEditProductData] = useState('');
  const [complementsArray, setComplementsArray] = useState([]);
  const [reorderCategoryComplementModal, setReorderCategoryComplementModal] =
    useState(false);
  const [reorderComplementsCategoryArray, setReorderComplementsCategoryArray] =
    useState([]);
  const [reorderCategoryComplementIdx, setReorderCategoryComplementIdx] =
    useState(null);
  const [copyComplementIdx, setCopyComplementIdx] = useState(null);
  const [copyCategoryIdx, setCopyCategoryIdx] = useState(null);
  const [confirmDeleteComplementIdx, setConfirmDeleteComplementIdx] =
    useState(null);
  const [confirmDeleteCompCategoryIdx, setConfirmDeleteCompCategoryIdx] =
    useState(null);
  const [
    confirmDeleteCompCategoryOrComplementModal,
    setConfirmDeleteCompCategoryOrComplementModal,
  ] = useState(false);
  const [confirmDeleteCategoryIdx, setConfirmDeleteCategoryIdx] =
    useState(null);
  const [confirmDeleteProductIdx, setConfirmDeleteProductIdx] = useState(null);
  const [
    confirmDeleteCategoryOrProductModal,
    setConfirmDeleteCategoryOrProductModal,
  ] = useState(false);
  const [reorderProductCategoryModal, setReorderProductCategoryModal] =
    useState(false);
  const [reorderProductsCategoryArray, setReorderProductsCategoryArray] =
    useState([]);
  const [reorderProductCategoryIdx, setReorderProductCategoryIdx] =
    useState(null);
  const [copyProductComplementModal, setCopyProductComplementModal] =
    useState(false);
  const [complementMassEditionModal, setComplementMassEditionModal] =
    useState(false);
  const [complementActionHidden, setComplementActionHidden] = useState(true);
  const [
    deletedComplementsCategoriesArray,
    setDeletedComplementsCategoriesArray,
  ] = useState([]);
  const [deletedComplementsArray, setDeletedComplementsArray] = useState([]);
  const pageLoading = useSelector(
    (state) => state.productCategories.pageLoading
  );
  const updateCategoryStatusLoading = useSelector(
    (state) => state.productCategories.updateCategoryStatusLoading
  );
  const updateProductStatusLoading = useSelector(
    (state) => state.productCategories.updateProductStatusLoading
  );
  const updateProductAmountLoading = useSelector(
    (state) => state.productCategories.updateProductAmountLoading
  );
  const updateComplOptionAmountLoading = useSelector(
    (state) => state.productCategories.updateComplOptionAmountLoading
  );
  const updateComplCategoryStatusSuccess = useSelector(
    (state) => state.productCategories.updateComplCategoryStatusSuccess
  );
  const updateComplOptionStatusSuccess = useSelector(
    (state) => state.productCategories.updateComplOptionStatusSuccess
  );
  const updateComplOptionAmountSuccess = useSelector(
    (state) => state.productCategories.updateComplOptionAmountSuccess
  );
  const success = useSelector((state) => state.productCategories.success);
  const categoryModalSuccess = useSelector(
    (state) => state.productCategories.categoryModalSuccess
  );
  const productModalSuccess = useSelector(
    (state) => state.productCategories.productModalSuccess
  );
  const deleteCategoryProductModalSuccess = useSelector(
    (state) => state.productCategories.deleteCategoryProductModalSuccess
  );
  const updateProductsCategoryOrdenationModalSuccess = useSelector(
    (state) =>
      state.productCategories.updateProductsCategoryOrdenationModalSuccess
  );
  const updateOnboardingStepLoading = useSelector(
    (state) => state.onboarding.updateOnboardingStepLoading
  );
  const dispatch = useDispatch();

  useEffect(() => {
    // Retrieve the catalog
    dispatch(getProductCategoriesRequest());
    dispatch(getComplementsRequest());
  }, [dispatch]);

  // Handle close new/edit category modal
  const handleCloseCategoryModal = () => {
    setHandleCategoryTypeModal(null);
    setHandleCategoryModal(false);
    setEditCategoryData('');
  };

  // Handle close new/edit product modal
  const handleCloseProductModal = () => {
    setHandleProductTypeModal(null);
    setHandleProductModal(false);
    setEditProductData('');
  };

  // Handle close copy product complement modal
  const handleCloseCopyProductComplementModal = () => {
    setCopyComplementIdx(null);
    setCopyCategoryIdx(null);
    setCopyProductComplementModal(false);
  };

  // Handle new category button click
  const handleNewCategory = () => {
    setHandleCategoryTypeModal('new');
    setHandleCategoryModal(true);
  };

  // Handle category edit button click
  function handleEditCategory(category) {
    let cantEdit = false;

    // Verify if any product has a coupon with a kuppi boost active campaign
    category.products.map((product) => {
      if (!cantEdit) {
        cantEdit = product.coupon && product.coupon.boostCampaign;
      }
    });

    // If cant pause, inform error
    if (cantEdit) {
      toast.error(
        'Não é possível editar uma categoria que tenha um produto, que por sua vez tem um cupom associado a uma campanha Kuppi Boost ativa.',
        {
          autoClose: 6000,
        }
      );
    } else {
      setHandleCategoryTypeModal('edit');
      setEditCategoryData({
        id: category.id,
        name: category.name,
      });
      setHandleCategoryModal(true);
    }
  }

  // Handle close confirmation delete of category or product
  const handleCloseDeleteCategoryProduct = () => {
    setConfirmDeleteCategoryOrProductModal(false);
    setConfirmDeleteCategoryIdx(null);
    setConfirmDeleteProductIdx(null);
  };

  // Handle category delete button click
  function handleDeleteCategory(category) {
    let cantDelete = false;

    // Verify if any product has a coupon with a kuppi boost active campaign
    category.products.map((product) => {
      if (!cantDelete) {
        cantDelete = product.coupon && product.coupon.boostCampaign;
      }
    });

    // If cant delete, inform error
    if (cantDelete) {
      toast.error(
        'Não é possível excluir uma categoria que tenha um produto, que por sua vez tem um cupom associado a uma campanha Kuppi Boost ativa.',
        {
          autoClose: 6000,
        }
      );
    } else {
      setConfirmDeleteCategoryIdx(category.id);
      setConfirmDeleteCategoryOrProductModal(true);
    }
  }

  // Handle product new button click
  function handleNewProduct(categoryId) {
    setProductCategoryInputValue(categoryId);
    setHandleProductTypeModal('new');
    setHandleProductModal(true);
  }

  // Handle product edit button click
  function handleEditProduct(product) {
    if (product.coupon && product.coupon.boostCampaign) {
      toast.error(
        'Não é possível editar um produto que tenha um cupom associado a uma campanha Kuppi Boost ativa.',
        {
          autoClose: 6000,
        }
      );
    } else {
      setEditProductData(product);
      setHandleProductTypeModal('edit');
      setHandleProductModal(true);
    }
  }

  // Handle product delete button click
  function handleDeleteProduct(product) {
    if (product.coupon && product.coupon.boostCampaign) {
      toast.error(
        'Não é possível excluir um produto que tenha um cupom associado a uma campanha Kuppi Boost ativa.',
        {
          autoClose: 6000,
        }
      );
    } else {
      setConfirmDeleteProductIdx(product.id);
      setConfirmDeleteCategoryOrProductModal(true);
    }
  }

  // Handle category pause button click
  function handlePauseCategory(category) {
    let cantPause = false;

    // Verify if any product has a coupon with a kuppi boost active campaign
    category.products.map((product) => {
      if (!cantPause) {
        cantPause = product.coupon && product.coupon.boostCampaign;
      }
    });

    // If cant pause, inform error
    if (cantPause) {
      toast.error(
        'Não é possível pausar uma categoria que tenha um produto, que por sua vez tem um cupom associado a uma campanha Kuppi Boost ativa.',
        {
          autoClose: 6000,
        }
      );
    } else {
      dispatch(updateCategoryStatusRequest(category.id, !category.is_active));
    }
  }

  // Handle product pause button click
  function handlePauseProduct(product) {
    if (product.coupon && product.coupon.boostCampaign) {
      toast.error(
        'Não é possível pausar um produto que tenha um cupom associado a uma campanha Kuppi Boost ativa.',
        {
          autoClose: 6000,
        }
      );
    } else {
      dispatch(updateProductStatusRequest(product.id, !product.is_active));
    }
  }

  // Handle product amount update
  function handleUpdateProductAmount(productId, newAmount) {
    dispatch(updateProductAmountRequest(productId, newAmount));
  }

  // Handle complement category pause button click
  function handlePauseComplCategory(complCategory) {
    dispatch(
      updateComplCategoryStatusRequest(
        complCategory.id,
        !complCategory.is_active
      )
    );
  }

  // Handle complement option pause button click
  function handlePauseComplOption(complOption) {
    dispatch(
      updateComplOptionStatusRequest(complOption.id, !complOption.is_active)
    );
  }

  // Handle complement option amount update
  function handleUpdateComplOptionAmount(complOptionId, newAmount) {
    dispatch(updateComplOptionAmountRequest(complOptionId, newAmount));
  }

  // Handle clear success complements
  function handleComplClearSuccess() {
    dispatch(updateComplClearSuccess());
  }

  // Get the new catalog when update or create an category
  useEffect(() => {
    if (categoryModalSuccess) {
      handleCloseCategoryModal();
      dispatch(categoryModalClearSuccess());
      // Retrieve the catalog
      dispatch(getProductCategoriesRequest());
    }
  }, [categoryModalSuccess]);

  // Get the new catalog when update or create an product
  useEffect(() => {
    if (productModalSuccess) {
      handleCloseProductModal();
      dispatch(productModalClearSuccess());
      // Retrieve the catalog
      dispatch(getProductCategoriesRequest());
    }
  }, [productModalSuccess, dispatch]);

  // Get the catalog when delete an category or product
  useEffect(() => {
    if (deleteCategoryProductModalSuccess) {
      handleCloseDeleteCategoryProduct();
      dispatch(deleteCategoryProductModalClearSuccess());
      // Retrieve the catalog
      dispatch(getProductCategoriesRequest());
    }
  }, [deleteCategoryProductModalSuccess, dispatch]);

  // Get the catalog when update ordenation of the catalog
  useEffect(() => {
    if (updateProductsCategoryOrdenationModalSuccess) {
      // Close reorder catalog modal
      setReorderProductCategoryIdx(null);
      setReorderProductCategoryModal(false);
      dispatch(updateProductCategoriesOrdenationClearSuccess());
      // Retrieve the catalog
      dispatch(getProductCategoriesRequest());
    }
  }, [updateProductsCategoryOrdenationModalSuccess, dispatch]);

  const isSaveButtonDisabled = () => {
    return !hasChanges || updateOnboardingStepLoading;
  };

  const isSaveButtonActive = () => {
    return hasChanges;
  };

  // Handle Complete Catalog Step
  function handleCompleteCatalogStep() {
    if (steps.indexOf(handler.currOnboardingStep) < steps.indexOf('step4')) {
      dispatch(updateOnboardingStepRequest('step4'));
    } else {
      handler.setCurrOnboardingStepPage(handler.currOnboardingStep);
    }
  }

  // See if there is any category active with any product active
  useEffect(() => {
    if(productCategories.length > 0) {
      const categoryIsActive = productCategories.find((category) => {
        const productIsActive = category.products.find((product) => { 
          return product?.is_active
        })
        return category?.is_active && productIsActive?.is_active
      })
      if(categoryIsActive) {
        setHasChanges(true)
      } else setHasChanges(false)
    }
  },[productCategories])

  // Catalog openned through onboarding at mobile 
  if (isMobileOnboarding) {
    const categoryHandler = {
      categories: productCategories,
      pageLoading: pageLoading,
      opennedCategory: opennedCategory,
      updateProductAmountLoading: updateProductAmountLoading,
      updateComplOptionAmountLoading: updateComplOptionAmountLoading,
      updateComplCategoryStatusSuccess: updateComplCategoryStatusSuccess,
      updateComplOptionStatusSuccess: updateComplOptionStatusSuccess,
      updateComplOptionAmountSuccess: updateComplOptionAmountSuccess,
      updateOnboardingStepLoading: updateOnboardingStepLoading,
      importIfoodCatalogMaintenance: importIfoodCatalogMaintenance,
      handlePauseCategory: (val) => handlePauseCategory(val),
      handlePauseProduct: (val) => handlePauseProduct(val),
      handleUpdateProductAmount: (productId, newAmount) =>
        handleUpdateProductAmount(productId, newAmount),
      handlePauseComplCategory: (val) => handlePauseComplCategory(val),
      handlePauseComplOption: (val) => handlePauseComplOption(val),
      handleUpdateComplOptionAmount: (complOptionId, newAmount) =>
        handleUpdateComplOptionAmount(complOptionId, newAmount),
      handleComplClearSuccess: () => handleComplClearSuccess(),
      setOpennedCategory: (val) => setOpennedCategory(val),
      isSaveButtonActive: () => isSaveButtonActive(),
      isSaveButtonDisabled: () => isSaveButtonDisabled(),
      handleCompleteCatalogStep: () => handleCompleteCatalogStep(),
    };

    return <CatalogOnboarding handler={categoryHandler} />;
  }
  // Catalog openned through onboarding or platform at web
  else {
    const categoryHandler = {
      handleCategoryModal: handleCategoryModal,
      handleCategoryTypeModal: handleCategoryTypeModal,
      editCategoryData: editCategoryData,
      handleCloseCategoryModal: () => handleCloseCategoryModal(),
    };

    const productHandler = {
      handleProductModal: handleProductModal,
      handleProductTypeModal: handleProductTypeModal,
      editProductData: editProductData,
      productCategoryInputValue: productCategoryInputValue,
      confirmDeleteComplementIdx: confirmDeleteComplementIdx,
      confirmDeleteCompCategoryIdx: confirmDeleteCompCategoryIdx,
      complementsArray: complementsArray,
      complementActionHidden: complementActionHidden,
      productCategories: productCategories,
      deletedComplementsCategoriesArray: deletedComplementsCategoriesArray,
      deletedComplementsArray: deletedComplementsArray,
      setDeletedComplementsCategoriesArray: (val) =>
        setDeletedComplementsCategoriesArray(val),
      setDeletedComplementsArray: (val) => setDeletedComplementsArray(val),
      setConfirmDeleteCompCategoryIdx: (val) =>
        setConfirmDeleteCompCategoryIdx(val),
      setConfirmDeleteComplementIdx: (val) =>
        setConfirmDeleteComplementIdx(val),
      setConfirmDeleteCompCategoryOrComplementModal: (val) =>
        setConfirmDeleteCompCategoryOrComplementModal(val),
      setProductCategoryInputValue: (val) => setProductCategoryInputValue(val),
      setComplementsArray: (val) => setComplementsArray(val),
      setComplementActionHidden: (val) => setComplementActionHidden(val),
      setCopyProductComplementModal: (val) =>
        setCopyProductComplementModal(val),
      setReorderCategoryComplementModal: (val) =>
        setReorderCategoryComplementModal(val),
      setReorderComplementsCategoryArray: (val) =>
        setReorderComplementsCategoryArray(val),
      handleCloseProductModal: () => handleCloseProductModal(),
    };

    const confirmDeleteHandler = {
      confirmDeleteComplementIdx: confirmDeleteComplementIdx,
      confirmDeleteCompCategoryIdx: confirmDeleteCompCategoryIdx,
      complementsArray: complementsArray,
      deletedComplementsCategoriesArray: deletedComplementsCategoriesArray,
      deletedComplementsArray: deletedComplementsArray,
      confirmDeleteCompCategoryOrComplementModal:
        confirmDeleteCompCategoryOrComplementModal,
      setConfirmDeleteCompCategoryOrComplementModal: (val) =>
        setConfirmDeleteCompCategoryOrComplementModal(val),
      setConfirmDeleteCompCategoryIdx: (val) =>
        setConfirmDeleteCompCategoryIdx(val),
      setConfirmDeleteComplementIdx: (val) =>
        setConfirmDeleteComplementIdx(val),
      setComplementsArray: (val) => setComplementsArray(val),
      setComplementActionHidden: (val) => setComplementActionHidden(val),
    };

    const copyProductComplementHandler = {
      productCategories: productCategories,
      copyProductComplementModal: copyProductComplementModal,
      copyCategoryIdx: copyCategoryIdx,
      copyComplementIdx: copyComplementIdx,
      complementsArray: complementsArray,
      setCopyComplementIdx: (idxComp) => setCopyComplementIdx(idxComp),
      setCopyCategoryIdx: (idx) => setCopyCategoryIdx(idx),
      setComplementsArray: (val) => setComplementsArray(val),
      setCopyProductComplementModal: (val) =>
        setCopyProductComplementModal(val),
      setComplementActionHidden: (val) => setComplementActionHidden(val),
      handleCloseCopyProductComplementModal: () =>
        handleCloseCopyProductComplementModal(),
    };

    const reorderCategoryComplementHandler = {
      reorderCategoryComplementModal: reorderCategoryComplementModal,
      reorderCategoryComplementIdx: reorderCategoryComplementIdx,
      reorderComplementsCategoryArray: reorderComplementsCategoryArray,
      setReorderCategoryComplementIdx: (val) =>
        setReorderCategoryComplementIdx(val),
      setReorderCategoryComplementModal: (val) =>
        setReorderCategoryComplementModal(val),
      setComplementsArray: (val) => setComplementsArray(val),
      setReorderComplementsCategoryArray: (val) =>
        setReorderComplementsCategoryArray(val),
    };

    const reorderProductCategoryHandler = {
      reorderProductCategoryModal: reorderProductCategoryModal,
      reorderProductCategoryIdx: reorderProductCategoryIdx,
      reorderProductsCategoryArray: reorderProductsCategoryArray,
      setReorderProductCategoryIdx: (val) => setReorderProductCategoryIdx(val),
      setReorderProductCategoryModal: (val) =>
        setReorderProductCategoryModal(val),
      setReorderProductsCategoryArray: (val) =>
        setReorderProductsCategoryArray(val),
    };

    const confirmDeleteCategoryOrProductHandler = {
      confirmDeleteCategoryOrProductModal: confirmDeleteCategoryOrProductModal,
      confirmDeleteCategoryIdx: confirmDeleteCategoryIdx,
      confirmDeleteProductIdx: confirmDeleteProductIdx,
      setConfirmDeleteCategoryOrProductModal: (val) =>
        setConfirmDeleteCategoryOrProductModal(val),
    };

    const importIfoodCatalogHandler = {
      isOnboarding: isOnboarding,
      productCategories: productCategories,
      importIfoodCatalogModal: importIfoodCatalogModal,
      importIfoodCatalogMaintenance: importIfoodCatalogMaintenance,
      setImportIfoodCatalogModal: (val) => setImportIfoodCatalogModal(val),
    };

    const complementsMassEditionHandler = {
      onClose: () => setComplementMassEditionModal(false)
    };

    const loadingPage = (
      <ContentPage>
        <Col margleft="20px" height="104vh">
          <Col alignCenter justifyCenter>
            <Spinner
              style={{
                width: '1.8rem',
                height: '1.8rem',
                color: preto,
              }}
            />
          </Col>
        </Col>
      </ContentPage>
    );

    const errorPage = (
      <ContentPage>
        <Col margleft={px2vw(20)} height="100vh">
          <Row alignCenter justifyCenter>
            {/* TODO: Ajustar tamanho do icone */}
            <Icon large>sentiment_very_dissatisfied</Icon>
            <ErrorText>
              Ocorreu um erro ao carregar o catálogo. Tente novamente.
            </ErrorText>
          </Row>
        </Col>
      </ContentPage>
    );

    const webContent = (
      <>
        <ContentPage>
          <Row height="auto" spaceBetween margbottom={px2vw(20)}>
            <PageTitle>Catálogo</PageTitle>
            {!isOnboarding && (
              <Row width="auto" margtop={px2vw(-20)} margright={px2vw(40)}>
                <AcceptingOrdersBox>
                  <Row margbottom={px2vw(5)}>
                    <AcceptingOrdersButtons />
                  </Row>
                </AcceptingOrdersBox>
              </Row>
            )}
          </Row>
          <SubtitleTextBox>
            <SubtitleText>
              O catálogo é sua vitrine. Aqui você pode criar categorias, inserir
              produtos, complementos e opcionais, definir disponibilidade e
              alterar preços. Comece adicionando uma categoria aos seus
              produtos, como por exemplo, pratos principais, pizzas ou até mesmo
              bebidas. Use palavras que ajudem a aguçar o apetite dos clientes,
              isso ajuda a atrair os clientes.
            </SubtitleText>
          </SubtitleTextBox>
          {/* Actions menu */}
          <InfoBox>
            <NewCategoryButton
              id="catalogAddNewCategory"
              onClick={handleNewCategory}
            >
              <NewCategoryButtonText>Adicionar categoria</NewCategoryButtonText>
            </NewCategoryButton>
            <MassEditionButton
              onClick={() => setComplementMassEditionModal(true)}
            >
              <ButtonBox>
                <ButtonImage
                  src={require('../../assets/images/edit/edit.png')}
                />
                <ButtonText>
                  Edição em Massa <br /> de Complementos
                </ButtonText>
              </ButtonBox>
            </MassEditionButton>
            <ReorderButton
              onClick={() => {
                if (
                  productCategories.length > 1 ||
                  (productCategories[0] &&
                    productCategories[0].products &&
                    productCategories[0].products.length > 1)
                ) {
                  setReorderProductsCategoryArray(
                    JSON.parse(JSON.stringify(productCategories))
                  );

                  setReorderProductCategoryModal(true);
                } else {
                  toast.error(
                    'Não existem categorias/produtos para serem ordenados.',
                    {
                      autoClose: 6000,
                    }
                  );
                }
              }}
            >
              <ButtonBox>
                <ButtonImage
                  src={require('../../assets/images/arrows/arrow_reorder.png')}
                />
                <ButtonText>
                  Reordenar <br /> Catálogo
                </ButtonText>
              </ButtonBox>
            </ReorderButton>
            <IfoodImportButton
              onClick={() => {
                setImportIfoodCatalogModal(true);
              }}
            >
              <ButtonBox>
                <ButtonImage
                  src={require('../../assets/images/arrows/arrow_cicle.png')}
                />
                <ButtonText>
                  Importar Cardápio <br /> do Ifood
                </ButtonText>
              </ButtonBox>
            </IfoodImportButton>
          </InfoBox>
          {/* Categories */}
          {productCategories.length > 0 &&
            productCategories.map((category) => (
              <CategoryDataBox key={`category-${category.id}`}>
                {/* Category header */}
                <CategoryHeaderBox>
                  <Row>
                    <Row
                      cursor="pointer"
                      onClick={() => {
                        setOpennedCategory(
                          opennedCategory === category.id ? -1 : category.id
                        );
                      }}
                    >
                      <Row
                        width="auto"
                        margtop={px2vw(20)}
                        margright={px2vw(30)}
                      >
                        <Image
                          src={
                            opennedCategory === category.id
                              ? require('../../assets/images/arrows/arrow_top@2x.png')
                              : require('../../assets/images/arrows/arrow_under@2x.png')
                          }
                          width={px2vw(25)}
                          height={px2vw(10)}
                        />
                      </Row>
                      <Row justifyStart>
                        <CategoryHeaderTitle>
                          {category.name}
                        </CategoryHeaderTitle>
                      </Row>
                    </Row>
                    <Row justifyEnd margtop={px2vw(6)} margright={px2vw(20)}>
                      <PauseButton
                        width={category.is_active ? px2vw(160) : px2vw(190)}
                        onClick={() => handlePauseCategory(category)}
                        disabled={updateCategoryStatusLoading}
                      >
                        <ButtonBox>
                          <ButtonImage
                            active={!category.is_active}
                            src={require('../../assets/images/pause/pause_green.png')}
                          />
                          {category.is_active ? (
                            <ButtonTextRegular greenColor>
                              Pausar Categoria
                            </ButtonTextRegular>
                          ) : (
                            <ButtonTextRegular yellowColor>
                              Despausar Categoria
                            </ButtonTextRegular>
                          )}
                        </ButtonBox>
                      </PauseButton>
                      {false && (
                        <DuplicateButton margleft="30px" onClick={() => {}}>
                          <ButtonBox>
                            <ButtonImage
                              src={require('../../assets/images/duplicate/duplicate.png')}
                            />
                            <ButtonTextRegular>Duplicar</ButtonTextRegular>
                          </ButtonBox>
                        </DuplicateButton>
                      )}
                      <EditButton
                        margleft={px2vw(20)}
                        onClick={() => handleEditCategory(category)}
                      >
                        <ButtonBox>
                          <ButtonImage
                            src={require('../../assets/images/edit/edit.png')}
                          />
                          <ButtonTextRegular>Editar</ButtonTextRegular>
                        </ButtonBox>
                      </EditButton>
                      <DeleteButton
                        margleft={px2vw(20)}
                        onClick={() => {
                          handleDeleteCategory(category);
                        }}
                      >
                        <ButtonBox>
                          <ButtonImage
                            src={require('../../assets/images/delete/delete_black.png')}
                          />
                          <ButtonTextRegular>Excluir</ButtonTextRegular>
                        </ButtonBox>
                      </DeleteButton>
                    </Row>
                  </Row>
                </CategoryHeaderBox>
                {/* Category itens */}
                <ExpansiveCol isActive={opennedCategory === category.id}>
                  {/* Items */}
                  <Table>
                    <TableBody>
                      {category.products.map((product) => (
                        <TableRow hover key={product.id}>
                          <Row height={px2vw(50)} width={px2vw(1055)}>
                            <Row
                              alignCenter
                              justifyStart
                              padleft={px2vw(25)}
                              width={px2vw(870)}
                              margright={px2vw(20)}
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                handleEditProduct(product);
                              }}
                            >
                              <ProductNameBox>
                                {product.file_id && (
                                  <ProductNameImage
                                    src={require('../../assets/images/camera/camera.png')}
                                  />
                                )}
                                {!product.file_id && (
                                  <ProductNameImage
                                    noImage
                                    src={require('../../assets/images/camera/no_camera.png')}
                                  />
                                )}
                                <Row justifyCenter alignCenter>
                                  <ProductNameText>
                                    {product.name}
                                  </ProductNameText>
                                </Row>
                              </ProductNameBox>
                            </Row>
                            <Row alignCenter justifyStart width={px2vw(200)}>
                              <ProductAmountText>
                                {parseFloat(product.amount.toFixed(2)) !==
                                  parseFloat(0) &&
                                  screenAmountMask(product.amount.toFixed(2))}
                              </ProductAmountText>
                            </Row>
                            <Row alignCenter justifyEnd margright={px2vw(20)}>
                              <PauseButton
                                width={
                                  product.is_active ? px2vw(160) : px2vw(190)
                                }
                                onClick={() => handlePauseProduct(product)}
                                disabled={updateProductStatusLoading}
                              >
                                <ButtonBox>
                                  <ButtonImage
                                    active={!product.is_active}
                                    src={require('../../assets/images/pause/pause_green.png')}
                                  />
                                  {product.is_active ? (
                                    <ButtonTextRegular greenColor>
                                      Pausar Produto
                                    </ButtonTextRegular>
                                  ) : (
                                    <ButtonTextRegular yellowColor>
                                      Despausar Produto
                                    </ButtonTextRegular>
                                  )}
                                </ButtonBox>
                              </PauseButton>
                              {false && (
                                <DuplicateButton
                                  margleft={px2vw(30)}
                                  onClick={() => {}}
                                >
                                  <ButtonBox>
                                    <ButtonImage
                                      src={require('../../assets/images/duplicate/duplicate.png')}
                                    />
                                    <ButtonTextRegular>
                                      Duplicar
                                    </ButtonTextRegular>
                                  </ButtonBox>
                                </DuplicateButton>
                              )}
                              <EditButton
                                margleft={px2vw(20)}
                                onClick={() => {
                                  handleEditProduct(product);
                                }}
                              >
                                <ButtonBox>
                                  <ButtonImage
                                    src={require('../../assets/images/edit/edit.png')}
                                  />
                                  <ButtonTextRegular>Editar</ButtonTextRegular>
                                </ButtonBox>
                              </EditButton>
                              <DeleteButton
                                margleft={px2vw(20)}
                                onClick={() => {
                                  handleDeleteProduct(product);
                                }}
                              >
                                <ButtonBox>
                                  <ButtonImage
                                    src={require('../../assets/images/delete/delete_black.png')}
                                  />
                                  <ButtonTextRegular>Excluir</ButtonTextRegular>
                                </ButtonBox>
                              </DeleteButton>
                            </Row>
                          </Row>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  {/* Category footer */}
                  <CategoryFooterBox>
                    <AddProductButton
                      id="catalogAddNewProductButton"
                      onClick={() => {
                        handleNewProduct(category.id);
                      }}
                    >
                      <ButtonBox>
                        <ButtonImage
                          src={require('../../assets/images/plus/plus_green.png')}
                        />
                        <ButtonTextBoldGreen>
                          Adicionar produto
                        </ButtonTextBoldGreen>
                      </ButtonBox>
                    </AddProductButton>
                  </CategoryFooterBox>
                </ExpansiveCol>
              </CategoryDataBox>
            ))}
          {productCategories.length === 0 && (
            <Col height="40vh">
              <Row alignCenter justifyCenter>
                {/* TODO: Ajustar tamanho do icone */}
                <Icon large>sentiment_very_dissatisfied</Icon>
                <ErrorText>
                  Você não possui nenhum produto no seu catálogo.
                </ErrorText>
              </Row>
            </Col>
          )}
          {isOnboarding && (
            <Row
              height="auto"
              justifyEnd
              margbottom={px2vw(40)}
              margtop={px2vw(40)}
            >
              <SaveButton
                id="catalogOnboardingSaveButton"
                hasChanges={isSaveButtonActive()}
                type="button"
                disabled={isSaveButtonDisabled()}
                onClick={() => handleCompleteCatalogStep()}
              >
                {updateOnboardingStepLoading ? (
                  <LoadingBox>
                    <Spinner
                      style={{
                        width: '1.8rem',
                        height: '1.8rem',
                        color: preto,
                      }}
                    />{' '}
                  </LoadingBox>
                ) : (
                  <SaveButtonText hasChanges={isSaveButtonActive()}>
                    Avançar
                  </SaveButtonText>
                )}
              </SaveButton>
            </Row>
          )}
        </ContentPage>
        {/* Loading screen modal */}
        {(updateCategoryStatusLoading || updateProductStatusLoading) && (
          <LoadingScreenModal />
        )}
        {/* Handle category modal (new and edit) */}
        {handleCategoryModal && (
          <CatalogCategoryModal handler={categoryHandler} />
        )}
        {/* Handle product modal (new and edit) */}
        {handleProductModal && <CatalogProductModal handler={productHandler} />}
        {/* Confirm delete category or product modal */}
        {confirmDeleteCategoryOrProductModal && (
          <CatalogConfirmDeleteCategoryOrProductModal
            handler={confirmDeleteCategoryOrProductHandler}
          />
        )}
        {/* Confirm delete complement category or complement modal */}
        {confirmDeleteCompCategoryOrComplementModal && (
          <CatalogConfirmDeleteCompCategoryOrComplementModal
            handler={confirmDeleteHandler}
          />
        )}
        {/* Reorder category and/or complement modal */}
        {reorderCategoryComplementModal && (
          <CatalogReorderCategoryComplementModal
            handler={reorderCategoryComplementHandler}
          />
        )}
        {/* Copy product complements modal */}
        {copyProductComplementModal && (
          <CatalogCopyProductComplementModal
            handler={copyProductComplementHandler}
          />
        )}
        {/* Import ifood catalog modal */}
        {importIfoodCatalogModal && (
          <CatalogImportIfoodCatalogModal handler={importIfoodCatalogHandler} />
        )}
        {/* Reorder product and/or category modal */}
        {reorderProductCategoryModal && (
          <CatalogReorderProductCategoryModal
            handler={reorderProductCategoryHandler}
          />
        )}
        {complementMassEditionModal && (
          <CatalogComplementMassEditionModal
            handler={complementsMassEditionHandler}
          />
        )}
      </>
    );

    if (pageLoading) {
      return loadingPage;
    }
    if (!success) {
      return errorPage;
    }
    return webContent;
  }
}
