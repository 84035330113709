import React from 'react';

import { Row } from '../../../../../../styles/grid';
import { 
  ImageBox,
  Image,
  MostRecommendedTextBox,
} from '../styles';

import px2vw from '../../../../../../utils/responsiveness/px2vw';

import { 
  MostRecommendedText, 
} from '../constants';

export default function MostRecommendedBox({ isMobile = false}) {
  return (
    <Row alignCenter margbottom={isMobile ? px2vw(10) : px2vw(10)} margtop={isMobile ? px2vw(60) : '0'} width="auto">
      <ImageBox
        width={isMobile ? px2vw(129) : px2vw(29)}
        height={isMobile ? px2vw(124) : px2vw(24)}
        margright={isMobile ? px2vw(108) : px2vw(8)}
      >
        <Image
          src={require('../../../../../../assets/images/payment-methods/more_choosed_trophy.png')}
          alt="Trophy"
          width="100%"
          height="100%"
        />
      </ImageBox>
      <MostRecommendedTextBox isMobile={isMobile}>
        {MostRecommendedText}
      </MostRecommendedTextBox>
      <ImageBox
        width={isMobile ? px2vw(129) : px2vw(29)}
        height={isMobile ? px2vw(124) : px2vw(24)}
        margleft={isMobile ? px2vw(108) : px2vw(8)}
      >
        <Image
          src={require('../../../../../../assets/images/payment-methods/more_choosed_trophy.png')}
          alt="Trophy"
          width="100%"
          height="100%"
        />
      </ImageBox>
    </Row>
  );
}
