import axios from 'axios';

export default async function URLToBase64(url, callback) {
  const reader = new FileReader();
  await axios({
    method: 'get',
    url,
    responseType: 'blob',
  }).then((response) => {
    reader.onloadend = () => {
      callback(reader.result);
    };
    reader.readAsDataURL(response.data);
  });
}
