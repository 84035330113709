import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';

import { cinzaKuppi1, cinzaKuppi4 } from '../../styles/colors';

export const TermsOfUseTitle = styled.div`
  font-size: 40px;
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.72px;
  margin-bottom: 40px;
`;

export const TermsOfUseContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${cinzaKuppi1};
  border: 1px solid ${cinzaKuppi4};
  border-radius: 50px;
  width: 80%;
  height: 80%;
  padding: 40px;
`;

export const TermsOfUseContentBox = styled(ReactMarkdown)`
  overflow-wrap: break-word;
  height: 100%;
  overflow-y: auto;
`;

export const TermsOfUseError = styled.div`
  font-size: 20px;
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.72px;
  margin-bottom: 40px;
`;
