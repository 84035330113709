import { Row } from 'react-bootstrap';
import styled from 'styled-components';
import {
  branco,
  verdeKuppi2,
  verdeKuppi25,
} from '../../styles/colors';

import px2vw from '../../utils/responsiveness/px2vw';

export const AppContainer = styled.div`
  margin-left: 30px;
  display: flex;
  background: ${branco};
  height: 100%;
  width: 82.2%;
  position: fixed;
  bottom: 0;
  right: ${px2vw(0.1)};
  padding-top: ${px2vw(10)};
  box-shadow: 0 0 0 ${px2vw(10)} ${verdeKuppi25} inset;
  z-index: 50;
  ${(props) => `
    min-height: ${props.minheight};
  `};

  ${(props) =>
    props.margleft &&
    `
    margin-left: ${props.margleft};
  `};
`;

export const AppContent = styled(Row)`
  position: absolute;
  width: 100%;
  height: 100%;
`;

export const Modal = styled.div`
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  right: 0;
  border-radius: ${px2vw(12)};
  padding: 0 ${px2vw(38)};
  background: ${branco};
  max-width: ${px2vw(360)};
  min-height: ${px2vw(130)};
  transform: translate(-40%, -60%);
  z-index: 10;
`;

export const Overlay = styled(Row)`
  position: absolute;
  height: 100%;
  width: 100vw;
  top: 0;
  left: 0;
  right: 0;
  background: #0006;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  z-index: 9;
  box-sizing: content-box;
`;

export const RobotImg = styled.img`
  height: ${px2vw(140)};
  position: absolute;
  left: ${px2vw(-54)};
  top: ${px2vw(25)};
`;

export const Span = styled.span`
  font-size: ${px2vw(11)};
  font-weight: 600;
  text-align: center;
  ${(props) => props.size && `font-size: ${props.size};`}
  ${(props) => props.textAlign && `text-align: ${props.textAlign};`}
  ${(props) => props.color && `color: ${props.color};`}
  ${(props) => props.letterSpacing && `letter-spacing: ${props.letterSpacing};`}
  ${(props) => props.lineHeight && `line-height: ${props.lineHeight};`}
  ${(props) => props.fontWeight && `font-weight: ${props.fontWeight};`}
  ${(props) => props.p && `padding: ${props.p};`}
  ${(props) => props.pb && `padding-bottom: ${props.pb};`}
  ${(props) => props.pl && `padding-left: ${props.pl};`}
  ${(props) => props.pr && `padding-right: ${props.pr};`}
  ${(props) => props.pt && `padding-top: ${props.pt};`}
  ${(props) => props.m && `margin: ${props.m};`}
  ${(props) => props.mb && `margin-bottom: ${props.mb};`}
  ${(props) => props.ml && `margin-left: ${props.ml};`}
  ${(props) => props.mr && `margin-right: ${props.mr};`}
  ${(props) => props.mt && `margin-top: ${props.mt};`}
  ${(props) => props.maxWidth && `max-width: ${props.maxWidth};`}
  ${(props) => props.overflow && `overflow: ${props.overflow};`}
  ${(props) => props.textOverflow && `text-overflow: ${props.textOverflow};`}
  ${(props) => props.whiteSpace && `white-space: ${props.whiteSpace};`}
`;

export const Button = styled.button`
  background: ${branco};
  min-width: ${px2vw(100)};
  height: ${px2vw(28)};
  border-radius: ${px2vw(5)};
  margin: ${px2vw(5)};
  font-weight: 700;
  margin-top: ${px2vw(10)};
  ${(props) =>
    props.primary &&
    `
    background: ${verdeKuppi2};
    color: ${branco};
    border: none;
    `}

    ${(props) =>
      props.clearMessage &&
      `
      background: ${verdeKuppi2};
      color: ${branco};
      border: none;
      border-radius: ${px2vw(3)};
      padding: 0 ${px2vw(5)};
      `}
`;

