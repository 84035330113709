import styled from 'styled-components';

import {
  branco,
  cinzaKuppi4,
  preto,
  verdeKuppi2,
} from '../../../../../styles/colors';
import { Col } from '../../../../../styles/grid';

import px2vw from '../../../../../utils/responsiveness/px2vw';

export const TypeBox = styled.div`
  width: 98%;
  display: flex;
  flex-direction: row;
  font-size: ${px2vw(11)};
  height: ${px2vw(105)};
  border-radius: 8px;
  border: 2px solid ${(props) => (props.selected ? verdeKuppi2 : cinzaKuppi4)};
  padding: ${px2vw(10)} ${px2vw(10)};
  padding-top: ${px2vw(20)};
  margin-top: ${px2vw(5)};
  margin-left: auto;
  margin-right: auto;
  position: relative;
  cursor:pointer;
`;

export const ResumeBox = styled(TypeBox)`
  width: auto;
  height: auto;
  flex-direction: column;
  margin: ${px2vw(10)} auto;
  padding: ${px2vw(10)};
`;

export const Input = styled.input`
  border: 1px solid ${preto};
  text-align: center;
  padding: ${px2vw(2)} ${px2vw(4)};
  -moz-appearance: textfield;
  width: ${px2vw(60)};
  border-radius: ${px2vw(6)};
  height: ${px2vw(25)};
  margin: ${px2vw(5)} auto;
  margin-top: ${px2vw(8)};
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const OverFlowY = styled(Col)`
  ::-webkit-scrollbar {
    width: 0.5em;
    margin-left: 10px;
  }
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${verdeKuppi2};
    outline: 1px solid ${verdeKuppi2};
    border-radius: 15px;
  }
`;

export const RadioButton = styled.div`
    border-radius: 50%;
    width: ${px2vw(10)};
    height; ${px2vw(10)};
    background: ${branco};
    padding:${px2vw(4)};
    border: ${px2vw(5)} solid ${(props) =>
  props.selected ? verdeKuppi2 : cinzaKuppi4};
    margin: 0 auto;
    position:absolute;
    right: -${px2vw(0)};
    top: -${px2vw(10)};
`;

export const CustonInputRange = styled.div`
  width: 73%;

  .input-range__track.input-range__track--background {
    background: #dbdbdb;
  }

  .input-range__track.input-range__track--active {
    background: ${verdeKuppi2};
  }

  .input-range__track.input-range__track--disabled {
    background: #dbdbdb;
  }

  .input-range__slider {
    background: ${verdeKuppi2};
    border: 1px solid ${verdeKuppi2};
  }

  .input-range__label-container {
    display: none;
  }

  .input-range--disabled .input-range__slider {
    background: #dbdbdb;
    border: 1px solid #dbdbdb;
  }

  .input-range--disabled .input-range__track {
    background: #dbdbdb;
  }

  ${(props) =>
    props.disabled &&
    `
    .text {
      color: #dbdbdb;
    }

    .input-range__track.input-range__track--background {
      background: #dbdbdb;
    }

    .input-range__track.input-range__track--active {
      background: #dbdbdb;
    }

    .input-range__label-container {
      display: none;
    }
  `}
`;
