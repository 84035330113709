export function getSalesRecoverDataRequest() {
  return {
    type: 'salesRecover/GET_SALES_RECOVER_DATA_REQUEST',
  };
}

export function getSalesRecoverDataFailure() {
  return {
    type: 'salesRecover/GET_SALES_RECOVER_DATA_FAILURE',
  };
}

export function getSalesRecoverDataSuccess(data) {
  return {
    type: 'salesRecover/GET_SALES_RECOVER_DATA_SUCCESS',
    payload: data,
  };
}

export function updateSalesRecoverConfigsRequest() {
  return {
    type: '@salesRecover/UPDATE_SALES_RECOVER_CONFIG_DATA',
  };
}

export function updateSalesRecoverConfigsSuccess(data) {
  return {
    type: '@salesRecover/UPDATE_SALES_RECOVER_CONFIG_DATA_SUCCESS',
    payload: data,
  };
}

export function updateSalesRecoverConfigsFailure() {
  return {
    type: '@salesRecover/UPDATE_SALES_RECOVER_CONFIG_DATA_FAILURE',
  };
}
