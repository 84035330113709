import React, { useState } from 'react';
import { FormControlLabel } from '@material-ui/core';

import {
  NormalText,
  Image,
  ExpansiveCol,
} from '../../../BaseScreen/styles';
import { Row, Col } from '../../../../../styles/grid';
import {
  preto,
  cinzaKuppi1,
  cinzaKuppi9,
} from '../../../../../styles/colors';
import {
  SwitchController,
  SwitchWithInput,
} from '../../../Step2-DeliveryParams/Mobile/styles';

import px2vw from '../../../../../utils/responsiveness/px2vw';

import Product from './Product'

export default function Category({ handler, category }) {
  const [currEditProductId, setCurrEditProductId] = useState(null);
  const [currEditProductAmount, setCurrEditProductAmount] = useState(null);

  function handleCategoryClick(categoryId) {
    handler.setOpennedCategory(handler.opennedCategory === categoryId ? -1 : categoryId);
    clearCurrEditProductData();
  }

  function clearCurrEditProductData() {
    setCurrEditProductId(null);
    setCurrEditProductAmount(null);
  }

  const productHandler = {
    ...handler,
    currEditProductId: currEditProductId,
    currEditProductAmount: currEditProductAmount,
    setCurrEditProductId: (val) => setCurrEditProductId(val),
    setCurrEditProductAmount: (val) => setCurrEditProductAmount(val),
    clearCurrEditProductData: () => clearCurrEditProductData(),
  }

  return (
    <Col
      borderbottom={`2px solid ${cinzaKuppi9}`}
    >
      <Row>
        <Row
          background={cinzaKuppi1}
          padleft={px2vw(100)}
          justifyStart
          onClick={() => handleCategoryClick(category.id)}
          cursor="pointer"
        >
          <Row width={px2vw(100)} margtop={px2vw(60)} margright={px2vw(100)}>
            <Image
              src={handler.opennedCategory === category.id ? require('../../../../../assets/images/arrows/arrow_top@2x.png') : require('../../../../../assets/images/arrows/arrow_under@2x.png')}
              width={px2vw(85)}
              height={px2vw(40)}
            />
          </Row>
          <NormalText
            textalign='flex-start'
            margtop={px2vw(40)}
            fontweight="bold"
            color={preto}
            fontsize={px2vw(55)}
            width="100%"
          >
            {category.name}
          </NormalText>
        </Row>
        <Row justifyEnd width="auto">
          <SwitchWithInput
            key={'pause-category'}
            marginBottom={px2vw(20)}
            margtop={px2vw(30)}
          >
            <FormControlLabel
              control={
                <SwitchController
                  checked={category.is_active}
                  type="checkbox"
                  onChange={() => handler.handlePauseCategory(category)}
                />
              }
            />
          </SwitchWithInput>
        </Row>
      </Row>
      <ExpansiveCol isActive={handler.opennedCategory === category.id}>
        {category.products.map((product) => {
          return (
            <Product
              product={product}
              handler={productHandler}
            />
          );
        })}
      </ExpansiveCol>
    </Col>
  );
}
