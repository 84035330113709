import { takeLatest, call, put, all, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { apiAnunciante } from '../../../services/api';
import {
  getSalesRecoverDataFailure,
  getSalesRecoverDataSuccess,
  updateSalesRecoverConfigsFailure,
  updateSalesRecoverConfigsSuccess,
} from './actions';
import { logout } from '../auth/actions';

const userData = (state) => state.user.user;
const salesRecoverData = (state) => state.salesRecover.salesRecoverData;

export function* getSalesRecoverDataRequest() {
  try {
    const user = yield select(userData);

    const request = yield call(
      apiAnunciante.get,
      `/user-info/${user.advertiserInfo.id}/sales-recover`
    );

    yield put(getSalesRecoverDataSuccess(request.data));
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(getSalesRecoverDataFailure());
      toast.error(
        `Ocorreu um erro ao recuperar as configurações do recuperador de vendas. Carregue a página novamente!`,
        {
          autoClose: 6000,
        }
      );
    }
  }
}

export function* updateSalesRecoverConfigsRequest() {
  try {
    const user = yield select(userData);
    const salesRecover = yield select(salesRecoverData);

    const request = yield call(
      apiAnunciante.put,
      `/user-info/${user.advertiserInfo.id}/sales-recover`,
      { is_active: !salesRecover.is_active }
    );

    yield put(updateSalesRecoverConfigsSuccess(request.data));
  } catch (err) {   
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(updateSalesRecoverConfigsFailure());
      toast.error(
        `Ocorreu um erro ao recuperar as configurações do recuperador de vendas. Carregue a página novamente!`,
        {
          autoClose: 6000,
        }
      );
    }
  }
}

export default all([
  takeLatest('salesRecover/GET_SALES_RECOVER_DATA_REQUEST', getSalesRecoverDataRequest),
  takeLatest('@salesRecover/UPDATE_SALES_RECOVER_CONFIG_DATA', updateSalesRecoverConfigsRequest),
]);
