import React from 'react';

import { Row } from '../../../../../../styles/grid';
import {
  PageTitle,
  PageText,
  TermsLink,
} from '../../../../styles';

import px2vw from '../../../../../../utils/responsiveness/px2vw';

import { 
  PageTextFirstPayment, 
  PageTextRenewPayment, 
  PageTitleFirstPayment, 
  PageTitleRenewPayment
} from '../constants';
import {
  PageTextTermsUseText1, 
  PageTextTermsUseText2,
} from '../../constants';

export default function HeaderDefaultSignatureWeb({ handler }) {
  return (
    <>
      <PageTitle>
        {handler.isFirstPayment && PageTitleFirstPayment}
        {handler.isRenewPayment && PageTitleRenewPayment}
      </PageTitle>
      <Row
        justifyStart
        margtop={px2vw(20)}
        margbottom={px2vw(20)}
        width={px2vw(650)}
      >
        <PageText>
          {/* First payment */}
          {handler.isFirstPayment && PageTextFirstPayment}
          {/* Renew payment */}
          {handler.isRenewPayment && PageTextRenewPayment.replace('$$VALIDITY$$', handler.signaturePaymentData?.start_validity_at)}
          {/* First and Renew */}
          <TermsLink to={handler.termsOfUseLink} target="_blank">
            {PageTextTermsUseText1}
          </TermsLink>
          {PageTextTermsUseText2}
        </PageText>
      </Row>
    </>
  );
}
