import React from 'react';
import { Spinner } from 'reactstrap';

import { ModalBackground } from '../Modal/styles';

import { Col } from '../../styles/grid';

import { preto } from '../../styles/colors';

export default function LoadingScreenModal() {
  return (
    <ModalBackground isVisible>
      <Col margleft="200px">
        <Col alignCenter justifyCenter>
          <Spinner
            style={{
              width: '1.8rem',
              height: '1.8rem',
              color: preto,
            }}
          />
        </Col>
      </Col>
    </ModalBackground>
  );
}
