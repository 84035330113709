import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Radio } from '@material-ui/core';

import { Text } from '../../../../styles/common';
import { Col, Row } from '../../../../styles/grid';
import { branco, preto } from '../../../../styles/colors';
import { PrimaryButton, SecundaryButton } from '../styles';

import px2vw from '../../../../utils/responsiveness/px2vw';

import {
  deleteComplementsFailure,
  deleteComplementsRequest,
  updateComplementStatusFailure,
  updateComplementStatusRequest,
} from '../../../../store/modules/massHandleComplements/actions';

export default function MassActionBox({ list, onClose, onSuccess }) {
  const dispatch = useDispatch();
  const [actionType, setActionType] = useState('');
  const {
    updateComplementStatusLoading,
    updateComplementStatusSuccess,
    deleteComplementLoading,
    deleteComplementSuccess,
  } = useSelector((state) => state.massHandleComplements);

  useEffect(() => {
    if (updateComplementStatusSuccess) {
      onSuccess();
      dispatch(updateComplementStatusFailure());
    }
  }, [updateComplementStatusSuccess]);

  useEffect(() => {
    if (deleteComplementSuccess) {
      onSuccess();
      dispatch(deleteComplementsFailure());
    }
  }, [deleteComplementSuccess]);

  const loadingValidation = deleteComplementLoading || updateComplementStatusLoading

  const handleRequest = () => {
    if (actionType === '') {
      return;
    } else if (actionType === 'delete') {
      dispatch(deleteComplementsRequest(list));
    } else {
      const status = actionType === 'pause' ? false : true;
      dispatch(updateComplementStatusRequest(list, status));
    }
  };
  return (
    <Col
      width={px2vw(350)}
      minheight={px2vw(250)}
      height="auto"
      background={branco}
      borderRadius={px2vw(10)}
      pad={`${px2vw(25)} ${px2vw(30)}`}
      justifyStart
    >
      <Row margbottom={px2vw(15)} alignCenter justifyStart height="auto">
        <Text size={px2vw(18)} color={preto} alignStart>
          Qual ação deseja realizar?
        </Text>

        <img
          style={{ width: px2vw(21), cursor: 'pointer' }}
          onClick={onClose}
          className="ml-auto"
          src={require('../../../../assets/images/close/close.png')}
        />
      </Row>

      <Row margbottom={px2vw(8)} alignCenter justifyStart height="auto">
        <Radio
          disabled={loadingValidation}
          style={{ paddingLeft: 0 }}
          onClick={() => setActionType('pause')}
          checked={actionType === 'pause'}
          color="default"
        />
        <Text size={px2vw(12)} color={preto} alignStart>
          Pausar todos os itens selecionados
        </Text>
      </Row>
      <Row margbottom={px2vw(8)} alignCenter justifyStart height="auto">
        <Radio
          disabled={loadingValidation}
          style={{ paddingLeft: 0 }}
          onClick={() => setActionType('unpause')}
          checked={actionType === 'unpause'}
          color="default"
        />
        <Text size={px2vw(12)} color={preto} alignStart>
          Despausar todos os itens selecionados
        </Text>
      </Row>
      <Row margbottom={px2vw(15)} alignCenter justifyStart height="auto">
        <Radio
          disabled={loadingValidation}
          style={{ paddingLeft: 0 }}
          onClick={() => setActionType('delete')}
          checked={actionType === 'delete'}
          color="default"
        />
        <Text size={px2vw(12)} color={preto} alignStart>
          Excluir todos os itens selecionados
        </Text>
      </Row>
      <Row height="auto">
        <SecundaryButton
          disabled={loadingValidation}
          className="mx-auto"
          onClick={onClose}
        >
          Cancelar
        </SecundaryButton>
        <PrimaryButton
          disabled={loadingValidation}
          onClick={handleRequest}
          className="mx-auto"
        >
          Confirmar
        </PrimaryButton>
      </Row>
    </Col>
  );
}
