export const SectionTitleText = 'Defina seu plano';
export const MostRecommendedText = 'MAIS ESCOLHIDO POR NOSSOS CLIENTES';
export const BonusText1 = 'GANHE ';
export const BonusText2 = ' PARA PATROCINAR *';
export const BonusObsText = '* Os bônus são aplicados automaticamente no seu saldo na plataforma, para a criação de anúncios patrocinados automáticos através do Kuppi Boost';
export const DiscountText = '% de desconto';
export const CardRealSign = 'R$';
export const CardRealCents = ',00';
export const SaveMoneyText1 = 'economize até';
export const SaveMoneyText2 = 'no total';
