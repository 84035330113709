import React from "react";

import { GeneralText } from "../../../../../../styles/common";
import { Row, Col } from "../../../../../../styles/grid";
import { 
  SectionBox, 
  OrderSumaryTextBold, 
  OrderSumaryText 
} from '../../../../styles'

import px2vw from "../../../../../../utils/responsiveness/px2vw";
import { cpfCnpjMask } from '../../../../../../utils/masks';
import { formatCurrencyBRL } from '../../../../../../utils/utils';

import { 
  AdvertiserNameText, 
  CPFCNPJText,
  SignatureMonthlyAmountText,
  SignatureTotalAmountText,
  SignatureMonthlyInstalmentsText
} from '../../../../constants';
import { 
  SectionTitleText, 
  EmailText, 
  VigencyText,
  SaveAmountText
} from "../constants";

export default function PlanDetailMobile({ handler }) {
  return (
    <Col 
      padtop={px2vw(60)}
    >
      <Row margbottom={px2vw(40)} justifyStart>
        <GeneralText
          fontsize={px2vw(80)}
          fontweight="bold"
        >
          {SectionTitleText}
        </GeneralText>  
      </Row>
      <SectionBox>
        <Col 
          padtop={px2vw(40)}
          padbottom={px2vw(40)}
          padleft={px2vw(50)}
          padright={px2vw(50)}
          alignStart
        >
          <OrderSumaryText fontSize={px2vw(50)}>
            {AdvertiserNameText}
          </OrderSumaryText>
          <OrderSumaryTextBold
            advertiserName
            fontSize={px2vw(50)}
            maxChar="33ch"
          >
            {handler.signaturePaymentData?.advertiserInfo?.name}
          </OrderSumaryTextBold>
          <Col justifyStart margtop={px2vw(60)}>
            <OrderSumaryText fontSize={px2vw(50)}>
              {CPFCNPJText}
            </OrderSumaryText>
            <OrderSumaryTextBold fontSize={px2vw(50)}>
              {cpfCnpjMask(handler.signaturePaymentData?.advertiserInfo?.cpf_cnpj)}
            </OrderSumaryTextBold>
          </Col>
          <Col justifyStart margtop={px2vw(60)}>
            <OrderSumaryText fontSize={px2vw(50)}>
              {EmailText}
            </OrderSumaryText>
            <OrderSumaryTextBold 
              fontSize={px2vw(50)}
              advertiserName
              maxChar="33ch"
            >
              {handler.signaturePaymentData?.advertiserInfo?.user?.email}
            </OrderSumaryTextBold>
          </Col>
          <Col justifyStart margtop={px2vw(60)}>
            <OrderSumaryTextBold fontSize={px2vw(50)}>
              {handler.selectedPlan ? handler.selectedPlan?.name : handler.recurringPaymentData?.subscription?.name}
            </OrderSumaryTextBold>
            <OrderSumaryText fontSize={px2vw(50)}>
              {VigencyText.replace('$$VIGENCY$$', handler.vigencyInfo)}
            </OrderSumaryText>
          </Col>
          <Col justifyStart margtop={px2vw(60)}>
            <OrderSumaryText fontSize={px2vw(50)}>
              {SignatureMonthlyAmountText}
            </OrderSumaryText>
            <OrderSumaryTextBold fontSize={px2vw(50)}>
              {handler.selectedPlan?.period > 1 && (`${handler.selectedPlan?.period}${SignatureMonthlyInstalmentsText}`)}
              {formatCurrencyBRL(handler.selectedPlan?.new_subscription_amount.toFixed(2), {})}
            </OrderSumaryTextBold>                 
          </Col>
          {handler.selectedPlan?.period > 1 && (
            <Col justifyStart margtop={px2vw(60)}>
              <OrderSumaryText fontSize={px2vw(50)}>
                {SignatureTotalAmountText}
              </OrderSumaryText>
              <OrderSumaryTextBold fontSize={px2vw(50)}>
                {formatCurrencyBRL(
                  (
                    Math.round(
                      (handler.selectedPlan?.new_subscription_amount ? handler.selectedPlan?.new_subscription_amount : handler.newPlanOptions && handler.newPlanOptions[0] ? handler.newPlanOptions[0].new_subscription_amount : formatCurrencyBRL(handler.recurringPaymentData?.subscription?.amount.toFixed(2), {})) 
                        *
                        handler.selectedPlan?.period *
                        100                         
                    ) / 100
                  ).toFixed(2),
                  {}
                )}
              </OrderSumaryTextBold>
            </Col>
          )}                      
          {handler.selectedPlan?.discount_percentage !== 0 && (
            <OrderSumaryTextBold fontSize={px2vw(50)}>
              {`${SaveAmountText}${formatCurrencyBRL(handler.selectedPlan?.saved_amount, {})}`}
            </OrderSumaryTextBold> 
          )}
        </Col>
      </SectionBox>
    </Col>
  )
}
