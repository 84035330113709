import React from 'react';
import { useSelector } from 'react-redux'
import { Doughnut } from 'react-chartjs-2';

import { Row, Col } from '../../../../styles/grid';
import px2vw from '../../../../utils/responsiveness/px2vw';
import { formatCurrencyBRL } from '../../../../utils/utils';

import {
  verdeKuppi1,
  amareloKuppi2,
  verdeKuppi7,
  verdeKuppi8,
  amareloKuppi3,
  amareloKuppi4,
} from '../../../../styles/colors';

import {
  HrTable,
  ChartOptionColorBox,
  ChartOptionName,
  TableHeader,
  TableTextRegular,
  TableTextBold,
  TableTextBackground,
} from './styles';

export default function DeliveryTakeAwayMetrics() {
  const cashReportTotals = useSelector((state) => state.reports.cashReportTotals);
  
  const ordersTypeData = {
    labels: ['Delivery', 'Retirada'],
    datasets: [
      {
        data: [
          cashReportTotals.total_delivery_orders_qty,
          cashReportTotals.total_take_away_orders_qty,
        ],
        backgroundColor: [verdeKuppi1, amareloKuppi2],
        hoverBackgroundColor: [verdeKuppi1, amareloKuppi2],
        borderWidth: 0,
      },
    ],
  };

  return (
    <Row margtop={px2vw(30)}>
      <Col margtop={px2vw(47)} margleft={px2vw(-40)}>
        <Doughnut
          data={ordersTypeData}
          legend={{
            display: false,
          }}
          options={{
            cutoutPercentage: 60,
          }}
          width={2}
          height={1}
        />
      </Col>
      <Col margtop={px2vw(50)} margleft={px2vw(-15)}>
        <Row>
          <Col>
            <ChartOptionColorBox bgcolor={verdeKuppi1} />
            <ChartOptionColorBox
              bgcolor={amareloKuppi2}
              margtop={px2vw(10)}
            />
          </Col>
          <Col margleft={px2vw(-190)} margtop={px2vw(2)}>
            <ChartOptionName>
              Vendas por Delivery:
              {` ${(
                (parseInt(cashReportTotals.total_delivery_orders_qty) * 100) /
                parseInt(cashReportTotals.total_orders_qty)
              ).toFixed(2)}%`}
            </ChartOptionName>
            <ChartOptionName margtop={px2vw(12)}>
              Vendas por Retirada:
              {` ${(
                (parseInt(cashReportTotals.total_take_away_orders_qty) * 100) /
                parseInt(cashReportTotals.total_orders_qty)
              ).toFixed(2)}%`}
            </ChartOptionName>
          </Col>
        </Row>
      </Col>
      <Col>
        <TableHeader backgroundColor={verdeKuppi7} color={verdeKuppi8}>
          Delivery
        </TableHeader>
        <Row height={px2vw(21)} spaceBetween>
          <TableTextBold>Total de Pedidos</TableTextBold>
          <TableTextBackground justifyCenter>
            <TableTextRegular>
              {cashReportTotals.total_delivery_orders_qty}
            </TableTextRegular>
          </TableTextBackground>
        </Row>
        <HrTable />
        <Row height={px2vw(21)} spaceBetween>
          <TableTextBold>Faturamento</TableTextBold>
          <TableTextBackground>
            <TableTextRegular>
              {formatCurrencyBRL(
                cashReportTotals.total_delivery_orders_amount,
                {}
              )}
            </TableTextRegular>
          </TableTextBackground>
        </Row>
        <HrTable />
        <Row height={px2vw(21)} spaceBetween>
          <TableTextBold>Ticket Médio</TableTextBold>
          <TableTextBackground>
            <TableTextRegular>
              {formatCurrencyBRL(
                cashReportTotals.total_avg_ticket_delivery_amount,
                {}
              )}
            </TableTextRegular>
          </TableTextBackground>
        </Row>
        <HrTable />
        <Row height={px2vw(21)} spaceBetween>
          <TableTextBold>Valor em Dinheiro</TableTextBold>
          <TableTextBackground>
            <TableTextRegular>
              {formatCurrencyBRL(
                cashReportTotals.total_delivery_orders_cash_amount,
                {}
              )}
            </TableTextRegular>
          </TableTextBackground>
        </Row>
        <HrTable />
        <Row height={px2vw(21)} spaceBetween>
          <TableTextBold>Valor em Débito</TableTextBold>
          <TableTextBackground>
            <TableTextRegular>
              {formatCurrencyBRL(
                cashReportTotals.total_delivery_orders_debit_amount,
                {}
              )}
            </TableTextRegular>
          </TableTextBackground>
        </Row>
        <HrTable />
        <Row height={px2vw(21)} spaceBetween> 
          <TableTextBold>Valor em Voucher</TableTextBold>
          <TableTextBackground>
            <TableTextRegular>
              {formatCurrencyBRL(
                cashReportTotals.total_delivery_orders_voucher_amount,
                {}
              )}
            </TableTextRegular>
          </TableTextBackground>
        </Row>
        <HrTable />
        <Row height={px2vw(21)} spaceBetween>
          <TableTextBold>Valor em Crédito</TableTextBold>
          <TableTextBackground>
            <TableTextRegular>
              {formatCurrencyBRL(
                cashReportTotals.total_delivery_orders_credit_amount,
                {}
              )}
            </TableTextRegular>
          </TableTextBackground>
        </Row>        
        <HrTable />
        <Row height={px2vw(21)} spaceBetween>
          <TableTextBold>Valor em Pix</TableTextBold>
          <TableTextBackground>
            <TableTextRegular>
              {formatCurrencyBRL(
                cashReportTotals.total_delivery_orders_pix_amount,
                {}
              )}
            </TableTextRegular>
          </TableTextBackground>
        </Row>
        <HrTable />
      </Col>
      <Col width={px2vw(100)} />
      <Col>
        <TableHeader
          backgroundColor={amareloKuppi3}
          color={amareloKuppi4}
        >
          Retirada
        </TableHeader>
        <Row height={px2vw(21)} spaceBetween>
          <TableTextBold>Total de Pedidos</TableTextBold>
          <TableTextBackground justifyCenter>
            <TableTextRegular>
              {cashReportTotals.total_take_away_orders_qty}
            </TableTextRegular>
          </TableTextBackground>
        </Row>
        <HrTable />
        <Row height={px2vw(21)} spaceBetween>
          <TableTextBold>Faturamento</TableTextBold>
          <TableTextBackground>
            <TableTextRegular>
              {formatCurrencyBRL(
                cashReportTotals.total_take_away_orders_amount,
                {}
              )}
            </TableTextRegular>
          </TableTextBackground>
        </Row>
        <HrTable />
        <Row height={px2vw(21)} spaceBetween>
          <TableTextBold>Ticket Médio</TableTextBold>
          <TableTextBackground>
            <TableTextRegular>
              {formatCurrencyBRL(
                cashReportTotals.total_avg_ticket_take_away_amount,
                {}
              )}
            </TableTextRegular>
          </TableTextBackground>
        </Row>
        <HrTable />
        <Row height={px2vw(21)} spaceBetween> 
          <TableTextBold>Valor em Dinheiro</TableTextBold>
          <TableTextBackground>
            <TableTextRegular>
              {formatCurrencyBRL(
                cashReportTotals.total_take_away_orders_cash_amount,
                {}
              )}
            </TableTextRegular>
          </TableTextBackground>
        </Row>
        <HrTable />
        <Row height={px2vw(21)} spaceBetween> 
          <TableTextBold>Valor em Débito</TableTextBold>
          <TableTextBackground>
            <TableTextRegular>
              {formatCurrencyBRL(
                cashReportTotals.total_take_away_orders_debit_amount,
                {}
              )}
            </TableTextRegular>
          </TableTextBackground>
        </Row>
        <HrTable />
        <Row height={px2vw(21)} spaceBetween> 
          <TableTextBold>Valor em Voucher</TableTextBold>
          <TableTextBackground>
            <TableTextRegular>
              {formatCurrencyBRL(
                cashReportTotals.total_take_away_orders_voucher_amount,
                {}
              )}
            </TableTextRegular>
          </TableTextBackground>
        </Row>
        <HrTable />
        <Row height={px2vw(21)} spaceBetween>
          <TableTextBold>Valor em Crédito</TableTextBold>
          <TableTextBackground>
            <TableTextRegular>
              {formatCurrencyBRL(
                cashReportTotals.total_take_away_orders_credit_amount,
                {}
              )}
            </TableTextRegular>
          </TableTextBackground>
        </Row>
        <HrTable />
        <Row height={px2vw(21)} spaceBetween>
          <TableTextBold>Valor em Pix</TableTextBold>
          <TableTextBackground>
            <TableTextRegular>
              {formatCurrencyBRL(
                cashReportTotals.total_take_away_orders_pix_amount,
                {}
              )}
            </TableTextRegular>
          </TableTextBackground>
        </Row>
        <HrTable />
      </Col>
    </Row>
  );
}