export function verifyUserExistsByPhoneRequest(phone) {
  return {
    type: '@manualOrder/VERIFY_USER_EXISTS_BY_PHONE_REQUEST',
    payload: { phone },
  };
}

export function createNewUserRequest(phone, name) {
  return {
    type: '@manualOrder/CREATE_NEW_USER_REQUEST',
    payload: { phone, name },
  };
}

export function setClientInfo(clientData, type) {
  return {
    type: '@manualOrder/SET_CLIENT_INFO',
    payload: {
      clientData,
      type,
    },
  };
}

export function setClientInfoFailure(type) {
  return {
    type: '@manualOrder/SET_CLIENT_INFO_FAILURE',
    payload: {
      type,
    },
  };
}

export function setUpdatedClientName(name) {
  return {
    type: '@manualOrder/SET_UPDATED_CLIENT_NAME',
    payload: {
      name,
    },
  };
}

export function clearClientInfo() {
  return {
    type: '@manualOrder/CLEAR_CLIENT_INFO',
  };
}

export function getProductData() {
  return {
    type: '@manualOrder/GET_PRODUCT_DATA',
  };
}

export function setProductData(payload) {
  return {
    type: '@manualOrder/SET_PRODUCT_DATA',
    payload: payload,
  };
}

export function setCurrStep(currStep, previousStep = null) {
  return {
    type: '@manualOrder/SET_CURR_STEP',
    payload: {
      currStep,
      previousStep,
    },
  };
}

export function setCurrProductViewDetails(product) {
  return {
    type: '@manualOrder/SET_CURR_PRODUCT_VIEW_DETAILS',
    payload: product,
  };
}

export function addToBag(product) {
  return {
    type: '@manualOrder/ADD_TO_BAG',
    payload: product,
  };
}

export function updateInBag(productId, newProductCount) {
  return {
    type: '@manualOrder/UPDATE_IN_BAG',
    payload: {
      productId,
      newProductCount,
    },
  };
}

export function updateProductInBag(product) {
  return {
    type: '@manualOrder/UPDATE_PRODUCT_IN_BAG',
    payload: product,
  };
}

export function removeFromBag(product) {
  return {
    type: '@manualOrder/REMOVE_FROM_BAG',
    payload: product,
  };
}

export function cleanBag() {
  return {
    type: '@manualOrder/CLEAN_BAG',
  };
}

export function setAdvertiserPaymentMethod(advertiser_payment_method) {
  return {
    type: '@manualOrder/SET_ADVERTISER_PAYMENT_METHOD',
    payload: advertiser_payment_method,
  };
}

export function setPaymentMethod(payment_method) {
  return {
    type: '@manualOrder/SET_PAYMENT_METHOD',
    payload: payment_method,
  };
}

export function setDeliveryType(type) {
  return {
    type: '@manualOrder/SET_DELIVERY_TYPE',
    payload: type,
  };
}

export function setAdvertiserInfo(data) {
  return {
    type: '@manualOrder/SET_ADVERTISER_INFO',
    payload: data,
  };
}

export function setChangeValue(ChangeValue) {
  return {
    type: '@manualOrder/SET_CHANGE_VALUE',
    payload: ChangeValue,
  };
}

export function setSchedule(schedule) {
  return {
    type: '@manualOrder/SET_SCHEDULE_DATA',
    payload: schedule,
  };
}

export function setAddress(address) {
  return {
    type: '@manualOrder/SET_ADDRESS',
    payload: address,
  };
}

export function setAddresses(addresses) {
  return {
    type: '@manualOrder/SET_ADDRESSES',
    payload: addresses,
  };
}

export function addAddress(address) {
  return {
    type: '@manualOrder/ADD_TO_ADDRESSES',
    payload: address,
  };
}

export function deleteAddress(address) {
  return {
    type: '@manualOrder/DELETE_ADDRESS',
    payload: address,
  };
}

export function setDeliveryFee(tax) {
  return {
    type: '@manualOrder/SET_DELIVERY_FEE',
    payload: tax,
  };
}

export function cleanManualOrderData() {
  return {
    type: '@manualOrder/CLEAN_MANUAL_ORDER_DATA',
  };
}

export function createManualOrderRequest() {
  return {
    type: '@manualOrder/CREATE_MANUAL_ORDER_REQUEST',
  };
}

export function createManualOrderSuccess() {
  return {
    type: '@manualOrder/CREATE_MANUAL_ORDER_SUCCESS',
  };
}

export function createManualOrderFailure() {
  return {
    type: '@manualOrder/CREATE_MANUAL_ORDER_FAILURE',
  };
}

export function setManualOrderModalState(status) {
  return {
    type: '@manualOrder/SET_MANUAL_ORDER_MODAL_STATE',
    payload: status,
  };
}

export function setCurrManualOrderStatusData(currManualOrderStatusData) {
  return {
    type: '@manualOrder/SET_CURRENT_MANUAL_ORDER_STATUS_DATA',
    payload: { currManualOrderStatusData },
  };
}

export function clearCurrManualOrderStatusData() {
  return {
    type: '@manualOrder/CLEAR_CURRENT_MANUAL_ORDER_STATUS_DATA',
  };
}

export function formatOrderComplements(orders) {
  return {
    type: '@manualOrder/FORMAT_ORDER_COMPLEMENTS',
    payload: { orders },
  };
}

export function setIsMinimized(status) {
  return {
    type: '@manualOrder/SET_IS_MINIMIZED',
    payload: status,
  };
}

export function setDiscount(type, value) {
  return {
    type: '@manualOrder/SET_DISCOUNT',
    payload: { type, value },
  };
}


export function setIsOpennedFromWhatsappchat() {
  return {
    type: '@manualOrder/SET_IS_OPENNED_FROM_WHATSAPPCHAT',
  };
}

export function cleanDiscount() {
  return {
    type: '@manualOrder/CLEAN_DISCOUNT',
  };
}
