import styled, { css } from 'styled-components';
import confetti from '../../assets/images/onboarding/confetti@2x.png';

import { branco, cinzaKuppi6 } from '../../styles/colors';

import px2vw from '../../utils/responsiveness/px2vw';
import { keyframes } from 'styled-components';

const fadeIn = keyframes`
  to {
    transform: initial;
    opacity: 1;
  }
`;

export const ModalBackground = styled.div`
  display: none;
  position: fixed;
  z-index: 20;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: ${cinzaKuppi6}99;

  ${(props) =>
    props.isVisible &&
    `
    display: flex;
    justify-content: center;
    align-items: center;
  `};
`;

export const ModalContent = styled.div`
  transform: translateY(${px2vw(-15)});
  opacity: 0;
  animation: ${fadeIn} 0.3s forwards;
  background-color: ${branco};
  padding: ${(props) => props.padding || '20px'};
  border-radius: 20px;
  box-shadow: 2px 2px 3px transparent;

  ${(props) => `
    width: ${props.width};
    min-height: ${props.height};
    max-height: ${props.maxheight};
    background-color: ${props.bgcolor};
    margin-top: ${props.margtop};
  `};

  ${(props) =>
    props.backgroundImg &&
    `
    background-image: url(${confetti});
    background-size: 100% ${props.backgroundImgSize || px2vw(250)}, cover;
    background-repeat: no-repeat;
  `};
`;

export const CloseButton = styled.button`
  width: ${px2vw(18)};
  height: ${px2vw(18)};
  border: none;
  z-index: 10;
  background-color: transparent;

  :focus {
    background-color: transparent !important;
  }

  ${(props) =>
    props.absolute &&
    css`
      position: absolute;
      top: ${props.top || px2vw(10)};
      right: ${props.right || px2vw(10)};
    `}
`;

export const CloseImage = styled.img`
  width: ${(props) => props.width || px2vw(20)};
  height: ${(props) => props.height || px2vw(20)};
  padding-right: ${(props) => props.margright};

  ${(props) =>
    props.isMobile &&
    `
    width: ${px2vw(70)};
    height: ${px2vw(70)};
  `};
`;

export const ModalText = styled.div`
  margin-top: ${px2vw(25)};
  font-size: ${px2vw(16)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.48px;
  text-align: center;

  ${(props) => `
    padding-left: ${props.paddingLR};
    padding-right: ${props.paddingLR};
    margin-top: ${props.margtop};
    font-size: ${props.fontsize};
  `};
`;

export const ModalText2 = styled.div`
  font-size: ${px2vw(18)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.48px;
  text-align: center;

  ${(props) => `
    padding-left: ${props.paddingLR};
    padding-right: ${props.paddingLR};
    font-size: ${props.fontsize};
  `};
`;

export const ArrowImg = styled.img`
  position: absolute;
  top: ${px2vw(230)};
  ${(props) => props.left && `left: ${px2vw(-20)};`}
  ${(props) => props.right && `right: ${px2vw(-20)};`}
width: ${px2vw(40)};
  height: ${px2vw(40)};
  transition: width 0.1s, height 0.1s;
  :hover {
    width: ${px2vw(39.5)};
    height: ${px2vw(39.5)};
  }
`;

