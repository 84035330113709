import styled from 'styled-components';

import { cinzaKuppi1, cinzaKuppi2 } from '../../styles/colors';

export const ExportOptionsBox = styled.div`
  z-index: 3;
  position: relative;
  transition: all 0.2s ease-in-out;
  top: -30px;
  margin-left: 10px;
  margin-right: 195px;
  margin-bottom: 20px;
`;

export const ExportOptionButton = styled.div`
  background: ${cinzaKuppi1};
  position: absolute;
  display: flex;
  padding: 5px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  border-radius: 5px;

  .options {
    transition: all 0.2s ease-in-out;

    ${(props) =>
      !props.openedAllOptions
        ? `
          opacity: 0;
          height: 0;
          padding: 0;
      `
        : `
          box-shadow: 0px 8px 10px 3px rgba(0,0,0,0.15) !important; 
          height: 100%;
          opacity: 1;
          padding: 5px;
    `};

    :hover {
      background: ${cinzaKuppi2} !important;
    }
  }
`;

export const OptionText = styled.div`
  font-size: 14px;
  font-family: AvenirNextLTPro;
font-weight: 700;
  letter-spacing: 0.32px;
  padding-left: 5px;
`;

export const OptionBox = styled.button`
  padding: 2px 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 190px;
  background: ${cinzaKuppi1};
  border: none;
`;

export const IconBox = styled.div`
  margin-top: 4px;
  margin-right: 10px;

  ${(props) =>
    props.openedAllOptions &&
    `
    transition: all 0.2s ease-in-out;
    transform: rotate(180deg);
  `}
`;

export const IconImage = styled.img`
  width: 22px;
  height: 22px;
`;

export const ExportPrimaryOptionText = styled.div`
  font-size: 14px;
  font-family: AvenirNextLTPro;
font-weight: 700;
  letter-spacing: 0.32px;
  margin-top: 2px;
`;
