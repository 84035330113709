import React from 'react';
import { useSelector } from 'react-redux';

import { 
  GoToCatalog, 
  ImportProductToCouponContent, 
  ImportProductToCouponContentProductButton, 
  ImportProductToCouponContentProductsBox, 
  ImportProductToCouponContentText, 
  NoProductsToCreateCouponText 
} from '../../../styles';
import { Col } from '../../../../../styles/grid';

import Modal from '../../../../../components/Modal';
import { CloseButton, CloseImage, ModalHeader, ModalTitleText } from '../../../../../components/Modal/styles';

import px2vw from '../../../../../utils/responsiveness/px2vw';

import { catalog } from '../../../../../routes/routeMap';

export default function ImportProductToCouponModal({ handler }) {
  const products = useSelector((state) => state.coupons.products);

  return (
    <Modal
      isVisible
      width={px2vw(400)}
      height={px2vw(500)}
    >
      <ImportProductToCouponContent>
        <ModalHeader>
          <ModalTitleText>Escolher produto</ModalTitleText>
          <CloseButton
            onClick={() => handler.setImportProductToCoupon(false)}
            margleft={px2vw(340)}
            margtop={px2vw(5)}
          >
            <CloseImage
              src={require('../../../../../assets/images/close/close.png')}
            />
          </CloseButton>
        </ModalHeader>
        <ImportProductToCouponContentText>
          Para copiar o produto que será aplicado o desconto, basta
          selecioná-lo abaixo:
        </ImportProductToCouponContentText>
        <ImportProductToCouponContentProductsBox>
          {products.map((product, idx) => (
            <ImportProductToCouponContentProductButton
              key={`productToImport-${product.id}-${idx}`}
              type="button"
              onClick={() => handler.handleImportProductToCoupon(product)}
            >
              {product.name}
            </ImportProductToCouponContentProductButton>
          ))}
          {products.length === 0 && (
            <Col justifyCenter alignCenter margtop={px2vw(-40)}>
              <NoProductsToCreateCouponText >
                Não existem produtos elegíveis para criação de cupons.
                Clique no botão abaixo para acessar o catálogo e criar novos
                produtos.
              </NoProductsToCreateCouponText>
              <GoToCatalog to={catalog}>Catálogo</GoToCatalog>
            </Col>
          )}
        </ImportProductToCouponContentProductsBox>
      </ImportProductToCouponContent>
    </Modal>
  )
}
