import { takeLatest, call, put, all, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { apiAnunciante } from '../../../services/api';

import {
  getPromptOrdersPerDayAndPerHourSuccess,
  getPromptOrdersPerDayAndPerHourFailure,
  getPromptOrderTypesSuccess,
  getPromptOrderTypesFailure,
  getPromptTopSoldProductsSuccess,
  getPromptTopSoldProductsFailure,
  getPromptOrdersPerNeighborhoodSuccess,
  getPromptOrdersPerNeighborhoodFailure
} from './actions';

const userData = (state) => state.user.user;

export function* getPromptOrdersPerDayAndPerHourRequest({ payload }) {
  try {
    const user = yield select(userData);
    const response = yield call(
      apiAnunciante.post,
      `/user-info/${user.advertiserInfo.id}/ai/insights/orders-per-day-and-per-hour`,
      {
        advertiser_category_name: payload.advertiserCategory,
        week_day_text: payload.weekdayText,
        per_hours: payload.perHoursText,
      }
    );
    yield put(
      getPromptOrdersPerDayAndPerHourSuccess(
        payload.key,
        response.data
      )
    );
  } catch (err) {
    toast.error(
      'Ocorreu um erro na solicitação. Recarregue a página e tente novamente!',
      {
        autoClose: 6000,
      }
    );
    yield put(getPromptOrdersPerDayAndPerHourFailure());
  }
}

export function* getPromptOrderTypesRequest({ payload }) {
  try {
    const user = yield select(userData);
    const response = yield call(
      apiAnunciante.post,
      `/user-info/${user.advertiserInfo.id}/ai/insights/orders-types`,
      {
        advertiser_category_name: payload.advertiserCategory,
        order_origins: payload.orderOrigins,
        order_types: payload.orderTypes,
      }
    );
    yield put(
      getPromptOrderTypesSuccess(
        payload.key,
        response.data
      )
    );
  } catch (err) {
    toast.error(
      'Ocorreu um erro na solicitação. Recarregue a página e tente novamente!',
      {
        autoClose: 6000,
      }
    );
    yield put(getPromptOrderTypesFailure());
  }
}

export function* getPromptTopSoldProductsRequest({ payload }) {
  try {
    const user = yield select(userData);
    const response = yield call(
      apiAnunciante.post,
      `/user-info/${user.advertiserInfo.id}/ai/insights/top-sold-products`,
      {
        advertiser_category_name: payload.advertiserCategory,
        products: payload.products,
      }
    );
    yield put(
      getPromptTopSoldProductsSuccess(
        payload.key,
        response.data
      )
    );
  } catch (err) {
    toast.error(
      'Ocorreu um erro na solicitação. Recarregue a página e tente novamente!',
      {
        autoClose: 6000,
      }
    );
    yield put(getPromptTopSoldProductsFailure());
  }
}

export function* getPromptOrdersPerNeighborhoodRequest({ payload }) {
  try {
    const user = yield select(userData);
    const response = yield call(
      apiAnunciante.post,
      `/user-info/${user.advertiserInfo.id}/ai/insights/orders-per-neighborhood`,
      {
        advertiser_category_name: payload.advertiserCategory,
        neighborhoods: payload.neighborhoods,
      }
    );

    yield put(
      getPromptOrdersPerNeighborhoodSuccess(
        payload.key,
        response.data
      )
    );
  } catch (err) {
    toast.error(
      'Ocorreu um erro na solicitação. Recarregue a página e tente novamente!',
      {
        autoClose: 6000,
      }
    );
    yield put(getPromptOrdersPerNeighborhoodFailure());
  }
}

export default all([
  takeLatest(
    '@insightsAi/GET_PROMPT_ORDERS_PER_DAY_AND_PER_HOUR_REQUEST',
    getPromptOrdersPerDayAndPerHourRequest
  ),
  takeLatest(
    '@insightsAi/GET_PROMPT_ORDER_TYPES_REQUEST',
    getPromptOrderTypesRequest
  ),
  takeLatest(
    '@insightsAi/GET_PROMPT_TOP_SELLING_PRODUCTS_REQUEST',
    getPromptTopSoldProductsRequest
  ),
  takeLatest(
    '@insightsAi/GET_PROMPT_ORDERS_PER_NEIGHBORHOOD_REQUEST',
    getPromptOrdersPerNeighborhoodRequest
  ),
]);
