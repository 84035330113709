import {
  formatInvoiceCommonData,
  includeSpace,
  centralizeText,
  rightText,
  justifyText,
  leftText,
  formatAmount,
  formatAddress
} from '../common';
import { formatOrderNumber } from '../../../utils';

function formatProductsHeader(maxCharLine) {
  const itemField = 'Item';
  const qtdField = 'Qtd';
  const vlUnitField = 'Vl Unit.';
  const vlTotalField = 'Vl Total';
  const lengthLine =
    Number(itemField.length) +
    Number(qtdField.length) +
    Number(vlUnitField.length) +
    Number(vlTotalField.length);
  return `${includeSpace(
    maxCharLine - (Number(lengthLine) + 13)
  )}${itemField}${includeSpace(3)}${qtdField}${includeSpace(
    5
  )}${vlUnitField}${includeSpace(5)}${vlTotalField}<br>`;
}

export const createTextPlainOrderInvoice = ({
  orderStatusData,
  advertiserData,
}) => {
  const maxCharLine =
    advertiserData.advertiserInfo.advertiserPrintConfig.max_line_chars || 47;
  const maxCharAmount = 8;

  // Format Invoice Common Data
  const { advertiser, client, order } = formatInvoiceCommonData(
    orderStatusData,
    advertiserData
  );

  let invoiceTextPlain = '';

  try {
    // Advertiser address data only if he wants
    let advertiserAddressData = '';
    if (advertiserData.address[0].is_visible_on_web_menu) {
      advertiserAddressData = formatAddress(advertiser.address, 0, maxCharLine);
    }

    // Scheduling data
    let schedulingData = '';
    if (orderStatusData.order.order_type === 'scheduled') {
      schedulingData = `${centralizeText(
        `${order.order_type} ${orderStatusData.order.scheduling_start_at}`, maxCharLine
      )}<br>`;
    }

    // Additional data for online payment or offline payment change.
    let paymentMethodAdditionalData = '';
    // Change Data only for cash payment
    if (
      orderStatusData.order.paymentMethod.type === 'offline' &&
      orderStatusData.order.paymentMethod.slug === 'cash'
    ) {
      paymentMethodAdditionalData = `${justifyText(
        'Troco para:',
        `${
          order.change !== 'Não precisa'
            ? formatAmount(order.paid_amount, maxCharAmount)
            : 'Não precisa'
        }`, maxCharLine
      )}<br>
      ${
        order.change !== 'Não precisa'
          ? justifyText('Valor do troco:', `${formatAmount(order.change, maxCharAmount)}`, maxCharLine)
          : ''
      }<br>`;
    } else if (orderStatusData.order.paymentMethod.type === 'online') {
      // If payment method is online.
      paymentMethodAdditionalData = `${leftText('Pedido pago online.', maxCharLine)}<br>`;
      paymentMethodAdditionalData += `${leftText(
        'Não precisa cobrar o cliente.', maxCharLine
      )} <br>`;
    }

    // Delivery fee date only for delivery type "delivery"
    let deliveryFeeData = '';
    if (orderStatusData.order.delivery_type === 'delivery') {
      deliveryFeeData = `${justifyText(
        'Taxa de entrega:',
        `${formatAmount(Number(orderStatusData.order.delivery_fee).toFixed(2), maxCharAmount)}`, maxCharLine
      )}<br>`;
    }

    // HTML for the order products details
    let orderProducts = '';

    // Mount products and complements detailing
    orderStatusData.order.orderProduct.forEach((orderProduct) => {
      const productAmount = Number(orderProduct.product.amount).toFixed(2);
      const productAmountTotalAmount = (
        productAmount * orderProduct.quantity
      ).toFixed(2);

      // Add product data
      let productData = `# ${orderProduct.product.name.substring(
        0,
        Number(maxCharLine) - 2
      )}<br>`;
      productData += `${rightText(
        `${orderProduct.quantity}${includeSpace(3)}${formatAmount(
          productAmount, maxCharAmount
        )}${includeSpace(3)}${formatAmount(productAmountTotalAmount, maxCharAmount)}`, maxCharLine
      )}<br>`;

      // If product has complements, include them
      if (orderProduct.orderProductComplements) {
        orderProduct.orderProductComplements.forEach((productComplement) => {
          if (productComplement.complements) {
            productComplement.complements.forEach((complement) => {
              const complementAmount = complement.amount
                ? Number(complement.amount).toFixed(2)
                : Number(0).toFixed(2);
              const complementTotalAmount = (
                complementAmount *
                complement.quantity *
                orderProduct.quantity
              ).toFixed(2);
              // Add complement data
              productData += `${includeSpace(4)}+ ${complement.name.substring(
                0,
                Number(maxCharLine) - 6
              )}<br>${rightText(
                `${complement.quantity}${includeSpace(3)}${formatAmount(
                  complementAmount, maxCharAmount
                )}${includeSpace(3)}${formatAmount(complementTotalAmount, maxCharAmount)}`, maxCharLine
              )}<br>`;
            });
          }
        });
      }

      // If product has observation, include them
      if (orderProduct.obs) {
        // Split the obs line 1
        const regexObsLine1 = new RegExp(
          `.{1,${Number(maxCharLine) - 9}}`,
          'g'
        );
        let obsArr = orderProduct.obs.match(regexObsLine1);
        const obsLine1 = obsArr[0];

        // Split the others obs lines
        const regexObs = new RegExp(`.{1,${maxCharLine}}`, 'g');
        obsArr = orderProduct.obs
          .substring(
            orderProduct.obs.indexOf(obsLine1) + obsLine1.length,
            orderProduct.obs.length
          )
          .match(regexObs);

        // Add other obs lines
        productData += `${includeSpace(4)}Obs: ${obsLine1}<br>`;
        if (obsArr) {
          obsArr.forEach((obsLine) => {
            productData += `${obsLine}<br>`;
          });
        }
      }

      orderProducts += productData;
    });

    // Mount Invoice HTML
    invoiceTextPlain = `<html>
<!-- Invoice Data -->
<body>
<!-- Advertiser Data -->
${centralizeText(advertiser.name, maxCharLine)}<br>
${'-'.repeat(maxCharLine)}<br>
CNPJ: ${advertiser.cnpj}<br>
Telefone: ${advertiser.phone}<br>
${advertiserAddressData}
<!-- Client Data -->
${'-'.repeat(maxCharLine)}<br>
${centralizeText('DADOS DO CLIENTE', maxCharLine)}
<br>${'-'.repeat(maxCharLine)}<br>
Nome: ${client.name.substring(0, Number(maxCharLine - 6))}<br>
Telefone: ${client.phone}<br>
Endereço: ${formatAddress(client.address, 10, maxCharLine)}<br>
<!-- Order Data -->
${'-'.repeat(maxCharLine)}<br>
${centralizeText(`PEDIDO ${formatOrderNumber(orderStatusData.order.id)}`, maxCharLine)}<br>
${centralizeText(
  `${order.delivery_type} - Realizado em ${orderStatusData.new_at}`, maxCharLine
)}<br>
${schedulingData}
${'-'.repeat(maxCharLine)}<br>
<!-- Order Products -->
${formatProductsHeader(maxCharLine)}
${orderProducts}
${'-'.repeat(maxCharLine)}<br>
<!-- Amounts Order Data -->
${justifyText(
  'Valor Total:',
  `${formatAmount(Number(orderStatusData.order.amount).toFixed(2), maxCharAmount)}`, maxCharLine
)}<br>
${justifyText(
  'Desconto:',
  `${formatAmount(Number(orderStatusData.order.discount).toFixed(2), maxCharAmount)}`, maxCharLine
)}<br>
${deliveryFeeData}
${justifyText(
  'Valor a pagar:',
  `${formatAmount(Number(orderStatusData.order.total_amount).toFixed(2), maxCharAmount)}`, maxCharLine
)}<br>
${'-'.repeat(maxCharLine)}<br>
<!-- Payment Order Data -->
${justifyText(
  'Forma de pagamento:',
  `${orderStatusData.order.paymentMethod.name}`, maxCharLine
)}<br>
${paymentMethodAdditionalData}
<!-- Footer Data -->
${'-'.repeat(maxCharLine)}<br>
${centralizeText(
  `CONSUMIDOR ${client.name.substring(0, Number(maxCharLine - 11))}`, maxCharLine
)}<br>
${centralizeText('Kuppi kuppi.com.br', maxCharLine)}<br>
</body>
</html>`;
  } catch (err) {
    invoiceTextPlain = `
    <html><body>
Ocorreu um erro ao imprimir em plain<br>
text, verifique a quantidade máxima <br>
de colunas por linha que está config<br>
urada. Caso o erro persista, entre e<br>
m contato com o suporte</body></html>`;
  }

  return invoiceTextPlain;
};
