import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AccessValidate from '../../utils/validations/AccessValidate';
import px2vw from '../../utils/responsiveness/px2vw';

import PasswordRequired from '../../components/PasswordRequired';

import { PageTitle, ContentPage } from '../_layouts/styles/common';

import { Row } from '../../styles/grid';

import CashReport from './CashReport';
import PixKeyReport from './PixKeyReport';

import {
  SubtitleTextBox,
  SubtitleText,
  TabsBox,
  TabBox,
  Tab,
  TabSelected,
} from './styles';

import { clearFields } from '../../store/modules/auth/actions';
import OrdersReport from './OrderReport';

export default function Reports() {
  const { access } = useSelector((state) => state.user);
  const [selectedTab, setSelectedTab] = useState('cashReport');
  const adminAccessSuccess = useSelector(
    (state) => state.auth.adminAccessSuccess
  );
  const [adminAccessGranted, setAdminAccessGranted] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (adminAccessSuccess) {
      setAdminAccessGranted(true);
      // Clear admin access success
      dispatch(clearFields());
    }
  }, [adminAccessSuccess]);

  const pageHeader = <PageTitle>Relatórios</PageTitle>;

  const passwordRequiredContent = (
    <ContentPage>
      {pageHeader}
      <Row justifyCenter margtop={px2vw(50)} height="auto">
        <PasswordRequired
          height={px2vw(400)}
          infoText="O acesso a esse recurso é restrito a administradores. Caso você não tenha sua senha de administrador, solicite-a ao nosso atendimento!"
        />
      </Row>
    </ContentPage>
  );

  const pageContent = (
    <ContentPage>
      {pageHeader}
      <SubtitleTextBox>
        <SubtitleText>
          <i>Em breve novos relatórios para o seu estabelecimento!</i>
        </SubtitleText>
      </SubtitleTextBox>
      <Row height={px2vw(80)} justifyStart>
        <TabsBox>
          <TabBox>
            <Tab
              onClick={() => {
                setSelectedTab('cashReport');
              }}
            >
              Resumo do Caixa
            </Tab>
            {selectedTab === 'cashReport' && (
              <TabSelected isVisible width="auto" />
            )}
          </TabBox>
        </TabsBox>
        <TabsBox>
          <TabBox>
            <Tab
              onClick={() => {
                setSelectedTab('pixKeyReport');
              }}
            >
              Pagamentos com Chave Pix
            </Tab>
            {selectedTab === 'pixKeyReport' && (
              <TabSelected isVisible width="auto" />
            )}
          </TabBox>
        </TabsBox>
        <TabsBox>
          <TabBox>
            <Tab
              onClick={() => {
                setSelectedTab('orders');
              }}
            >
              Vendas
            </Tab>
            {selectedTab === 'orders' && <TabSelected isVisible width="auto" />}
          </TabBox>
        </TabsBox>
        {/*
        <TabsBox>
           <TabBox>
            <Tab
              onClick={() => {
                setSelectedTab('menu_and_conversion');
              }}
            >
              Cardápio e Conversão
            </Tab>
            {selectedTab === 'menu_and_conversion' && (
              <TabSelected isVisible width="auto" />
            )}
          </TabBox> 
        </TabsBox>
        */}
      </Row>
      {selectedTab === 'cashReport' && <CashReport />}
      {selectedTab === 'pixKeyReport' && <PixKeyReport />}
      {selectedTab === 'orders' && <OrdersReport />}
      {/* {selectedTab === 'menu_and_conversion' && <MenuAndConversionReport />} */}
    </ContentPage>
  );

  if (
    !AccessValidate(access, null, null, 'no_billing_data') ||
    adminAccessGranted
  ) {
    return pageContent;
  }

  return passwordRequiredContent;
}
