import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import sjcl from 'sjcl';
import { toast } from 'react-toastify';

import { Form } from '@rocketseat/unform';

import { Content } from '../_layouts/styles/common';

import { Col, Row } from '../../styles/grid';

import { LoadingBox } from '../../pages/_layouts/styles/common';

import px2vw from '../../utils/responsiveness/px2vw';

import {
  Container,
  PageContent,
  LogoKuppi,
  Message,
  PasswordTitleText,
  PasswordInputBox,
  PasswordInputLabel,
  PasswordBox,
  PasswordInput,
  SeePasswordButton,
  SeePasswordImage,
  ConfirmResetPasswordButton,
  ConfirmResetPasswordButtonText,
} from './styles';

import { preto } from '../../styles/colors';

import {
  verifyResetAdminPasswordTokenRequest,
  changeAdminPasswordRequest,
} from '../../store/modules/auth/actions';

export default function ResetAdminPassword() {
  const urlParams = new URLSearchParams(useLocation().search);
  const [token] = useState(urlParams.get('t'));
  const [newPasswordVisible, setNewPasswordVisible] = useState(false);
  const [confirmNewPasswordVisible, setConfirmNewPasswordVisible] = useState(
    false
  );
  const verifyResetAdminPasswordTokenLoading = useSelector(
    (state) => state.auth.verifyResetAdminPasswordTokenLoading
  );
  const verifyResetAdminPasswordTokenSuccess = useSelector(
    (state) => state.auth.verifyResetAdminPasswordTokenSuccess
  );
  const changePasswordSuccess = useSelector(
    (state) => state.auth.changePasswordSuccess
  );
  const changePasswordLoading = useSelector(
    (state) => state.auth.changePasswordLoading
  );
  const dispatch = useDispatch();

  const handleSubmit = ({ newPassword, confirmNewPassword }) => {
    if (newPassword.trim() === '') {
      toast.error('Nova senha não informada', {
        autoClose: 6000,
      });
    } else if (confirmNewPassword.trim() === '') {
      toast.error('Confirmação de nova senha não informada', {
        autoClose: 6000,
      });
    } else if (newPassword.trim() !== confirmNewPassword.trim()) {
      toast.error('As senhas digitadas não conferem', {
        autoClose: 6000,
      });
    } else {
      dispatch(
        changeAdminPasswordRequest(
          token, sjcl.codec.hex.fromBits(sjcl.hash.sha256.hash(newPassword))
        )
      );
    }
  };

  useEffect(() => {
    if (token) {
      dispatch(verifyResetAdminPasswordTokenRequest(token));
    }
  }, [token]);

  const loadingContent = (
    <Content>
      <Row>
        <Col height="90vh" alignCenter justifyCenter margleft={px2vw(165)}>
          <Col alignCenter justifyCenter>
            <Spinner
              style={{
                width: '1.8rem',
                height: '1.8rem',
                color: preto,
              }}
            />
          </Col>
        </Col>
        <Row justifyEnd width="auto">
          <LogoKuppi
            src={require('../../assets/images/logo_kuppi/logo_kuppi@2x.png')}
          />
        </Row>
      </Row>
    </Content>
  );

  const resetAdminPasswordContent = (
    <Container>
      <Row>
        <Row alignCenter justifyCenter margleft={px2vw(165)}>
          <PageContent>
            <Row margbottom={px2vw(20)} width={px2vw(350)}>
              <PasswordTitleText>
                Para redefinir a senha de administrador, preencha os campos
                abaixo:
              </PasswordTitleText>
            </Row>
            <Form onSubmit={handleSubmit}>
              <PasswordInputBox>
                <Col height="auto" width="80%">
                  <PasswordInputLabel>Nova senha</PasswordInputLabel>
                </Col>
                <PasswordBox>
                  <PasswordInput
                    name="newPassword"
                    type={newPasswordVisible ? 'text' : 'password'}
                    placeholder="Digite a nova senha"
                  />
                  <SeePasswordButton
                    type="button"
                    onClick={() => setNewPasswordVisible(!newPasswordVisible)}
                  >
                    <SeePasswordImage
                      src={require(newPasswordVisible
                        ? '../../assets/images/eye-outline/eye-outline.png'
                        : '../../assets/images/eye-outline/eye-off-outline.png')}
                    />
                  </SeePasswordButton>
                </PasswordBox>
              </PasswordInputBox>

              <PasswordInputBox>
                <Col height="auto" width="80%">
                  <PasswordInputLabel>Confirmar nova senha</PasswordInputLabel>
                </Col>
                <PasswordBox>
                  <PasswordInput
                    name="confirmNewPassword"
                    type={confirmNewPasswordVisible ? 'text' : 'password'}
                    placeholder="Confirme a nova senha"
                  />
                  <SeePasswordButton
                    type="button"
                    onClick={() =>
                      setConfirmNewPasswordVisible(!confirmNewPasswordVisible)
                    }
                  >
                    <SeePasswordImage
                      src={require(confirmNewPasswordVisible
                        ? '../../assets/images/eye-outline/eye-outline.png'
                        : '../../assets/images/eye-outline/eye-off-outline.png')}
                    />
                  </SeePasswordButton>
                </PasswordBox>
              </PasswordInputBox>

              <Row alignCenter height="auto" margtop={px2vw(20)}>
                <ConfirmResetPasswordButton
                  type="submit"
                  disabled={changePasswordLoading}
                >
                  {changePasswordLoading ? (
                    <LoadingBox>
                      <Spinner
                        style={{
                          width: '1.8rem',
                          height: '1.8rem',
                          color: preto,
                        }}
                      />{' '}
                    </LoadingBox>
                  ) : (
                    <ConfirmResetPasswordButtonText>
                      Trocar Senha
                    </ConfirmResetPasswordButtonText>
                  )}
                </ConfirmResetPasswordButton>
              </Row>
            </Form>
          </PageContent>
        </Row>
        <Row justifyEnd width="auto">
          <LogoKuppi
            src={require('../../assets/images/logo_kuppi/logo_kuppi@2x.png')}
          />
        </Row>
      </Row>
    </Container>
  );

  const passwordChangedContent = (
    <Content>
      <Row>
        <Col margleft={px2vw(150)} height="90vh">
          <Col alignCenter justifyCenter>
            <Message>
              Senha de administrador redefinida com sucesso.
            </Message>
          </Col>
        </Col>
        <Row justifyEnd width="auto">
          <LogoKuppi
            src={require('../../assets/images/logo_kuppi/logo_kuppi@2x.png')}
          />
        </Row>
      </Row>
    </Content>
  );

  const errorContent = (
    <Content>
      <Row>
        <Col margleft={px2vw(150)} height="90vh">
          <Col alignCenter justifyCenter>
            <Message>
              Ocorreu um erro, recarregue a página e tente novamente.
              <br />
              Caso persista, entre em contato com o suporte.
            </Message>
          </Col>
        </Col>
        <Row justifyEnd width="auto">
          <LogoKuppi
            src={require('../../assets/images/logo_kuppi/logo_kuppi@2x.png')}
          />
        </Row>
      </Row>
    </Content>
  );

  if (verifyResetAdminPasswordTokenLoading) {
    return loadingContent;
  }
  if (verifyResetAdminPasswordTokenSuccess) {
    return resetAdminPasswordContent;
  } 
  if (changePasswordSuccess) {
    return passwordChangedContent;
  } 
  return errorContent;
}
