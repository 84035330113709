import styled, { css } from 'styled-components';

import {
  azulKuppi1,
  branco,
  cinzaKuppi1,
  cinzaKuppi4,
  cinzaKuppi7,
  preto,
  verdeKuppi2,
} from '../../../../styles/colors';
import { Row } from '../../../../styles/grid';

import px2vw from '../../../../utils/responsiveness/px2vw';

export const ModalContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
`;

export const ModalHeader = styled.div`
  width: 100%;
  background-color: ${branco};
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: ${px2vw(20)} ${px2vw(28)} ${px2vw(15)} ${px2vw(28)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  font-size: ${px2vw(25)};
  display: flex;
  align-items: space-between;
  justify-content: space-between;
`;

export const CloseButton = styled.button`
  width: ${px2vw(18)};
  height: ${px2vw(18)};
  border: none;
  z-index: 10;
  background-color: transparent;

  :focus {
    background-color: transparent !important;
  }

  ${(props) =>
    props.absolute &&
    css`
      position: absolute;
      top: ${props.top || px2vw(10)};
      right: ${props.right || px2vw(10)};
    `}
`;

export const CloseImage = styled.img`
  width: ${px2vw(15)};
  height: ${px2vw(15)};
`;

export const ModalContent = styled.div`
  background-color: ${branco};
  padding: ${(props) => props.padding || `${px2vw(0)} ${px2vw(15)}`};
  display: ${({ grid }) => (grid ? 'grid' : 'flex')};
  grid-template-columns: ${({ gridColumsTemplate }) =>
    gridColumsTemplate ?? '1fr 1fr'};
  grid-column-gap: ${({ gap }) => gap ?? '1rem'};
  flex-direction: column;
  justify-content: start;
  height: 100%;
  ${(props) => `
    width: ${props.width};
    min-height: ${props.height || px2vw(400)};
    max-height: ${props.maxheight || px2vw(1000)};
    background-color: ${props.bgcolor};
    margin-top: ${props.margtop};
    padding: ${props.padding};
    padding-bottom: ${props.paddingBottom}
  `};
`;

export const GeneralInfosContainer = styled.div`
  display: flex;
  width: 100%;
  min-height: 25rem;
  flex-direction: column;
  justify-content: start;
  border-radius: 1rem;
  background-color: ${cinzaKuppi1};
  padding: 1rem;
`;

export const ModalFooter = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: ${px2vw(90)};
  background-color: ${branco};
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: ${px2vw(15)} ${px2vw(32)};
  margin-bottom: ${px2vw(5)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  font-size: ${px2vw(22)};
`;

export const TypeBox = styled.div`
  display: flex;
  min-width: ${px2vw(205)};
  max-width: ${({ fullSize }) => (fullSize ? '100%' : px2vw(205))};
  flex-direction: column;
  justify-content: start;
  align-items: center;
  font-size: ${px2vw(11)};
  height: ${({ fullSize }) => (fullSize ? '100%' : px2vw(135))};
  border-radius: 1rem;
  border: 3px solid ${(props) => (props.selected ? verdeKuppi2 : cinzaKuppi4)};
  padding: ${({ padding }) => padding ?? `${px2vw(10)} ${px2vw(10)}`};
  position: relative;
  cursor: ${({noCursor}) => (noCursor ? '': "pointer")};
`;

export const TypeBoxContainer = styled(Row)`
  display: ${({ grid }) => (grid ? 'grid' : 'flex')};
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  height: 100%;
  justify-content: center;
  align-items: center;
  /* margin-bottom: ${px2vw(10)}; */
`;

export const RadioButton = styled.div`
  border-radius: 50%;
  width: ${px2vw(14)};
  height: ${px2vw(14)};
  background: ${branco};
  padding: ${px2vw(4)};
  border: ${px2vw(7)} solid
    ${(props) => (props.selected ? verdeKuppi2 : cinzaKuppi4)};
  margin: 0 auto;
  position: absolute;
  right: 0px;
`;

export const CheckBox = styled.div`
  border-radius: 4px;
  width: ${px2vw(15)};
  height: ${px2vw(15)};
  border: 3px solid ${cinzaKuppi7};
  padding: 10px;
  margin-right: ${px2vw(6)};
  cursor: pointer;
  ${(props) => props.checked && `background: ${verdeKuppi2};`}
`;

export const SearchInput = styled.input`
  border-radius: ${px2vw(8)};
  width: 100%;
  font-size: ${px2vw(12)};
  height: ${px2vw(36)};
  border: 2px solid ${cinzaKuppi7};
  padding-left: ${px2vw(35)};
  padding-right: ${px2vw(15)};
  &::placeholder {
    font-weight: 600;
    font-size: ${px2vw(12)};
    color: ${cinzaKuppi7};
  }
  selector::-webkit-search-cancel-button {
    content: 'x';
    background-color: red;
    /* ... */
  }
`;

export const ManualSegmentationButton = styled.button`
  border-radius: ${px2vw(5)};
  width: ${px2vw(140)};
  height: ${px2vw(22)};
  border: 1px solid ${preto};
  margin: 0 auto;
  cursor: pointer;
  background: ${branco};
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
`;

export const SearchInputStyle = {
  position: 'absolute',
  left: px2vw(12),
  fontSize: px2vw(15),
  fontWeight: 700,
  color: cinzaKuppi7,
};

export const SearchInputClearStyle = {
  position: 'absolute',
  right: px2vw(12),
  fontSize: px2vw(15),
  fontWeight: 700,
  color: cinzaKuppi7,
  cursor: 'pointer',
};

export const Input = styled.input`
  border: 1px solid ${preto};
  text-align: center;
  padding: ${px2vw(2)} ${px2vw(4)};
  -moz-appearance: textfield;
  width: ${px2vw(60)};
  border-radius: ${px2vw(6)};
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const SliderInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  margin-top: 2rem;
`
