import styled from 'styled-components';

import px2vw from '../../../../utils/responsiveness/px2vw';

export const ProductImage = styled.img`
  width: ${px2vw(150)};
  height: ${px2vw(100)};
  object-fit: cover;

  border-radius: ${px2vw(10)};
`;

export const LogoImage = styled.img`
  width: ${px2vw(150)};
  height: ${px2vw(100)};
  object-fit: cover;

  border-radius: ${px2vw(10)};
  filter: blur(4px);
`;

export const TextRegular = styled.div`
  margin-left: ${px2vw(20)};
  font-size: ${px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.36px;
`;