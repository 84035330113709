import styled from 'styled-components';

import { Select } from '@rocketseat/unform';

import px2vw from '../../../utils/responsiveness/px2vw';
import { branco, cinzaKuppi4, preto, verdeKuppi4 } from '../../../styles/colors';

export const SwitchLabelRegularTextBefore = styled.div`
  font-size: ${(props) => props.ftsize || px2vw(10)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.36px;

  ${(props) =>
    props.isChecked &&
    `
    color: ${branco};
  `};

  ${(props) => `
    width: ${props.width};
    margin-right: ${props.margright};
    margin-left: ${props.margleft};
    margin-top: ${props.margtop};
  `};

  ${(props) =>
    props.disabled &&
    `
    color: ${preto}60;
  `};
`;

export const IntervalOption = styled.button`
  background-color: ${branco};
  height: ${px2vw(25)};
  width: auto;
  min-width: ${px2vw(85)};
  border: 1px solid;
  border-radius: ${px2vw(5)};
  padding-left: ${px2vw(8)};
  padding-right: ${px2vw(8)};
  margin-bottom: ${px2vw(2)};
  margin-right: ${px2vw(2)};
  margin-left: ${px2vw(2)};

  ${(props) =>
    props.isChecked &&
    `
    background-color: ${verdeKuppi4};
    border: 1px solid ${verdeKuppi4};
  `};

  ${(props) =>
    props.disabled &&
    `
    opacity: 0.2;
  `};
`;

export const SelectInputBox = styled(Select)`
  width: ${(props) => props.width || px2vw(40)};
  height: ${(props) => props.height || px2vw(29)};
  font-size: ${(props) => props.ftsize || px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.36px;
  

  ${(props) => `
    margin-left: ${props.margleft};
    margin-bottom: ${props.margbottom};
    padding-left: ${props.padleft};
    padding-right: ${props.padright};
  `};

  ${(props) => props.isMobile && `
    width: 100%;
    height: ${px2vw(150)};
    font-size: ${px2vw(50)};
    border-radius:${px2vw(30)};
    border: 1px solid ${cinzaKuppi4};
    margin-left: ${props.margleft};
    margin-bottom: ${props.margbottom};
    padding-left: ${px2vw(50)};
    padding-right: ${props.padright};
    background-color: ${branco}
  `};
`;