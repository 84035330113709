import React from 'react';
import { Spinner } from 'reactstrap';
import { ModalPrimaryButton } from '../../../../components/Modal/styles';
import { branco, preto } from '../../../../styles/colors';
import { Col, Row } from '../../../../styles/grid';
import px2vw from '../../../../utils/responsiveness/px2vw';
import { LoadingBox } from '../../../_layouts/styles/common';
import { InputsBox, LoginInput, LoginInputBox, LoginInputLabel, NormalText } from '../../styles';

import RecoveryGoBackButton from '../RecoveryGoBackButton';
import { 
  btnConfirm, 
  btnTextLogin2, 
  inputCompanyName, 
  inputDocument, 
  recoverEmail, 
  recoverEmailFound2, 
  recoverEmailNotFound, 
  recoveryText2 
} from '../../constants';

export default function RecoveryEmail({ handler }) {
  return (
    <Col width="100%" alignCenter>
      {!handler.recoveredEmailModal && !handler.notRecoveredEmailModal && (
        <>
          <RecoveryGoBackButton handler={handler} goBackPage="recovery_type" />
          <Row width={px2vw(1300)}>
            <NormalText center fontweight="normal" maxWidth={px2vw(1400)}>
              {recoveryText2}
            </NormalText>
          </Row>
          <InputsBox mobile>
            <LoginInputBox margtop={px2vw(30)} mobile width={px2vw(1100)}>
              <Col alignStart margleft={px2vw(200)}>
                <LoginInputLabel margleft={px2vw(-90)} mobile>{inputCompanyName}</LoginInputLabel>
              </Col>
              <LoginInput
                mobile
                key="editor1"
                name="name"
                type="text"
                placeholder={inputCompanyName}
                maxLength="100"
                value={handler.completeNameInputValue}
                onChange={handler.setCompleteNameInputValue}
              />
            </LoginInputBox>
            <LoginInputBox margtop={px2vw(10)} mobile width={px2vw(1100)}>
              <Col alignStart margleft={px2vw(200)}>
                <LoginInputLabel margleft={px2vw(-90)} mobile>{inputDocument}</LoginInputLabel>
              </Col>
              <LoginInput
                key="editor2"
                name="name"
                type="text"
                mobile
                placeholder={inputDocument}
                maxLenght="18"
                value={handler.cnpjInputValue}
                onChange={handler.setCnpjInputValue}
              />
            </LoginInputBox>
          </InputsBox>
          {!handler.recoveredEmailModal && (
            <ModalPrimaryButton
              mobile
              margtop={px2vw(80)}
              width={px2vw(1090)}
              onClick={() => handler.handleRecoverEmail()}
            >
              {handler.recoverEmailLoading ? (
                <LoadingBox>
                  <Spinner
                    style={{
                      width: '1.8rem',
                      height: '1.8rem',
                      color: preto,
                    }}
                  />
                </LoadingBox>
              ) : (
                <NormalText center color={branco} >
                  {recoverEmail}
                </NormalText  >
              )}
            </ModalPrimaryButton>
          )}
        </>
      )}
      
      {handler.notRecoveredEmailModal && (
        <>
          <NormalText center mobile margtop={px2vw(150)}>
            {recoverEmailNotFound}
          </NormalText>
          <ModalPrimaryButton
            mobile
            width={px2vw(800)}
            margtop={px2vw(40)}
            onClick={() => {
              handler.setNotRecoveredEmailModal(false);
              handler.setCurrLoginStep("main");
            }}
          >
            <NormalText mobile center color={branco}>{btnConfirm}</NormalText>
          </ModalPrimaryButton>
        </>
      )}

      {handler.recoveredEmailModal && (
        <>
          <NormalText center mobile margtop={px2vw(150)}>
            {recoverEmailFound2}
          </NormalText>
          <NormalText mobile center>{handler.recoveredEmail}</NormalText>
          <ModalPrimaryButton
            mobile
            width={px2vw(800)}
            margtop={px2vw(40)}
            onClick={() => {
              handler.setRecoveredEmailModal(false);
              handler.setCurrLoginStep("main");
            }}
          >
            <NormalText mobile center color={branco}>{btnTextLogin2}</NormalText>
          </ModalPrimaryButton>
        </>
      )}
    </Col>
  )
}

