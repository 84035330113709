import styled from 'styled-components';
import { Link } from 'react-router-dom';
import px2vw from '../../../utils/responsiveness/px2vw';

import { branco, verdeKuppi1 } from '../../../styles/colors';

export const ActiveTextGreen = styled.div`
  color: ${verdeKuppi1};
  font-size: ${(props) => props.fontSize || px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.36px;
`;

export const GoToDashboard = styled(Link)`
  font-size: ${(props) => props.fontSize || px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.36px;
  color: ${branco};
  width: ${(props) => props.width || px2vw(180)};
  height: ${(props) => props.height || px2vw(35)};
  border-radius: ${(props) => props.borderradius || px2vw(10)};
  border: none;
  background-color: ${verdeKuppi1};
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
    color: ${branco};
  }
`;
