import { takeLatest, call, put, all, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import axios from 'axios';

import { apiAnunciante } from '../../../services/api';

import {
  updateWhatsappbotStatus,
} from './actions';
import {
  updateUserInfoSuccess,
} from '../user/actions';
import {
  logout
} from '../auth/actions';

import { getURL } from '../../../utils/utils';
import { clearChatState, setIsDeletingMessage } from '../whatsappChat/actions';
import { forage } from '../../persistReducers';

const delay = (ms) => new Promise((res) => setTimeout(res, ms)); // yield delay(1000)
const userData = (state) => state.user.user;

export function* createWhatsappBotAPI() {
  const tokenData = (state) => state.auth.token;
  const token = yield select(tokenData);
  const user = yield select(userData);

  // Get whatsapp lib
  const whatsappbotURL = `anunciante_whatsappbot_api_${user.advertiserInfo.advertiserWhatsappbotConfig.whatsapp_lib}`

  // Create Axios for Anunciante Whatsappbot API
  return axios.create({
    baseURL: getURL(whatsappbotURL),
    timeout: 30000,
    headers: {
      common: {
        Authorization: `Bearer ${token}`
      }
    }
  });
}

export function* initiateWhatsappbotRequest() {
  try {
    // Create Axios for Anunciante Whatsappbot API
    const apiAnuncianteWhatsappbot = yield call(createWhatsappBotAPI, {});

    const user = yield select(userData);

    const responseInit = yield call(
      apiAnuncianteWhatsappbot.get,
      `/user-info/${user.advertiserInfo.id}/init`
    );

    if (responseInit.data.success === 'created a new instance' || responseInit.data.success === 'already exists an instance') {
      try {
        yield delay(1000);

        const responseQr = yield call(
          apiAnuncianteWhatsappbot.get,
          `/user-info/${user.advertiserInfo.id}/qr`
        );
        yield put(updateWhatsappbotStatus('initialized', false, null, responseQr.data));
      } catch (err) {
        if (err.response.data.error === 'instance already connected') {
          yield put(updateWhatsappbotStatus('connected', true, user?.advertiserInfo?.advertiserWhatsappbotConfig?.phone));
        } else if (err.response.data.error === 'instance initialized qr code not generated yet') {
          yield put(updateWhatsappbotStatus('initialized', false, null));
        } else {
          yield put(updateWhatsappbotStatus('disconnected', false, null));
        }
      }
    }
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(updateWhatsappbotStatus('disconnected', false, null));
      toast.error(`Ocorreu um erro ao gerar o QrCode. Tente novamente!`, {
        autoClose: 6000,
      });
    }
  }
}

export function* disconnectWhatsappbotRequest() {
  try {
    // Create Axios for Anunciante Whatsappbot API
    const apiAnuncianteWhatsappbot = yield call(createWhatsappBotAPI, {});

    const user = yield select(userData);

    yield call(
      apiAnuncianteWhatsappbot.get,
      `/user-info/${user.advertiserInfo.id}/logout`
    );
    yield put(updateWhatsappbotStatus('disconnected', false, null));
    yield put(clearChatState())
    forage.clear()
    window.location.reload();
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(updateWhatsappbotStatus('disconnected', false, null));
      toast.error(`Ocorreu um erro ao desconectar o chatbot. Tente novamente!`, {
        autoClose: 6000,
      });
    }
  }
}

export function* changeWhatsappbotFunctionsRequest({ payload }) {
  try {
    const user = yield select(userData);

    const userResponse = yield call(
      apiAnunciante.put,
      `/user-info/${user.advertiserInfo.id}/orders-configs`,
      {
        is_using_chatbot: payload.isUsingChatbot,
        is_send_whatsapp_msg_update_status: payload.isSendingOrderStatusMsg,
      }
    );

    // Save in redux the user info data
    yield put(updateUserInfoSuccess(userResponse.data));
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      toast.error(
        `Ocorreu um erro ao atualizar as configurações. Tente novamente!`,
        {
          autoClose: 6000,
        }
      );
    }
  }
}

export function* sendWhatsappbotOrderStatusMessageRequest({ payload }) {
  try {
    // Create Axios for Anunciante Whatsappbot API
    const apiAnuncianteWhatsappbot = yield call(createWhatsappBotAPI, {});

    const user = yield select(userData);

    yield call(
      apiAnuncianteWhatsappbot.post,
      `/user-info/${user.advertiserInfo.id}/send-order-status-message`,
      {
        client_phone_number: payload.clientPhone,
        order_status_message: payload.orderStatusMessage
      }
    );
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else if (err.response?.status === 409) {
      toast.error(`Atualização de status do pedido não enviada, o número do cliente está bloqueado no seu whatsapp.`, {
        autoClose: 6000,
      });
    } else {
      yield put(updateWhatsappbotStatus('disconnected', false, null));
      toast.error(`Ocorreu um erro ao enviar a atualização de status do pedido para o cliente.`, {
        autoClose: 6000,
      });
    }
  }
}

export function* sendWhatsappBotChatMessageRequest({ payload }) {
  try {
    // Create Axios for Anunciante Whatsappbot API
    const apiAnuncianteWhatsappbot = yield call(createWhatsappBotAPI, {});

    const user = yield select(userData);

    yield call(
      apiAnuncianteWhatsappbot.post,
      `/user-info/${user.advertiserInfo.id}/send-message`,
      {
        client_phone_number: payload.clientPhone,
        chat_message: payload.chatMessage,
        type: payload.type,
        media: payload.media
      }
    );
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else if (err.response?.status === 409) {
      toast.error(`Mensagem não enviada, o número do cliente está bloqueado no seu whatsapp.`, {
        autoClose: 6000,
      });
    } else {
      toast.error(`Ocorreu um erro ao enviar a mensagem.`, {
        autoClose: 6000,
      });
    }
  }
}

export function* whatsappBotChatDeleteMessageRequest({ payload }) {
  try {
    // Create Axios for Anunciante Whatsappbot API
    const apiAnuncianteWhatsappbot = yield call(createWhatsappBotAPI, {});

    const user = yield select(userData);

    yield call(
      apiAnuncianteWhatsappbot.post,
      `/user-info/${user.advertiserInfo.id}/delete-message`,
      {
        client_phone_number: payload.clientPhone,
        message_id: payload.id
      }
    );

    toast.success(`A mensagem foi apagada.`, {
      autoClose: 6000,
    });

    yield put(setIsDeletingMessage(''))
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else if (err.response?.status === 409) {
      toast.error(`Mensagem não apagada, o número do cliente está bloqueado no seu whatsapp.`, {
        autoClose: 6000,
      });
    } else {
      toast.error(`Ocorreu um erro ao apagar a mensagem.`, {
        autoClose: 6000,
      });
    }
  }
}

export default all([
  takeLatest('@whatsappBot/INITIATE_WHATSAPPBOT_REQUEST', initiateWhatsappbotRequest),
  takeLatest('@whatsappBot/DISCONNECT_WHATSAPPBOT_REQUEST', disconnectWhatsappbotRequest),
  takeLatest('@whatsappBot/CHANGE_WHATSAPPBOT_FUNCTIONS_REQUEST', changeWhatsappbotFunctionsRequest),
  takeLatest('@whatsappBot/SEND_WHATSAPPBOT_ORDER_STATUS_MESSAGE_REQUEST', sendWhatsappbotOrderStatusMessageRequest),
  takeLatest('@whatsappBot/SEND_WHATSAPPBOT_BOT_CHAT_MESSAGE_REQUEST', sendWhatsappBotChatMessageRequest),
  takeLatest('@whatsappBot/WHATSAPPBOT_BOT_CHAT_DELETE_MESSAGE_REQUEST', whatsappBotChatDeleteMessageRequest),
]);
