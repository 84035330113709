import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'reactstrap';
import KuppiBoostModal from '../../../components/KuppiBoost';
import { finishOnboardingRequest } from '../../../store/modules/kuppiBoost/actions';
import {
  branco,
  preto2,
  verdeKuppi1,
} from '../../../styles/colors';
import px2vw from '../../../utils/responsiveness/px2vw';
import { ContentPage } from '../../_layouts/styles/common';
import { Text } from '../common';
import { Col, Row } from '../grid';
import {
  ContentBox,
  ImgBox,
  ImgTrial,
  StatusBox,
  Title,
  IconImg,
  ButtonPrimary,
} from '../styles';

export default function StatusPageContent() {
  const dispatch = useDispatch()
  const kuppiBoostData = useSelector((state) => state.kuppiBoost);
  const [statusData, setStatusData] = useState(
    kuppiBoostData?.onboarding?.status
  );
  const [isOnboardingModalVisible, setIsOnboardingModalVisible] =
    useState(false);

  const { finish_onboarding_loading } = kuppiBoostData;

  useEffect(() => {
    setStatusData(kuppiBoostData?.onboarding?.status);
  }, [kuppiBoostData]);

  return (
    <>
      <ContentPage className="mt-3">
        <Title size={px2vw(21)}>Kuppi Boost</Title>
        <ContentBox
          width="100%"
          padding={`${px2vw(45)} ${px2vw(30)} ${px2vw(45)}  ${px2vw(30)}`}
          className="mt-4"
        >
          <Col width="100%" margtop={px2vw(10)} alignCenter>
            <Row width="100%" margtop={px2vw(20)}>
              <StatusBox height="auto">
                <Text
                  className="mx-auto"
                  size={px2vw(19)}
                  margbottom={px2vw(23)}
                  color={preto2}
                  bold
                >
                  Aguardando confirmação do robô
                </Text>

                <Row
                  alignCenter
                  padding={`0 ${px2vw(45)}`}
                  width="100%"
                  justifyStart
                  height={px2vw(50)}
                >
                  <IconImg
                    src={require('../../../assets/images/kuppi_boost_icon/green_analytics.png')}
                  />

                  <Text size={px2vw(12)} color={preto2} bold>
                    Informações de acesso recebidas
                  </Text>
                </Row>

                <Col
                  margin={`0 ${px2vw(58)}`}
                  width={px2vw(3)}
                  height={px2vw(45)}
                  background={verdeKuppi1}
                ></Col>

                <Row
                  alignCenter
                  padding={`0 ${px2vw(45)}`}
                  width="100%"
                  justifyStart
                  height={px2vw(50)}
                >
                  <IconImg
                    src={require('../../../assets/images/kuppi_boost_icon/green_robot.png')}
                  />
                  <Text
                    className="my-auto"
                    size={px2vw(12)}
                    color={preto2}
                    bold
                  >
                    Informações de acesso enviadas ao robô
                  </Text>
                </Row>

                <Col
                  margin={`0 ${px2vw(58)}`}
                  width={px2vw(3)}
                  height={px2vw(45)}
                  background={verdeKuppi1}
                ></Col>

                <Row
                  alignCenter
                  padding={`0 ${px2vw(45)}`}
                  width="100%"
                  justifyStart
                  height={px2vw(50)}
                >
                  <IconImg
                    width={px2vw(24)}
                    height={px2vw(24)}
                    src={require('../../../assets/images/kuppi_boost_icon/green_eyes.png')}
                  />
                  <Text size={px2vw(12)} color={preto2} bold>
                    Início da checagem dos acessos
                  </Text>
                </Row>

                {statusData === 'pending_approval' ? (
                  <>
                    {' '}
                    <Col
                      margin={`0 ${px2vw(58)}`}
                      width={px2vw(3)}
                      height={px2vw(50)}
                      background={preto2}
                    ></Col>
                    <Row
                      alignCenter
                      padding={`0 ${px2vw(45)}`}
                      width="100%"
                      justifyStart
                      height={px2vw(50)}
                    >
                      <IconImg
                        src={require('../../../assets/images/kuppi_boost_icon/black_ok.png')}
                      />
                      <Text size={px2vw(12)} color={preto2} bold>
                        Confirmação dos acessos
                      </Text>
                    </Row>
                    <Col
                      margin={`0 ${px2vw(58)}`}
                      width={px2vw(3)}
                      height={px2vw(50)}
                      background={preto2}
                    ></Col>
                    <Row
                      alignCenter
                      padding={`0 ${px2vw(45)}`}
                      width="100%"
                      justifyStart
                      height={px2vw(50)}
                    >
                      <IconImg
                        width={px2vw(23)}
                        height={px2vw(23)}
                        src={require('../../../assets/images/kuppi_boost_icon/black_rocket.png')}
                      />
                      <Text size={px2vw(12)} color={preto2} bold>
                        Tudo pronto para o seu delivery decolar!
                      </Text>
                    </Row>
                  </>
                ) : statusData === 'bad_configuration' ? (
                  <>
                    <Col
                      margin={`0 ${px2vw(58)}`}
                      width={px2vw(3)}
                      height={px2vw(50)}
                      background={preto2}
                    ></Col>
                    <Row
                      alignCenter
                      padding={`0 ${px2vw(45)}`}
                      width="100%"
                      justifyStart
                      height={px2vw(50)}
                    >
                      <IconImg
                        src={require('../../../assets/images/kuppi_boost_icon/red_fail.png')}
                      />
                      <Col justifyStart>
                        <Text size={px2vw(12)} color={preto2} bold>
                          Acesso com informações incorretas
                        </Text>
                        <Text
                          justifyStart
                          size={px2vw(10)}
                          color={preto2}
                          regular
                        >
                          Nosso robô não consegui realizar as configurações
                          necessárias. Entre em contato com o suporte ou refaça
                          o onboarding com os IDs corretos.
                        </Text>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <>
                    <Col
                      margin={`0 ${px2vw(58)}`}
                      width={px2vw(3)}
                      height={px2vw(50)}
                      background={verdeKuppi1}
                    ></Col>
                    <Row
                      alignCenter
                      padding={`0 ${px2vw(45)}`}
                      width="100%"
                      justifyStart
                      height={px2vw(50)}
                    >
                      <IconImg
                        src={require('../../../assets/images/kuppi_boost_icon/green_ok.png')}
                      />
                      <Text size={px2vw(12)} color={preto2} bold>
                        Confirmação dos acessos
                      </Text>
                    </Row>
                    <Col
                      margin={`0 ${px2vw(58)}`}
                      width={px2vw(3)}
                      height={px2vw(50)}
                      background={verdeKuppi1}
                    ></Col>
                    <Row
                      alignCenter
                      padding={`0 ${px2vw(45)}`}
                      width="100%"
                      justifyStart
                      height={px2vw(50)}
                    >
                      <IconImg
                        width={px2vw(23)}
                        height={px2vw(23)}
                        src={require('../../../assets/images/kuppi_boost_icon/green_rocket.png')}
                      />
                      <Text size={px2vw(12)} color={preto2} bold>
                        Tudo pronto para o seu delivery decolar!
                      </Text>
                    </Row>
                  </>
                )}
              </StatusBox>
              <ImgBox column background="transparent">
                <ImgTrial
                  src={require('../../../assets/images/kuppi_boost/bg_status_page.png')}
                />
                {statusData === 'bad_configuration' ? (
                  <ButtonPrimary
                    size={px2vw(14)}
                    width={px2vw(300)}
                    onClick={() => setIsOnboardingModalVisible(true)}
                  >
                    Refazer Onboarding
                  </ButtonPrimary>
                ) : statusData === 'pending_approval' ? (
                  <Text margtop="auto" size={px2vw(10)} color={preto2} regular>
                    Você poderá iniciar assim que os acessos forem confirmados!
                  </Text>
                ) : (
                  <ButtonPrimary
                    size={px2vw(15)}
                    width={px2vw(260)}
                    onClick={() => dispatch(finishOnboardingRequest())}
                  >
                    {finish_onboarding_loading ? (
                      <Spinner
                        style={{
                          width: px2vw(11),
                          height: px2vw(11),
                          color: branco,
                        }}
                        className="m-auto position-relative"
                      />
                    ) : (
                      'Iniciar'
                    )}
                  </ButtonPrimary>
                )}
              </ImgBox>
            </Row>
          </Col>
        </ContentBox>
      </ContentPage>

      {isOnboardingModalVisible && (
        <KuppiBoostModal
          minheight="100%"
          width={px2vw(750)}
          padding="none"
          onClose={() => setIsOnboardingModalVisible(false)}
          isVisible
        />
      )}
    </>
  );
}
