import styled from 'styled-components';
import { Icon } from 'react-materialize';
import { Link } from 'react-router-dom';

import {
  preto,
  branco,
  cinzaKuppi6,
  verdeKuppi1,
  verdeKuppi2,
  verdeKuppi10,
  vermelhoKuppi1,
  vermelhoKuppi5,
} from '../../../styles/colors';

import px2vw from '../../../utils/responsiveness/px2vw';

export const Container = styled.div`
  width: ${px2vw(230)};
  padding-top: ${px2vw(10)};
  padding-left: ${px2vw(18)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
`;

export const Content = styled.div`
  width: ${px2vw(200)};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  font-family: AvenirNextLTPro;
  font-weight: normal;
`;

export const LogoImageBox = styled.div`
  width: ${px2vw(200)};
  height: ${px2vw(87)};
  margin-top: ${px2vw(10)};
  margin-left: ${px2vw(10)};
  margin-bottom: ${px2vw(-30)};

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: AvenirNextLTPro;
  font-weight: normal;
`;

export const LogoImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: AvenirNextLTPro;
  font-weight: normal;
`;

export const OptionBox = styled(Link)`
  width: 100%;
  height: ${px2vw(40)};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: ${px2vw(4)} 0;
  text-decoration: none !important;
  font-family: AvenirNextLTPro;
  font-weight: normal;
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
    color: ${preto};
  }

  ${(props) =>
    props.optionactive &&
    `
    .text {
      color: ${verdeKuppi1};
    }

    .icon {
      filter: invert(59%) sepia(83%) saturate(389%) hue-rotate(106deg) brightness(93%) contrast(85%);
    }
  `}
`;

export const ChatBotCard = styled(Link)`
  width: 92%;
  height: ${px2vw(45)};
  padding-left: ${px2vw(8)};
  background-color: ${vermelhoKuppi5};
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: ${px2vw(4)} 0;
  text-decoration: none !important;
  font-family: AvenirNextLTPro;
  font-weight: normal;

  ${(props) =>
    props.isActive &&
    `
    background-image: linear-gradient(to right, ${verdeKuppi2}, ${verdeKuppi10});
  `}
`;

export const WppCard = styled(Link)`
  width: 92%;
  height: ${px2vw(45)};
  padding-left: ${px2vw(8)};
  padding-right: ${px2vw(10)};
  background-color: ${branco};
  border-radius: 8px;
  box-shadow: ${(props) =>
    props.isActive
      ? '0px 0px 8px rgba(137, 255, 118, 0.9)'
      : '0px 0px 8px rgba(255, 70, 70, 0.5)'};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: ${px2vw(4)} 0;
  text-decoration: none !important;
  font-family: AvenirNextLTPro;
  font-weight: normal;
`;

export const ChatBotText = styled.div`
  color: ${(props) => `${props.color || branco}`};
  font-size: ${px2vw(11)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  ${(props) =>
    props.title &&
    `
    color: ${props.color || branco};
    font-size: ${px2vw(12)};
    font-weight: bold;
  `}
`;

export const StoreStatusBox = styled(Link)`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-decoration: none !important;
  font-family: AvenirNextLTPro;
  font-weight: normal;
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
    color: ${preto};
  }
  margin-top: ${px2vw(15)};
  margin-bottom: ${px2vw(-5)};

  ${(props) => props.isOpen && `
    cursor: default;
  `}
`;

export const StoreStatusIndicator = styled.div`
  background-color: ${vermelhoKuppi5};
  font-size: ${px2vw(16)};
  font-weight: bold;
  border-radius: ${px2vw(15)};
  width: ${px2vw(15.5)};
  height: ${px2vw(15)};
  margin-right: ${px2vw(10)};
  margin-top: ${px2vw(1)};

  ${(props) => props.isOpen && `
    background-color: ${verdeKuppi2};
  `}
`;

export const StoreStatusText = styled.div`
  color: ${preto};
  font-size: ${px2vw(10)};

  ${(props) => props.title && `
    font-size: ${px2vw(12)};
    font-weight: bold;
  `}
`;

export const OptionSeparator = styled.hr`
  width: 100%;
  opacity: 5%;
  margin-top: ${px2vw(10)};
  margin-bottom: ${px2vw(10)};
  background-color: ${preto};
`;

export const MenuIconBox = styled.div`
  position: relative;
  width: ${px2vw(23)};
  height: ${px2vw(23)};

  ${(props) => `
    margin-left: ${props.margleft};
    width: ${props.size};
    height: ${props.size};
  `};
`;

export const MenuIconImg = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
`;

export const MenuOption = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding-top: 7px;
  margin-bottom: 8px;
  text-decoration: none;
  width: 100%;
  height: 43px;
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
    color: ${preto};
  }

  ${(props) =>
    props.optionActive &&
    `
    .text {
      color: ${verdeKuppi1};
    }

    .icon {
      filter: invert(59%) sepia(83%) saturate(389%) hue-rotate(106deg) brightness(93%) contrast(85%);
    }
  `}
`;

export const OptionText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  margin-left: ${px2vw(14)};
  color: ${(props) => props.fcolor ? props.fcolor : cinzaKuppi6};
  font-size: ${(props) => props.fs ? props.fs : px2vw(13)};
  font-family: AvenirNextLTPro;
  font-weight: normal;

  ${(props) => `
    margin-right: ${props.margright};
    margin-left: ${props.margleft};
    margin-top: ${props.margtop};
  `};

  ${(props) =>
    props.optiontitle &&
    `
    margin-left: 0px;
    margin-bottom: ${px2vw(10)};
  `};

  .badge {
    font-size: ${px2vw(9)};
    font-family: AvenirNextLTPro;
    font-weight: 700;
    margin-left: ${px2vw(5)};
    background: ${verdeKuppi1};
    padding: 0.3em 0.4em !important;
  }
`;

export const Title = styled.div`
  margin-bottom: ${px2vw(50)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
`;

export const IconOption = styled(Icon)`
  color: ${cinzaKuppi6};
`;

export const SelectedBar = styled.div`
  background: ${(props) => (props.optionActive ? verdeKuppi1 : 'transparent')};
  width: ${px2vw(5)};
  height: 100%;
  border-radius: ${px2vw(5)};
  margin-left: auto;
  margin-right: ${px2vw(-11)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
`;

export const LogoutButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  border: none;
  background: transparent;
  margin-left: ${px2vw(-15)};
  margin-bottom: ${px2vw(20)};
`;

export const QtdNewOrders = styled.div`
  font-size: ${px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.4px;
  border-radius: ${px2vw(13)};
  width: ${px2vw(26)};
  height: ${px2vw(26)};
  background-color: transparent;
  color: transparent;
  margin-left: ${px2vw(10)};
  padding-top: ${px2vw(3)};
  text-align: center;

  ${(props) =>
    props.isVisible &&
    `
      background-color: ${vermelhoKuppi1};
      color: ${branco};
  `};
`;
