import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS } from 'chart.js/auto';
import { ChartContainer, ChartOptionColorBox, ChartOptionName } from './styles';
import { Col, Row } from '../../styles/grid';
import px2vw from '../../utils/responsiveness/px2vw';

export default function DoughnutChart({ chartData, chartWidth, chartLegends, needMargTop }) {
  return (
    <Row width="auto" height="auto">
      <Col width="auto" height="auto">
        <ChartContainer width={chartWidth}>
          <Doughnut data={chartData} />
        </ChartContainer>
      </Col>
      <Col width="auto" margtop={needMargTop ? px2vw(75) : px2vw(50)} margleft={px2vw(20)}>
        {chartLegends.map((legend, index) => {
          return (
            <Row width="auto" height="auto" margbottom={px2vw(10)} key={`Legend_Key: ${index}`}>
              <Col width="auto" height="auto">
                <ChartOptionColorBox bgcolor={legend.color} />
              </Col>
              <Col
                width="auto"
                height="auto"
                margleft={px2vw(10)}
                margtop={px2vw(2)}
              >
                <ChartOptionName>
                  {legend.name}:{` ${legend.ordersQty.toFixed(2)}%`}
                </ChartOptionName>
              </Col>
            </Row>
          );
        })}
      </Col>
    </Row>
  );
}
