import React from 'react';

import CheckIcon from '../../assets/images/onboarding/Vector@2x.png';

import px2vw from '../../utils/responsiveness/px2vw';

import {
  CheckboxContainer,
  HiddenCheckbox,
  Text,
  StyledCheckbox,
} from './styles';

export default function Checkbox({ 
  label, 
  onChange, 
  checked, 
  disabled, 
  onClick, 
  padtop = 0,
  labelmarginleft = 0,
  fontSize = 14, 
  styledBoxWidth = 25,
  styledBoxHeight = 20,
  imgWidth = 15,
  height = 30,
  insideWidth = 500
}) {
  return (
    <>
      <CheckboxContainer 
        padtop={padtop}
        checked={checked} 
        onClick={onClick} 
        height={height}
        insideWidth={insideWidth}
        
        >
        <HiddenCheckbox checked={checked} />
        <StyledCheckbox
          type="checkbox"
          checked={checked}
          onChange={onChange}
          disabled={disabled}
          width={styledBoxWidth}
          height={styledBoxHeight}
        >
          <img
            style={{ width: `${px2vw(imgWidth)}` }}
            alt="Check Icon"
            src={checked && CheckIcon}
          />
        </StyledCheckbox>
        <Text fontSize={fontSize} marginleft={labelmarginleft}>{label}</Text>
      </CheckboxContainer>
    </>
  );
}
