import { takeLatest, call, put, all, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { apiAnunciante } from '../../../services/api';

import {
  updateStoreAvailabilitySuccess,
  updateStoreAvailabilityFailure,
  updateStorePaymentMethodSuccess,
  updateStorePaymentMethodFailure,
  updateStoreDeliveryTakeAwaySuccess,
  updateStoreDeliveryTakeAwayFailure,
  getSelectedNeighborhoodsSuccess,
  getSelectedNeighborhoodsFailure,
  pauseNeighborhoodSuccess,
  pauseNeighborhoodFailure,
  updateNeighborhoodSuccess,
  updateNeighborhoodFailure,
  deleteNeighborhoodSuccess,
  deleteNeighborhoodFailure,
  getLocalitiesSuccess,
  getLocalitiesFailure,
  getDistrictsSuccess,
  getDistrictsFailure,
  addNewNeighborhoodSuccess,
  addNewNeighborhoodFailure,
  updateStoreSchedulingAvailabilitySuccess,
  updateStoreSchedulingAvailabilityFailure,
  getAdvertiserPaymentMethodsSuccess,
  getAdvertiserPaymentMethodsFailure,
} from './actions';
import { updateUserInfoSuccess } from '../user/actions';
import { logout } from '../auth/actions';

import {
  sendStoreAvailability,
  sendStoreDeliveryTakeAwayConfigs,
  sendStoreScheduling,
} from '../../../services/vendors/mixpanel';

const userReducerData = (state) => state.user;
const userData = (state) => state.user.user;

export function* updateStoreAvailabilityRequest({ payload }) {
  try {
    const user = yield select(userData);
    const userReducer = yield select(userReducerData);

    // Create store availability
    const sundayJson = { is_active: false, horaries: [] };
    const mondayJson = { is_active: false, horaries: [] };
    const tuesdayJson = { is_active: false, horaries: [] };
    const wednesdayJson = { is_active: false, horaries: [] };
    const thursdayJson = { is_active: false, horaries: [] };
    const fridayJson = { is_active: false, horaries: [] };
    const saturdayJson = { is_active: false, horaries: [] };

    if (payload.sunEnabled) {
      payload.sunHoraries.forEach((horaries) => {
        let startTime = horaries.start_time;
        let endTime = horaries.end_time;
        if (startTime === '' || endTime === '' || startTime === endTime) {
          startTime = '00:00';
          endTime = '00:00';
        } else if (
          startTime !== '00:00' &&
          (endTime === '00:00' || endTime === '24:00')
        ) {
          endTime = '23:59';
        }

        sundayJson.is_active = true;

        const startHour = parseInt(startTime.substring(0, 2), 10);
        const endHour = parseInt(endTime.substring(0, 2), 10);
        if (endHour < startHour) {
          // verification of the need to update the time of dawn of the next day
          if (startHour > 0 && endTime !== '23:59') {
            const monHorariesLength = payload.monHoraries.length;
            const mondayFirstHorary = monHorariesLength
              ? payload.monHoraries[0].start_time
              : false;
            if (mondayFirstHorary === '00:00') {
              payload.monHoraries.shift();
            }

            sundayJson.horaries.push({
              start_at: startTime,
              end_at: '23:59',
            });

            mondayJson.is_active = true;
            mondayJson.horaries.unshift({
              start_at: '00:00',
              end_at: endTime,
            });
          } else {
            sundayJson.horaries.push({
              start_at: startTime,
              end_at: endTime,
            });
          }
        } else {
          sundayJson.horaries.push({
            start_at: startTime,
            end_at: endTime,
          });
        }
      });
    }
    if (payload.monEnabled) {
      const sundayJsonLength = sundayJson.horaries.length;
      const mondayJsonLength = mondayJson.horaries.length;
      // Checks if the previous day's early morning shift has been removed
      if (mondayJsonLength && mondayJson.horaries[0].start_at !== '00:00') {
        payload.monHoraries.shift();
      }

      // Verification of the need to remove the morning time of the current day, if the last time of the previous day is not after midnight.
      if (sundayJsonLength) {
        const sundayLastHorary =
          sundayJson.horaries[sundayJsonLength - 1].end_at;
        const mondayFirstHorary = payload.monHoraries.length
          ? payload.monHoraries[0].start_time
          : false;

        if (
          sundayLastHorary !== '23:59' &&
          mondayFirstHorary === '00:00' &&
          !mondayJsonLength
        ) {
          payload.monHoraries.shift();
        }
      }

      payload.monHoraries.forEach((horaries) => {
        let startTime = horaries.start_time;
        let endTime = horaries.end_time;

        if (startTime === '' || endTime === '' || startTime === endTime) {
          startTime = '00:00';
          endTime = '00:00';
        } else if (
          startTime !== '00:00' &&
          (endTime === '00:00' || endTime === '24:00')
        ) {
          endTime = '23:59';
        }

        mondayJson.is_active = true;

        const startHour = parseInt(startTime.substring(0, 2), 10);
        const endHour = parseInt(endTime.substring(0, 2), 10);
        if (endHour < startHour) {
          // verification of the need to update the time of dawn of the next day
          if (startHour > 0 && endTime !== '23:59') {
            const tueHorariesLength = payload.tueHoraries.length;
            const tuesdayFirstHorary = tueHorariesLength
              ? payload.tueHoraries[0].start_time
              : false;
            if (tuesdayFirstHorary === '00:00') {
              payload.tueHoraries.shift();
            }

            mondayJson.horaries.push({
              start_at: startTime,
              end_at: '23:59',
            });
            tuesdayJson.is_active = true;
            tuesdayJson.horaries.unshift({
              start_at: '00:00',
              end_at: endTime,
            });
          } else {
            mondayJson.horaries.push({
              start_at: startTime,
              end_at: endTime,
            });
          }
        } else {
          mondayJson.horaries.push({
            start_at: startTime,
            end_at: endTime,
          });
        }
      });
    }
    if (payload.tueEnabled) {
      const mondayJsonLength = mondayJson.horaries.length;
      const tuesdayJsonLength = tuesdayJson.horaries.length;
      // Checks if the previous day's early morning shift has been removed
      if (tuesdayJsonLength && tuesdayJson.horaries[0].start_at !== '00:00') {
        payload.tueHoraries.shift();
      }
      //Verification of the need to remove the morning time of the current day, if the last time of the previous day is not after midnight.
      if (mondayJsonLength) {
        const mondayLastHorary =
          mondayJson.horaries[mondayJsonLength - 1].end_at;
        const tuesdayFirstHorary = payload.tueHoraries.length
          ? payload.tueHoraries[0].start_time
          : false;

        if (
          mondayLastHorary !== '23:59' &&
          tuesdayFirstHorary === '00:00' &&
          !tuesdayJsonLength
        ) {
          payload.tueHoraries.shift();
        }
      }

      payload.tueHoraries.forEach((horaries) => {
        let startTime = horaries.start_time;
        let endTime = horaries.end_time;
        if (startTime === '' || endTime === '' || startTime === endTime) {
          startTime = '00:00';
          endTime = '00:00';
        } else if (
          startTime !== '00:00' &&
          (endTime === '00:00' || endTime === '24:00')
        ) {
          endTime = '23:59';
        }

        tuesdayJson.is_active = true;

        const startHour = parseInt(startTime.substring(0, 2), 10);
        const endHour = parseInt(endTime.substring(0, 2), 10);
        if (endHour < startHour) {
          // verification of the need to update the time of dawn of the next day
          if (startHour > 0 && endTime !== '23:59') {
            const wedHorariesLength = payload.wedHoraries.length;
            const wednesdayFirstHorary = wedHorariesLength
              ? payload.wedHoraries[0].start_time
              : false;
            if (wednesdayFirstHorary === '00:00') {
              payload.wedHoraries.shift();
            }

            tuesdayJson.horaries.push({
              start_at: startTime,
              end_at: '23:59',
            });
            wednesdayJson.is_active = true;
            wednesdayJson.horaries.unshift({
              start_at: '00:00',
              end_at: endTime,
            });
          } else {
            tuesdayJson.horaries.push({
              start_at: startTime,
              end_at: endTime,
            });
          }
        } else {
          tuesdayJson.horaries.push({
            start_at: startTime,
            end_at: endTime,
          });
        }
      });
    }
    if (payload.wedEnabled) {
      const tuesdayJsonLength = tuesdayJson.horaries.length;
      const wednesdayJsonLength = wednesdayJson.horaries.length;
      // Checks if the previous day's early morning shift has been removed
      if (
        wednesdayJsonLength &&
        wednesdayJson.horaries[0].start_at !== '00:00'
      ) {
        payload.wedHoraries.shift();
      }
      // Verification of the need to remove the morning time of the current day, if the last time of the previous day is not after midnight.
      if (tuesdayJsonLength) {
        const tuesdayLastHorary =
          tuesdayJson.horaries[tuesdayJsonLength - 1].end_at;
        const wednesdayFirstHorary = payload.wedHoraries.length
          ? payload.wedHoraries[0].start_time
          : false;

        if (
          tuesdayLastHorary !== '23:59' &&
          wednesdayFirstHorary === '00:00' &&
          !wednesdayJsonLength
        ) {
          payload.wedHoraries.shift();
        }
      }

      payload.wedHoraries.forEach((horaries) => {
        let startTime = horaries.start_time;
        let endTime = horaries.end_time;
        if (startTime === '' || endTime === '' || startTime === endTime) {
          startTime = '00:00';
          endTime = '00:00';
        } else if (
          startTime !== '00:00' &&
          (endTime === '00:00' || endTime === '24:00')
        ) {
          endTime = '23:59';
        }

        wednesdayJson.is_active = true;

        const startHour = parseInt(startTime.substring(0, 2), 10);
        const endHour = parseInt(endTime.substring(0, 2), 10);
        if (endHour < startHour) {
          // verification of the need to update the time of dawn of the next day
          if (startHour > 0 && endTime !== '23:59') {
            const thuHorariesLength = payload.thuHoraries.length;
            const thursdayFirstHorary = thuHorariesLength
              ? payload.thuHoraries[0].start_time
              : false;
            if (thursdayFirstHorary === '00:00') {
              payload.thuHoraries.shift();
            }

            wednesdayJson.horaries.push({
              start_at: startTime,
              end_at: '23:59',
            });
            thursdayJson.is_active = true;
            thursdayJson.horaries.unshift({
              start_at: '00:00',
              end_at: endTime,
            });
          } else {
            wednesdayJson.horaries.push({
              start_at: startTime,
              end_at: endTime,
            });
          }
        } else {
          wednesdayJson.horaries.push({
            start_at: startTime,
            end_at: endTime,
          });
        }
      });
    }
    if (payload.thuEnabled) {
      const wednesdayJsonLength = wednesdayJson.horaries.length;
      const thursdayJsonLength = thursdayJson.horaries.length;
      // Checks if the previous day's early morning shift has been removed
      if (thursdayJsonLength && thursdayJson.horaries[0].start_at !== '00:00') {
        payload.thuHoraries.shift();
      }

      if (wednesdayJsonLength) {
        const wednesdayLastHorary =
          wednesdayJson.horaries[wednesdayJsonLength - 1].end_at;
        const thursdayFirstHorary = payload.thuHoraries.length
          ? payload.thuHoraries[0].start_time
          : false;

        if (
          wednesdayLastHorary !== '23:59' &&
          thursdayFirstHorary === '00:00' &&
          !thursdayJsonLength
        ) {
          payload.thuHoraries.shift();
        }
      }

      payload.thuHoraries.forEach((horaries) => {
        let startTime = horaries.start_time;
        let endTime = horaries.end_time;
        if (startTime === '' || endTime === '' || startTime === endTime) {
          startTime = '00:00';
          endTime = '00:00';
        } else if (
          startTime !== '00:00' &&
          (endTime === '00:00' || endTime === '24:00')
        ) {
          endTime = '23:59';
        }

        thursdayJson.is_active = true;

        const startHour = parseInt(startTime.substring(0, 2), 10);
        const endHour = parseInt(endTime.substring(0, 2), 10);
        if (endHour < startHour) {
          // verification of the need to update the time of dawn of the next day
          if (startHour > 0 && endTime !== '23:59') {
            const friHorariesLength = payload.friHoraries.length;
            const fridayFirstHorary = friHorariesLength
              ? payload.friHoraries[0].start_time
              : false;
            if (fridayFirstHorary === '00:00') {
              payload.friHoraries.shift();
            }

            thursdayJson.horaries.push({
              start_at: startTime,
              end_at: '23:59',
            });
            fridayJson.is_active = true;
            fridayJson.horaries.unshift({
              start_at: '00:00',
              end_at: endTime,
            });
          } else {
            thursdayJson.horaries.push({
              start_at: startTime,
              end_at: endTime,
            });
          }
        } else {
          thursdayJson.horaries.push({
            start_at: startTime,
            end_at: endTime,
          });
        }
      });
    }
    if (payload.friEnabled) {
      const thursdayJsonLength = thursdayJson.horaries.length;
      const fridayJsonLength = fridayJson.horaries.length;
      // Checks if the previous day's early morning shift has been removed
      if (fridayJsonLength && fridayJson.horaries[0].start_at !== '00:00') {
        payload.friHoraries.shift();
      }

      // Verification of the need to remove the morning time of the current day, if the last time of the previous day is not after midnight.
      if (thursdayJsonLength) {
        const thursdayLastHorary =
          thursdayJson.horaries[thursdayJsonLength - 1].end_at;

        const fridayFirstHorary = payload.friHoraries.length
          ? payload.friHoraries[0].start_time
          : false;

        if (
          thursdayLastHorary !== '23:59' &&
          fridayFirstHorary === '00:00' &&
          !fridayJsonLength
        ) {
          payload.friHoraries.shift();
        }
      }

      payload.friHoraries.forEach((horaries) => {
        let startTime = horaries.start_time;
        let endTime = horaries.end_time;
        if (startTime === '' || endTime === '' || startTime === endTime) {
          startTime = '00:00';
          endTime = '00:00';
        } else if (
          startTime !== '00:00' &&
          (endTime === '00:00' || endTime === '24:00')
        ) {
          endTime = '23:59';
        }

        fridayJson.is_active = true;

        const startHour = parseInt(startTime.substring(0, 2), 10);
        const endHour = parseInt(endTime.substring(0, 2), 10);
        if (endHour < startHour) {
          // Verification of the need to update the time of dawn of the next day.
          if (startHour > 0 && endTime !== '23:59') {
            const satHorariesLength = payload.satHoraries.length;
            const saturdayFirstHorary = satHorariesLength
              ? payload.satHoraries[0].start_time
              : false;
            if (saturdayFirstHorary === '00:00') {
              payload.satHoraries.shift();
            }

            fridayJson.horaries.push({
              start_at: startTime,
              end_at: '23:59',
            });
            saturdayJson.is_active = true;
            saturdayJson.horaries.unshift({
              start_at: '00:00',
              end_at: endTime,
            });
          } else {
            fridayJson.horaries.push({
              start_at: startTime,
              end_at: endTime,
            });
          }
        } else {
          fridayJson.horaries.push({
            start_at: startTime,
            end_at: endTime,
          });
        }
      });
    }
    if (payload.satEnabled) {
      const fridayJsonLength = fridayJson.horaries.length;
      const saturdayJsonLength = saturdayJson.horaries.length;
      const satHorariesLength = payload.satHoraries.length;
      let sunHasMorningShift = false;
      // Checks if the previous day's early morning shift has been removed
      if (saturdayJsonLength && saturdayJson.horaries[0].start_at !== '00:00') {
        payload.satHoraries.shift();
      }

    // Verification of the need to remove the morning time of the current day, if the last time of the previous day is not after midnight.
      if (fridayJsonLength) {
        const fridayLastHorary =
          fridayJson.horaries[fridayJsonLength - 1].end_at;
        const saturdayFirstHorary = satHorariesLength
          ? payload.satHoraries[0].start_time
          : false;

        if (
          fridayLastHorary !== '23:59' &&
          saturdayFirstHorary === '00:00' &&
          !saturdayJsonLength
        ) {
          payload.satHoraries.shift();
        }
      }

      payload.satHoraries.forEach((horaries) => {
        let startTime = horaries.start_time;
        let endTime = horaries.end_time;
        if (startTime === '' || endTime === '' || startTime === endTime) {
          startTime = '00:00';
          endTime = '00:00';
        } else if (
          startTime !== '00:00' &&
          (endTime === '00:00' || endTime === '24:00')
        ) {
          endTime = '23:59';
        }

        saturdayJson.is_active = true;

        const startHour = parseInt(startTime.substring(0, 2), 10);
        const endHour = parseInt(endTime.substring(0, 2), 10);
        if (endHour < startHour) {
          // verification of the need to update the time of dawn of the next day
          if (startHour > 0 && endTime !== '23:59') {
            const sunHorariesLength = payload.sunHoraries.length;
            const sundayFirstHorary = sunHorariesLength
              ? payload.sunHoraries[0].start_time
              : false;
            if (sundayFirstHorary === '00:00') {
              sundayJson.horaries.shift();
            }

            saturdayJson.horaries.push({
              start_at: startTime,
              end_at: '23:59',
            });
            sundayJson.is_active = true;
            sundayJson.horaries.unshift({
              start_at: '00:00',
              end_at: endTime,
            });
            sunHasMorningShift = true;
          } else {
            saturdayJson.horaries.push({
              start_at: startTime,
              end_at: endTime,
            });
          }
        } else {
          saturdayJson.horaries.push({
            start_at: startTime,
            end_at: endTime,
          });
        }
      });

      if (payload.satHoraries.length) {
        const saturdayLastHorary =
          satHorariesLength > 1
            ? payload.satHoraries[satHorariesLength - 1].end_time
            : payload.satHoraries[0].end_time;
        const sundayFirstHorary = sundayJson.horaries.length
          ? sundayJson.horaries[0].start_at
          : false;

        if (
          !sunHasMorningShift &&
          saturdayLastHorary !== '23:59' &&
          sundayFirstHorary === '00:00'
        ) {
          sundayJson.horaries.shift();
        }
      }
    }

    const response = yield call(
      apiAnunciante.put,
      `/user-info/${user.advertiserInfo.id}/availabilities`,
      {
        sunday: sundayJson,
        monday: mondayJson,
        tuesday: tuesdayJson,
        wednesday: wednesdayJson,
        thursday: thursdayJson,
        friday: fridayJson,
        saturday: saturdayJson,
      }
    );

    //MixPanel Event
    sendStoreAvailability({
      id: user.advertiserInfo.id,
      name: user.advertiserInfo.name,
      email: user.email,
      sunday: sundayJson,
      monday: mondayJson,
      tuesday: tuesdayJson,
      wednesday: wednesdayJson,
      thursday: thursdayJson,
      friday: fridayJson,
      saturday: saturdayJson,
      hasFreeTrialValid: userReducer.hasFreeTrialValid,
    });

    // Save in redux the user info data
    yield put(updateUserInfoSuccess(response.data));
    yield put(updateStoreAvailabilitySuccess());
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(updateStoreAvailabilityFailure());
      toast.error(
        'Ocorreu um erro ao atualizar os horários de funcionamento. Revise os dados e tente novamente!',
        {
          autoClose: 6000,
        }
      );
    }
  }
}

export function* updateStorePaymentMethodRequest({ payload }) {
  try {
    const user = yield select(userData);

    const paymentMethodRequest = payload.paymentMethods.map((paymentMethod) => {
      if (paymentMethod.id) {
        return {
          id: paymentMethod.id,
          is_active: paymentMethod.is_active,
          external_processor_key: paymentMethod.external_processor_key,
        };
      }
    });
    yield call(
      apiAnunciante.put,
      `/user-info/${user.advertiserInfo.id}/payment-methods`,
      {
        payment_methods: paymentMethodRequest,
      }
    );

    yield put(updateStorePaymentMethodSuccess(payload.paymentMethods));
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(updateStorePaymentMethodFailure());
      toast.error(
        'Ocorreu um erro ao atualizar as formas de pagamento. Tente novamente!',
        {
          autoClose: 6000,
        }
      );
    }
  }
}

export function* updateStoreDeliveryTakeAwayRequest({ payload }) {
  try {
    const user = yield select(userData);
    const userReducer = yield select(userReducerData);

    const requestPayloadObj = {
      is_using_delivery: payload.deliveryActive,
      is_using_range_area: payload.useRadiusArea,
      is_using_neighborhood_area: payload.useNeighborhoodArea,
      is_using_take_away: payload.takeAwayActive,
    };

    // Order minimum amount
    let orderMinimumAmount = 0;
    if (payload.orderMinimumAmount) {
      orderMinimumAmount = payload.orderMinimumAmountInputValue;
    }
    // Include order minimum amount in the request obj
    requestPayloadObj.order_minimum_amount = orderMinimumAmount;

    // Delivery active
    if (payload.deliveryActive) {
      // Delivery time avg
      const deliveryTimeAvg =
        payload.deliveryTimeAvg &&
        payload.deliveryTimeAvgInputValue.trim() !== ''
          ? payload.deliveryTimeAvgInputValue
          : 0;
      // Include delivery time avg in the request obj
      requestPayloadObj.delivery_time_avg = deliveryTimeAvg;

      // Radius area
      if (payload.useRadiusArea) {
        // Delivery range area
        let deliveryRangeArea = 1;
        if (payload.maxDistInputValue !== '') {
          deliveryRangeArea = payload.maxDistInputValue;
        }
        // Include delivery range area in the request obj
        requestPayloadObj.delivery_range_area = deliveryRangeArea;

        // Delivery fee data
        let deliveryFee = 0;
        let dynamicDeliveryFee = 0;
        let dynamicMaxDistApply = 0;
        let dynamicExceededFeeAmount = 0;
        let dynamicFreeDist = 0;
        let dynamicFeeCalcType = 'route';

        // Unique fee for all deliveries
        if (payload.deliveryFee) {
          deliveryFee = payload.deliveryFeeInputValue;
        } else if (payload.dynamicDeliveryFee) {
          // Dynamic delivery fee
          dynamicDeliveryFee = payload.dynamicDeliveryFeeAmountInputValue;
          dynamicMaxDistApply = payload.dynamicMaxDistApplyInputValue;
          dynamicExceededFeeAmount = payload.dynamicExceededFeeAmountInputValue;
          if (payload.dynamicFreeDeliveryAtDistActiveInputValue) {
            dynamicFreeDist = payload.dynamicFreeDeliveryAtDistInputValue;
          }
        }
        // Delivery fee calc type (route or radius)
        if (payload.dynamicFeeCalcTypeRadiusInputValue) {
          dynamicFeeCalcType = 'radius';
        }
        // Include delivery fee data in the request obj
        requestPayloadObj.delivery_range_area = deliveryRangeArea;
        requestPayloadObj.delivery_fee = deliveryFee;
        requestPayloadObj.delivery_dynamic_fee = dynamicDeliveryFee;
        requestPayloadObj.delivery_dynamic_fee_max_dist = dynamicMaxDistApply;
        requestPayloadObj.delivery_dynamic_fee_exceeded_amount =
          dynamicExceededFeeAmount;
        requestPayloadObj.delivery_dynamic_fee_free_dist = dynamicFreeDist;
        requestPayloadObj.delivery_dynamic_fee_calc_type = dynamicFeeCalcType;
      }
    }

    // Take Away active
    if (payload.takeAwayActive) {
      // Take Away Avg time
      const takeAwayTimeAvg =
        payload.takeAwayTimeAvgInputValue.trim() !== ''
          ? payload.takeAwayTimeAvgInputValue
          : 0;
      // Include take away avg in the request obj
      requestPayloadObj.take_away_time_avg = takeAwayTimeAvg;
    }

    const response = yield call(
      apiAnunciante.put,
      `/user/${user.id}/user-info`,
      requestPayloadObj
    );

    // Save in redux the user info data
    yield put(updateUserInfoSuccess(response.data));
    yield put(updateStoreDeliveryTakeAwaySuccess());

    //MixPanel Event
    sendStoreDeliveryTakeAwayConfigs({
      id: user.advertiserInfo.id,
      name: user.advertiserInfo.name,
      email: user.email,
      delivery_range_area: requestPayloadObj.delivery_range_area,
      delivery_fee: requestPayloadObj.delivery_fee,
      delivery_dynamic_fee: requestPayloadObj.delivery_dynamic_fee,
      delivery_dynamic_fee_max_dist:
        requestPayloadObj.delivery_dynamic_fee_max_dist,
      delivery_dynamic_fee_exceeded_amount:
        requestPayloadObj.delivery_dynamic_fee_exceeded_amount,
      delivery_dynamic_fee_free_dist:
        requestPayloadObj.delivery_dynamic_fee_free_dist,
      delivery_dynamic_fee_calc_type:
        requestPayloadObj.delivery_dynamic_fee_calc_type,
      hasFreeTrialValid: userReducer.hasFreeTrialValid,
    });
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(updateStoreDeliveryTakeAwayFailure());
      toast.error(
        'Ocorreu um erro ao atualizar os parâmetros de delivery / retirada. Tente novamente!',
        {
          autoClose: 6000,
        }
      );
    }
  }
}

export function* getSelectedNeighborhoodsRequest() {
  try {
    const user = yield select(userData);

    const response = yield call(
      apiAnunciante.get,
      `/user-info/${user.advertiserInfo.id}/neighborhood-area-configs`
    );

    yield put(getSelectedNeighborhoodsSuccess(response.data));
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(getSelectedNeighborhoodsFailure());
    }
  }
}

export function* pauseNeighborhoodRequest({ payload }) {
  try {
    const user = yield select(userData);

    const response = yield call(
      apiAnunciante.put,
      `/user-info/${user.advertiserInfo.id}/neighborhood-area-config/${payload.selectedNeighborhoodId}`,
      {
        is_active: !payload.isActive,
      }
    );

    yield put(pauseNeighborhoodSuccess(response.data));
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      if (err?.response?.data?.error?.message) {
        toast.error(err.response.data.error.message, {
          autoClose: 6000,
        });
      } else if (payload.isActive) {
        toast.error(
          'Não conseguimos desativar a entrega no bairro. Tente novamente e caso o erro persista, contate o suporte.',
          {
            autoClose: 6000,
          }
        );
      } else {
        toast.error(
          'Não conseguimos ativar a entrega no bairro. Tente novamente e caso o erro persista, contate o suporte.',
          {
            autoClose: 6000,
          }
        );
      }

      yield put(pauseNeighborhoodFailure());
    }
  }
}

export function* updateNeighborhoodRequest({ payload }) {
  try {
    const user = yield select(userData);

    const response = yield call(
      apiAnunciante.put,
      `/user-info/${user.advertiserInfo.id}/neighborhood-area-config/${payload.selectedNeighborhoodId}`,
      {
        delivery_fee: payload.deliveryFee,
      }
    );

    yield put(updateNeighborhoodSuccess(response.data));
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      if (err?.response?.data?.error?.message) {
        toast.error(err.response.data.error.message, {
          autoClose: 6000,
        });
      } else {
        toast.error(
          'Não conseguimos atualizar a entrega no bairro. Tente novamente e caso o erro persista, contate o suporte.',
          {
            autoClose: 6000,
          }
        );
      }

      yield put(updateNeighborhoodFailure());
    }
  }
}

export function* deleteNeighborhoodRequest({ payload }) {
  try {
    const user = yield select(userData);

    const response = yield call(
      apiAnunciante.delete,
      `/user-info/${user.advertiserInfo.id}/neighborhood-area-config/${payload.selectedNeighborhoodId}`
    );

    yield put(deleteNeighborhoodSuccess(response.data));
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      if (err?.response?.data?.error?.message) {
        toast.error(err.response.data.error.message, {
          autoClose: 6000,
        });
      } else {
        toast.error(
          'Não conseguimos excluir a entrega no bairro. Tente novamente e caso o erro persista, contate o suporte.',
          {
            autoClose: 6000,
          }
        );
      }

      yield put(deleteNeighborhoodFailure());
    }
  }
}

export function* getLocalitiesRequest({ payload }) {
  try {
    const response = yield call(
      apiAnunciante.get,
      `/localities?page=1&limit=1700&uf=${payload.selectedState}`
    );

    yield put(getLocalitiesSuccess(response.data.data));
  } catch (err) {
    toast.error('Ocorreu um erro ao carregar os municípios, tente novamente!', {
      autoClose: 6000,
    });

    yield put(getLocalitiesFailure());
  }
}

export function* getDistrictsRequest({ payload }) {
  try {
    const response = yield call(
      apiAnunciante.get,
      `/districts?page=1&limit=2200&locality_id=${payload.localityId}`
    );

    yield put(getDistrictsSuccess(response.data.data));
  } catch (err) {
    toast.error(
      'Ocorreu um erro ao carregar os bairros do município selecionado, tente novamente!',
      {
        autoClose: 6000,
      }
    );

    yield put(getDistrictsFailure());
  }
}

export function* addNewNeighborhoodRequest({ payload }) {
  try {
    const user = yield select(userData);

    const response = yield call(
      apiAnunciante.post,
      `/user-info/${user.advertiserInfo.id}/neighborhood-area-config`,
      {
        district_id: payload.districtId,
        delivery_fee: payload.deliveryFee,
        is_active: true,
      }
    );

    yield put(addNewNeighborhoodSuccess(response.data));
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      if (err.response?.status === 401) {
        yield put(logout(true));
      } else {
        if (err?.response?.data?.error?.message) {
          toast.error(err.response.data.error.message, {
            autoClose: 6000,
          });
        } else {
          toast.error(
            'Não conseguimos incluir a taxa para o bairro escolhido. Tente novamente e caso o erro persista, contate o suporte.',
            {
              autoClose: 6000,
            }
          );
        }

        yield put(addNewNeighborhoodFailure());
      }
    }
  }
}

export function* updateStoreSchedulingAvailabilityRequest({ payload }) {
  try {
    const user = yield select(userData);
    const userReducer = yield select(userReducerData);

    // Create store scheduling availability
    const sundayJson = { is_active: false, horaries: [] };
    const mondayJson = { is_active: false, horaries: [] };
    const tuesdayJson = { is_active: false, horaries: [] };
    const wednesdayJson = { is_active: false, horaries: [] };
    const thursdayJson = { is_active: false, horaries: [] };
    const fridayJson = { is_active: false, horaries: [] };
    const saturdayJson = { is_active: false, horaries: [] };

    if (payload.sunEnabled) {
      let startTime = payload.sunStartTime;
      let endTime = payload.sunEndTime;
      if (startTime === '' || endTime === '' || startTime === endTime) {
        startTime = '00:00';
        endTime = '00:00';
      } else if (
        startTime !== '00:00' &&
        (endTime === '00:00' || endTime === '24:00')
      ) {
        endTime = '23:59';
      }

      sundayJson.is_active = true;

      const startHour = parseInt(startTime.substring(0, 2), 10);
      const endHour = parseInt(endTime.substring(0, 2), 10);
      if (endHour < startHour) {
        if (startHour > 0 && endTime !== '23:59') {
          sundayJson.horaries.push({
            start_at: startTime,
            end_at: '23:59',
          });
          mondayJson.is_active = true;
          mondayJson.horaries.push({
            start_at: '00:00',
            end_at: endTime,
          });
        } else {
          sundayJson.horaries.push({
            start_at: startTime,
            end_at: endTime,
          });
        }
      } else {
        sundayJson.horaries.push({
          start_at: startTime,
          end_at: endTime,
        });
      }
    }
    if (payload.monEnabled) {
      let startTime = payload.monStartTime;
      let endTime = payload.monEndTime;
      if (startTime === '' || endTime === '' || startTime === endTime) {
        startTime = '00:00';
        endTime = '00:00';
      } else if (
        startTime !== '00:00' &&
        (endTime === '00:00' || endTime === '24:00')
      ) {
        endTime = '23:59';
      }

      mondayJson.is_active = true;

      const startHour = parseInt(startTime.substring(0, 2), 10);
      const endHour = parseInt(endTime.substring(0, 2), 10);
      if (endHour < startHour) {
        if (startHour > 0 && endTime !== '23:59') {
          mondayJson.horaries.push({
            start_at: startTime,
            end_at: '23:59',
          });
          tuesdayJson.is_active = true;
          tuesdayJson.horaries.push({
            start_at: '00:00',
            end_at: endTime,
          });
        } else {
          mondayJson.horaries.push({
            start_at: startTime,
            end_at: endTime,
          });
        }
      } else {
        mondayJson.horaries.push({
          start_at: startTime,
          end_at: endTime,
        });
      }
    }
    if (payload.tueEnabled) {
      let startTime = payload.tueStartTime;
      let endTime = payload.tueEndTime;
      if (startTime === '' || endTime === '' || startTime === endTime) {
        startTime = '00:00';
        endTime = '00:00';
      } else if (
        startTime !== '00:00' &&
        (endTime === '00:00' || endTime === '24:00')
      ) {
        endTime = '23:59';
      }

      tuesdayJson.is_active = true;

      const startHour = parseInt(startTime.substring(0, 2), 10);
      const endHour = parseInt(endTime.substring(0, 2), 10);
      if (endHour < startHour) {
        if (startHour > 0 && endTime !== '23:59') {
          tuesdayJson.horaries.push({
            start_at: startTime,
            end_at: '23:59',
          });
          wednesdayJson.is_active = true;
          wednesdayJson.horaries.push({
            start_at: '00:00',
            end_at: endTime,
          });
        } else {
          tuesdayJson.horaries.push({
            start_at: startTime,
            end_at: endTime,
          });
        }
      } else {
        tuesdayJson.horaries.push({
          start_at: startTime,
          end_at: endTime,
        });
      }
    }
    if (payload.wedEnabled) {
      let startTime = payload.wedStartTime;
      let endTime = payload.wedEndTime;
      if (startTime === '' || endTime === '' || startTime === endTime) {
        startTime = '00:00';
        endTime = '00:00';
      } else if (
        startTime !== '00:00' &&
        (endTime === '00:00' || endTime === '24:00')
      ) {
        endTime = '23:59';
      }

      wednesdayJson.is_active = true;

      const startHour = parseInt(startTime.substring(0, 2), 10);
      const endHour = parseInt(endTime.substring(0, 2), 10);
      if (endHour < startHour) {
        if (startHour > 0 && endTime !== '23:59') {
          wednesdayJson.horaries.push({
            start_at: startTime,
            end_at: '23:59',
          });
          thursdayJson.is_active = true;
          thursdayJson.horaries.push({
            start_at: '00:00',
            end_at: endTime,
          });
        } else {
          wednesdayJson.horaries.push({
            start_at: startTime,
            end_at: endTime,
          });
        }
      } else {
        wednesdayJson.horaries.push({
          start_at: startTime,
          end_at: endTime,
        });
      }
    }
    if (payload.thuEnabled) {
      let startTime = payload.thuStartTime;
      let endTime = payload.thuEndTime;
      if (startTime === '' || endTime === '' || startTime === endTime) {
        startTime = '00:00';
        endTime = '00:00';
      } else if (
        startTime !== '00:00' &&
        (endTime === '00:00' || endTime === '24:00')
      ) {
        endTime = '23:59';
      }

      thursdayJson.is_active = true;

      const startHour = parseInt(startTime.substring(0, 2), 10);
      const endHour = parseInt(endTime.substring(0, 2), 10);
      if (endHour < startHour) {
        if (startHour > 0 && endTime !== '23:59') {
          thursdayJson.horaries.push({
            start_at: startTime,
            end_at: '23:59',
          });
          fridayJson.is_active = true;
          fridayJson.horaries.push({
            start_at: '00:00',
            end_at: endTime,
          });
        } else {
          thursdayJson.horaries.push({
            start_at: startTime,
            end_at: endTime,
          });
        }
      } else {
        thursdayJson.horaries.push({
          start_at: startTime,
          end_at: endTime,
        });
      }
    }
    if (payload.friEnabled) {
      let startTime = payload.friStartTime;
      let endTime = payload.friEndTime;
      if (startTime === '' || endTime === '' || startTime === endTime) {
        startTime = '00:00';
        endTime = '00:00';
      } else if (
        startTime !== '00:00' &&
        (endTime === '00:00' || endTime === '24:00')
      ) {
        endTime = '23:59';
      }

      fridayJson.is_active = true;

      const startHour = parseInt(startTime.substring(0, 2), 10);
      const endHour = parseInt(endTime.substring(0, 2), 10);
      if (endHour < startHour) {
        if (startHour > 0 && endTime !== '23:59') {
          fridayJson.horaries.push({
            start_at: startTime,
            end_at: '23:59',
          });
          saturdayJson.is_active = true;
          saturdayJson.horaries.push({
            start_at: '00:00',
            end_at: endTime,
          });
        } else {
          fridayJson.horaries.push({
            start_at: startTime,
            end_at: endTime,
          });
        }
      } else {
        fridayJson.horaries.push({
          start_at: startTime,
          end_at: endTime,
        });
      }
    }
    if (payload.satEnabled) {
      let startTime = payload.satStartTime;
      let endTime = payload.satEndTime;
      if (startTime === '' || endTime === '' || startTime === endTime) {
        startTime = '00:00';
        endTime = '00:00';
      } else if (
        startTime !== '00:00' &&
        (endTime === '00:00' || endTime === '24:00')
      ) {
        endTime = '23:59';
      }

      saturdayJson.is_active = true;

      const startHour = parseInt(startTime.substring(0, 2), 10);
      const endHour = parseInt(endTime.substring(0, 2), 10);
      if (endHour < startHour) {
        if (startHour > 0 && endTime !== '23:59') {
          saturdayJson.horaries.push({
            start_at: startTime,
            end_at: '23:59',
          });
          sundayJson.is_active = true;
          sundayJson.horaries.push({
            start_at: '00:00',
            end_at: endTime,
          });
        } else {
          saturdayJson.horaries.push({
            start_at: startTime,
            end_at: endTime,
          });
        }
      } else {
        saturdayJson.horaries.push({
          start_at: startTime,
          end_at: endTime,
        });
      }
    }

    // Create store scheduling configs
    const response = yield call(
      apiAnunciante.put,
      `/user-info/${user.advertiserInfo.id}/scheduling-configs`,
      {
        sunday: sundayJson,
        monday: mondayJson,
        tuesday: tuesdayJson,
        wednesday: wednesdayJson,
        thursday: thursdayJson,
        friday: fridayJson,
        saturday: saturdayJson,
        interval_between_scheduling: payload.interval,
        max_orders_per_scheduling: parseInt(
          payload.schedulingMaxQtyInputValue,
          10
        ),
        min_scheduling_time: parseInt(payload.schedulingMinTimeInputValue, 10),
        max_available_schedule_days: parseInt(
          payload.maxQtyAvailableSchedulesDaysInputValue,
          10
        ),
      }
    );

    //MixPanel Event
    sendStoreScheduling({
      id: user.advertiserInfo.id,
      name: user.advertiserInfo.name,
      email: user.email,
      sunday: sundayJson,
      monday: mondayJson,
      tuesday: tuesdayJson,
      wednesday: wednesdayJson,
      thursday: thursdayJson,
      friday: fridayJson,
      saturday: saturdayJson,
      interval_between_scheduling: payload.interval,
      max_orders_per_scheduling: parseInt(
        payload.schedulingMaxQtyInputValue,
        10
      ),
      min_scheduling_time: parseInt(payload.schedulingMinTimeInputValue, 10),
      max_available_schedule_days: parseInt(
        payload.maxQtyAvailableSchedulesDaysInputValue,
        10
      ),
      hasFreeTrialValid: userReducer.hasFreeTrialValid,
    });

    // Save in redux the user info data
    yield put(updateUserInfoSuccess(response.data));
    yield put(updateStoreSchedulingAvailabilitySuccess());
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(updateStoreSchedulingAvailabilityFailure());
      toast.error(
        'Ocorreu um erro ao atualizar as configurações de agendamento. Revise os dados e tente novamente!',
        {
          autoClose: 6000,
        }
      );
    }
  }
}

export function* getAdvertiserPaymentMethodsRequest() {
  try {
    const user = yield select(userData);

    const response = yield call(
      apiAnunciante.get,
      `/user-info/${user.advertiserInfo.id}/payment-methods`
    );

    yield put(getAdvertiserPaymentMethodsSuccess(response.data));
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(getAdvertiserPaymentMethodsFailure());
    }
  }
}

export default all([
  takeLatest(
    '@myStore/UPDATE_STORE_AVAILABILITY_REQUEST',
    updateStoreAvailabilityRequest
  ),
  takeLatest(
    '@myStore/UPDATE_STORE_PAYMENT_METHOD_REQUEST',
    updateStorePaymentMethodRequest
  ),
  takeLatest(
    '@myStore/UPDATE_STORE_DELIVERY_TAKE_AWAY_REQUEST',
    updateStoreDeliveryTakeAwayRequest
  ),
  takeLatest(
    '@myStore/GET_SELECTED_NEIGHBORHOODS_REQUEST',
    getSelectedNeighborhoodsRequest
  ),
  takeLatest('@myStore/PAUSE_NEIGHBORHOOD_REQUEST', pauseNeighborhoodRequest),
  takeLatest('@myStore/UPDATE_NEIGHBORHOOD_REQUEST', updateNeighborhoodRequest),
  takeLatest('@myStore/DELETE_NEIGHBORHOOD_REQUEST', deleteNeighborhoodRequest),
  takeLatest('@myStore/GET_LOCALITIES_REQUEST', getLocalitiesRequest),
  takeLatest('@myStore/GET_DISTRICTS_REQUEST', getDistrictsRequest),
  takeLatest(
    '@myStore/ADD_NEW_NEIGHBORHOOD_REQUEST',
    addNewNeighborhoodRequest
  ),
  takeLatest(
    '@myStore/UPDATE_STORE_SCHEDULING_AVAILABILITY_REQUEST',
    updateStoreSchedulingAvailabilityRequest
  ),
  takeLatest(
    '@myStore/GET_ADVERTISER_PAYMENT_METHODS_REQUEST',
    getAdvertiserPaymentMethodsRequest
  ),
]);
