import React, {
  Fragment,
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Icon } from 'react-materialize';
import { Spinner } from 'reactstrap';
import { toast } from 'react-toastify';

import { Row, Col } from '../../styles/grid';
import px2vw from '../../utils/responsiveness/px2vw';

import Modal from '../Modal';
import {
  ModalPrimaryButton,
  ModalPrimaryButtonText,
  ModalActions,
  ModalSecundaryButton,
  ModalSecundaryButtonText,
} from '../Modal/styles';

import {
  Header,
  Content,
  Hr,
  TableRow,
  HeaderButton,
  PagButton,
  PrevIcon,
  NextIcon,
  ErrorText,
  DefaultCardIcon,
  ExpiredCardIcon,
  ConfirmDeleteModal,
  ConfirmDeleteModalText,
  LoadingBox,
} from './styles';

import { preto } from '../../styles/colors';

import { SvgBox, Arrow, Arrow2 } from './icons';
import { sort, makePages, sortByColumnValue } from './tableOptions';

import CreditCardManageButton from './CreditCardManageButton';
import TablePerPage from '../TablePerPage';

import {
  getPaymentCardsRequest,
  setDefaultCreditCardRequest,
  deleteCardRequest,
} from '../../store/modules/profileAccount/actions';

export default function CreditCardsTable() {
  const paymentCards = useSelector(
    (state) => state.profileAccount.paymentCards
  );
  const [rows, setRows] = useState([]);
  const [rowsCurrentPage, setRowsCurrentPage] = useState();
  const [structure, setStructure] = useState(null);
  const [currentPage, setCurrentPage] = useState('1');
  const [lastPage, setLastPage] = useState();
  const [firstPage] = useState('1');
  const [sortedCol, setSortedCol] = useState('');
  const [confirmDeleteCardModal, setConfirmDeleteCardModal] = useState(false);
  const deleteCardModalLoading = useSelector(
    (state) => state.profileAccount.deleteCardModalLoading
  );
  const deleteCardModalSuccess = useSelector(
    (state) => state.profileAccount.deleteCardModalSuccess
  );
  const paymentCardsLoading = useSelector(
    (state) => state.profileAccount.paymentCardsLoading
  );
  const paymentCardsSuccess = useSelector(
    (state) => state.profileAccount.paymentCardsSuccess
  );
  const [confirmDeleteCardIdx, setConfirmDeleteCardIdx] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    // Get the paymentCards
    dispatch(getPaymentCardsRequest());
  }, [dispatch]);

  useEffect(() => {
    if (structure) {
      setLastPage(
        Object.keys(structure)[Object.keys(structure).length - 1].toString()
      );
    }
  }, [structure]);

  useMemo(() => {
    setStructure(makePages(rows.length, rowsCurrentPage));
  }, [rows, rowsCurrentPage]);

  useEffect(() => {
    if (paymentCardsSuccess) {
      setRows(paymentCards);
    }
  }, [paymentCardsSuccess]);

  useEffect(() => {
    if (paymentCards) {
      setRows(paymentCards);
    }
  }, [paymentCards]);

  useEffect(() => {
    if (deleteCardModalSuccess) {
      setConfirmDeleteCardIdx(null);
      setConfirmDeleteCardModal(false);
    }
  }, [deleteCardModalSuccess]);

  useEffect(() => {
    if (rows.length < 4) {
      setRowsCurrentPage(rows.length);
    }
    if (rowsCurrentPage > 4) {
    } else {
      setRowsCurrentPage(4);
    }
  }, [rows]);

  const handleSort = useCallback(
    (col) => {
      if (sortedCol !== col) {
        setSortedCol(col);
        setRows(sort(rows, col, 'asc'));
      } else {
        setSortedCol('');
        setRows(sort(rows, col, 'desc'));
      }
    },
    [rows]
  );

  const handleSortByColumnValue = useCallback(
    (col) => {
      if (sortedCol !== col) {
        setSortedCol(col);
        setRows(sortByColumnValue(rows, col, 'asc'));
      } else {
        setSortedCol('');
        setRows(sortByColumnValue(rows, col, 'desc'));
      }
    },
    [rows]
  );

  function handlePerPage(size) {
    setRowsCurrentPage(size);
  }

  function handleChangePage(direction) {
    if (direction === 'next') {
      const curr = +currentPage + 1;
      setCurrentPage(curr.toString());
    } else if (direction === 'prev') {
      const curr = +currentPage - 1;
      setCurrentPage(curr.toString());
    }
  }

  function formatExpirationDate(expirationDate) {
    return `${expirationDate.substring(0, 2)}/20${expirationDate.substring(
      2,
      4
    )}`;
  }

  function isCardExpired(expirationDate) {
    // Date in american format MM/dd/yyyy
    const expDate = new Date(
      `${expirationDate.substring(0, 2)}/01/20${expirationDate.substring(2, 4)}`
    );

    return expDate - new Date() < 0;
  }

  const loadingPage = (
    <Col height="20vh" margtop={px2vw(100)}>
      <Col margleft="20px" height="104vh">
        <Col alignCenter justifyCenter>
          <Spinner
            style={{
              width: '1.8rem',
              height: '1.8rem',
              color: preto,
            }}
          />
        </Col>
      </Col>
    </Col>
  );
  const contentPage = (
    <>
      <Col padding={`0 ${px2vw(15)}`} alignCenter justifyCenter>
        <Row>
          <Header
            onClick={() => handleSort('is_default')}
            width="30%"
            title="Cartão padrão"
          >
            <HeaderButton>
              <DefaultCardIcon />
              <SvgBox sorted={sortedCol === 'is_default'}>
                <Arrow />
                <Arrow2 />
              </SvgBox>
            </HeaderButton>
          </Header>
          <Header onClick={() => handleSort('last_digits')}>
            <HeaderButton>
              Últimos 4 dígitos
              <SvgBox sorted={sortedCol === 'last_digits'}>
                <Arrow />
                <Arrow2 />
              </SvgBox>
            </HeaderButton>
          </Header>

          <Header
            onClick={() => handleSortByColumnValue('expiration_date')}
            width="70%"
          >
            <HeaderButton>
              Vence em
              <SvgBox sorted={sortedCol === 'expiration_date'}>
                <Arrow />
                <Arrow2 />
              </SvgBox>
            </HeaderButton>
          </Header>

          <Header onClick={() => handleSort('brand')} width="70%">
            <HeaderButton>
              Bandeira
              <SvgBox sorted={sortedCol === 'brand'}>
                <Arrow />
                <Arrow2 />
              </SvgBox>
            </HeaderButton>
          </Header>

          <Header onClick={() => handleSort('holder_name')}>
            <HeaderButton>
              Nome do Portador
              <SvgBox sorted={sortedCol === 'holder_name'}>
                <Arrow />
                <Arrow2 />
              </SvgBox>
            </HeaderButton>
          </Header>

          <Header onClick={() => handleSort('holder_document')} width="120%">
            <HeaderButton>
              CPF/CNPJ do Portador
              <SvgBox sorted={sortedCol === 'holder_document'}>
                <Arrow />
                <Arrow2 />
              </SvgBox>
            </HeaderButton>
          </Header>

          <Header width="20%" />
        </Row>
        <Row>
          <Hr />
        </Row>
        {structure ? (
          rows.map((card, index) =>
            structure[currentPage] &&
            structure[currentPage].start <= index + 1 &&
            structure[currentPage].end >= index + 1 ? (
              <Fragment key={card.id}>
                <TableRow
                  alignCenter
                  justifyCenter
                  title={
                    isCardExpired(card.expiration_date)
                      ? `Esse cartão está vencido. ${
                          card.is_default
                            ? `Considere removê-lo e coloque outro cartão sendo o padrão. Caso não tenha outro, adicione.`
                            : `Considere removê-lo.`
                        }`
                      : ''
                  }
                >
                  <Content width="32%">
                    {card.is_default && (
                      <DefaultCardIcon title="Esse é o seu cartão padrão" />
                    )}
                  </Content>
                  <Content width="100%">{`****${card.last_digits}`}</Content>
                  <Content width="70%">
                    {formatExpirationDate(card.expiration_date)}
                    {isCardExpired(card.expiration_date) && (
                      <Row margleft={px2vw(-40)}>
                        <ExpiredCardIcon />
                      </Row>
                    )}
                  </Content>
                  <Content width="70%">{card.brand}</Content>
                  <Content>{card.holder_name}</Content>
                  <Content width="120%">{card.holder_document}</Content>
                  <Content width="5%">
                    <CreditCardManageButton
                      handleMakeDefaultCoupon={() => {
                        if (card.is_default) {
                          toast.error('Esse cartão já é o seu cartão padrão.', {
                            autoClose: 6000,
                          });
                        } else if (isCardExpired(card.expiration_date)) {
                          toast.error(
                            'Não é possível colocar como padrão um cartão vencido.',
                            {
                              autoClose: 6000,
                            }
                          );
                        } else {
                          dispatch(setDefaultCreditCardRequest(card.id));
                        }
                      }}
                      handleDeleteCoupon={() => {
                        setConfirmDeleteCardIdx(card.id);
                        setConfirmDeleteCardModal(true);
                      }}
                    />
                  </Content>
                </TableRow>
                <Row>
                  <Hr />
                </Row>
              </Fragment>
            ) : null
          )
        ) : (
          <Col height="20vh" margtop={px2vw(40)}>
            <Row alignCenter justifyCenter>
              {/* TODO: Ajustar tamanho do icone */}
              <Icon large>sentiment_very_dissatisfied</Icon>
              <ErrorText>
                Você não tem cartões de crédito a serem exibidos.
              </ErrorText>
            </Row>
          </Col>
        )}
        {structure && (
          <Row alignCenter justifyEnd>
            <Row alignCenter justifyEnd height={px2vw(40)} margin={px2vw(10)}>
              <TablePerPage
                response={handlePerPage}
                length={rows.length}
                qtdItens="4"
              />
              <Row alignCenter justifyCenter width="10%">
                {!structure[currentPage] && handleChangePage('prev')}
                {structure[currentPage] && structure[currentPage].start} -{' '}
                {structure[currentPage] && structure[currentPage].end} de{' '}
                {rows.length}
              </Row>
              <PagButton
                onClick={() => handleChangePage('prev')}
                disabled={currentPage === firstPage}
                title="Página anterior"
              >
                <PrevIcon disabled={currentPage === firstPage} />
              </PagButton>
              <PagButton
                onClick={() => handleChangePage('next')}
                title="Próxima página"
                disabled={currentPage === lastPage}
              >
                <NextIcon disabled={currentPage === lastPage} />
              </PagButton>
            </Row>
          </Row>
        )}
      </Col>
      {/* Confirm delete card */}
      {confirmDeleteCardModal && (
        <Modal
          isVisible={confirmDeleteCardModal}
          width={px2vw(340)}
          height={px2vw(190)}
        >
          <ConfirmDeleteModal>
            <ConfirmDeleteModalText>
              Tem certeza que deseja excluir o cartão?
            </ConfirmDeleteModalText>
            <ModalActions>
              <ModalSecundaryButton
                marginRight={px2vw(20)}
                margtop={px2vw(25)}
                width={px2vw(120)}
                type="button"
                onClick={() => {
                  setConfirmDeleteCardModal(false);
                  setConfirmDeleteCardIdx(null);
                }}
                disabled={deleteCardModalLoading}
              >
                <ModalSecundaryButtonText>Não</ModalSecundaryButtonText>
              </ModalSecundaryButton>
              <ModalPrimaryButton
                margleft={px2vw(20)}
                margtop={px2vw(25)}
                width={px2vw(120)}
                type="button"
                onClick={() => {
                  dispatch(deleteCardRequest(confirmDeleteCardIdx));
                }}
                disabled={deleteCardModalLoading}
              >
                {deleteCardModalLoading ? (
                  <LoadingBox>
                    <Spinner
                      style={{
                        width: '1.8rem',
                        height: '1.8rem',
                        color: preto,
                      }}
                    />
                  </LoadingBox>
                ) : (
                  <ModalPrimaryButtonText>Sim</ModalPrimaryButtonText>
                )}
              </ModalPrimaryButton>
            </ModalActions>
          </ConfirmDeleteModal>
        </Modal>
      )}
    </>
  );
  const errorPage = (
    <Col height="20vh" margtop={px2vw(100)}>
      <Col margleft="20px" height="100vh">
        <Row alignCenter justifyCenter>
          {/* TODO: Ajustar tamanho do icone */}
          <Icon large>sentiment_very_dissatisfied</Icon>
          <ErrorText>
            Ocorreu um erro ao carregar os cartões. Tente novamente.
          </ErrorText>
        </Row>
      </Col>
    </Col>
  );

  if (paymentCardsLoading) {
    return loadingPage;
  }
  if (!paymentCardsSuccess) {
    return errorPage;
  }
  return contentPage;
}
