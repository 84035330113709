import React, { useState, useEffect, useMemo } from 'react';

import { FormControlLabel } from '@material-ui/core';
import { Spinner } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import {
  ConfigGroup,
  SaveButton,
  SaveButtonText,
  ConfigGroupTitle,
  ConfigGroupContent,
  SwitchMyStoreBox,
  StoreForm,
  SwitchLabelBoldText,
} from '../styles';
import {
  AccountConfigureHere,
  PaymentMethodOption,
  PaymentMethodRow,
} from './styles';
import px2vw from '../../../utils/responsiveness/px2vw';
import { preto } from '../../../styles/colors';
import {
  SwitchController,
  SwitchLabelRegularText,
  LoadingBox,
  SwitchInput,
} from '../../_layouts/styles/common';
import { Row, Col } from '../../../styles/grid';

import {
  clearSuccess,
  updateStorePaymentMethodRequest,
  getAdvertiserPaymentMethodsRequest,
} from '../../../store/modules/myStore/actions';

import StorePaymentMethodsOnboardingMobile from '../../Onboarding/Step2-DeliveryParams/Mobile/StorePaymentMethodsOnboardingMobile';
import { cpfCnpjMask, dddPhoneMask } from '../../../utils/masks';
import CpfCnpjValidator from '../../../utils/validations/CpfCnpjValidator';
import AccessValidate from '../../../utils/validations/AccessValidate';
import history from '../../../services/history';
import { financial } from '../../../routes/routeMap';

function StorePaymentMethods(props) {
  const [paymentChanges, setPaymentChanges] = useState(false);
  const storePaymentMethods = useSelector(
    (state) => state.myStore.advertiserPaymentMethods
  );
  const [storePmCash, setStorePmCash] = useState([]);
  const [storePmsCreditCardOffline, setStorePmsCreditCardOffline] = useState(
    []
  );
  const [storePmsDebitCardOffline, setStorePmsDebitCardOffline] = useState([]);
  const [storePmsVoucherOffline, setStorePmsVoucherOffline] = useState([]);
  const [storePmCashActive, setStorePmCashActive] = useState(false);
  const [storePmsCreditCardOfflineActive, setStorePmsCreditCardOfflineActive] =
    useState(false);
  const [storePmsDebitCardOfflineActive, setStorePmsDebitCardOfflineActive] =
    useState(false);
  const [storePmsVoucherOfflineActive, setStorePmsVoucherOfflineActive] =
    useState(false);
  const [storePmPixKeyActive, setStorePmPixKeyActive] = useState(false);
  const [storePmPixKey, setStorePmPixKey] = useState([]);
  const [pixKey, setPixKey] = useState('');
  const [storePmAutomaticPixKeyActive, setStorePmAutomaticPixKeyActive] =
    useState(false);
  const [storePmAutomaticPixKeyEnabled, setStorePmAutomaticPixKeyEnabled] =
    useState(false);
  const [storePmAutomaticPixKey, setStoreAutomaticPmPixKey] = useState([]);
  const getAdvertiserPaymentMethodLoading = useSelector(
    (state) => state.myStore.advertiserPaymentMethodsLoading
  );
  const getAdvertiserPaymentMethodSuccess = useSelector(
    (state) => state.myStore.advertiserPaymentMethodsSuccess
  );
  const updatePaymentMethodLoading = useSelector(
    (state) => state.myStore.updatePaymentMethodLoading
  );
  const updatePaymentMethodSuccess = useSelector(
    (state) => state.myStore.updatePaymentMethodSuccess
  );
  const dispatch = useDispatch();
  const pixKeyRegex =
    /^[A-Za-z0-9]{8}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{12}$/;
  const emailRegex =
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
  const hasLetter = /[A-Za-z]+/;
  const { access } = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(getAdvertiserPaymentMethodsRequest());
  }, []);

  const automaticPixPermission = useMemo(() => {
    return AccessValidate(access, null, null, 'automatic_pix');
  }, [access]);

  // Load the initial state of payment methods after loading
  useEffect(() => {
    if (getAdvertiserPaymentMethodSuccess) {
      // Spread the payment methods paymentMethodsay into specifics array for each payment method
      setStorePmCash([
        ...storePaymentMethods.filter((pm) => {
          if (pm.paymentMethod.function === 'money') {
            setStorePmCashActive(pm.is_active);
          }
          return pm.paymentMethod.function === 'money';
        }),
      ]);
      // Pix key payment
      setStorePmPixKey([
        ...storePaymentMethods.filter((pm) => {
          if (
            pm.paymentMethod.function === 'money_transfer' &&
            pm.paymentMethod.slug === 'pix_chave'
          ) {
            setStorePmPixKeyActive(pm.is_active);
            setPixKey(pm.external_processor_key);
          }
          return pm.paymentMethod.slug === 'pix_chave';
        }),
      ]);
      // Automatic Pix payment
      setStoreAutomaticPmPixKey([
        ...storePaymentMethods.filter((pm) => {
          if (
            pm.paymentMethod.function === 'money_transfer' &&
            pm.paymentMethod.slug === 'pix_copia_cola'
          ) {
            setStorePmAutomaticPixKeyActive(pm.is_active);
            setStorePmAutomaticPixKeyEnabled(pm.is_enabled);
          }
          return pm.paymentMethod.slug === 'pix_copia_cola';
        }),
      ]);
      // Credit Card Offline
      setStorePmsCreditCardOffline([
        ...storePaymentMethods.filter((pm) => {
          const isOfflineCreditCard =
            pm.paymentMethod.type === 'offline' &&
            pm.paymentMethod.function === 'credit';
          if (
            isOfflineCreditCard &&
            !storePmsCreditCardOfflineActive &&
            pm.is_active
          ) {
            setStorePmsCreditCardOfflineActive(true);
          }
          return isOfflineCreditCard;
        }),
      ]);
      // Debit Card Offline
      setStorePmsDebitCardOffline([
        ...storePaymentMethods.filter((pm) => {
          const isOfflineDebitCard =
            pm.paymentMethod.type === 'offline' &&
            pm.paymentMethod.function === 'debit';
          if (
            isOfflineDebitCard &&
            !storePmsDebitCardOfflineActive &&
            pm.is_active
          ) {
            setStorePmsDebitCardOfflineActive(true);
          }
          return isOfflineDebitCard;
        }),
      ]);
      // Voucher Offline
      setStorePmsVoucherOffline([
        ...storePaymentMethods.filter((pm) => {
          const isOfflineVoucherCard =
            pm.paymentMethod.type === 'offline' &&
            pm.paymentMethod.function === 'voucher';
          if (
            isOfflineVoucherCard &&
            !storePmsVoucherOfflineActive &&
            pm.is_active
          ) {
            setStorePmsVoucherOfflineActive(true);
          }
          return isOfflineVoucherCard;
        }),
      ]);
    }
  }, [getAdvertiserPaymentMethodSuccess]);

  // Verify if the payment category is disabled or not
  function isPaymentCategoryDisabled(paymentCategory) {
    switch (paymentCategory) {
      // Credit card offline category
      case 'creditCardOffline':
        return !storePmsCreditCardOfflineActive;
      // Debit card offline category
      case 'debitCardOffline':
        return !storePmsDebitCardOfflineActive;
      // Voucher offline category
      case 'voucherOffline':
        return !storePmsVoucherOfflineActive;
      default:
        return false;
    }
  }

  // Handle the switch of payment category
  function handlePaymentCategorySwitch(paymentCategory) {
    setPaymentChanges(true);
    switch (paymentCategory) {
      // Cash
      case 'cash':
        const newCash = JSON.parse(JSON.stringify(storePmCash));
        newCash.forEach((pm) => {
          pm.is_active = !pm.is_active;
        });
        setStorePmCash(newCash);
        setStorePmCashActive(!storePmCashActive);
        break;
      // Pix key
      case 'chave_pix':
        const newPixKey = JSON.parse(JSON.stringify(storePmPixKey));
        newPixKey.forEach((pm) => {
          pm.is_active = !pm.is_active;
        });
        setStorePmPixKey(newPixKey);
        setStorePmPixKeyActive(!storePmPixKeyActive);
        if (!storePmPixKeyActive && storePmAutomaticPixKeyActive) {
          const newAutomaticPixKey = JSON.parse(
            JSON.stringify(storePmAutomaticPixKey)
          );
          newAutomaticPixKey.forEach((pm) => {
            pm.is_active = !pm.is_active;
          });
          setStoreAutomaticPmPixKey(newAutomaticPixKey);
          setStorePmAutomaticPixKeyActive(!storePmAutomaticPixKeyActive);
        }
        break;
      // Automatic Pix
      case 'automatic_pix':
        const newAutomaticPixKey = JSON.parse(
          JSON.stringify(storePmAutomaticPixKey)
        );
        newAutomaticPixKey.forEach((pm) => {
          pm.is_active = !pm.is_active;
        });
        setStoreAutomaticPmPixKey(newAutomaticPixKey);
        setStorePmAutomaticPixKeyActive(!storePmAutomaticPixKeyActive);

        if (storePmPixKeyActive && !storePmAutomaticPixKeyActive) {
          const newPixKey = JSON.parse(JSON.stringify(storePmPixKey));
          newPixKey.forEach((pm) => {
            pm.is_active = !pm.is_active;
          });
          setStorePmPixKey(newPixKey);
          setStorePmPixKeyActive(!storePmPixKeyActive);
        }

        break;
      // Credit card offline category
      case 'creditCardOffline':
        if (storePmsCreditCardOfflineActive) {
          const newCreditCardOffline = JSON.parse(
            JSON.stringify(storePmsCreditCardOffline)
          );
          newCreditCardOffline.forEach((pm) => {
            pm.is_active = false;
          });
          setStorePmsCreditCardOffline(newCreditCardOffline);
          setStorePmsCreditCardOfflineActive(false);
        } else {
          setStorePmsCreditCardOfflineActive(true);
        }
        break;
      // Debit card offline category
      case 'debitCardOffline':
        if (storePmsDebitCardOfflineActive) {
          const newDebitCardOffline = JSON.parse(
            JSON.stringify(storePmsDebitCardOffline)
          );
          newDebitCardOffline.forEach((pm) => {
            pm.is_active = false;
          });
          setStorePmsDebitCardOffline(newDebitCardOffline);
          setStorePmsDebitCardOfflineActive(false);
        } else {
          setStorePmsDebitCardOfflineActive(true);
        }
        break;
      // Voucher offline category
      case 'voucherOffline':
        if (storePmsVoucherOfflineActive) {
          const newVoucherOffline = JSON.parse(
            JSON.stringify(storePmsVoucherOffline)
          );
          newVoucherOffline.forEach((pm) => {
            pm.is_active = false;
          });
          setStorePmsVoucherOffline(newVoucherOffline);
          setStorePmsVoucherOfflineActive(false);
        } else {
          setStorePmsVoucherOfflineActive(true);
        }
        break;
      default:
        break;
    }
  }

  // Handle payment method active
  function handleChangePmActive(paymentCategory, pmId) {
    setPaymentChanges(true);
    let categoryActive = false;
    switch (paymentCategory) {
      // Credit card offline
      case 'creditCardOffline':
        // Disabled payment method option
        const newCreditCardOffline = JSON.parse(
          JSON.stringify(storePmsCreditCardOffline)
        );
        newCreditCardOffline.forEach((pm) => {
          if (Number(pm.id) === Number(pmId)) {
            pm.is_active = !pm.is_active;
          }
        });
        // Verify if the payment category still active or not
        newCreditCardOffline.forEach((pm) => {
          if (pm.is_active && !categoryActive) {
            categoryActive = true;
          }
        });
        // Set the payment category active
        setStorePmsCreditCardOfflineActive(categoryActive);
        // Set the new array of payment methods
        setStorePmsCreditCardOffline(newCreditCardOffline);
        break;
      // Debit card offline
      case 'debitCardOffline':
        // Disabled payment method option
        const newDebitCardOffline = JSON.parse(
          JSON.stringify(storePmsDebitCardOffline)
        );
        newDebitCardOffline.forEach((pm) => {
          if (Number(pm.id) === Number(pmId)) {
            pm.is_active = !pm.is_active;
          }
        });
        // Verify if the payment category still active or not
        newDebitCardOffline.forEach((pm) => {
          if (pm.is_active && !categoryActive) {
            categoryActive = true;
          }
        });
        // Set the payment category active
        setStorePmsDebitCardOfflineActive(categoryActive);
        // Set the new array of payment methods
        setStorePmsDebitCardOffline(newDebitCardOffline);
        break;
      // Voucher offline
      case 'voucherOffline':
        // Disabled payment method option
        const newVoucherOffline = JSON.parse(
          JSON.stringify(storePmsVoucherOffline)
        );
        newVoucherOffline.forEach((pm) => {
          if (Number(pm.id) === Number(pmId)) {
            pm.is_active = !pm.is_active;
          }
        });
        // Verify if the payment category still active or not
        newVoucherOffline.forEach((pm) => {
          if (pm.is_active && !categoryActive) {
            categoryActive = true;
          }
        });
        // Set the payment category active
        setStorePmsVoucherOfflineActive(categoryActive);
        // Set the new array of payment methods
        setStorePmsVoucherOffline(newVoucherOffline);
        break;
      default:
        break;
    }
  }

  // Display only the quantity per line of payment methods inside a category
  function displayPaymentMethodsInline(
    paymentMethodsArr,
    paymentCategory,
    pmPerLine = 3
  ) {
    // Transform the payment methods array in other splited by a specific
    // number per line
    const paymentMethodsArrPerLine = paymentMethodsArr.reduce(
      (resultArray, item, index) => {
        const chunkIndex = Math.floor(index / pmPerLine);

        if (!resultArray[chunkIndex]) {
          // eslint-disable-next-line no-param-reassign
          resultArray[chunkIndex] = [];
        }

        resultArray[chunkIndex].push(item);

        return resultArray;
      },
      []
    );

    // Return the each line with the respective quantity of payment method options
    return paymentMethodsArrPerLine.map((storePmsPerLine, idx) => {
      return (
        <PaymentMethodRow
          key={`PaymentMethodRow_${idx}`}
          isMobileOnboarding={props.isMobileOnboarding}
        >
          {storePmsPerLine.map((pm, idx) => {
            return (
              <PaymentMethodOption
                key={`PaymentMethodOption_${pm.id}_${idx}`}
                type="button"
                isChecked={pm.is_active}
                onClick={() => handleChangePmActive(paymentCategory, pm.id)}
                disabled={isPaymentCategoryDisabled(paymentCategory)}
                isMobileOnboarding={props.isMobileOnboarding}
              >
                <SwitchLabelRegularText
                  isChecked={pm.is_active}
                  isMobileOnboarding={props.isMobileOnboarding}
                >
                  {pm.paymentMethod.name
                    .replace(' Crédito', '')
                    .replace(' Débito', '')}
                </SwitchLabelRegularText>
              </PaymentMethodOption>
            );
          })}
        </PaymentMethodRow>
      );
    });
  }

  function handleTypingPixKey(e) {
    setPixKey(handleValidatePixkeyType(e.target.value));
    setPaymentChanges(true);
  }

  function handleValidatePixkeyType(pixkey) {
    if (!pixkey.match(hasLetter)) {
      if (pixkey.length === 11) {
        if (CpfCnpjValidator(pixkey)) {
          return cpfCnpjMask(pixkey);
        } else {
          return dddPhoneMask(pixkey);
        }
      } else if (pixkey.length >= 12) {
        return cpfCnpjMask(pixkey);
      }
    } else {
      return pixkey;
    }
  }

  // Handle form submit for payment methods
  function handleStorePaymentMethodsSubmit() {
    let saving = true;
    let newStorePmPixKey = [...storePmPixKey];
    let newStorePmAutomaticPix = [...storePmAutomaticPixKey];

    const paymentMethods = [
      ...storePmCash,
      ...storePmsCreditCardOffline,
      ...storePmsDebitCardOffline,
      ...storePmsVoucherOffline,
      ...newStorePmPixKey,
      ...newStorePmAutomaticPix,
    ];
    const isSomePmActive = paymentMethods.filter((pm) => pm.is_active);

    if (isSomePmActive.length === 0) {
      saving = false;
      toast.error('Deve ser selecionado ao menos uma forma de pagamento.', {
        autoClose: 6000,
      });
    } else if (
      storePmsCreditCardOfflineActive ||
      storePmsDebitCardOfflineActive ||
      storePmsVoucherOfflineActive ||
      storePmPixKeyActive ||
      storePmAutomaticPixKeyActive
    ) {
      if (storePmsCreditCardOfflineActive) {
        const isSomeCreditCardActive = storePmsCreditCardOffline.filter(
          (pm) => pm.is_active
        );
        if (isSomeCreditCardActive.length === 0) {
          saving = false;
          toast.error(
            'Foi selecionado a utilização da forma de pagamento cartão de crédito, porém não foi escolhida nenhuma bandeira.',
            {
              autoClose: 6000,
            }
          );
        }
      }
      if (storePmsDebitCardOfflineActive) {
        const isSomeDebitCardActive = storePmsDebitCardOffline.filter(
          (pm) => pm.is_active
        );
        if (isSomeDebitCardActive.length === 0) {
          saving = false;
          toast.error(
            'Foi selecionado a utilização da forma de pagamento cartão de débito, porém não foi escolhida nenhuma bandeira.',
            {
              autoClose: 6000,
            }
          );
        }
      }
      if (storePmsVoucherOfflineActive) {
        const isSomeVoucherActive = storePmsVoucherOffline.filter(
          (pm) => pm.is_active
        );
        if (isSomeVoucherActive.length === 0) {
          saving = false;
          toast.error(
            'Foi selecionado a utilização da forma de pagamento vale refeição/alimentação, porém não foi escolhida nenhum tipo.',
            {
              autoClose: 6000,
            }
          );
        }
      }
      if (storePmPixKeyActive) {
        if (pixKey && pixKey.trim() !== '') {
          if (pixKey.match(hasLetter)) {
            if (!pixKey.includes('@') && !pixKey.match(pixKeyRegex)) {
              saving = false;
              toast.error(
                'A chave aleatória informada como chave pix é inválida!',
                {
                  autoClose: 6000,
                }
              );
            } else if (pixKey.includes('@') && !pixKey.match(emailRegex)) {
              saving = false;
              toast.error('O email informado como chave pix é inválido!', {
                autoClose: 6000,
              });
            }
          } else {
            if (
              (pixKey.length === 14 || pixKey.length === 18) &&
              !CpfCnpjValidator(pixKey)
            ) {
              saving = false;
              toast.error('O CPF/CNPJ informado como chave pix é inválido!', {
                autoClose: 6000,
              });
            }
          }
          newStorePmPixKey = storePmPixKey.map((pm) => ({
            ...pm,
            external_processor_key: pixKey,
          }));
        } else {
          saving = false;
          toast.error(
            'Digite uma chave pix válida (Email, CPF/CNPJ, telefone com DDD ou chave aleatória)!',
            {
              autoClose: 6000,
            }
          );
        }
      } else {
        // Force clear pix key at database, when user disable this payment method
        newStorePmPixKey = storePmPixKey.map((pm) => ({
          ...pm,
          external_processor_key: null,
        }));
      }
    }

    if (saving) {
      dispatch(
        updateStorePaymentMethodRequest([
          ...storePmCash,
          ...storePmsCreditCardOffline,
          ...storePmsDebitCardOffline,
          ...storePmsVoucherOffline,
          ...newStorePmPixKey,
          ...newStorePmAutomaticPix,
        ])
      );
    }
  }

  // Update states when update is succesfully
  useEffect(() => {
    if (updatePaymentMethodSuccess) {
      setPaymentChanges(false);
      dispatch(clearSuccess());
      if (props.onSuccess) {
        props.onSuccess();
      }
    }
  }, [updatePaymentMethodSuccess, dispatch]);

  const isSaveButtonDisabled = () => {
    if (props.isStep) {
      return !props.isActive || updatePaymentMethodLoading;
    }
    return !paymentChanges || updatePaymentMethodLoading;
  };

  const isSaveButtonActive = () => {
    if (props.isStep) {
      return props.isActive;
    }
    return paymentChanges;
  };

  const handler = {
    updatePaymentMethodLoading: updatePaymentMethodLoading,
    storePmCash: storePmCash,
    storePmCashActive: storePmCashActive,
    storePmsCreditCardOffline: storePmsCreditCardOffline,
    storePmsCreditCardOfflineActive: storePmsCreditCardOfflineActive,
    storePmsDebitCardOffline: storePmsDebitCardOffline,
    storePmsDebitCardOfflineActive: storePmsDebitCardOfflineActive,
    storePmsVoucherOffline: storePmsVoucherOffline,
    storePmsVoucherOfflineActive: storePmsVoucherOfflineActive,
    storePmPixKey: storePmPixKey,
    storePmPixKeyActive: storePmPixKeyActive,
    storePmAutomaticPixKeyActive: storePmAutomaticPixKeyActive,
    pixKey: pixKey,
    isSaveButtonDisabled: () => isSaveButtonDisabled(),
    isSaveButtonActive: () => isSaveButtonActive(),
    displayPaymentMethodsInline: (
      paymentMethodsArr,
      paymentCategory,
      pmPerLine
    ) =>
      displayPaymentMethodsInline(
        paymentMethodsArr,
        paymentCategory,
        pmPerLine
      ),
    handlePaymentCategorySwitch: (paymentCategory) =>
      handlePaymentCategorySwitch(paymentCategory),
    handleTypingPixKey: (e) => handleTypingPixKey(e),
    handleStorePaymentMethodsSubmit: () => handleStorePaymentMethodsSubmit(),
  };

  // MyStore openned through onboarding at mobile
  if (props.isMobileOnboarding) {
    return <StorePaymentMethodsOnboardingMobile handler={handler} />;
  }
  // MyStore openned through onboarding or platform at web
  else {
    return (
      <ConfigGroup isStep={props.isStep} isActive={props.isActive}>
        <StoreForm onSubmit={handleStorePaymentMethodsSubmit}>
          <ConfigGroupContent backgroundColor={props.backgroundColor}>
            <Row height="auto">
              <Row justifyStart>
                <ConfigGroupTitle>
                  Defina as formas de pagamento que sua loja aceita
                </ConfigGroupTitle>
              </Row>
              <SaveButton
                id="myStorePaymentMethodsSaveButton"
                hasChanges={isSaveButtonActive()}
                type="submit"
                disabled={isSaveButtonDisabled()}
              >
                {updatePaymentMethodLoading ? (
                  <LoadingBox>
                    <Spinner
                      style={{
                        width: '1.8rem',
                        height: '1.8rem',
                        color: preto,
                      }}
                    />{' '}
                  </LoadingBox>
                ) : (
                  <SaveButtonText hasChanges={isSaveButtonActive()}>
                    Salvar
                  </SaveButtonText>
                )}
              </SaveButton>
            </Row>
            {getAdvertiserPaymentMethodLoading ? (
              <Col height="5vh" alignCenter justifyCenter>
                <Col alignCenter justifyCenter>
                  <Spinner
                    style={{
                      width: '1.8rem',
                      height: '1.8rem',
                      color: preto,
                    }}
                  />
                </Col>
              </Col>
            ) : (
              <Row height="auto">
                <Col id="myStorePaymentMethodsOffline" margtop={px2vw(10)}>
                  <SwitchLabelBoldText>
                    Pagamento na entrega
                  </SwitchLabelBoldText>
                  {/* Cash */}
                  {storePmCash && (
                    <SwitchMyStoreBox margtop={px2vw(20)} margleft={px2vw(10)}>
                      <FormControlLabel
                        control={
                          <SwitchController
                            checked={storePmCashActive}
                            onChange={() => handlePaymentCategorySwitch('cash')}
                            type="checkbox"
                          />
                        }
                        label={
                          <SwitchLabelRegularText>
                            Dinheiro
                          </SwitchLabelRegularText>
                        }
                      />
                    </SwitchMyStoreBox>
                  )}
                  {/* Credit Card Offline */}
                  <SwitchMyStoreBox margtop={px2vw(20)} margleft={px2vw(10)}>
                    <FormControlLabel
                      control={
                        <SwitchController
                          checked={storePmsCreditCardOfflineActive}
                          onChange={() =>
                            handlePaymentCategorySwitch('creditCardOffline')
                          }
                          type="checkbox"
                        />
                      }
                      label={
                        <SwitchLabelRegularText>
                          Cartão de Crédito
                        </SwitchLabelRegularText>
                      }
                    />
                  </SwitchMyStoreBox>
                  {/* Credit Card Offline Payment Options */}
                  {storePmsCreditCardOffline &&
                    displayPaymentMethodsInline(
                      storePmsCreditCardOffline,
                      'creditCardOffline'
                    )}
                  {/* Debit Card Offline */}
                  <SwitchMyStoreBox margtop={px2vw(20)} margleft={px2vw(10)}>
                    <FormControlLabel
                      control={
                        <SwitchController
                          checked={storePmsDebitCardOfflineActive}
                          onChange={() =>
                            handlePaymentCategorySwitch('debitCardOffline')
                          }
                          type="checkbox"
                        />
                      }
                      label={
                        <SwitchLabelRegularText>
                          Cartão de Débito
                        </SwitchLabelRegularText>
                      }
                    />
                  </SwitchMyStoreBox>
                  {/* Debit Card Offline Payment Options */}
                  {storePmsDebitCardOffline &&
                    displayPaymentMethodsInline(
                      storePmsDebitCardOffline,
                      'debitCardOffline'
                    )}
                  {/* Voucher Offline */}
                  <SwitchMyStoreBox margtop={px2vw(20)} margleft={px2vw(10)}>
                    <FormControlLabel
                      control={
                        <SwitchController
                          checked={storePmsVoucherOfflineActive}
                          onChange={() =>
                            handlePaymentCategorySwitch('voucherOffline')
                          }
                          type="checkbox"
                        />
                      }
                      label={
                        <SwitchLabelRegularText>
                          Voucher / Ticket Refeição
                        </SwitchLabelRegularText>
                      }
                    />
                  </SwitchMyStoreBox>
                  {/* Voucher Offline Payment Options */}
                  {storePmsVoucherOffline &&
                    displayPaymentMethodsInline(
                      storePmsVoucherOffline,
                      'voucherOffline'
                    )}
                </Col>

                <Col id="myStorePaymentMethodsOnline" margtop={px2vw(10)}>
                  <SwitchLabelBoldText>Pagamento online</SwitchLabelBoldText>
                  {storePmPixKey && (
                    <Col>
                      <SwitchMyStoreBox
                        margtop={px2vw(20)}
                        margleft={px2vw(10)}
                      >
                        <FormControlLabel
                          control={
                            <SwitchController
                              checked={storePmPixKeyActive}
                              onChange={() =>
                                handlePaymentCategorySwitch('chave_pix')
                              }
                              type="checkbox"
                            />
                          }
                          label={
                            <Row justifyCenter alignCenter height={px2vw(30)}>
                              <SwitchLabelRegularText margright={px2vw(10)}>
                                Chave Pix
                              </SwitchLabelRegularText>
                              {storePmPixKeyActive && (
                                <SwitchInput
                                  placeholder="Email, CPF/CNPJ, telefone com DDD ou chave aleatória"
                                  name="pixKey"
                                  width={px2vw(350)}
                                  value={pixKey}
                                  onChange={(e) => handleTypingPixKey(e)}
                                />
                              )}
                            </Row>
                          }
                        />
                      </SwitchMyStoreBox>
                      <SwitchLabelRegularText
                        fontSize={px2vw(10)}
                        margtop={px2vw(10)}
                        margright={px2vw(20)}
                        margleft={px2vw(40)}
                      >
                        * Com o Chave Pix, você precisa conferir os pagamentos
                        antes de preparar os pedidos
                      </SwitchLabelRegularText>
                    </Col>
                  )}

                  {storePmAutomaticPixKey && automaticPixPermission && (
                    <Col>
                      <SwitchMyStoreBox
                        margtop={px2vw(20)}
                        margleft={px2vw(10)}
                      >
                        <FormControlLabel
                          control={
                            <SwitchController
                              checked={storePmAutomaticPixKeyActive}
                              onChange={() =>
                                handlePaymentCategorySwitch('automatic_pix')
                              }
                              type="checkbox"
                            />
                          }
                          label={
                            <SwitchLabelRegularText margright={px2vw(50)}>
                              Pix Automatizado
                            </SwitchLabelRegularText>
                          }
                        />
                      </SwitchMyStoreBox>
                      <SwitchLabelRegularText
                        fontSize={px2vw(10)}
                        margtop={px2vw(10)}
                        margright={px2vw(20)}
                        margleft={px2vw(40)}
                      >
                        * Com o Pix Automatizado, você não precisa se preocupar
                        em conferir os pagamentos. Nosso robô faz o trabalho pra
                        você
                      </SwitchLabelRegularText>
                      {!storePmAutomaticPixKeyEnabled && storePmAutomaticPixKeyActive && <SwitchLabelRegularText
                        fontSize={px2vw(10)}
                        margtop={px2vw(10)}
                        margright={px2vw(20)}
                        margleft={px2vw(40)}
                      >
                        * Uma conta bancária ainda não foi configurada para sua
                        loja.{' '}
                        <AccountConfigureHere onClick={() => history.push(financial)}>
                          Clique para confirmar.
                        </AccountConfigureHere>
                      </SwitchLabelRegularText>}
                    </Col>
                  )}
                </Col>
              </Row>
            )}
          </ConfigGroupContent>
        </StoreForm>
      </ConfigGroup>
    );
  }
}

export default StorePaymentMethods;
