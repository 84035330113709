import React from 'react';
import { Spinner } from 'reactstrap';

import { Col, Row } from '../../../../../../styles/grid';
import { 
  NewPaymentsTitle, 
  NewPaymentsOptionBox, 
  NewPaymentsBoxBoldTitle,
  NewPaymentsBoxPlanType,
  NewPaymentsObs,
  NewPaymentsBoxPlan,
  NewPaymentsObsMonthlyDiscount,
  ConfirmPlanButton,
  BoostCredits
} from '../styles';

import px2vw from '../../../../../../utils/responsiveness/px2vw';
import { formatCurrencyBRL } from '../../../../../../utils/utils';

import MostRecommendedBox from '../MostRecommendedBox';

import { 
  BonusText1,
  BonusText2,
  CardRealCents, 
  CardRealSign, 
  DiscountText, 
  SaveMoneyText1, 
  SaveMoneyText2 
} from '../constants';

export default function PlanOptionsWeb({ handler }) {
  function boldStringPart(str){
    const willChange = str.toString().split('R$')
    const valueWithoutDecimals = willChange[1].trim().split(',')
    return (
      <Row>
        <NewPaymentsTitle>{CardRealSign}</NewPaymentsTitle>
        <NewPaymentsBoxBoldTitle>{valueWithoutDecimals[0]}</NewPaymentsBoxBoldTitle>
        <NewPaymentsTitle>{CardRealCents}</NewPaymentsTitle>
      </Row>      
    )
  }

  const PaymentsBox = ({ plan } ) => {
    const planName = plan.name.split(' ');
    const planWithDiscount = formatCurrencyBRL(plan.new_subscription_amount, {});
    const planDiscountPercentage = plan.discount_percentage;
    const savedAmount = formatCurrencyBRL(plan.saved_amount, {});
    const showBoostDiscount = plan.boost_credits_amount !== 0 && handler.showKuppiBoostDiscounts;
    const isRecommended = plan.is_recommended;

    return(
      <Col alignCenter>
        {isRecommended && (
          <MostRecommendedBox />
        )}
        <NewPaymentsOptionBox 
          showBoostDiscount={showBoostDiscount}
          key={`newPaymentBox-${plan.subscription_id}`}
          selectedPlan={plan.subscription_id === handler.selectedPlan?.subscription_id}
          onClick={() => {
            handler.setSelectedPlan(plan);
          }}
        >
          {showBoostDiscount && (
            <Row margbottom={px2vw(-7)} height="auto">
              <BoostCredits>
                <Row width='auto' alignCenter>
                  <span>
                    <span>{BonusText1}</span>
                    <span className="valueColor">R$ {plan.boost_credits_amount}</span>
                    <span>{BonusText2}</span>
                  </span>
                </Row>
              </BoostCredits>
            </Row>
          )}
          <Row
            pad={px2vw(15)}
          >
            {plan.discount_percentage === 0 && (
              <Col alignCenter>
                <Col 
                  alignCenter
                  height='auto'
                  margtop={px2vw(28)}
                >
                  <NewPaymentsBoxPlan>{planName[0]}</NewPaymentsBoxPlan>
                  <NewPaymentsBoxPlanType>{planName[1]}</NewPaymentsBoxPlanType>
                </Col>
                <Row 
                  margtop={px2vw(5)}
                  margbottom={px2vw(5)}
                  height='auto'
                >
                  <NewPaymentsTitle>{boldStringPart(planWithDiscount)}</NewPaymentsTitle>
                </Row>
                <Col 
                  alignCenter
                  margtop={px2vw(-7)}
                >
                  <Row 
                    alignEnd
                    margtop={showBoostDiscount ? px2vw(-57) : '0'}
                  >
                    <ConfirmPlanButton
                      onClick={() => {
                        handler.setSelectedPlan(plan);
                      }}
                      isActive={handler.selectedPlan?.subscription_id === plan.subscription_id && plan.subscription_id !== handler.recurringPaymentData?.subscription?.id}
                      isRecomended={isRecommended && handler.selectedPlan?.subscription_id !== plan.subscription_id && handler.selectedPlan?.subscription_id === handler.recurringPaymentData?.subscription?.id} 
                    >
                      {plan.subscription_id === handler.recurringPaymentData?.subscription?.id && 'Plano Atual'}
                      {handler.selectedPlan?.subscription_id !== plan.subscription_id && plan.subscription_id !== handler.recurringPaymentData?.subscription?.id && 'Quero esse plano'}
                      {handler.selectedPlan?.subscription_id === plan.subscription_id && plan.subscription_id !== handler.recurringPaymentData?.subscription?.id && 'Plano Escolhido'}
                    </ConfirmPlanButton>
                  </Row>
                </Col>
              </Col>
            )}
            {plan.discount_percentage !== 0 && (
              <Col alignCenter>
                <Col 
                  alignCenter
                  height='auto'
                >
                  <NewPaymentsObsMonthlyDiscount>{`${planDiscountPercentage}${DiscountText}`}</NewPaymentsObsMonthlyDiscount>
                  <NewPaymentsBoxPlan>{planName[0]}</NewPaymentsBoxPlan>
                  <NewPaymentsBoxPlanType>{planName[1]}</NewPaymentsBoxPlanType>
                </Col>
                <Row 
                  margtop={px2vw(5)}
                  height='auto'
                >
                  <NewPaymentsTitle>{boldStringPart(planWithDiscount)}</NewPaymentsTitle>
                </Row>
                <Col 
                  alignCenter
                  margtop={showBoostDiscount ? px2vw(-33) : px2vw(-7)}
                >
                  <Col alignCenter height='auto'>
                    <NewPaymentsObs>
                      {SaveMoneyText1}
                    </NewPaymentsObs>
                    <NewPaymentsObs>{`${savedAmount} ${SaveMoneyText2}`}</NewPaymentsObs>
                  </Col>        
                  <Row 
                    alignEnd
                    margtop={showBoostDiscount ? px2vw(-93) : '0'}
                  >
                    <ConfirmPlanButton
                      isActive={handler.selectedPlan?.subscription_id === plan.subscription_id && plan.subscription_id !== handler.recurringPaymentData?.subscription?.id}
                      isRecomended={isRecommended && handler.selectedPlan?.subscription_id !== plan.subscription_id && handler.selectedPlan?.subscription_id === handler.recurringPaymentData?.subscription?.id} 
                    >
                      {plan.subscription_id === handler.recurringPaymentData?.subscription?.id && 'Plano Atual'}
                      {handler.selectedPlan?.subscription_id !== plan.subscription_id && plan.subscription_id !== handler.recurringPaymentData?.subscription?.id && 'Quero esse plano'}
                      {handler.selectedPlan?.subscription_id === plan.subscription_id && plan.subscription_id !== handler.recurringPaymentData?.subscription?.id && 'Plano Escolhido'}
                    </ConfirmPlanButton>
                  </Row>
                </Col>
              </Col>
            )}
          </Row>
        </NewPaymentsOptionBox>
      </Col>
    );
  }

  return (
    <Row alignEnd>
      {handler.recurringPaymentData?.subscription ? 
        handler.newPlanOptions.map((plan, index) => {
          return <PaymentsBox key={`paymentBox-${index}`} plan={plan} />   
        })
      :
        <Spinner/> 
      }    
    </Row>
  );
}
