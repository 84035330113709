import React from "react";
import { Spinner } from "reactstrap";

import {
  Card,
  NormalText,
  FormBox,
} from '../../BaseScreen/styles';
import { Row, Col } from '../../../../styles/grid';
import { branco, preto, vermelhoKuppi1 } from '../../../../styles/colors';
import { SelectInputBox } from "../../../MyStore/StoreScheduling/styles";
import { LoadingBox } from "../../../../styles/common";
import { 
  CircleBot, 
  DragImageBox, 
  FormDataInput, 
  ImageCoverPreview, 
  ImageLogoPreview, 
  InputImage
} from "../styles";

import advertiserLogoImg from '../../../../assets/images/advertiser_logo/advertiser_logo.png';

import px2vw from '../../../../utils/responsiveness/px2vw';

import ButtonPrimary from '../../../../components/ButtonPrimary';
import ChangePasswordModal from "../../../../components/ChangePasswordModal";

function Field(props) {
  return (
    <Col width='100%' height='auto' margbottom={props.optionalMargbottom ? props.optionalMargbottom : px2vw(30)} margtop={px2vw(30)}
      spaceBetween margleft={props.margleft}>
      <NormalText
        fontweight='bold'
        textalign='flex-start'
        color={preto}
        fontsize={px2vw(50)}>
        {props.title}
      </NormalText>
      <FormDataInput
        fontsize={px2vw(50)}
        placeholder={props.title}
        borderradius={px2vw(30)}
        name={props.title}
        type="text"
        value={props.value}
        empty={props.title === 'Complemento' ? false : props.value.length === 0 ? true : false}
        height={px2vw(150)}
        width={props.optionalWidth ? props.optionalWidth : '100%'}
        onChange={props.onChange}
        disabled={props.disabled}
      />
    </Col >
  )
}

export default function ProfileConfigsMobile({ handler }) {
  const options = [
    { 'title': 'Nome da loja *', 'value': handler.storeName, 'onChange': handler.setStoreName },
    { 'title': 'CPF / CNPJ *', 'value': handler.cpfCnpj, 'onChange': handler.setCpfCnpj },
    { 'title': 'Senha', 'value': handler.password, 'optionalWidth': px2vw(800), 'disabled': 'true' },
  ]

  const secondOptions = [
    { 'title': 'Whatsapp do delivery *', 'value': handler.whatsappDelivery, 'onChange': handler.setWhatsappDelivery, 'optionalMargbottom': px2vw(150) },
    { 'title': 'CEP *', 'value': handler.zipcode, 'onChange': handler.setZipcode },
    { 'title': 'Endereço *', 'value': handler.street, 'onChange': handler.setStreet },
  ]

  const addressComplements = [
    { 'title': 'Cidade *', 'value': handler.city, 'onChange': handler.setCity, 'optionalWidth': px2vw(820) },
    { 'title': 'Estado *', 'value': handler.state, 'onChange': handler.setState, 'optionalWidth': px2vw(290) }
  ]

  const thirdOptions = [
    { 'title': 'Nome Completo *', 'value': handler.representativeName, 'onChange': handler.setRepresentativeName },
    { 'title': 'Email *', 'value': handler.email, 'onChange': handler.setEmail },
    { 'title': 'Telefone (Whatsapp) *', 'value': handler.phoneWhatsappRepresentative, 'onChange': handler.setPhoneWhatsappRepresentative },
  ]

  const rowOptions = [
    { 'title': 'Número *', 'value': handler.number, 'onChange': handler.setNumber, 'optionalWidth': px2vw(370) },
    { 'title': 'Complemento', 'value': handler.complement, 'onChange': handler.setComplement, 'optionalWidth': px2vw(750) }
  ]

  return (
    <>
      <Col height='auto' alignCenter >
        {/* Upload Cover Image */}
        <Row>
          <DragImageBox isUploadCoverMobile>
            {!handler.fileCoverData.base64 &&
              !handler.user.advertiserInfo.coverImage && (

                <NormalText
                  fontweight='bold'
                  color={branco}
                  fontsize={px2vw(55)}
                  textalign='flex-start'>
                  Arraste e solte a foto de capa aqui
                </NormalText>

              )}
            {handler.fileCoverData.base64 && (
              <ImageCoverPreview isMobile src={handler.fileCoverData.base64} />
            )}
            {handler.user.advertiserInfo.coverImage &&
              !handler.fileCoverData.base64 && (
                <ImageCoverPreview
                  isMobile
                  src={`${handler.user.advertiserInfo.coverImage.url
                    }?nocache=${new Date().getTime()}`}
                />
              )}
            <InputImage
              onChange={handler.handleFileCover}
              type="file"
              id="coverImage"
              name="coverImage"
            />
          </DragImageBox>
        </Row>
        {/* Upload Logo Image */}
        <Row margtop={px2vw(-105)}>
          <DragImageBox isUploadLogoMobile pad='0'>
            {!handler.fileLogoData.base64 && !handler.user.avatar && (
              <>
                <CircleBot isMobile>
                  <Col>
                    <Row height="auto">
                      <NormalText
                        fontweight='bold'
                        color={branco}
                        fontsize={px2vw(45)}
                        textalign='flex-start'>
                        Adicionar
                      </NormalText>
                    </Row>
                    <Row height="auto">
                      <NormalText
                        fontweight='bold'
                        color={branco}
                        fontsize={px2vw(45)}
                        textalign='flex-start'>
                        Logo
                      </NormalText>
                    </Row>
                  </Col>
                </CircleBot>
              </>
            )}
            {handler.fileLogoData.base64 && (
              <ImageLogoPreview isMobile src={handler.fileLogoData.base64} />
            )}
            {handler.user.avatar && !handler.fileLogoData.base64 && (
              <ImageLogoPreview
                isMobile
                src={
                  handler.user.avatar
                    ? `${handler.user.avatar.url
                    }?nocache=${new Date().getTime()}`
                    : advertiserLogoImg
                }

              />)}
            <InputImage
              onChange={handler.handleFileLogo}
              type="file"
              id="logoImage"
              name="logoImage"
            />
          </DragImageBox>
        </Row>

        <FormBox web={false} onSubmit={handler.handleStoreConfigsSubmit}>
          <Card
            margtop={px2vw(90)}
            padtop={px2vw(20)}
            padright={px2vw(60)}
            padleft={px2vw(60)}
            margbottom={px2vw(300)}
            borderRadius={`${px2vw(60)} ${px2vw(60)} ${px2vw(60)} ${px2vw(60)}`}
            width={1320}
            height='auto'
          >
            <NormalText
              fontweight='bold'
              color={preto}
              fontsize={px2vw(80)}
            >
              Dados da Loja
            </NormalText>
            {options.map((option) => {
              return (
                <Field
                  title={option.title}
                  key={`options${option.title.toString()}`}
                  value={option.value}
                  onChange={option.onChange}
                  optionalWidth={option.optionalWidth && option.optionalWidth}
                  disabled={option.disabled && option.disabled}
                />
              )
            })}

            <Row justifyEnd padright={px2vw(80)} margleft={px2vw(40)} margtop={px2vw(-150)} margbottom={px2vw(60)} >
              <NormalText
                cursor='pointer'
                textdecoration='underline'
                textalign='end'
                fontweight='normal'
                color={vermelhoKuppi1}
                fontsize={px2vw(50)}
                onClick={handler.setChangePassword}
              >
                Alterar senha
              </NormalText>
            </Row>

            <Col margtop={px2vw(50)} margbottom={px2vw(30)}>
              <NormalText
                fontweight='bold'
                textalign='flex-start'
                color={preto}
                fontsize={px2vw(50)}>
                Categoria do Estabelecimento *
              </NormalText>
              <SelectInputBox
                isMobile
                name="advertiserCategory"
                placeholder="Defina a categoria"
                options={handler.advertiserCategories}
                value={handler.advertiserCategoryId}
                onChange={(e) => handler.setAdvertiserCategoryId(e)}
              />
            </Col>


            {secondOptions.map((option) => {
              return <Field
                title={option.title}
                key={`secondOptions${option.title.toString()}`}
                value={option.value} onChange={option.onChange}
                optionalWidth={option.optionalWidth && option.optionalWidth}
                optionalMargbottom={option.optionalMargbottom && option.optionalMargbottom}
              />
            })}
            <Row >
              {rowOptions.map((option) => {
                return <Field
                  title={option.title}
                  key={`rowOptions${option.title.toString()}`}
                  value={option.value} onChange={option.onChange}
                  optionalWidth={option.optionalWidth && option.optionalWidth}
                />
              })}
            </Row>
            <Field
              title='Bairro *'
              key={`rowOptionsneighboorhoord`}
              value={handler.neighborhood} onChange={handler.setNeighborhood}
            />
            <Row margleft={px2vw(-40)}>
              {addressComplements.map((option) => {
                return <Field
                  margleft={px2vw(80)}
                  title={option.title}
                  key={`rowOptions${option.title.toString()}`}
                  value={option.value} onChange={option.onChange}
                  optionalWidth={option.optionalWidth && option.optionalWidth}
                  disabled
                />
              })}
            </Row>
            <NormalText
              fontweight='bold'
              color={preto}
              fontsize={px2vw(80)}
              margtop={px2vw(150)}
            >
              Dados do Responsável
            </NormalText>
            {thirdOptions.map((option) => {
              return <Field
                title={option.title}
                key={`thirdOptions${option.title.toString()}`}
                value={option.value} onChange={option.onChange}
                optionalWidth={option.optionalWidth && option.optionalWidth}
              />
            })}
            <Col width="auto" height="auto" alignEnd>
              <Row width="auto" height="auto" >
                <ButtonPrimary
                  fontSize={50}
                  type="submit"
                  height={150}
                  minWidth={600}
                  borderRadius={25}>
                  {handler.onboardingStoreConfigsLoading || handler.updateOnboardingStepLoading ? (
                    <LoadingBox>
                      <Spinner
                        style={{
                          width: '1.8rem',
                          height: '1.8rem',
                          color: preto,
                        }}
                      />{' '}
                    </LoadingBox>
                  ) : (
                    'Confirmar'
                  )}
                </ButtonPrimary>
              </Row>
            </Col>
          </Card>
        </FormBox>
      </Col>
      {handler.changePassword && <ChangePasswordModal handler={handler} isMobile={true} />}
    </>
  )
}