export function rowsPerPageOptions(length) {
  if ((length < 10 && length > 5) || length === 10) {
    return [5, length];
  }
  if (length < 5 || length === 5) {
    return [length];
  }

  return [5, 10, length];
}
