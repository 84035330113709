import axios from 'axios';
import { getURL } from '../utils/utils';

// Create Axios for Global API
export const apiGlobal = axios.create({
  baseURL: getURL('global_api'),
  timeout: 30000,
});

// Create Axios for Anunciante API
export const apiAnunciante = axios.create({
  baseURL: getURL('anunciante_api'),
  timeout: 65000,
});

// Create Axios for RD Station API
export const apiRDStation = axios.create({
  baseURL: 'https://api.rd.services',
  timeout: 30000,
})
