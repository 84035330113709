import styled from 'styled-components';

import px2vw from '../../../utils/responsiveness/px2vw';

import { Select } from '@rocketseat/unform';

import {
  branco,
  branco2,
  preto2,
  verdeKuppi1,
  cinzaKuppi3,
  cinzaKuppi4,
  verdeKuppi29
} from '../../../styles/colors';

export const OrderReportFormBox = styled.div`
  border-radius: ${px2vw(20)};
  background-color: ${branco};
  width: ${px2vw(1020)};
  height: auto;
  padding: ${px2vw(30)};
  margin-top: ${px2vw(5)};
  margin-bottom: ${px2vw(25)};

  box-shadow: 0px 8px 9px -2px ${cinzaKuppi4};
`;

export const TextFormBold = styled.div`
  font-size: ${px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.36px;
  border-right: ${(props) =>
    props.separator ? `1px solid ${cinzaKuppi4}` : 'none'};
  width: ${(props) => props.width};
  height: ${(props) => props.height};

  ${(props) =>
    props.alignCenter &&
    `
    text-align: center;
  `};

  ${(props) =>
    props.flexCenter &&    `
    display: flex;
    align-items: center;
    justify-content: center;
  `};
`;

export const TextFormRegular = styled.div`
  font-size: ${px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.36px;
`;

export const OrdersReportBox = styled.div`
  border-radius: ${px2vw(20)};
  background-color: ${branco};
  width: ${px2vw(1020)};
  height: auto;
  padding-top: ${px2vw(20)};
  padding-left: ${px2vw(30)};
  padding-right: ${px2vw(30)};
  margin-top: ${px2vw(5)};
  margin-bottom: ${px2vw(25)};

  box-shadow: 0px 8px 9px -2px ${cinzaKuppi4};
`;

export const TextBold = styled.div`
  font-size: ${px2vw(16)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.36px;
  border-right: ${(props) =>
    props.separator ? `1px solid ${cinzaKuppi4}` : 'none'};
  width: ${(props) => props.width};
  height: ${(props) => props.height};


  ${(props) => `
    font-size: ${props.fontSize};
  `};

  ${(props) =>
    props.ColorGreen &&
    `
    font-size: ${px2vw(15)};
    color: ${verdeKuppi1};
  `};

  ${(props) =>
    props.alignCenter &&
    `
    text-align: center;
  `};

  ${(props) =>
    props.flexCenter &&    `
    display: flex;
    align-items: center;
    justify-content: center;
  `};
`;

export const DateDiv = styled.div`
  .date {
    width: ${px2vw(100)};
    padding: 0.5em;
    border: 1px solid ${cinzaKuppi4};
    border-radius: ${px2vw(5)};
    font-size: ${px2vw(14)};
    font-family: AvenirNextLTPro;
    font-weight: normal;
    letter-spacing: 0.36px;
    height: ${px2vw(32)};

    ::placeholder {
      color: ${cinzaKuppi4} !important;
    }

    :focus {
      border: 1px solid ${cinzaKuppi3} !important;
      box-shadow: none !important;
    }
  }
`;

export const FilterButton = styled.button`
  background-color: ${verdeKuppi1};
  width: ${px2vw(100)};
  height: ${px2vw(30)};
  border: none;
  border-radius: 10px;
  padding: 0.3em;
  margin-left: ${px2vw(30)};
`;

export const FilterButtonText = styled.div`
  font-size: ${px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.36px;
  color: ${branco};
`;

export const TextRegular = styled.div`
  font-size: ${px2vw(12)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.36px;

  ${(props) => `font-size: ${props.fontSize};`};
`;

export const SelectInputBox = styled(Select)`
  width: ${(props) => props.width || px2vw(200)};
  height: ${(props) => props.height || px2vw(32)};
  font-size: ${(props) => props.ftsize || px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.36px;
  background: ${branco};
  border-radius:${px2vw(5)};
  padding-left: ${px2vw(5)};
  border: ${px2vw(1)} solid ${cinzaKuppi4};
  

  ${(props) => `
    margin-left: ${props.margleft};
    margin-bottom: ${props.margbottom};
    padding-left: ${props.padleft};
    padding-right: ${props.padright};
  `};
`;

export const ContainerForImg = styled.div`
  width: ${px2vw(35)};
  height: ${px2vw(20)};
  display: flex;
  justify-content: center;

  ${(props) => `
    width: ${props.width};
    height: ${props.height};
  `}; 
`;

export const AIButton = styled.div`
  width: ${px2vw(210)};
  height: ${px2vw(30)};
  border-radius: ${px2vw(5)};
  border: ${px2vw(1)} ${preto2} solid;
  display: flex;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
`;

export const AIRecomendationTextBox = styled.div`
  width: 100%;
  height: 100%;
  padding: ${px2vw(15)};
  margin-top: ${px2vw(20)};
  margin-bottom: ${px2vw(20)};
  background: ${branco2};
  border-radius: ${px2vw(10)};
  border: ${px2vw(1)} ${cinzaKuppi4} solid;
  display: none;

  ${(props) => props.showBox && `
    display: flex;
    flex-direction: column;
  `};

`;

export const SubTitleBox = styled.div`
  width: ${px2vw(225)};
  height: ${px2vw(30)};
  border-radius: ${px2vw(25)};
  background: ${verdeKuppi29};
  display: flex;
  justify-content: center;
  align-items: center;
`;