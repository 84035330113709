import React, {
} from 'react';
import {
  Header,
  HeaderButton,
} from './styles';
import { SvgBox, Arrow, Arrow2 } from './icons';


export default function CouponRows({ width, title, isSortedColumn, handleSort }) {

  return (
    <Header width={width} onClick={() => handleSort()}>
      <HeaderButton>
        {title}
        <SvgBox sorted={isSortedColumn}>
          <Arrow />
          <Arrow2 />
        </SvgBox>
      </HeaderButton>
    </Header>
  )
}