import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage/session';
import auth from './auth/reducer';
import user from './user/reducer';
import firstSteps from './firstSteps/reducer';
import userAddressQuery from './userAddressQuery/reducer';
import orders from './orders/reducer';
import manualOrder from './manualOrder/reducer';
import productCategories from './productCategories/reducer';
import coupons from './coupons/reducer';
import usedCoupons from './usedCoupons/reducer';
import myStore from './myStore/reducer';
import loyaltyCard from './loyaltyCard/reducer';
import clients from './clients/reducer';
import dashboardMetrics from './dashboardMetrics/reducer';
import kuppiBoost from './kuppiBoost/reducer';
import onboarding from './onboarding/reducer';
import importIfood from './importIfood/reducer';
import signaturePayment from './signaturePayment/reducer';
import profileAccount from './profileAccount/reducer';
import reports from './reports/reducer';
import mgm from './mgm/reducer';
import notifications from './notifications/reducer';
import whatsappChat from './whatsappChat/reducer';
import whatsappChatConversations from './whatsappChatConversations/reducer';
import ratings from './ratings/reducer';
import salesRecover from './salesRecover/reducer';
import massMessage from './massMessage/reducer';
import insightsAi from './insightsAI/reducer';
import challenges from './challenges/reducer';
import massHandleComplements from './massHandleComplements/reducer';
import financial from './financial/reducer';

const appReducer = combineReducers({
  auth,
  user,
  firstSteps,
  userAddressQuery,
  orders,
  manualOrder,
  productCategories,
  coupons,
  usedCoupons,
  myStore,
  loyaltyCard,
  clients,
  dashboardMetrics,
  kuppiBoost,
  onboarding,
  importIfood,
  signaturePayment,
  profileAccount,
  reports,
  mgm,
  notifications,
  whatsappChat,
  whatsappChatConversations,
  ratings,
  salesRecover,
  massMessage,
  insightsAi,
  challenges,
  massHandleComplements,
  financial,
});

const rootReducer = (state, action) => {
  if (action.type === '@auth/LOG_OUT') {
    storage.removeItem('persist:root');
    storage.removeItem('persist:kuppi-advertiser');
    state = undefined;
  }

  return appReducer(state, action);
};

export { rootReducer };
