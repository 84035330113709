import React from 'react';
import { MessageVideo } from '../../style';
import BrokenMedia from '../../BrokenMedia';

export const VideoMessage = ({ video }) => {
  return video.url ? (
    <>
      <MessageVideo width="320" height="240" controls>
        <source src={video.url} type="video/mp4" />
        Your browser does not support the video tag.
      </MessageVideo>
    </>
  ) : (
    <BrokenMedia type={'video'} />
  );
};
