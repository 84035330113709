import produce from 'immer';

const INITIAL_STATE = {
  address: {
    zipcode: '',
    street: '',
    neighborhood: '',
    city: '',
    state: '',
  },
  addressAdditionalData: {
    number: '',
    complement: '',
    phone1: '',
    phone2: '',
    whatsapp: '',
  },
  success: false,
  loading: false,
};

export default function userAddressQuery(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@userAddressQuery/QUERY_ZIPCODE_REQUEST': {
        draft.loading = true;
        draft.address = {
          zipcode: '',
          street: '',
          neighborhood: '',
          city: '',
          state: '',
        };
        break;
      }
      case '@userAddressQuery/QUERY_ZIPCODE_SUCCESS': {
        draft.loading = false;
        draft.address = action.payload.addressData;
        break;
      }
      case '@userAddressQuery/QUERY_ZIPCODE_FAILURE': {
        draft.loading = false;
        draft.address = {
          zipcode: '',
          street: '',
          neighborhood: '',
          city: '',
          state: '',
        };
        break;
      }
      case '@userAddressQuery/CLEAR_SUCCESS': {
        draft.success = false;
        break;
      }
      case '@auth/LOGIN_SUCCESS': {
        draft.address.zipcode = action.payload.user.address[0].zipcode;
        draft.address.street = action.payload.user.address[0].street;
        draft.address.neighborhood =
          action.payload.user.address[0].neighborhood;
        draft.address.city = action.payload.user.address[0].city;
        draft.address.state = action.payload.user.address[0].state;
        draft.addressAdditionalData.number =
          action.payload.user.address[0].number;
        draft.addressAdditionalData.complement =
          action.payload.user.address[0].complement;
        draft.addressAdditionalData.phone1 =
          action.payload.user.address[0].phone_1;
        draft.addressAdditionalData.phone2 =
          action.payload.user.address[0].phone_2;
        draft.addressAdditionalData.whatsapp =
          action.payload.user.address[0].phone_whatsapp;
        break;
      }
      case '@user/GET_USER_SUCCESS': {
        draft.address.zipcode = action.payload.userData.address[0].zipcode;
        draft.address.street = action.payload.userData.address[0].street;
        draft.address.neighborhood =
          action.payload.userData.address[0].neighborhood;
        draft.address.city = action.payload.userData.address[0].city;
        draft.address.state = action.payload.userData.address[0].state;
        draft.addressAdditionalData.number =
          action.payload.userData.address[0].number;
        draft.addressAdditionalData.complement =
          action.payload.userData.address[0].complement;
        draft.addressAdditionalData.phone1 =
          action.payload.userData.address[0].phone_1;
        draft.addressAdditionalData.phone2 =
          action.payload.userData.address[0].phone_2;
        draft.addressAdditionalData.whatsapp =
          action.payload.userData.address[0].phone_whatsapp;
        break;
      }
      case '@user/UPDATE_USER_ADDRESS_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@user/UPDATE_USER_ADDRESS_SUCCESS': {
        draft.success = true;
        draft.addressAdditionalData.number =
          action.payload.userAddressData.number;
        draft.addressAdditionalData.complement =
          action.payload.userAddressData.complement;
        draft.addressAdditionalData.phone1 =
          action.payload.userAddressData.phone_1;
        draft.addressAdditionalData.phone2 =
          action.payload.userAddressData.phone_2;
        draft.addressAdditionalData.whatsapp =
          action.payload.userAddressData.phone_whatsapp;
        draft.loading = false;
        break;
      }
      case '@user/UPDATE_USER_ADDRESS_FAILURE': {
        draft.success = false;
        draft.loading = false;
        break;
      }
      default:
    }
  });
}
