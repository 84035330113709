import React from 'react';
import { AiOutlinePlus } from 'react-icons/ai';
import { ManualOrderText, ManualOrderButtonPrimary } from './styles';
import px2vw from '../../utils/responsiveness/px2vw';
import { Row } from '../../styles/grid';

export default function ManualOrderBar(props) {
  return (
    <Row width="auto" alignCenter>
      <ManualOrderText>Faça o pedido você mesmo!</ManualOrderText>
      <ManualOrderButtonPrimary onClick={props.onClick}>
        <Row width="auto">
          <AiOutlinePlus fontSize={px2vw(16)} fontWeight="bold" />
          <Row width="auto" margleft={px2vw(10)}>Criar pedido manual</Row>
        </Row>
      </ManualOrderButtonPrimary>
    </Row>
  );
}
