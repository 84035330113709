import React, {
  Fragment,
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';

import moment from 'moment';

import px2vw from '../../../../utils/responsiveness/px2vw';
import { BsChevronDoubleDown } from 'react-icons/bs';
import {
  Header,
  Content,
  Hr,
  TableRow,
  HeaderButton,
  PagButton,
  PrevIcon,
  NextIcon,
  Container,
  SecundaryButton,
} from './styles';

import { sort, makePages } from './tableOptions';

import TablePerPage from '../../../../components/TablePerPage';
import { Col, Row } from '../../../../styles/grid';

import { useDispatch, useSelector } from 'react-redux';
import CampaignDetailModal from '../../CampaignDetailModal';
import { Text } from '../../../../styles/common';
import { getCampaignDetails } from '../../../../store/modules/massMessage/actions';
import MassMessageModal from '..';
import { ButtonPrimary } from '../../styles';

export default function CampaignTable({ data, onClickCreateCampaign }) {
  const dispatch = useDispatch();
  const [rows, setRows] = useState(data);
  const [rowsCurrentPage, setRowsCurrentPage] = useState();
  const [isCampaignModalVisible, setIsCampaignModalVisible] = useState(false);
  const [structure, setStructure] = useState(null);
  const [currentPage, setCurrentPage] = useState('1');
  const [lastPage, setLastPage] = useState();
  const [firstPage] = useState('1');
  const [sortedCol, setSortedCol] = useState('id');
  const [isCampaignDetailModalOpen, setIsCampaignDetailModalOpen] =
    useState(false);
  const user = useSelector((state) => state.user.user);
  const { currentStep } = useSelector((state) => state.massMessage);

  useEffect(() => {
    if (structure) {
      setLastPage(
        Object.keys(structure)[Object.keys(structure).length - 1].toString()
      );
    }
  }, [structure]);

  useMemo(() => {
    setStructure(makePages(rows.length, rowsCurrentPage));
  }, [rows, rowsCurrentPage]);

  useEffect(() => {
    setRows(data);
  }, [data]);

  useEffect(() => {
    if (rows.length < 5) {
      setRowsCurrentPage(rows.length);
    }
    if (rowsCurrentPage > 5) {
    } else {
      setRowsCurrentPage(5);
    }
  }, [rows]);

  const handleSort = useCallback(
    (col, inside) => {
      if (sortedCol !== col) {
        setSortedCol(col);
        if (inside) {
          setRows(sort(rows, col, 'asc', true));
        } else {
          setRows(sort(rows, col, 'asc'));
        }
      } else {
        setSortedCol('');
        if (inside) {
          setRows(sort(rows, col, 'desc', true));
        } else {
          setRows(sort(rows, col, 'desc'));
        }
      }
    },
    [rows]
  );

  function handlePerPage(size) {
    setRowsCurrentPage(size);
    setCurrentPage('1');
  }

  function handleChangePage(direction) {
    if (direction === 'next') {
      const curr = +currentPage + 1;
      setCurrentPage(curr.toString());
    } else if (direction === 'prev') {
      const curr = +currentPage - 1;
      setCurrentPage(curr.toString());
    }
  }

  function returnStatus(type) {
    switch (type) {
      case 'pending_payment':
        return 'Aguardando Pagamento';
      case 'canceled_payment':
        return 'Pagamento Cancelado';
      case 'paid':
        return 'Pagamento Confirmado';
      case 'sending':
        return 'Em Andamento';
      case 'error':
        return 'Erro no Envio';
      case 'finished':
        return 'Envio Concluído';
      case 'expired_payment':
        return 'Pagamento Expirado';
      case 'canceled':
        return 'Envio Cancelado';
      default:
        return '-';
    }
  }

  function handleSelectedCampaign(cpnId) {
    dispatch(getCampaignDetails(cpnId));
    setIsCampaignDetailModalOpen(true);
  }

  return (
    <Col padding="0 15px" alignCenter justifyCenter>
      <Row alignCenter>
        <Header width={px2vw(135)} onClick={() => handleSort('scheduled_at')}>
          <HeaderButton>
            Data do <br /> Envio
            <BsChevronDoubleDown
              style={{
                fontSize: px2vw(15),
                fontWeight: 'bolder',
                position: 'absolute',
                right: 0,
                transform: sortedCol === 'scheduled_at' ? 'rotate(180deg)' : '',
                transition: 'all 0.2s ease-in-out',
              }}
            />
          </HeaderButton>
        </Header>

        <Header width={px2vw(135)} onClick={() => handleSort('scheduled_at')}>
          <HeaderButton>
            Expiração do
            <br /> pagamento
            <BsChevronDoubleDown
              style={{
                fontSize: px2vw(15),
                fontWeight: 'bolder',
                position: 'absolute',
                right: 0,
                transform: sortedCol === 'scheduled_at' ? 'rotate(180deg)' : '',
                transition: 'all 0.2s ease-in-out',
              }}
            />
          </HeaderButton>
        </Header>

        <Header width={px2vw(130)} onClick={() => handleSort('status')}>
          <HeaderButton>
            Status
            <BsChevronDoubleDown
              style={{
                fontSize: px2vw(15),
                fontWeight: 'bolder',
                position: 'absolute',
                right: 0,
                transform: sortedCol === 'status' ? 'rotate(180deg)' : '',
                transition: 'all 0.2s ease-in-out',
              }}
            />
          </HeaderButton>
        </Header>

        <Header
          width={px2vw(135)}
          onClick={() => handleSort('reached_contacts_qty')}
        >
          <HeaderButton>
            Pessoas
            <br /> alcançadas
            <BsChevronDoubleDown
              style={{
                fontSize: px2vw(15),
                fontWeight: 'bolder',
                position: 'absolute',
                right: 0,
                transform:
                  sortedCol === 'reached_contacts_qty' ? 'rotate(180deg)' : '',
                transition: 'all 0.2s ease-in-out',
              }}
            />
          </HeaderButton>
        </Header>

        <Header
          width={px2vw(125)}
          onClick={() => handleSort('link_clicks_qty')}
        >
          <HeaderButton>
            Cliques no
            <br /> link
            <BsChevronDoubleDown
              style={{
                fontSize: px2vw(15),
                fontWeight: 'bolder',
                position: 'absolute',
                right: 0,
                transform:
                  sortedCol === 'link_clicks_qty' ? 'rotate(180deg)' : '',
                transition: 'all 0.2s ease-in-out',
              }}
            />
          </HeaderButton>
        </Header>

        <Header width={px2vw(125)} onClick={() => handleSort('sales_qty')}>
          <HeaderButton>
            Vendas
            <BsChevronDoubleDown
              style={{
                fontSize: px2vw(15),
                fontWeight: 'bolder',
                position: 'absolute',
                right: 0,
                transform: sortedCol === 'sales_qty' ? 'rotate(180deg)' : '',
                transition: 'all 0.2s ease-in-out',
              }}
            />
          </HeaderButton>
        </Header>
      </Row>
      <Row>
        <Hr />
      </Row>
      <Container>
        {structure ? (
          rows.map((campaign, index) =>
            structure[currentPage] &&
            structure[currentPage].start <= index + 1 &&
            structure[currentPage].end >= index + 1 ? (
              <Fragment key={campaign?.id}>
                <TableRow
                  relative
                  alignCenter
                  width="100%"
                  minheight={px2vw(65)}
                  className="py-1"
                >
                  {campaign?.campaignImage?.url ? (
                    <img
                      alt="campaignImg"
                      style={{
                        borderRadius: px2vw(5),
                        position: 'absolute',
                        width: px2vw(70),
                        left: px2vw(45),
                        height: px2vw(45),
                        margin: 'auto 0',
                      }}
                      src={campaign?.campaignImage?.url}
                    />
                  ) : (
                    <img
                      alt="campaignImg"
                      style={{
                        borderRadius: px2vw(5),
                        position: 'absolute',
                        width: px2vw(70),
                        left: px2vw(45),
                        height: px2vw(45),
                        margin: 'auto 0',
                        filter: 'blur(4px)',
                      }}
                      src={user.avatar.url}
                    />
                  )}
                  <Content alignCenter width={px2vw(135)}>
                    {moment(campaign?.scheduled_at)
                      .utcOffset('+00:00')
                      .format('DD/MM/YYYY HH:mm')}
                  </Content>
                  <Content alignCenter width={px2vw(135)}>
                    {campaign?.status === 'pending_payment'
                      ? moment(
                          campaign?.advertiserMassMessagePayment[0]
                            ?.pix_expiration_at
                        )
                          .utcOffset('+00:00')
                          .format('DD/MM/YYYY HH:mm')
                      : '-'}
                  </Content>
                  <Content alignCenter width={px2vw(130)}>
                    {returnStatus(campaign?.status)}
                  </Content>
                  <Content alignCenter width={px2vw(135)}>
                    {campaign?.reached_contacts_qty} de {campaign?.contacts_qty}
                  </Content>
                  <Content alignCenter width={px2vw(125)}>
                    {campaign?.link_clicks_qty}
                  </Content>
                  <Content alignCenter fontWt="700" width={px2vw(125)}>
                    {campaign?.sales_qty}
                  </Content>
                  <SecundaryButton
                    onClick={() => handleSelectedCampaign(campaign?.id)}
                  >
                    Detalhes
                  </SecundaryButton>
                </TableRow>
                <Row>
                  <Hr />
                </Row>
              </Fragment>
            ) : null
          )
        ) : (
          <>
            <Row padding="15px 0 10px 0" alignCenter margtop={px2vw(80)}>
              <img
                alt="noCampaigns"
                src={require(`../../../../assets/images/kuppi_boost/sad_id.png`)}
                style={{ marginRight: px2vw(10), width: px2vw(30) }}
              />
              <Text size={px2vw(15)}>
                Você não possui nenhuma campanha ativa
              </Text>
            </Row>
            <Row padding="15px 0 10px 0" alignCenter margtop={px2vw(15)}>
              <ButtonPrimary
                width={px2vw(150)}
                size={px2vw(13)}
                height={px2vw(30)}
                onClick={onClickCreateCampaign}
              >
                Criar campanha
              </ButtonPrimary>
            </Row>
          </>
        )}
      </Container>
      {structure && (
        <Row alignCenter justifyEnd>
          <Row alignCenter justifyEnd height={px2vw(40)} margin={px2vw(10)}>
            <TablePerPage response={handlePerPage} length={rows.length} />
            <Row
              style={{ fontSize: px2vw(15) }}
              alignCenter
              justifyCenter
              width="12%"
            >
              {!structure[currentPage] && handleChangePage('prev')}
              {structure[currentPage] && structure[currentPage].start} -{' '}
              {structure[currentPage] && structure[currentPage].end} de{' '}
              {rows.length}
            </Row>
            <PagButton
              onClick={() => handleChangePage('prev')}
              disabled={currentPage === firstPage}
              title="Página anterior"
            >
              <PrevIcon disabled={currentPage === firstPage} />
            </PagButton>
            <PagButton
              onClick={() => handleChangePage('next')}
              title="Próxima página"
              disabled={currentPage === lastPage}
            >
              <NextIcon disabled={currentPage === lastPage} />
            </PagButton>
          </Row>
        </Row>
      )}
      {isCampaignModalVisible && (
        <MassMessageModal
          height={px2vw(565)}
          width={px2vw(currentStep === '3' || currentStep === '2' ? 780 : 700)}
          padding="none"
          onClose={() => {
            setIsCampaignModalVisible(false);
          }}
          isVisible
        />
      )}
      {isCampaignDetailModalOpen && (
        <CampaignDetailModal
          padding="none"
          onClose={() => setIsCampaignDetailModalOpen(false)}
          onEdit={() => setIsCampaignModalVisible(true)}
          onClickCreateCampaign={() => {
            onClickCreateCampaign();
            setIsCampaignDetailModalOpen(false);
          }}
          isVisible
        />
      )}
    </Col>
  );
}
