import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  cinzaKuppi10,
  cinzaKuppi4,
  cinzaKuppi7,
  preto,
} from '../../../../styles/colors';
import QRCode from 'qrcode';
import { Text } from '../../../../styles/common';

import { ModalPrimaryButton } from '../styles';
import { Col, Row } from '../../../../styles/grid';
import { weekdays } from '../../../../utils/getCurrentDay';
import {
  ModalContent,
  CloseButton,
  ModalHeader,
  CloseImage,
  ModalFooter,
  Span,
  ScheduleDay,
  SideSlider,
  ScheduleItem,
  Input,
  PixCodeBar,
  PixCodeImg,
} from './styles';
import { Radio } from '@material-ui/core';

import { AiOutlineLeft } from 'react-icons/ai';
import { MdContentCopy } from 'react-icons/md';
import px2vw from '../../../../utils/responsiveness/px2vw';
import { addDays, isToday } from 'date-fns';
import { formatDate } from '../../../../utils/formatDate';
import animationData from '../../../../assets/animations/kuppiBoost/kuppiBoost.json';
import {
  createCampaign,
  getMassMessageData,
  setCurrentStep,
  setSelectedSchedule as setSelectedGlobalSchedule,
} from '../../../../store/modules/massMessage/actions';
import { timeMask } from '../../../../utils/masks';
import { isTimeValid } from '../../../../utils/validations/validations';
import { toast } from 'react-toastify';
import moment from 'moment';
import Lottie from 'react-lottie';
import { getChallengesStatusRequest } from '../../../../store/modules/challenges/actions';

export default function Schedule(props) {
  const dispatch = useDispatch();
  const [qrCodePix, setQrCodePix] = useState('');
  const [selectedHour, setSelectedHour] = useState(null);
  const [horaryInput, setHoraryInput] = useState('');
  const schedulesAvailability = useSelector(
    (state) => state.user?.user?.advertiserInfo?.advertiserAvailability
  );
  const challengeState = useSelector(
    (state) => state.challenges.challenges
  );

  const {
    createCampaignLoading,
    createCampaignSuccess,
    currPayment,
    campaigns,
  } = useSelector((state) => state.massMessage);

  const availabilities = useMemo(
    () =>
      weekdays.map((day) =>
        schedulesAvailability ? schedulesAvailability[day] : null
      ),
    [schedulesAvailability]
  );

  const scheduleDays = useMemo(() => {
    let counter = 0;
    const weekDays = [];
    const date = new Date();
    const isAtLeastOneDayActive = availabilities.some((day) => day.is_active);

    while (weekDays.length < 7 && isAtLeastOneDayActive) {
      let day = addDays(date, counter);
      const currentAvailability = availabilities[day.getDay()];
      const currentAvailabilityFirstHorary =
        currentAvailability.horaries[0]?.start_at;

      if (
        currentAvailability?.is_active &&
        !(
          currentAvailabilityFirstHorary === '00:00' &&
          currentAvailability.horaries.length === 1
        )
      ) {
        weekDays.push({
          id: formatDate(day, 'yyyy-MM-dd-cccccc'),
          date: day,
          schedules: [],
          shortName: formatDate(day, 'cccccc'),
          shortMonthName: formatDate(day, 'MMM'),
        });
      }
      counter++;
    }

    return weekDays;
  }, [availabilities]);

  const [selectedSchedule, setSelectedSchedule] = useState(
    scheduleDays ? scheduleDays[0] : null
  );

  const createCampaignValidation =
    selectedHour === 'others'
      ? selectedSchedule && selectedHour && horaryInput.length === 5
      : selectedSchedule && selectedHour;

  function handleBacktoPreviousPage() {
    dispatch(setCurrentStep('4'));
  }

  const horary = useMemo(() => {
    const date = new Date(selectedSchedule?.date);
    const currentAvailability = availabilities[date.getDay()];
    const firstHorary =
      currentAvailability?.horaries.length > 1 &&
      currentAvailability?.horaries[0]?.start_at === '00:00'
        ? currentAvailability?.horaries[1].start_at
        : currentAvailability?.horaries[0].start_at;
    const isOpenningMidNigth =
      firstHorary?.substring(0, 2).replace(':', '') === '00';
    const preOpenningHorary = isOpenningMidNigth
      ? '22:00'
      : `${
          firstHorary?.substring(0, 2).replace(':', '') -
          2 +
          firstHorary?.substring(2)
        }`;

    const openningHour = moment().set({
      hour: firstHorary?.split(':')[0],
      minute: firstHorary?.split(':')[1],
      second: 0,
    });
    const preOpenningHour = moment().set({
      hour: preOpenningHorary?.split(':')[0],
      minute: preOpenningHorary?.split(':')[1],
      second: 0,
    });

    const validateTimeOpennning =
      openningHour.diff(new Date(), 'minutes') < 10 && isToday(date);

    const validateTimePreOpennning =
      preOpenningHour.diff(new Date(), 'minutes') < 10 && isToday(date);

    return {
      firstHorary,
      preOpenningHorary,
      validateTimeOpennning,
      validateTimePreOpennning,
    };
  }, [selectedSchedule]);

  function handleCreateCampaign() {
    const date = new Date(selectedSchedule?.date);
    const hourSelected = moment(selectedSchedule?.date).set({
      hour: horaryInput?.split(':')[0],
      minute: horaryInput?.split(':')[1],
      second: 0,
    });

    if (selectedHour === 'others' && !isTimeValid(horaryInput)) {
      toast.error('Horário inválido', {
        autoClose: 6000,
      });
      return;
    } else if (
      isToday(date) &&
      selectedHour === 'others' &&
      hourSelected.diff(new Date(), 'minutes') < 10
    ) {
      toast.error(
        'O horário de envio de ser 10 minutos maior que o horário atual',
        {
          autoClose: 6000,
        }
      );
      return;
    }

    let schedule = new Date();
    if (selectedHour === 'others') {
      schedule = moment(selectedSchedule?.date).set({
        hour: horaryInput?.substring(0, 2).replace(':', ''),
        minute: horaryInput?.substring(3, 5),
        second: 0,
      });
      dispatch(setSelectedGlobalSchedule(schedule.format('YYYY-MM-DD HH:mm')));
    } else if (selectedHour === 'preOpenning') {
      schedule = moment(selectedSchedule?.date).set({
        hour: horary.preOpenningHorary?.substring(0, 2).replace(':', ''),
        minute: horary.preOpenningHorary?.substring(3, 5),
        second: 0,
      });
      dispatch(setSelectedGlobalSchedule(schedule.format('YYYY-MM-DD HH:mm')));
    } else {
      schedule = moment(selectedSchedule?.date).set({
        hour: horary.firstHorary?.substring(0, 2).replace(':', ''),
        minute: horary.firstHorary?.substring(3, 5),
        second: 0,
      });
      dispatch(setSelectedGlobalSchedule(schedule.format('YYYY-MM-DD HH:mm')));
    }

    const hasAvailableCampaingAtSameHorary = campaigns.find(
      (c) =>
        c.scheduled_at === `${schedule.format('YYYY-MM-DDTHH:mm:ss.000')}Z` &&
         !['canceled_payment', 'canceled'].includes(c.status)
    );

    if (schedule.diff(moment().set({ second: 0 }), 'minutes') < 10) {
      toast.error(
        'O horário de envio de ser 10 minutos maior que o horário atual',
        {
          autoClose: 6000,
        }
      );
      return;
    }

    if (hasAvailableCampaingAtSameHorary) {
      toast.error(
        'Você já tem uma campanha agendada para esse horário, escolha outro horário',
        {
          autoClose: 6000,
        }
      );
    } else {
      dispatch(createCampaign());
    }
  }

  const pixCode = currPayment?.pix_qr_code;

  useEffect(() => {
    // Set QR code data
    QRCode.toDataURL(pixCode)
      .then((text) => {
        setQrCodePix(text);
      })
      .catch((err) => {});
  }, [pixCode]);

  useEffect(() => {
    if (
      currPayment &&
      currPayment?.total_amount === 0 &&
      createCampaignSuccess
    ) {
      dispatch(getMassMessageData());
      props.onClose();
    }
  }, [createCampaignSuccess, currPayment]);

  useEffect(function updateChallengeStateOnCloseScheduleModal() {
    return () => {
      if (!challengeState?.is_completed_modal_closed) {
        dispatch(getChallengesStatusRequest());
      }
    }
  }, [])

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid', // slice
    },
  };

  const handleClosePaidCampaign = () => {
    dispatch(getMassMessageData());
    props.onClose();
  }

  function copyPixPaymentCode() {
    var copyText = document.getElementById('pixCode');
    var textArea = document.createElement('textarea');
    textArea.value = copyText.textContent;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();

    toast.success('A chave Pix foi copiada com sucesso!');
  }

  const varName =
    selectedHour === 'preOpenning' ? 'preOpenningHorary' : 'firstHorary';

  if (createCampaignSuccess) {
    return (
      <>
        <ModalHeader>
          Realize o pagamento para efetuar o disparo
          <CloseButton onClick={handleClosePaidCampaign}>
            <CloseImage
              src={require('../../../../assets/images/close/close.png')}
            />
          </CloseButton>
        </ModalHeader>
        <ModalContent>
          <Col width="100%" alignCenter className="pr-0">
            <Text
              className="mr-auto"
              margbottom={px2vw(20)}
              margtop={px2vw(20)}
              justifyStart
              size={px2vw(14.5)}
            >
              Para pagar via Pix, utilize o QR Code ou copie e cole o código
              abaixo
            </Text>

            <PixCodeImg className="m-auto" src={qrCodePix} />

            <Row
              className="mx-auto"
              border={`${px2vw(3)} dashed ${preto}`}
              height={px2vw(43)}
              width="80%"
              alignCenter
              cursor="pointer"
              relative
              onClick={copyPixPaymentCode}
              margbottom={px2vw(20)}
              margtop={px2vw(20)}
              spaceBetween
              borderRadius={px2vw(8)}
            >
              <PixCodeBar id="pixCode" className="mx-auto">
                {currPayment?.pix_qr_code || ''.toUpperCase()}
              </PixCodeBar>
              <MdContentCopy
                className="mx-auto"
                style={{
                  fontSize: px2vw(21),
                }}
              />
            </Row>
            <Row justifyCenter alignCenter>
              <img
                alt="warningIcon"
                src={require('../../../../assets/images/mass_message/warning_icon.svg')}
                style={{
                  height: px2vw(25),
                  marginRight: px2vw(10),
                }}
              />
              <Text justifyStart regular size={px2vw(14)}>
                *O pagamento dessa campanha deverá ser realizado{' '}
                {isToday(currPayment?.pix_expiration_at) && 'hoje'} até{' '}
                {isToday(currPayment?.pix_expiration_at)
                  ? 'as ' +
                    moment(currPayment?.pix_expiration_at)
                      .utcOffset('+00:00')
                      .format('HH:mm')
                  : moment(currPayment?.pix_expiration_at)
                      .utcOffset('+00:00')
                      .format('DD/MM/YYYY [as] HH:mm')}
                . Caso não seja feito até o horário, a campanha será expirada
                automaticamente.
              </Text>
            </Row>
          </Col>
        </ModalContent>
        <Row height="auto" padbottom={px2vw(18)} justifyCenter className="pr-3">
          <ModalPrimaryButton
            width={px2vw(180)}
            onClick={handleClosePaidCampaign}
          >
            Informar pagamento
          </ModalPrimaryButton>
        </Row>
      </>
    );
  }

  if (createCampaignLoading) {
    return (
      <>
        <ModalHeader width="85%">
          Aguarde um momento, estamos preparando a melhor campanha para você :)
        </ModalHeader>
        <ModalContent>
          <Row height={px2vw(350)} alignStart justifyCenter>
            <Lottie
              options={defaultOptions}
              isStopped={false}
              isPaused={false}
            />
          </Row>
        </ModalContent>
      </>
    );
  }

  return (
    <>
      <ModalHeader>
        Escolha os melhores horários
        <CloseButton onClick={props.onClose}>
          <CloseImage
            src={require('../../../../assets/images/close/close.png')}
          />
        </CloseButton>
      </ModalHeader>
      <ModalContent>
        <SideSlider>
          {scheduleDays?.map((scheduleDay, idx) => (
            <ScheduleDay
              selected={scheduleDay.id === selectedSchedule?.id}
              onClick={() => {
                setSelectedSchedule(scheduleDay);
                setSelectedHour(null);
              }}
              key={`${scheduleDay.id}-${idx}`}
            >
              <Span
                fontSize={px2vw(14)}
                fontWeight="200"
                color={cinzaKuppi7}
                cursor="pointer"
                alignCenter
              >
                {`${
                  scheduleDay.shortMonthName.substr(0, 1).toUpperCase() +
                  scheduleDay.shortMonthName.substr(1)
                }/${scheduleDay.date.getFullYear().toString().substr(2, 2)}`}
              </Span>
              <Span
                alignCenter
                fontSize={px2vw(14)}
                fontWeight="200"
                color={cinzaKuppi7}
                cursor="pointer"
              >
                {isToday(scheduleDay.date)
                  ? 'hoje'
                  : scheduleDay.shortName.substr(0, 3)}
              </Span>
              <Span
                fontSize={px2vw(14)}
                fontWeight="700"
                color={preto}
                alignCenter
                cursor="pointer"
              >
                {scheduleDay.date.getDate()}
              </Span>
            </ScheduleDay>
          ))}
        </SideSlider>
        <Text margtop={px2vw(15)} weight="700" size={px2vw(15)}>
          Escolha o melhor horário
        </Text>
        <Col height="auto">
          <ScheduleItem>
            <Row
              spaceBetWeen
              alignCenter
              onClick={() =>
                horary?.validateTimePreOpennning || !selectedSchedule
                  ? null
                  : setSelectedHour('preOpenning')
              }
            >
              <Span
                fontSize={px2vw(14)}
                fontWeight="400"
                className="mx-0"
                color={
                  horary?.validateTimePreOpennning || !selectedSchedule
                    ? cinzaKuppi4
                    : cinzaKuppi10
                }
                cursor="pointer"
              >
                Pré-abertura:{' '}
                {selectedSchedule ? horary?.preOpenningHorary : '-'} (2 horas
                antes de abrir)
              </Span>
              <Radio
                className="ml-auto"
                checked={selectedHour === 'preOpenning'}
                color="default"
                disabled={horary?.validateTimePreOpennning || !selectedSchedule}
              />
            </Row>
          </ScheduleItem>
          <ScheduleItem>
            <Row
              spaceBetWeen
              alignCenter
              onClick={() =>
                horary?.validateTimeOpennning || !selectedSchedule
                  ? null
                  : setSelectedHour('openning')
              }
            >
              <Span
                fontSize={px2vw(14)}
                fontWeight="400"
                className="mx-0"
                color={
                  horary?.validateTimeOpennning || !selectedSchedule
                    ? cinzaKuppi4
                    : cinzaKuppi10
                }
                cursor="pointer"
              >
                Abertura: {horary?.firstHorary || '-'} (Horário de abertura)
              </Span>
              <Radio
                className="ml-auto"
                checked={selectedHour === 'openning'}
                color="default"
                disabled={horary?.validateTimeOpennning || !selectedSchedule}
              />
            </Row>
          </ScheduleItem>
          <ScheduleItem onClick={null}>
            <Row
              spaceBetWeen
              alignCenter
              onClick={() => setSelectedHour('others')}
            >
              <Span
                fontSize={px2vw(14)}
                fontWeight="400"
                className="mx-0"
                color={!selectedSchedule ? cinzaKuppi4 : cinzaKuppi10}
                cursor="pointer"
              >
                Outro
              </Span>
              <Radio
                className="ml-auto"
                disabled={!selectedSchedule}
                checked={selectedHour === 'others'}
                color="default"
              />
            </Row>
          </ScheduleItem>
        </Col>
        {selectedHour === 'others' && (
          <Row alignCenter justifyStart pad={`${px2vw(10)} 0`}>
            <Span
              fontSize={px2vw(14)}
              fontWeight="500"
              className="mr-2"
              color={preto}
              cursor="pointer"
            >
              Defina manualmente o melhor horário
            </Span>
            <Input
              type="text"
              maxLength="5"
              value={horaryInput}
              onChange={(t) => setHoraryInput(timeMask(t.target.value))}
              width={px2vw(50)}
            />
          </Row>
        )}
        {selectedSchedule && selectedHour && selectedHour !== 'others' && (
          <Row className="m-auto" justifyCenter alignCenter>
            <img
              alt="warningIcon2"
              src={require('../../../../assets/images/mass_message/warning_icon.svg')}
              style={{
                height: px2vw(25),
                marginRight: px2vw(10),
              }}
            />

            <Text justifyStart regular size={px2vw(14)}>
              *O pagamento dessa campanha deverá ser realizado{' '}
              {isToday(selectedSchedule?.date) ? 'hoje' : ''} até{' '}
              {isToday(selectedSchedule?.date)
                ? 'às ' +
                  moment()
                    .utcOffset('+00:00')
                    .set({
                      hour: horary?.[varName]?.substring(0, 2).replace(':', ''),
                      minute: horary?.[varName]?.substring(3, 5),
                    })
                    .add(1, 'h')
                    .format('HH:mm')
                : moment(selectedSchedule?.date)
                    .utcOffset('+00:00')
                    .set({
                      hour: horary?.[varName]?.substring(0, 2).replace(':', ''),
                      minute: horary?.[varName]?.substring(3, 5),
                    })
                    .add(1, 'h')
                    .format(
                      horary?.[varName]?.substring(0, 2).replace(':', '') > 11
                        ? 'DD/MM/YYYY [às] HH:mm'
                        : 'DD/MM/YYYY [às] hh:mm'
                    )}
              . Caso não seja feito até o horário, a campanha será expirada
              automaticamente.
            </Text>
          </Row>
        )}
      </ModalContent>
      <ModalFooter>
        <Row
          onClick={handleBacktoPreviousPage}
          justifyStart
          alignCenter
          width="auto"
          cursor="pointer"
        >
          <AiOutlineLeft fontSize={px2vw(15)} className="mr-1" />
          <Text regular alignCenter size={px2vw(15)}>
            Voltar
          </Text>
        </Row>

        <ModalPrimaryButton
          disabled={!createCampaignValidation}
          className="ml-auto"
          onClick={handleCreateCampaign}
        >
          Avançar
        </ModalPrimaryButton>
      </ModalFooter>
    </>
  );
}
