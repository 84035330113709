import styled from 'styled-components';
import px2vw from '../../../utils/responsiveness/px2vw';
import { Icon } from 'react-materialize';
import { Text } from '../../../styles/common';
import { verdeKuppi16, verdeKuppi5, branco } from '../../../styles/colors';

export const CloseModal = styled(Icon)`
  position: absolute;
  right: ${px2vw(10)};
  top: ${px2vw(10)};
  cursor: pointer;
`;

export const Overlay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0005;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 999999999999999999999998;
`;

export const ProgressModalContainer = styled.div`
  display: flex;
  position: relative;
  min-height: ${px2vw(400)};
  min-width: ${px2vw(600)};
  max-width: ${px2vw(600)};
  z-index: 999999999999999999999999;
  background-color: ${branco};
  border-radius: ${px2vw(10)};
  overflow: hidden;
`;

export const LeftContainer = styled.div`
  display: flex;
  flex: 2;
  min-width: 45%;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  background: linear-gradient(
    215deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(0, 50, 25, 1) 55%,
    rgba(0, 50, 25, 1) 90%
  );
  padding: ${px2vw(7)};
  padding-top: ${px2vw(20)};
  padding-bottom: ${px2vw(20)};
`;
export const Hashtag = styled(Text)`
  color: white;
`;
export const Title = styled(Text)`
  color: white;
  text-transform: uppercase;
  text-align: center;
`;

export const Description = styled(Text)`
  color: white;
  text-align: center;
  padding: 0 ${px2vw(10)};
`;

export const Award = styled(Text)`
  color: white;
  text-align: center;
  padding: 0 ${px2vw(20)};
`;

export const StartButton = styled.button`
  background: linear-gradient(
      231.72deg,
      ${verdeKuppi16} 7.51%,
      rgba(47, 254, 95, 0) 91.54%
    ),
    ${verdeKuppi5};
  padding: ${px2vw(7)} ${px2vw(35)};
  border-radius: ${px2vw(3)};
  outline: none;
  border: 1px solid #000;
  text-transform: uppercase;
  font-weight: bold;
`;

export const RightContainer = styled.div`
  display: flex;
  flex: 3;
  align-items: center;
  flex-direction: column;
  padding: ${px2vw(10)};
  padding-top: ${px2vw(20)};
  padding-bottom: ${px2vw(20)};
`;

export const TaskTitle = styled(Text)`
  text-transform: uppercase;
  text-align: center;
`;

export const TaskSubTitle = styled(Text)`
  text-transform: uppercase;
  text-align: center;
`;
