export const SectionTitleText = 'Defina a forma de pagamento';
export const RecommendedPaymentChoiceText = 'RECOMENDADA';
export const CreditCardInfoText = `Pagando com cartão de crédito a liberação do seu acesso será
imediata, após a operadora do cartão autorizar o pagamento.
Esse cartão será utilizado de forma automática nas próximas
cobranças`;
export const CreditCardFieldLabel = 'Número do Cartão de Crédito';
export const CreditCardFieldPlaceholder = '4444333322221111';
export const ExpiryDateFieldLabel = 'Vencimento';
export const ExpiryDateFieldPlaceholder = '00/00';
export const CVVFieldLabel = 'CVV';
export const CVVFieldPlaceholder = '0000';
export const OwnerCardNameFieldLabel = 'Nome do Titular';
export const OwnerCardNameFieldPlaceholder = 'Como está gravado no cartão';
export const OwnerCardDocFieldLabel = 'CPF ou CNPJ do Titular';
export const OwnerCardDocFieldPlaceholder = '000.000.000-00 / 00.000.000/0001-00';
export const PixInfoText = `Pagando com pix a liberação do seu acesso pode demorar
até 10 minutos para ser efetuada, pois o sistema aguarda a
confirmação do crédito do pix para liberar automaticamente o acesso`;
export const PixInfoText1 = '• Os dados para visualizar novamente o pix gerado serão enviado para o email cadastrado.';
export const PixInfoText2 = '• Para pagar, escaneie o QR code pelo aplicativo do seu banco ou copie o código e use no aplicativo do seu banco.';
export const PixInfoText3 = '• O código pix gerado será válido para pagamento até hoje às 23:59:57';
export const BoletoInfoText = `Pagando com boleto a liberação do seu acesso pode demorar
até 3 dias para ser efetuada, pois o sistema aguarda a
confirmação do pagamento do boleto para liberar
automaticamente o acesso`;
export const BoletoInfoExpiryText = '• Defina quando será o vencimento do boleto:';
export const BoletoInfoExpiryOpt1Text = 'Hoje';
export const BoletoInfoExpiryOpt2Text = 'Amanhã';
export const BoletoInfoExpiryOpt3Text = 'Daqui a 02 dias';
export const BoletoInfoText1 = '• Os dados para visualizar novamente o boleto gerado serão enviados para o email cadastrado.';
export const BoletoInfoText2 = '• Imprima o boleto e pague no banco de sua preferência, ou pague pela internet utilizando o código de barras.';
export const TotalOrderAmountText = 'Valor total do pagamento';
export const GeneratePixText = 'Gerar Pix';
export const GenerateBoletoText = 'Gerar Boleto';
export const PayCreditCardText = 'Pagar';