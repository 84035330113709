import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Radio } from '@material-ui/core';
import { Progress } from 'reactstrap';

import {
  Container,
  Span,
  ProgressBar,
  BackIcon,
  ModalFooter,
  ButtonPrimary,
  PaymentMethod,
  PaymentImg,
  ChangeModal,
  ChangeModalHeader,
  ChangeValueInput,
} from './styles';
import {
  cinzaKuppi1,
  preto,
  verdeKuppi5,
  cinzaKuppi10,
  verdeKuppi2,
  branco,
  cinzaKuppi2,
} from '../../../styles/colors';
import { Row, Col } from '../../../styles/grid';

import px2vw from '../../../utils/responsiveness/px2vw';
import { moneyMask } from '../../../utils/masks';
import { formatCurrencyBRL } from '../../../utils/utils';

import {
  setChangeValue,
  setCurrStep,
  setPaymentMethod,
} from '../../../store/modules/manualOrder/actions';

export default function PaymentData(props) {
  const dispatch = useDispatch();
  const { advertiserPaymentMethod, bag } = useSelector(
    (state) => state?.manualOrder
  );
  const global = useSelector((state) => state?.manualOrder);
  const previousStep = useSelector((state) => state.manualOrder?.previousStep);
  const deliveryFee =
    global.bag?.deliveryType === 'delivery' ? global.deliveryFee : 0;
  const [offlinePaymentMethodData, setOfflinePaymentMethodData] =
    useState(
      advertiserPaymentMethod?.filter(
        (pm) => pm?.paymentMethod?.type === 'offline'
      )
    );
  const [onlinePaymentMethodData, setOnlinePaymentMethodData] = useState(
    advertiserPaymentMethod?.filter(
      (pm) => pm?.paymentMethod?.type === 'online' && pm?.paymentMethod?.slug !== 'pix_copia_cola'
    )
  );

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [changeValueInput, setChangeValueInput] = useState('');
  const [changeValueModalIsVisible, setChangeValueModalIsVisible] =
    useState(false);
  const [willNeedChange, setWillNeedChange] = useState(false);
  const isChangeValueValid =
    changeValueInput / 100 >
    (parseFloat(bag.priceOfTheWholeBag) + deliveryFee) / 100;

  const markItemAsSelected = (payment_method_id, type) => {
    const dataSource =
      type === 'online' ? onlinePaymentMethodData : offlinePaymentMethodData;
    const pm = dataSource.find((pm) => pm.id === payment_method_id);

    setSelectedPaymentMethod(pm);

    if (
      pm.paymentMethod.slug !== 'cash' &&
      (changeValueModalIsVisible || willNeedChange)
    ) {
      setChangeValueModalIsVisible(false);
      setWillNeedChange(false);
    }
  };

  function handleBackTo() {
    if (previousStep) {
      dispatch(setCurrStep(previousStep));
    } else {
      dispatch(setCurrStep('2'));
    }
  }

  function handleSelectPaymentMethod() {
    dispatch(setPaymentMethod(selectedPaymentMethod));
    dispatch(setChangeValue(0));
    dispatch(setCurrStep('5'));
  }

  function handleSetChangeValue(e) {
    dispatch(setPaymentMethod(selectedPaymentMethod));

    if (e.target.id === 'cancel') {
      dispatch(setChangeValue(0));
      dispatch(setCurrStep('5'));
    } else if (e.target.id === 'continue') {
      dispatch(setChangeValue(changeValueInput));
      dispatch(setCurrStep('5'));
    } else {
      setWillNeedChange(true);
    }
  }

  return (
    <>
      <ProgressBar>
        <Progress
          value={75}
          bar={true}
          style={{
            backgroundColor: verdeKuppi5,
            borderRaduis: '0px',
            height: '10px',
          }}
        />
      </ProgressBar>
      <Row
        justifyStart
        alignCenter
        className="py-1 px-2"
        cursor="pointer"
        background={cinzaKuppi1}
        onClick={() => handleBackTo()}
      >
        <BackIcon
          src={require('../../../assets/images/arrows/arrow_left.png')}
        />
        <Span padding={px2vw(3)} color={preto}>
          Pagamento
        </Span>
      </Row>

      <Container className="px-0 pb-2">
        {onlinePaymentMethodData?.length > 0 &&
          onlinePaymentMethodData?.map((pm, index) => (
            <>
              <Row
                justifyStart
                alignCenter
                className="py-1 px-2"
                cursor="pointer"
                background={cinzaKuppi1}
                style={{
                  borderTop: `2px solid ${cinzaKuppi2}`,
                }}
                onClick={() => handleBackTo()}
              >
                <Span padding={px2vw(3)} padLeft={px2vw(35)} color={preto}>
                  Pague online
                </Span>
              </Row>
              <PaymentMethod
                key={`${pm?.id}-${index}`}
                onClick={() => markItemAsSelected(pm?.id, 'online')}
              >
                <PaymentImg src={pm?.paymentMethod?.image_url} />
                <Span
                  fontSize={px2vw(14)}
                  fontWeight="400"
                  className="ml-3"
                  color={cinzaKuppi10}
                  cursor="pointer"
                >
                  {pm?.paymentMethod?.name}
                </Span>
                <Radio
                  className="ml-auto"
                  checked={pm?.id === selectedPaymentMethod?.id}
                  color="default"
                />
              </PaymentMethod>
            </>
          ))}

        {offlinePaymentMethodData?.length && (
          <Row
            justifyStart
            alignCenter
            className="py-1 px-2"
            cursor="pointer"
            background={cinzaKuppi1}
            style={{
              borderTop: `2px solid ${cinzaKuppi2}`,
            }}
            onClick={() => handleBackTo()}
          >
            <Span padding={px2vw(3)} padLeft={px2vw(35)} color={preto}>
              Pague na entrega
            </Span>
          </Row>
        )}

        {offlinePaymentMethodData?.length > 0 ? (
          offlinePaymentMethodData?.map((pm, index) => (
            <PaymentMethod
              key={`${pm?.id}-${index}`}
              onClick={() => {
                markItemAsSelected(pm?.id, 'offline');
                if (pm.paymentMethod.slug === 'cash') {
                  setChangeValueModalIsVisible(true);
                }
              }}
            >
              <PaymentImg src={pm?.paymentMethod?.image_url} />
              <Span
                fontSize={px2vw(14)}
                fontWeight="400"
                className="ml-3"
                color={cinzaKuppi10}
                cursor="pointer"
              >
                {pm?.paymentMethod?.name}
              </Span>
              <Radio
                className="ml-auto"
                checked={pm?.id === selectedPaymentMethod?.id}
                color="default"
              />
            </PaymentMethod>
          ))
        ) : (
          <Row>
            <Span fontWeight="300">
              Não existem formas de pagamento cadastradas
            </Span>
          </Row>
        )}
      </Container>
      {changeValueModalIsVisible ? (
        <ChangeModal>
          <ChangeModalHeader>
            <Span fontWeight="700" color={cinzaKuppi10} className="mx-2 mt-2">
              {willNeedChange ? 'Troco pra quanto?' : 'Você precisa de troco?'}
            </Span>
          </ChangeModalHeader>
          {!willNeedChange ? (
            <Row alignCenter className="mt-2">
              <ButtonPrimary
                onClick={(e) => handleSetChangeValue(e)}
                id="cancel"
                fontColor={branco}
                color={verdeKuppi2}
                width={px2vw(185)}
              >
                Não
              </ButtonPrimary>
              <ButtonPrimary
                onClick={(e) => handleSetChangeValue(e)}
                fontColor={branco}
                color={verdeKuppi2}
                width={px2vw(185)}
              >
                Sim
              </ButtonPrimary>
            </Row>
          ) : (
            <Col alignCenter className="mt-2">
              <Row alignCenter justifyCenter className="mt-2">
                <Span
                  fontWeight="400"
                  fontSize={px2vw(11.5)}
                  color={cinzaKuppi10}
                  className="mx-2"
                  display="block"
                >
                  O pedido deu{' '}
                  <b>
                    {formatCurrencyBRL(
                      parseFloat(bag.priceOfTheWholeBag) + deliveryFee,
                      2
                    )}
                  </b>
                  . Digite quanto será pago em dinheiro.
                </Span>
              </Row>
              <Row alignCenter justifyCenter className="mt-2 mb-2">
                <Span
                  fontWeight="bolder"
                  fontSize={px2vw(16)}
                  color={cinzaKuppi10}
                  className="mx-2"
                  display="block"
                >
                  R$
                </Span>
                <ChangeValueInput
                  maxLength="7"
                  placeholder="0.00"
                  onChange={(e) =>
                    setChangeValueInput(moneyMask(e.target.value))
                  }
                  value={changeValueInput}
                />
              </Row>
              <Row alignCenter className="mt-2">
                <ButtonPrimary
                  onClick={(e) => handleSetChangeValue(e)}
                  id="continue"
                  disabled={!isChangeValueValid}
                  fontColor={branco}
                  color={verdeKuppi2}
                  width={px2vw(250)}
                >
                  Continuar
                </ButtonPrimary>
              </Row>
            </Col>
          )}
        </ChangeModal>
      ) : (
        <ModalFooter>
          <ButtonPrimary
            disabled={!selectedPaymentMethod}
            onClick={() => handleSelectPaymentMethod()}
          >
            Continuar
          </ButtonPrimary>
        </ModalFooter>
      )}
    </>
  );
}
