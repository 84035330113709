import React from 'react';

import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

import AccessValidate from '../utils/validations/AccessValidate';

import { store } from '../store';

import SignedLayout from '../pages/_layouts/signed';
import DefaultLayout from '../pages/_layouts/default';
import NoLayout from '../pages/_layouts/noLayout';

import { dashboard, onboarding, firstSteps, blockedSignaturePayment, login } from './routeMap';

export default function RouteWrapper({
  isPrivate,
  option,
  permissionRole,
  permissionUser,
  onlyNewUsers,
  userRole,
  component: Component,
  ...rest
}) {
  const redux = store.getState();
  const { user } = redux.user;
  const { access } = redux.user;
  const { signed } = redux.auth;

  // If users does not have access to the feature, redirect to dashboard
  if (!AccessValidate(access, userRole, permissionRole, permissionUser, true)) {
    return <Redirect to={dashboard} />;
  }

  // If user try access kuppi boost feature, but the category not allow, redirect to dashboard
  if (signed && !user.advertiserInfo.advertiserCategory.is_kuppi_boost_available && rest.path.includes('/kuppi-boost')) {
    return <Redirect to={dashboard} />;
  }

  /*
    If not signed, and try to access private pages,
    redirect to login page
  */
  if (!signed && isPrivate) {
    return <Redirect to={login} />;
  }

  /*
    If is signed, is blocked for non payment redirect to
    blocked payment page
  */
  if (
    signed &&
    user.advertiserInfo.is_overdue_payment &&
    ((rest.path.includes('/portal') &&
      !rest.path.includes('/pagamento-assinatura-bloqueada')) ||
      rest.path.includes('/primeiros-passos') || rest.path.includes('/onboarding'))
  ) {
    return <Redirect to={blockedSignaturePayment} />;
  }

  /*
    If is signed, not finished the first steps and try
    to access other page that first steps, redirect to first steps
  */
  if (
    signed &&
    !user.advertiserInfo.is_finished_first_steps &&
    !user.advertiserInfo.is_overdue_payment &&
    (rest.path.includes('/portal') || rest.path.includes('/onboarding'))
  ) {
    return <Redirect to={firstSteps} />;
  }

  /*
    If is signed, not finished the onboarding and try
    to access other page that onboarding, redirect to onboarding
    except blocked payment page
  */
  if (
    signed &&
    !user.advertiserInfo.is_done_onboarding && user.advertiserInfo.is_finished_first_steps &&
    ((rest.path.includes('/portal') && !rest.path.includes('/pagamento-assinatura-bloqueada'))
    || rest.path.includes('/primeiros-passos')
    || (rest.path.includes('/portal/pagamento-assinatura-bloqueada') && !user.advertiserInfo.is_overdue_payment))
  ) {
    return <Redirect to={onboarding} />;
  }

  /*
    If is signed, not accepted the terms of use and try
    to access other page that dashboard, redirect to dashboard
  */
  if (
    signed &&
    !user.advertiserInfo.is_terms_accepted &&
    rest.path.includes('/portal') &&
    !rest.path.includes('/dashboard') &&
    !rest.path.includes('/baixe-nosso-app') &&
    !rest.path.includes('/portal/pagamento-assinatura-bloqueada')
  ) {
    return <Redirect to={dashboard} />;
  }

  /* Redirect to the respective route */
  const Layout = rest.noLayout
    ? NoLayout
    : signed
    ? SignedLayout
    : DefaultLayout;

  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout option={option}>
          <Component {...props} />
        </Layout>
      )}
    />
  );
}

RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  option: PropTypes.string,
  permissionRole: PropTypes.string,
  permissionUser: PropTypes.string,
  userRole: PropTypes.string,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
};

RouteWrapper.defaultProps = {
  isPrivate: false,
  option: '',
  permissionRole: null,
  permissionUser: null,
  userRole: null,
};
