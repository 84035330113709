export function rowsPerPageOptions(length) {
  if ((length < 10 && length > 7) || length === 10) {
    return [7, length];
  }
  if (length < 7 || length === 7) {
    return [length];
  }

  return [7, 10, length];
}
