import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Spinner } from 'reactstrap';

import { 
  ModalActions, 
  ModalPrimaryButton, 
  ModalPrimaryButtonText, 
  ModalSecundaryButton, 
  ModalSecundaryButtonText 
} from '../../../../../components/Modal/styles';
import { LoadingBox } from '../../../../../styles/common';
import { preto } from '../../../../../styles/colors';

import Modal from '../../../../../components/Modal';

import px2vw from '../../../../../utils/responsiveness/px2vw';
import { UpdateAvailableCouponsContent, UpdateAvailableCouponsContentText } from '../../../../../components/CuponTable/styles';
import { updateAvailableCouponsRequest } from '../../../../../store/modules/coupons/actions';
import { ItemInput } from '../../../styles';
import { onlyNumbers } from '../../../../../utils/utils';

export default function UpdateAvailableCouponsModal ({ handler }) {
  const [newAvailableCouponsInput, setNewAvailableCouponsInput] = useState('');
  const dispatch = useDispatch();

  return (
    <Modal
      isVisible
      width={px2vw(370)}
      height={px2vw(230)}
    >
      <UpdateAvailableCouponsContent>
        <UpdateAvailableCouponsContentText>
          Informe a nova quantidade de cupons disponível para uso
        </UpdateAvailableCouponsContentText>
        <ItemInput
          name="newAvailableCoupons"
          width={px2vw(100)}
          placeholder="15"
          min="1"
          max="999999999"
          type="text"
          value={newAvailableCouponsInput}
          onChange={(t) => {
            setNewAvailableCouponsInput(
              onlyNumbers(t.target.value.substring(0, 9))
            );

          }}
        />
        <ModalActions>
          <ModalSecundaryButton
            marginRight={px2vw(20)}
            margtop={px2vw(30)}
            width={px2vw(100)}
            type="button"
            onClick={() => handler.handleCloseUsageLimitModal()}
            disabled={handler.updateAvailableCouponsLoading}
          >
            <ModalSecundaryButtonText>Cancelar</ModalSecundaryButtonText>
          </ModalSecundaryButton>
          <ModalPrimaryButton
            margleft={px2vw(20)}
            margtop={px2vw(30)}
            width={px2vw(100)}
            type="button"
            onClick={() => {
              dispatch(updateAvailableCouponsRequest(handler.actionCoupon.id, handler.actionCoupon.mode, newAvailableCouponsInput))
            }}
            disabled={handler.updateAvailableCouponsLoading || newAvailableCouponsInput.trim() === '' || parseInt(newAvailableCouponsInput) === 0}
          >
            {handler.updateAvailableCouponsLoading ? (
              <LoadingBox>
                <Spinner
                  style={{
                    width: '1.8rem',
                    height: '1.8rem',
                    color: preto,
                  }}
                />{' '}
              </LoadingBox>
            ) : (
              <ModalPrimaryButtonText>Atualizar</ModalPrimaryButtonText>
            )}
          </ModalPrimaryButton>
        </ModalActions>
      </UpdateAvailableCouponsContent>
    </Modal>
  )
}
