import styled, { keyframes } from 'styled-components';
import { amareloKuppi5, branco, verdeKuppi2 } from '../../styles/colors';
import { FaBell } from 'react-icons/fa';
import px2vw from '../../utils/responsiveness/px2vw';

const blink = keyframes`
  to {
    outline: 8px solid ${amareloKuppi5}00;
  }
`;

const shake = keyframes`
  0%{transform: rotate(20deg);}
  50%{transform: rotate(-20deg);}
  100%{transform: rotate(20deg);}
`;

export const ImgContainer = styled.div`
  position: relative;
  width: ${px2vw(55)};
  height: ${px2vw(55)};
  margin-top: ${px2vw(15)};
  border-radius: 50%;
  background: ${verdeKuppi2};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${branco};
  span {
    font-size: ${px2vw(17)};
  }
  animation: ${blink} 1s infinite;
  ${(props) =>
    props.callingAttendant &&
    `
  outline: 2px solid ${amareloKuppi5};
  `}
`;

export const Span = styled.span`
  font-size: ${px2vw(11)};
  font-weight: 600;
  text-align: center;
  ${(props) => props.size && `font-size: ${props.size};`}
  ${(props) => props.textAlign && `text-align: ${props.textAlign};`}
  ${(props) => props.color && `color: ${props.color};`}
  ${(props) => props.letterSpacing && `letter-spacing: ${props.letterSpacing};`}
  ${(props) => props.lineHeight && `line-height: ${props.lineHeight};`}
  ${(props) => props.fontWeight && `font-weight: ${props.fontWeight};`}
  ${(props) => props.p && `padding: ${props.p};`}
  ${(props) => props.pb && `padding-bottom: ${props.pb};`}
  ${(props) => props.pl && `padding-left: ${props.pl};`}
  ${(props) => props.pr && `padding-right: ${props.pr};`}
  ${(props) => props.pt && `padding-top: ${props.pt};`}
  ${(props) => props.m && `margin: ${props.m};`}
  ${(props) => props.mb && `margin-bottom: ${props.mb};`}
  ${(props) => props.ml && `margin-left: ${props.ml};`}
  ${(props) => props.mr && `margin-right: ${props.mr};`}
  ${(props) => props.mt && `margin-top: ${props.mt};`}
  ${(props) => props.maxWidth && `max-width: ${props.maxWidth};`}
  ${(props) => props.overflow && `overflow: ${props.overflow};`}
  ${(props) => props.textOverflow && `text-overflow: ${props.textOverflow};`}
  ${(props) => props.whiteSpace && `white-space: ${props.whiteSpace};`}
`;

export const StyledBell = styled(FaBell)`
  position: absolute;
  left: 0;
  top: 0;
  animation: ${shake} .3s infinite;
`;
