import { all } from 'redux-saga/effects';

import auth from './auth/sagas';
import user from './user/sagas';
import firstSteps from './firstSteps/sagas';
import userAddressQuery from './userAddressQuery/sagas';
import orders from './orders/sagas';
import manualOrder from './manualOrder/sagas';
import productCategories from './productCategories/sagas';
import coupons from './coupons/sagas';
import usedCoupons from './usedCoupons/sagas';
import myStore from './myStore/sagas';
import loyaltyCard from './loyaltyCard/sagas';
import clients from './clients/sagas';
import dashboardMetrics from './dashboardMetrics/sagas';
import kuppiBoost from './kuppiBoost/sagas';
import onboarding from './onboarding/sagas';
import importIfood from './importIfood/sagas';
import signaturePayment from './signaturePayment/sagas';
import profileAccount from './profileAccount/sagas';
import reports from './reports/sagas';
import whatsappBot from './whatsappBot/sagas';
import mgm from './mgm/sagas';
import whatsappChat from './whatsappChat/sagas';
import whatsappChatConversations from './whatsappChatConversations/sagas';
import ratings from './ratings/sagas';
import salesRecover from './salesRecover/sagas';
import insightsAi from './insightsAI/sagas';
import massMessage from './massMessage/sagas';
import challenges from './challenges/sagas';
import financial from './financial/sagas';
import massHandleComplements from './massHandleComplements/sagas';

export default function* rootSaga() {
  return yield all([
    auth,
    user,
    firstSteps,
    userAddressQuery,
    orders,
    manualOrder,
    productCategories,
    coupons,
    usedCoupons,
    myStore,
    loyaltyCard,
    clients,
    dashboardMetrics,
    kuppiBoost,
    onboarding,
    importIfood,
    signaturePayment,
    profileAccount,
    reports,
    whatsappBot,
    mgm,
    whatsappChat,
    whatsappChatConversations,
    ratings,
    salesRecover,
    massMessage,
    insightsAi,
    challenges,
    massHandleComplements,
    financial,
  ]);
}
