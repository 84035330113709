import styled from 'styled-components';
import { vermelhoKuppi1, branco } from '../../styles/colors';
import px2vw from '../../utils/responsiveness/px2vw';


export const Bagde = styled.div`
  border-radius: ${px2vw(999)};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  padding: 0px ${px2vw(8)};
  min-width: ${(props) => props.w || px2vw(20)};
  max-width: ${(props) => props.w || px2vw(23)};
  height: ${(props) => props.h || px2vw(20)};
  color: ${(props) => props.color || branco};
  font-size: ${(props) => props.fontSize || px2vw(12)};
  background: ${(props) => props.bg || vermelhoKuppi1};
  margin-right: ${px2vw(5)};
`;
