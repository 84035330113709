import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Spinner } from 'reactstrap';
import { Icon } from 'react-materialize';
import { Form } from '@rocketseat/unform';
import { parse, format, add } from 'date-fns';

import Modal from '../../../components/Modal';
import ButtonOptionsModal from '../../../components/ButtonOptionsModal';
import SubscriptionsTable from '../../../components/SubscriptionsTable';
import CreditCardsTable from '../../../components/CreditCardsTable';

import px2vw from '../../../utils/responsiveness/px2vw';
import { onlyNumbers, formatCurrencyBRL } from '../../../utils/utils';
import { cpfCnpjMask, cardValidityMask } from '../../../utils/masks';
import CpfCnpjValidator from '../../../utils/validations/CpfCnpjValidator';
import { isCardDateValid } from '../../../utils/validations/validations';

import { ContentPage, ErrorText } from '../../_layouts/styles/common';

import {
  CloseButton,
  CloseImage,
  ModalPrimaryButton,
  ModalPrimaryButtonText,
  ModalActions,
  ModalSecundaryButton,
  ModalSecundaryButtonText,
  ModalHeader,
  ModalTitleText,
} from '../../../components/Modal/styles';

import { Row, Col } from '../../../styles/grid';

import { preto, cinzaKuppi1 } from '../../../styles/colors';

import {
  DataTitleText,
  DataText,
  EditButtonBox,
  ModalInputLabelText,
  ModalInput,
  SignaturePlanDataBox,
  SignaturePlanHistoryDataBox,
  CancelPlanModal,
  CancelPlanModalText,
  CancelPlanModalText2,
  CancelPlanModalTitle,
  ManageCardModal,
  NewCardButton,
  NewCardButtonText,
  NewCardInfoText,
  CheckboxDefaultCard,
  CheckboxDefaultCardChecked,
  LoadingBox,
} from './styles';

import {
  getSubscriptionsRequest,
  getRecurringRequest,
  addNewCardRequest,
} from '../../../store/modules/profileAccount/actions';

export default function Signature() {
  const advertiserSubscriptions = useSelector(
    (state) => state.profileAccount.subscriptions
  );
  const paymentCards = useSelector((state) => state.user.user.paymentCard);
  const recurring = useSelector((state) => state.profileAccount.recurring);
  const [isInFreeMonth, setIsInFreeMonth] = useState(false);
  const [
    finishPlanAfterFreeMonthData,
    setFinishPlanAfterFreeMonthData,
  ] = useState(null);
  const [startCurrPlanData, setStartCurrPlanData] = useState(null);
  const [cancelPlan, setCancelPlan] = useState(false);
  const [manageCreditCardsModal, setManageCreditCardsModal] = useState(false);
  const [addNewCardModal, setAddNewCardModal] = useState(false);
  const [newCardNumberInputValue, setNewCardNumberInputValue] = useState('');
  const [newCardCnpjCpfInputValue, setNewCardCnpjCpfInputValue] = useState('');
  const [newCardValidityInputValue, setNewCardValidityInputValue] = useState(
    ''
  );
  const [newCardCVVInputValue, setNewCardCVVInputValue] = useState('');
  const [newCardDefaultChecked, setNewCardDefaultChecked] = useState(false);
  const [orderedSubscriptions, setOrderedSubscriptions] = useState(
    advertiserSubscriptions
  );
  const subscriptionsLoading = useSelector(
    (state) => state.profileAccount.subscriptionsLoading
  );
  const recurringLoading = useSelector(
    (state) => state.profileAccount.recurringLoading
  );
  const addNewCardModalLoading = useSelector(
    (state) => state.profileAccount.addNewCardModalLoading
  );
  const addNewCardModalSuccess = useSelector(
    (state) => state.profileAccount.addNewCardModalSuccess
  );
  const errorSubscription = useSelector(
    (state) => state.profileAccount.errorSubscription
  );
  const errorRecurring = useSelector(
    (state) => state.profileAccount.errorRecurring
  );
  const dispatch = useDispatch();

  useEffect(() => {
    // Get the advertiser subscriptions
    dispatch(getSubscriptionsRequest());
    // Get the recurring
    dispatch(getRecurringRequest());
  }, [dispatch]);

  useEffect(() => {
    if (advertiserSubscriptions.length > 0) {
      setIsInFreeMonth(
        advertiserSubscriptions[0].payment_type === 'free' &&
          advertiserSubscriptions.length === 1
      );

      // Sort the advertiser subscriptions in order to show at history
      const advSubsOrdered = [...advertiserSubscriptions];
      advSubsOrdered.sort(function sort(a, b) {
        return b.id - a.id;
      });
      setOrderedSubscriptions(advSubsOrdered);
    }
  }, [advertiserSubscriptions]);

  useEffect(() => {
    if (recurring) {
      setStartCurrPlanData(
        format(
          add(parse(recurring.next_recurring_at, 'dd/MM/yyyy', new Date()), {
            months: recurring.subscription.period * -1,
          }),
          'dd/MM/yyyy'
        )
      );

      setFinishPlanAfterFreeMonthData(
        format(
          add(parse(recurring.next_recurring_at, 'dd/MM/yyyy', new Date()), {
            months: recurring.subscription.period,
          }),
          'dd/MM/yyyy'
        )
      );
    }
  }, [recurring]);

  useEffect(() => {
    if (addNewCardModalSuccess) {
      setAddNewCardModal(false);
      setNewCardNumberInputValue('');
      setNewCardCnpjCpfInputValue('');
      setNewCardValidityInputValue('');
      setNewCardCVVInputValue('');
      setNewCardDefaultChecked(false);
    }
  }, [addNewCardModalSuccess]);

  function getActionButtons() {
    const buttons = [];
    if (paymentCards) {
      buttons.push('Gerenciar cartões');
    }
    buttons.push('Cancelar assinatura');

    return buttons;
  }

  function getActionButtonsClicks() {
    const buttonsClicks = [];
    if (paymentCards) {
      buttonsClicks.push(() => setManageCreditCardsModal(true));
    }
    buttonsClicks.push(() => setCancelPlan(true));

    return buttonsClicks;
  }

  const handleNewCardSubmit = ({
    newCardNumber,
    newCardValidity,
    newCardCVV,
    newCardName,
    newCardCpfCnpj,
  }) => {
    if (
      newCardNumber === '' ||
      newCardValidity === '' ||
      newCardCVV === '' ||
      newCardName === '' ||
      newCardCpfCnpj === ''
    ) {
      toast.error('Todos os campos são obrigatórios!', {
        autoClose: 6000,
      });
    } else if (newCardNumber.length < 14 || newCardNumber.length > 16) {
      toast.error('Cartão inválido!', {
        autoClose: 6000,
      });
    } else if (newCardCVV.length < 3 || newCardCVV.length > 4) {
      toast.error(
        'Código de segurança inválido. O código deve ter 3 ou 4 dígitos',
        {
          autoClose: 6000,
        }
      );
    } else if (!CpfCnpjValidator(newCardCpfCnpj)) {
      toast.error(
        'CPF/CNPJ inválido. CPF deve ter 11 dígitos e CNPJ 14 dígitos',
        {
          autoClose: 6000,
        }
      );
    } else if (newCardValidity.length < 5 || newCardValidity.length > 5) {
      toast.error(
        'Data de validade do cartão inválida. O formato esperado é: MM/AA',
        {
          autoClose: 6000,
        }
      );
    } else if (!isCardDateValid(newCardValidity)) {
      toast.error(
        'Cartão expirado, verifique se a data informada está correta e no formato: MM/AA.',
        {
          autoClose: 6000,
        }
      );
    } else {
      dispatch(
        addNewCardRequest(
          newCardNumber,
          onlyNumbers(newCardValidity),
          newCardCVV,
          newCardName,
          onlyNumbers(newCardCpfCnpj),
          newCardDefaultChecked
        )
      );
    }
  };

  const contentPage = (
    <>
      <Col>
        {/* Current plan data */}
        <Row margtop={px2vw(5)} height="auto" justifyStart>
          <DataTitleText>Dados do plano atual</DataTitleText>
        </Row>
        <SignaturePlanDataBox>
          {recurringLoading && (
            <Col height="20vh" margtop={px2vw(40)}>
              <Col alignCenter justifyCenter>
                <Spinner
                  style={{
                    width: '1.8rem',
                    height: '1.8rem',
                    color: preto,
                  }}
                />
              </Col>
            </Col>
          )}
          {!recurringLoading && recurring && (
            <Col>
              <Row>
                <Col>
                  <DataTitleText>Plano</DataTitleText>
                  <DataText>{recurring.subscription.name}</DataText>
                </Col>
                <Col>
                  <DataTitleText>Início</DataTitleText>
                  <DataText>
                    {!isInFreeMonth &&
                      orderedSubscriptions[0] &&
                      orderedSubscriptions[0].start_validity_at}
                    {isInFreeMonth && recurring.next_recurring_at}
                  </DataText>
                </Col>
                <Col>
                  <DataTitleText>Valido até</DataTitleText>
                  <DataText>
                    {!isInFreeMonth &&
                      orderedSubscriptions[0] &&
                      orderedSubscriptions[0].finish_validity_at}
                    {isInFreeMonth && finishPlanAfterFreeMonthData}
                  </DataText>
                </Col>
                <EditButtonBox margtop={px2vw(40)}>
                  <ButtonOptionsModal
                    width={px2vw(120)}
                    buttons={getActionButtons()}
                    buttonsClicks={getActionButtonsClicks()}
                  />
                </EditButtonBox>
              </Row>
              <div
                style={{
                  backgroundColor: cinzaKuppi1,
                  height: `${px2vw(4)}`,
                  width: '100%',
                }}
              />
              <Row>
                <Col width="87%">
                  <DataTitleText>Último pagamento</DataTitleText>
                  <DataText>
                    {!isInFreeMonth && recurring.last_payment_at}
                    {isInFreeMonth && '-'}
                  </DataText>
                </Col>
                <Col width="87%">
                  <DataTitleText>Valor</DataTitleText>
                  <DataText>
                    {`${formatCurrencyBRL(
                      recurring.amount.toFixed(2),
                      {}
                    )}/mês`}
                  </DataText>
                </Col>
                <Col width="97%">
                  <DataTitleText>Forma de pagamento</DataTitleText>
                  <DataText>
                    {recurring.payment_type === 'pix' && 'PIX'}
                    {recurring.payment_type === 'boleto' && 'Boleto'}
                    {recurring.payment_type === 'credit_card' &&
                      `Cartão de Crédito ${
                        recurring.paymentCard &&
                        recurring.paymentCard.brand.toUpperCase()
                      } ****${
                        recurring.paymentCard &&
                        recurring.paymentCard.last_digits
                      }`}
                    {!recurring.payment_type && '-'}
                  </DataText>
                </Col>
              </Row>
            </Col>
          )}
          {!recurring && (
            <Col height="20vh" margtop={px2vw(40)}>
              <Row alignCenter justifyCenter>
                {/* TODO: Ajustar tamanho do icone */}
                <Icon large>sentiment_very_dissatisfied</Icon>
                <ErrorText fontSize={px2vw(16)}>
                  Erro ao carregar os dados do plano atual.
                </ErrorText>
              </Row>
            </Col>
          )}
        </SignaturePlanDataBox>
        <div style={{ height: `${px2vw(25)}` }} />
        {/* Subscriptions history data */}
        <DataTitleText>Histórico de pagamento</DataTitleText>
        <SignaturePlanHistoryDataBox>
          {subscriptionsLoading && (
            <Col height="20vh" margtop={px2vw(40)}>
              <Col alignCenter justifyCenter>
                <Spinner
                  style={{
                    width: '1.8rem',
                    height: '1.8rem',
                    color: preto,
                  }}
                />
              </Col>
            </Col>
          )}
          {!subscriptionsLoading && (
            <SubscriptionsTable data={orderedSubscriptions} />
          )}
        </SignaturePlanHistoryDataBox>
      </Col>
      {/* Cancel Signature Plan - Talk to support */}
      {cancelPlan && (
        <Modal isVisible={cancelPlan} width={px2vw(400)} height={px2vw(290)}>
          <CancelPlanModal>
            <CancelPlanModalTitle>Cancelar Assinatura</CancelPlanModalTitle>
            <CancelPlanModalText2>
              Para cancelar a sua assinatura, entre em contato conosco através
              do email:
            </CancelPlanModalText2>
            <CancelPlanModalText>suporte@kuppi.com.br</CancelPlanModalText>
            <ModalActions>
              <ModalPrimaryButton
                margleft={px2vw(10)}
                margtop={px2vw(30)}
                width={px2vw(140)}
                onClick={() => {
                  setCancelPlan(false);
                }}
              >
                <ModalPrimaryButtonText>Fechar</ModalPrimaryButtonText>
              </ModalPrimaryButton>
            </ModalActions>
          </CancelPlanModal>
        </Modal>
      )}
      {/* Manage credit cards */}
      {manageCreditCardsModal && (
        <Modal
          isVisible={manageCreditCardsModal}
          width={px2vw(900)}
          height={px2vw(500)}
        >
          <ManageCardModal>
            <ModalHeader>
              <ModalTitleText>Gerenciar cartões de crédito</ModalTitleText>
              <CloseButton
                onClick={() => {
                  setManageCreditCardsModal(false);
                }}
              >
                <CloseImage
                  src={require('../../../assets/images/close/close.png')}
                />
              </CloseButton>
            </ModalHeader>
            <Row margtop={px2vw(20)} justifyStart>
              <NewCardButton onClick={() => setAddNewCardModal(true)}>
                <NewCardButtonText>Adicionar novo cartão</NewCardButtonText>
              </NewCardButton>
            </Row>
            <CreditCardsTable />
          </ManageCardModal>
        </Modal>
      )}
      {/* Add new card */}
      {addNewCardModal && (
        <Modal
          isVisible={addNewCardModal}
          width={px2vw(500)}
          height={px2vw(400)}
        >
          <ManageCardModal>
            <ModalHeader>
              <ModalTitleText>Adicionar novo cartão de crédito</ModalTitleText>
              <CloseButton
                disabled={addNewCardModalLoading}
                onClick={() => {
                  setAddNewCardModal(false);
                  setNewCardCnpjCpfInputValue('');
                  setNewCardValidityInputValue('');
                  setNewCardCVVInputValue('');
                  setNewCardDefaultChecked(false);
                }}
              >
                <CloseImage
                  src={require('../../../assets/images/close/close.png')}
                />
              </CloseButton>
            </ModalHeader>
            <Row margtop={px2vw(20)} justifyStart>
              <NewCardInfoText>
                Ao efetuar o cadastro de um novo cartão de crédito, será feita
                uma transação no valor de R$1,23 para garantir que os dados
                estão corretos e que esse cartão poderá ser utilizado
                posteriormente. Note que esse valor será estornado
                automaticamente após a validação dos dados.
              </NewCardInfoText>
            </Row>
            <Form onSubmit={handleNewCardSubmit}>
              <Row margtop={px2vw(25)}>
                <Col margright={px2vw(13)}>
                  <ModalInputLabelText>
                    Número do cartão de crédito
                  </ModalInputLabelText>
                  <ModalInput
                    name="newCardNumber"
                    width={px2vw(253)}
                    placeholder="4444333322221111"
                    maxLength="16"
                    value={newCardNumberInputValue}
                    onChange={(t) => {
                      setNewCardNumberInputValue(onlyNumbers(t.target.value));
                    }}
                  />
                </Col>
                <Col margright={px2vw(13)}>
                  <ModalInputLabelText>Vencimento</ModalInputLabelText>
                  <ModalInput
                    name="newCardValidity"
                    width={px2vw(80)}
                    placeholder="00/00"
                    maxLength="5"
                    value={newCardValidityInputValue}
                    onChange={(t) =>
                      setNewCardValidityInputValue(
                        cardValidityMask(t.target.value)
                      )
                    }
                  />
                </Col>
                <Col>
                  <ModalInputLabelText>CVV</ModalInputLabelText>
                  <ModalInput
                    name="newCardCVV"
                    width={px2vw(80)}
                    placeholder="1234"
                    maxLength="4"
                    value={newCardCVVInputValue}
                    onChange={(t) =>
                      setNewCardCVVInputValue(onlyNumbers(t.target.value))
                    }
                  />
                </Col>
              </Row>
              <Row margtop={px2vw(20)}>
                <Col margright={px2vw(13)}>
                  <ModalInputLabelText>Nome do titular</ModalInputLabelText>
                  <ModalInput
                    name="newCardName"
                    width={px2vw(212)}
                    maxLength="100"
                    placeholder="Como está gravado no cartão"
                  />
                </Col>
                <Col>
                  <ModalInputLabelText>
                    CPF ou CNPJ do titular
                  </ModalInputLabelText>
                  <ModalInput
                    name="newCardCpfCnpj"
                    width={px2vw(212)}
                    placeholder="000.000.000-00 / 00.000.000/0001-00"
                    maxLength="18"
                    value={newCardCnpjCpfInputValue}
                    onChange={(t) =>
                      setNewCardCnpjCpfInputValue(cpfCnpjMask(t.target.value))
                    }
                  />
                </Col>
              </Row>
              <Row
                margtop={px2vw(20)}
                alignCenter
                justifyStart
                style={{ cursor: 'pointer' }}
                width="auto"
                type="button"
                onClick={() => {
                  setNewCardDefaultChecked(!newCardDefaultChecked);
                }}
              >
                <CheckboxDefaultCard>
                  <CheckboxDefaultCardChecked checked={newCardDefaultChecked} />
                </CheckboxDefaultCard>
                <ModalInputLabelText margleft={px2vw(15)}>
                  Definir como cartão de crédito padrão
                </ModalInputLabelText>
              </Row>
              <ModalActions>
                <ModalSecundaryButton
                  marginRight={px2vw(20)}
                  margtop={px2vw(30)}
                  width={px2vw(200)}
                  type="button"
                  disabled={addNewCardModalLoading}
                  onClick={() => {
                    setAddNewCardModal(false);
                    setNewCardCnpjCpfInputValue('');
                    setNewCardValidityInputValue('');
                    setNewCardCVVInputValue('');
                    setNewCardDefaultChecked(false);
                  }}
                >
                  <ModalSecundaryButtonText>Cancelar</ModalSecundaryButtonText>
                </ModalSecundaryButton>
                <ModalPrimaryButton
                  margleft={px2vw(20)}
                  margtop={px2vw(30)}
                  width={px2vw(200)}
                  type="submit"
                  disabled={addNewCardModalLoading}
                >
                  {addNewCardModalLoading ? (
                    <LoadingBox>
                      <Spinner
                        style={{
                          width: '1.8rem',
                          height: '1.8rem',
                          color: preto,
                        }}
                      />
                    </LoadingBox>
                  ) : (
                    <ModalPrimaryButtonText>Adicionar</ModalPrimaryButtonText>
                  )}
                </ModalPrimaryButton>
              </ModalActions>
            </Form>
          </ManageCardModal>
        </Modal>
      )}
    </>
  );
  
  const errorPage = (
    <ContentPage>
      <Col alignCenter height="80vh">
        <Row alignCenter justifyCenter>
          {/* TODO: Ajustar tamanho do icone */}
          <Icon large>sentiment_very_dissatisfied</Icon>
          <ErrorText>
            Ocorreu um erro ao carregar os dados da assinatura. Tente novamente.
          </ErrorText>
        </Row>
      </Col>
    </ContentPage>
  );

  if (errorSubscription && errorRecurring) {
    return errorPage;
  }
  return contentPage;
}
