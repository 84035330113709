import styled, { css } from 'styled-components';
import px2vw from '../../utils/responsiveness/px2vw';
import { branco, verdeKuppi1, verdeKuppi2, verdeKuppi4 } from '../../styles/colors';

export const StyledButtonPrimary = styled.button`
  background-color: ${(props) => props.background || verdeKuppi1};
  border: 1px solid ${(props) => props.borderColor || verdeKuppi1};
  color: ${(props) => props.color || branco};
  border-radius: ${(props) => px2vw(props.borderRadius || 10)};
  height: ${(props) => px2vw(props.height || 40)};
  min-width: ${(props) => px2vw(props.minWidth || 50)};
  line-height: ${(props) => px2vw(props.height || 40)};
  font-size: ${(props) => px2vw(props.fontSize || 14)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.36px;
  text-align: center;
  padding: 0 ${px2vw(20)};
  cursor: pointer;

  ${(props) =>
    props.minWidth &&
    css`
      min-width: ${props.minWidth};
    `}

  ${(props) =>
    props.margright &&
    css`
      margin-right: ${props.margright};
    `}

  :hover {
    background-color: ${verdeKuppi4};
    border: 1px solid ${verdeKuppi1};
  }

  &:focus,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
    color: ${branco};
  }

  ${(props) => props.onboardingMobile && `
    min-width: ${px2vw(1000)};
    background: ${verdeKuppi2};
    color ${branco};
    margin-top: ${px2vw(40)};
    margin-bottom: ${px2vw(50)};
    border-radius: ${px2vw(20)};
    height: ${px2vw(120)};
  `}
`;
