import React, { useEffect, useState } from 'react';

import { Drawer } from './styles';

export default function CustomDrawer(props) {
  const [drawerWidth, setDrawerWidth] = useState('85%')
  const [redefiningWidth, setRedefiningWidth] = useState(false)

  function redefineDrawerWidth(currXPosition) {
    let curr = `${(currXPosition) / 4}vw`
    setDrawerWidth(curr)
  }

  useEffect(() => {
    try {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    } catch {}
  }, [])

  useEffect(() => {
    const baseWidth = 40.0
    const maxWidth = 86.0
    const comparedValue = parseFloat(drawerWidth.replace('vw', ''))

    if (comparedValue > maxWidth || comparedValue > baseWidth) {
      setRedefiningWidth(true)
      setDrawerWidth(`${maxWidth}%`)
    } else if (comparedValue < baseWidth) {
      props.handler.setSideMenuState(false)
    }
  }, [redefiningWidth])

  return (
    <Drawer
      id="drawer"
      isOpen={props.handler.sideMenuState}
      onTouchMove={(e) => redefineDrawerWidth(e.touches[0].pageX)}
      onTouchEnd={() => setRedefiningWidth(!redefiningWidth)}
      width={drawerWidth}>

      {props.children}

    </Drawer>
  )
}