import styled from 'styled-components';

import { branco, verdeKuppi1 } from '../../../styles/colors';

import px2vw from '../../../utils/responsiveness/px2vw';

export const GeneralText = styled.div`
  ${(props) => `
    color: ${props.color};
    width: ${props.width};
    height: ${props.height};
    font-size: ${props.fontsize};
    text-align: ${props.textalign || "center"};
    margin: ${props.marg};
    margin-left: ${props.margleft};
    margin-right: ${props.margright};
    margin-top: ${props.margtop};
    margin-bottom: ${props.margbottom};
    padding-left: ${props.padleft};
    padding-right: ${props.padright};
    padding-bottom: ${props.padbottom};
    padding-top: ${props.padtop};
    padding: ${props.pad};
    font-weight: ${props.fontweight || 'normal'};
    text-decoration: ${props.textdecoration};
    cursor: ${props.cursor};
    background: ${props.background};
    border-radius: ${props.borderradius};
    border-left: ${props.borderLeft};
    border-right: ${props.borderRight};
  `}  
`;

export const LoginImage = styled.img`
  width: ${(props) => props.width || '100%' };
  height: ${(props) => props.height || '100%' };
  object-fit: fit;
  
  ${(props) => props.mobile && `
    object-fit: fit;
    width: ${px2vw(1400)};
    height: ${px2vw(1400)};
    background: ${props.background};
  `}
`;

export const LogoImage = styled.img`
  width: ${px2vw(760)};
  height: ${px2vw(450)};
  margin-bottom: ${px2vw(-100)};
`;

export const LogoImageBox = styled.div`
  flex-direction: row;
  padding-top: ${px2vw(50)};
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 100%;
`;

export const LoginBox = styled.div`
  display: flex;
  flex-direction: row;
  width: 80%;
`;

export const LoginButton = styled.button`
  background-color: ${verdeKuppi1};
  width: 100%;
  height: ${px2vw(150)};
  border: 1px solid ${verdeKuppi1};
  border-radius: 5px;
  padding: 0.3em;
  margin-top: ${px2vw(80)};

  :focus {
    background-color: ${verdeKuppi1} !important;
  }
`;

export const LoginButtonText = styled.div`
  font-size: 16px;
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.36px;
  color: ${branco};
`;