import produce from 'immer';

const INITIAL_STATE = {
  firstStepsSuccess: false,
  firstStepsLoading: false,
  firstStepsSurveyData: {},
  currSurveyPageStep: 'initial',
  updateSurveyObjectiveLoading: false, 
  updateSurveyObjectiveSuccess: false,
  updateSurveyFindOutAboutLoading: false, 
  updateSurveyFindOutAboutSuccess: false,
  updateSurveyStoreDataLoading: false,
  updateSurveyStoreDataSuccess: false,
};

export default function firstSteps(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@firstSteps/CLEAR_SUCCESS': {
        draft.firstStepsSuccess = false;
        break;
      }
      case '@firstSteps/PROCESS_FINISH_FIRST_STEPS_REQUEST': {
        draft.firstStepsLoading = true;
        break;
      }
      case '@firstSteps/PROCESS_FINISH_FIRST_STEPS_SUCCESS': {
        draft.firstStepsSuccess = true;
        draft.firstStepsLoading = false;
        break;
      }
      case '@firstSteps/PROCESS_FINISH_FIRST_STEPS_FAILURE': {
        draft.firstStepsSuccess = false;
        draft.firstStepsLoading = false;
        break;
      }
      case '@user/UPDATE_USER_INFO_REQUEST': {
        draft.firstStepsLoading = true;
        break;
      }
      case '@user/UPDATE_USER_INFO_SUCCESS': {
        draft.firstStepsSuccess = true;
        draft.firstStepsLoading = false;
        break;
      }
      case '@user/UPDATE_USER_INFO_FAILURE': {
        draft.firstStepsSuccess = false;
        draft.firstStepsLoading = false;
        break;
      }
      case '@user/UPDATE_USER_ADDRESS_REQUEST': {
        draft.firstStepsLoading = true;
        break;
      }
      case '@user/UPDATE_USER_ADDRESS_SUCCESS': {
        draft.firstStepsSuccess = true;
        draft.firstStepsLoading = false;
        break;
      }
      case '@user/UPDATE_USER_ADDRESS_FAILURE': {
        draft.firstStepsSuccess = false;
        draft.firstStepsLoading = false;
        break;
      }
      case '@firstSteps/UPDATE_SURVEY_OBJECTIVE_REQUEST': {
        draft.updateSurveyObjectiveLoading = true;
        draft.updateSurveyObjectiveSuccess = false;
        break;
      }
      case '@firstSteps/UPDATE_SURVEY_OBJECTIVE_SUCCESS': {
        draft.firstStepsSurveyData = action.payload.firstStepsSurveyData;
        draft.updateSurveyObjectiveLoading = false;
        draft.updateSurveyObjectiveSuccess = true;
        draft.currSurveyPageStep = 'step2';
        break;
      }
      case '@firstSteps/UPDATE_SURVEY_OBJECTIVE_FAILURE': {
        draft.updateSurveyObjectiveLoading = false;
        draft.updateSurveyObjectiveSuccess = false;
        break;
      }
      case '@firstSteps/UPDATE_SURVEY_FIND_OUT_ABOUT_REQUEST': {
        draft.updateSurveyFindOutAboutLoading = true;
        draft.updateSurveyFindOutAboutSuccess = false;
        break;
      }
      case '@firstSteps/UPDATE_SURVEY_FIND_OUT_ABOUT_SUCCESS': {
        draft.firstStepsSurveyData = action.payload.firstStepsSurveyData;
        draft.updateSurveyFindOutAboutLoading = false;
        draft.updateSurveyFindOutAboutSuccess = true;
        draft.currSurveyPageStep = 'step3';
        break;
      }
      case '@firstSteps/UPDATE_SURVEY_FIND_OUT_ABOUT_FAILURE': {
        draft.updateSurveyFindOutAboutLoading = false;
        draft.updateSurveyFindOutAboutSuccess = false;
        break;
      }
      case '@firstSteps/UPDATE_SURVEY_STORE_DATA_REQUEST': {
        draft.updateSurveyStoreDataLoading = true;
        draft.updateSurveyStoreDataSuccess = false;
        break;
      }
      case '@firstSteps/UPDATE_SURVEY_STORE_DATA_SUCCESS': {
        draft.firstStepsSurveyData = action.payload.firstStepsSurveyData;
        draft.updateSurveyStoreDataLoading = false;
        draft.updateSurveyStoreDataSuccess = true;
        draft.currSurveyPageStep = 'finished';
        break;
      }
      case '@firstSteps/UPDATE_SURVEY_STORE_DATA_FAILURE': {
        draft.updateSurveyStoreDataLoading = false;
        draft.updateSurveyStoreDataSuccess = false;
        break;
      }
      case '@auth/LOGIN_SUCCESS': {
        draft.firstStepsSurveyData = action.payload.user.advertiserInfo.advertiserOnboardingSurvey;
        break;
      }
      case '@firstSteps/SET_CURR_SURVEY_PAGE_STEP': {
        draft.currSurveyPageStep = action.payload.currSurveyPageStep;
        break;
      }
      default:
    }
  });
}
