import styled, { css } from 'styled-components';

import {
  preto,
  branco,
  cinzaKuppi4,
  cinzaKuppi7,
  verdeKuppi2,
  cinzaKuppi11,
  cinzaKuppi2,
  verdeKuppi1,
  verdeKuppi10,
} from '../../../styles/colors';

import px2vw from '../../../utils/responsiveness/px2vw';

export const Container = styled.section`
  background: ${branco};
  max-height: inherit;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 0.3em;
    border-radius: 3px;
  }
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${verdeKuppi2};
    outline: 1px solid ${verdeKuppi2};
  }
`;

export const ModalFooter = styled.footer`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${px2vw(26)};
  border: 1px solid ${cinzaKuppi4};
  box-shadow: -0.1px -3px 3px ${cinzaKuppi4};
  margin-top: auto;
  min-height: ${px2vw(100)};
  width: 100%;
  z-index: 5;
`;

export const Span = styled.span`
  cursor: ${(props) => (props.cursor ? props.cursor : 'initial')};
  padding: ${(props) => (props.padding ? props.padding : '0')};
  font-size: ${(props) => (props.fontSize ? props.fontSize : px2vw(14))};
  font-family: AvenirNextLTPro;
  letter-spacing: 0.36px;
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 'bold')};
  display: ${(props) => (props.display ? props.display : 'block')};
  color: ${(props) => (props.color ? props.color : verdeKuppi2)};
  text-align: ${(props) => (props.alignCenter ? 'center' : 'initial')};
  @media (max-width: 500px) {
    font-size: ${px2vw(20)};
  }
  .badge {
    background: ${cinzaKuppi11};
    font-size: ${px2vw(13)};
  }
`;

export const ModalHeader = styled.header`
  display: flex;
  justify-content: space-between;
  background-color: ${verdeKuppi2};
  align-items: center;
  width: 100%;
  letter-spacing: 0.36px;
`;

export const ManualOrderHeader = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${verdeKuppi2};
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
  border-color: ${verdeKuppi2};
  padding: ${px2vw(13)} ${px2vw(20)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  color: ${branco};
  font-size: ${px2vw(15.5)};
  display: flex;
  align-items: space-between;
  justify-content: space-between;
`;

export const ProgressBar = styled.div`
  background-color: ${cinzaKuppi11};
  width: 100%;
`;

export const CloseButton = styled.button`
  width: ${px2vw(18)};
  height: ${px2vw(18)};
  border: none;
  z-index: 10;
  background-color: transparent;

  :focus {
    background-color: transparent !important;
  }

  ${(props) =>
    props.absolute &&
    css`
      position: absolute;
      top: ${props.top || px2vw(10)};
      right: ${props.right || px2vw(10)};
    `}
`;

export const CloseImage = styled.img`
  width: ${px2vw(17)};
  height: ${px2vw(17)};
`;

export const PaymentImg = styled.img`
  height: ${px2vw(22)};
`;

export const BackIcon = styled.img`
  margin-left: ${px2vw(13)};
  background-color: ${cinzaKuppi4};
  border-radius: 2px;
  padding: ${px2vw(7)};
  cursor: pointer;
  :active {
    background-color: ${cinzaKuppi7};
  }
`;

export const StepTittle = styled.div`
  font-family: AvenirNextLTPro;
  font-weight: 700;
  color: ${preto};
  font-size: ${px2vw(17)};
  margin-right: ${px2vw(15.5)};
  white-space: nowrap;
  letter-spacing: 0.36px;
`;

export const ScheduleDay = styled.div`
  display: flex;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  margin: 0 ${px2vw(3)};
  padding: ${px2vw(6)} ${px2vw(17)};
  border: ${(props) =>
    props.selected
      ? `2px solid  ${verdeKuppi10}`
      : `2px solid  ${cinzaKuppi7}`};
  box-shadow: ${(props) =>
    props.selected ? `0px 0px 12px 1px ${verdeKuppi10}40` : 'none'};
  border-radius: 6px;
`;

export const ScheduleItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  align-items: center;
  padding: ${px2vw(3)} ${px2vw(3)};
  border-top: 2px solid ${cinzaKuppi4};
  &:last-child {
    border-bottom: 2px solid ${cinzaKuppi4};
  }
`;

export const ButtonPrimary = styled.button`
background: ${verdeKuppi2};
border: none;
display: flex;
justify-content: center;
align-items: center;
margin: 0 ${px2vw(8)};
color: ${branco};
border-radius: ${px2vw(5)};
font-size: ${px2vw(13)};
font-family: AvenirNextLTPro;
font-weight: 700;
letter-spacing: 0.32px;
padding: ${px2vw(8)};
width: ${px2vw(320)};
box-shadow: 2px 2px 3px ${cinzaKuppi2};
&:hover {
  box-shadow: 2px 2px 3px ${cinzaKuppi2};
}

&:active {
  text-decoration: none;
  background: ${verdeKuppi1};
  box-shadow: none;
  box-shadow: 2px 2px 3px ${cinzaKuppi2};
}

&:disabled {
  text-decoration: none;
  background: ${cinzaKuppi2};
  box-shadow: none;
  color: ${branco};
}
`;

export const SideSlider = styled.div`
display: flex;
-webkit-overflow-scrolling: touch;
align-items: center;
padding: ${px2vw(10)} 0;
overflow-y: scroll;
::-webkit-scrollbar {
  height: 0.3em

}
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${verdeKuppi2};
    outline: 1px solid ${verdeKuppi2};
    height: 0.3em
  }
 `;
