import React, { useState, useEffect } from 'react';
import { Spinner } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '../Modal';
import { preto } from '../../styles/colors';
import ButtonPrimary from '../ButtonPrimary';
import { LoadingBox } from '../../styles/common';

import {
  TermsTitle,
  SpinnerContainer,
  TermsAndPoliciesContentBox,
  TermsParagraph,
  TermsContent,
  TermsAndPoliciesHeader,
  TermsAndPoliciesFooter,
} from './styles';
import px2vw from '../../utils/responsiveness/px2vw';
import {
  acceptTermsAndPolicyRequest,
  getTermsAndPolicyRequest,
} from '../../store/modules/user/actions';

function TermsAndPoliciesModal(props) {
  const [termsContent, setTermsContent] = useState('');
  const user = useSelector((state) => state.user);
  const { loadingGetTermsAndPolicy, successGetTermsAndPolicy, loadingAcceptTermsOfUse } = user;
  const dispatch = useDispatch();

  useEffect(() => {
      dispatch(getTermsAndPolicyRequest('TUA'));
  }, [dispatch]);

  useEffect(() => {
    if (successGetTermsAndPolicy) {
      setTermsContent(user.terms.term_policy_content);
    }
  }, [successGetTermsAndPolicy]);

  const handleOnConfirm = () => {
    if (user.terms) {
      dispatch(acceptTermsAndPolicyRequest(user.terms.id));
    }
  };

  const loadingContent = (
    <SpinnerContainer>
      <Spinner
        style={{
          margtop: `${px2vw(140)}`,
          width: '1.8rem',
          height: '1.8rem',
          color: preto,
        }}
      />
    </SpinnerContainer>
  );

  const successContent = (
    <>
      <TermsAndPoliciesContentBox>
        <TermsContent>{termsContent}</TermsContent>
      </TermsAndPoliciesContentBox>
      <TermsAndPoliciesFooter>
        <ButtonPrimary
          disabled={loadingAcceptTermsOfUse}
          onClick={handleOnConfirm}
          minWidth={px2vw(150)}
          width={px2vw(150)}
        >
          {loadingAcceptTermsOfUse ? (
            <LoadingBox>
              <Spinner
                style={{
                  width: '1.8rem',
                  height: '1.8rem',
                  color: preto,
                }}
              />{' '}
            </LoadingBox>
          ) : (
            'Aceitar termos'
          )}
        </ButtonPrimary>
      </TermsAndPoliciesFooter>
    </>
  );

  return (
    <Modal
      isVisible={props.isVisible}
      padding={`${px2vw(30)} 0`}
      width={px2vw(700)}
      height={px2vw(530)}
    >
      <TermsAndPoliciesHeader>
        <TermsTitle>Termos de uso</TermsTitle>
        <TermsParagraph>
          Para acessar a plataforma você precisa ler e aceitar os nossos termos de uso.
        </TermsParagraph>
      </TermsAndPoliciesHeader>
      {loadingGetTermsAndPolicy ? loadingContent : successContent}
    </Modal>
  );
}

export default TermsAndPoliciesModal;
