import React from 'react';

import { Row, Col } from '../../../../../../styles/grid';
import { 
  BlockedImage, 
  PageText, 
  PageTitle, 
  TermsLink 
} from '../../../../styles';

import px2vw from '../../../../../../utils/responsiveness/px2vw';

import { 
  PageTextNotRenewPayment,
   PageTextRenewPayment 
} from '../constants';
import {
  BlockedAccessText1, 
  BlockedAccessText2,
  PageTextTermsUseText1, 
  PageTextTermsUseText2,
} from '../../constants';

export default function HeaderBlockedSignatureMobile({ handler }) {
  return (
    <>
      <Row>
        <BlockedImage
          size={px2vw(230)}
          margtop="0"
          src={require('../../../../../../assets/images/blocked_signature/blocked_signature.png')}
        />
        <Col margleft={px2vw(40)} margtop={px2vw(5)}>
          <Row>
            <PageTitle fontsize={px2vw(70)}>
              {BlockedAccessText1}
            </PageTitle>
          </Row>
          <Row margleft={px2vw(5)}>
            <PageTitle fontsize={px2vw(70)}>
              {BlockedAccessText2}
            </PageTitle>
          </Row>
        </Col>
      </Row>
      <Row justifyStart margtop={px2vw(30)} margbottom={px2vw(20)}>
        <PageText fontsize={px2vw(50)}>
          {/* First payment */}
          {!handler.isRenewPayment && PageTextNotRenewPayment}
          {/* Renew payment */}
          {handler.isRenewPayment && PageTextRenewPayment.replace('$$VALIDITY$$', handler.signaturePaymentData?.start_validity_at)}
          { /* First and Renew */}
          <TermsLink to={handler.termsOfUseLink} target="_blank">
            {PageTextTermsUseText1}
          </TermsLink>
          {PageTextTermsUseText2}
        </PageText>
      </Row>
    </>
  );
}
