import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { Container } from './styles';

import { profileAndSignature } from '../../routes/routeMap';

import { setCurrentTab } from '../../store/modules/profileAccount/actions';

function SignatureNotPaidBar() {
  const dispatch = useDispatch();

  return (
    <Container>
      {`Não identificamos o seu último pagamento. Para evitar o bloqueio automático, realize o pagamento acessando o `}
      <Link
        to={profileAndSignature}
        onClick={() => dispatch(setCurrentTab('tabSignature'))}
      >
        Perfil e Assinatura
      </Link>
    </Container>
  );
}

export default SignatureNotPaidBar;
