import React from 'react';
import { useSelector } from 'react-redux';
import { Spinner } from 'reactstrap';

import { Col, Row } from '../../../../../styles/grid';
import { 
  AcceptedPlaces, 
  AdvertiserCategory, 
  AdvertiserInfoBox,
  AdvertiserLogo, 
  AdvertiserName, 
  AdvertiserNameBackButton, 
  ConfirmNewCouponContent, 
  ConfirmNewCouponContentText, 
  ConfirmNewCouponContentTitle, 
  CouponDescriptionText, 
  CouponExpirationDateText, 
  CouponExpirationText, 
  CouponExpirationTitleText, 
  CouponNameText, 
  CouponPreviewBox, 
  ProductImageBox 
} from '../../../styles';
import { 
  ModalActions, 
  ModalPrimaryButton, 
  ModalPrimaryButtonText, 
  ModalSecundaryButton, 
  ModalSecundaryButtonText 
} from '../../../../../components/Modal/styles';
import { LoadingBox } from '../../../../../styles/common';
import { preto } from '../../../../../styles/colors';

import Modal from '../../../../../components/Modal';

import px2vw from '../../../../../utils/responsiveness/px2vw';

export default function ConfirmNewCouponModal({ handler }) {
  const userData = useSelector((state) => state.user.user);
  const userInfo = useSelector((state) => state.user.user.advertiserInfo);

  return (
    <Modal
      isVisible
      width={px2vw(380)}
      height={px2vw(handler.couponDiscountType === 'codeDiscount' ? 520 : 570)}
    >
      <ConfirmNewCouponContent>
        <ConfirmNewCouponContentTitle>
          Este é o seu cupom de desconto
        </ConfirmNewCouponContentTitle>
        <CouponPreviewBox>
          {(
            handler.couponDiscountType === 'traditionalCartPercentageDiscount' || 
            handler.couponDiscountType === 'flashCartPercentageDiscount' || 
            handler.couponDiscountType === 'codeDiscount'
           ) && (
            <>
              <ProductImageBox
                blur
                image={
                  userData.avatar
                    ? `${userData.avatar.url
                    }?nocache=${new Date().getTime()}`
                    : require('../../../../../assets/images/advertiser_logo/advertiser_logo.png')
                }
              />
            </>
          )}
          {(
            handler.couponDiscountType === 'traditionalProductDiscount' || 
            handler.couponDiscountType === 'flashProductDiscount'
           ) && (
            <>
              {handler.productAssociatedToCoupon?.url ? (
                <ProductImageBox
                  image={`${handler.productAssociatedToCoupon.url
                    }?nocache=${new Date().getTime()}`}
                />
              ) : (
                <ProductImageBox
                  blur
                  image={
                    userData.avatar
                      ? `${userData.avatar.url
                      }?nocache=${new Date().getTime()}`
                      : require('../../../../../assets/images/advertiser_logo/advertiser_logo.png')
                  }
                />
              )}
            </>
          )}
          <AdvertiserInfoBox>
            <Col padtop={px2vw(3)} padleft={px2vw(15)}>
              <Row justifyStart margbottom={px2vw(-5)}>
                <AdvertiserName>{userInfo.name}</AdvertiserName>
                <AdvertiserNameBackButton
                  src={require('../../../../../assets/images/arrows/arrow_right.png')}
                />
              </Row>
              <Row>
                <Col>
                  <AdvertiserCategory>
                    {userInfo.advertiserCategory.name}
                  </AdvertiserCategory>
                  <Row justifyStart margtop="5px">
                    {(handler.acceptOnDelivery) && (
                      <AcceptedPlaces>DELIVERY</AcceptedPlaces>
                    )}
                    {(handler.acceptOnStore) && (
                      <AcceptedPlaces>RETIRADA</AcceptedPlaces>
                    )}
                  </Row>
                </Col>
                <Col margtop={px2vw(-10)} alignEnd>
                  <AdvertiserLogo
                    src={
                      userData.avatar
                        ? `${userData.avatar.url
                        }?nocache=${new Date().getTime()}`
                        : require('../../../../../assets/images/advertiser_logo/advertiser_logo.png')
                    }
                  />
                </Col>
              </Row>
            </Col>
          </AdvertiserInfoBox>
          <Col pad={px2vw(25)}>
            {handler.couponDiscountType === 'codeDiscount' && (
              <>
                <CouponNameText>{`Código: ${handler.codeCouponDiscountCode}`}</CouponNameText>
                <CouponDescriptionText>{handler.couponDisplayNamePreview}</CouponDescriptionText>
              </>
            )}
            {handler.couponDiscountType !== 'codeDiscount' && (
              <CouponNameText>{handler.couponDisplayNamePreview}</CouponNameText>
            )}
            <CouponDescriptionText>
              {handler.couponDescriptionPreview}
            </CouponDescriptionText>
            {handler.couponDiscountType !== 'codeDiscount' && (
              <>
                <CouponExpirationTitleText>
                  Validade do cupom
                </CouponExpirationTitleText>
                <Row margtop={px2vw(8)} justifyStart>
                  <CouponExpirationText>Expira</CouponExpirationText>
                  <CouponExpirationDateText>
                    {handler.couponValidityDateInputValue}
                  </CouponExpirationDateText>
                </Row>
              </>
            )}
          </Col>
        </CouponPreviewBox>
        <ConfirmNewCouponContentText>
          Deseja confirmar a criação?
        </ConfirmNewCouponContentText>
        <ModalActions>
          <ModalSecundaryButton
            marginRight={px2vw(10)}
            margtop={px2vw(30)}
            width={px2vw(150)}
            onClick={() => handler.setConfirmNewCoupon(false)}
            disabled={handler.newCouponLoading}
          >
            <ModalSecundaryButtonText>Cancelar</ModalSecundaryButtonText>
          </ModalSecundaryButton>
          <ModalPrimaryButton
            margleft={px2vw(10)}
            margtop={px2vw(30)}
            width={px2vw(150)}
            onClick={() => handler.handleNewCouponConfirmSubmit()}
            disabled={handler.newCouponLoading}
          >
            {handler.newCouponLoading ? (
              <LoadingBox>
                <Spinner
                  style={{
                    width: '1.8rem',
                    height: '1.8rem',
                    color: preto,
                  }}
                />{' '}
              </LoadingBox>
            ) : (
              <ModalPrimaryButtonText>Confirmar</ModalPrimaryButtonText>
            )}
          </ModalPrimaryButton>
        </ModalActions>
      </ConfirmNewCouponContent>
    </Modal>
  )
}
