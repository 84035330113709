import React from 'react';

import {
  NewCouponModalContent,
  CouponButton,
  CouponImage,
  CouponText,
  CouponTitle,
  NewCoupon,
  Separator,
} from '../../../styles';
import { Row } from '../../../../../styles/grid';

import Modal from '../../../../../components/Modal';
import { CloseButton, CloseImage, ModalHeader } from '../../../../../components/Modal/styles';

import px2vw from '../../../../../utils/responsiveness/px2vw';

export default function SelectNewCouponModal({ handler }) {
  return (
    <Modal
      isVisible 
      width="auto"
      height={px2vw(200)}
    >
      <NewCouponModalContent>
        <ModalHeader>
          <Row justifyEnd padright={px2vw(10)} padtop={px2vw(10)}>
            <CloseButton
              onClick={() => handler.setCreateNewCouponModalVisible(false)}
            >
              <CloseImage
                src={require('../../../../../assets/images/close/close.png')}
              />
            </CloseButton>
          </Row>
          
        </ModalHeader>
        <Row justifyCenter margtop={px2vw(-20)} margleft={px2vw(-20)}>
          <NewCoupon>
            <CouponImage src={require('../../../../../assets/images/logo_kuppi/logo_black.png')} />
            <CouponTitle>Cupom Tradicional</CouponTitle>
            <CouponText>
              Essa opção oferece uma variedade maior de cupons de desconto, que
              podem ser configurados de acordo com seus objetivos. O prazo para
              o uso dos clientes costuma ser maior.
            </CouponText>
            <CouponButton onClick={() => handler.handleManageCouponContent('traditionalCoupon')}>
              Selecionar
            </CouponButton>
          </NewCoupon>
          <Separator src={require('../../../../../assets/images/traditional_coupon/separator.png')} />
          <NewCoupon>
            <CouponImage src={require('../../../../../assets/images/lightning_coupon/lightning_coupon.png')} />
            <CouponTitle>Cupom Relâmpago</CouponTitle>
            <CouponText>
              O ideal é que o desconto aqui seja mais generoso, pois nessa opção
              o senso de escassez é melhor explorado, limita-se o número de
              resgates do cupom, e o prazo para uso é mais curto.
            </CouponText>
            <CouponButton onClick={() => handler.handleManageCouponContent('flashCoupon')}>
              Selecionar
            </CouponButton>
          </NewCoupon>
          <Separator src={require('../../../../../assets/images/traditional_coupon/separator.png')} />
          <NewCoupon>
            <CouponImage code src={require('../../../../../assets/images/code_coupon/code_coupon.png')} />
            <CouponTitle>Cupom por Código</CouponTitle>
            <CouponText>
              Essa opção te permite criar um cupom de desconto com um código específico. 
              É uma opção de cupom mais utilizada para estratégias com influenciadores, 
              primeiro pedido e etc.
            </CouponText>
            <CouponButton onClick={() => handler.handleManageCouponContent('codeCoupon')}>
              Selecionar
            </CouponButton>
          </NewCoupon>
        </Row>
      </NewCouponModalContent>
    </Modal>
  )
}
