import styled, { css } from 'styled-components';

import {
  branco,
  cinzaKuppi4,
  verdeKuppi2,
  preto,
  cinzaKuppi11,
} from '../../../../styles/colors';

import px2vw from '../../../../utils/responsiveness/px2vw';

export const ModalHeader = styled.div`
  width: 100%;
  background-color: ${branco};
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: ${px2vw(33)} ${px2vw(28)} ${px2vw(15)} ${px2vw(28)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  font-size: ${px2vw(25)};
  display: flex;
  align-items: space-between;
  justify-content: space-between;
`;

export const CloseButton = styled.button`
  width: ${px2vw(18)};
  height: ${px2vw(18)};
  border: none;
  z-index: 10;
  background-color: transparent;

  :focus {
    background-color: transparent !important;
  }

  ${(props) =>
    props.absolute &&
    css`
      position: absolute;
      top: ${props.top || px2vw(10)};
      right: ${props.right || px2vw(10)};
    `}
`;

export const CloseImage = styled.img`
  width: ${px2vw(22)};
  height: ${px2vw(22)};
`;

export const ModalContent = styled.div`
  background-color: ${branco};
  margin: ${px2vw(5)} ${px2vw(25)};
  display: flex;
  ${(props) => `
    width: ${props.width};
    min-height: ${props.height || px2vw(400)};
    max-height: ${props.maxheight || px2vw(400)};
    background-color: ${props.bgcolor};
    margin-top: ${props.margtop};
  `};
  flex-wrap: wrap;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 0.5em;
    margin-left: 10px;
  }
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${verdeKuppi2};
    outline: 1px solid ${verdeKuppi2};
    border-radius: 15px;
  }
`;
export const ModalFooter = styled.div`
  width: 100%;
  min-height: ${px2vw(90)};
  margin-top: auto;
  background-color: ${branco};
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: ${px2vw(15)} ${px2vw(40)};
  font-family: AvenirNextLTPro;
  font-weight: 500;
  font-size: ${px2vw(14)};
`;

export const TemplateOption = styled.div`
  margin: ${px2vw(10)} ${px2vw(5)};
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid ${cinzaKuppi4};
  border-top: none;
  border-radius: 10px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;

  width: ${px2vw(300)};
  height: ${(props) => props.height || px2vw(335)};
  background: ${cinzaKuppi4};
  :hover {
    border-color: ${cinzaKuppi11};
  }

  ${(props) =>
    props.selected &&
    `
    background: ${verdeKuppi2};
    box-shadow: 0px 5px 30px #00000029;
    border: 2px solid ${verdeKuppi2};
    :hover {
      border-color: ${verdeKuppi2};
    }
  `};
`;

export const ImageBox = styled.div`
  margin-top: ${px2vw(3)};
  position: relative;
  display:flex;
  justify-content:center;
  align-items:center;
  ${(props) => props.width && `width: ${props.width};`}
  ${(props) => props.height && `height: ${props.height};`}
`;

export const TemplateImage = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;

  border-top-left-radius: ${(props) => props.btlRadius && props.btlRadius};
  border-top-right-radius: ${(props) => props.btrRadius && props.btrRadius};
  border-bottom-left-radius: ${(props) => props.bblRadius && props.bblRadius};
  border-bottom-right-radius: ${(props) => props.bbrRadius && props.bbrRadius};
`;

export const ColorOption = styled.div`
  border-radius: 50px;
  border-right-color: ${(props) => props.color1 || preto};
  border-top-color: ${(props) => props.color2 || preto};
  border-bottom-color: ${(props) => props.color1 || preto};
  border-left-color: ${(props) => props.color2 || preto};
  border-width: ${px2vw(17)};
  border-style: solid;
  height: 0px;
  width: 0px;
  margin-bottom: ${px2vw(5)};
  position: absolute;
  top: ${px2vw(-17.2)};
  left: ${px2vw(-17.2)};
  transform: rotate(90deg);
`;

export const ColorOptionBorder = styled.div`
  border-radius: 50px;
  border-right-color: ${(props) =>
    props.selected ? verdeKuppi2 : cinzaKuppi4};
  border-top-color: ${(props) => (props.selected ? verdeKuppi2 : cinzaKuppi4)};
  border-bottom-color: ${(props) =>
    props.selected ? verdeKuppi2 : cinzaKuppi4};
  border-left-color: ${(props) => (props.selected ? verdeKuppi2 : cinzaKuppi4)};
  border-width: ${px2vw(19.7)};
  border-style: solid;
  height: 0px;
  width: 0px;
  margin-bottom: ${px2vw(7)};
  position: relative;
`;

export const ColorBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  margin: 0 ${px2vw(5)};
`;
