import { Radio } from '@material-ui/core';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setInsertBlackList,
  setDeleteConfig,
  setIsboxVisible,
  updateBlackListRequest,
} from '../../../store/modules/massMessage/actions';
import { cinzaKuppi10 } from '../../../styles/colors';
import { Text } from '../../../styles/common';
import { Row } from '../../../styles/grid';
import px2vw from '../../../utils/responsiveness/px2vw';

import {
  Box,
  CheckBox,
  Container,
  PrimaryButton,
  SecundaryButton,
  Span,
} from './styles';

export default function DeleteBox({onBlackListSend, isFromManualList}) {
  const dispatch = useDispatch();
  const { blacklistConfig, contactsBlacklist, sendClientList } = useSelector(
    (state) => state.massMessage
  );
  const [deleteType, setDeleteType] = useState(
    blacklistConfig?.deleteType === 'individual' ? 'occasional' : ''
  );
  const [massDeleteType, setMassDeleteType] = useState(false);

  function handleSendToBlackList() {
    if (
      blacklistConfig?.deleteType === 'individual' &&
      deleteType === 'permanent'
    ) {
      dispatch(setIsboxVisible(false));
      dispatch(updateBlackListRequest());
    } else if (
      blacklistConfig?.deleteType === 'individual' &&
      deleteType === 'occasional'
    ) {
      dispatch(
        setInsertBlackList([...contactsBlacklist, blacklistConfig.contact])
      );
      dispatch(setIsboxVisible(false));
    } else if (
      blacklistConfig?.deleteType === 'multiple' &&
      deleteType === 'deleteAll'
    ) {
      if (massDeleteType) {
        dispatch(updateBlackListRequest());
      } else {
        dispatch(
          setInsertBlackList([...contactsBlacklist, ...blacklistConfig.contact])
        );
      }
      dispatch(setIsboxVisible(false));
    } else if (
      blacklistConfig?.deleteType === 'multiple' &&
      deleteType === 'keepAll'
    ) {
      const deleteIdsList = blacklistConfig.contact.map((c) => c.id);
      const deleteList = sendClientList.filter(
        (c) => !deleteIdsList.includes(c.id)
      );

      dispatch(setDeleteConfig('multiple', deleteList));
      if (massDeleteType) {
        dispatch(updateBlackListRequest());
      } else {
        dispatch(
          setInsertBlackList([...contactsBlacklist, ...deleteList])
        );
      }
      dispatch(setIsboxVisible(false));
    }

    onBlackListSend()
  }

  function handleCancelAction() {
    dispatch(setIsboxVisible(false));
  }
  
  return (
    <Container>
      <Box>
        <Text alignCenter size={px2vw(16)}>
          {`Como deseja prosseguir com a Ação ${
            blacklistConfig?.deleteType !== 'individual' ? 'em Massa' : ''
          }?`}
        </Text>
        {blacklistConfig?.deleteType === 'individual' ? (
          <>
            <Row
              marg={`${px2vw(10)} 0`}
              pad={`0 0 0 ${px2vw(35)}`}
              height="auto"
              alignCenter
              justifyStart
            >
              <Radio
                onClick={() => setDeleteType('occasional')}
                checked={deleteType === 'occasional'}
                color="default"
              />
              <Span
                fontSize={px2vw(13)}
                fontWeight="600"
                color={cinzaKuppi10}
                cursor="pointer"
              >
                Excluir apenas desse disparo.
              </Span>
            </Row>
            <Row
              marg={`${px2vw(10)} 0`}
              pad={`0 0 0 ${px2vw(35)}`}
              height="auto"
              alignCenter
              justifyStart
            >
              <Radio
                onClick={() => setDeleteType('permanent')}
                checked={deleteType === 'permanent'}
                color="default"
              />
              <Span
                fontSize={px2vw(13)}
                fontWeight="600"
                color={cinzaKuppi10}
                cursor="pointer"
              >
                Excluir de todos os disparos.
              </Span>
            </Row>
          </>
        ) : (
          <>
            <Row
              marg={`${px2vw(10)} 0`}
              pad={`0 0 0 ${px2vw(35)}`}
              height="auto"
              alignCenter
              justifyStart
            >
              <Radio
                onClick={() => setDeleteType('deleteAll')}
                checked={deleteType === 'deleteAll'}
                color="default"
              />
              <Span
                fontSize={px2vw(13)}
                fontWeight="600"
                color={cinzaKuppi10}
                cursor="pointer"
              >
                Excluir os selecionados.
              </Span>
            </Row>
            <Row
              marg={`${px2vw(10)} 0`}
              pad={`0 0 0 ${px2vw(35)}`}
              height="auto"
              alignCenter
              justifyStart
            >
              <Radio
                onClick={() => setDeleteType('keepAll')}
                checked={deleteType === 'keepAll'}
                color="default"
              />
              <Span
                fontSize={px2vw(13)}
                fontWeight="600"
                color={cinzaKuppi10}
                cursor="pointer"
              >
                Manter apenas os selecionados.
              </Span>
            </Row>
            <Row
              marg={`${px2vw(10)} 0`}
              pad={`0 0 0 ${px2vw(35)}`}
              height="auto"
              alignCenter
              justifyStart
            >
              <CheckBox
                disabled={!deleteType}
                checked={massDeleteType}
                onClick={() => setMassDeleteType(!massDeleteType)}
                className="mx-2"
              ></CheckBox>
              <Span
                fontSize={px2vw(12)}
                fontWeight="600"
                color={cinzaKuppi10}
                cursor="pointer"
              >
                Executar está ação para os próximos disparos?
              </Span>
            </Row>
          </>
        )}
        <Row marg={`${px2vw(10)} 0`} pad={`${px2vw(10)} 0 0 0`} height="auto">
          <PrimaryButton onClick={handleCancelAction} className="mx-auto">
            Cancelar
          </PrimaryButton>
          <SecundaryButton
            disabled={!deleteType}
            className="mx-auto"
            onClick={handleSendToBlackList}
          >
            Confirmar
          </SecundaryButton>
        </Row>
      </Box>
    </Container>
  );
}
