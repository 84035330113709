import React, { useEffect,  useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { preto, cinzaKuppi4, cinzaKuppi1 } from '../../../../styles/colors';

import animationData from '../../../../assets/animations/kuppiBoost/kuppiBoost.json';
import { Text } from '../../../../styles/common';
import { Row, Col } from '../../../../styles/grid';
import px2vw from '../../../../utils/responsiveness/px2vw';
import { AiOutlineLeft } from 'react-icons/ai';
import { ModalPrimaryButton } from '../styles';

import Lottie from 'react-lottie';

import {
  ModalContent,
  CloseButton,
  ModalHeader,
  CloseImage,
  ModalFooter,
  Select,
  Input,
  AnimatedImg,
} from './styles';
import {
  createCampaign,
  getGenAds,
  setCampaignCurrentStep,
} from '../../../../store/modules/kuppiBoost/actions';
import moment from 'moment';
import { formatCurrencyBRL } from '../../../../utils/utils';
import debounce from '../../../../utils/debounce/debounce';
import { Spinner } from 'reactstrap';
import { toast } from 'react-toastify';
import MapContent from '../../MapContent';

export default function CampaignDetails(props) {
  const dispatch = useDispatch();
  const {
    create_campaign_loading,
    create_campaign_success,
    balance,
    gen_ads,
    gen_loading,
    campaign
  } = useSelector((state) => state.kuppiBoost);

  const userData = useSelector((state) => state.user.user);
  const myLatLng = {
    lat: Number(userData.address[0].latitude),
    lng: Number(userData.address[0].longitude),
  };
  const deliveryTakeawayConfig = userData.advertiserInfo?.advertiserDeliveryTakeawayConfig[0];
  const isUsingNeighborhoodArea =
    !deliveryTakeawayConfig?.is_using_range_area &&
    deliveryTakeawayConfig?.is_using_neighborhood_area;
  const [campaignTotalDays, setCampaignTotalDays] = useState(
    gen_ads?.campaign_period || 0
  );
  const [rangeArea, setRangeArea] = useState(!isUsingNeighborhoodArea ? userData?.advertiserInfo.delivery_range_area : 10);
  const campaignBuget = balance / parseInt(campaignTotalDays);
  const campaignValidation = (balance / parseInt(campaignTotalDays)) < 0;
  const { type } = campaign;
  const maxRange = type === 'whatsapp' ? 50 : 80

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid', // slice
    },
  };

  const campaignEndDate = moment()
    .add(campaignTotalDays, 'd')
    .format('DD/MM/YYYY');

  const rangeOptions = (total) => {
    if (total > maxRange) {
      total = maxRange;
    }

    if (isUsingNeighborhoodArea) {
      total = 10;
    }
    const options = [];

    for (let i = 1; i <= total; i++) {
      const selected = rangeArea === i || (i === total && rangeArea > total)

      options.push(
        <option key={i} value={i} selected={selected}>
          {i + ' km'}
        </option>
      );
    }

    return options;
  };

  const daysOptions = (minDays, maxDays) => {
    const options = [];

    for (let i = minDays; i <= maxDays; i++) {
      if (i === minDays) {
        options.push(
          <option key={i} value={i} selected>
            {i + ' dias'}
          </option>
        )
      } else {
        options.push(
          <option key={i} value={i}>
            {i + ' dias'}
          </option>
        );
      }
    }

    return options;
  };

  useEffect(() => {
    if (!gen_loading) {
      dispatch(getGenAds(rangeArea > maxRange ? 50 : rangeArea, balance));
    }
  }, [rangeArea]);

  useEffect(() => {
    setCampaignTotalDays(gen_ads?.campaign_period)
  }, [gen_ads])

  function isDisabledChangeCampaignDays() {
    if (gen_ads?.campaign_max_period === gen_ads?.campaign_period) {
      toast.info(
        'Não é possível alterar a quantidade de dias que a campanha vai rodar, com as configurações atuais',
        {
          autoClose: 6000,
        }
      );
    }
  }

  function handleBacktoPreviousStep() {
    dispatch(setCampaignCurrentStep('2'));
  }

  const handleCreateCampaign = () => {
    dispatch(createCampaign(balance, rangeArea));
  };

  const mainContent = (
    <>
      <ModalHeader>
        Defina os detalhes do impulsionamento
        <CloseButton onClick={props.onClose}>
          <CloseImage
            src={require('../../../../assets/images/close/close.png')}
          />
        </CloseButton>
      </ModalHeader>
      <ModalContent height={px2vw(380)}>
        <Row justifyStart>
          <Col minheight={px2vw(350)} justifyStart className="pr-3">
            <Text justifyStart size={px2vw(13)}>
              Localização do impulsionamento
            </Text>

            <Row
              justifyStart
              alignCenter
              margtop={px2vw(8)}
              margbottom={px2vw(8)}
            >
              <Select
                className="mr-2"
                onChange={(e) =>
                  debounce(setRangeArea(parseInt(e.target.value)))
                }
              >
                {rangeOptions(maxRange)}
              </Select>
              <Text justifyStart size={px2vw(13)} regular>
                Raio de alcance padrão definido por sua área de delivery
              </Text>
            </Row>

            <div id="map">
                <MapContent rangeArea={rangeArea} position={myLatLng} />
            </div>

            <Col justifyStart className="mt-auto">
              <Text justifyStart size={px2vw(13)} regular>
                Estimativa de pessoas alcançadas
              </Text>
              {gen_loading ? (
                <Spinner
                  style={{
                    width: px2vw(19),
                    height: px2vw(19),
                    color: preto,
                  }}
                  className="mx-auto my-2"
                />
              ) : (
                <Text
                  justifyStart
                  size={px2vw(17)}
                  bold={!gen_loading}
                  margtop={px2vw(7)}
                  fadeIn
                >
                  {`${gen_ads?.campaign_estimated_reach} pessoas`}
                </Text>
              )}
            </Col>
          </Col>
          <Col
            className="pl-4"
            minheight={px2vw(350)}
            justifyStart
            style={{ borderLeft: `2px solid ${cinzaKuppi4}` }}
          >
            <Text justifyStart bold size={px2vw(13)}>
              Duração da campanha
            </Text>
            <Row
              margtop={px2vw(7)}
              margbottom={px2vw(20)}
              alignCenter
              justifyStart
            >
              <Select
                className="w-10 mr-auto"
                onChange={(e) =>
                  debounce(setCampaignTotalDays(e.target.value))
                }
                onClick={() => isDisabledChangeCampaignDays()}
                readOnly={
                  gen_ads?.campaign_max_period === gen_ads?.campaign_period
                }
              >
                {daysOptions(gen_ads?.campaign_period, gen_ads?.campaign_max_period)}
              </Select>

              <Text
                justifyStart
                size={px2vw(12)}
                width={px2vw(45)}
                regular
                className="mr-3"
              >
                Data do término:
              </Text>
              <Input
                type="text"
                value={campaignEndDate}
                width={px2vw(115)}
                disabled
              />
            </Row>
            <Col
              background={cinzaKuppi1}
              height={px2vw(170)}
              justifyStart
              pad={px2vw(20)}
              borderRadius={px2vw(11)}
            >
              <Text justifyStart size={px2vw(13)} className="ml-2">
                Orçamento diário da campanha
              </Text>
              <Text
                justifyStart
                size={px2vw(13)}
                className="ml-2 mb-auto"
                regular
                margtop={px2vw(5)}
              >
                {formatCurrencyBRL(parseFloat(campaignBuget), 2)}
              </Text>
              <Text
                justifyStart
                size={px2vw(13)}
                className="ml-2 mt-auto"
                margtop={px2vw(5)}
              >
                Orçamento total da campanha
              </Text>
              <Text
                justifyStart
                size={px2vw(13)}
                className="ml-2"
                regular
                margtop={px2vw(5)}
              >
                {formatCurrencyBRL(balance, 2)}
              </Text>
            </Col>
            <Col justifyStart className="mt-auto">
              <Text justifyStart size={px2vw(13)} regular>
                Estimativa de pessoas alcançadas diariamente
              </Text>
              {gen_loading ? (
                <Spinner
                  style={{
                    width: px2vw(19),
                    height: px2vw(19),
                    color: preto,
                  }}
                  className="mx-auto my-2"
                />
              ) : (
                <Text fadeIn justifyStart size={px2vw(17)} margtop={px2vw(7)}>
                  {`${Math.ceil(
                    gen_ads?.campaign_estimated_reach / campaignTotalDays
                  ) || 0} pessoas`}
                </Text>
              )}
            </Col>
          </Col>
        </Row>
      </ModalContent>
      <ModalFooter>
        <Row className="my-auto" alignCenter>
          <Row
            onClick={handleBacktoPreviousStep}
            justifyStart
            alignCenter
            width="auto"
            cursor="pointer"
          >
            <AiOutlineLeft className="mr-2" /> Voltar
          </Row>
          <Col>
            <ModalPrimaryButton
              disabled={campaignValidation}
              width={px2vw(170)}
              fontSize={px2vw(12)}
              className="ml-auto"
              onClick={handleCreateCampaign}
            >
              Turbinar minha loja
            </ModalPrimaryButton>
          </Col>
        </Row>
      </ModalFooter>
    </>
  );

  const creatingCampaignLoader = (
    <>
      <ModalHeader width="85%">
        Aguarde um momento, estamos preparando a melhor campanha para você :)
      </ModalHeader>
      <ModalContent>
        <Row height={px2vw(350)} alignStart justifyCenter>
          <Lottie options={defaultOptions} isStopped={false} isPaused={false} />
        </Row>
      </ModalContent>
    </>
  );

  const statusCampaignContent = (
    <ModalContent height={px2vw(500)} margtop={px2vw(25)}>
      <Col height={px2vw(450)} alignCenter className="mt-auto">
        <Text justifyStart size={px2vw(22)}>
          Campanha criada com sucesso!
        </Text>

        <AnimatedImg
          className="my-auto"
          src={require('../../../../assets/images/success/checkmark.png')}
        />

        <ModalPrimaryButton
          fontSize={px2vw(15)}
          width={px2vw(200)}
          height={px2vw(45)}
          onClick={props.onClose}
        >
          Prosseguir
        </ModalPrimaryButton>
      </Col>
    </ModalContent>
  );

  if (create_campaign_loading) {
    return creatingCampaignLoader;
  } else if (create_campaign_success) {
    return statusCampaignContent;
  } else {
    return mainContent;
  }
}
