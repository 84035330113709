import React from 'react';
import { cinzaKuppi4 } from '../../../../styles/colors';
import styled from 'styled-components';
import px2vw from '../../../../utils/responsiveness/px2vw';
import { TaskItem } from './TaskItem';

export const TaskList = ({ challenges = [] }) => {
  return (
    <Container>
      {challenges.map((item, key) => (
        <TaskItem key={key} {...item} />
      ))}
    </Container>
  );
};

export const Container = styled.ul`
  width: 100%;
  padding: ${px2vw(15)} ${px2vw(5)};
`;
