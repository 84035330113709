import styled from 'styled-components';

import Table from '@material-ui/core/Table';

import {
  branco,
  preto,
  cinzaKuppi4,
  verdeKuppi1,
  verdeKuppi5,
} from '../../styles/colors';

export const ActionsMenuBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  height: 50px;
`;

export const CouponsBox = styled.div`
  width: 1380px;
  background-color: ${branco};
  margin-left: 5px;
  border-radius: 20px;
  padding: 0px 25px;
`;

export const SearchBox = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 20px;
`;

export const SearchButton = styled.button`
  margin-top: 6px;
  margin-right: -38px;
  width: 20px;
  height: 20px;
  border: none;
  z-index: 10;
  background-color: transparent;
`;

export const SearchImage = styled.img`
  width: 20px;
  height: 20px;
`;

export const SearchInput = styled.input`
  padding: 1em;
  padding-left: 42px;
  margin-left: 0.5em;
  margin-right: 11px;
  margin-bottom: 12px;
  border: 1px solid ${cinzaKuppi4};
  border-radius: 5px;
  font-size: 14px;
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.32px;
  width: 250px;
  height: 35px;
`;

export const TabsBox = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 20px;
  border-bottom: 1px solid ${cinzaKuppi4};
`;

export const TabBox = styled.div`
  display: block;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Tab = styled.button`
  height: 50px;
  margin-right: 40px;
  font-size: 22px;
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.48px;
  border: none;
  background-color: transparent;
`;

export const TabSelected = styled.div`
  display: none;
  height: 5px;
  width: 125px;
  background-color: ${verdeKuppi1};
  border-radius: 5px;
  margin-top: -7px;
  margin-right: 40px;
  margin-bottom: 20px;

  ${(props) =>
    props.isVisible &&
    `
    display: block;
  `};

  ${(props) => `
    width: ${props.width};
  `};
`;

export const NewCoupomButton = styled.button`
  background-color: ${verdeKuppi1};
  width: 150px;
  height: 44px;
  border: none;
  border-radius: 10px;
  padding: 0.3em;
  margin-top: 5px;
  margin-left: 840px;
`;

export const NewCoupomButtonText = styled.div`
  font-size: 16px;
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.36px;
  color: ${branco};
`;

export const SetOrderOrdersBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const SetOrderOrdersButton = styled.button`
  height: 40px;
  margin-top: 5px;
  border: none;
  background-color: transparent;

  ${(props) => `
    margin-left: ${props.margleft};
  `};
`;

export const SetOrderOrdersButtonText = styled.div`
  font-size: 16px;
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.36px;
  margin-top: 2px;
`;

export const TabContent = styled.div`
  display: none;

  ${(props) =>
    props.isVisible &&
    `
    display: block;
  `};
`;

export const TableCoupons = styled(Table)`
  min-width: 650;
`;

export const CouponsRowText = styled.div`
  font-size: 16px;
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.36px;

  ${(props) =>
    props.isBold &&
    `
    font-family: AvenirNextLTPro;
    font-weight: 700;
  `};

  ${(props) => `
    margin-top: ${props.margtop};
    margin-left: ${props.margleft};
    font-size: ${props.fontSize};
  `};
`;

export const CouponsHeaderText = styled.text`
  font-size: 16px;
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.36px;

  ${(props) => `
    margin-left: ${props.margleft};
  `};
`;

export const HeaderOrderButton = styled.button`
  height: 21px;
  width: 21px;
  margin-left: 10px;
  border: none;
  background-color: transparent;
`;

export const CouponsHeaderImage = styled.img`
  height: 20px;
  width: 20px;
  margin-bottom: -4px;
`;

export const NewCoupomTraditional = styled.div`
  float: left;
  height: 550px;
  width: 50%;
  background-color: ${verdeKuppi1};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  padding-top: 10px;
`;

export const TraditionalCoupomImage = styled.img`
  width: 90px;
  height: 90px;
  margin-top: 10px;
`;

export const TraditionalCoupomTitle = styled.div`
  font-size: 32px;
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.72px;
  margin-top: 20px;
  color: ${branco};
`;

export const TraditionalCoupomText = styled.div`
  font-size: 16px;
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.36px;
  margin-top: 40px;
  width: 300px;
  text-align: center;
  color: ${branco};
`;

export const TraditionalCoupomButton = styled.button`
  background-color: transparent;
  width: 190px;
  height: 40px;
  border: 2px solid ${branco};
  border-radius: 10px;
  padding: 0.3em;
  margin-top: 60px;
  font-size: 16px;
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.36px;
  color: ${branco};

  :hover {
    background-color: ${branco};
    color: ${preto};
  }
`;

export const NewCoupomLightning = styled.div`
  float: right;
  height: 550px;
  width: 50%;
  background-color: ${verdeKuppi5};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  padding-top: 400px;
`;

export const LightningCoupomImage = styled.img`
  width: 90px;
  height: 90px;
  margin-top: 10px;
`;

export const LightningCoupomTitle = styled.div`
  font-size: 32px;
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.72px;
  margin-top: 20px;
`;

export const LightningCoupomText = styled.div`
  font-size: 16px;
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.36px;
  margin-top: 40px;
  width: 300px;
  text-align: center;
`;

export const LightningCoupomButton = styled.button`
  background-color: transparent;
  width: 190px;
  height: 40px;
  border: 2px solid ${preto};
  border-radius: 10px;
  padding: 0.3em;
  margin-top: 60px;
  font-size: 16px;
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.36px;

  :hover {
    background-color: ${branco};
    border: 2px solid ${branco};
  }
`;
