import React, { useState, useEffect } from 'react';
import moment from 'moment';

import { FormControlLabel } from '@material-ui/core';
import { Spinner } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import px2vw from '../../../utils/responsiveness/px2vw';
import { timeMask } from '../../../utils/masks';
import { isTimeValid } from '../../../utils/validations/validations';
import { onlyNumbers } from '../../../utils/utils';
import { schedulingMinDaysOptionsArr, schedulingMinHoursOptionsArr, schedulingMinMinutesOptions } from '../../../utils/commonObjects';

import { preto } from '../../../styles/colors';
import { SwitchLabelRegularTextBefore, IntervalOption, SelectInputBox } from './styles';
import { Row, Col } from '../../../styles/grid';

import {
  ConfigGroup,
  SaveButton,
  SaveButtonText,
  ConfigGroupTitle,
  ConfigGroupContent,
  StoreForm,
} from '../styles';

import {
  SwitchController,
  SwitchLabelRegularText,
  SwitchWithInput,
  SwitchWithInputBox,
  SwitchInput,
  LoadingBox,
} from '../../_layouts/styles/common';

import {
  clearSuccess,
  updateStoreSchedulingAvailabilityRequest,
} from '../../../store/modules/myStore/actions';

function StoreScheduling(props) {
  const [weekDays, setWeekDays] = useState([
    {
      name: 'sunday',
      is_active: false,
      start_time: '',
      end_time: '',
    },
    {
      name: 'monday',
      is_active: false,
      start_time: '',
      end_time: '',
    },
    {
      name: 'tuesday',
      is_active: false,
      start_time: '',
      end_time: '',
    },
    {
      name: 'wednesday',
      is_active: false,
      start_time: '',
      end_time: '',
    },
    {
      name: 'thursday',
      is_active: false,
      start_time: '',
      end_time: '',
    },
    {
      name: 'friday',
      is_active: false,
      start_time: '',
      end_time: '',
    },
    {
      name: 'saturday',
      is_active: false,
      start_time: '',
      end_time: '',
    },
  ]);
  const [hasChanges, setHasChanges] = useState(false);
  const storeSchedulingAvailability = useSelector(
    (state) => state.user.user.advertiserInfo.advertiserSchedulingAvailability
  );
  const storeSchedulingConfigs = useSelector(
    (state) => state.user.user.advertiserInfo.advertiserSchedulingConfig
  );
  const [
    schedulingMinDaysInputValue,
    setSchedulingMinDaysInputValue,
  ] = useState(0);
  const [
    schedulingMinHoursInputValue,
    setSchedulingMinHoursInputValue,
  ] = useState(0);
  const [
    schedulingMinMinutesInputValue,
    setSchedulingMinMinutesInputValue,
  ] = useState(0);
  const [
    schedulingMinTimeInputValue,
    setSchedulingMinTimeInputValue,
  ] = useState('');
  const [
    maxQtyAvailableSchedulesDays,
    setMaxQtyAvailableSchedulesDays,
  ] = useState(false);
  const [maxQtyAvailableSchedulesDaysInputValue, setMaxQtyAvailableSchedulesDaysInputValue] = useState(
    '10'
  );
  const [interval, setInterval] = useState(0);
  const [schedulingMaxQtyInputValue, setSchedulingMaxQtyInputValue] = useState(
    ''
  );
  const schedulingLoading = useSelector(
    (state) => state.myStore.schedulingLoading
  );
  const schedulingSuccess = useSelector(
    (state) => state.myStore.schedulingSuccess
  );
  const schedulingMinDaysOptions = schedulingMinDaysOptionsArr();
  const schedulingMinHoursOptions = schedulingMinHoursOptionsArr();
  const dispatch = useDispatch();

  useEffect(() => {
    if (schedulingSuccess) {
      setHasChanges(false);
      dispatch(clearSuccess());
      if (props.onSuccess) {
        props.onSuccess();
      }
    }
  }, [schedulingSuccess, dispatch]);

  useEffect(() => {
    // Get only the week days from store
    const storeSchedulingAvailabilityWeekDays = Object.keys(
      storeSchedulingAvailability
    ).filter(
      (k) =>
        k !== 'id' &&
        k !== 'advertiser_info_id' &&
        k !== 'created_at' &&
        k !== 'updated_at'
    );
    // List with changed days
    const listWithChangedDay = [];

    storeSchedulingAvailabilityWeekDays.forEach((dayName) => {
      weekDays.forEach((day, index) => {
        const weekDay = day;
        const theDayBefore =
          index > 0 ? weekDays[index - 1] : weekDays[weekDays.length - 1];

        if (day.name === dayName) {
          weekDay.is_active = storeSchedulingAvailability[dayName].is_active;

          storeSchedulingAvailability[dayName].horaries.forEach((horary) => {
            const startHour = parseInt(horary.start_at.substring(0, 2), 10);
            // Hour is greater than midnight, so get time for today
            if (startHour > 0) {
              weekDay.start_time = horary.start_at;
              weekDay.end_time = horary.end_at;
              // Hour is before midnight, so get the last hour from yesterday
            } else {
              theDayBefore.end_time = horary.end_at;
            }
          });
        }
        // Since I'm using forEach and I'm changing the day before,
        // I use this array to store the changes
        listWithChangedDay[index] = weekDay;
      });
    });
    setWeekDays(listWithChangedDay);
  }, [storeSchedulingAvailability]);

  useEffect(() => {
    // Min time to schedule
    setSchedulingMinTimeInputValue('');
    const days = Math.floor((storeSchedulingConfigs.min_scheduling_time / 24) / 60);
    const daysInMinutes = parseInt(days, 10) > 0 ? ((parseInt(days, 10) * 24) * 60) : parseInt(days, 10);
    const hours = Math.floor((storeSchedulingConfigs.min_scheduling_time - daysInMinutes) / 60);
    const hoursInMinutes = parseInt(hours, 10) > 0 ? (parseInt(hours, 10) * 60) : parseInt(hours, 10);
    const minutes = storeSchedulingConfigs.min_scheduling_time - daysInMinutes - hoursInMinutes;
    setSchedulingMinDaysInputValue(days);
    setSchedulingMinHoursInputValue(hours);
    setSchedulingMinMinutesInputValue(minutes);
    setSchedulingMinTimeInputValue(storeSchedulingConfigs.min_scheduling_time);
    // Max schedules per time
    setSchedulingMaxQtyInputValue('');
    setSchedulingMaxQtyInputValue(
      storeSchedulingConfigs.max_orders_per_scheduling
    );
    // Max qty available schedules days
    if (storeSchedulingConfigs.max_available_schedule_days && (parseInt(storeSchedulingConfigs.max_available_schedule_days, 10) !== 10)) {
      setMaxQtyAvailableSchedulesDays(true);
      setMaxQtyAvailableSchedulesDaysInputValue(storeSchedulingConfigs.max_available_schedule_days);
    }
    // Schedules interval
    setInterval(parseInt(storeSchedulingConfigs.interval_between_scheduling, 10));
  }, [storeSchedulingConfigs]);

  const onChanges = () => setHasChanges(true);

  const handleToggleDayActive = (dayName) => {
    onChanges();

    const listWithChangedDay = weekDays.map((day) => {
      const weekDay = day;
      if (day.name === dayName) {
        weekDay.is_active = !day.is_active;
        weekDay.start_time = '';
        weekDay.end_time = '';
      }
      return weekDay;
    });

    setWeekDays(() => listWithChangedDay);
  };

  const handleOnChangeDayInput = (options) => {
    onChanges();

    const { dayName, startTime, endTime } = options;

    const listWithChangedDay = weekDays.map((day) => {
      const weekDay = day;
      if (day.name === dayName) {
        if (startTime || startTime === '') {
          weekDay.start_time = startTime;
        }
        if (endTime || endTime === '') {
          weekDay.end_time = endTime;
        }
      }
      return weekDay;
    });

    setWeekDays(() => listWithChangedDay);
  };

  const translateDayName = (dayName) => {
    switch (dayName) {
      case 'sunday':
        return 'Domingo';
      case 'monday':
        return 'Segunda';
      case 'tuesday':
        return 'Terça';
      case 'wednesday':
        return 'Quarta';
      case 'thursday':
        return 'Quinta';
      case 'friday':
        return 'Sexta';
      case 'saturday':
        return 'Sábado';
      default:
        return '';
    }
  };

  const isSaveButtonDisabled = () => {
    if (props.isStep) {
      return !props.isActive || schedulingLoading;
    }
    return !hasChanges || schedulingLoading;
  };

  const isSaveButtonActive = () => {
    if (props.isStep) {
      return props.isActive;
    }
    return hasChanges;
  };

  const handleSetInterval = (interval) => {
    onChanges();
    setInterval(interval);
  };

  function setSchedulingMinTime(type, newSelectedTime) {
    onChanges();

    let newTime = 0;
    const selectedTime = parseInt(newSelectedTime, 10);
    const daysInMinutes = parseInt(schedulingMinDaysInputValue, 10) > 0 ? ((parseInt(schedulingMinDaysInputValue, 10) * 24) * 60) : parseInt(schedulingMinDaysInputValue, 10);
    const hoursInMinutes = parseInt(schedulingMinHoursInputValue, 10) > 0 ? (parseInt(schedulingMinHoursInputValue, 10) * 60) : parseInt(schedulingMinHoursInputValue, 10);
    const minutes = parseInt(schedulingMinMinutesInputValue, 10);

    if (type === 'days') {
      // Set the new time at select input
      setSchedulingMinDaysInputValue(newSelectedTime);
      // Calc days in minutes
      newTime = (selectedTime * 24) * 60;
      // Add at the new time theses hours in minute
      newTime += hoursInMinutes;
      // Add at the new time theses minutes in minute 
      newTime += minutes;
    } else if (type === 'hours') {
      // Set the new time at select input
      setSchedulingMinHoursInputValue(newSelectedTime);
      // Calc hours in minutes
      newTime = selectedTime * 60;
      // Add at the new time theses days in minute
      newTime += daysInMinutes;
      // Add at the new time theses minutes in minute 
      newTime += minutes
    } else if (type === 'minutes') {
      // Set the new time at select input
      setSchedulingMinMinutesInputValue(newSelectedTime);
      // Add minutes
      newTime = selectedTime;
      // Add at the new time theses days in minute
      newTime += daysInMinutes;
      // Add at the new time theses hours in minute 
      newTime += hoursInMinutes;
    }

    setSchedulingMinTimeInputValue(newTime.toString());
  }

  const handleStoreSchedulingSubmit = () => {
    const isAtLeastOneDayActive = weekDays.some((day) => day.is_active);
    const isAtLeastOneHoraryValid = weekDays.every((day) => {
      const startTime = day.start_time;
      const endTime = day.end_time;
      const isActive = day.is_active;
      // Validate the horaries only if this day is active
      if (isActive) {
        return isTimeValid(startTime) && isTimeValid(endTime);
      }
      return true;
    });
    let hasValidHorary = false;
    weekDays.forEach((day) => {
      const startTime = onlyNumbers(day.start_time);
      const endTime = onlyNumbers(day.end_time);
      const isActive = day.is_active;
      // Validate the horaries only if this day is active
      if (isActive && !hasValidHorary) {
        const startTimeHour1 = startTime.substring(0, 2);
        const startTimeMinute1 = startTime.substring(2, 4);
        const endTimeHour2 = endTime.substring(0, 2);
        const endTimeMinute2 = endTime.substring(2, 4);
        const date1 = new Date(`2001-01-01T${startTimeHour1}:${startTimeMinute1}`).toISOString();
        const date2 = new Date(`2001-01-01T${endTimeHour2}:${endTimeMinute2}`).toISOString();
        const diffTimeInMinutes = moment(date2).diff(date1, 'minutes');
        hasValidHorary = diffTimeInMinutes >= interval;
      }
    });

    if (!isAtLeastOneDayActive) {
      toast.error(
        'Deve ser selecionado ao menos um dia para a loja estar aberta.',
        {
          autoClose: 6000,
        }
      );
      return;
    }
    if (!isAtLeastOneHoraryValid) {
      toast.error(
        'Algum dia foi selecionado, porém o horário não foi informado corretamente. Revise os campos.',
        {
          autoClose: 6000,
        }
      );
      return;
    }
    if (!hasValidHorary) {
      toast.error(
        'Algum dia foi selecionado, porém a faixa de horário informada está menor do que o intervalo de horários disponíveis. Revise os campos.',
        {
          autoClose: 6000,
        }
      );
      return;
    }

    const updateStoreAvailabilityArgs = [];
    weekDays.forEach((day) => {
      updateStoreAvailabilityArgs.push(
        day.is_active,
        day.start_time,
        day.end_time
      );
    });
    // Verify the schedule config was succesfully filled
    // Scheduling min time
    if (schedulingMinTimeInputValue === '' || parseInt(schedulingMinTimeInputValue, 10) === parseInt(0, 10)) {
      toast.error('Não foi definido o tempo mínimo para agendar o pedido.', {
        autoClose: 6000,
      });
      return;
    }
    // Scheduling interval
    if (interval === 0) {
      toast.error('Intervalo entre agendamentos não escolhido.', {
        autoClose: 6000,
      });
      return;
    }
    // Scheduling max qty
    if (schedulingMaxQtyInputValue === '') {
      toast.error(
        'Não foi definida a quantidade máxima de agendamentos por horário.',
        {
          autoClose: 6000,
        }
      );
      return;
    }
    if (parseInt(schedulingMaxQtyInputValue, 10) === parseInt(0, 10)) {
      toast.error(
        'A quantidade máxima de agendamentos por horário não pode ser zero.',
        {
          autoClose: 6000,
        }
      );
      return;
    }
    if (maxQtyAvailableSchedulesDays && (maxQtyAvailableSchedulesDaysInputValue === '')) {
      toast.error(
        'Não foi definida a quantidade de dias para agendamentos que vão aparecer para o cliente.',
        {
          autoClose: 6000,
        }
      );
      return;
    }

    dispatch(
      updateStoreSchedulingAvailabilityRequest(
        ...updateStoreAvailabilityArgs,
        schedulingMinTimeInputValue,
        schedulingMaxQtyInputValue,
        maxQtyAvailableSchedulesDaysInputValue,
        interval
      )
    );
  }
  
  return (
    <ConfigGroup isStep={props.isStep} isActive={props.isActive}>
      <StoreForm onSubmit={handleStoreSchedulingSubmit}>
        <ConfigGroupContent backgroundColor={props.backgroundColor}>
          <Row>
            <Row justifyStart>
              <ConfigGroupTitle>
                Defina as configurações de agendamento
              </ConfigGroupTitle>
            </Row>
            <SaveButton
              id="myStoreSchedulingAvailabilityHorariesSaveButton"
              hasChanges={isSaveButtonActive()}
              type="submit"
              disabled={isSaveButtonDisabled()}
            >
              {schedulingLoading ? (
                <LoadingBox>
                  <Spinner
                    style={{
                      width: '1.8rem',
                      height: '1.8rem',
                      color: preto,
                    }}
                  />{' '}
                </LoadingBox>
              ) : (
                <SaveButtonText hasChanges={isSaveButtonActive()}>
                  Salvar
                </SaveButtonText>
              )}
            </SaveButton>
          </Row>
          <Row>
            <Col
              id="myStoreSchedulingAvailabilityHoraries"
              margleft={px2vw(10)}
              margbottom={px2vw(3)}
            >
              {weekDays.map((day) => (
                <SwitchWithInput key={day.name} marginBottom={px2vw(5)}>
                  <FormControlLabel
                    control={
                      <SwitchController
                        checked={day.is_active}
                        type="checkbox"
                        onChange={() => handleToggleDayActive(day.name)}
                      />
                    }
                    label={
                      <SwitchLabelRegularText>
                        {translateDayName(day.name)}
                      </SwitchLabelRegularText>
                    }
                  />
                  <SwitchWithInputBox isVisible={day.is_active}>
                    <SwitchLabelRegularText>
                      <SwitchInput
                        name={`${day.name}StartTime`}
                        maxLength="5"
                        placeholder="11:00"
                        value={day.start_time}
                        onChange={(t) => {
                          handleOnChangeDayInput({
                            dayName: day.name,
                            startTime: timeMask(t.target.value),
                          });
                        }}
                      />
                      às
                      <SwitchInput
                        name={`${day.name}EndTime`}
                        maxLength="5"
                        placeholder="11:00"
                        value={day.end_time}
                        onChange={(t) => {
                          handleOnChangeDayInput({
                            dayName: day.name,
                            endTime: timeMask(t.target.value),
                          });
                        }}
                      />
                    </SwitchLabelRegularText>
                  </SwitchWithInputBox>
                </SwitchWithInput>
              ))}
            </Col>
            <Col margtop={px2vw(50)}>
              {/* Min time do schedule */}
              <Row>
                <SwitchLabelRegularText width={px2vw(350)} margtop={px2vw(9)}>
                  Tempo mínimo que o seu cliente<br />terá para agendar o pedido
                </SwitchLabelRegularText>
                <Row alignCenter justifyStart margtop={px2vw(9)}>
                  <SelectInputBox
                    value={schedulingMinDaysInputValue}
                    name="schedulingMinDays"
                    options={schedulingMinDaysOptions}
                    onChange={(t) => setSchedulingMinTime('days', t.target.value)}
                  />
                  <SwitchLabelRegularTextBefore margleft={px2vw(5)}>
                    dia(s)
                  </SwitchLabelRegularTextBefore>
                  <SelectInputBox
                    value={schedulingMinHoursInputValue}
                    name="schedulingMinHours"
                    options={schedulingMinHoursOptions}
                    margleft={px2vw(15)}
                    onChange={(t) => setSchedulingMinTime('hours', t.target.value)}
                  />
                  <SwitchLabelRegularTextBefore margleft={px2vw(5)}>
                    hora(s)
                  </SwitchLabelRegularTextBefore>
                  <SelectInputBox
                    value={schedulingMinMinutesInputValue}
                    name="schedulingMinMinutes"
                    options={schedulingMinMinutesOptions}
                    margleft={px2vw(15)}
                    onChange={(t) => setSchedulingMinTime('minutes', t.target.value)}
                  />
                  <SwitchLabelRegularTextBefore margleft={px2vw(5)}>
                    minuto(s)
                  </SwitchLabelRegularTextBefore>
                </Row>
              </Row>
              {/* Schedules interval */}
              <Col margtop={px2vw(20)} margbottom={px2vw(20)}>
                <SwitchLabelRegularText width={px2vw(400)}>
                  Intervalo de horários disponíveis dentro dos dias selecionados
                </SwitchLabelRegularText>
                <Row justifyStart margleft={px2vw(10)} margtop={px2vw(10)}>
                  <IntervalOption
                    type="button"
                    isChecked={interval === 30}
                    onClick={() => handleSetInterval(30)}
                  >
                    <SwitchLabelRegularText isChecked={interval === 30}>
                      30 em 30 min
                    </SwitchLabelRegularText>
                  </IntervalOption>
                  <IntervalOption
                    type="button"
                    isChecked={interval === 60}
                    onClick={() => handleSetInterval(60)}
                  >
                    <SwitchLabelRegularText isChecked={interval === 60}>
                      1 em 1 hora
                    </SwitchLabelRegularText>
                  </IntervalOption>
                  <IntervalOption
                    type="button"
                    isChecked={interval === 120}
                    onClick={() => handleSetInterval(120)}
                  >
                    <SwitchLabelRegularText isChecked={interval === 120}>
                      2 em 2 horas
                    </SwitchLabelRegularText>
                  </IntervalOption>
                </Row>
                <Row justifyStart margleft={px2vw(10)} margtop={px2vw(5)}>
                  <IntervalOption
                    type="button"
                    isChecked={interval === 180}
                    onClick={() => handleSetInterval(180)}
                  >
                    <SwitchLabelRegularText isChecked={interval === 180}>
                      3 em 3 horas
                    </SwitchLabelRegularText>
                  </IntervalOption>
                  <IntervalOption
                    type="button"
                    isChecked={interval === 360}
                    onClick={() => handleSetInterval(360)}
                  >
                    <SwitchLabelRegularText isChecked={interval === 360}>
                      6 em 6 horas
                    </SwitchLabelRegularText>
                  </IntervalOption>
                </Row>
              </Col>
              {/* Max schedules per time */}
              <Row margbottom={px2vw(20)}>
                <SwitchLabelRegularText width={px2vw(400)} margtop={px2vw(9)}>
                  Quantidade máxima de agendamentos<br />por horário
                </SwitchLabelRegularText>
                <Row alignCenter justifyStart margtop={px2vw(9)}>
                  <SwitchInput
                    width={px2vw(50)}
                    name="schedulingMaxQty"
                    placeholder="30"
                    maxLength="2"
                    value={schedulingMaxQtyInputValue}
                    onChange={(t) => {
                      onChanges();
                      setSchedulingMaxQtyInputValue(
                        onlyNumbers(t.target.value)
                      );
                    }}
                  />
                </Row>
              </Row>
              {/* Max qty of available schedules days */}
              <SwitchWithInput margleft={px2vw(9)}>
                <FormControlLabel
                  control={
                    <SwitchController
                      type="checkbox"
                      checked={maxQtyAvailableSchedulesDays}
                      onChange={(t) => {
                        onChanges();
                        if (maxQtyAvailableSchedulesDays) {
                          setMaxQtyAvailableSchedulesDaysInputValue('10');
                        }
                        setMaxQtyAvailableSchedulesDays(!maxQtyAvailableSchedulesDays);
                      }}
                    />
                  }
                  label={
                    <SwitchLabelRegularText
                      width={px2vw(280)}
                    >
                      Definir a quantidade de dias para agendamento <br />que vão aparecer para o cliente. Caso não seja <br />informado, serão exibidos 10 dias
                    </SwitchLabelRegularText>
                  }
                />
                <SwitchWithInputBox isVisible={maxQtyAvailableSchedulesDays}>
                  <SwitchLabelRegularText>
                    <SwitchInput
                      width={px2vw(50)}
                      name="schedulingAvailableMaxQty"
                      maxLength="2"
                      placeholder="10"
                      value={maxQtyAvailableSchedulesDays && maxQtyAvailableSchedulesDaysInputValue}
                      onChange={(t) => {
                        onChanges();
                        setMaxQtyAvailableSchedulesDaysInputValue(onlyNumbers(t.target.value));
                      }}
                    />
                  </SwitchLabelRegularText>
                </SwitchWithInputBox>
              </SwitchWithInput>
            </Col>
          </Row>
        </ConfigGroupContent>
      </StoreForm>
    </ConfigGroup>
  );
}

export default StoreScheduling;
