import produce from 'immer';

const INITIAL_STATE = {
  importIfoodCatalogModalSuccess: null,
};

export default function importIfood(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@importIfood/IMPORT_IFOOD_CATALOG_SUCCESS': {
        draft.importIfoodCatalogModalSuccess = true;
        break;
      }
      case '@importIfood/IMPORT_IFOOD_CATALOG_FAILURE': {
        draft.importIfoodCatalogModalSuccess = false;
        break;
      }
      case '@importIfood/IMPORT_IFOOD_CATALOG_CLEAR_SUCCESS': {
        draft.importIfoodCatalogModalSuccess = null;
        break;
      }
      default:
    }
  });
}
