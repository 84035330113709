import React, { useEffect, useState } from 'react';
import {
  BannerContainer,
  ContentPage,
  ImgBox,
  ImgIcon,
  InvisibleVector,
  PageTitle,
  TextContent,
  Vector,
} from './styles';
import { Col, Row } from '../../styles/grid';
import { Tab, TabBox, TabSelected, TabsBox } from '../MyStore/styles';
import px2vw from '../../utils/responsiveness/px2vw';
import AccountDetails from './AccountDetails';
import Transfers from './Transfers';
import { useDispatch, useSelector } from 'react-redux';
import { getFinancialStatusRequest } from '../../store/modules/financial/actions';
import { preto } from '../../styles/colors';
import { Spinner } from 'reactstrap';

export default function Financial() {
  const [currTabSelected, setCurrTabSelected] = useState('tabAccountDetails');
  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();
  const { storeData, storeDataLoading } = useSelector(
    (state) => state.financial
  );

  function handleCurrTabSelected(tabSelected) {
    switch (tabSelected) {
      case 'tabAccountDetails':
        setCurrTabSelected('tabAccountDetails');
        break;
      case 'tabFinancialDetails':
        setCurrTabSelected('tabFinancialDetails');
      default:
    }
  }

  useEffect(() => {
    dispatch(getFinancialStatusRequest());
  }, []);

  const loadingPage = (
    <Col margleft="20px" height="50vh">
      <Row alignCenter justifyCenter>
        <Spinner
          style={{
            width: '1.4rem',
            height: '1.4rem',
            color: preto,
          }}
        />
      </Row>
    </Col>
  );

  return (
    <ContentPage>
      <PageTitle>Financeiro</PageTitle>

      <BannerContainer>
        <PageTitle isSubTitle>Pix Automatizado</PageTitle>
        <TextContent>
          Com o Pix Automatizado você não precisa se preocupar em conferir
          nenhum comprovante, nosso robô fará tudo para você! Nesse formato,
          você terá uma taxa de 1,47% por pedido pago e o valor será repassado em
          apenas 1 dia útil.
        </TextContent>

        <Row>
          <InvisibleVector />
          <Vector />
          <ImgBox>
            <ImgIcon
              src={require('../../assets/images/financial/pix_simbol.png')}
            />
          </ImgBox>
        </Row>
      </BannerContainer>

      {storeDataLoading ? (
        loadingPage
      ) : (
        <TabsBox margleft={px2vw(-30)}>
          <TabBox>
            <Tab onClick={() => handleCurrTabSelected('tabAccountDetails')}>
              Dados da Conta
            </Tab>
            <TabSelected
              isVisible={currTabSelected === 'tabAccountDetails'}
              width="auto"
            />
          </TabBox>
          {['active', 'enabled'].includes(storeData.status) && (
            <TabBox>
              <Tab onClick={() => handleCurrTabSelected('tabFinancialDetails')}>
                Repasses do Período
              </Tab>
              <TabSelected
                isVisible={currTabSelected === 'tabFinancialDetails'}
                width="auto"
              />
            </TabBox>
          )}
        </TabsBox>
      )}
      {currTabSelected === 'tabAccountDetails' && !storeDataLoading && (
        <AccountDetails />
      )}
      {currTabSelected === 'tabFinancialDetails' && !storeDataLoading && (
        <Transfers />
      )}
    </ContentPage>
  );
}
