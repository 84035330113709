import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  Bar,
  ChatBarTab,
  ChatList,
  EmptyContacts,
  SearchEmptyContactsObs,
  SearchInput,
  SearchInputClearStyle,
  SearchInputStyle,
  SelectedTab,
  Span,
  LoadMore,
} from './style';
import { cinzaKuppi1, cinzaKuppi8, preto } from '../../../styles/colors';

import { Col, Row } from '../../../styles/grid';

import px2vw from '../../../utils/responsiveness/px2vw';
import { BiSearchAlt2 } from 'react-icons/bi';
import { IoClose } from 'react-icons/io5';

import { useDispatch, useSelector } from 'react-redux';
import {
  clearMessageCount,
  getAllActiveOrdersData,
  getAllClientsData,
  removeWaitAttendantList,
  setSelectedConversation,
} from '../../../store/modules/whatsappChat/actions';
import { useCallback } from 'react';

import { toast } from 'react-toastify';
import { getClientName } from '../../../utils/getClientName';
import { Spinner } from 'reactstrap';
import { ChatBarItem } from './Item';
import { WorkerSocket } from '../../../context/ws';

export default function ChatBar() {
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState('allChats');
  const [inputSearchData, setInputSearchData] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const loadMoreRef = useRef(null);
  const barRef = useRef(null);
  const conversationsPerPage = 10;


  const { allClientsData, isLoadingActiveOrders, selectedConversation } =
    useSelector((state) => state.whatsappChat);
  const { conversations, chatBarItems, isHistorySyncComplete } = useSelector(
    (state) => state.whatsappChatConversations
  );

  const [hasActiveOrders, setHasActiveOrders] = useState(false);

  const getOrderStatusTag = useCallback(
    (phone) => {
      const client = allClientsData.find((client) => client.phone === phone);
      if (!client || !client.active_orders.length) {
        return false;
      }

      return client.active_orders[0].status;
    },
    [allClientsData, currentPage]
  );

  const chatList = useMemo(() => {
    const sortChatList = (a, b) => {
      const filteredAConversations = conversations[a].filter(
        (c) => c.status !== 'MASS_MESSAGE_SENT'
      );
      const filteredBConversations = conversations[b].filter(
        (c) => c.status !== 'MASS_MESSAGE_SENT'
      );

      const aLastMessageIndex =
        filteredAConversations.length === 0
          ? 0
          : filteredAConversations.length - 1;
      const bLastMessageIndex =
        filteredBConversations.length === 0
          ? 0
          : filteredBConversations.length - 1;

      const aValue = filteredAConversations[aLastMessageIndex];
      const bValue = filteredBConversations[bLastMessageIndex];

      return bValue?.messageTimestamp - aValue?.messageTimestamp;
    };

    const searchFilter = (key) => {
      const messages = [...conversations[key]];
      const sortMessage = messages.sort((m1, m2) => {
        return m1.messageTimestamp - m2.messageTimestamp;
      });

      const pushname = getClientName(key, messages);
      const exibitionName =
        pushname?.length > 18
          ? pushname.slice(0, 21).replace('\\n', ' ') + '...'
          : pushname;

      const lastMessage =
        sortMessage[sortMessage.length === 0 ? 0 : sortMessage.length - 1];

      if (
        inputSearchData &&
        !lastMessage?.message?.conversation
          ?.toLowerCase()
          .includes(inputSearchData.toLowerCase()) &&
        !exibitionName.toLowerCase().includes(inputSearchData.toLowerCase()) &&
        !key.includes(inputSearchData)
      ) {
        return false;
      }
      return true;
    };

    const paginateConversationsFilter = (phone, i) => {
      const statusTag = getOrderStatusTag(phone);

      if (selectedTab === 'deliveryOptions' && !statusTag) {
        return false;
      }

      if (selectedTab === 'deliveryOptions' && statusTag) {
        return true;
      }

      const clientName = getClientName(phone, conversations[phone]);
      return (
        i < currentPage * conversationsPerPage ||
        (inputSearchData.length > 0 &&
          (phone?.includes(inputSearchData) ||
            clientName?.toLowerCase().includes(inputSearchData)))
      );
    };

    return (
      (
        Object.keys(conversations)
          .filter((c) => c.status !== 'MASS_MESSAGE_SENT')
          .sort(sortChatList) || []
      )
        .filter(paginateConversationsFilter)
        .filter(searchFilter) || []
    );
  }, [conversations, selectedTab, currentPage, inputSearchData]);

  useEffect(() => {
    const options = {
      root: barRef.current,
      rootMargin: '0px',
      threshold: 0.5,
    };

    const observer = new IntersectionObserver((entities) => {
      const target = entities[0];
      if (target.isIntersecting) {
        setCurrentPage((old) => {
          return old + 1;
        });
      }
    }, options);

    if (loadMoreRef.current) {
      observer.observe(loadMoreRef.current);
    }
  }, [loadMoreRef]);

  useEffect(() => {
    const nActiveOrder = allClientsData.find(
      (item) => item.active_orders?.length > 0
    );
    if (nActiveOrder) {
      setHasActiveOrders(true);
    } else {
      setHasActiveOrders(false);
    }
  }, [allClientsData]);

  useEffect(() => {
    let timeout;
    if (chatList.length > 0 && allClientsData.length === 0) {
      timeout = setTimeout(() => dispatch(getAllClientsData(chatList)), 3000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [chatList]);

  useEffect(() => {
    const phonesWithTags = (allClientsData || []).map((item) => item.phone);
    const phonesWithoutTags = (chatList || []).filter(
      (phone) => !phonesWithTags.includes(phone)
    );

    if (!chatList.length || phonesWithoutTags.length === 0) return;

    const phonesToFetch = [...new Set([...phonesWithoutTags])];

    dispatch(getAllClientsData(phonesToFetch));
  }, [currentPage, inputSearchData]);

  const isWhatsappwebPage = window.location.pathname.includes('whatsappchat');

  useEffect(() => {
    if (selectedConversation.id.trim() !== '') {
      handleOnClickChatlist(selectedConversation.id, selectedConversation.name);
    }
  }, []);

  const handleOnClickChatlist = (phone, name) => {
    const index = 0;
    dispatch(setSelectedConversation({ id: phone, index, name }));
    dispatch(clearMessageCount(phone));
    dispatch(removeWaitAttendantList(phone));
    toast.dismiss(`callAttenddant-${phone}`);
  };

  return (
    <Bar
      ref={barRef}
      isWhatsappwebPage={isWhatsappwebPage}
      relative
      justifyStart
    >
      <Row
        borderbottom={`${px2vw(2)} solid ${cinzaKuppi8}`}
        height={px2vw(60)}
        width="100%"
        borderRight={`${px2vw(6)} solid ${cinzaKuppi8};`}
      >
        <ChatBarTab
          onClick={() => {
            setSelectedTab('allChats');
            setHasActiveOrders(false);
            setInputSearchData('');
          }}
        >
          Todas as conversas
          {selectedTab === 'allChats' && <SelectedTab />}
        </ChatBarTab>
        <Row
          borderLeft={`${px2vw(2)} solid ${cinzaKuppi1}`}
          width={px2vw(2)}
          height="100%"
        ></Row>
        <ChatBarTab
          onClick={() => {
            dispatch(getAllActiveOrdersData());
            setSelectedTab('deliveryOptions');
            setInputSearchData('');
          }}
        >
          Operação Delivery
          {selectedTab === 'deliveryOptions' && <SelectedTab />}
        </ChatBarTab>
      </Row>

      <Col
        justifyStart
        borderbottom={`${px2vw(2)} solid ${cinzaKuppi8}`}
        borderRight={`${px2vw(6)} solid ${cinzaKuppi8};`}
        minheight={px2vw(90)}
        maxheight={px2vw(90)}
        width="100%"
      >
        <Span className="mx-auto" pt={px2vw(13)} pb={px2vw(5)}>
          {selectedTab === 'allChats'
            ? 'Nessa visão, você visualiza todas as conversas do whatsapp.'
            : 'Nessa visão, você visualiza apenas conversas com pedido ativo.'}
        </Span>
        <Row alignCenter relative height="auto" width="90%" className="mx-auto">
          <BiSearchAlt2 style={SearchInputStyle} />
          <SearchInput
            value={inputSearchData}
            onChange={(e) => {
              setInputSearchData(e.target.value);
            }}
            placeholder="Pesquisar uma conversa"
          />
          {inputSearchData.length > 0 && (
            <IoClose
              onClick={() => setInputSearchData('')}
              style={SearchInputClearStyle}
            />
          )}
        </Row>
        {!isHistorySyncComplete && (
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              fontSize: px2vw(10),
              fontWeight: 'bold',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            Sincronizando conversas
          </div>
        )}
      </Col>
      <ChatList>
        {(chatList || []).map((phone) => {
          return (
            <ChatBarItem
              key={phone}
              phone={phone}
              message={chatBarItems[phone]}
              currentPage={currentPage}
              pushname={getClientName(phone, conversations[phone])}
            />
          );
        })}

        {chatList.length === 0 && inputSearchData.length > 0 && (
          <Col height="auto">
            <EmptyContacts>
              Nenhuma conversa, contato ou mensagem encontrada.
            </EmptyContacts>

            <SearchEmptyContactsObs>
              Use o Whatsapp no seu celular para ver conversas e mensagens mais
              antigas.
            </SearchEmptyContactsObs>
          </Col>
        )}

        {!hasActiveOrders &&
          selectedTab === 'deliveryOptions' &&
          !isLoadingActiveOrders && (
            <Col height="auto">
              <EmptyContacts>Nenhum pedido ativo.</EmptyContacts>
            </Col>
          )}

        {isLoadingActiveOrders && selectedTab === 'deliveryOptions' && (
          <Spinner
            style={{
              width: px2vw(15),
              height: px2vw(15),
              color: preto,
            }}
            className="ml-auto mr-auto mt-4"
          />
        )}

        <LoadMore ref={loadMoreRef}>
          <div
            style={{
              height: '20px',
              padding: '20px',
            }}
          >
            {((!isLoadingActiveOrders && selectedTab === 'deliveryOptions') ||
              selectedTab === 'allChats') &&
              chatList.length < Object.keys(conversations).length &&
              chatList.length > 10 && (
                <Spinner
                  style={{
                    width: '1rem',
                    height: '1rem',
                    color: preto,
                  }}
                />
              )}
          </div>
        </LoadMore>
      </ChatList>
    </Bar>
  );
}
