import { takeLatest, call, put, all, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { apiAnunciante } from '../../../services/api';

import {
  getOrdersQtySuccess,
  getOrdersSuccess,
  getOrdersPaginatedSuccess,
  getOrdersFailure,
  updateOrderStatusSuccess,
  updateOrderStatusFailure,
  cancelOrderSuccess,
  cancelOrderFailure,
  getOrdersWithoutLoaderSuccess,
  getOrdersWithoutLoaderFailure,
  changeOrdersConfigsSuccess,
  changeOrdersConfigsFailure,
  changeAcceptingOrdersSuccess,
  changeAcceptingOrdersFailure,
  getSearchedOrderSuccess,
  getSearchedOrderFailure,
  updatePixKeyPaymentSuccess,
  updatePixKeyPaymentFailure,
  updateOrderPaymentMethodSuccess,
  updateOrderPaymentMethodFailure,
  createTestOrderSuccess,
  createTestOrderFailure,
  updateMultipleOrdersStatusesSuccess,
  updateMultipleOrdersStatusesFailure,
  updateOrdersArrays,
  getOrderDetailSuccess,
  getOrderDetailFailure
} from './actions';
import { updateUserInfoSuccess } from '../user/actions';
import { logout } from '../auth/actions';

import { sendOrdersFunnel } from '../../../services/vendors/mixpanel';
import { getAllClientsData } from '../whatsappChat/actions';
import { getChallengesStatusRequest } from '../challenges/actions';
import history from '../../../services/history';
import { orders } from '../../../routes/routeMap';
import { formatArrayAsObject } from '../../../utils/utils';
import { formatOrdersComplements } from '../../../utils/ordersConfigs/ordersConfigs';

const userReducerData = (state) => state.user;
const userData = (state) => state.user.user;
const ordersData = (state) => state.orders;

export function* getOrdersRequest({ payload }) {
  try {
    const user = yield select(userData);

    const response = yield call(
      apiAnunciante.get,
      `/user-info/${user.advertiserInfo.id}/orders/${payload.status}`
    );

    const ordersData = response.data.orders_data.data;
    const ordersPaginationData = {
      total: response.data.orders_data.total,
      perPage: response.data.orders_data.perPage,
      page: response.data.orders_data.page,
      lastPage: response.data.orders_data.lastPage,
    };

    yield put(
      getOrdersSuccess(payload.status, ordersData, ordersPaginationData, response.data.orders_qty)
    );
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(getOrdersFailure(payload.status));
    }
  }
}

export function* getOrdersWithoutLoaderRequest({ payload }) {
  try {
    const user = yield select(userData);

    const response = yield call(
      apiAnunciante.get,
      `/user-info/${user.advertiserInfo.id}/orders/${payload.status}`
    );

    const ordersData = response.data.orders_data.data;
    const ordersPaginationData = {
      total: response.data.orders_data.total,
      perPage: response.data.orders_data.perPage,
      page: response.data.orders_data.page,
      lastPage: response.data.orders_data.lastPage,
    };

    yield put(
      getOrdersWithoutLoaderSuccess(
        payload.status,
        ordersData,
        ordersPaginationData,
        response.data.orders_qty
      )
    );
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(getOrdersWithoutLoaderFailure());
    }
  }
}

export function* getOrdersPaginatedRequest({ payload }) {
  try {
    const user = yield select(userData);

    const response = yield call(
      apiAnunciante.get,
      `/user-info/${user.advertiserInfo.id}/orders/${payload.status}?page=${payload.paginationPage}`
    );

    const ordersData = response.data.orders_data.data;
    const ordersPaginationData = {
      total: response.data.orders_data.total,
      perPage: response.data.orders_data.perPage,
      page: response.data.orders_data.page,
      lastPage: response.data.orders_data.lastPage,
    };

    yield put(
      getOrdersPaginatedSuccess(
        payload.status,
        ordersData,
        ordersPaginationData,
        response.data.orders_qty
      )
    );
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(getOrdersFailure(payload.status));
    }
  }
}

export function* getOrderDetailRequest({ payload }) {
  try {
    const user = yield select(userData);

    const response = yield call(
      apiAnunciante.get,
      `/user-info/${user.advertiserInfo.id}/order/${payload.orderId}`
    );

    const formattedOrders = formatOrdersComplements(response.data)

    yield put(getOrderDetailSuccess(formattedOrders[0]));
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      if (err.response?.status === 401) {
        yield put(logout(true));
      } else {
        toast.error('Ocorreu um erro ao buscar os dados do pedido.', {
          autoClose: 6000,
        });
        yield put(getOrderDetailFailure());
      }
    }
  }
}

export function* updateOrderStatusRequest({ payload }) {
  try {
    const user = yield select(userData);
    const userReducer = yield select(userReducerData);
    const orderData = payload.orderData;

    const response = yield call(
      apiAnunciante.put,
      `/user-info/${user.advertiserInfo.id}/order/${orderData.order_id}/status`,
      {
        status: payload.newStatus,
        order_type: orderData.order.order_type,
      }
    );

    yield put(updateOrdersArrays([{
      ...orderData, 
      ...response.data.orders_data
    }], response.data.orders_qty));
  
    if (payload.newStatus === 'canceled') {
      yield put(cancelOrderSuccess());
    } else {
      yield put(updateOrderStatusSuccess());
    }
    // Get the tags data of all conversations in whatsapp
    yield put(getAllClientsData([orderData.order.clientInfo.phone]))
    //MixPanel Event
    sendOrdersFunnel(`Orders Funnel - ${payload.newStatus}`, {
      id: user.advertiserInfo.id,
      email: user.email,
      clientName: orderData.order.clientInfo.name,
      clientId: orderData.order.clientInfo.id,
      advertiserId: user.advertiserInfo.id,
      orderId: orderData.order.id,
      status: orderData.status,
      createdAt: orderData.new_at,
      acceptedAt: orderData.accepted_at,
      canceledAt: orderData.canceled_at,
      deliveryAt: orderData.delivery_at,
      finishedAt: orderData.finished_at,
      preparingAt: orderData.preparing_at,
      readyTakeAwayAt: orderData.ready_take_away_at,
      orderType: orderData.order.order_type,
      deliveryType: orderData.order.delivery_type,
      hasFreeTrialValid: userReducer.hasFreeTrialValid,
    });
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      if (payload.newStatus === 'canceled') {
        yield put(cancelOrderFailure());
      } else {
        yield put(updateOrderStatusFailure());
      }
      if (err?.response?.data?.error?.message) {
        toast.error(err.response.data.error.message, {
          autoClose: 6000,
        });
      } else {
        toast.error(
          `Ocorreu um erro ao atualizar o status do pedido. Tente novamente!`,
          {
            autoClose: 6000,
          }
        );
      }
    }
  }
}

export function* changeOrdersConfigsRequest({ payload }) {
  try {
    const user = yield select(userData);

    let { maxCharLine } = payload;
    if (payload.maxCharLine < 36) {
      maxCharLine = 47;
    }

    const userResponse = yield call(
      apiAnunciante.put,
      `/user-info/${user.advertiserInfo.id}/orders-configs`,
      {
        print_type: payload.printType,
        max_line_chars: payload.printType === 'html' ? '47' : maxCharLine,
        is_send_whatsapp_msg_update_status: payload.sendWhatsappMsgUpdateStatus,
        is_print_order_invoice_accept_order:
          payload.printOrderInvoiceAcceptOrder,
        is_using_chatbot: payload.isUsingChatbot,
        print_method: payload.printMethod,
        printer_name: payload.printerName,
        max_line_chars_automatic: payload.maxLineCharsAutomatic,
      }
    );
    // Save in redux the user info data
    yield put(updateUserInfoSuccess(userResponse.data));
    yield put(changeOrdersConfigsSuccess());
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(changeOrdersConfigsFailure());
      toast.error(
        `Ocorreu um erro ao atualizar as configurações. Tente novamente!`,
        {
          autoClose: 6000,
        }
      );
    }
  }
}

export function* changeAcceptingOrdersRequest({ payload }) {
  try {
    const user = yield select(userData);

    // Update user info data
    const response = yield call(
      apiAnunciante.put,
      `/user-info/${user.advertiserInfo.id}/orders-configs`,
      {
        is_accepting_immediate_order: payload.acceptingImmediate,
        is_accepting_scheduled_order: payload.acceptingScheduled,
      }
    );

    // Save in redux the user info data
    yield put(changeAcceptingOrdersSuccess(response.data));
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      toast.error(
        'Ocorreu um erro ao alterar o status de recebimento de pedidos. Tente novamente!',
        {
          autoClose: 6000,
        }
      );
      yield put(changeAcceptingOrdersFailure());
    }
  }
}

export function* getSearchedOrderRequest({ payload }) {
  try {
    const user = yield select(userData);

    const response = yield call(
      apiAnunciante.get,
      `/user-info/${user.advertiserInfo.id}/order/${payload.orderId}`
    );

    const formattedOrders = formatOrdersComplements(response.data)

    yield put(getSearchedOrderSuccess(formattedOrders));
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      if (err.response?.status === 401) {
        yield put(logout(true));
      } else {
        toast.error('Não foi encontrado pedido com o número digitado.', {
          autoClose: 6000,
        });
        yield put(getSearchedOrderFailure());
      }
    }
  }
}

export function* getClientOrdersData({ payload }) {
  try {
    const user = yield select(userData);

    const response = yield call(
      apiAnunciante.post,
      `/user-info/${user.advertiserInfo.id}/orders?conversation_id=${payload.conversationId}`, {
        order_ids: payload.ordersIds
      }
    );

    const formattedOrders = formatOrdersComplements(response.data)

    yield put(getSearchedOrderSuccess(formattedOrders));
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      if (err.response?.status === 401) {
        yield put(logout(true));
      } else {
        toast.error('Não foi encontrado pedido com o número digitado.', {
          autoClose: 6000,
        });
        yield put(getSearchedOrderFailure());
      }
    }
  }
}

export function* updatePixKeyPaymentRequest({ payload }) {
  try {
    const user = yield select(userData);
    yield call(
      apiAnunciante.put,
      `/user-info/${user.advertiserInfo.id}/order/${payload.orderPaymentData.order_id}/update-pix-key-status`,
      {
        is_confirmed: payload.orderPaymentData.paid_at ? false : true,
      }
    );

    // Save in redux the user info data
    yield put(updatePixKeyPaymentSuccess());
    toast.success('A confirmação do pagamento foi atualizada!');
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(updatePixKeyPaymentFailure());
      toast.error(`Ocorreu um erro ao atualizar a confirmação do pagamento!`, {
        autoClose: 6000,
      });
    }
  }
}

export function* updateOrderPaymentMethodRequest({ payload }) {
  try {
    const user = yield select(userData);
    const response = yield call(
      apiAnunciante.put,
      `/user-info/${user.advertiserInfo.id}/order/${payload.orderId}/update-order-payment-method`,
      {
        new_payment_method_id: payload.newOrderPMId,
        older_payment_method_id: payload.olderOrderPMId,
      }
    );

    yield put(updateOrderPaymentMethodSuccess(response.data[0]));
    toast.success('A forma de pagamento foi atualizada');
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(updateOrderPaymentMethodFailure());
      toast.error(
        `Ocorreu um erro ao atualizar a forma de pagamento pagamento!`,
        {
          autoClose: 6000,
        }
      );
    }
  }
}

export function* createTestOrderRequest() {
  try {
    const user = yield select(userData);

    yield call(
      apiAnunciante.post,
      `/user-info/${user.advertiserInfo.id}/orders/create-test-order`,
      {
        test_order_qty: 2,
      }
    );

    yield put(createTestOrderSuccess());
    toast.success('Pedidos de teste gerados com sucesso!');
    history.push(orders)
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      toast.error('Erro ao criar pedidos testes. Tente novamente e caso persista contate o suporte.', {
        autoClose: 6000,
      });
      yield put(createTestOrderFailure());
    }
  }
}

export function* updateMultipleOrdersStatusesRequest({ payload }) {
  try {
    const user = yield select(userData);
    const isCompletedModalClosed = yield select((state) => state.challenges.challenges.is_completed_modal_closed);
    const response = yield call(
      apiAnunciante.post,
      `/user-info/${user.advertiserInfo.id}/orders/mass-actions`,
      {
        orders_ids: payload.idOrderslist,
        cancel_order: payload.cancelOrder
      }
    );

    // Get the tags data of all conversations in whatsapp
    const { newAccepted, preparing, deliveringReadyTakeAway, finished } = yield select(ordersData);
    const orderStatuses = [...Object.values(newAccepted), ...Object.values(preparing), ...Object.values(deliveringReadyTakeAway), ...Object.values(finished)].filter((orderStatus) => payload.idOrderslist.includes(orderStatus.order_id))
    yield put(getAllClientsData(orderStatuses.map((orderStatus) => orderStatus.order.clientInfo.phone)))

    // Included updated order data to the local reducer
    let orderStatusesObj = formatArrayAsObject(orderStatuses, 'order_id');
    response.data.orders_data.forEach((newOrderData) => {
      orderStatusesObj[newOrderData.order_id] = { ...orderStatusesObj[newOrderData.order_id], ...newOrderData}
    })

    yield put(updateOrdersArrays(Object.values(orderStatusesObj), response.data.orders_qty));

    yield put(updateMultipleOrdersStatusesSuccess());

    if (!isCompletedModalClosed) {
      yield put(getChallengesStatusRequest());
    }
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(updateMultipleOrdersStatusesFailure());
      toast.error(`Ocorreu um erro ao atualizar o status dos pedidos selecionados!`, {
        autoClose: 6000,
      });
    }
  }
}

export default all([
  takeLatest('@orders/GET_ORDERS_REQUEST', getOrdersRequest),
  takeLatest(
    '@orders/GET_ORDERS_WITHOUT_LOADER_REQUEST',
    getOrdersWithoutLoaderRequest
  ),
  takeLatest('@orders/GET_ORDERS_PAGINATED_REQUEST', getOrdersPaginatedRequest),
  takeLatest('@orders/GET_ORDER_DETAIL_REQUEST', getOrderDetailRequest),
  takeLatest('@orders/UPDATE_ORDER_STATUS_REQUEST', updateOrderStatusRequest),
  takeLatest('@orders/CANCEL_ORDER_REQUEST', updateOrderStatusRequest),
  takeLatest(
    '@orders/CHANGE_ORDERS_CONFIGS_REQUEST',
    changeOrdersConfigsRequest
  ),
  takeLatest(
    '@orders/CHANGE_ACCEPTING_ORDERS_REQUEST',
    changeAcceptingOrdersRequest
  ),
  takeLatest('@orders/GET_SEARCHED_ORDER_REQUEST', getSearchedOrderRequest),
  takeLatest(
    '@orders/UPDATE_ORDER_PAYMENT_METHOD_REQUEST',
    updateOrderPaymentMethodRequest
  ),
  takeLatest(
    '@orders/UPDATE_PIX_KEY_STATUS_REQUEST',
    updatePixKeyPaymentRequest
  ),
  takeLatest(
    '@orders/CREATE_TEST_ORDER_REQUEST',
    createTestOrderRequest
  ),
  takeLatest(
    '@orders/GET_CLIENT_ORDERS_DATA',
    getClientOrdersData
  ),
  takeLatest(
    '@orders/UPDATE_MULTIPLE_ORDER_STATUSES_REQUEST',
    updateMultipleOrdersStatusesRequest
  ),

]);
