import * as Yup from 'yup';
import CpfCnpjValidator from '../../../utils/validations/CpfCnpjValidator';

export const AccountSchema = Yup.object().shape({
  accountFullName: Yup.string().required('Por favor, informe o nome completo associado a conta'),
  accountType: Yup.string().required("Por favor, informe o tipo da conta"),
  accountDigitNumber: Yup.string().required("Por favor, informe o dígito da conta"),
  accountNumber: Yup.string().required("Por favor, informe o número da conta"),
  agency: Yup.string().required("Por favor, informe a agência"),
  bank: Yup.string().required("Por favor, selecione o banco"),
  birthdate: Yup.date()
  .transform((curr, orig) => (orig === '' ? null : curr))
  .required('Por favor, informe a data de nascimento'),
  representativeCPF: Yup.string().required("Por favor, informe o CPF do responsável") .test('validade_cpf_cnpj', 'O CPF do responsável é inválido', CpfCnpjValidator),
  representativeFullName: Yup.string().required("Por favor, informe o nome completo associado ao CPF"),
});
