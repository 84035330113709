import { takeLatest, call, put, all, select } from 'redux-saga/effects';

import { apiAnunciante } from '../../../services/api';

import {
  getCashReportSuccess,
  getCashReportFailure,
  getPixReportSuccess,
  getPixReportFailure,
  getOrdersReportSuccess,
  getOrdersReportFailure,
} from './actions';
import {
  logout
} from '../auth/actions';

import { sendReportsData } from '../../../services/vendors/mixpanel';

const userReducerData = (state) => state.user;
const userData = (state) => state.user.user;

export function* getCashReportRequest({ payload }) {
  try {
    const user = yield select(userData);
    const userReducer = yield select(userReducerData);

    const response = yield call(
      apiAnunciante.post,
      `/user-info/${user.advertiserInfo.id}/reports/cash`,
      {
        start_date: payload.startDate,
        end_date: payload.endDate,
      }
    );

    //MixPanel Event
    sendReportsData({
      id: user.advertiserInfo.id,
      name: user.advertiserInfo.name,
      email: user.email,
      startDate: payload.startDate,
      endDate: payload.endDate,
      hasFreeTrialValid: userReducer.hasFreeTrialValid
    })
    yield put(getCashReportSuccess(response.data));
  } catch (err) {   
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(getCashReportFailure());
    }
  }
}

export function* getPixReportRequest({payload}){
  try {
    const user = yield select(userData);
    const response = yield call(
      apiAnunciante.post,
      `/user-info/${user.advertiserInfo.id}/reports/pix-key?page=${payload.page}`,
      {
        start_date: payload.startDate,
        end_date: payload.endDate,
      }
    );
    
    yield put(getPixReportSuccess(response.data));
  } catch(err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(getPixReportFailure());
    }
  }
}

export function* getOrdersReportRequest({ payload }) {
  try {
    const user = yield select(userData);
    const response = yield call(
      apiAnunciante.post,
      `/user-info/${user.advertiserInfo.id}/reports/orders`,
      {
        start_date: payload.startDate,
        end_date: payload.endDate,
      }
    );
    yield put(getOrdersReportSuccess(response.data));

  } catch(err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(getOrdersReportFailure());
    }
  }
}

export default all([
  takeLatest(
    '@reports/GET_CASH_REPORT_REQUEST',
    getCashReportRequest
  ),
  takeLatest(
    '@reports/GET_PIX_REPORT_REQUEST',
    getPixReportRequest
  ),
  takeLatest(
    '@reports/GET_ORDERS_REPORT_REQUEST',
    getOrdersReportRequest
  ),
]);
