import styled from 'styled-components';

import { branco, cinzaKuppi1 } from '../../styles/colors';

import px2vw from '../../utils/responsiveness/px2vw';

export const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;

`;

export const ChangeButton = styled.button`
  border: none !important;
  box-shadow: none !important;
  background: transparent !important;
  height: 30px;
  width: 30px;
  border-radius: 20px;

  ${(props) => props.visible && `background: ${branco} !important;`}
  ${(props) => `z-index: ${props.zi}`}
`;

export const ModalEdit = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 1;
  background: ${branco};
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  width: ${(props) => props.width && props.width};

  ${(props) => (props.visible ? 'display: inherit;' : 'display: none')};
`;

export const ButtonEdit = styled.button`
  width: ${(props) => props.width ? props.width : '100%'};
  padding: 8px 15px;
  border: none !important;
  box-shadow: none !important;
  background: transparent !important;
  font-size: ${px2vw(10)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.36px;

  :focus {
    background: transparent !important;
  }

  :hover {
    background: ${cinzaKuppi1} !important;
  }
`;
