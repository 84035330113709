import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createTestOrderRequest } from '../../../store/modules/orders/actions';
import { setManualOrderModalState } from '../../../store/modules/manualOrder/actions';
import { massMessage, whatsappbot } from '../../../routes/routeMap';
import history from '../../../services/history';

export const ChallengeBarContext = createContext({
  tasks: [],
  showChallengeDetails: false,
  setShowChallengeDetails: () => {},
  setShowMassMessageModal: () => {},
  showMassMessageModal: false,
  finishedActivation: false,
  setFinishedActivation: () => {},
  conditions: [false, false, false, false]
});

export const ChallengeBarProvider = ({ children, value }) => {
  const { showChallengeDetails, setShowChallengeDetails } = value;
  const createTestOrderLoading = useSelector(
    (state) => state.orders.createTestOrderLoading
  );

  const challenges = useSelector((state) => state.challenges.challenges);
  const [showMassMessageModal, setShowMassMessageModal] = useState(false);
  const [finishedActivation, setFinishedActivation] = useState(false);
  const dispatch = useDispatch();

  const tasks = useMemo(() => {
    const challengeCompleteConditions = challenges?.challenge?.completed_status;

    const hasActivatedWhatsappBot =
      challenges?.challenge_status?.whatsappbot_active;
    const hasFinishedTwoTestOrders =
      challenges?.challenge_status?.finished_orders_qty >=
      challengeCompleteConditions?.finished_orders_qty;
    const hasCreatedManualOrder =
      challenges?.challenge_status?.finished_manual_order_qty >=
      challengeCompleteConditions?.finished_manual_order_qty;
    const hasMassMessageCampaign =
      challenges?.challenge_status?.mass_message_campaign_qty >=
      challengeCompleteConditions?.mass_message_campaign_qty;

    return [
      {
        step: 1,
        rule: 'Ative o seu chatbot no Whatsapp',
        condition: hasActivatedWhatsappBot,
        onClick: () => {
          if (!hasActivatedWhatsappBot) {
            history.push(whatsappbot);
            setShowChallengeDetails(false);
          }
        },
      },
      {
        step: 2,
        rule: `Conclua os dois pedidos testes (${challenges?.challenge_status?.finished_orders_qty} de ${challengeCompleteConditions?.finished_orders_qty})`,
        condition: hasFinishedTwoTestOrders,
        loading: createTestOrderLoading,
        onClick: async () => {
          if (!hasFinishedTwoTestOrders) {
            dispatch(createTestOrderRequest());
            setShowChallengeDetails(false);
          }
        },
      },
      {
        step: 3,
        rule: 'Crie e conclua um pedido manual',
        condition: hasCreatedManualOrder,
        onClick: () => {
          if (!hasCreatedManualOrder) {
            dispatch(setManualOrderModalState(true));
            setShowChallengeDetails(false);
          }
        },
      },
      {
        step: 4,
        rule: 'Faça um disparo em massa no Whatsapp',
        condition: hasMassMessageCampaign,
        onClick: () => {
          if (!hasMassMessageCampaign) {
            setShowChallengeDetails(false);
            setShowMassMessageModal(true);
          }
        },
      },
    ];
  }, [challenges, createTestOrderLoading]);

  const conditions = useMemo(() => {
    return tasks.map(({condition}) => condition)
  }, [tasks])

  useEffect(() => {
    if (challenges?.is_completed) {
      setFinishedActivation(true);
    }
  }, [challenges, setFinishedActivation]);

  return (
    <ChallengeBarContext.Provider
      value={{
        setShowChallengeDetails,
        showChallengeDetails,
        tasks,
        setShowMassMessageModal,
        showMassMessageModal,
        finishedActivation,
        setFinishedActivation,
        conditions,
      }}
    >
      {children}
    </ChallengeBarContext.Provider>
  );
};

export const useChallenge = () => {
  const { tasks, conditions, ...rest } = useContext(ChallengeBarContext);
  return { tasks, conditions,   ...rest };
};
