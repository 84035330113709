import styled, { css } from 'styled-components';

import {
  branco,
  cinzaKuppi1,
  cinzaKuppi4,
  cinzaKuppi7,
  verdeKuppi2,
  preto,
} from '../../../../styles/colors';

import px2vw from '../../../../utils/responsiveness/px2vw';
import { keyframes } from 'styled-components';

export const ModalHeader = styled.div`
  width: ${props => props.width || '100%'};
  background-color: ${branco};
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: ${px2vw(33)} ${px2vw(28)} ${px2vw(15)} ${px2vw(28)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  font-size: ${px2vw(25)};
  display: flex;
  align-items: space-between;
  justify-content: space-between;
`;

export const CloseButton = styled.button`
  width: ${px2vw(18)};
  height: ${px2vw(18)};
  border: none;
  z-index: 10;
  background-color: transparent;

  :focus {
    background-color: transparent !important;
  }

  ${(props) =>
    props.absolute &&
    css`
      position: absolute;
      top: ${props.top || px2vw(10)};
      right: ${props.right || px2vw(10)};
    `}
`;

export const CloseImage = styled.img`
  width: ${px2vw(22)};
  height: ${px2vw(22)};
`;

export const ModalContent = styled.div`
  background-color: ${branco};
  margin: ${px2vw(5)} ${px2vw(33)};
  display: flex;
  ${(props) => `
    width: ${props.width};
    min-height: ${props.height || px2vw(400)};
    max-height: ${props.maxheight || px2vw(400)};
    background-color: ${props.bgcolor};
    margin-top: ${props.margtop};
  `};
  flex-wrap: wrap;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 0.5em;
    margin-left: 10px;
  }
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${verdeKuppi2};
    outline: 1px solid ${verdeKuppi2};
    border-radius: 15px;
  }
`;
export const ModalFooter = styled.div`
  width: 100%;
  min-height: ${px2vw(90)};
  margin-top: auto;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  display:flex;
  justify-content:center;
  padding: ${px2vw(15)} ${px2vw(40)};
  font-family: AvenirNextLTPro;
  font-weight: 500;
  font-size: ${px2vw(14)};
`;

export const AdsCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  margin: 0 ${px2vw(70)} 0 ${px2vw(10)};
  background: ${cinzaKuppi1};
  width: ${px2vw(247)};
  height: ${px2vw(380)};
  flex-wrap: wrap;
`;

export const AdsFooter = styled.footer`
  margin-top: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  padding: ${px2vw(6)};
  background: ${cinzaKuppi4};
  width: ${px2vw(247)};
  height: ${px2vw(68)};
`;

export const AdsHeader = styled.header`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  word-wrap: break-word;
  padding: ${px2vw(0)} ${px2vw(8)};
  padding-top: ${px2vw(4)};
  width: ${px2vw(247)};
  height: ${px2vw(68)};
`;

export const ImageBox = styled.div`
  position: relative;
  ${(props) => props.width && `width: ${props.width};`}
  ${(props) => props.height && `height: ${props.height};`}
`;

export const Image = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;

  border-top-left-radius: ${(props) => props.btlRadius && props.btlRadius};
  border-top-right-radius: ${(props) => props.btrRadius && props.btrRadius};
  border-bottom-left-radius: ${(props) => props.bblRadius && props.bblRadius};
  border-bottom-right-radius: ${(props) => props.bbrRadius && props.bbrRadius};
`;

export const TxtArea = styled.textarea`
  margin-top: ${px2vw(15)};
  margin-bottom: ${px2vw(15)};
  padding: ${px2vw(7)};
  border-radius: ${px2vw(8)};
  border: 2px solid ${cinzaKuppi4};
  width: 100%;
  height: ${(props) => props.height || px2vw(125)};
  resize: none;
`;


export const Input = styled.input`
border: 2px solid ${cinzaKuppi7};
border-radius:${px2vw(6)};
height:${px2vw(30)};
width: ${(props) => props.width || px2vw(50)};
text-align: center;
font-family: AvenirNextLTPro;
font-style: normal;
font-weight: 700;
font-size: ${px2vw(14)};
line-height: 130%;

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
   opacity: 1;
}
`;

export const Select = styled.select`
border: 2px solid ${cinzaKuppi7};
border-radius:${px2vw(6)};
height:${px2vw(32)};
width: ${(props) => props.width || px2vw(70)};
text-align: center;
font-family: AvenirNextLTPro;
font-style: normal;
font-weight: 500;
font-size: ${px2vw(13)};
line-height: 130%;
letter-spacing: 0.72px;

`;

export const MapsContainer = styled.div`
height:${px2vw(175)};
width: 100%;
background: ${preto};
margin-bottom: ${px2vw(19)};
position: relative;
`;

const imgFadeIn = keyframes`
  to {
    opacity: 1;
    transform: initial;
  }
`;

export const AnimatedImg = styled.img`
  opacity: 0;
  transform: rotate(45deg) scale(0.5);
  animation: ${imgFadeIn} 0.7s forwards;
  width: ${px2vw(250)};
  height: ${px2vw(250)};
`;
