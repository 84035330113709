import React, {
  createContext,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearChatState,
  setIsConnected,
} from '../../store/modules/whatsappChat/actions';
import { store } from '../../store';
import {
  watchReceivedMessageAction,
  processReceivedMessageAction,
  setIsHistorySyncComplete,
  updateConversations,
} from '../../store/modules/whatsappChatConversations/actions';
import { getURL } from '../../utils/utils';
import { saveHistory } from '../../utils/getWhatsappHistory';
import AccessValidate from '../../utils/validations/AccessValidate';
import { toast } from 'react-toastify';
import ioV2 from 'socket.io-client-v2';

export const WorkerSocket = ioV2(getURL('wsWppSocket'), {
  secure: false,
  allowEIO3: true,
  reconnection: true,
  reconnectionDelayMax: 30000,
  rejectUnauthorized: false,
  transports: ['websocket'],
  path: '/worker/socket',
  autoConnect: true,
});

export const WebsocketContext = createContext({
  worker: {
    socket: WorkerSocket,
    isActive: false,
    requestCachedMessages: (phone = '') => {},
    reconnect: () => {},
  },
});

export const WebsocketProvider = ({ children }) => {
  const { token: accessToken } = useSelector((state) => state.auth);
  const advertiserInfoId = useSelector(
    (state) => state.user.user.advertiserInfo.id
  );
  const user = useSelector((state) => state.user);
  const isWhatsappActive =
    user.user.advertiserInfo.advertiserWhatsappbotConfig.is_active;
  const userAccess = useSelector((state) => state.user.access);
  const hasWhatsappchatPermission = AccessValidate(userAccess, null, null, 'whatsapp_bot_chat');

  const [workerIsActive, setWorkerIsActive] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!hasWhatsappchatPermission) {
      return
    }
    
    if (!isWhatsappActive) {
      if (WorkerSocket.connected) {
        WorkerSocket.disconnect();
      }
      return;
    }

    WorkerSocket.connect();
    WorkerSocket.on('connect', onConnect);
    WorkerSocket.on('disconnect', onDisconnect);
  }, [isWhatsappActive]);

  useEffect(() => {
    if (!hasWhatsappchatPermission) {
      return
    }

    if (!isWhatsappActive) {
      return handleShutdown;
    }
    // Worker Socket Connection
    WorkerSocket.on(`messages:${advertiserInfoId}`, onReceiveMessage);
    WorkerSocket.on(
      `successConnected:${advertiserInfoId}:${accessToken}`,
      onSuccessfullConnect
    );

    WorkerSocket.on(
      `loggoutSession:${advertiserInfoId}:${accessToken}`,
      onLoggedOut
    );

    WorkerSocket.on('connect_error', onConnectionError);

    WorkerSocket.on(`${advertiserInfoId}:history`, onReceiveHistory);

    WorkerSocket.on(`${advertiserInfoId}:history-complete`, onCompleteHistory);

    return handleShutdown;
  }, [isWhatsappActive]);

  const handleShutdown = () => {
    WorkerSocket.disconnect();
  };

  const onCompleteHistory = async () => {
    console.debug(
      `WPP WS - ${new Date()} - Socket ID: ${
        WorkerSocket.id
      } - receive new history-complete package`
    );

    dispatch(setIsHistorySyncComplete(true));
  };

  const onReceiveHistory = async ({ body }) => {
    console.debug(
      `WPP WS - ${new Date()} - Socket ID: ${
        WorkerSocket.id
      } - receive new history package`,
      body
    );

    await saveHistory(body);
  };

  const onConnectionError = (err) => {
    console.debug(
      `WPP WS - ${new Date()} - Socket ID: ${
        WorkerSocket.id
      } - error connecting to receive whatsapp messages, reason: ${err}`
    );
    if (!toast.isActive('notificationWPPWSError')) {
      toast.error(
        'Ocorreu um erro na comunicação para atualização de mensagens do whatsapp, verifique sua conexão de internet! Sem essa comunicação as mensagens seu whatsapp não aparecerão automaticamente para você!',
        {
          autoClose: 8000,
          toastId: 'notificationWPPWSError',
        }
      );
    }
  };

  const onReceiveMessage = (data) => {
    dispatch(processReceivedMessageAction(data));
    const state = store.getState();
    const selectedConversation = state.whatsappChat.selectedConversation;
    const conversationId = data?.body?.id;
    if (selectedConversation?.id === conversationId && !(data?.text == 'CACHED')
    ) {
      requestCachedMessages(selectedConversation?.id);
    }
  };

  const onDisconnect = () => {
    console.debug(
      `WPP WS - ${new Date()} - Socket ID: ${
        WorkerSocket.id
      } - disconnected event received`
    );

    dispatch(clearChatState());
    setWorkerIsActive(false);
  };

  const onConnect = () => {
    joinMessageChannel();
    fetchHistoryChannel();
    console.debug(
      `WPP WS - ${new Date()} - Socket ID: ${
        WorkerSocket.id
      } - connecting to receive wpp chats `
    );
    setWorkerIsActive(true);
    dispatch(setIsConnected(true));
  };

  const onLoggedOut = () => {
    console.debug(
      `WPP WS - ${new Date()} - Socket ID: ${
        WorkerSocket.id
      } - logout event received`
    );

    WorkerSocket.disconnect();
    dispatch(setIsConnected(false));
    dispatch(clearChatState());
  };

  const onSuccessfullConnect = () => {
    const state = store.getState();
    const whatsappIsActive =
      state.user.user.advertiserInfo.advertiserWhatsappbotConfig.is_active;

    console.debug(
      `WPP WS - ${new Date()} - Socket ID: ${
        WorkerSocket.id
      } - connected to receive wpp chats`
    );

    // Dispatch
    dispatch(setIsConnected(true));

    if (WorkerSocket.connected && whatsappIsActive) {
      localStorage.setItem('kuppi:currentWhatsappSession', accessToken);
      dispatch(updateConversations());
      dispatch(watchReceivedMessageAction());
    }
  };

  const handleReconnect = () => {
    if (WorkerSocket.connected) {
      WorkerSocket.disconnect();
    }
    WorkerSocket.open();
  };

  const joinMessageChannel = () => {
    WorkerSocket.emit('join-messagesChannel', {
      body: {
        advertiserInfoId: `${advertiserInfoId}`,
        token: `${accessToken}`,
      },
    });
  };

  const fetchHistoryChannel = () => {
    WorkerSocket.emit('fetch-historyChannel', {
      body: {
        advertiserInfoId: `${advertiserInfoId}`,
        token: `${accessToken}`,
      },
    });
  };

  const requestCachedMessages = useCallback(
    (phone) => {
      WorkerSocket.emit('fetch-messagesChannel', {
        body: {
          phone,
          advertiserInfoId: `${advertiserInfoId}`,
          token: `${accessToken}`,
        },
      });
    },
    [advertiserInfoId]
  );

  return (
    <WebsocketContext.Provider
      value={{
        worker: {
          socket: WorkerSocket,
          isActive: workerIsActive,
          requestCachedMessages,
          reconnect: handleReconnect,
        },
      }}
    >
      {children}
    </WebsocketContext.Provider>
  );
};
