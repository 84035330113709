import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { onlyNumbers } from '../../../utils/utils';

import { updateSurveyStoreDataRequest } from '../../../store/modules/firstSteps/actions';

import PageThreeWeb from './Web';
import PageThreeMobile from './Mobile';

export default function PageThree({ isWeb }) {
  const firstStepsSurveyData = useSelector(
    (state) => state.firstSteps.firstStepsSurveyData
  );
  const [salesOwnChannelPercentage, setSalesOwnChannelPercentage] = useState(
    firstStepsSurveyData.sales_own_channel_percentage
  );
  const [
    salesMarketplacesPercentage,
    setSalesMarketplacesPercentage,
  ] = useState(firstStepsSurveyData.sales_marketplaces_percentage);
  const [deliveryOnly, setDeliveryOnly] = useState(false);
  const [deliveryAndPhysical, setDeliveryAndPhysical] = useState(false);
  const [physicalOnly, setPhysicalOnly] = useState(false);
  const [deliveryType, setDeliveryType] = useState(
    firstStepsSurveyData.delivery_type
  );

  const [noOrders, setNoOrders] = useState(false);
  const [firstOrderRange, setFirstOrderRange] = useState(false);
  const [secondOrderRange, setSecondOrderRange] = useState(false);
  const [thirdOrderRange, setThirdOrderRange] = useState(false);
  const [fourthOrderRange, setFourthOrderRange] = useState(false);
  const [dailyOrdersAvg, setDailyOrdersAvg] = useState(firstStepsSurveyData.daily_orders_avg);

  const updateSurveyStoreDataLoading = useSelector(
    (state) => state.firstSteps.updateSurveyStoreDataLoading
  );

  function handlePercentage(currPercentage) {
    let validPercentage = currPercentage;
    if (Number(validPercentage) > Number(100)) {
      validPercentage = 100;
    } else if (Number(validPercentage) < Number(0) || validPercentage === '') {
      validPercentage = 0;
    }
    if (document.getElementById('percentage1') === document.activeElement) {
      setSalesOwnChannelPercentage(validPercentage);
      setSalesMarketplacesPercentage(100 - validPercentage);
    } else if (
      document.getElementById('percentage2') === document.activeElement
    ) {
      setSalesMarketplacesPercentage(validPercentage);
      setSalesOwnChannelPercentage(100 - validPercentage);
    }
  }

  const dispatch = useDispatch();

  function handleStoreDataSubmit() {
    if (!deliveryOnly && !deliveryAndPhysical && !physicalOnly) {
      toast.error('Por gentileza, selecionar uma opção na pergunta 3!', {
        autoClose: 6000,
      });
    }
    if (!noOrders && !firstOrderRange && !secondOrderRange && !thirdOrderRange && !fourthOrderRange) {
      toast.error('Por gentileza, selecionar uma opção na pergunta 4!', {
        autoClose: 6000,
      });
    } else {
      dispatch(
        updateSurveyStoreDataRequest(
          'finished',
          deliveryType,
          dailyOrdersAvg,
          salesOwnChannelPercentage,
          salesMarketplacesPercentage
        )
      );
    }
  }

  useEffect(() => {
    // Question 4 options
    setDeliveryType(firstStepsSurveyData.delivery_type);
    setDeliveryOnly(firstStepsSurveyData.delivery_type === 'delivery_only');
    setDeliveryAndPhysical(firstStepsSurveyData.delivery_type === 'delivery_and_physical');
    setPhysicalOnly(firstStepsSurveyData.delivery_type === 'physical_only');

    // Question 5 options
    setDailyOrdersAvg(firstStepsSurveyData.daily_orders_avg);
    setNoOrders(firstStepsSurveyData.daily_orders_avg === 'no_orders');
    setFirstOrderRange(firstStepsSurveyData.daily_orders_avg === '01_05_orders');
    setSecondOrderRange(firstStepsSurveyData.daily_orders_avg === '06_10_orders');
    setThirdOrderRange(firstStepsSurveyData.daily_orders_avg === '11_30_orders');
    setFourthOrderRange(firstStepsSurveyData.daily_orders_avg === 'more_30_orders');

    // Sales channel percentages
    setSalesOwnChannelPercentage(
      firstStepsSurveyData.sales_own_channel_percentage
    );
    setSalesMarketplacesPercentage(
      firstStepsSurveyData.sales_marketplaces_percentage
    );
  }, [firstStepsSurveyData]);

  function handleDeliveryTypeClick(selectedOption) {
    setDeliveryType(selectedOption)
    setDeliveryOnly(selectedOption === 'delivery_only');
    setDeliveryAndPhysical(selectedOption === 'delivery_and_physical');
    setPhysicalOnly(selectedOption === 'physical_only');
  }

  function handleDailyOrdersAvgClick(selectedOption) {
    setDailyOrdersAvg(selectedOption);
    setNoOrders(selectedOption === 'no_orders');
    setFirstOrderRange(selectedOption === '01_05_orders');
    setSecondOrderRange(selectedOption === '06_10_orders');
    setThirdOrderRange(selectedOption === '11_30_orders');
    setFourthOrderRange(selectedOption === 'more_30_orders');
    if (selectedOption === 'no_orders') {
      setSalesOwnChannelPercentage(0)
      setSalesMarketplacesPercentage(0)
    }
  }

  const handler = {
    updateSurveyStoreDataLoading: updateSurveyStoreDataLoading,
    handleStoreDataSubmit: handleStoreDataSubmit,
    deliveryOnly: deliveryOnly,
    deliveryAndPhysical: deliveryAndPhysical,
    physicalOnly: physicalOnly,
    deliveryTypeClick: (clickedOption) => handleDeliveryTypeClick(clickedOption),
    noOrders: noOrders,
    firstOrderRange: firstOrderRange,
    secondOrderRange: secondOrderRange,
    thirdOrderRange: thirdOrderRange,
    fourthOrderRange: fourthOrderRange,
    dailyOrdersAvgClick: (clickedOption) => handleDailyOrdersAvgClick(clickedOption),
    salesOwnChannelPercentage: salesOwnChannelPercentage,
    salesMarketplacesPercentage: salesMarketplacesPercentage,
    percentageChange: (e) =>
      handlePercentage(onlyNumbers(e.target.value.substring(0, 3))),
  };

  if (isWeb) {
    return <PageThreeWeb handler={handler} />;
  } else {
    return <PageThreeMobile handler={handler} />;
  }
}
