import { takeLatest, call, put, all, select } from 'redux-saga/effects';

import { apiAnunciante } from '../../../services/api';

import {
  getOrdersMetricsSuccess,
  getOrdersMetricsFailure,
  getGameficationSuccess,
  getGameficationFailure,
} from './actions';
import {
  logout
} from '../auth/actions';

const userData = (state) => state.user.user;

export function* getOrdersMetricsRequest() {
  try {
    const user = yield select(userData);

    const response = yield call(
      apiAnunciante.get,
      `/user-info/${user.advertiserInfo.id}/dashboard/metrics/orders`
    );

    yield put(getOrdersMetricsSuccess(response.data));
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(getOrdersMetricsFailure());
    }
  }
}

export function* getGameficationRequest() {
  try {
    const user = yield select(userData);

    const response = yield call(
      apiAnunciante.get,
      `/user-info/${user.advertiserInfo.id}/gamefication`
    );

    yield put(getGameficationSuccess(response.data));
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(getGameficationFailure());
    }
  }
}

export default all([
  takeLatest(
    '@dashboardMetrics/GET_ORDERS_METRICS_REQUEST',
    getOrdersMetricsRequest
  ),
  takeLatest(
    '@dashboardMetrics/GET_GAMEFICATION_REQUEST',
    getGameficationRequest
  )
]);
