import React from 'react';
import styled, { css } from 'styled-components';
import {
  cinzaKuppi11,
  cinzaKuppi4,
  verdeKuppi2,
  cinzaKuppi2,
  verdeKuppi1,
  cinzaKuppi1
} from '../../../../styles/colors';
import { TextField } from '@material-ui/core';
import { branco, preto } from '../../../../styles/colors';

import px2vw from '../../../../utils/responsiveness/px2vw';
import { keyframes } from 'styled-components';

const colorFadeIn = keyframes`
  0% {
  background-color: ${cinzaKuppi4};
  transform: scale(1.01);
}
  100% {
    background-color: ${verdeKuppi2};
    transform: initial;
  }
`;

export const ModalHeader = styled.div`
  width: 100%;
  background-color: ${branco};
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: ${px2vw(23)} ${px2vw(20)} ${px2vw(10)} ${px2vw(20)};
  font-family: AvenirNextLTPro;
  font-weight: bold;
  font-size: ${px2vw(20)};
  display: flex;
  align-items: space-between;
  justify-content: space-between;
`;

export const ProgressBar = styled.div`
  background-color: ${cinzaKuppi11};
  width: 100%;
`;

export const CloseButton = styled.button`
  width: ${px2vw(18)};
  height: ${px2vw(18)};
  border: none;
  z-index: 10;
  background-color: transparent;

  :focus {
    background-color: transparent !important;
  }

  ${(props) =>
    props.absolute &&
    css`
      position: absolute;
      top: ${props.top || px2vw(10)};
      right: ${props.right || px2vw(10)};
    `}
`;

export const CloseImage = styled.img`
  width: ${px2vw(17)};
  height: ${px2vw(17)};
`;

export const PaymentMethodImg = styled.img`
  height: ${px2vw(20)};
  margin-left:${px2vw(5)};
`;

export const Section = styled.section`
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: flex-start;
  padding: ${px2vw(20)};

`;

export const Box = styled.div`
width: ${px2vw(460)};
height: ${px2vw(135)};
border-radius: ${px2vw(5)};
padding: ${px2vw(10)};
background: ${cinzaKuppi1};
display:flex;
flex-direction: column;
margin: 0 auto;
margin-top:${px2vw(10)};
color: ${props => props.selected ? branco : preto};
cursor: pointer;
`

export const ButtonPrimary = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${branco};
  border: none;
  border-radius: ${px2vw(10)};
  font-size: ${px2vw(18)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.03em;
  padding: ${px2vw(8)};
  box-shadow: 2px 2px 3px ${cinzaKuppi2};
  width: ${(props) => (props.width ? props.width : px2vw(320))};
  height: ${px2vw(40)};

  ${(props) =>
    props.complete ? css`
    animation: ${colorFadeIn} 0.7s forwards;
` : `background-color: ${verdeKuppi2};`}

  margin-top: ${(props) => (props.margtop ? props.margtop : 0)};
  margin-right: ${(props) => (props.margright ? props.margright : 0)};

  &:active {
    box-shadow: 2px 2px 3px ${cinzaKuppi2};
    background: ${verdeKuppi1};
  }

  ${(props) =>
    props.disabled &&
    `
  background-color: ${cinzaKuppi4};
  color:${branco};

  &:active {
    box-shadow: 2px 2px 3px ${cinzaKuppi2};
    background: ${cinzaKuppi4};
  }

  `}
`;

export const CustonInputRange = styled.div`
  width: 100%;

  .input-range__track.input-range__track--background {
    background: #dbdbdb;
  }

  .input-range__track.input-range__track--active {
    background: ${verdeKuppi1};
  }

  .input-range__slider {
    background: ${verdeKuppi1};
    border: 1px solid ${verdeKuppi1};
  }

  .input-range__label-container {
    display: none;
  }

  ${(props) =>
    props.disabled &&
    `
    .text {
      color: #dbdbdb;
    }

    .input-range__track.input-range__track--background {
      background: #dbdbdb;
    }

    .input-range__track.input-range__track--active {
      background: #dbdbdb;
    }

    .input-range__slider {
      background: #dbdbdb;
      border: 1px solid #dbdbdb;
    }

    .input-range__label-container {
      display: none;
    }
  `}
`;

export const BalanceTextField = styled((props) => (
  <TextField InputProps={{ disableUnderline: true, maxLength: 7 }} {...props} />
))(({ theme }) => ({
  '& .MuiFilledInput-root': {
    border: `1px solid ${cinzaKuppi4}`,
    overflow: 'hidden',
    borderRadius: 4,
    backgroundColor: '#fcfcfb',

    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&.Mui-focused': {
      backgroundColor: 'transparent',
    },
  },
  '& label.Mui-focused': {
    color: preto,
  },
  width: '100%',
  marginTop: px2vw(10),
}));
