import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  branco,
  cinzaKuppi11,
  cinzaKuppi3,
  laranjaKuppi2,
  laranjaKuppi3,
  preto,
  vermelhoKuppi3,
  vermelhoKuppi4,
  verdeKuppi21,
  verdeKuppi18,
  azulKuppi6,
  azulKuppi5,
} from '../../../../styles/colors';
import { Text } from '../../../../styles/common';
import {
  rangeTemplate1,
  rangeTemplate2,
  rangeTemplate3,
  rangeTemplate4,
  rangeTemplate5,
} from '../../../../utils/handleImages/rangeTemplateImg';
import {
  conversionTemplate1,
  conversionTemplate2,
  conversionTemplate3,
  conversionTemplate4,
  conversionTemplate5,
} from '../../../../utils/handleImages/conversionTemplateImg';

import px2vw from '../../../../utils/responsiveness/px2vw';
import { ModalPrimaryButton } from '../styles';
import { AiOutlineLeft } from 'react-icons/ai';
import { Spinner } from 'reactstrap';

import {
  ModalContent,
  CloseButton,
  ModalHeader,
  CloseImage,
  ModalFooter,
  TemplateImage,
  ImageBox,
  TemplateOption,
  ColorOption,
  ColorOptionBorder,
  ColorBox,
} from './styles';
import { Row, Col } from '../../../../styles/grid';
import {
  setCampaignCurrentStep,
  setSelectedCoupon,
  setSelectedProduct,
} from '../../../../store/modules/kuppiBoost/actions';
import URLToBase64 from '../../../../utils/URLToBase64';
import ChangeAspectRatio from '../../../../utils/handleImages/ChangeAspectRatio';

export default function CampaignTemplate(props) {
  const dispatch = useDispatch();
  const kuppiBoostData = useSelector((state) => state.kuppiBoost);
  const { type, selected_coupon, selected_product } = kuppiBoostData.campaign;
  const isTypeRangeOrWhatsapp = type === 'range' || type === 'whatsapp';
  const wasImgUploadedLocally =
    selected_coupon?.was_image_uploaded_locally ||
    selected_product?.was_image_uploaded_locally;
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [advertiserLogo, setAdvertiserLogo] = useState();
  const [productImage, setProductImage] = useState();
  const [isloading, setIsloading] = useState();
  const [rangeTemplateData1, setRangeTemplateData1] = useState();
  const [rangeTemplateData2, setRangeTemplateData2] = useState();
  const [rangeTemplateData3, setRangeTemplateData3] = useState();
  const [rangeTemplateData4, setRangeTemplateData4] = useState();
  const [rangeTemplateData5, setRangeTemplateData5] = useState();
  const [conversionTemplateData1, setConversionTemplateData1] = useState();
  const [conversionTemplateData2, setConversionTemplateData2] = useState();
  const [conversionTemplateData3, setConversionTemplateData3] = useState();
  const [conversionTemplateData4, setConversionTemplateData4] = useState();
  const [conversionTemplateData5, setConversionTemplateData5] = useState();
  const [selectedColor, setSelectedColor] = useState('red');
  const userData = useSelector((state) => state.user.user);

  const userName = userData.advertiserInfo.name;
  const logoUrl = userData.avatar.url;
  const selectedImg = isTypeRangeOrWhatsapp
    ? selected_product?.img_url
    : selected_coupon?.product_url;

  useEffect(() => {
    handleImage();
    genImages();
  }, [productImage, advertiserLogo, selectedColor]);

  const genImages = useCallback(async () => {
    setIsloading(true);
    // Create images (product - coupon, advertiser logo, templates)
    const prodImage = new Image();
    prodImage.crossOrigin = 'Anonymous';
    const logoImage = new Image();
    const template1Image = new Image();
    const template2Image = new Image();
    const template3Image = new Image();
    const template4Image = new Image();
    const template5Image = new Image();

    if (productImage && advertiserLogo) {
      if (
        isTypeRangeOrWhatsapp ||
        (wasImgUploadedLocally && type === 'conversion')
      ) {
        // When logo image was loaded, force load product - coupon image
        logoImage.onload = function () {
          prodImage.src = `${productImage}`;
        };
        // When product - coupon imagem was loaded, force load template images
        prodImage.onload = async function () {
          template1Image.src =
            await require(`../../../../assets/images/kuppi_boost_templates_bg/range_1_${selectedColor}.png`);
          template2Image.src =
            await require(`../../../../assets/images/kuppi_boost_templates_bg/range_2_${selectedColor}.png`);
          template3Image.src =
            await require(`../../../../assets/images/kuppi_boost_templates_bg/range_3_${selectedColor}.png`);
          template4Image.src =
            await require(`../../../../assets/images/kuppi_boost_templates_bg/range_4_${selectedColor}.png`);
          template5Image.src =
            await require(`../../../../assets/images/kuppi_boost_templates_bg/range_5_${selectedColor}.png`);
        };
        // When template 1 was loaded, create the boost image using template 1
        template1Image.onload = async function () {
          // Create Range Template 1
          await rangeTemplate1(
            prodImage,
            logoImage,
            template1Image,
            selectedColor
          ).then(
            function (templateImage) {
              setRangeTemplateData1(templateImage);
            },
            function (err) {
              console.debug('KuppiBoost-Error-RangeTemplate1: ', err);
            }
          );
        };
        // When template 2 was loaded, create the boost image using template 2
        template2Image.onload = async function () {
          // Create Range Template 2
          await rangeTemplate2(
            prodImage,
            logoImage,
            template2Image,
            selectedColor
          ).then(
            function (templateImage) {
              setRangeTemplateData2(templateImage);
            },
            function (err) {
              console.debug('KuppiBoost-Error-RangeTemplate2: ', err);
            }
          );
        };
        // When template 3 was loaded, create the boost image using template 3
        template3Image.onload = async function () {
          // Create Range Template 3
          await rangeTemplate3(
            prodImage,
            logoImage,
            template3Image,
            selectedColor
          ).then(
            function (templateImage) {
              setRangeTemplateData3(templateImage);
            },
            function (err) {
              console.debug('KuppiBoost-Error-RangeTemplate3: ', err);
            }
          );
        };
        // When template 4 was loaded, create the boost image using template 4
        template4Image.onload = async function () {
          // Create Range Template 4
          await rangeTemplate4(
            prodImage,
            logoImage,
            template4Image,
            selectedColor
          ).then(
            function (templateImage) {
              setRangeTemplateData4(templateImage);
            },
            function (err) {
              console.debug('KuppiBoost-Error-RangeTemplate4: ', err);
            }
          );
        };
        // When template 5 was loaded, create the boost image using template 5
        template5Image.onload = async function () {
          // Create Range Template 5
          await rangeTemplate5(
            prodImage,
            logoImage,
            template5Image,
            userName,
            selectedColor
          ).then(
            function (templateImage) {
              setRangeTemplateData5(templateImage);
            },
            function (err) {
              console.debug('KuppiBoost-Error-RangeTemplate5: ', err);
            }
          );
        };
        // Load advertiser logo image, starting the boost images creation process
        logoImage.src = await ChangeAspectRatio(`${advertiserLogo}`, 1);
      } else {
        // When logo image was loaded, force load product - coupon image
        logoImage.onload = function () {
          prodImage.src = `${productImage}`;
        };
        // When product - coupon imagem was loaded, force load template images
        prodImage.onload = async function () {
          template1Image.src =
            await require(`../../../../assets/images/kuppi_boost_templates_bg/conversion_1_${selectedColor}.png`);
          template2Image.src =
            await require(`../../../../assets/images/kuppi_boost_templates_bg/conversion_2_${selectedColor}.png`);
          template3Image.src =
            await require(`../../../../assets/images/kuppi_boost_templates_bg/conversion_3_${selectedColor}.png`);
          template4Image.src =
            await require(`../../../../assets/images/kuppi_boost_templates_bg/conversion_4_${selectedColor}.png`);
          template5Image.src =
            await require(`../../../../assets/images/kuppi_boost_templates_bg/conversion_5_${selectedColor}.png`);
        };
        // When template 1 was loaded, create the boost image using template 1
        template1Image.onload = async function () {
          // Create Conversion Template 1
          await conversionTemplate1(
            prodImage,
            logoImage,
            template1Image,
            selected_coupon.coupon_short_display_name,
            selected_coupon.product_name,
            selectedColor
          ).then(
            function (templateImage) {
              setConversionTemplateData1(templateImage);
            },
            function (err) {
              console.debug('KuppiBoost-Error-ConversionTemplate1: ', err);
            }
          );
        };
        // When template 2 was loaded, create the boost image using template 2
        template2Image.onload = async function () {
          // Create Conversion Template 2
          await conversionTemplate2(
            prodImage,
            logoImage,
            template2Image,
            selected_coupon.coupon_short_display_name,
            selected_coupon.product_name,
            selectedColor
          ).then(
            function (templateImage) {
              setConversionTemplateData2(templateImage);
            },
            function (err) {
              console.debug('KuppiBoost-Error-ConversionTemplate2: ', err);
            }
          );
        };
        // When template 3 was loaded, create the boost image using template 3
        template3Image.onload = async function () {
          // Create Conversion Template 3
          await conversionTemplate3(
            prodImage,
            logoImage,
            template3Image,
            selected_coupon.coupon_short_display_name,
            selected_coupon.product_name,
            selectedColor
          ).then(
            function (templateImage) {
              setConversionTemplateData3(templateImage);
            },
            function (err) {
              console.debug('KuppiBoost-Error-ConversionTemplate3: ', err);
            }
          );
        };
        // When template 4 was loaded, create the boost image using template 4
        template4Image.onload = async function () {
          // Create Conversion Template 4
          await conversionTemplate4(
            prodImage,
            logoImage,
            template4Image,
            selected_coupon.coupon_short_display_name,
            selectedColor
          ).then(
            function (templateImage) {
              setConversionTemplateData4(templateImage);
            },
            function (err) {
              console.debug('KuppiBoost-Error-ConversionTemplate4: ', err);
            }
          );
        };
        // When template 5 was loaded, create the boost image using template 5
        template5Image.onload = async function () {
          // Create Conversion Template 5
          await conversionTemplate5(
            prodImage,
            logoImage,
            template5Image,
            selected_coupon.coupon_short_display_name,
            selected_coupon.product_name,
            selectedColor,
            userName
          ).then(
            function (templateImage) {
              setConversionTemplateData5(templateImage);
            },
            function (err) {
              console.debug('KuppiBoost-Error-ConversionTemplate5: ', err);
            }
          );
        };
        // Load advertiser logo image, starting the boost images creation process
        logoImage.src = await ChangeAspectRatio(`${advertiserLogo}`, 1);
      }
    } else {
      handleImage();
    }

    setIsloading(false);
  });

  async function handleImage() {
    await URLToBase64(
      `${logoUrl}?nocache=${new Date().getTime()}`,
      (result) => {
        setAdvertiserLogo(result);
      }
    );

    if (wasImgUploadedLocally) {
      setProductImage(selectedImg);
    } else {
      await URLToBase64(
        `${selectedImg}?nocache=${new Date().getTime()}`,
        (result) => {
          setProductImage(result);
        }
      );
    }
  }

  function handleSelectedTemplate(template) {
    setSelectedTemplate(template);
  }

  function handleBacktoPreviousStep() {
    dispatch(setCampaignCurrentStep('2'));
  }

  function handleGotoNextStep() {
    if (isTypeRangeOrWhatsapp) {
      dispatch(
        setSelectedProduct({
          ...selected_product,
          imgTemplate: selectedTemplate,
        })
      );
    } else {
      dispatch(
        setSelectedCoupon({ ...selected_coupon, imgTemplate: selectedTemplate })
      );
    }
    dispatch(setCampaignCurrentStep('4'));
  }
  return (
    <>
      <ModalHeader>
        Escolha o template ideal
        <CloseButton onClick={props.onClose}>
          <CloseImage
            src={require('../../../../assets/images/close/close.png')}
          />
        </CloseButton>
      </ModalHeader>
      <ModalContent>
        {wasImgUploadedLocally && (
          <>
            {isTypeRangeOrWhatsapp ? (
              <Col width="auto" height="auto">
                <TemplateOption
                  onClick={() =>
                    handleSelectedTemplate(selected_product?.img_url)
                  }
                  selected={selected_product?.img_url === selectedTemplate}
                >
                  <ImageBox width={px2vw(295)} height={px2vw(295)}>
                    {rangeTemplateData1 ? (
                      <TemplateImage
                        src={selected_product?.img_url}
                        btlRadius="10px"
                        btrRadius="10px"
                      />
                    ) : (
                      <Spinner
                        style={{
                          width: '1.8rem',
                          height: '1.8rem',
                          color: preto,
                        }}
                        className="m-auto position-relative"
                      />
                    )}
                  </ImageBox>
                  <Text
                    className="my-auto"
                    size={px2vw(17)}
                    color={
                      selectedTemplate === selected_product?.img_url
                        ? branco
                        : cinzaKuppi11
                    }
                    alignCenter
                  >
                    Selecionar
                  </Text>
                </TemplateOption>
              </Col>
            ) : (
              <Col width="auto" height="auto">
                <TemplateOption
                  onClick={() =>
                    handleSelectedTemplate(selected_coupon?.product_url)
                  }
                  selected={selected_coupon?.product_url === selectedTemplate}
                >
                  <ImageBox width={px2vw(295)} height={px2vw(295)}>
                    {selected_coupon ? (
                      <TemplateImage
                        src={selected_coupon?.product_url}
                        btlRadius="10px"
                        btrRadius="10px"
                      />
                    ) : (
                      <Spinner
                        style={{
                          width: '1.8rem',
                          height: '1.8rem',
                          color: preto,
                        }}
                        className="m-auto position-relative"
                      />
                    )}
                  </ImageBox>
                  <Text
                    className="my-auto"
                    size={px2vw(17)}
                    color={
                      selectedTemplate === selected_coupon?.product_url
                        ? branco
                        : cinzaKuppi11
                    }
                    alignCenter
                  >
                    Selecionar
                  </Text>
                </TemplateOption>
              </Col>
            )}
          </>
        )}
        {(wasImgUploadedLocally || isTypeRangeOrWhatsapp) && (
          <>
            <Col width="auto" height="auto">
              <TemplateOption
                onClick={() => handleSelectedTemplate(rangeTemplateData1)}
                selected={selectedTemplate === rangeTemplateData1}
              >
                <ImageBox width={px2vw(295)} height={px2vw(295)}>
                  {rangeTemplateData1 ? (
                    <TemplateImage
                      src={rangeTemplateData1}
                      btlRadius="10px"
                      btrRadius="10px"
                    />
                  ) : (
                    <Spinner
                      style={{
                        width: '1.8rem',
                        height: '1.8rem',
                        color: preto,
                      }}
                      className="m-auto position-relative"
                    />
                  )}
                </ImageBox>
                <Text
                  className="my-auto"
                  size={px2vw(17)}
                  color={
                    selectedTemplate === rangeTemplateData1
                      ? branco
                      : cinzaKuppi11
                  }
                  alignCenter
                >
                  Selecionar
                </Text>
              </TemplateOption>
            </Col>
            <Col width="auto" height="auto">
              <TemplateOption
                onClick={() => handleSelectedTemplate(rangeTemplateData2)}
                selected={selectedTemplate === rangeTemplateData2}
              >
                <ImageBox width={px2vw(295)} height={px2vw(295)}>
                  {rangeTemplateData2 ? (
                    <TemplateImage
                      src={rangeTemplateData2}
                      btlRadius="10px"
                      btrRadius="10px"
                    />
                  ) : (
                    <Spinner
                      style={{
                        width: '1.8rem',
                        height: '1.8rem',
                        color: preto,
                      }}
                      className="m-auto position-relative"
                    />
                  )}
                </ImageBox>
                <Text
                  className="my-auto"
                  size={px2vw(17)}
                  color={
                    selectedTemplate === rangeTemplateData2
                      ? branco
                      : cinzaKuppi11
                  }
                  alignCenter
                >
                  Selecionar
                </Text>
              </TemplateOption>
            </Col>

            <Col width="auto" height="auto">
              <TemplateOption
                onClick={() => handleSelectedTemplate(rangeTemplateData3)}
                selected={selectedTemplate === rangeTemplateData3}
              >
                <ImageBox width={px2vw(295)} height={px2vw(295)}>
                  {rangeTemplateData3 ? (
                    <TemplateImage
                      src={rangeTemplateData3}
                      btlRadius="10px"
                      btrRadius="10px"
                    />
                  ) : (
                    <Spinner
                      style={{
                        width: '1.8rem',
                        height: '1.8rem',
                        color: preto,
                      }}
                      className="m-auto position-relative"
                    />
                  )}
                </ImageBox>
                <Text
                  className="my-auto"
                  size={px2vw(17)}
                  color={
                    selectedTemplate === rangeTemplateData3
                      ? branco
                      : cinzaKuppi11
                  }
                  alignCenter
                >
                  Selecionar
                </Text>
              </TemplateOption>
            </Col>
            <Col width="auto" height="auto">
              <TemplateOption
                onClick={() => handleSelectedTemplate(rangeTemplateData4)}
                selected={selectedTemplate === rangeTemplateData4}
              >
                <ImageBox width={px2vw(295)} height={px2vw(295)}>
                  {rangeTemplateData4 ? (
                    <TemplateImage
                      src={rangeTemplateData4}
                      btlRadius="10px"
                      btrRadius="10px"
                    />
                  ) : (
                    <Spinner
                      style={{
                        width: '1.8rem',
                        height: '1.8rem',
                        color: preto,
                      }}
                      className="m-auto position-relative"
                    />
                  )}
                </ImageBox>
                <Text
                  className="my-auto"
                  size={px2vw(17)}
                  color={
                    selectedTemplate === rangeTemplateData4
                      ? branco
                      : cinzaKuppi11
                  }
                  alignCenter
                >
                  Selecionar
                </Text>
              </TemplateOption>
            </Col>

            <Col width="auto" height="auto">
              <TemplateOption
                onClick={() => handleSelectedTemplate(rangeTemplateData5)}
                selected={selectedTemplate === rangeTemplateData5}
              >
                <ImageBox width={px2vw(295)} height={px2vw(295)}>
                  {rangeTemplateData5 ? (
                    <TemplateImage
                      src={rangeTemplateData5}
                      btlRadius="10px"
                      btrRadius="10px"
                    />
                  ) : (
                    <Spinner
                      style={{
                        width: '1.8rem',
                        height: '1.8rem',
                        color: preto,
                      }}
                      className="m-auto position-relative"
                    />
                  )}
                </ImageBox>
                <Text
                  className="my-auto"
                  size={px2vw(17)}
                  color={
                    selectedTemplate === rangeTemplateData5
                      ? branco
                      : cinzaKuppi11
                  }
                  alignCenter
                >
                  Selecionar
                </Text>
              </TemplateOption>
            </Col>
          </>
        )}
        {!wasImgUploadedLocally && type === 'conversion' && (
          <>
            <Col width="auto" height="auto">
              <TemplateOption
                onClick={() => handleSelectedTemplate(conversionTemplateData1)}
                selected={selectedTemplate === conversionTemplateData1}
              >
                <ImageBox width={px2vw(295)} height={px2vw(295)}>
                  {conversionTemplateData1 ? (
                    <TemplateImage
                      src={conversionTemplateData1}
                      btlRadius="10px"
                      btrRadius="10px"
                    />
                  ) : (
                    <Spinner
                      style={{
                        width: '1.8rem',
                        height: '1.8rem',
                        color: preto,
                      }}
                      className="m-auto position-relative"
                    />
                  )}
                </ImageBox>
                <Text
                  className="my-auto"
                  size={px2vw(17)}
                  color={
                    selectedTemplate === conversionTemplateData1
                      ? branco
                      : cinzaKuppi11
                  }
                  alignCenter
                >
                  Selecionar
                </Text>
              </TemplateOption>
            </Col>
            <Col width="auto" height="auto">
              <TemplateOption
                onClick={() => handleSelectedTemplate(conversionTemplateData2)}
                selected={selectedTemplate === conversionTemplateData2}
              >
                <ImageBox width={px2vw(295)} height={px2vw(295)}>
                  {conversionTemplateData2 ? (
                    <TemplateImage
                      src={conversionTemplateData2}
                      btlRadius="10px"
                      btrRadius="10px"
                    />
                  ) : (
                    <Spinner
                      style={{
                        width: '1.8rem',
                        height: '1.8rem',
                        color: preto,
                      }}
                      className="m-auto position-relative"
                    />
                  )}
                </ImageBox>
                <Text
                  className="my-auto"
                  size={px2vw(17)}
                  color={
                    selectedTemplate === conversionTemplateData2
                      ? branco
                      : cinzaKuppi11
                  }
                  alignCenter
                >
                  Selecionar
                </Text>
              </TemplateOption>
            </Col>

            <Col width="auto" height="auto">
              <TemplateOption
                onClick={() => handleSelectedTemplate(conversionTemplateData3)}
                selected={selectedTemplate === conversionTemplateData3}
              >
                <ImageBox width={px2vw(295)} height={px2vw(295)}>
                  {conversionTemplateData3 ? (
                    <TemplateImage
                      src={conversionTemplateData3}
                      btlRadius="10px"
                      btrRadius="10px"
                    />
                  ) : (
                    <Spinner
                      style={{
                        width: '1.8rem',
                        height: '1.8rem',
                        color: preto,
                      }}
                      className="m-auto position-relative"
                    />
                  )}
                </ImageBox>
                <Text
                  className="my-auto"
                  size={px2vw(17)}
                  color={
                    selectedTemplate === conversionTemplateData3
                      ? branco
                      : cinzaKuppi11
                  }
                  alignCenter
                >
                  Selecionar
                </Text>
              </TemplateOption>
            </Col>
            <Col width="auto" height="auto">
              <TemplateOption
                onClick={() => handleSelectedTemplate(conversionTemplateData4)}
                selected={selectedTemplate === conversionTemplateData4}
              >
                <ImageBox width={px2vw(295)} height={px2vw(295)}>
                  {conversionTemplateData4 ? (
                    <TemplateImage
                      src={conversionTemplateData4}
                      btlRadius="10px"
                      btrRadius="10px"
                    />
                  ) : (
                    <Spinner
                      style={{
                        width: '1.8rem',
                        height: '1.8rem',
                        color: preto,
                      }}
                      className="m-auto position-relative"
                    />
                  )}
                </ImageBox>
                <Text
                  className="my-auto"
                  size={px2vw(17)}
                  color={
                    selectedTemplate === conversionTemplateData4
                      ? branco
                      : cinzaKuppi11
                  }
                  alignCenter
                >
                  Selecionar
                </Text>
              </TemplateOption>
            </Col>

            <Col width="auto" height="auto">
              <TemplateOption
                onClick={() => handleSelectedTemplate(conversionTemplateData5)}
                selected={selectedTemplate === conversionTemplateData5}
              >
                <ImageBox width={px2vw(295)} height={px2vw(295)}>
                  {conversionTemplateData5 ? (
                    <TemplateImage
                      src={conversionTemplateData5}
                      btlRadius="10px"
                      btrRadius="10px"
                    />
                  ) : (
                    <Spinner
                      style={{
                        width: '1.8rem',
                        height: '1.8rem',
                        color: preto,
                      }}
                      className="m-auto position-relative"
                    />
                  )}
                </ImageBox>
                <Text
                  className="my-auto"
                  size={px2vw(17)}
                  color={
                    selectedTemplate === conversionTemplateData5
                      ? branco
                      : cinzaKuppi11
                  }
                  alignCenter
                >
                  Selecionar
                </Text>
              </TemplateOption>
            </Col>
          </>
        )}
      </ModalContent>
      <ModalFooter>
        <Row className="my-auto" alignCenter>
          <Row
            onClick={handleBacktoPreviousStep}
            justifyStart
            alignCenter
            width="auto"
            cursor="pointer"
          >
            <AiOutlineLeft className="mr-2" /> Voltar
          </Row>
          <Row width="auto" margtop={px2vw(10)} className="mx-auto">
            <ColorBox onClick={() => setSelectedColor('red')}>
              <ColorOptionBorder selected={selectedColor === 'red'}>
                <ColorOption color1={vermelhoKuppi3} color2={vermelhoKuppi4} />
              </ColorOptionBorder>
            </ColorBox>
            <ColorBox onClick={() => setSelectedColor('orange')}>
              <ColorOptionBorder selected={selectedColor === 'orange'}>
                <ColorOption color1={laranjaKuppi2} color2={laranjaKuppi3} />
              </ColorOptionBorder>
            </ColorBox>
            <ColorBox onClick={() => setSelectedColor('green')}>
              <ColorOptionBorder selected={selectedColor === 'green'}>
                <ColorOption color1={verdeKuppi21} color2={verdeKuppi18} />
              </ColorOptionBorder>
            </ColorBox>
            <ColorBox onClick={() => setSelectedColor('blue')}>
              <ColorOptionBorder selected={selectedColor === 'blue'}>
                <ColorOption color1={azulKuppi5} color2={azulKuppi6} />
              </ColorOptionBorder>
            </ColorBox>
            <ColorBox onClick={() => setSelectedColor('gray')}>
              <ColorOptionBorder selected={selectedColor === 'gray'}>
                <ColorOption color1={branco} color2={cinzaKuppi3} />
              </ColorOptionBorder>
            </ColorBox>
          </Row>
          <Row width="auto">
            <ModalPrimaryButton
              onClick={handleGotoNextStep}
              disabled={!selectedTemplate}
              className="ml-auto"
            >
              Avançar
            </ModalPrimaryButton>
          </Row>
        </Row>
      </ModalFooter>
    </>
  );
}
