import { AddressFields, hasAtLeastOneOf } from '../utils/Adresses';
import { apiGlobal } from './api';

export const getAddressList = async (address) => {
  const response = await apiGlobal.post('/get-address-list', {
    address,
  });

  return response.data;
};

export const getAddressDetails = async (placeId, callback) => {
  const response = await apiGlobal.post('/get-address-details', {
    placeId,
  });

  if (callback) {
    callback();
  }

  return response.data;
};

const getAddressComponentValue = (addressComponent, fieldValue) =>
  !!addressComponent ? addressComponent[fieldValue] : '';

const getFromAddressComponents = (
  addressComponents,
  condition,
  fieldValue = 'long_name'
) => {
  let foundComponents = addressComponents.filter(condition);
  const componentValue =
    foundComponents.length > 1
      ? foundComponents
          .map((component) => getAddressComponentValue(component, fieldValue))
          .join(' ')
      : getAddressComponentValue(foundComponents.pop(), fieldValue);
  return componentValue;
};

export const getFieldFromAddressComponents = (field, addressComponents) => {
  switch (field) {
    case AddressFields.STREET:
      return getFromAddressComponents(addressComponents, (component) =>
        hasAtLeastOneOf(
          component.types,
          'street_address',
          'route',
          'sublocality_level_2',
          'sublocality_level_3'
        )
      );
    case AddressFields.NUMBER:
      return getFromAddressComponents(addressComponents, (component) =>
        hasAtLeastOneOf(component.types, 'street_number')
      );
    case AddressFields.NEIGHBORHOOD:
      return getFromAddressComponents(addressComponents, (component) =>
        hasAtLeastOneOf(
          component.types,
          'sublocality_level_1',
          'administrative_area_level_4'
        )
      );
    case AddressFields.CITY:
      return getFromAddressComponents(addressComponents, (component) =>
        hasAtLeastOneOf(component.types, 'administrative_area_level_2')
      );
    case AddressFields.STATE:
      return getFromAddressComponents(
        addressComponents,
        (component) =>
          hasAtLeastOneOf(component.types, 'administrative_area_level_1'),
        'short_name'
      );
    case AddressFields.ZIPCODE:
      return getFromAddressComponents(addressComponents, (component) =>
        hasAtLeastOneOf(component.types, 'postal_code')
      );
    default:
      break;
  }
};

export const getLatLngByAddressFields = async (fields) => {
  const formattedAddres = `${fields.street}, ${fields.number} ${fields.neighborhood} ${fields.city} ${fields.state} ${fields.zipcode}`;
  const response = await apiGlobal.post('/get-address', {
    address: formattedAddres,
  });
  return response.data;
};
