import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CampaignType from './Step1-CampaignType'

import { ModalBackground, ModalContent } from './styles';
import CampaignItem from './Step2-CampaignItem';
import CampaignTemplate from './Step3-CampaignTemplate';
import CampaignAds from './Step4-CampaignAds';
import CampaignDetails from './Step5-CampaignDetails';
import { cleanCampaignData } from '../../../store/modules/kuppiBoost/actions';

export default function Campaign(props) {
  const dispatch = useDispatch()
  const kuppiBoostData = useSelector((state) => state.kuppiBoost);
  const currentStep = kuppiBoostData.campaign?.currentStep;

  useEffect(() => {
    return () => {
      dispatch(cleanCampaignData())
    };
  }, []);

  return (
    <ModalBackground isVisible={props.isVisible}>
      <ModalContent
        width={props.width}
        height={props.height}
        bgcolor={props.bgcolor}
        margtop={props.margtop}
        padding={props.padding}
        backgroundImg={props.backgroundImg}
        backgroundImgSize={props.backgroundImgSize}
        maxheight={props.maxheight}
      >
        {currentStep === '1' && <CampaignType onClose={props.onClose} />}
        {currentStep === '2' && <CampaignItem onClose={props.onClose} />}
        {currentStep === '3' && <CampaignTemplate onClose={props.onClose} />}
        {currentStep === '4' && <CampaignAds onClose={props.onClose} />}
        {currentStep === '5' && <CampaignDetails onClose={props.onClose} />}
      </ModalContent>
    </ModalBackground>
  );
}
