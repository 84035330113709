import React from 'react';
import ChatBar from '../../pages/Whatsappchat/ChatBar';
import { Row } from '../../styles/grid';
import px2vw from '../../utils/responsiveness/px2vw';
import { FiMaximize } from 'react-icons/fi';
import { FiChevronUp } from 'react-icons/fi';
import { FiChevronDown } from 'react-icons/fi';

import {
  GlobalChatContainer,
  GlobalChatHeader,
  MinimizedGlobalChatHeader,
  Span,
} from './style';
import { useDispatch, useSelector } from 'react-redux';
import { setIsChatMinimized } from '../../store/modules/whatsappChat/actions';
import {  whatsappchat } from '../../routes/routeMap';
import { Link } from 'react-router-dom';
import { preto } from '../../styles/colors';
import WppMessageCounter from '../WppMessageCounter';
import { updateConversations } from '../../store/modules/whatsappChatConversations/actions';

export default function GlobalWppChat() {
  const { isChatMinimized } = useSelector((state) => state?.whatsappChat);
  const dispatch = useDispatch();

  const setIsMinimized = (status) => {
    dispatch(setIsChatMinimized(status));
  };

  const handleOpenMinimizedChat = () => {
    dispatch(updateConversations());
    setIsMinimized(false);
  }

  if (isChatMinimized) {
    return (
      <MinimizedGlobalChatHeader
        justifyCenter
        alignCenter
        onClick={() => handleOpenMinimizedChat()}
      >
        <WppMessageCounter />
        <Span>Mensagens</Span>
        <FiChevronUp
          className="my-auto ml-auto"
          style={{ marginRight: px2vw(15), cursor: 'pointer' }}
        />
        <Link to={whatsappchat} as="div">
          <FiMaximize
            className="my-auto cursor-pointer"
            style={{ cursor: 'pointer', color: preto }}
          />
        </Link>
      </MinimizedGlobalChatHeader>
    );
  }

  return (
    <GlobalChatContainer>
      <GlobalChatHeader onClick={() => setIsMinimized(true)}>
        <WppMessageCounter />
        <Span>Mensagens</Span>
        <FiChevronDown
          className="my-auto ml-auto"
          style={{ marginRight: px2vw(15), cursor: 'pointer' }}
          onClick={() => setIsMinimized(true)}
        />
        <Link to={whatsappchat}>
          <FiMaximize
            className="my-auto cursor-pointer"
            style={{ cursor: 'pointer', color: preto }}
          />
        </Link>
      </GlobalChatHeader>
      <Row
        width="98%"
        background="black"
        minheight={px2vw(1)}
        className="mx-auto"
      ></Row>
      <ChatBar />
    </GlobalChatContainer>
  );
}
