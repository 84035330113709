import produce from 'immer';

const INITIAL_STATE = {
  complements: [],
  editingAmountComplementID: null,
  updateComplementAmountLoading: false,
  updateComplementStatusLoading: false,
  updateComplementStatusSuccess: false,
  deleteComplementLoading: false,
  deleteComplementSuccess: false,
};

export default function massHandleComplements(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@massHandleComplements/SET_COMPLEMENTS': {
        draft.complements = action.payload;
        break;
      }
      case '@massHandleComplements/SET_IS_EDITING_AMOUNT': {
        draft.editingAmountComplementID = action.payload;
        break;
      }
      case '@massHandleComplements/UPDATE_COMPLEMENT_AMOUNT_REQUEST': {
        draft.updateComplementAmountLoading = true;
        break;
      }
      case '@massHandleComplements/UPDATE_COMPLEMENT_AMOUNT_SUCCESS': {
        draft.complements = state.complements.map((c) =>
          c.id === action.payload.id
            ? { ...c, amount: action.payload.amount }
            : c
        );
        draft.updateComplementAmountLoading = false;
        draft.editingAmountComplementID = null;
        break;
      }
      case '@massHandleComplements/UPDATE_COMPLEMENT_AMOUNT_FAILURE': {
        draft.updateComplementAmountLoading = false;
        break;
      }
      case '@massHandleComplements/UPDATE_COMPLEMENT_STATUS_REQUEST': {
        draft.updateComplementStatusLoading = true;
        draft.updateComplementStatusSuccess = false;
        break;
      }
      case '@massHandleComplements/UPDATE_COMPLEMENT_STATUS_SUCCESS': {
        draft.complements = state.complements.map((c) =>
          action.payload.list.includes(c.id)
            ? { ...c, is_active: action.payload.status }
            : c
        );
        draft.updateComplementStatusLoading = false;
        draft.updateComplementStatusSuccess = true;
        break;
      }
      case '@massHandleComplements/UPDATE_COMPLEMENT_STATUS_FAILURE': {
        draft.updateComplementStatusLoading = false;
        draft.updateComplementStatusSuccess = false;
        break;
      }
      case '@massHandleComplements/DELETE_COMPLEMENTS_REQUEST': {
        draft.deleteComplementLoading = true;
        break;
      }
      case '@massHandleComplements/DELETE_COMPLEMENTS_SUCCESS': {
        draft.complements = state.complements.filter((c) =>
          !action.payload.list.includes(c.id)
        );
        draft.deleteComplementLoading = false;
        draft.deleteComplementSuccess = true;
        break;
      }
      case '@massHandleComplements/DELETE_COMPLEMENTS_FAILURE': {
        draft.deleteComplementLoading = false;
        draft.deleteComplementSuccess = false;
        break;
      }

      default:
    }
  });
}
