import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';

import { getURL } from '../../../utils/utils';
import { sendOnboardingInfo } from '../../../services/vendors/mixpanel';

import StorePublicityWeb from './Web';
import StorePublicityMobile from './Mobile';

import { dashboard, askDownloadApp } from '../../../routes/routeMap';

import history from '../../../services/history';

import { updateOnboardingStepRequest } from '../../../store/modules/onboarding/actions';

export default function StorePublicity({ isWeb }) {
  const [currTabSelected, setCurrTabSelected] = useState(
    'tabInstagram'
  );

  const advertiserUser = useSelector((state) => state.user.user);
  const name = advertiserUser.advertiserInfo.name;
  const webMenuDomain = `${getURL('cardapio_web').replace('$$advertiser_slug$$', advertiserUser.advertiserInfo.slug)}`;
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useDispatch();
  const text = `Olá! Seja bem-vindo(a) ao ${name}!\n\nPara realizar o seu pedido, e conferir nossas promoções exclusivas e cartão fidelidade, basta clicar no link abaixo! 👇🏼\n\n ${webMenuDomain}\n\nCaso tenha alguma dúvida, mande por aqui e já te atenderemos.\n\nAgradecemos sua compreensão e preferência 🥰`;
  const linkToGuide = process.env.REACT_APP_LINK_TO_GUIDE_WPP_OCTADESK;

  const updateOnboardingStepLoading = useSelector(
    (state) => state.onboarding.updateOnboardingStepLoading
  );
  const currOnboardingStep = useSelector(
    (state) => state.onboarding.currOnboardingStep
  );

  const hasFreeTrialValid = useSelector((state) => state.user.hasFreeTrialValid);

  useEffect(() => {
    if (currOnboardingStep === 'finished') {
      setOpenModal(true);
    }
  }, [currOnboardingStep])

  function sendMixPanelFinishedOnboardingEvent(eventType) {
    //MixPanel Event
    sendOnboardingInfo({
      id: advertiserUser.advertiserInfo.id,
      name: advertiserUser.advertiserInfo.name,
      email: advertiserUser.email,
      type: eventType,
      currOnboardingStep: 'Concluded',
      hasFreeTrialValid: hasFreeTrialValid
    });
  }

  function accessPlatform() {
    if (isWeb) {
      sendMixPanelFinishedOnboardingEvent('finished-AccessDashboard');
      history.push(dashboard);
    } else {
      sendMixPanelFinishedOnboardingEvent('finished-AccessAskDownloadApp');
      history.push(askDownloadApp)
    }
  }

  function handleCopy(id) {
    let content = document.getElementById(id);
    content.select();
    document.execCommand('copy');

    toast.info("Copiado para a área de transferência!");
  }

  function handleFinishStep() {
    dispatch(updateOnboardingStepRequest('finished'));
  }

  const handler = {
    currTabSelected: currTabSelected,
    openModal: openModal,
    updateOnboardingStepLoading: updateOnboardingStepLoading,
    currOnboardingStep: currOnboardingStep,
    setCurrTabSelected: (v) => setCurrTabSelected(v),
    handleCopy: (id) => handleCopy(id),
    handleFinishStep: () => handleFinishStep(),
    accessPlatform: () => accessPlatform(),
    sendMixPanelFinishedOnboardingEvent: (eventType) => sendMixPanelFinishedOnboardingEvent(eventType),
    webMenuDomain: webMenuDomain,
    linkToGuide: linkToGuide,
    text: text,
    hasFreeTrialValid: hasFreeTrialValid,
  }

  if (isWeb) {
    return <StorePublicityWeb handler={handler} />;
  } else {
    return <StorePublicityMobile handler={handler} />;
  }
}
