import styled from 'styled-components';

import { branco, cinzaKuppi1 } from '../../styles/colors';

import px2vw from '../../utils/responsiveness/px2vw';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-itens: center;
  justify-content: center;
  background-color: ${cinzaKuppi1};
  width: 99.4vw;
  height: auto;
`;

export const LogoKuppi = styled.img`
  height: ${px2vw(106.8)};
  width: ${px2vw(196.8)};
  margin-right: ${px2vw(40)};
  margin-top: ${px2vw(20)};
`;

export const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${branco};
  border-radius: ${(props) => props.borderRadius || px2vw(20)};
  width: ${(props) => props.width || px2vw(760)};
  padding: ${(props) => props.pad || px2vw(40)};
  height: auto;
  min-height: ${(props) => props.minHeight || '90vh'};
  margin-top: ${px2vw(30)};
  margin-bottom: ${px2vw(30)};
`;

export const ErrorMessage = styled.div`
  font-size: ${(props) => props.fontSize || px2vw(20)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.72px;
  text-align: center;
`;
