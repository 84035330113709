import styled from 'styled-components';

import px2vw from '../../../utils/responsiveness/px2vw';
import { branco, verdeKuppi4 } from '../../../styles/colors';

export const PaymentMethodRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: ${(props) => props.isMobileOnboarding ? px2vw(50) : px2vw(5)};
`;

export const PaymentMethodOption = styled.button`
  background-color: ${branco};
  height: ${(props) => props.isMobileOnboarding ? px2vw(120) : px2vw(25)};
  width: auto;
  min-width: ${(props) => props.isMobileOnboarding ? px2vw(340) : px2vw(85)};
  border: 1px solid;
  border-radius: ${(props) => props.isMobileOnboarding ? px2vw(25) : px2vw(5)};
  padding-left: ${(props) => props.isMobileOnboarding ? px2vw(25) : px2vw(8)};
  padding-right: ${(props) => props.isMobileOnboarding ? px2vw(25) : px2vw(8)};
  margin-bottom: ${px2vw(2)};
  margin-right: ${px2vw(2)};
  margin-left: ${(props) => props.isMobileOnboarding ? px2vw(35) : px2vw(2)};

  ${(props) =>
    props.isChecked &&
    `
    background-color: ${verdeKuppi4};
    border: 1px solid ${verdeKuppi4};
  `};

  ${(props) =>
    props.disabled &&
    `
    opacity: 0.2;
  `};
`;

export const AccountConfigureHere = styled.span`
  cursor: pointer;
  text-decoration: underline;
`