import React from 'react';

import {
  ModalBackground,
  ModalContent,
} from './styles';

export default function Modal(props) {
  return (
    <ModalBackground isVisible={props.isVisible}>
      <ModalContent
        width={props.width}
        height={props.height}
        bgcolor={props.bgcolor}
        margtop={props.margtop}
        padding={props.padding}
        backgroundImg={props.backgroundImg}
        backgroundImgSize={props.backgroundImgSize}
        maxheight={props.maxheight}
      >
        {props.children}
      </ModalContent>
    </ModalBackground>
  );
}
