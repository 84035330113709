import React, {
  Fragment,
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { Icon } from 'react-materialize';
import { parse } from 'date-fns';

import { Row, Col } from '../../styles/grid';
import px2vw from '../../utils/responsiveness/px2vw';
import {
  formatCurrencyBRL,
  definePlanName,
  definePlanPeriodBasedValidity,
  getURL,
} from '../../utils/utils';

import {
  Header,
  Content,
  Hr,
  TableRow,
  HeaderButton,
  PagButton,
  PrevIcon,
  NextIcon,
  ErrorText,
  StatusBox,
  PaymentLinkButton,
} from './styles';

import { SvgBox, Arrow, Arrow2 } from './icons';
import { sort, sortByColumnValue, makePages } from './tableOptions';

import TablePerPage from '../TablePerPage';
import {
  vermelhoKuppi1,
  amareloKuppi1,
  verdeKuppi4,
} from '../../styles/colors';

export default function SubscriptionsTable({ data }) {
  const [rows, setRows] = useState([]);
  const [rowsCurrentPage, setRowsCurrentPage] = useState();
  const [structure, setStructure] = useState(null);
  const [currentPage, setCurrentPage] = useState('1');
  const [lastPage, setLastPage] = useState();
  const [firstPage] = useState('1');
  const [sortedCol, setSortedCol] = useState('');

  useEffect(() => {
    if (structure) {
      setLastPage(
        Object.keys(structure)[Object.keys(structure).length - 1].toString()
      );
    }
  }, [structure]);

  useMemo(() => {
    setStructure(makePages(rows.length, rowsCurrentPage));
  }, [rows, rowsCurrentPage]);

  useEffect(() => {
    setRows(data);
  }, [data]);

  useEffect(() => {
    if (rows.length < 5) {
      setRowsCurrentPage(rows.length);
    }
    if (rowsCurrentPage > 5) {
    } else {
      setRowsCurrentPage(5);
    }
  }, [rows]);

  const handleSort = useCallback(
    (col) => {
      if (sortedCol !== col) {
        setSortedCol(col);
        setRows(sort(rows, col, 'asc'));
      } else {
        setSortedCol('');
        setRows(sort(rows, col, 'desc'));
      }
    },
    [rows]
  );

  const handleSortByColumnValue = useCallback(
    (col) => {
      if (sortedCol !== col) {
        setSortedCol(col);
        setRows(sortByColumnValue(rows, col, 'asc'));
      } else {
        setSortedCol('');
        setRows(sortByColumnValue(rows, col, 'desc'));
      }
    },
    [rows]
  );

  function handlePerPage(size) {
    setRowsCurrentPage(size);
  }

  function handleChangePage(direction) {
    if (direction === 'next') {
      const curr = +currentPage + 1;
      setCurrentPage(curr.toString());
    } else if (direction === 'prev') {
      const curr = +currentPage - 1;
      setCurrentPage(curr.toString());
    }
  }

  function defineStatusColor(subscription) {
    const startDate = new Date(parse(subscription.start_validity_at, 'dd/MM/yyyy', new Date()).setHours(23, 59, 59))
    const isDuePayment = startDate - new Date() < 0 && subscription.status !== 'paid';

    if (subscription.status === 'refunded') {
      return vermelhoKuppi1;
    } 
    if (isDuePayment) {
      return vermelhoKuppi1;
    }
    if (
      subscription.status === 'not_paid' ||
      subscription.status === 'waiting_payment'
    ) {
      return amareloKuppi1;
    }
    return verdeKuppi4;
  }

  function defineStatusText(subscription) {
    const startDate = new Date(parse(subscription.start_validity_at, 'dd/MM/yyyy', new Date()).setHours(23, 59, 59))
    const isDuePayment = startDate - new Date() < 0 && subscription.status !== 'paid';

    if (subscription.status === 'refunded') {
      return 'Reembolso efetuado';
    } 
    if (isDuePayment) {
      return 'Pagamento atrasado';
    }
    if (
      subscription.status === 'not_paid' ||
      subscription.status === 'waiting_payment'
    ) {
      return 'Pagamento pendente';
    }
    return 'Assinatura Paga';
  }

  function openPaymentLink(paymentToken) {
    window.open(
      getURL('anunciante') + process.env.REACT_APP_PAYMENT_LINK + paymentToken,
      '_blank'
    );
  }

  return (
    <Col padding={`0 ${px2vw(15)}`} alignCenter justifyCenter>
      <Row height={px2vw(10)} />
      {structure ? (
        rows.map((subscription, index) =>
          structure[currentPage] &&
          structure[currentPage].start <= index + 1 &&
          structure[currentPage].end >= index + 1 ? (
            <Fragment key={subscription.id}>
              <TableRow alignCenter justifyCenter>
                <Col>
                  <Content margb={px2vw(5)}>
                    <Col>
                      <Header onClick={() => handleSort('id')}>
                        <HeaderButton>
                          {`Fatura #${subscription.id}`}
                          <SvgBox sorted={sortedCol === 'id'}>
                            <Arrow />
                            <Arrow2 />
                          </SvgBox>
                        </HeaderButton>
                      </Header>
                      <Row justifyStart>
                        <StatusBox
                          backgroundColor={defineStatusColor(subscription)}
                        >
                          {defineStatusText(subscription)}
                        </StatusBox>
                      </Row>
                    </Col>
                  </Content>
                  <Content>
                    <Col>
                      <Header onClick={() => handleSortByColumnValue('plan')}>
                        <HeaderButton>
                          Plano
                          <SvgBox sorted={sortedCol === 'plan'}>
                            <Arrow />
                            <Arrow2 />
                          </SvgBox>
                        </HeaderButton>
                      </Header>
                      <Row justifyStart>
                        {definePlanName(
                          subscription.start_validity_at,
                          subscription.finish_validity_at,
                          subscription.payment_type === 'free',
                          subscription.subscription.period,
                          subscription.subscription.name
                        )}
                      </Row>
                    </Col>
                  </Content>
                </Col>
                <Col>
                  <Content margb={px2vw(15)}>
                    <Col>
                      <Header onClick={() => handleSortByColumnValue('period')}>
                        <HeaderButton>
                          Período do contrato
                          <SvgBox sorted={sortedCol === 'period'}>
                            <Arrow />
                            <Arrow2 />
                          </SvgBox>
                        </HeaderButton>
                      </Header>
                      <Row justifyStart>
                        {definePlanPeriodBasedValidity(
                          subscription.start_validity_at,
                          subscription.finish_validity_at,
                          subscription.payment_type === 'free',
                          subscription.subscription.period
                        )}
                      </Row>
                    </Col>
                  </Content>
                  <Content>
                    <Col>
                      <Header
                        onClick={() => handleSortByColumnValue('vigency')}
                      >
                        <HeaderButton>
                          Vigência
                          <SvgBox sorted={sortedCol === 'vigency'}>
                            <Arrow />
                            <Arrow2 />
                          </SvgBox>
                        </HeaderButton>
                      </Header>
                      <Row justifyStart>
                        {`${subscription.start_validity_at} à ${subscription.finish_validity_at}`}
                      </Row>
                    </Col>
                  </Content>
                </Col>
                <Col>
                  <Content margb={px2vw(15)}>
                    <Col>
                      <Header
                        onClick={() =>
                          handleSortByColumnValue('payment_method')
                        }
                      >
                        <HeaderButton>
                          Forma de pagamento
                          <SvgBox sorted={sortedCol === 'payment_method'}>
                            <Arrow />
                            <Arrow2 />
                          </SvgBox>
                        </HeaderButton>
                      </Header>
                      <Row justifyStart>
                        {subscription.payment_type === 'free' && '-'}
                        {subscription.payment_type === 'pix' && 'PIX'}
                        {subscription.payment_type === 'boleto' && 'Boleto'}
                        {subscription.payment_type === 'credit_card' &&
                          subscription.paymentCard &&
                          `Cartão de Crédito ${subscription.paymentCard.brand.toUpperCase()} ****${
                            subscription.paymentCard.last_digits
                          }`}
                        {subscription.payment_type === 'credit_card' &&
                          !subscription.paymentCard &&
                          `Cartão de Crédito`}
                        {!subscription.payment_type && 'Não definida'}
                      </Row>
                    </Col>
                  </Content>
                  <Content>
                    <Col>
                      <Header onClick={() => handleSortByColumnValue('amount')}>
                        <HeaderButton>
                          Valor
                          <SvgBox sorted={sortedCol === 'amount'}>
                            <Arrow />
                            <Arrow2 />
                          </SvgBox>
                        </HeaderButton>
                      </Header>
                      <Row justifyStart>
                        {subscription.payment_type === 'free' && '-'}
                        {subscription.payment_type !== 'free' &&
                          `${formatCurrencyBRL(
                            (
                              Math.round(
                                subscription.amount.toFixed(2) *
                                  subscription.subscription.period *
                                  100
                              ) / 100
                            ).toFixed(2),
                            {}
                          )}`}
                      </Row>
                    </Col>
                  </Content>
                </Col>

                <Col>
                  <Content
                    margb={
                      subscription.status !== 'paid' && subscription.status !== 'refunded' ? px2vw(24) : px2vw(64)
                    }
                  >
                    <Col>
                      <Header onClick={() => handleSort('paid_at')}>
                        <HeaderButton>
                          Data de pagamento
                          <SvgBox sorted={sortedCol === 'paid_at'}>
                            <Arrow />
                            <Arrow2 />
                          </SvgBox>
                        </HeaderButton>
                      </Header>
                      <Row justifyStart>
                        {(!subscription.paid_at ||
                          subscription.payment_type === 'free') &&
                          '-'}
                        {subscription.payment_type !== 'free' &&
                          subscription.paid_at}
                      </Row>
                    </Col>
                  </Content>

                  <Content>
                    <Col>
                      <Row justifyStart>
                        {subscription.status !== 'paid' && subscription.status !== 'refunded' && (
                          <PaymentLinkButton
                            onClick={() =>
                              openPaymentLink(subscription.payment_token)
                            }
                          >
                            Efetuar pagamento
                          </PaymentLinkButton>
                        )}
                      </Row>
                    </Col>
                  </Content>
                </Col>
              </TableRow>
              <Row>
                <Hr />
              </Row>
            </Fragment>
          ) : null
        )
      ) : (
        <Col height="20vh" margtop={px2vw(40)}>
          <Row alignCenter justifyCenter>
            {/* TODO: Ajustar tamanho do icone */}
            <Icon large>sentiment_very_dissatisfied</Icon>
            <ErrorText>Você não tem pagamentos a serem exibidos.</ErrorText>
          </Row>
        </Col>
      )}
      {structure && (
        <Row alignCenter justifyEnd>
          <Row alignCenter justifyEnd height={px2vw(40)} margin={px2vw(10)}>
            <TablePerPage response={handlePerPage} length={rows.length} />
            <Row alignCenter justifyCenter width="10%">
              {!structure[currentPage] && handleChangePage('prev')}
              {structure[currentPage] && structure[currentPage].start} -{' '}
              {structure[currentPage] && structure[currentPage].end} de{' '}
              {rows.length}
            </Row>
            <PagButton
              onClick={() => handleChangePage('prev')}
              disabled={currentPage === firstPage}
              title="Página anterior"
            >
              <PrevIcon disabled={currentPage === firstPage} />
            </PagButton>
            <PagButton
              onClick={() => handleChangePage('next')}
              title="Próxima página"
              disabled={currentPage === lastPage}
            >
              <NextIcon disabled={currentPage === lastPage} />
            </PagButton>
          </Row>
        </Row>
      )}
    </Col>
  );
}
