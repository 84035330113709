import React from 'react';
import { Spinner } from 'reactstrap';

import px2vw from '../../../../utils/responsiveness/px2vw';
import { preto, cinzaKuppi5 } from '../../../../styles/colors';
import { Row, Col } from '../../../../styles/grid';

import ButtonPrimary from '../../../../components/ButtonPrimary';

import { LoadingBox } from '../../../../styles/common';

import Checkbutton from '../../../../components/Checkbox/Checkbutton';

import {
  QuestionTitleText,
  FormData,
  CheckbuttomContainer,
  InputData,
  SubText,
} from '../../BaseScreen/styles';

import { 
  QuestionFiveAnswer1, QuestionFiveAnswer2, QuestionFiveAnswer3, 
  QuestionFiveAnswer4, QuestionFiveAnswer5, QuestionFiveTitle, 
  QuestionFourAnswer1, QuestionFourAnswer2, QuestionFourAnswer3, 
  QuestionFourTitle, QuestionSixTitle, QuestionSixAnswer1,
  QuestionSixAnswer2, QuestionSixAnswer2SubText, QuestionSixAnswer1SubText, 
  QuestionSixPercent
} from '../constants';

export default function PageThreeWeb({ handler }) {
  return (
    <Col alignCenter>
      <FormData onSubmit={handler.handleStoreDataSubmit} web>
        <Row margtop={px2vw(50)} width="auto" justifyStart>
          <QuestionTitleText fontsize={px2vw(20)}>
            {QuestionFourTitle}
          </QuestionTitleText>
        </Row>
        <Row margtop={px2vw(15)} width="auto" justifyStart>
          <Col width="auto">
            <CheckbuttomContainer
              width='auto'
              padright={px2vw(10)}
              height={px2vw(35)}
              onClick={() => handler.deliveryTypeClick('delivery_only')}
            >
              <Checkbutton
                label={QuestionFourAnswer1}
                checked={handler.deliveryOnly}
              />
            </CheckbuttomContainer>
          </Col>
          <Col width="auto" margleft={px2vw(20)}>
            <CheckbuttomContainer
              width='auto'
              padright={px2vw(10)}
              height={px2vw(35)}
              onClick={() => handler.deliveryTypeClick('delivery_and_physical')}
            >
              <Checkbutton
                label={QuestionFourAnswer2}
                checked={handler.deliveryAndPhysical}
              />
            </CheckbuttomContainer>
          </Col>
          <Col width="auto" margleft={px2vw(20)}>
            <CheckbuttomContainer
              width='auto'
              padright={px2vw(10)}
              height={px2vw(35)}
              onClick={() => handler.deliveryTypeClick('physical_only')}
            >
              <Checkbutton
                label={QuestionFourAnswer3}
                checked={handler.physicalOnly}
              />
            </CheckbuttomContainer>
          </Col>
        </Row>
        <Row margtop={px2vw(20)} width="auto" justifyStart>
          <QuestionTitleText fontsize={px2vw(20)}>
            {QuestionFiveTitle}
          </QuestionTitleText>
        </Row>
        <Row margtop={px2vw(10)} justifyStart>
          <Col width="auto">
            <CheckbuttomContainer
              width='auto'
              padright={px2vw(10)}
              height={px2vw(35)}
              onClick={() => handler.dailyOrdersAvgClick('no_orders')}
            >
              <Checkbutton
                label={QuestionFiveAnswer1}
                checked={handler.noOrders}
              />
            </CheckbuttomContainer>
          </Col>
          <Col width="auto" margleft={px2vw(20)}>
            <CheckbuttomContainer
              width='auto'
              padright={px2vw(10)}
              height={px2vw(35)}
              onClick={() => handler.dailyOrdersAvgClick('01_05_orders')}
            >
              <Checkbutton
                label={QuestionFiveAnswer2}
                checked={handler.firstOrderRange}
              />
            </CheckbuttomContainer>
          </Col>
          <Col width="auto" margleft={px2vw(20)}>
            <CheckbuttomContainer
              width='auto'
              padright={px2vw(10)}
              height={px2vw(35)}
              onClick={() => handler.dailyOrdersAvgClick('06_10_orders')}
            >
              <Checkbutton
                label={QuestionFiveAnswer3}
                checked={handler.secondOrderRange}
              />
            </CheckbuttomContainer>
          </Col>
        </Row>
        <Row justifyStart >
          <Col width='auto'>
            <CheckbuttomContainer
              width='auto'
              padright={px2vw(10)}
              height={px2vw(35)}
              onClick={() => handler.dailyOrdersAvgClick('11_30_orders')}
            >
              <Checkbutton
                label={QuestionFiveAnswer4}
                checked={handler.thirdOrderRange}
              />
            </CheckbuttomContainer>
          </Col>
          <Col width="auto" margleft={px2vw(20)}>
            <CheckbuttomContainer
              width='auto'
              padright={px2vw(10)}
              height={px2vw(35)}
              onClick={() => handler.dailyOrdersAvgClick('more_30_orders')}
            >
              <Checkbutton
                label={QuestionFiveAnswer5}
                checked={handler.fourthOrderRange}
              />
            </CheckbuttomContainer>
          </Col>
        </Row>
        <Row margtop={px2vw(20)} width="auto" justifyStart>
          <QuestionTitleText fontsize={px2vw(20)}>
            {QuestionSixTitle}
          </QuestionTitleText>
        </Row>
        <Row margtop={px2vw(15)} width="auto" justifyStart>
          <Row width="auto">
            <Col>
              <QuestionTitleText fontsize={px2vw(16)}>
                {QuestionSixAnswer1}
              </QuestionTitleText>
              <Row width="auto">
                <SubText>{QuestionSixAnswer1SubText}</SubText>
              </Row>
            </Col>
            <Col width="auto" margtop={px2vw(10)} margleft={px2vw(10)}>
              <InputData
                type="text"
                name="percentage1"
                textalign="center"
                maxLength="3"
                disabled={handler.noOrders ? true : false}
                onClick={() => document.getElementById('percentage1').value = ''}
                value={handler.salesOwnChannelPercentage}
                width={px2vw(70)}
                height={px2vw(30)}
                border={`${px2vw(1)} solid ${cinzaKuppi5}`}
                onChange={handler.percentageChange}
              />
            </Col>
            <Col margtop={px2vw(10)} margleft={px2vw(5)} width="auto">
              <SubText fontsize={px2vw(20)} fontweight="bold">
                {QuestionSixPercent}
              </SubText>
            </Col>
          </Row>
          <Row margleft={px2vw(100)} width="auto">
            <Col>
              <QuestionTitleText fontsize={px2vw(16)}>
                {QuestionSixAnswer2}
              </QuestionTitleText>
              <Row width="auto">
                <SubText>{QuestionSixAnswer2SubText}</SubText>
              </Row>
            </Col>
            <Col width="auto" margtop={px2vw(10)} margleft={px2vw(10)}>
              <InputData
                type="text"
                name="percentage2"
                textalign="center"
                maxLength="3"
                disabled={handler.noOrders ? true : false}
                onClick={() => document.getElementById('percentage2').value = ''}
                value={handler.salesMarketplacesPercentage}
                width={px2vw(70)}
                height={px2vw(30)}
                border={`${px2vw(1)} solid ${cinzaKuppi5}`}
                onChange={handler.percentageChange}
              />
            </Col>
            <Col margtop={px2vw(10)} margleft={px2vw(5)} width="auto">
              <SubText fontsize={px2vw(20)} fontweight="bold">
                {QuestionSixPercent}
              </SubText>
            </Col>
          </Row>
        </Row>
        <Row justifyEnd margtop={px2vw(20)}>
          <ButtonPrimary minWidth={150} type="submit">
            {handler.updateSurveyStoreDataLoading ? (
              <LoadingBox>
                <Spinner
                  style={{
                    width: '1.8rem',
                    height: '1.8rem',
                    color: preto,
                  }}
                />{' '}
              </LoadingBox>
            ) : (
              'Concluir'
            )}
          </ButtonPrimary>
        </Row>
      </FormData>
    </Col>
  );
}
