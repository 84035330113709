import React from 'react';

import px2vw from '../../utils/responsiveness/px2vw';

import {
  ModalBackground,
  ModalContent,
  ModalPrimaryButtonText,
  ModalPrimaryButton,
  ModalSecundaryButton,
  ModalSecundaryButtonText,
  ModalHeader,
  ModalTitleText,
  CloseImage,
  ModalActions,
} from './styles';

export default function DisconnectWhatsappbotModal(props) {
  return (
    <ModalBackground isVisible={props.isVisible}>
      <ModalContent
        width={props.width}
        height={props.height}
        bgcolor={props.bgcolor}
        margtop={props.margtop}
        padding={props.padding}
        backgroundImg={props.backgroundImg}
        backgroundImgSize={props.backgroundImgSize}
        maxheight={props.maxheight}
      >
        <ModalHeader>
          <ModalTitleText>Desconectar Whatsapp</ModalTitleText>
          <CloseImage
            src={require('../../assets/images/close/close.png')}
            onClick={() => props.closeModal()}
          />
        </ModalHeader>
        <ModalHeader>Tem certeza que deseja desconectar o Whatsapp?</ModalHeader>
        <ModalActions justifyContent="center">
          <ModalPrimaryButton onClick={() => props.closeModal()}>
            <ModalPrimaryButtonText>Cancelar</ModalPrimaryButtonText>
          </ModalPrimaryButton>
          <ModalSecundaryButton margleft={px2vw(15)} onClick={() => props.disconnectWhatsapp()}>
            <ModalSecundaryButtonText>Confirmar</ModalSecundaryButtonText>
          </ModalSecundaryButton>
        </ModalActions>
      </ModalContent>
    </ModalBackground>
  );
}
