import React, { useState, useEffect } from 'react';
import QRCode from 'qrcode';

import { Row, Col } from '../../../../styles/grid';
import {
  ActiveTextYellow,
  GoToPaymentLink,
  CopyQrCode,
  CopyQrCodeTextBold
} from '../styles';
import {
  SuccessPaymentImage,
  PageTitle,
  PageText,
  SectionBox,
  Separator,
  OrderSumaryTextBold,
  OrderSumaryText,
} from '../../styles';

import px2vw from '../../../../utils/responsiveness/px2vw';
import { formatCurrencyBRL } from '../../../../utils/utils';

import {
  PageTitleSuccess,
  PageTextBlocked,
  PageTextRenewNotBlocked,
  PageTextFirstPayment,
  PixAmountText,
  PixExpirationText,
  PixExpirationDateText,
  PaymentInfo1,
  PaymentInfo2,
  PaymentInfo3
} from '../constants';
import {
  PlanPendingPaymentText,
  VigencyText,
  AdvertiserNameText,
  ChangePaymentMethodText,
  CopyPaymentCodeText
} from '../../constants';

export default function PixGeneratedSuccessWeb({ handler }) {
  const [qrCodePix, setQrCodePix] = useState('');

  useEffect(() => {
    // Set QR code data
    QRCode.toDataURL(handler.signaturePaymentData?.pix_qr_code)
    .then(text => {
      setQrCodePix(text)
    })
    .catch(err => {
      setQrCodePix(null)
    })
  }, [])

  return (
    <>
      <Row alignStart justifyStart>
        <SuccessPaymentImage
          src={require('../../../../assets/images/success/success.png')}
        />
        <PageTitle>{PageTitleSuccess}</PageTitle>
      </Row>
      <Separator />
      <Row
        justifyStart
        padleft={px2vw(25)}
        padright={px2vw(25)}
        margtop={px2vw(30)}
        margbottom={px2vw(30)}
      >
        <PageText>
          {handler.areBlocked && PageTextBlocked}
          {handler.isRenewPayment && !handler.areBlocked && PageTextRenewNotBlocked}
          {handler.isFirstPayment && PageTextFirstPayment}
        </PageText>
      </Row>
      <SectionBox>
        <Col
          padtop={px2vw(20)}
          padbottom={px2vw(20)}
          padleft={px2vw(50)}
          padright={px2vw(50)}
        >
          <Row margtop={px2vw(20)} padleft={px2vw(20)} padright={px2vw(20)}>
            <Col alignStart>
              <OrderSumaryTextBold>
                {`${handler.signaturePaymentData?.subscription?.name}`}
              </OrderSumaryTextBold>
              <ActiveTextYellow>
                {PlanPendingPaymentText}
              </ActiveTextYellow>
              <Col alignStart margtop={px2vw(10)}>
                <OrderSumaryText>
                  {VigencyText}
                </OrderSumaryText>
                <OrderSumaryTextBold>
                  {handler.vigencyInfo}
                </OrderSumaryTextBold>
              </Col>
              <Col alignStart margtop={px2vw(30)}>
                <OrderSumaryText>
                  {PixAmountText}
                </OrderSumaryText>
                <OrderSumaryTextBold>
                  {formatCurrencyBRL(
                    (
                      Math.round(
                        handler.signaturePaymentData?.amount *
                          handler.signaturePaymentData?.subscription?.period *
                          100
                      ) / 100
                    ).toFixed(2),
                    {}
                  )}
                </OrderSumaryTextBold>
              </Col>
            </Col>
            <Col alignEnd>
              <Col alignEnd>
                <OrderSumaryText>
                  {AdvertiserNameText}
                </OrderSumaryText>
                <OrderSumaryTextBold
                  advertiserName
                  title={handler.signaturePaymentData?.advertiserInfo?.name}
                >
                  {handler.signaturePaymentData?.advertiserInfo?.name}
                </OrderSumaryTextBold>
              </Col>
              <Col alignEnd margtop={px2vw(10)}>
                <OrderSumaryText>
                  {PixExpirationText}
                </OrderSumaryText>
                <OrderSumaryTextBold>
                  {PixExpirationDateText}
                </OrderSumaryTextBold>
              </Col>
              <Row justifyEnd margtop={px2vw(35)} width="100%">
                <GoToPaymentLink
                  onClick={() => handler.handleChangePaymentMethod() }
                >
                  {ChangePaymentMethodText}
                </GoToPaymentLink>
              </Row>
            </Col>
          </Row>
          <Col margtop={px2vw(50)} alignCenter justifyCenter>
            <OrderSumaryTextBold>
              {PaymentInfo1}
            </OrderSumaryTextBold>
            {qrCodePix && (
              <img alt="qrCodePIX" src={qrCodePix} />
            )}
            {!qrCodePix && (
              <Row margtop={px2vw(20)}>
                <img 
                  alt="qrCodePIXError"
                  src={require('../../../../assets/images/error/error.png')}
                  width={px2vw(750)}
                  height={px2vw(1050)}
                />
                <Row width={px2vw(300)} padtop={px2vw(8)} margleft={px2vw(10)}>
                  <OrderSumaryText>
                    Ocorreu um erro ao gerar o QRCode, utilize o código abaixo para pagar
                  </OrderSumaryText>
                </Row>
              </Row>
            )}
          </Col>
          <Col margtop={px2vw(20)} alignCenter justifyCenter>
            <OrderSumaryTextBold>
              {PaymentInfo2}
            </OrderSumaryTextBold>
          </Col>
          <Col margtop={px2vw(30)} alignCenter justifyCenter>
            <OrderSumaryTextBold>
              {PaymentInfo3}
            </OrderSumaryTextBold>
            <Row className="copy" margtop={px2vw(10)} margbottom={px2vw(15)}>
              <OrderSumaryText pixQrCode id="pixQrCodeCopy">
                {handler.signaturePaymentData?.pix_qr_code}
              </OrderSumaryText>
            </Row>
            <CopyQrCode
              onClick={() => handler.handleCopyPaymentCode('pixQrCodeCopy')}
            >
              <CopyQrCodeTextBold>
                {CopyPaymentCodeText}
              </CopyQrCodeTextBold>
            </CopyQrCode>
          </Col>
        </Col>
      </SectionBox>
    </>
  );
}
