import styled, { css } from 'styled-components';

import { Form, Input, Select } from '@rocketseat/unform';

import px2vw from '../../../utils/responsiveness/px2vw';
import {
  branco,
  preto,
  cinzaKuppi3,
  cinzaKuppi4,
  verdeKuppi1,
} from '../../../styles/colors';

export const SwitchLabelBoldText = styled.h4`
  font-size: ${px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.36px;
  margin-bottom: 0;

  ${(props) =>
    props.marginBottom &&
    css`
      margin-bottom: ${props.marginBottom};
    `}
`;

export const SwitchLabelRegularText = styled.div`
  font-size: ${px2vw(11)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.36px;

  ${(props) =>
    props.isChecked &&
    `
    color: ${branco};
  `};

  ${(props) => `
    width: ${props.width};
    margin-right: ${props.margright};
    margin-left: ${props.margleft};
    margin-top: ${props.margtop};
  `};

  ${(props) =>
    props.disabled &&
    `
    color: ${preto}60;
  `};
`;

export const SwitchLabelRegularTextBefore = styled.div`
  font-size: ${px2vw(10)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.36px;

  ${(props) =>
    props.isChecked &&
    `
    color: ${branco};
  `};

  ${(props) => `
    width: ${props.width};
    margin-right: ${props.margright};
    margin-left: ${props.margleft};
    margin-top: ${props.margtop};
  `};

  ${(props) =>
    props.disabled &&
    `
    color: ${preto}60;
  `};
`;

export const OptionRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${px2vw(10)};
  padding-left: ${px2vw(20)};
  padding-right: ${px2vw(25)};
`;

export const Header = styled.header`
  width: 100%;
  margin-bottom: ${px2vw(30)};
  display: flex;
  justify-content: space-between;
  h2 {
    font-family: AvenirNextLTPro;
font-weight: 700;
    font-size: ${px2vw(28)};
    letter-spacing: 0.72px;
  }
`;

export const CheckboxFeeCalcType = styled.div`
  border: 1px solid ${cinzaKuppi3};
  background-color: ${branco};
  width: ${px2vw(12)};
  height: ${px2vw(12)};
  margin-right: ${px2vw(10)};
  margin-top: ${px2vw(2)};
`;

export const CheckboxFeeCalcTypeChecked = styled.div`
  background-color: ${verdeKuppi1};
  width: ${px2vw(8)};
  height: ${px2vw(8)};
  margin-top: ${px2vw(1)};
  margin-left: ${px2vw(1.3)};
  display: none;

  ${(props) =>
    props.checked &&
    `
      display: block;
    `};
`;

export const ModalInputLabelText = styled.div`
  font-size: ${px2vw(12)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.32px;
`;

export const ModalInput = styled(Input)`
  padding: 0.5em;
  margin-top: ${px2vw(5)};
  border: 1px solid ${cinzaKuppi4};
  border-radius: ${px2vw(5)};
  font-size: ${px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.36px;
  width: ${px2vw(280)};
  height: ${px2vw(40)};

  ::placeholder {
    color: ${cinzaKuppi4} !important;
  }

  :focus {
    border: 1px solid ${cinzaKuppi3} !important;
    box-shadow: none !important;
  }

  ${(props) => `
    width: ${props.width};
    height: ${props.height};
    margin-top: ${props.margtop};
  `};
`;

export const ModalSelectInput = styled(Select)`
  margin-top: ${px2vw(5)};
  height: ${px2vw(40)};
  font-size: ${px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.36px;

  ${(props) => `
    width: ${props.width};
    margin-top: ${props.margtop};
    margin-right: ${props.margright};
  `};
`;

export const FormNewNeighborhoodData = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
