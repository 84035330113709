import React from 'react';
import Modal from '../../../../components/Modal';
import { 
  CloseButton, 
  CloseImage, 
  ModalActions, 
  ModalHeader, 
  ModalPrimaryButton, 
  ModalPrimaryButtonText, 
  ModalSecundaryButton, 
  ModalSecundaryButtonText, 
  ModalText, 
  ModalTitleText 
} from '../../../../components/Modal/styles';
import { credentialsRecoverySlug, recoverEmail, recoverPassword, recoveryText } from '../../constants';
import px2vw from '../../../../utils/responsiveness/px2vw';
import { ForgotEmailPasswordModal } from '../../styles';

export default function RecoveryTypeWeb({ handler }) {
  return handler.forgotEmailPasswordModal && (
    <Modal
      isVisible={handler.forgotEmailPasswordModal}
      width={px2vw(470)}
      height={px2vw(200)}
    >
      <ForgotEmailPasswordModal>
        <ModalHeader>
          <ModalTitleText>{credentialsRecoverySlug}</ModalTitleText>
          <CloseButton onClick={() => handler.setForgotEmailPasswordModal(false)}>
            <CloseImage
              src={require('../../../../assets/images/close/close.png')}
            />
          </CloseButton>
        </ModalHeader>
        <ModalText paddingLR={px2vw(33)} margtop={px2vw(35)}>
          {recoveryText}
        </ModalText>
        <ModalActions
          width="100%"
          justifyContent="space-around"
          padding={`${px2vw(30)} 0 ${px2vw(5)}`}
        >
          <ModalSecundaryButton
            width={px2vw(200)}
            onClick={() => handler.handleForgotEmail()}
          >
            <ModalSecundaryButtonText>
              {recoverEmail}
            </ModalSecundaryButtonText>
          </ModalSecundaryButton>
          <ModalPrimaryButton
            width={px2vw(200)}
            onClick={() => handler.handleForgotPassword()}
          >
            <ModalPrimaryButtonText>
              {recoverPassword}
            </ModalPrimaryButtonText>
          </ModalPrimaryButton>
        </ModalActions>
      </ForgotEmailPasswordModal>
    </Modal>
  )
}