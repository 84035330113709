import styled, { keyframes } from 'styled-components';
import { branco, cinzaKuppi1 } from '../../styles/colors';

import { Col } from '../../styles/grid';
import px2vw from '../../utils/responsiveness/px2vw';

const transicao = keyframes`
to {
  opacity: initial;
  transform: initial;
  bottom: ${-500};
}
`;

export const GlobalChatContainer = styled(Col)`
  background: ${branco};
  right: ${px2vw(10)};
  bottom: ${0};
  position: fixed;
  width: ${px2vw(360)};
  height: 80%;
  z-index: 50;
  -webkit-box-shadow: 0px 2px 16px 6px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0px 2px 16px 6px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 2px 16px 6px rgba(0, 0, 0, 0.4);
  opacity: 0;
  animation: ${transicao} 0.3s forwards;
`;

export const GlobalChatHeader = styled.div`
  width: 100%;
  background-color: ${branco};
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: ${px2vw(13)} ${px2vw(20)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  font-size: ${px2vw(15.5)};
  display: flex;
  align-items: space-between;
  justify-content: space-between;
  border: 1px solid ${cinzaKuppi1};
  border-bottom: none;
  -webkit-box-shadow: 0px 2px 16px 6px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0px 2px 16px 6px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 2px 16px 6px rgba(0, 0, 0, 0.4);
  cursor: pointer;
`;

export const MinimizedGlobalChatHeader = styled(GlobalChatHeader)`
  width: ${px2vw(360)};
  position: fixed;
  bottom: 0px;
  right: ${px2vw(70)};
  z-index: 50;
  -webkit-box-shadow: 0px 0px 8px 5px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0px 0px 8px 5px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 0px 8px 5px rgba(0, 0, 0, 0.4);
  a {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  cursor: pointer;
`;

export const Span = styled.span`
  font-size: ${px2vw(15.5)};
  text-align: center;
  font-weight:700;
  display:flex;
  align-items: center;
  justify-content: center;
  ${(props) => props.size && `font-size: ${props.size};`}
  ${(props) => props.textAlign && `text-align: ${props.textAlign};`}
  ${(props) => props.color && `color: ${props.color};`}
  ${(props) => props.letterSpacing && `letter-spacing: ${props.letterSpacing};`}
  ${(props) => props.lineHeight && `line-height: ${props.lineHeight};`}
  ${(props) => props.p && `padding: ${props.p};`}
  ${(props) => props.pb && `padding-bottom: ${props.pb};`}
  ${(props) => props.pl && `padding-left: ${props.pl};`}
  ${(props) => props.pr && `padding-right: ${props.pr};`}
  ${(props) => props.pt && `padding-top: ${props.pt};`}
  ${(props) => props.m && `margin: ${props.m};`}
  ${(props) => props.mb && `margin-bottom: ${props.mb};`}
  ${(props) => props.ml && `margin-left: ${props.ml};`}
  ${(props) => props.mr && `margin-right: ${props.mr};`}
  ${(props) => props.mt && `margin-top: ${props.mt};`}
  ${(props) => props.maxWidth && `max-width: ${props.maxWidth};`}
  ${(props) => props.overflow && `overflow: ${props.overflow};`}
  ${(props) => props.textOverflow && `text-overflow: ${props.textOverflow};`}
  ${(props) => props.whiteSpace && `white-space: ${props.whiteSpace};`}
`;
