import styled from 'styled-components';
import { cinzaKuppi6 } from '../../../../styles/colors';
import px2vw from '../../../../utils/responsiveness/px2vw';

export const SvgBox = styled.svg.attrs({
  width: px2vw(15),
  height: px2vw(15),
})`
  ${(props) => props.sorted && `transform: rotate(180deg);`};
  transition: all 0.2s ease-in-out;
  margin-left: 5px;

  path {
    fill: ${cinzaKuppi6};
    fill: ${(props) => props.color && props.color};
  }
`;

export const Arrow = styled.path.attrs({
  d:
    'M6.63,11.61,12,7.29l5.37,4.48a1,1,0,0,0,1.27-1.54l-6-5a1,1,0,0,0-1.27,0l-6,4.83a1,1,0,1,0,1.26,1.55Z',
})`
  transform: scale(0.8);
`;

export const Arrow2 = styled.path.attrs({
  d:
    'M12.64,12.23a1,1,0,0,0-1.27,0l-6,4.83a1,1,0,1,0,1.26,1.56L12,14.29l5.37,4.48a1,1,0,0,0,1.27-1.54Z',
})`
  transform: scale(0.8);
`;
