export function setCurrentStep(step) {
  return {
    type: '@massMessage/SET_CURRENT_STEP',
    payload: step,
  };
}

export function getMassMessageData() {
  return {
    type: '@massMessage/GET_MASS_MESSAGE_DATA',
  };
}

export function setSelectedCoupon(data) {
  return {
    type: '@massMessage/SET_SELECTED_COUPON',
    payload: data,
  };
}

export function setImage(data) {
  return {
    type: '@massMessage/SET_IMAGE',
    payload: data,
  };
}

export function setIsboxVisible(status) {
  return {
    type: '@massMessage/SET_IS_BOX_VISIBLE',
    payload: status,
  };
}

export function setDeleteConfig(type, contact) {
  return {
    type: '@massMessage/SET_DELETE_CONFIG',
    payload: { type, contact },
  };
}

export function setInsertBlackList(list) {
  return {
    type: '@massMessage/SET_INSERT_BLACKLIST',
    payload: list,
  };
}
export function blacklistRequestFailure() {
  return {
    type: '@massMessage/BLACKLIST_REQUEST_FAILURE',
  };
}
export function blacklistRequestSuccess() {
  return {
    type: '@massMessage/BLACKLIST_REQUEST_SUCCESS',
  };
}

export function setIsBlackTableOpen(status) {
  return {
    type: '@massMessage/SET_IS_BLACKLIST_TABLE_OPEN',
    payload: status,
  };
}

export function updateBlackListRequest() {
  return {
    type: '@massMessage/UPDATE_BLACK_LIST_REQUEST',
  };
}

export function removeFromBlackListRequest(id) {
  return {
    type: '@massMessage/REMOVE_FROM_BLACK_LIST_REQUEST',
    payload: { client_id: id },
  };
}

export function setMessage(string) {
  return {
    type: '@massMessage/SET_MESSAGE',
    payload: string,
  };
}

export function setSelectedSchedule(schedule) {
  return {
    type: '@massMessage/SET_SELECTED_SCHEDULE',
    payload: schedule,
  };
}

export function createCampaign() {
  return {
    type: '@massMessage/CREATE_CAMPAIGN_REQUEST',
  };
}

export function createCampaignSuccess() {
  return {
    type: '@massMessage/CREATE_CAMPAIGN_SUCCESS',
  };
}

export function createCampaignFailure() {
  return {
    type: '@massMessage/CREATE_CAMPAIGN_FAILURE',
  };
}

export function setCurrentPayment(data) {
  return {
    type: '@massMessage/SET_CURRENT_PAYMENT',
    payload: data,
  };
}

export function cleanMassMessageData() {
  return {
    type: '@massMessage/CLEAN_MASS_MESSAGE_DATA',
  };
}

export function setSelectedCampaign(data) {
  return {
    type: '@massMessage/SET_SELECTED_CAMPAIGN',
    payload: data,
  };
}

export function setMassMessageData(data) {
  return {
    type: '@massMessage/SET_MASS_MESSAGE_DATA',
    payload: data,
  };
}

export function setCurrentClientList(list) {
  return {
    type: '@massMessage/SET_CURRENT_CLIENT_LIST',
    payload: list,
  };
}

export function setCampaigns(list) {
  return {
    type: '@massMessage/SET_CAMPAIGNS',
    payload: list,
  };
}

export function setRemoveFromBlackList(id) {
  return {
    type: '@massMessage/SET_REMOVE_FROM_BLACKLIST',
    payload: id,
  };
}

export function getCampaignDetails(id) {
  return {
    type: '@massMessage/GET_CAMPAIGN_DETAILS',
    payload: id,
  };
}

export function cancelMassMessagePaymentRequest(id) {
  return {
    type: '@massMessage/CANCEL_MASS_MESSAGE_PAYMENT_REQUEST',
    payload: id,
  };
}

export function cancelMassMessagePaymentSuccess(campaigns) {
  return {
    type: '@massMessage/CANCEL_MASS_MESSAGE_PAYMENT_SUCCESS',
    payload: campaigns,
  };
}

export function cancelMassMessagePaymentFailure() {
  return {
    type: '@massMessage/CANCEL_MASS_MESSAGE_PAYMENT_FAILURE',
  };
}

export function setManualList(list, config) {
  return {
    type: '@massMessage/SET_MANUAL_LIST',
    payload: { list, config },
  };
}

export function setSegmentations(list) {
  return {
    type: '@massMessage/SET_SEGMENTATIONS',
    payload: list,
  };
}

export function setIsUsingMassMessageBonus(isUsingBonus) {
  return {
    type: '@massMessage/SET_IS_USING_BONUS',
    payload: isUsingBonus,
  };
}

export function setShowUnsubscribeMessage(showUnsubcribe) {
  return {
    type: '@massMessage/SET_SHOW_UNSUBSCRIBE_MESSAGE',
    payload: showUnsubcribe,
  };
}

export function setSendCatalogLink(sendCatalogLink) {
  return {
    type: '@massMessage/SEND_CATALOG_LINK',
    payload: sendCatalogLink,
  };
}

export function setCampaignType(campaignType) {
  return {
    type: '@massMessage/SET_CAMPAIGN_TYPE',
    payload: campaignType,
  };
}

export function setIsEditing(isEditing) {
  return {
    type: '@massMessage/SET_IS_EDITING',
    payload: isEditing,
  };
}

export function updateMassMessageCampaign(message, image) {
  return {
    type: '@massMessage/UPDATE_MASS_MESSAGE_CAMPAIGN_REQUEST',
    payload: {
      message,
      image,
    },
  };
}

export function updateMassMessageCampaignSuccess() {
  return {
    type: '@massMessage/UPDATE_MASS_MESSAGE_CAMPAIGN_SUCCESS',
  };
}

export function updateMassMessageCampaignFailure() {
  return {
    type: '@massMessage/UPDATE_MASS_MESSAGE_CAMPAIGN_FAILURE',
  };
}

export function updateMassMessageCampaignClear() {
  return {
    type: '@massMessage/UPDATE_MASS_MESSAGE_CAMPAIGN_CLEAR',
  };
}

export function cancelMassMessageCampaignSuccess() {
  return {
    type: '@massMessage/CANCEL_MASS_MESSAGE_CAMPAIGN_SUCCESS',
  };
}

export function cancelMassMessageCampaignClear() {
  return {
    type: '@massMessage/CANCEL_MASS_MESSAGE_CAMPAIGN_CLEAR',
  };
}

export function cancelMassMessageCampaign(massMessageId) {
  return {
    type: '@massMessage/CANCEL_MASS_MESSAGE_CAMPAIGN_REQUEST',
    payload: massMessageId,
  };
}
