import produce from 'immer';

const INITIAL_STATE = {
  isOpennedFromWhatsappChat: false,
  isManualOrderModalVisible: false,
  advertiser: null,
  currStep: '1',
  previousStep: null,
  address: null,
  addresses: [],
  advertiserPaymentMethod: [],
  currManualOrderStatusData: null,
  deliveryFee: 0,
  paymentMethod: null,
  createManualOrderSuccess: null,
  currProductViewDetails: null,
  isGettingClientInfoLoading: false,
  isCreatingUserLoading: false,
  isCreatingUserSuccess: false,
  isNewClient: false,
  clientData: {
    client_id: null,
    name: '',
    total_orders: null,
    last_order: null,
    phone: null,
  },
  productCategory: null,
  products: null,
  selectedSchedule: null,
  orderRequestLoading: false,
  isMinimized: null,
  bag: {
    sizeBag: 0,
    discount_in_bag: null,
    discount_in_amount: null,
    discount_in_bag_coupon: null,
    priceOfTheWholeBag: null,
    priceWithoutDiscount: null,
    items_in_bag: [],
    deliveryType: 'delivery',
    changeValue: 0,
  },
};

function recalcBagAmounts(draft) {
  let priceOfTheWholeBag = 0;
  let priceWithoutDiscount = 0;
  let sizeBag = 0;

  draft.bag.items_in_bag.forEach((item) => {
    sizeBag += item.productQuantity;

    const currentPriceOfTheWholeBag = item.amount;
    const currentPriceWithoutDiscount = item.amount;

    let complementsTotalPrice = 0;
    item.selectedComplements.forEach((c) => {
      if (c.is_base_amount_more_expensive) {
        let arrAmountOptions = c.options.map((o) => {
          return o.quantity > 0 && o.amount;
        });
        let moreExpensiveOption = Math.max.apply(null, arrAmountOptions);

        c.options.forEach((o) => {
          complementsTotalPrice += moreExpensiveOption * o.quantity;
        });
      } else {
        c.options.forEach((o) => {
          if (o.is_selected && o.amount) {
            complementsTotalPrice += o.amount;
          } else if (o.quantity && o.amount) {
            complementsTotalPrice += o.amount * o.quantity;
          }
        });
      }
    });

    priceOfTheWholeBag +=
      (currentPriceOfTheWholeBag + complementsTotalPrice) *
      item.productQuantity;
    priceWithoutDiscount +=
      (currentPriceWithoutDiscount + complementsTotalPrice) *
      item.productQuantity;
  });

  draft.bag.sizeBag = sizeBag;
  draft.bag.priceWithoutDiscount = priceWithoutDiscount.toFixed(2);

  if (!draft.bag.discount_in_bag && !draft.bag.discount_in_amount) {
    draft.bag.priceOfTheWholeBag = priceOfTheWholeBag.toFixed(2);
  } else if (draft.bag.discount_in_amount) {
    draft.bag.priceOfTheWholeBag = (
      priceWithoutDiscount - draft.bag.discount_in_amount
    ).toFixed(2);
  } else {
    draft.bag.priceOfTheWholeBag = (
      priceOfTheWholeBag -
      priceOfTheWholeBag * (draft.bag.discount_in_bag / 100)
    ).toFixed(2);
  }
}

export default function manualOrder(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@manualOrder/CLEAR_CLIENT_INFO': {
        draft.clientData = {
          client_id: null,
          name: '',
          total_orders: null,
          last_order: null,
          phone: null,
        };
        draft.isNewClient = false;
        break;
      }
      case '@manualOrder/VERIFY_USER_EXISTS_BY_PHONE_REQUEST': {
        draft.isGettingClientInfoLoading = true;
        draft.clientData = {
          client_id: null,
          name: '',
          total_orders: null,
          last_order: null,
          phone: null,
        };
        draft.isNewClient = false;
        break;
      }
      case '@manualOrder/CREATE_NEW_USER_REQUEST': {
        draft.isCreatingUserLoading = true;
        draft.isCreatingUserSuccess = false;
        draft.clientData = {
          client_id: null,
          name: '',
          total_orders: null,
          last_order: null,
          phone: null,
        };
        break;
      }
      case '@manualOrder/SET_CLIENT_INFO': {
        if (action.payload.type === 'verify') {
          draft.clientData = action.payload.clientData.clientData;
          draft.isNewClient = action.payload.clientData.isNewClient;
          draft.isGettingClientInfoLoading = false;
        } else if (action.payload.type === 'create') {
          draft.isCreatingUserSuccess = true;
          draft.isCreatingUserLoading = false;
          draft.clientData = action.payload.clientData.clientData;
        }
        break;
      }
      case '@manualOrder/SET_CLIENT_INFO_FAILURE': {
        if (action.payload.type === 'verify') {
          draft.isGettingClientInfoLoading = false;
        } else if (action.payload.type === 'create') {
          draft.isCreatingUserSuccess = false;
          draft.isCreatingUserLoading = false;
        }
        break;
      }
      case '@manualOrder/SET_UPDATED_CLIENT_NAME': {
        draft.clientData.name = action.payload.name;
        break;
      }
      case '@manualOrder/GET_PRODUCT_DATA': {
        draft.productCategory = null;
        draft.products = null;
        break;
      }
      case '@manualOrder/SET_PRODUCT_DATA': {
        draft.productCategory = action.payload.advertiser.productCategory;
        draft.products = action.payload.products;
        break;
      }
      case '@manualOrder/SET_CURR_STEP': {
        draft.currStep = action.payload.currStep;
        draft.previousStep = action.payload.previousStep;
        break;
      }
      case '@manualOrder/SET_CURR_PRODUCT_VIEW_DETAILS': {
        draft.currProductViewDetails = action.payload;
        break;
      }
      case '@manualOrder/ADD_TO_BAG': {
        draft.bag.items_in_bag.push(action.payload);

        recalcBagAmounts(draft);
        break;
      }
      case '@manualOrder/UPDATE_IN_BAG': {
        const updateItemId = action.payload.productId;
        const newProductCount = action.payload.newProductCount;

        draft.bag.items_in_bag.forEach((item) => {
          if (item.id === updateItemId) {
            item.productQuantity = newProductCount;
          }
        });

        recalcBagAmounts(draft);
        break;
      }
      case '@manualOrder/UPDATE_PRODUCT_IN_BAG': {
        const updatedProduct = action.payload;
        let updateItemFromBagIdx = -1;

        draft.bag.items_in_bag.filter((currItem, currIdx) => {
          if (currItem.id === updatedProduct.id) {
            updateItemFromBagIdx = currIdx;
          }
        });

        if (updateItemFromBagIdx !== -1) {
          draft.bag.items_in_bag[updateItemFromBagIdx] = updatedProduct;
        }

        recalcBagAmounts(draft);
        break;
      }
      case '@manualOrder/REMOVE_FROM_BAG': {
        const removeItemId = action.payload.id;
        let removeItemFromBagIdx = -1;

        draft.bag.items_in_bag.filter((currItem, currIdx) => {
          if (currItem.id === removeItemId) {
            removeItemFromBagIdx = currIdx;
          }
        });

        if (removeItemFromBagIdx !== -1) {
          draft.bag.items_in_bag.splice(removeItemFromBagIdx, 1);
        }

        recalcBagAmounts(draft);
        break;
      }
      case '@manualOrder/SET_DISCOUNT': {
        if (action.payload.type === 'amount_discount') {
          draft.bag.discount_in_amount = action.payload.value;
          draft.bag.discount_in_bag = null;
        } else {
          draft.bag.discount_in_bag = action.payload.value;
          draft.bag.discount_in_amount = null;
        }

        recalcBagAmounts(draft);
        break;
      }
      case '@manualOrder/CLEAN_BAG': {
        draft.bag = INITIAL_STATE.bag;
        break;
      }
      case '@manualOrder/CLEAN_DISCOUNT': {
        draft.bag.discount_in_bag = null;
        draft.bag.discount_in_amount = null;

        recalcBagAmounts(draft);
        break;
      }
      case '@manualOrder/SET_ADVERTISER_PAYMENT_METHOD': {
        draft.advertiserPaymentMethod = action.payload;
        break;
      }
      case '@manualOrder/SET_PAYMENT_METHOD': {
        draft.paymentMethod = action.payload;
        break;
      }
      case '@manualOrder/SET_DELIVERY_TYPE': {
        draft.bag.deliveryType = action.payload;
        break;
      }
      case '@manualOrder/SET_ADVERTISER_INFO': {
        draft.advertiser = action.payload;
        break;
      }
      case '@manualOrder/SET_CHANGE_VALUE': {
        draft.bag.changeValue = action.payload;
        break;
      }
      case '@manualOrder/SET_SCHEDULE_DATA': {
        draft.selectedSchedule = action.payload;
        break;
      }
      case '@manualOrder/SET_MANUAL_ORDER_MODAL_STATE': {
        draft.isManualOrderModalVisible = action.payload;
        break;
      }
      case '@manualOrder/SET_ADDRESS': {
        draft.address = action.payload;
        break;
      }
      case '@manualOrder/SET_ADDRESSES': {
        draft.addresses = action.payload;
        break;
      }
      case '@manualOrder/ADD_TO_ADDRESSES': {
        draft.addresses.unshift(action.payload);
        break;
      }
      case '@manualOrder/SET_DELIVERY_FEE': {
        draft.deliveryFee = action.payload;
        break;
      }
      case '@manualOrder/DELETE_ADDRESS': {
        const idx = draft.addresses.findIndex(
          (address) =>
            address.latitude === action.payload.latitude &&
            address.longitude === action.payload.longitude
        );

        if (idx >= 0) {
          draft.addresses.splice(idx, 1);
        }
        break;
      }
      case '@manualOrder/CREATE_MANUAL_ORDER_REQUEST': {
        draft.orderRequestLoading = true;
        draft.createManualOrderSuccess = null;
        break;
      }
      case '@manualOrder/CREATE_MANUAL_ORDER_SUCCESS': {
        draft.createManualOrderSuccess = true;
        draft.orderRequestLoading = false;
        draft.isManualOrderModalVisible = false;
        break;
      }
      case '@manualOrder/CREATE_MANUAL_ORDER_FAILURE': {
        draft.orderRequestLoading = false;
        draft.createManualOrderSuccess = false;
        break;
      }
      case '@manualOrder/SET_CURRENT_MANUAL_ORDER_STATUS_DATA': {
        draft.currManualOrderStatusData =
          action.payload.currManualOrderStatusData;
        break;
      }
      case '@manualOrder/CLEAR_CURRENT_MANUAL_ORDER_STATUS_DATA': {
        draft.currManualOrderStatusData = null;
        break;
      }
      case '@manualOrder/SET_IS_MINIMIZED': {
        draft.isMinimized = action.payload;
        break;
      }
      case '@manualOrder/SET_IS_OPENNED_FROM_WHATSAPPCHAT': {
        draft.isOpennedFromWhatsappChat = true;
        break;
      }
      case '@manualOrder/CLEAN_MANUAL_ORDER_DATA': {
        draft.isOpennedFromWhatsappChat = false;
        draft.isManualOrderModalVisible = false;
        draft.advertiser = null;
        draft.currStep = '1';
        draft.address = null;
        draft.addresses = [];
        draft.createManualOrderSuccess = null;
        draft.advertiserPaymentMethod = [];
        draft.deliveryFee = 0;
        draft.paymentMethod = null;
        draft.currProductViewDetails = null;
        draft.isGettingClientInfoLoading = false;
        draft.isCreatingUserLoading = false;
        draft.isCreatingUserSuccess = false;
        draft.isNewClient = false;
        draft.clientData = {
          client_id: null,
          name: '',
          total_orders: null,
          last_order: null,
          phone: null,
        };
        draft.productCategory = null;
        draft.products = null;
        draft.selectedSchedule = null;
        draft.orderRequestLoading = false;
        draft.isMinimized = false;
        draft.bag = {
          sizeBag: 0,
          discount_in_bag: null,
          discount_in_amount: null,
          discount_in_bag_coupon: null,
          priceOfTheWholeBag: null,
          priceWithoutDiscount: null,
          items_in_bag: [],
          deliveryType: 'delivery',
          changeValue: 0,
        };
        break;
      }
      default:
        break;
    }
  });
}
