import React from 'react';
import { Spinner } from 'reactstrap';

import { LoadingBox } from '../../../../styles/common';
import { FormBox, NormalText, PageTitle } from '../../BaseScreen/styles';
import { Row, Col } from '../../../../styles/grid';
import { preto, cinzaKuppi1, vermelhoKuppi1 } from '../../../../styles/colors';
import {
  DragImageBox,
  ImageCoverPreview,
  ImageLogoPreview,
  InputImage,
  InformationContainer,
  InformationText,
  CircleBot,
  InputLabel,
  FormDataInput,
  SelectInputBox,
} from '../styles';

import advertiserLogoImg from '../../../../assets/images/advertiser_logo/advertiser_logo.png';

import px2vw from '../../../../utils/responsiveness/px2vw';

import ChangePasswordModal from '../../../../components/ChangePasswordModal';
import ButtonPrimary from '../../../../components/ButtonPrimary';

export default function ProfileConfigsWeb({ handler }) {
  return (
    <>
      <Col
        background={cinzaKuppi1}
        borderRadius={!handler.hasFreeTrialValid && px2vw(60)}

      >
        <Row
          margtop={px2vw(28)}
          margleft={px2vw(35)}
          margbottom={px2vw(15)}
          justifyStart
          width="auto"
        >
          <PageTitle>Perfil</PageTitle>
        </Row>

        {/* Upload Cover Image */}
        <Row margbottom={px2vw(20)}>
          <DragImageBox>
            {!handler.fileCoverData.base64 &&
              !handler.user.advertiserInfo.coverImage && (
                <>
                  <span>Arraste e solte a foto de capa aqui</span>
                </>
              )}
            {handler.fileCoverData.base64 && (
              <ImageCoverPreview src={handler.fileCoverData.base64} />
            )}
            {handler.user.advertiserInfo.coverImage &&
              !handler.fileCoverData.base64 && (
                <ImageCoverPreview
                  src={`${
                    handler.user.advertiserInfo.coverImage.url
                  }?nocache=${new Date().getTime()}`}
                />
              )}
            <InputImage
              onChange={handler.handleFileCover}
              type="file"
              id="coverImage"
              name="coverImage"
            />
          </DragImageBox>
        </Row>

        {/* Upload Logo Image */}
        <Row margtop={px2vw(-105)}>
          <DragImageBox logo>
            {!handler.fileLogoData.base64 && !handler.user.avatar && (
              <>
                <CircleBot>
                  <Col>
                    <Row height="auto">Adicionar</Row>
                    <Row height="auto">logo</Row>
                  </Col>
                </CircleBot>
                <Col width="auto" margright={px2vw(-300)}>
                  <InformationContainer>
                    <img
                      alt="indicator"
                      id="informationImg"
                      src={require('../../../../assets/images/onboarding/indicator@2x.png')}
                    />
                    <InformationText>
                      <Row justifyStart>
                        <NormalText fontweight="700">
                          Aqui vai o logo do seu negócio!
                        </NormalText>
                      </Row>
                      <Row justifyStart>
                        <NormalText fontsize={px2vw(13)}>
                          imagem deve ter no máximo 600x600px
                        </NormalText>
                      </Row>
                      <Row justifyStart>
                        <NormalText fontsize={px2vw(11)}>
                          * Campo obrigatório
                        </NormalText>
                      </Row>
                    </InformationText>
                  </InformationContainer>
                </Col>
              </>
            )}
            {handler.fileLogoData.base64 && (
              <ImageLogoPreview logo src={handler.fileLogoData.base64} />
            )}
            {handler.user.avatar && !handler.fileLogoData.base64 && (
              <ImageLogoPreview
                logo
                src={
                  handler.user.avatar
                    ? `${
                        handler.user.avatar.url
                      }?nocache=${new Date().getTime()}`
                    : advertiserLogoImg
                }
              />
            )}
            <InputImage
              onChange={handler.handleFileLogo}
              type="file"
              id="logoImage"
              name="logoImage"
            />
          </DragImageBox>
        </Row>
        <Row margbottom={px2vw(20)}>
          <FormBox web onSubmit={handler.handleStoreConfigsSubmit}>
            <Col justifyStart>
              <PageTitle fontsize={px2vw(20)}>Confirme seus dados</PageTitle>

              <PageTitle fontsize={px2vw(20)} margtop={px2vw(30)}>
                Dados da loja
              </PageTitle>
              <Row width="auto" height="auto" margtop={px2vw(10)}>
                <Col>
                  <InputLabel>Nome da loja *</InputLabel>
                  <FormDataInput
                    name="storeName"
                    type="text"
                    height={px2vw(30)}
                    width={px2vw(450)}
                    value={handler.storeName}
                    empty={handler.storeName.length === 0 ? true : false}
                    onChange={(e) => handler.setStoreName(e)}
                  />
                </Col>
                <Col margleft={px2vw(10)}>
                  <InputLabel>CPF / CNPJ *</InputLabel>
                  <FormDataInput
                    name="cnpj"
                    type="text"
                    height={px2vw(30)}
                    width={px2vw(200)}
                    value={handler.cpfCnpj}
                    empty={handler.cpfCnpj.length === 0 ? true : false}
                    onChange={(e) => handler.setCpfCnpj(e)}
                  />
                </Col>
                <Col margleft={px2vw(10)}>
                  <InputLabel>Senha</InputLabel>
                  <FormDataInput
                    name="password"
                    type="text"
                    value={handler.password}
                    height={px2vw(30)}
                    width={px2vw(200)}
                    disabled
                  />

                </Col>
                <Col margleft={px2vw(10)} margtop={px2vw(15)}>
                  <NormalText
                    textdecoration="underline"
                    cursor="pointer"
                    color={vermelhoKuppi1}
                    onClick={handler.setChangePassword}
                  >
                    Alterar senha
                  </NormalText>
                </Col>
              </Row>
              <Row width="auto" height="auto" margtop={px2vw(15)}>
                <Col>
                  <InputLabel>Categoria do estabelecimento *</InputLabel>
                  <SelectInputBox
                    padright={px2vw(100)}
                    padleft={px2vw(10)}
                    height={px2vw(29)}
                    name="advertiserCategory"
                    width={px2vw(450)}
                    placeholder="Defina a categoria"
                    options={handler.advertiserCategories}
                    value={handler.advertiserCategoryId}
                    onChange={(e) => handler.setAdvertiserCategoryId(e)}
                  />
                </Col>
                <Col margleft={px2vw(10)}>
                  <InputLabel>Whatsapp do delivery *</InputLabel>
                  <FormDataInput
                    name="phoneOne"
                    type="text"
                    height={px2vw(30)}
                    width={px2vw(200)}
                    value={handler.whatsappDelivery}
                    empty={handler.whatsappDelivery.length === 0 ? true : false}
                    onChange={(e) => handler.setWhatsappDelivery(e)}
                  />
                </Col>
              </Row>
              <Row width="auto" height="auto" margtop={px2vw(25)}>
                <Col>
                  <InputLabel>CEP *</InputLabel>
                  <FormDataInput
                    name="cep"
                    type="text"
                    height={px2vw(30)}
                    width={px2vw(100)}
                    value={handler.zipcode}
                    empty={handler.zipcode.length === 0 ? true : false}
                    onChange={(e) => handler.setZipcode(e)}
                  />
                </Col>
                <Col margleft={px2vw(10)}>
                  <InputLabel>Endereço *</InputLabel>
                  <FormDataInput
                    name="address"
                    type="text"
                    height={px2vw(30)}
                    width={px2vw(300)}
                    value={handler.street}
                    empty={handler.street.length === 0 ? true : false}
                    onChange={(e) => handler.setStreet(e)}
                  />
                </Col>
                <Col margleft={px2vw(10)}>
                  <InputLabel>Número *</InputLabel>
                  <FormDataInput
                    name="number"
                    type="text"
                    height={px2vw(30)}
                    width={px2vw(100)}
                    value={handler.number}
                    empty={handler.number.length === 0 ? true : false}
                    onChange={(e) => handler.setNumber(e)}
                  />
                </Col>
                <Col margleft={px2vw(10)}>
                  <InputLabel>Complemento</InputLabel>
                  <FormDataInput
                    name="complement"
                    type="text"
                    height={px2vw(30)}
                    width={px2vw(200)}
                    value={handler.complement}
                    onChange={(e) => handler.setComplement(e)}
                  />
                </Col>
                <Col margleft={px2vw(10)}>
                  <InputLabel>Bairro *</InputLabel>
                  <FormDataInput
                    name="district"
                    type="text"
                    height={px2vw(30)}
                    width={px2vw(225)}
                    value={handler.neighborhood}
                    empty={handler.neighborhood.length === 0 ? true : false}
                    onChange={(e) => handler.setNeighborhood(e)}
                  />
                </Col>
              </Row>
              <Row width="auto" height="auto" margtop={px2vw(15)}>
                <Col>
                  <InputLabel>Cidade *</InputLabel>
                  <FormDataInput
                    name="city"
                    type="text"
                    height={px2vw(30)}
                    width={px2vw(250)}
                    value={handler.city}
                    empty={handler.city.length === 0 ? true : false}
                    onChange={(e) => handler.setCity(e)}
                    disabled
                  />
                </Col>
                <Col margleft={px2vw(10)}>
                  <InputLabel>Estado *</InputLabel>
                  <FormDataInput
                    name="state"
                    type="text"
                    height={px2vw(30)}
                    width={px2vw(60)}
                    value={handler.state}
                    empty={handler.state.length === 0 ? true : false}
                    onChange={(e) => handler.setState(e)}
                    disabled
                  />
                </Col>
              </Row>
              <PageTitle fontsize={px2vw(20)} margtop={px2vw(40)}>
                Dados do responsável
              </PageTitle>
              <Row width="auto" height="auto" margtop={px2vw(10)}>
                <Col>
                  <InputLabel>Nome completo *</InputLabel>
                  <FormDataInput
                    name="fullName"
                    type="text"
                    height={px2vw(30)}
                    width={px2vw(400)}
                    value={handler.representativeName}
                    empty={handler.representativeName.length === 0 ? true : false}
                    onChange={(e) => handler.setRepresentativeName(e)}
                  />
                </Col>
                <Col margleft={px2vw(10)}>
                  <InputLabel>Email *</InputLabel>
                  <FormDataInput
                    name="email"
                    type="text"
                    height={px2vw(30)}
                    width={px2vw(350)}
                    value={handler.email}
                    empty={handler.email.length === 0 ? true : false}
                    onChange={(e) => handler.setEmail(e)}
                  />
                </Col>
                <Col margleft={px2vw(10)}>
                  <InputLabel>Telefone (Whatsapp) *</InputLabel>
                  <FormDataInput
                    name="whatsapp"
                    type="text"
                    height={px2vw(30)}
                    width={px2vw(200)}
                    value={handler.phoneWhatsappRepresentative}
                    empty={handler.phoneWhatsappRepresentative.length === 0 ? true : false}
                    onChange={(e) => handler.setPhoneWhatsappRepresentative(e)}
                  />
                </Col>
              </Row>
            </Col>
            <Col width="auto" height="auto" justifyEnd>
              <Row width="auto" height="auto" margleft={px2vw(-160)}>
                <ButtonPrimary minWidth={150} type="submit">
                  {handler.onboardingStoreConfigsLoading || handler.updateOnboardingStepLoading ? (
                    <LoadingBox>
                      <Spinner
                        style={{
                          width: '1.8rem',
                          height: '1.8rem',
                          color: preto,
                        }}
                      />{' '}
                    </LoadingBox>
                  ) : (
                    'Confirmar'
                  )}
                </ButtonPrimary>
              </Row>
            </Col>
          </FormBox>
        </Row>
        <Row margbottom={px2vw(75)} />
      </Col>

      {/* Change password modal */}
      {handler.changePassword && <ChangePasswordModal handler={handler} isMobile={false} />}
    </>
  );
}
