import { store } from '../store';
import {
  setIsHistorySyncComplete,
  updateConversations,
} from '../store/modules/whatsappChatConversations/actions';
import { forage } from '../store/persistReducers';

export const saveHistory = async ({ data }) => {
  const eventData = JSON.parse(data);

  if (eventData) {
    const history = eventData?.history;

    if (!Object.keys(history).length) return;

    store.dispatch(setIsHistorySyncComplete(false));

    const promises = Object.keys(history).map(async (phone) => {
      if (!history[phone]) return;

      const stored = await forage.getItem(phone);

      if (stored) {
        history[phone].forEach((message) => {
          const msgIndex = stored.findIndex(
            (storedMsg) => message.id === storedMsg.id
          );
          if (msgIndex === -1) {
            stored.push(message);
          } else if (message.pushName) {
            stored[msgIndex] = message;
          }
        });

        await forage.setItem(phone, [...new Set(stored)]);
      } else {
        const newHistoryArray = [];
        history[phone].forEach((history) => {
          const msgIndex = newHistoryArray.findIndex(
            (item) => item.id === history.id
          );
          if (msgIndex === -1) {
            newHistoryArray.push(history);
          } else if (history.pushName) {
            newHistoryArray[msgIndex] = history;
          }
        });
        await forage.setItem(phone, [...new Set(newHistoryArray)]);
      }
      return true;
    });

    await Promise.all(promises);
  }
  store.dispatch(updateConversations());
};
