import React from 'react';
import px2vw from '../../../utils/responsiveness/px2vw';

import { Bar } from '../styles';

export default function NewProgressBar(props) {
  const count = props.count;
  const subsPercent = count / 10;
  const height = props.height ? px2vw(props.height) : px2vw(7);

  return (
    <Bar height={height}>
      {subsPercent > 0 && (
        <span style={{ width: `${subsPercent}%` }} />
      )}
    </Bar>
  );
}