import styled from 'styled-components';

import px2vw from '../../../utils/responsiveness/px2vw';
import { branco, cinzaKuppi1, cinzaKuppi4 } from '../../../styles/colors';

export const ConfigGroupContent = styled.div`
  background-color: ${(props) => props.backgroundColor || cinzaKuppi1};
  border-radius: ${px2vw(20)};
  padding: ${px2vw(30)};
  width: 100%;
  /* min-height: ${(props) => px2vw(props.minHeight) || px2vw(260)}; */
  max-height: ${(props) => px2vw(props.maxHeight) || px2vw(420)};
  margin-bottom: ${px2vw(25)};
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  box-shadow: 0px 8px 9px -2px ${cinzaKuppi4};
`;

export const AccountDataBox = styled.div`
  border-radius: ${px2vw(20)};
  background-color: ${branco};
  width: ${px2vw(1020)};
  height: ${(props) => props.height};
  padding-left: ${px2vw(30)};
  padding-right: ${px2vw(30)};
`;
