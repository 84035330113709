export function updateOnboardingStepRequest(onboardingStep) {
  return {
    type: '@onboarding/UPDATE_ONBOARDING_STEP_REQUEST',
    payload: { onboardingStep },
  };
}

export function updateOnboardingStepSuccess(advertiserOnboardingSurvey) {
  return {
    type: '@onboarding/UPDATE_ONBOARDING_STEP_SUCCESS',
    payload: { advertiserOnboardingSurvey },
  };
}

export function updateOnboardingStepFailure() {
  return {
    type: '@onboarding/UPDATE_ONBOARDING_STEP_FAILURE',
  };
}

export function updateOnboardingStoreConfigsRequest(
  fileLogoData,
  fileCoverData,
  name,
  cpfCnpj,
  representativeName,
  email,
  phoneWhatsappRepresentative,
  street,
  number,
  complement,
  neighborhood,
  city,
  state,
  zipcode,
  phoneWhatsapp,
  advertiserCategoryId
) {
  return {
    type: '@onboarding/UPDATE_ONBOARDING_STORE_CONFIGS_REQUEST',
    payload: {
      fileLogoData,
      fileCoverData,
      name,
      cpfCnpj,
      representativeName,
      email,
      phoneWhatsappRepresentative,
      street,
      number,
      complement,
      neighborhood,
      city,
      state,
      zipcode,
      phoneWhatsapp,
      advertiserCategoryId,
    },
  };
}

export function updateOnboardingStoreConfigsSuccess() {
  return {
    type: '@onboarding/UPDATE_ONBOARDING_STORE_CONFIGS_SUCCESS',
  };
}

export function updateOnboardingStoreConfigsFailure() {
  return {
    type: '@onboarding/UPDATE_ONBOARDING_STORE_CONFIGS_FAILURE',
  };
}

export function clearSuccess() {
  return {
    type: '@onboarding/CLEAR_SUCCESS',
  };
}
