import styled from 'styled-components';

import px2vw from '../../../utils/responsiveness/px2vw';

export const StyledCheckbox = styled.label`
  margin-right: ${px2vw(10)};
  cursor: pointer;
`;


