import produce from 'immer';

const INITIAL_STATE = {
  currentTab: 'tabProfile',
  isDuePayment: false,
  subscriptions: [],
  recurring: null,
  paymentCards: [],
  subscriptionsLoading: false,
  recurringLoading: false,
  paymentCardsLoading: false,
  paymentCardsSuccess: false,
  deleteCardModalLoading: false,
  deleteCardModalSuccess: false,
  addNewCardModalLoading: false,
  addNewCardModalSuccess: false,
  errorSubscriptions: false,
  errorRecurring: false,
};

export default function profileAccount(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@profileAccount/SET_CURRENT_TAB': {
        draft.currentTab = action.payload.tab;
        break;
      }
      case '@profileAccount/GET_SUBSCRIPTIONS_REQUEST': {
        draft.subscriptions = [];
        draft.subscriptionsLoading = true;
        draft.errorSubscriptions = false;
        break;
      }
      case '@profileAccount/GET_SUBSCRIPTIONS_SUCCESS': {
        draft.subscriptions = action.payload.subscriptions;
        draft.isDuePayment = action.payload.isDuePayment;
        draft.subscriptionsLoading = false;
        draft.errorSubscriptions = false;
        break;
      }
      case '@profileAccount/GET_SUBSCRIPTIONS_FAILURE': {
        draft.subscriptions = [];
        draft.isDuePayment = false;
        draft.subscriptionsLoading = false;
        draft.errorSubscriptions = true;
        break;
      }
      case '@profileAccount/GET_RECURRING_REQUEST': {
        draft.recurring = null;
        draft.recurringLoading = true;
        draft.errorRecurring = false;
        break;
      }
      case '@profileAccount/GET_RECURRING_SUCCESS': {
        draft.recurring = action.payload.recurring;
        draft.recurringLoading = false;
        draft.errorRecurring = false;
        break;
      }
      case '@profileAccount/GET_RECURRING_FAILURE': {
        draft.recurring = null;
        draft.recurringLoading = false;
        draft.errorSubscriptions = true;
        break;
      }
      case '@profileAccount/GET_PAYMENT_CARDS_REQUEST': {
        draft.paymentCardsLoading = true;
        draft.paymentCardsSuccess = false;
        break;
      }
      case '@profileAccount/GET_PAYMENT_CARDS_SUCCESS': {
        draft.paymentCards = action.payload.paymentCards;
        draft.paymentCardsLoading = false;
        draft.paymentCardsSuccess = true;
        break;
      }
      case '@profileAccount/GET_PAYMENT_CARDS_FAILURE': {
        draft.paymentCards = null;
        draft.paymentCardsLoading = false;
        draft.paymentCardsSuccess = false;
        break;
      }
      case '@profileAccount/SET_DEFAULT_CREDIT_CARD_REQUEST': {
        draft.paymentCardsLoading = true;
        draft.paymentCardsSuccess = false;
        break;
      }
      case '@profileAccount/SET_DEFAULT_CREDIT_CARD_SUCCESS': {
        draft.paymentCards = action.payload.paymentCards;
        draft.paymentCardsLoading = false;
        draft.paymentCardsSuccess = true;
        break;
      }
      case '@profileAccount/SET_DEFAULT_CREDIT_CARD_FAILURE': {
        draft.paymentCardsLoading = false;
        draft.paymentCardsSuccess = true;
        break;
      }
      case '@profileAccount/DELETE_CARD_REQUEST': {
        draft.deleteCardModalLoading = true;
        draft.deleteCardModalSuccess = false;
        break;
      }
      case '@profileAccount/DELETE_CARD_SUCCESS': {
        draft.paymentCards = action.payload.paymentCards;
        draft.deleteCardModalLoading = false;
        draft.deleteCardModalSuccess = true;
        break;
      }
      case '@profileAccount/DELETE_CARD_FAILURE': {
        draft.deleteCardModalLoading = false;
        draft.deleteCardModalSuccess = true;
        break;
      }
      case '@profileAccount/ADD_NEW_CARD_REQUEST': {
        draft.addNewCardModalLoading = true;
        draft.addNewCardModalSuccess = false;
        break;
      }
      case '@profileAccount/ADD_NEW_CARD_SUCCESS': {
        draft.paymentCards = action.payload.paymentCards;
        draft.addNewCardModalLoading = false;
        draft.addNewCardModalSuccess = true;
        break;
      }
      case '@profileAccount/ADD_NEW_CARD_FAILURE': {
        draft.addNewCardModalLoading = false;
        draft.addNewCardModalSuccess = false;
        break;
      }
      case '@auth/LOGIN_SUCCESS': {
        draft.paymentCards = action.payload.user.paymentCard;
        break;
      }
      case '@auth/LOG_OUT': {
        draft.currentTab = 'tabProfile';
        draft.subscriptions = [];
        draft.subscriptionsLoading = false;
        draft.recurring = null;
        draft.recurringLoading = false;
        draft.paymentCards = [];
        draft.paymentCardsLoading = false;
        draft.paymentCardsSuccess = false;
        draft.errorSubscriptions = false;
        draft.errorRecurring = false;
        draft.errorPaymentCards = false;
        draft.deleteCardModalLoading = false;
        draft.deleteCardModalSuccess = false;
        draft.addNewCardModalLoading = false;
        draft.addNewCardModalSuccess = false;
        draft.isDuePayment = false;
        break;
      }
      default:
    }
  });
}
