import React from 'react';
import { MessageQuote, MessageQuoted } from '../../style';
import { Row } from '../../../../../../styles/grid';
import { useSelector } from 'react-redux';
import px2vw from '../../../../../../utils/responsiveness/px2vw';
import { ImFilm } from 'react-icons/im';
import { FaCamera, FaFile, FaMicrophone } from 'react-icons/fa';
import { azulKuppi1, cinzaKuppi3, verdeKuppi25 } from '../../../../../../styles/colors';
import DOMPurify from 'dompurify';

export const QuotedMessage = ({ quoted }) => {
  const { id: selectedConversationId } = useSelector(
    (state) => state.whatsappChat.selectedConversation
  );
  return (
    <>
      <MessageQuoted>
        {selectedConversationId === quoted?.quotedPhone ? (
          <span style={{ color: azulKuppi1, fontWeight: '500' }}>Cliente:</span>
        ) : (
          <span style={{ color: verdeKuppi25, fontWeight: '500' }}>Você:</span>
        )}
        <Row justifyStart alignCenter height="auto">
          {quoted?.type === 'audio' && (
            <>
              <FaMicrophone
                color={cinzaKuppi3}
                style={{ marginRight: px2vw(5) }}
              />{' '}
              Mensagem de aúdio
            </>
          )}
          {quoted?.type === 'video' && (
            <>
              <ImFilm color={cinzaKuppi3} style={{ marginRight: px2vw(5) }} />{' '}
              {quoted?.quotedMessage?.length ? '' : 'Mensagem de vídeo'}
            </>
          )}

          {quoted?.type === 'image' && (
            <>
              <FaCamera color={cinzaKuppi3} style={{ marginRight: px2vw(5) }} />{' '}
              {quoted?.quotedMessage?.length ? '' : 'Imagem'}
            </>
          )}
          {quoted?.type === 'sticker' && (
            <>
              <FaCamera color={cinzaKuppi3} style={{ marginRight: px2vw(5) }} />{' '}
              {quoted?.quotedMessage?.length ? '' : 'Figurinha'}
            </>
          )}
          {quoted?.type === 'document' && (
            <>
              <FaFile color={cinzaKuppi3} style={{ marginRight: px2vw(5) }} />{' '}
              {quoted?.quotedMessage?.length ? '' : 'Documento'}
            </>
          )}

          {quoted?.quotedMessage?.length > 60
            ? quoted?.quotedMessage.slice(0, 57) + '...'
            : quoted?.quotedMessage}
        </Row>
      </MessageQuoted>
      <MessageQuote>{DOMPurify.sanitize(quoted.text)}</MessageQuote>
    </>
  );
};
