import React from "react";

import {
  NormalText,
} from '../../BaseScreen/styles';
import {
  TabSelected
} from './styles'
import { Row, Col } from '../../../../styles/grid';
import { preto } from '../../../../styles/colors';

import px2vw from '../../../../utils/responsiveness/px2vw';

import StoreAvailabilities from '../../../MyStore/StoreAvailabilities';
import StorePaymentMethods from '../../../MyStore/StorePaymentMethods';
import StoreDeliveryTakeAwayParams from '../../../MyStore/StoreDeliveryTakeAwayParams';

export default function DeliveryParamsMobile({ handler }) {
  return (
    <>
      <Row
        justifyStart
        overflowX
        cursor='pointer'
        margbottom={px2vw(-60)}
      >    
        <Col 
          pad={px2vw(50)}
          onClick={() => handler.handleCurrTabSelected('tabStoreAvailabilities')}
        >
          <NormalText
            fontweight='bold'
            color={preto}
            fontsize={px2vw(60)}>Horários</NormalText>
          <TabSelected 
            isVisible={handler.currTabSelected === 'tabStoreAvailabilities'}
            width="100%"
            height={px2vw(15)}
          />
        </Col>

        <Col
          pad={px2vw(50)}
          onClick={() => handler.handleCurrTabSelected('tabStorePaymentMethods')}
        >
          <NormalText
            fontweight='bold'
            color={preto}
            fontsize={px2vw(60)}>Pagamentos</NormalText>
          <TabSelected 
            isVisible={handler.currTabSelected === 'tabStorePaymentMethods'}
            width="100%"
            height={px2vw(15)}
          />
        </Col>
        <Col  
          minwidth={px2vw(640)}
          pad={px2vw(50)}
          onClick={() => handler.handleCurrTabSelected('tabStoreDeliveryTakeAwayParams')}
        >
          <NormalText
            fontweight='bold'
            color={preto}
            fontsize={px2vw(60)}>Delivery/ Retirada</NormalText>
            <TabSelected 
              isVisible={handler.currTabSelected === 'tabStoreDeliveryTakeAwayParams'}
              width="100%"
              height={px2vw(15)}
            />
        </Col>
      </Row>
      {handler.currTabSelected === 'tabStoreAvailabilities' && <StoreAvailabilities isOnboarding isMobile={handler.isMobileOnboarding} />}
      {handler.currTabSelected === 'tabStorePaymentMethods' && <StorePaymentMethods isMobileOnboarding={handler.isMobileOnboarding} />}
      {handler.currTabSelected === 'tabStoreDeliveryTakeAwayParams' && <StoreDeliveryTakeAwayParams isMobileOnboarding={handler.isMobileOnboarding} />}
    </>
  )
}