import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addDays, isToday, parseISO } from 'date-fns';
import addMinutes from 'date-fns/addMinutes';
import { Progress } from 'reactstrap';
import { toast } from 'react-toastify';
import WhatsApp from '@material-ui/icons/WhatsApp';
import { CircularProgress, FormControlLabel } from '@material-ui/core';

import {
  Container,
  Span,
  ProgressBar,
  BackIcon,
  ModalFooter,
  PinBtn,
  ButtonPrimary,
  PinIcon,
  Box,
  SideSlider,
  iconMui,
  PaymentMethod,
  SwitchWithInput,
  SwitchController,
  SwitchWithInputBox,
  SwitchLabelRegularText,
  SwitchInput,
} from './styles';
import { PaymentImg } from '../Step3-PaymentMethod/styles';
import {
  cinzaKuppi1,
  preto,
  verdeKuppi5,
  cinzaKuppi10,
  cinzaKuppi4,
  cinzaKuppi7,
  vermelhoKuppi6,
} from '../../../styles/colors';
import { Row, Col } from '../../../styles/grid';

import px2vw from '../../../utils/responsiveness/px2vw';
import { formatAddress,  } from '../../../utils/masks';
import { formatCurrencyBRL, onlyNumbers } from '../../../utils/utils';
import { formatDate } from '../../../utils/formatDate';
import { makeId, makeSchedulesForHoraries } from '../../../utils/makeSchedules';
import { weekdays } from '../../../utils/getCurrentDay';
import { sendManualOrderInfo } from '../../../services/vendors/mixpanel';

import {
  createManualOrderRequest,
  setCurrStep,
  setSchedule,
  setChangeValue,
  setPaymentMethod,
  setDiscount,
  cleanDiscount,
} from '../../../store/modules/manualOrder/actions';

export default function OrderReview() {
  const hasFreeTrialValid = useSelector(
    (state) => state.user.hasFreeTrialValid
  );
  const user = useSelector((state) => state.user.user);
  const manualOrderData = useSelector((state) => state?.manualOrder);
  const orderRequestLoading = manualOrderData.orderRequestLoading;
  const addressData = manualOrderData.advertiser?.user?.address[0];
  const deliveryFee = manualOrderData?.advertiser?.delivery_fee;
  const clientAddressData = manualOrderData.address;
  const bagData = manualOrderData?.bag;
  const [isApplyingDiscountInOrder, setIsApplyingDiscountInOrder] =
    useState(false);
  const [discountType, setDiscountType] = useState('percent_discount');
  const [percentDiscountValue, setPercentDiscountValue] = useState(
    (state) => state?.manualOrder.bag.discount_in_bag
  );
  const [amountDiscountValue, setAmountDiscountValue] = useState(
    (state) => state?.manualOrder.bag.discount_in_amount
  );
  const deliveryTax =
    bagData.deliveryType === 'delivery' ? manualOrderData.deliveryFee : 0;
  const {
    items_in_bag,
    priceOfTheWholeBag,
    priceWithoutDiscount,
    changeValue,
  } = bagData;
  const paymentMethod = manualOrderData?.paymentMethod?.paymentMethod;
  const deliveryTimeAvg = manualOrderData?.advertiser?.delivery_time_avg;
  const takeAwayTimeAvg = manualOrderData?.advertiser?.take_away_time_avg;
  const maxPossibleScheduleDays = 721;
  const eachDayFromNow = Array.from(Array(maxPossibleScheduleDays).keys()).map(
    (_, idx) => addDays(new Date(), idx)
  );
  const schedulesAvailability = useSelector(
    (state) => state.manualOrder?.advertiser?.advertiserSchedulingAvailability
  );
  const schedulingConfig = useSelector(
    (state) => state.manualOrder?.advertiser?.advertiserSchedulingConfig
  );
  const ordersConfig = useSelector(
    (state) => state.manualOrder?.advertiser?.advertiserOrdersConfig
  );
  const currentAddress = manualOrderData.address;
  const getDeliveryValue = manualOrderData.deliveryFee;
  const deliveryTakeawayConfig =
    manualOrderData.advertiser?.advertiserDeliveryTakeawayConfig[0];
  const deliveryDynamicFee = manualOrderData.advertiser?.delivery_dynamic_fee;
  const deliveryDynamicFeeFreeDist =
    manualOrderData.advertiser?.delivery_dynamic_fee_free_dist;
  const intervalBetweenSchedulesInMinutes =
    schedulingConfig?.interval_between_scheduling;
  const minSchedulingTime = schedulingConfig?.min_scheduling_time;
  const isAcceptScheduled = ordersConfig?.is_accepting_scheduled_order;
  const isAcceptImmediate = ordersConfig?.is_accepting_immediate_order;
  const dispatch = useDispatch();

  const availabilities = useMemo(
    () =>
      weekdays.map((day) =>
        schedulesAvailability ? schedulesAvailability[day] : null
      ),
    [schedulesAvailability]
  );

  const scheduleDays = useMemo(
    () =>
      eachDayFromNow.map((date, idx) => {
        const currentAvailability = availabilities[date.getDay()];
        const lastHorary = currentAvailability
          ? [...currentAvailability?.horaries].pop()
          : null;
        if (lastHorary && currentAvailability?.is_active) {
          const schedules = makeSchedulesForHoraries({
            date,
            lastHorary,
            minSchedulingTime,
            intervalBetweenSchedulesInMinutes,
            isAcceptScheduled,
            isAcceptImmediate,
          });
          const scheduleDay = {
            id: formatDate(date, 'yyyy-MM-dd-cccccc'),
            date,
            schedules,
            shortName: formatDate(date, 'cccccc'),
          };
          return scheduleDay;
        } else if (
          isToday(date) &&
          !currentAvailability?.is_active &&
          isAcceptImmediate
        ) {
          const now = new Date();
          const scheduleDay = {
            id: formatDate(date, 'yyyy-MM-dd-cccccc'),
            date,
            schedules: [
              {
                id: makeId(now, null, 'immediate'),
                timeFrom: now,
                timeTo: null,
                type: 'immediate',
              },
            ],
            shortName: formatDate(date, 'cccccc'),
          };
          return scheduleDay;
        } else {
          const scheduleDay = {
            id: formatDate(date, 'yyyy-MM-dd-cccccc'),
            date,
            schedules: [],
            shortName: formatDate(date, 'cccccc'),
          };
          return scheduleDay;
        }
      }),
    [
      availabilities,
      intervalBetweenSchedulesInMinutes,
      isAcceptScheduled,
      isAcceptImmediate,
      minSchedulingTime,
    ]
  );

  const nextAvailableScheduleDay = useMemo(
    () =>
      scheduleDays?.find((scheduleDay) => {
        if (isToday(scheduleDay.date) && scheduleDay.schedules.length) {
          // Force only return the schedule immediate, if the user accepts immediate orders
          if (
            scheduleDay.schedules[0].type === 'immediate' &&
            isAcceptImmediate
          ) {
            return true;
          }
          // Force only return the schedule scheduled, if the user accepts scheduled orders
          else if (
            scheduleDay.schedules[0].type === 'scheduled' &&
            isAcceptScheduled
          ) {
            return true;
          }
        } else {
          return scheduleDay.schedules.length;
        }
      }),
    [scheduleDays]
  );

  const [selectedSchedule, setSelectedSchedule] = useState(null);

  useEffect(() => {
    if (
      selectedSchedule &&
      selectedSchedule.type === 'immediate' &&
      ordersConfig?.is_accepting_immediate_order
    ) {
      return;
    } else if (
      selectedSchedule &&
      selectedSchedule.type === 'scheduled' &&
      ordersConfig?.is_accepting_scheduled_order
    ) {
      return;
    }
    const firstSchedule = nextAvailableScheduleDay?.schedules[0];
    if (firstSchedule && !manualOrderData?.selectedSchedule) {
      setSelectedSchedule(firstSchedule);
      dispatch(setSchedule(firstSchedule));
    }

    if (manualOrderData?.selectedSchedule) {
      setSelectedSchedule(manualOrderData?.selectedSchedule);
      dispatch(setSchedule(manualOrderData?.selectedSchedule));
    }
  }, [nextAvailableScheduleDay, selectedSchedule, ordersConfig]);

  const selectSchedule = useCallback(
    (id, scheduleDayId) => {
      const scheduleDay = scheduleDays.find(
        (scheduleDay) => scheduleDay.id === scheduleDayId
      );
      const foundSchedule = scheduleDay.schedules.find(
        (schedule) => schedule.id === id
      );

      if (foundSchedule) {
        setSelectedSchedule(foundSchedule);
        dispatch(setSchedule(foundSchedule));
      }
    },
    [scheduleDays]
  );

  const schedules =
    nextAvailableScheduleDay?.schedules.length > 3
      ? nextAvailableScheduleDay?.schedules?.slice(0, 3)
      : nextAvailableScheduleDay?.schedules;

  const formatSchedule = useCallback(
    (schedule) => {
      if (!schedule) return '';
      const timeFrom = new Date(schedule?.timeFrom);
      const timeTo = new Date(schedule?.timeTo);
      const type = schedule?.type;
      const timeFromImmediate = addMinutes(
        timeFrom,
        manualOrderData.bag.deliveryType === 'delivery'
          ? deliveryTimeAvg
          : takeAwayTimeAvg
      );

      if (isToday(timeFrom) && type === 'immediate') {
        return `hoje, ${formatDate(timeFrom, 'HH:mm')} - ${formatDate(
          timeFromImmediate,
          'HH:mm'
        )}`;
      } else if (isToday(timeFrom) && timeTo) {
        return `hoje, ${formatDate(timeFrom, 'HH:mm')} - ${formatDate(
          timeTo,
          'HH:mm'
        )}`;
      } else {
        return `${formatDate(
          new Date(timeFrom),
          'cccccc dd/MM, HH:mm'
        )} - ${formatDate(new Date(timeTo), 'HH:mm')}`;
      }
    },
    [manualOrderData.bag.deliveryType, deliveryTimeAvg, takeAwayTimeAvg]
  );

  const DeliveryTypes = {
    DYNAMIC: 'dynamic',
    FREE: 'free',
    UNIQUE: 'unique',
  };

  const getFeeType = useMemo(() => {
    if (deliveryFee === 0 && deliveryDynamicFee !== 0) {
      return DeliveryTypes.DYNAMIC;
    }

    if (deliveryFee === 0 && deliveryDynamicFee === 0) {
      return DeliveryTypes.FREE;
    }

    if (deliveryFee !== 0 && deliveryDynamicFee === 0) {
      return DeliveryTypes.UNIQUE;
    }
  }, [deliveryDynamicFee, deliveryFee]);

  const getDeliveryDescription = useMemo(() => {
    const deliveryValue = getDeliveryValue;
    if (
      deliveryTakeawayConfig &&
      deliveryTakeawayConfig.is_using_neighborhood_area &&
      !deliveryTakeawayConfig.is_using_range_area
    ) {
      if (!currentAddress) {
        return `Selecione seu endereço`;
      }
      return deliveryValue > 0 ? formatCurrencyBRL(deliveryValue, 2) : `Grátis`;
    }
    switch (getFeeType) {
      case DeliveryTypes.FREE:
        return 'Grátis';
      case DeliveryTypes.UNIQUE:
        return `${formatCurrencyBRL(deliveryFee, 2)}`;
      case DeliveryTypes.DYNAMIC:
        if (deliveryDynamicFeeFreeDist && !deliveryValue) {
          return `Grátis até ${deliveryDynamicFeeFreeDist}km.`;
        } else if (!deliveryDynamicFeeFreeDist && !deliveryValue) {
          return `À partir de ${formatCurrencyBRL(deliveryDynamicFee, 2)}`;
        }
        return formatCurrencyBRL(deliveryValue, 2);
      default:
        return '';
    }
  }, [
    deliveryFee,
    getDeliveryValue,
    getFeeType,
    deliveryDynamicFee,
    deliveryDynamicFeeFreeDist,
    deliveryTakeawayConfig,
    currentAddress,
  ]);

  useEffect(() => {
    if (paymentMethod?.slug === 'cash' && changeValue !== 0) {
      if (
        parseFloat(priceOfTheWholeBag) + deliveryTax ===
        parseFloat(changeValue)
      ) {
        dispatch(setChangeValue(0));
      } else if (
        parseFloat(priceOfTheWholeBag) + deliveryTax >
        parseFloat(changeValue)
      ) {
        dispatch(setChangeValue(0));
        dispatch(setPaymentMethod(null));
      }
    }
  }, [priceOfTheWholeBag, deliveryTax]);

  useEffect(() => {
    //MixPanel Event
    sendManualOrderInfo({
      id: user.advertiserInfo.id,
      name: user.advertiserInfo.name,
      email: user.email,
      currManualOrderStep: 'OrderReview',
      hasFreeTrialValid: hasFreeTrialValid,
    });
  }, []);

  useEffect(() => {
    if (isApplyingDiscountInOrder) {
      if (discountType === 'amount_discount') {
        dispatch(setDiscount('amount_discount', amountDiscountValue));
      } else {
        dispatch(setDiscount('percent_discount', percentDiscountValue));
      }
    } else {
      dispatch(cleanDiscount());
    }
  }, [
    isApplyingDiscountInOrder,
    amountDiscountValue,
    percentDiscountValue,
    discountType,
  ]);

  function handleCreateManualOrder() {
    if (bagData?.deliveryType === 'delivery' && !clientAddressData) {
      toast.error('Endereço de entrega não foi informado.', {
        autoClose: 6000,
      });
    } else if (!paymentMethod) {
      toast.error('Forma de pagamento não informada.', { autoClose: 6000 });
    } else {
      dispatch(createManualOrderRequest());
    }
  }

  function handleBackToCatalog() {
    dispatch(setCurrStep('2', '5'));
  }

  function handleSeeSchedules() {
    dispatch(setCurrStep('5-1'));
  }

  function handleDiscountType(type) {
    if (type === 'amount_discount') {
      setPercentDiscountValue('');
    } else if (type === 'percent_discount') {
      setAmountDiscountValue('');
    }

    setDiscountType(type);
  }

  function handleSetDiscount(type, value) {
    value = value.replace(',', '.');
    if (type === 'amount_discount') {
      if (value < 0 || parseFloat(value) > parseFloat(priceWithoutDiscount)) {
        value = priceWithoutDiscount;
      }
      setAmountDiscountValue(value);
    } else if (type === 'percent_discount') {
      if (value < 0 || value > 100) {
        value = 100;
      }
      setPercentDiscountValue(onlyNumbers(value));
    }
  }

  return (
    <>
      <ProgressBar>
        <Progress
          value={100}
          bar={true}
          style={{
            backgroundColor: verdeKuppi5,
            borderRaduis: '0px',
            height: '10px',
          }}
        />
      </ProgressBar>
      <Row
        justifyStart
        alignCenter
        className="py-1 px-2"
        cursor="pointer"
        background={cinzaKuppi1}
        onClick={() => handleBackToCatalog()}
      >
        <BackIcon
          src={require('../../../assets/images/arrows/arrow_left.png')}
        />
        <Span padding={px2vw(3)} color={preto}>
          Resumo do Pedido
        </Span>
      </Row>
      <Container className="my-2 px-5">
        <Col
          borderbottom={`1px solid ${cinzaKuppi4}`}
          cursor={bagData.deliveryType === 'delivery' ? 'pointer' : 'initial'}
          onClick={() => {
            if (bagData.deliveryType === 'delivery') {
              dispatch(setCurrStep('4', '5'));
            }
          }}
        >
          <Row justifyStart className="mb-2">
            <Span
              className="ml-2"
              fontWeight="bolder"
              color={cinzaKuppi10}
              cursor={bagData.deliveryType === 'delivery' ? 'pointer' : 'initial'}
            >
              {bagData?.deliveryType === 'delivery'
                ? 'Entregar em'
                : 'Retirada em'}
            </Span>
          </Row>
          <Row justifyStart className="mb-2">
            <Span
              className="ml-2"
              fontWeight="200"
              color={cinzaKuppi10}
              fontSize={px2vw(12)}
              cursor={
                bagData.deliveryType === 'delivery' ? 'pointer' : 'initial'
              }
            >
              <PinIcon
                src={require('../../../assets/images/pin/pin.png')}
                cursor={bagData.deliveryType === 'delivery' ? 'pointer' : 'initial'}
              />
              {manualOrderData?.bag?.deliveryType === 'delivery' &&
                clientAddressData &&
                formatAddress(clientAddressData)}
              {manualOrderData?.bag?.deliveryType === 'delivery' &&
                !clientAddressData &&
                'Clique para escolher o endereço'}
              {manualOrderData?.bag?.deliveryType === 'take_away' &&
                formatAddress(addressData)}
            </Span>
          </Row>
        </Col>
        <SideSlider className="mt-3">
          {selectedSchedule?.type !== 'immediate' &&
          !isToday(parseISO(selectedSchedule?.timeFrom)) ? (
            <Box delivery onClick={() => handleSeeSchedules()} selected>
              <PinBtn
                src={require('../../../assets/images/pinbtn/pinbtn.png')}
              />
              <Span
                className="ml-2 mb-2"
                fontWeight="bolder"
                color={cinzaKuppi10}
                cursor="pointer"
              >
                {selectedSchedule?.type === 'immediate' ? 'Padrão' : 'Agendado'}
              </Span>
              <Span
                className="ml-2"
                fontWeight="500"
                color={cinzaKuppi7}
                fontSize={px2vw(11)}
                cursor="pointer"
              >
                {formatSchedule(selectedSchedule)}
              </Span>
            </Box>
          ) : (
            schedules?.slice(0, 3).map((schedule) => (
              <Box
                className="mr-2"
                key={schedule.id}
                selected={selectedSchedule?.id === schedule?.id}
                onClick={() =>
                  selectSchedule(schedule.id, nextAvailableScheduleDay?.id)
                }
              >
                {selectedSchedule?.id === schedule?.id && (
                  <PinBtn
                    src={require('../../../assets/images/pinbtn/pinbtn.png')}
                  />
                )}
                <Span
                  className="ml-2 mb-2"
                  fontWeight="bolder"
                  color={cinzaKuppi10}
                  cursor="pointer"
                >
                  {schedule?.type === 'immediate' ? 'Padrão' : 'Agendar'}
                </Span>
                <Span
                  className="ml-2"
                  fontWeight="500"
                  color={cinzaKuppi7}
                  fontSize={px2vw(11)}
                  cursor="pointer"
                >
                  {formatSchedule(schedule)}
                </Span>
              </Box>
            ))
          )}
        </SideSlider>
        {isAcceptScheduled && (
          <Row
            justifyEnd
            borderbottom={`1px solid ${cinzaKuppi4}`}
          >
            <Span
              className="ml-2 my-2"
              fontWeight="500"
              color={verdeKuppi5}
              cursor="pointer"
              fontSize={px2vw(11)}
              onClick={() => handleSeeSchedules()}
            >
              Ver horários
            </Span>
          </Row>
        )}
        <Col
          alignStart
          cursor="pointer"
          onClick={() => dispatch(setCurrStep('3', '5'))}
        >
          <Span
            className="mt-2"
            fontWeight="bolder"
            color={cinzaKuppi10}
            cursor="pointer"
          >
            Forma de pagamento
          </Span>
          <PaymentMethod className="mb-2">
            <PaymentImg src={paymentMethod?.image_url} />
            <Span
              className="ml-3"
              fontSize={px2vw(12)}
              fontWeight="400"
              color={cinzaKuppi10}
              cursor="pointer"
            >
              {paymentMethod?.name ||
                'Clique para escolher a forma de pagamento'}
            </Span>
          </PaymentMethod>
        </Col>
        <Box
          h="auto"
          width="98.5%"
          pad={px2vw(0)}
          selected={isApplyingDiscountInOrder}
        >
          <Row 
            height="auto" 
            justifyStart 
            pad={px2vw(8)} 
            onClick={() => setIsApplyingDiscountInOrder(!isApplyingDiscountInOrder)}
          >
            <Span color={cinzaKuppi10}>Aplicar desconto no pedido</Span>
            <PinBtn
              src={require(`../../../assets/images/pinbtn/${
                isApplyingDiscountInOrder ? 'pinbtn' : 'pinOffbtn'
              }.png`)}
            />
          </Row>
          {isApplyingDiscountInOrder && (
            <Col padleft={px2vw(10)}>
              <Row justifyStart height="auto">
                <Col width={px2vw(150)}>
                  <SwitchWithInput
                    margleft={px2vw(15)}
                    width="auto"
                    marginBottom={px2vw(2)}
                  >
                    <FormControlLabel
                      className="mr-0"
                      control={
                        <SwitchController
                          checked={discountType === 'percent_discount'}
                          type="checkbox"
                          onChange={() => handleDiscountType('percent_discount')}
                        />
                      }
                      label={
                        <SwitchLabelRegularText size={px2vw(9)}>
                          Percentual no valor
                        </SwitchLabelRegularText>
                      }
                    />
                  </SwitchWithInput>
                </Col>
                <SwitchWithInputBox isVisible={true}>
                  <SwitchLabelRegularText>
                    <SwitchInput
                      width={px2vw(40)}
                      name="percent_value"
                      disabled={discountType !== 'percent_discount'}
                      maxLength="3"
                      placeholder="10"
                      value={percentDiscountValue || ''}
                      onChange={(e) =>
                        handleSetDiscount('percent_discount', e.target.value)
                      }
                    />
                    <b>%</b>
                  </SwitchLabelRegularText>
                </SwitchWithInputBox>
              </Row>
              <Row justifyStart height="auto" width="100%">
                <Col width={px2vw(150)}>
                  <SwitchWithInput
                    margleft={px2vw(15)}
                    width="auto"
                    marginBottom={px2vw(0)}
                  >
                    <FormControlLabel
                      className="mr-0"
                      control={
                        <SwitchController
                          checked={discountType === 'amount_discount'}
                          type="checkbox"
                          onChange={() => handleDiscountType('amount_discount')}
                        />
                      }
                      label={
                        <SwitchLabelRegularText size={px2vw(9)}>
                          Desconto no valor
                        </SwitchLabelRegularText>
                      }
                    />
                  </SwitchWithInput>
                </Col>
                <SwitchWithInputBox isVisible={true}>
                  <SwitchLabelRegularText>
                    <SwitchInput
                      width={px2vw(70)}
                      name="amount_discount"
                      disabled={discountType !== 'amount_discount'}
                      maxLength={priceOfTheWholeBag.length || 5}
                      placeholder="R$ 5,00"
                      value={amountDiscountValue}
                      onChange={(e) =>
                        handleSetDiscount('amount_discount', e.target.value)
                      }
                    />
                  </SwitchLabelRegularText>
                </SwitchWithInputBox>
              </Row>
            </Col>
          )}
        </Box>
        <Col>
          <Row justifyStart className="mt-3">
            <Span
              className="mb-2  mx-0"
              fontWeight="bolder"
              color={cinzaKuppi10}
            >
              Resumo do pedido
            </Span>
          </Row>
          {items_in_bag.length > 0 &&
            items_in_bag?.map((item, index) => (
              <Col
                key={`${item.id}-${index * 10}`}
                className="py-1"
                justifyStart
                alignEnd
              >
                <Row alignEnd>
                  <Span
                    fontSize={px2vw(11)}
                    color={cinzaKuppi10}
                    fontWeight="500"
                    className="mr-auto"
                  >
                    {item.productQuantity}x {item.name}
                  </Span>
                  {item.amount > 0 && (
                    <Span
                      fontSize={px2vw(11)}
                      color={cinzaKuppi10}
                      className="ml-auto"
                    >
                      {formatCurrencyBRL(item.amount, 2)}
                    </Span>
                  )}
                </Row>
                {item?.selectedComplements?.length > 0 &&
                  item.selectedComplements.map((complement) => (
                    <React.Fragment key={complement.id}>
                      {complement.options.map((option) => (
                        <React.Fragment key={option.id}>
                          {option?.quantity > 0 && !option.is_selected ? (
                            <Row
                              key={`${item.id}-${index}`}
                              className="pl-3"
                              justifyStart
                            >
                              <Span
                                fontSize={px2vw(11)}
                                color={cinzaKuppi10}
                                fontWeight="500"
                              >
                                {option.quantity}x {option.name}
                              </Span>

                              {option.amount > 0 && (
                                <Span
                                  fontSize={px2vw(11)}
                                  color={cinzaKuppi10}
                                  fontWeight="bolder"
                                  className="ml-auto"
                                >
                                  {formatCurrencyBRL(option.amount, 2)}
                                </Span>
                              )}
                            </Row>
                          ) : (
                            option.quantity > 0 &&
                            option.is_selected && (
                              <Row className="pl-3" justifyStart>
                                <Span
                                  fontSize={px2vw(11)}
                                  color={cinzaKuppi10}
                                  fontWeight="500"
                                  key={`${item.id}-${index}`}
                                >
                                  {option.quantity}x {option.name}
                                </Span>

                                {option.amount > 0 && (
                                  <Span
                                    fontSize={px2vw(11)}
                                    color={cinzaKuppi10}
                                    fontWeight="bolder"
                                    className="ml-auto"
                                  >
                                    {formatCurrencyBRL(option.amount, 2)}
                                  </Span>
                                )}
                              </Row>
                            )
                          )}
                        </React.Fragment>
                      ))}
                    </React.Fragment>
                  ))}
              </Col>
            ))}
        </Col>
      </Container>
      <Col bordertop={`1px solid ${cinzaKuppi4}`} className="pt-2">
        {paymentMethod?.slug === 'cash' && changeValue > 0 && (
          <>
            <Row className="px-5">
              <Span
                fontSize={px2vw(9)}
                color={cinzaKuppi10}
                fontWeight="400"
                className="mr-auto"
                display="inline-block"
              >
                Troco para
              </Span>
              <Span
                fontSize={px2vw(11)}
                color={cinzaKuppi10}
                fontWeight="400"
                className="ml-auto"
                display="inline-block"
              >
                {formatCurrencyBRL(changeValue, 2)}
              </Span>
            </Row>
            <Row className="px-5">
              <Span
                fontSize={px2vw(9)}
                color={cinzaKuppi10}
                fontWeight="400"
                className="mr-auto"
                display="inline-block"
              >
                Total do troco
              </Span>
              <Span
                fontSize={px2vw(11)}
                color={cinzaKuppi10}
                fontWeight="400"
                className="ml-auto"
                display="inline-block"
              >
                {formatCurrencyBRL(
                  parseFloat(changeValue - priceOfTheWholeBag) - deliveryTax,
                  2
                )}
              </Span>
            </Row>
          </>
        )}
        {isApplyingDiscountInOrder && (
          <Row className="px-5">
            <Span
              fontSize={px2vw(9)}
              color={vermelhoKuppi6}
              fontWeight="400"
              className="mr-auto"
              display="inline-block"
            >
              Desconto{' '}
              {discountType === 'percent_discount' &&
                `de ${percentDiscountValue || 0}%`}
            </Span>
            <Span
              fontSize={px2vw(11)}
              color={vermelhoKuppi6}
              fontWeight="400"
              className="ml-auto"
              display="inline-block"
            >
              {discountType === 'percent_discount'
                ? `${formatCurrencyBRL(
                    priceWithoutDiscount * ((percentDiscountValue || 0) / 100),
                    2
                  )}`
                : `${formatCurrencyBRL(amountDiscountValue || 0.0, 2)}`}
            </Span>
          </Row>
        )}
        {manualOrderData.bag?.deliveryType === 'delivery' && (
          <Row className="px-5">
            <Span
              fontSize={px2vw(9)}
              color={cinzaKuppi10}
              fontWeight="400"
              className="mr-auto"
              display="inline-block"
            >
              Entrega
            </Span>
            <Span
              fontSize={px2vw(11)}
              color={cinzaKuppi10}
              fontWeight="400"
              className="ml-auto"
              display="inline-block"
            >
              {getDeliveryDescription}
            </Span>
          </Row>
        )}
        <Row className="px-5">
          <Span
            fontSize={px2vw(9.5)}
            color={cinzaKuppi10}
            fontWeight="700"
            className="mr-auto"
            display="inline-block"
          >
            Subtotal
          </Span>
          <Span
            fontSize={px2vw(11)}
            color={cinzaKuppi10}
            fontWeight="400"
            className="ml-auto"
            display="inline-block"
          >
            {formatCurrencyBRL(priceOfTheWholeBag, 2)}
          </Span>
        </Row>
        <Row className="px-5">
          <Span
            fontSize={px2vw(12)}
            color={cinzaKuppi10}
            fontWeight="bolder"
            className="mr-auto"
            display="inline-block"
          >
            Total
          </Span>
          <Span
            fontSize={px2vw(13)}
            color={cinzaKuppi10}
            fontWeight="bolder"
            className="ml-auto"
            display="inline-block"
          >
            {formatCurrencyBRL(parseFloat(priceOfTheWholeBag) + deliveryTax, 2)}
          </Span>
        </Row>
      </Col>
      <ModalFooter>
        <ButtonPrimary
          onClick={handleCreateManualOrder}
          disabled={orderRequestLoading}
        >
          {!orderRequestLoading ? (
            <>
              Concluir e notificar <WhatsApp className="ml-3" />
            </>
          ) : (
            <CircularProgress style={iconMui} size="sm" />
          )}
        </ButtonPrimary>
      </ModalFooter>
    </>
  );
}
