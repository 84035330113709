import produce from 'immer';

const INITIAL_STATE = {
  conversations: [],
  chatBarItems: [],
  isHistorySyncComplete: false,
};

export default function conversations(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@whatsappChatConversations/SET_CONVERSATIONS_ARRAY':
        draft.conversations = action.payload;
        break;
      case '@whatsappChatConversations/SET_CHAT_BAR_ITEMS':
        draft.chatBarItems = action.payload;
        break;
      case '@whatsappChatConversations/SET_NEW_MESSAGE':
        draft.conversations = action.payload;
        break;
      case '@whatsappChatConversations/SET_IS_HISTORY_SYNC':
        draft.isHistorySyncComplete = action.payload
        break;
      default:
    }
  });
}
