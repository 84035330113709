import React, { useMemo, useState } from 'react';
import { LeftSection } from './LeftSection';
import { TaskSection } from './TaskSection';
import { useChallenge } from '../Context';
import { useDispatch, useSelector } from 'react-redux';
import { CloseModal, Overlay, ProgressModalContainer } from './styles';
import { getURL } from '../../../utils/utils';
import { sendTestMessage } from '../../../store/modules/challenges/actions';

export const ProgressModal = ({ onClose = () => {}, onConfirm = () => {}, award = '' }) => {
  const { conditions } = useChallenge();
  const { advertiserInfo } = useSelector((state) => state.user.user);

  const [stepOne, stepTwo, stepThree, stepFour] = conditions;
  const dispatch = useDispatch()

  const paymentToken = useSelector((state) => state.user.paymentToken);

  const openPaymentLink = () => {
    window.open(
      getURL('anunciante') + process.env.REACT_APP_PAYMENT_LINK + paymentToken,
      '_blank'
    );
  };

  const closedFirstModal =
    Boolean(
      localStorage.getItem(`@${advertiserInfo.id}:closedChallengeModal:1`)
    ) && stepOne;
  const closedSecondModal =
    Boolean(
      localStorage.getItem(`@${advertiserInfo.id}:closedChallengeModal:2`)
    ) && stepTwo;
  const closedThirdModal =
    Boolean(
      localStorage.getItem(`@${advertiserInfo.id}:closedChallengeModal:3`)
    ) && stepThree;
  const closedFourthModal =
    Boolean(
      localStorage.getItem(`@${advertiserInfo.id}:closedChallengeModal:4`)
    ) && stepFour;
    const closedFifthModal =
    Boolean(
      localStorage.getItem(`@${advertiserInfo.id}:closedChallengeModal:5`)
    );

  const [show, setShow] = useState(
    !Boolean(localStorage.getItem(`@${advertiserInfo.id}:closedChallengeModal`))
  );

  const handleClose = () => {
    onClose();
    localStorage.setItem(`@${advertiserInfo.id}:closedChallengeModal`, true);
    setShow(false);
  };

  const content = useMemo(() => {
    if (!stepOne && !stepTwo && !stepThree && !stepFour && show) {
      return {
        title: 'APRENDA A MEXER NA KUPPI COM 4 TAREFAS RÁPIDAS',
        description:
          'Complete as 4 tarefas do desafio da ativação em 48 horas.',
        award: `Ganhe ${award}.`,
        buttonText: 'Iniciar Desafio',
        onConfirm: () => {
          onConfirm();
          handleClose();
          setShow(false);
        },
        onClose: () => {
          onClose()
          localStorage.setItem(
            `@${advertiserInfo.id}:closedChallengeModal`,
            true
          );
          setShow(false);
        }
      };
    }

    if (stepOne && !closedFirstModal) {
      return {
        title: 'Você concluiu a primeira tarefa!',
        description:
          'Agora você já sabe como ativar o WhatsApp na Plataforma da Kuppi!',
        award:
          'Clique em continuar para receber uma mensagem e simular o atendimento do chatbot.',
        buttonText: 'Continuar Desafio e simular atendimento',
        onConfirm: () => {
          localStorage.setItem(
            `@${advertiserInfo.id}:closedChallengeModal:1`,
            true
          );
          dispatch(sendTestMessage())
          onConfirm();
          onClose();
        },
        onClose: () => {
          onClose()
          localStorage.setItem(
            `@${advertiserInfo.id}:closedChallengeModal:1`,
            true
          );
        }
      };
    }

    if (stepTwo && !closedSecondModal) {
      return {
        title: 'VOCÊ CONCLUIU A SEGUNDA TAREFA! 👏',
        description:
          'Agora você já sabe como fazer a gestão dos pedidos na Plataforma.',
        award: null,
        buttonText: 'Continuar Desafio',
        onConfirm: () => {
          localStorage.setItem(
            `@${advertiserInfo.id}:closedChallengeModal:2`,
            true
          );
          onClose();
          onConfirm();
        },
        onClose: () => {
          onClose()
          localStorage.setItem(
            `@${advertiserInfo.id}:closedChallengeModal:2`,
            true
          );
        }
      };
    }

    if (stepThree && !closedThirdModal) {
      return {
        title: 'VOCÊ CONCLUIU A TERCEIRA TAREFA! 👏',
        description:
          'Agora você já sabe como criar pedidos manuais na Plataforma da Kuppi.',
        award: null,
        buttonText: 'Continuar Desafio',
        onConfirm: () => {
          localStorage.setItem(
            `@${advertiserInfo.id}:closedChallengeModal:3`,
            true
          );
          onClose();
          onConfirm();
        },
        onClose: () => {
          onClose()
          localStorage.setItem(
            `@${advertiserInfo.id}:closedChallengeModal:3`,
            true
          );
        }
      };
    }

    if (stepFour && !closedFourthModal) {
      return {
        title: 'VOCÊ CONCLUIU A QUARTA TAREFA! 👏',
        description:
          'Agora você já sabe como criar campanhas de disparo em massa na Plataforma da kuppi.',
        award: null,
        buttonText: 'Continuar Desafio',
        onConfirm: () => {
          localStorage.setItem(
            `@${advertiserInfo.id}:closedChallengeModal:4`,
            true
          );
          onClose();
          onConfirm();
        },
        onClose: () => {
          onClose()
          localStorage.setItem(
            `@${advertiserInfo.id}:closedChallengeModal:4`,
            true
          );
        }
      };
    }
    if (stepOne && stepTwo && stepThree && stepFour && !closedFifthModal) {
      return {
        title: 'VOCÊ CONCLUIU O DESAFIO! 👏',
        description:
          'Tudo pronto para utilizar a Kuppi e ver o seu Delivery crescer a cada semana.',
        award: `Você ganhou ${award}.`,
        buttonText: 'Resgatar Desconto',
        onConfirm: () => {
          localStorage.setItem(
            `@${advertiserInfo.id}:closedChallengeModal:5`,
            true
          );
          handleClose();
          openPaymentLink();
        },
        onClose: () => {
          onClose()
          localStorage.setItem(
            `@${advertiserInfo.id}:closedChallengeModal:5`,
            true
          );
        }
      };
    }

    return null;
  }, [
    conditions,
    closedFirstModal,
    closedSecondModal,
    closedThirdModal,
    closedFourthModal,
    closedFifthModal,
    show,
  ]);

  const handleConfirm = () => {
    [
      closedFirstModal,
      closedSecondModal,
      closedThirdModal,
      closedFourthModal,
    ].forEach((s, i) => {
      if (s) {
        localStorage.setItem(
          `@${advertiserInfo.id}:closedChallengeModal:${i + 1}`,
          true
        );
      }
    });

    localStorage.setItem(`@${advertiserInfo.id}:closedChallengeModal`, true);

    content.onConfirm();
    setShow(false);
  };

  if (
    (!closedFirstModal ||
      !closedSecondModal ||
      !closedThirdModal ||
      !closedFourthModal ||
      !closedFifthModal ||
      show) &&
    content
  ) {
    return (
      <>
        <Overlay>
          <ProgressModalContainer>
            <CloseModal onClick={() => {
              handleClose()
              content.onClose()
            }}>clear</CloseModal>

            <LeftSection {...content} />

            <TaskSection
              buttonText={content?.buttonText}
              onConfirm={handleConfirm}
            />
          </ProgressModalContainer>
        </Overlay>
      </>
    );
  }

  return null;
};
