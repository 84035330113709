import { takeLatest, call, put, all, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { apiAnunciante } from '../../../services/api';

import {
  getRatingsConfigSuccess,
  getRatingsConfigFailure,
  updateRatingsConfigsSuccess,
  updateRatingsConfigsFailure,
  getRatingsSuccess,
  getRatingsFailure,
  getRatingByOrderIdSuccess,
  getRatingByOrderIdFailure
} from './actions';

import {
  logout
} from '../auth/actions';

const userInfoData = (state) => state.user.user.advertiserInfo;

export function* getRatingsConfigRequest() {
  try {
    const userInfo = yield select(userInfoData);

    const response = yield call(
      apiAnunciante.get,
      `/user-info/${userInfo.id}/ratings/config`
    );

    yield put(getRatingsConfigSuccess(response.data));
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(getRatingsConfigFailure());
      toast.error(
        `Ocorreu um erro ao recuperar as configurações de avaliações. Carregue a página novamente!`,
        {
          autoClose: 6000,
        }
      );
    }
  }
}

export function* updateRatingsConfigRequest({ payload }) {
  try {
    const userInfo = yield select(userInfoData);

    yield call(
      apiAnunciante.put,
      `/user-info/${userInfo.id}/ratings/config`,
      {
        is_rating_active: payload.isRatingsActive,
        minutes_to_send_rating: payload.ratingsMsgTime
      }
    );

    yield put(updateRatingsConfigsSuccess({
      is_rating_active: payload.isRatingsActive,
      minutes_to_send_rating: payload.ratingsMsgTime
    }));
    toast.success('Configurações de avaliações atualizadas com sucesso',
      {
        autoClose: 6000,
      }
    )
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(updateRatingsConfigsFailure());
      toast.error(
        `Ocorreu um erro ao atualizar as configurações de avaliações. Carregue a página novamente!`,
        {
          autoClose: 6000,
        }
      );
    }
  }
}

export function* getRatingsRequest() {
  try {
    const userInfo = yield select(userInfoData);

    const response = yield call(
      apiAnunciante.get,
      `/user-info/${userInfo.id}/ratings`
    );

    yield put(getRatingsSuccess(response.data));
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(getRatingsFailure());
      toast.error(
        `Ocorreu um erro ao recuperar as avaliações. Carregue a página novamente!`,
        {
          autoClose: 6000,
        }
      );
    }
  }
}

export function* getRatingsPaginatedRequest({ payload }) {
  try {
    const userInfo = yield select(userInfoData);

    const response = yield call(
      apiAnunciante.get,
      `/user-info/${userInfo.id}/ratings?page=${payload.paginationPage}`
    );

    yield put(getRatingsSuccess(response.data));
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(getRatingsFailure());
      toast.error(
        `Ocorreu um erro ao recuperar as avaliações. Carregue a página novamente!`,
        {
          autoClose: 6000,
        }
      );
    }
  }
}

export function* getRatingByOrderIdRequest({ payload }) {
  try {
    const userInfo = yield select(userInfoData);

    const response = yield call(
      apiAnunciante.get,
      `/user-info/${userInfo.id}/ratings/${payload.orderId}`
    );

    yield put(getRatingByOrderIdSuccess(response.data));
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(getRatingByOrderIdFailure());
      toast.error(
        `Ocorreu um erro ao pesquisar a avalição do pedido. Carregue a página novamente!`,
        {
          autoClose: 6000,
        }
      );
    }
  }
}

export default all([
  takeLatest(
    '@ratings/GET_RATINGS_CONFIG_REQUEST',
    getRatingsConfigRequest
  ),
  takeLatest(
    '@ratings/UPDATE_RATINGS_CONFIG_REQUEST',
    updateRatingsConfigRequest
  ),
  takeLatest(
    '@ratings/GET_RATINGS_REQUEST',
    getRatingsRequest
  ),
  takeLatest(
    '@ratings/GET_RATINGS_PAGINATED_REQUEST',
    getRatingsPaginatedRequest
  ),
  takeLatest(
    '@ratings/GET_RATING_BY_ORDER_ID_REQUEST',
    getRatingByOrderIdRequest
  )
]);
