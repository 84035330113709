import styled from 'styled-components';

import px2vw from '../../../utils/responsiveness/px2vw';

import {
  branco,
  preto,
  verdeKuppi1,
  amareloKuppi2,
} from '../../../styles/colors';

export const ActiveTextYellow = styled.div`
  color: ${amareloKuppi2};
  font-size: ${(props) => props.fontSize || px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.36px;
`;

export const GoToPaymentLink = styled.div`
  font-size: ${(props) => props.fontSize || px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.36px;
  width: ${(props) => props.width || px2vw(230)};
  height: ${(props) => props.height || px2vw(35)};
  color: ${preto}80;
  border-radius: ${(props) => props.borderradius || px2vw(10)};
  border: 2px solid ${preto}80;
  background-color: ${branco};
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const CopyQrCode = styled.div`
  font-size: ${(props) => props.fontSize || px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.36px;
  color: ${branco};
  width: ${(props) => props.width || px2vw(230)};
  height: ${(props) => props.height || px2vw(35)};
  border-radius: ${(props) => props.borderradius || px2vw(10)};
  border: none;
  background-color: ${verdeKuppi1};
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const CopyQrCodeTextBold = styled.span`
  font-size: ${(props) => props.fontSize || px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.36px;
`;
