import { toast } from 'react-toastify';
import qz from 'qz-tray';
import moment from 'moment';

import { EscPosPrint } from '../printOrderInvoice/printFormats/ESCPOS';

const fakePrintData = {
  order: {
    address: {
      city: 'Rio de Janeiro',
      complement: 'Apto 10 Bloco C',
      neighborhood: 'Barra da Tijuca',
      number: '900',
      state: 'RJ',
      street: 'Rua Mário Covas Junior',
      zipcode: '22631030',
    },
    amount: 23.7,
    change: 0,
    clientInfo: {
      name: 'Cliente Teste',
      phone: '5521999966660',
    },
    delivery_fee: 3,
    delivery_type: 'delivery',
    discount: 0,
    id: 1,
    orderProduct: [
      {
        obs: null,
        orderProductComplements: [
          {
            complements: [
              {
                amount: null,
                name: 'Nome Complemento 1',
                quantity: 1,
              },
            ],
          },
        ],
        product: {
          amount: 7.9,
          name: 'Nome Produto 1',
        },
        quantity: 3,
      },
    ],
    order_type: 'immediate',
    paymentMethod: {
      name: 'Ben Visa Vale Alimentação',
    },
    total_amount: 26.7,
  },
  new_at: moment(new Date()).format('DD/MM/YYYY HH:mm'),
};

export function printInvoiceQZTray({ printerName, orderStatusData, advertiserData, maxLineCharsAutomatic }, testPrint = false) {
  if (!qz.websocket.isActive()) {
    toast.warning('QZ Tray não conectado. Por favor, tente novamente!');
    return;
  }
  
  // Try to find the printer before print, avoiding errors if the store printer name wasn't found anymore
  qz.printers.find(printerName)
    .then(() => {
      // Mount ESCPOS print data
      const data = EscPosPrint({
        orderStatusData: testPrint ? fakePrintData : orderStatusData,
        advertiserData: advertiserData,
        maxLineCharsAutomatic: maxLineCharsAutomatic,
      })

      // Print data
      let config = qz.configs.create(printerName);
      qz.print(config, data);
    })
    .catch((e) => {
      if (printerName && printerName !== 'select_printer') {
        toast.error(
          `Não é possível imprimir na impressora selecionada: ${printerName}. Verifique se a impressora está disponível e associada para impressão no QZ Tray.`
        );
      } else if (testPrint) {
        toast.error(
          `Não é possível testar a impressão sem selecionar uma impressora.`
        );
        } else {
        toast.error(
          `Não foi escolhida uma impressora para imprimir. Acesse as configurações e configure-a corretamente.`
        );
      }
    });
}



