import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  BoxContainer, Container, Separator, SmallText, TitleText, Text
} from '../styles';

import { branco, cinzaKuppi4, preto, preto2, verdeKuppi2 } from '../../../styles/colors';
import { Col, Row } from '../../../styles/grid';

import px2vw from '../../../utils/responsiveness/px2vw';
import { formatCurrencyBRL } from '../../../utils/utils';

import {
  getSalesRecoverDataRequest,
} from '../../../store/modules/salesRecover/actions';

export default function StatsPage() {
  const {
    salesRecoverData
  } = useSelector((state) => state.salesRecover)
  const [sentMessages, setSentMessages] = useState(0);
  const [recoveredSales, setRecoveredSales] = useState(0);
  const [recoveredSalesRevenue, setRecoveredSalesRevenue] = useState(0.0);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSalesRecoverDataRequest());
  }, []);

  useEffect(() => {
    setSentMessages(salesRecoverData?.reached_leads_qty || 0);
    setRecoveredSales(salesRecoverData?.total_recovered_sales || 0);
    setRecoveredSalesRevenue(salesRecoverData?.recovered_revenue || 0.0);
  }, [salesRecoverData]);

  return (
    <Container>
      <Row justifyStart alignCenter spaceAround width={px2vw(550)} margleft={px2vw(-20)} padtop={px2vw(30)} padbottom={px2vw(30)}>
        {/* Reached People */}
        <BoxContainer
          key={'saleRecoverStatsCard#1'}
          width={px2vw(228)}
          height={px2vw(270)}
        >
          <TitleText padtop={px2vw(10)}>Pessoas Alcançadas</TitleText>
          <Separator />
          <Row justifyStart padleft={px2vw(6)} padright={px2vw(6)} height="auto">
            <TitleText fontSize={px2vw(15)} color={preto} textAlign="flex-start">
              {sentMessages ? `${sentMessages} cliente${sentMessages > 1 ? 's' : ''}` : '0 mensagens enviadas'}
            </TitleText>
          </Row>
          <Row justifyStart padleft={px2vw(6)} padright={px2vw(6)} height="auto">
            <SmallText fontSize={px2vw(13)} color={preto} textAlign="flex-start">
              {sentMessages ? `${sentMessages > 1 ? 'pediram' : 'pediu'} o link do seu cardápio e não ${sentMessages > 1 ? 'compraram' : 'comprou'}` : 'Ainda não foram enviadas mensagens de recuperação de venda'}
            </SmallText>
          </Row>
          <Row
            height={px2vw(48)}
            className="mt-auto"
            background={sentMessages > 0 ? verdeKuppi2 : cinzaKuppi4}
            relative
            justifyCenter
            alignCenter
            borderRadius={`0 0 ${px2vw(9)} ${px2vw(9)}`}
            padding={px2vw(-10)}
          >
            {sentMessages > 0 && (
              <img
                className="w-100 position-absolute"
                style={{ top: px2vw(-49) }}
                src={require('../../../assets/images/sales_recover/graphic_bg.png')}
              />
            )}
            <Text size={px2vw(21)} color={sentMessages ? branco : preto2} bold>
              {sentMessages > 0 ? '100%' : '0%'}
            </Text>
          </Row>
        </BoxContainer>
        {/* Recovered Sales */}
        <BoxContainer
          key={'saleRecoverStatsCard#2'}
          width={px2vw(228)}
          height={px2vw(270)}
        >
          <TitleText padtop={px2vw(10)}>Vendas Recuperadas</TitleText>
          <Separator />
          <Row justifyStart padleft={px2vw(6)} padright={px2vw(6)} height="auto">
            <TitleText fontSize={px2vw(15)} color={preto} textAlign="flex-start">
              {`${recoveredSales} venda${recoveredSales > 1 ? 's' : ''}`}
            </TitleText>
          </Row>
          <Col alignStart padleft={px2vw(6)} padright={px2vw(6)} height="auto">
            <SmallText fontSize={px2vw(13)} color={preto} textAlign="flex-start">
              {recoveredSales ? `${recoveredSales > 1 ? 'foram' : 'foi'} recuperada${recoveredSales > 1 ? 's' : ''} pelo robô` : 'Ainda não houveram vendas recuperadas'}
            </SmallText>
            {recoveredSales && (
              <Row margtop={px2vw(10)}>
                <SmallText fontSize={px2vw(13)} color={preto} textAlign="flex-start">
                  {` Totalizando ${formatCurrencyBRL(recoveredSalesRevenue, 2)} de faturamento`}
                </SmallText>
              </Row>
            )}
          </Col>
          <Row
            height={px2vw(49)}
            className="mt-auto"
            background={recoveredSales ? verdeKuppi2 : cinzaKuppi4}
            relative
            justifyCenter
            alignCenter
            borderRadius={`0 0 ${px2vw(9)} ${px2vw(9)}`}
          >
            <Text size={px2vw(21)} color={recoveredSales ? branco : preto2} bold>
              {sentMessages > 0 ? `${Math.trunc((recoveredSales / sentMessages) * 100)}%` : '0%'}
            </Text>
          </Row>
        </BoxContainer>
      </Row>
    </Container>
  )
}
