import styled from 'styled-components';
import confetti from '../../assets/images/onboarding/confetti@2x.png';

import {
  branco,
  preto,
  verdeKuppi1,
  verdeKuppi4,
  cinzaKuppi2,
  cinzaKuppi3,
  cinzaKuppi4,
  cinzaKuppi6,
} from '../../styles/colors';

import px2vw from '../../utils/responsiveness/px2vw';

export const ModalBackground = styled.div`
  display: none;
  position: fixed;
  z-index: 20;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: ${cinzaKuppi6}99;

  ${(props) =>
    props.isVisible &&
    `
    display: flex;
    justify-content: center;
    align-items: center;
  `};
`;

export const ModalContent = styled.div`
  background-color: ${branco};
  padding: ${(props) => props.padding || '20px'};
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${(props) => `
    width: ${props.width};
    min-height: ${props.height};
    max-height: ${props.maxheight};
    background-color: ${props.bgcolor};
    margin-top: ${props.margtop};
  `};

  ${(props) =>
    props.backgroundImg &&
    `
    background-image: url(${confetti});
    background-size: 100% ${props.backgroundImgSize || px2vw(250)}, cover;
    background-repeat: no-repeat;
  `};
`;

export const ModalTitleText = styled.div`
  font-size: ${px2vw(18)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.48px;
  text-align: center;

  ${(props) => `
    padding-left: ${props.paddingLR};
    padding-right: ${props.paddingLR};
    margin-top: ${props.margtop};
    font-size: ${props.fontsize};
  `};
`;

export const ModalActions = styled.div`
  display: flex;
  flex-direction: row;

  ${(props) => `
    padding: ${props.padding};
    width: ${props.width};
    justify-content: ${props.justifyContent};
    margin-top: ${props.margtop};
  `};
`;

export const ChangeStatusButton = styled.div`
  background-color: ${verdeKuppi1};
  border: 1px solid ${verdeKuppi1};
  color: ${branco};
  border-radius: ${px2vw(10)};
  height: ${px2vw(40)};
  width: ${px2vw(200)};
  font-size: ${px2vw(13)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.36px;
  text-align: center;
  padding-top: ${px2vw(10)};
  cursor: pointer;

  :hover {
    background-color: ${verdeKuppi4};
    border: 1px solid ${verdeKuppi1};
  }

  &:focus,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
    color: ${branco};
  }
`;

export const ModalPrimaryButton = styled.button`
  background-color: ${verdeKuppi1};
  width: ${px2vw(120)};
  height: ${px2vw(40)};
  border: 1px solid ${verdeKuppi1};
  border-radius: ${px2vw(10)};
  padding: 0.3em;

  :focus {
    background-color: ${verdeKuppi1} !important;
  }

  :hover {
    background-color: ${verdeKuppi4};
    border: 1px solid ${verdeKuppi1};
  }

  ${(props) => `
    margin-top: ${props.margtop};
    width: ${props.width};
    margin-left: ${props.margleft};
    margin-right: ${props.marginRight};
  `};

  ${(props) =>
    props.disabled &&
    `
    background-color: ${cinzaKuppi4};
    border: 1px solid ${cinzaKuppi4};
  `}
`;

export const ModalPrimaryButtonText = styled.div`
  font-size: ${px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.36px;
  color: ${branco};

  ${(props) =>
    props.disabled &&
    `
    color: ${cinzaKuppi3};
  `}
`;

export const ModalSecundaryButton = styled.button`
  background-color: ${branco};
  width: ${px2vw(120)};
  height: ${px2vw(40)};
  border: 2px solid ${preto};
  border-radius: ${px2vw(10)};
  padding: 0.3em;

  :focus {
    background-color: ${branco} !important;
  }

  :hover {
    background-color: ${cinzaKuppi2};
  }

  ${(props) => `
    margin-top: ${props.margtop}; 
    width: ${props.width};
    margin-right: ${props.marginRight};
    margin-left: ${props.margleft};
  `};
`;

export const ModalSecundaryButtonText = styled.div`
  font-size: ${px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.36px;
`;

export const CloseImage = styled.img`
  width: ${(props) => props.width || px2vw(20)};
  height: ${(props) => props.height || px2vw(20)};
  padding-right: ${(props) => props.margright};
  cursor: pointer;

  ${(props) =>
    props.isMobile &&
    `
    width: ${px2vw(70)};
    height: ${px2vw(70)};
  `};
`;

export const ModalText = styled.div`
  margin-top: ${px2vw(25)};
  font-size: ${px2vw(16)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.48px;
  text-align: center;

  ${(props) => `
    padding-left: ${props.paddingLR};
    padding-right: ${props.paddingLR};
    margin-top: ${props.margtop};
    font-size: ${props.fontsize};
  `};
`;

export const ModalText2 = styled.div`
  font-size: ${px2vw(18)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.48px;
  text-align: center;

  ${(props) => `
    padding-left: ${props.paddingLR};
    padding-right: ${props.paddingLR};
    font-size: ${props.fontsize};
  `};
`;

export const ModalHeader = styled.header`
  font-size: ${px2vw(12)};
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  ${(props) =>
    props.isMobile &&
    `
    padding-right: ${px2vw(90)};
  `};
`;
