import produce from 'immer';
import { paginateState } from '../../../utils/commonObjects';

const rateGrade = {
  percent: 0.0,
  rating_qty: 0
}

const ratingsData = { 
  rating: 0.0,
  total_ratings: 0,
  very_bad: rateGrade,
  bad: rateGrade,
  good: rateGrade,
  very_good: rateGrade,
  excellent: rateGrade,
}

const INITIAL_STATE = {
  ratingsConfigMinutesToSend: 25,
  ratingsConfigIsRatingActive: false,
  ratingsConfigUpdateLoading: false,
  ratingsConfigUpdateSuccess: false,
  getRatingsLoading: false,
  getRatingsPaginationLoading: false,
  ratingsList: [],
  ratingsPagination: paginateState,
  ratingsData: ratingsData,
  getRatingByOrderIdLoading: false,
};

export default function ratings(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@ratings/GET_RATINGS_CONFIG_SUCCESS': {
        draft.ratingsConfigMinutesToSend = action.payload.ratingsConfig.minutes_to_send_rating;
        draft.ratingsConfigIsRatingActive = action.payload.ratingsConfig.is_rating_active;
        break;
      }
      case '@ratings/GET_RATINGS_CONFIG_FAILURE': {
        draft.ratingsConfigMinutesToSend = 25;
        draft.ratingsConfigIsRatingActive = false;
        break;
      }
      case '@ratings/UPDATE_RATINGS_CONFIG_REQUEST': {
        draft.ratingsConfigUpdateLoading = true;
        draft.ratingsConfigUpdateSuccess = false;
        break;
      }      
      case '@ratings/UPDATE_RATINGS_CONFIG_SUCCESS': {
        draft.ratingsConfigUpdateLoading = false;
        draft.ratingsConfigUpdateSuccess = true;
        draft.ratingsConfigMinutesToSend = action.payload.ratingsConfig.minutes_to_send_rating;
        draft.ratingsConfigIsRatingActive = action.payload.ratingsConfig.is_rating_active;
        break;
      }
      case '@ratings/UPDATE_RATINGS_CONFIG_FAILURE': {
        draft.ratingsConfigUpdateLoading = false;
        draft.ratingsConfigUpdateSuccess = false;
        break;
      }
      case '@ratings/GET_RATINGS_REQUEST': {
        draft.getRatingsLoading = true;
        break;
      }
      case '@ratings/GET_RATINGS_PAGINATED_REQUEST': {
        draft.getRatingsPaginationLoading = true;
        break;
      }
      case '@ratings/GET_RATINGS_SUCCESS': {
        draft.getRatingsLoading = false;
        draft.getRatingsPaginationLoading = false;
        draft.ratingsList = [];
        draft.ratingsPagination = paginateState;
        draft.ratingsList = action.payload.ratings.data;
        draft.ratingsPagination = {
          total: action.payload.ratings.total,
          perPage: action.payload.ratings.perPage,
          page: action.payload.ratings.page,
          lastPage: action.payload.ratings.lastPage,
        };
        draft.ratingsData = action.payload.rating_data;
        break;
      }
      case '@ratings/GET_RATINGS_FAILURE': {
        draft.getRatingsLoading = false;
        draft.getRatingsPaginationLoading = false;
        draft.ratingsList = [];
        draft.ratingsPagination = paginateState;
        draft.ratingsData = ratingsData;
        break;
      }
      case '@ratings/GET_RATING_BY_ORDER_ID_REQUEST': {
        draft.getRatingByOrderIdLoading = true;
        break;
      }
      case '@ratings/GET_RATING_BY_ORDER_ID_SUCCESS': {
        draft.getRatingByOrderIdLoading = false;
        draft.ratingsList = [];
        draft.ratingsPagination = paginateState;
        draft.ratingsList = action.payload.ratings;
        break;
      }
      case '@ratings/GET_RATING_BY_ORDER_ID_FAILURE': {
        draft.getRatingByOrderIdLoading = false;
        break;
      }
      case '@ratings/CLEAR_SUCCESS': {
        draft.ratingsConfigUpdateSuccess = false;
        break;
      }
      default:
    }
  });
}
