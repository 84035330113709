import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { onlyNumbers } from '../../../../utils/utils';
import { cpfCnpjMask } from '../../../../utils/masks';
import px2vw from '../../../../utils/responsiveness/px2vw';
import px2em from '../../../../utils/responsiveness/px2em';
import { updateStoreDataField } from '../../../../store/modules/financial/actions';
import {
  SaveButton,
  SaveButtonText,
} from '../../styles';
import {
  ConfigGroupTitle,
  SwitchLabelBoldText,
} from '../../../MyStore/styles';
import { OptionSelector } from '../../styles';
import { Col, Row } from '../../../../styles/grid';
import { LoadingBox, SwitchInput } from '../../../_layouts/styles/common';
import { Spinner } from 'reactstrap';
import { preto } from '../../../../styles/colors';
import ButtonSecondary from '../../../../components/ButtonSecondary';

export const BankData = ({
  isCNPJ,
  data,
  isBankEditable,
  widths,
  onSave = () => {},
  onCancel = () => {}
}) => {
  const { user } = useSelector((state) => state.user);
  const { createSellerLoading, isFirstAccess, bankList } = useSelector(
    (state) => state.financial
  );

  const isEditable = useMemo(() => isBankEditable, [isBankEditable])

  const accountCategories = [
    { id: 'conta_corrente', title: 'Corrente' },
    { id: 'conta_poupanca', title: 'Poupança' },
  ];

  const dispatch = useDispatch();

  return (
    <>
      <Row>
        <Col justifyStart>
          <ConfigGroupTitle>Dados Bancários</ConfigGroupTitle>
          <ConfigGroupTitle isSubtitle>
            Cadastre a sua conta bancária que receberá os repasses dos
            pagamentos online
          </ConfigGroupTitle>
          <ConfigGroupTitle isSubtitle>
            {isCNPJ
              ? `A conta deve estar associada ao CNPJ da empresa (${cpfCnpjMask(
                  onlyNumbers(user.advertiserInfo.cpf_cnpj)
                )})`
              : `A conta deve estar associada ao CPF (${cpfCnpjMask(
                  onlyNumbers(user.advertiserInfo.cpf_cnpj)
                )})`}
          </ConfigGroupTitle>
        </Col>
      </Row>

      <Row spaceBetween margtop={px2vw(20)}>
        <Col justifyStart>
          <SwitchLabelBoldText marginLeft={px2em(4)}>Banco</SwitchLabelBoldText>
          <OptionSelector
            padleft={px2vw(10)}
            id="bankOptionSelector"
            name="bankOptionSelector"
            width={px2vw(240)}
            disabled={!isEditable}
            placeholder="Escolha seu banco"
            value={data.bank}
            options={bankList || []}
            onChange={(t) =>
              dispatch(
                updateStoreDataField({
                  field: 'bank',
                  value: t.target.value,
                })
              )
            }
          />
        </Col>

        <Col justifyStart width={widths} margright={px2vw(10)}>
          <SwitchLabelBoldText marginLeft={px2em(4)} marginBottom={px2vw(3)}>
            Agência
          </SwitchLabelBoldText>
          <SwitchInput
            height={px2vw(40)}
            width="80%"
            name="agency"
            placeholder=""
            maxLength="10"
            disabled={!isEditable}
            value={data.agency}
            onChange={(t) =>
              dispatch(
                updateStoreDataField({
                  field: 'agency',
                  value: onlyNumbers(t.target.value),
                })
              )
            }
          />
        </Col>

        <Col justifyStart width={widths} margright={px2vw(-15)}>
          <SwitchLabelBoldText
            marginLeft={px2em(4)}
            marginBottom={px2vw(3)}
            width={px2vw(110)}
          >
            Conta Corrente
          </SwitchLabelBoldText>
          <SwitchInput
            height={px2vw(40)}
            width="80%"
            name="accountNumber"
            placeholder=""
            maxLength="15"
            disabled={!isEditable}
            value={data.accountNumber}
            onChange={(t) =>
              dispatch(
                updateStoreDataField({
                  field: 'accountNumber',
                  value: onlyNumbers(t.target.value),
                })
              )
            }
          />
        </Col>
        <Col justifyStart width={widths}>
          <SwitchInput
            margtop={px2vw(20)}
            height={px2vw(40)}
            width="40%"
            name="accountDigitNumber"
            placeholder=""
            maxLength="1"
            disabled={!isEditable}
            value={data.accountDigitNumber}
            onChange={(t) =>
              dispatch(
                updateStoreDataField({
                  field: 'accountDigitNumber',
                  value: onlyNumbers(t.target.value),
                })
              )
            }
          />
        </Col>

        <Col justifyStart>
          <SwitchLabelBoldText marginLeft={px2em(4)}>Tipo</SwitchLabelBoldText>
          <OptionSelector
            padleft={px2vw(10)}
            margright={px2vw(15)}
            id="accountTypeOptionSelector"
            name="accountTypeOptionSelector"
            width={px2vw(150)}
            placeholder="Selecione"
            disabled={!isEditable}
            value={data.accountType}
            options={accountCategories}
            onChange={(t) => {
              dispatch(
                updateStoreDataField({
                  field: 'accountType',
                  value: t.target.value,
                })
              );
            }}
          />
        </Col>
      </Row>

      <Row margtop={px2vw(20)}>
        <Col justifyStart>
          <SwitchLabelBoldText marginLeft={px2em(4)} marginBottom={px2vw(3)}>
            {isCNPJ ? 'Razão Social' : 'Nome Completo'}
          </SwitchLabelBoldText>
          <SwitchInput
            height={px2vw(40)}
            width="83%"
            name="accountFullName"
            disabled={!isEditable}
            placeholder={
              isCNPJ
                ? 'Informe a razão social da empresa associada ao CNPJ, conforme consta na Receita Federal'
                : 'Informe o nome completo associado ao CPF, sem abreviações conforme consta na Receita Federal'
            }
            value={data.accountFullName}
            onChange={(t) =>
              dispatch(
                updateStoreDataField({
                  field: 'accountFullName',
                  value: t.target.value,
                })
              )
            }
          />
        </Col>
      </Row>

      <Row alignEnd justifyEnd margtop={px2vw(15)}>
      {!isFirstAccess && isEditable && <ButtonSecondary style={{marginRight: 10}} onClick={onCancel}>Cancelar</ButtonSecondary>}
        <SaveButton
          disabled={createSellerLoading}
          onClick={onSave}
          financialPageWidth={!isFirstAccess}
        >
          {createSellerLoading ? (
            <LoadingBox>
              <Spinner
                style={{
                  width: '1.8rem',
                  height: '1.8rem',
                  color: preto,
                }}
              />{' '}
            </LoadingBox>
          ) : (
            <SaveButtonText>
              {isFirstAccess
                ? `Cadastrar`
                : isEditable
                ? 'Salvar'
                : `Alterar Dados`}
            </SaveButtonText>
          )}
        </SaveButton>
      </Row>
    </>
  );
};
