import React, { useEffect, useState } from 'react';
import { Spinner } from 'reactstrap';

import px2vw from '../../../../utils/responsiveness/px2vw';
import { preto } from '../../../../styles/colors';
import { Col, Row } from '../../../../styles/grid';

import ButtonPrimary from '../../../../components/ButtonPrimary';

import { LoadingBox } from '../../../../styles/common';

import Checkbox from '../../../../components/Checkbox';

import { QuestionTitleText, FormData, CheckboxContainer } from '../../BaseScreen/styles';
import { scrollTop } from '../../../../utils/utils';


export default function PageOneMobile({ handler }) {
  const surveyFontSize = 50;
  const containerMarginInBetween = 60;
  const checkboxHeight = 120;

  const [scroll, setScroll] = useState(true)
  useEffect(() => {
    if (scroll) {
      scrollTop()
      setScroll(false)
    }
  }, [scroll])

  return (
    <Col>
      <Row margtop={px2vw(50)} justifyCenter padleft={px2vw(90)} padright={px2vw(90)}>
        <QuestionTitleText fontsize={px2vw(70)}>
          1 - Qual o principal objetivo você busca atingir utilizando a Kuppi?
        </QuestionTitleText>
      </Row>
      <FormData onSubmit={handler.handleSurveyObjectivesSubmit} width={'100%'}>
        <CheckboxContainer
          width={'100%'}
          padding={px2vw(25)}
          margbottom={containerMarginInBetween}
          justifyContent={'flex-start'}
          onClick={() => handler.objectiveOptionClick('increase_sales')}
        >
          <Checkbox
            padtop={25}
            height={checkboxHeight}
            styledBoxWidth={120}
            styledBoxHeight={100}
            imgWidth={55}
            labelmarginleft={50}
            fontSize={surveyFontSize}
            insideWidth={1200}
            label="Aumentar minhas vendas no Delivery"
            name="objectiveIncreaseSales"
            checked={handler.objectiveIncreaseSales}
          />
        </CheckboxContainer>
        <CheckboxContainer
          margbottom={containerMarginInBetween}
          width={'100%'}
          padding={px2vw(25)}
          justifyContent={'flex-start'}
          onClick={() => handler.objectiveOptionClick('reduce_marketplace_dependency')}
        >
          <Checkbox
            padtop={25}
            height={150}
            styledBoxWidth={120}
            styledBoxHeight={100}
            imgWidth={55}
            labelmarginleft={50}
            fontSize={surveyFontSize}
            insideWidth={1200}
            label="Reduzir minha dependência dos marketplaces"
            name="objectiveReduceMarketplaceDependency"
            checked={handler.objectiveReduceMarketplaceDependency}
          />
        </CheckboxContainer>
        <CheckboxContainer
          width={'100%'}
          padding={px2vw(25)}
          margbottom={containerMarginInBetween}
          justifyContent={'flex-start'}
          onClick={() => handler.objectiveOptionClick('optimize_delivery_operation')}
        >
          <Checkbox
            padtop={25}
            height={checkboxHeight}
            styledBoxWidth={120}
            styledBoxHeight={100}
            imgWidth={55}
            labelmarginleft={50}
            fontSize={surveyFontSize}
            insideWidth={1200}
            label="Automatizar o atendimento do meu delivery"
            name="objectiveOptimizeDeliveryOperation"
            checked={handler.objectiveOptimizeDeliveryOperation}
          />
        </CheckboxContainer>
        <CheckboxContainer
          width={'100%'}
          padding={px2vw(25)}
          margbottom={containerMarginInBetween}
          justifyContent={'flex-start'}
          onClick={() => handler.objectiveOptionClick('growth_marketing_strategy')}
        >
          <Checkbox
            padtop={25}
            height={150}
            styledBoxWidth={120}
            styledBoxHeight={100}
            imgWidth={55}
            labelmarginleft={50}
            fontSize={surveyFontSize}
            insideWidth={1200}
            label="Melhorar as estratégias de marketing do meu negócio"
            name="objectiveGrowthMarketingStrategy"
            checked={handler.objectiveGrowthMarketingStrategy}
          />
        </CheckboxContainer>
        <CheckboxContainer
          width={'100%'}
          padding={px2vw(25)}
          margbottom={containerMarginInBetween}
          justifyContent={'flex-start'}
          onClick={() => handler.objectiveOptionClick('fidelize_clients')}
        >
          <Checkbox
            padtop={25}
            height={checkboxHeight}
            styledBoxWidth={120}
            styledBoxHeight={100}
            imgWidth={55}
            labelmarginleft={50}
            fontSize={surveyFontSize}
            insideWidth={1200}
            label="Fidelizar cada vez mais clientes"
            name="objectiveFidelizeClients"
            checked={handler.objectiveFidelizeClients}
          />
        </CheckboxContainer>
        <CheckboxContainer
          width={'100%'}
          padding={px2vw(25)}
          margbottom={containerMarginInBetween}
          justifyContent={'flex-start'}
          onClick={() => handler.objectiveOptionClick('not_sure_exploring_product')}
        >
          <Checkbox
            padtop={25}
            height={150}
            styledBoxWidth={120}
            styledBoxHeight={100}
            imgWidth={55}
            labelmarginleft={50}
            fontSize={surveyFontSize}
            insideWidth={1200}
            label="Não tenho certeza ainda, estou apenas explorando o produto"
            name="objectiveNotSureExploringProduct"
            checked={handler.objectiveNotSureExploringProduct}
          />
        </CheckboxContainer>
        <Row justifyEnd margtop={px2vw(50)} height={px2vw(400)}>
          <ButtonPrimary
            borderRadius={40}
            minWidth='60%'
            height={150}
            fontSize={60}
            type='submit'
          >
            {handler.updateSurveyObjectivesLoading ? (
              <LoadingBox>
                <Spinner
                  style={{
                    width: '1.8rem',
                    height: '1.8rem',
                    color: preto,
                  }}
                />{' '}
              </LoadingBox>
            ) : (
              'Avançar'
            )}
          </ButtonPrimary>
        </Row>
      </FormData>
    </Col>
  )
}
