import React from 'react';

import { Col, Row } from '../../styles/grid';
import {
  Image,
  ImageBox,
  Title,
  NormalText,
  WhiteBox,
  GoToStoreButton,
} from './styles';

import px2vw from '../../utils/responsiveness/px2vw';
import RealTimeDimensions from '../../utils/realTimeDimensions';

import { dashboard } from '../../routes/routeMap';
import history from '../../services/history';

export default function AskDownloadApp() {
  const [width] = RealTimeDimensions();
  const isMobile = (width > 0 && width < 800) || width === 0 ? true : false;

  function openDownloadAppKuppi() {
    const linkToApp = process.env.REACT_APP_LINK_TO_DOWNLOAD_APP;
    window.open(
      linkToApp,
      '_blank'
    );
  }

  const askDownloadAppMobileContent = (
    <>
      <Col
        alignCenter
        padtop={px2vw(150)}
        padleft={px2vw(100)}
        padright={px2vw(100)}
      >
        <Title>Gerencie seus pedidos de onde estiver com o celular</Title>

        <Row
          width="auto"
          margtop={px2vw(50)}
          margleft={px2vw(175)}
          justifyCenter
        >
          <ImageBox>
            <Image
              src={require('../../assets/images/login/plate@2x.png')}
              alt="plate"
            />
          </ImageBox>

          <NormalText>
            Aceite pedidos de forma facilitada de onde estiver
          </NormalText>
        </Row>
        <Row
          width="auto"
          margtop={px2vw(40)}
          margleft={px2vw(175)}
          justifyCenter
        >
          <ImageBox bell>
            <Image
              src={require('../../assets/images/login/bell@2x.png')}
              alt="Bell"
            />
          </ImageBox>

          <NormalText bell>
            Receba notificações sempre que um pedido chegar
          </NormalText>
        </Row>
        <Row
          width="auto"
          margtop={px2vw(50)}
          margleft={px2vw(175)}
          justifyCenter
        >
          <ImageBox>
            <Image
              src={require('../../assets/images/login/menu@2x.png')}
              alt="Menu"
            />
          </ImageBox>

          <NormalText menu>Pause itens do cardápio</NormalText>
        </Row>
        <Row
          width="auto"
          margleft={px2vw(75)}
          padbottom={px2vw(100)}
          justifyCenter
        >
          <ImageBox app>
            <Image
              src={require('../../assets/images/login/appView@2x.png')}
              alt="App View"
            />
          </ImageBox>
        </Row>
      </Col>
      <Col alignCenter margtop={px2vw(-800)} height="auto">
        <WhiteBox>
          <GoToStoreButton onClick={() => openDownloadAppKuppi()}>
            FAZER DOWNLOAD DO APP KUPPI PARCEIRO
          </GoToStoreButton>

          <Row height="auto">
            <NormalText
              notInterested
              onClick={() => history.push(dashboard)}
            >
              Não tenho interesse
            </NormalText>
          </Row>
        </WhiteBox>
      </Col>
    </>
  )

  if (isMobile) {
    return askDownloadAppMobileContent;
  } else {
    history.push(dashboard);
  }
}
