import React, { useState } from 'react';

import { Text } from '../../../../../styles/common';

import px2vw from '../../../../../utils/responsiveness/px2vw';
import { ModalPrimaryButton } from '../../styles';
import { Row } from '../../../../../styles/grid';

import { ModalFooter, CheckBox } from '../styles';

import { AiOutlineLeft } from 'react-icons/ai';

import { useMemo } from 'react';

export const SegmentationFooter = ({
  showBack = true,
  showConfirm = false,
  showTermsAccept = false,
  hasClients = false,
  onGoBack,
  onGoNext,
}) => {
  const [termsHasBeenAccepted, setTermsHasBeenAccepted] = useState(false);

  const showBackButton = useMemo(() => {
    return showBack;
  }, [showBack]);

  const showTermsToAccept = useMemo(() => {
    return showTermsAccept;
  }, [showTermsAccept]);

  const showConfirmButton = useMemo(() => {
    return showConfirm;
  }, [showConfirm]);

  const hasClientsToShow = useMemo(() => {
    return hasClients;
  }, [hasClients]);

  return (
    <ModalFooter>
      {showBackButton && (
        <Row
          onClick={onGoBack}
          justifyStart
          alignCenter
          width="auto"
          cursor="pointer"
        >
          <AiOutlineLeft fontSize={px2vw(15)} className="mr-1" />
          <Text regular alignCenter size={px2vw(15)}>
            Voltar
          </Text>
        </Row>
      )}

      {showTermsToAccept && (
        <>
          <Row className="ml-auto" width="auto" alignCenter>
            <CheckBox
              checked={termsHasBeenAccepted}
              onClick={() => setTermsHasBeenAccepted(!termsHasBeenAccepted)}
            />
            <Text regular alignCenter size={px2vw(14)}>
              Aceito os{' '}
              <a
                style={{
                  textDecoration: 'none',
                }}
                target="_blank"
                href="/termos-de-uso?t=TUDM"
              >
                <strong
                  style={{
                    margin: `0 ${px2vw(4)}`,
                    cursor: 'pointer',
                  }}
                >
                  Termos
                </strong>{' '}
              </a>
              do disparo em massa
            </Text>
          </Row>
          <ModalPrimaryButton
            disabled={!termsHasBeenAccepted || !hasClientsToShow}
            className="ml-auto"
            onClick={onGoNext}
          >
            Avançar
          </ModalPrimaryButton>
        </>
      )}

      {showConfirmButton && (
        <Row justifyCenter alignCenter>
          <ModalPrimaryButton onClick={onGoBack}>Confirmar</ModalPrimaryButton>
        </Row>
      )}
    </ModalFooter>
  );
};
