import styled from 'styled-components';

import px2vw from '../../../utils/responsiveness/px2vw';

import {
  branco,
  cinzaKuppi4,
} from '../../../styles/colors';

export const PixReportBox = styled.div`
  border-radius: ${px2vw(20)};
  background-color: ${branco};
  width: ${px2vw(1020)};
  height: auto;
  padding: ${px2vw(30)};
  margin-top: ${px2vw(5)};

  box-shadow: 0px 8px 9px -2px ${cinzaKuppi4};
`;

export const TextRegular = styled.div`
  font-size: ${px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.36px;
  border-right: ${(props) =>
    props.separator ? `1px solid ${cinzaKuppi4}` : 'none'};
  width: ${(props) => props.width};
  height: ${(props) => props.height};

  .img {
    width: ${px2vw(25)};
  }

  ${(props) =>
    props.alignCenter &&
   `text-align: center;
  `};

  ${(props) =>
    props.verticalCenter &&
    `
    display:flex;
    justify-content: flex-start;
    align-items: center;
  `};

  ${(props) =>
    props.flexCenter &&
    `
    display: flex;
    justify-content: center;
  `};
`;

export const TextBold = styled.div`
  font-size: ${px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.36px;
  border-right: ${(props) =>
    props.separator ? `1px solid ${cinzaKuppi4}` : 'none'};
  width: ${(props) => props.width};
  height: ${(props) => props.height};

  ${(props) =>
    props.alignCenter &&
    `
    text-align: center;
  `};

  ${(props) =>
    props.flexCenter &&
    `
    display: flex;
    justify-content: center;
  `};
`;

export const Title = styled.div`
  font-size: ${px2vw(18)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.4px;
`;

export const TitleKeyPix = styled.div`
  font-size: ${px2vw(15)};
  font-family: AvenirNextLTPro;
  font-weight: ${(props) => props.weight};
  letter-spacing: 0.4px;
`;
