import styled from 'styled-components';
import {
  cinzaKuppi1,
  cinzaKuppi15,
  cinzaKuppi4,
} from '../../../../styles/colors';
import { Col, Row } from '../../../../styles/grid';
import px2vw from '../../../../utils/responsiveness/px2vw';

export const TextBar = styled(Col)`
  padding: ${px2vw(10)} ${px2vw(10)} ${px2vw(10)} ${px2vw(10)};
  width: 100%;
  height: auto;
  max-height: ${px2vw(120)};
  background: ${cinzaKuppi1};
  ${(props) =>
    props.isManualOrderModalVisible &&
    `
  padding-left: 27%;
  width: 100%;
  align-self:flex-end;
`}
`;

export const TextFieldContainer = styled(Col)`
  width: 93%;
  height: auto;
  position: relative;
  min-height: ${px2vw(65)};
  max-height: 100%;
  background: white;
  border: 1px solid black;
  border-radius: ${px2vw(11)};
  overflow: hidden;
`;

export const TextField = styled.textarea`
  padding: ${px2vw(7)} ${px2vw(30)} ${px2vw(1)} ${px2vw(13)};
  width: 100%;
  height: auto;
  font-size: ${px2vw(13)};
  min-height: ${px2vw(50)};
  max-height: 100%;
  resize: none;
  border: none;
  background: transparent;
  &::placeholder {
    font-weight: 600;
    font-size: ${px2vw(13)};
    color: ${cinzaKuppi4};
  }
  &::-webkit-scrollbar {
    width: ${px2vw(6)};
    height: ${px2vw(6)};
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${cinzaKuppi15};
    border: ${px2vw(4)} solid ${cinzaKuppi15};
    background-clip: padding-box;
  }
`;
export const ToolBar = styled(Row)`
  padding-left: ${px2vw(13)};
  padding-top: ${px2vw(5)};
  padding-bottom: ${px2vw(4)};
  width: 100%;
  height: auto;
  justify-content: flex-start;
  max-height: 100%;
  border-top: 1px solid ${cinzaKuppi4};
`;

export const iconStyle = {
  margin: `${px2vw(0)} ${px2vw(13)}`,
};

export const Icon = styled.img`
  cursor: pointer;
  ${(props) => props.h && `height: ${props.h};`}
  ${(props) => props.m && `margin: ${props.m};`}
`;
