export function getOrdersMetricsRequest(loading) {
  return {
    type: '@dashboardMetrics/GET_ORDERS_METRICS_REQUEST',
    payload: { loading },
  };
}

export function getOrdersMetricsSuccess(ordersMetrics) {
  return {
    type: '@dashboardMetrics/GET_ORDERS_METRICS_SUCCESS',
    payload: ordersMetrics,
  };
}

export function getOrdersMetricsFailure() {
  return {
    type: '@dashboardMetrics/GET_ORDERS_METRICS_FAILURE',
  };
}

export function getGameficationRequest() {
  return {
    type: '@dashboardMetrics/GET_GAMEFICATION_REQUEST',
  };
}
export function getGameficationSuccess(data) {
  return {
    type: '@dashboardMetrics/GET_GAMEFICATION_SUCCESS',
    payload: data
  };
}
export function getGameficationFailure() {
  return {
    type: '@dashboardMetrics/GET_GAMEFICATION_FAILURE',
  };
}
