import React from 'react';
import { Spinner } from 'reactstrap';

import { Content } from '../../_layouts/styles/common';
import { Col, Row } from '../../../styles/grid';
import px2vw from '../../../utils/responsiveness/px2vw';

import DefaultContentMobile from '../../../components/SignaturePayment/DefaultContent/Mobile';
import PaymentCreditCardSuccessMobile from '../../../components/SignaturePayment/PaymentCreditCardSuccess/Mobile';
import BoletoGeneratedSuccessMobile from '../../../components/SignaturePayment/BoletoGeneratedSuccess/Mobile';
import PixGeneratedSuccessMobile from '../../../components/SignaturePayment/PixGeneratedSuccess/Mobile';

import { Container, PageContent, ErrorMessage } from '../styles';
import { preto } from '../../../styles/colors';

import { Image } from '../../../styles/common';

export default function SignaturePaymentMobile({ handler }) {
  const loadingContent = (
    <Content>
      <Col height="90vh" alignCenter justifyCenter>
        <Row justifyCenter width="auto" height="auto" margtop={px2vw(30)} margbottom={px2vw(450)}>
          <Image
            width={px2vw(900)}
            height={px2vw(500)}
            src={require('../../../assets/images/logo_kuppi/logo_kuppi@2x.png')}
          />
        </Row>
        <Col alignCenter justifyCenter>
          <Spinner
            style={{
              width: '1.8rem',
              height: '1.8rem',
              color: preto,
            }}
          />
        </Col>
      </Col>
    </Content>
  );

  const signaturePaymentContent = (
    <Container>
      <Row 
        justifyCenter 
        width="auto" 
        margtop={px2vw(30)} 
        margbottom={px2vw(30)} 
        height="auto"
      >
        <Image
          width={px2vw(900)}
          height={px2vw(500)}
          src={require('../../../assets/images/logo_kuppi/logo_kuppi@2x.png')}
        />
      </Row>
      <Row justifyCenter>
        <PageContent 
          minHeight="auto"
          width="100%" 
          pad={px2vw(80)} 
          borderRadius={px2vw(90)}
        >
          {!handler.boletoGeneratedSuccess && !handler.creditCardPaidSuccess && !handler.pixGeneratedSuccess && (
            <DefaultContentMobile handler={handler} />
          )}
          {handler.pixGeneratedSuccess && (
            <PixGeneratedSuccessMobile handler={handler} />
          )}
          {handler.boletoGeneratedSuccess && (
            <BoletoGeneratedSuccessMobile handler={handler} />
          )} 
          {handler.creditCardPaidSuccess && (
            <PaymentCreditCardSuccessMobile handler={handler} />
          )}
        </PageContent>
      </Row>
    </Container>
  );

  const errorContent = (
    <Content>
      <Col height="90vh" alignCenter justifyCenter>
        <Row justifyCenter width="auto" height="auto" margtop={px2vw(30)} margbottom={px2vw(100)}>
          <Image
          width={px2vw(900)}
          height={px2vw(500)}
            src={require('../../../assets/images/logo_kuppi/logo_kuppi@2x.png')}
          />
        </Row>
        <Col alignCenter justifyCenter padleft={px2vw(120)} padright={px2vw(120)}>
          <ErrorMessage fontSize={px2vw(70)}>
            Ocorreu um erro, recarregue a página e tente novamente.
            <br />
            Caso persista, entre em contato com o suporte.
          </ErrorMessage>
        </Col>
      </Col>
    </Content>
  );

  if (handler.loading) {
    return loadingContent;
  }
  if (handler.success) {
    return signaturePaymentContent;
  }
  return errorContent;
}
