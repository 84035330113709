import React from 'react';
import { Spinner } from 'reactstrap';

import { preto, verdeKuppi7 } from '../../../../styles/colors';
import { Row, Col } from '../../../../styles/grid';
import { LoadingBox } from '../../../../styles/common';
import {
  QuestionTitleText,
  FormData,
  CheckboxContainer,
  CheckbuttomContainer,
  InputDataTextArea,
} from '../../BaseScreen/styles';

import ButtonPrimary from '../../../../components/ButtonPrimary';
import Checkbox from '../../../../components/Checkbox';
import Checkbutton from '../../../../components/Checkbox/Checkbutton';

import px2vw from '../../../../utils/responsiveness/px2vw';
import { firstStepsPageTwoQuestionTwoTitleText, firstStepsPageTwoQuestionThreeTitleText, firstStepsPageTwoQuestionTwoAnswerOneText, firstStepsPageTwoQuestionTwoAnswerTwoText, firstStepsPageTwoQuestionTwoAnswerThreeText, firstStepsPageTwoQuestionTwoAnswerFourText, firstStepsPageTwoQuestionTwoAnswerFiveText, firstStepsPageTwoQuestionTwoAnswerSixText, firstStepsPageTwoQuestionThreeAnswerOneText, firstStepsPageTwoQuestionThreeAnswerTwoText, firstStepsPageTwoForwardButtonText } from '../constants';

export default function PageTwoWeb({ handler }) {
  const titleFontSize = 14;
  return (
    <Col width="auto" alignCenter>
      <FormData onSubmit={handler.handleSurveyFindOutAboutSubmit} web>
        <Row margtop={px2vw(50)} justifyStart>
          <QuestionTitleText fontsize={px2vw(20)}>
            {firstStepsPageTwoQuestionTwoTitleText}
          </QuestionTitleText>
        </Row>
        <Row margtop={px2vw(10)} height="auto">
          <Col width="auto">
            <CheckboxContainer
              width={px2vw(350)}
              height={px2vw(30)}
              margbottom={10}
              backgroundColor={verdeKuppi7}
              onClick={() => handler.findOutAboutOptionClick('social_media_advertising')}
            >
              <Checkbox
                label={firstStepsPageTwoQuestionTwoAnswerOneText}
                checked={handler.mediaAdvertising}

              />
            </CheckboxContainer>
            <CheckboxContainer
              width={px2vw(350)}
              height={px2vw(30)}
              margbottom={10}
              backgroundColor={verdeKuppi7}
              onClick={() => handler.findOutAboutOptionClick('member_get_member')}
            >
              <Checkbox
                label={firstStepsPageTwoQuestionTwoAnswerTwoText}
                checked={handler.memberGetMember}

              />
            </CheckboxContainer>
            <CheckboxContainer
              width={px2vw(350)}
              margbottom={10}
              backgroundColor={verdeKuppi7}
              onClick={() => handler.findOutAboutOptionClick('kuppi_wpp_employee_contact')}
            >
              <Checkbox
                height="auto"
                label={firstStepsPageTwoQuestionTwoAnswerThreeText}
                checked={handler.wppEmployeeContact}

              />
            </CheckboxContainer>

            <CheckboxContainer
              width={px2vw(350)}
              height={px2vw(30)}
              margbottom={10}
              backgroundColor={verdeKuppi7}
              onClick={() => handler.findOutAboutOptionClick('google_search')}
            >
              <Checkbox
                label={firstStepsPageTwoQuestionTwoAnswerFourText}
                checked={handler.googleSearch}

              />
            </CheckboxContainer>

            <CheckboxContainer
              width={px2vw(350)}
              height={px2vw(30)}
              margbottom={10}
              backgroundColor={verdeKuppi7}
              onClick={() => handler.findOutAboutOptionClick('wpp_group')}
            >
              <Checkbox
                label={firstStepsPageTwoQuestionTwoAnswerFiveText}
                checked={handler.wppGroup}

              />
            </CheckboxContainer>
          </Col>
          <Col width="auto" margleft={px2vw(10)}>
            <CheckboxContainer
              width={px2vw(350)}
              height={px2vw(120)}
              margbottom={10}
              backgroundColor={verdeKuppi7}
            >
              <Checkbox
                label={firstStepsPageTwoQuestionTwoAnswerSixText}
                checked={handler.other}
                onClick={() => handler.findOutAboutOptionClick('other')}
              />
              <InputDataTextArea
                name="Text"
                maxLength="100"
                width="96%"
                disabled={!handler.other}
                value={handler.otherContent}
                onChange={handler.otherContentChange}
              />
            </CheckboxContainer>
          </Col>
        </Row>
        <Row margtop={px2vw(20)} justifyStart>
          <QuestionTitleText fontsize={px2vw(20)}>
            {firstStepsPageTwoQuestionThreeTitleText}
          </QuestionTitleText>
        </Row>
        <Row margtop={px2vw(5)} justifyStart>
          <Col width="auto">
            <CheckbuttomContainer
              width={px2vw(175)}
              height={px2vw(35)}
              onClick={() => handler.hasComputerClick(true)}
            >
              <Checkbutton fontSize={titleFontSize} label={firstStepsPageTwoQuestionThreeAnswerOneText} checked={handler.hasComputer !== null && handler.hasComputer} />
            </CheckbuttomContainer>
          </Col>
          <Col width="auto" margleft={px2vw(20)}>
            <CheckbuttomContainer
              width={px2vw(185)}
              height={px2vw(35)}
              onClick={() => handler.hasComputerClick(false)}
            >
              <Checkbutton fontSize={titleFontSize} label={firstStepsPageTwoQuestionThreeAnswerTwoText} checked={handler.hasComputer !== null && !handler.hasComputer} />
            </CheckbuttomContainer>
          </Col>
        </Row>
        <Row justifyEnd>
          <ButtonPrimary minWidth={150} type="submit">
            {handler.updateSurveyFindOutAboutLoading ? (
              <LoadingBox>
                <Spinner
                  style={{
                    width: '1.8rem',
                    height: '1.8rem',
                    color: preto,
                  }}
                />{' '}
              </LoadingBox>
            ) : (
              firstStepsPageTwoForwardButtonText
            )}
          </ButtonPrimary>
        </Row>
      </FormData>
    </Col>
  );
}
