import produce from 'immer';

const INITIAL_STATE = {
  promptOrdersPerDayAndPerHourLoading: false,
  produceromptOrdersPerDayAndPerHourSuccess: false,
  promptOrdersPerDayAndPerHourResponse: {},
  promptOrderTypesLoading: false,
  promptOrderTypesSuccess: false,
  promptOrderTypesResponse: {},
  promptTopSoldProductsLoading: false,
  promptTopSoldProductsSuccess: false,
  promptTopSoldProductsResponse: {},
  promptOrdersPerNeighborhoodLoading: false,
  promptOrdersPerNeighborhoodSuccess: false,
  promptOrdersPerNeighborhoodResponse: {},
};
export default function insightsAi(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@insightsAi/GET_PROMPT_ORDERS_PER_DAY_AND_PER_HOUR_REQUEST': {
        draft.promptOrdersPerDayAndPerHourLoading = true;
        draft.promptOrdersPerDayAndPerHourSuccess = false;
        break;
      }
      case '@insightsAi/GET_PROMPT_ORDERS_PER_DAY_AND_PER_HOUR_SUCCESS': {
        draft.promptOrdersPerDayAndPerHourResponse = {
          ...state.promptOrdersPerDayAndPerHourResponse,
          [action.payload.key]: action.payload.response,
        };
        draft.promptOrdersPerDayAndPerHourLoading = false;
        draft.promptOrdersPerDayAndPerHourSuccess = true;
        break;
      }
      case '@insightsAi/GET_PROMPT_ORDERS_PER_DAY_AND_PER_HOUR_FAILURE': {
        draft.promptOrdersPerDayAndPerHourLoading = false;
        draft.promptOrdersPerDayAndPerHourSuccess = false;
        break;
      }
      case '@insightsAi/GET_PROMPT_ORDER_TYPES_REQUEST': {
        draft.promptOrderTypesLoading = true;
        draft.promptOrderTypesSuccess = false;
        break;
      }
      case '@insightsAi/GET_PROMPT_ORDER_TYPES_SUCCESS': {
        draft.promptOrderTypesResponse = {
          ...state.promptOrderTypesResponse,
          [action.payload.key]: action.payload.response,
        };
        draft.promptOrderTypesLoading = false;
        draft.promptOrderTypesSuccess = true;
        break;
      }
      case '@insightsAi/GET_PROMPT_ORDER_TYPES_FAILURE': {
        draft.promptOrderTypesLoading = false;
        draft.promptOrderTypesSuccess = false;
        break;
      }
      case '@insightsAi/GET_PROMPT_TOP_SELLING_PRODUCTS_REQUEST': {
        draft.promptTopSoldProductsLoading = true;
        draft.promptTopSoldProductsSuccess = false;
        break;
      }
      case '@insightsAi/GET_PROMPT_TOP_SELLING_PRODUCTS_SUCCESS': {
        draft.promptTopSoldProductsResponse = {
          ...state.promptTopSoldProductsResponse,
          [action.payload.key]: action.payload.response,
        };
        draft.promptTopSoldProductsLoading = false;
        draft.promptTopSoldProductsSuccess = true;
        break;
      }
      case '@insightsAi/GET_PROMPT_TOP_SELLING_PRODUCTS_FAILURE': {
        draft.promptTopSoldProductsLoading = false;
        draft.promptTopSoldProductsSuccess = false;
        break;
      }
      case '@insightsAi/GET_PROMPT_ORDERS_PER_NEIGHBORHOOD_REQUEST': {
        draft.promptOrdersPerNeighborhoodLoading = true;
        draft.promptOrdersPerNeighborhoodSuccess = false;
        break;
      }
      case '@insightsAi/GET_PROMPT_ORDERS_PER_NEIGHBORHOOD_SUCCESS': {
        draft.promptOrdersPerNeighborhoodResponse = {
          ...state.promptOrdersPerNeighborhoodResponse,
          [action.payload.key]: action.payload.response,
        };
        draft.promptOrdersPerNeighborhoodLoading = false;
        draft.promptOrdersPerNeighborhoodSuccess = true;
        break;
      }
      case '@insightsAi/GET_PROMPT_ORDERS_PER_NEIGHBORHOOD_FAILURE': {
        draft.promptOrdersPerNeighborhoodLoading = false;
        draft.promptOrdersPerNeighborhoodSuccess = false;
        break;
      }
      default:
    }
  });
}
