import produce from 'immer';

const INITIAL_STATE = {
  isCurrSession: false,
  allClientsData: [],
  isLoadingActiveOrders: false,
  isChatMinimized: true,
  selectedConversation: { id: '', name: '' },
  revokedMessages: [],
  isConnected: false,
  newMessagesCount: [],
  waitAttendantList: [],
  selectedMedia: null,
  isDeletingMessage: '',
};

export default function whatsappChat(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@whatsappChat/SET_IS_CHAT_MINIMIZED': {
        draft.isChatMinimized = action.payload;
        break;
      }
      case '@whatsappChat/SET_ALL_CLIENTS_DATA': {
        draft.allClientsData = action.payload;
        draft.isCurrSession = true;
        draft.isLoadingActiveOrders = false
        break;
      }
      case '@whatsappChat/SET_SELECTED_CONVERSATION':
        draft.selectedConversation = action.payload;
        break;
      case '@whatsappChat/SET_IS_CONNECTED':
        draft.isConnected = action.payload;
        break;
      case '@whatsappChat/UPDATE_NEW_MESSAGE_COUNT':
        draft.newMessagesCount = action.payload;
        break;
      case '@whatsappChat/SET_IS_CURR_SESSIONS':
        draft.isCurrSession = action.payload;
        break;
      case '@whatsappChat/SET_WAIT_ATTENDANT_LIST':
        draft.waitAttendantList = action.payload;
        break;
      case '@whatsappChat/SET_SELECTED_MEDIA':
        draft.selectedMedia = action.payload;
        break;
      case '@whatsappChat/SET_REVOKED_MESSAGES':
        draft.revokedMessages = action.payload;
        break;
      case '@whatsappChat/SET_IS_DELETING_MESSAGE':
        draft.isDeletingMessage = action.payload;
        break;
      case '@whatsappChat/CLEAR_CHAT_STATE':
        draft.newMessagesCount = INITIAL_STATE.newMessagesCount;
        draft.revokedMessages = INITIAL_STATE.revokedMessages;
        draft.allClientsData = INITIAL_STATE.allClientsData;
        draft.newMessagesCount = INITIAL_STATE.newMessagesCount;
        draft.selectedConversation = INITIAL_STATE.selectedConversation;
        draft.waitAttendantList = INITIAL_STATE.waitAttendantList;
        draft.selectedMedia = INITIAL_STATE.selectedMedia;
        draft.isDeletingMessage = INITIAL_STATE.isDeletingMessage;
        draft.isLoadingActiveOrders = INITIAL_STATE.isLoadingActiveOrders;
        break;
      case '@whatsappChat/GET_ALL_ACTIVE_ORDERS_DATA':
        draft.isLoadingActiveOrders = true
        break;
      default:
        break;
    }
  });
}
