import React from "react";
import { FormControlLabel } from "@material-ui/core";
import { Spinner } from 'reactstrap';

import {
  Card,
  NormalText,
} from '../../../BaseScreen/styles';
import {
  SwitchController,
  SwitchLabelRegularText,
  SwitchWithInput,
  SwitchInput,
  StoreForm,
  SaveButton,
  SaveButtonText,
} from '../styles';
import {
  LoadingBox,
} from '../../../../_layouts/styles/common';
import { Row, Col } from '../../../../../styles/grid';
import { preto } from '../../../../../styles/colors';

import px2vw from '../../../../../utils/responsiveness/px2vw';

export default function StorePaymentMethodsOnboardingMobile({ handler }) {
  return (
    <StoreForm onSubmit={handler.handleStorePaymentMethodsSubmit}>
      <Card
        width="auto"
        height="auto"
        padleft={px2vw(30)}
        padright={px2vw(30)}
        borderRadius={px2vw(60)}
        margtop={px2vw(60)}
      >
        <Row margbottom={px2vw(100)} alignStart>
          <Col margleft={px2vw(20)}>
            <NormalText
              textalign='flex-start'
              margleft={px2vw(20)}
              margtop={px2vw(80)}
              fontweight='bold'
              color={preto}
              fontsize={px2vw(60)} >
              Defina as formas de pagamento que sua loja aceita
            </NormalText>
            <NormalText
              margtop={px2vw(80)}
              margleft={px2vw(20)}
              fontweight='bold'
              color={preto}
              fontsize={px2vw(50)} >
              Pagamento na entrega
            </NormalText>
          </Col>
        </Row>
         {/* Cash */}
        {handler.storePmCash && (
          <SwitchWithInput key={'cash'} margleft={px2vw(80)} marginBottom={px2vw(20)}>
            <FormControlLabel
              control={
                <SwitchController
                  checked={handler.storePmCashActive}
                  type="checkbox"
                  onChange={() => handler.handlePaymentCategorySwitch('cash')}
                />
              }
              label={
                <SwitchLabelRegularText>Dinheiro</SwitchLabelRegularText>
              }
            />
          </SwitchWithInput>
        )}
        {/* Credit Card Offline */}
        <SwitchWithInput key={'creditCard'} margleft={px2vw(80)} marginBottom={px2vw(20)}>
          <FormControlLabel
            control={
              <SwitchController
                checked={handler.storePmsCreditCardOfflineActive}
                type="checkbox"
                onChange={() => handler.handlePaymentCategorySwitch('creditCardOffline')}
              />
            }
            label={
              <SwitchLabelRegularText>Cartão de Crédito</SwitchLabelRegularText>
            }
          />
        </SwitchWithInput>
        {/* Credit Card Offline Payment Options */}
        {handler.storePmsCreditCardOfflineActive && handler.storePmsCreditCardOffline && handler.displayPaymentMethodsInline(handler.storePmsCreditCardOffline, 'creditCardOffline', 3)}
        {/* Debit Card Offline */}
        <SwitchWithInput key={'debitCard'} margleft={px2vw(80)} marginBottom={px2vw(20)}>
          <FormControlLabel
            control={
              <SwitchController
                checked={handler.storePmsDebitCardOfflineActive}
                type="checkbox"
                onChange={() => handler.handlePaymentCategorySwitch('debitCardOffline')}
              />
            }
            label={
              <SwitchLabelRegularText>Cartão de Débito</SwitchLabelRegularText>
            }
          />
        </SwitchWithInput>
        {/* Debit Card Offline Payment Options */}
        {handler.storePmsDebitCardOfflineActive && handler.storePmsDebitCardOffline && handler.displayPaymentMethodsInline(handler.storePmsDebitCardOffline, 'debitCardOffline', 3)}
        {/* Voucher Offline */}
        <SwitchWithInput key={'voucher'} margleft={px2vw(80)} marginBottom={px2vw(20)}>
          <FormControlLabel
            control={
              <SwitchController
                checked={handler.storePmsVoucherOfflineActive}
                type="checkbox"
                onChange={() => handler.handlePaymentCategorySwitch('voucherOffline')}
              />
            }
            label={
              <SwitchLabelRegularText>Voucher / Ticket Refeição</SwitchLabelRegularText>
            }
          />
        </SwitchWithInput>
        {/* Voucher Offline Payment Options */}
        {handler.storePmsVoucherOfflineActive && handler.storePmsVoucherOffline && handler.displayPaymentMethodsInline(handler.storePmsVoucherOffline, 'voucherOffline', 1)}

        {handler.storePmPixKey && (
          <>
            <Row margbottom={px2vw(100)} justifyStart>
              <NormalText
                margtop={px2vw(80)}
                margleft={px2vw(40)}
                fontweight='bold'
                color={preto}
                fontsize={px2vw(50)}
              >
                Pagamento online
              </NormalText>
            </Row>

            <SwitchWithInput key={'chavepix-active'} margleft={px2vw(80)} marginBottom={px2vw(20)}>
              <FormControlLabel
                control={
                  <SwitchController
                    checked={handler.storePmPixKeyActive}
                    type="checkbox"
                    onChange={() => handler.handlePaymentCategorySwitch('chave_pix')}
                  />
                }
                label={
                  <SwitchLabelRegularText>Chave Pix</SwitchLabelRegularText>
                }
              />
            </SwitchWithInput>
            {handler.storePmPixKeyActive && (
              <SwitchWithInput key={'chavepix-active-text'} margleft={px2vw(40)} marginBottom={px2vw(80)} margtop={px2vw(30)}>
                <SwitchInput
                  name="pixKey"
                  placeholder="Email, CPF/CNPJ, telefone com DDD ou chave aleatória"
                  width={px2vw(1000)}
                  value={handler.pixKey}
                  onChange={(e) => handler.handleTypingPixKey(e)}
                />
              </SwitchWithInput>
            )}
          </>
        )}
                
        <Row justifyEnd width='auto' margtop={px2vw(40)} margbottom={px2vw(90)} margright={px2vw(40)}>
          <SaveButton
            hasChanges={handler.isSaveButtonActive()}
            type="submit"
            disabled={handler.isSaveButtonDisabled()}
          >
            {handler.updatePaymentMethodLoading ? (
              <LoadingBox>
                <Spinner
                  style={{
                    width: '1.8rem',
                    height: '1.8rem',
                    color: preto,
                  }}
                />{' '}
              </LoadingBox>
            ) : (
              <SaveButtonText hasChanges={handler.isSaveButtonActive()}>
                Salvar
              </SaveButtonText>
            )}
          </SaveButton>
        </Row>
      </Card>
    </StoreForm>
  )
}