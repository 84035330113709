import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Spinner } from 'reactstrap';
import { FormControlLabel } from '@material-ui/core';

import {
  BasicInput, BoxContainer, Container, MediumText, 
  RoundedContainer, Separator, SmallText, TitleText,
  ArrowImg
} from '../styles';
import { LoadingBox, SwitchButtonBox, SwitchController } from '../../_layouts/styles/common';
import { Row } from '../../../styles/grid';
import { preto } from '../../../styles/colors';
import { SaveButton, SaveButtonText } from '../../MyStore/styles';

import px2vw from '../../../utils/responsiveness/px2vw';
import { onlyNumbers } from '../../../utils/utils';

import {
  clearSuccess,
  getRatingsConfigRequest,
  updateRatingsConfigsRequest
} from '../../../store/modules/ratings/actions';

export default function ConfigsPage() {
  const {
    ratingsConfigIsRatingActive,
    ratingsConfigMinutesToSend,
    ratingsConfigUpdateLoading,
    ratingsConfigUpdateSuccess
  } = useSelector((state) => state.ratings);
  const [isReviewActive, setIsReviewActive] = useState(false);
  const [ratingsMsgTime, setRatingsMsgTime] = useState(5);
  const [hasChanges, setHasChanges] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getRatingsConfigRequest());
  }, [])

  useEffect(() => {
    setIsReviewActive(ratingsConfigIsRatingActive);
    setRatingsMsgTime(ratingsConfigMinutesToSend);
  }, [ratingsConfigIsRatingActive, ratingsConfigMinutesToSend])

  useEffect(() => {
    if (ratingsConfigUpdateSuccess) {
      setHasChanges(false);
      dispatch(clearSuccess());
    }
  }, [ratingsConfigUpdateSuccess])

  const Card = ({ idx, title, text }) => {
    return (
      <BoxContainer>
        <RoundedContainer>
          <MediumText color={preto}>{idx}</MediumText>
        </RoundedContainer>
        <TitleText >{title}</TitleText>
        <Separator />
        <Row height={px2vw(100)}>
          <SmallText fontSize={px2vw(12)} color={preto}>
            {text}
          </SmallText>
        </Row>
      </BoxContainer>
    )
  }

  function handleConfigInputChanges(input) {
      input !== ratingsMsgTime ? setHasChanges(true) : setHasChanges(false)
      setRatingsMsgTime(input)
  }

  return (
    <Container margbottom={px2vw(-10)}>
      <Row padtop={px2vw(30)} justifyStart spacebetween width={'100%'}>
        <SwitchButtonBox
          width={px2vw(420)}
          margtop="-7px"
        >
          <FormControlLabel
            control={
              <SwitchController
                checked={isReviewActive}
                type="checkbox"
                onChange={() => {
                  setIsReviewActive(!isReviewActive);
                  setHasChanges(true);
                }}
              />
            }
            label={
              <MediumText padTop={px2vw(30)} padLeft={px2vw(15)}>
                Ativar avaliação via Whatsappbot
              </MediumText>
            }
          />
        </SwitchButtonBox>
        <SaveButton
          id="reviewConfigsSaveButton"
          hasChanges={hasChanges}
          type="submit"
          disabled={!hasChanges || ratingsConfigUpdateLoading}
          margleft={px2vw(395)}
          onClick={() => {
            if (parseInt(ratingsMsgTime) < 5) {
              toast.error("O tempo mínimo é de 5 minutos")
            } else if (parseInt(ratingsMsgTime) > 60) {
              toast.error("O tempo máximo é de 60 minutos")
            } else if (!ratingsMsgTime) {
              toast.error("O tempo mínimo não pode ficar vazio")
            } else {
              dispatch(updateRatingsConfigsRequest(isReviewActive, ratingsMsgTime));
            }            
          }}
        >
          {ratingsConfigUpdateLoading ? (
            <LoadingBox>
              <Spinner
                style={{
                  width: '1.8rem',
                  height: '1.8rem',
                  color: preto,
                }}
              />{' '}
            </LoadingBox>
          ) : (
            <SaveButtonText hasChanges={hasChanges}>
              Salvar
            </SaveButtonText>
          )}
        </SaveButton>
      </Row>
      <Row width={'70%'} margleft={px2vw(-28)} margtop={px2vw(12)} alignCenter>
        <SmallText isActive={isReviewActive}>
          Defina em quanto tempo a mensagem deverá ser enviada após conclusão do pedido
        </SmallText>
        <BasicInput
          isActive={isReviewActive}
          maxLength="2"
          placeholder='5'
          disabled={!isReviewActive}
          value={ratingsMsgTime}
          onChange={(e) => { handleConfigInputChanges(onlyNumbers(e.target.value)) }}
        />
        <SmallText isActive={isReviewActive} margleft={px2vw(5)}>
          min
        </SmallText>
      </Row>

      <Row spaceAround alignCenter justifyCenter margleft={px2vw(-16)} margtop={px2vw(30)} margbottom={px2vw(30)}>
        <Card
          key={'ratingCard#1'}
          idx={'1'}
          title="Gatilho da Mensagem"
          text={`Você concluiu um pedido e já se passaram ${ratingsMsgTime || 5} minutos.`}
        />
        <ArrowImg
          src={require('../../../assets/images/ratings/right_arrow.svg')}
        />
        <Card
          key={'ratingCard#2'}
          idx={'2'}
          title="Mensagem Disparada"
          text="Seu cliente recebe uma mensagem no WhatsApp pedindo para avaliar o pedido com nota de 1 a 5."
        />
        <ArrowImg
          src={require('../../../assets/images/ratings/right_arrow.svg')}
        />
        <Card
          key={'ratingCard#3'}
          idx={'3'}
          title="Avaliação Registrada"
          text={`A avaliação do seu cliente fica registrada na aba de Relatório em Avaliações para você analisar.`}
        />
      </Row>
    </Container>
  )
}