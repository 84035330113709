import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'reactstrap';
import { toast } from 'react-toastify';

import Modal from '../../../components/Modal';
import ButtonOptionsModal from '../../../components/ButtonOptionsModal';

import px2vw from '../../../utils/responsiveness/px2vw';
import { onlyNumbers } from '../../../utils/utils';

import {
  ModalHeader,
  ModalTitleText,
  CloseButton,
  CloseImage,
  ModalPrimaryButton,
  ModalPrimaryButtonText,
  ModalActions,
  ModalSecundaryButton,
  ModalSecundaryButtonText,
} from '../../../components/Modal/styles';

import { Row, Col } from '../../../styles/grid';

import { LoadingBox } from '../../_layouts/styles/common';

import { preto, cinzaKuppi1 } from '../../../styles/colors';

import {
  ProfileDataBox,
  DataTitleText,
  DataText,
  EditButtonBox,
  EditPersonalDataModal,
  ModalInputLabelText,
  ModalInput,
  EditAddressDataModal,
  FormUserData,
  FormAddressData,
} from './styles';

import { cpfCnpjMask, cepMask, dddPhoneMask } from '../../../utils/masks';

import {
  queryZipcodeRequest,
  clearSuccess as userAddressClearSuccess,
} from '../../../store/modules/userAddressQuery/actions';

import {
  updateUserAddressRequest,
  updateUserPasswordRequest,
  clearSuccess,
} from '../../../store/modules/user/actions';

export default function Profile() {
  const user = useSelector((state) => state.user.user);
  const userAddressQuery = useSelector((state) => state.userAddressQuery);
  const [editPersonalData, setEditPersonalData] = useState(false);
  const [editAddressData, setEditAddressData] = useState(false);
  const [newPasswordInputValue, setNewPasswordInputValue] = useState(
    '***********'
  );
  const [
    confirmNewPasswordInputValue,
    setConfirmNewPasswordInputValue,
  ] = useState('***********');
  const [zipcodeInputValue, setZipcodeInputValue] = useState(
    cepMask(userAddressQuery.address.zipcode)
  );
  const [addressInputValue, setAddressInputValue] = useState(
    userAddressQuery.address.street
  );
  const [numberInputValue, setNumberInputValue] = useState(
    userAddressQuery.addressAdditionalData.number
  );
  const [complInputValue, setComplInputValue] = useState(
    userAddressQuery.addressAdditionalData.complement
  );
  const [neighborhoodInputValue, setNeighborhoodInputValue] = useState(
    userAddressQuery.address.neighborhood
  );
  const [cityInputValue, setCityInputValue] = useState(
    userAddressQuery.address.city
  );
  const [stateInputValue, setStateInputValue] = useState(
    userAddressQuery.address.state
  );
  const [phone1InputValue, setPhone1InputValue] = useState(
    dddPhoneMask(userAddressQuery.addressAdditionalData.phone1)
  );
  const [phone2InputValue, setPhone2InputValue] = useState(
    dddPhoneMask(userAddressQuery.addressAdditionalData.phone2)
  );
  const [whatsappInputValue, setWhatsappInputValue] = useState(
    dddPhoneMask(userAddressQuery.addressAdditionalData.whatsapp)
  );
  const userLoading = useSelector((state) => state.user.loading);
  const userSuccess = useSelector((state) => state.user.success);
  const addressLoading = useSelector((state) => state.userAddressQuery.loading);
  const addressSuccess = useSelector((state) => state.userAddressQuery.success);
  const dispatch = useDispatch();

  useEffect(() => {
    setAddressInputValue(userAddressQuery.address.street);
    setNeighborhoodInputValue(userAddressQuery.address.neighborhood);
    setCityInputValue(userAddressQuery.address.city);
    setStateInputValue(userAddressQuery.address.state);
  }, [userAddressQuery]);

  useEffect(() => {
    if (zipcodeInputValue.length === 10) {
      dispatch(queryZipcodeRequest(onlyNumbers(zipcodeInputValue)));
    } else {
      setNumberInputValue('');
      setComplInputValue('');
      setAddressInputValue('');
      setNeighborhoodInputValue('');
      setCityInputValue('');
      setStateInputValue('');
    }
  }, [zipcodeInputValue, dispatch]);

  const handleCloseEditPersonalData = () => {
    setNewPasswordInputValue('***********');
    setConfirmNewPasswordInputValue('***********');
    setEditPersonalData(false);
  };

  const handleCloseEditAddressData = () => {
    setZipcodeInputValue(cepMask(user.address[0].zipcode));
    setNumberInputValue(user.address[0].number);
    setComplInputValue(user.address[0].complement);
    setPhone1InputValue(dddPhoneMask(user.address[0].phone_1));
    setPhone2InputValue(dddPhoneMask(user.address[0].phone_2));
    setWhatsappInputValue(dddPhoneMask(user.address[0].phone_whatsapp));
    setEditAddressData(false);
  };

  // Advertiser Personal Data
  const handleEditPersonalDataSubmit = ({
    editPersonalDataPassword,
    editPersonalDataConfirmPassword,
  }) => {
    if (
      editPersonalDataPassword === '' ||
      editPersonalDataConfirmPassword === ''
    ) {
      toast.error('Todos os campos são obrigatórios!', {
        autoClose: 6000,
      });
    } else if (editPersonalDataPassword !== editPersonalDataConfirmPassword) {
      toast.error(
        'O campo nova senha e confirmar nova senha devem ser iguais.',
        {
          autoClose: 6000,
        }
      );
    } else if (editPersonalDataPassword.length < 6) {
      toast.error('A senha deve ter no mínimo 6 dígitos!', {
        autoClose: 6000,
      });
    } else {
      dispatch(updateUserPasswordRequest(editPersonalDataPassword));
    }
  };

  // Advertiser Address Data
  const handleEditAddressDataSubmit = ({
    editAddressDataZipcode,
    editAddressDataStreet,
    editAddressDataNumber,
    editAddressDataComplement,
    editAddressDataNeighborhood,
    editAddressDataCity,
    editAddressDataState,
    editAddressDataPhone1,
    editAddressDataPhone2,
    editAddressDataWhatsapp,
  }) => {
    if (
      editAddressDataZipcode === '' ||
      editAddressDataStreet === '' ||
      editAddressDataNumber === '' ||
      editAddressDataNeighborhood === '' ||
      editAddressDataCity === '' ||
      editAddressDataState === '' ||
      editAddressDataPhone1 === ''
    ) {
      toast.error(
        'Todos os campos são obrigatórios, exceto: Complemento, Telefone 2 e Whatsapp',
        {
          autoClose: 6000,
        }
      );
    } else if (editAddressDataZipcode.length < 10) {
      toast.error('CEP inválido', {
        autoClose: 6000,
      });
    } else if (editAddressDataPhone1.length < 12) {
      toast.error('Telefone 1 inválido', {
        autoClose: 6000,
      });
    } else if (
      editAddressDataWhatsapp !== '' &&
      editAddressDataWhatsapp.length < 12
    ) {
      toast.error('Whatsapp inválido', {
        autoClose: 6000,
      });
    } else if (
      editAddressDataPhone2 !== '' &&
      editAddressDataPhone2.length < 12
    ) {
      toast.error('Telefone 2 inválido', {
        autoClose: 6000,
      });
    } else {
      dispatch(
        updateUserAddressRequest(
          onlyNumbers(editAddressDataZipcode),
          editAddressDataStreet,
          editAddressDataNumber,
          editAddressDataComplement,
          editAddressDataNeighborhood,
          editAddressDataCity,
          editAddressDataState,
          onlyNumbers(editAddressDataPhone1),
          onlyNumbers(editAddressDataPhone2),
          onlyNumbers(editAddressDataWhatsapp)
        )
      );
    }
  };

  useEffect(() => {
    if (userSuccess) {
      if (editPersonalData) {
        handleCloseEditPersonalData();
      } else if (editAddressData) {
        handleCloseEditAddressData();
      }
      dispatch(clearSuccess());
    }
  }, [userSuccess, dispatch]);

  useEffect(() => {
    if (addressSuccess) {
      if (editAddressData) {
        handleCloseEditAddressData();
      }
      dispatch(userAddressClearSuccess());
    }
  }, [addressSuccess, dispatch]);

  return (
    <>
      <Col>
        <ProfileDataBox>
          <Col>
            <Row>
              <Col>
                <DataTitleText>Nome</DataTitleText>
                <DataText>{user.advertiserInfo.name}</DataText>
              </Col>
              <Col>
                <DataTitleText>Representante</DataTitleText>
                <DataText>
                  {user.advertiserInfo.representative_name
                    ? user.advertiserInfo.representative_name
                    : '-'}
                </DataText>
              </Col>
              <Col width="80%">
                <DataTitleText>Categoria</DataTitleText>
                <DataText>
                  {user.advertiserInfo.advertiserCategory.name}
                </DataText>
              </Col>
              <EditButtonBox margtop={px2vw(40)}>
                <ButtonOptionsModal
                  buttons={['Editar']}
                  buttonsClicks={[() => setEditPersonalData(true)]}
                />
              </EditButtonBox>
            </Row>
            <div
              style={{
                backgroundColor: cinzaKuppi1,
                height: `${px2vw(4)}`,
                width: '100%',
              }}
            />
            <Row>
              <Col width="60%">
                <DataTitleText>CPF/CNPJ</DataTitleText>
                <DataText>{cpfCnpjMask(user.advertiserInfo.cpf_cnpj)}</DataText>
              </Col>
              <Col width="60%">
                <DataTitleText>Senha</DataTitleText>
                <DataText>•••••••••••</DataText>
              </Col>
              <Col>
                <DataTitleText>E-mail</DataTitleText>
                <DataText>{user.email}</DataText>
              </Col>
            </Row>
          </Col>
        </ProfileDataBox>
        <div style={{ height: `${px2vw(25)}` }} />
        <ProfileDataBox>
          <Col>
            <Row>
              <Col>
                <DataTitleText>Endereço</DataTitleText>
                <DataText>{user.address[0].street}</DataText>
              </Col>
              <Col width="40%">
                <DataTitleText>Número</DataTitleText>
                <DataText>{user.address[0].number}</DataText>
              </Col>
              <Col width="60%">
                <DataTitleText>Complemento</DataTitleText>
                <DataText>{user.address[0].complement || '-'}</DataText>
              </Col>
              <Col width="50%">
                <DataTitleText>Bairro</DataTitleText>
                <DataText>{user.address[0].neighborhood}</DataText>
              </Col>
              <Col width="60%">
                <DataTitleText>Cidade</DataTitleText>
                <DataText>{user.address[0].city}</DataText>
              </Col>
              <EditButtonBox margtop={px2vw(40)}>
                <ButtonOptionsModal
                  buttons={['Editar']}
                  buttonsClicks={[() => setEditAddressData(true)]}
                />
              </EditButtonBox>
            </Row>
            <div
              style={{
                backgroundColor: cinzaKuppi1,
                height: `${px2vw(4)}`,
                width: '100%',
              }}
            />
            <Row>
              <Col>
                <DataTitleText>Estado</DataTitleText>
                <DataText>{user.address[0].state}</DataText>
              </Col>
              <Col>
                <DataTitleText>CEP</DataTitleText>
                <DataText>{cepMask(user.address[0].zipcode)}</DataText>
              </Col>
              <Col>
                <DataTitleText>Telefone 1</DataTitleText>
                <DataText>{dddPhoneMask(user.address[0].phone_1)}</DataText>
              </Col>
              <Col>
                <DataTitleText>Telefone 2</DataTitleText>
                <DataText>
                  {user.address[0].phone_2
                    ? dddPhoneMask(user.address[0].phone_2)
                    : '-'}
                </DataText>
              </Col>
              <Col>
                <DataTitleText>Whatsapp</DataTitleText>
                <DataText>
                  {user.address[0].phone_whatsapp
                    ? dddPhoneMask(user.address[0].phone_whatsapp)
                    : '-'}
                </DataText>
              </Col>
            </Row>
          </Col>
        </ProfileDataBox>
      </Col>
      {/* Change Personal Data Modal */}
      {editPersonalData && (
        <Modal
          isVisible={editPersonalData}
          width={px2vw(685)}
          height={px2vw(505)}
        >
          <EditPersonalDataModal>
            <ModalHeader>
              <ModalTitleText>Alterar dados</ModalTitleText>
              <CloseButton
                onClick={handleCloseEditPersonalData}
                disabled={userLoading}
              >
                <CloseImage
                  src={require('../../../assets/images/close/close.png')}
                />
              </CloseButton>
            </ModalHeader>
            <FormUserData onSubmit={handleEditPersonalDataSubmit}>
              <Col margleft={px2vw(45)}>
                <Row margtop={px2vw(35)}>
                  <Col>
                    <ModalInputLabelText>
                      Nome do Estabelecimento
                    </ModalInputLabelText>
                    <ModalInput
                      name="editPersonalDataName"
                      width={px2vw(605)}
                      value={user.advertiserInfo.name}
                      disabled
                    />
                  </Col>
                </Row>
                <Row margtop={px2vw(25)}>
                  <Col>
                    <ModalInputLabelText>Representante</ModalInputLabelText>
                    <ModalInput
                      name="editPersonalDataRepresentativeName"
                      value={user.advertiserInfo.representative_name}
                      disabled
                    />
                  </Col>
                  <Col>
                    <ModalInputLabelText>Categoria</ModalInputLabelText>
                    <ModalInput
                      name="editPersonalDataCategory"
                      value={user.advertiserInfo.advertiserCategory.name}
                      disabled
                    />
                  </Col>
                </Row>
                <Row margtop={px2vw(25)}>
                  <Col>
                    <ModalInputLabelText>CPF/CNPJ</ModalInputLabelText>
                    <ModalInput
                      name="editPersonalDataCpfCnpj"
                      value={cpfCnpjMask(user.advertiserInfo.cpf_cnpj)}
                      disabled
                    />
                  </Col>
                  <Col>
                    <ModalInputLabelText>E-mail</ModalInputLabelText>
                    <ModalInput
                      name="editPersonalDataEmail"
                      value={user.email}
                      disabled
                    />
                  </Col>
                </Row>
                <Row margtop={px2vw(25)}>
                  <Col>
                    <ModalInputLabelText>Nova senha</ModalInputLabelText>
                    <ModalInput
                      name="editPersonalDataPassword"
                      type="password"
                      value={newPasswordInputValue}
                      onFocus={() => setNewPasswordInputValue('')}
                      onChange={(t) => setNewPasswordInputValue(t.target.value)}
                    />
                  </Col>
                  <Col>
                    <ModalInputLabelText>
                      Confirmar nova senha
                    </ModalInputLabelText>
                    <ModalInput
                      name="editPersonalDataConfirmPassword"
                      type="password"
                      value={confirmNewPasswordInputValue}
                      onFocus={() => setConfirmNewPasswordInputValue('')}
                      onChange={(t) =>
                        setConfirmNewPasswordInputValue(t.target.value)
                      }
                    />
                  </Col>
                </Row>
              </Col>
              <ModalActions>
                <ModalSecundaryButton
                  marginRight={px2vw(20)}
                  margtop={px2vw(40)}
                  width={px2vw(180)}
                  onClick={handleCloseEditPersonalData}
                  type="button"
                  disabled={userLoading}
                >
                  <ModalSecundaryButtonText>Cancelar</ModalSecundaryButtonText>
                </ModalSecundaryButton>

                <ModalPrimaryButton
                  margleft={px2vw(20)}
                  margtop={px2vw(40)}
                  width={px2vw(180)}
                  type="submit"
                  disabled={userLoading}
                >
                  {userLoading ? (
                    <LoadingBox>
                      <Spinner
                        style={{
                          width: '1.8rem',
                          height: '1.8rem',
                          color: preto,
                        }}
                      />{' '}
                    </LoadingBox>
                  ) : (
                    <ModalPrimaryButtonText>Confirmar</ModalPrimaryButtonText>
                  )}
                </ModalPrimaryButton>
              </ModalActions>
            </FormUserData>
          </EditPersonalDataModal>
        </Modal>
      )}
      {/* Change Address Data Modal */}
      {editAddressData && (
        <Modal
          isVisible={editAddressData}
          width={px2vw(685)}
          height={px2vw(430)}
        >
          <EditAddressDataModal>
            <ModalHeader>
              <ModalTitleText>Alterar endereço</ModalTitleText>
              <CloseButton
                onClick={handleCloseEditAddressData}
                margleft={px2vw(590)}
              >
                <CloseImage
                  src={require('../../../assets/images/close/close.png')}
                />
              </CloseButton>
            </ModalHeader>
            <FormAddressData onSubmit={handleEditAddressDataSubmit}>
              <Col margleft={px2vw(45)}>
                <Row margtop={px2vw(40)}>
                  <Col margright={px2vw(40)}>
                    <ModalInputLabelText>CEP</ModalInputLabelText>
                    <ModalInput
                      name="editAddressDataZipcode"
                      width={px2vw(105)}
                      placeholder="00.000-000"
                      maxLength="10"
                      value={zipcodeInputValue}
                      onChange={(t) =>
                        setZipcodeInputValue(cepMask(t.target.value))
                      }
                    />
                  </Col>
                  <Col margleft={px2vw(-20)}>
                    <ModalInputLabelText>Endereço</ModalInputLabelText>
                    <ModalInput
                      id="editAddressDataStreet"
                      name="editAddressDataStreet"
                      width={px2vw(225)}
                      maxLength="255"
                      placeholder="Rua ABC"
                      value={addressInputValue}
                      onChange={(t) => setAddressInputValue(t.target.value)}
                    />
                  </Col>
                  <Col margleft={px2vw(35)}>
                    <ModalInputLabelText>Número</ModalInputLabelText>
                    <ModalInput
                      name="editAddressDataNumber"
                      width={px2vw(70)}
                      maxLength="10"
                      placeholder="000"
                      value={numberInputValue}
                      onChange={(t) => setNumberInputValue(t.target.value)}
                    />
                  </Col>
                  <Col margleft={px2vw(-15)}>
                    <ModalInputLabelText>Complemento</ModalInputLabelText>
                    <ModalInput
                      name="editAddressDataComplement"
                      width={px2vw(80)}
                      maxLength="255"
                      placeholder="Sala 123"
                      value={complInputValue}
                      onChange={(t) => setComplInputValue(t.target.value)}
                    />
                  </Col>
                </Row>
                <Row margtop={px2vw(30)}>
                  <Col margright={px2vw(40)}>
                    <ModalInputLabelText>Bairro</ModalInputLabelText>
                    <ModalInput
                      id="editAddressDataNeighborhood"
                      name="editAddressDataNeighborhood"
                      width={px2vw(220)}
                      maxLength="100"
                      placeholder="Bairro"
                      value={neighborhoodInputValue}
                      onChange={(t) =>
                        setNeighborhoodInputValue(t.target.value)
                      }
                    />
                  </Col>
                  <Col margright={px2vw(50)}>
                    <ModalInputLabelText>Cidade</ModalInputLabelText>
                    <ModalInput
                      id="editAddressDataCity"
                      name="editAddressDataCity"
                      width={px2vw(192)}
                      maxLength="100"
                      placeholder="Cidade"
                      value={cityInputValue}
                      onChange={(t) => setCityInputValue(t.target.value)}
                      disabled
                    />
                  </Col>
                  <Col>
                    <ModalInputLabelText>Estado</ModalInputLabelText>
                    <ModalInput
                      id="editAddressDataState"
                      name="editAddressDataState"
                      width={px2vw(50)}
                      maxLength="2"
                      placeholder="RJ"
                      value={stateInputValue}
                      onChange={(t) => setStateInputValue(t.target.value)}
                      disabled
                    />
                  </Col>
                </Row>
                <Row margtop={px2vw(30)}>
                  <Col>
                    <ModalInputLabelText>Telefone 1</ModalInputLabelText>
                    <ModalInput
                      name="editAddressDataPhone1"
                      width={px2vw(170)}
                      placeholder="00 00000.0000"
                      maxLength="13"
                      value={phone1InputValue}
                      onChange={(t) =>
                        setPhone1InputValue(dddPhoneMask(t.target.value))
                      }
                    />
                  </Col>
                  <Col>
                    <ModalInputLabelText>Telefone 2</ModalInputLabelText>
                    <ModalInput
                      name="editAddressDataPhone2"
                      width={px2vw(170)}
                      placeholder="00 00000.0000"
                      maxLength="13"
                      value={phone2InputValue}
                      onChange={(t) =>
                        setPhone2InputValue(dddPhoneMask(t.target.value))
                      }
                    />
                  </Col>
                  <Col>
                    <ModalInputLabelText>Whatsapp</ModalInputLabelText>
                    <ModalInput
                      name="editAddressDataWhatsapp"
                      width={px2vw(170)}
                      placeholder="00 00000.0000"
                      maxLength="13"
                      value={whatsappInputValue}
                      onChange={(t) =>
                        setWhatsappInputValue(dddPhoneMask(t.target.value))
                      }
                    />
                  </Col>
                </Row>
              </Col>
              <ModalActions>
                <ModalSecundaryButton
                  marginRight={px2vw(20)}
                  margtop={px2vw(40)}
                  width={px2vw(180)}
                  onClick={handleCloseEditAddressData}
                  type="button"
                >
                  <ModalSecundaryButtonText>Cancelar</ModalSecundaryButtonText>
                </ModalSecundaryButton>
                <ModalPrimaryButton
                  margleft={px2vw(20)}
                  margtop={px2vw(40)}
                  width={px2vw(180)}
                  disabled={addressLoading}
                >
                  {addressLoading ? (
                    <LoadingBox>
                      <Spinner
                        style={{
                          width: '1.8rem',
                          height: '1.8rem',
                          color: preto,
                        }}
                      />{' '}
                    </LoadingBox>
                  ) : (
                    <ModalPrimaryButtonText>Confirmar</ModalPrimaryButtonText>
                  )}
                </ModalPrimaryButton>
              </ModalActions>
            </FormAddressData>
          </EditAddressDataModal>
        </Modal>
      )}
    </>
  );
}
