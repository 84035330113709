import React from 'react';

import {
  Image,
  NormalText,
  LogoImage,
  PageTitle,
  EmailText,
} from '../../styles';
import { TabSelected } from '../../../Step5-StorePublicity/styles';
import { Row, Col } from '../../../../../styles/grid';
import { amareloKuppi1, branco, cinzaKuppi1, cinzaKuppi10, preto } from '../../../../../styles/colors';

import ButtonPrimary from '../../../../../components/ButtonPrimary';
import CustomDrawer from '../../../../../components/CustomDrawer';

import px2vw from '../../../../../utils/responsiveness/px2vw';
import { getURL } from '../../../../../utils/utils';

export default function MobileSideBar({ handler }) {
  const controller = {
    main: {
      logoImg: require('../../../../../assets/images/logo_kuppi/logo_kuppi_wo_slogan.png'),
      logoutImg: require('../../../../../assets/images/onboarding/logout_mobile2@2x.png'),
      profileSquare: require('../../../../../assets/images/onboarding/profileSquare@2x.png'),
      value: `${handler.minimumSubscriptionAmount}`
    },
    tabs: [
      {
        img: handler.handleSideMenuImgs(
          'step1',
          require('../../../../../assets/images/onboarding/numberOne@2x.png'),
          require('../../../../../assets/images/onboarding/numberOne@2x.png'),
        ),
        text: 'Configuração de perfil',
        step: 'step1',
        onClick: () => handler.handleSideBarMenuClick('step1', true)
      },
      {
        img: handler.handleSideMenuImgs(
          'step2',
          require('../../../../../assets/images/onboarding/numberTwo@2x.png'),
          require('../../../../../assets/images/onboarding/numberTwoGreen@2x.png')
        ),
        text: 'Parâmetros do delivery',
        step: 'step2',
        onClick: () => handler.handleSideBarMenuClick('step2', true)
      },
      {
        img: handler.handleSideMenuImgs(
          'step3',
          require('../../../../../assets/images/onboarding/numberThree@2x.png'),
          require('../../../../../assets/images/onboarding/numberThreeGreen@2x.png')
        ),
        text: 'Catálogo',
        step: 'step3',
        onClick: () => handler.handleSideBarMenuClick('step3', true)
      },
      {
        img: handler.handleSideMenuImgs(
          'step4',
          require('../../../../../assets/images/onboarding/numberFour@2x.png'),
          require('../../../../../assets/images/onboarding/numberFourGreen@2x.png')
        ),
        text: 'Configuração de pedido',
        step: 'step4',
        onClick: () => handler.handleSideBarMenuClick('step4', true)
      },
      {
        img: handler.handleSideMenuImgs(
          'step5',
          require('../../../../../assets/images/onboarding/numberFive@2x.png'),
          require('../../../../../assets/images/onboarding/numberFiveGreen@2x.png')
        ),
        text: 'Divulgação',
        step: 'step5',
        onClick: () => handler.handleSideBarMenuClick('step5', true)
      }
    ],
  }

  function openPaymentLink() {
    window.open(
      getURL('anunciante') + process.env.REACT_APP_PAYMENT_LINK + handler.paymentToken,
      '_blank'
    );
  }

  return (
    <CustomDrawer handler={handler}>
      <Col alignCenter>
        <Row
          spaceAround
        >
          <LogoImage
            margtop={px2vw(70)}
            width={px2vw(700)}
            height={px2vw(390)}
            src={controller.main.logoImg}
          />
          <Image src={require('../../../../../assets/images/onboarding/closeSubMenu@2x.png')}
            width={px2vw(150)}
            height={px2vw(150)}
            margtop={px2vw(160)}
            cursor="pointer"
            onClick={handler.setSideMenuState}
          />
        </Row>
        <Col alignCenter margbottom={px2vw(120)}>
          <LogoImage
            margtop={px2vw(10)}
            margbottom={px2vw(50)}
            width={px2vw(120)}
            height={px2vw(110)}
            src={controller.main.profileSquare}
          />
          <PageTitle textalign="center" fontsize={px2vw(100)} sidebarName>{handler.user.advertiserInfo.name}</PageTitle>
          <EmailText fontsize="60">{handler.user.email}</EmailText>
        </Col>

        {controller.tabs.map((tab) => {
          return (
            <Col alignStart
              borderbottom={`1px solid ${cinzaKuppi1}`}
              bordertop={`1px solid ${cinzaKuppi1}`}
              height={px2vw(250)}
              onClick={tab.onClick}
            >
              <Row
                spaceBetween
                alignCenter
                width='100%'
              >
                <Row alignCenter
                  width='100%'
                  margleft={px2vw(70)}
                  height={px2vw(250)}>

                  <Image
                    width={px2vw(100)}
                    height={px2vw(100)}
                    src={tab.img}
                  />
                  <NormalText
                    width={tab.text === 'Configuração de pedido' ? px2vw(820) : px2vw(700)}
                    fontsize={px2vw(60)}
                    margleft={px2vw(80)}
                    fontweight={handler.currOnboardingStepPage === tab.step ? 'bold' : 'normal'}
                  >
                    {tab.text}
                  </NormalText>
                </Row>
                <Row alignCenter>
                  <TabSelected
                    isVisible={handler.currOnboardingStepPage === tab.step}
                    width={px2vw(10)}
                    height={px2vw(150)}
                    margbottom={'0px'}
                  />
                </Row>
              </Row>
            </Col>
          )
        })}
        <Col
          margtop={px2vw(45)}
          padtop={px2vw(20)}
          width={px2vw(1050)}
          height='auto'
          alignCenter
          background={cinzaKuppi10}
          borderRadius={px2vw(20)}
        >
          <Row padtop={px2vw(40)} height={px2vw(130)}>
            <NormalText
              color={branco}
              padleft={px2vw(50)}
              fontsize={px2vw(60)}
              textalign='center'
            >
              Você possui 
              <NormalText
                fontweight="700"
                color={amareloKuppi1}
                margleft={px2vw(30)}
                margright={px2vw(30)}
                fontsize={px2vw(60)}
              >
                {`${handler.remainingFreeDays} ${handler.remainingFreeDays > 1 ? 'dias restantes' : 'dia restante'}`}
              </NormalText>
            </NormalText>
            <NormalText
              fontweight="700"
              color={branco}
              margright={px2vw(20)}
              fontsize={px2vw(60)}
            >
              de
            </NormalText>
          </Row>
          <NormalText
            color={branco}
            margright={px2vw(20)}
            fontsize={px2vw(60)}
          >
            teste gratuito
          </NormalText>
          <Row>
            <NormalText
              color={branco}
              margright={px2vw(20)}
              padtop={px2vw(20)}
              fontsize={px2vw(60)}
            >
              A partir de
            </NormalText>
            <NormalText
              fontweight="700"
              color={branco}
              margright={px2vw(20)}
              fontsize={px2vw(80)}
            >
              R$ {controller.main.value}
            </NormalText>
            <NormalText
              color={branco}
              margright={px2vw(20)}
              padtop={px2vw(20)}
              fontsize={px2vw(60)}
            >
              / mês
            </NormalText>
          </Row>

          <Row>
            <ButtonPrimary
              onboardingMobile
              minWidth={px2vw(1100)}
              height={100}
              fontSize={50}
              onClick={() => openPaymentLink()}
            >
              Assine agora
            </ButtonPrimary>
          </Row>
        </Col>
        <Row
          margbottom={px2vw(10)}
          margtop={px2vw(40)}
          width='auto'
          onClick={() => handler.handleLogout()}
          cursor='pointer'
        >
          <Image
            height={px2vw(160)}
            width={px2vw(260)}
            src={controller.main.logoutImg}
            cursor='pointer'
          />
          <NormalText
            color={preto}
            margtop={px2vw(40)}
            margleft={px2vw(20)}
            fontsize={px2vw(50)}
            cursor='pointer'
          >
            Sair
          </NormalText>
        </Row>
      </Col>
    </CustomDrawer>
  )
}