import React from 'react';
import BrokenMedia from '../../BrokenMedia';
import { MessageAudio } from '../../style';

export const AudioMessage = ({ audio }) => {
  return audio?.url ? (
    <MessageAudio controls autoplay>
      <source src={audio.url} type="audio/ogg" />
      Your browser does not support the audio element.
    </MessageAudio>
  ) : (
    <BrokenMedia type={'audio'} />
  );
};
