import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { updateSurveyFindOutAboutRequest } from '../../../store/modules/firstSteps/actions';

import PageTwoWeb from './Web';
import PageTwoMobile from './Mobile';

export default function PageTwo({ isWeb }) {
  const firstStepsSurveyData = useSelector(
    (state) => state.firstSteps.firstStepsSurveyData
  );
  const [mediaAdvertising, setMediaAdvertising] = useState(false);
  const [memberGetMember, setMemberGetMember] = useState(false);
  const [wppEmployeeContact, setWppEmployeeContact] = useState(false);
  const [googleSearch, setGoogleSearch] = useState(false);
  const [wppGroup, setWppGroup] = useState(false);
  const [other, setOther] = useState(false);
  const [otherContent, setOtherContent] = useState('');
  const [findOutAbout, setFindOutAbout] = useState(firstStepsSurveyData.find_out_about)
  const [hasComputer, setHasComputer] = useState(
    firstStepsSurveyData.has_computer
  );
  const updateSurveyFindOutAboutLoading = useSelector(
    (state) => state.firstSteps.updateSurveyFindOutAboutLoading
  );
  const dispatch = useDispatch();

  const handleSurveyFindOutAboutSubmit = () => {
    if (
      !mediaAdvertising &&
      !memberGetMember &&
      !wppEmployeeContact &&
      !googleSearch &&
      !wppGroup &&
      !other
    ) {
      toast.error('Escolha como ficou sabendo da Kuppi!', {
        autoClose: 6000,
      });
    } else if (other && otherContent === '') {
      toast.error(
        'Por gentileza informe o nome da outra forma que ficou sabendo da Kuppi!',
        {
          autoClose: 6000,
        }
      );
    } else if (hasComputer === null) {
      toast.error(
        'Por gentileza informe se você possui computador/notebook ou não!',
        {
          autoClose: 6000,
        }
      );
    } else {
      dispatch(
        updateSurveyFindOutAboutRequest(
          'step3',
          findOutAbout,
          otherContent,
          hasComputer
        )
      );
    }
  };

  useEffect(() => {
    setFindOutAbout(firstStepsSurveyData.find_out_about);
    setMediaAdvertising(firstStepsSurveyData.find_out_about === 'social_media_advertising');
    setMemberGetMember(firstStepsSurveyData.find_out_about === 'member_get_member');
    setWppEmployeeContact(firstStepsSurveyData.find_out_about === 'kuppi_wpp_employee_contact');
    setGoogleSearch(firstStepsSurveyData.find_out_about === 'google_search');
    setWppGroup(firstStepsSurveyData.find_out_about === 'wpp_group');
    setOther(firstStepsSurveyData.find_out_about === 'other');
    setOtherContent(firstStepsSurveyData.find_out_about === 'other' ? firstStepsSurveyData.find_out_about_other_content : '');
    setHasComputer(firstStepsSurveyData.has_computer);
  }, [firstStepsSurveyData]);

  const handleChangeFindOutAboutOptionClick = (findOutAbout) => {
    setFindOutAbout(findOutAbout);
    setMediaAdvertising(findOutAbout === 'social_media_advertising');
    setMemberGetMember(findOutAbout === 'member_get_member');
    setWppEmployeeContact(findOutAbout === 'kuppi_wpp_employee_contact');
    setGoogleSearch(findOutAbout === 'google_search');
    setWppGroup(findOutAbout === 'wpp_group');
    setOther(findOutAbout === 'other');
    setOtherContent(findOutAbout !== 'other' ? '' : otherContent);
  };

  const handler = {
    updateSurveyFindOutAboutLoading: updateSurveyFindOutAboutLoading,
    handleSurveyFindOutAboutSubmit: handleSurveyFindOutAboutSubmit,
    mediaAdvertising: mediaAdvertising,
    memberGetMember: memberGetMember,
    wppEmployeeContact: wppEmployeeContact,
    googleSearch: googleSearch,
    wppGroup: wppGroup,
    other: other,
    findOutAboutOptionClick: (findOutAbout) => handleChangeFindOutAboutOptionClick(findOutAbout),
    otherContent: otherContent,
    otherContentChange: (e) => setOtherContent(e.target.value),
    hasComputer: hasComputer,
    hasComputerClick: (val) => setHasComputer(val)
  }

  if (isWeb) {
    return <PageTwoWeb handler={handler}/>
  } else {
    return <PageTwoMobile handler={handler}/>
  }
}
