import React, { useState, useEffect } from 'react';

import { Icon } from 'react-materialize';
import px2vw from '../../utils/responsiveness/px2vw';

import { rowsPerPageOptions } from './options';
import { Container, Text, Content, IconBox, Select } from './styles';

export default function TablePagination({ length, response, qtdItens = 5 }) {
  const [currentLength, setCurrentLength] = useState(length);
  const [selected, setSelected] = useState('');
  const [selectedAll, setSelectedAll] = useState(false);

  useEffect(() => {
    if (currentLength < qtdItens) {
      setSelected(currentLength);
    } else {
      setSelected(qtdItens);
    }
  }, [currentLength]);

  useEffect(() => {
    setCurrentLength(length);
  }, [length]);

  const toggle = () => setSelectedAll(!selectedAll);

  const handleChangeOption = (selectedOption) => {
    setSelected(selectedOption);
    toggle();
    response(selectedOption);
  };

  const options = rowsPerPageOptions(currentLength);

  return (
    <>
      <Text>Linhas por página:</Text>
      <Container selectedAll={selectedAll} selected={selected}>
        <Select selectedAll={selectedAll}>
          <Content onClick={toggle}>
            <Text marginBottom={px2vw(1)}>{selected}</Text>
            <IconBox selectedAll={selectedAll} iconMarginTop={px2vw(7)}>
              <Icon style={{fontSize: px2vw(19)}}>expand_more</Icon>
            </IconBox>
          </Content>
          {options.map((option, index) => (
            <Content
              key={index}
              onClick={() => handleChangeOption(option)}
              className="options"
              disabled={!selectedAll}
            >
              <Text>{option}</Text>
              <IconBox />
            </Content>
          ))}
        </Select>
      </Container>
    </>
  );
}
