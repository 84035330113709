import React, { useCallback, useMemo, useState } from 'react';
import { whatsappchat } from '../../../../routes/routeMap';
import {
  Bagde,
  ChatListItem,
  ExpandMore,
  PopoverMenu,
  PopoverMenuItem,
  Span,
  StyledLink,
} from '../style';
import {
  clearMessageCount,
  removeWaitAttendantList,
  setIsChatMinimized,
  setMessageCount,
  setSelectedConversation,
} from '../../../../store/modules/whatsappChat/actions';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from '../../../../styles/grid';
import { ImFilm } from 'react-icons/im';
import { FaCamera, FaFile, FaMicrophone } from 'react-icons/fa';
import { RiContactsFill } from 'react-icons/ri';

import {
  amareloKuppi5,
  branco,
  cinzaKuppi3,
  preto,
  verdeKuppi14,
  verdeKuppi26,
} from '../../../../styles/colors';

import px2vw from '../../../../utils/responsiveness/px2vw';

import moment from 'moment';
import DOMPurify from 'dompurify';
import { translateWeekDay } from '../../../../utils/formatDate';
import { toast } from 'react-toastify';
import WppAvatar from '../../../../components/WppAvatar';
import { updateConversations } from '../../../../store/modules/whatsappChatConversations/actions';
import { WorkerSocket } from '../../../../context/ws';
import { useWorkerSocket } from '../../../../hooks/useWebSocket';

export const ChatBarItem = ({ phone, pushname, message, currentPage }) => {
  const {
    allClientsData,
    newMessagesCount,
    waitAttendantList,
    selectedConversation,
  } = useSelector((state) => state.whatsappChat);

  const { requestCachedMessages } = useWorkerSocket();

  const advertiserInfoId = useSelector(
    (state) => state.user.user.advertiserInfo.id
  );

  const isCallingAttendant = waitAttendantList.includes(phone);

  const dispatch = useDispatch();

  const [isHover, setIsHover] = useState('');
  const [showMenu, setShowMenu] = useState(false);

  const lastMessage = useMemo(() => {
    return JSON.parse(message);
  }, [message, phone]);

  const exibitionName = useMemo(() => {
    return pushname?.length > 18
      ? pushname.slice(0, 21).replace('\\n', ' ') + '...'
      : pushname;
  }, [pushname]);

  const formatedTimestamp = useMemo(() => {
    const timestamp = new Date(lastMessage?.messageTimestamp * 1000);

    const isMoreThanOneWeek = moment(timestamp).isSameOrBefore(
      moment().subtract(6, 'days')
    );
    const isMoreThanHour = moment(timestamp).isSameOrBefore(
      moment().subtract(1, 'hours')
    );
    const isMoreThanOneDay = moment(timestamp).isSameOrBefore(
      moment().subtract(1, 'days')
    );
    const isMoreThanTwoDays = moment(timestamp).isSameOrBefore(
      moment().subtract(2, 'days')
    );

    if (isMoreThanOneWeek) {
      return moment(timestamp).format('DD/MM/YYYY');
    } else if (isMoreThanTwoDays) {
      return translateWeekDay(moment(timestamp).format('dddd').toLowerCase());
    } else if (
      isMoreThanOneDay ||
      moment(timestamp).get('day') !== moment(new Date()).get('day')
    ) {
      return 'Ontem';
    } else if (isMoreThanHour) {
      return moment(timestamp).format('HH:mm');
    } else {
      return `${
        moment().diff(moment(timestamp), 'minutes') === 0
          ? 'Agora'
          : `${moment().diff(moment(timestamp), 'minutes')} min`
      }`;
    }
  }, [lastMessage]);

  const hasNewMessages = useMemo(() => {
    return newMessagesCount.filter((item) => item === phone).length || '';
  }, [newMessagesCount]);

  const statusBadge = useMemo(() => {
    const client = allClientsData.find((client) => client.phone === phone);
    if (!client || !client.active_orders.length) {
      return false;
    }

    return client.active_orders[0].status;
  }, [allClientsData, phone]);

  const formatLastMessage = useCallback(
    (lastMessage) => {
      if (lastMessage?.video) {
        return (
          <Row alignCenter>
            <ImFilm color={cinzaKuppi3} style={{ marginRight: px2vw(3) }} />{' '}
            Mensagem de vídeo
          </Row>
        );
      }
      if (lastMessage?.image) {
        return (
          <Row alignCenter>
            <FaCamera color={cinzaKuppi3} style={{ marginRight: px2vw(3) }} />{' '}
            Imagem
          </Row>
        );
      }
      if (lastMessage?.audio) {
        return (
          <Row alignCenter>
            <FaMicrophone
              color={cinzaKuppi3}
              style={{ marginRight: px2vw(3) }}
            />{' '}
            Mensagem de aúdio
          </Row>
        );
      }
      if (lastMessage?.document) {
        return (
          <Row alignCenter>
            <FaFile color={cinzaKuppi3} style={{ marginRight: px2vw(3) }} />{' '}
            Documento
          </Row>
        );
      }
      if (lastMessage?.contact) {
        return (
          <Row alignCenter>
            <RiContactsFill
              color={cinzaKuppi3}
              style={{ marginRight: px2vw(3) }}
            />{' '}
            Contato
          </Row>
        );
      }

      const text =
        lastMessage?.conversation ||
        lastMessage?.buttons?.conversation ||
        lastMessage?.quoted?.text;

      const sanitizedText = DOMPurify.sanitize(text);

      if (text?.length > 0) {
        return sanitizedText.length > 27
          ? sanitizedText.slice(0, 30) + '...'
          : sanitizedText;
      }

      return 'Nova mensagem';
    },
    [lastMessage]
  );

  const fullName = exibitionName.split(' ');

  const handleOnClickChatlist = (phone, name) => {
    dispatch(setSelectedConversation({ id: phone, name }));
    dispatch(clearMessageCount(phone));
    dispatch(removeWaitAttendantList(phone));
    dispatch(updateConversations());

    requestCachedMessages(phone)

    toast.dismiss(`callAttenddant-${phone}`);
  };

  const getClientTag = useCallback(
    (phone) => {
      const client = allClientsData.find((client) => client.phone === phone);

      if (!client) {
        return 'Potencial Cliente';
      }

      const { client_type_tag } = client;

      return client_type_tag || 'Cliente novo';
    },
    [allClientsData, currentPage]
  );

  return (
    <StyledLink
      key={phone}
      to={whatsappchat}
      onMouseOver={() => setIsHover(phone)}
      onMouseLeave={() => {
        setIsHover('');
        setShowMenu(false);
      }}
    >
      {isHover === phone && (
        <ExpandMore
          onClick={() => {
            setShowMenu(!showMenu);
          }}
        />
      )}

      {isHover === phone && showMenu && (
        <PopoverMenu height="auto">
          <PopoverMenuItem
            onClick={() => {
              dispatch(setMessageCount(phone));
              setShowMenu(false);
            }}
          >
            Marcar como não lida
          </PopoverMenuItem>
        </PopoverMenu>
      )}

      <ChatListItem
        relative
        key={phone}
        selected={selectedConversation?.id === phone}
        onClick={() => {
          dispatch(setIsChatMinimized(true));
          handleOnClickChatlist(phone, pushname);
        }}
      >
        <WppAvatar callingAttendant={isCallingAttendant} fullName={fullName} />

        <Col justifyStart width="100%">
          <Span
            whiteSpace="nowrap"
            fontWeight="500"
            mt={px2vw(11)}
            size={px2vw(15)}
            title={pushname || exibitionName}
          >
            {exibitionName}
          </Span>
          <Span
            letterSpacing="0.005em"
            lineHeight="150%"
            fontWeight="500"
            size={px2vw(12)}
            textAlign="initial"
            maxWidth={px2vw(200)}
            whiteSpace="nowrap"
          >
            {formatLastMessage(lastMessage?.message)}
          </Span>
          <Row justifyStart alignCenter margtop={px2vw(4)} padright={px2vw(10)}>
            <Bagde mr={px2vw(5)}>{getClientTag(phone)}</Bagde>

            {statusBadge && (
              <Bagde mr={px2vw(5)} bg={verdeKuppi14}>
                {statusBadge}
              </Bagde>
            )}
            {isCallingAttendant && (
              <Bagde bg={amareloKuppi5}>Solicitou atendimento</Bagde>
            )}
          </Row>
        </Col>
        <Col
          absolute
          right="0"
          justifyStart
          alignEnd
          className="ml-auto"
          width={px2vw(75)}
        >
          <Span
            fontWeight="600"
            mt={px2vw(15)}
            size={px2vw(11)}
            pr={px2vw(10)}
            color={
              formatedTimestamp === 'Agora' || formatedTimestamp === '1 min'
                ? verdeKuppi26
                : preto
            }
          >
            {formatedTimestamp}
          </Span>
          {!!hasNewMessages && (
            <Bagde
              w={px2vw(29)}
              h={px2vw(19)}
              fontSize={px2vw(10)}
              color={branco}
              mr={px2vw(10)}
              mt={px2vw(1)}
              bg={verdeKuppi26}
            >
              {hasNewMessages}
            </Bagde>
          )}
        </Col>
      </ChatListItem>
    </StyledLink>
  );
};
