import styled, { css } from 'styled-components';

import { Form } from '@rocketseat/unform';

import px2vw from '../../utils/responsiveness/px2vw';

import {
  branco,
  preto,
  verdeKuppi1,
  cinzaKuppi2,
  cinzaKuppi3,
  cinzaKuppi4,
  cinzaKuppi1,
  verdeKuppi2,
  cinzaKuppi11,
  vermelhoKuppi1,
} from '../../styles/colors';

export const Span = styled.span`
  cursor: ${(props) => (props.cursor ? props.cursor : 'initial')};
  padding: ${(props) => (props.padding ? props.padding : '0')};
  font-size: ${(props) => (props.fontSize ? props.fontSize : px2vw(14))};
  font-family: AvenirNextLTPro;
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 'bold')};
  display: ${(props) => (props.display ? props.display : 'block')};
  color: ${(props) => (props.color ? props.color : verdeKuppi2)};
`;

export const ChangeImageButton = styled.button`
  margin-left: ${px2vw(30)};
  border: 2px solid ${preto}99;
  border-radius: ${px2vw(7)};
  background-color: transparent;
  padding: ${px2vw(5)};
  width: ${px2vw(190)};
`;

export const ImgChangeImageButton = styled.img`
  margin-top: ${px2vw(10)};
  margin-right: ${px2vw(10)};
  height: ${px2vw(16)};
  width: ${px2vw(16)};
`;

export const ChangeImageBox = styled.div`
  margin-top: ${px2vw(-10)};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ChangeImageText = styled.div`
  margin-top: ${px2vw(10)};
  font-size: ${px2vw(16)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.36px;
`;

export const ConfigGroupsBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: ${px2vw(30)};

  .MuiFormControlLabel-root {
    margin-left: 0;
    margin-right: 0;
  }
  .MuiTypography-body1 {
    line-height: 1;
  }
`;

export const ConfigGroup = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  &:not(:last-child) {
    margin-right: ${px2vw(20)};
  }

  ${(props) =>
    props.isStep && !props.isActive
      ? css`
          pointer-events: none;
          opacity: 0.4;
          cursor: not-allowed;
        `
      : ''}
`;

export const ConfigGroupTitle = styled.h3`
  font-family: AvenirNextLTPro;
  font-weight: 700;
  font-size: ${px2vw(18)};
  letter-spacing: 0.48px;

  ${(props) =>
    props.isSubtitle &&
    `
    font-size: ${px2vw(14)};
    font-weight: normal;
  `}

  ${(props) =>
    props.financialStatus === 'active' &&
    `
    color: ${verdeKuppi1};
  `};

  ${(props) =>
    props.financialStatus === 'denied' &&
    `
  color: ${vermelhoKuppi1};
`};
`;

export const StatusDot = styled.div`
  display: flex;
  background-color: ${({status}) => ['active', 'enabled'].includes(status) ? verdeKuppi1 : vermelhoKuppi1};
  padding: 10px;
  width: 10px;
  height: 10px;
  border-radius: 999px;
  margin-right: 0.5rem;
`;

export const ConfigGroupStatus = styled.h3`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: AvenirNextLTPro;
  font-weight: 600;
  font-size: ${px2vw(12)};
  letter-spacing: 0.48px;
  border: 1px solid ${cinzaKuppi2};
  padding: 0.75rem;
  border-radius: 5px;

  ${(props) =>
    props.isSubtitle &&
    `
    font-size: ${px2vw(14)};
    font-weight: normal;
  `}

  ${(props) =>
    props.financialStatus === 'active' &&
    `
    color: ${verdeKuppi1};
  `};

  ${(props) =>
    props.financialStatus === 'denied' &&
    `
  color: ${vermelhoKuppi1};
`};
`;

export const ConfigGroupContent = styled.div`
  background-color: ${(props) => props.backgroundColor || cinzaKuppi1};
  border-radius: ${px2vw(20)};
  padding: ${px2vw(30)};
  width: 100%;
  min-height: ${px2vw(420)};
  max-height: ${(props) => px2vw(props.maxHeight) || px2vw(420)};
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  box-shadow: 0px 8px 9px -2px ${cinzaKuppi4};

  ${(props) =>
    props.noContent &&
    `
    align-items: center;
    justify-content: center;
  `};
`;

export const SwitchMyStoreBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  ${(props) =>
    props.isNotVisible &&
    `
    display: none;
  `};
  ${(props) =>
    props.marginBottom &&
    css`
      margin-bottom: ${props.marginBottom};
    `}
  ${(props) =>
    props.margtop &&
    css`
      margin-top: ${props.margtop};
    `}
  ${(props) =>
    props.margleft &&
    css`
      margin-left: ${props.margleft};
    `}
    ${(props) =>
    props.marginRight &&
    css`
      margin-right: ${props.marginRight};
    `}
`;

export const SaveButton = styled.button`
  background-color: ${cinzaKuppi2};
  height: ${px2vw(40)};
  width: ${px2vw(100)};
  line-height: ${px2vw(40)};
  border: none;
  border-radius: ${px2vw(8)};

  ${(props) => `
    margin-top: ${props.margtop};
    margin-left: ${props.margleft};
  `};

  ${(props) =>
    props.hasChanges &&
    `
    background-color: ${verdeKuppi1};
  `};

  ${(props) =>
    props.financialPageWidth &&
    `
    width: ${px2vw(120)};
  `};
`;

export const SaveButtonText = styled.div`
  font-size: ${px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.36px;
  color: ${cinzaKuppi3};

  ${(props) =>
    props.hasChanges &&
    `
    color: ${branco};
  `};
`;

export const StoreForm = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const ButtonImage = styled.img`
  height: ${px2vw(15)};
  width: ${px2vw(15)};
  ${(props) => `margin-left: ${props.margleft};`};
  ${(props) => `margin-right: ${props.margright};`};
  ${(props) => `margin-top: ${props.margtop};`};

  ${(props) =>
    !props.isVisible &&
    `
    display: none;
  `};
`;

export const TabsBox = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: ${px2vw(20)};
  margin-left: ${(props) => props.margleft};
`;

export const TabBox = styled.div`
  display: block;
  flex-direction: column;
  align-items: center;
  margin-left: ${(props) => props.margleft || px2vw(40)};
  justify-content: center;
`;

export const Tab = styled.button`
  height: ${px2vw(45)};
  width: 100%;
  font-size: ${px2vw(20)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.48px;
  border: none;
  background-color: transparent;
`;

export const TabSelected = styled.div`
  display: none;
  height: ${px2vw(5)};
  width: ${px2vw(125)};
  background-color: ${verdeKuppi1};
  border-radius: ${px2vw(5)};
  margin-top: ${px2vw(-7)};
  margin-bottom: ${px2vw(20)};

  ${(props) =>
    props.isVisible &&
    `
    display: block;
  `};

  ${(props) => `
    width: ${props.width};
  `};
`;

export const SwitchLabelBoldText = styled.h4`
  font-size: ${px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.36px;
  margin-bottom: 0;

  ${(props) =>
    props.marginBottom &&
    css`
      margin-bottom: ${props.marginBottom};
    `}
  ${(props) =>
    props.width &&
    css`
      width: ${props.width};
    `}

  ${(props) =>
    props.marginLeft &&
    css`
      margin-left: ${props.marginLeft};
    `}
  
    ${(props) =>
    props.noBold &&
    css`
      font-weight: normal;
    `}
`;

export const CloseButton = styled.button`
  align-self: flex-start;
  margin-left: ${px2vw(9)};
  margin-top: ${px2vw(4)};
  width: ${px2vw(24)};
  height: ${px2vw(24)};
  border: none;
  z-index: 10;
  background-color: transparent;

  :focus {
    background-color: transparent !important;
  }

  ${(props) =>
    props.absolute &&
    css`
      position: absolute;
      top: ${props.top || px2vw(10)};
      right: ${props.right || px2vw(10)};
    `}
`;

export const CloseImage = styled.img`
  width: ${(props) => props.width || px2vw(24)};
  height: ${(props) => props.height || px2vw(24)};
`;

export const ButtonPrimary = styled.button`
  background-color: ${(props) => (props.disabled ? cinzaKuppi4 : verdeKuppi2)};
  border: 1px solid ${(props) => (props.disabled ? cinzaKuppi4 : verdeKuppi2)};
  color: ${branco};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${px2vw(5)};
  font-size: ${px2vw(15)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.32px;
  padding: ${px2vw(8)};
  width: ${(props) => props.width || px2vw(200)};
  height: ${(props) => props.height || px2vw(31)};
  box-shadow: 2px 2px 3px ${cinzaKuppi2};

  &:active {
    box-shadow: 2px 2px 3px ${cinzaKuppi2};
    background: ${verdeKuppi1};
  }

  :hover {
    background-color: ${(props) =>
      props.disabled ? cinzaKuppi4 : verdeKuppi2};
    border: 1px solid ${(props) => (props.disabled ? '' : cinzaKuppi4)};
  }

  &:focus,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
    color: ${branco};
  }
`;
