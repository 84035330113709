import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'reactstrap';

import {
  CategoryModal,
  ModalTitleText,
  ReorderComplementSubtitle,
  ReorderComplementsBox,
  ReorderComplementBox,
  ReorderComplementName,
  ReorderComplementHeaderBox,
  ReorderComplementHeaderName,
  ButtonImage,
} from '../styles';

import {
  LoadingBox,
} from '../../_layouts/styles/common';

import Modal from '../../../components/Modal';
import {
  ModalHeader,
  CloseButton,
  CloseImage,
  ModalPrimaryButton,
  ModalPrimaryButtonText,
  ModalActions,
  ModalSecundaryButton,
  ModalSecundaryButtonText,
} from '../../../components/Modal/styles';

import {
  branco,
  preto,
  cinzaKuppi2,
} from '../../../styles/colors';

import { Row, Col } from '../../../styles/grid';

import px2vw from '../../../utils/responsiveness/px2vw';

import DragAndDrop from '../../../utils/dragAndDrop/DragAndDrop';

import {
  formatCatalogUpdateOrdenation,
} from '../../../store/modules/productCategories/actions';

export default function CatalogReorderProductCategoryModal({ handler }) {
  const updateProductsCategoryOrdenationModalLoading = useSelector(
    (state) =>
      state.productCategories.updateProductsCategoryOrdenationModalLoading
  );
  const [
    productsCategoryOrdenationNewIndexChanges,
    setProductsCategoryOrdenationNewIndexChanges,
  ] = useState([]);
  const [
    productsOrdenationNewIndexChanges,
    setProductsOrdenationNewIndexChanges,
  ] = useState([]);
  const dispatch = useDispatch();

  const handleCloseReorderProductCategoryModal = () => {
    handler.setReorderProductCategoryIdx(null);
    handler.setReorderProductCategoryModal(false);
  }

  // Receive return after product category Drag And Drop
  function handleProductCategoryDragAndDrop(productCategoryBox) {
    setProductsCategoryOrdenationNewIndexChanges(productCategoryBox);
  }

  // Receive return after product Drag And Drop
  function handleProductDragAndDrop(productBox) {
    setProductsOrdenationNewIndexChanges(productBox);
  }

  // Call product category Drag And Drop handler
  const currentProductCategoryDragAndDrop = useCallback(() => {
    DragAndDrop(
      { id: '#productCategory', classNameBox: '.reorderProductCategoryBox' },
      handleProductCategoryDragAndDrop
    );
  }, []);

  // Call product Drag And Drop handler
  const currentProductDragAndDrop = useCallback(() => {
    DragAndDrop(
      {
        id: '#product',
        classNameBox: '.reorderProductBox',
      },
      handleProductDragAndDrop
    );
  }, []);

  // Set product category ordenation changed after Drag And Drop
  useEffect(() => {
    if (productsCategoryOrdenationNewIndexChanges.length > 0) {
      const currProductCategoryArray = [...handler.reorderProductsCategoryArray];

      handler.reorderProductsCategoryArray.forEach(
        (productCategory, productCategoryIdx) => {
          productsCategoryOrdenationNewIndexChanges.forEach(
            (newProductCategory, newProductCategoryIdx) => {
              if (
                parseInt(newProductCategory, 10) ===
                parseInt(productCategoryIdx, 10)
              ) {
                currProductCategoryArray[productCategoryIdx].ordenation_index =
                  parseInt(newProductCategoryIdx, 10) + 1;
              }
            }
          );
        }
      );

      handler.setReorderProductsCategoryArray([...currProductCategoryArray]);
      setProductsCategoryOrdenationNewIndexChanges([]);
    }
  }, [productsCategoryOrdenationNewIndexChanges]);

  // Set product ordenation changed after Drag And Drop
  useEffect(() => {
    if (productsOrdenationNewIndexChanges.length > 0) {
      const currProductArray = [...handler.reorderProductsCategoryArray];

      handler.reorderProductsCategoryArray[handler.reorderProductCategoryIdx].products.forEach(
        (product, productIdx) => {
          productsOrdenationNewIndexChanges.forEach(
            (newProduct, newProductIdx) => {
              if (parseInt(newProduct, 10) === parseInt(productIdx, 10)) {
                currProductArray[handler.reorderProductCategoryIdx].products[
                  productIdx
                ].ordenation_index = parseInt(newProductIdx, 10) + 1;
              }
            }
          );
        }
      );

      handler.setReorderProductsCategoryArray([...currProductArray]);
      setProductsOrdenationNewIndexChanges([]);
    }
  }, [productsOrdenationNewIndexChanges]);

  // Handle complement category Drag And Drop
  useEffect(() => {
    currentProductCategoryDragAndDrop();
  }, [handler.reorderProductCategoryModal]);

  // Handle complement Drag And Drop
  useEffect(() => {
    currentProductDragAndDrop();
  }, [handler.reorderProductCategoryIdx]);

  // Accept catalog products/categories ordenation changes
  function handleReorderCatalog() {
    const currReorderProductsCategoryArray = [...handler.reorderProductsCategoryArray];

    // Sort the products categories array using the new ordenation index value
    currReorderProductsCategoryArray.sort(function sort(a, b) {
      if (a.ordenation_index - b.ordenation_index === 0) {
        // Changed the return for Chrome, to avoid error when order the array
        return navigator.userAgent.indexOf('Chrome') !== -1 ? -1 : 1;
      }
      return a.ordenation_index - b.ordenation_index;
    });

    // Sort the products array using the new ordenation index value
    currReorderProductsCategoryArray.forEach((category) => {
      category.products.sort(function sort(a, b) {
        if (a.ordenation_index - b.ordenation_index === 0) {
          // Changed the return for Chrome, to avoid error when order the array
          return navigator.userAgent.indexOf('Chrome') !== -1 ? -1 : 1;
        }
        return a.ordenation_index - b.ordenation_index;
      });
    });

    // Update products categories ordenation
    dispatch(formatCatalogUpdateOrdenation(currReorderProductsCategoryArray));
  }

  return (
    <Modal
      isVisible={handler.reorderProductCategoryModal}
      width={px2vw(700)}
      height={px2vw(580)}
      padding={px2vw(25)}
    >
      <CategoryModal>
        <ModalHeader>
          <ModalTitleText>Reordenar Catálogo</ModalTitleText>
          <CloseButton
            disabled={updateProductsCategoryOrdenationModalLoading}
            onClick={handleCloseReorderProductCategoryModal}
          >
            <CloseImage
              src={require('../../../assets/images/close/close.png')}
            />
          </CloseButton>
        </ModalHeader>
        <ReorderComplementSubtitle>
          Para alterar a ordem dos produtos ou categorias do seu catálogo:
          clique, arraste e solte o item na nova posição.
        </ReorderComplementSubtitle>
        <Row alignStart margleft={px2vw(-8.5)} height="auto">
          <ReorderComplementHeaderBox>
            <ReorderComplementHeaderName>
              Categorias
            </ReorderComplementHeaderName>
          </ReorderComplementHeaderBox>
          <ReorderComplementHeaderBox>
            <ReorderComplementHeaderName>
              Produtos
            </ReorderComplementHeaderName>
          </ReorderComplementHeaderBox>
        </Row>
        <ReorderComplementsBox>
          <Row width={px2vw(640)}>
            <Col
              justifyStart
              id="productCategory"
              className="dropzoneProductCategory"
              padtop={px2vw(10)}
            >
              {handler.reorderProductsCategoryArray.map((category, idx) => (
                <ReorderComplementBox
                  draggable="true"
                  className={`reorderProductCategoryBox reorderProductCategoryBox${idx}`}
                  id={idx}
                  onClick={() => {
                    // Unmark all of others category complements box
                    const elements = document.getElementsByClassName(
                      'reorderProductCategoryBox'
                    );
                    for (let i = 0; i < elements.length; i++) {
                      elements[i].style.backgroundColor = branco;
                    }

                    // Mark the selected one
                    document.querySelector(
                      `.dropzoneProductCategory .reorderProductCategoryBox${idx}`
                    ).style.backgroundColor = cinzaKuppi2;

                    handler.setReorderProductCategoryIdx(idx);
                  }}
                >
                  <Row alignStart justifyStart>
                    <ReorderComplementName
                      title={
                        category.name.length >= 33 ? category.name : null
                      }
                    >
                      {category.name.length >= 33
                        ? `${category.name.substring(0, 29)}...`
                        : category.name}
                    </ReorderComplementName>
                    <Row alignCenter justifyEnd width={px2vw(20)}>
                      <ButtonImage
                        src={require('../../../assets/images/reorder/reorder.png')}
                      />
                    </Row>
                  </Row>
                </ReorderComplementBox>
              ))}
            </Col>
            <Col
              justifyStart
              id="product"
              className="dropzoneProduct"
              padtop={px2vw(10)}
            >
              {handler.reorderProductCategoryIdx !== null &&
                handler.reorderProductsCategoryArray[
                  handler.reorderProductCategoryIdx
                ].products.map((product, idxProd) => (
                  <ReorderComplementBox
                    className={`reorderProductBox reorderProductBox${idxProd}`}
                    id={idxProd}
                    isComplement
                    draggable="true"
                  >
                    <Row alignStart justifyStart>
                      <ReorderComplementName
                        title={
                          product.name.length >= 33 ? product.name : null
                        }
                      >
                        {product.name.length >= 33
                          ? `${product.name.substring(0, 29)}...`
                          : product.name}
                      </ReorderComplementName>
                      <Row alignCenter justifyEnd width={px2vw(20)}>
                        <ButtonImage
                          src={require('../../../assets/images/reorder/reorder.png')}
                        />
                      </Row>
                    </Row>
                  </ReorderComplementBox>
                ))}
            </Col>
          </Row>
        </ReorderComplementsBox>
        <ModalActions>
          <ModalSecundaryButton
            margleft={px2vw(80)}
            marginRight={px2vw(20)}
            margtop={px2vw(30)}
            width={px2vw(220)}
            onClick={handleCloseReorderProductCategoryModal}
            disabled={updateProductsCategoryOrdenationModalLoading}
          >
            <ModalSecundaryButtonText>Cancelar</ModalSecundaryButtonText>
          </ModalSecundaryButton>
          <ModalPrimaryButton
            margleft={px2vw(40)}
            margtop={px2vw(30)}
            width={px2vw(220)}
            onClick={handleReorderCatalog}
            disabled={updateProductsCategoryOrdenationModalLoading}
          >
            {updateProductsCategoryOrdenationModalLoading ? (
              <LoadingBox>
                <Spinner
                  style={{
                    width: '1.8rem',
                    height: '1.8rem',
                    color: preto,
                  }}
                />{' '}
              </LoadingBox>
            ) : (
              <ModalPrimaryButtonText>Salvar</ModalPrimaryButtonText>
            )}
          </ModalPrimaryButton>
        </ModalActions>
      </CategoryModal>
    </Modal>
  );
}
