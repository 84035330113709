import React, { useState, useEffect } from 'react';

import { FormControlLabel } from '@material-ui/core';
import { Spinner } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import {
  ConfigGroup,
  SaveButton,
  SaveButtonText,
  ConfigGroupTitle,
  ConfigGroupContent,
  SwitchMyStoreBox,
  StoreForm,
  ButtonImage,
} from '../styles';

import {
  SwitchLabelBoldText,
  SwitchLabelRegularText,
  SwitchLabelRegularTextBefore,
  Header,
  OptionRow,
  CheckboxFeeCalcType,
  CheckboxFeeCalcTypeChecked,
  FormNewNeighborhoodData,
  ModalInput,
  ModalInputLabelText,
  ModalSelectInput,
} from './styles';

import {
  SwitchController,
  SwitchWithInput,
  SwitchWithInputBox,
  SwitchInput,
  LoadingBox,
} from '../../_layouts/styles/common';

import { Row, Col } from '../../../styles/grid';

import px2vw from '../../../utils/responsiveness/px2vw';

import { onlyNumbers, onlyNumbersAmount } from '../../../utils/utils';
import { amountMask } from '../../../utils/masks';

import { statesOptions } from '../../../utils/commonObjects';

import Modal from '../../../components/Modal';
import AdvertiserSelectedNeighborhoodsTable from '../../../components/AdvertiserSelectedNeighborhoodsTable';

import {
  CloseButton,
  CloseImage,
  ModalPrimaryButton,
  ModalPrimaryButtonText,
  ModalTitleText,
  ModalActions,
  ModalSecundaryButton,
  ModalSecundaryButtonText,
} from '../../../components/Modal/styles';

import { preto } from '../../../styles/colors';
import manageDynamicFeeImg from '../../../assets/images/manage_dynamic_fee/manage_dynamic_fee.png';

import {
  clearSuccess,
  updateStoreDeliveryTakeAwayRequest,
  getLocalitiesRequest,
  getDistrictsRequest,
  addNewNeighborhoodRequest,
  getSelectedNeighborhoodsRequest,
} from '../../../store/modules/myStore/actions';

import StoreDeliveryTakeAwayParamsOnboardingMobile from '../../Onboarding/Step2-DeliveryParams/Mobile/StoreDeliveryTakeAwayParamsOnboardingMobile';

function StoreDeliveryTakeAwayParams(props) {
  const userAddressState = useSelector(
    (state) => state.user.user.address[0].state
  );
  const userInfo = useSelector((state) => state.user.user.advertiserInfo);
  const [orderMinimumAmount, setOrderMinimumAmount] = useState(false);
  const [
    orderMinimumAmountInputValue,
    setOrderMinimumAmountInputValue,
  ] = useState('');

  const [takeAwayActive, setTakeAwayActive] = useState(false);
  const [takeAwayTimeAvgInputValue, setTakeAwayTimeAvgInputValue] = useState(
    0
  );

  const [deliveryActive, setDeliveryActive] = useState(false);

  const [maxDistInputValue, setMaxDistInputValue] = useState('');
  const [radiusArea, setRadiusArea] = useState(false);
  const [neighborhoodArea, setNeighborhoodArea] = useState(false);
  const selectedNeighborhoods = useSelector(
    (state) => state.myStore.selectedNeighborhoods
  );
  const [neighborhoodAreaModal, setNeighborhoodAreaModal] = useState(false);
  const localities = useSelector((state) => state.myStore.localities);
  const localitiesLoading = useSelector(
    (state) => state.myStore.localitiesLoading
  );
  const localitiesSuccess = useSelector(
    (state) => state.myStore.localitiesSuccess
  );
  const [localitiesSelect, setLocalitiesSelect] = useState([]);
  const [newStateSelected, setNewStateSelected] = useState(userAddressState);
  const [newCitySelectedObj, setNewCitySelectedObj] = useState(null);
  const districts = useSelector((state) => state.myStore.districts);
  const districtsLoading = useSelector(
    (state) => state.myStore.districtsLoading
  );
  const districtsSuccess = useSelector(
    (state) => state.myStore.districtsSuccess
  );
  const [districtsSelect, setDistrictsSelect] = useState([]);
  const [newNeighborhoodSelectedObj, setNewNeighborhoodSelectedObj] = useState(
    null
  );
  const [newDeliveryFee, setNewDeliveryFee] = useState(null);
  const addNewNeighborhoodDataLoading = useSelector(
    (state) => state.myStore.addNewNeighborhoodDataLoading
  );
  const addNewNeighborhoodDataSuccess = useSelector(
    (state) => state.myStore.addNewNeighborhoodDataSuccess
  );

  const [deliveryFree, setDeliveryFree] = useState(false);
  const [deliveryFee, setDeliveryFee] = useState(false);
  const [deliveryFeeInputValue, setDeliveryFeeInputValue] = useState('');
  const [dynamicDeliveryFee, setDynamicDeliveryFee] = useState(false);

  const [deliveryTimeAvg, setDeliveryTimeAvg] = useState(false);
  const [deliveryTimeAvgInputValue, setDeliveryTimeAvgInputValue] = useState(
    0
  );
  const [dynamicDeliveryFeeModal, setDynamicDeliveryFeeModal] = useState(false);
  const [dynamicDeliveryFeeAmount, setDynamicDeliveryFeeAmount] = useState('');
  const [
    dynamicDeliveryFeeAmountInputValue,
    setDynamicDeliveryFeeAmountInputValue,
  ] = useState('');
  const [dynamicMaxDistApply, setDynamicMaxDistApply] = useState('');
  const [
    dynamicMaxDistApplyInputValue,
    setDynamicMaxDistApplyInputValue,
  ] = useState('');
  const [dynamicExceededFeeAmount, setDynamicExceededFeeAmount] = useState('');
  const [
    dynamicExceededFeeAmountInputValue,
    setDynamicExceededFeeAmountInputValue,
  ] = useState('');
  const [
    dynamicFreeDeliveryAtDistActive,
    setDynamicFreeDeliveryAtDistActive,
  ] = useState(false);
  const [
    dynamicFreeDeliveryAtDistActiveInputValue,
    setDynamicFreeDeliveryAtDistActiveInputValue,
  ] = useState(false);
  const [dynamicFreeDeliveryAtDist, setDynamicFreeDeliveryAtDist] = useState(
    ''
  );
  const [
    dynamicFreeDeliveryAtDistInputValue,
    setDynamicFreeDeliveryAtDistInputValue,
  ] = useState('');
  const [dynamicFeeCalcTypeRoute, setDynamicFeeCalcTypeRoute] = useState(false);
  const [
    dynamicFeeCalcTypeRouteInputValue,
    setDynamicFeeCalcTypeRouteInputValue,
  ] = useState(false);
  const [dynamicFeeCalcTypeRadius, setDynamicFeeCalcTypeRadius] = useState(
    false
  );
  const [
    dynamicFeeCalcTypeRadiusInputValue,
    setDynamicFeeCalcTypeRadiusInputValue,
  ] = useState(false);

  const [deliveryChanges, setDeliveryChanges] = useState(false);

  const deliveryTakeAwayLoading = useSelector(
    (state) => state.myStore.deliveryTakeAwayLoading
  );
  const deliveryTakeAwaySuccess = useSelector(
    (state) => state.myStore.deliveryTakeAwaySuccess
  );
  const updateOnboardingStepLoading = useSelector(
    (state) => state.onboarding.updateOnboardingStepLoading
  );  
  const updateOnboardingStepSuccess = useSelector(
    (state) => state.onboarding.updateOnboardingStepSuccess
  );
  const dispatch = useDispatch();

  // Set the store delivery / take away data
  useEffect(() => {
    // Order Minimum Amount
    setOrderMinimumAmountInputValue('');
    if (parseFloat(userInfo.order_minimum_amount) !== parseFloat(0)) {
      setOrderMinimumAmount(true);
      setOrderMinimumAmountInputValue(
        amountMask(parseFloat(userInfo.order_minimum_amount).toFixed(2))
      );
    }

    // Take away active
    setTakeAwayActive(userInfo.is_using_take_away);
    setTakeAwayTimeAvgInputValue('');
    if (userInfo.take_away_time_avg) {
      setTakeAwayTimeAvgInputValue(userInfo.take_away_time_avg);
    }

    // Delivery active
    setDeliveryActive(userInfo.is_using_delivery);
    // Delivery range area
    setMaxDistInputValue(userInfo.delivery_range_area);
    // Delivery time avg
    setDeliveryTimeAvg(!!userInfo.delivery_time_avg);
    setDeliveryTimeAvgInputValue('');
    if (userInfo.delivery_time_avg) {
      setDeliveryTimeAvgInputValue(userInfo.delivery_time_avg);
    }
    // Use Radius Area
    setRadiusArea(
      userInfo.advertiserDeliveryTakeawayConfig[0].is_using_range_area
    );
    // Use Neighborhood Area
    setNeighborhoodArea(
      userInfo.advertiserDeliveryTakeawayConfig[0].is_using_neighborhood_area
    );
    // Delivery fee
    if (parseFloat(userInfo.delivery_dynamic_fee) !== parseFloat(0)) {
      setDynamicDeliveryFee(true);
      setDynamicDeliveryFeeAmount(
        amountMask(parseFloat(userInfo.delivery_dynamic_fee).toFixed(2))
      );
      setDynamicDeliveryFeeAmountInputValue(
        amountMask(parseFloat(userInfo.delivery_dynamic_fee).toFixed(2))
      );
      setDynamicMaxDistApply(userInfo.delivery_dynamic_fee_max_dist);
      setDynamicMaxDistApplyInputValue(userInfo.delivery_dynamic_fee_max_dist);
      setDynamicExceededFeeAmount(
        amountMask(
          parseFloat(userInfo.delivery_dynamic_fee_exceeded_amount).toFixed(2)
        )
      );
      setDynamicExceededFeeAmountInputValue(
        amountMask(
          parseFloat(userInfo.delivery_dynamic_fee_exceeded_amount).toFixed(2)
        )
      );
      if (
        parseFloat(userInfo.delivery_dynamic_fee_free_dist) !== parseFloat(0)
      ) {
        setDynamicFreeDeliveryAtDistActive(true);
        setDynamicFreeDeliveryAtDistActiveInputValue(true);
        setDynamicFreeDeliveryAtDist(userInfo.delivery_dynamic_fee_free_dist);
        setDynamicFreeDeliveryAtDistInputValue(
          userInfo.delivery_dynamic_fee_free_dist
        );
      }
      setDynamicFeeCalcTypeRoute(
        userInfo.delivery_dynamic_fee_calc_type === 'route'
      );
      setDynamicFeeCalcTypeRouteInputValue(
        userInfo.delivery_dynamic_fee_calc_type === 'route'
      );
      setDynamicFeeCalcTypeRadius(
        userInfo.delivery_dynamic_fee_calc_type === 'radius'
      );
      setDynamicFeeCalcTypeRadiusInputValue(
        userInfo.delivery_dynamic_fee_calc_type === 'radius'
      );
    } else if (parseFloat(userInfo.delivery_fee) === parseFloat(0)) {
      setDeliveryFree(true);
    } else {
      setDeliveryFee(true);
      setDeliveryFeeInputValue(
        amountMask(parseFloat(userInfo.delivery_fee).toFixed(2))
      );
    }
    dispatch(getSelectedNeighborhoodsRequest());
  }, [userInfo]);

  // Change delivery tax amount type
  function handleChangeDeliveryType(type) {
    setDeliveryChanges(true);
    setDeliveryFeeInputValue('');
    setDynamicDeliveryFeeAmountInputValue('');
    setDynamicMaxDistApplyInputValue('');
    setDynamicExceededFeeAmountInputValue('');
    setDynamicFreeDeliveryAtDistActiveInputValue(false);
    setDynamicFreeDeliveryAtDistInputValue(false);
    setDynamicFeeCalcTypeRouteInputValue(false);
    setDynamicFeeCalcTypeRadiusInputValue(false);

    if (type === 'delivery_free') {
      setDeliveryFree(true);
      setDeliveryFee(false);
      setDynamicDeliveryFee(false);
      setDynamicFreeDeliveryAtDist('');
      setDynamicDeliveryFeeAmount('');
      setDynamicMaxDistApply('');
      setDynamicExceededFeeAmount('');
      setDynamicFreeDeliveryAtDistActive(false);
      setDynamicFreeDeliveryAtDist('');
      setDynamicFeeCalcTypeRoute(true);
      setDynamicFeeCalcTypeRadius(false);
    } else if (type === 'delivery_fee') {
      setDeliveryFree(false);
      setDeliveryFee(true);
      setDynamicDeliveryFee(false);
      setDynamicFreeDeliveryAtDist('');
      setDynamicDeliveryFeeAmount('');
      setDynamicMaxDistApply('');
      setDynamicExceededFeeAmount('');
      setDynamicFreeDeliveryAtDistActive(false);
      setDynamicFreeDeliveryAtDist('');
      setDynamicFeeCalcTypeRoute(true);
      setDynamicFeeCalcTypeRadius(false);
    } else if (type === 'dynamic_delivery_fee') {
      setDeliveryFree(false);
      setDeliveryFee(false);
      setDynamicDeliveryFee(true);
    }
  }

  function handleChangeDeliveryArea(type) {
    setDeliveryChanges(true);

    if (type === 'radius_area') {
      setRadiusArea(true);
      setNeighborhoodArea(false);
    } else if (type === 'neighborhood_area') {
      setRadiusArea(false);
      setNeighborhoodArea(true);
      // Get the selectedNeighborhoods
      dispatch(getSelectedNeighborhoodsRequest());
    }
  }

  function handleDeliveryActive() {
    setDeliveryChanges(true);
    setDeliveryActive(!deliveryActive);
  }

  function handleTakeAwayActive() {
    setDeliveryChanges(true);
    setTakeAwayActive(!takeAwayActive);
  }

  function handleCancelDynamicDeliveryFeeModal() {
    setDynamicDeliveryFeeAmountInputValue(
      onlyNumbersAmount(dynamicDeliveryFeeAmount)
    );
    setDynamicMaxDistApplyInputValue(
      onlyNumbers(dynamicMaxDistApply)
    );
    setDynamicExceededFeeAmountInputValue(
      onlyNumbersAmount(dynamicExceededFeeAmount)
    );
    setDynamicFreeDeliveryAtDistInputValue(
      onlyNumbers(dynamicFreeDeliveryAtDist)
    );
    setDynamicFreeDeliveryAtDistActiveInputValue(
      dynamicFreeDeliveryAtDistActive
    );
    setDynamicFeeCalcTypeRouteInputValue(dynamicFeeCalcTypeRoute);
    setDynamicFeeCalcTypeRadiusInputValue(dynamicFeeCalcTypeRadius);

    setDynamicDeliveryFeeModal(false);
  }

  function handleConfirmDynamicDeliveryFeeModal() {
    if (dynamicDeliveryFeeAmountInputValue === '') {
      toast.error('Valor mínimo da taxa de entrega não definido.', {
        autoClose: 6000,
      });
    } else if (dynamicDeliveryFeeAmountInputValue.length < 7) {
      toast.error('Valor mínimo da taxa de entrega inválido.', {
        autoClose: 6000,
      });
    } else if (
      parseFloat(
        (
          1 * onlyNumbersAmount(dynamicDeliveryFeeAmountInputValue)
        ).toFixed(2)
      ) === parseFloat(0)
    ) {
      toast.error(
        'Valor mínimo da taxa de entrega não pode ser zero.',
        {
          autoClose: 6000,
        }
      );
    } else if (dynamicMaxDistApplyInputValue === '') {
      toast.error(
        'Distância ao qual a taxa mínima de entrega deve ser aplicada não definida.',
        {
          autoClose: 6000,
        }
      );
    } else if (
      parseInt(dynamicMaxDistApplyInputValue, 10) ===
      parseInt(0, 10)
    ) {
      toast.error(
        'Distância ao qual a taxa mínima de entrega deve ser aplicada não pode ser zero.',
        {
          autoClose: 6000,
        }
      );
    } else if (
      parseInt(dynamicMaxDistApplyInputValue, 10) >
      parseInt(maxDistInputValue, 10)
    ) {
      toast.error(
        `Distância ao qual taxa mínima de entrega deve ser aplicada, não pode ser maior que o seu raio máximo de entrega, que é: ${parseInt(
          maxDistInputValue,
          10
        )}km.`,
        {
          autoClose: 6000,
        }
      );
    } else if (dynamicExceededFeeAmountInputValue === '') {
      toast.error('Valor por km excedente não definido.', {
        autoClose: 6000,
      });
    } else if (dynamicExceededFeeAmountInputValue.length < 7) {
      toast.error('Valor por km excedente inválido.', {
        autoClose: 6000,
      });
    } else if (
      dynamicFreeDeliveryAtDistActiveInputValue &&
      dynamicFreeDeliveryAtDistInputValue === ''
    ) {
      toast.error(
        'Quilometragem grátis da entrega não definida. Para não ter quilometragem grátis, deve-se desmarcar a opção.',
        {
          autoClose: 6000,
        }
      );
    } else if (
      dynamicFreeDeliveryAtDistActiveInputValue &&
      parseInt(dynamicFreeDeliveryAtDistInputValue, 10) ===
        parseInt(0, 10)
    ) {
      toast.error(
        'Quilometragem grátis da entrega não definida. Para não ter quilometragem grátis, deve-se desmarcar a opção.',
        {
          autoClose: 6000,
        }
      );
    } else if (
      dynamicFreeDeliveryAtDistActiveInputValue &&
      parseInt(dynamicFreeDeliveryAtDistInputValue, 10) >=
        parseInt(maxDistInputValue, 10)
    ) {
      toast.error(
        `Quilometragem grátis da entrega, não pode ser maior ou igual ao seu raio máximo de entrega, que é: ${parseInt(
          maxDistInputValue,
          10
        )}km.`,
        {
          autoClose: 6000,
        }
      );
    } else if (
      dynamicFreeDeliveryAtDistActiveInputValue &&
      parseInt(dynamicFreeDeliveryAtDistInputValue, 10) >=
        parseInt(dynamicMaxDistApplyInputValue, 10)
    ) {
      toast.error(
        `Quilometragem grátis da entrega, não pode ser maior ou igual a distância que o valor mínimo de entrega será aplicado, que é: ${parseInt(
          dynamicMaxDistApplyInputValue,
          10
        )}km.`,
        {
          autoClose: 6000,
        }
      );
    } else if (
      !dynamicFeeCalcTypeRouteInputValue &&
      !dynamicFeeCalcTypeRadiusInputValue
    ) {
      toast.error(
        `Não foi definido o que será utilizado como base para o cálculo da taxa de entrega dinâmica. Selecione Rota ou Linha Reta.`,
        {
          autoClose: 6000,
        }
      );
    } else {
      setDynamicDeliveryFeeAmount(
        onlyNumbersAmount(dynamicDeliveryFeeAmountInputValue)
      );
      setDynamicMaxDistApply(
        onlyNumbers(dynamicMaxDistApplyInputValue)
      );
      setDynamicExceededFeeAmount(
        onlyNumbersAmount(dynamicExceededFeeAmountInputValue)
      );
      setDynamicFreeDeliveryAtDist(
        onlyNumbers(dynamicFreeDeliveryAtDistInputValue)
      );
      setDynamicFreeDeliveryAtDistActive(
        dynamicFreeDeliveryAtDistActiveInputValue
      );
      setDynamicFeeCalcTypeRoute(dynamicFeeCalcTypeRouteInputValue);
      setDynamicFeeCalcTypeRadius(
        dynamicFeeCalcTypeRadiusInputValue
      );

      setDynamicDeliveryFeeModal(false);
    }
  }

  // Validate delivery / take away form data
  const validateForm = () => {
    let salva = true;

    if (!deliveryActive && !takeAwayActive) {
      toast.error(
        'Você deve ativar ao menos uma opção (Delivery e/ou Retirada).',
        {
          autoClose: 6000,
        }
      );
      salva = false;
    } else {
      if (orderMinimumAmount && orderMinimumAmountInputValue === '') {
        toast.error('Valor mínimo de pedido não definido.', {
          autoClose: 6000,
        });
        salva = false;
      } else if (
        orderMinimumAmount &&
        orderMinimumAmountInputValue.length < 7
      ) {
        toast.error('Valor mínimo de pedido inválido.', {
          autoClose: 6000,
        });
        salva = false;
      } else if (
        orderMinimumAmount &&
        parseFloat(
          (1 * onlyNumbersAmount(orderMinimumAmountInputValue)).toFixed(2)
        ) === parseFloat(0)
      ) {
        toast.error(
          'Valor mínimo de pedido não pode ser zero. Para não definir um valor, desmarque a opção de valor mínimo.',
          {
            autoClose: 6000,
          }
        );
        salva = false;
      }

      if (deliveryActive) {
        if (deliveryTimeAvgInputValue < 0 || deliveryTimeAvgInputValue > 240) {
          toast.error(
            'Tempo médio de entrega inválido. Deve ser entre 0-240 minutos.',
            {
              autoClose: 6000,
            }
          );
          salva = false;
        } else if (radiusArea) {
          if (maxDistInputValue === '') {
            toast.error('Raio de entrega não definido.', {
              autoClose: 6000,
            });
            salva = false;
          } else if (parseInt(maxDistInputValue, 10) === parseInt(0, 10)) {
            toast.error('Raio de entrega não pode ser zero.', {
              autoClose: 6000,
            });
            salva = false;
          } else if (deliveryFee && deliveryFeeInputValue === '') {
            toast.error('Taxa de entrega única não definida.', {
              autoClose: 6000,
            });
            salva = false;
          } else if (deliveryFee && deliveryFeeInputValue.length < 7) {
            toast.error('Taxa de entrega única inválida.', {
              autoClose: 6000,
            });
            salva = false;
          } else if (
            deliveryFee &&
            parseFloat(
              (1 * onlyNumbersAmount(deliveryFeeInputValue)).toFixed(2)
            ) === parseFloat(0)
          ) {
            toast.error(
              'Taxa de entrega informada não pode ser zero. Para taxa de entrega grátis, selecione a opção correspondente.',
              {
                autoClose: 6000,
              }
            );
            salva = false;
          } else if (
            dynamicDeliveryFee &&
            (dynamicDeliveryFeeAmountInputValue === '' ||
              dynamicMaxDistApplyInputValue === '' ||
              dynamicExceededFeeAmountInputValue === '' ||
              (dynamicFreeDeliveryAtDistActiveInputValue &&
                dynamicFreeDeliveryAtDistInputValue === '') ||
              (!dynamicFeeCalcTypeRouteInputValue &&
                !dynamicFeeCalcTypeRadiusInputValue))
          ) {
            toast.error(
              'Informações referentes a taxa de entrega dinâmica estão inválidas. Verifique-as e tente novamente.',
              {
                autoClose: 6000,
              }
            );
            salva = false;
          }
        } else if (neighborhoodArea) {
          if (
            (!userInfo.advertiserDeliveryTakeawayConfig[0]
              .is_using_neighborhood_area &&
              (!selectedNeighborhoods || selectedNeighborhoods.length === 0)) ||
            (userInfo.advertiserDeliveryTakeawayConfig[0]
              .is_using_neighborhood_area &&
              selectedNeighborhoods.length === 0)
          ) {
            toast.error(
              'Não foram incluídos bairros para entrega. Inclua e tente novamente',
              {
                autoClose: 6000,
              }
            );
            salva = false;
          }
        }
      }

      if (takeAwayActive) {
        if (takeAwayTimeAvgInputValue === '') {
          toast.error('Tempo médio para retirada dos pedidos não definido.', {
            autoClose: 6000,
          });
          salva = false;
        } else if (
          takeAwayTimeAvgInputValue < 0 ||
          takeAwayTimeAvgInputValue > 240
        ) {
          toast.error(
            'Tempo médio para retirada dos pedidos inválido. Deve ser entre 0-240 minutos.',
            {
              autoClose: 6000,
            }
          );
          salva = false;
        }
      }
    }

    return salva;
  };

  // Handle form submit for delivery / take away
  const handleStoreDeliveryTakeAwaySubmit = () => {
    const salva = validateForm();

    if (salva) {
      dispatch(
        updateStoreDeliveryTakeAwayRequest(
          orderMinimumAmount,
          onlyNumbersAmount(orderMinimumAmountInputValue),
          takeAwayActive,
          onlyNumbers(takeAwayTimeAvgInputValue),
          deliveryActive,
          onlyNumbers(maxDistInputValue),
          radiusArea,
          neighborhoodArea,
          deliveryFee,
          onlyNumbersAmount(deliveryFeeInputValue),
          dynamicDeliveryFee,
          onlyNumbersAmount(dynamicDeliveryFeeAmountInputValue),
          onlyNumbers(dynamicMaxDistApplyInputValue),
          onlyNumbersAmount(dynamicExceededFeeAmountInputValue),
          dynamicFreeDeliveryAtDistActiveInputValue,
          onlyNumbers(dynamicFreeDeliveryAtDistInputValue),
          dynamicFeeCalcTypeRouteInputValue,
          dynamicFeeCalcTypeRadiusInputValue,
          deliveryTimeAvg,
          onlyNumbers(deliveryTimeAvgInputValue)
        )
      );
    }
  };

  useEffect(() => {
    if ((!props.isOnboarding && deliveryTakeAwaySuccess) || (props.isOnboarding && updateOnboardingStepSuccess)) {
      setDeliveryChanges(false);
      dispatch(clearSuccess());
      if (props.onSuccess) {
        props.onSuccess();
      }
    }
  }, [deliveryTakeAwaySuccess, updateOnboardingStepSuccess, dispatch]);

  const isSaveButtonDisabled = () => {
    if (props.isStep) {
      return !props.isActive || deliveryTakeAwayLoading || updateOnboardingStepLoading;
    }
    return !deliveryChanges || deliveryTakeAwayLoading || updateOnboardingStepLoading;
  };

  const isSaveButtonActive = () => {
    if (props.isStep) {
      return props.isActive;
    }
    return deliveryChanges;
  };

  // Get localities data (state, city)
  useEffect(() => {
    if (neighborhoodArea || neighborhoodAreaModal) {
      dispatch(getLocalitiesRequest(newStateSelected));
      setDistrictsSelect([]);
    }
  }, [neighborhoodArea, neighborhoodAreaModal]);

  // Set localities at select input
  useEffect(() => {
    if (localitiesSuccess) {
      dispatch(clearSuccess());
      localitiesSelect.length = 0;
      localitiesSelect.push({
        id: '',
        title: 'Selecione a cidade',
      });
      localities.map((locality, idx) =>
        localitiesSelect.push({
          id: idx,
          title: `${locality.name}`,
        })
      );

      if (localitiesSelect.length === 1) {
        toast.error(
          'Para o estado selecionado, não existem cidades possíveis de serem utilizados. Escolha outro estado.',
          {
            autoClose: 6000,
          }
        );
      }
    }
  }, [localitiesSuccess]);

  // Load districts for selected locality
  useEffect(() => {
    if (districtsSuccess) {
      dispatch(clearSuccess());
      districtsSelect.length = 0;
      districtsSelect.push({
        id: '',
        title: 'Selecione o bairro',
      });
      districts.map((district, idx) =>
        districtsSelect.push({
          id: idx,
          title: `${district.name}`,
        })
      );

      if (districtsSelect.length === 1) {
        toast.error(
          'Para a cidade selecionada, não existem bairros possíveis de serem utilizados. Escolha outra cidade.',
          {
            autoClose: 6000,
          }
        );
      }
    }
  }, [districtsSuccess]);

  useEffect(() => {
    dispatch(clearSuccess());
    setNewDeliveryFee(null);
  }, [addNewNeighborhoodDataSuccess]);

  const handler = {
    deliveryTakeAwayLoading: deliveryTakeAwayLoading,
    deliveryActive: deliveryActive,
    deliveryTimeAvg: deliveryTimeAvg,
    deliveryTimeAvgInputValue: deliveryTimeAvgInputValue,
    radiusArea: radiusArea,
    neighborhoodArea: neighborhoodArea,
    maxDistInputValue: maxDistInputValue,
    deliveryFree: deliveryFree,
    deliveryFee: deliveryFee,
    deliveryFeeInputValue: deliveryFeeInputValue,
    dynamicDeliveryFee: dynamicDeliveryFee,
    dynamicDeliveryFeeAmountInputValue: dynamicDeliveryFeeAmountInputValue,
    dynamicMaxDistApplyInputValue: dynamicMaxDistApplyInputValue,
    dynamicExceededFeeAmountInputValue: dynamicExceededFeeAmountInputValue,
    dynamicFreeDeliveryAtDistInputValue: dynamicFreeDeliveryAtDistInputValue,
    dynamicFreeDeliveryAtDistActiveInputValue: dynamicFreeDeliveryAtDistActiveInputValue,
    dynamicFeeCalcTypeRouteInputValue: dynamicFeeCalcTypeRouteInputValue,
    dynamicFeeCalcTypeRadiusInputValue: dynamicFeeCalcTypeRadiusInputValue,
    takeAwayActive: takeAwayActive,
    takeAwayTimeAvgInputValue: takeAwayTimeAvgInputValue,
    orderMinimumAmount: orderMinimumAmount,
    orderMinimumAmountInputValue: orderMinimumAmountInputValue,
    neighborhoodAreaModal: neighborhoodAreaModal,
    dynamicDeliveryFeeModal: dynamicDeliveryFeeModal,
    newStateSelected: newStateSelected,
    localitiesSelect: localitiesSelect,
    districtsSelect: districtsSelect,
    localitiesLoading: localitiesLoading,
    localities: localities,
    newCitySelectedObj: newCitySelectedObj,
    districtsLoading: districtsLoading,
    districts: districts,
    newDeliveryFee: newDeliveryFee,
    addNewNeighborhoodDataLoading: addNewNeighborhoodDataLoading,
    newNeighborhoodSelectedObj: newNeighborhoodSelectedObj,
    isSaveButtonDisabled: () => isSaveButtonDisabled(),
    isSaveButtonActive: () => isSaveButtonActive(),
    setDeliveryChanges: (val) => setDeliveryChanges(val),
    setDeliveryTimeAvg: (val) => setDeliveryTimeAvg(val),
    setDeliveryTimeAvgInputValue: (val) => setDeliveryTimeAvgInputValue(val),
    setMaxDistInputValue: (val) => setMaxDistInputValue(val),
    setNeighborhoodAreaModal: (val) => setNeighborhoodAreaModal(val),
    setDeliveryFeeInputValue: (val) => setDeliveryFeeInputValue(val),
    setDynamicDeliveryFeeAmountInputValue: (val) => setDynamicDeliveryFeeAmountInputValue(val),
    setDynamicMaxDistApplyInputValue: (val) => setDynamicMaxDistApplyInputValue(val),
    setDynamicExceededFeeAmountInputValue: (val) => setDynamicExceededFeeAmountInputValue(val),
    setDynamicFreeDeliveryAtDistInputValue: (val) => setDynamicFreeDeliveryAtDistInputValue(val),
    setDynamicFreeDeliveryAtDistActiveInputValue: (val) => setDynamicFreeDeliveryAtDistActiveInputValue(val),
    setDynamicFeeCalcTypeRouteInputValue: (val) => setDynamicFeeCalcTypeRouteInputValue(val),
    setDynamicFeeCalcTypeRadiusInputValue: (val) => setDynamicFeeCalcTypeRadiusInputValue(val),
    setDynamicDeliveryFeeModal: (val) => setDynamicDeliveryFeeModal(val),
    setTakeAwayTimeAvgInputValue: (val) => setTakeAwayTimeAvgInputValue(val),
    setOrderMinimumAmount: (val) => setOrderMinimumAmount(val),
    setOrderMinimumAmountInputValue: (val) => setOrderMinimumAmountInputValue(val),
    setNewStateSelected: (val) => setNewStateSelected(val),
    setNewCitySelectedObj: (val) => setNewCitySelectedObj(val),
    setNewNeighborhoodSelectedObj: (val) => setNewNeighborhoodSelectedObj(val),
    setNewDeliveryFee: (val) => setNewDeliveryFee(val),
    setLocalitiesSelect: (val) => setLocalitiesSelect(val),
    setDistrictsSelect: (val) => setDistrictsSelect(val),
    handleDeliveryActive: () => handleDeliveryActive(),
    handleChangeDeliveryArea: (val) => handleChangeDeliveryArea(val),
    handleChangeDeliveryType: (val) => handleChangeDeliveryType(val),
    handleTakeAwayActive: () => handleTakeAwayActive(),
    handleStoreDeliveryTakeAwaySubmit: () => handleStoreDeliveryTakeAwaySubmit(),
    handleGetLocalitiesRequest: (val) => dispatch(getLocalitiesRequest(val)),
    handleGetDistrictsRequest: (val) => dispatch(getDistrictsRequest(val)),
    handleAddNewNeighborhoodRequest: (neighborhoodId, deliveryFee) => dispatch(addNewNeighborhoodRequest(neighborhoodId, deliveryFee)),
    handleCancelDynamicDeliveryFeeModal: () => handleCancelDynamicDeliveryFeeModal(),
    handleConfirmDynamicDeliveryFeeModal: () => handleConfirmDynamicDeliveryFeeModal(),
  }

  // MyStore openned through onboarding at mobile 
  if (props.isMobileOnboarding) {
    return <StoreDeliveryTakeAwayParamsOnboardingMobile handler={handler} />;
  } 
  // MyStore openned through onboarding or platform at web
  else {
    return (
      <ConfigGroup isStep={props.isStep} isActive={props.isActive}>
        <StoreForm onSubmit={handleStoreDeliveryTakeAwaySubmit}>
          <ConfigGroupContent backgroundColor={props.backgroundColor}>
            <Row>
              <Row justifyStart>
                <ConfigGroupTitle>
                  Defina as configurações de delivery / retirada
                </ConfigGroupTitle>
              </Row>
              <SaveButton
                id="myStoreDeliveryParamsSaveButton"
                hasChanges={isSaveButtonActive()}
                type="submit"
                disabled={isSaveButtonDisabled()}
              >
                {deliveryTakeAwayLoading || updateOnboardingStepLoading ? (
                  <LoadingBox>
                    <Spinner
                      style={{
                        width: '1.8rem',
                        height: '1.8rem',
                        color: preto,
                      }}
                    />
                  </LoadingBox>
                ) : (
                  <SaveButtonText hasChanges={isSaveButtonActive()}>
                    Salvar
                  </SaveButtonText>
                )}
              </SaveButton>
            </Row>
            <div id="myStoreDeliveryParams">
              <Row justifyStart margbottom={px2vw(10)} margtop={px2vw(10)}>
                <SwitchLabelBoldText>
                  Configurações do delivery
                </SwitchLabelBoldText>
              </Row>
              <Row
                justifyStart
                margtop={px2vw(10)}
                margbottom={px2vw(10)}
                margleft={px2vw(10)}
                minheight={px2vw(130)}
              >
                {/* Switch active delivery */}
                <SwitchMyStoreBox marginRight={px2vw(25)}>
                  <SwitchMyStoreBox marginBottom={px2vw(3)} margleft={px2vw(9)}>
                    <FormControlLabel
                      control={
                        <SwitchController
                          type="checkbox"
                          checked={deliveryActive}
                          onChange={() => {
                            handleDeliveryActive();
                          }}
                        />
                      }
                      label={
                        <SwitchLabelRegularText>
                          Ativar delivery
                        </SwitchLabelRegularText>
                      }
                    />
                  </SwitchMyStoreBox>
                </SwitchMyStoreBox>
                {/* Switch to define average delivery time */}
                <SwitchMyStoreBox marginRight={px2vw(25)}>
                  <SwitchWithInput
                    minHeight={px2vw(70)}
                    margleft={px2vw(9)}
                    flexDir="column"
                  >
                    <FormControlLabel
                      control={
                        <SwitchController
                          type="checkbox"
                          checked={deliveryActive && deliveryTimeAvg}
                          onChange={(e) => {
                            setDeliveryChanges(true);
                            setDeliveryTimeAvg(e.target.checked);
                          }}
                        />
                      }
                      label={
                        <SwitchLabelRegularText>
                          Tempo médio de entrega
                        </SwitchLabelRegularText>
                      }
                      disabled={!deliveryActive}
                    />
                    <SwitchWithInputBox
                      margleft={px2vw(-40)}
                      isVisible={deliveryActive && deliveryTimeAvg}
                    >
                      <Row alignCenter justifyStart margtop={px2vw(-5)}>
                        <SwitchInput
                          width={px2vw(50)}
                          name="deliveryTimeAvg"
                          placeholder="30"
                          maxLength="3"
                          value={deliveryTimeAvgInputValue}
                          onChange={(t) => {
                            setDeliveryChanges(true);
                            setDeliveryTimeAvgInputValue(
                              onlyNumbers(t.target.value)
                            );
                          }}
                        />
                        min
                      </Row>
                    </SwitchWithInputBox>
                  </SwitchWithInput>
                </SwitchMyStoreBox>
                {/* Switch to define delivery area */}
                <SwitchMyStoreBox marginRight={px2vw(25)}>
                  {/* Delivery area label */}
                  <SwitchLabelRegularText
                    marginBottom={px2vw(8)}
                    disabled={!deliveryActive}
                  >
                    Definição da área de entrega
                  </SwitchLabelRegularText>
                  {/* Delivery area by radius */}
                  <SwitchWithInput margleft={px2vw(9)}>
                    <FormControlLabel
                      control={
                        <SwitchController
                          type="checkbox"
                          checked={deliveryActive && radiusArea}
                          onChange={() => handleChangeDeliveryArea('radius_area')}
                        />
                      }
                      label={
                        <SwitchLabelRegularText width={px2vw(150)}>
                          Por raio de entrega máximo
                        </SwitchLabelRegularText>
                      }
                      disabled={!deliveryActive}
                    />
                    <SwitchWithInputBox isVisible={deliveryActive && radiusArea}>
                      <Row alignCenter justifyStart margtop={px2vw(-3)}>
                        <SwitchInput
                          name="maxDist"
                          maxLength="3"
                          placeholder="10"
                          value={maxDistInputValue}
                          onChange={(t) => {
                            setDeliveryChanges(true);
                            setMaxDistInputValue(onlyNumbers(t.target.value));
                          }}
                          disabled={!deliveryActive}
                        />
                        <SwitchLabelRegularTextBefore disabled={!deliveryActive}>
                          km
                        </SwitchLabelRegularTextBefore>
                      </Row>
                    </SwitchWithInputBox>
                  </SwitchWithInput>
                  {/* Delivery area by neighborhood */}
                  <SwitchMyStoreBox margtop={px2vw(-5)} margleft={px2vw(9)}>
                    <FormControlLabel
                      control={
                        <SwitchController
                          type="checkbox"
                          checked={deliveryActive && neighborhoodArea}
                          onChange={() => {
                            handleChangeDeliveryArea('neighborhood_area');
                            setNeighborhoodAreaModal(true);
                          }}
                        />
                      }
                      label={
                        <Row>
                          <SwitchLabelRegularText>
                            Por bairro
                          </SwitchLabelRegularText>
                          <ButtonImage
                            isVisible={deliveryActive && neighborhoodArea}
                            src={manageDynamicFeeImg}
                            margleft={px2vw(10)}
                          />
                        </Row>
                      }
                      disabled={!deliveryActive}
                    />
                  </SwitchMyStoreBox>
                </SwitchMyStoreBox>
                {/* Delivery area by radius - fee definitions */}
                <SwitchMyStoreBox
                  isNotVisible={
                    (deliveryActive && !radiusArea) ||
                    (!deliveryActive && radiusArea)
                  }
                >
                  {/* Delivery area by radius - fee definitions label */}
                  <SwitchLabelRegularText
                    marginBottom={px2vw(8)}
                    disabled={!deliveryActive}
                  >
                    Taxa de entrega
                  </SwitchLabelRegularText>
                  {/* Delivery area by radius - fee definitions free */}
                  <SwitchMyStoreBox
                    margtop={px2vw(10)}
                    marginBottom={px2vw(3)}
                    margleft={px2vw(9)}
                  >
                    <FormControlLabel
                      control={
                        <SwitchController
                          type="checkbox"
                          checked={deliveryActive && deliveryFree}
                          onChange={() => {
                            handleChangeDeliveryType('delivery_free');
                          }}
                        />
                      }
                      label={
                        <SwitchLabelRegularText>
                          Entrega grátis
                        </SwitchLabelRegularText>
                      }
                      disabled={!deliveryActive}
                    />
                  </SwitchMyStoreBox>
                  {/* Delivery area by radius - fee definitions unique */}
                  <SwitchWithInput margleft={px2vw(9)}>
                    <FormControlLabel
                      control={
                        <SwitchController
                          type="checkbox"
                          checked={deliveryActive && deliveryFee}
                          onChange={() =>
                            handleChangeDeliveryType('delivery_fee')
                          }
                        />
                      }
                      label={
                        <SwitchLabelRegularText
                          width={px2vw(140)}
                          margright={px2vw(-5)}
                        >
                          Taxa de entrega única
                        </SwitchLabelRegularText>
                      }
                      disabled={!deliveryActive}
                    />
                    <SwitchWithInputBox isVisible={deliveryActive && deliveryFee}>
                      <SwitchLabelRegularText>
                        <SwitchInput
                          width={px2vw(82)}
                          name="deliveryFee"
                          maxLength="9"
                          placeholder="R$ 18,00"
                          value={deliveryActive && deliveryFeeInputValue}
                          onChange={(t) => {
                            setDeliveryChanges(true);
                            setDeliveryFeeInputValue(amountMask(t.target.value));
                          }}
                        />
                      </SwitchLabelRegularText>
                    </SwitchWithInputBox>
                  </SwitchWithInput>
                  {/* Delivery area by radius - fee definitions dynamic */}
                  <SwitchMyStoreBox margtop={px2vw(-5)} margleft={px2vw(9)}>
                    <FormControlLabel
                      control={
                        <SwitchController
                          type="checkbox"
                          checked={deliveryActive && dynamicDeliveryFee}
                          onChange={() => {
                            handleChangeDeliveryType('dynamic_delivery_fee');
                            setDynamicDeliveryFeeAmountInputValue(
                              amountMask(dynamicDeliveryFeeAmountInputValue)
                            );
                            setDynamicMaxDistApplyInputValue(
                              dynamicMaxDistApplyInputValue
                            );
                            setDynamicExceededFeeAmountInputValue(
                              amountMask(dynamicExceededFeeAmountInputValue)
                            );
                            setDynamicFreeDeliveryAtDistInputValue(
                              dynamicFreeDeliveryAtDistInputValue
                            );
                            setDynamicFreeDeliveryAtDistActiveInputValue(
                              dynamicFreeDeliveryAtDistActiveInputValue
                            );
                            setDynamicFeeCalcTypeRouteInputValue(
                              dynamicFeeCalcTypeRouteInputValue
                            );
                            setDynamicFeeCalcTypeRadiusInputValue(
                              dynamicFeeCalcTypeRadiusInputValue
                            );

                            setDynamicDeliveryFeeModal(true);
                          }}
                        />
                      }
                      label={
                        <Row>
                          <SwitchLabelRegularText>
                            Taxa de entrega dinâmica
                          </SwitchLabelRegularText>
                          <ButtonImage
                            isVisible={deliveryActive && dynamicDeliveryFee}
                            src={manageDynamicFeeImg}
                            margleft={px2vw(10)}
                          />
                        </Row>
                      }
                      disabled={!deliveryActive}
                    />
                  </SwitchMyStoreBox>
                </SwitchMyStoreBox>
              </Row>
              <Row margtop={px2vw(40)}>
                {/* Takeaway configs */}
                <Col>
                  <Row justifyStart>
                    <SwitchLabelBoldText>
                      Configurações para retirada
                    </SwitchLabelBoldText>
                  </Row>
                  <Row
                    justifyStart
                    margtop={px2vw(10)}
                    margbottom={px2vw(10)}
                    margleft={px2vw(10)}
                  >
                    <SwitchMyStoreBox marginRight={px2vw(30)}>
                      <SwitchMyStoreBox
                        marginBottom={px2vw(3)}
                        margleft={px2vw(9)}
                      >
                        <FormControlLabel
                          control={
                            <SwitchController
                              type="checkbox"
                              checked={takeAwayActive}
                              onChange={() => {
                                handleTakeAwayActive();
                              }}
                            />
                          }
                          label={
                            <SwitchLabelRegularText width={px2vw(130)}>
                              Ativar retirada de pedidos na loja
                            </SwitchLabelRegularText>
                          }
                        />
                      </SwitchMyStoreBox>
                    </SwitchMyStoreBox>
                    <SwitchMyStoreBox marginRight={px2vw(40)}>
                      <SwitchLabelRegularText disabled={!takeAwayActive}>
                        Tempo médio para retirada dos pedidos
                      </SwitchLabelRegularText>
                      <Row alignCenter justifyStart margtop={px2vw(12)}>
                        <SwitchInput
                          width={px2vw(50)}
                          name="takeAwayTimeAvg"
                          placeholder="30"
                          maxLength="3"
                          value={takeAwayTimeAvgInputValue}
                          onChange={(t) => {
                            setDeliveryChanges(true);
                            setTakeAwayTimeAvgInputValue(
                              onlyNumbers(t.target.value)
                            );
                          }}
                          disabled={!takeAwayActive}
                        />
                        <SwitchLabelRegularTextBefore disabled={!takeAwayActive}>
                          min
                        </SwitchLabelRegularTextBefore>
                      </Row>
                    </SwitchMyStoreBox>
                  </Row>
                </Col>
                {/* Delivery / Takeaway configs */}
                <Col margleft={px2vw(150)}>
                  <Row justifyStart>
                    <SwitchLabelBoldText>
                      Configurações do delivery e retirada
                    </SwitchLabelBoldText>
                  </Row>
                  <Row
                    justifyStart
                    margtop={px2vw(10)}
                    margbottom={px2vw(-20)}
                    margleft={px2vw(10)}
                  >
                    <SwitchMyStoreBox>
                      <SwitchWithInput
                        minHeight={px2vw(70)}
                        margtop={px2vw(8)}
                        margleft={px2vw(9)}
                        flexDir="column"
                      >
                        <FormControlLabel
                          control={
                            <SwitchController
                              type="checkbox"
                              checked={orderMinimumAmount}
                              onChange={() => {
                                setDeliveryChanges(true);
                                setOrderMinimumAmount(!orderMinimumAmount);
                              }}
                            />
                          }
                          label={
                            <SwitchLabelRegularText>
                              Valor mínimo dos pedidos
                            </SwitchLabelRegularText>
                          }
                        />
                        <SwitchWithInputBox
                          margleft={px2vw(-32)}
                          isVisible={orderMinimumAmount}
                        >
                          <SwitchLabelRegularText>
                            <SwitchInput
                              width={px2vw(82)}
                              name="orderMinimumAmount"
                              maxLength="9"
                              placeholder="R$ 18,00"
                              value={orderMinimumAmountInputValue}
                              onChange={(t) => {
                                setDeliveryChanges(true);
                                setOrderMinimumAmountInputValue(
                                  amountMask(t.target.value)
                                );
                              }}
                            />
                          </SwitchLabelRegularText>
                        </SwitchWithInputBox>
                      </SwitchWithInput>
                    </SwitchMyStoreBox>
                  </Row>
                </Col>
              </Row>
            </div>
          </ConfigGroupContent>
        </StoreForm>
        {dynamicDeliveryFeeModal && (
          <Modal
            isVisible={dynamicDeliveryFeeModal}
            padding={px2vw(30)}
            width={px2vw(500)}
            height={px2vw(350)}
          >
            {/* Header */}
            <Header>
              <ModalTitleText>
                Configurações da taxa de entrega dinâmica
              </ModalTitleText>
              <CloseButton onClick={() => setDynamicDeliveryFeeModal(false)}>
                <CloseImage
                  src={require('../../../assets/images/close/close.png')}
                />
              </CloseButton>
            </Header>
            {/* Delivery fee amount */}
            <OptionRow justifyStart>
              <SwitchLabelRegularText>
                Defina o valor mínimo da taxa de entrega
              </SwitchLabelRegularText>
              <SwitchLabelRegularText>
                <SwitchInput
                  name="dynamicDeliveryFeeAmount"
                  width={px2vw(85)}
                  margright={px2vw(-6)}
                  maxLength="9"
                  placeholder="R$ 18,00"
                  value={dynamicDeliveryFeeAmountInputValue}
                  onChange={(t) => {
                    setDynamicDeliveryFeeAmountInputValue(
                      amountMask(t.target.value)
                    );
                  }}
                />
              </SwitchLabelRegularText>
            </OptionRow>
            {/* Max dist apply */}
            <OptionRow justifyStart>
              <SwitchLabelRegularText>
                Até qual distância o valor deve ser aplicado?
              </SwitchLabelRegularText>
              <SwitchLabelRegularText>
                <SwitchInput
                  name="dynamicMaxDistApply"
                  width={px2vw(60)}
                  maxLength="2"
                  placeholder="10"
                  value={dynamicMaxDistApplyInputValue}
                  onChange={(t) => {
                    setDynamicMaxDistApplyInputValue(onlyNumbers(t.target.value));
                  }}
                />
                km
              </SwitchLabelRegularText>
            </OptionRow>
            {/* Exceeded fee amount */}
            <OptionRow justifyStart>
              <SwitchLabelRegularText>
                Defina o valor por km excedente
              </SwitchLabelRegularText>
              <SwitchLabelRegularText>
                <SwitchInput
                  width={px2vw(85)}
                  margright={px2vw(-6)}
                  name="dynamicExceededFeeAmount"
                  maxLength="9"
                  placeholder="R$ 18,00"
                  value={dynamicExceededFeeAmountInputValue}
                  onChange={(t) => {
                    setDynamicExceededFeeAmountInputValue(
                      amountMask(t.target.value)
                    );
                  }}
                />
              </SwitchLabelRegularText>
            </OptionRow>
            {/* Free delivery at dist */}
            <OptionRow justifyStart>
              <SwitchWithInput marginBottom="0px">
                <FormControlLabel
                  control={
                    <SwitchController
                      type="checkbox"
                      checked={dynamicFreeDeliveryAtDistActiveInputValue}
                      onChange={() => {
                        setDynamicFreeDeliveryAtDistActiveInputValue(
                          !dynamicFreeDeliveryAtDistActiveInputValue
                        );
                        setDynamicFreeDeliveryAtDistInputValue('');
                      }}
                    />
                  }
                  label={
                    <Row width={px2vw(269)} justifyStart>
                      <SwitchLabelRegularText margtop={px2vw(2)}>
                        Grátis até distância determinada
                      </SwitchLabelRegularText>
                      <ButtonImage
                        isVisible
                        src={require('../../../assets/images/info_free_dist/info_free_dist.png')}
                        title="Aqui você pode incluir uma distância com entrega grátis sem interferir no preço dinâmico."
                      />
                    </Row>
                  }
                />
                <SwitchWithInputBox
                  isVisible={dynamicFreeDeliveryAtDistActiveInputValue}
                >
                  <SwitchLabelRegularText>
                    <Row alignCenter>
                      <SwitchInput
                        name="dynamicFreeDeliveryAtDist"
                        width={px2vw(60)}
                        maxLength="2"
                        placeholder="10"
                        value={dynamicFreeDeliveryAtDistInputValue}
                        onChange={(t) => {
                          setDynamicFreeDeliveryAtDistInputValue(
                            onlyNumbers(t.target.value)
                          );
                        }}
                      />
                      km
                    </Row>
                  </SwitchLabelRegularText>
                </SwitchWithInputBox>
              </SwitchWithInput>
            </OptionRow>
            {/* Dynamic delivery fee calculate way */}
            <Col justifyStart padleft={px2vw(20)} padright={px2vw(25)}>
              <SwitchLabelRegularText>
                Defina a base para o cálculo da taxa de entrega dinâmica:
              </SwitchLabelRegularText>
              <Row margtop={px2vw(5)} justifyStart>
                <Row
                  margleft={px2vw(50)}
                  justifyStart
                  alignCenter
                  width="auto"
                  onClick={() => {
                    setDynamicFeeCalcTypeRouteInputValue(true);
                    setDynamicFeeCalcTypeRadiusInputValue(false);
                  }}
                >
                  <CheckboxFeeCalcType>
                    <CheckboxFeeCalcTypeChecked
                      checked={dynamicFeeCalcTypeRouteInputValue}
                    />
                  </CheckboxFeeCalcType>
                  <SwitchLabelRegularText margtop={px2vw(2)} margright={px2vw(5)}>
                    Rota
                  </SwitchLabelRegularText>
                  <ButtonImage
                    isVisible
                    src={require('../../../assets/images/info_free_dist/info_free_dist.png')}
                    title="Será utilizada a km referente a rota (de carro/moto) mais curta entre o seu endereço e o endereço de entrega"
                  />
                </Row>
                <Row
                  margleft={px2vw(60)}
                  justifyStart
                  alignCenter
                  width="auto"
                  onClick={() => {
                    setDynamicFeeCalcTypeRouteInputValue(false);
                    setDynamicFeeCalcTypeRadiusInputValue(true);
                  }}
                >
                  <CheckboxFeeCalcType>
                    <CheckboxFeeCalcTypeChecked
                      checked={dynamicFeeCalcTypeRadiusInputValue}
                    />
                  </CheckboxFeeCalcType>
                  <SwitchLabelRegularText margtop={px2vw(2)} margright={px2vw(5)}>
                    Linha Reta
                  </SwitchLabelRegularText>
                  <ButtonImage
                    isVisible
                    src={require('../../../assets/images/info_free_dist/info_free_dist.png')}
                    title="Será utilizada a km referente a distância em linha reta entre o seu endereço e o endereço de entrega"
                  />
                </Row>
              </Row>
            </Col>

            <ModalActions justifyContent="space-around">
              <ModalSecundaryButton
                width={px2vw(150)}
                margtop={px2vw(30)}
                type="button"
                onClick={() => handleCancelDynamicDeliveryFeeModal()}
              >
                <ModalSecundaryButtonText>Cancelar</ModalSecundaryButtonText>
              </ModalSecundaryButton>
              <ModalPrimaryButton
                width={px2vw(150)}
                margtop={px2vw(30)}
                type="button"
                onClick={() => handleConfirmDynamicDeliveryFeeModal()}
              >
                <ModalPrimaryButtonText>Confirmar</ModalPrimaryButtonText>
              </ModalPrimaryButton>
            </ModalActions>
          </Modal>
        )}
        {neighborhoodAreaModal && (
          <Modal
            isVisible={neighborhoodAreaModal}
            padding={px2vw(30)}
            width={px2vw(900)}
            height={px2vw(550)}
          >
            <Header>
              <ModalTitleText>
                Configurações da área de entrega por bairro
              </ModalTitleText>
              <CloseButton
                onClick={() => {
                  setNeighborhoodAreaModal(false);
                  setLocalitiesSelect([]);
                  setDistrictsSelect([]);
                  setNewCitySelectedObj(null);
                  setNewNeighborhoodSelectedObj(null);
                  setNewDeliveryFee(null);
                }}
                disabled={addNewNeighborhoodDataLoading}
              >
                <CloseImage
                  src={require('../../../assets/images/close/close.png')}
                />
              </CloseButton>
            </Header>
            <FormNewNeighborhoodData>
              <Row margtop={px2vw(10)} spaceBetween>
                <Col width={px2vw(140)}>
                  <ModalInputLabelText>Estado</ModalInputLabelText>
                  <ModalSelectInput
                    id="newState"
                    name="newState"
                    width={px2vw(140)}
                    placeholder="Selecione o estado"
                    value={newStateSelected}
                    options={statesOptions}
                    onChange={(t) => {
                      // Set the current state selected
                      setNewStateSelected(t.target.value);
                      // Clear the localities select
                      localitiesSelect.length = 0;
                      localitiesSelect.push({
                        id: '',
                        title: 'Selecione a cidade',
                      });
                      // Clear the current locality selected
                      setNewCitySelectedObj(null);
                      // Clear the districts select
                      districtsSelect.length = 0;
                      districtsSelect.push({
                        id: '',
                        title: 'Selecione o bairro',
                      });
                      // Clear the current district selected
                      setNewNeighborhoodSelectedObj(null);
                      // Get all localities from the current state
                      dispatch(getLocalitiesRequest(t.target.value));
                    }}
                    disabled={localitiesLoading}
                  />
                </Col>
                <Col width={px2vw(200)}>
                  <ModalInputLabelText>Cidade</ModalInputLabelText>
                  <ModalSelectInput
                    id="newCity"
                    name="newCity"
                    width={px2vw(200)}
                    placeholder={
                      localitiesSelect.length === 1 &&
                      localitiesSelect[0].title === 'Selecione a cidade'
                        ? 'Sem cidades disponíveis'
                        : 'Selecione a cidade'
                    }
                    options={localitiesSelect}
                    onChange={(t) => {
                      // Set the current locality selected
                      setNewCitySelectedObj(
                        t.target.value !== '' ? localities[t.target.value] : null
                      );
                      // Clear the districts select
                      districtsSelect.length = 0;
                      districtsSelect.push({
                        id: '',
                        title: 'Selecione o bairro',
                      });
                      // Clear the current district selected
                      setNewNeighborhoodSelectedObj(null);
                      if (t.target.value !== '') {
                        // Get all districts from the current locality
                        dispatch(
                          getDistrictsRequest(localities[t.target.value].id)
                        );
                      }
                    }}
                    disabled={
                      localitiesLoading ||
                      (localitiesSelect.length === 1 &&
                        localitiesSelect[0].title === 'Selecione a cidade')
                    }
                  />
                </Col>
                <Col width={px2vw(260)}>
                  <ModalInputLabelText>Bairro</ModalInputLabelText>
                  <ModalSelectInput
                    id="newNeighborhood"
                    name="newNeighborhood"
                    width={px2vw(260)}
                    placeholder={
                      districtsSelect.length === 1 &&
                      districtsSelect[0].title === 'Selecione o bairro'
                        ? 'Sem bairros disponíveis'
                        : 'Selecione o bairro'
                    }
                    options={districtsSelect}
                    onChange={(t) => {
                      setNewNeighborhoodSelectedObj(districts[t.target.value]);
                    }}
                    disabled={
                      !newCitySelectedObj ||
                      districtsLoading ||
                      (districtsSelect.length === 1 &&
                        districtsSelect[0].title === 'Selecione o bairro')
                    }
                  />
                </Col>
                <Col width={px2vw(110)}>
                  <ModalInputLabelText>Taxa de entrega</ModalInputLabelText>
                  <ModalInput
                    name="newDeliveryFee"
                    width={px2vw(110)}
                    maxLength="9"
                    onChange={(t) => {
                      setNewDeliveryFee(amountMask(t.target.value));
                    }}
                    value={amountMask(newDeliveryFee)}
                    placeholder="R$ 4.50"
                  />
                </Col>
                <ModalPrimaryButton
                  margtop={px2vw(22)}
                  margleft={px2vw(10)}
                  width={px2vw(90)}
                  disabled={addNewNeighborhoodDataLoading}
                  type="button"
                  onClick={() => {
                    if (!newCitySelectedObj) {
                      toast.error('Não foi selecionada a cidade', {
                        autoClose: 6000,
                      });
                    } else if (!newNeighborhoodSelectedObj) {
                      toast.error('Não foi selecionado o bairro', {
                        autoClose: 6000,
                      });
                    } else if (!newDeliveryFee || newDeliveryFee.length < 7) {
                      toast.error('Taxa de entrega inválida', {
                        autoClose: 6000,
                      });
                    } else {
                      dispatch(
                        addNewNeighborhoodRequest(
                          newNeighborhoodSelectedObj.id,
                          onlyNumbersAmount(newDeliveryFee)
                        )
                      );
                    }
                  }}
                >
                  {addNewNeighborhoodDataLoading ? (
                    <LoadingBox>
                      <Spinner
                        style={{
                          width: '1.8rem',
                          height: '1.8rem',
                          color: preto,
                        }}
                      />{' '}
                    </LoadingBox>
                  ) : (
                    <ModalPrimaryButtonText>Incluir</ModalPrimaryButtonText>
                  )}
                </ModalPrimaryButton>
              </Row>
            </FormNewNeighborhoodData>
            <AdvertiserSelectedNeighborhoodsTable />
          </Modal>
        )}
      </ConfigGroup>
    );
  }
}

export default StoreDeliveryTakeAwayParams;
