import React from 'react';

import { Row, Col } from '../../../../../../styles/grid';
import px2vw from '../../../../../../utils/responsiveness/px2vw';

import {
  PageTitle,
  PageText,
  TermsLink,
  BlockedImage,
} from '../../../../styles';

import {
  PageTextCanceled, PageTextCanceled1, PageTextCanceled2
} from '../constants';
import { 
  BlockedAccessText1, 
  BlockedAccessText2, 
  PageTextTermsUseText1, 
  PageTextTermsUseText2 
} from '../../constants';

export default function HeaderCanceledSignatureWeb({ handler }) {
  return (
    <>
      <Row>
        <BlockedImage
          size={px2vw(230)}
          margtop="0"
          src={require('../../../../../../assets/images/blocked_signature/blocked_signature.png')}
        />
        <Col margleft={px2vw(40)} margtop={px2vw(5)}>
          <Row>
            <PageTitle fontsize={px2vw(70)}>
              {BlockedAccessText1}
            </PageTitle>
          </Row>
          <Row margleft={px2vw(5)}>
            <PageTitle fontsize={px2vw(70)}>
              {BlockedAccessText2}
            </PageTitle>
          </Row>
        </Col>
      </Row>
      <Row justifyStart margtop={px2vw(30)} margbottom={px2vw(20)}>
        <PageText fontsize={px2vw(50)}>
          {PageTextCanceled}<p />
          {PageTextCanceled1}<p />
          {PageTextCanceled2}
          <TermsLink to={handler.termsOfUseLink} target="_blank">
            {PageTextTermsUseText1}
          </TermsLink>
          {PageTextTermsUseText2}
        </PageText>
      </Row>
    </>
  );
}
