import React from 'react';
import styled from 'styled-components';
import px2vw from '../../../../utils/responsiveness/px2vw';
import { verdeKuppi10, verdeKuppi15 } from '../../../../styles/colors';
import { FaCheck } from 'react-icons/fa';

export const StepIcon = ({ step, done = true }) => {
  return (
    <StepIconContainer size={25} done={done}>
      {done ? <FaCheck /> : step}
    </StepIconContainer>
  );
};

const StepIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  font-weight: bold;
  text-align: center;
  width: 30px;
  height: 30px;
  min-width: ${({ size }) => px2vw(size || 7)};
  min-height: ${({ size }) => px2vw(size || 7)};
  font-size: ${px2vw(15)};
  border-radius: 999px;
  border: ${({ done }) => (done ? '0px' : '2px')} solid black;
  background-color: ${({ done }) => (!done ? verdeKuppi15 : verdeKuppi10)};
`;
