import React, { useState, useEffect, useMemo } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { Spinner } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Icon } from 'react-materialize';
import moment from 'moment';
import { Form } from '@rocketseat/unform';
import { toast } from 'react-toastify';

import { Col, Row } from '../../../styles/grid';
import '../../../styles/react-datepicker.css';
import ptBR from 'date-fns/locale/pt-BR';
import {
  ContentPage,
  ErrorText,
  LoadingBox,
} from '../../_layouts/styles/common';
import {
  AIButton,
  AIRecomendationTextBox,
  ContainerForImg,
  DateDiv,
  FilterButton,
  FilterButtonText,
  OrderReportFormBox,
  OrdersReportBox,
  SelectInputBox,
  TextBold,
  TextFormBold,
  TextFormRegular,
  TextRegular,
} from './styles';
import BarChart from '../../../components/BarChart';
import px2vw from '../../../utils/responsiveness/px2vw';
import {
  preto,
  verdeKuppi1,
  cinzaKuppi7,
  amareloKuppi2,
  azulKuppi4,
  roxoKuppi2,
} from '../../../styles/colors';
import DoughnutChart from '../../../components/DoughnutChart';
import TopSoldProductsTable from './TopSoldProductsTable';
import OrderPerDistrictsTable from './OrderPerDistrictsTable';
import { clearReportErrors, getOrdersReportRequest } from '../../../store/modules/reports/actions';
import {
  getPromptOrdersPerDayAndPerHourRequest,
  getPromptOrderTypesRequest,
  getPromptTopSoldProductsRequest,
  getPromptOrdersPerNeighborhoodRequest
} from '../../../store/modules/insightsAI/actions';

export default function OrdersReport() {
  const orderPerWeekDay = useSelector((state) => state.reports.orderPerWeekDay);
  const orderPerHour = useSelector((state) => state.reports.orderPerHour);
  const orderTypes = useSelector((state) => state.reports.orderTypes);
  const topSoldProducts = useSelector((state) => state.reports.topSoldProducts);
  const paymentMethodsTotals = useSelector(
    (state) => state.reports.paymentMethodsTotals
  );
  const ordersPerNeighborhood = useSelector(
    (state) => state.reports.ordersPerNeighborhood
  );
  const promptOrdersPerDayAndPerHourLoading = useSelector(
    (state) => state.insightsAi.promptOrdersPerDayAndPerHourLoading
  );
  const promptOrdersPerDayAndPerHourResponse = useSelector(
    (state) => state.insightsAi.promptOrdersPerDayAndPerHourResponse
  );
  const promptOrderTypesLoading = useSelector(
    (state) => state.insightsAi.promptOrderTypesLoading
  );
  const promptOrderTypesResponse = useSelector(
    (state) => state.insightsAi.promptOrderTypesResponse
  );
  const promptTopSoldProductsLoading = useSelector(
    (state) => state.insightsAi.promptTopSoldProductsLoading
  );
  const promptTopSoldProductsResponse = useSelector(
    (state) => state.insightsAi.promptTopSoldProductsResponse
  );
  const promptOrdersPerNeighborhoodLoading = useSelector(
    (state) => state.insightsAi.promptOrdersPerNeighborhoodLoading
  );
  const promptOrdersPerNeighborhoodResponse = useSelector(
    (state) => state.insightsAi.promptOrdersPerNeighborhoodResponse
  );
  const { advertiserCategory } = useSelector(
    (state) => state.user.user.advertiserInfo
  );
  const ordersLoading = useSelector((state) => state.reports.ordersLoading);
  const ordersError = useSelector((state) => state.reports.ordersFailure);
  registerLocale('pt-BR', ptBR);
  const [startDate, setStartDate] = useState(moment(new Date()).toDate());
  const [endDate, setEndDate] = useState(moment(new Date()).toDate());
  const [daysInterval, setDaysInterval] = useState('0');
  const [selectOptions, setSelecOptions] = useState([
    { id: 0, title: 'Selecione um período' },
    { id: 1, title: 'Últimos 30 dias' },
    { id: 2, title: 'Últimos 15 dias' },
    { id: 3, title: 'Últimos 7 dias' },
  ]);
  const [dataPerDay, setDataPerDay] = useState({
    labels: [],
    datasets: [
      {
        label: 'Pedidos por dia',
        data: [],
        backgroundColor: verdeKuppi1,
      },
    ],
  });
  const [bestDayForSales, setBestDayForSales] = useState('');
  const [bestHourForSales, setBestHourForSales] = useState('');
  const [totalOrders, setTotalOrders] = useState('');
  const [dataPerHour, setDataPerHour] = useState({
    labels: [],
    datasets: [
      {
        label: 'Pedidos por hora',
        data: [],
        backgroundColor: verdeKuppi1,
      },
    ],
  });
  const [dataPerOrderType, setDataPerOrderType] = useState({
    datasets: [
      {
        data: [0, 0, 0],
        backgroundColor: [verdeKuppi1, amareloKuppi2, roxoKuppi2],
        hoverBackgroundColor: [verdeKuppi1, amareloKuppi2, roxoKuppi2],
        borderWidth: 0,
      },
    ],
  });
  const [legendsForChartData, setLegendsForChartData] = useState([
    {
      name: 'Vendas Cardápio Web',
      ordersQty: 0,
      color: verdeKuppi1,
    },
    {
      name: 'Vendas Pedido Manual',
      ordersQty: 0,
      color: amareloKuppi2,
    },
    {
      name: 'Vendas bot',
      ordersQty: 0,
      color: roxoKuppi2,
    },
  ]);
  const [dataPerOrderTypeChartTwo, setDataPerOrderTypeChartTwo] = useState({
    datasets: [
      {
        data: [0, 0, 0, 0],
        backgroundColor: [verdeKuppi1, amareloKuppi2, roxoKuppi2, azulKuppi4],
        hoverBackgroundColor: [
          verdeKuppi1,
          amareloKuppi2,
          roxoKuppi2,
          azulKuppi4,
        ],
        borderWidth: 0,
      },
    ],
  });
  const [legendsForChartDataChartTwo, setLegendsForChartDataChartTwo] =
    useState([
      {
        name: 'Vendas Kuppi Boost',
        ordersQty: 0,
        color: verdeKuppi1,
      },
      {
        name: 'Vendas Chatbot',
        ordersQty: 0,
        color: amareloKuppi2,
      },
      {
        name: 'Vendas Disparo em Massa',
        ordersQty: 0,
        color: roxoKuppi2,
      },
      {
        name: 'Vendas Orgânicas',
        ordersQty: 0,
        color: azulKuppi4,
      },
    ]);
  const [topSoldProductsData, setTopSoldProductsData] = useState([]);
  const [ordersPerNeighborhoodData, setOrdersPerNeighborhoodData] = useState(
    []
  );
  const weekDays = {
    Sun: {
      name: 'Domingo',
      qty: 0,
    },
    Mon: {
      name: 'Segunda-feira',
      qty: 0,
    },
    Tue: {
      name: 'Terça-feira',
      qty: 0,
    },
    Wed: {
      name: 'Quarta-feira',
      qty: 0,
    },
    Thu: {
      name: 'Quinta-feira',
      qty: 0,
    },
    Fri: {
      name: 'Sexta-feira',
      qty: 0,
    },
    Sat: {
      name: 'Sábado',
      qty: 0,
    },
  };
  const orderTypeNames = {
    qty_web_menu_orders: 'Pedido Cardápio Web',
    qty_manual_orders: 'Pedido Manual (Criados manualmente na plataforma)',
    qty_organic_orders: 'Pedido Orgânico (que chegaram por alguma outra forma sem ser por disparo ou tráfego pago)',
    qty_mass_message_orders: 'Pedido Disparo em Massa no WhatsApp',
    qty_boost_orders: 'Pedido Kuppi Boost (Tráfego Pago)'
  };
  const dispatch = useDispatch();

  useEffect(() =>  {
    dispatch(clearReportErrors());
  }, []);

  // sets the url list with the urls of the cards images
  const xAxisImagesUrls = useMemo(() => {
    if (paymentMethodsTotals.length > 0) {
      const cardInfoData = paymentMethodsTotals
        .map((paymentMethod) => {
          if (
            paymentMethod?.credit?.orders_amount ||
            paymentMethod?.debit?.orders_amount ||
            paymentMethod?.voucher?.orders_amount
          ) {
            return paymentMethod.image;
          }
        })
        .filter((item) => item);

      return cardInfoData;
    }

    return [];
  }, [paymentMethodsTotals]);

  const xAxisImagesUrlsForPaymentMethodChart = useMemo(() => {
    if (paymentMethodsTotals.length > 0) {
      const cardInfoData = paymentMethodsTotals
        .map((paymentMethod) => {
          if (
            paymentMethod?.credit?.orders_amount ||
            paymentMethod?.debit?.orders_amount ||
            paymentMethod?.voucher?.orders_amount
          ) {
            return {
              brand: paymentMethod.brand,
              creditAmount: parseFloat(
                paymentMethod?.credit?.orders_amount ?? 0
              ),
              debitAmount: parseFloat(
                (paymentMethod?.debit?.orders_amount ||
                  paymentMethod?.voucher?.orders_amount) ??
                  0
              ),
            };
          }
        })
        .filter((item) => item);
      const creditPaymentsList = cardInfoData.map(
        (paymentMethod) => paymentMethod.creditAmount
      );
      const debitPaymentsList = cardInfoData.map(
        (paymentMethod) => paymentMethod.debitAmount
      );

      return {
        labels: cardInfoData.map((item) => item.brand),
        datasets: [
          {
            label: 'Pagamento em crédito',
            data: creditPaymentsList,
            backgroundColor: verdeKuppi1,
          },
          {
            label: 'Pagamento em débito',
            data: debitPaymentsList,
            backgroundColor: cinzaKuppi7,
          },
        ],
      };
    }

    return {
      labels: [],
      datasets: [
        {
          label: 'Pagamento em crédito',
          data: [],
          backgroundColor: verdeKuppi1,
        },
        {
          label: 'Pagamento em débito',
          data: [],
          backgroundColor: cinzaKuppi7,
        },
      ],
    };
  }, [paymentMethodsTotals]);

  // Sets the key for reducer, to save the response of the AI, so when the advertiser requests the same date
  // to the AI coments, the response will be the same as shown before and there will be no request to the AI API
  const responseKey = useMemo(() => {
    return `${moment(startDate).format('DD/MM/YYYY')}_${moment(endDate).format(
      'DD/MM/YYYY'
    )}`;
  }, [startDate, endDate]);

  // mount the text to send to the AI for orders per day charts
  const ordersPerDay = useMemo(() => {
    if (orderPerWeekDay && orderPerWeekDay?.length > 0) {
      orderPerWeekDay.forEach((day) => {
        weekDays[day.week_day_name].qty = day.qty_order;
      });

      const weekDayText = Object.values(weekDays)
        .map((day) => {
          return `${day.name}: ${day.qty}`;
        })
        .join('\n');

      return `${weekDayText}`;
    }

    return '';
  }, [orderPerWeekDay]);

  // mount the text to send to the AI for orders per hour charts
  const ordersPerHour = useMemo(() => {
    if (orderPerHour.length > 0) {
      const perHours = orderPerHour
        .map((hour) => {
          return `${hour.hour}:00 -> ${hour.qty_order}`;
        })
        .join('\n');

      return `${perHours}`;
    }

    return '';
  }, [orderPerHour]);

  // mount the text to send to the AI for orders origins and types charts
  const promptOrderTypes = useMemo(() => {
    if (parseInt(orderTypes?.chartOne?.qty_orders_total) > 0) {
      const orderTypesArr = Object.keys(orderTypes?.chartOne)
        .map((item) => {
          if (item !== 'qty_orders_total') {
            return `${orderTypeNames[item]}: ${orderTypes?.chartOne[item]}%`;
          }
        })
        .join('\n');

      return `${orderTypesArr}`;
    }
  }, [orderTypes]);

  // mount the text to send to the AI for orders origins and types charts
  const promptOrderOrigin = useMemo(() => {
    if (parseInt(orderTypes?.chartTwo?.qty_orders_total) > 0) {
      const orderOriginsArr = Object.keys(orderTypes?.chartTwo)
        .map((item) => {
          if (item !== 'qty_orders_total') {
            return `${orderTypeNames[item]}: ${orderTypes?.chartTwo[item]}%`;
          }
        })
        .join('\n');

      return `${orderOriginsArr}`;
    }
  }, [orderTypes]);

  // mount the text to send to the AI for top sold products
  const promptTopSoldProducts = useMemo(() => {
    if (topSoldProducts.length > 0) {
      let arrProducts = [];
      for (let i = 0; i < topSoldProducts.length; i++) {
        if (i === 10) {
          break;
        } else {
          arrProducts.push(
            `${i + 1} - ${topSoldProducts[i].product_name}; Qtd de vendas: ${
              topSoldProducts[i].total_sold
            }; Ticket Médio: R$ ${(topSoldProducts[i].total_billed === null
              ? 0
              : topSoldProducts[i].total_billed /
                parseInt(topSoldProducts[i].total_sold)
            ).toFixed(2)}; Total Faturado: R$ ${
              topSoldProducts[i].total_billed === null
                ? 0
                : topSoldProducts[i].total_billed.toFixed(2)
            };\n`
          );
        }
      }

      let arrProductsString = arrProducts.join('');

      return `${arrProductsString}`;
    }
  }, [topSoldProducts]);

  // mount the text to send to the AI for orders per neighborhood
  const promptOrdersPerNeighborhood = useMemo(() => {
    if (ordersPerNeighborhood.length > 0) {
      let arrProducts = [];
      for (let i = 0; i < ordersPerNeighborhood.length; i++) {
        if (i === 10) {
          break;
        } else {
          arrProducts.push(
            `${i + 1} - ${
              ordersPerNeighborhood[i].neighborhood
            }; Qtd de vendas: ${
              ordersPerNeighborhood[i].qty_orders
            }; Ticket Médio: R$ ${(ordersPerNeighborhood[i].total_sold === null
              ? 0
              : parseInt(ordersPerNeighborhood[i].total_sold) /
                parseInt(ordersPerNeighborhood[i].qty_orders)
            ).toFixed(2)}; Total Faturado: R$ ${
              ordersPerNeighborhood[i].total_sold === null
                ? 0
                : parseInt(ordersPerNeighborhood[i].total_sold).toFixed(2)
            };\n`
          );
        }
      }
      let arrProductsString = arrProducts.join('');

      return `${arrProductsString}`;
    }
  }, [ordersPerNeighborhood]);

  // Sets the inputs of startDate and endDate according to the daysInterval input value
  useEffect(() => {
    let today = moment(new Date());
    if (daysInterval !== '0') {
      setEndDate(moment(today.subtract(1, 'days')).toDate());
    }

    if (daysInterval === '1') {
      setStartDate(moment(today.subtract(30, 'days')).toDate());
    } else if (daysInterval === '2') {
      setStartDate(moment(today.subtract(15, 'days')).toDate());
    } else if (daysInterval === '3') {
      setStartDate(moment(today.subtract(7, 'days')).toDate());
    }
  }, [daysInterval]);

  // Sets the arrays for dataPerDay, dataPerHour, topSoldProducts and ordersPerNeighborhood
  useEffect(() => {
    function returnIndexOfGreatestValue(arr) {
      let major = arr[0];
      let idx = 0;
      for (let i = 0; i < arr.length; i++) {
        if (arr[i] > major) {
          major = arr[i];
          idx = i;
        }
      }
      return idx;
    }

    if (orderPerWeekDay && orderPerWeekDay?.length > 0) {
      let days = [];
      let qtyOrder = [];

      orderPerWeekDay.map((day) => {
        qtyOrder.push(parseInt(day.qty_order));
        if (day.week_day_name === 'Sun') {
          day = 'Dom';
          days.push(day);
        } else if (day.week_day_name === 'Mon') {
          day = 'Seg';
          days.push(day);
        } else if (day.week_day_name === 'Tue') {
          day = 'Ter';
          days.push(day);
        } else if (day.week_day_name === 'Wed') {
          day = 'Qua';
          days.push(day);
        } else if (day.week_day_name === 'Thu') {
          day = 'Qui';
          days.push(day);
        } else if (day.week_day_name === 'Fri') {
          day = 'Sex';
          days.push(day);
        } else {
          day = 'Sab';
          days.push(day);
        }
      });

      // Sets the day by the key in weekDays object
      setBestDayForSales(
        weekDays[
          orderPerWeekDay[returnIndexOfGreatestValue(qtyOrder)].week_day_name
        ].name
      );
      setTotalOrders(qtyOrder.reduce((sum, value) => sum + value, 0));
      setDataPerDay({
        labels: days,
        datasets: [
          {
            label: 'Pedidos por dia',
            data: qtyOrder,
            backgroundColor: verdeKuppi1,
          },
        ],
      });
    }

    if (orderPerHour.length > 0) {
      let hoursArr = [];
      let qtyOrder = [];
      orderPerHour.map((hour) => {
        hoursArr.push(`${hour.hour}h`);
        qtyOrder.push(parseInt(hour.qty_order));
      });

      setBestHourForSales(hoursArr[returnIndexOfGreatestValue(qtyOrder)]);
      setDataPerHour({
        labels: hoursArr,
        datasets: [
          {
            label: 'Pedidos por dia',
            data: qtyOrder,
            backgroundColor: verdeKuppi1,
          },
        ],
      });
    }

    if (
      parseInt(orderTypes?.chartOne?.qty_orders_total) > 0 ||
      parseInt(orderTypes?.chartTwo?.qty_orders_total) > 0
    ) {
      setLegendsForChartData([
        {
          name: 'Vendas Cardápio Web',
          ordersQty: parseFloat(orderTypes.chartOne.qty_web_menu_orders),
          color: verdeKuppi1,
        },
        {
          name: 'Vendas Pedido Manual',
          ordersQty: parseFloat(orderTypes.chartOne.qty_manual_orders),
          color: amareloKuppi2,
        },
      ]);

      setDataPerOrderType({
        datasets: [
          {
            data: [
              parseFloat(orderTypes.chartOne.qty_web_menu_orders),
              parseFloat(orderTypes.chartOne.qty_manual_orders),
            ],
            backgroundColor: [verdeKuppi1, amareloKuppi2],
            hoverBackgroundColor: [verdeKuppi1, amareloKuppi2],
            borderWidth: 0,
          },
        ],
      });

      setLegendsForChartDataChartTwo([
        {
          name: 'Vendas Kuppi Boost',
          ordersQty: parseFloat(orderTypes.chartTwo.qty_boost_orders),
          color: verdeKuppi1,
        },
        {
          name: 'Vendas Disparo em Massa',
          ordersQty: parseFloat(orderTypes.chartTwo.qty_mass_message_orders),
          color: amareloKuppi2,
        },
        {
          name: 'Vendas Orgânicas',
          ordersQty: parseFloat(orderTypes.chartTwo.qty_organic_orders),
          color: azulKuppi4,
        },
      ]);

      setDataPerOrderTypeChartTwo({
        datasets: [
          {
            data: [
              parseFloat(orderTypes.chartTwo.qty_boost_orders),
              parseFloat(orderTypes.chartTwo.qty_mass_message_orders),
              parseFloat(orderTypes.chartTwo.qty_organic_orders)
            ],
            backgroundColor: [
              verdeKuppi1,
              amareloKuppi2,
              azulKuppi4,
            ],
            hoverBackgroundColor: [
              verdeKuppi1,
              amareloKuppi2,
              azulKuppi4,
            ],
            borderWidth: 0,
          },
        ],
      });
    }

    if (topSoldProducts.length > 0) {
      setTopSoldProductsData(
        topSoldProducts.map((product) => {
          return {
            name: product.product_name,
            amount_of_sales: parseInt(product.total_sold),
            average_ticket:
              product.total_billed !== null
                ? product.total_billed.toFixed(2) / parseInt(product.total_sold)
                : 0,
            total_billed:
              product.total_billed !== null
                ? product.total_billed.toFixed(2)
                : 0,
          };
        })
      );
    }

    if (ordersPerNeighborhood.length > 0) {
      setOrdersPerNeighborhoodData(
        ordersPerNeighborhood.map((neighborhood) => {
          return {
            district_name: neighborhood.neighborhood,
            total_orders: parseInt(neighborhood.qty_orders),
            average_ticket:
              parseInt(neighborhood.total_sold) /
              parseInt(neighborhood.qty_orders),
            total_billing: parseInt(neighborhood.total_sold),
          };
        })
      );
    }
  }, [
    orderPerWeekDay,
    orderPerHour,
    orderTypes,
    topSoldProducts,
    ordersPerNeighborhood,
  ]);

  function handleSubmit() {
    if (startDate === null) {
      toast.error('Data de início é obrigatória.', {
        autoClose: 6000,
      });

      return false;
    }

    if (endDate === null) {
      toast.error('Data de fim é obrigatória.', {
        autoClose: 6000,
      });

      return false;
    }

    // Start Date - DDMMAAAA
    const startDateDay = startDate.getDate().toString();
    const startDateMonth = (startDate.getMonth() + 1).toString();
    const startDateYear = startDate.getFullYear();

    const newStartDate = new Date(
      `${startDateYear}-${startDateMonth}-${startDateDay}`
    );
    try {
      newStartDate.toISOString();
    } catch (e) {
      toast.error('Data de início inválida.', {
        autoClose: 6000,
      });

      return false;
    }

    // End Date - DDMMAAAA
    const endDateDay = endDate.getDate().toString();
    const endDateMonth = (endDate.getMonth() + 1).toString();
    const endDateYear = endDate.getFullYear();

    const newEndDate = new Date(`${endDateYear}-${endDateMonth}-${endDateDay}`);
    try {
      newEndDate.toISOString();
    } catch (e) {
      toast.error('Data de fim inválida.', {
        autoClose: 6000,
      });

      return false;
    }

    if (newEndDate < newStartDate) {
      toast.error('Data de fim não pode ser menor que a data de início.', {
        autoClose: 6000,
      });

      return false;
    }

    if (newEndDate > new Date()) {
      toast.error('Data de fim não pode ser maior que a data atual.', {
        autoClose: 6000,
      });

      return false;
    }

    const diffStartDates = moment(newStartDate)
    const diffEndDates = moment(newEndDate);
    if (diffEndDates.diff(diffStartDates, 'days') > 31) {
      toast.error('Intervalo de pesquisa não pode ser maior que 31 dias.', {
        autoClose: 6000,
      });

      return false;
    }

    dispatch(
      getOrdersReportRequest(
        `${startDateYear}-${startDateMonth}-${startDateDay}`,
        `${endDateYear}-${endDateMonth}-${endDateDay}`
      )
    );
  }

  function SplitTextIntoNewLines(text) {
    return text?.split('\n\n').map(str => <p>{str}</p>);
  }

  const contentPage = (
    <>
      <OrderReportFormBox>
        <TextFormBold>Escolha o período que deseja visualizar</TextFormBold>
        <Form onSubmit={handleSubmit}>
          <Row justifyStart width="auto" margtop={px2vw(20)}>
            <Col alignStart width="auto" margright={px2vw(10)}>
              <SelectInputBox
                value={daysInterval}
                name="daysInterval"
                options={selectOptions}
                onChange={(t) => setDaysInterval(t.target.value)}
              />
            </Col>
            <Col width="auto" alignStart>
              <DateDiv>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={startDate}
                  onChange={(date) => {
                    setStartDate(date);
                    setDaysInterval('0');
                  }}
                  locale="pt-BR"
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  className="date"
                  onChangeRaw={(e) => {
                    e.preventDefault();
                  }}
                />
              </DateDiv>
            </Col>
            <Row
              width="auto"
              margtop={px2vw(4)}
              margleft={px2vw(8)}
              margright={px2vw(8)}
            >
              <TextFormRegular>até</TextFormRegular>
            </Row>
            <Col width="auto" alignStart>
              <DateDiv>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={endDate}
                  onChange={(date) => {
                    setEndDate(date);
                    setDaysInterval('0');
                  }}
                  locale="pt-BR"
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  className="date"
                  onChangeRaw={(e) => {
                    e.preventDefault();
                  }}
                />
              </DateDiv>
            </Col>
            <FilterButton type="submit" disabled={ordersLoading}>
              {ordersLoading ? (
                <LoadingBox>
                  <Spinner
                    style={{
                      width: '1.4rem',
                      height: '1.4rem',
                      color: preto,
                    }}
                  />
                </LoadingBox>
              ) : (
                <FilterButtonText>
                  Filtrar
                </FilterButtonText>
              )}
            </FilterButton>
          </Row>
        </Form>
      </OrderReportFormBox>
      {orderPerWeekDay?.length === 0 && (
        <Col alignCenter height="60vh">
          <Row height="auto" alignCenter justifyCenter>
            {/* TODO: Ajustar tamanho do icone */}
            <Icon large>sentiment_very_dissatisfied</Icon>
            <ErrorText>Não existem vendas para o período solicitado.</ErrorText>
          </Row>
        </Col>
      )}
      {orderPerWeekDay?.length > 0 && (
        <>
          {/* Orders per week/ Orders per hour */}
          <OrdersReportBox>
            <Row spaceBetween padright={px2vw(50)}>
              <Col width="auto" height="auto" margbottom={px2vw(20)}>
                <TextBold>Pedidos por dia da semana</TextBold>
                <TextRegular>Melhor dia para vendas</TextRegular>
                <TextBold ColorGreen>{bestDayForSales}</TextBold>
                <TextRegular fontSize={px2vw(9)}>
                  {totalOrders} pedidos no total
                </TextRegular>
                <BarChart chartData={dataPerDay} chartWidth={px2vw(425)} />
              </Col>

              <Col width="auto" height="auto" margbottom={px2vw(20)}>
                <TextBold>Pedidos por hora do dia</TextBold>
                <TextRegular>Melhores horários</TextRegular>
                <TextBold ColorGreen>{`${bestHourForSales}`}</TextBold>
                <TextRegular fontSize={px2vw(9)}>
                  {totalOrders} pedidos no total
                </TextRegular>
                <BarChart chartData={dataPerHour} chartWidth={px2vw(425)} />
              </Col>
            </Row>
            <Row
              margtop={px2vw(10)}
              margbottom={px2vw(10)}
              spaceBetween
              padright={px2vw(50)}
            >
              <TextBold>Recomendações e Insights</TextBold>
              <AIButton
                onClick={
                  () => {
                    if (promptOrdersPerDayAndPerHourResponse?.[
                      `ordersPerDay-${responseKey}`
                    ]) {
                      toast.error('Recomendação de IA já gerada para esse período')
                    } else {
                      dispatch(
                        getPromptOrdersPerDayAndPerHourRequest(
                          `ordersPerDay-${responseKey}`,
                          advertiserCategory.name,
                          ordersPerDay,
                          ordersPerHour
                        )
                      )
                    }
                  }
                }
              >
                {promptOrdersPerDayAndPerHourLoading ? (
                  <LoadingBox>
                    <Spinner
                      style={{
                        width: '1.4rem',
                        height: '1.4rem',
                        color: preto,
                      }}
                    />
                  </LoadingBox>
                ) : (
                  <>
                    <ContainerForImg width={px2vw(20)} height={px2vw(15)}>
                      <img
                        src={require('../../../assets/images/A_I/A_I@2x.png')}
                        alt="A I Button Icon"
                        width="100%"
                        height="100%"
                      />
                    </ContainerForImg>
                    <TextRegular>Gerar Recomendação com IA</TextRegular>
                  </>
                )}
              </AIButton>
            </Row>
            <Col padright={px2vw(50)}>
              <AIRecomendationTextBox
                showBox={
                  promptOrdersPerDayAndPerHourResponse?.[
                    `ordersPerDay-${responseKey}`
                  ]
                }
              >
                <Row justifyEnd>
                  <Row width="auto" margtop={px2vw(5)}>
                    <TextRegular>
                      Gerados via Inteligencia Artificial +
                    </TextRegular>
                    <Col
                      width="auto"
                      heigth="auto"
                      margleft={px2vw(5)}
                      margbottom={px2vw(-2)}
                    >
                      <TextBold fontSize={px2vw(14)}>OpenAI</TextBold>
                    </Col>
                  </Row>
                </Row>

                <Col margtop={px2vw(10)}>
                  <TextRegular>
                    {SplitTextIntoNewLines(promptOrdersPerDayAndPerHourResponse?.[`ordersPerDay-${responseKey}`])}
                  </TextRegular>
                </Col>
              </AIRecomendationTextBox>
            </Col>
          </OrdersReportBox>

          {/* OrderType */}
          <OrdersReportBox>
            <Row spaceBetween padright={px2vw(50)}>
              <Col width="auto" height="auto" margbottom={px2vw(20)}>
                <TextBold>Tipo dos Pedidos</TextBold>
                <DoughnutChart
                  needMargTop
                  chartData={dataPerOrderType}
                  chartWidth={px2vw(225)}
                  chartLegends={legendsForChartData}
                />
              </Col>

              <Col width="auto" height="auto" margbottom={px2vw(20)}>
                <TextBold>Origem dos Pedidos</TextBold>
                <DoughnutChart
                  chartData={dataPerOrderTypeChartTwo}
                  chartWidth={px2vw(225)}
                  chartLegends={legendsForChartDataChartTwo}
                />
              </Col>
            </Row>

            <Row
              margtop={px2vw(10)}
              margbottom={px2vw(10)}
              spaceBetween
              padright={px2vw(50)}
            >
              <TextBold>Recomendações e Insights</TextBold>
              <AIButton
                onClick={
                  () => {
                    if (promptOrderTypesResponse?.[
                      `ordersType-${responseKey}`
                    ]) {
                      toast.error('Recomendação de IA já gerada para esse período')
                    } else {
                      dispatch(
                        getPromptOrderTypesRequest(
                          `ordersType-${responseKey}`,
                          advertiserCategory.name,
                          promptOrderOrigin,
                          promptOrderTypes
                        )
                      )
                    }
                  }
                }
              >
                {promptOrderTypesLoading ? (
                  <LoadingBox>
                    <Spinner
                      style={{
                        width: '1.4rem',
                        height: '1.4rem',
                        color: preto,
                      }}
                    />
                  </LoadingBox>
                ) : (
                  <>
                    <ContainerForImg width={px2vw(20)} height={px2vw(15)}>
                      <img
                        src={require('../../../assets/images/A_I/A_I@2x.png')}
                        alt="A I Button Icon"
                        width="100%"
                        height="100%"
                      />
                    </ContainerForImg>
                    <TextRegular>Gerar Recomendação com IA</TextRegular>
                  </>
                )}
              </AIButton>
            </Row>
            <Col padright={px2vw(50)}>
              <AIRecomendationTextBox
                showBox={
                  promptOrderTypesResponse?.[`ordersType-${responseKey}`]
                }
              >
                <Row justifyEnd>
                  <Row width="auto" margtop={px2vw(5)}>
                    <TextRegular>
                      Gerados via Inteligencia Artificial +
                    </TextRegular>
                    <Col
                      width="auto"
                      heigth="auto"
                      margleft={px2vw(5)}
                      margbottom={px2vw(-2)}
                    >
                      <TextBold fontSize={px2vw(14)}>OpenAI</TextBold>
                    </Col>
                  </Row>
                </Row>

                <Col margtop={px2vw(10)}>
                  <TextRegular>
                    {SplitTextIntoNewLines(promptOrderTypesResponse?.[`ordersType-${responseKey}`])}
                  </TextRegular>
                </Col>
              </AIRecomendationTextBox>
            </Col>
          </OrdersReportBox>

          {/* Top Sold Products Table */}
          <OrdersReportBox>
            <Row spaceBetween padright={px2vw(50)} margbottom={px2vw(20)}>
              <TextBold>Produtos mais vendidos</TextBold>
            </Row>
            <TopSoldProductsTable data={topSoldProductsData} />

            <Row margtop={px2vw(10)} margbottom={px2vw(10)} spaceBetween>
              <TextBold>Recomendações e Insights</TextBold>
              <AIButton
                onClick={
                  () => {
                    if (promptTopSoldProductsResponse?.[
                      `topSoldProducts-${responseKey}`
                    ]) {
                      toast.error('Recomendação de IA já gerada para esse período')
                    } else {
                      dispatch(
                        getPromptTopSoldProductsRequest(
                          `topSoldProducts-${responseKey}`,
                          advertiserCategory.name,
                          promptTopSoldProducts
                        )
                      )
                    }
                  }
                }
              >
                {promptTopSoldProductsLoading ? (
                  <LoadingBox>
                    <Spinner
                      style={{
                        width: '1.4rem',
                        height: '1.4rem',
                        color: preto,
                      }}
                    />
                  </LoadingBox>
                ) : (
                  <>
                    <ContainerForImg width={px2vw(20)} height={px2vw(15)}>
                      <img
                        src={require('../../../assets/images/A_I/A_I@2x.png')}
                        alt="A I Button Icon"
                        width="100%"
                        height="100%"
                      />
                    </ContainerForImg>
                    <TextRegular>Gerar Recomendação com IA</TextRegular>
                  </>
                )}
              </AIButton>
            </Row>
            <Col>
              <AIRecomendationTextBox
              showBox={
                promptTopSoldProductsResponse?.[
                  `topSoldProducts-${responseKey}`
                ]
              }
              >
                <Row justifyEnd>
                  <Row width="auto" margtop={px2vw(5)}>
                    <TextRegular>
                      Gerados via Inteligencia Artificial +
                    </TextRegular>
                    <Col
                      width="auto"
                      heigth="auto"
                      margleft={px2vw(5)}
                      margbottom={px2vw(-2)}
                    >
                      <TextBold fontSize={px2vw(14)}>OpenAI</TextBold>
                    </Col>
                  </Row>
                </Row>

                <Col margtop={px2vw(20)}>
                  <TextRegular>
                    {SplitTextIntoNewLines(promptTopSoldProductsResponse?.[`topSoldProducts-${responseKey}`])}
                  </TextRegular>
                </Col>
              </AIRecomendationTextBox>
            </Col>
          </OrdersReportBox>

          {/* Payment Method Chart */}
          <OrdersReportBox>
            <Row spaceBetween padright={px2vw(50)} margbottom={px2vw(20)}>
              <Col width="auto" height="auto">
                <TextBold>Formas de pagamento</TextBold>
                <BarChart
                  chartData={xAxisImagesUrlsForPaymentMethodChart}
                  chartWidth={px2vw(950)}
                  needOptions
                />
                <Row margtop={px2vw(-20)} spaceAround>
                  {xAxisImagesUrls.map((image) => {
                    return (
                      <>
                        <Col margleft={px2vw(38)} width="auto">
                          <ContainerForImg>
                            <img
                              src={image}
                              alt="teste"
                              width="100%"
                              height="100%"
                            />
                          </ContainerForImg>
                        </Col>
                      </>
                    );
                  })}
                </Row>
                <Row
                  margtop={px2vw(40)}
                  spaceBetween
                  padright={px2vw(50)}
                ></Row>
              </Col>
            </Row>
          </OrdersReportBox>

          {/* Order per Neighborhood */}
          <OrdersReportBox>
            <Row spaceBetween padright={px2vw(50)} margbottom={px2vw(20)}>
              <TextBold>Pedidos por bairro</TextBold>
            </Row>
            <OrderPerDistrictsTable data={ordersPerNeighborhoodData} />
            <Row margtop={px2vw(10)} margbottom={px2vw(10)} spaceBetween>
              <TextBold>Recomendações e Insights</TextBold>
              <AIButton
                onClick={
                  () => {
                    if (promptOrdersPerNeighborhoodResponse?.[
                      `ordersPerNeighborhood-${responseKey}`
                    ]) {
                      toast.error('Recomendação de IA já gerada para esse período')
                    } else {
                      dispatch(
                        getPromptOrdersPerNeighborhoodRequest(
                          `ordersPerNeighborhood-${responseKey}`,
                          advertiserCategory.name,
                          promptOrdersPerNeighborhood
                        )
                      )
                    }
                  }
                }
              >
                {promptOrdersPerNeighborhoodLoading ? (
                  <LoadingBox>
                    <Spinner
                      style={{
                        width: '1.4rem',
                        height: '1.4rem',
                        color: preto,
                      }}
                    />
                  </LoadingBox>
                ) : (
                  <>
                    <ContainerForImg width={px2vw(20)} height={px2vw(15)}>
                      <img
                        src={require('../../../assets/images/A_I/A_I@2x.png')}
                        alt="A I Button Icon"
                        width="100%"
                        height="100%"
                      />
                    </ContainerForImg>
                    <TextRegular>Gerar Recomendação com IA</TextRegular>
                  </>
                )}
              </AIButton>
            </Row>
            <Col>
              <AIRecomendationTextBox
                showBox={
                  promptOrdersPerNeighborhoodResponse?.[
                    `ordersPerNeighborhood-${responseKey}`
                  ]
                }
              >
                <Row justifyEnd>
                  <Row width="auto" margtop={px2vw(5)}>
                    <TextRegular>
                      Gerados via Inteligencia Artificial +
                    </TextRegular>
                    <Col
                      width="auto"
                      heigth="auto"
                      margleft={px2vw(5)}
                      margbottom={px2vw(-2)}
                    >
                      <TextBold fontSize={px2vw(14)}>OpenAI</TextBold>
                    </Col>
                  </Row>
                </Row>

                <Col margtop={px2vw(10)}>
                  <TextRegular>
                    {SplitTextIntoNewLines(promptOrdersPerNeighborhoodResponse?.[`ordersPerNeighborhood-${responseKey}`])}
                  </TextRegular>
                </Col>
              </AIRecomendationTextBox>
            </Col>
          </OrdersReportBox>
        </>
      )} 
    </>
  );

  const errorPage = (
    <ContentPage minheight={px2vw(450)}>
      <Col alignCenter height="60vh">
        <Row height="auto" alignCenter justifyCenter>
          {/* TODO: Ajustar tamanho do icone */}
          <Icon large>sentiment_very_dissatisfied</Icon>
          <ErrorText>
            Ocorreu um erro ao carregar o relatório de pedidos. Tente novamente.
          </ErrorText>
        </Row>
      </Col>
    </ContentPage>
  );

  if (ordersError) {
    return errorPage;
  }
  return contentPage;
}
