import styled, { css } from 'styled-components';
import {
  cinzaKuppi1,
  cinzaKuppi11,
  verdeKuppi20,
  verdeKuppi2,
} from '../../../styles/colors';

import {
  branco,
  preto,
  verdeKuppi1,
  verdeKuppi4,
  cinzaKuppi2,
  cinzaKuppi3,
  cinzaKuppi4,
  cinzaKuppi6,
} from '../../../styles/colors';

import px2vw from '../../../utils/responsiveness/px2vw';
import { keyframes } from 'styled-components';

const colorFadeIn = keyframes`
  0% {
  background-color: ${cinzaKuppi4};
  transform: scale(1.01);
}
  100% {
    background-color: ${verdeKuppi2};
    transform: initial;
  }
`;

export const ModalBackground = styled.div`
  display: none;
  position: fixed;
  z-index: 20;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: ${cinzaKuppi6}99;

  ${(props) =>
    props.isVisible &&
    `
    display: flex;
    justify-content: center;
    align-items: center;
  `};
`;

export const BalanceBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${px2vw(44)};
  width: ${px2vw(350)};
  border-radius: ${px2vw(6)};
  background: ${cinzaKuppi1};
  font-weight: bold;
  font-family: AvenirNextLTPro;
  font-weight: 700;
  color: ${cinzaKuppi3};
  font-size: ${px2vw(13)};
  ${(props) =>
    props.balance &&
    `
  background:${verdeKuppi20};
  color:${preto};
  `}
`;

export const ModalHeader = styled.div`
  width: 100%;
  background-color: ${branco};
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: ${px2vw(23)} ${px2vw(20)} ${px2vw(10)} ${px2vw(20)};
  font-family: AvenirNextLTPro;
  font-weight: bold;
  font-size: ${px2vw(22)};
  display: flex;
  align-items: space-between;
  justify-content: flex-start;
`;

export const ProgressBar = styled.div`
  background-color: ${cinzaKuppi11};
  width: 100%;
`;

export const ModalContent = styled.div`
  background-color: ${branco};
  padding: ${(props) => props.padding || '20px'};
  border-radius: 20px;

  ${(props) => `
    width: ${props.width};
    min-height: ${props.height};
    max-height: ${props.maxheight};
    background-color: ${props.bgcolor};
    margin-top: ${props.margtop};
  `};
`;

export const ButtonPrimary = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${branco};
  border: none;
  border-radius: ${px2vw(5)};
  font-size: ${px2vw(17)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.015em;
  padding: ${px2vw(8)};
  box-shadow: 2px 2px 3px ${cinzaKuppi2};
  width: ${(props) => (props.width ? props.width : px2vw(320))};
  height: ${px2vw(35)};

  ${(props) =>
    props.complete
      ? css`
          animation: ${colorFadeIn} 0.7s forwards;
        `
      : `background-color: ${verdeKuppi2};`}

  margin-top: ${(props) => (props.margtop ? props.margtop : 0)};
  margin-right: ${(props) => (props.margright ? props.margright : 0)};

  &:active {
    box-shadow: 2px 2px 3px ${cinzaKuppi2};
    background: ${verdeKuppi1};
  }

  ${(props) =>
    props.disabled &&
    `
  background-color: ${cinzaKuppi4};
  color:${branco};

  &:active {
    box-shadow: 2px 2px 3px ${cinzaKuppi2};
    background: ${cinzaKuppi4};
  }

  `}
`;

export const ModalPrimaryButton = styled.button`
  background-color: ${verdeKuppi1};
  width: ${px2vw(2180)};
  height: ${px2vw(35)};
  border: 1px solid ${verdeKuppi1};
  border-radius: ${px2vw(10)};
  padding: 0.3em;

  :focus {
    background-color: ${verdeKuppi1} !important;
  }

  :hover {
    background-color: ${verdeKuppi4};
    border: 1px solid ${verdeKuppi1};
  }

  ${(props) => `
    margin-top: ${props.margtop};
    width: ${props.width};
    margin-left: ${props.margleft};
    margin-right: ${props.marginRight};
  `};

  ${(props) =>
    props.disabled &&
    `
    background-color: ${cinzaKuppi4};
    border: 1px solid ${cinzaKuppi4};
  `}
`;

export const ModalPrimaryButtonText = styled.div`
  font-size: ${px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.36px;
  color: ${branco};

  ${(props) =>
    props.disabled &&
    `
    color: ${cinzaKuppi3};
  `}
`;

export const ModalSecundaryButton = styled.button`
  background-color: ${branco};
  width: ${px2vw(2180)};
  height: ${(props) => (props.height ? props.height : px2vw(35))};;
  border: 2px solid ${preto};
  border-radius: ${px2vw(5)};
  padding: 0.3em;

  :focus {
    background-color: ${branco} !important;
  }

  :hover {
    background-color: ${cinzaKuppi2};
  }

  ${(props) => `
    margin-top: ${props.margtop};
    width: ${props.width};
    margin-right: ${props.marginRight};
    margin-left: ${props.margleft};
  `};

  ${(props) =>
    props.disabled &&
    `
  border: 2px solid ${cinzaKuppi4};
  .button{
    color:${cinzaKuppi4};
  }

  `};
`;

export const ModalSecundaryButtonText = styled.div`
  font-size: ${(props) => (props.size ? props.size : px2vw(17))};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.36px;
  ${(props) =>
    props.center &&
    `
    display: flex;
    align-items:center;
    justify-content:center;
  `}
`;

export const CloseButton = styled.button`
  width: ${px2vw(18)};
  height: ${px2vw(18)};
  border: none;
  z-index: 10;
  background-color: transparent;

  :focus {
    background-color: transparent !important;
  }

  ${(props) =>
    props.absolute &&
    css`
      position: absolute;
      top: ${props.top || px2vw(10)};
      right: ${props.right || px2vw(10)};
    `}
`;

export const CloseImage = styled.img`
  width: ${(props) => props.width || px2vw(20)};
  height: ${(props) => props.height || px2vw(20)};
  padding-right: ${(props) => props.margright};

  ${(props) =>
    props.isMobile &&
    `
    width: ${px2vw(70)};
    height: ${px2vw(70)};
  `};
`;
