import React from 'react';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { Progress } from 'reactstrap';

import { preto, verdeKuppi5 } from '../../../../styles/colors';
import { Text } from '../../../../styles/common';
import { Col, Row } from '../../../../styles/grid';
import { ButtonPrimary } from '../Step1-FacebookId/styles';

import px2vw from '../../../../utils/responsiveness/px2vw';

import { setCurrentStep } from '../../../../store/modules/kuppiBoost/actions';

import {
  CloseButton,
  CloseImage,
  ModalHeader,
  ProgressBar,
  Container,
  StepImg,
  Section,
  ArrowImg,
  Ellipse,
  ArrowButton,
} from './styles';

export default function FacebookPartner(props) {
  const dispatch = useDispatch();

  const handleSetStep = (nextStep) => {
    dispatch(setCurrentStep(nextStep));
  };

  function handleCopyKuppiPartnerID() {
    var copyText = document.getElementById('kuppiPartnerID');
    var textArea = document.createElement('textarea');
    textArea.value = copyText.textContent;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();

    toast.info('ID da Kuppi copiado com sucesso!');
  }

  return (
    <>
      <ModalHeader>
      Configuração Inicial do Kuppi Boost
        <CloseButton onClick={props.onClose}>
          <CloseImage src={require('../../../../assets/images/close/close.png')} />
        </CloseButton>
      </ModalHeader>
      <ProgressBar>
        <Progress
          value={33}
          bar={true}
          style={{
            backgroundColor: verdeKuppi5,
            borderRaduis: '0px',
            height: '7px',
          }}
        />
      </ProgressBar>
      <Section className="position-relative">
        <Col width={px2vw(55)} height="100%" relative>
          <ArrowButton onClick={() => handleSetStep('1')}>
            <ArrowImg
              right
              src={require('../../../../assets/images/arrows/arrow_left_ellipse.png')}
            />
          </ArrowButton>
        </Col>
        <Container>
          <Text
            color={preto}
            size={px2vw(14)}
            margtop={px2vw(20)}
            justifyStart
            bold
          >
            1. Clique em Atribuir Parceiros
          </Text>
          <Text
            color={preto}
            size={px2vw(14)}
            justifyStart
            bold
          >
            2. Selecione "Identificação da empresa parceira"
          </Text>
          <Col 
            alignStart 
            width="auto" 
            height="auto" 
            cursor="pointer" 
            margbottom={px2vw(15)}
            onClick={() => handleCopyKuppiPartnerID()}
          >
            <Text
              color={preto}
              size={px2vw(14)}
              justifyStart
              bold
            >
              3. Clique aqui para copiar o ID da Kuppi:
            </Text>
            <Text
              id="kuppiPartnerID"
              color={preto}
              size={px2vw(14)}
              margleft={px2vw(18)}
              justifyStart
              bold
              underline
            >
              749702618704290
            </Text>
          </Col>
          <Row height="auto" margtop={px2vw(-40)}>
            <StepImg
              src={require('../../../../assets/images/kuppi_boost/onboarding_2.png')}
            />
          </Row>
          <ButtonPrimary onClick={() => handleSetStep('3')} className="ml-auto" margtop={px2vw(-20)} margright={px2vw(20)} width={px2vw(250)}>
            Avançar
          </ButtonPrimary>
        </Container>
        <Col width={px2vw(55)} height="100%" relative>
          <ArrowButton onClick={() => handleSetStep('3')}>
            <ArrowImg
              left
              src={require('../../../../assets/images/arrows/arrow_right_ellipse.png')}
            />
          </ArrowButton>
        </Col>
      </Section>
      <Row
        height="auto"
        alignEnd
        relative
        margbottom={px2vw(10)}
        pad={px2vw(7)}
      >
        <Ellipse />
        <Ellipse selected />
        <Ellipse />
        <Ellipse />
      </Row>
    </>
  );
}
