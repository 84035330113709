import React, { useContext, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Badge } from 'reactstrap';
import { addHours, format, isWithinInterval } from 'date-fns';

import { logout } from '../../../store/modules/auth/actions';
import AccessValidate from '../../../utils/validations/AccessValidate';
import px2vw from '../../../utils/responsiveness/px2vw';

import {
  dashboard,
  profileAndSignature,
  manageCoupons,
  usedCoupons,
  loyaltyCard,
  myStore,
  catalog,
  orders,
  clients,
  kuppiBoost,
  reports,
  whatsappbot,
  whatsappchat,
  deliveryGuru,
  ratings,
  salesRecover,
  massMessage,
  financial,
} from '../../../routes/routeMap';

import {
  Container,
  Content,
  LogoImage,
  LogoImageBox,
  OptionBox,
  OptionSeparator,
  OptionText,
  SelectedBar,
  LogoutButton,
  QtdNewOrders,
  MenuIconBox,
  MenuIconImg,
  ChatBotCard,
  ChatBotText,
  WppCard,
  StoreStatusText,
  StoreStatusIndicator,
  StoreStatusBox,
} from './styles';

import { Row, Col } from '../../../styles/grid';

import { setCurrentTab } from '../../../store/modules/profileAccount/actions';

import { SocketContext } from '../../../context/ws';
import {
  cinzaKuppi8,
  laranjaKuppi6,
  marromKuppi3,
  preto,
  verdeKuppi1,
  verdeKuppi2,
  vermelhoKuppi5,
} from '../../../styles/colors';
import WppMessageCounter from '../../WppMessageCounter';
import getCurrentDay from '../../../utils/getCurrentDay';
import { scrollTop } from '../../../utils/utils';

export default function SideBar({ option }) {
  const currPath = useLocation().pathname;
  const userInfo = useSelector((state) => state.user.user.advertiserInfo);
  const ordersQty = useSelector((state) => state.orders.ordersQty);
  const { access } = useSelector((state) => state.user);
  const whatsappIsActive = useSelector(
    (state) =>
      state.user.user.advertiserInfo.advertiserWhatsappbotConfig.is_active
  );
  const [notificationWS] = useContext(SocketContext);
  const storeIsOpenned = verifyIfStoreIsOpenned();
  const dispatch = useDispatch();

  const automaticPixPermission = useMemo(() => {
    return AccessValidate(access, null, null, 'automatic_pix')
  }, [access])

  function handleLogout() {
    console.debug(
      `WS - ${new Date()} - Socket ID: ${
        notificationWS.id
      } - disconnected to receive orders/whatsappbot status.`
    );
    notificationWS.disconnect();
    dispatch(logout());
  }

  function verifyIfStoreIsOpenned() {
    const currentDay = getCurrentDay();
    const now = new Date();
    const timeZoneOffsetInHour = now.getTimezoneOffset() / 60;
    let isOpen = false;

    if (
      userInfo.advertiserAvailability[currentDay]?.is_active &&
      userInfo.is_accepting_orders
    ) {
      isOpen = userInfo.advertiserAvailability[currentDay].horaries.some(
        (horary) => {
          if (horary.start_at === '00:00' && horary.end_at === '00:00') {
            return true;
          }
          return isWithinInterval(now, {
            start: addHours(
              new Date(
                `${format(new Date(), 'yyyy-MM-dd')}T${horary.start_at}:00Z`
              ),
              timeZoneOffsetInHour
            ),
            end: addHours(
              new Date(
                `${format(new Date(), 'yyyy-MM-dd')}T${horary.end_at}:00Z`
              ),
              timeZoneOffsetInHour
            ),
          });
        }
      );

      return isOpen;
    }
  }

  return (
    <Col>
      <LogoImageBox>
        <LogoImage
          src={require('../../../assets/images/logo_kuppi/logo_kuppi_wo_slogan.png')}
        />
      </LogoImageBox>
      {/* Store Status */}
      <StoreStatusBox
        isOpen={storeIsOpenned}
        to={storeIsOpenned ? currPath : orders}
      >
        <Col alignCenter>
          <Row>
            <StoreStatusIndicator isOpen={storeIsOpenned} />
            <StoreStatusText title>
              {storeIsOpenned ? 'Loja Aberta' : 'Loja Fechada'}
            </StoreStatusText>
          </Row>
          {!storeIsOpenned && (
            <StoreStatusText>{'Clique para ativar sua loja'}</StoreStatusText>
          )}
        </Col>
      </StoreStatusBox>
      <Col pad={`${px2vw(15)} 0 0 0`}>
        <Col
          alignCenter
          spaceBetween
          width="100%"
          pad={`${px2vw(10)} 0`}
          bordertop={`2px solid ${cinzaKuppi8}`}
          borderbottom={`2px solid ${cinzaKuppi8}`}
        >
          <ChatBotCard
            isActive={whatsappIsActive}
            to={whatsappbot}
            target={'_self'}
            onClick={() => scrollTop()}
          >
            <Col width="auto">
              <img
                alt="wppOnOff"
                style={{ height: '96%', marginTop: 'auto' }}
                src={require(whatsappIsActive
                  ? '../../../assets/images/whatsapp_button/whatsapp_button_on.svg'
                  : '../../../assets/images/whatsapp_button/whatsapp_button_off.svg')}
              />
            </Col>
            <Col margleft={px2vw(whatsappIsActive ? 19 : 11)}>
              <ChatBotText title>
                Whatsapp {whatsappIsActive ? 'conectado' : 'desativado'}
              </ChatBotText>
              <ChatBotText>Abrir Configurações</ChatBotText>
            </Col>
          </ChatBotCard>

          {AccessValidate(access, null, null, 'whatsapp_bot_chat') && (
            <WppCard
              isActive={whatsappIsActive}
              to={whatsappchat}
              onClick={() => scrollTop()}
            >
              <Col width="auto">
                <img
                  alt="wppChatOn"
                  src={require('../../../assets/images/whatsapp_green_icon/green_icon.svg')}
                />
              </Col>

              <Col margleft={px2vw(whatsappIsActive ? 19 : 11)}>
                <ChatBotText color={preto} title>
                  Whatsapp Chat
                </ChatBotText>
                <ChatBotText
                  color={whatsappIsActive ? verdeKuppi2 : vermelhoKuppi5}
                >
                  {whatsappIsActive ? 'Ao vivo' : 'Desconectado'}
                </ChatBotText>
              </Col>
              {!whatsappIsActive ? (
                <Col width="auto">
                  <img
                    alt="wppChatOff"
                    src={require('../../../assets/images/wifi_icon/wifi_icon_off.svg')}
                  />
                </Col>
              ) : (
                <WppMessageCounter />
              )}
            </WppCard>
          )}
        </Col>
      </Col>
      <Container>
        <Content>
          <OptionBox
            optionactive={option === 'dashboard' ? 'true' : undefined}
            to={dashboard}
            target={'_self'}
            onClick={() => scrollTop()}
          >
            <MenuIconBox>
              <MenuIconImg
                className="icon"
                alt="Dashboard"
                src={require('../../../assets/images/dashboard_icon/dashboard_icon@2x.png')}
              />
            </MenuIconBox>
            <OptionText className="text">Dashboard</OptionText>
            <SelectedBar optionActive={option === 'dashboard'} />
          </OptionBox>
          <OptionBox
            optionactive={option === 'orders' ? 'true' : undefined}
            to={orders}
            target={'_self'}
            onClick={() => scrollTop()}
          >
            <MenuIconBox>
              <MenuIconImg
                className="icon"
                alt="Pedidos"
                src={require('../../../assets/images/orders/orders@2x.png')}
              />
            </MenuIconBox>
            <OptionText className="text" margright={px2vw(30)}>
              Pedidos
            </OptionText>
            <QtdNewOrders
              isVisible={ordersQty && ordersQty.totalNewAcceptedOrdersQty > 0}
            >
              {ordersQty && ordersQty.totalNewAcceptedOrdersQty}
            </QtdNewOrders>
            <SelectedBar optionActive={option === 'orders'} />
          </OptionBox>
          <OptionBox
            id="catalog"
            optionactive={option === 'catalog' ? 'true' : undefined}
            to={catalog}
            target={'_self'}
            onClick={() => scrollTop()}
          >
            <MenuIconBox>
              <MenuIconImg
                className="icon"
                alt="Catálogo"
                src={require('../../../assets/images/catalog/catalog@2x.png')}
              />
            </MenuIconBox>
            <OptionText className="text">Catálogo</OptionText>
            <SelectedBar optionActive={option === 'catalog'} />
          </OptionBox>
          <OptionBox
            optionactive={option === 'massMessage' ? 'true' : undefined}
            to={massMessage}
            target={'_self'}
            onClick={() => scrollTop()}
          >
            <MenuIconBox>
              <MenuIconImg
                className="icon"
                alt="Mensagens"
                src={require('../../../assets/images/mass_message/mass_message@2x.png')}
              />
            </MenuIconBox>
            <Col width="auto" margright={px2vw(10)}>
              <OptionText className="text" fcolor={laranjaKuppi6}>
                Disparo em Massa
              </OptionText>
              <OptionText
                className="text"
                fs={px2vw(10)}
                margtop={px2vw(-4)}
                fcolor={marromKuppi3}
              >
                Aumente suas vendas
              </OptionText>
            </Col>
            <MenuIconBox>
              <MenuIconImg
                className="icon"
                alt="Foguete"
                src={require('../../../assets/images/mass_message/rocket@2x.png')}
              />
            </MenuIconBox>
            <SelectedBar optionActive={option === 'massMessage'} />
          </OptionBox>
          <OptionSeparator />
          <OptionBox
            id="myStore"
            optionactive={option === 'myStore' ? 'true' : undefined}
            to={myStore}
            target={'_self'}
            onClick={() => scrollTop()}
          >
            <MenuIconBox>
              <MenuIconImg
                className="icon"
                alt="Minha Loja"
                src={require('../../../assets/images/my_store/my_store@2x.png')}
              />
            </MenuIconBox>
            <OptionText className="text">Configurações da Loja</OptionText>
            <SelectedBar optionActive={option === 'myStore'} />
          </OptionBox>
          {automaticPixPermission && <OptionBox
            optionactive={option === 'financial' ? 'true' : undefined}
            to={financial}
            target={'_self'}
          >
            <MenuIconBox>
              <MenuIconImg
                className="icon"
                alt="Online Payment"
                src={require('../../../assets/images/financial/finance_symbol.png')}
              />
            </MenuIconBox>
            <OptionText className="text">
              Pagamento Online <Badge color="success" >BETA</Badge>
            </OptionText>
            <SelectedBar optionActive={option === 'financial'} />
          </OptionBox>}
          <OptionBox
            optionactive={option === 'reports' ? 'true' : undefined}
            to={reports}
            target={'_self'}
            onClick={() => scrollTop()}
          >
            <MenuIconBox>
              <MenuIconImg
                className="icon"
                alt="Relatórios"
                src={require('../../../assets/images/reports/reports@2x.png')}
              />
            </MenuIconBox>
            <OptionText className="text">Relatórios</OptionText>
            {AccessValidate(access, null, null, 'no_billing_data') && (
              <MenuIconBox margleft={px2vw(30)} size={px2vw(27)}>
                <MenuIconImg
                  alt="Cadeado"
                  src={require('../../../assets/images/lock/lock@2x.png')}
                />
              </MenuIconBox>
            )}
            <SelectedBar optionActive={option === 'reports'} />
          </OptionBox>
          <OptionBox
            optionactive={option === 'manageCoupons' ? 'true' : undefined}
            to={manageCoupons}
            target={'_self'}
            onClick={() => scrollTop()}
          >
            <MenuIconBox>
              <MenuIconImg
                className="icon"
                alt="Gerenciar Cupons"
                src={require('../../../assets/images/manage_coupons/manage_coupons@2x.png')}
              />
            </MenuIconBox>
            <OptionText className="text">Promoções</OptionText>
            <SelectedBar optionActive={option === 'manageCoupons'} />
          </OptionBox>
          {/* hidden temporarilly
          <OptionBox optionactive={option === 'usedCoupons' ? "true" : undefined} to={usedCoupons} target={"_self"} onClick={() => scrollTop()}>
            <MenuIconBox>
              <MenuIconImg
                className="icon"
                alt="Cupons Utilizados"
                src={require('../../../assets/images/used_coupons/used_coupons@2x.png')}
              />
            </MenuIconBox>
            <OptionText className="text">Cupons Utilizados</OptionText>
            <SelectedBar optionActive={option === 'usedCoupons'} />
          </OptionBox>
          */}
          <OptionBox
            optionactive={option === 'clients' ? 'true' : undefined}
            to={clients}
            target={'_self'}
            onClick={() => scrollTop()}
          >
            <MenuIconBox>
              <MenuIconImg
                className="icon"
                alt="Clientes"
                src={require('../../../assets/images/clients/clients@2x.png')}
              />
            </MenuIconBox>
            <OptionText className="text">Meus Clientes</OptionText>
            <SelectedBar optionActive={option === 'clients'} />
          </OptionBox>
          <OptionBox
            optionactive={option === 'ratings' ? 'true' : undefined}
            to={ratings}
            target={'_self'}
            onClick={() => scrollTop()}
          >
            <MenuIconBox>
              <MenuIconImg
                className="icon"
                alt="Avaliações"
                src={require('../../../assets/images/ratings/rating_menu_icon_black.png')}
              />
            </MenuIconBox>
            <OptionText className="text">Avaliações</OptionText>
            <SelectedBar optionActive={option === 'ratings'} />
          </OptionBox>
          <OptionSeparator />
          <OptionBox
            optionactive={option === 'loyaltyCard' ? 'true' : undefined}
            to={loyaltyCard}
            target={'_self'}
            onClick={() => scrollTop()}
          >
            <MenuIconBox>
              <MenuIconImg
                className="icon"
                alt="Cartão Fidelidade"
                src={require('../../../assets/images/loyalty_card/loyalty_card@2x.png')}
              />
            </MenuIconBox>
            <OptionText className="text">Cartão Fidelidade Digital</OptionText>
            <SelectedBar optionActive={option === 'loyaltyCard'} />
          </OptionBox>
          {userInfo.advertiserCategory.is_kuppi_boost_available && (
            <OptionBox
              optionactive={option === 'kuppiBoost' ? 'true' : undefined}
              to={kuppiBoost}
              target={'_self'}
              onClick={() => scrollTop()}
            >
              <MenuIconBox>
                <MenuIconImg
                  className="icon"
                  alt="Clientes"
                  src={require('../../../assets/images/kuppi_boost/kuppi_boost@2x.png')}
                />
              </MenuIconBox>
              <OptionText className="text">Kuppi Boost</OptionText>
              <SelectedBar optionActive={option === 'kuppiBoost'} />
            </OptionBox>
          )}
          <OptionBox
            optionactive={option === 'salesRecover' ? 'true' : undefined}
            to={salesRecover}
            target={'_self'}
            onClick={() => scrollTop()}
          >
            <MenuIconBox>
              <MenuIconImg
                className="icon"
                alt="recuperador"
                src={require('../../../assets/images/sales_recover/side_bar_icon.svg')}
              />
            </MenuIconBox>
            <OptionText className="text">Recupera Vendas</OptionText>
            <SelectedBar optionActive={option === 'salesRecover'} />
          </OptionBox>
          <OptionSeparator />
          <OptionBox
            optionactive={option === 'profile' ? 'true' : undefined}
            to={profileAndSignature}
            target={'_self'}
            onClick={() => {
              scrollTop();
              dispatch(setCurrentTab('tabProfile'));
            }}
          >
            <MenuIconBox>
              <MenuIconImg
                className="icon"
                alt="Perfil e Assinatura"
                src={require('../../../assets/images/profile_signature_icon/profile_signature_icon@2x.png')}
              />
            </MenuIconBox>
            <OptionText className="text">Perfil e Assinatura</OptionText>
            <SelectedBar optionActive={option === 'profile'} />
          </OptionBox>
          {/* hidden temporarilly
          <OptionBox
            optionactive={option === 'deliveryGuru' ? 'true' : undefined}
            to={deliveryGuru}
            target={'_self'}
            onClick={() => scrollTop()}
          >
            <MenuIconBox>
              <MenuIconImg
                className="icon"
                alt="Guru do Delivery"
                src={require('../../../assets/images/delivery_guru/delivery_guru@2x.png')}
              />
            </MenuIconBox>
            <OptionText className="text">Guru do Delivery</OptionText>
            <SelectedBar optionActive={option === 'deliveryGuru'} />
          </OptionBox>
          */}
          <OptionSeparator />
          <LogoutButton onClick={() => handleLogout()}>
            <MenuIconBox>
              <MenuIconImg
                className="icon"
                alt="Logout"
                src={require('../../../assets/images/logout/logout@2x.png')}
              />
            </MenuIconBox>
            <OptionText
              className="text"
              margleft={px2vw(30)}
              margtop={px2vw(2)}
            >
              Sair
            </OptionText>
          </LogoutButton>
        </Content>
      </Container>
    </Col>
  );
}
