import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Icon } from 'react-materialize';
import { AiOutlineLeft } from 'react-icons/ai';

import { toast } from 'react-toastify';
import { ErrorText, Text } from '../../../../styles/common';
import { ModalPrimaryButton } from '../styles';
import {
  ModalContent,
  CloseButton,
  ModalHeader,
  CloseImage,
  ModalFooter,
  ImageFrame,
  ImageBox,
  ProductCard,
  ProductDescription,
  UploadImage,
  UploadIcon,
} from './styles';
import { Row } from '../../../../styles/grid';
import { preto } from '../../../../styles/colors';

import {
  setCampaignCurrentStep,
  setSelectedCoupon,
  setSelectedProduct,
} from '../../../../store/modules/kuppiBoost/actions';

import { formatCurrencyBRL } from '../../../../utils/utils';
import px2vw from '../../../../utils/responsiveness/px2vw';
import {
  ModalSecundaryButton,
  ModalSecundaryButtonText,
} from '../../AccountLink/styles';

export default function CampaignItem(props) {
  const dispatch = useDispatch();
  const kuppiBoostData = useSelector((state) => state.kuppiBoost);
  const { available_coupons, available_products } = kuppiBoostData;
  const { type } = kuppiBoostData.campaign;
  const isTypeRangeOrWhatsApp = type === 'range' || type === 'whatsapp';
  const [selectedCouponData, setSelectedCouponData] = useState(null);
  const [selectedProductData, setSelectedProductData] = useState(null);
  const [fileItemData, setFileItemData] = useState({
    base64: null,
    name: '',
    size: 0,
    type: '',
    subtype: '',
    width: null,
    height: null,
  });

  const handleCleanAll = () => {
    setFileItemData({
      base64: null,
      name: '',
      size: 0,
      type: '',
      subtype: '',
      width: null,
      height: null,
    });
  };

  async function handleFileItem(event) {
    const f = event.target.files[0];

    if (f) {
      const fname = f.name;
      const fsize = (f.size / 1000000).toFixed(1);
      const ftype = f.type.split('/')[0];
      const fsubtype = f.type.split('/')[1];

      const itemFileReader = new FileReader();
      itemFileReader.onload = function () {
        setFileItemData({
          ...fileItemData,
          base64: itemFileReader.result,
          name: fname,
          size: fsize,
          type: ftype,
          subtype: fsubtype,
        });
      };
      itemFileReader.readAsDataURL(f);
    } else {
      handleCleanAll();
    }
  }

  useEffect(() => {
    const supportedFormats = ['png', 'jpg', 'jpeg'];

    if (fileItemData.base64 && fileItemData.size && fileItemData.size > 2) {
      toast.error('A imagem do item precisa ser menor que 2mb.', {
        autoClose: 6000,
      });
      setFileItemData({ ...fileItemData, base64: null });
    } else if (
      fileItemData.base64 &&
      fileItemData.subtype &&
      !supportedFormats.includes(fileItemData.subtype)
    ) {
      toast.error('A imagem do item precisa ser do tipo .png, .jpg ou .jpeg.', {
        autoClose: 6000,
      });
      setFileItemData({ ...fileItemData, base64: null });
    } else {
      // Get the real width and height of the item image
      if (fileItemData.base64) {
        const image = new Image();
        image.src = fileItemData.base64;

        image.onload = () => {
          setFileItemData({
            ...fileItemData,
            width: image.naturalWidth,
            height: image.naturalHeight,
          });
        };

        if (isTypeRangeOrWhatsApp) {
          dispatch(
            setSelectedProduct({
              was_image_uploaded_locally: true,
              img_url: fileItemData.base64,
            })
          );
        } else {
          dispatch(
            setSelectedCoupon({
              was_image_uploaded_locally: true,
              product_url: fileItemData.base64,
            })
          );
        }

        dispatch(setCampaignCurrentStep('3'));
      }
    }
  }, [fileItemData.base64]);

  function handleSelectCoupon(coupon) {
    setSelectedCouponData(coupon);
  }

  function handleSelectProduct(product) {
    setSelectedProductData(product);
  }

  function handleBacktoPreviousStep() {
    dispatch(setCampaignCurrentStep('1'));
  }

  function handleUploadImage() {
    setSelectedCouponData(null);
    setSelectedProductData(null);
    document.getElementById('itemImage').click();
  }

  function handleGotoNextStep() {
    dispatch(setSelectedProduct({...selectedProductData, was_image_uploaded_locally: false}));
    dispatch(setSelectedCoupon({...selectedCouponData, was_image_uploaded_locally: false}));
    dispatch(setCampaignCurrentStep('3'));
  }
  return (
    <>
      <ModalHeader>
        {type === 'range'
          ? 'Escolha 1 produto dos seus mais vendidos'
          : 'Escolha o cupom que será impulsionado'}
        <CloseButton onClick={props.onClose}>
          <CloseImage
            src={require('../../../../assets/images/close/close.png')}
          />
        </CloseButton>
      </ModalHeader>
      <ModalContent>
        {type === 'conversion' &&
          available_coupons.length > 0 &&
          available_coupons.map((item) => (
            <ProductCard
              conversion
              key={item.coupon_id}
              selected={
                selectedCouponData &&
                selectedCouponData.coupon_id === item.coupon_id
              }
              onClick={() => handleSelectCoupon(item)}
            >
              <ImageBox
                width={px2vw(143)}
                height="100%"
                conversion
                selected={
                  selectedCouponData &&
                  selectedCouponData.coupon_id === item.coupon_id
                }
              >
                <ImageFrame
                  src={`${item.product_url}?nocache=${new Date().getTime()}`}
                  btlRadius={px2vw(7)}
                  bblRadius={px2vw(7)}
                />
              </ImageBox>
              <ProductDescription>
                <Text size={px2vw(13)} color={preto}>
                  {item.product_name}
                </Text>
                <div className="my-auto">
                  <Text justifyStart size={px2vw(14)} color={preto}>
                    {item.coupon_short_display_name.substring(
                      item.coupon_short_display_name.indexOf('R'),
                      item.coupon_short_display_name.lastIndexOf('r') + 1
                    )}
                  </Text>
                  <Text justifyStart size={px2vw(23)} color={preto}>
                    {item.coupon_short_display_name.substring(
                      item.coupon_short_display_name.lastIndexOf('r') + 1,
                      item.coupon_short_display_name.length
                    )}
                  </Text>
                </div>
                <Text
                  regular
                  justifyStart
                  size={px2vw(9)}
                  color={preto}
                  margbottom={px2vw(
                    item.product_description.length < 40 ? 25 : 10
                  )}
                  wrap
                >
                  {item.product_description.toUpperCase()}
                </Text>
              </ProductDescription>
            </ProductCard>
          ))}
        {type === 'conversion' && available_coupons?.length === 0 && (
          <Row
            alignCenter
            justifyCenter
            width={px2vw(400)}
            height="auto"
            margleft={px2vw(100)}
          >
            {/* TODO: Ajustar tamanho do icone */}
            <Icon large>sentiment_very_dissatisfied</Icon>
            <ErrorText>
              Você não possui cupons cadastrados e/ou ativos para impulsionar.
            </ErrorText>
          </Row>
        )}

        {isTypeRangeOrWhatsApp && (
          <ProductCard
            static
            width={px2vw(182.7)}
            height={px2vw(190)}
            selected={false}
          >
            <UploadImage
              src={require('../../../../assets/images/kuppi_boost/imgbox.png')}
            />
            <Text
              alignCenter
              size={px2vw(13)}
              margbottom={px2vw(3)}
              margtop={px2vw(10)}
              className="mx-auto"
            >
              Já possui a arte para impulsionar?
            </Text>
            <input
              className="d-none"
              onChange={handleFileItem}
              type="file"
              id="itemImage"
              name="itemImage"
            />

            <ModalSecundaryButton
              width={px2vw(145)}
              height={px2vw(28)}
              radius={px2vw(5)}
              onClick={() => handleUploadImage()}
              className="mx-auto"
              type="button"
            >
              <ModalSecundaryButtonText
                center
                size={px2vw(11)}
                className="mr-auto"
              >
                <UploadIcon
                  src={require('../../../../assets/images/kuppi_boost/upload.png')}
                />
                Importar imagem
              </ModalSecundaryButtonText>
            </ModalSecundaryButton>
          </ProductCard>
        )}

        {isTypeRangeOrWhatsApp &&
          available_products?.length > 0 &&
          available_products.map((item) => (
            <ProductCard
              key={item.id}
              width={px2vw(182.7)}
              height={px2vw(190)}
              selected={
                selectedProductData && selectedProductData.id === item.id
              }
              onClick={() => handleSelectProduct(item)}
            >
              <ImageBox
                width="100%"
                height={px2vw(100)}
                selected={
                  selectedCouponData &&
                  selectedCouponData.coupon_id === item.coupon_id
                }
              >
                <ImageFrame
                  src={`${item.img_url}?nocache=${new Date().getTime()}`}
                  btlRadius={px2vw(7)}
                  btrRadius={px2vw(7)}
                />
              </ImageBox>
              <ProductDescription width="100%" height={px2vw(90)}>
                <Text justifyStart size={px2vw(12)} wrap margbottom={px2vw(10)}>
                  {item.product_name}
                </Text>
                <Text justifyStart size={px2vw(19)}>
                  {formatCurrencyBRL(item.amount, 2)}
                </Text>
              </ProductDescription>
            </ProductCard>
          ))}
        {isTypeRangeOrWhatsApp && available_products?.length === 0 && (
          <Row
            alignCenter
            justifyCenter
            width={px2vw(400)}
            height="auto"
            margleft={px2vw(100)}
          >
            {/* TODO: Ajustar tamanho do icone */}
            <Icon large>sentiment_very_dissatisfied</Icon>
            <ErrorText>
              Você não possui produtos cadastrados com fotos e/ou ativos para
              impulsionar.
            </ErrorText>
          </Row>
        )}
      </ModalContent>
      <ModalFooter>
        <Row className="my-auto" alignCenter>
          <Row
            onClick={handleBacktoPreviousStep}
            justifyStart
            alignCenter
            width="auto"
            cursor="pointer"
          >
            <AiOutlineLeft className="mr-2" /> Voltar
          </Row>
          <Row>
            <ModalPrimaryButton
              onClick={handleGotoNextStep}
              disabled={!selectedCouponData && !selectedProductData}
              className="ml-auto"
            >
              Avançar
            </ModalPrimaryButton>
          </Row>
        </Row>
      </ModalFooter>
    </>
  );
}
