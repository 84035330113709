export function getChallengesStatusRequest() {
  return {
    type: '@challenges/GET_CHALLENGES_STATUS_REQUEST',
  };
}

export function getChallengesStatusSuccess(advertiserChallengesStatus) {
  return {
    type: '@challenges/GET_CHALLENGES_STATUS_SUCCESS',
    payload: { advertiserChallengesStatus },
  };
}

export function getChallengesStatusFailure() {
  return {
    type: '@challenges/GET_CHALLENGES_STATUS_FAILURE',
  }
}

export function challengeCompletedModalClosedRequest(challengeId, isCompletedModalClosed) {
  return {
    type: '@challenges/CHALLENGE_COMPLETED_MODAL_CLOSED_REQUEST',
    payload: { challengeId, isCompletedModalClosed },
  }
}
 
export function clearSuccess() {
  return {
    type: '@challenges/CLEAR_SUCCESS',
  };
}

export function sendTestMessage() {
  return {
    type: '@challenges/SEND_TEST_MESSAGE',
  };
}