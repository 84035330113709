import React from 'react';
import { FormControlLabel } from '@material-ui/core';

import { Col, Row } from '../../../../styles/grid';
import { 
  DisponibilityLabel, 
  HourDisponibilityBox, 
  ItemInput, 
  ItemInputLabelRegular, 
  ItemSelectInput, 
  SpecificHourBox
} from '../../styles';
import { SwitchButtonBox, SwitchController } from '../../../_layouts/styles/common';

import px2vw from '../../../../utils/responsiveness/px2vw';
import { timeMask } from '../../../../utils/masks';
import { productAvailability } from '../../../../utils/commonObjects';

export default function CouponHoraries({ handler }) {
  function handleDayDisponibility(dayOfWeek) {
    // The below lines commented are because the coupon horaries will not be handled by now

    if (dayOfWeek === 'sunday') {
      handler.setHorarySunday({
        active: !handler.horarySunday.active,
        availability: '1',
        specificHourStart: '',
        specificHourEnd: '',
      });
      // document.getElementById('itemSunAvailability').value = '1';
      // handleHourDisponibility('1', 'sunday');
    } else if (dayOfWeek === 'monday') {
      handler.setHoraryMonday({
        active: !handler.horaryMonday.active,
        availability: '1',
        specificHourStart: '',
        specificHourEnd: '',
      });
      // document.getElementById('itemMonAvailability').value = '1';
      // handleHourDisponibility('1', 'monday');
    } else if (dayOfWeek === 'tuesday') {
      handler.setHoraryTuesday({
        active: !handler.horaryTuesday.active,
        availability: '1',
        specificHourStart: '',
        specificHourEnd: '',
      });
      // document.getElementById('itemTueAvailability').value = '1';
      // handleHourDisponibility('1', 'tuesday');
    } else if (dayOfWeek === 'wednesday') {
      handler.setHoraryWednesday({
        active: !handler.horaryWednesday.active,
        availability: '1',
        specificHourStart: '',
        specificHourEnd: '',
      });
      // document.getElementById('itemWedAvailability').value = '1';
      // handleHourDisponibility('1', 'wednesday');
    } else if (dayOfWeek === 'thursday') {
      handler.setHoraryThursday({
        active: !handler.horaryThursday.active,
        availability: '1',
        specificHourStart: '',
        specificHourEnd: '',
      });
      // document.getElementById('itemThuAvailability').value = '1';
      // handleHourDisponibility('1', 'thursday');
    } else if (dayOfWeek === 'friday') {
      handler.setHoraryFriday({
        active: !handler.horaryFriday.active,
        availability: '1',
        specificHourStart: '',
        specificHourEnd: '',
      });
      // document.getElementById('itemFriAvailability').value = '1';
      // handleHourDisponibility('1', 'friday');
    } else if (dayOfWeek === 'saturday') {
      handler.setHorarySaturday({
        active: !handler.horarySaturday.active,
        availability: '1',
        specificHourStart: '',
        specificHourEnd: '',
      });
      // document.getElementById('itemSatAvailability').value = '1';
      // handleHourDisponibility('1', 'saturday');
    }
  }

  function handleHourDisponibility(selectedOption, dayOfWeek) {
    let specificHour = false;
    if (selectedOption === '2') {
      specificHour = true;
    }

    if (dayOfWeek === 'sunday') {
      handler.horarySunday.availability = specificHour ? '2' : '1';
      handler.horarySunday.specificHourStart = '';
      handler.horarySunday.specificHourEnd = '';
    } else if (dayOfWeek === 'monday') {
      handler.horaryMonday.availability = specificHour ? '2' : '1';
      handler.horaryMonday.specificHourStart = '';
      handler.horaryMonday.specificHourEnd = '';
    } else if (dayOfWeek === 'tuesday') {
      handler.horaryTuesday.availability = specificHour ? '2' : '1';
      handler.horaryTuesday.specificHourStart = '';
      handler.horaryTuesday.specificHourEnd = '';
    } else if (dayOfWeek === 'wednesday') {
      handler.horaryWednesday.availability = specificHour ? '2' : '1';
      handler.horaryWednesday.specificHourStart = '';
      handler.horaryWednesday.specificHourEnd = '';
    } else if (dayOfWeek === 'thursday') {
      handler.horaryThursday.availability = specificHour ? '2' : '1';
      handler.horaryThursday.specificHourStart = '';
      handler.horaryThursday.specificHourEnd = '';
    } else if (dayOfWeek === 'friday') {
      handler.horaryFriday.availability = specificHour ? '2' : '1';
      handler.horaryFriday.specificHourStart = '';
      handler.horaryFriday.specificHourEnd = '';
    } else if (dayOfWeek === 'saturday') {
      handler.horarySaturday.availability = specificHour ? '2' : '1';
      handler.horarySaturday.specificHourStart = '';
      handler.horarySaturday.specificHourEnd = '';
    }
  }

  return (
    <>
      <div
        style={{
          marginBottom: `${px2vw(20)}`,
          marginTop: `${px2vw(20)}`,
        }}
      >
        <hr />
      </div>
      <Col justifyStart margleft={px2vw(-20)} height="auto">
        <DisponibilityLabel>
          Dias e horários que o cupom estará disponível
        </DisponibilityLabel>
        <Row justifyStart margtop={px2vw(10)}>
          <SwitchButtonBox width={px2vw(250)} margleft={px2vw(20)}>
            <FormControlLabel
              control={
                <SwitchController
                  checked={handler.horarySunday.active}
                  type="checkbox"
                  onChange={() =>
                    handleDayDisponibility('sunday')
                  }
                />
              }
              label={
                <ItemInputLabelRegular>
                  Domingo
                </ItemInputLabelRegular>
              }
            />
          </SwitchButtonBox>
          <HourDisponibilityBox
            margtop="7px"
            isVisible={false} // handler.horarySunday.active}
          >
            <ItemSelectInput
              id="itemSunAvailability"
              name="itemSunAvailability"
              width="180px"
              height="35px"
              margright="20px"
              options={productAvailability}
              value={handler.horarySunday.availability}
              onChange={(t) => {
                handleHourDisponibility(t.target.value, 'sunday');
              }}
            />
            <SpecificHourBox
              isVisible={handler.horarySunday.active && handler.horarySunday.availability === '2'}
            >
              <ItemInput
                name="itemSunStartTime"
                height="35px"
                width="65px"
                maxLength="5"
                placeholder="18:00"
                value={handler.horarySunday.specificHourStart}
                onChange={(t) => {
                  handler.horarySunday.specificHourStart = timeMask(t.target.value);
                }}
              />
              <ItemInputLabelRegular margtop="10px" pad="5px">
                às
              </ItemInputLabelRegular>
              <ItemInput
                name="itemSunEndTime"
                height="35px"
                width="65px"
                maxLength="5"
                placeholder="23:00"
                value={handler.horarySunday.specificHourEnd}
                onChange={(t) => {
                  handler.horarySunday.specificHourEnd = timeMask(t.target.value);
                }}
              />
            </SpecificHourBox>
          </HourDisponibilityBox>
        </Row>
        <Row justifyStart margtop={px2vw(-10)}>
          <SwitchButtonBox width={px2vw(250)} margleft={px2vw(20)}>
            <FormControlLabel
              control={
                <SwitchController
                  checked={handler.horaryMonday.active}
                  type="checkbox"
                  onChange={() =>
                    handleDayDisponibility('monday')
                  }
                />
              }
              label={
                <ItemInputLabelRegular>
                  Segunda
                </ItemInputLabelRegular>
              }
            />
          </SwitchButtonBox>
          <HourDisponibilityBox
            margtop="-10px"
            isVisible={false} // handler.horaryMonday.active}
          >
            <ItemSelectInput
              id="itemMonAvailability"
              name="itemMonAvailability"
              width="180px"
              height="35px"
              margright="20px"
              options={productAvailability}
              value={handler.horaryMonday.availability}
              onChange={(t) => {
                handleHourDisponibility(t.target.value, 'monday');
              }}
            />
            <SpecificHourBox
              isVisible={handler.horaryMonday.active && handler.horaryMonday.availability === '2'}
            >
              <ItemInput
                name="itemMonStartTime"
                height="35px"
                width="65px"
                maxLength="5"
                placeholder="18:00"
                value={handler.horaryMonday.specificHourStart}
                onChange={(t) => {
                  handler.horarySunday.specificHourStart = timeMask(t.target.value);
                }}
              />
              <ItemInputLabelRegular margtop="10px" pad="5px">
                às
              </ItemInputLabelRegular>
              <ItemInput
                name="itemMonEndTime"
                height="35px"
                width="65px"
                maxLength="5"
                placeholder="23:00"
                value={handler.horaryMonday.specificHourEnd}
                onChange={(t) => {
                  handler.horarySunday.specificHourEnd = timeMask(t.target.value);
                }}
              />
            </SpecificHourBox>
          </HourDisponibilityBox>
        </Row>
        <Row justifyStart margtop={px2vw(-10)}>
          <SwitchButtonBox width={px2vw(250)} margleft={px2vw(20)}>
            <FormControlLabel
              control={
                <SwitchController
                  checked={handler.horaryTuesday.active}
                  type="checkbox"
                  onChange={() =>
                    handleDayDisponibility('tuesday')
                  }
                />
              }
              label={
                <ItemInputLabelRegular>
                  Terça
                </ItemInputLabelRegular>
              }
            />
          </SwitchButtonBox>
          <HourDisponibilityBox
            margtop="-10px"
            isVisible={false} // handler.horaryTuesday.active}
          >
            <ItemSelectInput
              id="itemTueAvailability"
              name="itemTueAvailability"
              width="180px"
              height="35px"
              margright="20px"
              options={productAvailability}
              value={handler.horaryTuesday.availability}
              onChange={(t) => {
                handleHourDisponibility(t.target.value, 'tuesday');
              }}
            />
            <SpecificHourBox
              isVisible={handler.horaryTuesday.active && handler.horaryTuesday.availability === '2'}
            >
              <ItemInput
                name="itemTueStartTime"
                height="35px"
                width="65px"
                maxLength="5"
                placeholder="18:00"
                value={handler.horaryTuesday.specificHourStart}
                onChange={(t) => {
                  handler.horaryTuesday.specificHourStart = timeMask(t.target.value);
                }}
              />
              <ItemInputLabelRegular margtop="10px" pad="5px">
                às
              </ItemInputLabelRegular>
              <ItemInput
                name="itemTueEndTime"
                height="35px"
                width="65px"
                maxLength="5"
                placeholder="23:00"
                value={handler.horaryTuesday.specificHourEnd}
                onChange={(t) => {
                  handler.horaryTuesday.specificHourEnd = timeMask(t.target.value);
                }}
              />
            </SpecificHourBox>
          </HourDisponibilityBox>
        </Row>
        <Row justifyStart margtop={px2vw(-10)}>
          <SwitchButtonBox width={px2vw(250)} margleft={px2vw(20)}>
            <FormControlLabel
              control={
                <SwitchController
                  checked={handler.horaryWednesday.active}
                  type="checkbox"
                  onChange={() =>
                    handleDayDisponibility('wednesday')
                  }
                />
              }
              label={
                <ItemInputLabelRegular>
                  Quarta
                </ItemInputLabelRegular>
              }
            />
          </SwitchButtonBox>
          <HourDisponibilityBox
            margtop="-10px"
            isVisible={false} // handler.horaryWednesday.active}
          >
            <ItemSelectInput
              id="itemWedAvailability"
              name="itemWedAvailability"
              width="180px"
              height="35px"
              margright="20px"
              options={productAvailability}
              value={handler.horaryWednesday.availability}
              onChange={(t) => {
                handleHourDisponibility(
                  t.target.value,
                  'wednesday'
                );
              }}
            />
            <SpecificHourBox
              isVisible={handler.horaryWednesday.active && handler.horaryWednesday.availability === '2'}
            >
              <ItemInput
                name="itemWedStartTime"
                height="35px"
                width="65px"
                maxLength="5"
                placeholder="18:00"
                value={handler.horaryWednesday.specificHourStart}
                onChange={(t) => {
                  handler.horaryWednesday.specificHourStart = timeMask(t.target.value);
                }}
              />
              <ItemInputLabelRegular margtop="10px" pad="5px">
                às
              </ItemInputLabelRegular>
              <ItemInput
                name="itemWedEndTime"
                height="35px"
                width="65px"
                maxLength="5"
                placeholder="23:00"
                value={handler.horaryWednesday.specificHourEnd}
                onChange={(t) => {
                  handler.horaryWednesday.specificHourEnd = timeMask(t.target.value);
                }}
              />
            </SpecificHourBox>
          </HourDisponibilityBox>
        </Row>
        <Row justifyStart margtop={px2vw(-10)}>
          <SwitchButtonBox width={px2vw(250)} margleft={px2vw(20)}>
            <FormControlLabel
              control={
                <SwitchController
                  checked={handler.horaryThursday.active}
                  type="checkbox"
                  onChange={() =>
                    handleDayDisponibility('thursday')
                  }
                />
              }
              label={
                <ItemInputLabelRegular>
                  Quinta
                </ItemInputLabelRegular>
              }
            />
          </SwitchButtonBox>
          <HourDisponibilityBox
            margtop="-10px"
            isVisible={false} // handler.horaryThursday.active}
          >
            <ItemSelectInput
              id="itemThuAvailability"
              name="itemThuAvailability"
              width="180px"
              height="35px"
              margright="20px"
              options={productAvailability}
              value={handler.horaryThursday.availability}
              onChange={(t) => {
                handleHourDisponibility(t.target.value, 'thursday');
              }}
            />
            <SpecificHourBox
              isVisible={handler.horaryThursday.active && handler.horaryThursday.availability === '2'}
            >
              <ItemInput
                name="itemThuStartTime"
                height="35px"
                width="65px"
                maxLength="5"
                placeholder="18:00"
                value={handler.horaryThursday.specificHourStart}
                onChange={(t) => {
                  handler.horaryThursday.specificHourStart = timeMask(t.target.value);
                }}
              />
              <ItemInputLabelRegular margtop="10px" pad="5px">
                às
              </ItemInputLabelRegular>
              <ItemInput
                name="itemThuEndTime"
                height="35px"
                width="65px"
                maxLength="5"
                placeholder="23:00"
                value={handler.horaryThursday.specificHourEnd}
                onChange={(t) => {
                  handler.horaryThursday.specificHourEnd = timeMask(t.target.value);
                }}
              />
            </SpecificHourBox>
          </HourDisponibilityBox>
        </Row>
        <Row justifyStart margtop={px2vw(-10)}>
          <SwitchButtonBox width={px2vw(250)} margleft={px2vw(20)}>
            <FormControlLabel
              control={
                <SwitchController
                  checked={handler.horaryFriday.active}
                  type="checkbox"
                  onChange={() =>
                    handleDayDisponibility('friday')
                  }
                />
              }
              label={
                <ItemInputLabelRegular>
                  Sexta
                </ItemInputLabelRegular>
              }
            />
          </SwitchButtonBox>
          <HourDisponibilityBox
            margtop="-10px"
            isVisible={false} // handler.horaryFriday.active}
          >
            <ItemSelectInput
              id="itemFriAvailability"
              name="itemFriAvailability"
              width="180px"
              height="35px"
              margright="20px"
              options={productAvailability}
              value={handler.horaryFriday.availability}
              onChange={(t) => {
                handleHourDisponibility(t.target.value, 'friday');
              }}
            />
            <SpecificHourBox
              isVisible={handler.horaryFriday.active && handler.horaryFriday.availability === '2'}
            >
              <ItemInput
                name="itemFriStartTime"
                height="35px"
                width="65px"
                maxLength="5"
                placeholder="18:00"
                value={handler.horaryFriday.specificHourStart}
                onChange={(t) => {
                  handler.horaryFriday.specificHourStart = timeMask(t.target.value);
                }}
              />
              <ItemInputLabelRegular margtop="10px" pad="5px">
                às
              </ItemInputLabelRegular>
              <ItemInput
                name="itemFriEndTime"
                height="35px"
                width="65px"
                maxLength="5"
                placeholder="23:00"
                value={handler.horaryFriday.specificHourEnd}
                onChange={(t) => {
                  handler.horaryFriday.specificHourEnd = timeMask(t.target.value);
                }}
              />
            </SpecificHourBox>
          </HourDisponibilityBox>
        </Row>
        <Row justifyStart margtop={px2vw(-10)}>
          <SwitchButtonBox width={px2vw(250)} margleft={px2vw(20)}>
            <FormControlLabel
              control={
                <SwitchController
                  checked={handler.horarySaturday.active}
                  type="checkbox"
                  onChange={() =>
                    handleDayDisponibility('saturday')
                  }
                />
              }
              label={
                <ItemInputLabelRegular>
                  Sábado
                </ItemInputLabelRegular>
              }
            />
          </SwitchButtonBox>
          <HourDisponibilityBox
            margtop="-10px"
            isVisible={false} // handler.horarySaturday.active}
          >
            <ItemSelectInput
              id="itemSatAvailability"
              name="itemSatAvailability"
              width="180px"
              height="35px"
              margright="20px"
              options={productAvailability}
              value={handler.horarySaturday.availability}
              onChange={(t) => {
                handleHourDisponibility(t.target.value, 'saturday');
              }}
            />
            <SpecificHourBox
              isVisible={handler.horarySaturday.active && handler.horarySaturday.availability === '2'}
            >
              <ItemInput
                name="itemSatStartTime"
                height="35px"
                width="65px"
                maxLength="5"
                placeholder="18:00"
                value={handler.horarySaturday.specificHourStart}
                onChange={(t) => {
                  handler.horarySaturday.specificHourStart = timeMask(t.target.value);
                }}
              />
              <ItemInputLabelRegular margtop="10px" pad="5px">
                às
              </ItemInputLabelRegular>
              <ItemInput
                name="itemSatEndTime"
                height="35px"
                width="65px"
                maxLength="5"
                placeholder="23:00"
                value={handler.horarySaturday.specificHourEnd}
                onChange={(t) => {
                  handler.horarySaturday.specificHourEnd = timeMask(t.target.value);
                }}
              />
            </SpecificHourBox>
          </HourDisponibilityBox>
        </Row>
      </Col>
    </>
  )
}
