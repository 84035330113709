export function getKuppiBoost() {
  return {
    type: '@kuppiBoost/GET_KUPPI_BOOST',
  };
}

export function setKuppiBoost(
  campaigns,
  available_coupons,
  available_products,
  payments_data
) {
  return {
    type: '@kuppiBoost/SET_KUPPI_BOOST',
    payload: {
      campaigns,
      available_coupons,
      available_products,
      payments_data,
    },
  };
}

export function setCurrentStep(newStep) {
  return {
    type: '@kuppiBoost/SET_CURRENT_STEP',
    payload: newStep,
  };
}

export function setFacebookId(newId) {
  return {
    type: '@kuppiBoost/SET_FACEBOOK_ID',
    payload: newId,
  };
}

export function setInstagramId(newId) {
  return {
    type: '@kuppiBoost/SET_INSTAGRAM_ID',
    payload: newId,
  };
}

export function setTempBalance(total) {
  return {
    type: '@kuppiBoost/SET_TEMP_BALANCE',
    payload: total,
  };
}

export function setIsFinished(status) {
  return {
    type: '@kuppiBoost/SET_IS_FINISHED',
    payload: status,
  };
}

export function setBalanceModalCurrentStep(newStep) {
  return {
    type: '@kuppiBoost/SET_BALANCE_MODAL_CURRENT_STEP',
    payload: newStep,
  };
}

export function setCampaignType(type) {
  return {
    type: '@kuppiBoost/SET_CAMPAIGN_TYPE',
    payload: type,
  };
}

export function setSelectedCoupon(coupon) {
  return {
    type: '@kuppiBoost/SET_SELECTED_COUPON',
    payload: coupon,
  };
}
export function setTitle(string) {
  return {
    type: '@kuppiBoost/SET_TITLE',
    payload: string,
  };
}
export function setMainText(string) {
  return {
    type: '@kuppiBoost/SET_MAIN_TEXT',
    payload: string,
  };
}

export function setSelectedProduct(product) {
  return {
    type: '@kuppiBoost/SET_SELECTED_PRODUCT',
    payload: product,
  };
}

export function setCampaignCurrentStep(step) {
  return {
    type: '@kuppiBoost/SET_CAMPAIGN_CURRENT_STEP',
    payload: step,
  };
}

export function getGenAds(rangeArea, balance) {
  return {
    type: '@kuppiBoost/GET_GEN_ADS',
    payload: { rangeArea, balance },
  };
}

export function setGenAds(gen_ads) {
  return {
    type: '@kuppiBoost/SET_GEN_ADS',
    payload: { gen_ads },
  };
}

export function getFacebookCheck() {
  return {
    type: '@kuppiBoost/GET_FACEBOOK_CHECK',
  };
}

export function setOnboardingData(data) {
  return {
    type: '@kuppiBoost/SET_KUPPI_BOOST_ONBOARDING',
    payload: data,
  };
}

export function getOnboardingData() {
  return {
    type: '@kuppiBoost/GET_KUPPI_BOOST_ONBOARDING',
  };
}

export function updateOnboardingData(
  facebook_id,
  instagram_id,
  cbClose,
  cbUpdate = () => null
) {
  return {
    type: '@kuppiBoost/UPDATE_KUPPI_BOOST_ONBOARDING',
    payload: { facebook_id, instagram_id, cbClose, cbUpdate },
  };
}

export function setOnboardingUpdateLoader(status) {
  return {
    type: '@kuppiBoost/SET_KUPPI_BOOST_UPDATE_LOADER',
    payload: status,
  };
}

export function createCampaign(campaign_budget, rangeArea) {
  return {
    type: '@kuppiBoost/CREATE_CAMPAIGN_REQUEST',
    payload: { campaign_budget, rangeArea },
  };
}

export function setBalance(total) {
  return {
    type: '@kuppiBoost/SET_BALANCE',
    payload: total,
  };
}

export function setCreateCampaignSuccess() {
  return {
    type: '@kuppiBoost/SET_CREATE_CAMPAIGN_SUCCESS',
  };
}

export function setCreateCampaignFailure() {
  return {
    type: '@kuppiBoost/SET_CREATE_CAMPAIGN_FAILURE',
  };
}
export function cleanCampaignData() {
  return {
    type: '@kuppiBoost/CLEAN_CAMPAIGN_DATA',
  };
}
export function createPixPaymentSuccess(code) {
  return {
    type: '@kuppiBoost/CREATE_PIX_PAYMENT_SUCCESS',
    payload: code,
  };
}
export function createPixPaymentFailure() {
  return {
    type: '@kuppiBoost/CREATE_PIX_PAYMENT_FAILURE',
  };
}

export function createPixPaymentRequest(total_amount, budget, processing_fee) {
  return {
    type: '@kuppiBoost/CREATE_PIX_PAYMENT_RESQUEST',
    payload: { total_amount, budget, processing_fee },
  };
}

export function cancelPixPaymentRequest(payment_id) {
  return {
    type: '@kuppiBoost/CANCEL_PIX_PAYMENT_RESQUEST',
    payload: { payment_id },
  };
}

export function finishOnboardingRequest() {
  return {
    type: '@kuppiBoost/FINISH_ONBOARDING_REQUEST',
  };
}

export function setPixPaymentCode(code) {
  return {
    type: '@kuppiBoost/SET_PIX_PAYMENT_CODE',
    payload: code,
  };
}

export function cancelPixPaymentSuccess() {
  return {
    type: '@kuppiBoost/CANCEL_PAYMENT_SUCCESS',
  };
}
export function cancelPixPaymentFailure() {
  return {
    type: '@kuppiBoost/CANCEL_PAYMENT_FAILURE',
  };
}
export function finishOnboardingSuccess() {
  return {
    type: '@kuppiBoost/FINISH_ONBOARDING_SUCCESS',
  };
}
export function finishOnboardingFailure() {
  return {
    type: '@kuppiBoost/FINISH_ONBOARDING_FAILURE',
  };
}

export function whatsappCheckRequest() {
  return {
    type: '@kuppiBoost/WHATSAPP_CHECK_REQUEST',
  };
}

export function whatsappCheckRequestSuccess(status) {
  return {
    type: '@kuppiBoost/WHATSAPP_CHECK_SUCCESS',
    payload: status,
  };
}

export function whatsappCheckRequestFailure() {
  return {
    type: '@kuppiBoost/WHATSAPP_CHECK_FAILURE',
  };
}
