import React from 'react';
import MuiAccordion from '@material-ui/core/Accordion';
import { withStyles } from '@material-ui/core';

import {
  cinzaKuppi8,
} from '../../../styles/colors';

export default function Accordion(props) {
  const Accordion = withStyles({
    root: {
      background: cinzaKuppi8,
      boxShadow: 'none',
      display:'block',
      '&$expanded': {
        margin: 'auto',
        borderTop: '1px solid #DBDBDB',
      },
    },
    expanded: {},
  })(MuiAccordion);

  return (
    <Accordion {...props}>
      {props.children}
    </Accordion>
  );
}
