import React from 'react';

import { Container, Filler } from './styles';

function ProgressBar({ bgcolor, completed }) {
  const progressBar = (
    <Container>
      <Filler completed={completed} bgcolor={bgcolor} />
    </Container>
  );

  return progressBar;
}

export default ProgressBar;
