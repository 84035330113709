import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InputRange from 'react-input-range';
import { Progress } from 'reactstrap';
import { Radio } from '@material-ui/core';
import { Spinner } from 'reactstrap';
import {
  cinzaKuppi7,
  preto,
  preto2,
  verdeKuppi5,
} from '../../../../styles/colors';
import { Text } from '../../../../styles/common';
import { Col, Row } from '../../../../styles/grid';
import px2vw from '../../../../utils/responsiveness/px2vw';
import { formatCurrencyBRL } from '../../../../utils/utils';
import {
  ModalSecundaryButtonText,
  ModalSecundaryButton,
} from '../../../Modal/styles';
import {
  CloseButton,
  ModalHeader,
  CloseImage,
  ProgressBar,
  Section,
  PaymentMethodImg,
  ButtonPrimary,
  Box,
  CustonInputRange,
} from './style';

import {
  createPixPaymentRequest,
} from '../../../../store/modules/kuppiBoost/actions';

export default function PaymentData(props) {
  const global = useSelector((state) => state);
  const { create_pix_payment_loading } = global.kuppiBoost;
  const dispatch = useDispatch();

  const [balanceToAdd, setBalanceToAdd] = useState(250);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('');
  const processFee = process.env.REACT_APP_PROCESS_FEE_PERCENT / 100;
  const totalProcessFeeFormated = formatCurrencyBRL(
    balanceToAdd * processFee,
    2
  );
  const totalAmount = (balanceToAdd * processFee) + balanceToAdd;
  const totalProcessFee = (balanceToAdd * processFee);

  const handleConfirmBalanceAddition = () => {
    dispatch(
      createPixPaymentRequest(totalAmount, balanceToAdd, totalProcessFee)
    );
  };

  return (
    <>
      <ModalHeader>
        Adicionar Saldo
        <CloseButton onClick={props.onClose}>
          <CloseImage
            src={require('../../../../assets/images/close/close.png')}
          />
        </CloseButton>
      </ModalHeader>
      <ProgressBar>
        <Progress
          value={0}
          bar={true}
          style={{
            backgroundColor: verdeKuppi5,
            borderRaduis: '0px',
            height: '4px',
          }}
        />
      </ProgressBar>
      <Section>
        <Col height="auto" justifyStart>
          <Text size={px2vw(17)} color={preto} bold justifyStart>
            Deslize para definir o valor a ser impulsionado
          </Text>
          <Box>
            <Row height="auto" spaceBetween>
              <Text size={px2vw(13)} color={cinzaKuppi7} bold justifyStart>
                R$100
              </Text>
              <Text size={px2vw(13)} color={cinzaKuppi7} bold justifyStart>
                R$1000
              </Text>
            </Row>
            <Row alignStart justifyCenter height="auto">
              <CustonInputRange>
                <InputRange
                  maxValue={1000}
                  minValue={100}
                  step={50}
                  value={balanceToAdd}
                  onChange={(value) => setBalanceToAdd(value)}
                />
              </CustonInputRange>
            </Row>
            <Text
              size={px2vw(13)}
              margtop={px2vw(10)}
              color={preto}
              bold
              justifyStart
            >
              Valor impulsionado{' '}
              {formatCurrencyBRL(parseFloat(balanceToAdd), 2)}
            </Text>
            <Text
              size={px2vw(9)}
              margtop={px2vw(4)}
              color={preto}
              regular
              justifyStart
            >
              Taxa de processamento {totalProcessFeeFormated}
            </Text>
            <Text
              size={px2vw(14)}
              margtop={px2vw(4)}
              color={preto2}
              bold
              justifyStart
            >
              Valor total a ser pago:{' '}
              {formatCurrencyBRL(
                parseFloat(balanceToAdd + balanceToAdd * processFee),
                2
              )}
            </Text>
          </Box>
          <Text
            size={px2vw(15)}
            margtop={px2vw(20)}
            color={preto2}
            bold
            justifyStart
          >
            Forma de pagamento
          </Text>

          <Row justifyStart alignCenter height={px2vw(30)} margtop={px2vw(15)}>
            Pix
            <PaymentMethodImg
              src={require('../../../../assets/images/payment-methods/logo-pix.png')}
            />
            <Radio
              onClick={() => setSelectedPaymentMethod('pix')}
              className="ml-auto"
              color="default"
            />
          </Row>
          <Text
            size={px2vw(10)}
            margtop={px2vw(4)}
            color={preto}
            regular
            justifyStart
          >
            *O pagamento tem um prazo máximo de processamento de 1 dia útil.
          </Text>
          <Row height="auto" spaceAround margtop={px2vw(80)}>
            <ModalSecundaryButton
              width={px2vw(220)}
              onClick={props.onClose}
              type="button"
            >
              <ModalSecundaryButtonText>Cancelar</ModalSecundaryButtonText>
            </ModalSecundaryButton>

            <ButtonPrimary
              onClick={handleConfirmBalanceAddition}
              complete={selectedPaymentMethod && balanceToAdd}
              disabled={
                !create_pix_payment_loading &&
                (!selectedPaymentMethod ||
                !balanceToAdd)
              }
              width={px2vw(220)}
            >
              {create_pix_payment_loading ? (
                <Spinner
                  style={{
                    width: px2vw(15),
                    height: px2vw(15),
                    color: preto,
                  }}
                  className="m-auto"
                />
              ) : (
                'Adicionar Saldo'
              )}
            </ButtonPrimary>
          </Row>
        </Col>
      </Section>
    </>
  );
}
