import React from "react";
import { toast } from 'react-toastify';
import { FormControlLabel } from "@material-ui/core";

import {
  NormalText,
  Image,
} from '../../../../BaseScreen/styles';
import { Row, Col } from '../../../../../../styles/grid';
import { branco, preto, cinzaKuppi2 } from '../../../../../../styles/colors';
import { SwitchController, SwitchWithInput } from "../../../../Step2-DeliveryParams/Mobile/styles";
import { SwitchInput } from "../../styles";

import px2vw from '../../../../../../utils/responsiveness/px2vw';
import { amountMask } from "../../../../../../utils/masks";
import { onlyNumbersAmount } from "../../../../../../utils/utils";

export default function Product({ product, handler }) {
  return (
    <Row 
      padleft={px2vw(70)}
      padright={px2vw(20)} 
      background={branco}
      bordertop={`2px solid ${cinzaKuppi2}`}
      alignCenter
    >
      <Row padright={px2vw(60)} justifyStart>
        {product.productImage ? (
          <Image src={require('../../../../../../assets/images/camera/camera@2x.png')}
            width={px2vw(80)}
            height={px2vw(70)}
          />
        ) : (
          <Image src={require('../../../../../../assets/images/camera/no_camera@2x.png')}
            width={px2vw(80)}
            height={px2vw(65)}
          />
        )}
        <Row 
          justifyStart
          margleft={px2vw(60)}
        >        
          <NormalText
            textalign='flex-start'
            fontweight='bold'
            color={preto}
            fontsize={px2vw(45)}
          >
            {product.name}
          </NormalText>
        </Row>
      </Row>
      <Col alignEnd width="auto" margtop={px2vw(30)}>
        <Row
          onClick={() => {
            if (handler.currEditProductId !== product.id) {
              handler.setCurrEditProductId(product.id);
              handler.setCurrEditProductAmount(amountMask(product.amount.toFixed(2)));
            }
          }}
        >
          <SwitchInput
            name={`editProductAmount-${product.id}`}
            maxLength="10"
            placeholder="R$ 4.50"
            width={px2vw(430)}
            margleft="0px"
            padleft={px2vw(35)}
            margbottom={px2vw(35)}
            margtop={px2vw(10)}
            value={handler.currEditProductId !== product.id ? amountMask(product.amount.toFixed(2)) : handler.currEditProductAmount}
            onChange={(t) => handler.setCurrEditProductAmount(amountMask(t.target.value))}
            readonly={handler.currEditProductId !== product.id}
            cursor="pointer"
            disabled={handler.updateProductAmountLoading && handler.currEditProductId === product.id}
          />
          <Row margleft={px2vw(-190)} margtop={px2vw(40)} cursor="pointer">
            <Image src={require('../../../../../../assets/images/onboarding/editButton@2x.png')}
              width={px2vw(60)}
              height={px2vw(60)}
              cursor="pointer"
            />
          </Row>
        </Row>
        {handler.currEditProductId !== product.id && (
          <Row minwidth={px2vw(430)}>
            {product.productComplementCategories.length > 0 && (
              <Image src={require('../../../../../../assets/images/onboarding/productOption@2x.png')}
                width={px2vw(150)}
                height={px2vw(150)}
                cursor="pointer"
                onClick={() => {
                  handler.clearCurrEditProductData();
                  handler.handleOpenComplementModal(product.productComplementCategories);
                }}
              />
            )}
            <Row padtop={px2vw(30)} width="auto">
              <SwitchWithInput 
                key={'pause-product'}
                marginBottom={px2vw(40)}
                margright={px2vw(-35)}
                margleft={product.productComplementCategories.length > 0 ? px2vw(140) : px2vw(290)}
              >
                <FormControlLabel
                  control={
                    <SwitchController
                      checked={product.is_active}
                      type="checkbox"
                      onChange={() => {
                        handler.handlePauseProduct(product);
                      }}
                    />
                  }
                />
              </SwitchWithInput>
            </Row>
          </Row>
        )}
        {handler.currEditProductId === product.id && (
          <Row 
            width={px2vw(430)} 
            minheight={px2vw(200)} 
            margright={px2vw(55)}
            padtop={px2vw(40)}
            spaceAround
          >
            <Image
              width={px2vw(75)}
              height={px2vw(70)}
              src={require('../../../../../../assets/images/onboarding/confirm_edit@2x.png')}
              cursor="pointer"
              onClick={() => {
                if (!handler.updateProductAmountLoading) {
                  if (handler.currEditProductAmount.length < 7) {
                    toast.error('Valor do produto inválido', {
                      autoClose: 6000,
                    });
                  } else {
                    handler.handleUpdateProductAmount(product.id, onlyNumbersAmount(handler.currEditProductAmount));
                  }
                }
              }}
            />
            <Image
              width={px2vw(73)}
              height={px2vw(73)}
              src={require('../../../../../../assets/images/onboarding/cancel_edit@2x.png')}
              cursor="pointer"
              onClick={() => {
                if (!handler.updateProductAmountLoading) {
                  handler.clearCurrEditProductData();
                }
              }}
            />
          </Row>
        )}
      </Col>
    </Row>
  )
}