import styled from 'styled-components';
import { branco, cinzaKuppi4 } from '../../styles/colors';
import px2vw from '../../utils/responsiveness/px2vw';
import { keyframes } from 'styled-components';

const growth = keyframes`
  0% {
    height: 0;
    opacity: 0;
  }
  50% {
    height: auto;
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const ContentBox = styled.div`
  display: flex;
  border-radius: ${(props) => (props.radius ? props.radius : px2vw(16))};
  background-color: ${branco};
  width: ${(props) => (props.width ? props.width : px2vw(355))};
  height: ${(props) => (props.height ? props.height : '100%')};
  box-shadow: 0px 8px 9px -2px ${cinzaKuppi4};
  padding: ${(props) => (props.padding ? props.padding : '')};
  flex-direction: ${(props) => (props.column ? 'column' : 'row')};
  cursor:pointer;
`;

export const TextContent = styled.div`
  font-size: 16px;
  font-family: AvenirNextLTPro;
  font-weight: 700;
  animation-name: ${growth};
  animation-duration: 0.4s;
  overflow: clip;

  ${(props) => props.textAlign && `text-align: ${props.textAlign};`}
  ${(props) => props.width && `width: ${props.width};`}
  ${(props) => props.size && `font-size: ${props.size};`}
  ${(props) => props.color && `color: ${props.color};`}
  ${(props) => props.margin && `margin: ${props.margin};`}
  ${(props) =>
    props.bold &&
    `font-family: AvenirNextLTPro;
font-weight: 700;`}
  ${(props) =>
    props.regular &&
    `font-family: AvenirNextLTPro;
font-weight: normal;`}

  .bold {
    font-family: AvenirNextLTProBold !important;
  }
`;

export const Text = styled.div`
  font-size: 16px;
  font-family: AvenirNextLTPro;
  font-weight: 700;
  animation-name: ${growth};

  ${(props) => props.textAlign && `text-align: ${props.textAlign};`}
  ${(props) => props.width && `width: ${props.width};`}
  ${(props) => props.size && `font-size: ${props.size};`}
  ${(props) => props.color && `color: ${props.color};`}
  ${(props) => props.margin && `margin: ${props.margin};`}
  ${(props) =>
    props.bold &&
    `font-family: AvenirNextLTPro;
font-weight: 700;`}
  ${(props) =>
    props.regular &&
    `font-family: AvenirNextLTPro;
font-weight: normal;`}

  .bold {
    font-family: AvenirNextLTProBold !important;
  }
`;
