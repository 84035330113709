import React, { useState, useEffect, useCallback } from 'react';
import { Badge, Progress } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Radio } from '@material-ui/core';

import {
  Container,
  BookIcon,
  Span,
  ProgressBar,
  StepTittle,
  BackIcon,
  ProductItem,
  ProductPrice,
  ProductDescription,
  ProductImg,
  ComplementItem,
  ComplementMenu,
  ComplementMenuHeader,
  BtnControl,
  ChatImg,
  TxtArea,
  ModalFooter,
  ManualOrderButtonPrimary,
} from './styles';
import {
  cinzaKuppi1,
  cinzaKuppi3,
  cinzaKuppi5,
  preto,
  verdeKuppi5,
  vermelhoKuppi1,
} from '../../../styles/colors';
import { Row, Col } from '../../../styles/grid';

import { amountMask } from '../../../utils/masks';
import numToWords from '../../../utils/numToWords';
import { formatCurrencyBRL } from '../../../utils/utils';

import px2vw from '../../../utils/responsiveness/px2vw';

import {
  addToBag,
  setCurrProductViewDetails,
  setCurrStep,
  updateProductInBag,
} from '../../../store/modules/manualOrder/actions';

export default function ProductDetails(props) {
  const dispatch = useDispatch();
  const manualOrderData = useSelector((state) => state?.manualOrder);
  const product = manualOrderData.currProductViewDetails;
  const previousStep = useSelector(
    (state) => state.manualOrder?.previousStep
  );
  const isEdittingItemFromBag = previousStep === '2-2';
  const complements = isEdittingItemFromBag ? product.selectedComplements : product.productComplementCategories;
  const [selectedComplements, setSelectedComplements] = useState(isEdittingItemFromBag ? product.selectedComplements : null);
  const [canAddToCart, setCanAddToCart] = useState(false);
  const [productCount, setProductCount] = useState(isEdittingItemFromBag ? product.productQuantity : 1);
  const [price, setPrice] = useState(isEdittingItemFromBag ? product.priceWithComplements : product?.amount);
  const [priceNoDiscount, setPriceNoDiscount] = useState(isEdittingItemFromBag ? product.priceNoDiscountWithComplements : product?.amount);
  const [productToBag, setProductToBag] = useState(isEdittingItemFromBag ? product : null);
  const [observation, setObservation] = useState(isEdittingItemFromBag ? product.obs : '');

  const [complementOptions, setComplementOptions] = useState(
    complements.map((complement) => ({
      id: complement.id,
      name: complement.name,
      min_qty: complement.min_qty,
      max_qty: complement.max_qty,
      is_required: complement.is_required,
      is_base_amount_more_expensive: complement.is_base_amount_more_expensive,
      options: complement.options
        ? complement.options.map((option) => ({
            id: option.id,
            name: option.name,
            description: option.description,
            amount: option.amount,
            is_selected: option.is_selected,
            quantity: option.quantity,
          }))
        : complement.productComplementOptions.map((option) => ({
            id: option.id,
            name: option.name,
            description: option.description,
            amount: option.amount,
            is_selected: false,
            quantity: 0,
          })),
    }))
  );

  useEffect(() => {
    const productWithSelectedComplements = { ...product };

    productWithSelectedComplements.selectedComplements = selectedComplements;
    productWithSelectedComplements.productQuantity = productCount;
    productWithSelectedComplements.obs = observation;
    productWithSelectedComplements.priceNoDiscountWithComplements = priceNoDiscount;
    productWithSelectedComplements.priceWithComplements = price;

    setProductToBag(productWithSelectedComplements);
  }, [product, productCount, selectedComplements, price, observation]);

  useEffect(() => {
    setComplementOptions(() => {
      return complements.map((complement) => ({
        id: complement.id,
        name: complement.name,
        min_qty: complement.min_qty,
        max_qty: complement.max_qty,
        is_required: complement.is_required,
        is_base_amount_more_expensive: complement.is_base_amount_more_expensive,
        options: complement.options
          ? complement.options.map((option) => ({
              id: option.id,
              name: option.name,
              description: option.description,
              amount: option.amount,
              is_selected: option.is_selected,
              quantity: option.quantity,
            }))
          : complement.productComplementOptions.map((option) => ({
              id: option.id,
              name: option.name,
              description: option.description,
              amount: option.amount,
              is_selected: false,
              quantity: 0,
            })),
      }));
    });

  }, [complements]);

  const markItemAsSelected = (complement_id, option_id) => {
    setComplementOptions((prevState) => {
      const complement = prevState.find((c) => c.id === complement_id);
      const option = complement?.options.find((o) => o.id === option_id);

      const total = complement?.options?.forEach((o) => {
        if (o.id === option.id) {
          o.is_selected = true;
          o.quantity = 1;
        } else {
          o.is_selected = false;
          o.quantity = 0;
        }
      });
      const newState = prevState.map((prevComplement) => {
        if (prevComplement.id === complement_id) {
          return complement;
        }
        return prevComplement;
      });
      return newState;
    });
  };

  const onChangeQuantity = useCallback(
    (complement_id, option_id, shouldIncrease) => {
      const complement = complementOptions.find((c) => c.id === complement_id);
      const option = complement?.options?.find((o) => o.id === option_id);
      let optionsSum = 0;
      const total = complement?.options?.forEach((o) => {
        optionsSum += o.quantity;
      });
      if (shouldIncrease && optionsSum < complement?.max_qty) {
        option.quantity += 1;
      } else if (!shouldIncrease && optionsSum !== 0) {
        option.quantity -= 1;
      }

      const newState = complementOptions.map((prevComplement) => {
        if (prevComplement.id === complement_id) {
          return complement;
        }
        return prevComplement;
      });

      setComplementOptions(newState);
    },
    [complementOptions]
  );

  const handleOnChangeComplementOptions = useCallback(
    (complements) => {
      // Verificar se todos os complementos obrigatórios foram selecionados
      const isRequiredComplementsFullfiled = complements
        .filter((c) => c.is_required)
        .every((c) => {
          let optionsSum = 0;
          c.options.forEach((o) => {
            optionsSum += o.quantity;
          });
          return c.options.some(
            (o) => o.is_selected || optionsSum >= c.min_qty
          );
        });

      // Calcular preço dos complementos
      let complementsTotalPrice = 0;
      complements.forEach((c) => {
        if (c.is_base_amount_more_expensive) {
          let arrAmountOptions = c.options.map((o) => {
            return o.quantity > 0 && o.amount;
          });
          let moreExpensiveOption = Math.max.apply(null, arrAmountOptions);

          c.options.forEach((o) => {
            complementsTotalPrice += moreExpensiveOption * o.quantity;
          });
        } else {
          c.options.forEach((o) => {
            if (o.is_selected && o.amount) {
              complementsTotalPrice += o.amount;
            } else if (o.quantity && o.amount) {
              complementsTotalPrice += o.amount * o.quantity;
            }
          });
        }
      });

      setCanAddToCart(isRequiredComplementsFullfiled);

      setPrice(product?.amount + complementsTotalPrice);

      setPriceNoDiscount((_) => {
        const basePrice = product?.amount;
        return basePrice + complementsTotalPrice;
      });

      if (isRequiredComplementsFullfiled) {
        setSelectedComplements(() => complements);
      }
    },
    [product, setSelectedComplements]
  );

  useEffect(() => {
    handleOnChangeComplementOptions(complementOptions);
  }, [handleOnChangeComplementOptions, complementOptions]);

  const getCurrentQuantity = useCallback(
    (complement_id) => {
      const complement = complementOptions?.find((c) => c.id === complement_id);
      let optionsSum = 0;

      const total = complement?.options?.forEach((o) => {
        optionsSum += o.quantity;
      });

      return optionsSum;
    },
    [complementOptions]
  );

  function handleBackTo() {
    dispatch(setCurrProductViewDetails(null));

    if (isEdittingItemFromBag) {
      dispatch(setCurrStep(previousStep));
    } else {
      dispatch(setCurrStep('2', previousStep));
    }
  }

  function handleAddItemTobag() {
    if (isEdittingItemFromBag) {
      dispatch(updateProductInBag(productToBag));
      dispatch(setCurrStep(previousStep));
    } else {
      dispatch(addToBag(productToBag));
      dispatch(setCurrStep('2', previousStep));
    }
  }

  const handleOnChangeProductCount = (e) => {
    if (e.target.id === 'add') {
      setProductCount(productCount + 1);
    }
    if (e.target.id === 'remove' && productCount > 1) {
      setProductCount(productCount - 1);
    }
  };
  return (
    <>
      <ProgressBar>
        <Progress
          value={40}
          bar={true}
          style={{
            backgroundColor: verdeKuppi5,
            borderRaduis: '0px',
            height: '10px',
          }}
        />
      </ProgressBar>
      <Row
        justifyStart
        alignCenter
        className="py-1 px-2"
        cursor="pointer"
        background={cinzaKuppi1}
        onClick={() => handleBackTo()}
      >
        <BackIcon
          src={require('../../../assets/images/arrows/arrow_left.png')}
        />
        <Span padding={px2vw(3)} color={preto}>
          Voltar
        </Span>
      </Row>
      <Row alignEnd justifyStart className="px-4 mt-1">
        <BookIcon src={require('../../../assets/images/book/book.png')} />
        <StepTittle>
          {isEdittingItemFromBag && 'Editar Produto'}
          {!isEdittingItemFromBag && 'Detalhes do Produto'}
        </StepTittle>
      </Row>

      <ProductItem>
        <Col>
          <Row>
            <ProductImg
              src={require('../../../assets/images/camera/camera.png')}
            />
            <ProductDescription>{product?.name}</ProductDescription>
            <ProductPrice>
              {product?.amount !== 0 &&
                amountMask(Math.round(product?.amount * 100))}
            </ProductPrice>
          </Row>

          {product?.description && (
            <Row justifyStart>
              <Span
                className="my-2"
                color={cinzaKuppi5}
                fontSize={px2vw(10)}
                fontWeight="400"
              >
                {product?.description}
              </Span>
            </Row>
          )}
        </Col>
      </ProductItem>

      <Container>
        {complementOptions?.length > 0 &&
          complementOptions?.map((complement) => (
            <ComplementMenu key={complement.id}>
              <ComplementMenuHeader>
                <Span className="m-0" fontSize={px2vw(13)} color={preto}>
                  {complement.name}
                  {complement.is_required && (
                    <Badge className="p-1 ml-3">Obrigatório</Badge>
                  )}
                </Span>
                {complement.is_required && complement.max_qty > 1 && (
                  <>
                    <Span className="m-0" fontSize={px2vw(13)} color={preto}>
                      {`${getCurrentQuantity(complement.id)} de
                      ${complement.max_qty} opções`}
                    </Span>

                    <Span
                      className="m-0"
                      fontWeight="normal"
                      fontSize={px2vw(13)}
                      color={vermelhoKuppi1}
                    >
                      {getCurrentQuantity(complement.id) < complement.min_qty &&
                        `Mínimo ${
                          complement.min_qty === 1
                            ? 'uma'
                            : complement.min_qty === 2
                            ? 'duas'
                            : numToWords(complement.min_qty)
                        } ${complement.min_qty > 1 ? 'opções' : 'opção'}`}
                    </Span>
                  </>
                )}
                {!complement.is_required && complement.max_qty > 1 && (
                  <Span
                    className="m-0"
                    fontWeight="normal"
                    fontSize={px2vw(13)}
                    color={preto}
                  >
                    {`Até ${complement.max_qty} opções`}
                  </Span>
                )}
                {complement.is_required && complement.max_qty === 1 && (
                  <Span
                    className="m-0"
                    fontWeight="normal"
                    fontSize={px2vw(13)}
                    color={preto}
                  >
                    Selecione uma opção
                  </Span>
                )}
                {!complement.is_required && complement.max_qty === 1 && (
                  <Span
                    className="m-0"
                    fontWeight="normal"
                    fontSize={px2vw(13)}
                    color={preto}
                  >
                    Até uma opção
                  </Span>
                )}
              </ComplementMenuHeader>
              <Col>
                {complement?.options?.map((option) =>
                  complement.max_qty > 1 ? (
                    <ComplementItem key={option.id}>
                      <Col className="my-auto">
                        <Span
                          className="m-0"
                          fontWeight="normal"
                          fontSize={px2vw(12)}
                          color={preto}
                        >
                          {option.name}
                        </Span>
                        {option.amount > 0 && (
                          <Span
                            className="m-0"
                            fontWeight="300"
                            fontSize={px2vw(10)}
                            color={cinzaKuppi3}
                          >
                            {`+ ${formatCurrencyBRL(option.amount, 2)}`}
                          </Span>
                        )}
                      </Col>
                      <Row margleft="auto" alignEnd justifyEnd>
                        {option.quantity > 0 && (
                          <>
                            <BtnControl
                              fontWeight="bolder"
                              onClick={() =>
                                onChangeQuantity(
                                  complement.id,
                                  option.id,
                                  false
                                )
                              }
                            >
                              -
                            </BtnControl>

                            <Span
                              className="mx-2 my-auto"
                              fontWeight="bolder"
                              fontSize={px2vw(15)}
                              color={preto}
                            >
                              {option.quantity}
                            </Span>
                          </>
                        )}
                        <BtnControl
                          fontWeight="bolder"
                          onClick={() =>
                            onChangeQuantity(complement.id, option.id, true)
                          }
                        >
                          +
                        </BtnControl>
                      </Row>
                    </ComplementItem>
                  ) : (
                    <ComplementItem
                      key={option.id}
                      onClick={() =>
                        markItemAsSelected(complement.id, option.id)
                      }
                    >
                      <Col className="my-auto" width="100%">
                        <Span
                          className="m-0"
                          fontWeight="normal"
                          fontSize={px2vw(12)}
                          color={preto}
                        >
                          {option.name}
                        </Span>
                        {option.amount > 0 && (
                          <Span
                            className="m-0"
                            fontWeight="300"
                            fontSize={px2vw(10)}
                            color={cinzaKuppi3}
                          >
                            {`+ ${formatCurrencyBRL(option.amount, 2)}`}
                          </Span>
                        )}
                      </Col>
                      <Row className="my-auto" margleft="auto" alignEnd justifyEnd>
                        <Radio checked={option.is_selected} color="default" />
                      </Row>
                    </ComplementItem>
                  )
                )}
              </Col>
            </ComplementMenu>
          ))}
        <Col className="px-3 mt-3 mb-2" justifyStart>
          <Span color={preto} className="mb-2" fontSize={px2vw(13)}>
            <ChatImg
              className="mr-2"
              src={require('../../../assets/images/Chat/chat.png')}
            />
            Alguma Observação?
          </Span>
          <TxtArea
            value={observation}
            onChange={(e) => setObservation(e.target.value)}
            rows="4"
          />
        </Col>
      </Container>
      <ModalFooter className="px-4">
        <Row alignCenter className="mx-3" justifyStart>
          <BtnControl
            fontWeight="bolder"
            id="remove"
            disabled={productCount < 1}
            onClick={(e) => handleOnChangeProductCount(e)}
          >
            -
          </BtnControl>
          <Span className="mx-3" fontWeight="bolder" color={preto}>
            {productCount}
          </Span>
          <BtnControl
            fontWeight="bolder"
            id="add"
            onClick={(e) => handleOnChangeProductCount(e)}
          >
            +
          </BtnControl>

          <ManualOrderButtonPrimary
            disabled={!canAddToCart}
            onClick={() => handleAddItemTobag()}
          >
            {isEdittingItemFromBag && 'Atualizar'}
            {!isEdittingItemFromBag && 'Adicionar'}
            {price !== 0 && (
              <Span
                color={preto}
                className="ml-3"
                display="inline-block"
                fontSize={px2vw(15)}
              >
                {formatCurrencyBRL(price * productCount, 2)}
              </Span>
            )}
          </ManualOrderButtonPrimary>
        </Row>
      </ModalFooter>
    </>
  );
}
