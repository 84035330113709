import React, { useEffect } from 'react';
import { toast } from 'react-toastify';

import {
  TabContent,
  DragImageBox,
  DragImage,
  ModalText2,
  ProductInputLabel,
  ProductInput,
  ProductTextAreaInput,
  RemainingCharactersProduct,
  ProductSelectInput,
  SelectImageLabel,
  InputImage,
  ImageCoverPreview,
} from '../../styles';

import { Row, Col } from '../../../../styles/grid';

import {
  ModalPrimaryButton,
  ModalPrimaryButtonText,
  ModalActions,
  ModalSecundaryButton,
  ModalSecundaryButtonText,
} from '../../../../components/Modal/styles';

import px2vw from '../../../../utils/responsiveness/px2vw';
import {
  amountMask,
} from '../../../../utils/masks';

export default function TabDetails({ handler }) {
  // Handle product image edit or create image
  useEffect(() => {
    const supportedFormats = ['png', 'jpg', 'jpeg'];

    if (handler.fileProductData.base64 && handler.fileProductData.size && handler.fileProductData.size > 5) {
      toast.error('A foto do produto precisa ser menor que 5mb.', {
        autoClose: 6000,
      });
      handler.setFileProductData({...handler.fileProductData, base64: null });
    } else if (
      (handler.fileProductData.base64 &&
        handler.fileProductData.subtype &&
        !supportedFormats.includes(handler.fileProductData.subtype)) ||
      (handler.fileProductData.base64 &&
        handler.fileProductData.subtype &&
        !supportedFormats.includes(handler.fileProductData.subtype))
    ) {
      toast.error(
        'A foto do produto precisa ser do tipo .png, .jpg ou .jpeg.',
        {
          autoClose: 6000,
        }
      );
      handler.setFileProductData({...handler.fileProductData, base64: null });
    }

    // Get the real width and height of the image
    if (handler.fileProductData.base64) {
      const image = new Image();
      image.src = handler.fileProductData.base64;

      image.onload = () => {
        handler.setFileProductData({
          ...handler.fileProductData, 
          width: image.naturalWidth,
          height: image.naturalHeight
        });
      }
    }
  }, [handler.fileProductData.base64]);

  // Set the remaining chars of new product description
  function handleProductNameChange(newValue) {
    handler.setProductNameInputValue(newValue);
    handler.setProductNameRemainingChars(newValue.length);
  }

  // Set the remaining chars of new product description
  function handleNewProductDescriptionChange(newValue) {
    handler.setProductDescriptionInputValue(newValue);
    handler.setProductDescriptionRemainingChars(newValue.length);
  }

  // Handle file product image
  async function handleFileProduct(event) {
    const f = event.target.files[0];

    if (f) {
      const fname = f.name;
      const fsize = (f.size / 1000000).toFixed(1);
      const ftype = f.type.split('/')[0];
      const fsubtype = f.type.split('/')[1];

      const productFileReader = new FileReader();
      productFileReader.onload = function () {
        handler.setFileProductData({
          ...handler.fileProductData, 
          base64: productFileReader.result, 
          name: fname, 
          size: fsize, 
          type: ftype, 
          subtype: fsubtype 
        })
      };
      productFileReader.readAsDataURL(f);
    } else {
      handler.handleClearFileData();
    }
  }

  return (
    <TabContent>
      <Row height="auto">
        {/* Product image */}
        <Col>
          <Col alignCenter relative height="auto">
            <DragImageBox>
              {/* Has no selected image */}
              {((handler.handleProductTypeModal === 'edit' &&
                !handler.productImageURL) ||
                handler.handleProductTypeModal === 'new') &&
                !handler.fileProductData.base64 && (
                  <>
                    <DragImage
                      src={require('../../../../assets/images/upload_image/upload_image.png')}
                    />
                    <ModalText2>
                      Arraste e solte a foto aqui
                    </ModalText2>
                  </>
                )}
              {/* Has selected image */}
              {handler.fileProductData.base64 && (
                <ImageCoverPreview src={handler.fileProductData.base64} />
              )}
              {/* Edit modal with product image returned from database */}
              {handler.handleProductTypeModal === 'edit' &&
                handler.productImageURL &&
                !handler.fileProductData.base64 && (
                  <ImageCoverPreview
                    src={`${handler.productImageURL}?nocache=${new Date().getTime()}`}
                  />
                )}
            </DragImageBox>
            <InputImage
              type="file"
              id="productImage"
              name="productImage"
              onChange={handleFileProduct}
            />
          </Col>
          <Col height="auto">
            <SelectImageLabel htmlFor="productImage">
              + Escolher Foto
            </SelectImageLabel>
          </Col>
        </Col>
        <Col margleft={px2vw(15)}>
          <Row>
            {/* Product name */}
            <Col>
              <ProductInputLabel>Nome</ProductInputLabel>
              <ProductInput
                name="productName"
                placeholder="X-Burguer"
                maxLength="100"
                value={handler.productNameInputValue}
                onChange={(t) =>
                  handleProductNameChange(t.target.value)
                }
              />
              <Row alignEnd justifyEnd width={px2vw(280)}>
                <RemainingCharactersProduct>
                  {handler.productNameRemainingChars}/100 caracteres
                </RemainingCharactersProduct>
              </Row>
            </Col>
            {/* Product category */}
            <Col margleft={px2vw(25)}>
              <ProductInputLabel>Categoria</ProductInputLabel>
              <ProductSelectInput
                id="productCategory"
                name="productCategory"
                width={px2vw(280)}
                placeholder="Defina a categoria"
                value={handler.productCategoryInputValue}
                options={handler.categories}
                onChange={(t) =>
                  handler.setProductCategoryInputValue(t.target.value)
                }
              />
            </Col>
          </Row> 
          <Row margtop={px2vw(30)} height="auto">
            {/* Product description */}
            <Col>
              <ProductInputLabel>Descrição</ProductInputLabel>
              <ProductTextAreaInput
                id="productDescription"
                name="productDescription"
                placeholder="Dois Hamburguers de 150gr"
                maxLength="1000"
                width={px2vw(460)}
                height={px2vw(140)}
                value={handler.productDescriptionInputValue}
                onChange={(t) =>
                  handleNewProductDescriptionChange(t.target.value)
                }
              />
              <Row alignEnd justifyEnd width={px2vw(460)}>
                <RemainingCharactersProduct>
                  {handler.productDescriptionRemainingChars}/1000 caracteres
                </RemainingCharactersProduct>
              </Row>
            </Col>
            {/* Product amount */}
            <Col margleft={px2vw(18)}>
              <ProductInputLabel>Preço</ProductInputLabel>
              <ProductInput
                id="productAmount"
                name="productAmount"
                width={px2vw(105)}
                maxLength="10"
                placeholder="R$ 18,00"
                value={handler.productAmountInputValue}
                onChange={(t) =>
                  handler.setProductAmountInputValue(
                    amountMask(t.target.value)
                  )
                }
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <ModalActions>
        <ModalSecundaryButton
          margleft={px2vw(60)}
          marginRight={px2vw(20)}
          margtop={px2vw(30)}
          width={px2vw(290)}
          onClick={handler.handleCloseProductModal}
        >
          <ModalSecundaryButtonText>
            Cancelar
          </ModalSecundaryButtonText>
        </ModalSecundaryButton>
        <ModalPrimaryButton
          id="productNextButton"
          margleft={px2vw(20)}
          margtop={px2vw(30)}
          width={px2vw(290)}
          type="button"
          onClick={() => handler.validateProductDetails('tabComplements')}
        >
          <ModalPrimaryButtonText>Avançar</ModalPrimaryButtonText>
        </ModalPrimaryButton>
      </ModalActions>
    </TabContent>
  );
}
