import styled from 'styled-components';
import { IoArrowRedoOutline } from 'react-icons/io5';

import { Row } from '../../../styles/grid';

import px2vw from '../../../utils/responsiveness/px2vw';

import {
  branco,
  preto,
  cinzaKuppi1,
  cinzaKuppi4,
  verdeKuppi1,
  verdeKuppi4,
  cinzaKuppi3,
  cinzaKuppi9,
} from '../../../styles/colors';

export const Content = styled(Row)`
  padding-left: ${(props) => props.padLeft ? `${props.padLeft}` : px2vw(20)};
  padding-right: ${(props) => props.padRight ? `${props.padRight}` : px2vw(20)};
  font-size: ${px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.36px;
  height: ${px2vw(100)};
`;

export const Hr = styled.div`
  border-top: 2px solid ${cinzaKuppi1};
  height: 1px;
  width: 100%;
`;

export const TableRow = styled(Row)`
  :hover {
    background: ${cinzaKuppi9}80;
  }
`;

export const ErrorText = styled.div`
  font-size: ${px2vw(18)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.4px;
  margin-left: ${px2vw(20)};
`;

export const OrderImage = styled.img`
  height: ${px2vw(13)};
  width: ${px2vw(13)};
  margin-right: ${px2vw(10)};
  margin-top: ${px2vw(9)};
`;

export const ChangeStatusButton = styled.div`
  background-color: ${verdeKuppi1};
  border: 1px solid ${verdeKuppi1};
  color: ${branco};
  border-radius: ${px2vw(10)};
  height: ${px2vw(40)};
  width: ${px2vw(200)};
  font-size: ${px2vw(13)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.36px;
  text-align: center;
  padding-top: ${px2vw(10)};
  cursor: pointer;

  :hover {
    background-color: ${verdeKuppi4};
    border: 1px solid ${verdeKuppi1};
  }

  &:focus,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
    color: ${branco};
  }
`;

export const NextStatusIcon = styled(IoArrowRedoOutline).attrs({
  size: px2vw(20),
})`
  margin-top: ${px2vw(5)};
  margin-right: ${px2vw(15)};
  ${(props) => `
  margin-top: ${props.margtop};
`};
`;

export const PaginateContainer = styled.div`
  padding-top: ${px2vw(12)};

  .pagination {
    color: ${verdeKuppi1};
  }

  .page {
    text-align: center;
    border: 1px solid ${preto};
    border-radius: ${px2vw(5)};
    width: ${px2vw(28)};
    height: ${px2vw(27)};
    color: ${preto};
    padding-top: ${px2vw(3)};
    margin-left: ${px2vw(2)};
    margin-right: ${px2vw(2)};
    font-size: ${px2vw(13)};
    font-family: AvenirNextLTPro;
    font-weight: 700;
    letter-spacing: 0.36px;
  }

  .break_cn {
    cursor: default;
    margin-top: ${px2vw(-2)};
    margin-left: ${px2vw(2)};
    margin-right: ${px2vw(2)};
    font-size: ${px2vw(16)};
    font-family: AvenirNextLTPro;
    font-weight: 700;
    letter-spacing: 0.48px;
    color: ${preto};
  }

  .active {
    border: 1px solid ${verdeKuppi1};
    background-color: ${verdeKuppi1};
    color: ${branco};
  }

  .previous_cn, .next_cn {
    text-align: center;
    cursor: default;
    margin-left: ${px2vw(2)};
    margin-right: ${px2vw(2)};
    font-size: ${px2vw(15)};
    font-family: AvenirNextLTPro;
    font-weight: 700;
    letter-spacing: 0.48px;
    color: ${preto};
    padding-top: ${px2vw(1.2)};
    border: 1px solid ${preto};
    border-radius: ${px2vw(5)};
    width: ${px2vw(28)};
    height: ${px2vw(27)};
  }

  .previous_a_cn, .next_a_cn {
    text-decoration: none;
    color: ${preto};
    :hover {
      color: ${preto};
    }
  }

  .disabled_cn {
    color: ${cinzaKuppi4};
    border: 1px solid ${cinzaKuppi4};
    cursor: not-allowed;
  }

  .disabled_a_cn {
    color: ${cinzaKuppi4};
    cursor: not-allowed;
    text-decoration: none;
    :hover {
      color: ${cinzaKuppi4};
    }
  }
`;

export const ButtonCheckEmpty = styled.div`
  width: ${px2vw(15)};
  height: ${px2vw(15)};
  margin-right: ${px2vw(10)};
  border: ${px2vw(1)} solid ${cinzaKuppi3};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${px2vw(3)};
`;

export const ImageContainer = styled.div`
  width: ${px2vw(15)};
  height: ${px2vw(15)};
  display: none;
  margin-top: ${px2vw(-5)};

  ${(props) => props.checkVisible && `
    display: inline-block;
  `}

  ${(props) => props.showArrow && `
    display: inline-block;
    margin-right: ${px2vw(5)};
  `}

  ${(props) => `
    margin-top: ${props.margtop};
  `}
`;
