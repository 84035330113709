import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Row, Col } from '../../../../styles/grid';
import px2vw from '../../../../utils/responsiveness/px2vw';

import {
  TextBold,
  TextBold2,
} from '../styles';

import {
  ProductImage,
  LogoImage,
  TextRegular,
} from './styles';

export default function TopSoldProducts() {
  const [normalProductsArrPerLine, setNormalProductsArrPerLine] = useState([]);
  const cashReportTopSoldProducts = useSelector((state) => state.reports.cashReportTopSoldProducts);
  const user = useSelector((state) => state.user.user);

  useEffect(() => {
    if (cashReportTopSoldProducts) {
      // Separate the top sold products into 2 columns of 3 products
      setNormalProductsArrPerLine(cashReportTopSoldProducts.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / 2);

        if (!resultArray[chunkIndex]) {
          // eslint-disable-next-line no-param-reassign
          resultArray[chunkIndex] = [];
        }

        resultArray[chunkIndex].push(item);

        return resultArray;
      }, []));
    }
  }, [cashReportTopSoldProducts])

  return (
    <Col>
      <Row margbottom={px2vw(30)} justifyStart>
        <TextBold2>Produtos mais vendidos</TextBold2>
      </Row>
        <Col>
          {normalProductsArrPerLine.map((item, idxGroup) => {
            return (
              <Row key={`productGroup${idxGroup}`} justifyStart>
                {item.map((product, idx) => {
                  return (
                    <Row
                      key={`product-${idxGroup}-${product.id}`}
                      margbottom={px2vw(30)}
                      width={px2vw(480)}
                    >
                      <Col margtop={px2vw(30)}>
                        <TextBold>
                          {`${idxGroup + idxGroup + idx + 1}° `}
                          {product.product_name}
                        </TextBold>
                        <TextRegular margleft={px2vw(20)}>
                          {`${product.total_sold} vendido`}{product.total_sold > 1 && 's'}
                        </TextRegular>
                      </Col>
                      <Col>
                        {product.img_url === null ? (
                          <LogoImage src={user.avatar.url}/>
                        ) : (
                          <ProductImage src={product.img_url} />
                        )}
                      </Col>
                    </Row>
                  );
                })}
              </Row>
            );
          })}
        </Col>
    </Col>
  );
}