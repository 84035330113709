import { toast } from 'react-toastify';

import Formats from './printFormats/formats';

export function printOrderInvoice({
  orderStatusData,
  advertiserData,
  showErrorMessage = false,
}) {
  const invoicePrintWindow = window.open('', 'PRINT', 'width=600,height=400');

  if (!invoicePrintWindow && showErrorMessage) {
    toast.error(
      'Não foi possível abrir a página para impressão do pedido. Por favor permita em seu navegador, que a plataforma possa abrir popups.',
      {
        autoClose: false,
      }
    );
  } else {
    let invoice = '';

    invoice = Formats[advertiserData.advertiserInfo.advertiserPrintConfig.print_type]({ orderStatusData, advertiserData });
    
    invoicePrintWindow.document.write(invoice);

    invoicePrintWindow.document.close(); // necessary for IE >= 10
    invoicePrintWindow.focus(); // necessary for IE >= 10*/

    invoicePrintWindow.print();
    invoicePrintWindow.close();
  }
}

