export function getCashReportRequest(startDate, endDate) {
  return {
    type: '@reports/GET_CASH_REPORT_REQUEST',
    payload: { startDate, endDate },
  };
}

export function getCashReportSuccess(cashReport) {
  return {
    type: '@reports/GET_CASH_REPORT_SUCCESS',
    payload: { cashReport },
  };
}

export function getCashReportFailure() {
  return {
    type: '@reports/GET_CASH_REPORT_FAILURE',
  };
}

export function getPixReportRequest(page, startDate, endDate) {
  return {
    type: '@reports/GET_PIX_REPORT_REQUEST',
    payload: { page, startDate, endDate },
  };
}

export function getPixReportSuccess(pixReport) {
  return {
    type: '@reports/GET_PIX_REPORT_SUCCESS',
    payload: { pixReport },
  };
}

export function getPixReportFailure() {
  return {
    type: '@reports/GET_PIX_REPORT_FAILURE',
  };
}

export function getOrdersReportRequest(startDate, endDate) {
  return {
    type: "@reports/GET_ORDERS_REPORT_REQUEST",
    payload: {startDate, endDate}
  }
}

export function getOrdersReportSuccess(ordersReports) {
  return {
    type: "@reports/GET_ORDERS_REPORT_SUCCESS",
    payload: {ordersReports}
  }
}

export function getOrdersReportFailure() {
  return {
    type: "@reports/GET_ORDERS_REPORT_FAILURE"
  }
}
 
export function clearSuccess() {
  return {
    type: '@reports/CLEAR_SUCCESS',
  };
}

export function clearReportErrors() {
  return {
    type: '@reports/CLEAR_REPORT_ERRORS',
  };
}
