import React, {
  Fragment,
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Icon } from 'react-materialize';
import { Spinner } from 'reactstrap';
import { toast } from 'react-toastify';

import { Row, Col } from '../../styles/grid';
import px2vw from '../../utils/responsiveness/px2vw';
import { onlyNumbersAmount, formatCurrencyBRL } from '../../utils/utils';
import { amountMask } from '../../utils/masks';

import Modal from '../Modal';
import {
  ModalPrimaryButton,
  ModalPrimaryButtonText,
  ModalActions,
  ModalSecundaryButton,
  ModalSecundaryButtonText,
} from '../Modal/styles';

import {
  Header,
  Content,
  Hr,
  TableRow,
  HeaderButton,
  PagButton,
  PrevIcon,
  NextIcon,
  NavText,
  ErrorText,
  UpdateModal,
  UpdateModalText,
  ModalInputLabelText,
  ModalInput,
  ConfirmDeleteModal,
  ConfirmDeleteModalText,
  LoadingBox,
} from './styles';

import { preto } from '../../styles/colors';

import { SvgBox, Arrow, Arrow2 } from './icons';
import { sort, makePages, sortByColumnValue } from './tableOptions';

import NeighborhoodManageButton from './NeighborhoodManageButton';

import {
  pauseNeighborhoodRequest,
  updateNeighborhoodRequest,
  deleteNeighborhoodRequest,
} from '../../store/modules/myStore/actions';

export default function AdvertiserSelectedNeighborhoodsTable() {
  const selectedNeighborhoods = useSelector(
    (state) => state.myStore.selectedNeighborhoods
  );
  const [rows, setRows] = useState([]);
  const [rowsCurrentPage, setRowsCurrentPage] = useState();
  const [structure, setStructure] = useState(null);
  const [currentPage, setCurrentPage] = useState('1');
  const [lastPage, setLastPage] = useState();
  const [firstPage] = useState('1');
  const [sortedCol, setSortedCol] = useState('');
  const [updateNeighborhoodObj, setUpdateNeighborhoodObj] = useState(null);
  const [
    updateNeighborhoodDeliveryFee,
    setUpdateNeighborhoodDeliveryFee,
  ] = useState(null);
  const [updateNeighborhoodModal, setUpdateNeighborhoodModal] = useState(false);
  const [
    confirmDeleteNeighborhoodIdx,
    setConfirmDeleteNeighborhoodIdx,
  ] = useState(null);
  const [
    confirmDeleteNeighborhoodModal,
    setConfirmDeleteNeighborhoodModal,
  ] = useState(false);

  const pauseNeighborhoodLoading = useSelector(
    (state) => state.myStore.pauseNeighborhoodLoading
  );
  const pauseNeighborhoodSuccess = useSelector(
    (state) => state.myStore.pauseNeighborhoodSuccess
  );
  const updateNeighborhoodModalLoading = useSelector(
    (state) => state.myStore.updateNeighborhoodModalLoading
  );
  const updateNeighborhoodModalSuccess = useSelector(
    (state) => state.myStore.updateNeighborhoodModalSuccess
  );
  const deleteNeighborhoodModalLoading = useSelector(
    (state) => state.myStore.deleteNeighborhoodModalLoading
  );
  const deleteNeighborhoodModalSuccess = useSelector(
    (state) => state.myStore.deleteNeighborhoodModalSuccess
  );
  const selectedNeighborhoodsLoading = useSelector(
    (state) => state.myStore.selectedNeighborhoodsLoading
  );
  const selectedNeighborhoodsSuccess = useSelector(
    (state) => state.myStore.selectedNeighborhoodsSuccess
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (structure) {
      setLastPage(
        Object.keys(structure)[Object.keys(structure).length - 1].toString()
      );
    }
  }, [structure]);

  useMemo(() => {
    setStructure(makePages(rows.length, rowsCurrentPage));
  }, [rows, rowsCurrentPage]);

  useEffect(() => {
    if (selectedNeighborhoodsSuccess || pauseNeighborhoodSuccess) {
      setRows(selectedNeighborhoods);
    }
  }, [selectedNeighborhoodsSuccess, pauseNeighborhoodSuccess]);

  useEffect(() => {
    if (selectedNeighborhoods) {
      setRows(selectedNeighborhoods);
    }
  }, [selectedNeighborhoods]);

  useEffect(() => {
    if (deleteNeighborhoodModalSuccess) {
      setConfirmDeleteNeighborhoodIdx(null);
      setConfirmDeleteNeighborhoodModal(false);
    }
  }, [deleteNeighborhoodModalSuccess]);

  useEffect(() => {
    if (updateNeighborhoodModalSuccess) {
      setUpdateNeighborhoodObj(null);
      setUpdateNeighborhoodDeliveryFee(null);
      setUpdateNeighborhoodModal(false);
    }
  }, [updateNeighborhoodModalSuccess]);

  useEffect(() => {
    if (rows.length < 4) {
      setRowsCurrentPage(rows.length);
    }
    if (rowsCurrentPage > 4) {
    } else {
      setRowsCurrentPage(4);
    }
  }, [rows]);

  const handleSort = useCallback(
    (col) => {
      if (sortedCol !== col) {
        setSortedCol(col);
        setRows(sort(rows, col, 'asc'));
      } else {
        setSortedCol('');
        setRows(sort(rows, col, 'desc'));
      }
    },
    [rows]
  );

  const handleSortByColumnValue = useCallback(
    (col) => {
      if (sortedCol !== col) {
        setSortedCol(col);
        setRows(sortByColumnValue(rows, col, 'asc'));
      } else {
        setSortedCol('');
        setRows(sortByColumnValue(rows, col, 'desc'));
      }
    },
    [rows]
  );

  function handleChangePage(direction) {
    if (direction === 'next') {
      const curr = +currentPage + 1;
      setCurrentPage(curr.toString());
    } else if (direction === 'prev') {
      const curr = +currentPage - 1;
      setCurrentPage(curr.toString());
    }
  }

  const loadingPage = (
    <Col height="20vh" margtop={px2vw(100)}>
      <Col margleft="20px" height="104vh">
        <Col alignCenter justifyCenter>
          <Spinner
            style={{
              width: '1.8rem',
              height: '1.8rem',
              color: preto,
            }}
          />
        </Col>
      </Col>
    </Col>
  );

  const contentPage = (
    <>
      <Col
        padding={`0 ${px2vw(15)}`}
        alignStart
        justifyStart
        minheight={px2vw(280)}
        height={px2vw(280)}
        margtop={px2vw(5)}
      >
        <Row>
          <Header
            width="55%"
            onClick={() => handleSortByColumnValue('state_city')}
          >
            <HeaderButton>
              Estado / Cidade
              <SvgBox sorted={sortedCol === 'state_city'}>
                <Arrow />
                <Arrow2 />
              </SvgBox>
            </HeaderButton>
          </Header>

          <Header
            width="70%"
            onClick={() => handleSortByColumnValue('district_name')}
          >
            <HeaderButton>
              Bairro
              <SvgBox sorted={sortedCol === 'district_name'}>
                <Arrow />
                <Arrow2 />
              </SvgBox>
            </HeaderButton>
          </Header>

          <Header width="50%" onClick={() => handleSort('delivery_fee')}>
            <HeaderButton>
              Taxa de entrega
              <SvgBox sorted={sortedCol === 'delivery_fee'}>
                <Arrow />
                <Arrow2 />
              </SvgBox>
            </HeaderButton>
          </Header>

          <Header width="30%" onClick={() => handleSort('is_active')}>
            <HeaderButton>
              Status
              <SvgBox sorted={sortedCol === 'is_active'}>
                <Arrow />
                <Arrow2 />
              </SvgBox>
            </HeaderButton>
          </Header>

          <Header width="20%" />
        </Row>
        <Row>
          <Hr />
        </Row>
        {structure ? (
          rows.map((neighborhood, index) =>
            structure[currentPage] &&
            structure[currentPage].start <= index + 1 &&
            structure[currentPage].end >= index + 1 ? (
              <Fragment key={neighborhood.id}>
                <TableRow alignCenter justifyCenter>
                  <Content width="90%">{`${neighborhood.uf} / ${neighborhood.city}`}</Content>
                  <Content width="115%">{neighborhood.neighborhood}</Content>
                  <Content width="80%">
                    {formatCurrencyBRL(
                      neighborhood.delivery_fee.toFixed(2),
                      {}
                    )}
                  </Content>
                  <Content width="60%">
                    {neighborhood.is_active && 'Ativo'}
                    {!neighborhood.is_active && 'Desativado'}
                  </Content>
                  <Content width="20%">
                    <NeighborhoodManageButton
                      isActive={neighborhood.is_active}
                      handlePauseNeighborhood={() => {
                        dispatch(
                          pauseNeighborhoodRequest(
                            neighborhood.id,
                            neighborhood.is_active
                          )
                        );
                      }}
                      handleEditNeighborhood={() => {
                        setUpdateNeighborhoodObj(neighborhood);
                        setUpdateNeighborhoodDeliveryFee(
                          formatCurrencyBRL(
                            neighborhood.delivery_fee.toFixed(2),
                            {}
                          )
                        );
                        setUpdateNeighborhoodModal(true);
                      }}
                      handleDeleteNeighborhood={() => {
                        setConfirmDeleteNeighborhoodIdx(neighborhood.id);
                        setConfirmDeleteNeighborhoodModal(true);
                      }}
                    />
                  </Content>
                </TableRow>
                <Row>
                  <Hr />
                </Row>
              </Fragment>
            ) : null
          )
        ) : (
          <Col height="20vh" margtop={px2vw(-40)}>
            <Row alignCenter justifyCenter>
              {/* TODO: Ajustar tamanho do icone */}
              <Icon large>sentiment_very_dissatisfied</Icon>
              <ErrorText>
                Você não tem bairros para entrega a serem exibidos.
              </ErrorText>
            </Row>
          </Col>
        )}
        {structure && (
          <Row alignCenter justifyEnd>
            <Row alignCenter justifyEnd height={px2vw(40)} margin={px2vw(10)}>
              <NavText alignCenter justifyCenter width="10%">
                {!structure[currentPage] && handleChangePage('prev')}
                {structure[currentPage] && structure[currentPage].start} -{' '}
                {structure[currentPage] && structure[currentPage].end} de{' '}
                {rows.length}
              </NavText>
              <PagButton
                onClick={() => handleChangePage('prev')}
                disabled={currentPage === firstPage}
                title="Página anterior"
              >
                <PrevIcon disabled={currentPage === firstPage} />
              </PagButton>
              <PagButton
                onClick={() => handleChangePage('next')}
                title="Próxima página"
                disabled={currentPage === lastPage}
              >
                <NextIcon disabled={currentPage === lastPage} />
              </PagButton>
            </Row>
          </Row>
        )}
      </Col>
      {/* Confirm delete neighborhood */}
      {confirmDeleteNeighborhoodModal && (
        <Modal
          isVisible={confirmDeleteNeighborhoodModal}
          width={px2vw(420)}
          height={px2vw(190)}
        >
          <ConfirmDeleteModal>
            <ConfirmDeleteModalText>
              Tem certeza que deseja excluir a entrega nesse bairro?
            </ConfirmDeleteModalText>
            <ModalActions>
              <ModalSecundaryButton
                marginRight={px2vw(20)}
                margtop={px2vw(25)}
                width={px2vw(120)}
                type="button"
                onClick={() => {
                  setConfirmDeleteNeighborhoodModal(false);
                  setConfirmDeleteNeighborhoodIdx(null);
                }}
                disabled={deleteNeighborhoodModalLoading}
              >
                <ModalSecundaryButtonText>Não</ModalSecundaryButtonText>
              </ModalSecundaryButton>
              <ModalPrimaryButton
                margleft={px2vw(20)}
                margtop={px2vw(25)}
                width={px2vw(120)}
                type="button"
                onClick={() => {
                  dispatch(
                    deleteNeighborhoodRequest(confirmDeleteNeighborhoodIdx)
                  );
                }}
                disabled={deleteNeighborhoodModalLoading}
              >
                {deleteNeighborhoodModalLoading ? (
                  <LoadingBox>
                    <Spinner
                      style={{
                        width: '1.8rem',
                        height: '1.8rem',
                        color: preto,
                      }}
                    />
                  </LoadingBox>
                ) : (
                  <ModalPrimaryButtonText>Sim</ModalPrimaryButtonText>
                )}
              </ModalPrimaryButton>
            </ModalActions>
          </ConfirmDeleteModal>
        </Modal>
      )}
      {/* Update neighborhood delivery fee */}
      {updateNeighborhoodModal && (
        <Modal
          isVisible={updateNeighborhoodModal}
          width={px2vw(420)}
          height={px2vw(270)}
        >
          <UpdateModal>
            <UpdateModalText
              margtop={px2vw(20)}
            >{`${updateNeighborhoodObj.uf} / ${updateNeighborhoodObj.city}`}</UpdateModalText>
            <UpdateModalText>
              {updateNeighborhoodObj.neighborhood}
            </UpdateModalText>
            <Row margtop={px2vw(30)} justifyCenter alignCenter>
              <ModalInputLabelText>Taxa de entrega</ModalInputLabelText>
              <ModalInput
                name="updateDeliveryFee"
                width={px2vw(100)}
                onChange={(t) => {
                  setUpdateNeighborhoodDeliveryFee(amountMask(t.target.value));
                }}
                value={amountMask(updateNeighborhoodDeliveryFee)}
                maxLength="9"
                placeholder="R$ 4.50"
              />
            </Row>
            <ModalActions>
              <ModalSecundaryButton
                marginRight={px2vw(20)}
                margtop={px2vw(40)}
                width={px2vw(120)}
                type="button"
                onClick={() => {
                  setUpdateNeighborhoodModal(false);
                  setUpdateNeighborhoodObj(null);
                  setUpdateNeighborhoodDeliveryFee(null);
                }}
                disabled={updateNeighborhoodModalLoading}
              >
                <ModalSecundaryButtonText>Cancelar</ModalSecundaryButtonText>
              </ModalSecundaryButton>
              <ModalPrimaryButton
                margleft={px2vw(20)}
                margtop={px2vw(40)}
                width={px2vw(120)}
                type="button"
                onClick={() => {
                  if (updateNeighborhoodDeliveryFee.length < 7) {
                    toast.error('Taxa de entrega inválida', {
                      autoClose: 6000,
                    });
                  } else {
                    dispatch(
                      updateNeighborhoodRequest(
                        updateNeighborhoodObj.id,
                        onlyNumbersAmount(updateNeighborhoodDeliveryFee)
                      )
                    );
                  }
                }}
                disabled={updateNeighborhoodModalLoading}
              >
                {updateNeighborhoodModalLoading ? (
                  <LoadingBox>
                    <Spinner
                      style={{
                        width: '1.8rem',
                        height: '1.8rem',
                        color: preto,
                      }}
                    />
                  </LoadingBox>
                ) : (
                  <ModalPrimaryButtonText>Salvar</ModalPrimaryButtonText>
                )}
              </ModalPrimaryButton>
            </ModalActions>
          </UpdateModal>
        </Modal>
      )}
    </>
  );
  const errorPage = (
    <Col height="20vh" margtop={px2vw(150)}>
      <Col margleft="20px" height="100vh">
        <Row alignCenter justifyCenter>
          {/* TODO: Ajustar tamanho do icone */}
          <Icon large>sentiment_very_dissatisfied</Icon>
          <ErrorText>
            Ocorreu um erro ao carregar os bairros de entrega. Tente novamente.
          </ErrorText>
        </Row>
      </Col>
    </Col>
  );

  if (selectedNeighborhoodsLoading || pauseNeighborhoodLoading) {
    return loadingPage;
  }
  if (!selectedNeighborhoodsSuccess) {
    return errorPage;
  }
  return contentPage;
}
