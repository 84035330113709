import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Spinner } from 'reactstrap';

import { Content } from '../_layouts/styles/common';

import { Col } from '../../styles/grid';

import {
  TermsOfUseTitle,
  TermsOfUseContentBox,
  TermsOfUseContent,
  TermsOfUseError,
} from './styles';

import { preto } from '../../styles/colors';

import { getTermsAndPolicyRequest } from '../../store/modules/user/actions';

export default function TermsOfUse() {
  const [termsTitle, setTermsTitle] = useState('');
  const [termsContent, setTermsContent] = useState('');
  const user = useSelector((state) => state.user);
  const { loadingGetTermsAndPolicy, successGetTermsAndPolicy } = user;
  const dispatch = useDispatch();
  const urlParams = new URLSearchParams(useLocation().search);
  const [term, setTerm] = useState(urlParams.get('t'));

  useEffect(() => {
    dispatch(getTermsAndPolicyRequest(term || 'TUA'));
  }, [dispatch]);

  useEffect(() => {
    if (successGetTermsAndPolicy) {
      setTermsTitle(user.terms.term_policy_name);
      setTermsContent(user.terms.term_policy_content);
    }
  }, [successGetTermsAndPolicy]);

  const loadingContent = (
    <Content>
      <Col height="80vh">
        <Col alignCenter justifyCenter>
          <Spinner
            style={{
              width: '1.8rem',
              height: '1.8rem',
              color: preto,
            }}
          />
        </Col>
      </Col>
    </Content>
  );

  const successContent = (
    <Content>
      <Col alignCenter justifyStart pad="40px">
        <TermsOfUseTitle>{termsTitle}</TermsOfUseTitle>
        <TermsOfUseContent>
          <TermsOfUseContentBox>{termsContent}</TermsOfUseContentBox>
        </TermsOfUseContent>
      </Col>
    </Content>
  );

  const errorContent = (
    <Content>
      <Col height="80vh">
        <Col alignCenter justifyCenter>
          <TermsOfUseError>
            Ocorreu um erro ao carregar os termos de uso. Tente novamente.
          </TermsOfUseError>
        </Col>
      </Col>
    </Content>
  );

  if (loadingGetTermsAndPolicy) {
    return loadingContent;
  }
  if (successGetTermsAndPolicy) {
    return successContent;
  }
  return errorContent;
}
