import React, { useMemo } from 'react';
import px2vw from '../../../utils/responsiveness/px2vw';

import { TaskList } from './TaskList';
import { useChallenge } from '../Context';
import { RightContainer, StartButton, TaskSubTitle, TaskTitle } from './styles';

export const TaskSection = ({
  title = 'Tarefas do desafio',
  subtitle = 'Nossos top clientes concluíram o desafio nas primeiras horas de uso.',
  buttonText = 'Iniciar Desafio',
  onConfirm = () => {},
}) => {
  const { conditions } = useChallenge();

  const data = [
    {
      title: 'Ative o WhatsApp Bot na plataforma da Kuppi',
      description:
        'Basta ler o QR Code pelo seu WhatsApp e definir se você quer ativar o bot de atendimento ou apenas o envio de status automático.',
      step: 1,
      isDone: false,
    },
    {
      title: 'Receba e conclua dois pedidos testes',
      description:
        'Dessa forma você vai ter a experiência de como os pedidos chegam na plataforma e como funciona o envio automático de mudança de status.',
      step: 2,
      isDone: false,
    },
    {
      title: 'Crie e conclua um pedido manual pela plataforma',
      description:
        'Aqui você vai entender como funciona a criação de um pedido manual para os casos do cliente querer pedir direto pela conversa.',
      step: 3,
      isDone: false,
    },
    {
      title: 'Crie um Disparo em Massa no WhatsApp',
      description:
        'Você tem R$ 100 de bônus para fazer o seu primeiro disparo em massa e divulgar alguma promoção para lançar o cardápio digital no WhatsApp.',
      step: 4,
      isDone: false,
    },
  ];

  const challenges = useMemo(() => {
    return data.map((task, i) => ({ ...task, isDone: conditions[i] }));
  }, [conditions, data]);

  return (
    <RightContainer>
      <TaskTitle size={px2vw(20)}>{title}</TaskTitle>
      <TaskSubTitle size={px2vw(7)} regular>
        {subtitle}
      </TaskSubTitle>
      <TaskList challenges={challenges} />
      <StartButton onClick={onConfirm}>{buttonText}</StartButton>
    </RightContainer>
  );
};

