import React, { useEffect, useState } from 'react';
import {
  AppContainer,
  AppContent,
  Overlay,
  Modal,
  RobotImg,
  Span,
  Button,
} from './style';
import ChatBar from './ChatBar';
import ChatWindow from './ChatWindow';
import { useDispatch, useSelector } from 'react-redux';
import ManualOrderModal from '../../components/ManualOrderModal';
import px2vw from '../../utils/responsiveness/px2vw';
import { Col, Row } from '../../styles/grid';
import {
  setIsConnected,
  setIsDeletingMessage,
} from '../../store/modules/whatsappChat/actions';

import { Link } from 'react-router-dom';
import { whatsappbot } from '../../routes/routeMap';
import { Spinner } from 'reactstrap';
import { preto } from '../../styles/colors';
import { useCallback } from 'react';
import { whatsappBotChatDeleteMessageRequest } from '../../store/modules/whatsappBot/actions';
import { toast } from 'react-toastify';
import {
  setConversations,
  updateConversations,
} from '../../store/modules/whatsappChatConversations/actions';
import { useWorkerSocket } from '../../hooks/useWebSocket';

export default function Whatsappchat() {
  const dispatch = useDispatch();
  const whatsappIsActive = useSelector(
    (state) =>
      state.user.user.advertiserInfo.advertiserWhatsappbotConfig.is_active
  );
  const isManualOrderModalVisible = useSelector(
    (state) => state.manualOrder.isManualOrderModalVisible
  );

  const {
    isConnected,
    isDeletingMessage,
    selectedConversation,
    waitAttendantList,
  } = useSelector((state) => state.whatsappChat);
  const { conversations, isHistorySyncComplete } = useSelector(
    (state) => state.whatsappChatConversations
  );

  const [isReconnecting, setIsReconnecting] = useState(false);
  const workerSocket = useWorkerSocket();

  useEffect(() => {
    waitAttendantList.forEach((client_phone) => {
      toast.dismiss(`callAttenddant-${client_phone}`);
    });
  }, []);

  useEffect(() => {
    dispatch(updateConversations());
  }, []);

  const handleReconnectChat = (e) => {
    e.preventDefault();
    dispatch(setConversations([]));
    dispatch(setIsConnected(false));
    setIsReconnecting(true);
    setTimeout(() => {
      setIsReconnecting(false);
      workerSocket.reconnect();
      window.location.reload();
    }, 1000);
  };

  const renderModalcontent = useCallback(() => {
    if (isDeletingMessage) {
      return (
        <>
          <Overlay />
          <AppContent>
            <Modal>
              <Col
                height={px2vw(130)}
                spaceAround
                padbottom={px2vw(15)}
                padtop={px2vw(15)}
              >
                <Span size={px2vw(12)} maxWidth="98%" className="mx-auto">
                  Tem certeza que deseja apagar está mensagem? Essa ação é
                  irreversível.
                </Span>
                <Row height="auto">
                  <Button
                    onClick={() =>
                      dispatch(
                        whatsappBotChatDeleteMessageRequest(
                          selectedConversation.id,
                          isDeletingMessage
                        )
                      )
                    }
                    clearMessage
                  >
                    APAGAR PARA TODOS
                  </Button>
                  <Button
                    onClick={() => dispatch(setIsDeletingMessage(''))}
                    clearMessage
                  >
                    CANCELAR
                  </Button>
                </Row>
              </Col>
            </Modal>
          </AppContent>
        </>
      );
    }

    if (!whatsappIsActive) {
      return (
        <>
          <Overlay />
          <AppContent>
            <Modal>
              <Col
                height={px2vw(130)}
                spaceAround
                padbottom={px2vw(15)}
                padtop={px2vw(15)}
              >
                <Span size={px2vw(12)} maxWidth="98%" className="mx-auto">
                  Você precisa conectar o seu WhatsApp para utilizar essa
                  funcionalidade.
                  <br />
                  <br />
                  Clique no botão abaixo e leia o QR Code.
                </Span>

                <Row height="auto">
                  <Link to={whatsappbot}>
                    <Button primary>GERAR QR CODE</Button>
                  </Link>
                </Row>
              </Col>
              <RobotImg
                src={require('../../assets/images/logo_robot/robot.svg')}
              />
            </Modal>
          </AppContent>
        </>
      );
    }

    if (!isConnected) {
      return (
        <>
          <Overlay />
          <AppContent>
            <Modal>
              <Col
                height={px2vw(130)}
                spaceAround
                padbottom={px2vw(15)}
                padtop={px2vw(15)}
              >
                <Span size={px2vw(12)} maxWidth="98%" className="mx-auto">
                  O WhatsApp está aberto em outra janela. Clique em “Usar aqui”
                  para usar o Whatsapp nessa janela
                </Span>

                <Row height="auto">
                  <Button primary onClick={handleReconnectChat}>
                    {!isReconnecting ? 'USAR AQUI' : <Spinner />}
                  </Button>
                </Row>
              </Col>
              <RobotImg
                src={require('../../assets/images/logo_robot/robot.svg')}
              />
            </Modal>
          </AppContent>
        </>
      );
    }

    if (
      (Object.keys(conversations).length === 0 && !isHistorySyncComplete) ||
      isReconnecting
    ) {
      return (
        <>
          <Overlay />
          <AppContent>
            <Modal>
              <Row
                height={px2vw(100)}
                alignCenter
                padbottom={px2vw(15)}
                padtop={px2vw(15)}
                className="my-auto"
              >
                <Span size={px2vw(15)} maxWidth="98%">
                  Carregando mensagens
                </Span>
                <Spinner
                  style={{
                    marginLeft: px2vw(10),
                    width: px2vw(17),
                    height: px2vw(17),
                    color: preto,
                  }}
                />
              </Row>
              <RobotImg
                src={require('../../assets/images/logo_robot/robot.svg')}
              />
            </Modal>
          </AppContent>
        </>
      );
    }

    if (Object.keys(conversations).length === 0) {
      return (
        <>
          <Overlay />
          <AppContent>
            <Modal>
              <Row
                height={px2vw(100)}
                alignCenter
                padbottom={px2vw(15)}
                padtop={px2vw(15)}
                className="my-auto"
              >
                <Span size={px2vw(15)} maxWidth="98%">
                  Falha ao carregar histórico. Reconecte o bot nesse navegador
                  para carregar seu histórico.
                </Span>
              </Row>
              <RobotImg
                src={require('../../assets/images/logo_robot/robot.svg')}
              />
            </Modal>
          </AppContent>
        </>
      );
    }
  }, [
    whatsappIsActive,
    isConnected,
    isDeletingMessage,
    conversations,
    isHistorySyncComplete,
  ]);

  return (
    <>
      <Row height="130vh" width="100vh"></Row>
      <AppContainer>
        {renderModalcontent()}
        <ChatBar />
        <ChatWindow />
        {isManualOrderModalVisible && (
          <ManualOrderModal
            isVisible={isManualOrderModalVisible}
            width={px2vw(535)}
            height={px2vw(570)}
            padding="0 0"
          />
        )}
      </AppContainer>
    </>
  );
}
