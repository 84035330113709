import React from 'react';

import {
  CloseButton,
  CloseImage,
  ModalBackground,
  ModalContent,
  ModalHeader,
} from './styles';

import { Col } from '../../../../styles/grid';

import HistoryTable from './HistoryTable';
import { useSelector } from 'react-redux';
import px2vw from '../../../../utils/responsiveness/px2vw';

export default function HistoryModal(props) {
  const { gamefication } = useSelector((state) => state.dashboardMetrics);
  return (
    <ModalBackground isVisible={props.isVisible}>
      <ModalContent
        width={props.width}
        height={props.height}
        bgcolor={props.bgcolor}
        margtop={props.margtop}
        padding={props.padding}
        backgroundImg={props.backgroundImg}
        backgroundImgSize={props.backgroundImgSize}
        maxheight={props.height}
      >
        <ModalHeader>
          Histórico
          <CloseButton onClick={props.onClose}>
            <CloseImage
              src={require('../../../../assets/images/close/close.png')}
            />
          </CloseButton>
        </ModalHeader>
        <Col maxheight={px2vw(420)} padtop={px2vw(10)}>
          <HistoryTable data={gamefication.history || []} />
        </Col>
      </ModalContent>
    </ModalBackground>
  );
}
