import produce from 'immer';

const INITIAL_STATE = {
  onboarding: { status: '' },
  campaign: {
    main_text: '',
    description: '',
    currentStep: '1',
    type: '',
    selected_coupon: null,
    selected_product: null,
  },
  finish_onboarding_loading: false,
  create_campaign_loading: false,
  create_campaign_success: false,
  create_campaign_failure: false,
  create_pix_payment_loading: false,
  pix_payment_code: '',
  pix_payment_read_only: false,
  cancel_payment_loading: false,
  gen_loading: false,
  onboarding_loading: false,
  update_onboarding_loading: false,
  has_whatsapp_request_loading: false,
  has_whatsapp: null,
  new_page_experience: null,
  gen_ads: null,
  facebook_id: null,
  instagram_id: null,
  campaigns: [],
  available_coupons: [],
  available_products: [],
  payments_data: [],
  balanceModalCurrentStep: '1',
  onboardingCurrentStep: '1',
  balance: 0,
};

export default function kuppiBoost(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@kuppiBoost/GET_KUPPI_BOOST':
        draft.loading = true;
        break;
      case '@kuppiBoost/SET_KUPPI_BOOST':
        draft.loading = false;
        draft.campaigns = action.payload.campaigns;
        draft.available_coupons = action.payload.available_coupons;
        draft.available_products = action.payload.available_products;
        draft.payments_data = action.payload.payments_data;
        break;
      case '@kuppiBoost/SET_CURRENT_STEP': {
        draft.onboardingCurrentStep = action.payload;
        break;
      }
      case '@kuppiBoost/SET_INSTAGRAM_ID': {
        draft.instagram_id = action.payload;
        break;
      }
      case '@kuppiBoost/SET_MAIN_TEXT': {
        draft.campaign.main_text = action.payload;
        break;
      }
      case '@kuppiBoost/WHATSAPP_CHECK_REQUEST': {
        draft.has_whatsapp_request_loading = true;
        break;
      }
      case '@kuppiBoost/WHATSAPP_CHECK_SUCCESS': {
        draft.has_whatsapp_request_loading = false;
        draft.has_whatsapp = action.payload.has_whatsapp;
        draft.new_page_experience = action.payload.new_page_experience;
        draft.onboarding.whatsapp_fb_linked_status = action.payload.whatsapp_fb_linked_status;
        break;
      }
      case '@kuppiBoost/WHATSAPP_CHECK_FAILURE': {
        draft.has_whatsapp_request_loading = false;
        draft.has_whatsapp = false;
        draft.new_page_experience = false;
        break;
      }
      case '@kuppiBoost/SET_TITLE': {
        draft.campaign.title = action.payload;
        break;
      }
      case '@kuppiBoost/SET_FACEBOOK_ID': {
        draft.facebook_id = action.payload;
        break;
      }
      case '@kuppiBoost/SET_IS_FINISHED': {
        draft.onboarding.is_finished = action.payload;
        break;
      }
      case '@kuppiBoost/SET_BALANCE_MODAL_CURRENT_STEP': {
        draft.balanceModalCurrentStep = action.payload;
        break;
      }
      case '@kuppiBoost/SET_TEMP_BALANCE': {
        draft.temp_balance = action.payload;
        break;
      }
      case '@kuppiBoost/SET_CAMPAIGN_TYPE': {
        draft.campaign.type = action.payload;
        break;
      }
      case '@kuppiBoost/SET_SELECTED_PRODUCT': {
        draft.campaign.selected_product = action.payload;
        break;
      }
      case '@kuppiBoost/SET_SELECTED_COUPON': {
        draft.campaign.selected_coupon = action.payload;
        break;
      }
      case '@kuppiBoost/SET_CAMPAIGN_CURRENT_STEP': {
        draft.campaign.currentStep = action.payload;
        break;
      }
      case '@kuppiBoost/GET_GEN_ADS': {
        draft.gen_loading = true;
        break;
      }
      case '@kuppiBoost/SET_GEN_ADS': {
        draft.gen_loading = false;
        draft.gen_ads = action.payload.gen_ads;
        break;
      }
      case '@kuppiBoost/GET_KUPPI_BOOST_ONBOARDING': {
        draft.onboarding_loading = true;
        break;
      }
      case '@kuppiBoost/SET_KUPPI_BOOST_ONBOARDING': {
        draft.onboarding = action.payload;
        draft.onboarding_loading = false;
        break;
      }
      case '@kuppiBoost/SET_KUPPI_BOOST_UPDATE_LOADER': {
        draft.update_onboarding_loading = action.payload;
        break;
      }
      case '@kuppiBoost/UPDATE_KUPPI_BOOST_ONBOARDING': {
        draft.update_onboarding_loading = true;
        break;
      }
      case '@kuppiBoost/SET_BALANCE': {
        draft.balance = action.payload;
        break;
      }
      case '@kuppiBoost/CREATE_CAMPAIGN_REQUEST': {
        draft.create_campaign_loading = true;
        break;
      }
      case '@kuppiBoost/SET_CREATE_CAMPAIGN_SUCCESS': {
        draft.create_campaign_loading = false;
        draft.create_campaign_success = true;
        break;
      }
      case '@kuppiBoost/SET_CREATE_CAMPAIGN_FAILURE': {
        draft.create_campaign_loading = false;
        draft.create_campaign_failure = true;
        break;
      }
      case '@kuppiBoost/CLEAN_CAMPAIGN_DATA': {
        draft.campaign = {
          main_text: '',
          title: '',
          currentStep: '1',
          type: '',
          selected_coupon: null,
          selected_product: null,
        };
        draft.create_campaign_loading = false;
        draft.create_campaign_success = false;
        draft.create_campaign_failure = false;
        break;
      }
      case '@kuppiBoost/CREATE_PIX_PAYMENT_REQUEST': {
        draft.create_campaign_loading = false;
        draft.create_campaign_failure = true;
        break;
      }
      case '@kuppiBoost/CREATE_PIX_PAYMENT_RESQUEST': {
        draft.create_pix_payment_loading = true;
        break;
      }
      case '@kuppiBoost/CREATE_PIX_PAYMENT_SUCCESS': {
        draft.create_pix_payment_loading = false;
        draft.pix_payment_code = action.payload;
        break;
      }
      case '@kuppiBoost/CREATE_PIX_PAYMENT_FAILURE': {
        draft.create_pix_payment_loading = false;
        break;
      }
      case '@kuppiBoost/SET_PIX_PAYMENT_CODE': {
        draft.pix_payment_code = action.payload;
        draft.pix_payment_read_only = action.payload === '' ? false : true;
        break;
      }
      case '@kuppiBoost/CANCEL_PIX_PAYMENT_RESQUEST': {
        draft.cancel_payment_loading = true;
        break;
      }
      case '@kuppiBoost/CANCEL_PAYMENT_FAILURE': {
        draft.cancel_payment_loading = false;
        break;
      }
      case '@kuppiBoost/CANCEL_PAYMENT_SUCCESS': {
        draft.cancel_payment_loading = false;
        break;
      }
      case '@kuppiBoost/FINISH_ONBOARDING_REQUEST': {
        draft.finish_onboarding_loading = true;
        break;
      }
      case '@kuppiBoost/FINISH_ONBOARDING_SUCCESS': {
        draft.finish_onboarding_loading = false;
        break;
      }
      case '@kuppiBoost/FINISH_ONBOARDING_FAILURE': {
        draft.finish_onboarding_loading = false;
        break;
      }
      default:
    }
  });
}
