import styled from 'styled-components';

import px2vw from '../../../utils/responsiveness/px2vw';

import { Input } from '@rocketseat/unform';

import {
  preto,
  branco,
  cinzaKuppi2,
  cinzaKuppi3,
  cinzaKuppi4,
  verdeKuppi1,
  azulKuppi4,
} from '../../../styles/colors';

export const TabsBox = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: ${px2vw(20)};

  ${(props) => `
    margin-left: ${props.margleft};
  `};
`;

export const TabBox = styled.div`
  display: block;
  flex-direction: column;
  align-items: center;
  margin-right: ${px2vw(40)};
  justify-content: center;
`;

export const Tab = styled.button`
  height: ${px2vw(45)};
  width: 100%;
  font-size: ${px2vw(20)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.48px;
  border: none;
  background-color: transparent;
`;

export const TabSelected = styled.div`
  display: none;
  height: ${px2vw(5)};
  width: ${px2vw(125)};
  background-color: ${verdeKuppi1};
  border-radius: ${px2vw(5)};
  margin-top: ${px2vw(-7)};
  margin-bottom: ${px2vw(20)};

  ${(props) =>
    props.isVisible &&
    `
    display: flex;
  `};

  ${(props) => `
    width: ${props.width};
    height: ${props.height};
    margin-top: ${props.margtop};
    margin-bottom: ${props.margbottom};
    margin-left: ${props.margleft};
  `};
`;

export const FormDataInput = styled(Input)`
  padding: 0.5em;
  margin-top: ${px2vw(5)};
  border: 1px solid ${cinzaKuppi4};
  border-radius: ${px2vw(5)};
  font-size: ${px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: bold;
  letter-spacing: 0.36px;
  color: ${azulKuppi4};
  width: ${px2vw(280)};
  height: ${px2vw(40)};
  background: ${cinzaKuppi2};

  ::placeholder {
    color: ${cinzaKuppi4} !important;
  }

  :focus {
    border: 1px solid ${cinzaKuppi3} !important;
    box-shadow: none !important;
  }

  ${(props) => `
    width: ${props.width};
    height: ${props.height};
    margin-top: ${props.margtop};
  `};
`;

export const CopyButton = styled.button`
  width: ${px2vw(120)};
  height: ${px2vw(30)};
  font-size: ${px2vw(10)};
  font-family: AvenirNextLTPro;
  font-weight: bold;
  letter-spacing: 0.36px;
  border: 2px solid ${preto};
  border-radius: ${px2vw(5)};
  background: ${branco};

  :hover {
    background: ${cinzaKuppi2};
  }
  ${(props) => `
    width: ${props.width};
    height: ${props.height};
    margin-top: ${props.margtop};
    margin-left: ${props.margleft};
  `};
`;

export const InputDataTextArea = styled.textarea`
  width: ${(props) => px2vw(props.width || 470)};
  height: ${(props) => px2vw(props.height || 80)};
  padding: ${px2vw(10)};
  max-height: ${(props) => props.maxheight};
  margin-top: ${px2vw(5)};
  font-size: ${(props) => props.fontsize || px2vw(14)};
  font-family: AvenirNextLTPro;
  letter-spacing: 0.36px;
  border-radius: 5px;
  margin-left: ${px2vw(7)};
  margin-bottom: ${px2vw(7)};
  border: none;
  resize: none;
  background: ${cinzaKuppi2};

  :focus {
    border: inheritAll ? none : 1px solid ${cinzaKuppi3} !important;
    box-shadow: none !important;
  }

  ${(props) => `
    box-shadow: ${props.boxshadow};
    width: ${props.width};
    height: ${props.height};
    border: ${props.border};
    padding-left: ${props.padleft};
  `};

  ${(props) => props.inheritAll && `
    padding: inherit;
    margin-top: inherit;
    margin-left: inherit;
    margin-bottom: inherit;
    width: inherit;
    height: inherit;
    padding-left: ${px2vw(20)};
    padding-top: ${px2vw(20)};
    border: none ;
  `};
`;

export const TextArea = styled.div`
  width: ${(props) => px2vw(props.width || 470)};
  height: ${(props) => px2vw(props.height || 250)};
  margin-top: ${px2vw(5)};
  font-family: AvenirNextLTPro;
  letter-spacing: 0.36px;
  border-radius: 5px;
  background: ${cinzaKuppi2};
  padding: ${(props) => props.pad || px2vw(10)};
`;
