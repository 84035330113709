import React from 'react';
import { cinzaKuppi4 } from '../../../../styles/colors';
import styled from 'styled-components';
import px2vw from '../../../../utils/responsiveness/px2vw';
import { StepIcon } from '../../ChallengesDetails/StepChallenge/StepIcon';

export const TaskItem = ({
  title = 'Ative o WhatsApp Bot na plataforma da Kuppi',
  isDone = false,
  step = 1,
  description = 'Basta ler o QR Code pelo seu WhatsApp e definir se você quer ativar o bot de atendimento ou apenas o envio de status automático.',
}) => {
  return (
    <Container>
      <StepIcon step={step} done={isDone} />
      <Box>
        <Title size={px2vw(10)}>{title}</Title>
        <Description regular size={px2vw(8)}>
          {description}
        </Description>
      </Box>
    </Container>
  );
};

export const Title = styled.div`
  width: 100%;
  font-weight: bold;
  font-size:${px2vw(10)};
`;

export const Description = styled.div`
  width: 100%;
  font-size: ${px2vw(7)};
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  width: 100%;
  margin-left: ${px2vw(10)};
`;

export const Container = styled.li`
  display: flex;
  flex: 1;
  align-items: center;
  width: 100%;
  list-style: none;
  padding: 0;
  border: 1px solid ${cinzaKuppi4};
  padding: ${px2vw(10)};
  border-radius: ${px2vw(5)};
  margin-bottom: ${px2vw(5)};
`;
