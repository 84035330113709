import React from 'react';
import { Switch, withStyles } from '@material-ui/core';
import styled from 'styled-components';
import {
  branco,
  cinzaKuppi11,
  cinzaKuppi2,
  cinzaKuppi4,
  cinzaKuppi7,
  preto,
  verdeKuppi1,
  verdeKuppi2,
} from '../../../styles/colors';
import px2vw from '../../../utils/responsiveness/px2vw';

export const Container = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  background: ${preto}50;
  z-index: 10;
  border-radius: 20px;
  box-shadow: 2px 2px 3px transparent;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Box = styled.div`
  displat: flex;
  justify-content: space-around;
  background: ${branco};
  min-height: ${px2vw(240)};
  width: ${px2vw(370)};
  border-radius: 10px;
  padding: ${px2vw(5)} ${px2vw(30)};
  padding-top: ${px2vw(30)};
`;

export const Span = styled.span`
  cursor: ${(props) => (props.cursor ? props.cursor : 'initial')};
  padding: ${(props) => (props.padding ? props.padding : '0')};
  font-size: ${(props) => (props.fontSize ? props.fontSize : px2vw(14))};
  font-family: AvenirNextLTPro;
  letter-spacing: 0.36px;
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 'bold')};
  display: ${(props) => (props.display ? props.display : 'block')};
  color: ${(props) => (props.color ? props.color : verdeKuppi2)};
  text-align: ${(props) => (props.alignCenter ? 'center' : 'initial')};
  @media (max-width: 500px) {
    font-size: ${px2vw(20)};
  }
  .badge {
    background: ${cinzaKuppi11};
    font-size: ${px2vw(13)};
  }
`;

export const PrimaryButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${branco};
  border: none;
  background-color: ${verdeKuppi2};
  border-radius: ${px2vw(7)};
  font-size: ${(props) => props.fontSize || px2vw(13)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.015em;
  padding: ${px2vw(8)};
  box-shadow: 2px 2px 3px ${cinzaKuppi2};
  width: ${(props) => (props.width ? props.width : px2vw(139))};
  height: ${(props) => (props.height ? props.height : px2vw(35))};

  margin-top: ${(props) => (props.margtop ? props.margtop : 0)};
  margin-right: ${(props) => (props.margright ? props.margright : 0)};

  &:active {
    box-shadow: 2px 2px 3px ${cinzaKuppi2};
    background: ${verdeKuppi1};
  }
  ${(props) =>
    props.disabled &&
    `
  background-color: ${cinzaKuppi4};
  color:${branco};

  &:active {
    box-shadow: 2px 2px 3px ${cinzaKuppi2};
    background: ${cinzaKuppi4};
  }

  `}
`;

export const SecundaryButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${preto};
  background: ${branco};
  border: 3px solid ${preto};
  border-radius: ${px2vw(7)};
  font-size: ${(props) => props.fontSize || px2vw(13)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.015em;
  padding: ${px2vw(8)};
  box-shadow: 2px 2px 3px ${cinzaKuppi2};
  width: ${(props) => (props.width ? props.width : px2vw(139))};
  height: ${(props) => (props.height ? props.height : px2vw(35))};

  margin-top: ${(props) => (props.margtop ? props.margtop : 0)};
  margin-right: ${(props) => (props.margright ? props.margright : 0)};

  &:active {
    box-shadow: 2px 2px 3px ${cinzaKuppi2};
    background: ${cinzaKuppi2};
  }
  ${(props) =>
    props.disabled &&
    `
  background-color: ${cinzaKuppi4};
  border: 3px solid ${cinzaKuppi2};
  color:${branco};

  &:active {
    box-shadow: 2px 2px 3px ${cinzaKuppi2};
    background: ${cinzaKuppi4};
  }

  `}
`;

export const CheckBox = styled.div`
    border-radius: 4px;
    width: ${px2vw(8)};
    height; ${px2vw(8)};
    border: 3px solid ${cinzaKuppi7};
    padding:8px;
    margin-right: ${px2vw(15)};
    cursor:pointer;
    ${(props) =>
      props.checked &&
      `
    background: ${verdeKuppi2};
    `}
`;

