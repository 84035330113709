import styled from 'styled-components';
import { branco, cinzaKuppi4 } from '../../styles/colors';

import px2vw from '../../utils/responsiveness/px2vw';

export const SubtitleTextBox = styled.div`
  margin-top: ${px2vw(20)};
  width: ${px2vw(1028)};
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const SubtitleText = styled.div`
  font-size: ${px2vw(13)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.36px;
  margin-left: ${px2vw(5)};
`;

export const TipsBox = styled.div`
  background-color: ${branco};
  border-radius: ${px2vw(20)};
  border: none;
  height: auto;
  width: 100%;
  margin-top: ${px2vw(20)};
  margin-bottom: ${px2vw(20)};
  padding-left: ${px2vw(20)};
  box-shadow: 0px 8px 9px -2px ${cinzaKuppi4};
`;

export const TipsTitleText = styled.div`
  font-size: ${px2vw(20)};
  font-family: AvenirNextLTPro;
  font-weight: bold;
  letter-spacing: 0.36px;
  margin-left: ${px2vw(5)};
  margin-bottom: ${px2vw(10)};
`;

export const TipsSubtitleText = styled.div`
  font-size: ${px2vw(13)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.36px;
  margin-left: ${px2vw(10)};
  margin-bottom: ${px2vw(5)};
`;

export const TipsImage = styled.img`
  height: ${px2vw(160)};
  margin-right: ${px2vw(10)};
`;