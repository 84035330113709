export function getUsedCouponsTraditionalRequest() {
  return {
    type: '@coupons/GET_USED_COUPONS_TRADITIONAL_REQUEST',
  };
}

export function getUsedCouponsTraditionalSuccess(coupons) {
  return {
    type: '@coupons/GET_USED_COUPONS_TRADITIONAL_SUCCESS',
    payload: { coupons },
  };
}

export function getUsedCouponsTraditionalFailure() {
  return {
    type: '@coupons/GET_USED_COUPONS_TRADITIONAL_FAILURE',
  };
}

export function getUsedCouponsFlashRequest() {
  return {
    type: '@coupons/GET_USED_COUPONS_FLASH_REQUEST',
  };
}

export function getUsedCouponsFlashSuccess(coupons) {
  return {
    type: '@coupons/GET_USED_COUPONS_FLASH_SUCCESS',
    payload: { coupons },
  };
}

export function getUsedCouponsFlashFailure() {
  return {
    type: '@coupons/GET_USED_COUPONS_FLASH_FAILURE',
  };
}
