import React from 'react';
import { Row } from '../../styles/grid';
import px2vw from '../../utils/responsiveness/px2vw';
import { FaUserAlt } from 'react-icons/fa';
import { ImgContainer, Span, StyledBell } from './style';
import { useEffect } from 'react';
import { useState } from 'react';
import { amareloKuppi5 } from '../../styles/colors';

export default function WppAvatar(props) {
  const [initials, setInitials] = useState('');
  const { fullName, callingAttendant, width } = props;

  useEffect(() => {
    if (fullName?.length > 0 && !fullName?.includes('+55')) {
      const firstInitial = fullName[0][0];
      const secondInitial = fullName.length > 1 && !!fullName[1] ? fullName[1][0] : '';
      setInitials((firstInitial + secondInitial)?.replace(/\W/g, ''));
    }
  }, [fullName]);

  return (
    <Row height="100%" width={width ? width : px2vw(85)}>
      <ImgContainer callingAttendant={callingAttendant}>
        {callingAttendant && (
          <StyledBell
            color={amareloKuppi5}
            fontSize={px2vw(19)}
          />
        )}
        <Span size={px2vw(11)}>
          {initials.toUpperCase() || (
            <FaUserAlt style={{ fontSize: px2vw(18), marginTop: px2vw(-5) }} />
          )}
        </Span>
      </ImgContainer>
    </Row>
  );
}
