import styled from 'styled-components';
import { branco, cinzaKuppi2, cinzaKuppi3, cinzaKuppi4, cinzaKuppi5, preto, preto2, verdeKuppi1, verdeKuppi4 } from '../../styles/colors';

import px2vw from '../../utils/responsiveness/px2vw';

export const BannerContainer = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${branco};
  border-radius: ${px2vw(20)};
  width: 95%;
  height: ${px2vw(170)};
  margin-top: ${px2vw(30)};

  box-shadow: 0px 8px 9px -2px ${cinzaKuppi4};
`;

export const ArrowImg = styled.img`
  width: ${px2vw(80)};
  margin: 0 ${px2vw(12)};
  color: black;
  pointer-events: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  over-flow: hidden;
`;

export const BannerTitle = styled.text`
  font-size: ${px2vw(20)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.36px;
  padding-left: ${px2vw(15)};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${branco};
  border-radius: ${px2vw(20)};
  width: 95%;
  height: auto;
  padding-left: ${px2vw(30)};

  box-shadow: 0px 8px 9px -2px ${cinzaKuppi4};
`;

export const ImageContainer = styled.div`
  background-color: yellow;
  display: flex;
  flex-direction: column;
  height: ${px2vw(180)};
`;

export const Image = styled.img`
  width: ${px2vw(380)};
  height: ${px2vw(390)};
  border-radius: 0 ${px2vw(20)} ${px2vw(20)} 0; 
`;

export const TitleText = styled.text`
  font-size:  ${(props) => props.fontSize || px2vw(16)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.36px;
  color: ${preto};
  padding-top: ${(props) => props.padtop};
`;

export const BannerText = styled.h4`
  font-size: ${(props) => props.fontSize || px2vw(14)};
  font-family: AvenirNextLTPro;
  letter-spacing: 0.36px;
  padding-top: ${px2vw(10)};
  padding-left: ${px2vw(15)};
  width: 85%;
`;

export const MediumText = styled.text`
  font-size: ${(props) => props.fontSize || px2vw(15)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.36px;
  padding-top: ${(props) => props.padTop};
  padding-left: ${(props) => props.padLeft};
  padding-right: ${(props) => props.padRight};
  color: ${(props) => props.color};
  text-decoration: ${(props) => props.underline && 'underline'};
`;

export const BasicInput = styled.input`
  font-size: ${px2vw(12)};
  font-family: AvenirNextLTPro;
  border: 1px solid ${cinzaKuppi4};
  border-radius: ${px2vw(5)};
  width: ${px2vw(50)};
  height: ${px2vw(25)};
  text-align: center;
  margin-left: ${px2vw(10)};

  ::placeholder {
    color: ${cinzaKuppi5} !important;
    font-size: ${px2vw(12)} !important;
  }

  :focus {
    border: 1px solid ${preto2} !important;
    box-shadow: none !important;
  }

  ${(props) =>
    !props.isActive &&
    `
      color: ${preto}60;
  `};

`;

export const SmallText = styled.text`
  font-size: ${(props) => props.fontSize || px2vw(12)};
  font-family: AvenirNextLTPro;
  color: ${(props) => props.color ? props.color : props.isActive ? preto : cinzaKuppi4};
  margin-left: ${(props) => props.margleft};
  text-align: ${(props) => props.textAlign || 'center'};

  ${(props) => props.link && `
    text-decoration: underline;
    cursor: pointer;
    font-style: italic;
  `}
`;

export const BoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: ${cinzaKuppi4};
  border: 2px solid ${cinzaKuppi4};
  border-radius: ${px2vw(20)};
  width: ${(props) => props.width || px2vw(220)};
  height: ${(props) => props.height || px2vw(220)};
  justify-content: center;
  align-items: center;
  padding: ${px2vw(10)};
`;

export const RoundedContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${cinzaKuppi4};
  border-radius: ${px2vw(20)};
  width: ${px2vw(25)};
  height: ${px2vw(25)};
  margin-bottom: ${px2vw(15)};
`;

export const Separator = styled.hr`
  width: 90%;
  opacity: 25%;
  margin-top: ${px2vw(20)};
  margin-bottom: ${px2vw(20)};
  background-color: ${preto};
`;

export const GeneralButton = styled.button`
  width: ${(props) => props.width || '85%'};
  height: ${px2vw(30)};
  margin-top: ${px2vw(20)};
  margin-bottom: ${px2vw(20)};
  background-color: ${verdeKuppi1};
  border: 1px solid ${verdeKuppi1};
  border-radius: ${px2vw(3)};
`;

export const ImgContainer = styled.img`
  width: ${(props) => props.width};
  height: ${(props) => props.height};

  ${(props) => props.miniBox && `
    width: ${px2vw(20)};
    height: ${px2vw(20)};
  `}

`;

export const FilterButton = styled.button`
  width: ${px2vw(120)};
  height: ${px2vw(35)};
  color: ${branco};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.36px;
  font-size: ${px2vw(14)};
  margin-left: ${px2vw(20)};
  margin-bottom: ${px2vw(20)};
  background-color: ${verdeKuppi1};
  border: 1px solid ${verdeKuppi1};
  border-radius: ${px2vw(6)};

  ${(props) => !props.isActive && `
    background-color: ${cinzaKuppi2};
    border: none;
    color: ${cinzaKuppi3};
  `}
`;

export const BigText = styled.text`
  font-size: ${px2vw(25)};
  font-weight: 700;
  font-family: AvenirNextLTPro;
  text-align: center;
  margin-left: ${(props) => props.margleft || px2vw(160)};
`;

export const SmallCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: ${preto};
  border: 2px solid ${verdeKuppi4};
  border-radius: ${px2vw(20)};
  width: ${px2vw(150)};
  height: ${px2vw(120)};
  justify-content: center;
  align-items: center;
  padding: ${px2vw(20)};
  margin: ${px2vw(17)};
`;

export const SearchInput = styled.input`
  font-size: ${px2vw(12)};
  font-family: AvenirNextLTPro;
  color: ${preto};
  border: 1px solid ${cinzaKuppi4};
  border-radius: ${px2vw(5)};
  width: ${px2vw(250)};
  height: ${px2vw(35)};
  text-align: left;
  padding-left: ${px2vw(8)};
`;

export const DetailButton = styled.button`
  border-color: ${preto};
  border: 2px solid;
  border-radius: ${px2vw(5)};
  color: ${preto};
  background-color: ${branco};
  width: ${(props) => props.width || '60%'};
  height: ${px2vw(30)};
  font-family: AvenirNextLTPro;
  letter-spacing: 0.36px;
  font-weight: 700;
  margin-top: ${(props) => props.margtop || px2vw(30)};
  margin-left: ${(props) => props.margleft || px2vw(30)};
`;

export const ListContainer = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
`;

export const ListItem = styled.li`
  float: left;
  display: block;
  color: white;
  text-align: center;
  text-decoration: none;
`;

export const Text = styled.div`
  font-size: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: AvenirNextLTPro;
  font-weight: 700;
  ${(props) => props.borderbottom && `border-bottom: 2px solid ${cinzaKuppi4};`}
  ${(props) => props.margleft && `margin-left: ${props.margleft};`}
  ${(props) => props.margbottom && `margin-bottom: ${props.margbottom};`}
  ${(props) => props.margtop && `margin-top: ${props.margtop};`}
  ${(props) => props.margright && `margin-right: ${props.margright};`}
  ${(props) => props.padleft && `padding-left: ${props.padleft};`}
  ${(props) => props.padbottom && `padding-bottom: ${props.padbottom};`}
  ${(props) => props.padtop && `padding-top: ${props.padtop};`}
  ${(props) => props.padleft && `padding-left: ${props.padleft};`}
  ${(props) => props.size && `font-size: ${props.size};`}
  ${(props) => props.color && `color: ${props.color};`}
  ${(props) => props.txtOverFlow && `text-overflow: ellipsis;`}
  ${(props) => props.margin && `margin: ${props.margin};`}
  ${(props) => props.italic && `font-style: italic;`}
  ${(props) =>
    props.bold &&
    `font-family: AvenirNextLTPro;
  font-weight: 700;`}
  ${(props) =>
    props.regular &&
    `font-family: AvenirNextLTPro;
  font-weight: normal;`}
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : 'initial')};
  ${(props) => props.alignCenter && `text-align: center;`}
  ${(props) => props.weight && `font-weight: ${props.weight};`}
  ${(props) => props.justifyStart && `justify-content: flex-start;`}
  ${(props) =>
    props.wrap &&
    `
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  `}
  ${(props) =>
    props.fitLetter &&
    `
      font-style: normal;
      line-height: 150%;
      text-align: center;
      letter-spacing: 0.02em;
  `}
  ${(props) =>
    props.roboto &&
    `
      font-family: Roboto;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
      letter-spacing: 0.05em;
    `}

  width: ${(props) => props.width || 'auto'};
`;