import { onlyNumbers } from '../utils';

export function isCardDateValid(value) {
  const validityDate = onlyNumbers(value);
  const validityYear = validityDate.substring(
    validityDate.length - 2,
    validityDate.length
  );
  const validityMonth = validityDate.substring(0, 2);
  const fullYear = `${new Date().getFullYear()}`;
  const year = fullYear.substring(fullYear.length - 2, fullYear.length);
  const month = new Date().getMonth();

  if (validityMonth < 1 || validityMonth > 12) {
    return false;
  }

  if (validityYear === year && validityMonth < month + 1) {
    return false;
  }

  if (validityYear < year) {
    return false;
  }

  return true;
}

export function isDateValid(dateValue, timeValue) {
  if (dateValue.length < 10 || timeValue.length <  5) {
    return false;
  }

  // DDMMAAAA
  const date = onlyNumbers(dateValue);
  const dateYear = date.substring(date.length - 4, date.length);
  const dateMonth = date.substring(2, 4);
  const dateDay = date.substring(0, 2);
  // HHNN
  const time = onlyNumbers(timeValue);
  const timeHour = time.substring(0, 2);
  const timeMinute = time.substring(2, 4);

  try {
    new Date(`${dateYear}-${dateMonth}-${dateDay}`).toISOString();

    const validateDate = new Date(
      `${dateYear}-${dateMonth}-${dateDay}T${timeHour}:${timeMinute}`
    );
    const todayDate = new Date();

    if (validateDate <= todayDate) {
      return false;
    }
  } catch (err) {
    return false;
  }

  return true;
}

export function isTimeValid(timeValue) {
  if (timeValue.length <  5) {
    return false;
  }

  // HHNN
  const time = onlyNumbers(timeValue);
  const timeHour = time.substring(0, 2);
  const timeMinute = time.substring(2, 4);

  try {
    new Date(`2001-01-01T${timeHour}:${timeMinute}`).toISOString();
  } catch (err) {
    return false;
  }

  return true;
}
