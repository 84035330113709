import styled from 'styled-components';

import px2vw from '../../../../../utils/responsiveness/px2vw';

export const PlanDetailText2 = styled.div`
  font-size: ${px2vw(12)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.32px;
`;

export const PlanDetailTextBold = styled.div`
  font-size: ${px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.36px;

  ${(props) =>
    props.advertiserEmail &&
    `
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 33ch;
  `};
`;

export const PlanDetailTextBold2 = styled.div`
  font-size: ${px2vw(20)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.48px;

  ${(props) =>
    props.advertiserName &&
    `
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 23ch;
  `};
`;
