import styled, { css } from 'styled-components';

import {
  branco,
  verdeKuppi1,
  cinzaKuppi4,
  cinzaKuppi7,
  cinzaKuppi8,
  verdeKuppi2,
  preto,
} from '../../../../styles/colors';

import px2vw from '../../../../utils/responsiveness/px2vw';

export const ModalHeader = styled.div`
  width: 100%;
  background-color: ${branco};
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: ${px2vw(33)} ${px2vw(28)} ${px2vw(15)} ${px2vw(28)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  font-size: ${px2vw(25)};
  display: flex;
  align-items: space-between;
  justify-content: space-between;
`;

export const CloseButton = styled.button`
  width: ${px2vw(18)};
  height: ${px2vw(18)};
  border: none;
  z-index: 10;
  background-color: transparent;

  :focus {
    background-color: transparent !important;
  }

  ${(props) =>
    props.absolute &&
    css`
      position: absolute;
      top: ${props.top || px2vw(10)};
      right: ${props.right || px2vw(10)};
    `}
`;

export const CloseImage = styled.img`
  width: ${px2vw(22)};
  height: ${px2vw(22)};
`;

export const UploadImage = styled.img`
  width: ${px2vw(71)};
  margin: 0 auto;
  margin-top: ${px2vw(20)};
`;

export const UploadIcon = styled.img`
  width: ${px2vw(15)};
  margin-right: ${px2vw(5)};
`;


export const ModalContent = styled.div`
  background-color: ${branco};
  margin: ${px2vw(5)} ${px2vw(33)};
  display: flex;
  ${(props) => `
    width: ${props.width};
    min-height: ${props.height || px2vw(400)};
    max-height: ${props.maxheight || px2vw(400)};
    background-color: ${props.bgcolor};
    margin-top: ${props.margtop};
  `};
  flex-wrap: wrap;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 0.5em;
    margin-left: 10px;
  }
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${verdeKuppi2};
    outline: 1px solid ${verdeKuppi2};
    border-radius: 15px;
  }
`;

export const CampaignTypeCard = styled.div`
  padding: ${px2vw(23)} ${px2vw(16)};
  width: ${(props) => props.width || px2vw(237)};
  height: ${(props) => props.height || px2vw(340)};
  border: 2px solid ${cinzaKuppi4};
  border-radius: ${px2vw(15)};
  margin: 0 ${px2vw(5)};
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  opacity: ${(props) => props.selected || 0.5};
  cursor: pointer;
`;

export const CampaignTypeImg = styled.img`
  width: ${px2vw(100)};
  height: ${px2vw(142)};
  margin: 0 auto;
  pointer-events: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export const ModalFooter = styled.div`
  width: 100%;
  min-height: ${px2vw(90)};
  margin-top: auto;
  background-color: ${branco};
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: ${px2vw(15)} ${px2vw(40)};
  font-family: AvenirNextLTPro;
  font-weight: 500;
  font-size: ${px2vw(14)};
`;

export const ItemOption = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid #dbdbdb;
  border-top: none;
  border-radius: 10px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  margin: ${px2vw(20)} ${px2vw(10)};

  width: ${px2vw(182.7)};
  height: ${(props) => props.height || px2vw(240)};

  :hover {
    background: ${cinzaKuppi8};
  }

  ${(props) =>
    props.selected &&
    `
    box-shadow: 0px 5px 30px #00000029;
    border: 2px solid ${verdeKuppi1};
    border-top: none;
  `};
`;

export const TagItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  padding: 0px 5px;
  border: 1px solid ${cinzaKuppi7};

  font-size: ${px2vw(10)};
  color: ${cinzaKuppi7};
  background: transparent;
  ${(props) =>
    props.product &&
    `
  border: 1px solid ${preto};
  color:${preto};`}
`;

export const ImageBox = styled.div`
  position: relative;
  ${props => props.conversion && `border-right: 3px solid
    ${props.selected ? verdeKuppi1 : cinzaKuppi4};`}
  ${(props) => props.width && `width: ${props.width};`}
  ${(props) => props.height && `height: ${props.height};`}
`;

export const ImageFrame = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;

  border-top-left-radius: ${(props) => props.btlRadius && props.btlRadius};
  border-top-right-radius: ${(props) => props.btrRadius && props.btrRadius};
  border-bottom-left-radius: ${(props) => props.bblRadius && props.bblRadius};
  border-bottom-right-radius: ${(props) => props.bbrRadius && props.bbrRadius};
`;

export const ProductCard = styled.div`
  border-radius: ${px2vw(10)};
  position: relative;
  display: flex;
  flex-direction: ${props => props.conversion ? 'row' : 'column'};
  border: 3px solid ${cinzaKuppi4};
  ${props => props.static && `
  border: 3px solid ${verdeKuppi2+'6e'};
  `}
  width: ${props => props.width || px2vw(295)};
  height: ${props => props.height || px2vw(200)};
  margin: ${px2vw(5)};
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  ${(props) =>
    props.selected &&
    `
    box-shadow: 0 10px 10px rgba(0,0,0,0.30), 0 6px 3px rgba(0,0,0,0.22);
    border: 4px solid ${verdeKuppi1};
`}
`;
export const ProductDescription = styled.div`
  border-top-right-radius: ${px2vw(10)};
  border-bottom-right-radius: ${px2vw(10)};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: ${props => props.width || px2vw(150)};
  padding: ${px2vw(11)} ${px2vw(8)};
  height: ${props => props.height || px2vw(200)};
`;
;
