import React, { useState, useEffect } from 'react';
import { Icon } from 'react-materialize';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'reactstrap';
import { toast } from 'react-toastify';
import moment from 'moment';

import {
  PageTitle,
  ContentPage,
} from '../_layouts/styles/common';
import { Row, Col } from '../../styles/grid';
import {
  CouponsBox,
  TabsBox,
  TabBox,
  Tab,
  TabSelected,
  NewCouponButton,
  NewCouponButtonText,
  ManageCouponsContent,
  ErrorText,
  SubtitleTextBox,
  SubtitleText,
} from './styles';
import { preto } from '../../styles/colors';

import CouponTable from '../../components/CuponTable';
import SelectNewCouponModal from './Components/Modal/SelectNewCouponModal';
import TraditionalCoupon from './CouponTraditional';
import FlashCoupon from './CouponFlash';
import CodeCoupon from './CouponCode';
import ImportProductToCouponModal from './Components/Modal/ImportProductToCouponModal';
import ConfirmNewCouponModal from './Components/Modal/ConfirmNewCouponModal';

import {
  getCouponsTraditionalRequest,
  getCouponsFlashRequest,
  getCouponsCodeRequest,
  getProductsWithoutCouponsRequest,
  newTraditionalCouponRequest,
  newFlashCouponRequest,
  newCodeCouponRequest,
  updateTraditionalCouponRequest,
  updateFlashCouponRequest,
  updateCodeCouponRequest,
  deleteCouponModalClearSuccess,
  updateAvailableCouponsClearSuccess,
} from '../../store/modules/coupons/actions';

import { amountMask, timeMask, dateMask } from '../../utils/masks';
import { onlyNumbersAmount } from '../../utils/utils';
import { defaultCouponHorary } from '../../utils/commonObjects';
import {
  isTimeValid,
  isDateValid,
} from '../../utils/validations/validations';
import ConfirmDeleteCouponModal from './Components/Modal/ConfirmDeleteCouponModal';
import UpdateAvailableCouponsModal from './Components/Modal/UpdateAvailableCouponsModal';

export default function ManageCoupons() { 
  const couponsTraditional = useSelector((state) => state.coupons.couponsTraditional);
  const couponsFlash = useSelector((state) => state.coupons.couponsFlash);
  const couponsCode = useSelector((state) => state.coupons.couponsCode);
  const [manageCouponsActive, setManageCouponsActive] = useState(true);
  const [currTab, setCurrTab] = useState('tabTraditional');
  const [editCouponObj, setEditCouponObj] = useState(null);
  const [couponType, setCouponType] = useState('');
  const [couponDiscountType, setCouponDiscountType] = useState('');
  const [codeCouponDiscountCode, setCodeCouponDiscountCode] = useState('');
  const [minimumOrderAmountInputValue, setMinimumOrderAmountInputValue] = useState('');
  const [firstOrderDiscountOnly, setFirstOrderDiscountOnly] = useState(false);
  const [percentDiscountTotal, setPercentDiscountTotal] = useState(false);
  const [fixedDiscountTotal, setFixedDiscountTotal] = useState(false);
  const [usedCoupons, setUsedCoupons] = useState('');
  const [
    couponDiscountAmountInputValue,
    setCouponDiscountAmountInputValue,
  ] = useState('');
  const [horarySunday, setHorarySunday] = useState({...defaultCouponHorary});
  const [horaryMonday, setHoraryMonday] = useState({...defaultCouponHorary});
  const [horaryTuesday, setHoraryTuesday] = useState({...defaultCouponHorary});
  const [horaryWednesday, setHoraryWednesday] = useState({...defaultCouponHorary});
  const [horaryThursday, setHoraryThursday] = useState({...defaultCouponHorary});
  const [horaryFriday, setHoraryFriday] = useState({...defaultCouponHorary});
  const [horarySaturday, setHorarySaturday] = useState({...defaultCouponHorary});
  const [productAssociatedToCoupon, setProductAssociatedToCoupon] = useState(
    null
  );
  const [
    couponDiscountPercentInputValue,
    setCouponDiscountPercentInputValue,
  ] = useState('');
  const [
    couponValidityDateInputValue,
    setCouponValidityDateInputValue,
  ] = useState('');
  const [
    couponValidityTimeInputValue,
    setCouponValidityTimeInputValue,
  ] = useState('');
  const [
    couponProductPromotionalAmountInputValue,
    setCouponProductPromotionalAmountInputValue,
  ] = useState('');
  const [
    couponProductOriginalAmountInputValue,
    setCouponProductOriginalAmountInputValue,
  ] = useState('');
  const [couponUseLimitInputValue, setCouponUseLimitInputValue] = useState('');
  const [
    couponProductNameInputValue,
    setCouponProductNameInputValue,
  ] = useState('');
  const [
    couponShortDisplayNamePreview,
    setCouponShortDisplayNamePreview,
  ] = useState('');
  const [couponDisplayNamePreview, setCouponDisplayNamePreview] = useState('');
  const [couponDescriptionPreview, setCouponDescriptionPreview] = useState('');
  let couponShortDisplayName = '';
  let couponDisplayName = '';
  let couponDescription = '';
  const [newCouponMode, setNewCouponMode] = useState('');
  const [newCouponType, setNewCouponType] = useState('');
  const [remainingCharsProductName, setRemainingCharsProductName] = useState(
    '0'
  );
  const [acceptOnStore, setAcceptOnStore] = useState(false);
  const [acceptOnDelivery, setAcceptOnDelivery] = useState(false);
  const [acceptCard, setAcceptCard] = useState(false);
  const [acceptMoney, setAcceptMoney] = useState(false);
  const [acceptPix, setAcceptPix] = useState(false);
  const [createNewCouponModalVisible, setCreateNewCouponModalVisible] = useState(false);
  const [confirmNewCoupon, setConfirmNewCoupon] = useState(false);
  const [importProductToCoupon, setImportProductToCoupon] = useState(false);
  const couponLoading = useSelector((state) => state.coupons.couponsLoading);
  const error = useSelector((state) => state.coupons.error);
  const updateCouponStatusLoading = useSelector(
    (state) => state.coupons.updateCouponStatusLoading
  );
  const updateCouponLoading = useSelector(
    (state) => state.coupons.updateCouponLoading
  );
  const updateCouponSuccess = useSelector(
    (state) => state.coupons.updateCouponSuccess
  );
  const newCouponLoading = useSelector(
    (state) => state.coupons.newCouponLoading
  );
  const newCouponSuccess = useSelector(
    (state) => state.coupons.newCouponSuccess
  );
  const [confirmDeleteCoupom, setConfirmDeleteCoupon] = useState(false);
  const [actionCoupon, setActionCoupon] = useState('');
  const deleteCouponModalSuccess = useSelector(
    (state) => state.coupons.deleteCouponModalSuccess
  );
  const deleteCouponModalLoading = useSelector(
    (state) => state.coupons.deleteCouponModalLoading
  );
  const [availableCouponsModalVisible, setAvailableCouponsModalVisible] = useState(false);
  const updateAvailableCouponsLoading = useSelector(
    (state) => state.coupons.updateAvailableCouponsLoading
  );
  const updateAvailableCouponsSuccess = useSelector(
    (state) => state.coupons.updateAvailableCouponsSuccess
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCouponsTraditionalRequest());
    dispatch(getProductsWithoutCouponsRequest());
  }, [dispatch]);

  function handleTabSelected(selectedTab) {
    if (selectedTab === 'tabTraditional') {
      dispatch(getCouponsTraditionalRequest());
      setCurrTab('tabTraditional')
    } else if (selectedTab === 'tabFlash') {
      dispatch(getCouponsFlashRequest());
      setCurrTab('tabFlash')
    } else if (selectedTab === 'tabCode') {
      dispatch(getCouponsCodeRequest());
      setCurrTab('tabCode')
    }
  }

  function clearCouponState() {
    setHorarySunday({...defaultCouponHorary});
    setHoraryMonday({...defaultCouponHorary});
    setHoraryTuesday({...defaultCouponHorary});
    setHoraryWednesday({...defaultCouponHorary});
    setHoraryThursday({...defaultCouponHorary});
    setHoraryFriday({...defaultCouponHorary});
    setHorarySaturday({...defaultCouponHorary});
    setEditCouponObj(null);
    setProductAssociatedToCoupon(null);
    setCouponProductNameInputValue('');
    setRemainingCharsProductName('0');
    setCouponProductOriginalAmountInputValue('');
    setCouponProductPromotionalAmountInputValue('');
    setCouponDiscountPercentInputValue('');
    setCouponValidityDateInputValue('');
    setCouponValidityTimeInputValue('');
    setCouponUseLimitInputValue('');
    setCouponShortDisplayNamePreview('');
    setCouponDisplayNamePreview('');
    setCouponDescriptionPreview('');
    couponShortDisplayName = '';
    couponDisplayName = '';
    couponDescription = '';
    setCodeCouponDiscountCode('');
    setMinimumOrderAmountInputValue('');
    setFirstOrderDiscountOnly(false);
    setPercentDiscountTotal(false);
    setFixedDiscountTotal(false);
    setCouponDiscountAmountInputValue('');
    setNewCouponMode('');
    setNewCouponType('');
    setAcceptOnStore(false);
    setAcceptOnDelivery(false);
    setAcceptCard(false);
    setAcceptMoney(false);
    setAcceptPix(false);
    setCreateNewCouponModalVisible(false);
    setConfirmNewCoupon(false);
  }

  function handleManageCouponContent(content) {
    if (content === 'back') {
      setManageCouponsActive(true);
      setCouponDiscountType('');
      setCouponType('');
      clearCouponState();
      return;
    }

    if (content === 'codeCoupon') {
      setCouponDiscountType('codeDiscount');
    }

    setCreateNewCouponModalVisible(false);
    setManageCouponsActive(false);
    setConfirmNewCoupon(false);
    setCouponType(content);
  }

  function handleImportProductToCoupon(product) {
    setProductAssociatedToCoupon(product);
    setCouponProductNameInputValue(product.name);
    setRemainingCharsProductName(product.name.length);
    setCouponProductOriginalAmountInputValue(
      amountMask(product.amount.toFixed(2))
    );
    setCouponProductPromotionalAmountInputValue('');
    setImportProductToCoupon(false);
  }

  function couponCommonValidation(couponMode) {
    let couponValidityTime = '00:00';

    // Validity Date
    if (couponValidityDateInputValue === '' && couponMode !== 'code') {
      toast.error('Data de validade é obrigatória.', {
        autoClose: 6000,
      });
      return false;
    }

    // Validity Time - Flash Only
    if (couponMode === 'flash') {
      if (couponValidityTimeInputValue === '') {
        toast.error('Hora de término é obrigatória.', {
          autoClose: 6000,
        });
        return false;
      }
      if (!isTimeValid(couponValidityTimeInputValue)) {
        toast.error('Hora de término inválida.', {
          autoClose: 6000,
        });
        return false;
      }
      couponValidityTime = couponValidityTimeInputValue;

      // Validity Date/Time lightning coupon
      if (!isDateValid(couponValidityDateInputValue, couponValidityTime)) {
        toast.error('Data/Hora de validade inválida.', {
          autoClose: 6000,
        });
        return false;
      }
      // Validity Date/Time traditional coupon
    } else if (!isDateValid(couponValidityDateInputValue, couponValidityTime) && couponMode !== 'code') {
      toast.error('Data/Hora de validade inválida.', {
        autoClose: 6000,
      });
      return false;
    }
    
    if (!['normal', 'flash'].includes(couponMode) && (!acceptOnStore && !acceptOnDelivery)) {
      toast.error(
        'Deve ser selecionado ao menos um local para aceitação do cupom.',
        {
          autoClose: 6000,
        }
      );
      return false;
    }

    if (!acceptCard && !acceptMoney && !acceptPix) {
      toast.error('Deve ser selecionado ao menos uma condição de pagamento.', {
        autoClose: 6000,
      });
      return false;
    }

    if (['flash', 'code'].includes(couponMode)) {
      if (
        couponUseLimitInputValue === '' ||
        couponUseLimitInputValue === null || 
        parseInt(couponUseLimitInputValue) === 0
      ) {
        toast.error('Limite de uso é obrigatório para cupom relâmpago.', {
          autoClose: 6000,
        });
        return false;
      }
    }

    if (['normal', 'code'].includes(couponMode)) {
      if (
        !horarySunday.active &&
        !horaryMonday.active &&
        !horaryTuesday.active &&
        !horaryWednesday.active &&
        !horaryThursday.active &&
        !horaryFriday.active &&
        !horarySaturday.active
      ) {
        toast.error(
          'Deve ser selecionado ao menos um dia para o cupom estar disponível.',
          {
            autoClose: 6000,
          }
        );
        return false;
      }
      if (
        (horarySunday.availability === '2' &&
          (horarySunday.specificHourStart === '' ||
           horarySunday.specificHourEnd === '' ||
            !isTimeValid(horarySunday.specificHourStart) ||
            !isTimeValid(horarySunday.specificHourEnd) ||
            horarySunday.specificHourStart >= horarySunday.specificHourEnd)) ||
        (horaryMonday.availability === '2' &&
          (horaryMonday.specificHourStart === '' ||
            horaryMonday.specificHourEnd === '' ||
            !isTimeValid(horaryMonday.specificHourStart) ||
            !isTimeValid(horaryMonday.specificHourEnd) ||
            horaryMonday.specificHourStart >= horaryMonday.specificHourEnd)) ||
        (horaryTuesday.availability === '2' &&
          (horaryTuesday.specificHourStart === '' ||
            horaryTuesday.specificHourEnd === '' ||
            !isTimeValid(horaryTuesday.specificHourStart) ||
            !isTimeValid(horaryTuesday.specificHourEnd) ||
            horaryTuesday.specificHourStart >= horaryTuesday.specificHourEnd)) ||
        (horaryWednesday.availability === '2' &&
          (horaryWednesday.specificHourStart === '' ||
            horaryWednesday.specificHourEnd === '' ||
            !isTimeValid(horaryWednesday.specificHourStart) ||
            !isTimeValid(horaryWednesday.specificHourEnd) ||
            horaryWednesday.specificHourStart >= horaryWednesday.specificHourEnd)) ||
        (horaryThursday.availability === '2' &&
          (horaryThursday.specificHourStart === '' ||
            horaryThursday.specificHourEnd === '' ||
            !isTimeValid(horaryThursday.specificHourStart) ||
            !isTimeValid(horaryThursday.specificHourEnd) ||
            horaryThursday.specificHourStart >= horaryThursday.specificHourEnd)) ||
        (horaryFriday.availability === '2' &&
          (horaryFriday.specificHourStart === '' ||
            horaryFriday.specificHourEnd === '' ||
            !isTimeValid(horaryFriday.specificHourStart) ||
            !isTimeValid(horaryFriday.specificHourEnd) ||
            horaryFriday.specificHourStart >= horaryFriday.specificHourEnd)) ||
        (horarySaturday.availability === '2' &&
          (horarySaturday.specificHourStart === '' ||
            horarySaturday.specificHourEnd === '' ||
            !isTimeValid(horarySaturday.specificHourStart) ||
            !isTimeValid(horarySaturday.specificHourEnd) ||
            horarySaturday.specificHourStart >= horarySaturday.specificHourEnd))
      ) {
        toast.error(
          'Algum horário foi marcado como horário especifico, porém não foi informado o horário corretamente. Revise os campos.',
          {
            autoClose: 6000,
          }
        );
        return false;
      }
    }

    return true;
  }

  function couponDiscountAmountCommonValidation(couponMode) {
    const originalAmount = (
      1 * onlyNumbersAmount(couponProductOriginalAmountInputValue)
    ).toFixed(2);
    const promotionalAmount = (
      1 * onlyNumbersAmount(couponProductPromotionalAmountInputValue)
    ).toFixed(2);
    couponShortDisplayName = `De R$${Math.trunc(
      parseFloat(originalAmount)
    )} por R$${Math.trunc(parseFloat(promotionalAmount))}`;
    couponDisplayName = `De R$${originalAmount} por R$${promotionalAmount}`;
    couponDescription = couponProductNameInputValue;
    setCouponShortDisplayNamePreview(couponShortDisplayName);
    setCouponDisplayNamePreview(couponDisplayName);
    setCouponDescriptionPreview(couponDescription);

    if (couponProductPromotionalAmountInputValue === '') {
      toast.error('Não foi informado o valor promocional.', {
        autoClose: 6000,
      });
      return false;
    }
    if (couponProductPromotionalAmountInputValue.length < 7) {
      toast.error('Valor promocional inválido.', {
        autoClose: 6000,
      });
      return false;
    }
    if (!couponCommonValidation(couponMode)) {
      return false;
    }
    if (parseFloat(promotionalAmount) >= parseFloat(originalAmount)) {
      toast.error('Valor promocional deve ser menor que o valor original.', {
        autoClose: 6000,
      });
      return false;
    }

    return true;
  }

  function couponPercentCartCommonValidation(couponMode) {
    couponShortDisplayName = `${couponDiscountPercentInputValue}% de desconto`;
    couponDisplayName = `${couponDiscountPercentInputValue}% de desconto`;
    couponDescription = 'No valor total da compra';
    setCouponShortDisplayNamePreview(couponShortDisplayName);
    setCouponDisplayNamePreview(couponDisplayName);
    setCouponDescriptionPreview(couponDescription);

    if (
      couponDiscountPercentInputValue.length > 3 ||
      parseInt(couponDiscountPercentInputValue, 10) > 100 ||
      parseInt(couponDiscountPercentInputValue, 10) === 0 ||
      couponDiscountPercentInputValue === ''
    ) {
      toast.error(
        'Percentual de desconto inválido. O percentual deve estar entre 1% e 100%',
        {
          autoClose: 6000,
        }
      );
    } else if (!couponCommonValidation(couponMode)) {
      return false;
    } else {
      return true;
    }

    return false;
  }

  function couponCodeCommonValidation(couponMode) {
    let promotionalDiscountAmount = 0.0;
    if (percentDiscountTotal) {
      couponShortDisplayName = `${couponDiscountPercentInputValue}% de desconto`;
      couponDisplayName = `${couponDiscountPercentInputValue}% de desconto`;
      couponDescription = 'No valor total da compra';
    } else if (fixedDiscountTotal) {
      promotionalDiscountAmount = (
        1 * onlyNumbersAmount(couponDiscountAmountInputValue)
      ).toFixed(2);
      couponShortDisplayName = `R$${Math.trunc(
        parseFloat(promotionalDiscountAmount)
      )} de desconto`;
      couponDisplayName = `R$${promotionalDiscountAmount} de desconto`;
      couponDescription = 'No valor total da compra';
    }
    setCouponShortDisplayNamePreview(couponShortDisplayName);
    setCouponDisplayNamePreview(couponDisplayName);
    setCouponDescriptionPreview(couponDescription);

    const minimumOrderAmount = (
      1 * onlyNumbersAmount(minimumOrderAmountInputValue)
    ).toFixed(2);

    if (codeCouponDiscountCode.toString().trim() === '') {
      toast.error(
        'Código do cupom não definido',
        {
          autoClose: 6000,
        }
      );
    } else if (!percentDiscountTotal && !fixedDiscountTotal) {
      toast.error(
        'Não foi definido o desconto (Percentual ou Valor)',
        {
          autoClose: 6000,
        }
      );
    } else if (
      percentDiscountTotal && (
        couponDiscountPercentInputValue.length > 3 ||
        parseInt(couponDiscountPercentInputValue, 10) > 100 ||
        parseInt(couponDiscountPercentInputValue, 10) === 0 ||
        couponDiscountPercentInputValue === ''
      )
    ) {
      toast.error(
        'Percentual de desconto inválido. O percentual deve estar entre 1% e 100%',
        {
          autoClose: 6000,
        }
      );
    } else if (fixedDiscountTotal && couponDiscountAmountInputValue === '') {
      toast.error('Não foi informado o valor de desconto', {
        autoClose: 6000,
      });
    } else if (fixedDiscountTotal && couponDiscountAmountInputValue.length < 7) {
      toast.error('Valor de desconto inválido', {
        autoClose: 6000,
      });
    } else if (fixedDiscountTotal && parseFloat(promotionalDiscountAmount) <= parseFloat(0)) {
      toast.error('Valor de desconto deve ser maior que zero', {
        autoClose: 6000,
      });
    } else if (minimumOrderAmountInputValue === '') {
      toast.error('Não foi informado o valor mínimo do pedido', {
        autoClose: 6000,
      });
    } else if (minimumOrderAmountInputValue.length < 7) {
      toast.error('Valor mínimo do pedido inválido', {
        autoClose: 6000,
      });
    } else if (parseFloat(minimumOrderAmount) <= parseFloat(0)) {
      toast.error(
        'Valor mínimo do pedido deve ser maior que zero',
        {
          autoClose: 6000,
        }
      );
    } else if (!couponCommonValidation(couponMode)) {
      return false;
    } else {
      return true;
    }

    return false;
  }

  function handleEditCouponSubmit(couponMode) {
    let valid = false;

    switch (couponDiscountType) {
      case 'traditionalProductDiscount':
      case 'traditionalCartPercentageDiscount':
        if (couponDiscountType === 'traditionalProductDiscount') {
          valid = couponDiscountAmountCommonValidation(couponMode)
        } else if (couponDiscountType === 'traditionalCartPercentageDiscount') {
          valid = couponPercentCartCommonValidation(couponMode)
        }
  
        if (!valid) {
          return
        }
  
        dispatch(
          updateTraditionalCouponRequest(
            editCouponObj.id,
            couponShortDisplayName,
            couponDisplayName,
            couponDescription,
            onlyNumbersAmount(couponProductPromotionalAmountInputValue),
            couponDiscountPercentInputValue,
            couponValidityDateInputValue,
            true,//acceptOnStore,
            true,//acceptOnDelivery,
            acceptCard,
            acceptMoney,
            acceptPix,
            {
              sunday: horarySunday,
              monday: horaryMonday,
              tuesday: horaryTuesday,
              wednesday: horaryWednesday,
              thursday: horaryThursday,
              friday: horaryFriday,
              saturday: horarySaturday,
            },
          )
        );
        break;
      case 'flashProductDiscount':
      case 'flashCartPercentageDiscount':
        if (couponDiscountType === 'flashProductDiscount') {
          valid = couponDiscountAmountCommonValidation(couponMode)
        } else if (couponDiscountType === 'flashCartPercentageDiscount') {
          valid = couponPercentCartCommonValidation(couponMode)
        }
  
        if (!valid) {
          return
        }
  
        dispatch(
          updateFlashCouponRequest(
            editCouponObj.id,
            couponShortDisplayName,
            couponDisplayName,
            couponDescription,
            onlyNumbersAmount(couponProductPromotionalAmountInputValue),
            couponDiscountPercentInputValue,
            couponValidityDateInputValue,
            couponValidityTimeInputValue,
            couponUseLimitInputValue,
            true,//acceptOnStore,
            true,//acceptOnDelivery,
            acceptCard,
            acceptMoney,
            acceptPix,
          )
        );
        break;
      case 'codeDiscount':   
        if (!couponCodeCommonValidation(couponMode)) {
          return
        }
  
        dispatch(
          updateCodeCouponRequest(
            editCouponObj.id,
            newCouponType,
            codeCouponDiscountCode,
            couponShortDisplayName,
            couponDisplayName,
            couponDescription,
            couponDiscountPercentInputValue,
            onlyNumbersAmount(couponDiscountAmountInputValue),
            onlyNumbersAmount(minimumOrderAmountInputValue),
            firstOrderDiscountOnly,
            acceptOnStore,
            acceptOnDelivery,
            acceptCard,
            acceptMoney,
            acceptPix,
            {
              sunday: horarySunday,
              monday: horaryMonday,
              tuesday: horaryTuesday,
              wednesday: horaryWednesday,
              thursday: horaryThursday,
              friday: horaryFriday,
              saturday: horarySaturday,
            },
          )
        );
        break;
      default:
        break;
    }
  }

  function handleNewCouponDiscountAmountSubmit(couponMode) {
    if (couponProductOriginalAmountInputValue === '') {
      toast.error(
        'Não foi importado um combo ou produto do catálogo ao qual será gerado o cupom.',
        {
          autoClose: 6000,
        }
      );
    } else if (couponDiscountAmountCommonValidation(couponMode)) {
      setNewCouponMode(couponMode);
      setNewCouponType('from_value_to_value');
      setConfirmNewCoupon(true);
    }
  }

  function handleNewCouponPercentCartSubmit(couponMode) {
    if (couponPercentCartCommonValidation(couponMode)) {
      setNewCouponMode(couponMode);
      setNewCouponType('percentage_cart');
      setConfirmNewCoupon(true);
    }
  }

  function handleNewCodeCouponSubmit() {
    if (couponCodeCommonValidation('code')) {
      setNewCouponMode('code');
      setConfirmNewCoupon(true);
    }
  }

  function handleNewCouponConfirmSubmit() {
    switch (couponDiscountType) {
      case 'traditionalProductDiscount':
      case 'traditionalCartPercentageDiscount':
        dispatch(
          newTraditionalCouponRequest(
            productAssociatedToCoupon !== null
              ? productAssociatedToCoupon.id
              : null,
            newCouponType,
            couponShortDisplayName || couponShortDisplayNamePreview,
            couponDisplayName || couponDisplayNamePreview,
            couponDescription || couponDescriptionPreview,
            onlyNumbersAmount(couponProductOriginalAmountInputValue),
            onlyNumbersAmount(couponProductPromotionalAmountInputValue),
            couponDiscountPercentInputValue,
            couponValidityDateInputValue,
            true,//acceptOnStore,
            true,//acceptOnDelivery,
            acceptCard,
            acceptMoney,
            acceptPix,
            {
              sunday: horarySunday,
              monday: horaryMonday,
              tuesday: horaryTuesday,
              wednesday: horaryWednesday,
              thursday: horaryThursday,
              friday: horaryFriday,
              saturday: horarySaturday,
            },
          )
        );
        break;
      case 'flashProductDiscount':
      case 'flashCartPercentageDiscount':
        dispatch(
          newFlashCouponRequest(
            productAssociatedToCoupon !== null
              ? productAssociatedToCoupon.id
              : null,
            newCouponType,
            couponShortDisplayName || couponShortDisplayNamePreview,
            couponDisplayName || couponDisplayNamePreview,
            couponDescription || couponDescriptionPreview,
            onlyNumbersAmount(couponProductOriginalAmountInputValue),
            onlyNumbersAmount(couponProductPromotionalAmountInputValue),
            couponDiscountPercentInputValue,
            couponValidityDateInputValue,
            couponValidityTimeInputValue,
            couponUseLimitInputValue,
            true,//acceptOnStore,
            true,//acceptOnDelivery,
            acceptCard,
            acceptMoney,
            acceptPix
          )
        );
        break;
      case 'codeDiscount':
        dispatch(
          newCodeCouponRequest(
            codeCouponDiscountCode,
            newCouponType,
            couponShortDisplayName || couponShortDisplayNamePreview,
            couponDisplayName || couponDisplayNamePreview,
            couponDescription || couponDescriptionPreview,
            couponDiscountPercentInputValue,
            onlyNumbersAmount(couponDiscountAmountInputValue),
            onlyNumbersAmount(minimumOrderAmountInputValue),
            firstOrderDiscountOnly,
            couponUseLimitInputValue,
            acceptOnStore,
            acceptOnDelivery,
            acceptCard,
            acceptMoney,
            acceptPix,
            {
              sunday: horarySunday,
              monday: horaryMonday,
              tuesday: horaryTuesday,
              wednesday: horaryWednesday,
              thursday: horaryThursday,
              friday: horaryFriday,
              saturday: horarySaturday,
            },
          )
        );
        break;
      default:
        break;
    }
  }

  function handleCloseDeleteCoupon() {
    setConfirmDeleteCoupon(false);
    setActionCoupon('');
  }

  function handleDeleteCoupon(item) {
    setConfirmDeleteCoupon(true);
    setActionCoupon(item);
  }

  function handleCloseUsageLimitModal() {
    setAvailableCouponsModalVisible(false);
    setActionCoupon('');
  }

  useEffect(() => {
    if (editCouponObj) {
      setProductAssociatedToCoupon(null);
      setCouponProductNameInputValue('');
      setRemainingCharsProductName('0');
      setCouponProductOriginalAmountInputValue('');
      setCouponProductPromotionalAmountInputValue('');
      setCouponDiscountPercentInputValue('');
      setCouponValidityDateInputValue('');
      setCouponValidityTimeInputValue('');
      setCouponUseLimitInputValue('');
      setCodeCouponDiscountCode('');
      setMinimumOrderAmountInputValue('');
      setFirstOrderDiscountOnly(false);
      setPercentDiscountTotal(false);
      setFixedDiscountTotal(false);
      setCouponDiscountAmountInputValue('');
      setUsedCoupons('');
      setAcceptOnStore(false);
      setAcceptOnDelivery(false);
      setAcceptCard(false);
      setAcceptMoney(false);
      setAcceptPix(false);
      setHorarySunday({...defaultCouponHorary});
      setHoraryMonday({...defaultCouponHorary});
      setHoraryTuesday({...defaultCouponHorary});
      setHoraryWednesday({...defaultCouponHorary});
      setHoraryThursday({...defaultCouponHorary});
      setHoraryFriday({...defaultCouponHorary});
      setHorarySaturday({...defaultCouponHorary});

      if (editCouponObj.validity_at !== null) {
        setCouponValidityDateInputValue(
          dateMask(
            moment(
              editCouponObj.validity_at,
              'YYYY-MM-DD HH:mm:ss',
              true
            ).format('DD/MM/YYYY')
          )
        );
      }

      if (editCouponObj.type === 'from_value_to_value') {
        setCouponProductNameInputValue(editCouponObj.product.name);
        setRemainingCharsProductName(editCouponObj.product.name.length);
        setCouponProductOriginalAmountInputValue(
          amountMask(editCouponObj.benefits.from_value.toFixed(2))
        );
        setCouponProductPromotionalAmountInputValue(
          amountMask(editCouponObj.benefits.by_value.toFixed(2))
        );
        setProductAssociatedToCoupon(editCouponObj.product);
      } 
      
      if (editCouponObj.type === 'percentage_cart') {
        setCouponDiscountPercentInputValue(
          editCouponObj.benefits.cart_discount_percentage
        );
      }

      if (['flash', 'code'].includes(editCouponObj.mode)) {
        setCouponUseLimitInputValue(editCouponObj.usage_limit);
      }
      
      if (editCouponObj.mode === 'flash') {
        setCouponValidityTimeInputValue(
          timeMask(
            moment(
              editCouponObj.validity_at,
              'YYYY-MM-DD HH:mm:ss',
              true
            ).format('HH:mm')
          )
        );
      }

      if (editCouponObj.mode === 'code') {
        setNewCouponType(editCouponObj.type);
        setCodeCouponDiscountCode(editCouponObj.code);
        setMinimumOrderAmountInputValue(amountMask(editCouponObj.order_minimum_amount.toFixed(2)));
        setFirstOrderDiscountOnly(editCouponObj.is_first_order_discount);
        setPercentDiscountTotal(editCouponObj.type === 'percentage_cart');
        setFixedDiscountTotal(editCouponObj.type === 'fixed_cart');
        if (editCouponObj.benefits.cart_discount_fixed > 0) {
          setCouponDiscountAmountInputValue(amountMask(editCouponObj.benefits.cart_discount_fixed.toFixed(2)));
        }
        setUsedCoupons(editCouponObj.used_coupons);

        if (editCouponObj.used_coupons === editCouponObj.usage_limit) {
          toast.warn('Esse cupom atingiu o limite de uso, adicione novos cupons ao limite. Clique aqui para adicionar', { 
            autoClose: false,
            onClick: () => {
              setAvailableCouponsModalVisible(true);
              setActionCoupon(editCouponObj);
            },
          })
        }
      }

      setAcceptOnDelivery(editCouponObj.is_accept_delivery);
      setAcceptOnStore(editCouponObj.is_accept_physical);
      setAcceptCard(editCouponObj.is_accept_credit_card);
      setAcceptMoney(editCouponObj.is_accept_money);
      setAcceptPix(editCouponObj.is_accept_pix);

      if (editCouponObj.horaries !== null) {
        const horarySundayObj = {...defaultCouponHorary};
        if (editCouponObj.horaries.sunday.is_active) {
          horarySundayObj.active = true;
          if (
            editCouponObj.horaries.sunday.horaries[0].start_at !== '00:00' ||
            editCouponObj.horaries.sunday.horaries[0].end_at !== '00:00'
          ) {
            horarySundayObj.availability = '2';
            horarySundayObj.specificHourStart = editCouponObj.horaries.sunday.horaries[0].start_at;
            horarySundayObj.specificHourEnd = editCouponObj.horaries.sunday.horaries[0].end_at;
          }
        }
        setHorarySunday(horarySundayObj);

        const horaryMondayObj = {...defaultCouponHorary};
        if (editCouponObj.horaries.monday.is_active) {
          horaryMondayObj.active = true;
          if (
            editCouponObj.horaries.monday.horaries[0].start_at !== '00:00' ||
            editCouponObj.horaries.monday.horaries[0].end_at !== '00:00'
          ) {
            horaryMondayObj.availability = '2';
            horaryMondayObj.specificHourStart = editCouponObj.horaries.monday.horaries[0].start_at;
            horaryMondayObj.specificHourEnd = editCouponObj.horaries.monday.horaries[0].end_at;
          }
        }
        setHoraryMonday(horaryMondayObj);

        const horaryTuesdayObj = {...defaultCouponHorary};
        if (editCouponObj.horaries.tuesday.is_active) {
          horaryTuesdayObj.active = true;
          if (
            editCouponObj.horaries.tuesday.horaries[0].start_at !== '00:00' ||
            editCouponObj.horaries.tuesday.horaries[0].end_at !== '00:00'
          ) {
            horaryTuesdayObj.availability = '2';
            horaryTuesdayObj.specificHourStart = editCouponObj.horaries.tuesday.horaries[0].start_at;
            horaryTuesdayObj.specificHourEnd = editCouponObj.horaries.tuesday.horaries[0].end_at;
          }
        }
        setHoraryTuesday(horaryTuesdayObj);

        const horaryWednesdayObj = {...defaultCouponHorary};
        if (editCouponObj.horaries.wednesday.is_active) {
          horaryWednesdayObj.active = true;
          if (
            editCouponObj.horaries.wednesday.horaries[0].start_at !== '00:00' ||
            editCouponObj.horaries.wednesday.horaries[0].end_at !== '00:00'
          ) {
            horaryWednesdayObj.availability = '2';
            horaryWednesdayObj.specificHourStart = editCouponObj.horaries.wednesday.horaries[0].start_at;
            horaryWednesdayObj.specificHourEnd = editCouponObj.horaries.wednesday.horaries[0].end_at;
          }
        }
        setHoraryWednesday(horaryWednesdayObj);

        const horaryThursdayObj = {...defaultCouponHorary};
        if (editCouponObj.horaries.thursday.is_active) {
          horaryThursdayObj.active = true;
          if (
            editCouponObj.horaries.thursday.horaries[0].start_at !== '00:00' ||
            editCouponObj.horaries.thursday.horaries[0].end_at !== '00:00'
          ) {
            horaryThursdayObj.availability = '2';
            horaryThursdayObj.specificHourStart = editCouponObj.horaries.thursday.horaries[0].start_at;
            horaryThursdayObj.specificHourEnd = editCouponObj.horaries.thursday.horaries[0].end_at;
          }
        }
        setHoraryThursday(horaryThursdayObj);

        const horaryFridayObj = {...defaultCouponHorary};
        if (editCouponObj.horaries.friday.is_active) {
          horaryFridayObj.active = true;
          if (
            editCouponObj.horaries.friday.horaries[0].start_at !== '00:00' ||
            editCouponObj.horaries.friday.horaries[0].end_at !== '00:00'
          ) {
            horaryFridayObj.availability = '2';
            horaryFridayObj.specificHourStart = editCouponObj.horaries.friday.horaries[0].start_at;
            horaryFridayObj.specificHourEnd = editCouponObj.horaries.friday.horaries[0].end_at;
          }
        }
        setHoraryFriday(horaryFridayObj);

        const horarySaturdayObj = {...defaultCouponHorary};
        if (editCouponObj.horaries.saturday.is_active) {
          horarySaturdayObj.active = true;
          if (
            editCouponObj.horaries.saturday.horaries[0].start_at !== '00:00' ||
            editCouponObj.horaries.saturday.horaries[0].end_at !== '00:00'
          ) {
            horarySaturdayObj.availability = '2';
            horarySaturdayObj.specificHourStart = editCouponObj.horaries.saturday.horaries[0].start_at;
            horarySaturdayObj.specificHourEnd = editCouponObj.horaries.saturday.horaries[0].end_at;
          }
        }
        setHorarySaturday(horarySaturdayObj);
      }
    
      let couponType = '';
      let discountType = '';
      if (editCouponObj.mode === 'normal') {
        couponType = 'traditionalCoupon';
        if (editCouponObj.type === 'from_value_to_value') {
          discountType = 'traditionalProductDiscount';
        } else if (editCouponObj.type === 'percentage_cart') {
          discountType = 'traditionalCartPercentageDiscount';
        } 
      } else if (editCouponObj.mode === 'flash') {
        couponType = 'flashCoupon';
        if (editCouponObj.type === 'from_value_to_value') {
          discountType = 'flashProductDiscount';
        } else if (editCouponObj.type === 'percentage_cart') {
          discountType = 'flashCartPercentageDiscount';
        } 
      } else if (editCouponObj.mode === 'code') {
        couponType = 'codeCoupon';
        discountType = 'codeDiscount';
      }     

      setCouponDiscountType(discountType);
      handleManageCouponContent(couponType);
    }
  }, [editCouponObj])

  useEffect(() => {
    if (updateCouponSuccess) {
      handleManageCouponContent('back');
    }
  }, [updateCouponSuccess])

  useEffect(() => {
    if (newCouponSuccess) {
      handleManageCouponContent('back');
    }
  }, [newCouponSuccess])

  useEffect(() => {
    if (productAssociatedToCoupon !== null && !editCouponObj) {
      setCouponProductPromotionalAmountInputValue('');
      setCouponDiscountPercentInputValue('');
      setCouponValidityDateInputValue('');
      setCouponValidityTimeInputValue('');
      setCouponUseLimitInputValue('');
      setAcceptOnStore(false);
      setAcceptOnDelivery(false);
      setAcceptCard(false);
      setAcceptMoney(false);
      setAcceptPix(false);

      setHorarySunday({...defaultCouponHorary});
      setHoraryMonday({...defaultCouponHorary});
      setHoraryTuesday({...defaultCouponHorary});
      setHoraryWednesday({...defaultCouponHorary});
      setHoraryThursday({...defaultCouponHorary});
      setHoraryFriday({...defaultCouponHorary});
      setHorarySaturday({...defaultCouponHorary});
    }
  }, [productAssociatedToCoupon])

  useEffect(() => {
    if (deleteCouponModalSuccess) {
      handleCloseDeleteCoupon();
      dispatch(deleteCouponModalClearSuccess());
      dispatch(getProductsWithoutCouponsRequest());
    }
  }, [deleteCouponModalSuccess, dispatch])

  useEffect(() => {
    if (updateAvailableCouponsSuccess) {
      handleCloseUsageLimitModal();
      handleManageCouponContent('back');
      dispatch(updateAvailableCouponsClearSuccess())
    }
  }, [updateAvailableCouponsSuccess, dispatch])

  const handler = {
    couponDisplayNamePreview: couponDisplayNamePreview,
    couponDescriptionPreview: couponDescriptionPreview,
    editCouponObj: editCouponObj,
    newCouponLoading: newCouponLoading,
    updateCouponLoading: updateCouponLoading,
    productAssociatedToCoupon: productAssociatedToCoupon,
    setProductAssociatedToCoupon: (val) => setProductAssociatedToCoupon(val),
    couponProductNameInputValue: couponProductNameInputValue,
    setCouponProductNameInputValue: (val) => setCouponProductNameInputValue(val),
    remainingCharsProductName: remainingCharsProductName,
    setRemainingCharsProductName: (val) => setRemainingCharsProductName(val),
    couponProductOriginalAmountInputValue: couponProductOriginalAmountInputValue,
    setCouponProductOriginalAmountInputValue: (val) => setCouponProductOriginalAmountInputValue(val),
    couponProductPromotionalAmountInputValue: couponProductPromotionalAmountInputValue,
    setCouponProductPromotionalAmountInputValue: (val) => setCouponProductPromotionalAmountInputValue(val),
    couponValidityDateInputValue: couponValidityDateInputValue,
    setCouponValidityDateInputValue: (val) => setCouponValidityDateInputValue(val),
    couponDiscountPercentInputValue: couponDiscountPercentInputValue,
    setCouponDiscountPercentInputValue: (val) => setCouponDiscountPercentInputValue(val),
    couponValidityTimeInputValue: couponValidityTimeInputValue,
    setCouponValidityTimeInputValue: (val) => setCouponValidityTimeInputValue(val),
    couponUseLimitInputValue: couponUseLimitInputValue,
    setCouponUseLimitInputValue: (val) => setCouponUseLimitInputValue(val),
    codeCouponDiscountCode: codeCouponDiscountCode,
    setCodeCouponDiscountCode: (val) => setCodeCouponDiscountCode(val),
    minimumOrderAmountInputValue: minimumOrderAmountInputValue,
    setMinimumOrderAmountInputValue: (val) => setMinimumOrderAmountInputValue(val),
    firstOrderDiscountOnly: firstOrderDiscountOnly,
    setFirstOrderDiscountOnly: (val) => setFirstOrderDiscountOnly(val),
    percentDiscountTotal: percentDiscountTotal,
    setPercentDiscountTotal: (val) => setPercentDiscountTotal(val),
    fixedDiscountTotal: fixedDiscountTotal,
    setFixedDiscountTotal: (val) => setFixedDiscountTotal(val),
    couponDiscountAmountInputValue: couponDiscountAmountInputValue,
    setCouponDiscountAmountInputValue: (val) => setCouponDiscountAmountInputValue(val),
    actionCoupon: actionCoupon,
    setActionCoupon: (val) => setActionCoupon(val),
    setAvailableCouponsModalVisible: (val) => setAvailableCouponsModalVisible(val),
    deleteCouponModalLoading: deleteCouponModalLoading,
    handleCloseDeleteCoupon: () => handleCloseDeleteCoupon(),
    updateAvailableCouponsLoading: updateAvailableCouponsLoading,
    handleCloseUsageLimitModal: () => handleCloseUsageLimitModal(),
    usedCoupons: usedCoupons,
    couponType: couponType,
    couponDiscountType: couponDiscountType,
    setCouponDiscountType: (val) => setCouponDiscountType(val),
    acceptOnStore: acceptOnStore,
    setAcceptOnStore: (val) => setAcceptOnStore(val),
    acceptOnDelivery: acceptOnDelivery,
    setAcceptOnDelivery: (val) => setAcceptOnDelivery(val),
    acceptCard: acceptCard,
    setAcceptCard: (val) => setAcceptCard(val),
    acceptMoney: acceptMoney,
    setAcceptMoney: (val) => setAcceptMoney(val),
    acceptPix: acceptPix,
    setAcceptPix: (val) => setAcceptPix(val),
    horarySunday: horarySunday,
    setHorarySunday: (val) => setHorarySunday(val),
    horaryMonday: horaryMonday,
    setHoraryMonday: (val) => setHoraryMonday(val),
    horaryTuesday: horaryTuesday,
    setHoraryTuesday: (val) => setHoraryTuesday(val),
    horaryWednesday: horaryWednesday,
    setHoraryWednesday: (val) => setHoraryWednesday(val),
    horaryThursday: horaryThursday,
    setHoraryThursday: (val) => setHoraryThursday(val),
    horaryFriday: horaryFriday,
    setHoraryFriday: (val) => setHoraryFriday(val),
    horarySaturday: horarySaturday,
    setHorarySaturday: (val) => setHorarySaturday(val),
    setCreateNewCouponModalVisible: (val) => setCreateNewCouponModalVisible(val),
    setNewCouponType: (val) => setNewCouponType(val),
    clearCouponState: () => clearCouponState(),
    setConfirmNewCoupon: (val) => setConfirmNewCoupon(val),
    setImportProductToCoupon: (val) => setImportProductToCoupon(val),
    handleNewCouponConfirmSubmit: () => handleNewCouponConfirmSubmit(),
    handleImportProductToCoupon: (product) => handleImportProductToCoupon(product),
    handleManageCouponContent: (content) => handleManageCouponContent(content),
    handleNewCouponDiscountAmountSubmit: (val) => handleNewCouponDiscountAmountSubmit(val),
    handleNewCouponPercentCartSubmit: (val) => handleNewCouponPercentCartSubmit(val),
    handleNewCodeCouponSubmit: (val)  =>  handleNewCodeCouponSubmit(val),
    handleEditCouponSubmit: (val) => handleEditCouponSubmit(val),
  }

  const contentPage = (
    <>
      <ContentPage>
        {/* Manage Coupons List */}
        {manageCouponsActive && (
          <ManageCouponsContent>
            <PageTitle>Promoções</PageTitle>
            <SubtitleTextBox>
              <SubtitleText>
                Somente os seus 10 melhores descontos ativos e disponíveis
                naquele dia/horário serão exibidos na seção "Melhores descontos"
                do seu Cardápio Web.<br/>Serão exibidos os 5 melhores descontos de
                percentual antes dos demais e os outros 5 serão descontos de
                produtos. Os cupons por código não aparecerão nessa seção.
              </SubtitleText>
            </SubtitleTextBox>
            <CouponsBox>
              <TabsBox>
                <TabBox margrightTab>
                  <Tab onClick={() => handleTabSelected('tabTraditional')}>
                    Tradicional
                  </Tab>
                  <TabSelected isVisible={currTab === 'tabTraditional'} width="100%" />
                </TabBox>
                <TabBox margrightTab>
                  <Tab onClick={() => handleTabSelected('tabFlash')}>
                    Relâmpago
                  </Tab>
                  <TabSelected isVisible={currTab === 'tabFlash'} width="100%" />
                </TabBox>
                <TabBox>
                  <Tab onClick={() => handleTabSelected('tabCode')}>
                    Código
                  </Tab>
                  <TabSelected isVisible={currTab === 'tabCode'} width="100%" />
                </TabBox >
                <NewCouponButton onClick={() => setCreateNewCouponModalVisible(true)}>
                  <NewCouponButtonText>Criar Cupom</NewCouponButtonText>
                </NewCouponButton>
              </TabsBox>
              {(updateCouponStatusLoading || couponLoading) && (
                <Col height="20vh">
                  <Col alignCenter justifyCenter>
                    <Spinner
                      style={{
                        width: '1.8rem',
                        height: '1.8rem',
                        color: preto,
                      }}
                    />
                  </Col>
                </Col>
              )}
              {currTab === 'tabTraditional' && !updateCouponStatusLoading && !couponLoading && (
                <CouponTable
                  data={couponsTraditional}
                  editCoupon={(item) => {
                    if (item.boostCampaign) {
                      toast.error(
                        'Não é possível editar um cupom que tenha uma campanha Kuppi Boost ativa.',
                        {
                          autoClose: 6000,
                        }
                      );
                    } else {
                      setEditCouponObj(item);
                    }
                  }}
                  deleteCoupon={(itemId) => handleDeleteCoupon(itemId)}
                />
              )}
              {currTab === 'tabFlash' && !updateCouponStatusLoading && !couponLoading && (
                <CouponTable
                  data={couponsFlash}
                  editCoupon={(item) => setEditCouponObj(item)}
                  deleteCoupon={(item) => handleDeleteCoupon(item)}
                />
              )}
              {currTab === 'tabCode' && !updateCouponStatusLoading && !couponLoading && (
                <CouponTable
                  data={couponsCode}
                  selectedTab="code"
                  editCoupon={(item) => setEditCouponObj(item)}
                  deleteCoupon={(item) => handleDeleteCoupon(item)}
                  updateAvailableCoupons={(item) => {
                    setAvailableCouponsModalVisible(true);
                    setActionCoupon(item);
                  }}
                />
              )}
            </CouponsBox>
          </ManageCouponsContent>
        )}
        {couponType === 'traditionalCoupon' && (
          <TraditionalCoupon handler={handler} />
        )}
        {couponType === 'flashCoupon' && (
          <FlashCoupon handler={handler} />
        )}
        {couponType === 'codeCoupon' && (
          <CodeCoupon handler={handler} />
        )}
      </ContentPage>
      {createNewCouponModalVisible && (
        <SelectNewCouponModal handler={handler} />
      )}
      {confirmNewCoupon && (
        <ConfirmNewCouponModal handler={handler} />
      )}
      {importProductToCoupon && (
        <ImportProductToCouponModal handler={handler}/>
      )}
      {confirmDeleteCoupom && (
        <ConfirmDeleteCouponModal handler={handler} />
      )}
      {availableCouponsModalVisible && (
        <UpdateAvailableCouponsModal handler={handler} />
      )}
    </>
  );
  const errorPage = (
    <ContentPage>
      <Col alignCenter height="80vh">
        <Row alignCenter justifyCenter>
          {/* TODO: Ajustar tamanho do icone */}
          <Icon large>sentiment_very_dissatisfied</Icon>
          <ErrorText>
            Ocorreu um erro ao carregar os cupons. Tente novamente.
          </ErrorText>
        </Row>
      </Col>
    </ContentPage>
  );

  if (error) {
    return errorPage;
  }
  return contentPage;
}
