export function getRatingsConfigRequest() {
  return {
    type: '@ratings/GET_RATINGS_CONFIG_REQUEST'
  };
}

export function getRatingsConfigSuccess(ratingsConfig) {
  return {
    type: '@ratings/GET_RATINGS_CONFIG_SUCCESS',
    payload: { ratingsConfig }
  };
}

export function getRatingsConfigFailure() {
  return {
    type: '@ratings/GET_RATINGS_CONFIG_FAILURE'
  };
}

export function updateRatingsConfigsRequest(isRatingsActive, ratingsMsgTime) {
  return {
    type: '@ratings/UPDATE_RATINGS_CONFIG_REQUEST',
    payload: { isRatingsActive, ratingsMsgTime }
  }
}

export function updateRatingsConfigsSuccess(ratingsConfig) {
  return {
    type: '@ratings/UPDATE_RATINGS_CONFIG_SUCCESS',
    payload: { ratingsConfig }
  };
}

export function updateRatingsConfigsFailure() {
  return {
    type: '@ratings/UPDATE_RATINGS_CONFIG_FAILURE'
  };
}

export function getRatingsRequest() {
  return {
    type: '@ratings/GET_RATINGS_REQUEST'
  };
}

export function getRatingsSuccess(ratings) {
  return {
    type: '@ratings/GET_RATINGS_SUCCESS',
    payload: ratings
  }
}

export function getRatingsFailure() {
  return {
    type: '@ratings/GET_RATINGS_FAILURE',
  }
}

export function getRatingsPaginatedRequest(paginationPage) {
  return {
    type: '@ratings/GET_RATINGS_PAGINATED_REQUEST',
    payload: { paginationPage }
  };
}

export function getRatingByOrderIdRequest(orderId) {
  return {
    type: '@ratings/GET_RATING_BY_ORDER_ID_REQUEST',
    payload: { orderId }
  };
}

export function getRatingByOrderIdSuccess(ratings) {
  return {
    type: '@ratings/GET_RATING_BY_ORDER_ID_SUCCESS',
    payload: ratings
  }
}

export function getRatingByOrderIdFailure() {
  return {
    type: '@ratings/GET_RATING_BY_ORDER_ID_FAILURE',
  }
}

export function clearSuccess() {
  return {
    type: '@ratings/CLEAR_SUCCESS',
  }
}
