import styled from 'styled-components';

import {
  IoArrowRedoOutline,
  IoCloseCircleOutline,
  IoPrintOutline,
} from 'react-icons/io5';

import px2vw from '../../utils/responsiveness/px2vw';

import {
  branco,
  preto,
  cinzaKuppi3,
  verdeKuppi1,
  verdeKuppi24,
  verdeKuppi2,
  cinzaKuppi4,
} from '../../styles/colors';
import { Col, Row } from '../../styles/grid';

export const OrderDetailModalBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const ModalTitleText = styled.div`
  font-size: ${px2vw(25)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.48px;
`;

export const Amounts = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
`;

export const ItemAmount = styled.div`
  font-size: ${px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.36px;
`;

export const ItemAmountComplement = styled.div`
  font-size: ${px2vw(12)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.36px;
`;

export const ItemName = styled.div`
  font-size: ${px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.36px;
`;

export const ItemObs = styled.div`
  font-size: ${px2vw(12)};
  font-style: italic;
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.36px;
  margin-left: ${px2vw(20)};
  word-wrap: anywhere;
`;

export const ItemCompCategory = styled.div`
  width: ${px2vw(700)};
  font-size: ${px2vw(12)};
  font-style: italic;
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.36px;
  margin-left: ${px2vw(20)};
`;

export const ItemComplement = styled.div`
  width: ${px2vw(700)};
  font-size: ${px2vw(12)};
  font-style: italic;
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.36px;
  margin-left: ${px2vw(40)};
`;

export const ItemNameBold = styled.div`
  font-size: ${px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.36px;

  ${(props) => `
    margin-bottom: ${props.margbottom};
  `};
`;

export const Items = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const OrderItems = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: ${px2vw(-20)};
  min-height: ${px2vw(240)};
`;

export const TextBold = styled.div`
  font-size: ${px2vw(12)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.36px;
  margin-bottom: ${(props) => props.mb || px2vw(0)};
  margin-top: ${(props) => props.mt || px2vw(0)};
`;

export const TextRegular = styled.div`
  font-size: ${(props) => props.size || px2vw(12)};
  margin-left: ${(props) => props.ml || px2vw(0)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.36px;

  ${(props) =>
    props.clientName &&
    `
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    word-wrap: break-word;
    word-break: break-word;
    hyphens: auto;
    white-space: pre-line;
    max-width: 20ch;
  `};

  ${(props) => props.clientAddress && `
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    word-wrap: break-word;
    word-break: break-word;
    hyphens: auto;
    white-space: pre-line;
    max-width: 50ch;
  `};
`;

export const TextMedium = styled.div`
  font-size: ${px2vw(12)};
  font-family: AvenirNextLTPro;
  font-weight: 600;
  letter-spacing: 0.36px;

  ${(props) => `
    margin-right: ${props.margright};
  `};
`;

export const Separator = styled.hr`
  width: 98%;
  opacity: 8%;
  background-color: ${preto};
  margin-top: ${px2vw(-23)};
  margin-bottom: ${px2vw(10)};
  margin-left: ${px2vw(22)};
  margin-right: ${px2vw(22)};

  ${(props) => `
    margin-top: ${props.margtop};
    margin-bottom: ${props.margbottom};
  `};
`;

export const NextStatusIcon = styled(IoArrowRedoOutline).attrs({
  size: px2vw(20),
})`
  margin-top: ${px2vw(-3)};
  margin-right: ${px2vw(15)};
`;

export const CloseIcon = styled(IoCloseCircleOutline).attrs({
  size: px2vw(20),
})`
  margin-top: ${px2vw(-2)};
  margin-right: ${px2vw(15)};
  color: ${preto};
`;

export const PrintIcon = styled(IoPrintOutline).attrs({
  size: px2vw(20),
})`
  margin-top: ${px2vw(-2)};
  margin-right: ${px2vw(15)};
  color: ${preto};
`;

export const ConfirmCancelOrder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ConfirmCancelOrderText = styled.div`
  font-size: ${px2vw(20)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.48px;
  margin-left: ${px2vw(35)};
  margin-right: ${px2vw(35)};
  margin-top: ${px2vw(20)};
  text-align: center;
`;

export const ChoiceButton = styled.div`
  border: 1px solid ${cinzaKuppi3};
  background-color: ${branco};
  width: ${px2vw(12)};
  height: ${px2vw(12)};
`;

export const ChoiceButtonSelected = styled.div`
  position: absolute;
  background-color: ${verdeKuppi1};
  width: ${px2vw(7.5)};
  height: ${px2vw(7.5)};
  margin-top: ${px2vw(2.3)};
  display: none;

  ${(props) =>
    props.isChecked &&
    `
      display: block;
    `};
`;

export const SpanTxt = styled.span`
  color: ${verdeKuppi24};
  ${(props) => props.d && `${props.d}`}
  font-size: ${px2vw(8)};
  text-decoration: underline;
  margin-right: ${px2vw(5)};
  cursor: pointer;
`;

export const PmList = styled(Col)`
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 2px;
    border-radius: 5px;
  }
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px ${cinzaKuppi4};
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${verdeKuppi2};
  }
`;

export const OverflowYBox = styled(PmList)``;
export const PmItem = styled(Row)`
  cursor: pointer;
  background-color: ${branco};
  &:hover {
    background-color: ${cinzaKuppi4};
  }
`;
