import styled from 'styled-components';

import { branco, laranjaKuppi1 } from '../../styles/colors';

import px2vw from '../../utils/responsiveness/px2vw';

export const StatusBox = styled.div`
  padding-top: ${px2vw(12)};
  border: none;
  height: ${px2vw(52)};
  width: ${px2vw(250)};
  border-radius: ${px2vw(10)};
  color: ${branco}};

  ${(props) => `
    background-color: ${laranjaKuppi1};
    margin-left: ${props.margleft};
    width: ${props.width};
    height: ${props.height};
    padding-top: ${props.padtop};
    margin-top: ${props.margtop};
    margin-bottom: ${props.margbottom};
  `};
`;
