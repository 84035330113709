import React, { useState } from 'react';
import Modal from '../../components/Modal';
import px2vw from '../../utils/responsiveness/px2vw';
import { LoadingBox } from '../../styles/common';
import { Spinner } from 'reactstrap';
import { Row, Col } from '../../styles/grid';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import ButtonPrimary from '../../components/ButtonPrimary';
import { preto } from '../../styles/colors';

import {
  ModalHeader,
  ModalTitleText,
  CloseButton,
  CloseImage,
} from '../../components/Modal/styles';

import {
  EditPasswordModal,
  FormBox,
  InputLabel,
  LoginBox,
  LoginInput,
  SeePasswordButton,
  SeePasswordImage,
} from './styles';

import { updateUserPasswordRequest } from '../../store/modules/user/actions';

export default function ChangePasswordModal({ handler, isMobile = false }) {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [newPasswordInputValue, setNewPasswordInputValue] = useState(
    ''
  );
  const [
    confirmNewPasswordInputValue,
    setConfirmNewPasswordInputValue,
  ] = useState('');
  const userLoading = useSelector((state) => state.user.loading);
  const dispatch = useDispatch();

  // Advertiser Personal Data
  const handleEditPersonalDataSubmit = ({
    editPersonalDataPassword,
    editPersonalDataConfirmPassword,
  }) => {
    if (
      editPersonalDataPassword === '' ||
      editPersonalDataConfirmPassword === ''
    ) {
      toast.error('Todos os campos são obrigatórios!', {
        autoClose: 6000,
      });
    } else if (editPersonalDataPassword !== editPersonalDataConfirmPassword) {
      toast.error(
        'O campo nova senha e confirmar nova senha devem ser iguais.',
        {
          autoClose: 6000,
        }
      );
    } else if (editPersonalDataPassword.length < 6) {
      toast.error('A senha deve ter no mínimo 6 dígitos!', {
        autoClose: 6000,
      });
    } else {
      dispatch(updateUserPasswordRequest(editPersonalDataPassword));
    }
  };

  let modalMainWidth = px2vw(300);
  let modalMainHeight = px2vw(300);
  let modalTitleFontSize = px2vw(18);
  let modalOptionFontSize = px2vw(16);
  let modalBoxFieldWidth = px2vw(200);
  let modalBoxFieldHeight = px2vw(29);
  let modalFieldWidth = '80%';
  let modalInputFontSize = px2vw(16);
  let modalBtnFontSize = 16;
  let modalBtnHeight = 40;
  let modalBtnMinWidth = 150;
  let modalBtnBorderRadius = 10;
  let seePasswordImageMargLeft = px2vw(10);

  if (isMobile) {
    modalMainWidth = px2vw(1200);
    modalMainHeight = px2vw(700);
    modalTitleFontSize = px2vw(60);
    modalOptionFontSize = px2vw(45);
    modalBoxFieldWidth = '100%';
    modalBoxFieldHeight = px2vw(100);
    modalFieldWidth = px2vw(1000);
    modalInputFontSize = px2vw(45);
    modalBtnFontSize = 50;
    modalBtnHeight = 150;
    modalBtnMinWidth = 600;
    modalBtnBorderRadius = 25;
    seePasswordImageMargLeft = px2vw(-100);
  }

  return (
    <Modal
      isVisible={handler.changePassword}
      width={modalMainWidth}
      height={modalMainHeight}
    >
      <EditPasswordModal>
        <ModalHeader isMobile={isMobile}>
          <ModalTitleText fontsize={modalTitleFontSize}>Alterar senha</ModalTitleText>
          <CloseButton onClick={handler.setChangePassword}>
            <CloseImage 
              isMobile={isMobile}
              src={require('../../assets/images/close/close.png')} 
            />
          </CloseButton>
        </ModalHeader>
        <FormBox onSubmit={handleEditPersonalDataSubmit}>
          <Row margtop={px2vw(25)} margright={px2vw(15)}>
            <Col>
              <InputLabel fontsize={modalOptionFontSize}>Nova senha</InputLabel>
              <LoginBox width={modalFieldWidth}>
                <LoginInput
                  fontsize={modalInputFontSize}
                  width={modalBoxFieldWidth}
                  height={modalBoxFieldHeight}
                  name="editPersonalDataPassword"
                  type={passwordVisible ? 'text' : 'password'}
                  placeholder="Insira nova senha!"
                  onChange={(e) => setNewPasswordInputValue(e.target.value)}
                />
                <SeePasswordButton
                  type="button"
                  onClick={() => setPasswordVisible(!passwordVisible)}
                >
                  <SeePasswordImage
                    isMobile={isMobile}
                    src={require(passwordVisible
                      ? '../../assets/images/eye-outline/eye-outline.png'
                      : '../../assets/images/eye-outline/eye-off-outline.png')}
                    margleft={seePasswordImageMargLeft}
                  />
                </SeePasswordButton>
              </LoginBox>
            </Col>
          </Row>
          <Row margtop={px2vw(25)} margright={px2vw(15)}>
            <Col>
              <InputLabel fontsize={modalOptionFontSize}>Confirmar nova senha</InputLabel>
              <LoginBox width={modalFieldWidth}>
                <LoginInput
                  width={modalBoxFieldWidth}
                  height={modalBoxFieldHeight}
                  fontsize={modalInputFontSize}
                  name="editPersonalDataConfirmPassword"
                  type={confirmPasswordVisible ? 'text' : 'password'}
                  placeholder="Repita a nova senha!"
                  onChange={(e) =>
                    setConfirmNewPasswordInputValue(e.target.value)
                  }
                />
                <SeePasswordButton
                  type="button"
                  onClick={() =>
                    setConfirmPasswordVisible(!confirmPasswordVisible)
                  }
                >
                  <SeePasswordImage
                    isMobile={isMobile}
                    src={require(confirmPasswordVisible
                      ? '../../assets/images/eye-outline/eye-outline.png'
                      : '../../assets/images/eye-outline/eye-off-outline.png')}
                    margleft={seePasswordImageMargLeft}
                  />
                </SeePasswordButton>
              </LoginBox>
            </Col>
          </Row>
          <Row margtop={px2vw(25)}>
            <ButtonPrimary
              type="submit"
              fontSize={modalBtnFontSize}
              height={modalBtnHeight}
              minWidth={modalBtnMinWidth}
              borderRadius={modalBtnBorderRadius}
              disabled={userLoading}>
              {userLoading ? (
                <LoadingBox>
                  <Spinner
                    style={{
                      width: '1.8rem',
                      height: '1.8rem',
                      color: preto,
                    }}
                  />{' '}
                </LoadingBox>
              ) : (
                'Confirmar'
              )}
            </ButtonPrimary>
          </Row>
        </FormBox>
      </EditPasswordModal>
    </Modal>
  );
}
