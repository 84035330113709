import styled from 'styled-components';

import px2vw from '../../utils/responsiveness/px2vw';

import {
  branco,
  preto,
  cinzaKuppi2,
  cinzaKuppi3,
  cinzaKuppi4,
  verdeKuppi1,
  verdeKuppi4,
  verdeKuppi24,
  verdeKuppi2,
  
} from '../../styles/colors';

export const SelectActionButtonBorder = styled.div`
  width: ${px2vw(15)};
  height: ${px2vw(15)};

  border: ${px2vw(1)} solid ${cinzaKuppi3};
  border-radius: ${px2vw(10)};

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SelectActionButtonGreen = styled.div`
  width: ${px2vw(10)};
  height: ${px2vw(10)};

  background: ${verdeKuppi1};
  border-radius: ${px2vw(10)};

  display: none;

  ${(props) => props.isActive && `
    display: inline-block;
  `}
`;

export const TextForSelectButton = styled.div`
  font-family: AvenirNextLTPro;
  letter-spacing: 0.48px;
  font-size: ${px2vw(13)};
`;

export const ButtonPrimary = styled.button`
  background-color: ${verdeKuppi1};
  width: ${px2vw(218)};
  height: ${px2vw(40)};
  border: 1px solid ${verdeKuppi1};
  border-radius: ${px2vw(10)};
  padding: 0.3em;
  color: ${branco};

  :focus {
    background-color: ${verdeKuppi1} !important;
  }

  :hover {
    background-color: ${verdeKuppi4};
    border: 1px solid ${verdeKuppi1};
  }

  ${(props) => `
    margin-top: ${props.margtop};
    width: ${props.width};
    margin-left: ${props.margleft};
    margin-right: ${props.marginRight};
  `};

  ${(props) =>
    props.disabled &&
    `
    background-color: ${cinzaKuppi4};
    border: 1px solid ${cinzaKuppi4};
  `}
`;

export const ButtonSecondary = styled.button`
  background-color: ${branco};
  width: ${px2vw(218)};
  height: ${px2vw(40)};
  border: 2px solid ${preto};
  border-radius: ${px2vw(10)};
  padding: 0.3em;

  :focus {
    background-color: ${branco} !important;
  }

  :hover {
    background-color: ${cinzaKuppi2};
  }

  ${(props) => `
    margin-top: ${props.margtop};
    width: ${props.width};
    margin-right: ${props.marginRight};
    margin-left: ${props.margleft};
  `};
`;