import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Progress } from 'reactstrap';

import {
  preto,
  verdeKuppi5,
} from '../../../../styles/colors';
import { Text } from '../../../../styles/common';
import { Col, Row } from '../../../../styles/grid';
import {
  CloseButton,
  CloseImage,
  ModalHeader,
  ProgressBar,
  Container,
  StepImg,
  Section,
  ArrowImg,
  SwitchInput,
  ButtonPrimary,
  Ellipse,
  ArrowButton,
} from './styles';

import px2vw from '../../../../utils/responsiveness/px2vw';
import { onlyNumbers } from '../../../../utils/utils';

import {
  setCurrentStep,
  setInstagramId,
} from '../../../../store/modules/kuppiBoost/actions';

export default function InstagramId(props) {
  const dispatch = useDispatch();
  const kuppiBoostData = useSelector((state) => state.kuppiBoost);
  const { instagram_id } = kuppiBoostData;
  const [instagramIdData, setInstagramIdData] = useState(instagram_id ? instagram_id : '');

  const handleSetStep = (nextStep) => {
    dispatch(setCurrentStep(nextStep));
  };

  const handleSetInstagramId = (id) => {
    dispatch(setCurrentStep('4'));
    dispatch(setInstagramId(id));
    toast.success(`Os dados foram salvos com sucesso`, {
      autoClose: 2000,
    });
  };

  return (
    <>
      <ModalHeader>
        Configuração Inicial do Kuppi Boost
        <CloseButton onClick={props.onClose}>
          <CloseImage
            src={require('../../../../assets/images/close/close.png')}
          />
        </CloseButton>
      </ModalHeader>
      <ProgressBar>
        <Progress
          value={66}
          bar={true}
          style={{
            backgroundColor: verdeKuppi5,
            borderRaduis: '0px',
            height: '7px',
          }}
        />
      </ProgressBar>
      <Section>
        <Col width={px2vw(55)} height="100%" relative>
          <ArrowButton onClick={() => handleSetStep('2')}>
            <ArrowImg
              right
              src={require('../../../../assets/images/arrows/arrow_left_ellipse.png')}
            />
          </ArrowButton>
        </Col>
        <Container>
        <Text
            color={preto}
            size={px2vw(14)}
            margtop={px2vw(15)}
            textalign="left"
            justifyStart
          >
            1. Clique em "Contas do Instagram"
          </Text>
          <Text
            color={preto}
            size={px2vw(14)}
            textalign="left"
            justifyStart
          >
            2. Selecione a página do seu Delivery e clique "no ID da sua página" para copiá-lo
          </Text>
          <Text
            color={preto}
            size={px2vw(14)}
            margbottom={px2vw(20)}
            textalign="left"
            justifyStart
          >
            3. Insira o ID no campo e clique em "Salvar"
          </Text>
          <Row height="auto">
            <StepImg
              src={require('../../../../assets/images/kuppi_boost/onboarding_3.png')}
            />
          </Row>
          <Text color={preto} size={px2vw(16)} textalign="left" justifyStart margtop={px2vw(-20)}>
            Insira o ID da sua Página do Instagram aqui:
          </Text>
          <Row height="auto" justifyStart margtop={px2vw(10)}>
            <SwitchInput
              value={instagramIdData}
              onChange={(e) => setInstagramIdData(onlyNumbers(e.target.value))}
              radius={px2vw(3)}
              maxLength="20"
              name="instagram_id"
              width="100%"
            />
            <ButtonPrimary
              complete={instagramIdData.length >= 17}
              disabled={instagramIdData.length < 17}
              onClick={() => handleSetInstagramId(instagramIdData)}
              width={px2vw(280)}
            >
              Salvar
            </ButtonPrimary>
          </Row>
        </Container>
        <Col width={px2vw(55)} height="100%" relative>
          <ArrowButton 
            disabled={instagramIdData.length < 17}
            onClick={() => handleSetInstagramId(instagramIdData)}
          >
            <ArrowImg
              left
              src={require('../../../../assets/images/arrows/arrow_right_ellipse.png')}
            />
          </ArrowButton>
        </Col>
      </Section>
      <Row
        height="auto"
        alignEnd
        relative
        margbottom={px2vw(10)}
        pad={px2vw(7)}
      >
        <Ellipse />
        <Ellipse />
        <Ellipse selected />
        <Ellipse />
      </Row>
    </>
  );
}
