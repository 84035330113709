import React, { useMemo } from 'react';

import { Row } from '../../../styles/grid';

import px2vw from '../../../utils/responsiveness/px2vw';
import { ActivationChallengeBox, NormalText } from '../styles';
import NewProgressBar from '../../ProgressBar/NewProgressBar';

import { ChallengeStep } from './StepChallenge';
import { useChallenge } from '../Context';
import { CloseModal } from '../MassMessageModal/styled';

export const ChallengesDetails = ({
  timePeriod = '48 horas',
  award = '15% de desconto na sua próxima renovação',
  onClose = () => {},
}) => {
  const { tasks } = useChallenge();

  const currentProgress = useMemo(() => {
    return (
      (tasks.reduce((prev, current) => prev + (current.condition || 0), 0) /
        tasks.length) *
      1000
    );
  }, [tasks]);

  return (
    <Row justifyEnd margleft={px2vw(-20)}>
      <ActivationChallengeBox>
        <CloseModal onClick={onClose}>clear</CloseModal>
        <NormalText fontsize={px2vw(16)} fontweight="bold">
          Desafio da Ativação
        </NormalText>
        <NormalText margtop={px2vw(5)} fontsize={px2vw(11)}>
          Complete o desafio da ativação e receba {award}.
        </NormalText>
        <NormalText
          margtop={px2vw(10)}
          margbottom={px2vw(5)}
          fontsize={px2vw(12)}
          fontweight="bold"
        >
          Seu progresso
        </NormalText>
        <NewProgressBar count={currentProgress} />
        <NormalText margtop={px2vw(5)} fontsize={px2vw(11)}>
          Conclua as tarefas abaixo em {timePeriod} horas para resgatar o seu
          desconto.
        </NormalText>

        {(tasks || []).map((challenge, index) => {
          return (
            <ChallengeStep
              key={index}
              isCompleted={challenge.condition}
              step={challenge.step}
              rule={challenge.rule}
              onClick={challenge.onClick}
            />
          );
        })}
      </ActivationChallengeBox>
    </Row>
  );
};
