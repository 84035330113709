import React from 'react';

import { Row } from '../../../../styles/grid';
import { NormalText } from '../../styles';

import px2vw from '../../../../utils/responsiveness/px2vw';

export default function RecoveryGoBackButton({ handler, goBackPage }) {
  return (
    <Row 
      width="auto"
      cursor="pointer"
      alignStart
      onClick={() => handler.setCurrLoginStep(goBackPage)}
      margbottom={px2vw(50)}
    >
      <NormalText mobile center>{' < voltar'}</NormalText >
    </Row>
  );
}

