import React from 'react';

import { Row, Col } from '../../../../styles/grid';
import { SectionTitle, PageText } from '../../styles';

import px2vw from '../../../../utils/responsiveness/px2vw';

import HeaderDefaultSignatureWeb from '../Components/HeaderDefaultSignature/Web';
import HeaderBlockedSignatureWeb from '../Components/HeaderBlockedSignature/Web';
import HeaderCanceledSignatureWeb from '../Components/HeaderCanceledSignature/Web';
import PlanOptionsWeb from '../Components/PlanOptions/Web';
import PlanDetailWeb from '../Components/PlanDetail/Web';
import PaymentDetailWeb from '../Components/PaymentDetail/Web';
import { BonusObsText, SectionTitleText } from '../Components/PlanOptions/constants';

export default function DefaultContentWeb({ handler }) {
  return (
    <>
      {/* Page header when are canceled */}
      {handler.areCanceled && <HeaderCanceledSignatureWeb handler={handler} />}
      {/* Page header when are blocked */}
      {handler.areBlocked && !handler.areCanceled && <HeaderBlockedSignatureWeb handler={handler} />}
      {/* Page header when are not canceled and blocked */}
      {!handler.areCanceled && !handler.areBlocked && <HeaderDefaultSignatureWeb handler={handler} />}
      {/* Plan options */}
      {handler.newPlanOptions.length > 0 && (
        <Col margtop={px2vw(10)}>
          {handler.newPlanOptions.length > 1 && (
            <SectionTitle>
              {SectionTitleText}
            </SectionTitle>
          )}
          <Row margbottom={px2vw(handler.showKuppiBoostDiscounts ? 25 : 40)}>
            <PlanOptionsWeb handler={handler} />
          </Row>
          {handler.showKuppiBoostDiscounts && (
            <Col 
              margbottom={px2vw(40)} 
              width={px2vw(690)}
              padleft={px2vw(110)}
              padright={px2vw(110)}
            >
              <PageText fontsize={px2vw(12)} alignCenter>
                {BonusObsText}
              </PageText>
            </Col>
          )}
        </Col>
      )}
      {/* Plan details */}
      <PlanDetailWeb handler={handler} />
      {/* Payment detail */}
      <PaymentDetailWeb handler={handler} />
    </>
  );
}
