import produce from 'immer';

const INITIAL_STATE = {
  productCategories: [],
  success: false,
  pageLoading: false,
  categoryModalLoading: false,
  categoryModalSuccess: false,
  updateCategoryStatusLoading: false,
  updateProductStatusLoading: false,
  updateProductAmountLoading: false,
  updateComplCategoryStatusLoading: false,
  updateComplOptionStatusLoading: false,
  updateComplOptionAmountLoading: false,
  updateComplCategoryStatusSuccess: false,
  updateComplOptionStatusSuccess: false,
  updateComplOptionAmountSuccess: false,
  productModalLoading: false,
  productModalSuccess: false,
  deleteCategoryProductModalLoading: false,
  deleteCategoryProductModalSuccess: false,
  loadingScreenModalSuccess: false,
  updateProductsCategoryOrdenationModalLoading: false,
  updateProductsCategoryOrdenationModalSuccess: false,
};

export default function productCategories(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@productCategories/GET_PRODUCT_CATEGORIES_REQUEST': {
        draft.pageLoading = true;
        draft.productCategories = [];
        draft.success = false;
        break;
      }
      case '@productCategories/GET_PRODUCT_CATEGORIES_SUCCESS': {
        draft.pageLoading = false;
        draft.productCategories = action.payload.productCategories;
        draft.success = true;
        break;
      }
      case '@productCategories/GET_PRODUCT_CATEGORIES_FAILURE': {
        draft.pageLoading = false;
        draft.productCategories = [];
        draft.success = false;
        break;
      }
      case '@productCategories/CREATE_NEW_CATEGORY_REQUEST': {
        draft.categoryModalLoading = true;
        draft.categoryModalSuccess = false;
        break;
      }
      case '@productCategories/CREATE_NEW_CATEGORY_SUCCESS': {
        draft.categoryModalLoading = false;
        draft.categoryModalSuccess = true;
        break;
      }
      case '@productCategories/CREATE_NEW_CATEGORY_FAILURE': {
        draft.categoryModalLoading = false;
        draft.categoryModalSuccess = false;
        break;
      }
      case '@productCategories/UPDATE_CATEGORY_STATUS_REQUEST': {
        draft.updateCategoryStatusLoading = true;
        break;
      }
      case '@productCategories/UPDATE_CATEGORY_STATUS_SUCCESS': {
        draft.updateCategoryStatusLoading = false;
        break;
      }
      case '@productCategories/UPDATE_CATEGORY_STATUS_FAILURE': {
        draft.updateCategoryStatusLoading = false;
        break;
      }
      case '@productCategories/UPDATE_PRODUCT_STATUS_REQUEST': {
        draft.updateProductStatusLoading = true;
        break;
      }
      case '@productCategories/UPDATE_PRODUCT_STATUS_SUCCESS': {
        draft.updateProductStatusLoading = false;
        break;
      }
      case '@productCategories/UPDATE_PRODUCT_STATUS_FAILURE': {
        draft.updateProductStatusLoading = false;
        break;
      }
      case '@productCategories/UPDATE_PRODUCT_AMOUNT_REQUEST': {
        draft.updateProductAmountLoading = true;
        break;
      }
      case '@productCategories/UPDATE_PRODUCT_AMOUNT_SUCCESS': {
        draft.updateProductAmountLoading = false;
        break;
      }
      case '@productCategories/UPDATE_PRODUCT_AMOUNT_FAILURE': {
        draft.updateProductAmountLoading = false;
        break;
      }
      case '@productCategories/UPDATE_COMPL_CATEGORY_STATUS_REQUEST': {
        draft.updateComplCategoryStatusLoading = true;
        draft.updateComplCategoryStatusSuccess = false;
        break;
      }
      case '@productCategories/UPDATE_COMPL_CATEGORY_STATUS_SUCCESS': {
        draft.updateComplCategoryStatusLoading = false;
        draft.updateComplCategoryStatusSuccess = true;
        break;
      }
      case '@productCategories/UPDATE_COMPL_CATEGORY_STATUS_FAILURE': {
        draft.updateComplCategoryStatusLoading = false;
        draft.updateComplCategoryStatusSuccess = false;
        break;
      }
      case '@productCategories/UPDATE_COMPL_OPTION_STATUS_REQUEST': {
        draft.updateComplOptionStatusLoading = true;
        draft.updateComplOptionStatusSuccess = false;
        break;
      }
      case '@productCategories/UPDATE_COMPL_OPTION_STATUS_SUCCESS': {
        draft.updateComplOptionStatusLoading = false;
        draft.updateComplOptionStatusSuccess = true;
        break;
      }
      case '@productCategories/UPDATE_COMPL_OPTION_STATUS_FAILURE': {
        draft.updateComplOptionStatusLoading = false;
        draft.updateComplOptionStatusSuccess = false;
        break;
      }
      case '@productCategories/UPDATE_COMPL_OPTION_AMOUNT_REQUEST': {
        draft.updateComplOptionAmountLoading = true;
        draft.updateComplOptionAmountSuccess = false;
        break;
      }
      case '@productCategories/UPDATE_COMPL_OPTION_AMOUNT_SUCCESS': {
        draft.updateComplOptionAmountLoading = false;
        draft.updateComplOptionAmountSuccess = true;
        break;
      }
      case '@productCategories/UPDATE_COMPL_OPTION_AMOUNT_FAILURE': {
        draft.updateComplOptionAmountLoading = false;
        draft.updateComplOptionAmountSuccess = false;
        break;
      }
      case '@productCategories/UPDATE_COMPL_CLEAR_SUCCESS': {
        draft.updateComplCategoryStatusSuccess = false;
        draft.updateComplOptionStatusSuccess = false;
        draft.updateComplOptionAmountSuccess = false;
        break;
      }
      case '@productCategories/UPDATE_CATEGORY_NAME_REQUEST': {
        draft.categoryModalLoading = true;
        draft.categoryModalSuccess = false;
        break;
      }
      case '@productCategories/UPDATE_CATEGORY_NAME_SUCCESS': {
        draft.categoryModalLoading = false;
        draft.categoryModalSuccess = true;
        break;
      }
      case '@productCategories/UPDATE_CATEGORY_NAME_FAILURE': {
        draft.categoryModalLoading = false;
        draft.categoryModalSuccess = false;
        break;
      }
      case '@productCategories/CREATE_NEW_PRODUCT_REQUEST': {
        draft.productModalLoading = true;
        draft.productModalSuccess = false;
        break;
      }
      case '@productCategories/CREATE_NEW_PRODUCT_SUCCESS': {
        draft.productModalLoading = false;
        draft.productModalSuccess = true;
        break;
      }
      case '@productCategories/CREATE_NEW_PRODUCT_FAILURE': {
        draft.productModalLoading = false;
        draft.productModalSuccess = false;
        break;
      }
      case '@productCategories/UPDATE_PRODUCT_REQUEST': {
        draft.productModalLoading = true;
        draft.productModalSuccess = false;
        break;
      }
      case '@productCategories/UPDATE_PRODUCT_SUCCESS': {
        draft.productModalLoading = false;
        draft.productModalSuccess = true;
        break;
      }
      case '@productCategories/UPDATE_PRODUCT_FAILURE': {
        draft.productModalLoading = false;
        draft.productModalSuccess = false;
        break;
      }
      case '@productCategories/CLEAR_SUCCESS': {
        draft.success = false;
        break;
      }
      case '@productCategories/CATEGORY_MODAL_CLEAR_SUCCESS': {
        draft.categoryModalSuccess = false;
        break;
      }
      case '@productCategories/PRODUCT_MODAL_CLEAR_SUCCESS': {
        draft.productModalSuccess = false;
        break;
      }
      case '@productCategories/DELETE_CATEGORY_REQUEST': {
        draft.deleteCategoryProductModalLoading = true;
        draft.deleteCategoryProductModalSuccess = false;
        break;
      }
      case '@productCategories/DELETE_PRODUCT_REQUEST': {
        draft.deleteCategoryProductModalLoading = true;
        draft.deleteCategoryProductModalSuccess = false;
        break;
      }
      case '@productCategories/DELETE_CATEGORY_OR_PRODUCT_SUCCESS': {
        draft.deleteCategoryProductModalLoading = false;
        draft.deleteCategoryProductModalSuccess = true;
        break;
      }
      case '@productCategories/DELETE_CATEGORY_OR_PRODUCT_FAILURE': {
        draft.deleteCategoryProductModalLoading = false;
        draft.deleteCategoryProductModalSuccess = false;
        break;
      }
      case '@productCategories/DELETE_CATEGORY_OR_PRODUCT_MODAL_CLEAR_SUCCESS': {
        draft.deleteCategoryProductModalSuccess = false;
        break;
      }
      case '@productCategories/UPDATE_PRODUCT_CATEGORIES_ORDENATION_REQUEST': {
        draft.updateProductsCategoryOrdenationModalLoading = true;
        draft.updateProductsCategoryOrdenationModalSuccess = false;
        break;
      }
      case '@productCategories/UPDATE_PRODUCT_CATEGORIES_ORDENATION_SUCCESS': {
        draft.updateProductsCategoryOrdenationModalLoading = false;
        draft.updateProductsCategoryOrdenationModalSuccess = true;
        break;
      }
      case '@productCategories/UPDATE_PRODUCT_CATEGORIES_ORDENATION_FAILURE': {
        draft.updateProductsCategoryOrdenationModalLoading = false;
        draft.updateProductsCategoryOrdenationModalSuccess = false;
        break;
      }
      case '@productCategories/UPDATE_PRODUCT_CATEGORIES_ORDENATION_CLEAR_SUCCESS': {
        draft.updateProductsCategoryOrdenationModalSuccess = false;
        break;
      }
      default:
    }
  });
}
