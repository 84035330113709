export function AppCuesIdentify (userData) {
  if (process.env.REACT_APP_ENV_ALL_API === 'production' &&
    process.env.REACT_APP_APPCUES_ENABLED === 'true') {
    
    // Verify if WhatsappChat is enabled
    const whatsappChatPermission = userData.user.permissions.find((permission) => {
      return permission.slug === 'whatsapp_bot_chat'
    })
    const isWhatsappChatEnabled = whatsappChatPermission != null;
    // Advertiser Subscriptions
    const advPlan = userData.user.advertiserInfo.advertiserSubscription;

    if (window.Appcues) {
      window.Appcues.identify(
        userData.user.advertiserInfo.id,
        {
          id: userData.user.advertiserInfo.id,
          email: userData.user.email,
          name: userData.user.advertiserInfo.name,
          created_at: userData.user.created_at,
          city: userData.user.address[0].city,
          state: userData.user.address[0].state,
          advertiserCategory: userData.user.advertiserInfo.advertiserCategory.name,
          advertiserPlan: advPlan[advPlan.length - 1].subscription.name,
          advertiserPlanAmount: advPlan[advPlan.length - 1].subscription.amount,
          hasFreeTrialValid: userData.hasFreeTrialValid,
          isWhatsappChatEnabled: isWhatsappChatEnabled
        }
      );
    }
  }
}
