export function setIsChatMinimized(status) {
  return {
    type: '@whatsappChat/SET_IS_CHAT_MINIMIZED',
    payload: status,
  };
}

export function setAllClientsData(data) {
  return {
    type: '@whatsappChat/SET_ALL_CLIENTS_DATA',
    payload: data,
  };
}

export function setSelectedConversation(data) {
  return {
    type: '@whatsappChat/SET_SELECTED_CONVERSATION',
    payload: data,
  };
}

export function getAllClientsData(phoneList, forceLastRequest = true) {
  return {
    type: '@whatsappChat/GET_ALL_CLIENTS_DATA',
    payload: { phoneList, forceLastRequest },
  };
}

export function getAllActiveOrdersData() {
  return {
    type: '@whatsappChat/GET_ALL_ACTIVE_ORDERS_DATA',
  };
}

export function setIsConnected(status) {
  return {
    type: '@whatsappChat/SET_IS_CONNECTED',
    payload: status,
  };
}

export function updateNewMessagesCount(newValue) {
  return {
    type: '@whatsappChat/UPDATE_NEW_MESSAGE_COUNT',
    payload: newValue,
  };
}

export function setMessageCount(phone) {
  return {
    type: '@whatsappChat/SET_MESSAGE_COUNT',
    payload: phone,
  };
}

export function clearChatState() {
  return {
    type: '@whatsappChat/CLEAR_CHAT_STATE',
  };
}

export function clearMessageCount(phone) {
  return {
    type: '@whatsappChat/CLEAR_MESSAGE_COUNT',
    payload: phone,
  };
}

export function setIsCurrSession(status) {
  return {
    type: '@whatsappChat/SET_IS_CURR_SESSIONS',
    payload: status,
  };
}

export function addWaitAttendantList(phone) {
  return {
    type: '@whatsappChat/ADD_WAIT_ATTENDANT_LIST',
    payload: phone,
  };
}

export function setWaitAttendantList(phone) {
  return {
    type: '@whatsappChat/SET_WAIT_ATTENDANT_LIST',
    payload: phone,
  };
}

export function removeWaitAttendantList(phone) {
  return {
    type: '@whatsappChat/REMOVE_WAIT_ATTENDANT_LIST',
    payload: phone,
  };
}

export function setSelectedMedia(base64) {
  return {
    type: '@whatsappChat/SET_SELECTED_MEDIA',
    payload: base64,
  };
}

export function addToRevokedList(id) {
  return {
    type: '@whatsappChat/ADD_TO_REVOKED_LIST',
    payload: id,
  };
}

export function setRevokedMessages(arr) {
  return {
    type: '@whatsappChat/SET_REVOKED_MESSAGES',
    payload: arr,
  };
}

export function setIsDeletingMessage(id) {
  return {
    type: '@whatsappChat/SET_IS_DELETING_MESSAGE',
    payload: id,
  };
}
