import { takeLatest, call, put, all, select } from 'redux-saga/effects';

import { apiAnunciante } from '../../../services/api';

import {
  getClientsSuccess,
  getClientsFailure,
  getClientsMetricsSuccess,
  getClientsMetricsFailure,
  getClientDetailsFailure,
  getClientDetailsSuccess,
} from './actions';
import {
  logout
} from '../auth/actions';

const userData = (state) => state.user.user;

export function* getClientsRequest() {
  try {
    const user = yield select(userData);

    const response = yield call(
      apiAnunciante.get,
      `/user-info/${user.advertiserInfo.id}/clients`
    );

    yield put(getClientsSuccess(response.data));
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(getClientsFailure());
    }
  }
}

export function* getClientsMetricsRequest() {
  try {
    const user = yield select(userData);

    const response = yield call(
      apiAnunciante.get,
      `/user-info/${user.advertiserInfo.id}/clients/metrics`
    );

    yield put(getClientsMetricsSuccess(response.data));
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(getClientsMetricsFailure());
    }
  }
}

export function* getClientDetailsRequest({ payload }) {
  try {
    const user = yield select(userData);

    const response = yield call(
      apiAnunciante.get,
      `/user-info/${user.advertiserInfo.id}/clients/${payload.clientId}/details`
    );

    yield put(getClientDetailsSuccess(response.data));
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(getClientDetailsFailure());
    }
  }
}

export default all([
  takeLatest('@clients/GET_CLIENTS_REQUEST', getClientsRequest),
  takeLatest('@clients/GET_CLIENTS_METRICS_REQUEST', getClientsMetricsRequest),
  takeLatest('@clients/GET_CLIENTS_DETAILS_REQUEST', getClientDetailsRequest)
]);
