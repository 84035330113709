import { takeLatest, call, put, all, select } from 'redux-saga/effects';
import { apiAnunciante } from '../../../services/api';
import { toast } from 'react-toastify';
import {
  deleteComplementsFailure,
  deleteComplementsSuccess,
  setComplements,
  updateComplementAmountFailure,
  updateComplementAmountSuccess,
  updateComplementStatusFailure,
  updateComplementStatusSuccess,
} from './actions';
import { logout } from '../auth/actions';

const userData = (state) => state.user.user;
const complementsData = (state) => state.massHandleComplements;

export function* getComplementsRequest() {
  try {
    const user = yield select(userData);

    const response = yield call(
      apiAnunciante.get,
      `/user-info/${user.advertiserInfo.id}/complements/mass-action`
    );

    yield put(setComplements(response.data));
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    }
  }
}

export function* updateComplementStatusRequest({ payload }) {
  try {
    const { list, status } = payload;
    const user = yield select(userData);

    yield call(
      apiAnunciante.put,
      `/user-info/${user.advertiserInfo.id}/complements/mass-action`,
      { list, status }
    );

    toast.success(
      `Os complementos foram ${status ? 'despausados' : 'pausados'}!`
    );
    yield put(updateComplementStatusSuccess(list, status));
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(updateComplementStatusFailure());
      toast.error('Erro ao atualizar o status dos complementos!');
    }
  }
}

export function* deleteComplementsRequest({ payload }) {
  try {
    const { list } = payload;
    const user = yield select(userData);

    yield call(
      apiAnunciante.patch,
      `/user-info/${user.advertiserInfo.id}/complements/mass-action`,
      { list }
    );

    toast.success(`Os complementos foram excluidos!`);
    yield put(deleteComplementsSuccess(list));
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(deleteComplementsFailure());
      toast.error('Erro ao excluir os complementos!');
    }
  }
}

export function* updateComplementAmountRequest({ payload }) {
  try {
    const user = yield select(userData);
    const complements = yield select(complementsData);

    yield call(
      apiAnunciante.post,
      `/user-info/${user.advertiserInfo.id}/complements/mass-action`,
      {
        amount: payload,
        id: complements.editingAmountComplementID,
      }
    );

    toast.success(`O valor do complemento foi atualizado`, {
      autoClose: 6000,
    });
    yield put(
      updateComplementAmountSuccess(complements.editingAmountComplementID, payload)
    );
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(updateComplementAmountFailure());
      toast.error(`Erro ao atualizar o valor do complemento`, {
        autoClose: 6000,
      });
    }
  }
}

export default all([
  takeLatest('@massHandleComplements/GET_COMPLEMENTS_REQUEST', getComplementsRequest),
  takeLatest(
    '@massHandleComplements/UPDATE_COMPLEMENT_STATUS_REQUEST',
    updateComplementStatusRequest
  ),
  takeLatest('@massHandleComplements/DELETE_COMPLEMENTS_REQUEST', deleteComplementsRequest),
  takeLatest(
    '@massHandleComplements/UPDATE_COMPLEMENT_AMOUNT_REQUEST',
    updateComplementAmountRequest
  ),
]);
