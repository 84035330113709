export function sort(arr, col, direction, inside) {
  const currentArr = [...arr];

  if (inside) {
    const splited = col.split('.');
    const col1 = splited[0];
    const col2 = splited[1];

    if (currentArr && currentArr.length) {
      if (direction === 'asc') {
        currentArr.sort((a, b) => {
          if (a[col1][col2] > b[col1][col2]) return 1;
          if (a[col1][col2] < b[col1][col2]) return -1;
          return 0;
        });
      }
      if (direction === 'desc') {
        currentArr.sort((a, b) => {
          if (a[col1][col2] < b[col1][col2]) return 1;
          if (a[col1][col2] > b[col1][col2]) return -1;
          return 0;
        });
      }
    }
  } else if (currentArr && currentArr.length) {
    if (direction === 'asc') {
      currentArr.sort((a, b) => {
        if (a[col] > b[col]) return 1;
        if (a[col] < b[col]) return -1;
        return 0;
      });
    }
    if (direction === 'desc') {
      currentArr.sort((a, b) => {
        if (a[col] < b[col]) return 1;
        if (a[col] > b[col]) return -1;
        return 0;
      });
    }
  }
  return currentArr;
}

export function makePages(length, perPage) {
  if (length > 0 && perPage > 0) {
    const countKeys = Math.ceil(length / perPage);
    const keys = Array.from(Array(countKeys), (_, i) => i + 1);
    const pages = {};
    let start = 1;
    let end = perPage;

    keys.forEach((item, index) => {
      if (index === keys.length - 1) {
        pages[item] = {
          start,
          end: length,
        };
        start = start + perPage - 1;
        end = end + perPage - 1;
      } else {
        pages[item] = { start, end };
        start += perPage;
        end += perPage;
      }
    });

    return pages;
  }
}
