import styled from 'styled-components';
import { branco } from '../../styles/colors';

export const Container = styled.div`
  display: ${(props) => (props.visible ? 'flex' : 'none')};
  background: rgb(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 100%;
  height: 100%;
  z-index: 10;
`;

export const Modal = styled.div`
  position: relative;
  padding: 30px;
  background: ${branco};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-family: AvenirNextLTPro;
  font-weight: normal;

  box-shadow: 0px 3px 6px #00000029;
  border-radius: 20px;

  width: 750px;
  width: ${(props) => props.width};
  padding: ${(props) => props.padding};
  height: ${(props) => props.height};
  min-height: ${(props) => props.minHeight};
  min-width: ${(props) => props.minWidth};
`;

// export const CloseButton = styled.button`
//   border: none;
//   font-size: 20px;
//   background: transparent;
//   position: absolute;
//   top: 20px;
//   right: 30px;
//   font-family: AvenirNextLTPro;
//   font-weight: normal;

//   :focus {
//     background: transparent;
//   }

//   i {
//     font-size: 34px;
//   }
// `;

export const CloseButton = styled.div`
  border: none;
  background: transparent;
  cursor: pointer;

  i {
    font-size: 40px;
  }
`;

export const Text = styled.div`
  font-size: 16px;
  font-family: AvenirNextLTPro;
  font-weight: 700;

  ${(props) => props.textAlign && `text-align: ${props.textAlign};`}
  ${(props) => props.width && `width: ${props.width};`}
  ${(props) => props.size && `font-size: ${props.size};`}
  ${(props) => props.color && `color: ${props.color};`}
  ${(props) => props.margin && `margin: ${props.margin};`}
  ${(props) => props.margbottom && `margin-bottom: ${props.margbottom};`}
  ${(props) => props.margright && `margin-right: ${props.margright};`}
  ${(props) => props.margleft && `margin-left: ${props.margleft};`}
  ${(props) => props.margtop && `margin-top: ${props.margtop};`}
  ${(props) =>
    props.bold &&
    `font-family: AvenirNextLTPro;
font-weight: 700;`}
  ${(props) =>
    props.regular &&
    `font-family: AvenirNextLTPro;
font-weight: normal;`}

  .bold {
    font-family: AvenirNextLTProBold !important;
  }
`;

export const Text2 = styled.span`
  font-size: 16px;
  font-family: AvenirNextLTPro;
  font-weight: 700;

  ${(props) => props.textAlign && `text-align: ${props.textAlign};`}
  ${(props) => props.width && `width: ${props.width};`}
  ${(props) => props.size && `font-size: ${props.size};`}
  ${(props) => props.color && `color: ${props.color};`}
  ${(props) => props.margin && `margin: ${props.margin};`}
  ${(props) =>
    props.bold &&
    `font-family: AvenirNextLTPro;
font-weight: 700;`}
  ${(props) =>
    props.regular &&
    `font-family: AvenirNextLTPro;
font-weight: normal;`}

  .bold {
    font-family: AvenirNextLTProBold !important;
  }
`;

export const Text3 = styled.div`
  font-size: 16px;
  font-family: AvenirNextLTPro;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;

  ${(props) => props.textAlign && `text-align: ${props.textAlign};`}
  ${(props) => props.width && `width: ${props.width};`}
  ${(props) => props.size && `font-size: ${props.size};`}
  ${(props) => props.color && `color: ${props.color};`}
  ${(props) => props.margin && `margin: ${props.margin};`}
  ${(props) =>
    props.bold &&
    `font-family: AvenirNextLTPro;
font-weight: 700;`}
  ${(props) =>
    props.regular &&
    `font-family: AvenirNextLTPro;
font-weight: normal;`}

  .bold {
    font-family: AvenirNextLTProBold !important;
  }
`;

export const ImageBox = styled.div`
  position: relative;
  ${(props) => props.width && `width: ${props.width};`}
  ${(props) => props.height && `height: ${props.height};`}
`;

export const Image = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;

  border-top-left-radius: ${(props) => props.btlRadius && props.btlRadius};
  border-top-right-radius: ${(props) => props.btrRadius && props.btrRadius};
  border-bottom-left-radius: ${(props) => props.bblRadius && props.bblRadius};
  border-bottom-right-radius: ${(props) => props.bbrRadius && props.bbrRadius};
`;
