import styled from 'styled-components';
import { verdeKuppi1 } from '../../styles/colors';

const Default = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 100%;

  /****FLEX WRAP *** */

  ${(props) => props.flexWrap && `flex-wrap: wrap;`}

  /* ****** OVERFLOW ****** */

  ${(props) => props.overflowY && `overflow-x: hidden; overflow-y: scroll;`}
  ${(props) => props.overflowX && `overflow-y: hidden; overflow-x: scroll;`}
  ${(props) => props.overflow && `overflow: scroll;`}

  /*********** BORDER ********** */

  border: ${(props) => props.border};

  /* ****** BORDER RADIUS ****** */

  ${(props) => props.borderRadius && `border-radius: ${props.borderRadius};`}

  /* ****** BACKGROUND ****** */

  ${(props) => props.background && `background: ${props.background};`}

    /* ****** BOLD ****** */

    ${(props) => props.bold && `font-family: AvenirNextLTPro;
font-weight: 700; `}

  /* ****** BOLD ON HOVER ****** */

  ${(props) =>
    props.boldOnHover && `:hover { font-family: AvenirNextLTPro;
font-weight: 700; }`}

  /* ****** UNDERLINE ****** */

  ${(props) => props.underline && `text-decoration: underline;`}

  /* ****** OVERLINE ****** */

  ${(props) => props.overline && `text-decoration: overline;`}

  /* ****** TRASITION ****** */

  ${(props) => props.transition && `transition: all 0.2s ease-in-out;`}

  /* ****** NOWWRAP ****** */

  ${(props) => props.noWrap && `flex-wrap: nowrap;`}

  /* ****** RIGHT ****** */

  ${(props) => props.alignStart && `align-items: flex-start;`}
  ${(props) => props.justifyStart && `justify-content: flex-start;`}

  /* ****** CENTER ****** */

  ${(props) => props.alignCenter && `align-items: center;`}
  ${(props) => props.justifyCenter && `justify-content: center;`}

  /* ****** LEFT ****** */

  ${(props) => props.alignEnd && `align-items: flex-end;`}
  ${(props) => props.justifyEnd && `justify-content: flex-end;`}

  /* ****** Width / Height ****** */
  width: ${(props) => props.width};
  height: ${(props) => props.height};

  /* ****** MAX Width / MAX Height ****** */
  max-width: ${(props) => props.maxWidth};
  max-height: ${(props) => props.maxHeight};

  /* ****** Padding ****** */
  padding: ${(props) => props.padding};

  /* ****** Margin ****** */
  margin: ${(props) => props.margin};

  /* ****** Z-INDEX ****** */
  z-index: ${(props) => props.zIndex};

  /* ****** Flex ****** */
  ${(props) => props.flexOn && `flex: 1;`}

  /* ****** POSITION ****** */
  ${(props) => props.relative && `position: relative;`}
  ${(props) => props.absolute && `position: absolute;`}
  ${(props) => props.fixed && `position: fixed;`}

    /* ****** SPACE ****** */
  ${(props) => props.spaceAround && `justify-content: space-around;`}
  ${(props) => props.spaceBetween && `justify-content: space-between;`}
  ${(props) => props.spaceEvenly && `justify-content: space-evenly;`}

  /* ****** CURSOR ****** */
  ${(props) => props.pointer && `cursor: pointer;`}

  /* ****** Custon Scrollbar ****** */

  ${(props) =>
    props.custonScrollbar &&
    `
    ::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  ::-webkit-scrollbar:vertical {
    width: 8px;
  }

  ::-webkit-scrollbar:horizontal {
    height: 8px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid #00000000;
    background: ${verdeKuppi1};
  }
  `}


  top: ${(props) => props.left && props.left};
  left: ${(props) => props.left && props.left};
  right: ${(props) => props.right && props.right};
  bottom: ${(props) => props.bottom && props.bottom};

  .ml-auto {margin-left: auto}
  .mr-auto {margin-right: auto}
  .mt-auto {margin-top: auto}
  .mb-auto {margin-bottom: auto}
`;

export const Row = styled(Default)`
  flex-direction: row;
`;

export const Col = styled(Default)`
  flex-direction: column;
`;
