import styled from 'styled-components';
import { Input, Select } from '@rocketseat/unform';

import px2vw from '../../../utils/responsiveness/px2vw';

import {
  branco,
  verdeKuppi2,
  cinzaKuppi3,
  cinzaKuppi4,
  cinzaKuppi6,
  cinzaKuppi5,
  cinzaKuppi9,
  vermelhoKuppi1
} from '../../../styles/colors';

export const CircleTop = styled.div`
  width: ${px2vw(150)};
  height: ${px2vw(75)};
  background-color: ${cinzaKuppi4};

  border-radius: ${px2vw(100)} ${px2vw(100)} 0px 0px;

  z-index: 1;
`;

export const CircleBot = styled.div`
  width: ${px2vw(160)};
  height: ${px2vw(75)};
  background-color: ${verdeKuppi2};
  margin-bottom: ${px2vw(-75)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.48px;
  font-size: ${px2vw(15)};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${branco};
  cursor: default;

  border-radius: 0px 0px ${px2vw(100)} ${px2vw(100)};

  z-index: 1;

  ${(props) => props.isMobile && `
    margin-top: ${px2vw(70)};
    width:${px2vw(300)};
    height: ${px2vw(150)};
    background-color: ${verdeKuppi2};
    border-radius: ${`0px 0px ${px2vw(180)} ${px2vw(180)}`};
  `};
`;

export const InformationContainer = styled.div`
  display: flex;
  z-index: 1;
  #informationImg {
    width: ${px2vw(30)};
    height: ${px2vw(90)};
  };
`;

export const InformationText = styled.div`
  width: ${px2vw(270)};
  height: ${px2vw(90)};
  border-radius: 0px ${px2vw(10)} ${px2vw(10)} 0px;
  background-color: ${branco};
  font-family: AvenirNextLTPro;
  font-size: ${px2vw(15)};
  padding: ${px2vw(10)};
  display: flex;
  flex-direction: column;
`;

export const InputLabel = styled.div`
  font-size: ${(props) => props.fontsize || px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.48px;
  margin-top: ${px2vw(2)};
  display: inline-block;

  ${(props) => ` 
    color: ${props.color};
    margin-top: ${props.margtop};
    padding-left: ${props.padleft};
    padding-right: ${props.padright};
    font-size: ${props.fontsize};
  `};

  ${(props) => props.isMobile && `
    font-size: ${px2vw(2)};
  `};
`;

export const FormDataInput = styled(Input)`
  padding: 0.5em;
  margin-top: ${px2vw(5)};
  border: 1px solid ${cinzaKuppi4};
  border-radius: ${(props) => props.borderradius || px2vw(5)};
  font-size: ${(props) => props.fontsize || px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.36px;
  width: ${px2vw(280)};
  height: ${(props) => props.height || px2vw(40)};

  ::placeholder {
    color: ${cinzaKuppi4} !important;
  }

  :focus {
    border: 1px solid ${cinzaKuppi3} !important;
    box-shadow: none !important;
  }

  ${(props) => `
    width: ${props.width};
    height: ${props.height};
    margin-top: ${props.margtop};
  `};

  ${(props) => props.disabled && `
    background-color: ${cinzaKuppi4};
    color: ${cinzaKuppi3};
    border: 1px solid ${cinzaKuppi3} !important;
  `};

  ${(props) => props.disabled && `
    background-color: ${cinzaKuppi4};
    color: ${cinzaKuppi3};
    border: 1px solid ${cinzaKuppi3} !important;
  `};

  ${(props) => props.empty && `
    border: 1px solid ${vermelhoKuppi1} !important;
  `};
`;

export const SelectInputBox = styled(Select)`
  margin-top: ${px2vw(5)};
  height: ${px2vw(35)};
  font-size: ${px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.36px;

  ${(props) => `
    width: ${props.width};
    height: ${props.height};
    margin-top: ${props.margtop};
    padding-left: ${props.padleft};
    background-color: ${branco};
    border: 1px solid ${cinzaKuppi4};
    border-radius: ${px2vw(5)}
  `};
`;

export const InputImageGroup = styled.div`
  margin-bottom: ${px2vw(20)};
  h3 {
    font-family: AvenirNextLTPro;
    font-weight: 700;
    font-size: ${px2vw(18)};
  }
`;

export const DragImageBox = styled.div`
  width:  ${px2vw(1020)};
  height: ${px2vw(150)};
  border: 1px dashed ${cinzaKuppi5};
  border-radius: ${px2vw(5)};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${(props) => props.pad || `40px 0`} ;
  margin-bottom: ${px2vw(10)};
  position: relative;

  background-color: ${cinzaKuppi5};

  span {
    margin-top: ${px2vw(-50)};
    font-size: ${px2vw(16)};
    font-family: AvenirNextLTPro;
    font-weight: 700;
    color: ${branco};
  };

  ${(props) => props.logo && `
    width: ${px2vw(150)};
    height: ${px2vw(150)};
    border-radius: ${px2vw(99)};
    background-color: ${cinzaKuppi4};
  `};

  ${(props) => props.isUploadCoverMobile && `
    width: ${px2vw(1200)};
    margin-top: ${px2vw(90)};
    border-radius: ${px2vw(30)};
    height: ${px2vw(400)};
    padding: 0px;
  `};

  ${(props) => props.isUploadLogoMobile && `
    width:${px2vw(300)};
    height: ${px2vw(300)};
    background-color: ${cinzaKuppi4};
    border-radius: ${px2vw(180)};
    margin-top: ${px2vw(-50)};
    padding: 0px;

  `};

`;

export const ImageCoverPreview = styled.img`
  width: 100%;
  max-height: ${px2vw(150)};
  object-fit: cover;
  border-radius: ${px2vw(8)};

  ${(props) => props.isMobile && `
    border-radius: ${px2vw(30)};
    max-height: ${px2vw(400)};
    background-size: cover;
    background-clip: none;
  `}
`;

export const ImageLogoPreview = styled.img`
  width: ${px2vw(70)};
  height: ${px2vw(70)};
  border: solid 1px ${cinzaKuppi9};
  border-radius: ${px2vw(35)};

  ${(props) => props.logo && `
    width: ${px2vw(150)};
    height: ${px2vw(150)};
    border-radius: ${px2vw(99)};
  `}

  ${(props) => props.isMobile && `
    width: ${px2vw(300)};
    height: ${px2vw(300)};
    border-radius: ${px2vw(180)};
  `}
`;

export const InputImage = styled.input`
  position: absolute;
  width: 100%;
  padding: 40px 0;
  height: 100%;
  opacity: 0;
  z-index: 2;
  cursor: pointer;
`;

export const SelectImageLabel = styled.label`
  cursor: pointer;
  text-align: left;
  font-size: ${px2vw(13)};
  padding: ${px2vw(5)} ${px2vw(10)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  border-radius: ${px2vw(8)};
  border: 2px solid ${cinzaKuppi6};
`;



