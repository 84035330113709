import produce from 'immer';

const INITIAL_STATE = {
  availableCoupons: [],
  configs: null,
  campaigns: [],
  contactsBlacklist: [],
  contactsPotentialList: [],
  contactsNewList: [],
  contactsRecurrentList: [],
  contactsVipList: [],
  contactsRiskList: [],
  contactsUnsubscribed: [],
  manualList: [],
  sendClientList: [],
  segmentations: [],
  contactListIgnoreWithCampaign: [],
  manualListConfig: null,
  getCampaignDetailsLoading: false,
  cancelMassMessagePaymentLoading: false,
  massMessageResquestLoading: false,
  currentStep: '1',
  selectedCampaign: null,
  selectedCoupon: null,
  image: null,
  message: '',
  selected_schedule: null,
  isBlacklistTableOpen: false,
  blacklistRequestLoading: false,
  blacklistRequestSuccess: false,
  blacklistRequestFailure: false,
  currPayment: null,
  createCampaignLoading: false,
  createCampaignSuccess: false,
  createCampaignFailure: false,
  isUsingBonus: true,
  showUnsubscribeMessage: true,
  sendCatalogLink: true,
  totalAmount: 0,
  isEditing: false,
  isEditingLoading: false,
  isEditingSuccess: false,
  isCancelSuccess: false,
  isCancelLoading: false,
  blacklistConfig: {
    isBoxVisible: false,
    deleteType: '',
    contact: '',
  },
  campaignType: 'massPromotion',
};

export default function massMessage(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@massMessage/SET_CURRENT_STEP': {
        draft.currentStep = action.payload;
        break;
      }
      case '@massMessage/GET_MASS_MESSAGE_DATA': {
        draft.massMessageResquestLoading = true;
        break;
      }
      case '@massMessage/SET_MASS_MESSAGE_DATA': {
        draft.availableCoupons = action.payload.availableCoupons;
        draft.campaigns = action.payload.advertiserMassMessageCampaigns;
        draft.configs = action.payload.configs;
        draft.contactListIgnoreWithCampaign =
          action.payload.contactListWithCampaign;
        draft.contactsBlacklist = [];
        draft.contactsPotentialList = [];
        draft.contactsNewList = [];
        draft.contactsRecurrentList = [];
        draft.contactsVipList = [];
        draft.contactsRiskList = [];
        draft.contactsUnsubscribed = [];

        action.payload.contactList.forEach((c) => {
          if (
            !c.is_enabled_promotional_messages &&
            c.is_accepting_promotional_messages
          ) {
            draft.contactsBlacklist.push(c);
          } else if (!c.is_accepting_promotional_messages) {
            draft.contactsUnsubscribed.push(c);
          } else if (c.type_tag === 'potential') {
            draft.contactsPotentialList.push(c);
          } else if (c.type_tag === 'new') {
            draft.contactsNewList.push(c);
          } else if (c.type_tag === 'recurrent') {
            draft.contactsRecurrentList.push(c);
          } else if (c.type_tag === 'vip') {
            draft.contactsVipList.push(c);
          } else if (c.type_tag === 'risk') {
            draft.contactsRiskList.push(c);
          }
        });

        draft.massMessageResquestLoading = false;
        break;
      }
      case '@massMessage/SET_SELECTED_COUPON': {
        draft.selectedCoupon = action.payload;
        break;
      }
      case '@massMessage/SET_IMAGE': {
        draft.image = action.payload;
        break;
      }
      case '@massMessage/SET_IS_BOX_VISIBLE': {
        draft.blacklistConfig.isBoxVisible = action.payload;
        break;
      }
      case '@massMessage/SET_DELETE_CONFIG': {
        draft.blacklistConfig.deleteType = action.payload.type;
        draft.blacklistConfig.contact = action.payload.contact;
        break;
      }
      case '@massMessage/UPDATE_BLACK_LIST_REQUEST': {
        draft.blacklistRequestLoading = true;
        break;
      }
      case '@massMessage/SET_REMOVE_FROM_BLACKLIST': {
        const restoredFromBlacklist = state.contactsBlacklist.find(
          (c) => c.id === action.payload
        );

        if (restoredFromBlacklist.type_tag === 'potential') {
          draft.contactsPotentialList.push(restoredFromBlacklist);
        } else if (restoredFromBlacklist.type_tag === 'new') {
          draft.contactsNewList.push(restoredFromBlacklist);
        } else if (restoredFromBlacklist.type_tag === 'recurrent') {
          draft.contactsRecurrentList.push(restoredFromBlacklist);
        } else if (restoredFromBlacklist.type_tag === 'vip') {
          draft.contactsVipList.push(restoredFromBlacklist);
        } else if (restoredFromBlacklist.type_tag === 'risk') {
          draft.contactsRiskList.push(restoredFromBlacklist);
        }

        draft.contactsBlacklist = state.contactsBlacklist.filter(
          (c) => c.id !== action.payload
        );
        break;
      }
      case '@massMessage/REMOVE_FROM_BLACK_LIST_REQUEST': {
        draft.blacklistRequestLoading = true;
        break;
      }
      case '@massMessage/SET_INSERT_BLACKLIST': {
        const contactsPotentialDeleteList = [];
        const contactsNewDeleteList = [];
        const contactsRecurrentDeleteList = [];
        const contactsVipDeleteList = [];
        const contactsRiskDeleteList = [];

        action.payload.forEach((c) => {
          if (c.type_tag === 'potential') {
            contactsPotentialDeleteList.push(c.id);
          } else if (c.type_tag === 'new') {
            contactsNewDeleteList.push(c.id);
          } else if (c.type_tag === 'recurrent') {
            contactsRecurrentDeleteList.push(c.id);
          } else if (c.type_tag === 'vip') {
            contactsVipDeleteList.push(c.id);
          } else if (c.type_tag === 'risk') {
            contactsRiskDeleteList.push(c.id);
          }
        });

        draft.contactsPotentialList = state.contactsPotentialList.filter(
          (cp) => !contactsPotentialDeleteList.includes(cp.id)
        );
        draft.contactsNewList = state.contactsNewList.filter(
          (cp) => !contactsNewDeleteList.includes(cp.id)
        );
        draft.contactsRecurrentList = state.contactsRecurrentList.filter(
          (cp) => !contactsRecurrentDeleteList.includes(cp.id)
        );
        draft.contactsVipList = state.contactsVipList.filter(
          (cp) => !contactsVipDeleteList.includes(cp.id)
        );
        draft.contactsRiskList = state.contactsRiskList.filter(
          (cp) => !contactsRiskDeleteList.includes(cp.id)
        );
        draft.manualList = state.manualList.filter(
          (cp) =>
            !contactsRiskDeleteList.includes(cp.id) &&
            !contactsVipDeleteList.includes(cp.id) &&
            !contactsNewDeleteList.includes(cp.id) &&
            !contactsPotentialDeleteList.includes(cp.id) &&
            !contactsRecurrentDeleteList.includes(cp.id)
        );

        draft.contactsBlacklist = action.payload;
        break;
      }
      case '@massMessage/BLACKLIST_REQUEST_FAILURE': {
        draft.blacklistRequestLoading = false;
        break;
      }
      case '@massMessage/BLACKLIST_REQUEST_SUCCESS': {
        draft.blacklistRequestLoading = false;
        break;
      }
      case '@massMessage/SET_IS_BLACKLIST_TABLE_OPEN': {
        draft.isBlacklistTableOpen = action.payload;
        break;
      }
      case '@massMessage/SET_MESSAGE': {
        draft.message = action.payload;
        break;
      }
      case '@massMessage/SET_SELECTED_SCHEDULE': {
        draft.selected_schedule = action.payload;
        break;
      }
      case '@massMessage/CREATE_CAMPAIGN_REQUEST': {
        draft.createCampaignLoading = true;
        break;
      }
      case '@massMessage/CREATE_CAMPAIGN_SUCCESS': {
        draft.createCampaignSuccess = true;
        draft.createCampaignLoading = false;
        break;
      }
      case '@massMessage/CREATE_CAMPAIGN_FAILURE': {
        draft.createCampaignFailure = true;
        draft.createCampaignLoading = false;
        break;
      }
      case '@massMessage/SET_CURRENT_PAYMENT': {
        draft.currPayment = action.payload;
        break;
      }
      case '@massMessage/SET_SELECTED_CAMPAIGN': {
        draft.getCampaignDetailsLoading = false;
        draft.selectedCampaign = action.payload;
        break;
      }
      case '@massMessage/SET_CURRENT_CLIENT_LIST': {
        draft.sendClientList = action.payload;
        break;
      }
      case '@massMessage/SET_CAMPAIGNS': {
        draft.campaigns = action.payload;
        draft.massMessageResquestLoading = false;
        break;
      }
      case '@massMessage/GET_CAMPAIGN_DETAILS': {
        draft.getCampaignDetailsLoading = true;
        break;
      }
      case '@massMessage/CANCEL_MASS_MESSAGE_PAYMENT_REQUEST': {
        draft.cancelMassMessagePaymentLoading = true;
        break;
      }
      case '@massMessage/CANCEL_MASS_MESSAGE_PAYMENT_SUCCESS': {
        draft.cancelMassMessagePaymentLoading = false;
        draft.campaigns = [];
        draft.campaigns = action.payload.advertiserMassMessageCampaigns;
        draft.selectedCampaign.campaignDetails = action.payload.campaignDetails;
        break;
      }
      case '@massMessage/CANCEL_MASS_MESSAGE_PAYMENT_FAILURE': {
        draft.cancelMassMessagePaymentLoading = false;
        break;
      }
      case '@massMessage/SET_MANUAL_LIST': {
        draft.manualList = action.payload.list;
        draft.manualListConfig = action.payload.config;
        break;
      }
      case '@massMessage/SET_SEGMENTATIONS': {
        draft.segmentations = action.payload;
        break;
      }
      case '@massMessage/SET_IS_USING_BONUS': {
        draft.isUsingBonus = action.payload;
        break;
      }
      case '@massMessage/SET_TOTAL_AMOUNT': {
        draft.totalAmount = action.payload;
        break;
      }
      case '@massMessage/CLEAN_MASS_MESSAGE_DATA': {
        draft.sendClientList = [];
        draft.getCampaignDetailsLoading = false;
        draft.currentStep = '1';
        draft.selectedCoupon = null;
        draft.image = null;
        draft.message = '';
        draft.selected_schedule = null;
        draft.isBlacklistTableOpen = false;
        draft.currPayment = null;
        draft.createCampaignLoading = false;
        draft.createCampaignSuccess = false;
        draft.createCampaignFailure = false;
        draft.manualList = [];
        draft.sendCatalogLink = true;
        draft.manualListConfig = null;
        draft.segmentations = [];
        draft.isUsingBonus = true;
        draft.blacklistConfig = {
          isBoxVisible: false,
          deleteType: '',
          contact: '',
        };
        break;
      }
      case '@massMessage/SET_SHOW_UNSUBSCRIBE_MESSAGE':
        draft.showUnsubscribeMessage = action.payload;
        break;
      case '@massMessage/SEND_CATALOG_LINK':
        draft.sendCatalogLink = action.payload;
        break;
      case '@massMessage/SET_CAMPAIGN_TYPE':
        draft.campaignType = action.payload;
        break;
      case '@massMessage/UPDATE_MASS_MESSAGE_CAMPAIGN_CLEAR':
        draft.isEditing = false;
        draft.isEditingSuccess = false;
        draft.isEditingLoading = false;
        break;
      case '@massMessage/UPDATE_MASS_MESSAGE_CAMPAIGN_SUCCESS':
        draft.isEditingSuccess = true;
        draft.isEditingLoading = false;
        break;
      case '@massMessage/UPDATE_MASS_MESSAGE_CAMPAIGN_FAILURE':
        draft.isEditingLoading = false;
        draft.isEditingSuccess = false;
        break;
      case '@massMessage/UPDATE_MASS_MESSAGE_CAMPAIGN_REQUEST':
        draft.isEditingLoading = true;
        break;
      case '@massMessage/CANCEL_MASS_MESSAGE_CAMPAIGN_REQUEST':
        draft.isCancelLoading = true;
        draft.isCancelSuccess = false;
        break;
      case '@massMessage/CANCEL_MASS_MESSAGE_CAMPAIGN_SUCCESS':
        draft.isCancelLoading = false;
        draft.isCancelSuccess = true;
        break;
      case '@massMessage/CANCEL_MASS_MESSAGE_CAMPAIGN_CLEAR':
        draft.isCancelLoading = false;
        draft.isCancelSuccess = false;
        break;
      case '@massMessage/SET_IS_EDITING':
        draft.isEditing = action.payload;
        break;
      default:
    }
  });
}
