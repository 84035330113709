import { createGlobalStyle } from 'styled-components';
import { branco, cinzaKuppi6 } from './colors';

import 'react-toastify/dist/ReactToastify.css';

import AvenirNextLTProBold from '../assets/fonts/AvenirNext/AvenirNextLTPro-Bold.otf';
import AvenirNextLTProBoldItalic from '../assets/fonts/AvenirNext/AvenirNextLTPro-BoldItalic.otf';
import AvenirNextLTProRegular from '../assets/fonts/AvenirNext/AvenirNextLTPro-Regular.otf';
import AvenirNextLTProMedium from '../assets/fonts/AvenirNext/AvenirNextLTPro-Medium.otf';
import AvenirNextLTProMediumItalic from '../assets/fonts/AvenirNext/AvenirNextLTPro-MediumItalic.otf';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  html, body, #root {
    min-height: 100%;
  }

  body {
    background-color: ${branco};
    -webkit-font-smoothing: antialiased !important;
    height: 100%;
  }

  body, input {
    color: ${cinzaKuppi6};
    font-family: AvenirNextLTPro;
    font-weight: normal;
    font-size: 14px;
  }

  button {
    cursor: pointer;
  }

  button:focus {
    outline: none;
  }

  @media (max-width: 800px) {
    html, body, #root {
      overflow-x: hidden;
    }
  }

  @font-face {
    font-family: AvenirNextLTPro;
    src: url(${AvenirNextLTProBold});
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: AvenirNextLTPro;
    src: url(${AvenirNextLTProBoldItalic});
    font-weight: 700;
    font-style: italic;
  }

  @font-face {
    font-family: AvenirNextLTPro;
    src: url(${AvenirNextLTProRegular});
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: AvenirNextLTPro;
    src: url(${AvenirNextLTProMedium});
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: AvenirNextLTPro;
    src: url(${AvenirNextLTProMediumItalic});
    font-weight: 600;
    font-style: italic;
  }
`;
