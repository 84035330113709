import React from 'react';

import {
  CategoryModal,
  ModalTitleText,
  CopyComplementSubtitle,
  CopyComplementBox,
  CopyComplementName,
  CopyComplementsBox,
} from '../styles';

import Modal from '../../../components/Modal';
import {
  ModalHeader,
  CloseButton,
  CloseImage,
  ModalPrimaryButton,
  ModalPrimaryButtonText,
  ModalActions,
  ModalSecundaryButton,
  ModalSecundaryButtonText,
} from '../../../components/Modal/styles';

import {
  cinzaKuppi2,
  cinzaKuppi4,
} from '../../../styles/colors';

import px2vw from '../../../utils/responsiveness/px2vw';

import {
  onlyNumbersAmount
} from '../../../utils/utils';

import {
  amountMask
} from '../../../utils/masks';

export default function CatalogCopyProductComplementModal({ handler }) {
  return (
    <Modal
      isVisible={handler.copyProductComplementModal}
      width={px2vw(600)}
      height={px2vw(580)}
      padding={px2vw(25)}
    >
      <CategoryModal>
        <ModalHeader>
          <ModalTitleText>Copiar complementos</ModalTitleText>
          <CloseButton
            onClick={handler.handleCloseCopyProductComplementModal}
          >
            <CloseImage
              src={require('../../../assets/images/close/close.png')}
            />
          </CloseButton>
        </ModalHeader>
        <CopyComplementSubtitle>
          Para copiar os complementos, escolha o produto desejado e
          confirme.
        </CopyComplementSubtitle>
        <CopyComplementsBox>
          {handler.productCategories.map((category, idx) =>
            category.products.map(
              (product, idxComp) =>
                product.productComplementCategories.length > 0 && (
                  <CopyComplementBox
                    className="productCopyBox"
                    id={`complementCopyIdxComp${idx}-${idxComp}`}
                    onClick={() => {
                      // Unmark all of others complements itens
                      const elements = document.getElementsByClassName(
                        'productCopyBox'
                      );
                      for (let i = 0; i < elements.length; i++) {
                        elements[i].style.backgroundColor = cinzaKuppi2;
                      }

                      document.getElementById(
                        `complementCopyIdxComp${idx}-${idxComp}`
                      ).style.backgroundColor = cinzaKuppi4;
                      handler.setCopyComplementIdx(idxComp);
                      handler.setCopyCategoryIdx(idx);
                    }}
                  >
                    <CopyComplementName
                      title={
                        product.name.length >= 66 ? product.name : null
                      }
                    >
                      {product.name.length >= 66
                        ? `${product.name.substring(0, 58)}...`
                        : product.name}
                    </CopyComplementName>
                  </CopyComplementBox>
                )
            )
          )}
        </CopyComplementsBox>
        <ModalActions>
          <ModalSecundaryButton
            margleft={px2vw(35)}
            marginRight={px2vw(20)}
            margtop={px2vw(30)}
            width={px2vw(220)}
            onClick={handler.handleCloseCopyProductComplementModal}
          >
            <ModalSecundaryButtonText>Cancelar</ModalSecundaryButtonText>
          </ModalSecundaryButton>
          <ModalPrimaryButton
            margleft={px2vw(20)}
            margtop={px2vw(30)}
            width={px2vw(220)}
            disabled={
              !(handler.copyCategoryIdx !== null && handler.copyComplementIdx !== null)
            }
            onClick={() => {
              handler.setCopyComplementIdx(null);
              handler.setCopyCategoryIdx(null);
              handler.setComplementsArray([]);

              handler.productCategories[handler.copyCategoryIdx].products[
                handler.copyComplementIdx
              ].productComplementCategories.map((category, idx) => {
                handler.complementsArray.push({
                  name: category.name,
                  is_active: true,
                  is_required: category.is_required,
                  min_qty: category.min_qty,
                  max_qty: category.max_qty,
                  ordenation_idx: category.ordenation_index,
                  complements: [],
                });

                category.productComplementOptions.map((complement) =>
                  handler.complementsArray[idx].complements.push({
                    name: complement.name,
                    description: complement.description,
                    amount: complement.amount
                      ? onlyNumbersAmount(
                          amountMask(complement.amount.toFixed(2))
                        )
                      : '',
                    is_active: true,
                    ordenation_idx: complement.ordenation_index,
                  })
                );

                return true;
              });

              handler.setComplementsArray([...handler.complementsArray]);
              handler.setCopyProductComplementModal(false);
              handler.setComplementActionHidden(false);
            }}
          >
            <ModalPrimaryButtonText
              disabled={
                !(handler.copyCategoryIdx !== null && handler.copyComplementIdx !== null)
              }
            >
              Confirmar
            </ModalPrimaryButtonText>
          </ModalPrimaryButton>
        </ModalActions>
      </CategoryModal>
    </Modal>
  );
}
