import styled, { css } from 'styled-components';

import {
  branco,
  cinzaKuppi11,
  cinzaKuppi4,
  cinzaKuppi7,
  verdeKuppi10,
  verdeKuppi2,
} from '../../../../styles/colors';

import px2vw from '../../../../utils/responsiveness/px2vw';

export const ModalHeader = styled.div`
  width: 100%;
  background-color: ${branco};
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: ${px2vw(33)} ${px2vw(28)} ${px2vw(15)} ${px2vw(28)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  font-size: ${px2vw(25)};
  display: flex;
  align-items: space-between;
  justify-content: space-between;
`;

export const CloseButton = styled.button`
  width: ${px2vw(18)};
  height: ${px2vw(18)};
  border: none;
  z-index: 10;
  background-color: transparent;

  :focus {
    background-color: transparent !important;
  }

  ${(props) =>
    props.absolute &&
    css`
      position: absolute;
      top: ${props.top || px2vw(10)};
      right: ${props.right || px2vw(10)};
    `}
`;

export const CloseImage = styled.img`
  width: ${px2vw(15)};
  height: ${px2vw(15)};
`;

export const ModalContent = styled.div`
  background-color: ${branco};
  padding: ${(props) => props.padding || px2vw(28)};
  padding-top: 0px;
  padding-bottom: 0px;
  display: flex;
  justify-content: start;
  align-items: start;
  flex-direction: column;
  ${(props) => `
    width: ${props.width};
    min-height: ${props.height || px2vw(400)};
    max-height: ${props.maxheight || px2vw(350)};
    background-color: ${props.bgcolor};
    margin-top: ${props.margtop};
  `};
`;

export const ModalFooter = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: ${px2vw(90)};
  margin-top: auto;
  background-color: ${branco};
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: ${px2vw(15)} ${px2vw(32)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  font-size: ${px2vw(22)};
`;

export const Span = styled.span`
  cursor: ${(props) => (props.cursor ? props.cursor : 'initial')};
  padding: ${(props) => (props.padding ? props.padding : '0')};
  font-size: ${(props) => (props.fontSize ? props.fontSize : px2vw(14))};
  font-family: AvenirNextLTPro;
  letter-spacing: 0.36px;
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 'bold')};
  display: ${(props) => (props.display ? props.display : 'block')};
  color: ${(props) => (props.color ? props.color : verdeKuppi2)};
  text-align: ${(props) => (props.alignCenter ? 'center' : 'initial')};
  @media (max-width: 500px) {
    font-size: ${px2vw(20)};
  }
  .badge {
    background: ${cinzaKuppi11};
    font-size: ${px2vw(13)};
  }
`;

export const ScheduleDay = styled.div`
  display: flex;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  margin: 0 ${px2vw(3)};
  padding: ${px2vw(6)} ${px2vw(17)};
  border: ${(props) =>
    props.selected
      ? `2px solid  ${verdeKuppi10}`
      : `2px solid  ${cinzaKuppi7}`};
  box-shadow: ${(props) =>
    props.selected ? `0px 0px 12px 1px ${verdeKuppi10}40` : 'none'};
  border-radius: 6px;
`;

export const SideSlider = styled.div`
  height: ${px2vw(110)};
  width: 100%;
  display: flex;
  -webkit-overflow-scrolling: touch;
  align-items: center;
  justify-content: flex-start;
  padding: ${px2vw(10)} 0;
  overflow-x: scroll;
  ::-webkit-scrollbar {
    height: 0.3em;
  }
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${verdeKuppi2};
    outline: 1px solid ${verdeKuppi2};
    height: 0.3em;
  }
`;

export const ScheduleItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  align-items: center;
  padding: ${px2vw(3)} ${px2vw(3)};
  border-top: 2px solid ${cinzaKuppi4};
  &:last-child {
    border-bottom: 2px solid ${cinzaKuppi4};
  }
`;

export const Input = styled.input`
  border: 2px solid ${cinzaKuppi7};
  border-radius: ${px2vw(6)};
  height: ${px2vw(30)};
  width: ${(props) => props.width || px2vw(50)};
  text-align: center;
  font-family: AvenirNextLTPro;
  font-style: normal;
  font-size: ${px2vw(14)};
  line-height: 130%;

  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    opacity: 1;
  }
`;

export const PixCodeImg = styled.img`
  margin: ${px2vw(25)} auto;
  width: ${px2vw(200)};
  height: ${px2vw(200)};
`;

export const PixCodeBar = styled.div`
  width: 89%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  align-items: center;
  font-style: normal;
  font-weight: 700;
  font-size: ${px2vw(12)};
  line-height: ${px2vw(19)};
  text-align: center;
  letter-spacing: 0.005em;
  position: relative;
  vertical-align: middle;
`;

export const AnimatedImg = styled.img`
  width: ${px2vw(250)};
  height: ${px2vw(250)};
`;
