import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import { cpfCnpjMask } from '../../utils/masks';

import { onlyNumbers } from '../../utils/utils';

import CpfCnpjValidator from '../../utils/validations/CpfCnpjValidator';

import {
  loginRequest,
  recoverPasswordRequest,
  recoverEmailRequest,
  clearFields,
} from '../../store/modules/auth/actions';
import RealTimeDimensions from '../../utils/realTimeDimensions';

import LoginWeb from './Web';
import LoginMobile from './Mobile';

export default function Login() {
  const urlParams = new URLSearchParams(useLocation().search);
  const error = urlParams.get('erro');
  const [width] = RealTimeDimensions();
  const isMobile = width > 0 && width < 800 ? true : false;
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [forgotEmailPasswordModal, setForgotEmailPasswordModal] = useState(
    false
  );
  const [recoverEmailModal, setRecoverEmailModal] = useState(false);
  const [completeNameInputValue, setCompleteNameInputValue] = useState('');
  const [cnpjInputValue, setCnpjInputValue] = useState('');
  const recoveredEmail = useSelector((state) => state.auth.recoveredEmail);
  const [recoveredEmailModal, setRecoveredEmailModal] = useState(false);
  const [notRecoveredEmailModal, setNotRecoveredEmailModal] = useState(false);
  const [recoverPasswordModal, setRecoverPasswordModal] = useState(false);
  const [
    sentInstructionsPasswordModal,
    setSentInstructionsPasswordModal,
  ] = useState(false);
  const [currLoginStep, setCurrLoginStep] = useState('main');
  const loading = useSelector((state) => state.auth.loading);
  const recoverEmailLoading = useSelector(
    (state) => state.auth.recoverEmailLoading
  );
  const recoverEmailSuccess = useSelector(
    (state) => state.auth.recoverEmailSuccess
  );
  const recoverEmailError = useSelector(
    (state) => state.auth.recoverEmailError
  );
  
  const dispatch = useDispatch();

  // Get permission to send notifications
  async function getNotificationPermission() {
    // Verify if the browser has notification functionality
    if ('Notification' in window) {
      // If user blocked the notification permission
      if (Notification.permission === 'denied') {
        toast.info(
          'Você não permitiu que a nossa plataforma envie notificações. Caso queira as notificações do navegador, ative-as.',
          {
            autoClose: 8000,
          }
        );
      } else {
        // Ask user to allow notification permissions
        Notification.requestPermission();
      }
    }
  }

  useEffect(() => {
    dispatch(clearFields());
    getNotificationPermission();
  }, [dispatch]);

  useEffect(() => {
    if (error === 'sessao-invalida') {
      toast.error('Sua sessão está inválida. Faça login novamente', {
        autoClose: false,
      });
    }
  }, [error])

  const handleSubmit = ({ email, password }) => {
    dispatch(loginRequest(email, password));
  };

  function handleForgotPasswordEmail() {
    if (isMobile) {
      setCurrLoginStep("recovery_type");
    } else {
      setForgotEmailPasswordModal(true);
    }
  }

  function handleForgotEmail() {
    setCompleteNameInputValue('');
    setCnpjInputValue('');

    if (isMobile) {
      setCurrLoginStep('recovery_email');
    } else {
      setForgotEmailPasswordModal(false);
      setRecoverEmailModal(true);
    }
  }

  function handleForgotPassword() {
    if (isMobile) {
      setCurrLoginStep('recovery_password');
    } else {
      setForgotEmailPasswordModal(false);
      setRecoverPasswordModal(true);
    }
  }

  function handleRecoverEmail() {
    if (completeNameInputValue === '' || cnpjInputValue === '') {
      toast.error('Todos os dados são obrigatórios.', {
        autoClose: 6000,
      });
    } else if (!CpfCnpjValidator(cnpjInputValue)) {
      toast.error('CNPJ inválido.', {
        autoClose: 6000,
      });
    } else {
      dispatch(
        recoverEmailRequest(completeNameInputValue, onlyNumbers(cnpjInputValue))
      );
    }
  }

  const handleRecoverPassword = ({ email }) => {
    if (email === '') {
      toast.error('O e-mail é obrigatório.', {
        autoClose: 6000,
      });
    } else {
      dispatch(recoverPasswordRequest(email));
      setRecoverPasswordModal(false);
      setSentInstructionsPasswordModal(true);
    }
  }

  useEffect(() => {
    if (recoverEmailSuccess) {
      setRecoverEmailModal(false);
      setRecoveredEmailModal(true);
      setNotRecoveredEmailModal(false);
      dispatch(clearFields());
    }
  }, [recoverEmailSuccess, dispatch]);

  useEffect(() => {
    if (recoverEmailError) {
      setRecoverEmailModal(false);
      setNotRecoveredEmailModal(true);
      dispatch(clearFields());
    }
  }, [recoverEmailError, dispatch]);

  const handler = {
    handleSubmit: handleSubmit,
    handleRecoverEmail: handleRecoverEmail,
    handleRecoverPassword: handleRecoverPassword,
    handleForgotPassword: handleForgotPassword,
    handleForgotEmail: handleForgotEmail,
    handleForgotPasswordEmail: handleForgotPasswordEmail,
    forgotEmailPasswordModal: forgotEmailPasswordModal,
    recoverEmailModal: recoverEmailModal,
    recoverPasswordModal: recoverPasswordModal,
    recoverEmailLoading: recoverEmailLoading,
    notRecoveredEmailModal: notRecoveredEmailModal,
    recoveredEmailModal: recoveredEmailModal,
    sentInstructionsPasswordModal: sentInstructionsPasswordModal,
    recoveredEmail: recoveredEmail,
    loading: loading,
    passwordVisible: passwordVisible,
    currLoginStep: currLoginStep,
    cnpjInputValue: cnpjInputValue,
    completeNameInputValue: completeNameInputValue,
    setRecoverPasswordModal: (val) => setRecoverPasswordModal(val),
    setRecoverEmailModal: (val) => setRecoverEmailModal(val),
    setForgotEmailPasswordModal: (val) => setForgotEmailPasswordModal(val),
    setRecoveredEmailModal: (val) => setRecoveredEmailModal(val),
    setNotRecoveredEmailModal: (val) => setNotRecoveredEmailModal(val),
    setCurrLoginStep: (val) => setCurrLoginStep(val),
    setPasswordVisible: (val) => setPasswordVisible(val),
    setCnpjInputValue: (t) => setCnpjInputValue(cpfCnpjMask(t.target.value)),
    setCompleteNameInputValue: (t) => setCompleteNameInputValue(t.target.value),
    setSentInstructionsPasswordModal: (val) => setSentInstructionsPasswordModal(val)
  }

  if (isMobile) {
    return <LoginMobile handler={handler} />
  } else {
    return <LoginWeb handler={handler} />
  }
}

