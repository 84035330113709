import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import px2vw from '../../utils/responsiveness/px2vw';
import { cinzaKuppi6, cinzaKuppi1 } from '../../styles/colors';

export const TermsTitle = styled.h2`
  font-size: ${px2vw(28)};
  font-family: AvenirNextLTPro;
font-weight: 700;
  margin-bottom: ${px2vw(22)};
`;

export const TermsParagraph = styled.p`
  font-size: ${px2vw(16)};
  font-weight: 400;
  color: ${cinzaKuppi6};
  line-height: 1.6;
  margin-bottom: ${px2vw(10)};
  margin-left: ${px2vw(20)};
  margin-right: ${px2vw(20)};
`;

export const TermsContent = styled(ReactMarkdown)`
  font-size: ${px2vw(14)};
  font-weight: 400;
  color: ${cinzaKuppi6};
  line-height: 1.6;
  margin-bottom: ${px2vw(10)};
  margin-left: ${px2vw(10)};
  margin-right: ${px2vw(10)};
`;

export const TermsAndPoliciesContentBox = styled.div`
  overflow-wrap: break-word;
  max-height: ${px2vw(270)};
  height: 100%;
  overflow-y: auto;

  background: ${cinzaKuppi1};
  padding: 20px;
  margin: ${px2vw(20)} ${px2vw(20)};
`;

export const TermsAndPoliciesHeader = styled.header`
  display: flex;
  flex-direction: column;
  padding: 0 ${px2vw(30)};
  width: 100%;
`;

export const TermsAndPoliciesFooter = styled.footer`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: ${px2vw(30)};
`;

export const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 20px 0;
`;
