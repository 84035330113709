import styled from 'styled-components';

import px2vw from '../../utils/responsiveness/px2vw';

export const StatusBox = styled.div`
  padding-top: ${px2vw(12)};
  border: none;
  height: ${px2vw(52)};
  width: ${px2vw(250)};
  border-radius: ${px2vw(10)};

  ${(props) => `
    background-color: ${props.backgroundColor}60;
    margin-left: ${props.margleft};
    width: ${props.width};
    height: ${props.height};
    padding-top: ${props.padtop};
    margin-top: ${props.margtop};
  `};
`;
