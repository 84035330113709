import React from "react";
import { Link } from "react-router-dom";

import {
  Card,
  NormalText,
  InputDataTextArea
} from '../../../BaseScreen/styles';
import { Row, Col } from '../../../../../styles/grid';
import { branco, cinzaKuppi1, preto } from '../../../../../styles/colors';
import { StoreForm } from "../../../Step2-DeliveryParams/Mobile/styles";

import px2vw from '../../../../../utils/responsiveness/px2vw';

import ButtonPrimary from '../../../../../components/ButtonPrimary';

export default function WhatsappConfigs({ handler }) {
  return (
    <StoreForm>
      <Card
        padleft={px2vw(30)}
        padright={px2vw(30)}
        width={1360}
        height='auto'
        borderRadius={px2vw(60)}
        margtop={px2vw(60)}
      >
        <Col alignCenter margtop={px2vw(50)} >
          <Row justifyStart width={px2vw(1000)}>
            <NormalText
              margtop={px2vw(90)}
              fontweight='bold'
              color={preto}
              fontsize={px2vw(55)}
              textalign='flex-start'
            >
              Otimize o seu delivery no Whatsapp
            </NormalText>
          </Row>
          <Row justifyStart width={px2vw(1000)}>
            <NormalText
              margtop={px2vw(90)}
              margbottom={px2vw(90)}
              fontweight='normal'
              color={preto}
              fontsize={px2vw(45)}
              textalign='flex-start'
            >
              Utilize seu whatsapp para criar um ambiente simples para fazer seu pedido e receber notificações do andamento do pedido.
            </NormalText>
          </Row>
          <InputDataTextArea
            value={handler.text}
            id="mainText"
            backgroundcolor={cinzaKuppi1}
            padtop={px2vw(30)}
            padleft={px2vw(30)}
            width={px2vw(1000)}
            height={px2vw(850)}
            border={`1px solid ${cinzaKuppi1}`}
            fontsize={px2vw(40)}
          />
          <Row margtop={px2vw(55)} margbottom={px2vw(55)}>
            <ButtonPrimary
              color={preto}
              background={branco}
              borderColor={preto}
              minWidth={1000}
              height={100}
              fontSize={50}
              borderRadius={25}
              type='button'
              onClick={() => handler.handleCopy("mainText")}
            >
              Copiar mensagem
            </ButtonPrimary>
          </Row>
          <Row margtop={px2vw(55)} margbottom={px2vw(55)}>
            <ButtonPrimary
              color={preto}
              background={branco}
              borderColor={preto}
              minWidth={1000}
              height={100}
              fontSize={50}
              borderRadius={25}
              type='button'
            >
              <Link
                to={{ pathname: handler.linkToGuide }}
                target="_blank"
                style={{
                  textDecoration: "none",
                  color: preto
                }}
              >
                {'Guia de como fazer a alteração'}
              </Link>
            </ButtonPrimary>
          </Row>
        </Col>
      </Card>
      <Row margtop={px2vw(55)} margbottom={px2vw(55)} margright={px2vw(320)} justifyEnd>
        <ButtonPrimary
          minWidth={500}
          height={150}
          fontSize={50}
          borderRadius={25}
          type='button'
          onClick={handler.handleFinishStep}
        >
          Concluir
        </ButtonPrimary>
      </Row>
    </StoreForm>
  )
}
