export const weekdays = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
];

export default function getCurrentDay() {
  const now = new Date();
  const currentDay = weekdays[now.getDay()];

  return currentDay;
}
