import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { BannerContainer, BannerText, BannerTitle, Image } from './styles';
import { Col } from '../../styles/grid';
import { Tab, TabBox, TabsBox, TabSelected } from '../MyStore/styles';
import { ContentPage, PageTitle } from '../_layouts/styles/common';

import px2vw from '../../utils/responsiveness/px2vw';

import StatsPage from './StatsPage';
import ConfigsPage from './ConfigsPage';

export default function SalesRecover() {
  const salesRecoverConfigIsActive =  useSelector((state) => state.salesRecover.salesRecoverData?.is_active);
  const [currTabSelected, setCurrTabSelected] = useState('stats');

  useEffect(() => {
    if (!salesRecoverConfigIsActive) {
      setCurrTabSelected('config');
    } else {
      setCurrTabSelected('stats');
    }
  }, [salesRecoverConfigIsActive])

  return (
    <ContentPage>
      <PageTitle>Recuperador de Vendas</PageTitle>

      <BannerContainer>
        <Col margleft={px2vw(20)} width="70%">
          <BannerTitle>Recupere vendas no WhatsApp de forma automática</BannerTitle>
          <BannerText>Envie mensagens automáticas pelo WhatsApp para converter potenciais clientes que entram no cardápio mas não finalizam o pedido</BannerText>
          <BannerText>As informações das vendas recuperadas ficarão disponíveis na aba Estatísticas</BannerText>
          <BannerText fontSize={px2vw(12)}><b>OBS:</b> O WhatsApp precisa estar conectado à plataforma para que a mensagem seja enviada</BannerText>
        </Col>
        <Col alignEnd width="30%">
          <Image src={require("../../assets/images/sales_recover/header_bg.svg")} />
        </Col>
      </BannerContainer>

      <TabsBox>
        <TabBox margleft={px2vw(20)}>
          <Tab onClick={() => setCurrTabSelected('stats')}>
            Estatísticas
          </Tab>
          {currTabSelected === 'stats' && <TabSelected isVisible width="auto" />}
        </TabBox>
        <TabBox>
          <Tab onClick={() => setCurrTabSelected('config')}>
            Configurações
          </Tab>          
          {currTabSelected === 'config' && <TabSelected isVisible width="auto" />}
        </TabBox>
      </TabsBox>
      {currTabSelected === 'config' && <ConfigsPage />}
      {currTabSelected === 'stats' && <StatsPage />}
    </ContentPage>
  )
}