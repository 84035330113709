import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ChangeImagesModal from '../../components/ChangeImagesModal';
import AcceptingOrdersButtons from '../../components/AcceptingOrdersButtons';
import { AcceptingOrdersBox } from '../../components/AcceptingOrdersButtons/styles';

import { Row } from '../../styles/grid';

import px2vw from '../../utils/responsiveness/px2vw';
import RealTimeDimensions from '../../utils/responsiveness/RealTimeDimensions';

import { branco } from '../../styles/colors';

import { PageTitle, ContentPage } from '../_layouts/styles/common';

import {
  ChangeImageButton,
  ImgChangeImageButton,
  ChangeImageBox,
  ChangeImageText,
  TabsBox,
  TabBox,
  Tab,
  TabSelected,
} from './styles';

import { steps } from '../Onboarding';

import { updateOnboardingStepRequest } from '../../store/modules/onboarding/actions';
import { clearSuccessDeliveryParamsOnboarding } from '../../store/modules/myStore/actions';

import DeliveryParams from '../Onboarding/Step2-DeliveryParams/Mobile';
import StoreAvailabilities from './StoreAvailabilities';
import StorePaymentMethods from './StorePaymentMethods';
import StoreDeliveryTakeAwayParams from './StoreDeliveryTakeAwayParams';
import StoreScheduling from './StoreScheduling';
import { toast } from 'react-toastify';

export default function MyStore({ isOnboarding = false, handler }) {
  const [width] = RealTimeDimensions();
  const isWeb = width >= 800 ? true : false;
  const isMobileOnboarding = !isWeb && isOnboarding;
  const advertiserInfos = useSelector(
    (state) => state.user.user.advertiserInfo
  );
  const storeAvailabilities = useSelector(
    (state) => state.user.user.advertiserInfo.advertiserAvailability
  );
  const storePaymentMethods = useSelector(
    (state) => state.user.user.advertiserInfo.advertiserPaymentMethod
  );
  const [onboardingMyStoreSteps, setOnboardingMyStoreSteps] = useState({
    availabilitiesStep: validateActiveHorary(),
    paymentMethodsStep: validateActivePaymentMethod(),
    deliveryTakeAwayStep: advertiserInfos.is_using_delivery || advertiserInfos.is_using_take_away,
  });
  const [changeImagesModal, setChangeImagesModal] = useState(false);
  const [currTabSelected, setCurrTabSelected] = useState(
    'tabStoreAvailabilities'
  );
  const acceptingScheduledOrders = useSelector(
    (state) =>
      state.user.user.advertiserInfo.advertiserOrdersConfig
        .is_accepting_scheduled_order
  );
  const availabilitySuccess = useSelector(
    (state) => state.myStore.availabilitySuccess
  );
  const updatePaymentMethodSuccess = useSelector(
    (state) => state.myStore.updatePaymentMethodSuccess
  );
  const deliveryTakeAwaySuccess = useSelector(
    (state) => state.myStore.deliveryTakeAwaySuccess
  );
  const dispatch = useDispatch();

  // Validate if has any active horary
  function validateActiveHorary() {
    const weekDays = [
      'sunday',
      'monday',
      'tuesday',
      'wednesday',
      'thursday',
      'friday',
      'saturday'
    ];
    let hasActiveHorary = false;

    weekDays.forEach((weekDay) => {
      if (!hasActiveHorary) {
        hasActiveHorary = storeAvailabilities[weekDay].is_active
      }
    });
    return hasActiveHorary
  }

  // Validate if has any active payment method
  function validateActivePaymentMethod() {
    const activePaymentMethods = storePaymentMethods.filter((paymentMethod) => {
      return paymentMethod.is_active === true
    })
    return activePaymentMethods.length > 0;
  }
  
  // Change tabs in the onboarding
  useEffect(() => {
    if (isOnboarding && availabilitySuccess) {
      setOnboardingMyStoreSteps({
        ...onboardingMyStoreSteps,
        availabilitiesStep: true
      });
      setCurrTabSelected('tabStorePaymentMethods');
    } else if (isOnboarding && updatePaymentMethodSuccess) {
      setOnboardingMyStoreSteps({
        ...onboardingMyStoreSteps,
        paymentMethodsStep: true
      });
      setCurrTabSelected('tabStoreDeliveryTakeAwayParams');
    } else if (isOnboarding && deliveryTakeAwaySuccess) {
      setOnboardingMyStoreSteps({
        ...onboardingMyStoreSteps,
        deliveryTakeAwayStep: true
      });
      dispatch(clearSuccessDeliveryParamsOnboarding());
      if (steps.indexOf(handler.currOnboardingStep) < steps.indexOf('step3')) {
        dispatch(updateOnboardingStepRequest('step3'));
      } else {
        handler.setCurrOnboardingStepPage(handler.currOnboardingStep);
      }
    }
  }, [
    availabilitySuccess,
    updatePaymentMethodSuccess,
    deliveryTakeAwaySuccess,
  ]);

  // Force the first tab active, when the user deactivate the scheduling
  // order receiving and is at the scheduling tab
  useEffect(() => {
    if (!acceptingScheduledOrders && currTabSelected === 'tabStoreScheduling') {
      setCurrTabSelected('tabStoreAvailabilities');
    }
  }, [acceptingScheduledOrders]);

  function handleCurrTabSelected(selectedTab) {
    if (isOnboarding) {
      if (selectedTab === 'tabStorePaymentMethods' && !onboardingMyStoreSteps.availabilitiesStep) {
        toast.error('Você deve finalizar o passo atual antes de avançar', {
          autoClose: 6000,
        });
        return;
      } else if (selectedTab === 'tabStoreDeliveryTakeAwayParams' && (!onboardingMyStoreSteps.availabilitiesStep || !onboardingMyStoreSteps.paymentMethodsStep)) {
        toast.error('Você deve finalizar o passo atual antes de avançar', {
          autoClose: 6000,
        });
        return;
      }
    }
    setCurrTabSelected(selectedTab);
  }

  const handlerMobile = {
    isMobileOnboarding: isMobileOnboarding,
    currTabSelected: currTabSelected,
    handleCurrTabSelected: (selectedTab) => handleCurrTabSelected(selectedTab),
  }

  // MyStore openned through onboarding at mobile 
  if (isMobileOnboarding) {
    return <DeliveryParams handler={handlerMobile} />;
  } 
  // MyStore openned through onboarding or platform at web
  else {
    return (
      <>
        <ContentPage>
          <Row height="auto" spaceBetween margbottom={px2vw(20)}>
            {!isOnboarding ? (
              <PageTitle>Configurações da Loja</PageTitle>
            ) : (
              <PageTitle>Parâmetros do delivery</PageTitle>
            )}
            {!isOnboarding && (
              <Row width="auto" margtop={px2vw(-20)}>
                <AcceptingOrdersBox>
                  <Row margbottom={px2vw(5)}>
                    <AcceptingOrdersButtons />
                    <Row width="auto" margtop={px2vw(43)} margright={px2vw(5)}>
                      <ChangeImageButton
                        id="myStoreChangeImagesButton"
                        onClick={() => setChangeImagesModal(true)}
                      >
                        <ChangeImageBox>
                          <ImgChangeImageButton
                            src={require('../../assets/images/image_icon/image_icon.png')}
                          />
                          <ChangeImageText>Alterar imagens</ChangeImageText>
                        </ChangeImageBox>
                      </ChangeImageButton>
                    </Row>
                  </Row>
                </AcceptingOrdersBox>
              </Row>
            )}
          </Row>
          <TabsBox>
            <TabBox>
              <Tab onClick={() => handleCurrTabSelected('tabStoreAvailabilities')}>
                Horários
              </Tab>
              <TabSelected
                isVisible={currTabSelected === 'tabStoreAvailabilities'}
                width={px2vw(86)}
              />
            </TabBox>
            <TabBox>
              <Tab onClick={() => handleCurrTabSelected('tabStorePaymentMethods')}>
                Pagamentos
              </Tab>
              <TabSelected
                isVisible={currTabSelected === 'tabStorePaymentMethods'}
                width={px2vw(124)}
              />
            </TabBox>
            <TabBox>
              <Tab
                onClick={() =>
                  handleCurrTabSelected('tabStoreDeliveryTakeAwayParams')
                }
              >
                Delivery / Retirada
              </Tab>
              <TabSelected
                isVisible={currTabSelected === 'tabStoreDeliveryTakeAwayParams'}
                width={px2vw(189)}
              />
            </TabBox>
            {acceptingScheduledOrders && (
              <TabBox>
                <Tab onClick={() => setCurrTabSelected('tabStoreScheduling')}>
                  Agendamento
                </Tab>
                <TabSelected
                  isVisible={currTabSelected === 'tabStoreScheduling'}
                  width={px2vw(142)}
                />
              </TabBox>
            )}
          </TabsBox>
          {currTabSelected === 'tabStoreAvailabilities' && (
            <StoreAvailabilities isOnboarding={isOnboarding} backgroundColor={branco} />
          )}
          {currTabSelected === 'tabStorePaymentMethods' && (
            <StorePaymentMethods backgroundColor={branco} />
          )}
          {currTabSelected === 'tabStoreDeliveryTakeAwayParams' && (
            <StoreDeliveryTakeAwayParams isOnboarding={isOnboarding} backgroundColor={branco} />
          )}
          {currTabSelected === 'tabStoreScheduling' && (
            <StoreScheduling backgroundColor={branco} />
          )}
        </ContentPage>
        {/* Change images modal */}
        <ChangeImagesModal
          isVisible={changeImagesModal}
          onClose={() => setChangeImagesModal(false)}
        />
      </>
    );
  }
}
