import React from 'react';

import { Content } from '../../_layouts/styles/common';

import MainWeb from './Main';
import RecoveryTypeWeb from './RecoveryType';
import RecoveryEmailWeb from './RecoveryEmail';
import RecoveryPasswordWeb from './RecoveryPassword';

export default function LoginWeb({ handler }) {
  return (
    <Content>
      <MainWeb handler={handler} />
      {/* Recover Email or Password Modal */}
      <RecoveryTypeWeb handler={handler} />
      {/* Recover Email Modal */}
      <RecoveryEmailWeb handler={handler} />
      {/* Recover Password Modal */}
      <RecoveryPasswordWeb handler={handler} />
    </Content>
  )
}
