import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ChangeImagesModal from '../../components/ChangeImagesModal';

import px2vw from '../../utils/responsiveness/px2vw';

import { Row, Col } from '../../styles/grid';

import Profile from './Profile';
import Signature from './Signature';

import {
  PageTitle,
  ContentPage,
  SubtitleBox,
  SubtitleButton,
  SubtitleImage,
  LogoImg,
} from '../_layouts/styles/common';

import { TabsBox, TabBox, Tab, TabSelected } from './styles';

import { setCurrentTab } from '../../store/modules/profileAccount/actions';

export default function ProfileSignature() {
  const currentTab = useSelector((state) => state.profileAccount.currentTab);
  const user = useSelector((state) => state.user.user);
  const [changeImagesModal, setChangeImagesModal] = useState(false);
  const [tabProfile, setTabProfile] = useState(true);
  const [tabSignature, setTabSignature] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setTabProfile(currentTab === 'tabProfile');
    setTabSignature(currentTab === 'tabSignature');
  }, [currentTab]);

  function handleTabSelected(selectedTab) {
    dispatch(setCurrentTab(selectedTab));
  }

  return (
    <>
      <ContentPage >
        <Col>
          <Row height={px2vw(70)}>
            <Col
              width={px2vw(70)}
              height={px2vw(70)}
              background="white"
              borderRadius={px2vw(35)}
            >
              <LogoImg
                src={
                  user.avatar
                    ? `${user.avatar.url}?nocache=${new Date().getTime()}`
                    : require('../../assets/images/advertiser_logo/advertiser_logo.png')
                }
              />
            </Col>
            <Col margleft={px2vw(20)} margtop={px2vw(2)} height={px2vw(70)}>
              <PageTitle>Perfil e Assinatura</PageTitle>
              <SubtitleBox>
                <SubtitleButton onClick={() => setChangeImagesModal(true)}>
                  Alterar Imagens
                  <SubtitleImage
                    src={require('../../assets/images/arrows/arrow_right.png')}
                  />
                </SubtitleButton>
              </SubtitleBox>
            </Col>
          </Row>
          <TabsBox>
            <TabBox margrightTab>
              <Tab onClick={() => handleTabSelected('tabProfile')}>Perfil</Tab>
              <TabSelected isVisible={tabProfile} width="100%" />
            </TabBox>
            <TabBox>
              <Tab onClick={() => handleTabSelected('tabSignature')}>
                Assinatura
              </Tab>
              <TabSelected isVisible={tabSignature} width="100%" />
            </TabBox>
          </TabsBox>
          {tabProfile && <Profile />}
          {tabSignature && <Signature />}
        </Col>
      </ContentPage>
      {/* Change images modal */}
      {changeImagesModal && (
        <ChangeImagesModal
          isVisible={changeImagesModal}
          onClose={() => setChangeImagesModal(false)}
        />
      )}
    </>
  );
}
