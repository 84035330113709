import styled from 'styled-components';
import { Col } from '../../../styles/grid';
import backgroundImage from '../../../assets/images/whatsapp_chat_bg/wppbg.png';

import px2vw from '../../../utils/responsiveness/px2vw';
import { cinzaKuppi1 } from '../../../styles/colors';

export const Window = styled(Col)`
  width: 70%;
  height: 100%;
  background-image: url(${backgroundImage});
  background-repeat: repeat-x;
`;

export const BgBanner = styled(Col)`
  width: 70%;
  height: 100%;
  background: ${cinzaKuppi1};
  padding-bottom: ${px2vw(100)};
`;

export const BgImg = styled.img`
  width: ${px2vw(300)};
`;

export const Span = styled.span`
  font-size: ${px2vw(11)};
  font-weight: 600;
  text-align: center;
  ${(props) => props.size && `font-size: ${props.size};`}
  ${(props) => props.textAlign && `text-align: ${props.textAlign};`}
  ${(props) => props.color && `color: ${props.color};`}
  ${(props) => props.letterSpacing && `letter-spacing: ${props.letterSpacing};`}
  ${(props) => props.lineHeight && `line-height: ${props.lineHeight};`}
  ${(props) => props.fontWeight && `font-weight: ${props.fontWeight};`}
  ${(props) => props.p && `padding: ${props.p};`}
  ${(props) => props.pb && `padding-bottom: ${props.pb};`}
  ${(props) => props.pl && `padding-left: ${props.pl};`}
  ${(props) => props.pr && `padding-right: ${props.pr};`}
  ${(props) => props.pt && `padding-top: ${props.pt};`}
  ${(props) => props.m && `margin: ${props.m};`}
  ${(props) => props.mb && `margin-bottom: ${props.mb};`}
  ${(props) => props.ml && `margin-left: ${props.ml};`}
  ${(props) => props.mr && `margin-right: ${props.mr};`}
  ${(props) => props.mt && `margin-top: ${props.mt};`}
  ${(props) => props.maxWidth && `max-width: ${props.maxWidth};`}
  ${(props) => props.overflow && `overflow: ${props.overflow};`}
  ${(props) => props.textOverflow && `text-overflow: ${props.textOverflow};`}
  ${(props) => props.whiteSpace && `white-space: ${props.whiteSpace};`}
`;
