import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Row } from '../../styles/grid';
import { cinzaKuppi1 } from '../../styles/colors';

import px2vw from '../../utils/responsiveness/px2vw';
import { ButtonCheckEmpty, ImageContainer } from './styles';
import check from '../../assets/images/checkbox/check@2x.png';
import arrowRight from '../../assets/images/arrows/arrow_right_2@2x.png';
import OrdersMassActionsModal from '../OrdersMassActionsModal';
import {
  setCurrOrderConfigs,
  updateIdsList,
} from '../../store/modules/orders/actions';

export default function OrdersMassActionsMenu(props) {
  const { ordersListByCurrentTab } = props;

  const idsListToMassAction = useSelector(
    (state) => state.orders.idsListToMassAction
  );
  const listToMassActionSuccess = useSelector(
    (state) => state.orders.listToMassActionSuccess
  );
  const [ordersMassActionsModalVisible, setOrdersMassActionsModalVisible] =
    useState(false);
  const alwaysPrintOrder = useSelector(
    (state) =>
      state.user.user.advertiserInfo.advertiserOrdersConfig
        .is_print_order_invoice_accept_order
  );
  const alwaysSendWhatsappMsg = useSelector(
    (state) =>
      state.user.user.advertiserInfo.advertiserOrdersConfig
        .is_send_whatsapp_msg_update_status
  );

  const dispatch = useDispatch();

  useEffect(() => {dispatch(updateIdsList([]))}, [])

  useEffect(() => {
    if (listToMassActionSuccess) {
      setOrdersMassActionsModalVisible(false);
      dispatch(updateIdsList([]));
    }
  }, [listToMassActionSuccess]);

  return (
    <>
      <Row justifyStart width="auto" height="auto">
        <Row
          alignCenter
          background={cinzaKuppi1}
          height={px2vw(30)}
          margbot={px2vw(5)}
          width={px2vw(80)}
          cursor="pointer"
          onClick={() => {
            if (!idsListToMassAction.length) {              
              dispatch(updateIdsList(ordersListByCurrentTab.map((order) => order.order_id)));
            } else {
              dispatch(updateIdsList([]));
            }
          }}
        >
          <ButtonCheckEmpty>
            <ImageContainer
              checkVisible={
                ordersListByCurrentTab
                  .map((order) => order.order_id)
                  .every((id) => idsListToMassAction.includes(id)) &&
                !!idsListToMassAction.length
              }
            >
              <img width="100%" height="100%" src={check} alt="Check icon" />
            </ImageContainer>
          </ButtonCheckEmpty>
          Todos
        </Row>

        {idsListToMassAction.length > 1 && (
          <Row
            alignCenter
            background={cinzaKuppi1}
            height={px2vw(30)}
            margbot={px2vw(5)}
            width={px2vw(125)}
            margleft={px2vw(5)}
            cursor="pointer"
            onClick={() => {
              dispatch(setCurrOrderConfigs(alwaysPrintOrder, alwaysSendWhatsappMsg));
              setOrdersMassActionsModalVisible(true)
            }}
          >
            <ImageContainer showArrow>
              <img width="100%" height="100%" src={arrowRight} alt="Check icon" />
            </ImageContainer>
            Ação em Massa
          </Row>
        )}
      </Row>
      {ordersMassActionsModalVisible && (
        <OrdersMassActionsModal
          ordersMassActionsModalVisible={ordersMassActionsModalVisible}
          setOrdersMassActionsModalVisible={() =>
            setOrdersMassActionsModalVisible()
          }
          listWithAllIds={idsListToMassAction}
          ordersListByCurrentTab={ordersListByCurrentTab}
        />
      )}
    </>
  );
}
