export const QuestionFourTitle = '4 - Você trabalha apenas com delivery ou também possui loja física?';
export const QuestionFourAnswer1 = 'Trabalho apenas com delivery';
export const QuestionFourAnswer2 = 'Trabalho com delivery e loja física';
export const QuestionFourAnswer3 = 'Não tenho delivery, apenas loja física';
export const QuestionFiveTitle = '5 - Quantos pedidos você recebe diariamente, em média?';
export const QuestionFiveAnswer1 = 'Ainda não recebo pedidos, estou começando';
export const QuestionFiveAnswer2 = '1 a 5 pedidos por dia';
export const QuestionFiveAnswer3 = '6 a 10 pedidos por dia';
export const QuestionFiveAnswer4 = '11 a 30 pedidos por dia';
export const QuestionFiveAnswer5 = 'Mais de 30 pedidos por dia';
export const QuestionSixTitle = '6 - Represente em % a quantidade de pedidos que cada canal trás para o seu negócio';
export const QuestionSixAnswer1 = 'Canais próprios';
export const QuestionSixAnswer1SubText = '(Whatsapp, Instagram, ...)';
export const QuestionSixAnswer2 = 'Marketplaces';
export const QuestionSixAnswer2SubText = '(Ifood, 99Food, AiQfome e etc...)';
export const QuestionSixPercent = '%';

