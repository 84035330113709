import styled from 'styled-components';

import px2vw from '../../utils/responsiveness/px2vw';

import { Form, Input } from '@rocketseat/unform';

import {
  cinzaKuppi3,
  cinzaKuppi4,
} from '../../styles/colors';

export const EditPasswordModal = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
padding-left: ${px2vw(20)};
`;

export const FormBox = styled(Form)`
 
`;

export const InputLabel = styled.div`
  font-size: ${(props) => props.fontsize || px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.48px;
  margin-top: ${px2vw(2)};
  display: inline-block;

  ${(props) => ` 
    color: ${props.color};
    margin-top: ${props.margtop};
    padding-left: ${props.padleft};
    padding-right: ${props.padright};
    font-size: ${props.fontsize};
  `};
`;

export const LoginBox = styled.div`
  display: flex;
  flex-direction: row;
  width: ${(props) => props.width || `80%`};
`;

export const LoginInput = styled(Input)`
  padding: 10px 10px !important;
  margin-right: ${px2vw(5)};
  margin-top: ${px2vw(6)};
  border: 1px solid ${cinzaKuppi4} !important;
  border-radius: 5px !important;
  font-size: ${(props) => props.fontsize || px2vw(16)} !important;
  font-family: font-family: AvenirNextLTPro !important;
  font-weight: normal;
  letter-spacing: 0.36px !important;
  width: ${(props) => props.width || px2vw(200)};
  height: ${(props) => props.height || px2vw(29)};

  ::placeholder {
    color: ${cinzaKuppi3} !important;
  }

  :focus {
    border: 1px solid ${cinzaKuppi3} !important;
    box-shadow: none !important;
  }

  ${(props) => `
    width: ${props.width} !important;
  `};

  ${(props) => props.isMobile && `
    width: ${px2vw(1000)};
    height: ${px2vw(100)};
    fontsize: ${px2vw(45)};
  `};
`;

export const SeePasswordButton = styled.button`
  margin-left: ${px2vw(-40)};
  margin-top: ${px2vw(10)};
  width: ${px2vw(20)};
  height: ${px2vw(20)};
  border: none;
  z-index: 10;
  background-color: transparent;

  :focus {
    background-color: transparent !important;
  }
`;

export const SeePasswordImage = styled.img`
  width: ${(props) => props.width || '100%'};
  height: ${(props) => props.height || '100%'};
  margin-left: ${(props) => props.margleft};

  ${(props) => props.isMobile && `
    width: ${px2vw(80)};
    height: ${px2vw(80)};
  `};
`;