import React from 'react';

import { Row, Col } from '../../../../styles/grid';
import { SectionTitle } from '../../styles';

import px2vw from '../../../../utils/responsiveness/px2vw';

import HeaderDefaultSignatureMobile from '../Components/HeaderDefaultSignature/Mobile'
import HeaderCanceledSignatureMobile from '../Components/HeaderCanceledSignature/Mobile';
import HeaderBlockedSignatureMobile from '../Components/HeaderBlockedSignature/Mobile';
import PlanOptionsMobile from '../Components/PlanOptions/Mobile';
import PlanDetailMobile from '../Components/PlanDetail/Mobile';
import PaymentDetailMobile from '../Components/PaymentDetail/Mobile';
import { SectionTitleText } from '../Components/PlanOptions/constants';

export default function DefaultContentMobile({ handler }) {
  return (
    <>
      {/* Page header when are canceled */}
      {handler.areCanceled && <HeaderCanceledSignatureMobile handler={handler} />}
      {/* Page header when are blocked */}
      {handler.areBlocked && !handler.areCanceled && <HeaderBlockedSignatureMobile handler={handler} />}
      {/* Page header when are not canceled and blocked */}
      {!handler.areCanceled && !handler.areBlocked && <HeaderDefaultSignatureMobile handler={handler} />}
      {/* Plan options */}
      {handler.newPlanOptions.length > 0 && (
        <Col margtop={px2vw(30)}>
          {handler.newPlanOptions.length > 1 && (
            <SectionTitle fontsize={px2vw(80)}>
              {SectionTitleText}
            </SectionTitle>
          )}
          <Row margbottom={px2vw(!handler.recurringPaymentData.is_using_discount_matrix ? 25 : 40)}>
            <PlanOptionsMobile handler={handler} />
          </Row>
        </Col>
      )}
      {/* Plan details */}
      <PlanDetailMobile handler={handler} />
      {/* Payment detail */}
      <PaymentDetailMobile handler={handler} />
    </>
  );
}
