import styled, { css, keyframes } from 'styled-components';
import {
  branco,
  cinzaKuppi1,
  cinzaKuppi2,
  cinzaKuppi4,
  preto,
  verdeKuppi1,
  verdeKuppi2,
  verdeKuppi4,
  cinzaKuppi3,
  verdeKuppi20,
  verdeKuppi8,
  cinzaKuppi12,
} from '../../styles/colors';

import px2vw from '../../utils/responsiveness/px2vw';

const colorFadeIn = keyframes`
  0% {
  background-color: ${cinzaKuppi4};
  transform: scale(1.01);
}
  100% {
    background-color: ${verdeKuppi2};
    transform: initial;
  }
`;

const fadeIn = keyframes`
to {
opacity: 1;
}

`;

export const Header = styled.div`
  background: ${verdeKuppi8};
  height: ${px2vw(50)};
  width: calc(100% - ${px2vw(230)});
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: ${px2vw(230)};
  border-radius: ${px2vw(50)} 0px 0px 0px;

  ${(props) =>
    props.initial &&
    `
    background: ${verdeKuppi8};
    width: 100%;
    height: ${px2vw(50)};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-radius: 0px 0px 0px 0px;
  `};
`;

export const NormalText = styled.div`
  font-family: AvenirNextLTPro;

  ${(props) => `
    color: ${props.color};
    width: ${props.width};
    height: ${props.height};
    font-size: ${props.fontsize};
    text-align: ${props.textalign || 'center'};
    margin-left: ${props.margleft};
    margin-right: ${props.margright};
    margin-top: ${props.margtop};
    margin-bottom: ${props.margbottom};
    padding-left: ${props.padleft};
    padding-right: ${props.padright};
    padding-bottom: ${props.padbottom};
    padding-top: ${props.padtop};
    padding: ${props.pad};
    font-weight: ${props.fontweight || 'normal'};
    text-decoration: ${props.textdecoration};
    cursor: ${props.cursor};
  `};

  ${(props) =>
    props.minHeight &&
    `
    min-height: ${props.minHeight};
  `};

  ${(props) =>
    props.disabled &&
    `
      color: ${preto}60;
  `};

  ${(props) =>
    props.complDescription &&
    `
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 28ch;
  `};

  ${(props) =>
    !props.complDescription &&
    `
    display: flex;
    flex-wrap: wrap;
  `};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100vh;
  font-family: AvenirNextLTPro;
  font-weight: normal;
  position: relative;
`;

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: ${(props) => props.size || px2vw(28)};
  font-weight: bold;
  font-family: AvenirNextLTPro;
  font-weight: 700;
`;

export const ContentBox = styled.div`
  display: flex;
  border-radius: ${(props) => (props.radius ? props.radius : px2vw(16))};
  background-color: ${branco};
  width: ${(props) => (props.width ? props.width : px2vw(355))};
  height: ${(props) => (props.height ? props.height : 'auto')};
  box-shadow: 0px 8px 9px -2px ${cinzaKuppi4};
  padding: ${(props) => (props.padding ? props.padding : '')};
  flex-direction: ${(props) => (props.column ? 'column' : 'row')};
`;

export const VideoBox = styled.div`
  border-radius: ${px2vw(16)};
  width: ${(props) => (props.width ? props.width : '100%')};
  height: ${(props) => (props.height ? props.height : '100%')};
  box-shadow: 0px 8px 9px -2px ${cinzaKuppi4};
  margin-left: ${px2vw(15)};
`;

export const KuppiBoostBg = styled.img`
  position: absolute;
  border-radius: 0 20px 20px 0;
  height: ${px2vw(300)};
  ${(props) =>
    props.isFinished &&
    ` top: ${px2vw(-19)};
    height: ${px2vw(175)};
  `}
  right: 0;
  pointer-events: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  over-flow: hidden;
`;

export const ButtonPrimary = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${branco};
  border: none;
  border-radius: ${(props) => props.radius || px2vw(10)};
  font-size: ${(props) => props.size || px2vw(13)};
  font-family: AvenirNextLTPro;
  font-weight: ${(props) => props.fontWeight || '600'};
  letter-spacing: 0.015em;
  padding: ${px2vw(8)};
  width: ${(props) => (props.width ? props.width : px2vw(320))};
  height: ${(props) => (props.height ? props.height : px2vw(44))};

  ${(props) =>
    props.complete
      ? css`
          animation: ${colorFadeIn} 0.7s forwards;
        `
      : `background-color: ${verdeKuppi2};`}

  margin-top: ${(props) => (props.margtop ? props.margtop : 0)};
  margin-right: ${(props) => (props.margright ? props.margright : 0)};

  &:active {
    box-shadow: 0px 1px 1.5px ${cinzaKuppi2};
    background: ${verdeKuppi1};
  }

  ${(props) =>
    props.disabled &&
    `
  background-color: ${cinzaKuppi4};
  color:${branco};

  &:active {
    box-shadow: 2px 2px 3px ${cinzaKuppi2};
    background: ${cinzaKuppi4};
  }

  `}
`;

export const NumberIndex = styled.div`
  border-radius: 50%;
  background-color: ${verdeKuppi4};
  text-align: center;
  margin-right:${px2vw(7)};
  height: ${px2vw(30)};
  min-width: ${px2vw(30)};
  font-size: ${px2vw(19)};
  font-weight: bolder;
  font-family: AvenirNextLTPro;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MenuBox = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${branco};
  margin-left: ${px2vw(5)};
  border-radius: ${px2vw(20)} 0 ${px2vw(20)} ${px2vw(20)};
  min-height: ${px2vw(300)};
  box-shadow: 0px 8px 9px -2px ${cinzaKuppi4};
  opacity: 0;
  animation: ${fadeIn} 0.4s forwards;
`;

export const TabsBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  .ml-auto {
    margin-left: auto;
  }
`;

export const TabBox = styled.div`
  display: block;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: ${(props) => (props.margL ? props.margL : px2vw(10))};
`;

export const Tab = styled.button`
  height: ${px2vw(35)};
  font-size: ${px2vw(15)};
  font-family: AvenirNextLTPro;
  letter-spacing: 0.48px;
  border: none;
  border-bottom: ${px2vw(5)} solid transparent;
  background-color: transparent;
  line-height: 1.5;
  font-weight: 700;

  ${(props) =>
    props.selected &&
    `
    border-bottom: ${px2vw(5)} solid ${verdeKuppi1};

  `};
`;

export const TabSelected = styled.div`
  display: none;
  height: ${px2vw(5)};
  width: ${px2vw(95)};
  background-color: ${verdeKuppi1};
  border-radius: ${px2vw(5)};
  margin-top: ${px2vw(-7)};
  margin-bottom: ${px2vw(20)};

  ${(props) =>
    props.isVisible &&
    `
    display: block;
  `};

  ${(props) => `
    width: ${props.width};
  `};
`;

export const BalanceBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${px2vw(40)};
  width: ${px2vw(260)};
  border-radius: ${px2vw(6)};
  margin-left: ${px2vw(11)};
  margin-right: ${px2vw(20)};
  background: ${cinzaKuppi1};
  font-weight: bold;
  font-family: AvenirNextLTPro;
  font-weight: 700;
  color: ${cinzaKuppi3};
  font-size: ${px2vw(13)};
  ${(props) =>
    props.balance &&
    `
  background:${verdeKuppi20};
  color:${preto};
  `}
`;

export const Box = styled.div`
  display: flex;
  height: ${props => props.height || px2vw(125)};
  width: ${props => props.width || px2vw(490)};
  font-weight: 700;
  background: ${branco};
  color: ${cinzaKuppi3};
  position: relative;
  border-radius: ${px2vw(10)};
  padding: ${props => props.pad || px2vw(6)};

  ${(props) => props.justifyContent && `
    justify-content: space-around;
  `}
  
  ${(props) =>
    props.border &&
    ` &::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: ${px2vw(10)};
    padding: ${px2vw(3)}; /* control the border thickness */
    background: linear-gradient(45deg,#f8474166,#e34ca652,#f2800561,#0012fa70);
    -webkit-mask:
      linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
            mask-composite: exclude;
    pointer-events: none;
  }`}
`;

export const WarningImg = styled.img`
  width: ${(props) => (props.width ? props.width : px2vw(90))};
  height: ${(props) => (props.height ? props.height : px2vw(90))};
  margin-top: ${px2vw(10)};
  pointer-events: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export const InstaImg = styled.img`
  width: ${(props) => (props.width ? props.width : px2vw(40))};
  height: ${(props) => (props.height ? props.height : px2vw(40))};
  position: absolute;
  left: ${px2vw(-10)};
  top: ${px2vw(-10)};
  pointer-events: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export const IconImg = styled.img`
  width: ${(props) => (props.width ? props.width : px2vw(27))};
  height: ${(props) => (props.height ? props.height : px2vw(27))};
  margin-right:${px2vw(11)};
  pointer-events: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export const BalanceIcon = styled.img`
  width: ${(props) => (props.width ? props.width : px2vw(33))};
  height: ${(props) => (props.height ? props.height : px2vw(33))};
  margin-right: ${px2vw(10)};
  pointer-events: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export const ImgBox = styled.div`
  height: ${(props) => props.height || px2vw(510)};
  width: ${(props) => props.width || '57%'};
  background: ${(props) => props.background || cinzaKuppi12};
  display: flex;
  align-items: center;
  justify-content: center;
  ${props => props.column && 'flex-direction: column;'}
  border-radius: ${px2vw(10)};
`;

export const StatusBox = styled.div`
height: ${(props) => props.height || px2vw(510)};
  padding:${px2vw(15)} ${px2vw(10)};
  width: 57%;
  background: ${branco};
  border: 2px solid ${cinzaKuppi4};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: ${px2vw(10)};
`;

export const ImgTrial = styled.img`
  width: 85%;
  pointer-events: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export const MenuBar = styled.div`
  height: ${px2vw(40)};
  width: ${px2vw(610)};
  margin: ${px2vw(10)} 0 ${px2vw(10)} 0;
  padding: ${px2vw(8)} ${px2vw(10)};
  background: ${branco};
  display: flex;
  align-items: center;
  opacity: 0;
  animation: ${fadeIn} 0.4s forwards;
`;

export const StatusImg = styled.img`
  height: ${px2vw(13)};
  width: ${px2vw(13)};
  margin: 0 ${px2vw(7)};
  pointer-events: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export const Ul = styled.ul`
  padding: ${px2vw(20)} 0 ${px2vw(15)} ${px2vw(22)};
  li {
    font-style: normal;
    font-weight: 500;
    font-size: ${px2vw(14)};
    letter-spacing: 0.02em;
    line-height: 200%;
    margin-left: ${px2vw(10)};
  }
`;

export const GradientButton = styled.button`
  background: linear-gradient(
      231.72deg,
      #2ffe5f 7.51%,
      rgba(47, 254, 95, 0) 91.54%
    ),
    #2cfe89;
  border: none;
  color: ${preto};
  border-radius: ${px2vw(5)};
  font-size: ${px2vw(13)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.32px;
  padding: ${px2vw(8)};
  margin: ${(props) => (props.marg ? props.marg : 'auto auto 0 auto')};
  width: ${(props) => props.width ? props.width : px2vw(200)};
  &:hover {
    text-decoration: none;
    box-shadow: 2px 2px 3px ${cinzaKuppi2};
  }

  &:active {
    text-decoration: none;
    background: linear-gradient(
        5.72deg,
        #2ffe5f 15.51%,
        rgba(47, 254, 95, 0) 80.54%
      ),
      #2cfe89;
    box-shadow: none;
  }

  &:disabled {
    text-decoration: none;
    background: ${cinzaKuppi2};
    box-shadow: none;
  }
`;

export const MenuBarBox = styled.div`
  height: ${px2vw(37)};
  ${(props) => props.width && `width: ${props.width};`}
  ${(props) =>
    props.separator && `border-right: ${px2vw(1.5)} solid ${cinzaKuppi4};`}
  display: flex;
  ${(props) => props.stretch && `align-items: stretch;`}
  align-items: center;
  font-family: AvenirNextLTPro;
  font-weight: 700;
  justify-content: center;
  ${(props) => props.flexCenter && `align-items: center;`}
  ${(props) => props.flexEnd && `justify-content: flex-end;`}
  ${(props) => props.margbottom && `margin-bottom: ${props.margbottom};`}
  ${(props) => props.margtop && `margin-top: ${props.margtop};`}
  ${(props) => props.size && `font-size: ${props.size};`}
  ${(props) => props.color && `color: ${props.color};`}
  ${(props) => props.margin && `margin: ${props.margin};`}
  ${(props) => props.cursor && `cursor: ${props.cursor};`}
  ${(props) =>
    props.bold &&
    `font-family: AvenirNextLTPro;
  font-weight: 700;`}
  ${(props) =>
    props.regular &&
    `font-family: AvenirNextLTPro;
  font-weight: normal;`}
  ${(props) => props.alignCenter && `text-align: center;`}
  ${(props) => props.justifyStart && `justify-content: flex-start;`}
  ${(props) => props.wrap && `flex-wrap: wrap;`}
  ${(props) =>
    props.fitLetter &&
    `
  font-style: normal;
  letter-spacing: 0.02em;
  `}
`;
