export function setCurrentTab(tab) {
  return {
    type: '@profileAccount/SET_CURRENT_TAB',
    payload: { tab },
  };
}

export function getSubscriptionsRequest() {
  return {
    type: '@profileAccount/GET_SUBSCRIPTIONS_REQUEST',
  };
}

export function getSubscriptionsSuccess(subscriptions, isDuePayment, hasFreeTrialValid) {
  return {
    type: '@profileAccount/GET_SUBSCRIPTIONS_SUCCESS',
    payload: { subscriptions, isDuePayment, hasFreeTrialValid },
  };
}

export function getSubscriptionsFailure() {
  return {
    type: '@profileAccount/GET_SUBSCRIPTIONS_FAILURE',
  };
}

export function getRecurringRequest() {
  return {
    type: '@profileAccount/GET_RECURRING_REQUEST',
  };
}

export function getRecurringSuccess(recurring) {
  return {
    type: '@profileAccount/GET_RECURRING_SUCCESS',
    payload: { recurring },
  };
}

export function getRecurringFailure() {
  return {
    type: '@profileAccount/GET_RECURRING_FAILURE',
  };
}

export function formatSubscriptions(subscriptions) {
  return {
    type: '@profileAccount/FORMAT_SUBSCRIPTIONS',
    payload: { subscriptions },
  };
}

export function getPaymentCardsRequest() {
  return {
    type: '@profileAccount/GET_PAYMENT_CARDS_REQUEST',
  };
}

export function getPaymentCardsSuccess(paymentCards) {
  return {
    type: '@profileAccount/GET_PAYMENT_CARDS_SUCCESS',
    payload: { paymentCards },
  };
}

export function getPaymentCardsFailure() {
  return {
    type: '@profileAccount/GET_PAYMENT_CARDS_FAILURE',
  };
}

export function setDefaultCreditCardRequest(cardId) {
  return {
    type: '@profileAccount/SET_DEFAULT_CREDIT_CARD_REQUEST',
    payload: { cardId },
  };
}

export function setDefaultCreditCardSuccess(paymentCards) {
  return {
    type: '@profileAccount/SET_DEFAULT_CREDIT_CARD_SUCCESS',
    payload: { paymentCards },
  };
}

export function setDefaultCreditCardFailure() {
  return {
    type: '@profileAccount/SET_DEFAULT_CREDIT_CARD_FAILURE',
  };
}

export function deleteCardRequest(cardId) {
  return {
    type: '@profileAccount/DELETE_CARD_REQUEST',
    payload: { cardId },
  };
}

export function deleteCardSuccess(paymentCards) {
  return {
    type: '@profileAccount/DELETE_CARD_SUCCESS',
    payload: { paymentCards },
  };
}

export function deleteCardFailure() {
  return {
    type: '@profileAccount/DELETE_CARD_FAILURE',
  };
}

export function addNewCardRequest(
  cardNumber,
  cardValidity,
  cardCVV,
  cardName,
  cpfCnpj,
  isDefault
) {
  return {
    type: '@profileAccount/ADD_NEW_CARD_REQUEST',
    payload: {
      cardNumber,
      cardValidity,
      cardCVV,
      cardName,
      cpfCnpj,
      isDefault,
    },
  };
}

export function addNewCardSuccess(paymentCards) {
  return {
    type: '@profileAccount/ADD_NEW_CARD_SUCCESS',
    payload: { paymentCards },
  };
}

export function addNewCardFailure() {
  return {
    type: '@profileAccount/ADD_NEW_CARD_FAILURE',
  };
}
