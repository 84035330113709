import styled from "styled-components";
import px2vw from "../../../../../utils/responsiveness/px2vw";
import { FaUserCircle } from "react-icons/fa";
import { branco, cinzaKuppi10 } from "../../../../../styles/colors";
import { Row } from "../../../../../styles/grid";

export const VCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 250px;
  padding: ${px2vw(5)};
  justify-content: center;
  align-items: center;
`;

export const VCardTitle = styled.div`
  font-size: ${px2vw(11)};
  font-weight: bold;
  text-overflow: ellipsis;
`;

export const VCardTel = styled.div`
  font-size: ${px2vw(8)};
  font-weight: lighter;
`;

export const VCardItem = styled(Row)`
  padding-bottom: ${px2vw(5)};
`;

export const SeeMore = styled.details`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  & > summary {
    list-style: none;
    text-align: center;
    font-weight: bold;
    border-radius: ${px2vw(50)};
    color: ${branco};
    background-color: ${cinzaKuppi10 + 90};
    user-select: none;
  }
`;

export const OtherVCards = styled.p`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-top: ${px2vw(5)};
  padding-left: ${px2vw(5)};
`;

export const VCardImage = styled(FaUserCircle)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30%;
  font-size: ${px2vw(30)};
  margin-right: ${px2vw(5)};
`;
