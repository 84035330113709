import styled from 'styled-components';

import { Input } from '@rocketseat/unform';

import px2vw from '../../../utils/responsiveness/px2vw';

import {
  branco,
  verdeKuppi1,
  cinzaKuppi3,
  cinzaKuppi4,
} from '../../../styles/colors';

export const DataTitleText = styled.div`
  font-size: ${px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.36px;
`;

export const DataText = styled.div`
  font-size: ${px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.36px;

  ${(props) => `
    width: ${props.width};
  `};
`;

export const EditButtonBox = styled.div`
  ${(props) => `margin-top: ${props.margtop}`};
  ${(props) => `margin-right: ${props.margright}`};
  ${(props) => `margin-left: ${props.margleft}`};
`;

export const ModalTitleText = styled.div`
  font-size: ${px2vw(28)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.72px;
  margin-left: ${px2vw(-405)};
  margin-top: ${px2vw(-45)};
`;

export const ModalInputLabelText = styled.div`
  font-size: ${px2vw(12)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.32px;

  ${(props) => `
  margin-left: ${props.margleft};
`};
`;

export const ModalInput = styled(Input)`
  padding: 0.5em;
  margin-top: ${px2vw(5)};
  border: 1px solid ${cinzaKuppi4};
  border-radius: ${px2vw(5)};
  font-size: ${px2vw(12)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.36px;
  width: ${px2vw(280)};
  height: ${px2vw(30)};

  ::placeholder {
    color: ${cinzaKuppi4} !important;
  }

  :focus {
    border: 1px solid ${cinzaKuppi3} !important;
    box-shadow: none !important;
  }

  ${(props) => `
    width: ${props.width};
    height: ${props.height};
    margin-top: ${props.margtop};
  `};
`;

export const CheckboxDefaultCard = styled.div`
  border: 1px solid ${cinzaKuppi3};
  background-color: ${branco};
  width: ${px2vw(12)};
  height: ${px2vw(12)};
`;

export const CheckboxDefaultCardChecked = styled.div`
  background-color: ${verdeKuppi1};
  width: ${px2vw(8)};
  height: ${px2vw(8)};
  margin-top: ${px2vw(1)};
  margin-left: ${px2vw(1.3)};
  display: none;

  ${(props) =>
    props.checked &&
    `
      display: block;
    `};
`;

export const LoadingBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const SignaturePlanDataBox = styled.div`
  border-radius: ${px2vw(20)};
  background-color: ${branco};
  width: ${px2vw(1020)};
  height: ${px2vw(210)};
  padding-left: ${px2vw(30)};
  padding-right: ${px2vw(30)};
  margin-top: ${px2vw(5)};

  box-shadow: 0px 8px 9px -2px ${cinzaKuppi4};
`;

export const SignaturePlanHistoryDataBox = styled.div`
  border-radius: ${px2vw(20)};
  background-color: ${branco};
  width: ${px2vw(1020)};
  height: auto;
  min-height: ${px2vw(238)};
  padding-left: ${px2vw(30)};
  padding-right: ${px2vw(30)};
  padding-bottom: ${px2vw(25)};
  margin-top: ${px2vw(5)};

  box-shadow: 0px 8px 9px -2px ${cinzaKuppi4};
`;

export const CancelPlanModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const CancelPlanModalTitle = styled.div`
  font-size: ${px2vw(23)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.64px;
  margin-left: ${px2vw(45)};
  margin-right: ${px2vw(45)};
  margin-top: ${px2vw(20)};
  text-align: center;
`;

export const CancelPlanModalText = styled.div`
  font-size: ${px2vw(18)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.48px;
  margin-left: ${px2vw(45)};
  margin-right: ${px2vw(45)};
  margin-top: ${px2vw(20)};
  text-align: center;
`;

export const CancelPlanModalText2 = styled.div`
  font-size: ${px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.36px;
  margin-left: ${px2vw(45)};
  margin-right: ${px2vw(45)};
  margin-top: ${px2vw(30)};
  text-align: center;
`;

export const ManageCardModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  padding: ${px2vw(15)};
`;

export const NewCardButton = styled.button`
  background-color: ${verdeKuppi1};
  width: ${px2vw(155)};
  height: ${px2vw(35)};
  border: none;
  border-radius: ${px2vw(10)};
  padding: 0.3em;
`;

export const NewCardButtonText = styled.div`
  font-size: ${px2vw(12)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.36px;
  color: ${branco};
`;

export const NewCardInfoText = styled.div`
  font-size: ${px2vw(12)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.32px;
  width: ${px2vw(420)};
`;
