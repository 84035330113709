import moment from 'moment';
import { useEffect, useState } from 'react';

const getHoursCountDownValues = (countDown, maxHours = 24) => {
  // Days: Math.floor(countDown / (1000 * 60 * 60 * 24));
  // Hours with days: Math.floor((countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
  const hours = Math.floor(
    (countDown % (1000 * 60 * 60 * maxHours)) / (1000 * 60 * 60)
    );

  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

  return [hours, minutes, seconds];
};

const useHoursCountDown = (startDate, targetInHours = 24) => {
  const countDownDate = moment(startDate).add(targetInHours, 'hours').toDate().getTime()
  const [countDown, setCountDown] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      const newCountDown = countDownDate - new Date().getTime()
      setCountDown(newCountDown);
      if(newCountDown <= 0) {
        clearInterval(interval)
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [countDownDate]);

  return getHoursCountDownValues(countDown, targetInHours)
};

export default useHoursCountDown;
