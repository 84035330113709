export function getFinancialStatusRequest() {
  return {
    type: '@financial/GET_FINANCIAL_STATUS_REQUEST',
  };
}

export function getFinancialStatusSuccess(accountData) {
  return {
    type: '@financial/GET_FINANCIAL_STATUS_SUCCESS',
    payload: accountData,
  };
}

export function getFinancialStatusFailure() {
  return {
    type: '@financial/GET_FINANCIAL_STATUS_FAILURE',
  };
}

export function createSellerRequest() {
  return {
    type: '@financial/CREATE_SELLER_REQUEST',
  };
}

export function createSellerFailure() {
  return {
    type: '@financial/CREATE_SELLER_FAILURE',
  };
}

export function createSellerSuccess() {
  return {
    type: '@financial/CREATE_SELLER_SUCCESS',
  };
}

export function updateSellerInfoRequest(payload) {
  return {
    type: '@financial/UPDATE_SELLER_INFO_REQUEST',
    payload: { payload },
  };
}

export function updateSellerBankRequest(payload) {
  return {
    type: '@financial/UPDATE_SELLER_BANK_REQUEST',
    payload: { payload },
  };
}

export function updateSellerBankSuccess() {
  return {
    type: '@financial/UPDATE_SELLER_BANK_SUCCESS',
  };
}

export function updateSellerBankFailure() {
  return {
    type: '@financial/UPDATE_SELLER_BANK_FAILURE',
  };
}

export function updateSellerInfoSuccess() {
  return {
    type: '@financial/UPDATE_FINANCIAL_RECORDS_SUCCESS',
  };
}

export function updateSellerInfoFailure() {
  return {
    type: '@financial/UPDATE_FINANCIAL_RECORDS_FAILURE',
  };
}

export function clearTransfersData() {
  return {
    type: '@financial/CLEAR_TRANSFERS_DATA'
  }
}

export function getTransfersDataRequest(startDate, endDate) {
  return {
    type: '@financial/GET_FINANCIAL_TRANSFERS_REQUEST',
    payload: { 
      startDate,
      endDate
    }
  };
}

export function getTransfersDataSuccess(transfers) {
  return {
    type: '@financial/GET_FINANCIAL_TRANSFERS_SUCCESS',
    payload: { transfers },
  };
}

export function getTransfersDataFailure() {
  return {
    type: '@financial/GET_FINANCIAL_TRANSFERS_FAILURE',
  };
}

export function getTransferTransactionsRequest(transferId) {
  return {
    type: '@financial/GET_FINANCIAL_TRANSFERS_TRANSACTIONS_REQUEST',
    payload: { transferId },
  };
}

export function getTransferTransactionsSuccess(transactions) {
  return {
    type: '@financial/GET_FINANCIAL_TRANSFERS_TRANSACTIONS_SUCCESS',
    payload: { transactions },
  };
}

export function getTransferTransactionsFailure() {
  return {
    type: '@financial/GET_FINANCIAL_TRANSFERS_TRANSACTIONS_FAILURE',
  };
}

export function updateStoreDataField(payload) {
  return {
    type: '@financial/UPDATE_STORE_DATA_FIELD',
    payload: { payload },
  };
}

export function setBankList(bankList) {
  return {
    type: '@financial/SET_BANK_LIST',
    payload: bankList,
  };
}

export function clearRequestErrors() {
  return {
    type: '@financial/CLEAR_REQUEST_ERRORS'
  }
}