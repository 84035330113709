import React from 'react';

import 'leaflet/dist/leaflet.css';

import { MapContainer, TileLayer, Marker, Circle } from 'react-leaflet';
import { Icon } from 'leaflet';
import px2vw from '../../../utils/responsiveness/px2vw';

export default function MapContent(props) {
  const { lat, lng } = props.position;
  const myPosition = [lat, lng];

  const blackOptions = { color: 'black', borderWidth: '1px' }

  return (
    <MapContainer
      style={{ width: px2vw(300), height: px2vw(185), position: 'relative' }}
      center={myPosition}
      zoom={13}
      scrollWheelZoom={true}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Circle
        center={myPosition}
        pathOptions={blackOptions}
        radius={props.rangeArea * 1000}
      >
        <Marker 
          icon={
            new Icon({iconUrl: require('../../../assets/images/pin-fixed.svg'), 
            iconSize: [25, 41], 
            iconAnchor: [12, 41]})
          } 
          position={myPosition} 
        />
      </Circle>
    </MapContainer>
  );
}
