import React from 'react';

import {
  formatDateTimeOrderText,
  formatDateTimeOrder,
} from '../../utils/utils';

import px2vw from '../../utils/responsiveness/px2vw';

import { Row, Col } from '../../styles/grid';

import { ClockImage, OrdersRowText } from '../../pages/_layouts/styles/orders';

import {
  amareloKuppi1,
  verdeKuppi9,
  verdeKuppi4,
  cinzaKuppi6,
  vermelhoKuppi1,
  roxoKuppi1,
} from '../../styles/colors';

import { StatusBox } from './styles';

export default function OrderStatusBox(props) {
  const { orderStatusData, width, height, padtop, margtop } = props;

  return (
    <StatusBox
      width={width}
      height={height}
      padtop={padtop}
      margtop={margtop}
      backgroundColor={
        orderStatusData.status === 'new'
          ? amareloKuppi1
          : orderStatusData.status === 'accepted'
          ? verdeKuppi9
          : orderStatusData.status === 'preparing'
          ? verdeKuppi4
          : orderStatusData.status === 'delivering' ||
            orderStatusData.status === 'ready_take_away'
          ? roxoKuppi1
          : orderStatusData.status === 'finished'
          ? cinzaKuppi6
          : vermelhoKuppi1
      }
    >
      <Row justifyCenter height={px2vw(20)} margbottom={px2vw(15)}>
        <ClockImage
          src={require('../../assets/images/clock/clock@2x.png')}
          size={px2vw(10)}
          margtop={px2vw(9)}
        />
        <Col width="auto">
          <OrdersRowText
            margtop={px2vw(8)}
            margleft={px2vw(5)}
            fontSize={px2vw(11)}
          >
            {orderStatusData.status === 'new' &&
              formatDateTimeOrderText(
                orderStatusData.new_at,
                orderStatusData.status
              )}
            {orderStatusData.status === 'accepted' &&
              formatDateTimeOrderText(
                orderStatusData.accepted_at,
                orderStatusData.status
              )}
            {orderStatusData.status === 'preparing' &&
              formatDateTimeOrderText(
                orderStatusData.preparing_at,
                orderStatusData.status
              )}
            {orderStatusData.status === 'delivering' &&
              formatDateTimeOrderText(
                orderStatusData.delivery_at,
                orderStatusData.status
              )}
            {orderStatusData.status === 'ready_take_away' &&
              formatDateTimeOrderText(
                orderStatusData.ready_take_away_at,
                orderStatusData.status
              )}
            {orderStatusData.status === 'finished' &&
              formatDateTimeOrderText(
                orderStatusData.finished_at,
                orderStatusData.status
              )}
            {orderStatusData.status === 'canceled' &&
              formatDateTimeOrderText(
                orderStatusData.canceled_at,
                orderStatusData.status
              )}
          </OrdersRowText>
          <OrdersRowText margleft={px2vw(5)} fontSize={px2vw(11)}>
            {orderStatusData.status === 'accepted' &&
              formatDateTimeOrder(
                orderStatusData.accepted_at,
                orderStatusData.status
              )}
            {orderStatusData.status === 'preparing' &&
              formatDateTimeOrder(
                orderStatusData.preparing_at,
                orderStatusData.status
              )}
            {orderStatusData.status === 'delivering' &&
              formatDateTimeOrder(
                orderStatusData.delivery_at,
                orderStatusData.status
              )}
            {orderStatusData.status === 'ready_take_away' &&
              formatDateTimeOrder(
                orderStatusData.ready_take_away_at,
                orderStatusData.status
              )}
            {orderStatusData.status === 'finished' &&
              formatDateTimeOrder(
                orderStatusData.finished_at,
                orderStatusData.status
              )}
            {orderStatusData.status === 'canceled' &&
              formatDateTimeOrder(
                orderStatusData.canceled_at,
                orderStatusData.status
              )}
          </OrdersRowText>
        </Col>
      </Row>
    </StatusBox>
  );
}
