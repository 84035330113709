import produce from 'immer';

const INITIAL_STATE = {
  localities: [],
  localitiesLoading: true,
  localitiesSuccess: false,
  districts: [],
  districtsLoading: true,
  districtsSuccess: false,
  addNewNeighborhoodDataLoading: false,
  addNewNeighborhoodDataSuccess: false,
  selectedNeighborhoods: [],
  selectedNeighborhoodsLoading: false,
  selectedNeighborhoodsSuccess: false,
  advertiserPaymentMethods: [],
  advertiserPaymentMethodsLoading: false,
  advertiserPaymentMethodsSuccess: false,
  pauseNeighborhoodLoading: false,
  pauseNeighborhoodSuccess: false,
  updateNeighborhoodModalLoading: false,
  updateNeighborhoodModalSuccess: false,
  deleteNeighborhoodModalLoading: false,
  deleteNeighborhoodModalSuccess: false,
  availabilityLoading: false,
  availabilitySuccess: false,
  updatePaymentMethodLoading: false,
  updatePaymentMethodSuccess: false,
  deliveryTakeAwayLoading: false,
  deliveryTakeAwaySuccess: false,
  schedulingLoading: false,
  schedulingSuccess: false,
};

export default function user(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@myStore/CLEAR_SUCCESS': {
        draft.availabilitySuccess = false;
        draft.updatePaymentMethodSuccess = false;
        draft.deliveryTakeAwaySuccess = false;
        draft.localitiesSuccess = false;
        draft.districtsSuccess = false;
        draft.addNewNeighborhoodDataLoading = false;
        draft.addNewNeighborhoodDataSuccess = false;
        draft.schedulingLoading = false;
        draft.schedulingSuccess = false;
        draft.advertiserPaymentMethodsLoading = false;
        draft.advertiserPaymentMethodsSuccess = false;
        break;
      }
      case '@myStore/UPDATE_STORE_AVAILABILITY_REQUEST': {
        draft.availabilityLoading = true;
        break;
      }
      case '@myStore/UPDATE_STORE_AVAILABILITY_SUCCESS': {
        draft.availabilitySuccess = true;
        draft.availabilityLoading = false;
        break;
      }
      case '@myStore/UPDATE_STORE_AVAILABILITY_FAILURE': {
        draft.availabilitySuccess = false;
        draft.availabilityLoading = false;
        break;
      }
      case '@myStore/UPDATE_STORE_PAYMENT_METHOD_REQUEST': {
        draft.updatePaymentMethodLoading = true;
        break;
      }
      case '@myStore/UPDATE_STORE_PAYMENT_METHOD_SUCCESS': {
        draft.advertiserPaymentMethods = action.payload.advertiserPaymentMethods;
        draft.updatePaymentMethodSuccess = true;
        draft.updatePaymentMethodLoading = false;
        break;
      }
      case '@myStore/UPDATE_STORE_PAYMENT_METHOD_FAILURE': {
        draft.updatePaymentMethodSuccess = false;
        draft.updatePaymentMethodLoading = false;
        break;
      }
      case '@myStore/UPDATE_STORE_DELIVERY_TAKE_AWAY_REQUEST': {
        draft.deliveryTakeAwayLoading = true;
        break;
      }
      case '@myStore/UPDATE_STORE_DELIVERY_TAKE_AWAY_SUCCESS': {
        draft.deliveryTakeAwaySuccess = true;
        draft.deliveryTakeAwayLoading = false;
        break;
      }
      case '@myStore/UPDATE_STORE_DELIVERY_TAKE_AWAY_FAILURE': {
        draft.deliveryTakeAwaySuccess = false;
        draft.deliveryTakeAwayLoading = false;
        break;
      }
      case '@myStore/GET_SELECTED_NEIGHBORHOODS_REQUEST': {
        draft.selectedNeighborhoodsLoading = true;
        draft.selectedNeighborhoodsSuccess = false;
        break;
      }
      case '@myStore/GET_SELECTED_NEIGHBORHOODS_SUCCESS': {
        draft.selectedNeighborhoods = action.payload.selectedNeighborhoods;
        draft.selectedNeighborhoodsLoading = false;
        draft.selectedNeighborhoodsSuccess = true;
        break;
      }
      case '@myStore/GET_SELECTED_NEIGHBORHOODS_FAILURE': {
        draft.selectedNeighborhoods = null;
        draft.selectedNeighborhoodsLoading = false;
        draft.selectedNeighborhoodsSuccess = false;
        break;
      }
      case '@myStore/PAUSE_NEIGHBORHOOD_REQUEST': {
        draft.pauseNeighborhoodLoading = true;
        draft.pauseNeighborhoodSuccess = false;
        break;
      }
      case '@myStore/PAUSE_NEIGHBORHOOD_SUCCESS': {
        draft.selectedNeighborhoods = action.payload.selectedNeighborhoods;
        draft.pauseNeighborhoodLoading = false;
        draft.pauseNeighborhoodSuccess = true;
        break;
      }
      case '@myStore/PAUSE_NEIGHBORHOOD_FAILURE': {
        draft.pauseNeighborhoodLoading = false;
        draft.pauseNeighborhoodSuccess = true;
        break;
      }
      case '@myStore/UPDATE_NEIGHBORHOOD_REQUEST': {
        draft.updateNeighborhoodModalLoading = true;
        draft.updateNeighborhoodModalSuccess = false;
        break;
      }
      case '@myStore/UPDATE_NEIGHBORHOOD_SUCCESS': {
        draft.selectedNeighborhoods = action.payload.selectedNeighborhoods;
        draft.updateNeighborhoodModalLoading = false;
        draft.updateNeighborhoodModalSuccess = true;
        break;
      }
      case '@myStore/UPDATE_NEIGHBORHOOD_FAILURE': {
        draft.updateNeighborhoodModalLoading = false;
        draft.updateNeighborhoodModalSuccess = true;
        break;
      }
      case '@myStore/DELETE_NEIGHBORHOOD_REQUEST': {
        draft.deleteNeighborhoodModalLoading = true;
        draft.deleteNeighborhoodModalSuccess = false;
        break;
      }
      case '@myStore/DELETE_NEIGHBORHOOD_SUCCESS': {
        draft.selectedNeighborhoods = action.payload.selectedNeighborhoods;
        draft.deleteNeighborhoodModalLoading = false;
        draft.deleteNeighborhoodModalSuccess = true;
        break;
      }
      case '@myStore/DELETE_NEIGHBORHOOD_FAILURE': {
        draft.deleteNeighborhoodModalLoading = false;
        draft.deleteNeighborhoodModalSuccess = true;
        break;
      }
      case '@myStore/GET_LOCALITIES_REQUEST': {
        draft.localities = [];
        draft.localitiesLoading = true;
        draft.localitiesSuccess = false;
        break;
      }
      case '@myStore/GET_LOCALITIES_SUCCESS': {
        draft.localities = action.payload.localities;
        draft.localitiesLoading = false;
        draft.localitiesSuccess = true;
        break;
      }
      case '@myStore/GET_LOCALITIES_FAILURE': {
        draft.localities = [];
        draft.localitiesLoading = false;
        draft.localitiesSuccess = false;
        break;
      }
      case '@myStore/GET_DISTRICTS_REQUEST': {
        draft.districts = [];
        draft.districtsLoading = true;
        draft.districtsSuccess = false;
        break;
      }
      case '@myStore/GET_DISTRICTS_SUCCESS': {
        draft.districts = action.payload.districts;
        draft.districtsLoading = false;
        draft.districtsSuccess = true;
        break;
      }
      case '@myStore/GET_DISTRICTS_FAILURE': {
        draft.districts = [];
        draft.districtsLoading = false;
        draft.districtsSuccess = false;
        break;
      }
      case '@myStore/ADD_NEW_NEIGHBORHOOD_REQUEST': {
        draft.addNewNeighborhoodDataLoading = true;
        draft.addNewNeighborhoodDataSuccess = false;
        break;
      }
      case '@myStore/ADD_NEW_NEIGHBORHOOD_SUCCESS': {
        draft.selectedNeighborhoods = action.payload.selectedNeighborhoods;
        draft.addNewNeighborhoodDataLoading = true;
        draft.addNewNeighborhoodDataSuccess = true;
        break;
      }
      case '@myStore/ADD_NEW_NEIGHBORHOOD_FAILURE': {
        draft.addNewNeighborhoodDataLoading = false;
        draft.addNewNeighborhoodDataSuccess = true;
        break;
      }
      case '@myStore/UPDATE_STORE_SCHEDULING_AVAILABILITY_REQUEST': {
        draft.schedulingLoading = true;
        break;
      }
      case '@myStore/UPDATE_STORE_SCHEDULING_AVAILABILITY_SUCCESS': {
        draft.schedulingSuccess = true;
        draft.schedulingLoading = false;
        break;
      }
      case '@myStore/UPDATE_STORE_SCHEDULING_AVAILABILITY_FAILURE': {
        draft.schedulingSuccess = false;
        draft.schedulingLoading = false;
        break;
      }
      case '@myStore/GET_ADVERTISER_PAYMENT_METHODS_REQUEST': {
        draft.advertiserPaymentMethodsLoading = true;
        draft.advertiserPaymentMethodsSuccess = false;
        break;
      }
      case '@myStore/GET_ADVERTISER_PAYMENT_METHODS_SUCCESS': {
        draft.advertiserPaymentMethods = action.payload.advertiserPaymentMethods;
        draft.advertiserPaymentMethodsLoading = false;
        draft.advertiserPaymentMethodsSuccess = true;
        break;
      }
      case '@myStore/GET_ADVERTISER_PAYMENT_METHODS_FAILURE': {
        draft.advertiserPaymentMethods = null;
        draft.advertiserPaymentMethodsLoading = false;
        draft.advertiserPaymentMethodsSuccess = false;
        break;
      }
      case '@myStore/CLEAR_SUCCES_DELIVERY_PARAMS_ONBOARDING': {
        draft.availabilitySuccess = false;
        draft.updatePaymentMethodSuccess = false;
        draft.deliveryTakeAwaySuccess = false;
        break;
      }
      default:
    }
  });
}
