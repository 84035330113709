import React, { useEffect, useState } from 'react';
import QRCode from 'qrcode';
import { Icon } from 'react-materialize';
import { useDispatch, useSelector } from 'react-redux';
import { cinzaKuppi1, preto } from '../../../styles/colors';
import { Text } from '../../../styles/common';
import { Row, Col } from '../../../styles/grid';

import px2vw from '../../../utils/responsiveness/px2vw';
import { formatCurrencyBRL, getURL } from '../../../utils/utils';
import { WhatsImg } from '../MassMessageModal/Step3-Message/styles';
import { MdContentCopy } from 'react-icons/md';

import {
  ButtonPrimary,
  CloseButton,
  ConversationIndicatorImg,
  MessageContainer,
  ModalBackground,
  ModalContent,
  PhoneImg,
  PixCodeBar,
  PixCodeImg,
  TextBox,
} from './styles';

import { toast } from 'react-toastify';
import moment from 'moment';
import { format } from '@flasd/whatsapp-formatting';
import { SecundaryButton } from '../MassMessageModal/CampaignTable/styles';
import { Spinner } from 'reactstrap';
import {
  cancelMassMessagePaymentRequest,
  setCurrentStep,
  setIsEditing,
} from '../../../store/modules/massMessage/actions';
import { ConfirmCancelModal } from '../MassMessageModal/ConfirmCancel';

export default function CampaignDetailModal(props) {
  const dispatch = useDispatch();
  const {
    selectedCampaign,
    getCampaignDetailsLoading,
    cancelMassMessagePaymentLoading,
  } = useSelector((state) => state.massMessage);
  const user = useSelector((state) => state.user.user);
  const [qrCodePix, setQrCodePix] = useState('');
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
  const pixCode = selectedCampaign?.campaignDetails?.pix_qr_code;
  const advertiserUrl = getURL('cardapio_web').replace(
    '$$advertiser_slug$$',
    user.advertiserInfo.slug
  );

  const isPaymentExpired =
    selectedCampaign?.campaignDetails?.status === 'pending_payment' &&
    moment(new Date()).isAfter(
      moment(selectedCampaign?.campaignDetails?.pix_expiration_at).utcOffset(
        '+03:00'
      )
    );

  useEffect(() => {
    // Set QR code data
    QRCode.toDataURL(pixCode)
      .then((text) => {
        setQrCodePix(text);
      })
      .catch((err) => {});
  }, [pixCode]);

  function copyPixPaymentCode() {
    var copyText = document.getElementById('pixCode');
    var textArea = document.createElement('textarea');
    textArea.value = copyText.textContent;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();

    toast.success('A chave Pix foi copiada com sucesso!');
  }

  return (
    <ModalBackground isVisible={props.isVisible}>
      {isConfirmModalVisible && (
        <ConfirmCancelModal
          onClose={() => {
            setIsConfirmModalVisible(false);
            props.onClose();
          }}
        />
      )}
      <ModalContent
        width={
          selectedCampaign?.campaignDetails?.status !== 'pending_payment'
            ? px2vw(700)
            : '85%'
        }
        minHeight={px2vw(770)}
      >
        {getCampaignDetailsLoading ? (
          <Row height="80vh">
            <Spinner
              style={{
                margin: 'auto',
                width: '1.8rem',
                height: '1.8rem',
                color: preto,
              }}
            />
          </Row>
        ) : (
          <>
            <Row alignCenter justifyCenter height="auto" margbottom={px2vw(20)}>
              <Col
                pad={`0 0 0 ${px2vw(40)}`}
                alignStart
                justifyCenter
                height="auto"
                width={px2vw(490)}
              >
                <Text size={px2vw(25)}>
                  Detalhes da campanha #{selectedCampaign?.campaignDetails?.id}
                </Text>
              </Col>
              <Row alignCenter justifyCenter className="ml-auto" width="auto">
                <CloseButton onClick={props.onClose}>
                  <Icon>clear</Icon>
                </CloseButton>
              </Row>
            </Row>
            <Row spaceAround className="pb-2">
              <Col
                height={px2vw(470)}
                width={
                  selectedCampaign?.campaignDetails?.status ===
                  'pending_payment'
                    ? '33%'
                    : '45%'
                }
                relative
              >
                <MessageContainer
                  padleft={
                    selectedCampaign?.campaignDetails?.status ===
                    'pending_payment'
                      ? px2vw(50)
                      : px2vw(30)
                  }
                  padtop={px2vw(80)}
                  padbottom={px2vw(10)}
                  padright={px2vw(10)}
                  justifyStart
                  absolute
                >
                  <TextBox first>
                    {selectedCampaign?.campaignDetails?.url && (
                      <>
                        <WhatsImg
                          src={selectedCampaign?.campaignDetails?.url}
                        />{' '}
                        <br />
                      </>
                    )}
                    <div
                      dangerouslySetInnerHTML={{
                        __html: format(
                          selectedCampaign?.campaignDetails?.main_text
                            ?.replace('<', '')
                            ?.replace('>', '') || ''
                        ),
                      }}
                    />
                    <ConversationIndicatorImg
                      src={require('../../../assets/images/sales_retriever/conversation_indicator.png')}
                    />
                    {selectedCampaign.campaignDetails.send_catalog_link && (
                      <>
                        <br />
                        <a href={advertiserUrl}>{advertiserUrl}</a>
                        <br />
                      </>
                    )}
                    {selectedCampaign?.campaignDetails
                      ?.show_unsubscribe_message && (
                      <>
                        <br />
                        Caso não queira mais receber mensagens promocionais,
                        digite "N"
                      </>
                    )}{' '}
                  </TextBox>
                </MessageContainer>

                <PhoneImg
                  src={require('../../../assets/images/sales_retriever/phone.svg')}
                />
              </Col>
              <Col
                height={px2vw(460)}
                background={cinzaKuppi1}
                pad={px2vw(15)}
                width={px2vw(355)}
                borderRadius={px2vw(10)}
                justifyStart
              >
                <Row height="auto">
                  <Text size={px2vw(17)}>Resumo da campanha</Text>
                </Row>
                <Row height="auto" margbottom={px2vw(5)}>
                  <Text regular>
                    Campanha de{' '}
                    {selectedCampaign?.campaignDetails?.coupon_id
                      ? 'promoção em massa'
                      : 'transmissão em massa'}
                  </Text>
                </Row>
                <Row margtop={px2vw(5)} justifyStart height="auto">
                  <Text regular size={px2vw(13)}>
                    Pessoas impactadas no disparo
                  </Text>
                </Row>
                <Row margtop={px2vw(5)} spaceBetween height="auto">
                  <Text size={px2vw(14)}>Novos clientes</Text>{' '}
                  <Text size={px2vw(13)}>
                    {selectedCampaign?.campaignDetails?.segmentations_qty[
                      'new'
                    ] || 0}
                  </Text>
                </Row>
                <Row margtop={px2vw(5)} spaceBetween height="auto">
                  <Text size={px2vw(13)}>Recorrentes</Text>
                  <Text size={px2vw(13)}>
                    {selectedCampaign?.campaignDetails?.segmentations_qty[
                      'recurrent'
                    ] || 0}
                  </Text>
                </Row>
                <Row margtop={px2vw(5)} spaceBetween height="auto">
                  <Text size={px2vw(13)}>VIPs</Text>
                  <Text size={px2vw(13)}>
                    {selectedCampaign?.campaignDetails?.segmentations_qty[
                      'vip'
                    ] || 0}
                  </Text>
                </Row>
                <Row margtop={px2vw(5)} spaceBetween height="auto">
                  <Text size={px2vw(13)}>Em risco</Text>
                  <Text size={px2vw(13)}>
                    {selectedCampaign?.campaignDetails?.segmentations_qty[
                      'risk'
                    ] || 0}
                  </Text>
                </Row>
                <Row margtop={px2vw(5)} spaceBetween height="auto">
                  <Text size={px2vw(13)}>Potenciais clientes</Text>
                  <Text size={px2vw(13)}>
                    {selectedCampaign?.campaignDetails?.segmentations_qty[
                      'potential'
                    ] || 0}
                  </Text>
                </Row>

                <Row margtop={px2vw(20)} spaceBetween height="auto">
                  <Text size={px2vw(13)} regular>
                    Total de pessoas impactadas
                  </Text>
                  <Text size={px2vw(13)}>
                    {selectedCampaign?.campaignDetails?.contacts_qty}
                  </Text>
                </Row>

                <Row margtop={px2vw(10)} spaceBetween height="auto">
                  <Text size={px2vw(13)} regular>
                    Horário agendado para o disparo
                  </Text>
                  <Text size={px2vw(12)}>
                    {moment(selectedCampaign?.campaignDetails?.scheduled_at)
                      .utcOffset('+00:00')
                      .format('DD/MM/YYYY HH:mm')}
                  </Text>
                </Row>

                <Row margtop={px2vw(10)} spaceBetween height="auto">
                  <Text size={px2vw(13)} regular>
                    Valor por contato
                  </Text>
                  <Text size={px2vw(13)}>
                    {formatCurrencyBRL(
                      selectedCampaign?.campaignDetails?.per_contact_amount,
                      2
                    )}
                  </Text>
                </Row>

                {!!selectedCampaign?.campaignDetails?.discount_amount && (
                  <>
                    <Row margtop={px2vw(10)} spaceBetween height="auto">
                      <Text size={px2vw(14)} regular>
                        Valor total
                      </Text>
                      <Text size={px2vw(14)}>
                        {formatCurrencyBRL(
                          selectedCampaign?.campaignDetails?.amount || 0,
                          2
                        )}
                      </Text>
                    </Row>
                    <Row margtop={px2vw(10)} spaceBetween height="auto">
                      <Text size={px2vw(14)} regular>
                        Desconto
                      </Text>
                      <Text size={px2vw(14)}>
                        {formatCurrencyBRL(
                          selectedCampaign?.campaignDetails?.discount_amount ||
                            0,
                          2
                        )}
                      </Text>
                    </Row>
                  </>
                )}
                <Row margtop={px2vw(10)} spaceBetween height="auto">
                  <Text size={px2vw(16)} regular>
                    Investimento total
                  </Text>
                  <Text size={px2vw(16)}>
                    {formatCurrencyBRL(
                      selectedCampaign?.campaignDetails?.budget || 0,
                      2
                    )}
                  </Text>
                </Row>
                {selectedCampaign?.campaignDetails?.sales_amount > 0 && (
                  <Row margtop={px2vw(10)} spaceBetween height="auto">
                    <Text size={px2vw(16)} regular>
                      Valor em Vendas
                    </Text>
                    <Text size={px2vw(16)}>
                      {formatCurrencyBRL(
                        selectedCampaign?.campaignDetails?.sales_amount || 0,
                        2
                      )}
                    </Text>
                  </Row>
                )}
                {selectedCampaign?.campaignDetails?.recovered_amount > 0 && (
                  <Row margtop={px2vw(10)} spaceBetween height="auto">
                    <Text size={px2vw(16)} regular>
                      Valor Recuperado
                    </Text>
                    <Text size={px2vw(16)}>
                      {formatCurrencyBRL(
                        selectedCampaign?.campaignDetails?.recovered_amount ||
                          0,
                        2
                      )}
                    </Text>
                  </Row>
                )}
                {selectedCampaign?.campaignDetails?.roi > 0 && (
                  <Row margtop={px2vw(10)} spaceBetween height="auto">
                    <Text size={px2vw(16)} regular>
                      ROI
                    </Text>
                    <Text size={px2vw(16)}>
                      {selectedCampaign?.campaignDetails?.roi}x
                    </Text>
                  </Row>
                )}
                <Row>
                  {['pending_payment', 'paid'].includes(
                    selectedCampaign?.campaignDetails?.status
                  ) && (
                    <SecundaryButton
                      position="initial"
                      className="m-auto"
                      disabled={
                        !['pending_payment', 'paid'].includes(
                          selectedCampaign?.campaignDetails?.status
                        )
                      }
                      width={px2vw(150)}
                      size={px2vw(13)}
                      height={px2vw(30)}
                      onClick={() => {
                        props.onEdit();
                        dispatch(setCurrentStep('edit'));
                        dispatch(setIsEditing(true));
                        props.onClose();
                      }}
                    >
                      Editar Campanha
                    </SecundaryButton>
                  )}

                  {['paid', 'sending'].includes(
                    selectedCampaign?.campaignDetails?.status
                  ) && (
                    <SecundaryButton
                      position="initial"
                      className="m-auto"
                      disabled={
                        !['paid', 'sending'].includes(
                          selectedCampaign?.campaignDetails?.status
                        )
                      }
                      width={px2vw(150)}
                      size={px2vw(13)}
                      height={px2vw(30)}
                      onClick={() => {
                        setIsConfirmModalVisible(true);
                      }}
                    >
                      Cancelar Campanha
                    </SecundaryButton>
                  )}
                </Row>
              </Col>
              {selectedCampaign?.campaignDetails?.status ===
                'pending_payment' && (
                <Col width={px2vw(330)}>
                  <Col alignCenter className="pr-0">
                    <Text
                      className="mr-auto"
                      margbottom={px2vw(20)}
                      margtop={px2vw(20)}
                      justifyStart
                      size={px2vw(14.5)}
                    >
                      {isPaymentExpired
                        ? 'O prazo para o pagamento da campanha expirou, clique no botão abaixo para criar uma nova campanha'
                        : 'Para pagar via Pix, utilize o QR Code ou copie e cole o código abaixo'}
                    </Text>

                    <PixCodeImg
                      isExpired={isPaymentExpired}
                      className="m-auto"
                      src={qrCodePix}
                    />

                    {!isPaymentExpired ? (
                      <Row
                        className="mx-auto"
                        border={`${px2vw(3)} dashed ${preto}`}
                        height={px2vw(43)}
                        width="96%"
                        alignCenter
                        cursor="pointer"
                        relative
                        onClick={copyPixPaymentCode}
                        margbottom={px2vw(20)}
                        margtop={px2vw(20)}
                        spaceBetween
                        borderRadius={px2vw(8)}
                      >
                        <PixCodeBar id="pixCode" className="mx-auto">
                          {selectedCampaign?.campaignDetails?.pix_qr_code ||
                            ''.toUpperCase()}
                        </PixCodeBar>
                        <MdContentCopy
                          className="mx-auto"
                          style={{
                            fontSize: px2vw(21),
                          }}
                        />
                      </Row>
                    ) : (
                      <ButtonPrimary
                        className="mx-auto mt-2"
                        width={px2vw(150)}
                        size={px2vw(13)}
                        height={px2vw(30)}
                        onClick={props.onClickCreateCampaign}
                      >
                        Criar Campanha
                      </ButtonPrimary>
                    )}

                    {!isPaymentExpired && (
                      <Row margtop={px2vw(30)}>
                        <SecundaryButton
                          position="initial"
                          className="m-auto"
                          width={px2vw(150)}
                          size={px2vw(13)}
                          height={px2vw(30)}
                          disabled={cancelMassMessagePaymentLoading}
                          onClick={() =>
                            dispatch(
                              cancelMassMessagePaymentRequest(
                                selectedCampaign?.campaignDetails?.id
                              )
                            )
                          }
                        >
                          {cancelMassMessagePaymentLoading ? (
                            <Row height="80vh">
                              <Spinner
                                style={{
                                  margin: 'auto',
                                  width: '1.8rem',
                                  height: '1.8rem',
                                  color: preto,
                                }}
                              />
                            </Row>
                          ) : (
                            'Cancelar Pagamento'
                          )}
                        </SecundaryButton>
                      </Row>
                    )}
                  </Col>
                </Col>
              )}
            </Row>
          </>
        )}
      </ModalContent>
    </ModalBackground>
  );
}
