import { ErrorBoundary } from '@sentry/react';
import React from 'react';
import { ToastContainer } from 'react-toastify';

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import { Router } from 'react-router-dom';

import { SocketContext, notificationWS } from './context/ws';

import history from './services/history';
import Routes from './routes';
import GlobalStyle from './styles/global';

import { store, persistor } from './store';
import { WorkerSocket } from './context/WebSocket';

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <SocketContext.Provider value={[notificationWS, WorkerSocket]}>
          <Router history={history}>
            <ErrorBoundary showDialog>
              <Routes />
            </ErrorBoundary>
            <GlobalStyle />
            <ToastContainer autoClose={3000} style={{ zIndex: 99999999999 }} />
          </Router>
        </SocketContext.Provider>
      </PersistGate>
    </Provider>
  );
}

export default App;
