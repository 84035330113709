import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import { Icon } from 'react-materialize';
import { toast } from 'react-toastify';
import { Row, Col } from '../../../styles/grid';
import px2vw from '../../../utils/responsiveness/px2vw';

import {
  Header,
  Content,
  Hr,
  TableRow,
  PagButton,
  PrevIcon,
  NextIcon,
  PauseButton,
  ButtonBox,
  ButtonImage,
  ButtonTextRegular,
  ErrorText,
  TextCodeCoupon,
} from '../styles';

import { sort, makePages } from '../tableOptions';

import CouponTableEditButton from '../../CouponTableEditButton';
import TablePerPage from '../../TablePerPage';

import {
  updateCouponStatusRequest,
} from '../../../store/modules/coupons/actions';

import CouponRows from '../couponRows';

export default function CodeCouponTable({ data, editCoupon, deleteCoupon, updateAvailableCoupons }) {
  const [rows, setRows] = useState([]);
  const [rowsCurrentPage, setRowsCurrentPage] = useState();
  const [structure, setStructure] = useState(null);
  const [currentPage, setCurrentPage] = useState('1');
  const [lastPage, setLastPage] = useState();
  const [firstPage] = useState('1');
  const [sortedCol, setSortedCol] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    if (structure) {
      setLastPage(
        Object.keys(structure)[Object.keys(structure).length - 1].toString()
      );
    }
  }, [structure]);

  useMemo(() => {
    setStructure(makePages(rows.length, rowsCurrentPage));
  }, [rows, rowsCurrentPage]);

  useEffect(() => {
    setRows(data);
  }, [data]);

  useEffect(() => {
    if (rows.length < 5) {
      setRowsCurrentPage(rows.length);
    }
    if (rowsCurrentPage > 5) {
    } else {
      setRowsCurrentPage(5);
    }
  }, [rows]);

  const handleSort = useCallback(
    (col) => {
      if (sortedCol !== col) {
        setSortedCol(col);
        setRows(sort(rows, col, 'asc'));
      } else {
        setSortedCol('');
        setRows(sort(rows, col, 'desc'));
      }
    },
    [rows]
  );

  function handlePerPage(size) {
    setRowsCurrentPage(size);
  }

  function handleChangePage(direction) {
    if (direction === 'next') {
      const curr = +currentPage + 1;
      setCurrentPage(curr.toString());
    } else if (direction === 'prev') {
      const curr = +currentPage - 1;
      setCurrentPage(curr.toString());
    }
  }

  function handleEditCoupon(item) {
    editCoupon(item);
  }  

  function handleDeleteCoupon(item) {
    deleteCoupon(item);
  }  

  function handleIncrementLimit(item) {
    updateAvailableCoupons(item);
  } 

  function formatCodeCouponType(type) {
    if (type === "percentage_cart") {
      return "Percentual na compra"
    } else {
      return "Valor fixo"
    }
  }

  return (
    <>
      <Col padding="0 15px" alignCenter justifyCenter>
        <Row>
          <CouponRows width={px2vw(200)} isSortedColumn={sortedCol === 'coupon'} title="Cupom" handleSort={() => handleSort('coupon')} />
          <CouponRows width={px2vw(245)} isSortedColumn={sortedCol === 'type'} title="Tipo de Cupom" handleSort={() => handleSort('type')} />
          <CouponRows width={px2vw(175)} isSortedColumn={sortedCol === 'discount'} title="Desconto" handleSort={() => handleSort('discount')} />
          <CouponRows width={px2vw(210)} isSortedColumn={sortedCol === 'used_coupons'} title="Qtd Utilizada" handleSort={() => handleSort('used_coupons')} />
          <CouponRows width={px2vw(245)} isSortedColumn={sortedCol === 'used_coupons'} title="Qtd Disponível" handleSort={() => handleSort('used_coupons')} />
          <CouponRows width={px2vw(150)} isSortedColumn={sortedCol === 'is_active'} title="Status" handleSort={() => handleSort('is_active')} />
          <Header width="10%" padding="25px 5px" />
        </Row>
        <Row>
          <Hr />
        </Row>
        {structure ? (
          rows.map((item, index) =>
            structure[currentPage] &&
              structure[currentPage].start <= index + 1 &&
              structure[currentPage].end >= index + 1 ? (
                <TableRow alignCenter justifyCenter key={item.id}>
                  <Content width={px2vw(220)}>
                    <TextCodeCoupon title={item.code}>
                      {item.code}
                    </TextCodeCoupon>
                  </Content>
                  <Content width={px2vw(265)}>{formatCodeCouponType(item.type)}</Content>
                  <Content width={px2vw(200)}>{(item.benefits.cart_discount_percentage > 0 && `${item.benefits.cart_discount_percentage} %`) || `R$ ${item.benefits.cart_discount_fixed.toFixed(2)}`}</Content>
                  <Content width={px2vw(200)} alignCenter>{item.used_coupons}</Content>
                  <Content width={px2vw(200)} alignCenter>{item.usage_limit - item.used_coupons}</Content>
                  <PauseButton
                    width={px2vw(230)}
                    margleft={px2vw(10)}
                    onClick={() => {
                      if (item.boostCampaign) {
                        toast.error(
                          'Não é possível pausar um cupom que tenha uma campanha Kuppi Boost ativa.',
                          {
                            autoClose: 6000,
                          }
                        );
                      } else {
                        dispatch(
                          updateCouponStatusRequest(item.id, item.mode, !item.is_active)
                        );
                      }
                    }}
                  >
                    <ButtonBox>
                      <ButtonImage
                        active={!item.is_active}
                        src={require('../../../assets/images/pause/pause_green.png')}
                      />
                      {item.is_active ? (
                        <ButtonTextRegular greenColor>
                          Pausar Cupom
                        </ButtonTextRegular>
                      ) : (
                        <ButtonTextRegular yellowColor>
                          Despausar Cupom
                        </ButtonTextRegular>
                      )}
                    </ButtonBox>
                  </PauseButton>
                  <Content margleft={px2vw(30)} width="10%">
                    <CouponTableEditButton
                      handleEditCoupon={() => { 
                        handleEditCoupon(item);
                      }}
                      handleIncrementLimit={() => { 
                        handleIncrementLimit(item);
                      }}
                      handleDeleteCoupon={() => {
                        if (item.boostCampaign) {
                          toast.error(
                            'Não é possível excluir um cupom que tenha uma campanha Kuppi Boost ativa.',
                            {
                              autoClose: 6000,
                            }
                          );
                        } else {
                          handleDeleteCoupon(item)
                        }
                      }}
                    />
                  </Content>
                </TableRow>
            ) : null
          )
        ) : (
          <Col height="20vh">
            <Row alignCenter justifyCenter>
              {/* TODO: Ajustar tamanho do icone */}
              <Icon large>sentiment_very_dissatisfied</Icon>
              <ErrorText>Você não tem cupons a serem exibidos.</ErrorText>
            </Row>
          </Col>
        )}
        {structure && (
          <Row alignCenter justifyEnd>
            <Row alignCenter justifyEnd height={px2vw(40)} margin="10px">
              <TablePerPage response={handlePerPage} length={rows.length} />
              <Row alignCenter justifyCenter width="10%">
                {!structure[currentPage] && handleChangePage('prev')}
                {structure[currentPage] && structure[currentPage].start} -{' '}
                {structure[currentPage] && structure[currentPage].end} de{' '}
                {rows.length}
              </Row>
              <PagButton
                onClick={() => handleChangePage('prev')}
                disabled={currentPage === firstPage}
                title="Página anterior"
              >
                <PrevIcon disabled={currentPage === firstPage} />
              </PagButton>
              <PagButton
                onClick={() => handleChangePage('next')}
                title="Próxima página"
                disabled={currentPage === lastPage}
              >
                <NextIcon disabled={currentPage === lastPage} />
              </PagButton>
            </Row>
          </Row>
        )}
      </Col>
    </>
  );
}
