import produce from 'immer';

const storeDataObj = {
  storeCPFCNPJ: '',
  representativeFullName: '',
  representativeCPF: '',
  representativeBirthdate: '',
  accountHolderType: '',
  bank: '',
  agency: '',
  accountFullName: '',
  accountNumber: '',
  accountDigitNumber: '',
  accountType: '',
  status: '',
};

const INITIAL_STATE = {
  isFirstAccess: false,
  bankList: [],
  storeData: storeDataObj,
  storeDataLoading: false,
  storeDataSuccess: false,
  createSellerLoading: false,
  createSellerError: false,
  updateSellerInfoError: false,
  updateBankInfoLoading: false,
  updateBankInfoError: false,
  transfersData: null,
  transfersLoading: false,
  transfersSuccess: false,
  transfersError: false,
  transferTransactions: [],
  transferTransactionsLoading: false,
  transferTransactionsSuccess: false,
  transferTransactionsError: false,
};

export default function financial(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@financial/GET_FINANCIAL_STATUS_REQUEST': {
        draft.storeDataLoading = true;
        draft.storeDataSuccess = false;
        break;
      }
      case '@financial/GET_FINANCIAL_STATUS_SUCCESS': {
        draft.storeDataLoading = false;
        if (action.payload.account_cpf_cnpj) {
          draft.storeData = {
            storeCPFCNPJ: action.payload.account_cpf_cnpj,
            representativeFullName: action.payload.representative_full_name,
            representativeCPF: action.payload.representative_cpf,
            representativeBirthdate: action.payload.representative_birthdate,
            accountFullName: action.payload.account_holder_name,
            accountHolderType: action.payload.account_holder_type,
            bank: action.payload.bank_id,
            agency: action.payload.agency,
            accountNumber: action.payload.account,
            accountDigitNumber: action.payload.account_digit,
            accountType: action.payload.account_type,
            status: action.payload.status,
          };
          draft.isFirstAccess = false;
        } else {
          draft.storeData = {...storeDataObj, representativeCPF: action.payload.representative_cpf}
          draft.isFirstAccess = true;
        }
        draft.storeDataSuccess = true;
        break;
      }
      case '@financial/GET_FINANCIAL_STATUS_FAILURE': {
        draft.storeDataLoading = false;
        draft.storeDataSuccess = false;
        draft.storeData = storeDataObj;
        break;
      }
      case '@financial/CREATE_SELLER_REQUEST': {
        draft.createSellerLoading = true;
        break;
      }
      case '@financial/CREATE_SELLER_SUCCESS': {
        draft.createSellerLoading = false;
        draft.storeDataSuccess = true;
        draft.isFirstAccess = false;
        draft.createSellerError = false;
        break;
      }
      case '@financial/CREATE_SELLER_FAILURE': {
        draft.createSellerLoading = false;
        draft.createSellerError = true;
        break;
      }
      case '@financial/UPDATE_SELLER_INFO_REQUEST': {
        draft.updateBankInfoLoading = true;
        draft.updateSellerInfoError = false;
        break;
      }
      case '@financial/UPDATE_FINANCIAL_RECORDS_FAILURE': {
        draft.updateBankInfoLoading = false;
        draft.updateSellerInfoError = true;
        break;
      }
      case '@financial/UPDATE_FINANCIAL_RECORDS_SUCCESS': {
        draft.updateBankInfoLoading = false;
        draft.updateSellerInfoError = false;
        break;
      }
      case '@financial/UPDATE_SELLER_BANK_REQUEST': {
        draft.updateBankInfoError = false;
        break;
      }
      case '@financial/UPDATE_SELLER_BANK_FAILURE': {
        draft.updateBankInfoError = true;
        break;
      }
      case '@financial/UPDATE_SELLER_BANK_SUCCESS': {
        draft.updateBankInfoError = false;
        break;
      }
      case '@financial/CLEAR_TRANSFERS_DATA': {
        draft.transfersLoading = false;
        draft.transfersSuccess = false;
        draft.transfersError = false;
        draft.transfersData = null;
        draft.transferTransactionsLoading = false;
        draft.transferTransactionsSuccess = false;
        draft.transferTransactionsError = false;
        draft.transferTransactions = [];
        break;
      }
      case '@financial/GET_FINANCIAL_TRANSFERS_REQUEST': {
        draft.transfersLoading = true;
        draft.transfersSuccess = false;
        draft.transfersError = false;
        draft.transfersData = null;
        break;
      }
      case '@financial/GET_FINANCIAL_TRANSFERS_SUCCESS': {
        draft.transfersData = action.payload.transfers;
        draft.transfersLoading = false;
        draft.transfersSuccess = true;
        break;
      }
      case '@financial/GET_FINANCIAL_TRANSFERS_FAILURE': {
        draft.transfersLoading = false;
        draft.transfersError = true;
        break;
      }
      case '@financial/GET_FINANCIAL_TRANSFERS_TRANSACTIONS_REQUEST': {
        draft.transferTransactionsLoading = true;
        draft.transferTransactionsSuccess = false;
        draft.transferTransactionsError = false;
        draft.transferTransactions = [];
        break;
      }
      case '@financial/GET_FINANCIAL_TRANSFERS_TRANSACTIONS_SUCCESS': {
        draft.transferTransactions = action.payload.transactions;
        draft.transferTransactionsLoading = false;
        draft.transferTransactionsSuccess = true;
        break;
      }
      case '@financial/GET_FINANCIAL_TRANSFERS_TRANSACTIONS_FAILURE': {
        draft.transferTransactionsLoading = false;
        draft.transferTransactionsError = true;
        break;
      }
      case '@financial/UPDATE_STORE_DATA_FIELD': {
        draft.storeData[action.payload.payload.field] =
          action.payload.payload.value;
        break;
      }
      case '@financial/SET_BANK_LIST': {
        const banks = action.payload.map((bank) => ({
          id: bank.id,
          title: `${bank.code} - ${bank.name}`,
        }));

        draft.bankList = banks;
      }
      case '@financial/CLEAR_REQUEST_ERRORS': {
        draft.updateSellerInfoError = false;
        draft.updateBankInfoError = false;
        break;
      }
      default:
    }
  });
}
