import styled from 'styled-components';
import { statusConfig } from './status';

export const Ball = styled.div`
  height: 13px;
  width: 13px;
  border-radius: 10px;
  background: ${(props) =>
    props.situation && statusConfig[props.situation].color};

  ${(props) =>
    props.big &&
    `
    height: 25px;
    width: 25px;
    border-radius: 20px;
  `};
`;

export const Text = styled.div`
  margin-left: 5px;
  font-family: AvenirNextLTPro;
  font-weight: normal;
  font-size: 14px;
  color: ${(props) => props.situation && statusConfig[props.situation].color};

  font-size: ${(props) => props.fontSize};

  ${(props) =>
    props.big &&
    `font-size: 22px; font-family: AvenirNextLTPro;
font-weight: 700;`};
`;
