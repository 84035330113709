import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateStoreDataField } from '../../../../store/modules/financial/actions';
import { LoadingBox, SwitchInput } from '../../../_layouts/styles/common';
import {
  SaveButton,
  SaveButtonText,
} from '../../styles';
import {
  ConfigGroupTitle,
  SwitchLabelBoldText,
} from '../../../MyStore/styles';
import { Col, Row } from '../../../../styles/grid';
import { onlyNumbers } from '../../../../utils/utils';
import { cpfMask } from '../../../../utils/masks';
import px2em from '../../../../utils/responsiveness/px2em';
import px2vw from '../../../../utils/responsiveness/px2vw';
import { preto } from '../../../../styles/colors';
import { Spinner } from 'reactstrap';
import moment from 'moment';
import ButtonSecondary from '../../../../components/ButtonSecondary';

export const OwnerData = ({
  isCNPJ,
  data,
  isSellerEditable,
  onCancel = () => {},
  onSave = () => {},
}) => {
  const { updateBankInfoLoading, isFirstAccess } = useSelector(
    (state) => state.financial
  );
  const dispatch = useDispatch();

  const isEditable = useMemo(() => isSellerEditable, [isSellerEditable]);

  return (
    <>
      <Col justifyStart spaceBetween margbottom={px2vw(15)}>
        <ConfigGroupTitle>Dados do Proprietário</ConfigGroupTitle>
        <ConfigGroupTitle isSubtitle>
          Cadastre os dados do Proprietário
        </ConfigGroupTitle>
      </Col>

      <Row justifyStart>
        <Col justifyStart width="75%">
          <SwitchLabelBoldText marginBottom={px2vw(3)} marginLeft={px2em(4)}>
            Nome Completo
          </SwitchLabelBoldText>
          {isEditable || isFirstAccess ? (
            <SwitchInput
              height={px2vw(40)}
              width="100%"
              name="representativeFullName"
              placeholder={
                'Informe o nome completo associado ao CPF, sem abreviações conforme consta na Receita Federal'
              }
              value={data.representativeFullName}
              onChange={(t) =>
                dispatch(
                  updateStoreDataField({
                    field: 'representativeFullName',
                    value: t.target.value,
                  })
                )
              }
            />
          ) : (
            <SwitchLabelBoldText
              marginLeft={px2em(4)}
              marginBottom={px2vw(3)}
              noBold
            >
              {data.representativeFullName}
            </SwitchLabelBoldText>
          )}
        </Col>

        <Col justifyStart margleft={px2vw(10)} width={'20%'}>
          <SwitchLabelBoldText marginLeft={px2em(1)} marginBottom={px2vw(3)}>
            CPF
          </SwitchLabelBoldText>
          {isCNPJ && (isEditable || isFirstAccess) ? (
            <SwitchInput
              height={px2vw(40)}
              width="100%"
              name="representativeCPF"
              placeholder=""
              maxLength="14"
              value={cpfMask(onlyNumbers(data.representativeCPF))}
              onChange={(t) =>
                dispatch(
                  updateStoreDataField({
                    field: 'representativeCPF',
                    value: onlyNumbers(t.target.value),
                  })
                )
              }
            />
          ) : (
            <SwitchLabelBoldText
              marginLeft={px2em(4)}
              marginBottom={px2vw(3)}
              noBold
            >
              {cpfMask(onlyNumbers(data.representativeCPF))}
            </SwitchLabelBoldText>
          )}
        </Col>
        <Col justifyStart margleft={px2vw(10)} width={'20%'}>
          <SwitchLabelBoldText marginLeft={px2em(4)} marginBottom={px2vw(3)}>
            Data de Nascimento
          </SwitchLabelBoldText>
          {isEditable || isFirstAccess ? (
            <SwitchInput
              height={px2vw(40)}
              width="100%"
              name="representativeBirthdate"
              placeholder=""
              type="date"
              value={moment(data.representativeBirthdate).utc().format('yyyy-MM-DD')}
              onFocus={(t) => {
                dispatch(
                  updateStoreDataField({
                    field: 'representativeBirthdate',
                    value: '',
                  })
                );
              }}
              onChange={(t) => {
                dispatch(
                  updateStoreDataField({
                    field: 'representativeBirthdate',
                    value: t.target.value,
                  })
                );
              }}
            />
          ) : (
            <SwitchLabelBoldText
              marginLeft={px2em(4)}
              marginBottom={px2vw(3)}
              noBold
            >
              {moment(data.representativeBirthdate).utc().format(
                'DD/MM/yyyy'
              )}
            </SwitchLabelBoldText>
          )}
        </Col>
      </Row>
      {!isFirstAccess && (
        <Row alignEnd justifyEnd margtop={px2vw(15)}>
          {!isFirstAccess && isEditable && (
            <ButtonSecondary style={{ marginRight: 10 }} onClick={onCancel}>
              Cancelar
            </ButtonSecondary>
          )}
          <SaveButton
            onClick={onSave}
            financialPageWidth={!isFirstAccess}
          >
            {updateBankInfoLoading ? (
              <LoadingBox>
                <Spinner
                  style={{
                    width: '1.8rem',
                    height: '1.8rem',
                    color: preto,
                  }}
                />{' '}
              </LoadingBox>
            ) : (
              <SaveButtonText>
                {isEditable ? `Salvar` : `Alterar Dados`}
              </SaveButtonText>
            )}
          </SaveButton>
        </Row>
      )}
    </>
  );
};
