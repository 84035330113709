import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Progress } from 'reactstrap';

import { preto, verdeKuppi2, verdeKuppi5 } from '../../../../styles/colors';
import { Text } from '../../../../styles/common';
import { Col, Row } from '../../../../styles/grid';
import {
  CloseButton,
  CloseImage,
  ModalHeader,
  ProgressBar,
  Container,
  StepImg,
  Section,
  ArrowImg,
  SwitchInput,
  ButtonPrimary,
  Ellipse,
  ArrowButton,
} from './styles';

import px2vw from '../../../../utils/responsiveness/px2vw';
import { onlyNumbers } from '../../../../utils/utils';

import {
  setCurrentStep,
  setFacebookId,
} from '../../../../store/modules/kuppiBoost/actions';

export default function FacebookId(props) {
  const kuppiBoostData = useSelector((state) => state.kuppiBoost);
  const { facebook_id } = kuppiBoostData;
  const [facebookIdData, setFacebookIdData] = useState(facebook_id ? facebook_id : '');
  const dispatch = useDispatch();

  const handleGoToFacebook = () => {
    window.open('https://business.facebook.com/settings', '_blank');
  };

  const handleSetFacebookId = (id) => {
    dispatch(setCurrentStep('2'));
    dispatch(setFacebookId(id));
    toast.success(`Os dados foram salvos com sucesso`, {
      autoClose: 2000,
    });
  };

  return (
    <>
      <ModalHeader>
      Configuração Inicial do Kuppi Boost
        <CloseButton onClick={props.onClose}>
          <CloseImage
            src={require('../../../../assets/images/close/close.png')}
          />
        </CloseButton>
      </ModalHeader>
      <ProgressBar>
        <Progress
          value={0}
          bar={true}
          style={{
            backgroundColor: verdeKuppi5,
            borderRaduis: '0px',
            height: '7px',
          }}
        />
      </ProgressBar>
      <Section>
        <Col width={px2vw(55)} height="100%" relative></Col>
        <Container>
          <Text
            color={preto}
            size={px2vw(14)}
            margtop={px2vw(15)}
            textalign="left"
            justifyStart
          >
            <div>
              1. Acesse{' '}
              <span
                style={{
                  textDecoration: 'underline',
                  color: verdeKuppi2,
                  cursor: 'pointer',
                }}
                onClick={handleGoToFacebook}
              >
                business.facebook.com/settings
              </span>{' '}
              e clique no seu Gerenciador de Negócios
            </div>
          </Text>
          <Text
            color={preto}
            size={px2vw(14)}
            textalign="left"
            justifyStart
          >
            2. Clique em Páginas
          </Text>
          <Text
            color={preto}
            size={px2vw(14)}
            textalign="left"
            justifyStart
          >
            3. Selecione a página do seu Delivery e clique "no ID da sua página" para copiá-lo
          </Text>
          <Text
            color={preto}
            size={px2vw(14)}
            margbottom={px2vw(20)}
            textalign="left"
            justifyStart
          >
            4. Insira o ID no campo e clique em "Salvar"
          </Text>
          <Row height="auto">
            <StepImg
              src={require('../../../../assets/images/kuppi_boost/onboarding_1.png')}
            />
          </Row>
          <Text color={preto} size={px2vw(16)} textalign="left" justifyStart margtop={px2vw(-20)}>
            Insira o ID da sua Página do Facebook aqui:
          </Text>
          <Row height="auto" justifyStart margtop={px2vw(10)}>
            <SwitchInput
              value={facebookIdData}
              onChange={(e) => setFacebookIdData(onlyNumbers(e.target.value))}
              radius={px2vw(3)}
              maxLength="20"
              name="facebook_id"
              width="100%"
            />
            <ButtonPrimary
              complete={facebookIdData.length >= 15}
              disabled={facebookIdData.length < 15}
              onClick={() => handleSetFacebookId(facebookIdData)}
              width={px2vw(280)}
            >
              Salvar
            </ButtonPrimary>
          </Row>
        </Container>
        <Col width={px2vw(55)} height="100%" relative>
          <ArrowButton 
            disabled={facebookIdData.length < 15}
            onClick={(e) => handleSetFacebookId(facebookIdData)}
          >
            <ArrowImg
              src={require('../../../../assets/images/arrows/arrow_right_ellipse.png')}
            />
          </ArrowButton>
        </Col>
      </Section>
      <Row
        height="auto"
        alignEnd
        relative
        margbottom={px2vw(10)}
        pad={px2vw(7)}
      >
        <Ellipse selected />
        <Ellipse />
        <Ellipse />
        <Ellipse />
      </Row>
    </>
  );
}
