import React, { useState } from 'react';

import { Row, Col } from '../../../../styles/grid';
import { branco, cinzaKuppi1 } from '../../../../styles/colors';
import {
  Card,
  Image,
  NormalText,
} from '../../BaseScreen/styles';

import WelcomeScreenCard from '../../../../components/WelcomeScreenCard';
import RecommendDesktop from './RecommendDesktop';
import { MainText, SecondaryText, step1BtnText, step1HeaderText, step1TitleText, step1TimerAmountText, step1SubText, step2BtnText, step2HeaderText, step2TitleText, step2TimerAmountText, step2SubText, step3BtnText, step3HeaderText, step3TitleText, step3TimerAmountText, step3SubText, step4BtnText, step4HeaderText, step4TitleText, step4TimerAmountText, step4SubText, step5BtnText, step5HeaderText, step5TitleText, step5TimerAmountText, step5SubText, UserNameText } from '../constants';

import px2vw from '../../../../utils/responsiveness/px2vw';

export default function WelcomeScreenMobile({ handler }) {
  const configImg = require('../../../../assets/images/onboarding/cards/config@2x.png');
  const storeConfigsImg = require('../../../../assets/images/onboarding/cards/deliveryParameters@2x.png');
  const catalogConfigsImg = require('../../../../assets/images/onboarding/cards/catalog@2x.png');
  const printConfigsImg = require('../../../../assets/images/onboarding/cards/print@2x.png');
  const advertiseImg = require('../../../../assets/images/onboarding/cards/advertise@2x.png');
  const cardWidth = 1200;
  const [showRecommendDesktopPage, setShowRecommendDesktopPage] = useState(true);
  const borderRadius = `${px2vw(60)} ${px2vw(60)} ${px2vw(60)} ${px2vw(60)}`;

  const recommendDesktopHandler = {
    setShowRecommendDesktopPage: (val) => setShowRecommendDesktopPage(val),
  }

  return (
    <Col alignCenter background={showRecommendDesktopPage ? branco : cinzaKuppi1} margbottom={px2vw(600)} height="100%">
      <Row
        alignCenter spaceBetween
        height={px2vw(220)} padleft={px2vw(60)} padright={px2vw(60)}
        background={branco} margbottom={px2vw(100)}
      >
        <Image
          width={px2vw(170)}
          height={px2vw(170)}
          src={require('../../../../assets/images/logo_kuppi/kuppi_logo@2x.png')}
        />
        <NormalText
          fontweight='bold'
          padright={px2vw(120)}
          padleft={px2vw(120)}
          fontsize={px2vw(60)}
        >
          {MainText}
        </NormalText>
        <Image
          width={px2vw(180)}
          height={px2vw(150)}
          alt="Logout"
          src={require('../../../../assets/images/onboarding/logout_mobile2@2x.png')}
          cursor="pointer"
          onClick={handler.handleLogout}
        />
      </Row>

      {showRecommendDesktopPage ? (
        <RecommendDesktop handler={recommendDesktopHandler} />
      ) : (
        <>
          <Col alignCenter margbottom={px2vw(100)}>
            <Card
              width={cardWidth}
              height='auto'
              borderRadius={borderRadius}
            >
              <NormalText
                padright={px2vw(70)}
                padleft={px2vw(70)}
                fontsize={px2vw(80)}
                fontweight='bold'
                padtop={px2vw(70)}
              >
                {UserNameText.replaceAll('@@name@@', handler.user.advertiserInfo.name)}  
              </NormalText>
              <NormalText
                textalign='start'
                padright={px2vw(70)}
                padleft={px2vw(70)}
                fontsize={px2vw(60)}
                padtop={px2vw(70)}
              >
                {SecondaryText}
              </NormalText>
            </Card>
          </Col>
          <WelcomeScreenCard
            isMobile
            headerStep={step1HeaderText}
            headerTimerAmount={step1TimerAmountText}
            mainImg={configImg}
            stepTitle={step1TitleText}
            stepSubText={step1SubText}
            stepBtnText={step1BtnText}
            onClick={handler.handleStartButtonClick}
            loading={handler.updateOnboardingStepLoading}
          />
          <WelcomeScreenCard
            isMobile
            opacity
            headerStep={step2HeaderText}
            headerTimerAmount={step2TimerAmountText}
            mainImg={storeConfigsImg}
            stepTitle={step2TitleText}
            stepSubText={step2SubText}
            stepBtnText={step2BtnText}
          />
          <WelcomeScreenCard
            isMobile
            opacity
            headerStep={step3HeaderText}
            headerTimerAmount={step3TimerAmountText}
            mainImg={catalogConfigsImg}
            stepTitle={step3TitleText}
            stepSubText={step3SubText}
            stepBtnText={step3BtnText}
          />
          <WelcomeScreenCard
            isMobile
            opacity
            headerStep={step4HeaderText}
            headerTimerAmount={step4TimerAmountText}
            mainImg={printConfigsImg}
            stepTitle={step4TitleText}
            stepSubText={step4SubText}
            stepBtnText={step4BtnText}
          />
          <WelcomeScreenCard
            isMobile
            opacity
            headerStep={step5HeaderText}
            headerTimerAmount={step5TimerAmountText}
            mainImg={advertiseImg}
            stepTitle={step5TitleText}
            stepSubText={step5SubText}
            stepBtnText={step5BtnText}
          />
        </>
      )}
    </Col>
  );
}
