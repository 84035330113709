import React, { useEffect, useState } from "react";
import { FormControlLabel } from "@material-ui/core";

import {
  NormalText,
  Image,
  ExpansiveCol,
} from '../../../BaseScreen/styles';
import { Row, Col } from '../../../../../styles/grid';
import { branco, cinzaKuppi1, preto, cinzaKuppi9 } from '../../../../../styles/colors';
import { ModalBackground, SwitchController, SwitchWithInput } from "../../../Step2-DeliveryParams/Mobile/styles";

import px2vw from '../../../../../utils/responsiveness/px2vw';

import Complement from './Complement'

export default function ProductComplementsModal({ handler }) {
  const [currProductComplCategories, setCurrProductComplCategories] = useState(handler.currProductComplements);
  const [opennedComplCategory, setOpennedComplCategory] = useState(-1); 
  const [opennedComplCategoryArrIdx, setOpennedComplCategoryArrIdx] = useState(-1); 
  const [currEditComplOptionId, setCurrEditComplOptionId] = useState(null);
  const [currEditComplOptionArrIdx, setCurrEditComplOptionArrIdx] = useState(-1);

  useEffect(() => {
    if (handler.updateComplCategoryStatusSuccess) {
      handler.handleComplClearSuccess();
      const currCategories = JSON.parse(JSON.stringify(currProductComplCategories));
      currCategories[opennedComplCategoryArrIdx].is_active = !currCategories[opennedComplCategoryArrIdx].is_active;
      setCurrProductComplCategories(currCategories);
    }
  }, [handler.updateComplCategoryStatusSuccess]);

  useEffect(() => {
    if (handler.updateComplOptionStatusSuccess) {
      handler.handleComplClearSuccess();
      const currCategories = JSON.parse(JSON.stringify(currProductComplCategories));
      currCategories[opennedComplCategoryArrIdx].productComplementOptions[currEditComplOptionArrIdx].is_active = !currCategories[opennedComplCategoryArrIdx].productComplementOptions[currEditComplOptionArrIdx].is_active;
      setCurrProductComplCategories(currCategories);
    }
  }, [handler.updateComplOptionStatusSuccess]);

  function handleComplCategoryClick(complCategoryId, idx) {
    setOpennedComplCategory(opennedComplCategory === complCategoryId ? -1 : complCategoryId);
    setOpennedComplCategoryArrIdx(opennedComplCategory === complCategoryId ? -1 : idx);
    clearCurrEditComplOptionData();
  }

  function clearCurrEditComplOptionData() {
    setCurrEditComplOptionId(null);
    setCurrEditComplOptionArrIdx(-1);
  }

  const complOptionHandler = {
    ...handler,
    currProductComplCategories: currProductComplCategories,
    opennedComplCategoryArrIdx: opennedComplCategoryArrIdx,
    currEditComplOptionId: currEditComplOptionId,
    currEditComplOptionArrIdx: currEditComplOptionArrIdx,
    setCurrEditComplOptionId: (val) => setCurrEditComplOptionId(val),
    setCurrEditComplOptionArrIdx: (val) => setCurrEditComplOptionArrIdx(val),
    setCurrProductComplCategories: (val) => setCurrProductComplCategories(val),
    clearCurrEditComplOptionData: () => clearCurrEditComplOptionData(),
  }

  return (
    <ModalBackground 
      isVisible={true}
    >
      <Col 
        overflow
        background={branco} 
        borderRadius={px2vw(20)} 
        justifyStart
        padbottom={px2vw(300)}
      >
        <Row padbottom={px2vw(100)} justifyStart height="auto" pad={px2vw(60)}>
          <Image src={require('../../../../../assets/images/onboarding/closeSubMenu@2x.png')}
            width={px2vw(150)}
            height={px2vw(150)}
            cursor="pointer"
            onClick={() => { handler.setComplementsModalState(false) }} />
          <NormalText
            fontweight='bold'
            textalign='flex-start'
            color={preto}
            fontsize={px2vw(80)}
            padtop={px2vw(10)}
            padleft={px2vw(140)}>
            Complementos
          </NormalText>
        </Row>
        {currProductComplCategories.map((complCategory, idx) => {
          return (
            <Col height='auto'
              borderbottom={`2px solid ${cinzaKuppi9}`}
            >
              <Row background={cinzaKuppi1} padtop={px2vw(20)}>
                <Row
                  padleft={px2vw(100)}
                  justifyStart
                  onClick={() => handleComplCategoryClick(complCategory.id, idx)}
                  cursor="pointer"
                >
                  <Row width={px2vw(100)} margtop={px2vw(60)} margright={px2vw(100)}>
                    <Image
                      src={opennedComplCategory === complCategory.id ? require('../../../../../assets/images/arrows/arrow_top@2x.png') : require('../../../../../assets/images/arrows/arrow_under@2x.png')}
                      width={px2vw(85)}
                      height={px2vw(40)}
                    />
                  </Row>
                  <NormalText
                    textalign='flex-start'
                    margtop={px2vw(40)}
                    fontweight="bold"
                    color={preto}
                    fontsize={px2vw(55)}
                    width="100%"
                  >
                    {complCategory.name}
                  </NormalText>
                </Row>
                <Row justifyEnd width="auto">
                  <SwitchWithInput
                    key={'pause-compl-category'}
                    marginBottom={px2vw(20)}
                    margtop={px2vw(30)}
                  >
                    <FormControlLabel
                      control={
                        <SwitchController
                          checked={complCategory.is_active}
                          type="checkbox"
                          onChange={() => { 
                            setOpennedComplCategoryArrIdx(idx);
                            handler.handlePauseComplCategory(complCategory);
                          }}
                        />
                      }
                    />
                  </SwitchWithInput>
                </Row>
              </Row>
              <ExpansiveCol isActive={opennedComplCategory === complCategory.id}>
                {complCategory.productComplementOptions.map((complOption, idx) => {
                  return (
                    <Complement complement={complOption} handler={complOptionHandler} idx={idx}/>
                  );
                })}
              </ExpansiveCol>
            </Col>
          );
        })}
      </Col>
    </ModalBackground>
  )
}