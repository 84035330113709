import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { BsInfoCircleFill } from 'react-icons/bs';
import { Progress } from 'reactstrap';
import moment from 'moment';
import 'moment/locale/pt-br';
import { buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import './custom.css';

import {
  AdvertiserDataBox,
  Hr,
  ProgressContainer,
  ButtonSecondary,
  TrophyCircle,
  Circle,
  ProgressBarStyle,
  CircleProgressBar,
} from '../styles';
import { Col, Row } from '../../../styles/grid';
import { Text } from '../../../styles/common';
import {
  cinzaKuppi7,
  preto2,
  verdeKuppi1,
  verdeKuppi24,
  verdeKuppi5,
} from '../../../styles/colors';

import px2vw from '../../../utils/responsiveness/px2vw';

import MedalsInfoModal from './MedalsInfoModal';
import HistoryModal from './HistoryModal';
import { getURL } from '../../../utils/utils';

export default function Gamefication() {
  const user = useSelector((state) => state.user.user);
  const [isMedalInfoModalOpenned, setIsMedalInfoModalOpenned] = useState(false);
  const [isHistoryModalOpenned, setIsHistoryModalOpenned] = useState(false);

  const { gamefication } = useSelector((state) => state.dashboardMetrics);
  moment.locale('pt-br');
  const inputDate = new Date();
  const currentMonthInPortuguese =
    moment(inputDate).format('MMMM').charAt(0).toUpperCase() +
    moment(inputDate).format('MMMM').slice(1).toLowerCase();

  function getPercent(x, y) {
    return (x / y) * 100;
  }

  const totalOrders = gamefication.month_order_qty;
  const monthlyGoal = gamefication.target;
  const monthlyGoalPercent = Math.round((totalOrders / monthlyGoal) * 100);
  const goal1 = Math.floor((monthlyGoal / 100) * 25);
  const goal2 = Math.floor((monthlyGoal / 100) * 50);
  const goal3 = Math.floor((monthlyGoal / 100) * 75);

  const gameficationPercent = useMemo(() => {
    let loader1Percent =
      totalOrders >= goal1 ? 100 : getPercent(totalOrders, goal1);
    let loader2Percent =
      totalOrders >= goal2
        ? 100
        : totalOrders <= goal1
        ? 0
        : getPercent(totalOrders - goal1, goal2 - goal1);
    let loader3Percent =
      totalOrders >= goal3
        ? 100
        : totalOrders < goal2
        ? 0
        : getPercent(totalOrders - goal2, goal3 - goal2);
    let loader4Percent =
      totalOrders >= monthlyGoal
        ? 100
        : totalOrders < goal3
        ? 0
        : getPercent(totalOrders - goal3, monthlyGoal - goal3);

    return { loader1Percent, loader2Percent, loader3Percent, loader4Percent };
  }, [totalOrders]);

  const currentTotalTrophys = useMemo(() => {
    if (gamefication?.month_order_qty >= gamefication?.target) {
      return gamefication?.total_trophys + 4
    }

    if (gamefication?.month_order_qty >= goal3) {
      return gamefication?.total_trophys + 3
    }

    if (gamefication?.month_order_qty >= goal2) {
      return gamefication?.total_trophys + 2
    }

    if (gamefication?.month_order_qty >= goal1) {
      return gamefication?.total_trophys + 1
    }

    return gamefication?.total_trophys
  }, [gamefication?.month_order_qty, gamefication?.target, gamefication?.total_trophys, goal1, goal2, goal3]);
  
  const concludeTargetText = useMemo(() => {
    const missingOrders = monthlyGoal - totalOrders;

    if (totalOrders >= monthlyGoal) {
      return 'Parabéns, você atingiu a meta de pedidos este mês!';
    }
    
    if (missingOrders > 1) {
      return `Faltam ${missingOrders} pedidos para atingir a meta deste mês`;
    }

    return `Falta ${missingOrders} pedido para atingir a meta deste mês`;
  }, [totalOrders, monthlyGoal]);
  
  function copyWebCatalogLink() {
    const copyArea = document.createElement('textarea');
    document.body.appendChild(copyArea);
    copyArea.value = getURL('cardapio_web').replace(
      '$$advertiser_slug$$',
      user.advertiserInfo.slug
    );
    copyArea.select();
    document.execCommand('copy');
    document.body.removeChild(copyArea);

    toast.info('O link da sua loja foi copiado com sucesso!');
  }

  function openWebCatalogLink() {
    window.open(
      getURL('cardapio_web').replace(
        '$$advertiser_slug$$',
        user.advertiserInfo.slug
      ),
      '_blank',
      'width=400,height=700'
    );
  }

  return (
    <>
      <Row spaceBetween width="100%" height="auto">
        <AdvertiserDataBox height={px2vw(210)} width={px2vw(375)}>
          <Text
            title={user.advertiserInfo.name}
            margtop={px2vw(12)}
            size={px2vw(17)}
          >
            {user.advertiserInfo.name}
          </Text>
          <Row height="auto" width="100%">
            <Text
              regular
              underline
              pointer
              size={px2vw(10)}
              onClick={() => openWebCatalogLink()}
            >
              Visualizar o cardápio
            </Text>
            <Text
              margleft={px2vw(40)}
              regular
              underline
              pointer
              size={px2vw(10)}
              onClick={() => copyWebCatalogLink()}
            >
              Copiar o link do cardápio
            </Text>
          </Row>
          <Row height="auto" padding={px2vw(8)} margtop={px2vw(15)}>
            <Col relative height="auto" width="52%">
              <Col alignCenter relative width="100%" height="auto">
                <img
                  style={{
                    width: px2vw(70),
                    height: px2vw(70),
                    borderRadius: '50%',
                    objectFit: 'cover'
                  }}
                  className="mx-auto"
                  src={`${user.avatar.url}?nocache=${new Date().getTime()}`}
                />
                <img
                  style={{
                    width: px2vw(38),
                    position: 'absolute',
                    bottom: px2vw(-5),
                    left: px2vw(13),
                  }}
                  src={require(`../../../assets/images/gamification_medals/${
                    gamefication.level_slug
                  }.png`)}
                />
                <Text margtop={px2vw(7)} weight="bolder" size={px2vw(17)}>
                  {gamefication.level}
                </Text>
              </Col>
              <Row
                margtop={px2vw(10)}
                pad={`0 ${px2vw(23)}`}
                justifyStart
                alignCenter
              >
                <img
                  style={{ width: px2vw(18) }}
                  src={require('../../../assets/images/gamification_medals/trophy.png')}
                />
                <Text
                  margleft={px2vw(4)}
                  margtop={px2vw(2)}
                  color={cinzaKuppi7}
                  regular
                  size={px2vw(10)}
                  height="auto"
                >
                  {currentTotalTrophys} Troféu
                  {currentTotalTrophys == 1 ? '' : 's'} conquistado
                  {currentTotalTrophys == 1 ? '' : 's'}
                </Text>
              </Row>
            </Col>
            <ProgressContainer
              padright={px2vw(25)}
              relative
              alignCenter
              height="auto"
              width="48%"
            >
              <BsInfoCircleFill
                style={{
                  right: px2vw(20),
                  top: px2vw(-1),
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setIsMedalInfoModalOpenned(true);
                }}
                className="position-absolute ml-auto"
                size={px2vw(18)}
              />
              <Row height="auto" width="72%">
                <CircleProgressBar
                  value={monthlyGoalPercent > 100 ? 100 : monthlyGoalPercent}
                  text={`${
                    monthlyGoalPercent > 100 ? 100 : monthlyGoalPercent
                  }%`}
                  strokeWidth={12}
                  circleRatio={0.5}
                  styles={buildStyles({
                    pathColor: verdeKuppi5,
                    rotation: 0.75,
                    strokeLinecap: 'round',
                    trailColor: '#eee',
                    textSize: px2vw(15),
                    textColor: cinzaKuppi7,
                  })}
                />
              </Row>
              <Text
                className="position-absolute"
                style={{ top: px2vw(80) }}
                margleft={px2vw(4)}
                margtop={px2vw(2)}
                color={cinzaKuppi7}
                regular
                size={px2vw(11)}
                height="auto"
              >
                {totalOrders} pedidos concluídos
              </Text>
              <Text
                className="position-absolute"
                style={{ top: px2vw(100) }}
                color={preto2}
                regular
                alignCenter
                size={px2vw(10)}
                height="auto"
              >
                {concludeTargetText}
              </Text>
            </ProgressContainer>
          </Row>
        </AdvertiserDataBox>
        <AdvertiserDataBox height={px2vw(210)} width={px2vw(630)}>
          <Row height="auto">
            <Col
              margtop={px2vw(13)}
              margleft={px2vw(15)}
              height={px2vw(40)}
              width="50%"
            >
              <Text margleft={px2vw(10)} weight="bolder" size={px2vw(17)}>
                Desafio da Kuppi
              </Text>
              <Text
                margtop={px2vw(4)}
                margleft={px2vw(10)}
                regular
                size={px2vw(12)}
              >
                Receba troféus a cada meta de pedidos batida
              </Text>
            </Col>
            <Row
              margtop={px2vw(13)}
              height={px2vw(40)}
              alignCenter
              justifyEnd
              width="50%"
              padright={px2vw(15)}
            >
              <Text
                margtop={px2vw(4)}
                margleft={px2vw(10)}
                color={verdeKuppi1}
                size={px2vw(12)}
              >
                {currentMonthInPortuguese}
              </Text>
              <ButtonSecondary onClick={() => setIsHistoryModalOpenned(true)}>
                Histórico
              </ButtonSecondary>
            </Row>
          </Row>
          <Hr />
          <Row
            margtop={px2vw(3)}
            padleft={px2vw(25)}
            justifyStart
            height="auto"
          >
            <Text
              color={verdeKuppi24}
              alignCenter
              size={px2vw(12)}
              height="auto"
            >
              Total de pedidos do mês: {totalOrders}
            </Text>
          </Row>
          <Row height={px2vw(105)} alignCenter pad={'0 ' + px2vw(25)}>
            <Circle complete={totalOrders > 0}></Circle>
            <Progress
              animated
              barStyle={ProgressBarStyle}
              style={{
                borderRadius: '0px',
                height: px2vw(9),
                width: '100%',
                marginRight: px2vw(-1),
                marginLeft: px2vw(-2),
              }}
              value={gameficationPercent.loader1Percent}
            />
            <TrophyCircle complete={totalOrders >= goal1}>
              <img
                style={{ width: px2vw(25) }}
                src={require(`../../../assets/images/gamification_medals/${
                  totalOrders < goal1 ? 'bw_' : ''
                }trophy.png`)}
              />
              <Text
                className="position-absolute"
                style={{ bottom: px2vw(-15) }}
                color={cinzaKuppi7}
                regular
                alignCenter
                size={px2vw(7.6)}
                height="auto"
              >
                {goal1} Pedidos
              </Text>
            </TrophyCircle>
            <Progress
              animated
              barStyle={ProgressBarStyle}
              style={{
                borderRadius: '0px',
                height: px2vw(9),
                width: '100%',
                marginLeft: px2vw(-1),
                marginRight: px2vw(-1),
              }}
              value={gameficationPercent.loader2Percent}
            />
            <TrophyCircle complete={totalOrders >= goal2}>
              <img
                style={{ width: px2vw(23) }}
                src={require(`../../../assets/images/gamification_medals/${
                  totalOrders < goal2 ? 'bw_' : ''
                }trophy.png`)}
              />
              <Text
                className="position-absolute"
                style={{ bottom: px2vw(-15) }}
                color={cinzaKuppi7}
                regular
                alignCenter
                size={px2vw(7.6)}
                height="auto"
              >
                {goal2} Pedidos
              </Text>
            </TrophyCircle>
            <Progress
              animated
              barStyle={ProgressBarStyle}
              style={{
                borderRadius: '0px',
                height: px2vw(9),
                width: '100%',
                marginLeft: px2vw(-1),
                marginRight: px2vw(-1),
              }}
              value={gameficationPercent.loader3Percent}
            />
            <TrophyCircle complete={totalOrders >= goal3}>
              <img
                style={{ width: px2vw(23) }}
                src={require(`../../../assets/images/gamification_medals/${
                  totalOrders < goal3 ? 'bw_' : ''
                }trophy.png`)}
              />
              <Text
                className="position-absolute"
                style={{ bottom: px2vw(-15) }}
                color={cinzaKuppi7}
                regular
                alignCenter
                size={px2vw(7.6)}
                height="auto"
              >
                {goal3} Pedidos
              </Text>
            </TrophyCircle>
            <Progress
              animated
              barStyle={ProgressBarStyle}
              style={{
                borderRadius: '0px',
                height: px2vw(9),
                width: '100%',
                marginLeft: px2vw(-1),
                marginRight: px2vw(-1),
              }}
              value={gameficationPercent.loader4Percent}
            />
            <TrophyCircle complete={totalOrders >= monthlyGoal}>
              <img
                style={{ width: px2vw(23) }}
                src={require(`../../../assets/images/gamification_medals/${
                  totalOrders < monthlyGoal ? 'bw_' : ''
                }trophy.png`)}
              />
              <Text
                className="position-absolute"
                style={{ bottom: px2vw(-15) }}
                color={cinzaKuppi7}
                regular
                alignCenter
                size={px2vw(7.6)}
                height="auto"
              >
                {monthlyGoal} Pedidos
              </Text>
            </TrophyCircle>
          </Row>
          <Row height="auto" justifyStart>
            <Text margleft={px2vw(25)} regular size={px2vw(10)} height="auto">
              A mudança de nível do seu delivery só será atualizada no início de cada mês
            </Text>
          </Row>
        </AdvertiserDataBox>
      </Row>
      {isMedalInfoModalOpenned && (
        <MedalsInfoModal
          height={px2vw(370)}
          width={px2vw(490)}
          padding={px2vw(19)}
          onClose={() => {
            setIsMedalInfoModalOpenned(false);
          }}
          levels={gamefication.levels}
          isVisible
        />
      )}
      {isHistoryModalOpenned && (
        <HistoryModal
          height={px2vw(550)}
          width={px2vw(680)}
          padding={px2vw(19)}
          onClose={() => {
            setIsHistoryModalOpenned(false);
          }}
          isVisible
        />
      )}
    </>
  );
}
