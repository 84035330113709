import React from 'react';
import { Bar } from 'react-chartjs-2';
import { ChartContainer } from './styles';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import px2vw from '../../utils/responsiveness/px2vw';
import { preto } from '../../styles/colors';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function BarChart({ chartData, chartWidth, needOptions, chartHeight}) {
  return (
    <ChartContainer width={chartWidth}>
      <Bar
        height={px2vw(chartHeight)}
        data={chartData}
        options={
          needOptions || {
            scales: {
              x: {
                stacked: true,
                ticks: {
                  color: preto,
                },
              },
              y: {
                stacked: true,
              },
              // xAxes: {
              //   ticks: {
              //     color: '#FFFFFF'
              //   }
              // },
            },
          }
        }
      />
    </ChartContainer>
  );
}
