import styled from 'styled-components';

import px2vw from '../../utils/responsiveness/px2vw';

import {
  branco,
  preto,
  cinzaKuppi3,
  verdeKuppi1,
  verdeKuppi24,
  verdeKuppi2,
  cinzaKuppi4,
} from '../../styles/colors';

export const ButtonCheckEmpty = styled.div`
  width: ${px2vw(15)};
  height: ${px2vw(15)};
  margin-right: ${px2vw(10)};
  border: ${px2vw(1)} solid ${cinzaKuppi3};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${px2vw(3)};
`;

export const ImageContainer = styled.div`
  width: ${px2vw(15)};
  height: ${px2vw(15)};
  display: none;
  margin-top: ${px2vw(-5)};

  ${(props) => props.checkVisible && `
    display: inline-block;
  `}

  ${(props) => props.showArrow && `
    display: inline-block;
    margin-right: ${px2vw(5)};
  `}
`;