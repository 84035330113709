import { takeLatest, call, put, all, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { parse } from 'date-fns';

import { apiAnunciante, apiGlobal } from '../../../services/api';

import {
  formatSubscriptions as formatSubscriptionsAction,
  getSubscriptionsSuccess,
  getSubscriptionsFailure,
  getRecurringSuccess,
  getRecurringFailure,
  getPaymentCardsSuccess,
  getPaymentCardsFailure,
  setDefaultCreditCardSuccess,
  setDefaultCreditCardFailure,
  deleteCardSuccess,
  deleteCardFailure,
  addNewCardSuccess,
  addNewCardFailure,
} from './actions';
import {
  logout
} from '../auth/actions';

const userData = (state) => state.user.user;

export function* formatSubscriptions({ payload }) {
  let isDuePayment = false;
  let hasFreeTrialValid = false;

  if (payload.subscriptions.length > 0) {
    // Verify if the user has a free trial valid
    if (
      payload.subscriptions[0].payment_type === 'free' && 
      payload.subscriptions.length === 2 && 
      ['not_paid', 'waiting_payment'].includes(payload.subscriptions[1].status)
    ) {
      hasFreeTrialValid = true;
    } 
    // Verify if the user has a delayed payment
    else {
      const lastSubscriptionData = payload.subscriptions[payload.subscriptions.length -1];
      const startDate = new Date(parse(lastSubscriptionData.start_validity_at, 'dd/MM/yyyy', new Date()).setHours(23, 59, 59));
      if (
        ['not_paid', 'waiting_payment'].includes(lastSubscriptionData.status) &&
        !lastSubscriptionData.paid_at && 
        startDate - new Date() < 0
      ) {
        isDuePayment = true;
      }
    }
  }

  yield put(getSubscriptionsSuccess(payload.subscriptions, isDuePayment, hasFreeTrialValid));
}

export function* getSubscriptionsRequest() {
  try {
    const user = yield select(userData);

    const response = yield call(
      apiAnunciante.get,
      `/user-info/${user.advertiserInfo.id}/subscriptions`
    );

    yield put(formatSubscriptionsAction(response.data));
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(getSubscriptionsFailure());
    }
  }
}

export function* getRecurringRequest() {
  try {
    const user = yield select(userData);

    const response = yield call(
      apiAnunciante.get,
      `/user-info/${user.advertiserInfo.id}/recurring`
    );

    yield put(getRecurringSuccess(response.data));
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(getRecurringFailure());
    }
  }
}

export function* getPaymentCardsRequest() {
  try {
    const user = yield select(userData);

    const response = yield call(
      apiGlobal.get,
      `/user/${user.id}/payment-card`
    );

    yield put(getPaymentCardsSuccess(response.data));
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(getPaymentCardsFailure());
    }
  }
}

export function* setDefaultCreditCardRequest({ payload }) {
  try {
    const user = yield select(userData);

    const response = yield call(
      apiGlobal.put,
      `/user/${user.id}/user-info/${user.advertiserInfo.id}/payment-card/${payload.cardId}`,
      { is_default: true }
    );

    yield put(setDefaultCreditCardSuccess(response.data));

    toast.success('Cartão marcado como padrão com sucesso.');
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      if (err?.response?.data?.error?.message) {
        toast.error(err.response.data.error.message, {
          autoClose: 6000,
        });
      } else {
        toast.error(
          'Não conseguimos marcar o cartão como padrão. Tente novamente e caso o erro persista, contate o suporte.',
          {
            autoClose: 6000,
          }
        );
      }

      yield put(setDefaultCreditCardFailure());
    }
  }
}

export function* deleteCardRequest({ payload }) {
  try {
    const user = yield select(userData);

    const response = yield call(
      apiGlobal.delete,
      `/user/${user.id}/user-info/${user.advertiserInfo.id}/payment-card/${payload.cardId}`
    );

    yield put(deleteCardSuccess(response.data));

    toast.success('Cartão excluído com sucesso.');
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      if (err?.response?.data?.error?.message) {
        toast.error(err.response.data.error.message, {
          autoClose: 6000,
        });
      } else {
        toast.error(
          'Não conseguimos excluir o cartão. Tente novamente e caso o erro persista, contate o suporte.',
          {
            autoClose: 6000,
          }
        );
      }

      yield put(deleteCardFailure());
    }
  }
}

export function* addNewCardRequest({ payload }) {
  try {
    const user = yield select(userData);

    const response = yield call(
      apiGlobal.post,
      `/user/${user.id}/user-info/${user.advertiserInfo.id}/payment-card`,
      {
        number: payload.cardNumber,
        cvv: payload.cardCVV,
        expiration_date: payload.cardValidity,
        holder_name: payload.cardName,
        holder_document: payload.cpfCnpj,
        is_default: payload.isDefault,
      }
    );

    yield put(addNewCardSuccess(response.data));

    toast.success('Cartão adicionado com sucesso.');
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      if (err?.response?.data?.error?.message) {
        toast.error(err.response.data.error.message, {
          autoClose: 6000,
        });
      } else {
        toast.error(
          'Não conseguimos adicionar o cartão. Revise os dados do cartão e tente novamente. Caso o erro persista, contate o suporte.',
          {
            autoClose: 6000,
          }
        );
      }

      yield put(addNewCardFailure());
    }
  }
}

export default all([
  takeLatest('@profileAccount/FORMAT_SUBSCRIPTIONS', formatSubscriptions),
  takeLatest(
    '@profileAccount/GET_SUBSCRIPTIONS_REQUEST',
    getSubscriptionsRequest
  ),
  takeLatest('@profileAccount/GET_RECURRING_REQUEST', getRecurringRequest),
  takeLatest(
    '@profileAccount/GET_PAYMENT_CARDS_REQUEST',
    getPaymentCardsRequest
  ),
  takeLatest(
    '@profileAccount/SET_DEFAULT_CREDIT_CARD_REQUEST',
    setDefaultCreditCardRequest
  ),
  takeLatest('@profileAccount/DELETE_CARD_REQUEST', deleteCardRequest),
  takeLatest('@profileAccount/ADD_NEW_CARD_REQUEST', addNewCardRequest),
]);
