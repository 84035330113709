import styled from 'styled-components'
import px2vw from '../../../../utils/responsiveness/px2vw';
import { cinzaKuppi4 } from '../../../../styles/colors';

export const ModalOutline = styled.div`
  z-index: 999999999999999999999999999999999998;
  position: fixed;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  background-color: #0005;
`;

export const Container = styled.div`
  display: flex;
  max-width: 25rem;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  z-index: 999999999999999999999999999999999999;
  background-color: white;
  border-color: 1px solid ${cinzaKuppi4};
  border-radius: 10px;
  padding: 1.5rem;
`;

export const Header = styled.div``;

export const Content = styled.div`
  margin-top: ${px2vw(15)};
  margin-bottom: ${px2vw(15)};
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
`;

export const HSpacer = styled.div`
  padding: 0.5rem;
`;
