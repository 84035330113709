import debounce from '../debounce/debounce';

export default function DragAndDrop(currentType, handleDragAndDrop) {
  /* VARS */
  const cards = document.querySelectorAll(
    `${currentType.id} ${currentType.classNameBox}`
  );
  const dropzones = document.querySelectorAll(`${currentType.id}`);

  let currentDragCardParent = null;

  /* CARDS */
  function cardDragStart() {
    this.classList.add('is-dragging');
  }

  function cardDragEnd() {
    this.classList.remove('is-dragging');

    const cardsArray = Array.from(dropzones[0].childNodes);
    const cardsArrayIdx = cardsArray.map((card) => {
      return card.id;
    });
    handleDragAndDrop(cardsArrayIdx);
  }

  function cardDragOver() {
    currentDragCardParent = this;
  }

  /* DROPZONES */
  function dropzoneDragEnter() {}

  function dropzoneDragOver(event) {
    event.preventDefault();
    const cardDragged = document.querySelector('.is-dragging');
    try {
      const idxParentDragged = Array.from(event.target.childNodes).indexOf(
        currentDragCardParent
      );
      const idxDragged = Array.from(event.target.childNodes).indexOf(
        cardDragged
      );

      if (idxDragged > idxParentDragged) {
        currentDragCardParent.insertAdjacentElement('beforebegin', cardDragged);
      } else {
        currentDragCardParent.insertAdjacentElement('afterend', cardDragged);
      }
    } catch (err) {}
  }

  cards.forEach((card) => {
    card.addEventListener('dragstart', cardDragStart);
    card.addEventListener('dragend', cardDragEnd);
    card.addEventListener('dragover', cardDragOver);
  });

  dropzones.forEach((dropzone) => {
    dropzone.addEventListener('dragenter', dropzoneDragEnter);
    dropzone.addEventListener('dragover', debounce(dropzoneDragOver, 50));
  });
}
