import { makeStyles } from '@material-ui/core';
import { Badge } from '@material-ui/core';

import styled, { css } from 'styled-components';

import {
  preto,
  branco,
  cinzaKuppi4,
  cinzaKuppi7,
  verdeKuppi2,
  cinzaKuppi8,
  verdeKuppi5,
  cinzaKuppi11,
} from '../../../styles/colors';

import px2vw from '../../../utils/responsiveness/px2vw';

export const Container = styled.div`
  background: ${branco};
  max-height: fit-content;
  overflow-y: auto;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .div {
    max-height: fit-content;
  }
`;

export const ModalHeader = styled.header`
  display: flex;
  justify-content: space-between;
  background-color: ${verdeKuppi2};
  align-items: center;
  width: 100%;
  letter-spacing: 0.36px;
`;

export const ManualOrderHeader = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${verdeKuppi2};
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
  border-color: ${verdeKuppi2};
  padding: ${px2vw(13)} ${px2vw(20)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  color: ${branco};
  font-size: ${px2vw(15.5)};
  display: flex;
  align-items: space-between;
  justify-content: space-between;
`;

export const ProgressBar = styled.div`
  background-color: ${cinzaKuppi11};
  width: 100%;
`;

export const CloseButton = styled.button`
  width: ${px2vw(18)};
  height: ${px2vw(18)};
  border: none;
  z-index: 10;
  background-color: transparent;

  :focus {
    background-color: transparent !important;
  }

  ${(props) =>
    props.absolute &&
    css`
      position: absolute;
      top: ${props.top || px2vw(10)};
      right: ${props.right || px2vw(10)};
    `}
`;

export const CloseImage = styled.img`
  width: ${px2vw(17)};
  height: ${px2vw(17)};
`;

export const BookIcon = styled.img`
  width: ${px2vw(29)};
  height: ${px2vw(26)};
  margin-right: ${px2vw(13)};
`;

export const BagIcon = styled.img`
  width: ${px2vw(29)};
  height: ${px2vw(29)};
`;

export const StepTittle = styled.div`
  font-family: AvenirNextLTPro;
  font-weight: 700;
  color: ${preto};
  font-size: ${px2vw(17)};
  margin-right: ${px2vw(15.5)};
  white-space: nowrap;
  letter-spacing: 0.36px;
`;

export const ProductItem = styled.div`
  width: 100%;
  display: flex;
  cursor:pointer;
  height: ${px2vw(50)};
  padding: ${px2vw(10)} ${px2vw(30)};
  border-top: 1px solid ${cinzaKuppi4};
  border-bottom: 1px solid ${cinzaKuppi4};
`;

export const ProductDescription = styled.span`
  font-family: AvenirNextLTPro;
  font-weight: 700;
  color: ${preto};
  margin-left: ${px2vw(14)};
  margin-right: auto;
  font-size: ${px2vw(14)};
  white-space: nowrap;
  letter-spacing: 0.36px;
`;

export const ProductPrice = styled.span`
  font-family: AvenirNextLTPro;
  font-weight: 700;
  margin-left: auto;
  color: ${verdeKuppi2};
  font-size: ${px2vw(14)};
  white-space: nowrap;
  letter-spacing: 0.36px;
`;

export const ProductImg = styled.img`
  width: ${px2vw(19)};
  height: ${px2vw(19)};
`;

export const ModalFooter = styled.footer`
  display: flex;
  cursor:pointer;
  padding-top: ${px2vw(5)};
  background: linear-gradient(
      231.72deg,
      #2ffe5f 7.51%,
      rgba(47, 254, 95, 0) 91.54%
    ),
    #2cfe89;
  justify-content: space-between;
  border-radius: 20px 20px 0px 0px;
  box-shadow: -0.1px -4px 2px ${cinzaKuppi8};
  align-items: center;
  margin-top: auto;
  min-height: ${px2vw(95)};
  max-height: ${px2vw(95)};
  width: 100%;
`;

export const useStyles = makeStyles({
  root: { maxHeight: '50%' },
  '@global': {
    '::-webkit-scrollbar': {
      width: '0.3em',
      borderRadius: '3px',
    },
    '::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '::-webkit-scrollbar-thumb': {
      backgroundColor: verdeKuppi2,
    },
  },
  accordSon: {
    background: branco,
    border: 'none',
    padding: '0',
    overflowY: 'scroll',
  },
  accordTittle: {
    fontFamily: 'AvenirNextLTPro',
    fontWeight: 700,
    color: verdeKuppi2,
    fontSize: px2vw(15.5),
    letterSpacing: '0.36px',
    border: 'none',
    padding: `0 ${px2vw(30)}`,
    height: px2vw(50),
  },
});

export const CustomizedBadge = styled(Badge)`
  & .MuiBadge-badge {
    background-color: ${branco};
    min-width: ${px2vw(25)};
    height: ${px2vw(25)};
    border-radius: 50%;
    font-size:${px2vw(15)};
    font-weight: bolder;
    border: ${px2vw(2)} solid ${verdeKuppi5};
    font-family: AvenirNextLTPro;
  }
`;

export const Span = styled.span`
  margin-left: ${px2vw(8)};
  text-align: ${(props) => (props.textAlign ? props.textAlign : 'initial')};
  padding: ${(props) => (props.padding ? props.padding : '0')};
  cursor: ${(props) => (props.cursor ? props.cursor : 'initial')};
  font-size: ${(props) => (props.fontSize ? props.fontSize : px2vw(14))};
  font-family: AvenirNextLTPro;
  letter-spacing: 0.36px;
  font-weight: ${(props) => (props. fontWeight ? props.fontWeight : 'bold')};
  display: ${(props) => (props.display ? props.display : 'block')};
  color: ${(props) => (props.color ? props.color : preto)};
  @media (max-width: 500px) {
    font-size: ${px2vw(20)};
  }
  .badge{
    background:${cinzaKuppi11};
    font-size: ${px2vw(13)};
  }
`;

export const inputTheme = makeStyles((theme) => ({
  inputRoot: {
    border: `1px solid ${cinzaKuppi7}`,
    width: px2vw(230),
    fontFamily: 'AvenirNextLTPro',
    borderRadius: '5px',
    '&.MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input:first-child': {
      padding: '7px',
    },
    '& input::placeholder': {
      fontSize: px2vw(13),
    },
  },
  option: {
    fontSize: px2vw(13),
    fontFamily: 'AvenirNextLTPro',
  },
  inputFocused: {},
  root: {
    '&.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot': {
      padding: 0,
    },
  },
  groupLabel: {
    fontWeight: '700',
    color: verdeKuppi2,
    borderTop: `0.5px solid ${cinzaKuppi7}`,
    borderBottom: `0.5px solid ${cinzaKuppi7}`,
    fontSize: px2vw(14),
    fontFamily: 'AvenirNextLTPro',
  },
}));
