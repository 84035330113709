import { takeLatest, call, put, all, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { apiGlobal, apiAnunciante } from '../../../services/api';

import {
  updateUserInfoSuccess,
  updateUserInfoFailure,
  updateUserAddressSuccess,
  updateUserAddressFailure,
  updateUserPasswordSuccess,
  updateUserPasswordFailure,
  getTermsAndPolicySuccess,
  getTermsAndPolicyFailure,
  updateUserImagesSuccess,
  updateUserImagesFailure,
  setIsTermsTermsAndPolicyAccepted,
  getUserSuccess,
  setIpAddress,
  getAdvertiserCategoriesSuccess,
  getAdvertiserCategoriesFailure,
} from './actions';
import {
  logout,
} from '../auth/actions';

const userData = (state) => state.user.user;

export function* updateUserInfoRequest({ payload }) {
  try {
    const user = yield select(userData);

    // Update user info data
    const userResponse = yield call(
      apiAnunciante.put,
      `/user/${user.id}/user-info`,
      {
        name: payload.userName,
        representative_name: payload.representativeName,
        phone_whatsapp_representative: `55${payload.representativeWhatsapp}`,
        cpf_cnpj: payload.userCnpj,
        advertiser_category_id: payload.userCategory,
      }
    );

    // Save in redux the user info data
    yield put(updateUserInfoSuccess(userResponse.data));
    toast.success('Dados do estabelecimento salvos com sucesso!');
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      toast.error(
        'Ocorreu um erro ao salvar os dados do estabelecimento. Tente novamente!',
        {
          autoClose: 6000,
        }
      );
      yield put(updateUserInfoFailure());
    }
  }
}

export function* updateUserAddressRequest({ payload }) {
  try {
    const user = yield select(userData);

    const userAddressResponse = yield call(
      apiGlobal.put,
      `/user/${user.id}/address`,
      {
        street: payload.address,
        number: payload.number,
        complement: payload.complement,
        neighborhood: payload.neighborhood,
        city: payload.city,
        state: payload.state,
        zipcode: payload.zipcode,
        phone_1: `55${payload.phone1}`,
        phone_2: payload.phone2 ? `55${payload.phone2}` : null,
        phone_whatsapp: payload.whatsapp ? `55${payload.whatsapp}` : null,
      }
    );

    // Update in redux the user data
    yield put(updateUserAddressSuccess(userAddressResponse.data));

    toast.success('Dados do endereço salvos com sucesso!');
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      toast.error(
        'Ocorreu um erro ao salvar os dados de endereço. Tente novamente!',
        {
          autoClose: 6000,
        }
      );
      yield put(updateUserAddressFailure());
    }
  }
}

export function* updateUserPasswordRequest({ payload }) {
  try {
    const user = yield select(userData);

    // Call global API to change user password
    yield call(apiGlobal.put, `/user/${user.id}`, {
      password: payload.newPassword,
    });

    yield put(updateUserPasswordSuccess());
    toast.success('Senha atualizada com sucesso!');
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      toast.error('Ocorreu um erro ao alterar a senha. Tente novamente!', {
        autoClose: 6000,
      });
      yield put(updateUserPasswordFailure());
    }
  }
}

export function* getTermsAndPolicyRequest({ payload }) {
  try {
    // Get terms and policy data
    const response = yield call(
      apiGlobal.get,
      `/term-and-policy/${payload.termPolicyCode}`
    );

    // Save in redux the terms data
    yield put(getTermsAndPolicySuccess(response.data));
  } catch (err) {
    yield put(getTermsAndPolicyFailure());
  }
}

export function* acceptTermsAndPolicyRequest({ payload }) {
  try {
    const user = yield select((state) => state.user);

    // Get terms and policy data
    yield call(
      apiAnunciante.put,
      `/user-info/${user.user.advertiserInfo.id}/accept-terms`, {
        term_policy_id: payload.termsId,
        ip_address: user.ipAddress
      }
    );

    yield put(
      setIsTermsTermsAndPolicyAccepted(true)
    );
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(setIsTermsTermsAndPolicyAccepted(false));
      toast.error(
        'Ocorreu um erro ao aceitar os termos de uso. Contate o suporte.',
        {
          autoClose: 6000,
        }
      );
    }
  }
}

export function* updateUserImagesRequest({ payload }) {
  try {
    const user = yield select(userData);

    const userImagesResponse = yield call(
      apiAnunciante.put,
      `/user-info/${user.advertiserInfo.id}/images`,
      {
        logo: {
          base64Content: payload.fileLogoData.base64,
          name: payload.fileLogoData.name,
          type: payload.fileLogoData.type,
          sub_type: payload.fileLogoData.subtype,
          image_width: payload.fileLogoData.width,
          image_height: payload.fileLogoData.height
        },
        cover: {
          base64Content: payload.fileCoverData.base64,
          name: payload.fileCoverData.name,
          type: payload.fileCoverData.type,
          sub_type: payload.fileCoverData.subtype,
          image_width: payload.fileCoverData.width,
          image_height: payload.fileCoverData.height
        },
      }
    );

    // Update in redux the user data
    yield put(updateUserImagesSuccess(userImagesResponse.data));

    toast.success('Fotos salvas com sucesso!');
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      toast.error('Ocorreu um erro ao salvar as fotos. Tente novamente!', {
        autoClose: 6000,
      });
      yield put(updateUserImagesFailure());
    }
  }
}

export function* getUserRequest() {
  try {
    const user = yield select(userData);

    // Update user info data
    const userResponse = yield call(
      apiGlobal.get,
      `/user/${user.id}`
    );
    // If the user is active save in redux the user info data
    if (userResponse.data.is_active) {
      yield put(getUserSuccess(userResponse.data));
    }
    // If the user is blocked, logout
    else {
      yield put(logout());
    }
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    }
  }
}

export function* getAdvertiserCategoriesRequest() {
  try {
    //Get advertiser categories
    const categories = yield call(
      apiGlobal.get,
      `/anunciante/categories`
    );

    // Store advertiser categories inside redux
    yield put(getAdvertiserCategoriesSuccess(categories.data));
  } catch (err) {
    yield put(getAdvertiserCategoriesFailure());
  }
}

export default all([
  takeLatest('@user/UPDATE_USER_INFO_REQUEST', updateUserInfoRequest),
  takeLatest('@user/UPDATE_USER_ADDRESS_REQUEST', updateUserAddressRequest),
  takeLatest('@user/UPDATE_USER_PASSWORD_REQUEST', updateUserPasswordRequest),
  takeLatest('@user/GET_TERMS_AND_POLICY_REQUEST', getTermsAndPolicyRequest),
  takeLatest('@user/UPDATE_USER_IMAGES_REQUEST', updateUserImagesRequest),
  takeLatest(
    '@user/ACCEPT_TERMS_AND_POLICY_REQUEST',
    acceptTermsAndPolicyRequest
  ),
  takeLatest('@user/GET_USER_REQUEST', getUserRequest),
  takeLatest('@user/GET_ADVERTISER_CATEGORIES_REQUEST',  getAdvertiserCategoriesRequest),
]);
