import React from 'react';
import { MessageImage } from '../../style';
import BrokenMedia from '../../BrokenMedia';

export const ImageMessage = ({ image }) => {
  return image.url ? (
    <MessageImage src={image.url} />
  ) : (
    <BrokenMedia type={'image'} />
  );
};
