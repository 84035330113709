import styled from 'styled-components';

import px2vw from '../../utils/responsiveness/px2vw';

import {
  branco,
  preto,
  verdeKuppi8,
  cinzaKuppi4,
  amareloKuppi1,
} from '../../styles/colors';

export const Header = styled.div`
  background: ${verdeKuppi8};
  height: ${px2vw(50)};
  width: 100%;
  display: flex;
  padding: 0 2rem;
  flex-direction: row;
  justify-content: space-between;
  border-radius: ${px2vw(50)} 0px 0px 0px;

  ${(props) =>
    props.initial && `
      border-radius: 0px 0px 0px 0px;
    `
  };
`;

export const NormalText = styled.div`
  font-family: AvenirNextLTPro;

  ${(props) => `
    color: ${props.color};
    width: ${props.width};
    height: ${props.height};
    font-size: ${props.fontsize};
    font-weight: ${props.fontweight};
    text-align: ${props.textalign || 'center'};
    margin-left: ${props.margleft};
    margin-right: ${props.margright};
    margin-top: ${props.margtop};
    margin-bottom: ${props.margbottom};
    padding-left: ${props.padleft};
    padding-right: ${props.padright};
    padding-bottom: ${props.padbottom};
    padding-top: ${props.padtop};
    padding: ${props.pad};
    font-weight: ${props.fontweight || 'normal'};
    text-decoration: ${props.textdecoration};
    cursor: ${props.cursor};
  `};

  ${(props) => props.minHeight && `
    min-height: ${props.minHeight};
  `};

  ${(props) =>
    props.disabled &&
    `
      color: ${preto}60;
  `};

  ${(props) =>
    props.complDescription &&
    `
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 28ch;
  `};

  ${(props) =>
    !props.complDescription &&
    `
    display: flex;
    flex-wrap: wrap;
  `};

  .yellowText {
    color: ${amareloKuppi1} !important;
  }
`;

export const ActivationChallengeBox = styled.div`
  background: ${branco};
  box-shadow: 0px 8px 9px -2px ${cinzaKuppi4};
  display: flex;
  flex-direction: column;
  width: ${px2vw(500)};
  min-height: ${px2vw(350)};
  border-radius: ${px2vw(25)};
  position: absolute;
  padding: ${px2vw(25)};
  z-index: 98;
`;

export const StepIconBox = styled.div`
  position: relative;
  width: ${px2vw(30)};
  height: ${px2vw(25)};

  ${(props) => `
    margin-left: ${props.margleft};
    margin-right: ${props.margright};
    padding-right: ${props.padright};
    width: ${props.size};
    height: ${props.size};
  `};
`;

export const Img = styled.img`
  ${(props) => `
    width: ${props.width};
    height: ${props.height};
  `}
`;

export const ChallengeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: ${px2vw(1)} solid ${cinzaKuppi4};
  border-radius: ${px2vw(10)};
  height: ${px2vw(50)};
  padding: 0px ${px2vw(10)};
  cursor: ${({completed}) => !completed ? 'pointer': 'default'};

  :hover {
    background: ${cinzaKuppi4};
  }

  ${(props) => `
    margin-top: ${props.margtop};
    margin-bottom: ${props.margbottom};
    width: ${props.width};
    height: ${props.height};
  `}
`;


export const RuleContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`
