import styled from 'styled-components';
import { preto, verdeKuppi1 } from '../../styles/colors';
import px2vw from '../../utils/responsiveness/px2vw';

export const Content = styled.div`
  display: flex;
  flex-direction: row;

  ${(props) =>
    props.isMobile &&
    `
      flex-direction: column;
    `};
`;

export const SubText = styled.div`
  font-size: ${px2vw(12)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.36px;

  ${(props) => `
    font-size: ${props.fontsize};
    font-weight:${props.fontweight};
  `};
`;

export const AdvertiserName = styled.div`
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.48px;
  margin-top: ${px2vw(2)};
  color: ${preto};
  font-size: ${px2vw(30)};

  ${(props) => props.isMobile && `
    font-size: ${px2vw(100)};
    display: flex;
    flex-wrap: wrap;
    .green{
      margin-left: ${px2vw(25)};
    
    }
  `};

  .green{
    color: ${verdeKuppi1};    
  }
`;

export const AdvertiserTitle = styled.div`
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.48px;
  margin-top: ${px2vw(2)};
  color: ${preto};
  font-size: ${px2vw(30)};
`;

export const PageTitle = styled.div`
  
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.48px;
  margin-top: ${px2vw(2)};
  display: inline-block;

  ${(props) => ` 
    color: ${props.color};
    padding-left: ${props.padleft};
    padding-right: ${props.padright};
    font-size: ${props.fontsize || px2vw(30)};
  `};

`;

export const DataText = styled.div`
  font-size: ${px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.36px;

  ${(props) => `
    width: ${props.width};
    color: ${props.color};
    font-size: ${props.fontSize};
    margin-top: ${props.margtop};
  `};

  ${(props) =>
    props.name &&
    `
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 30ch;
  `};
`;
