import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Icon } from 'react-materialize';
import { Spinner } from 'reactstrap';
import { toast } from 'react-toastify';
import { FormControlLabel } from '@material-ui/core/';

import SelectModal from '../../components/SelectModal';
import ExportOptionsModal from '../../components/ExportOptionsModal';
import LoyaltyCardHistoryTable from '../../components/LoyaltyCardHistoryTable';

import px2vw from '../../utils/responsiveness/px2vw';

import { Row, Col } from '../../styles/grid';

import {
  PageTitle,
  ContentPage,
  SwitchController,
  SwitchLabelRegularText,
  SwitchWithInput,
  SwitchWithInputBox,
  SwitchInput,
  LoadingBox,
} from '../_layouts/styles/common';

import { preto } from '../../styles/colors';

import {
  SubtitleTextBox,
  SubtitleText,
  InfoDataBox,
  InfoBox,
  InfoNumberText,
  InfoTypeText,
  SeeHistoryBox,
  SeeHistoryButton,
  SeeHistoryButtonImage,
  SeeHistoryButtonText,
  SaveButton,
  SaveButtonText,
  LoyaltyCardBox,
  ActiveLoyaltyCardBoxLeft,
  ActiveLoyaltyCardBoxRight,
  LoyaltyCardPrincipal,
  LoyaltyCardHistory,
  BackButtonBox,
  BackButton,
  BackButtonImage,
  BackButtonText,
  PageHistoryTitle,
  LoyaltyCardHistoryBox,
  InfoHistoryBox,
  SelectButtonOption,
  SelectButtonOptionText,
  ErrorText,
  QtyOrdersBenefitBox,
} from './styles';

import {
  clearSuccess,
  getLoyaltyCardRequest,
  getLoyaltyCardMetricsRequest,
  getLoyaltyCardHistoryRequest,
  updateLoyaltyCardRequest,
} from '../../store/modules/loyaltyCard/actions';

import { amountMask } from '../../utils/masks';

import { onlyNumbers, onlyNumbersAmount } from '../../utils/utils';

export default function LoyaltyCard() {
  const loyaltyCard = useSelector((state) => state.loyaltyCard?.loyaltyCard);
  const loyaltyCardMetrics = useSelector(
    (state) => state.loyaltyCard?.loyaltyCardMetrics
  );
  const loyaltyCardHistory = useSelector(
    (state) => state.loyaltyCard?.loyaltyCardHistory
  );
  const [loyaltyCardHistoryContent, setLoyaltyCardHistory] = useState(false);
  const totalQtyOrdersBenefitOptions = 10;
  const [loyaltyCardActive, setLoyaltyCardActive] = useState(false);
  const [qtyOrdersActiveBenefit, setQtyOrdersActiveBenefit] = useState(5);
  const [daysToExpiration, setDaysToExpiration] = useState('');
  const [acceptTakeAway, setAcceptTakeAway] = useState(false);
  const [acceptDelivery, setAcceptDelivery] = useState(false);
  const [hasMinimumTicket, setHasMinimumTicket] = useState(false);
  const [minimumTicketInputValue, setMinimumTicketInputValue] = useState('');
  const [hasMinimumOrderAmount, setHasMinimumOrderAmount] = useState(false);
  const [minimumOrderAmountInputValue, setMinimumOrderAmountInputValue] = useState('');
  const [creditBenefit, setCreditBenefit] = useState(false);
  const [creditBenefitInputValue, setCreditBenefitInputValue] = useState('');
  const [discountBenefit, setDiscountBenefit] = useState(false);
  const [discountBenefitInputValue, setDiscountBenefitInputValue] = useState(
    ''
  );
  const [changes, setChanges] = useState(false);
  const [filter, setFilter] = useState('today');
  
  const getLoyaltyCardLoading = useSelector(
    (state) => state.loyaltyCard?.getLoyaltyCardLoading
  );
  const getLoyaltyCardMetricsLoading = useSelector(
    (state) => state.loyaltyCard?.getLoyaltyCardMetricsLoading
  );
  const getLoyaltyCardHistoryLoading = useSelector(
    (state) => state.loyaltyCard?.getLoyaltyCardHistoryLoading
  );
  const error = useSelector((state) => state.loyaltyCard.error);
  const updateLoyaltyCardLoading = useSelector(
    (state) => state.loyaltyCard?.updateLoyaltyCardLoading
  );
  const updateLoyaltyCardSuccess = useSelector(
    (state) => state.loyaltyCard?.updateLoyaltyCardSuccess
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getLoyaltyCardRequest());
    dispatch(getLoyaltyCardMetricsRequest());
  }, [dispatch]);

  function handleMinimumTicket() {
    setChanges(true);
    setHasMinimumTicket(!hasMinimumTicket);
    setMinimumTicketInputValue('');
  }

  function handleMinimumOrderAmount() {
    setChanges(true);
    setHasMinimumOrderAmount(!hasMinimumOrderAmount);
    setMinimumOrderAmountInputValue('');
  }

  function handleBenefit(type) {
    setChanges(true);

    if (type === 'credit') {
      setCreditBenefit(!creditBenefit);
      setDiscountBenefit(creditBenefit);
      setDiscountBenefitInputValue('');
      setCreditBenefitInputValue('');
    } else if (type === 'discount') {
      setCreditBenefit(discountBenefit);
      setDiscountBenefit(!discountBenefit);
      setDiscountBenefitInputValue('');
      setCreditBenefitInputValue('');
    }
  }

  function handleClickAcceptedPlaces(place) {
    setChanges(true);

    if (place === 'nlStore') {
      setAcceptTakeAway(!acceptTakeAway);
    } else if (place === 'nlDelivery') {
      setAcceptDelivery(!acceptDelivery);
    }
  }

  function handleMaximumDays(days) {
    if (parseInt(days) >= 365) {
      return 365
    } else if (parseInt(days) <= 0) {
      return 0
    } else {
      return onlyNumbers(days)
    }
  }

  function handleFilterChange(selectedOption) {
    // TODO: Chamar backend para filtrar a lita de histórico de uso
    setFilter(selectedOption);
  }

  const handleLoyaltCardSubmit = () => {
    if (parseInt(daysToExpiration, 10) < 15 || parseInt(daysToExpiration, 10) > 365 || daysToExpiration === '') {
      toast.error(
        'Quantidade de dias para vencimento do cartão fidelidade deve estar entre 15 e 365',
        {
          autoClose: 6000,
        }
      );
    } else if (!acceptTakeAway && !acceptDelivery) {
      toast.error(
        'Deve ser escolhido pelo menos um local onde o cartão será aceito: Delivery ou Retirada',
        {
          autoClose: 6000,
        }
      );
    } else if (hasMinimumTicket && (minimumTicketInputValue.length < 7 || parseInt(onlyNumbersAmount(minimumTicketInputValue), 10) === 0)) {
      toast.error(
        'Foi informado que deve existir um ticket mínimo para o uso do cartão fidelidade, porém o valor é inválido',
        {
          autoClose: 6000,
        }
      );
    } else if (hasMinimumOrderAmount && (minimumOrderAmountInputValue.length < 7 || parseInt(onlyNumbersAmount(minimumOrderAmountInputValue), 10) === 0)) {
      toast.error(
        'Foi informado que deve existir um valor mínimo para o resgate do cartão fidelidade, porém o valor é inválido',
        {
          autoClose: 6000,
        }
      );
    } else if (creditBenefit && (creditBenefitInputValue.length < 7 || parseInt(onlyNumbersAmount(creditBenefitInputValue), 10) === 0)) {
      toast.error(
        'Foi informado que o benefício é um crédito em R$, porém o valor é inválido',
        {
          autoClose: 6000,
        }
      );
    } else if (!discountBenefit && !creditBenefit) {
      toast.error(
        'Por favor selecione um tipo de benefício para o cartão fidelidade',
        {
          autoClose: 6000,
        }
      )
     } else if (
      discountBenefit &&
      (discountBenefitInputValue.length > 2 ||
        parseInt(discountBenefitInputValue, 10) > 70 ||
        parseInt(discountBenefitInputValue, 10) < 5 ||
        discountBenefitInputValue === '')
    ) {
      toast.error(
        'Foi informado que o benefício é percentual, porém o percentual deve estar entre 5% e 70%', 
        {
          autoClose: 6000,
        }
      );
    } else {
      dispatch(
        updateLoyaltyCardRequest(
          loyaltyCardActive,
          qtyOrdersActiveBenefit,
          daysToExpiration,
          acceptTakeAway,
          acceptDelivery,
          hasMinimumTicket,
          onlyNumbersAmount(minimumTicketInputValue),
          hasMinimumOrderAmount,
          onlyNumbersAmount(minimumOrderAmountInputValue),
          creditBenefit,
          onlyNumbersAmount(creditBenefitInputValue),
          discountBenefit,
          onlyNumbers(discountBenefitInputValue),
        )
      );
    }
  };

  useEffect(() => {
    if (updateLoyaltyCardSuccess) {
      setChanges(false);
      dispatch(clearSuccess());
    }
  }, [updateLoyaltyCardSuccess, dispatch]);

  useEffect(() => {
    if (!error && !getLoyaltyCardLoading) { 
      if (loyaltyCard?.is_active) {
        setLoyaltyCardActive(true);
      }
      setQtyOrdersActiveBenefit(loyaltyCard?.amount_of_purchases);
      setDaysToExpiration(loyaltyCard?.card_expiry_in_days);
      setAcceptDelivery(loyaltyCard?.is_accept_delivery);
      setAcceptTakeAway(loyaltyCard?.is_accept_physical);
      if (loyaltyCard?.is_ticket_minimum_active) {
        setHasMinimumTicket(true);
        setMinimumTicketInputValue(
          amountMask(loyaltyCard?.ticket_minimum_amount.toFixed(2))
        );
      }
      if (loyaltyCard?.is_ticket_minimum_retrieval_active) {
        setHasMinimumOrderAmount(true);
        setMinimumOrderAmountInputValue(
          amountMask(loyaltyCard.ticket_minimum_amount_retrieval.toFixed(2))
        );
      }
      if (loyaltyCard?.type === 'fixed_cart') {
        setCreditBenefit(true);
        setCreditBenefitInputValue(
          amountMask(loyaltyCard.cart_discount_fixed.toFixed(2))
        );
      } else if (loyaltyCard?.type === 'percentage_cart') {
        setDiscountBenefit(true);
        setDiscountBenefitInputValue(loyaltyCard?.cart_discount_percentage);
      } 
    }
  }, [error, loyaltyCard]);

  const contentPage = (
    <ContentPage>
      {!loyaltyCardHistoryContent && (
        <LoyaltyCardPrincipal>
          <PageTitle>Cartão Fidelidade Digital</PageTitle>
          <SubtitleTextBox>
            <SubtitleText>
              Cartão fidelidade é uma funcionalidade que possibilita a retenção
              de seus clientes. Você pode ativá-lo, determinar que tipo de
              benefício que você dará para seus clientes, quantas compras serão
              necessárias para ganhar o benefício. O cartão tem um mês de
              validade a partir da primeira compra do cliente.
            </SubtitleText>
          </SubtitleTextBox>
          <InfoBox>
            <InfoDataBox>
              {getLoyaltyCardMetricsLoading ? (
                <Col height="5vh">
                  <Col alignCenter justifyCenter>
                    <Spinner
                      style={{
                        width: '1.8rem',
                        height: '1.8rem',
                        color: preto,
                      }}
                    />
                  </Col>
                </Col>
              ) : (
                <InfoNumberText>
                  {loyaltyCardMetrics.active_cards}
                </InfoNumberText>
              )}
              <InfoTypeText>Cartões ativos</InfoTypeText>
            </InfoDataBox>
            <InfoDataBox>
              {getLoyaltyCardMetricsLoading ? (
                <Col height="5vh">
                  <Col alignCenter justifyCenter>
                    <Spinner
                      style={{
                        width: '1.8rem',
                        height: '1.8rem',
                        color: preto,
                      }}
                    />
                  </Col>
                </Col>
              ) : (
                <InfoNumberText>
                  {loyaltyCardMetrics?.retrieved_bonus}
                </InfoNumberText>
              )}
              <InfoTypeText>Prêmios resgatados</InfoTypeText>
            </InfoDataBox>
            <SeeHistoryButton
              onClick={() => {
                dispatch(getLoyaltyCardHistoryRequest());
                setLoyaltyCardHistory(true);
              }}
            >
              <SeeHistoryBox>
                <SeeHistoryButtonImage
                  src={require('../../assets/images/arrows/arrow_cicle.png')}
                />
                <SeeHistoryButtonText>
                  Ver histórico de uso
                </SeeHistoryButtonText>
              </SeeHistoryBox>
            </SeeHistoryButton>
            <SaveButton
              hasChanges={changes}
              disabled={!changes || updateLoyaltyCardLoading}
              onClick={() => handleLoyaltCardSubmit()}
            >
              {updateLoyaltyCardLoading ? (
                <LoadingBox>
                  <Spinner
                    style={{
                      width: '1.8rem',
                      height: '1.8rem',
                      color: preto,
                    }}
                  />{' '}
                </LoadingBox>
              ) : (
                <SaveButtonText hasChanges={changes}>
                  Salvar
                </SaveButtonText>
              )}
            </SaveButton>
          </InfoBox>
          <LoyaltyCardBox>
            {getLoyaltyCardLoading && (
              <Col height="50vh">
                <Col alignCenter justifyCenter>
                  <Spinner
                    style={{
                      width: '1.8rem',
                      height: '1.8rem',
                      color: preto,
                    }}
                  />
                </Col>
              </Col>
            )}
            {!getLoyaltyCardLoading && (
              <>
                <ActiveLoyaltyCardBoxLeft>
                  <InfoTypeText marginBottom={px2vw(25)}>
                    Deseja ativar o cartão fidelidade?
                  </InfoTypeText>
                  <FormControlLabel
                    control={
                      <SwitchController
                        type="checkbox"
                        checked={loyaltyCardActive}
                        onChange={() => {
                          setChanges(true);
                          setLoyaltyCardActive(!loyaltyCardActive);
                        }}
                      />
                    }
                    label={
                      <SwitchLabelRegularText>
                        Ativar cartão
                      </SwitchLabelRegularText>
                    }
                  />
                  <InfoTypeText margtop={px2vw(21)} marginBottom={px2vw(15)}>
                    Quantas compras são necessárias para ativar o benefício?
                  </InfoTypeText>
                  <Row margtop={px2vw(5)} justifyStart>
                    {
                      [...Array(totalQtyOrdersBenefitOptions)].map((curr, i) => {
                        return <QtyOrdersBenefitBox key={i + 1} onClick={() => {
                          setChanges(true);
                          setQtyOrdersActiveBenefit(i + 1);
                        }} isActive={qtyOrdersActiveBenefit === (i + 1)}>{i + 1}</QtyOrdersBenefitBox>
                      })
                    }
                  </Row>
                  <InfoTypeText marginBottom={px2vw(20)} margtop={px2vw(30)}>
                    Quantos dias o seu cliente terá para completar o cartão fidelidade?
                  </InfoTypeText>
                  <SwitchWithInputBox
                    isVisible
                    margleft={px2vw(-3)}
                  >
                    <SwitchLabelRegularText>
                      <SwitchInput
                        name="daysToExpiration"
                        width={px2vw(70)}
                        margtop={px2vw(2)}
                        maxLength="3"
                        placeholder="20"
                        value={daysToExpiration}
                        onChange={(t) => {
                          setChanges(true);
                          setDaysToExpiration(
                            handleMaximumDays(t.target.value)
                          );
                        }}
                      />
                      dias de validade
                    </SwitchLabelRegularText>
                  </SwitchWithInputBox>
                  <InfoTypeText margtop={px2vw(25)} marginBottom={px2vw(15)}>
                    Onde o benefício será aceito?
                  </InfoTypeText>
                  <Row justifyStart>
                    <SelectButtonOption
                      isChecked={acceptTakeAway}
                      type="button"
                      onClick={() => handleClickAcceptedPlaces('nlStore')}
                    >
                      <SelectButtonOptionText isChecked={acceptTakeAway}>
                        Retirada
                      </SelectButtonOptionText>
                    </SelectButtonOption>
                    <SelectButtonOption
                      isChecked={acceptDelivery}
                      type="button"
                      onClick={() => handleClickAcceptedPlaces('nlDelivery')}
                    >
                      <SelectButtonOptionText isChecked={acceptDelivery}>
                        Delivery
                      </SelectButtonOptionText>
                    </SelectButtonOption>
                  </Row>
                </ActiveLoyaltyCardBoxLeft>
                <ActiveLoyaltyCardBoxRight>
                  <InfoTypeText marginBottom={px2vw(9)}>
                    Deseja determinar um ticket mínimo para seu cartão
                    fidelidade?
                  </InfoTypeText>
                  <Row justifyStart alignCenter>
                    <FormControlLabel
                      control={
                        <SwitchController
                          type="checkbox"
                          checked={!hasMinimumTicket}
                          onChange={() => handleMinimumTicket()}
                        />
                      }
                      label={
                        <SwitchLabelRegularText margright={px2vw(20)}>
                          Não
                        </SwitchLabelRegularText>
                      }
                    />
                    <SwitchWithInput margleft="0px" marginBottom="0px">
                      <FormControlLabel
                        control={
                          <SwitchController
                            type="checkbox"
                            checked={hasMinimumTicket}
                            onChange={() => handleMinimumTicket()}
                          />
                        }
                        label={
                          <SwitchLabelRegularText>Sim</SwitchLabelRegularText>
                        }
                      />
                      <SwitchWithInputBox
                        isVisible={hasMinimumTicket}
                        margleft={px2vw(20)}
                      >
                        <SwitchLabelRegularText>
                          <SwitchInput
                            name="minimumTicket"
                            width={px2vw(90)}
                            margtop="0px"
                            maxLength="9"
                            placeholder="R$ 20,00"
                            value={minimumTicketInputValue}
                            onChange={(t) => {
                              setChanges(true);
                              setMinimumTicketInputValue(
                                amountMask(t.target.value)
                              );
                            }}
                          />
                        </SwitchLabelRegularText>
                      </SwitchWithInputBox>
                    </SwitchWithInput>
                  </Row>
                  <InfoTypeText
                    marginRight={px2vw(-50)}
                    margtop={px2vw(20)}
                    marginBottom={px2vw(15)}
                  >
                    Deseja determinar um valor mínimo para o resgate do benefício?
                  </InfoTypeText>
                  <Row justifyStart alignCenter>
                    <FormControlLabel
                      control={
                        <SwitchController
                          type="checkbox"
                          checked={!hasMinimumOrderAmount}
                          onChange={() => handleMinimumOrderAmount()}
                        />
                      }
                      label={
                        <SwitchLabelRegularText margright={px2vw(20)}>
                          Não
                        </SwitchLabelRegularText>
                      }
                    />
                    <SwitchWithInput margleft="0px" marginBottom="0px">
                      <FormControlLabel
                        control={
                          <SwitchController
                            type="checkbox"
                            checked={hasMinimumOrderAmount}
                            onChange={() => handleMinimumOrderAmount()}
                          />
                        }
                        label={
                          <SwitchLabelRegularText>Sim</SwitchLabelRegularText>
                        }
                      />
                      <SwitchWithInputBox
                        isVisible={hasMinimumOrderAmount}
                        margleft={px2vw(20)}
                      >
                        <SwitchLabelRegularText>
                          <SwitchInput
                            name="isMinimumOrderAmount"
                            width={px2vw(90)}
                            margtop="0px"
                            maxLength="9"
                            placeholder="R$ 20,00"
                            value={minimumOrderAmountInputValue}
                            onChange={(t) => {
                              setChanges(true);
                              setMinimumOrderAmountInputValue(
                                amountMask(t.target.value)
                              );
                            }}
                          />
                        </SwitchLabelRegularText>
                      </SwitchWithInputBox>
                    </SwitchWithInput>
                  </Row>
                  <InfoTypeText
                    marginRight={px2vw(-50)}
                    margtop={px2vw(25)}
                    marginBottom={px2vw(15)}
                  >
                    Selecione qual prêmio seu cliente terá ao concluir o cartão fidelidade:
                  </InfoTypeText>
                  <SwitchWithInput margleft="0px" marginBottom="0px">
                    <FormControlLabel
                      control={
                        <SwitchController
                          type="checkbox"
                          checked={creditBenefit}
                          onChange={() => handleBenefit('credit')}
                        />
                      }
                      label={
                        <SwitchLabelRegularText>
                          Cupom com desconto em R$
                        </SwitchLabelRegularText>
                      }
                    />
                    <SwitchWithInputBox
                      isVisible={creditBenefit}
                      margleft={px2vw(8)}
                    >
                      <SwitchLabelRegularText>
                        <SwitchInput
                          name="creditBenefit"
                          width={px2vw(80)}
                          margtop={px2vw(3)}
                          maxLength="9"
                          placeholder="R$ 50,00"
                          value={creditBenefitInputValue}
                          onChange={(t) => {
                            setChanges(true);
                            setCreditBenefitInputValue(
                              amountMask(t.target.value)
                            );
                          }}
                        />
                      </SwitchLabelRegularText>
                    </SwitchWithInputBox>
                  </SwitchWithInput>
                  <SwitchWithInput margleft="0px" marginBottom="0px">
                    <FormControlLabel
                      control={
                        <SwitchController
                          type="checkbox"
                          checked={discountBenefit}
                          onChange={() => handleBenefit('discount')}
                        />
                      }
                      label={
                        <SwitchLabelRegularText>
                          Cupom com desconto percentual na compra
                        </SwitchLabelRegularText>
                      }
                    />
                    <SwitchWithInputBox
                      isVisible={discountBenefit}
                      margleft={px2vw(13)}
                    >
                      <SwitchLabelRegularText>
                        <SwitchInput
                          name="percentualDiscountBenefit"
                          width={px2vw(35)}
                          margtop={px2vw(2)}
                          maxLength="2"
                          placeholder="20"
                          value={discountBenefitInputValue}
                          onChange={(t) => {
                            setChanges(true);
                            setDiscountBenefitInputValue(
                              onlyNumbers(t.target.value)
                            );
                          }}
                        />
                        %
                      </SwitchLabelRegularText>
                    </SwitchWithInputBox>
                  </SwitchWithInput>
                </ActiveLoyaltyCardBoxRight>
              </>
            )}
          </LoyaltyCardBox>
        </LoyaltyCardPrincipal>
      )}
      {loyaltyCardHistoryContent && (
        <LoyaltyCardHistory>
          <BackButton
            button="button"
            onClick={() => setLoyaltyCardHistory(false)}
          >
            <BackButtonBox>
              <BackButtonImage
                src={require('../../assets/images/arrows/arrow_left.png')}
              />
              <BackButtonText>voltar</BackButtonText>
            </BackButtonBox>
          </BackButton>
          <PageHistoryTitle>Histórico de uso</PageHistoryTitle>
          <InfoHistoryBox>
            {false && (
              <>
                <ExportOptionsModal
                  primaryOption="Exportar utilizados"
                  options={[
                    { id: 'today', title: 'De hoje' },
                    { id: 'thisWeek', title: 'Desta semana' },
                    { id: 'thisMonth', title: 'Deste mês' },
                  ]}
                />
                <SelectModal
                  onSelectOption={(selectedOption) =>
                    handleFilterChange(selectedOption)
                  }
                  selectedOption="Hoje"
                  options={[
                    { id: 'today', title: 'Hoje' },
                    { id: 'thisWeek', title: 'Essa semana' },
                    { id: 'thisMonth', title: 'Esse mês' },
                    { id: 'sinceBegin', title: 'Desde o início' },
                  ]}
                />
              </>
            )}
          </InfoHistoryBox>
          <LoyaltyCardHistoryBox>
            {getLoyaltyCardHistoryLoading && (
              <Col height="20vh">
                <Col alignCenter justifyCenter>
                  <Spinner
                    style={{
                      width: '1.8rem',
                      height: '1.8rem',
                      color: preto,
                    }}
                  />
                </Col>
              </Col>
            )}
            {!getLoyaltyCardHistoryLoading && (
              <LoyaltyCardHistoryTable data={loyaltyCardHistory} />
            )}
          </LoyaltyCardHistoryBox>
        </LoyaltyCardHistory>
      )}
    </ContentPage>
  );

  const errorPage = (
    <ContentPage>
      <Col margleft={px2vw(200)} height="80vh">
        <Row alignCenter justifyCenter>
          {/* TODO: Ajustar tamanho do icone */}
          <Icon large>sentiment_very_dissatisfied</Icon>
          <ErrorText>
            Ocorreu um erro ao carregar o cartão fidelidade. Tente novamente.
          </ErrorText>
        </Row>
      </Col>
    </ContentPage>
  );

  if (error) {
    return errorPage;
  }
  return contentPage;
}
