import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Col } from '../../../styles/grid';
import { Container } from './styles';

import FreeTrialBar from '../../FreeTrialBar';
import ActivationChallengeBar from '../../ActivationChallengeBar';
import { getChallengesStatusRequest } from '../../../store/modules/challenges/actions';
import useHoursCountDown from '../../../hooks/useCountdown';
import { ChallengeBarProvider } from '../../ActivationChallengeBar/Context';
import { ChallengesDetails } from '../../ActivationChallengeBar/ChallengesDetails';
import { ProgressModal } from '../../ActivationChallengeBar/ProgressModal';
import { LeftSection } from '../../ActivationChallengeBar/ProgressModal/LeftSection';

export default function Skel({ children }) {
  const remainingFreeDays = useSelector(
    (state) => state.user.remainingFreeDays
  );
  const hasFreeTrialValid = useSelector(
    (state) => state.user.hasFreeTrialValid
  );
  const onboardingCreatedAt = useSelector(
    (state) =>
      state.user.user.advertiserInfo.advertiserOnboardingSurvey
        .onboarding_created_at
  );
  const splitSlug = useSelector(
    (state) => state.user.user.advertiserInfo.advertiserRecurring.split_slug
  );

  const user = useSelector((state) => state.user.user);
  const challenges = useSelector((state) => state.challenges.challenges);
  const challengesSuccess = useSelector(
    (state) => state.challenges.challengesSuccess
  );
  const orderStatus = useSelector((state) => state.orders.currOrderStatusData);
  const [showChallengeBar, setShowChallengeBar] = useState(false);
  const [isChallengesCompleted, setIsChallengesCompleted] = useState(false);
  const [isCompletedModalClosed, setIsCompletedModalClosed] = useState(false);
  const [hours, minutes, seconds] = useHoursCountDown(
    onboardingCreatedAt,
    challenges?.challenge?.expiration_min / 60 || 48
  );

  const [showChallengeDetails, setShowChallengeDetails] = useState(false);

  const isRemainingChallengeTime = hours + minutes + seconds > 0;
  const [showHeaders, setShowHeaders] = useState(false);
  const freeBenefit = `${
    challenges?.challenge?.benefit_free_value || 0
  }% de desconto na contratação`;
  const paidBenefit = `R$${
    challenges?.challenge?.benefit_paid_value || 0
  },00 de bônus no disparo em massa`;

  const isAdvertiserFromInternalKuppido = splitSlug.includes('without_split');
  const award =
    hasFreeTrialValid && isAdvertiserFromInternalKuppido
      ? freeBenefit
      : paidBenefit;

  const dispatch = useDispatch();

  useEffect(() => {
    if (user !== null && !isCompletedModalClosed) {
      dispatch(getChallengesStatusRequest());
    }
  }, [user, orderStatus, isCompletedModalClosed]);

  useEffect(() => {
    if (challengesSuccess && challenges) {
      setIsChallengesCompleted(challenges.is_completed);
      setIsCompletedModalClosed(challenges.is_completed_modal_closed);
      setShowChallengeBar(!challenges.is_completed);
      setShowHeaders(true);
    }
  }, [challengesSuccess]);

  const isWhatsappwebPage = window.location.pathname.includes('whatsappchat');

  const handleCloseProgressModal = () => {};

  return (
    <Col>
      <ChallengeBarProvider
        value={{ setShowChallengeDetails, showChallengeDetails }}
      >
        {/* Activation Challenge Bar */}
        {showChallengeBar && isRemainingChallengeTime && showHeaders ? (
          <ActivationChallengeBar
            countDown={[hours, minutes, seconds]}
            award={award}
          />
        ) : (
          hasFreeTrialValid &&
          remainingFreeDays > 0 &&
          showHeaders &&
          !showChallengeBar && (
            <FreeTrialBar isChallengeComplete={isChallengesCompleted} />
          )
        )}
        {showChallengeDetails && (
          <ChallengesDetails
            timePeriod={Math.round(challenges?.challenge?.expiration_min / 60)}
            award={award}
            onClose={() => setShowChallengeDetails(false)}
          />
        )}

        {isRemainingChallengeTime && showHeaders && (
          <ProgressModal
            onClose={handleCloseProgressModal}
            award={award}
            onConfirm={() => {
              setShowChallengeDetails(true);
            }}
          />
        )}
      </ChallengeBarProvider>
      <Container
        free={hasFreeTrialValid}
        wppChatPage={isWhatsappwebPage}
        activationChallenge={isRemainingChallengeTime || hasFreeTrialValid}
      >
        {children}
      </Container>
    </Col>
  );
}
