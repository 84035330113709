import styled, { css } from 'styled-components';

import {
  amareloKuppi9,
  branco,
  cinzaKuppi4,
  marromKuppi2,
  verdeKuppi2,
} from '../../../../styles/colors';

import px2vw from '../../../../utils/responsiveness/px2vw';

export const ModalHeader = styled.div`
  width: 100%;
  background-color: ${branco};
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: ${px2vw(33)} ${px2vw(28)} ${px2vw(15)} ${px2vw(28)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  font-size: ${px2vw(25)};
  display: flex;
  align-items: space-between;
  justify-content: space-between;
`;

export const CloseButton = styled.button`
  width: ${px2vw(18)};
  height: ${px2vw(18)};
  border: none;
  z-index: 10;
  background-color: transparent;

  :focus {
    background-color: transparent !important;
  }

  ${(props) =>
    props.absolute &&
    css`
      position: absolute;
      top: ${props.top || px2vw(10)};
      right: ${props.right || px2vw(10)};
    `}
`;

export const CloseImage = styled.img`
  width: ${px2vw(15)};
  height: ${px2vw(15)};
`;

export const ModalContent = styled.div`
  background-color: ${branco};
  padding: ${(props) => props.padding || '20px'};
  margin-right: ${px2vw(22)};
  display: flex;
  flex-wrap: wrap;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 0.6em;
    border-radius: 7px;
  }
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    border-radius: 7px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${verdeKuppi2};
    outline: 1px solid ${verdeKuppi2};
    border-radius: 7px;
  }

  ${(props) => `
    width: ${props.width};
    min-height: ${props.height || px2vw(400)};
    max-height: ${props.maxheight || px2vw(350)};
    background-color: ${props.bgcolor};
    margin-top: ${props.margtop};
  `};
`;

export const ModalFooter = styled.div`
  width: 100%;
  min-height: ${px2vw(90)};
  margin-top: auto;
  background-color: ${branco};
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: ${px2vw(15)} ${px2vw(32)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  font-size: ${px2vw(22)};
`;

export const CouponCard = styled.div`
  display: flex;
  padding: ${px2vw(12)};
  width: ${px2vw(340)};
  height: ${px2vw(200)};
  border: 2px solid ${cinzaKuppi4};
  border-radius: ${px2vw(10)};
  margin: 0 ${px2vw(5)};
  margin-bottom: ${px2vw(15)};
  ${(props) =>
    props.selected &&
    `
  border: 3px solid ${verdeKuppi2};
  box-shadow: inset 1px 1px 4px rgba(99, 99, 99, 99);
  `}
  cursor: pointer;
  transition: 100ms linear;
  &:hover {
    transform: scale(1.01);
  }
`;

export const Badge = styled.span`
  width: ${px2vw(180)};
  display: flex;
  justify-content: center;
  padding: ${px2vw(4)} ${px2vw(4)};
  color: ${(props) => props.color || marromKuppi2};
  font-weight: 700;
  font-size: ${px2vw(12)};
  background: ${(props) => props.bg || amareloKuppi9};
  border-radius: ${px2vw(4)};
  margin-bottom: ${px2vw(15)};
`;
