import produce from 'immer';

const INITIAL_STATE = {
  user: null,
  pwdEncrypted: null,
  terms: null,
  access: {
    roles: null,
    permissions: null,
  },
  loading: false,
  success: false,
  loadingGetTermsAndPolicy: false,
  successGetTermsAndPolicy: false,
  loadingAcceptTermsOfUse: false,
  updateImagesLoading: false,
  updateImagesSuccess: false,
  ipAddress: null,
  advertiserCategories: [],
  paymentToken: null,
  remainingFreeDays: 0,
  minimumSubscriptionAmount: 0,
  hasFreeTrialValid: false,
};

export default function user(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@auth/LOGIN_SUCCESS': {
        draft.user = action.payload.user;
        draft.pwdEncrypted = action.payload.pwdEncrypted;
        draft.access = {
          roles: action.payload.user.roles,
          permissions: action.payload.user.permissions,
        };
        break;
      }
      case '@auth/LOGIN_FAILURE': {
        draft.user = null;
        break;
      }
      case '@user/GET_USER_SUCCESS': {
        const {freeTrialData, ...user} = action.payload.userData;
        draft.user = user;
        if(freeTrialData) {
          draft.hasFreeTrialValid = freeTrialData.hasFreeTrialValid;
          draft.paymentToken = freeTrialData.paymentToken;
          draft.remainingFreeDays = freeTrialData.remainingFreeDays;
          draft.minimumSubscriptionAmount = freeTrialData.minimumSubscriptionAmount;
        }
        break;
      }
      case '@user/UPDATE_USER_INFO_SUCCESS': {
        draft.user.advertiserInfo = action.payload.userData;
        break;
      }
      case '@user/UPDATE_USER_ADDRESS_SUCCESS': {
        draft.user.address[0] = action.payload.userAddressData;
        break;
      }
      case '@user/UPDATE_USER_PASSWORD_REQUEST': {
        draft.loading = true;
        draft.success = false;
        break;
      }
      case '@user/UPDATE_USER_PASSWORD_SUCCESS': {
        draft.success = true;
        draft.loading = false;
        break;
      }
      case '@user/UPDATE_USER_PASSWORD_FAILURE': {
        draft.success = false;
        draft.loading = false;
        break;
      }
      case '@orders/CHANGE_ACCEPTING_ORDERS_SUCCESS': {
        draft.user.advertiserInfo = action.payload.advertiserInfo;
        break;
      }
      case '@user/GET_TERMS_AND_POLICY_REQUEST': {
        draft.loadingGetTermsAndPolicy = true;
        break;
      }
      case '@user/GET_TERMS_AND_POLICY_SUCCESS': {
        draft.successGetTermsAndPolicy = true;
        draft.terms = action.payload.termPolicyContent;
        draft.loadingGetTermsAndPolicy = false;
        break;
      }
      case '@user/GET_TERMS_AND_POLICY_FAILURE': {
        draft.successGetTermsAndPolicy = false;
        draft.terms = null;
        draft.loadingGetTermsAndPolicy = false;
        break;
      }
      case '@user/ACCEPT_TERMS_AND_POLICY_REQUEST': {
        draft.loadingAcceptTermsOfUse = true;
        break;
      }
      case '@user/SET_IS_TERMS_AND_POLICY_ACCEPTED': {
        draft.loadingAcceptTermsOfUse = false;
        draft.user.advertiserInfo.is_terms_accepted =
          action.payload.isTermsAccepted;
        break;
      }
      case '@user/UPDATE_USER_IMAGES_REQUEST': {
        draft.updateImagesLoading = true;
        draft.updateImagesSuccess = false;
        break;
      }
      case '@user/UPDATE_USER_IMAGES_SUCCESS': {
        draft.updateImagesLoading = false;
        draft.user = action.payload.userData;
        draft.updateImagesSuccess = true;
        break;
      }
      case '@user/UPDATE_USER_IMAGES_FAILURE': {
        draft.updateImagesLoading = false;
        draft.updateImagesSuccess = false;
        break;
      }
      case '@user/CLEAR_SUCCESS': {
        draft.success = false;
        draft.updateImagesSuccess = false;
        break;
      }
      case '@onboarding/FINISH_ONBOARDING_SUCCESS': {
        draft.user.advertiserInfo.is_done_onboarding =
          action.payload.isDoneOnboarding;
        break;
      }
      case '@signaturePayment/PROCESS_PAYMENT_CREDIT_CARD_SUCCESS': {
        draft.user = action.payload.creditCardPaymentSuccessData.user;
        break;
      }
      case '@myStore/GET_ADVERTISER_PAYMENT_METHODS_SUCCESS': {
        draft.user.advertiserInfo.advertiserPaymentMethod =
          action.payload.advertiserPaymentMethods;
        break;
      }
      case '@myStore/UPDATE_STORE_PAYMENT_METHOD_SUCCESS': {
        draft.user.advertiserInfo.advertiserPaymentMethod =
          action.payload.advertiserPaymentMethods;
        break;
      }
      case '@user/FETCH_IP_ADDRESS': {
        draft.loading = true;
        break;
      }
      case '@user/SET_IP_ADDRESS': {
        draft.loading = false;
        draft.ipAddress = action.payload.ipAddress;
        break;
      }
      case '@user/GET_ADVERTISER_CATEGORIES_SUCCESS': {
        draft.advertiserCategories = action.payload.categories;
        break;
      }
      case '@user/GET_ADVERTISER_CATEGORIES_FAILURE': {
        draft.advertiserCategories = [];
        break;
      }
      case '@user/UPDATE_TRIAL_INFO': {
        draft.hasFreeTrialValid = action.payload.freeTrialData.hasFreeTrialValid;
        draft.paymentToken = action.payload.freeTrialData.paymentToken;
        draft.remainingFreeDays = action.payload.freeTrialData.remainingFreeDays;
        draft.minimumSubscriptionAmount = action.payload.freeTrialData.minimumSubscriptionAmount;
        break;
      }
      case '@profileAccount/GET_SUBSCRIPTIONS_SUCCESS': {
        draft.hasFreeTrialValid = action.payload.hasFreeTrialValid;
        break;
      }
      case '@whatsappBot/DISCONNECT_WHATSAPPBOT_REQUEST': {
        draft.user.advertiserInfo.advertiserWhatsappbotConfig.is_active = false;
        draft.user.advertiserInfo.advertiserWhatsappbotConfig.status = 'disconnected';
        draft.user.advertiserInfo.advertiserWhatsappbotConfig.qr_code_image = null;
        draft.user.advertiserInfo.advertiserWhatsappbotConfig.phone = null;
        break;
      }
      case '@whatsappBot/UPDATE_WHATSAPPBOT_STATUS': {
        draft.user.advertiserInfo.advertiserWhatsappbotConfig.is_active = action.payload.isActive;
        draft.user.advertiserInfo.advertiserWhatsappbotConfig.status = action.payload.status;
        draft.user.advertiserInfo.advertiserWhatsappbotConfig.qr_code_image = action.payload.qrCodeImage;
        draft.user.advertiserInfo.advertiserWhatsappbotConfig.phone = action.payload.phone;
        break;
      }
      case "@whatsappBot/SET_WHATSAPP_PLATFORM":
        draft.user.advertiserInfo.advertiserWhatsappbotConfig.platform = action.payload.platform
        break;
      default:
        break;
    }
  });
}
