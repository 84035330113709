import React, {
  Fragment,
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import moment from 'moment';

import px2vw from '../../../utils/responsiveness/px2vw';
import { screenAmountMask } from '../../../utils/masks';

import {
  Header,
  Content,
  Hr,
  TableRow,
  HeaderButton,
  PagButton,
  PrevIcon,
  NextIcon,
  ImageBox,
  Image,
  TagType,
} from './styles';
import { SvgBox, Arrow, Arrow2 } from './icons';
import { sort, makePages } from './tableOptions';

import TableStatus from '../../KuppiBoost/TableStatus';
import TablePerPage from '../../../components/TablePerPage';
import ButtonSecondary from '../../../components/ButtonSecondary';

import AdsDetail from '../AdsDetail';

import { Col, Row } from '../../../styles/grid';

export default function AdsTable({ data }) {
  const [rows, setRows] = useState(data);
  const [rowsCurrentPage, setRowsCurrentPage] = useState();
  const [structure, setStructure] = useState(null);
  const [currentPage, setCurrentPage] = useState('1');
  const [lastPage, setLastPage] = useState();
  const [firstPage] = useState('1');
  const [sortedCol, setSortedCol] = useState('');
  const [selectedCampaign, setSelectedCampaign] = useState(null);

  useEffect(() => {
    if (structure) {
      setLastPage(
        Object.keys(structure)[Object.keys(structure).length - 1].toString()
      );
    }
  }, [structure]);

  useMemo(() => {
    setStructure(makePages(rows.length, rowsCurrentPage));
  }, [rows, rowsCurrentPage]);

  useEffect(() => {
    setRows(data);
  }, [data]);

  useEffect(() => {
    if (rows.length < 5) {
      setRowsCurrentPage(rows.length);
    }
    if (rowsCurrentPage > 5) {
    } else {
      setRowsCurrentPage(5);
    }
  }, [rows]);

  const handleSort = useCallback(
    (col, inside) => {
      if (sortedCol !== col) {
        setSortedCol(col);
        if (inside) {
          setRows(sort(rows, col, 'asc', true));
        } else {
          setRows(sort(rows, col, 'asc'));
        }
      } else {
        setSortedCol('');
        if (inside) {
          setRows(sort(rows, col, 'desc', true));
        } else {
          setRows(sort(rows, col, 'desc'));
        }
      }
    },
    [rows]
  );

  function handlePerPage(size) {
    setRowsCurrentPage(size);
    setCurrentPage('1');
  }

  function handleChangePage(direction) {
    if (direction === 'next') {
      const curr = +currentPage + 1;
      setCurrentPage(curr.toString());
    } else if (direction === 'prev') {
      const curr = +currentPage - 1;
      setCurrentPage(curr.toString());
    }
  }

  function handleOpenCampaignDetails(campaign) {
    setSelectedCampaign(campaign)
  }

  function handleCloseCampaignDetails() {
    setSelectedCampaign(null)
  }

  return (
    <>
      {/* Campaigns Table */}
      <Col padding="0 15px" alignCenter justifyCenter>
        <Row>
          <Header
            width="150%"
            onClick={() => handleSort('product.name' || 'coupon.short_display_name', true)}
          >
            <HeaderButton>
              Anúncio
              <SvgBox sorted={sortedCol === 'coupon.short_display_name'}>
                <Arrow />
                <Arrow2 />
              </SvgBox>
            </HeaderButton>
          </Header>

          <Header onClick={() => handleSort('advertiserBoostCampaignStats.reach', true)}>
            <HeaderButton>
              Alcance
              <SvgBox sorted={sortedCol === 'advertiserBoostCampaignStats.reach'}>
                <Arrow />
                <Arrow2 />
              </SvgBox>
            </HeaderButton>
          </Header>

          <Header onClick={() => handleSort('advertiserBoostCampaignStats.clicks', true)}>
            <HeaderButton>
              Clicks
              <SvgBox sorted={sortedCol === 'advertiserBoostCampaignStats.clicks'}>
                <Arrow />
                <Arrow2 />
              </SvgBox>
            </HeaderButton>
          </Header>

          <Header onClick={() => handleSort('budget')}>
            <HeaderButton column>
              <div>
                Valor
                <SvgBox sorted={sortedCol === 'budget'}>
                  <Arrow />
                  <Arrow2 />
                </SvgBox>
              </div>
              investido
            </HeaderButton>
          </Header>

          <Header onClick={() => handleSort('status')}>
            <HeaderButton>
              Status
              <SvgBox sorted={sortedCol === 'status'}>
                <Arrow />
                <Arrow2 />
              </SvgBox>
            </HeaderButton>
          </Header>

          <Header onClick={() => handleSort('finish_at')}>
            <HeaderButton column>
              <div>
                Data de
                <SvgBox sorted={sortedCol === 'finish_at'}>
                  <Arrow />
                  <Arrow2 />
                </SvgBox>
              </div>
              Encerramento
            </HeaderButton>
          </Header>
          <Header width="20%" padding="25px 5px" />
        </Row>
        <Row>
          <Hr />
        </Row>
        {structure ? (
          rows.map((item, index) =>
            structure[currentPage] &&
            structure[currentPage].start <= index + 1 &&
            structure[currentPage].end >= index + 1 ? (
              <Fragment key={item.id}>
                <TableRow
                  alignCenter
                  justifyCenter
                  cursor="pointer"
                  onClick={() => handleOpenCampaignDetails(item)}
                >
                  <Content width="150%" title={item.boost_type === 'range' ? item?.product?.name : item?.coupon?.short_display_name}>
                    <ImageBox>
                      <Image
                        borderRadius="2px"
                        src={`${
                          item.campaignImage.url
                        }?nocache=${new Date().getTime()}`}
                      />
                    </ImageBox>
                    <Col spaceBetween height="100%">
                    <TagType type={item.boost_type}>{item.boost_type === 'conversion' ? 'Conversão' : item.boost_type === 'whatsapp' ? 'Whatsapp' : 'Alcance'}</TagType>
                    <div style={{fontSize: px2vw(12)}} className="text" alt={(item.boost_type === 'whatsapp' && !item?.product?.name) ? 'Imagem inserida manualmente.' : ''}>
                      {item.boost_type === 'conversion' && item?.coupon?.short_display_name &&
                        item?.coupon?.short_display_name}
                        {(item.boost_type === 'range' || item.boost_type === 'whatsapp') && item?.product?.name}
                        {(item.boost_type === 'whatsapp' && !item?.product?.name) && 'Imagem inserida manualmente.'}
                    </div>
                    </Col>
                  </Content>
                  <Content>
                    {item.advertiserBoostCampaignStats.reach === 1
                      ? `${item.advertiserBoostCampaignStats.reach} Pessoa`
                      : `${item.advertiserBoostCampaignStats.reach} Pessoas`}
                  </Content>
                  <Content>
                    {item.advertiserBoostCampaignStats.clicks
                      ? item.advertiserBoostCampaignStats.clicks
                      : '0'}
                  </Content>
                  <Content>
                    {item.budget
                      ? screenAmountMask(item.budget.toFixed(2))
                      : screenAmountMask(0)}
                  </Content>
                  <Content>
                    <TableStatus
                      status={item.status}
                      finishAt={item.finish_at}
                      noColorText
                    />
                  </Content>
                  <Content>
                    {item.finish_at &&
                      moment(item.finish_at).format('DD/MM/YYYY')}
                  </Content>
                  <Content width="20%">
                    <ButtonSecondary
                      height={25}
                      borderRadius={5}
                      fontSize={px2vw(13)}
                      marginRight={px2vw(20)}
                      margleft={px2vw(-50)}
                    >
                      Detalhes
                    </ButtonSecondary>
                  </Content>
                </TableRow>
                <Row>
                  <Hr />
                </Row>
              </Fragment>
            ) : null
          )
        ) : (
          <Row padding="15px 0 10px 0">Nenhum anúncio no momento.</Row>
        )}
        {structure && (
          <Row alignCenter justifyEnd>
            <Row alignCenter justifyEnd height={px2vw(40)} margin="10px">
              <TablePerPage response={handlePerPage} length={rows.length} />
              <Row alignCenter justifyCenter width="10%">
                {!structure[currentPage] && handleChangePage('prev')}
                {structure[currentPage] && structure[currentPage].start} -{' '}
                {structure[currentPage] && structure[currentPage].end} de{' '}
                {rows.length}
              </Row>
              <PagButton
                onClick={() => handleChangePage('prev')}
                disabled={currentPage === firstPage}
                title="Página anterior"
              >
                <PrevIcon disabled={currentPage === firstPage} />
              </PagButton>
              <PagButton
                onClick={() => handleChangePage('next')}
                title="Próxima página"
                disabled={currentPage === lastPage}
              >
                <NextIcon disabled={currentPage === lastPage} />
              </PagButton>
            </Row>
          </Row>
        )}
      </Col>
      {/* Campaign Detail */}
      {!!selectedCampaign && (
        <AdsDetail
          handleVisible={() => handleCloseCampaignDetails()}
          data={selectedCampaign}
        />
      )}
    </>
  );
}
