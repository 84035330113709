import { takeLatest, call, put, all, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { apiAnunciante } from '../../../services/api';

import {
  updateOnboardingStoreConfigsSuccess,
  updateOnboardingStoreConfigsFailure,
  updateOnboardingStepSuccess,
  updateOnboardingStepFailure
} from './actions';
import { updateUserInfoSuccess, getUserSuccess } from '../user/actions';
import {
  logout
} from '../auth/actions';

import { sendOnboardingInfo } from '../../../services/vendors/mixpanel';

const userReducerData = (state) => state.user;
const userData = (state) => state.user.user;

export function* updateOnboardingStepRequest({ payload }) {
  try {
    const user = yield select((state) => state.user.user);
    const userReducer = yield select(userReducerData);
    let concludedOnboardingStep = null
    switch (payload.onboardingStep) {
      case 'step1':
        concludedOnboardingStep = 'initial';
        break;
      case 'step2':
        concludedOnboardingStep = 'step1';
        break;
      case 'step3':
        concludedOnboardingStep = 'step2';
        break;
      case 'step4':
        concludedOnboardingStep = 'step3';
        break;
      case 'step5':
        concludedOnboardingStep = 'step4';
        break;
      case 'finished':
        concludedOnboardingStep = 'step5';
        break;
      default:
        break; 
    }

    const response = yield call(
      apiAnunciante.put,
      `/user-info/${user.advertiserInfo.id}/onboarding/set-step`,
      { 
        onboarding_step: payload.onboardingStep
      }
    );
    
    //MixPanel Event
    sendOnboardingInfo({
      id: user.advertiserInfo.id,
      name: user.advertiserInfo.name,
      email: user.email,
      type: concludedOnboardingStep,
      currOnboardingStep: 'Concluded',
      hasFreeTrialValid: userReducer.hasFreeTrialValid
    });

    // Save in redux the user info data
    yield put(updateUserInfoSuccess(response.data));
    yield put(updateOnboardingStepSuccess(response.data.advertiserOnboardingSurvey));
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      if (payload.onboardingStep === 'step1') {
        toast.error(
          'Não conseguimos iniciar o processo de onboarding, tente novamente e caso o erro persista, entre em contato com o suporte!',
          {
            autoClose: 6000,
          }
        );
      } else if (payload.onboardingStep === 'finished') {
        toast.error(
          'Não conseguimos finalizar o processo de onboarding, tente novamente e caso o erro persista, entre em contato com o suporte!',
          {
            autoClose: 6000,
          }
        );
      } else {
        toast.error(
          'Não conseguimos atualizar o passo atual do onboarding, tente novamente e caso o erro persista, entre em contato com o suporte!',
          {
            autoClose: 6000,
          }
        ); 
      }
      
      yield put(updateOnboardingStepFailure());
    }
  }
}

export function* updateOnboardingStoreConfigsRequest({ payload }) {
  try {
    const user = yield select(userData);
    const response = yield call(
      apiAnunciante.put,
      `/user-info/${user.advertiserInfo.id}/onboarding/store-configs`,
      {
        logo: {
          base64Content: payload.fileLogoData.base64,
          name: payload.fileLogoData.name,
          type: payload.fileLogoData.type,
          sub_type: payload.fileLogoData.subtype,
          image_width: payload.fileLogoData.width,
          image_height: payload.fileLogoData.height,
        },
        cover: {
          base64Content: payload.fileCoverData.base64,
          name: payload.fileCoverData.name,
          type: payload.fileCoverData.type,
          sub_type: payload.fileCoverData.subtype,
          image_width: payload.fileCoverData.width,
          image_height: payload.fileCoverData.height,
        },
        name: payload.name,
        cpf_cnpj: payload.cpfCnpj,
        representative_name: payload.representativeName,
        email: payload.email,
        phone_whatsapp_representative: `55${payload.phoneWhatsappRepresentative}`,
        address: {
          street: payload.street,
          number: payload.number,
          complement: payload.complement,
          neighborhood: payload.neighborhood,
          city: payload.city,
          state: payload.state,
          zipcode: payload.zipcode,
          phone_whatsapp: `55${payload.phoneWhatsapp}`,
        },
        advertiser_category_id: payload.advertiserCategoryId,
      }
    );

    // Save in redux the user data
    yield put(getUserSuccess(response.data));
    yield put(updateOnboardingStoreConfigsSuccess());
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      if (err?.response?.data?.error?.message) {
        toast.error(err.response.data.error.message, {
          autoClose: 6000,
        });
      } else {
        toast.error('Ocorreu um erro ao salvar os dados. Tente novamente!', {
          autoClose: 6000,
        });
      }
      yield put(updateOnboardingStoreConfigsFailure());
    }
  }
}

export default all([
  takeLatest('@onboarding/UPDATE_ONBOARDING_STEP_REQUEST', updateOnboardingStepRequest),
  takeLatest(
    '@onboarding/UPDATE_ONBOARDING_STORE_CONFIGS_REQUEST',
    updateOnboardingStoreConfigsRequest
  ),
]);
