import React from 'react';

import { Row, Col } from '../../../../../../styles/grid';
import px2vw from '../../../../../../utils/responsiveness/px2vw';

import {
  PageTitle,
  PageText,
  TermsLink,
  BlockedImage,
} from '../../../../styles';

import { 
  PageTextNotRenewPayment, 
  PageTextRenewPayment 
} from '../constants';
import { 
  BlockedAccessText1, 
  BlockedAccessText2, 
  PageTextTermsUseText1, 
  PageTextTermsUseText2 
} from '../../constants';

export default function HeaderBlockedSignatureWeb({ handler }) {
  return (
    <Row>
      <BlockedImage
        src={require('../../../../../../assets/images/blocked_signature/blocked_signature.png')}
      />
      <Col margleft={px2vw(40)}>
        <Row>
          <PageTitle>
            {BlockedAccessText1}
          </PageTitle>
        </Row>
        <Row margleft={px2vw(5)}>
          <PageTitle>
            {BlockedAccessText2}
          </PageTitle>
        </Row>
        <Row justifyStart margtop={px2vw(20)} margbottom={px2vw(20)}>
          <PageText>
            {/* First payment */}
            {!handler.isRenewPayment && PageTextNotRenewPayment}
            {/* Renew payment */}
            {handler.isRenewPayment && PageTextRenewPayment.replace('$$VALIDITY$$', handler.signaturePaymentData?.start_validity_at)}
            { /* First and Renew */}
            <TermsLink to={handler.termsOfUseLink} target="_blank">
              {PageTextTermsUseText1}
            </TermsLink>
            {PageTextTermsUseText2}
          </PageText>
        </Row>
      </Col>
    </Row>
  )
}