import React, { useState } from 'react';
import { Icon } from 'react-materialize';

import { Container, ChangeButton, ModalEdit, ButtonEdit } from './styles';
import { SvgBox, Close, Close2 } from './icons';

export default function ButtonOptionsModal({ buttonsClicks, buttons, width }) {
  const [visible, setVisible] = useState(false);

  const handleVisible = () => {
    setVisible(!visible);
  };

  function handleEdit(idx) {
    setVisible(!visible);
    buttonsClicks[idx]();
  }

  return (
    <Container>
      <ModalEdit visible={visible} width={width}>
        {buttons.map((bt, idx) =>
          idx === 0 ? (
            <ButtonEdit key={`buttonEdit-${idx}`} onClick={() => handleEdit(idx)}>{bt}</ButtonEdit>
          ) : (
            <ButtonEdit key={`buttonEdit-${idx}`} margtop="10px" onClick={() => handleEdit(idx)}>
              {bt}
            </ButtonEdit>
          )
        )}
      </ModalEdit>
      {!visible && (
        <ChangeButton onClick={handleVisible} visible={visible}>
          <Icon>more_vert</Icon>
        </ChangeButton>
      )}
      {visible && (
        <ChangeButton zi="1" onClick={handleVisible} visible={visible}>
          <SvgBox>
            <Close />
            <Close2 />
          </SvgBox>
        </ChangeButton>
      )}
    </Container>
  );
}
