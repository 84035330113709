import React from 'react';
import { Spinner } from 'reactstrap';

import px2vw from '../../../../utils/responsiveness/px2vw';
import { preto, verdeKuppi11 } from '../../../../styles/colors';
import { Row } from '../../../../styles/grid';

import ButtonPrimary from '../../../../components/ButtonPrimary';

import { LoadingBox } from '../../../../styles/common';

import Checkbox from '../../../../components/Checkbox';

import {
  QuestionTitleText,
  FormData,
  CheckboxContainer,
} from '../../BaseScreen/styles';

export default function PageOneWeb({ handler }) {
  return (
    <>
      <Row margtop={px2vw(50)} margbottom={px2vw(30)}>
        <QuestionTitleText fontsize={px2vw(20)}>
          1 - Qual o principal objetivo você busca atingir utilizando a Kuppi?
        </QuestionTitleText>
      </Row>
      <FormData onSubmit={handler.handleSurveyObjectivesSubmit} web>
        <CheckboxContainer
          width={px2vw(600)}
          height={px2vw(30)}
          backgroundColor={verdeKuppi11}
          onClick={() => handler.objectiveOptionClick('increase_sales')}
        >
          <Checkbox
            label="Aumentar minhas vendas no Delivery"
            name="objectiveIncreaseSales"
            checked={handler.objectiveIncreaseSales}
          />
        </CheckboxContainer>
        <CheckboxContainer
          width={px2vw(600)}
          height={px2vw(30)}
          backgroundColor={verdeKuppi11}
          onClick={() => handler.objectiveOptionClick('reduce_marketplace_dependency')}
        >
          <Checkbox
            label="Reduzir minha dependência dos marketplaces"
            name="objectiveReduceMarketplaceDependency"
            checked={handler.objectiveReduceMarketplaceDependency}
          />
        </CheckboxContainer>
        <CheckboxContainer
          width={px2vw(600)}
          height={px2vw(30)}
          backgroundColor={verdeKuppi11}
          onClick={() => handler.objectiveOptionClick('optimize_delivery_operation')}
        >
          <Checkbox
            label="Automatizar o atendimento do meu delivery"
            name="objectiveOptimizeDeliveryOperation"
            checked={handler.objectiveOptimizeDeliveryOperation}
          />
        </CheckboxContainer>
        <CheckboxContainer
          width={px2vw(600)}
          height={px2vw(30)}
          backgroundColor={verdeKuppi11}
          onClick={() => handler.objectiveOptionClick('growth_marketing_strategy')}
        >
          <Checkbox
            label="Melhorar as estratégias de marketing do meu negócio"
            name="objectiveGrowthMarketingStrategy"
            checked={handler.objectiveGrowthMarketingStrategy}
          />
        </CheckboxContainer>
        <CheckboxContainer
          width={px2vw(600)}
          height={px2vw(30)}
          backgroundColor={verdeKuppi11}
          onClick={() => handler.objectiveOptionClick('fidelize_clients')}
        >
          <Checkbox
            label="Fidelizar cada vez mais clientes"
            name="objectiveFidelizeClients"
            checked={handler.objectiveFidelizeClients}
          />
        </CheckboxContainer>
        <CheckboxContainer
          width={px2vw(600)}
          height={px2vw(30)}
          backgroundColor={verdeKuppi11}
          onClick={() => handler.objectiveOptionClick('not_sure_exploring_product')}
        >
          <Checkbox
            label="Não tenho certeza ainda, estou apenas explorando o produto"
            name="objectiveNotSureExploringProduct"
            checked={handler.objectiveNotSureExploringProduct}
          />
        </CheckboxContainer>
        <Row justifyEnd margtop={px2vw(10)}>
          <ButtonPrimary minWidth={150} type="submit">
            {handler.updateSurveyObjectivesLoading ? (
              <LoadingBox>
                <Spinner
                  style={{
                    width: '1.8rem',
                    height: '1.8rem',
                    color: preto,
                  }}
                />{' '}
              </LoadingBox>
            ) : (
              'Avançar'
            )}
          </ButtonPrimary>
        </Row>
      </FormData>
    </>
  );
}
