import React from "react";

import { Col, Row } from "../../../../../../styles/grid";
import { 
  GeneralText, 
  OutlinedBox, 
  OutlinedDiv 
} from "../../../../../../styles/common";
import { 
  branco, 
  verdeKuppi2, 
  verdeKuppi4, 
  cinzaKuppi4 
} from "../../../../../../styles/colors";
import { PageText } from '../../../../styles';
import {
  BoostCredits
} from '../styles';

import px2vw from "../../../../../../utils/responsiveness/px2vw";
import { formatCurrencyBRL } from "../../../../../../utils/utils";

import { 
  BonusObsText,
  BonusText1,
  BonusText2,
  CardRealCents, 
  CardRealSign,
  DiscountText, 
  SaveMoneyText1, 
  SaveMoneyText2 
} from "../constants";
import MostRecommendedBox from "../MostRecommendedBox";

export default function PlanOptionsMobile({ handler }) {
  const showBoostDiscount = handler.selectedPlan?.boost_credits_amount !== 0 && handler.showKuppiBoostDiscounts;
  const isRecommended = handler.selectedPlan?.is_recommended;

  return (
    <Col 
      padtop={px2vw(40)}
      padbottom={px2vw(40)}
      padleft={px2vw(50)}
      padright={px2vw(50)}
      alignCenter
    >
      {/* Plan Choose Box */}
      {handler.newPlanOptions.length > 1 && (
        <OutlinedDiv 
          border={`${px2vw(2)} solid ${cinzaKuppi4}`}
          borderradius={px2vw(40)}
        >
          {handler.newPlanOptions.map((plan, idx) => {
            return (
              <Row
                height="100%"
                borderRadius={
                  idx === 0 ? `${px2vw(40)} ${px2vw(0)} ${px2vw(0)} ${px2vw(40)}` :
                  idx === handler.newPlanOptions.length - 1 ? `${px2vw(0)} ${px2vw(20)} ${px2vw(20)} ${px2vw(0)}` :
                  px2vw(0)
                }
                key={idx}
                onClick={() => {
                  handler.setSelectedPlan(plan);
                }}
              >
                <Col
                  alignCenter
                  borderRadius={
                    idx === 0  ? `${px2vw(40)} ${px2vw(0)} ${px2vw(0)} ${px2vw(40)}` :
                    idx === handler.newPlanOptions.length - 1 ? `${px2vw(0)} ${px2vw(20)} ${px2vw(20)} ${px2vw(0)}` :
                    px2vw(0)
                  }
                >
                  {plan.discount_percentage !== 0 && (
                    <GeneralText 
                      width="100%"
                      fontsize={px2vw(50)}
                      fontweight="bold"
                      color={
                        handler.selectedPlan?.name === plan.name ? branco : verdeKuppi4
                      }
                      background={
                        handler.selectedPlan?.name === plan.name ? verdeKuppi2 : branco
                      }
                      padtop={px2vw(40)}
                      borderradius={
                        idx === 0  ? `${px2vw(40)} ${px2vw(0)} ${px2vw(0)} ${px2vw(40)}` :
                        idx === handler.newPlanOptions.length - 1 ? `${px2vw(0)} ${px2vw(40)} ${px2vw(0)} ${px2vw(0)}` :
                        px2vw(0)
                      }
                      borderLeft={
                        plan.is_recommended ? `${px2vw(2)} solid ${cinzaKuppi4}` : 'none'
                      }
                      borderRight={
                        plan.is_recommended ? `${px2vw(2)} solid ${cinzaKuppi4}` : 'none'
                      }
                    >
                      {`${plan.discount_percentage}% OFF`}
                    </GeneralText>
                  )}
                  <GeneralText
                    height="100%"
                    fontweight="bold"
                    padtop={plan.discount_percentage !== 0 ? px2vw(22) : px2vw(79)}
                    padbottom={plan.discount_percentage !== 0 ? px2vw(22) : px2vw(79)}
                    padleft={px2vw(73)}
                    padright={px2vw(73)}
                    color={
                      handler.selectedPlan?.name === plan.name ? branco : verdeKuppi2
                    }
                    fontsize={px2vw(60)}
                    background={
                      handler.selectedPlan?.name === plan.name ? verdeKuppi2 : branco
                    }
                    borderradius={
                      idx === 0  ? `${px2vw(40)} ${px2vw(0)} ${px2vw(0)} ${px2vw(40)}` :
                      idx === handler.newPlanOptions.length - 1 ? `${px2vw(0)} ${px2vw(plan.discount_percentage !== 0 ? 0 : 40)} ${px2vw(40)} ${px2vw(0)}` :
                      px2vw(0)
                    }
                    borderLeft={
                      plan.is_recommended ? `${px2vw(2)} solid ${cinzaKuppi4}` : 'none'
                    }
                    borderRight={
                      plan.is_recommended ? `${px2vw(2)} solid ${cinzaKuppi4}` : 'none'
                    }
                  >
                    {plan.name.replace('Plano ', '')}
                  </GeneralText>
                </Col>
              </Row>
            );
          })}
        </OutlinedDiv>
      )}
      {/* Plan Card */}
      {isRecommended && (
        <MostRecommendedBox isMobile />
      )}
      <OutlinedBox 
        isRecommended={isRecommended || handler.newPlanOptions.length === 1}
        flexdirection="column" 
        width={px2vw(1200)}
        minheight={showBoostDiscount ? px2vw(1070) : px2vw(970)}
        textalign="center"
        justifycontent="flex-start"
      >
        {showBoostDiscount && (
          <Row margbottom={px2vw(40)} height="auto">
            <BoostCredits isMobile>
              <Row width='auto' alignCenter>
                <span>
                  <span>{BonusText1}</span>
                  <span className="valueColor">R$ {handler.selectedPlan?.boost_credits_amount}</span>
                  <span>{BonusText2}</span>
                </span>
              </Row>
            </BoostCredits>
          </Row>
        )}
        {handler.selectedPlan?.discount_percentage !== 0 && (
          <GeneralText 
            fontsize={px2vw(70)} 
            padtop={showBoostDiscount ? px2vw(40) : px2vw(100)} 
            padbottom={showBoostDiscount ? px2vw(40) : px2vw(100)} 
            fontweight="bold"
            color={verdeKuppi4}
          >
            {`${handler.selectedPlan?.discount_percentage}${DiscountText}`}
          </GeneralText>
        )}
        <GeneralText 
          fontsize={px2vw(70)}
          padtop={handler.selectedPlan?.discount_percentage === 0 ? !showBoostDiscount ? px2vw(180) : px2vw(160) : 0}
        >
          Plano
        </GeneralText>
        <GeneralText fontsize={px2vw(70)} fontweight="bold">
          {handler.selectedPlan?.name?.replace('Plano ', '')}
        </GeneralText>
        <Row 
          height="auto" 
          margtop={handler.selectedPlan?.discount_percentage === 0 && !showBoostDiscount ? px2vw(100) : 0}
        >
          <GeneralText
            padtop={px2vw(100)}
            padleft={px2vw(40)}
            fontsize={px2vw(100)}
            fontweight="bold"
          >
            {CardRealSign}
          </GeneralText>
          <GeneralText
            padtop={px2vw(10)}
            fontsize={px2vw(180)}
            fontweight="bold"
          >
            {handler.selectedPlan?.new_subscription_amount}
          </GeneralText>
          <GeneralText
            padtop={px2vw(100)}
            fontsize={px2vw(100)}
            fontweight="bold"
          >
            {CardRealCents}
          </GeneralText>
        </Row>
        {handler.selectedPlan?.discount_percentage !== 0 && (
          <GeneralText 
            fontsize={px2vw(50)}
            padbottom={px2vw(40)}
          >
            {`${SaveMoneyText1} ${formatCurrencyBRL(handler.selectedPlan?.saved_amount, {})} ${SaveMoneyText2}`}
          </GeneralText>
        )}
      </OutlinedBox>
      {showBoostDiscount && (
        <Col margtop={px2vw(50)}>
          <PageText fontsize={px2vw(43)} alignCenter>
            {BonusObsText}
          </PageText>
        </Col>
      )}
    </Col>
  )
}
