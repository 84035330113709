import React from "react";

import { Row, Col } from '../../../../styles/grid';
import {
  ActiveTextYellow,
  PrintBoletoButton,
  PrintButtonBox,
  PrintIcon,
  AlertPaymentImage,
  GoToPaymentLink,
  CopyBoletoCode,
  CopyBoletoCodeTextBold,
} from '../styles';
import {
  SuccessPaymentImage,
  PageTitle,
  PageText,
  SectionBox,
  OrderSumaryTextBold,
  OrderSumaryText,
} from '../../styles';

import px2vw from '../../../../utils/responsiveness/px2vw';
import { cpfCnpjMask } from '../../../../utils/masks';
import { formatCurrencyBRL } from '../../../../utils/utils';

import {
  PageTitleSuccess,
  PageTitleExpired,
  PageTextExpired,
  PageTextNotExpiredBlocked,
  PageTextNotExpiredRenewNotBlocked,
  PageTextNotExpiredFirstPayment,
  BoletoAmountText,
  BoletoExpirationText,
  BoletoPrintText,
  BoletoPaymentInfo
} from '../constants';
import {
  PlanPendingPaymentText,
  VigencyText,
  AdvertiserNameText,
  ChangePaymentMethodText,
  CPFCNPJText,
  CopyPaymentCodeText,
} from '../../constants';

export default function BoletoGeneratedSuccessMobile({ handler }) {
  return (
    <>
      <Row justifyStart alignCenter margbottom={px2vw(70)}>
        {handler.boletoExpired && (
          <AlertPaymentImage
            width={px2vw(170)}
            height={px2vw(172)}
            margright={px2vw(80)}
            margtop={px2vw(-10)}
            src={require('../../../../assets/images/attention/attention.png')}
          />
        )}
        {!handler.boletoExpired && (
          <SuccessPaymentImage
            size={px2vw(150)}
            margright={px2vw(80)}
            src={require('../../../../assets/images/success/success.png')}
          />
        )}
        <PageTitle fontsize={px2vw(70)}>
          {handler.boletoExpired && PageTitleExpired}
          {!handler.boletoExpired && PageTitleSuccess}
        </PageTitle>
      </Row>
      <Row margbottom={px2vw(90)}>
        <PageText fontsize={px2vw(50)}>
          {handler.boletoExpired && PageTextExpired.replace('$$BOLETO-EXPIRATION$$', handler.signaturePaymentData?.boleto_expiration_at)}
          {!handler.boletoExpired && handler.areBlocked && PageTextNotExpiredBlocked}
          {!handler.boletoExpired && handler.isRenewPayment && !handler.areBlocked && PageTextNotExpiredRenewNotBlocked}
          {!handler.boletoExpired && handler.isFirstPayment && PageTextNotExpiredFirstPayment}
        </PageText>
      </Row>
      <SectionBox
        margbottom={px2vw(160)}
      >
        <Col 
          padtop={px2vw(40)}
          padbottom={px2vw(40)}
          padleft={px2vw(50)}
          padright={px2vw(50)}
          alignStart
        >
          <OrderSumaryText fontSize={px2vw(50)}>
            {AdvertiserNameText}
          </OrderSumaryText>
          <OrderSumaryTextBold
            fontSize={px2vw(50)}
            maxChar="33ch"
            advertiserName
          >
            {handler.signaturePaymentData?.advertiserInfo?.name}
          </OrderSumaryTextBold>
          <Col justifyStart margtop={px2vw(60)}>
            <OrderSumaryText fontSize={px2vw(50)}>
              {CPFCNPJText}
            </OrderSumaryText>
            <OrderSumaryTextBold fontSize={px2vw(50)}>
              {cpfCnpjMask(handler.signaturePaymentData?.advertiserInfo?.cpf_cnpj)}
            </OrderSumaryTextBold>
          </Col>
          <Col justifyStart margtop={px2vw(60)}>
            <OrderSumaryTextBold fontSize={px2vw(50)}>
              {`${handler.signaturePaymentData?.subscription?.name}`}
            </OrderSumaryTextBold>
            <ActiveTextYellow fontSize={px2vw(50)}>
              {PlanPendingPaymentText}
            </ActiveTextYellow>
          </Col>
          <Col justifyStart margtop={px2vw(60)}>
            <OrderSumaryText fontSize={px2vw(50)}>
              {VigencyText}
            </OrderSumaryText>
            <OrderSumaryTextBold fontSize={px2vw(50)}>
              {handler.vigencyInfo}
            </OrderSumaryTextBold>
          </Col>
          <Row alignStart margtop={px2vw(60)}>
            <Col alignStart>
              <OrderSumaryText fontSize={px2vw(50)}>
                {BoletoAmountText}
              </OrderSumaryText>
              <OrderSumaryTextBold fontSize={px2vw(50)}>
                {formatCurrencyBRL(
                  (
                    Math.round(
                      handler.signaturePaymentData?.amount *
                        handler.signaturePaymentData?.subscription?.period *
                        100
                    ) / 100
                  ).toFixed(2),
                  {}
                )}
              </OrderSumaryTextBold>
            </Col>
            <Col alignEnd>
              <OrderSumaryText fontSize={px2vw(50)}>
                {BoletoExpirationText}
              </OrderSumaryText>
              <OrderSumaryTextBold fontSize={px2vw(50)}>
                {handler.signaturePaymentData?.boleto_expiration_at}
              </OrderSumaryTextBold>
            </Col>
          </Row>
          {handler.boletoExpired && (
            <Row justifyCenter margtop={px2vw(60)}>
              <GoToPaymentLink
                expired
                width={px2vw(1000)}
                height={px2vw(150)}
                fontSize={px2vw(60)}
                borderradius={px2vw(30)}
                onClick={() => handler.handleChangePaymentMethod() }
              >
                {ChangePaymentMethodText}
              </GoToPaymentLink>
            </Row>
          )}
          {!handler.boletoExpired && (
            <>
              <Col margtop={px2vw(60)} alignCenter justifyCenter>
                <OrderSumaryText fontSize={px2vw(50)}>
                  {BoletoPaymentInfo}
                </OrderSumaryText>
                <Row width="auto">
                  <OrderSumaryTextBold 
                    fontSize={px2vw(50)}
                    className="copy" 
                    id="boletoBarCodeCopy" 
                  >
                    {handler.signaturePaymentData?.boleto_bar_code}
                  </OrderSumaryTextBold>
                </Row>
              </Col>
              <Col alignCenter justifyCenter margtop={px2vw(60)}>
                <CopyBoletoCode
                  onClick={() => handler.handleCopyPaymentCode('boletoBarCodeCopy')}
                  width={px2vw(1000)}
                  height={px2vw(150)}
                  borderradius={px2vw(30)}
                >
                  <CopyBoletoCodeTextBold fontSize={px2vw(60)}>
                    {CopyPaymentCodeText}
                  </CopyBoletoCodeTextBold>
                </CopyBoletoCode>
                <Row margtop={px2vw(55)}>
                  <PrintBoletoButton
                    width={px2vw(1000)}
                    height={px2vw(150)}
                    borderradius={px2vw(30)}
                    onClick={() => {
                      window.open(
                        `${handler.signaturePaymentData?.boleto_url}`,
                        '_blank',
                        'width=800,height=700,top=100,left=110'
                      );
                    }}
                  >
                    <PrintButtonBox>
                      <PrintIcon
                        margright={px2vw(70)}
                        size={px2vw(80)}
                      />
                      <OrderSumaryTextBold fontSize={px2vw(60)}>
                        {BoletoPrintText}
                      </OrderSumaryTextBold>
                    </PrintButtonBox>
                  </PrintBoletoButton>
                </Row>
                <Row margtop={px2vw(55)}>
                  <GoToPaymentLink
                    width={px2vw(1000)}
                    height={px2vw(150)}
                    fontSize={px2vw(60)}
                    borderradius={px2vw(30)}
                    onClick={() => {
                      handler.handleChangePaymentMethod();
                    }}
                  >
                    {ChangePaymentMethodText}
                  </GoToPaymentLink>
                </Row>
              </Col>
            </>
          )}
        </Col>
      </SectionBox>
    </>
  )
}
