import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Spinner } from 'reactstrap';

import {
  preto,
} from '../../../styles/colors';

import {
  CategoryModal,
  ModalTitleText,
  CategoryInputBox,
  CategoryInput,
  CategoryInputLabel,
  RemainingCharacters,
  FormCategory,
} from '../styles';

import {
  LoadingBox,
} from '../../_layouts/styles/common';

import Modal from '../../../components/Modal';
import {
  ModalHeader,
  CloseButton,
  CloseImage,
  ModalPrimaryButton,
  ModalPrimaryButtonText,
  ModalActions,
  ModalSecundaryButton,
  ModalSecundaryButtonText,
} from '../../../components/Modal/styles';

import px2vw from '../../../utils/responsiveness/px2vw';

import {
  createNewCategoryRequest,
  updateCategoryNameRequest,
} from '../../../store/modules/productCategories/actions';

export default function CatalogCategoryModal({ handler }) {
  const [categoryNameInputValue, setCategoryNameInputValue] = useState('');
  const [categoryNameRemainingChars, setCategoryNameRemainingChars] = useState(
    0
  );
  const categoryModalLoading = useSelector(
    (state) => state.productCategories.categoryModalLoading
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (handler.handleCategoryTypeModal === 'edit') {
      handleCategoryNameChange(handler.editCategoryData.name);
    }
  }, [handler.handleCategoryTypeModal])

  // Handle close new/edit category modal
  const handleCloseCategoryModal = () => {
    setCategoryNameRemainingChars(0);
    setCategoryNameInputValue('');
    handler.handleCloseCategoryModal();
  };

  //Set the remaining chars of new/edit category name
  function handleCategoryNameChange(newValue) {
    setCategoryNameRemainingChars(newValue.length);
    setCategoryNameInputValue(newValue);
  }

  // Handle form submit for new/edit category
  const handleCategorySubmit = ({ categoryName }) => {
    if (categoryName === '') {
      toast.error('Preencha o nome da categoria!', {
        autoClose: 6000,
      });
    } else if (handler.handleCategoryTypeModal === 'new') {
      dispatch(createNewCategoryRequest(categoryName));
    } else {
      dispatch(updateCategoryNameRequest(handler.editCategoryData.id, categoryName));
    }
  };

  return (
    <Modal
      isVisible={handler.handleCategoryModal}
      width={px2vw(370)}
      padding={px2vw(25)}
    >
      <CategoryModal>
        <ModalHeader>
          <ModalTitleText>
            {handler.handleCategoryTypeModal === 'new'
              ? 'Criar categoria'
              : 'Editar categoria'}
          </ModalTitleText>
          <CloseButton
            onClick={handleCloseCategoryModal}
            disabled={categoryModalLoading}
          >
            <CloseImage
              src={require('../../../assets/images/close/close.png')}
            />
          </CloseButton>
        </ModalHeader>
        <FormCategory onSubmit={handleCategorySubmit}>
          <CategoryInputBox>
            <CategoryInputLabel>Nome da categoria</CategoryInputLabel>
            <CategoryInput
              id="categoryName"
              name="categoryName"
              placeholder="Pizzas"
              maxLength="40"
              value={categoryNameInputValue}
              onChange={(t) => handleCategoryNameChange(t.target.value)}
            />
            <RemainingCharacters>
              {categoryNameRemainingChars}/40 caracteres
            </RemainingCharacters>
          </CategoryInputBox>
          <ModalActions
            width="100%"
            justifyContent="space-around"
            margtop={px2vw(15)}
          >
            <ModalSecundaryButton
              width={px2vw(130)}
              type="button"
              onClick={handleCloseCategoryModal}
              disabled={categoryModalLoading}
            >
              <ModalSecundaryButtonText>Cancelar</ModalSecundaryButtonText>
            </ModalSecundaryButton>
            <ModalPrimaryButton
              id="categorySaveButton"
              width={px2vw(130)}
              type="submit"
              disabled={categoryModalLoading}
            >
              {categoryModalLoading ? (
                <LoadingBox>
                  <Spinner
                    style={{
                      width: '1.8rem',
                      height: '1.8rem',
                      color: preto,
                    }}
                  />{' '}
                </LoadingBox>
              ) : (
                <ModalPrimaryButtonText>Salvar</ModalPrimaryButtonText>
              )}
            </ModalPrimaryButton>
          </ModalActions>
        </FormCategory>
      </CategoryModal>
    </Modal>
  );
}
