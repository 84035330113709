import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Icon } from 'react-materialize';
import { Spinner } from 'reactstrap';

import { PageTitle, ContentPage } from '../_layouts/styles/common';
import {
  preto,
  verdeKuppi1,
  amareloKuppi2,
} from '../../styles/colors';
import { Row, Col } from '../../styles/grid';
import {
  ChartBox,
  ActionsMenuBox,
  SearchBox,
  SearchButton,
  SearchImage,
  SearchInput,
  ClientsDataBox,
  ErrorText,
  TitleStyleText,
  BoxIcon,
  IconBox,
  RegularStyleText,
  BoxSubtitle,
  ColoredBar,
  SearchCloseButton,
} from './styles';

import ClientsTable from './ClientsTable';

import px2vw from '../../utils/responsiveness/px2vw';

import {
  getClientsRequest,
  getClientsMetricsRequest,
} from '../../store/modules/clients/actions';

export default function Clients() {
  const [clientSearchData, setClientSearchData] = useState('');
  const [searchedClients, setSearchedClients] = useState(null);
  const clients = useSelector((state) => state.clients.clients);
  const clientsMetrics = useSelector((state) => state.clients.clientsMetrics);
  const getClientsLoading = useSelector(
    (state) => state.clients.getClientsLoading
  );
  const error = useSelector((state) => state.clients.error);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getClientsRequest());
    dispatch(getClientsMetricsRequest());
  }, [dispatch]);

  function handleClearSearchClient() {
    setSearchedClients(null);
    setClientSearchData('');
  }

  function handleSearchClient() {
    const filteredClients = clients.filter((client) => (client.name && client.name.toLowerCase().includes(clientSearchData.toLowerCase())) || (client.phone && client.phone.includes(clientSearchData)));
    setSearchedClients([...filteredClients]);
  }

  const InfoBox = () => {
    return (
      <ChartBox isTitleBox>
        <Row spaceBetween height="auto">
          <TitleStyleText>Clientes com Pedidos</TitleStyleText>
        </Row>
        <Row justifyStart alignCenter height={px2vw(100)}>
          <TitleStyleText hugeNumber>{clientsMetrics.total_clients_with_orders || 0}</TitleStyleText>
          <IconBox><BoxIcon src={require('../../assets/images/clients/clients_box.png')} /></IconBox>
        </Row>
        <Row justifyCenter alignStart spaceBetween margtop={px2vw(5)} height="auto" margbottom={px2vw(10)}>
          <Row>
            <ColoredBar bgColor={verdeKuppi1} />
            <BoxSubtitle>{clientsMetrics.more_than_one_order_percentage || 0}% já pediram mais<br />de uma vez</BoxSubtitle>
          </Row>
          <Row>
            <ColoredBar bgColor={amareloKuppi2} />
            <BoxSubtitle>{clientsMetrics.one_order_percentage || 0}% pediram<br />somente 1 vez</BoxSubtitle>
          </Row>
        </Row>
      </ChartBox>
    )
  }

  const TagBox = ({ title, subTitle, clients }) => {
    return (
      <ChartBox isTagBox>
        <TitleStyleText isTagBox>{title}</TitleStyleText>
        <RegularStyleText isTagBox>{subTitle}</RegularStyleText>
        <RegularStyleText isTagBox>{`Exist${Number(clients) === 1 ? 'e' : 'em'}`} <span><b>{clients || 0}</b></span> {`usuário${Number(clients) === 1 ? '' : 's'}`} nessa lista</RegularStyleText>
      </ChartBox>
    )
  }

  const contentPage = (
    <ContentPage>
      <PageTitle>Meus Clientes</PageTitle>
      {/* Charts Dashboard */}
      <Row height='auto'>
        <Col height='auto' justifyStart margtop={px2vw(10)}>
          <InfoBox />
        </Col>
        <Col height='auto' justifyStart margtop={px2vw(10)}>
          <Row>
            <TagBox 
              title='Novos clientes'
              subTitle='Tem apenas um pedido e esse pedido está dentro dos últimos 30 dias.'
              clients={clientsMetrics.new}/>
            <TagBox 
              title='Recorrentes'
              subTitle='Realizou mais de um pedido e não é VIP.'
              clients={clientsMetrics.recurrent}/>
            <TagBox 
              title='VIPS'
              subTitle='Tem mais de 3 pedidos e os últimos 3 pedidos foram dentro dos 30 dias.'
              clients={clientsMetrics.vip}/>
          </Row>
          <Row height='auto' justifyStart margtop={px2vw(10)}>
            <TagBox 
              title='Em risco'
              subTitle='Já pediu pelo menos 1 vez e o último pedido foi a mais de 30 dias.'
              clients={clientsMetrics.risk}/>
            <TagBox 
              title='Potenciais clientes'
              subTitle='Contatos do WhatsApp que nunca realizaram um pedido.'
              clients={clientsMetrics.potential}/>
          </Row>
        </Col>
      </Row>

      {/* Clients data */}
      <ClientsDataBox>
        {getClientsLoading && (
          <Col height="20vh">
            <Col alignCenter justifyCenter>
              <Spinner
                style={{
                  width: '1.8rem',
                  height: '1.8rem',
                  color: preto,
                }}
              />
            </Col>
          </Col>
        )}
        {!getClientsLoading && (
          <>
            {/* Actions menu */}
            <ActionsMenuBox>
              <SearchBox>
                <SearchInput
                  maxLength="30"
                  placeholder="Busque o cliente pelo nome ou telefone"
                  value={clientSearchData}
                  onChange={(t) => setClientSearchData(t.target.value)}
                />
                <SearchButton
                  hasActiveSearch={searchedClients}
                  onClick={() => handleSearchClient()}
                >
                  <SearchImage
                    src={require('../../assets/images/search/search.png')}
                  />
                </SearchButton>
                {searchedClients && (
                  <SearchCloseButton
                    onClick={() => handleClearSearchClient()}
                  >
                    <SearchImage
                      hasActiveSearch={searchedClients}
                      src={require('../../assets/images/close/close.png')}
                    />
                  </SearchCloseButton>
                )}
              </SearchBox>
            </ActionsMenuBox>
            <ClientsTable data={!searchedClients ? clients : searchedClients} />
          </>
        )}
      </ClientsDataBox>
    </ContentPage>
  );

  const errorPage = (
    <ContentPage>
      <Col alignCenter height="80vh">
        <Row alignCenter justifyCenter>
          {/* TODO: Ajustar tamanho do icone */}
          <Icon large>sentiment_very_dissatisfied</Icon>
          <ErrorText>
            Ocorreu um erro ao carregar a lista de clientes. Tente novamente.
          </ErrorText>
        </Row>
      </Col>
    </ContentPage>
  );

  if (error) {
    return errorPage;
  }
  return contentPage;
}
