export function updateSurveyObjectivesRequest(
  surveyStep,
  objective
) {
  return {
    type: '@firstSteps/UPDATE_SURVEY_OBJECTIVE_REQUEST',
    payload: {
      surveyStep,
      objective,
    },
  };
}

export function updateSurveyObjectivesSuccess(firstStepsSurveyData) {
  return {
    type: '@firstSteps/UPDATE_SURVEY_OBJECTIVE_SUCCESS',
    payload: { firstStepsSurveyData },
  };
}

export function updateSurveyObjectivesFailure() {
  return {
    type: '@firstSteps/UPDATE_SURVEY_OBJECTIVE_FAILURE',
  };
}

export function updateSurveyFindOutAboutRequest(
  surveyStep,
  findOutAbout,
  findOutAboutOtherContent,
  deliveryHasComputer
) {
  return {
    type: '@firstSteps/UPDATE_SURVEY_FIND_OUT_ABOUT_REQUEST',
    payload: {
      surveyStep,
      findOutAbout,
      findOutAboutOtherContent,
      deliveryHasComputer
    },
  };
}

export function updateSurveyFindOutAboutSuccess(firstStepsSurveyData) {
  return {
    type: '@firstSteps/UPDATE_SURVEY_FIND_OUT_ABOUT_SUCCESS',
    payload: { firstStepsSurveyData },
  };
}

export function updateSurveyFindOutAboutFailure() {
  return {
    type: '@firstSteps/UPDATE_SURVEY_FIND_OUT_ABOUT_FAILURE',
  };
}

export function updateSurveyStoreDataRequest(
  surveyStep,
  deliveryType,
  dailyOrdersAvg,
  salesOwnChannelPercentage,
  salesMarketplacesPercentage,
) {
  return {
    type: '@firstSteps/UPDATE_SURVEY_STORE_DATA_REQUEST',
    payload: {
      surveyStep,
      deliveryType,
      dailyOrdersAvg,
      salesOwnChannelPercentage,
      salesMarketplacesPercentage,
    },
  };
}

export function updateSurveyStoreDataSuccess(firstStepsSurveyData) {
  return {
    type: '@firstSteps/UPDATE_SURVEY_STORE_DATA_SUCCESS',
    payload: { firstStepsSurveyData },
  };
}

export function updateSurveyStoreDataFailure() {
  return {
    type: '@firstSteps/UPDATE_SURVEY_STORE_DATA_FAILURE',
  };
}

export function setCurrSurveyPageStep(currSurveyPageStep) {
  return {
    type: '@firstSteps/SET_CURR_SURVEY_PAGE_STEP',
    payload: { currSurveyPageStep },
  };
}
