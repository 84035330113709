export function clearSuccess() {
  return {
    type: '@user/CLEAR_SUCCESS',
  };
}

export function updateUserInfoRequest(
  userName,
  representativeName,
  representativeWhatsapp,
  userCnpj,
  userCategory
) {
  return {
    type: '@user/UPDATE_USER_INFO_REQUEST',
    payload: { userName, representativeName, representativeWhatsapp, userCnpj, userCategory },
  };
}

export function updateUserInfoSuccess(userData) {
  return {
    type: '@user/UPDATE_USER_INFO_SUCCESS',
    payload: { userData },
  };
}

export function updateUserInfoFailure() {
  return {
    type: '@user/UPDATE_USER_INFO_FAILURE',
  };
}

export function updateUserAddressRequest(
  zipcode,
  address,
  number,
  complement,
  neighborhood,
  city,
  state,
  phone1,
  phone2,
  whatsapp
) {
  return {
    type: '@user/UPDATE_USER_ADDRESS_REQUEST',
    payload: {
      zipcode,
      address,
      number,
      complement,
      neighborhood,
      city,
      state,
      phone1,
      phone2,
      whatsapp,
    },
  };
}

export function updateUserAddressSuccess(userAddressData) {
  return {
    type: '@user/UPDATE_USER_ADDRESS_SUCCESS',
    payload: { userAddressData },
  };
}

export function updateUserAddressFailure() {
  return {
    type: '@user/UPDATE_USER_ADDRESS_FAILURE',
  };
}

export function updateUserPasswordRequest(newPassword) {
  return {
    type: '@user/UPDATE_USER_PASSWORD_REQUEST',
    payload: { newPassword },
  };
}

export function updateUserPasswordSuccess() {
  return {
    type: '@user/UPDATE_USER_PASSWORD_SUCCESS',
  };
}

export function updateUserPasswordFailure() {
  return {
    type: '@user/UPDATE_USER_PASSWORD_FAILURE',
  };
}

export function updateTrialInfo(freeTrialData) {
  return {
    type: '@user/UPDATE_TRIAL_INFO',
    payload: { freeTrialData }
  };
}

export function getTermsAndPolicyRequest(termPolicyCode) {
  return {
    type: '@user/GET_TERMS_AND_POLICY_REQUEST',
    payload: { termPolicyCode },
  };
}

export function setIsTermsTermsAndPolicyAccepted(isTermsAccepted) {
  return {
    type: '@user/SET_IS_TERMS_AND_POLICY_ACCEPTED',
    payload: { isTermsAccepted },
  };
}

export function acceptTermsAndPolicyRequest(termsId) {
  return {
    type: '@user/ACCEPT_TERMS_AND_POLICY_REQUEST',
    payload: {
      termsId,
    },
  };
}

export function getTermsAndPolicySuccess(termPolicyContent) {
  return {
    type: '@user/GET_TERMS_AND_POLICY_SUCCESS',
    payload: { termPolicyContent },
  };
}

export function getTermsAndPolicyFailure() {
  return {
    type: '@user/GET_TERMS_AND_POLICY_FAILURE',
  };
}

export function setIpAddress(ipAddress) {
  return {
    type: '@user/SET_IP_ADDRESS',
    payload: { ipAddress },
  };
}

export function updateUserImagesRequest(
  fileLogoData,
  fileCoverData
) {
  return {
    type: '@user/UPDATE_USER_IMAGES_REQUEST',
    payload: {
      fileLogoData,
      fileCoverData
    },
  };
}

export function updateUserImagesSuccess(userData) {
  return {
    type: '@user/UPDATE_USER_IMAGES_SUCCESS',
    payload: { userData },
  };
}

export function updateUserImagesFailure() {
  return {
    type: '@user/UPDATE_USER_IMAGES_FAILURE',
  };
}

export function getUserInfoRequest() {
  return {
    type: '@user/GET_USER_INFO_REQUEST',
  };
}

export function getUserRequest() {
  return {
    type: '@user/GET_USER_REQUEST',
  };
}

export function getUserSuccess(userData) {
  return {
    type: '@user/GET_USER_SUCCESS',
    payload: { userData },
  };
}

export function getAdvertiserCategoriesRequest() {
  return {
    type: '@user/GET_ADVERTISER_CATEGORIES_REQUEST',
  };
}

export function getAdvertiserCategoriesSuccess(categories) {
  return {
    type: '@user/GET_ADVERTISER_CATEGORIES_SUCCESS',
    payload: { categories },
  };
}

export function getAdvertiserCategoriesFailure() {
  return {
    type: '@user/GET_ADVERTISER_CATEGORIES_FAILURE',
  };
}

