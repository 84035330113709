import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { Col, Row } from '../../../../styles/grid';
import {
  CloseButton,
  CloseImage,
  ConversationIndicatorImg,
  ModalBackground,
  ModalContent,
  ModalHeader,
  ModalTitleText,
  PhoneImg,
  TextArea,
  TextBox, 
  Text
} from './styles';

import px2vw from '../../../../utils/responsiveness/px2vw';
import { getURL } from '../../../../utils/utils';

export default function MessageConfigModal(props) {
  const user = useSelector((state) => state.user.user);
  const [textAreaValue, setTextAreaValue] =
    useState(`Eiii! Por que você ainda não fez seu pedido?.😆

Não adianta negar, você sabe que quer um delivery hoje.. 😍

Volte no nosso cardápio e aproveite os preços exclusivos pra fazer seu pedido! 👇🏼`);

  const advertiserUrl = getURL('cardapio_web').replace(
    '$$advertiser_slug$$',
    user.advertiserInfo.slug
  );

  return (
    <ModalBackground isVisible>
      <ModalContent
        width={props.width}
        height={px2vw(480)}
        bgcolor={props.bgcolor}
        margtop={props.margtop}
        padding={`${px2vw(30)} ${px2vw(20)}`}
        backgroundImg={props.backgroundImg}
        backgroundImgSize={props.backgroundImgSize}
        maxheight={props.maxheight}
      >
        <ModalHeader>
          <ModalTitleText>Mensagem Disparada</ModalTitleText>{' '}
          <CloseButton onClick={() => props.onClose()}>
            <CloseImage src={require(`../../../../assets/images/close/close.png`)} />
          </CloseButton>
        </ModalHeader>
        <Row pad={`${px2vw(40)} ${px2vw(20)}`}>
          <Col>
            <Text
              size={px2vw(13)}
              margtop={px2vw(15)}
              regular
              margbottom={px2vw(15)}
            >
              A mensagem abaixo será disparada 10 minutos após um cliente pedir o link do seu cardápio e não realizar nenhum pedido
            </Text>
            <Text size={px2vw(13)} margtop={px2vw(5)} margbottom={px2vw(15)}>
              Texto Principal:
            </Text>
            <TextArea value={textAreaValue} />
            <Text italic regular size={px2vw(11)} margtop={px2vw(13)}>
              Por enquanto, você ainda não conseguirá alterar essa mensagem.
            </Text>
          </Col>
          <Col relative>
            <Col padleft={px2vw(60)} padtop={px2vw(80)} justifyStart absolute>
              <TextBox first>
                {textAreaValue}
                <ConversationIndicatorImg
                  src={require('../../../../assets/images/sales_recover/conversation_indicator.png')}
                />
              </TextBox>
              <TextBox margtop={px2vw(8)} padright={px2vw(10)}>
                <a style={{ fontStyle: 'none' }} href={advertiserUrl}>
                  {advertiserUrl}
                </a>
              </TextBox>
            </Col>

            <PhoneImg
              className="ml-auto"
              src={require('../../../../assets/images/sales_recover/phone.svg')}
            />
          </Col>
        </Row>
      </ModalContent>
    </ModalBackground>
  );
}
