import produce from 'immer';

const INITIAL_STATE = {
  products: [],
  couponsTraditional: [],
  couponsFlash: [],
  couponsCode: [],
  error: false,
  success: false,
  couponsLoading: false,
  updateCouponStatusLoading: false,
  deleteCouponModalLoading: false,
  deleteCouponModalSuccess: false,
  updateCouponLoading: false,
  updateCouponSuccess: false,
  newCouponLoading: false,
  newCouponSuccess: false,
  updateAvailableCouponsLoading: false,
  updateAvailableCouponsSuccess: false,
};

export default function coupons(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@coupons/GET_COUPONS_TRADITIONAL_REQUEST': {
        draft.couponsLoading = true;
        draft.couponsTraditional = [];
        draft.error = false;
        break;
      }
      case '@coupons/GET_COUPONS_TRADITIONAL_SUCCESS': {
        draft.couponsLoading = false;
        draft.couponsTraditional = action.payload.coupons;
        draft.error = false;
        break;
      }
      case '@coupons/GET_COUPONS_TRADITIONAL_FAILURE': {
        draft.couponsLoading = false;
        draft.couponsTraditional = [];
        draft.error = true;
        break;
      }
      case '@coupons/GET_COUPONS_FLASH_REQUEST': {
        draft.couponsLoading = true;
        draft.couponsFlash = [];
        draft.error = false;
        break;
      }
      case '@coupons/GET_COUPONS_FLASH_SUCCESS': {
        draft.couponsLoading = false;
        draft.couponsFlash = action.payload.coupons;
        draft.error = false;
        break;
      }
      case '@coupons/GET_COUPONS_FLASH_FAILURE': {
        draft.couponsLoading = false;
        draft.couponsFlash = [];
        draft.error = true;
        break;
      }
      case '@coupons/GET_COUPONS_CODE_REQUEST': {
        draft.couponsLoading = true;
        draft.couponsCode = [];
        draft.error = false;
        break;
      }
      case '@coupons/GET_COUPONS_CODE_SUCCESS': {
        draft.couponsLoading = false;
        draft.couponsCode = action.payload.coupons;
        draft.error = false;
        break;
      }
      case '@coupons/GET_COUPONS_CODE_FAILURE': {
        draft.couponsLoading = false;
        draft.couponsTraditional = [];
        draft.error = true;
        break;
      }
      case '@coupons/GET_PRODUCTS_WITHOUT_COUPONS_REQUEST': {
        draft.products = [];
        draft.success = false;
        break;
      }
      case '@coupons/GET_PRODUCTS_WITHOUT_COUPONS_SUCCESS': {
        draft.products = action.payload.products;
        draft.success = true;
        break;
      }
      case '@coupons/GET_PRODUCTS_WITHOUT_COUPONS_FAILURE': {
        draft.products = [];
        draft.success = false;
        break;
      }
      case '@coupons/CLEAR_SUCCESS': {
        draft.success = false;
        break;
      }
      case '@coupons/NEW_TRADITIONAL_COUPON_REQUEST':
      case '@coupons/NEW_FLASH_COUPON_REQUEST':
      case '@coupons/NEW_CODE_COUPON_REQUEST': {
        draft.newCouponLoading = true;
        draft.newCouponSuccess = false;
        break;
      }
      case '@coupons/NEW_COUPON_SUCCESS': {
        draft.newCouponLoading = false;
        draft.newCouponSuccess = true;
        break;
      }
      case '@coupons/NEW_COUPON_FAILURE': {
        draft.newCouponLoading = false;
        draft.newCouponSuccess = false;
        break;
      }
      case '@coupons/UPDATE_TRADITIONAL_COUPON_REQUEST':
      case '@coupons/UPDATE_FLASH_COUPON_REQUEST':
      case '@coupons/UPDATE_CODE_COUPON_REQUEST':  {
        draft.updateCouponLoading = true;
        draft.updateCouponSuccess = false;
        break;
      }
      case '@coupons/UPDATE_COUPON_SUCCESS': {
        draft.updateCouponLoading = false;
        draft.updateCouponSuccess = true;
        break;
      }
      case '@coupons/UPDATE_COUPON_FAILURE': {
        draft.updateCouponLoading = false;
        draft.updateCouponSuccess = false;
        break;
      }
      case '@coupons/UPDATE_COUPON_STATUS_REQUEST': {
        draft.updateCouponStatusLoading = true;
        break;
      }
      case '@coupons/UPDATE_COUPON_STATUS_SUCCESS': {
        draft.updateCouponStatusLoading = false;
        break;
      }
      case '@coupons/UPDATE_COUPON_STATUS_FAILURE': {
        draft.updateCouponStatusLoading = false;
        break;
      }
      case '@coupons/DELETE_COUPON_REQUEST': {
        draft.deleteCouponModalLoading = true;
        draft.deleteCouponModalSuccess = false;
        break;
      }
      case '@coupons/DELETE_COUPON_SUCCESS': {
        draft.deleteCouponModalLoading = false;
        draft.deleteCouponModalSuccess = true;
        break;
      }
      case '@coupons/DELETE_COUPON_FAILURE': {
        draft.deleteCouponModalLoading = false;
        draft.deleteCouponModalSuccess = false;
        break;
      }
      case '@coupons/DELETE_COUPON_MODAL_CLEAR_SUCCESS': {
        draft.deleteCouponModalSuccess = false;
        break;
      }
      case '@coupons/UPDATE_AVAILABLE_COUPONS_REQUEST': {
        draft.updateAvailableCouponsLoading = true;
        draft.updateAvailableCouponsSuccess = false;
        break;
      }
      case '@coupons/UPDATE_AVAILABLE_COUPONS_SUCCESS': {
        draft.updateAvailableCouponsLoading = false;
        draft.updateAvailableCouponsSuccess = true;
        break;
      }
      case '@coupons/UPDATE_AVAILABLE_COUPONS_FAILURE': {
        draft.updateAvailableCouponsLoading = false;
        draft.updateAvailableCouponsSuccess = false;
        break;
      }
      case '@coupons/UPDATE_AVAILABLE_COUPONS_MODAL_CLEAR_SUCCESS': {
        draft.updateAvailableCouponsSuccess = false;
        break;
      }
      default:
    }
  });
}
