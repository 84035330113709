import React, { useEffect, useState } from 'react';

import px2vw from '../../../../../utils/responsiveness/px2vw';
import { Row } from '../../../../../styles/grid';
import { BiSearchAlt2 } from 'react-icons/bi';
import { IoClose } from 'react-icons/io5';

import {
  ModalContent,
  SearchInput,
  SearchInputStyle,
  SearchInputClearStyle,
} from '../styles';
import ClientListTable from '../../ClientListTable';

export const ContactListContent = ({ data, onSearchChange = () => {} }) => {
  const [search, setSearch] = useState('');

  useEffect(() => {
    onSearchChange(search);
  }, [search]);

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  return (
    <ModalContent style={{ display: 'flex' }}>
      <Row alignCenter relative height="auto" margbottom={px2vw(7)}>
        <BiSearchAlt2 style={SearchInputStyle} />
        <SearchInput
          value={search}
          onChange={handleSearchChange}
          placeholder="Busque pelo nome ou telefone de um cliente especifico"
        />
        {search.length > 0 && (
          <IoClose
            onClick={() => setSearch('')}
            style={SearchInputClearStyle}
          />
        )}
      </Row>

      <ClientListTable data={data} />
    </ModalContent>
  );
};
