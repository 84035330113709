export const productAvailability = [
  { id: 1, title: 'Sempre disponível' },
  { id: 2, title: 'Horário específico' },
];

export const defaultCouponHorary = {
  active: false,
  availability: '1',
  specificHourStart: '',
  specificHourEnd: '',
}

export const statesOptions = [
  { id: 'AC', title: 'AC (Acre)' },
  { id: 'AL', title: 'AL (Alagoas)' },
  { id: 'AP', title: 'AP (Amapá)' },
  { id: 'AM', title: 'AM (Amazonas)' },
  { id: 'BA', title: 'BA (Bahia)' },
  { id: 'CE', title: 'CE (Ceará)' },
  { id: 'DF', title: 'DF (Distrito Federal)' },
  { id: 'ES', title: 'ES (Espírito Santo)' },
  { id: 'GO', title: 'GO (Goiás)' },
  { id: 'MA', title: 'MA (Maranhão)' },
  { id: 'MT', title: 'MT (Mato Grosso)' },
  { id: 'MS', title: 'MS (Mato Grosso do Sul)' },
  { id: 'MG', title: 'MG (Minas Gerais)' },
  { id: 'PA', title: 'PA (Pará)' },
  { id: 'PB', title: 'PB (Paraíba)' },
  { id: 'PR', title: 'PR (Paraná)' },
  { id: 'PE', title: 'PE (Pernambuco)' },
  { id: 'PI', title: 'PI (Piauí)' },
  { id: 'RR', title: 'RR (Roraima)' },
  { id: 'RO', title: 'RO (Rondônia)' },
  { id: 'RJ', title: 'RJ (Rio de Janeiro)' },
  { id: 'RN', title: 'RN (Rio Grande do Norte)' },
  { id: 'RS', title: 'RS (Rio Grande do Sul)' },
  { id: 'SC', title: 'SC (Santa Catarina)' },
  { id: 'SP', title: 'SP (São Paulo)' },
  { id: 'SE', title: 'SE (Sergipe)' },
  { id: 'TO', title: 'TO (Tocantins)' },
];

export const schedulingMinDaysOptionsArr = () => {
  // Create scheduling min day options with 30 days
  const optionsArr = Array.from(Array(30).keys()).map((_, idx) => {
    return {
      id: idx + 1,
      title: `${(idx + 1)}`
    }
  });
  optionsArr.unshift({ id: 0, title: '-' });
  
  return optionsArr;
};

export const schedulingMinHoursOptionsArr = () => {
  // Create scheduling min hours options with 23 hours
  const optionsArr = Array.from(Array(23).keys()).map((_, idx) => {
    return {
      id: idx + 1,
      title: `${(idx + 1)}`
    }
  });
  optionsArr.unshift({ id: 0, title: '-' });
  
  return optionsArr;
};

export const schedulingMinMinutesOptions = [
  { id: 0, title: '-' },
  { id: 5, title: '5' },
  { id: 10, title: '10' },
  { id: 15, title: '15' },
  { id: 20, title: '20' },
  { id: 25, title: '25' },
  { id: 30, title: '30' },
  { id: 35, title: '35' },
  { id: 40, title: '40' },
  { id: 45, title: '45' },
  { id: 50, title: '50' },
  { id: 55, title: '55' },
];

export const statesWithId = [
  {
    id: 11,
    sigla: 'RO',
    nome: 'Rondônia',
  },
  {
    id: 12,
    sigla: 'AC',
    nome: 'Acre',
  },
  {
    id: 13,
    sigla: 'AM',
    nome: 'Amazonas',
  },
  {
    id: 14,
    sigla: 'RR',
    nome: 'Roraima',
  },
  {
    id: 15,
    sigla: 'PA',
    nome: 'Pará',
  },
  {
    id: 16,
    sigla: 'AP',
    nome: 'Amapá',
  },
  {
    id: 17,
    sigla: 'TO',
    nome: 'Tocantins',
  },
  {
    id: 21,
    sigla: 'MA',
    nome: 'Maranhão',
  },
  {
    id: 22,
    sigla: 'PI',
    nome: 'Piauí',
  },
  {
    id: 23,
    sigla: 'CE',
    nome: 'Ceará',
  },
  {
    id: 24,
    sigla: 'RN',
    nome: 'Rio Grande do Norte',
  },
  {
    id: 25,
    sigla: 'PB',
    nome: 'Paraíba',
  },
  {
    id: 26,
    sigla: 'PE',
    nome: 'Pernambuco',
  },
  {
    id: 27,
    sigla: 'AL',
    nome: 'Alagoas',
  },
  {
    id: 28,
    sigla: 'SE',
    nome: 'Sergipe',
  },
  {
    id: 29,
    sigla: 'BA',
    nome: 'Bahia',
  },
  {
    id: 31,
    sigla: 'MG',
    nome: 'Minas Gerais',
  },
  {
    id: 32,
    sigla: 'ES',
    nome: 'Espírito Santo',
  },
  {
    id: 33,
    sigla: 'RJ',
    nome: 'Rio de Janeiro',
  },
  {
    id: 35,
    sigla: 'SP',
    nome: 'São Paulo',
  },
  {
    id: 41,
    sigla: 'PR',
    nome: 'Paraná',
  },
  {
    id: 42,
    sigla: 'SC',
    nome: 'Santa Catarina',
  },
  {
    id: 43,
    sigla: 'RS',
    nome: 'Rio Grande do Sul',
  },
  {
    id: 50,
    sigla: 'MS',
    nome: 'Mato Grosso do Sul',
  },
  {
    id: 51,
    sigla: 'MT',
    nome: 'Mato Grosso',
  },
  {
    id: 52,
    sigla: 'GO',
    nome: 'Goiás',
  },
  {
    id: 53,
    sigla: 'DF',
    nome: 'Distrito Federal',
  },
];

export const paginateState = { 
  total: "0",
  perPage: 0,
  page: 0,
  lastPage: 0,
}