export function getProductCategoriesRequest() {
  return {
    type: '@productCategories/GET_PRODUCT_CATEGORIES_REQUEST',
  };
}

export function getProductCategoriesSuccess(productCategories) {
  return {
    type: '@productCategories/GET_PRODUCT_CATEGORIES_SUCCESS',
    payload: { productCategories },
  };
}

export function getProductCategoriesFailure() {
  return {
    type: '@productCategories/GET_PRODUCT_CATEGORIES_FAILURE',
  };
}

export function createNewCategoryRequest(categoryName) {
  return {
    type: '@productCategories/CREATE_NEW_CATEGORY_REQUEST',
    payload: { categoryName },
  };
}

export function createNewCategorySuccess() {
  return {
    type: '@productCategories/CREATE_NEW_CATEGORY_SUCCESS',
  };
}

export function createNewCategoryFailure() {
  return {
    type: '@productCategories/CREATE_NEW_CATEGORY_FAILURE',
  };
}

export function updateCategoryStatusRequest(categoryId, newStatus) {
  return {
    type: '@productCategories/UPDATE_CATEGORY_STATUS_REQUEST',
    payload: { categoryId, newStatus },
  };
}

export function updateCategoryStatusSuccess() {
  return {
    type: '@productCategories/UPDATE_CATEGORY_STATUS_SUCCESS',
  };
}

export function updateCategoryStatusFailure() {
  return {
    type: '@productCategories/UPDATE_CATEGORY_STATUS_FAILURE',
  };
}

export function updateProductStatusRequest(productId, newStatus) {
  return {
    type: '@productCategories/UPDATE_PRODUCT_STATUS_REQUEST',
    payload: { productId, newStatus },
  };
}

export function updateProductStatusSuccess() {
  return {
    type: '@productCategories/UPDATE_PRODUCT_STATUS_SUCCESS',
  };
}

export function updateProductStatusFailure() {
  return {
    type: '@productCategories/UPDATE_PRODUCT_STATUS_FAILURE',
  };
}

export function updateProductAmountRequest(productId, newAmount) {
  return {
    type: '@productCategories/UPDATE_PRODUCT_AMOUNT_REQUEST',
    payload: { productId, newAmount },
  };
}

export function updateProductAmountSuccess() {
  return {
    type: '@productCategories/UPDATE_PRODUCT_AMOUNT_SUCCESS',
  };
}

export function updateProductAmountFailure() {
  return {
    type: '@productCategories/UPDATE_PRODUCT_AMOUNT_FAILURE',
  };
}

export function updateComplCategoryStatusRequest(complCategoryId, newStatus) {
  return {
    type: '@productCategories/UPDATE_COMPL_CATEGORY_STATUS_REQUEST',
    payload: { complCategoryId, newStatus },
  };
}

export function updateComplCategoryStatusSuccess() {
  return {
    type: '@productCategories/UPDATE_COMPL_CATEGORY_STATUS_SUCCESS',
  };
}

export function updateComplCategoryStatusFailure() {
  return {
    type: '@productCategories/UPDATE_COMPL_CATEGORY_STATUS_FAILURE',
  };
}

export function updateComplOptionStatusRequest(complOptionId, newStatus) {
  return {
    type: '@productCategories/UPDATE_COMPL_OPTION_STATUS_REQUEST',
    payload: { complOptionId, newStatus },
  };
}

export function updateComplOptionStatusSuccess() {
  return {
    type: '@productCategories/UPDATE_COMPL_OPTION_STATUS_SUCCESS',
  };
}

export function updateComplOptionStatusFailure() {
  return {
    type: '@productCategories/UPDATE_COMPL_OPTION_STATUS_FAILURE',
  };
}

export function updateComplOptionAmountRequest(complOptionId, newAmount) {
  return {
    type: '@productCategories/UPDATE_COMPL_OPTION_AMOUNT_REQUEST',
    payload: { complOptionId, newAmount },
  };
}

export function updateComplOptionAmountSuccess() {
  return {
    type: '@productCategories/UPDATE_COMPL_OPTION_AMOUNT_SUCCESS',
  };
}

export function updateComplOptionAmountFailure() {
  return {
    type: '@productCategories/UPDATE_COMPL_OPTION_AMOUNT_FAILURE',
  };
}

export function updateComplClearSuccess() {
  return {
    type: '@productCategories/UPDATE_COMPL_CLEAR_SUCCESS',
  };
}

export function updateCategoryNameRequest(categoryId, newName) {
  return {
    type: '@productCategories/UPDATE_CATEGORY_NAME_REQUEST',
    payload: { categoryId, newName },
  };
}

export function updateCategoryNameSuccess() {
  return {
    type: '@productCategories/UPDATE_CATEGORY_NAME_SUCCESS',
  };
}

export function updateCategoryNameFailure() {
  return {
    type: '@productCategories/UPDATE_CATEGORY_NAME_FAILURE',
  };
}

export function createNewProductRequest(
  fileNewProduct,
  productName,
  productCategory,
  productDescription,
  productAmount,
  productSunEnabled,
  productSunStartTime,
  productSunEndTime,
  productMonEnabled,
  productMonStartTime,
  productMonEndTime,
  productTueEnabled,
  productTueStartTime,
  productTueEndTime,
  productWedEnabled,
  productWedStartTime,
  productWedEndTime,
  productThuEnabled,
  productThuStartTime,
  productThuEndTime,
  productFriEnabled,
  productFriStartTime,
  productFriEndTime,
  productSatEnabled,
  productSatStartTime,
  productSatEndTime,
  productActiveCoupon,
  productCouponShortDisplayName,
  productCouponDisplayName,
  productCouponDescription,
  productPromotionalAmount,
  productLightningCoupon,
  productPromotionalDate,
  productPromotionalTime,
  productPromotionalUseLimit,
  productComplements
) {
  return {
    type: '@productCategories/CREATE_NEW_PRODUCT_REQUEST',
    payload: {
      fileNewProduct,
      productName,
      productCategory,
      productDescription,
      productAmount,
      productSunEnabled,
      productSunStartTime,
      productSunEndTime,
      productMonEnabled,
      productMonStartTime,
      productMonEndTime,
      productTueEnabled,
      productTueStartTime,
      productTueEndTime,
      productWedEnabled,
      productWedStartTime,
      productWedEndTime,
      productThuEnabled,
      productThuStartTime,
      productThuEndTime,
      productFriEnabled,
      productFriStartTime,
      productFriEndTime,
      productSatEnabled,
      productSatStartTime,
      productSatEndTime,
      productActiveCoupon,
      productCouponShortDisplayName,
      productCouponDisplayName,
      productCouponDescription,
      productPromotionalAmount,
      productLightningCoupon,
      productPromotionalDate,
      productPromotionalTime,
      productPromotionalUseLimit,
      productComplements,
    },
  };
}

export function createNewProductSuccess() {
  return {
    type: '@productCategories/CREATE_NEW_PRODUCT_SUCCESS',
  };
}

export function createNewProductFailure() {
  return {
    type: '@productCategories/CREATE_NEW_PRODUCT_FAILURE',
  };
}

export function updateProductRequest(
  fileEditProduct,
  productId,
  productName,
  productCategory,
  productDescription,
  productAmount,
  productSunEnabled,
  productSunStartTime,
  productSunEndTime,
  productMonEnabled,
  productMonStartTime,
  productMonEndTime,
  productTueEnabled,
  productTueStartTime,
  productTueEndTime,
  productWedEnabled,
  productWedStartTime,
  productWedEndTime,
  productThuEnabled,
  productThuStartTime,
  productThuEndTime,
  productFriEnabled,
  productFriStartTime,
  productFriEndTime,
  productSatEnabled,
  productSatStartTime,
  productSatEndTime,
  productActiveCoupon,
  productCouponShortDisplayName,
  productCouponDisplayName,
  productCouponDescription,
  productPromotionalAmount,
  productLightningCoupon,
  productPromotionalDate,
  productPromotionalTime,
  productPromotionalUseLimit,
  productComplements,
  productDeletedComplementsCategories,
  productDeletedComplements
) {
  return {
    type: '@productCategories/UPDATE_PRODUCT_REQUEST',
    payload: {
      fileEditProduct,
      productId,
      productName,
      productCategory,
      productDescription,
      productAmount,
      productSunEnabled,
      productSunStartTime,
      productSunEndTime,
      productMonEnabled,
      productMonStartTime,
      productMonEndTime,
      productTueEnabled,
      productTueStartTime,
      productTueEndTime,
      productWedEnabled,
      productWedStartTime,
      productWedEndTime,
      productThuEnabled,
      productThuStartTime,
      productThuEndTime,
      productFriEnabled,
      productFriStartTime,
      productFriEndTime,
      productSatEnabled,
      productSatStartTime,
      productSatEndTime,
      productActiveCoupon,
      productCouponShortDisplayName,
      productCouponDisplayName,
      productCouponDescription,
      productPromotionalAmount,
      productLightningCoupon,
      productPromotionalDate,
      productPromotionalTime,
      productPromotionalUseLimit,
      productComplements,
      productDeletedComplementsCategories,
      productDeletedComplements,
    },
  };
}

export function updateProductSuccess() {
  return {
    type: '@productCategories/UPDATE_PRODUCT_SUCCESS',
  };
}

export function updateProductFailure() {
  return {
    type: '@productCategories/UPDATE_PRODUCT_FAILURE',
  };
}

export function clearSuccess() {
  return {
    type: '@productCategories/CLEAR_SUCCESS',
  };
}

export function categoryModalClearSuccess() {
  return {
    type: '@productCategories/CATEGORY_MODAL_CLEAR_SUCCESS',
  };
}

export function productModalClearSuccess() {
  return {
    type: '@productCategories/PRODUCT_MODAL_CLEAR_SUCCESS',
  };
}

export function deleteCategoryRequest(categoryId) {
  return {
    type: '@productCategories/DELETE_CATEGORY_REQUEST',
    payload: { categoryId },
  };
}

export function deleteProductRequest(productId) {
  return {
    type: '@productCategories/DELETE_PRODUCT_REQUEST',
    payload: { productId },
  };
}

export function deleteCategoryOrProductSuccess() {
  return {
    type: '@productCategories/DELETE_CATEGORY_OR_PRODUCT_SUCCESS',
  };
}

export function deleteCategoryOrProductFailure() {
  return {
    type: '@productCategories/DELETE_CATEGORY_OR_PRODUCT_FAILURE',
  };
}

export function deleteCategoryProductModalClearSuccess() {
  return {
    type: '@productCategories/DELETE_CATEGORY_OR_PRODUCT_MODAL_CLEAR_SUCCESS',
  };
}

export function formatCatalogUpdateOrdenation(catalog) {
  return {
    type: '@productCategories/FORMAT_CATALOG_UPDATE_ORDENATION',
    payload: { catalog },
  };
}

export function updateProductCategoriesOrdenationRequest(catalog) {
  return {
    type: '@productCategories/UPDATE_PRODUCT_CATEGORIES_ORDENATION_REQUEST',
    payload: { catalog },
  };
}

export function updateProductCategoriesOrdenationSuccess() {
  return {
    type: '@productCategories/UPDATE_PRODUCT_CATEGORIES_ORDENATION_SUCCESS',
  };
}

export function updateProductCategoriesOrdenationFailure() {
  return {
    type: '@productCategories/UPDATE_PRODUCT_CATEGORIES_ORDENATION_FAILURE',
  };
}

export function updateProductCategoriesOrdenationClearSuccess() {
  return {
    type:
      '@productCategories/UPDATE_PRODUCT_CATEGORIES_ORDENATION_CLEAR_SUCCESS',
  };
}
