import React from "react";
import { Spinner } from 'reactstrap';
import { FormControlLabel } from "@material-ui/core";

import {
  Card,
  Hr,
  Image,
  NormalText,
} from '../../../BaseScreen/styles';
import {
  SaveButton,
  SaveButtonText,
  SwitchController,
  SwitchLabelRegularText,
  SwitchWithInput,
  SwitchWithInputBox,
  SwitchInput,
  StoreForm,
} from '../styles';
import {
  LoadingBox,
} from '../../../../_layouts/styles/common';
import { Row, Col } from '../../../../../styles/grid';
import { preto } from '../../../../../styles/colors';

import px2vw from '../../../../../utils/responsiveness/px2vw';
import { onlyNumbers } from "../../../../../utils/utils";
import { amountMask } from "../../../../../utils/masks";

import NeighborhoodModal from '../../../Step2-DeliveryParams/Mobile/StoreDeliveryTakeAwayParamsOnboardingMobile/NeighborhoodModal';
import DynamicDeliveryModal from '../../../Step2-DeliveryParams/Mobile/StoreDeliveryTakeAwayParamsOnboardingMobile/DynamicDeliveryModal';

export default function StoreDeliveryTakeAwayParamsOnboardingMobile({ handler }) {
  return (
    <StoreForm onSubmit={handler.handleStoreDeliveryTakeAwaySubmit}>
      <Card
        width="auto"
        height="auto"
        padleft={px2vw(30)}
        padright={px2vw(30)}
        borderRadius={px2vw(60)}
        margtop={px2vw(60)}
      >
        <Col margleft={px2vw(20)} marginBottom={px2vw(20)}>
          <NormalText
            textalign='flex-start'
            margleft={px2vw(20)}
            margtop={px2vw(80)}
            fontweight='bold'
            color={preto}
            fontsize={px2vw(60)}>
            Defina as configurações de delivery / retirada
          </NormalText>
          <NormalText
            fontweight='bold'
            color={preto}
            margleft={px2vw(20)}
            margtop={px2vw(80)}
            fontsize={px2vw(50)}
            margbottom={px2vw(70)}>
            Configurações do delivery
          </NormalText>
          {/* Switch active delivery */}
          <SwitchWithInput key={'delivery'} margleft={px2vw(60)} marginBottom={px2vw(20)} margtop={px2vw(30)}>
            <FormControlLabel
              control={
                <SwitchController
                  checked={handler.deliveryActive}
                  type="checkbox"
                  onChange={() => handler.handleDeliveryActive()}
                />
              }
              label={
                <SwitchLabelRegularText>Ativar delivery</SwitchLabelRegularText>
              }
            />
          </SwitchWithInput>
          {/* Switch to define average delivery time */}
          <SwitchWithInput key={'avgDeliveryTime'} margleft={px2vw(60)} marginBottom={px2vw(20)}>
            <FormControlLabel
              control={
                <SwitchController
                  checked={handler.deliveryActive && handler.deliveryTimeAvg}
                  type="checkbox"
                  onChange={(t) => {
                    handler.setDeliveryChanges(true);
                    handler.setDeliveryTimeAvg(t.target.checked);
                  }}
                />
              }
              label={
                <SwitchLabelRegularText>Tempo médio de entrega</SwitchLabelRegularText>

              }
              disabled={!handler.deliveryActive}
            />
            <SwitchWithInputBox isVisible={handler.deliveryActive && handler.deliveryTimeAvg}>
              <SwitchLabelRegularText>
                <SwitchInput
                  name='deliveryTimeAvg'
                  maxLength="3"
                  placeholder="30"
                  width={px2vw(170)}
                  value={handler.deliveryTimeAvgInputValue}
                  onChange={(t) => {
                    handler.setDeliveryChanges(true);
                    handler.setDeliveryTimeAvgInputValue(onlyNumbers(t.target.value));
                  }}
                />
                min
              </SwitchLabelRegularText>
            </SwitchWithInputBox>
          </SwitchWithInput>
          {/* Switch to define delivery area */}
          <Col
            padleft={px2vw(90)}
            margtop={px2vw(20)}
          >
            <NormalText
              fontweight='bold'
              textalign='flex-start'
              color={preto}
              fontsize={px2vw(50)}
              padbottom={px2vw(50)}
              disabled={!handler.deliveryActive}
            >
              Definição da área de entrega
            </NormalText>
            {/* Delivery area by radius */}
            <SwitchWithInput key={'deliveryArea'} margleft={px2vw(60)} marginBottom={px2vw(20)} margtop={px2vw(30)}>
              <FormControlLabel
                control={
                  <SwitchController
                    checked={handler.deliveryActive && handler.radiusArea}
                    type="checkbox"
                    onChange={() => handler.handleChangeDeliveryArea('radius_area')}
                  />
                }
                label={
                  <SwitchLabelRegularText width={px2vw(480)}>Por raio de entrega máximo</SwitchLabelRegularText>

                }
                disabled={!handler.deliveryActive}
              />
              <SwitchWithInputBox isVisible={handler.deliveryActive && handler.radiusArea} margleft={px2vw(45)} marginBottom={px2vw(20)} margtop={px2vw(30)}>
                <SwitchLabelRegularText>
                  <SwitchInput
                    name={'maxDist'}
                    maxLength="3"
                    placeholder="10"
                    width={px2vw(170)}
                    value={handler.maxDistInputValue}
                    onChange={(t) => {
                      handler.setDeliveryChanges(true);
                      handler.setMaxDistInputValue(onlyNumbers(t.target.value));
                    }}
                    disabled={!handler.deliveryActive}
                  />
                  km
                </SwitchLabelRegularText>
              </SwitchWithInputBox>
            </SwitchWithInput>
            {(handler.deliveryActive && handler.radiusArea) && (
              <Col padleft={px2vw(60)} margbottom={px2vw(40)}>
                <NormalText
                  fontweight='bold'
                  color={preto}
                  fontsize={px2vw(50)}
                  padbottom={px2vw(50)}
                  padleft={px2vw(20)}>
                  Taxa de entrega
                </NormalText>
                {/* Delivery area by radius - fee definitions */}
                <SwitchWithInput key={'free'} margleft={px2vw(60)} marginBottom={px2vw(20)} margtop={px2vw(30)}>
                  <FormControlLabel
                    control={
                      <SwitchController
                        checked={handler.deliveryActive && handler.deliveryFree}
                        type="checkbox"
                        onChange={() => handler.handleChangeDeliveryType('delivery_free')}
                      />
                    }
                    label={
                      <SwitchLabelRegularText>Entrega grátis</SwitchLabelRegularText>
                    }
                    disabled={!handler.deliveryActive}
                  />
                </SwitchWithInput>
                {/* Delivery area by radius - fee definitions unique */}
                <SwitchWithInput key={'fixed-fee'} margleft={px2vw(60)} marginBottom={px2vw(20)} margtop={px2vw(30)}>
                  <FormControlLabel
                    control={
                      <SwitchController
                        checked={handler.deliveryActive && handler.deliveryFee}
                        type="checkbox"
                        onChange={() => handler.handleChangeDeliveryType('delivery_fee')}
                      />
                    }
                    label={
                      <SwitchLabelRegularText>Taxa de entrega única</SwitchLabelRegularText>
                    }
                    disabled={!handler.deliveryActive}
                  />
                  <SwitchWithInputBox isVisible={handler.deliveryActive && handler.deliveryFee} margleft={px2vw(-20)} marginBottom={px2vw(20)}>
                    <SwitchInput
                      name="deliveryFee"
                      maxLength="9"
                      placeholder="R$ 18,00"
                      width={px2vw(250)}
                      value={handler.deliveryActive && handler.deliveryFeeInputValue}
                      onChange={(t) => {
                        handler.setDeliveryChanges(true);
                        handler.setDeliveryFeeInputValue(amountMask(t.target.value));
                      }}
                    />
                  </SwitchWithInputBox>
                </SwitchWithInput>
                {/* Delivery area by radius - fee definitions dynamic */}
                <Row spaceBetween>
                  <SwitchWithInput key={'dinamic-fee'} margleft={px2vw(60)} marginBottom={px2vw(20)} margtop={px2vw(30)}>
                    <FormControlLabel
                      control={
                        <SwitchController
                          checked={handler.deliveryActive && handler.dynamicDeliveryFee}
                          type="checkbox"
                          onChange={() => {
                            handler.handleChangeDeliveryType('dynamic_delivery_fee');
                            handler.setDynamicDeliveryFeeAmountInputValue(
                              amountMask(handler.dynamicDeliveryFeeAmountInputValue)
                            );
                            handler.setDynamicMaxDistApplyInputValue(
                              handler.dynamicMaxDistApplyInputValue
                            );
                            handler.setDynamicExceededFeeAmountInputValue(
                              amountMask(handler.dynamicExceededFeeAmountInputValue)
                            );
                            handler.setDynamicFreeDeliveryAtDistInputValue(
                              handler.dynamicFreeDeliveryAtDistInputValue
                            );
                            handler.setDynamicFreeDeliveryAtDistActiveInputValue(
                              handler.dynamicFreeDeliveryAtDistActiveInputValue
                            );
                            handler.setDynamicFeeCalcTypeRouteInputValue(
                              handler.dynamicFeeCalcTypeRouteInputValue
                            );
                            handler.setDynamicFeeCalcTypeRadiusInputValue(
                              handler.dynamicFeeCalcTypeRadiusInputValue
                            );

                            handler.setDynamicDeliveryFeeModal(true);
                          }}
                        />
                      }
                      label={
                        <Row alignCenter>
                          <SwitchLabelRegularText margright={px2vw(90)}>Taxa de entrega dinâmica</SwitchLabelRegularText>
                          <Image
                            width={px2vw(100)}
                            height={px2vw(100)}
                            src={require('../../../../../assets/images/onboarding/wheel@2x.png')}
                            isNotVisible={!handler.deliveryActive || !handler.dynamicDeliveryFee}
                          />
                        </Row>
                      }
                      disabled={!handler.deliveryActive}
                    />
                  </SwitchWithInput>
                </Row>
              </Col>
            )}
            {/* Delivery area by neighborhood */}
            <Row padright={px2vw(160)} spaceBetween>
              <SwitchWithInput key={'delivery'} margleft={px2vw(60)} marginBottom={px2vw(20)} margtop={px2vw(30)}>
                <FormControlLabel
                  control={
                    <SwitchController
                      checked={handler.deliveryActive && handler.neighborhoodArea}
                      type="checkbox"
                      onChange={() => {
                        handler.handleChangeDeliveryArea('neighborhood_area');
                        handler.setNeighborhoodAreaModal(true);
                      }}
                    />
                  }
                  label={
                    <Row alignCenter>
                      <SwitchLabelRegularText margright={px2vw(90)}>Por bairro</SwitchLabelRegularText>
                      <Image
                        width={px2vw(100)}
                        height={px2vw(100)}
                        src={require('../../../../../assets/images/onboarding/wheel@2x.png')}
                        isNotVisible={!handler.deliveryActive || !handler.neighborhoodArea}
                      />
                    </Row>
                  }
                  disabled={!handler.deliveryActive}
                />
              </SwitchWithInput>
            </Row>
          </Col>
          {/* Takeaway configs */}
          <Hr margtop={px2vw(50)} margbottom={px2vw(50)} />
          <NormalText
            fontweight='bold'
            color={preto}
            fontsize={px2vw(50)}
            padbottom={px2vw(50)}>
            Configurações para retirada
          </NormalText>
          <SwitchWithInput key={'takeaway-active'} margleft={px2vw(60)} marginBottom={px2vw(20)} margtop={px2vw(30)}>
            <FormControlLabel
              control={
                <SwitchController
                  checked={handler.takeAwayActive}
                  type="checkbox"
                  onChange={() => handler.handleTakeAwayActive()}
                />
              }
              label={
                <SwitchLabelRegularText>Ativar retirada de pedidos na loja</SwitchLabelRegularText>
              }
            />
          </SwitchWithInput>
          <SwitchWithInput key={'takeaway-active-time'} margleft={px2vw(110)} marginBottom={px2vw(20)} margtop={px2vw(30)}>
            <SwitchLabelRegularText width={px2vw(680)} disabled={!handler.takeAwayActive}>Tempo médio para retirada de pedidos</SwitchLabelRegularText>
            <SwitchWithInputBox margleft={px2vw(100)} isVisible>
              <SwitchLabelRegularText disabled={!handler.takeAwayActive}>
                <SwitchInput
                  name="takeAwayTimeAvg"
                  maxLength="3"
                  placeholder="30"
                  width={px2vw(170)}
                  value={handler.takeAwayTimeAvgInputValue}
                  onChange={(t) => {
                    handler.setDeliveryChanges(true);
                    handler.setTakeAwayTimeAvgInputValue(
                      onlyNumbers(t.target.value)
                    );
                  }}
                  disabled={!handler.takeAwayActive}
                />
                min
              </SwitchLabelRegularText>
            </SwitchWithInputBox>
          </SwitchWithInput>
          <Hr margtop={px2vw(50)} margbottom={px2vw(50)} />
          {/* Delivery / Takeaway configs */}
          <NormalText
            fontweight='bold'
            color={preto}
            fontsize={px2vw(50)}
            padtop={px2vw(50)}
            padbottom={px2vw(50)}
          >
            Configurações do delivery / retirada
          </NormalText>
          <SwitchWithInput key={'minOrder'} margleft={px2vw(60)} marginBottom={px2vw(20)} margtop={px2vw(30)}>
            <FormControlLabel
              control={
                <SwitchController
                  checked={handler.orderMinimumAmount}
                  type="checkbox"
                  onChange={() => {
                    handler.setDeliveryChanges(true);
                    handler.setOrderMinimumAmount(!handler.orderMinimumAmount);
                  }}
                />
              }
              label={
                <SwitchLabelRegularText>Valor mínimo dos pedidos</SwitchLabelRegularText>
              }
            />
            <SwitchWithInputBox isVisible={handler.orderMinimumAmount}>
              <SwitchLabelRegularText>
                <SwitchInput
                  name="orderMinimumAmount"
                  maxLength="9"
                  placeholder="R$ 18,00"
                  value={handler.orderMinimumAmountInputValue}
                  width={px2vw(250)}
                  onChange={(t) => {
                    handler.setDeliveryChanges(true);
                    handler.setOrderMinimumAmountInputValue(
                      amountMask(t.target.value)
                    );
                  }}
                />
              </SwitchLabelRegularText>
            </SwitchWithInputBox>
          </SwitchWithInput>
        </Col>
        <Row justifyEnd width='auto' margtop={px2vw(80)} margbottom={px2vw(90)} margright={px2vw(40)}>
          <SaveButton
            hasChanges={handler.isSaveButtonActive()}
            type="submit"
            disabled={handler.isSaveButtonDisabled()}
          >
            {handler.deliveryTakeAwayLoading ? (
              <LoadingBox>
                <Spinner
                  style={{
                    width: '1.8rem',
                    height: '1.8rem',
                    color: preto,
                  }}
                />{' '}
              </LoadingBox>
            ) : (
              <SaveButtonText hasChanges={handler.isSaveButtonActive()}>
                Salvar
              </SaveButtonText>
            )}
          </SaveButton>
        </Row>
        {handler.neighborhoodAreaModal && <NeighborhoodModal handler={handler}/>}
        {handler.dynamicDeliveryFeeModal && <DynamicDeliveryModal handler={handler}/>}
      </Card>
    </StoreForm>
  )
}