import produce from 'immer';

const salesRecoverDataObj = { 
  is_active: false,
  reached_leads_qty: 0,
  total_recovered_sales: 0,
  recovered_revenue: 0.0
}

const INITIAL_STATE = {
  salesRecoverData: salesRecoverDataObj,
  getSalesRecoverDataLoading: false,
  updateSalesRecoverConfigLoading: false,
};

export default function salesRecover(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case 'salesRecover/GET_SALES_RECOVER_DATA_REQUEST': {
        draft.getSalesRecoverDataLoading = true;
        break;
      }
      case 'salesRecover/GET_SALES_RECOVER_DATA_SUCCESS': {
        draft.salesRecoverData.is_active = action.payload.is_active;
        draft.salesRecoverData.reached_leads_qty = action.payload.reached_leads_qty;
        draft.salesRecoverData.total_recovered_sales = action.payload.total_recovered_sales;
        draft.salesRecoverData.recovered_revenue = action.payload.recovered_revenue;
        draft.getSalesRecoverDataLoading = false;
        break;
      }
      case '@salesRecover/UPDATE_SALES_RECOVER_CONFIG_DATA_SUCCESS': {
        draft.salesRecoverData.is_active = action.payload.is_active;
        draft.salesRecoverData.reached_leads_qty = action.payload.reached_leads_qty;
        draft.salesRecoverData.total_recovered_sales = action.payload.total_recovered_sales;
        draft.salesRecoverData.recovered_revenue = action.payload.recovered_revenue;
        draft.updateSalesRecoverConfigLoading = false;
        break;
      }
      case '@salesRecover/UPDATE_SALES_RECOVER_CONFIG_DATA_FAILURE': {
        draft.updateSalesRecoverConfigLoading = false;
        break;
      }
      case '@salesRecover/UPDATE_SALES_RECOVER_CONFIG_DATA': {
        draft.updateSalesRecoverConfigLoading = true;
        break;
      }
      case 'salesRecover/GET_SALES_RECOVER_DATA_FAILURE': {
        draft.getSalesRecoverDataLoading = false;
        break;
      }
      default:
    }
  });
}
