import React from 'react';
import { MessageSticker } from '../../style';
import BrokenMedia from '../../BrokenMedia';

export const StickerMessage = ({ sticker }) => {
  return sticker?.url ? (
    <MessageSticker src={sticker.url} />
  ) : (
    <BrokenMedia type="sticker" />
  );
};
