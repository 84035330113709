import styled from 'styled-components';

import px2vw from '../../utils/responsiveness/px2vw';

import { verdeKuppi1 } from '../../styles/colors';

export const TabsBox = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: ${px2vw(20)};
`;

export const TabBox = styled.div`
  display: block;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: ${px2vw(40)};
`;

export const Tab = styled.button`
  height: ${px2vw(45)};
  font-size: ${px2vw(20)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.48px;
  border: none;
  background-color: transparent;
`;

export const TabSelected = styled.div`
  display: none;
  height: ${px2vw(5)};
  width: ${px2vw(125)};
  background-color: ${verdeKuppi1};
  border-radius: ${px2vw(5)};
  margin-top: ${px2vw(-7)};
  margin-bottom: ${px2vw(20)};

  ${(props) =>
    props.isVisible &&
    `
    display: block;
  `};

  ${(props) => `
    width: ${props.width};
  `};
`;
