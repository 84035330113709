import styled from 'styled-components';
import {
  azulKuppi1,
  branco,
  cinzaKuppi9,
  cinzaKuppi3,
  cinzaKuppi4,
  preto,
  verdeKuppi27,
  cinzaKuppi10,
} from '../../../../styles/colors';
import { Col, Row } from '../../../../styles/grid';
import { MdExpandMore } from 'react-icons/md';
import px2vw from '../../../../utils/responsiveness/px2vw';

export const Body = styled(Col)`
  width: 100%;
  height: 100%;
  max-height: 100%;
  padding: ${px2vw(10)};
  background: transparent;
  ${(props) =>
    props.isManualOrderModalVisible &&
    !props.isMinimized &&
    `
  margin-left: auto;
  width: 76%;
`}

  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: ${px2vw(6)};
    height: ${px2vw(6)};
    border-radius: 50%;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${preto}20;
    border: ${px2vw(4)} solid ${preto}20;
    border-radius: ${px2vw(15)};
    background-clip: padding-box;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }
`;

export const MessageItem = styled(Col)`
  justify-content: flex-start;
  width: auto;
  position: relative;
  min-width: 15%;
  max-width: 60%;
  height: auto;
  max-height: auto;
  ${(props) =>
    props.me &&
    `
align-self: flex-end;
margin-bottom: ${px2vw(10)};
`}
`;

export const MessageContent = styled(Col)`
  position: relative;
  background: ${branco};
  box-shadow: 0 1px 1px ${cinzaKuppi4};
  border-radius: ${px2vw(10)};
  padding: ${px2vw(3)};
  padding-right: ${px2vw(3)};
  margin-bottom: ${px2vw(5)};
  ${(props) =>
    props.me &&
    `
background: ${verdeKuppi27};
align-self: flex-end;
`}
  &:hover {
    .expandMoreIcon {
      display: flex;
    }
  }
`;

export const MessageText = styled.span`
  padding: ${px2vw(5)};
  height: auto;
  width: 100%;
  font-size: ${px2vw(14)};
  box-sizing: border-box;
  margin: ${px2vw(1)};
  word-break: break-word;
  p:last-child {
    margin-bottom: 0;
  }

  ${(props) =>
    props.isButton &&
    `
    color: ${azulKuppi1};
    cursor:pointer;
  font-weight:600;
  text-align:center;
  `}

  &:em {
    font-weight: 700;
  }
`;

export const MessageQuote = styled(MessageText)`
  padding: ${px2vw(1)};
`;

export const MessageImage = styled.img`
  padding: ${px2vw(5)};
  width: 100%;
  max-height: ${px2vw(400)};
  box-sizing: border-box;
  margin: ${px2vw(1)};
  border-radius: ${px2vw(15)};
`;

export const MessageSticker = styled.img`
  padding: ${px2vw(5)};
  width: ${px2vw(120)};
  height: ${px2vw(120)};
  box-sizing: border-box;
  margin: ${px2vw(1)};
  border-radius: ${px2vw(15)};
`;

export const MessageDocument = styled.div`
  padding: ${px2vw(5)};
  min-height: ${px2vw(80)};
  width: ${px2vw(300)};
  font-size: ${px2vw(14)};
  box-sizing: border-box;
  margin: ${px2vw(1)};
  border-radius: ${px2vw(15)};
  word-break: break-word;
`;

export const MessageDate = styled.span`
  padding: ${px2vw(1)};
  font-size: ${px2vw(11)};
  margin-left: auto;
  margin-right: ${px2vw(3)};
`;

export const MessageAudio = styled.audio`
  padding-top: ${px2vw(7)};
  min-height: ${px2vw(45)};
`;

export const MessageVideo = styled.video`
  border-radius: ${px2vw(10)};
  padding: ${px2vw(3)};
`;

export const MessageQuoted = styled.div`
  background: ${preto}20;
  width: 100%;
  border-radius: ${px2vw(5)};
  padding: ${px2vw(6)};
  font-size: ${px2vw(11)};
  font-style: italic;
  border-left: 3px solid blue;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: break-word;
  min-height: ${px2vw(50)};
`;

export const DateTag = styled.div`
  background: ${branco};
  margin: 0 auto;
  font-size: ${px2vw(10)};
  padding: ${px2vw(2)} ${px2vw(5)};
  position: sticky;
  font-weight: 600;
  top: 0;
  border-radius: ${px2vw(5)};
  min-width: ${px2vw(80)};
  text-align: center;
  margin-top: ${px2vw(5)};
  margin-bottom: ${px2vw(5)};
  z-index: 10;
`;

export const ShowMoreTag = styled.div`
  background: ${branco};
  margin: 0 auto;
  font-size: ${px2vw(10)};
  padding: ${px2vw(2)} ${px2vw(5)};
  font-weight: 600;
  top: 0;
  border-radius: ${px2vw(5)};
  min-width: ${px2vw(80)};
  text-align: center;
  margin-top: ${px2vw(5)};
  margin-bottom: ${px2vw(5)};
`;

export const MessageButton = styled(Col)`
  background: ${branco};
  box-shadow: 0 1px 1px ${cinzaKuppi4};
  border-radius: ${px2vw(10)};
  padding: ${px2vw(3)};
  padding-right: ${px2vw(3)};
  margin-bottom: ${px2vw(5)};
  width: 100%;

  ${(props) =>
    props.me &&
    `
  background: ${verdeKuppi27};
  align-self: flex-end;
  `}
`;

export const SelectedImg = styled.img`
  height: ${px2vw(400)};
  max-width: 90%;
  border-radius: 5px;
  padding: auto;
`;

export const ExpandMore = styled(MdExpandMore)`
  position: absolute;
  top: 0;
  right: 0;
  display: none;
  padding: ${px2vw(4)};
  border-radius: 9999px;
  background: inherit;
  font-size: ${px2vw(25)};
  color: ${preto}99;
  cursor: pointer;
`;

export const Overlay = styled(Row)`
  position: absolute;
  height: 100%;
  width: 100vw;
  top: 0;
  left: 0;
  right: 0;
  background: #0006;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  z-index: 1;
  box-sizing: content-box;
`;

export const PopoverMenuItem = styled(Row)`
  justify-content: center;
  align-items: center;
  color: ${preto};
  width: 100%;
  background: ${branco};
  &:hover {
    background: ${cinzaKuppi3}40;
  }
  cursor: pointer;
`;

export const BrokenMedia = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: ${px2vw(280)};
  height: ${px2vw(280)};
  justify-content: center;
  font-size: large;
  border-radius: ${px2vw(10)};
  align-items: center;
  text-align: center;
  background-color: ${cinzaKuppi9};
  ${({ type }) =>
    ['audio', 'document'].includes(type) &&
    `
    flex-direction: row;
    width: ${px2vw(280)};
    height: ${px2vw(80)};
  `}
`;

export const MDUL = styled.ul`
  list-style-position: inside;
  list-style-type: none;
  li::before {
    content: '- ';
  }
`;
export const MDOL = styled.ul`
  list-style-position: inside;
  list-style-type: decimal;
`;

export const ForwaredBadge = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${px2vw(8)};
  padding-left: ${px2vw(5)};
  padding-top: ${px2vw(3)};
  font-size: ${px2vw(8)};
  color: ${cinzaKuppi10};
`;

export const MDPre = styled.pre`
  margin-bottom: 0;
`
