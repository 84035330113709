export const PageTitleSuccess = 'Pagamento realizado com sucesso!';
export const PageTextRenewBlocked = `O pagamento da sua fatura foi efetuado com sucesso. 
Enviamos todos os detalhes da transação para seu e-mail. Aproveite a plataforma 
da Kuppi e tenha uma ótima experiência para alavancar suas vendas.`;
export const PageTextFirstPayment = `Parabéns, seu plano já está ativo! Enviamos todos os detalhes da
transação, bem como os seus dados de acesso a plataforma da Kuppi e demais
instruções para seu e-mail. Aproveite a plataforma da Kuppi e tenha uma
ótima experiência para alavancar suas vendas.`;
export const PlanActiveText = 'Ativo';
export const VigencyText = 'Vigência: $$VIGENCY$$';
export const CreditCardPaymentText = 'Pagamento com cartão';
export const AccessPlatformText = 'Acessar a plataforma';
