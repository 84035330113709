import React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cleanMassMessageData } from '../../../store/modules/massMessage/actions';
import CampaignType from './Step1-CampaignType';
import Coupon from './Step2-Coupon';
import Message from './Step3-Message';
import ClientSegmentation from './Step4-ClientSegmentation';
import Schedule from './Step5-Schedule';

import { ModalBackground, ModalContent } from './styles';
import { getOrdersRequest } from '../../../store/modules/orders/actions';
import EditCampaign from './EditCampaign';

export default function MassMessageModal({ ...props }) {
  const dispatch = useDispatch();
  const { currentStep, isEditing } = useSelector((state) => state.massMessage);

  useEffect(() => {
    return () => {
      dispatch(cleanMassMessageData());
    };
  }, []);

  useEffect(() => {
    dispatch(getOrdersRequest('finished'));
  }, []);

  return (
    <ModalBackground isVisible={props.isVisible}>
      <ModalContent
        width={props.width}
        height={props.height}
        bgcolor={props.bgcolor}
        margtop={props.margtop}
        padding={props.padding}
        backgroundImg={props.backgroundImg}
        backgroundImgSize={props.backgroundImgSize}
        maxheight={props.maxheight}
      >
        {currentStep === '1' && <CampaignType onClose={props.onClose} />}
        {currentStep === '2' && <Coupon onClose={props.onClose} />}
        {currentStep === '3' && (
          <Message isEditing={isEditing} onClose={props.onClose} />
        )}
        {currentStep === '4' && <ClientSegmentation onClose={props.onClose} />}
        {currentStep === '5' && <Schedule onClose={props.onClose} />}
        {isEditing && currentStep === 'edit' && <EditCampaign onClose={props.onClose} />}
      </ModalContent>
    </ModalBackground>
  );
}
