import React from 'react';
import { Spinner } from 'reactstrap';

import px2vw from '../../utils/responsiveness/px2vw';
import ButtonOnboarding from '../../components/ButtonOnboarding';
import { Row, Col } from '../../styles/grid';
import { preto, verdeKuppi4 } from '../../styles/colors';

import {
  Card,
  CardContainer,
  CardHeader,
  CardImg,
  CardText,
  Img,
  NormalText,
} from '../../pages/Onboarding/BaseScreen/styles';
import ButtonOnboardingSecondary from '../ButtonOnboarding/ButtonOnboardingSecondary';

import {
  LoadingBox,
} from '../../pages/_layouts/styles/common';
import { Text } from '../../styles/common';

export default function WelcomeScreenCard({
  mainImg,
  headerStep,
  stepTitle,
  stepSubText,
  stepBtnText,
  opacity,
  isMobile,
  loading,
  headerTimerAmount = '0',
  onClick = () => {}
}) {

  let cardWidth = 238;
  let cardHeight = 297;

  let btnShadowHeight = 6;
  let btnShadowWidth = 110;
  let btnHeight = 50;
  let btnWidth = 190;
  let btnFontSize = 16;

  let cardHeaderFontSize = 11;
  let cardHeaderborderRadius = 15;
  let cardHeaderHeight = 30;
  let cardHeaderPadLeft = 10;
  let cardHeaderPadRight = 10;

  let borderRadius = 15;
  let ellipsis = px2vw(70);
  let cardImgWidth = px2vw(40);
  let cardImgHeight = px2vw(40);
  let cardTitleFontSize = 16;
  let cardSubTitleFontSize = 12;
  let cardMargin = `${px2vw(15)} 0`;
  let imgSpaceRight = px2vw(55);
  let imgSpaceTop = px2vw(14);

  let cardMargBottom = 0;
  let cardImgMargleft = px2vw(20);
  let cardImgMargbottom = 0;
  let cardImgMargtop = px2vw(10);
  
  let subTextPadRight = 0;
  let subTextPadLeft = 0;
  let buttonMargTop = px2vw(20);
  let buttonMargBottom = 0;
  let stepSubTextMinHeight = px2vw(50);

  if (isMobile) {
    cardWidth = 1200;
    cardHeight = 1200;
    btnShadowHeight = 30;
    btnShadowWidth = 500;
    btnHeight = 200;
    btnWidth = 1000;
    btnFontSize = 70;
    cardHeaderFontSize = 70;
    cardHeaderborderRadius = 400;
    cardMargin = `${px2vw(55)} 0`;
    borderRadius = 150;
    cardHeaderHeight = 150;
    cardHeaderPadLeft = 100;
    cardHeaderPadRight = 100;
    ellipsis = px2vw(400);
    cardImgWidth = px2vw(250);
    cardImgHeight = px2vw(250);
    cardTitleFontSize = 70;
    cardSubTitleFontSize = 50;
    imgSpaceRight = px2vw(300);
    imgSpaceTop = px2vw(70);
    cardMargBottom = px2vw(100);
    cardImgMargleft = px2vw(100);
    cardImgMargbottom = px2vw(50);
    cardImgMargtop = px2vw(50);
    subTextPadRight = px2vw(120);
    subTextPadLeft = px2vw(120);
    buttonMargTop = px2vw(60);
    buttonMargBottom = px2vw(100);
    stepSubTextMinHeight = 'auto';
  }
  const greenElipsis = require('../../assets/images/onboarding/cards/greenEllipse@2x.png');
  const grayElipsis = require('../../assets/images/onboarding/cards/ellipse@2x.png');

  return (
    <Col alignCenter margbottom={cardMargBottom} width='auto'>
      <CardContainer opacity={opacity} width='auto'>
        <CardHeader
          fontsize={cardHeaderFontSize}
          borderRadius={cardHeaderborderRadius}
          color={preto}
          backgroundcolor={`${verdeKuppi4}35`}
          width={cardWidth}
          height={cardHeaderHeight}
          padleft={cardHeaderPadLeft}
          padright={cardHeaderPadRight}
        >
          {headerStep}
          <Text>
            {`${headerTimerAmount} min`}
          </Text>
        </CardHeader>
        <Card 
          width={cardWidth} 
          height={cardHeight} 
          borderRadius={`0 0 ${px2vw(borderRadius)} ${px2vw(borderRadius)}`}
        >
          <Row margleft={cardImgMargleft} margbottom={cardImgMargbottom} margtop={cardImgMargtop}>
            <CardImg>
              <Img
                width={ellipsis}
                height={ellipsis}
                alt="mainImgBg"
                src={opacity ? grayElipsis : greenElipsis}
              />
            </CardImg>
            <CardImg right={imgSpaceRight} top={imgSpaceTop}>
              <Img
                width={cardImgWidth}
                height={cardImgHeight}
                alt="mainImg"
                src={mainImg}
              />
            </CardImg>
          </Row>
          <Col alignCenter>
            <CardText fontsize={cardTitleFontSize} marg={cardMargin}>
              {stepTitle}
            </CardText>
            <NormalText
              padright={subTextPadRight}
              padleft={subTextPadLeft}
              fontsize={cardSubTitleFontSize}
              minHeight={stepSubTextMinHeight}
            >
              {stepSubText}
            </NormalText>
          </Col>
          <Col margtop={buttonMargTop} margbottom={buttonMargBottom} alignCenter>
            {opacity ? (
              <ButtonOnboardingSecondary
                fontsize={btnFontSize}
                btnWidth={btnWidth}
                btnHeight={btnHeight}
                btnShadowWidth={btnShadowWidth}
                btnShadowHeight={btnShadowHeight}
              >
                {stepBtnText}
              </ButtonOnboardingSecondary>
            ) : (
              <ButtonOnboarding
                fontsize={btnFontSize}
                btnWidth={btnWidth}
                btnHeight={btnHeight}
                btnShadowWidth={btnShadowWidth}
                btnShadowHeight={btnShadowHeight}
                onClick={onClick}
                disabled={loading}
              >
                {loading ? (
                  <LoadingBox>
                    <Spinner
                      style={{
                        width: '1.8rem',
                        height: '1.8rem',
                        color: preto,
                      }}
                    />
                  </LoadingBox>
                ) : stepBtnText}
              </ButtonOnboarding>
            )}
          </Col>
        </Card>
      </CardContainer>
    </Col>
  )
}