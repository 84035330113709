import React from 'react';
import { useSelector } from 'react-redux';

import { Row, Col } from '../../../../../styles/grid';
import { NormalText, Img } from '../../../BaseScreen/styles';

import ButtonPrimary from '../../../../../components/ButtonPrimary';
import ButtonSecondary from '../../../../../components/ButtonSecondary';

import px2vw from '../../../../../utils/responsiveness/px2vw';

export default function RecommendDesktop({ handler }) {
  const userReducer = useSelector((state) => state.user);

  function processRecommendedAction(type) {
    if (type === 'sendReminder') {
      // Decrypt user password to send trought whatsapp message
      const CryptoJS = require("crypto-js");
      const decPwdBytes = CryptoJS.AES.decrypt(userReducer.pwdEncrypted, process.env.REACT_APP_PASSWD_CRYPT_KEY);
      const pwdDecrypted = decPwdBytes.toString(CryptoJS.enc.Utf8);
      const reminderText = encodeURI(`Olá, é a Kuppi aqui!

Finalize as configurações do seu Delivery pelo *computador* e explore o potencial máximo da nossa ferramenta.
      
*Plataforma*: https://portal.kuppi.com.br
      
Estou enviando suas informações para mantê-las salva e de fácil acesso, mas elas também estão no seu e-mail.
      
*Usuário*: ${userReducer.user.email}
*Senha*: ${pwdDecrypted}
      
Mas não se preocupe, após configurado é possível receber e controlar os pedidos tanto pelo computador quanto pelo celular!`);

      window.open(
        `https://wa.me/${
          userReducer.user.advertiserInfo.phone_whatsapp_representative
        }?text=${reminderText}`
      );
    } else {
      handler.setShowRecommendDesktopPage(false);
    }
  }

  return (
    <Col alignCenter pad={px2vw(80)}>
      <Row>
        <Img
          width={px2vw(900)}
          height={px2vw(630)}
          alt="computer"
          src={require('../../../../../assets/images/onboarding/computerImg@2x.png')}
        />
      </Row>

      <NormalText
        textalign='start'
        padright={px2vw(70)}
        padleft={px2vw(70)}
        fontsize={px2vw(70)}
        padtop={px2vw(70)}
        fontweight="bold"
      >
        Recomendação
      </NormalText>
      <NormalText
        textalign='center'
        padleft={px2vw(20)}
        padright={px2vw(20)}
        padtop={px2vw(40)}
        fontsize={px2vw(60)}
        padbottom={px2vw(60)}
      >
        Acesse a nossa plataforma pelo computador para realizar a configuração 
        completa do seu delivery, pois pelo celular tem algumas limitações.
      </NormalText>

      <Row margbottom={px2vw(70)}>
        <ButtonPrimary
          borderRadius={40}
          minWidth='100%'
          height={180}
          fontSize={55}
          onClick={() => processRecommendedAction('sendReminder')}
        >
          Enviar lembrete para o meu whatsapp
        </ButtonPrimary>
      </Row>

      <ButtonSecondary
        borderRadius={40}
        minWidth='100%'
        height={180}
        fontSize={55}
        onClick={() => processRecommendedAction('continueHere')}
      >
        Acessar configuração pelo celular
      </ButtonSecondary>
    </Col>
  )
}
