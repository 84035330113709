import styled, { keyframes } from 'styled-components';
import {
  branco,
  cinzaKuppi1,
  cinzaKuppi15,
  cinzaKuppi3,
  cinzaKuppi4,
  cinzaKuppi8,
  preto,
  verdeKuppi2,
} from '../../../styles/colors';
import { Col, Row } from '../../../styles/grid';
import px2vw from '../../../utils/responsiveness/px2vw';
import { Link } from 'react-router-dom';
import { MdExpandMore } from 'react-icons/md';

const stretch = keyframes`
  0% {
    width: ${px2vw(125)};
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    width: ${px2vw(135)};
  }
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  position: relative;
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
    color: inherit;
  }
  color: inherit;
  width: 100%;
`;

export const Bar = styled(Col)`
  width: 31%;
  max-width: ${px2vw(415)};
  height: 100%;
  background: ${branco};
  ${(props) =>
    !props.isWhatsappwebPage &&
    `
  width: 100%;
  max-width: 100%;
  min-height: 100%;
  `}
`;

export const ChatBarTab = styled(Col)`
  width: 50%;
  max-width: ${px2vw(415)};
  height: 100%;
  font-weight: 600;
  align-items: center;
  font-size: ${px2vw(13)};
  justify-content: center;
  position: relative;
  cursor: pointer;
`;

export const SelectedTab = styled(Row)`
  position: absolute;
  bottom: ${px2vw(2)};
  border-radius: ${px2vw(10)};
  height: ${px2vw(5)};
  background: ${verdeKuppi2};
  animation-name: ${stretch};
  width: ${px2vw(135)};
  animation-duration: 0.4s;
`;

export const Span = styled.span`
  font-size: ${px2vw(11)};
  font-weight: 600;
  text-align: center;
  ${(props) => props.size && `font-size: ${props.size};`}
  ${(props) => props.textAlign && `text-align: ${props.textAlign};`}
  ${(props) => props.color && `color: ${props.color};`}
  ${(props) => props.letterSpacing && `letter-spacing: ${props.letterSpacing};`}
  ${(props) => props.lineHeight && `line-height: ${props.lineHeight};`}
  ${(props) => props.fontWeight && `font-weight: ${props.fontWeight};`}
  ${(props) => props.p && `padding: ${props.p};`}
  ${(props) => props.pb && `padding-bottom: ${props.pb};`}
  ${(props) => props.pl && `padding-left: ${props.pl};`}
  ${(props) => props.pr && `padding-right: ${props.pr};`}
  ${(props) => props.pt && `padding-top: ${props.pt};`}
  ${(props) => props.m && `margin: ${props.m};`}
  ${(props) => props.mb && `margin-bottom: ${props.mb};`}
  ${(props) => props.ml && `margin-left: ${props.ml};`}
  ${(props) => props.mr && `margin-right: ${props.mr};`}
  ${(props) => props.mt && `margin-top: ${props.mt};`}
  ${(props) => props.maxWidth && `max-width: ${props.maxWidth};`}
  ${(props) => props.overflow && `overflow: ${props.overflow};`}
  ${(props) => props.textOverflow && `text-overflow: ${props.textOverflow};`}
  ${(props) => props.whiteSpace && `white-space: ${props.whiteSpace};`}
`;

export const SearchInput = styled.input`
  border-radius: ${px2vw(8)};
  width: 100%;
  font-size: ${px2vw(12)};
  height: ${px2vw(33)};
  background: ${cinzaKuppi15}84;
  border: none;
  padding-left: ${px2vw(35)};
  padding-right: ${px2vw(15)};
  &::placeholder {
    font-weight: 600;
    font-size: ${px2vw(12)};
  }
  selector::-webkit-search-cancel-button {
    content: 'x';
    background-color: red;
    /* ... */
  }
`;

export const SearchInputStyle = {
  position: 'absolute',
  left: px2vw(12),
  fontSize: px2vw(15),
  fontWeight: 700,
  color: preto,
};

export const SearchInputClearStyle = {
  position: 'absolute',
  right: px2vw(12),
  fontSize: px2vw(15),
  fontWeight: 700,
  color: preto,
  cursor: 'pointer',
};

export const ChatList = styled(Col)`
  width: 100%;
  max-height: 100%;
  overflow-y: scroll;
  justify-content: flex-start;
  background: ${branco};

  &::-webkit-scrollbar {
    width: ${px2vw(6)};
    height: ${px2vw(6)};
    border-radius: 50%;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${cinzaKuppi15};
    border: ${px2vw(4)} solid ${cinzaKuppi15};
    border-radius: ${px2vw(15)};
    background-clip: padding-box;
  }
  &::-webkit-scrollbar-track {
    background: ${cinzaKuppi8};
  }
`;

export const ChatListItem = styled(Row)`
  width: 100%;
  justify-content: flex-start;
  min-height: ${px2vw(85)};
  max-height: ${px2vw(85)};
  border-bottom: ${px2vw(2)} solid ${cinzaKuppi8};
  background: ${branco};
  cursor: pointer;
  &:hover {
    background: ${cinzaKuppi1};
  }
  ${(props) =>
    props.selected &&
    `
  background: ${cinzaKuppi4}80;
  &:hover {
    background:${cinzaKuppi4}80;
  }
  `}
`;

export const Bagde = styled.div`
  border-radius: ${px2vw(10)};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  padding: 0px ${px2vw(4)};
  text-align: center;
  min-width: ${(props) => props.w || px2vw(70)};
  min-height: ${(props) => props.h || px2vw(17)};
  color: ${(props) => props.color || 'black'};
  font-size: ${(props) => props.fontSize || px2vw(8.5)};
  background: ${(props) => props.bg || cinzaKuppi4};
  ${(props) => props.p && `padding: ${props.p};`}
  ${(props) => props.pb && `padding-bottom: ${props.pb};`}
  ${(props) => props.pl && `padding-left: ${props.pl};`}
  ${(props) => props.pr && `padding-right: ${props.pr};`}
  ${(props) => props.pt && `padding-top: ${props.pt};`}
  ${(props) => props.m && `margin: ${props.m};`}
  ${(props) => props.mb && `margin-bottom: ${props.mb};`}
  ${(props) => props.ml && `margin-left: ${props.ml};`}
  ${(props) => props.mr && `margin-right: ${props.mr};`}
  ${(props) => props.mt && `margin-top: ${props.mt};`}

  @media (min-width: 800px) {
    padding: 0px ${px2vw(6)};
  }
`;

export const ExpandMore = styled(MdExpandMore)`
  position: absolute;
  top: ${px2vw(5)};
  right: ${px2vw(5)};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${px2vw(2)};
  border-radius: 9999px;
  background: ${branco};
  font-size: ${px2vw(24)};
  color: ${preto}99;
  cursor: pointer;
  z-index: 10;
`;

export const PopoverMenu = styled(Col)`
  position: absolute;
  width: fit-content;
  top: ${px2vw(25)};
  right: ${px2vw(10)};
  background-color: ${branco};
  border: 1px solid ${cinzaKuppi8};
  z-index: 10;
  padding: ${px2vw(3)};
  border-radius: ${px2vw(7)};
  font-size: ${px2vw(16)};
`;

export const PopoverMenuItem = styled(Row)`
  color: ${preto};
  justify-content: start;
  align-items: center;
  background: ${branco};
  padding: ${px2vw(5)} ${px2vw(7)};
  border-radius: ${px2vw(5)};
  font-size: ${px2vw(11)};
  &:hover {
    background: ${cinzaKuppi3}40;
  }
  cursor: pointer;
`;

export const EmptyContacts = styled.div`
  display: flex;
  width: 100%;
  height: ${px2vw(100)};
  justify-content: center;
  align-items: center;
`;

export const SearchEmptyContactsObs = styled.div`
  display: flex;
  width: 100%;
  height: ${px2vw(50)};
  justify-content: center;
  align-items: center;
  border-top: ${px2vw(2)} solid ${cinzaKuppi8};
  padding-left: ${px2vw(10)};
  padding-right: ${px2vw(10)};
`;

export const LoadMore = styled.div`
  width: 100%;
  height: 20px;
  display: flex;
  font-size: 0.7rem;
  justify-content: center;
  align-items: center;
  color: white;
`;
