import produce from 'immer';

const INITIAL_STATE = {
  usedCouponsTraditional: [],
  usedCouponsFlash: [],
  usedCouponsLoading: false,
};

export default function coupons(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@coupons/GET_USED_COUPONS_TRADITIONAL_REQUEST': {
        draft.usedCouponsLoading = true;
        draft.usedCouponsTraditional = [];
        draft.error = false;
        break;
      }
      case '@coupons/GET_USED_COUPONS_TRADITIONAL_SUCCESS': {
        draft.usedCouponsLoading = false;
        if (action.payload.coupons !== '') {
          draft.usedCouponsTraditional = action.payload.coupons;
        }
        draft.error = false;
        break;
      }
      case '@coupons/GET_USED_COUPONS_TRADITIONAL_FAILURE': {
        draft.usedCouponsLoading = false;
        draft.usedCouponsTraditional = [];
        draft.error = true;
        break;
      }
      case '@coupons/GET_USED_COUPONS_FLASH_REQUEST': {
        draft.usedCouponsLoading = true;
        draft.usedCouponsFlash = [];
        draft.error = false;
        break;
      }
      case '@coupons/GET_USED_COUPONS_FLASH_SUCCESS': {
        draft.usedCouponsLoading = false;
        if (action.payload.coupons !== '') {
          draft.usedCouponsFlash = action.payload.coupons;
        }
        draft.error = false;
        break;
      }
      case '@coupons/GET_USED_COUPONS_FLASH_FAILURE': {
        draft.usedCouponsLoading = false;
        draft.usedCouponsFlash = [];
        draft.error = true;
        break;
      }
      default:
    }
  });
}
