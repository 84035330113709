import { Radio } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { AiOutlineLeft } from 'react-icons/ai';
import InputRange from 'react-input-range';
import { useDispatch, useSelector } from 'react-redux';
import {
  setManualList,
  setSegmentations,
} from '../../../../../store/modules/massMessage/actions';
import { cinzaKuppi7 } from '../../../../../styles/colors';
import { Text } from '../../../../../styles/common';
import { Col, Row } from '../../../../../styles/grid';
import px2vw from '../../../../../utils/responsiveness/px2vw';
import { ModalPrimaryButton } from '../../styles';
import { CloseButton, CloseImage, ModalHeader, ModalContent } from '../styles';
import {
  TypeBox,
  RadioButton,
  CustonInputRange,
  Input,
  ResumeBox,
  OverFlowY,
} from './styles';

export default function ManualSegmentation({ onClose, onCreateManualListTab }) {
  const dispatch = useDispatch();
  const {
    contactsPotentialList,
    contactsNewList,
    contactsRecurrentList,
    contactsVipList,
    contactsRiskList,
    contactListIgnoreWithCampaign,
    manualListConfig,
    manualList,
  } = useSelector((state) => state.massMessage);

  const [selectedSegmentations, setSelectedSegmentations] = useState([
    'vip',
    'potential',
    'new',
    'recurrent',
    'risk',
  ]);

  const [isVipFilterActive, setIsVipFilterActive] = useState(
    manualListConfig?.isVipFilterActive || false
  );
  const [isRecurrentFilterActive, setIsRecurrentFilterActive] = useState(
    manualListConfig?.isRecurrentFilterActive || false
  );
  const [isNewFilterActive, setIsNewFilterActive] = useState(
    manualListConfig?.isNewFilterActive || false
  );
  const [isPotentialFilterActive, setIsPotentialFilterActive] = useState(
    manualListConfig?.isPotentialFilterActive || false
  );
  const [isRiskFilterActive, setIsRiskFilterActive] = useState(
    manualListConfig?.isRiskFilterActive || false
  );

  const contactsPotentialListData = !isPotentialFilterActive
    ? contactsPotentialList
    : contactsPotentialList.filter(
        (c) => !contactListIgnoreWithCampaign.includes(c.id)
      );
  const contactsNewListData = !isNewFilterActive
    ? contactsNewList
    : contactsNewList.filter(
        (c) => !contactListIgnoreWithCampaign.includes(c.id)
      );
  const contactsRecurrentListData = !isRecurrentFilterActive
    ? contactsRecurrentList
    : contactsRecurrentList.filter(
        (c) => !contactListIgnoreWithCampaign.includes(c.id)
      );
  const contactsVipListData = !isVipFilterActive
    ? contactsVipList
    : contactsVipList.filter(
        (c) => !contactListIgnoreWithCampaign.includes(c.id)
      );
  const contactsRiskListData = !isRiskFilterActive
    ? contactsRiskList
    : contactsRiskList.filter(
        (c) => !contactListIgnoreWithCampaign.includes(c.id)
      );

  const [vipTotal, setVipTotal] = useState(
    manualListConfig?.vipTotal || contactsVipListData?.length
  );

  const [potentialTotal, setPotentialTotal] = useState(
    manualListConfig?.potentialTotal || contactsPotentialListData?.length
  );
  const [recurrentTotal, setRecurrentTotal] = useState(
    manualListConfig?.recurrentTotal || contactsRecurrentListData?.length
  );
  const [newTotal, setNewTotal] = useState(
    manualListConfig?.newTotal || contactsNewListData?.length
  );
  const [riskTotal, setRiskTotal] = useState(
    manualListConfig?.riskTotal || contactsRiskListData?.length
  );

  useEffect(() => {
    if (isVipFilterActive && contactsVipListData?.length < vipTotal) {
      setVipTotal(contactsVipListData?.length);
    }

    if (
      isRecurrentFilterActive &&
      contactsRecurrentListData?.length < recurrentTotal
    ) {
      setRecurrentTotal(contactsRecurrentListData?.length);
    }

    if (
      isPotentialFilterActive &&
      contactsPotentialListData?.length < potentialTotal
    ) {
      setPotentialTotal(contactsPotentialListData?.length);
    }

    if (isNewFilterActive && contactsNewListData?.length < newTotal) {
      setNewTotal(contactsNewListData?.length);
    }

    if (isRiskFilterActive && contactsRiskListData?.length < riskTotal) {
      setRiskTotal(contactsRiskListData?.length);
    }
  }, [
    isVipFilterActive,
    isRecurrentFilterActive,
    isPotentialFilterActive,
    isNewFilterActive,
    isRiskFilterActive,
  ]);

  useEffect(() => {
    if (contactsVipList.length === 0 && selectedSegmentations.includes('vip')) {
      setSelectedSegmentations(
        selectedSegmentations.filter((s) => s !== 'vip')
      );
    }
    if (
      contactsPotentialList.length === 0 &&
      selectedSegmentations.includes('potential')
    ) {
      setSelectedSegmentations(
        selectedSegmentations.filter((s) => s !== 'potential')
      );
    }
    if (contactsNewList.length === 0 && selectedSegmentations.includes('new')) {
      setSelectedSegmentations(
        selectedSegmentations.filter((s) => s !== 'new')
      );
    }
    if (
      contactsRecurrentList.length === 0 &&
      selectedSegmentations.includes('recurrent')
    ) {
      setSelectedSegmentations(
        selectedSegmentations.filter((s) => s !== 'recurrent')
      );
    }
    if (
      contactsRiskList.length === 0 &&
      selectedSegmentations.includes('risk')
    ) {
      setSelectedSegmentations(
        selectedSegmentations.filter((s) => s !== 'risk')
      );
    }
  }, []);

  const totalClients = useMemo(() => {
    const isVipSelected = selectedSegmentations.includes('vip');
    const isRecurrentSelected = selectedSegmentations.includes('recurrent');
    const isNewSelected = selectedSegmentations.includes('new');
    const isPotentialSelected = selectedSegmentations.includes('potential');
    const isRiskSelected = selectedSegmentations.includes('risk');

    return (
      parseInt(isVipSelected ? vipTotal : 0) +
      parseInt(isPotentialSelected ? potentialTotal : 0) +
      parseInt(isNewSelected ? newTotal : 0) +
      parseInt(isRecurrentSelected ? recurrentTotal : 0) +
      parseInt(isRiskSelected ? riskTotal : 0)
    );
  }, [
    vipTotal,
    potentialTotal,
    newTotal,
    recurrentTotal,
    riskTotal,
    selectedSegmentations,
  ]);

  function handleSelectedSegmentation(seg) {
    const isAllReadySelected = selectedSegmentations.includes(seg);

    if (isAllReadySelected) {
      setSelectedSegmentations(selectedSegmentations.filter((s) => s !== seg));
    } else {
      setSelectedSegmentations([...selectedSegmentations, seg]);
    }
  }

  function handleOnChangeSetRecurrentList(e) {
    if (e.target.value === '') {
      setRecurrentTotal(0);
    } else if (parseInt(e.target.value) > contactsRecurrentListData.length) {
      setRecurrentTotal(contactsRecurrentListData.length);
    } else {
      setRecurrentTotal(e.target.value);
    }
  }
  function handleOnChangeSetVipList(e) {
    if (e.target.value === '') {
      setVipTotal(0);
    } else if (parseInt(e.target.value) > contactsVipListData.length) {
      setVipTotal(contactsVipListData.length);
    } else {
      setVipTotal(e.target.value);
    }
  }
  function handleOnChangeSetNewList(e) {
    if (e.target.value === '') {
      setNewTotal(0);
    } else if (parseInt(e.target.value) > contactsNewListData.length) {
      setNewTotal(contactsNewListData.length);
    } else {
      setNewTotal(e.target.value);
    }
  }
  function handleOnChangeSetPotentialList(e) {
    if (e.target.value === '') {
      setPotentialTotal(0);
    } else if (parseInt(e.target.value) > contactsPotentialListData.length) {
      setPotentialTotal(contactsPotentialListData.length);
    } else {
      setPotentialTotal(e.target.value);
    }
  }
  function handleOnChangeSetRiskList(e) {
    if (e.target.value === '') {
      setRiskTotal(0);
    } else if (parseInt(e.target.value) > contactsRiskListData.length) {
      setRiskTotal(contactsRiskListData.length);
    } else {
      setRiskTotal(e.target.value);
    }
  }

  function handleCreateManualList() {
    let list = [];
    let config = {
      potentialTotal,
      isPotentialFilterActive,
      vipTotal,
      isVipFilterActive,
      newTotal,
      isNewFilterActive,
      riskTotal,
      isRiskFilterActive,
      recurrentTotal,
      isRecurrentFilterActive,
    };
    if (selectedSegmentations.includes('potential')) {
      list.push(
        ...[...contactsPotentialListData]
          .sort((a, b) => 2 * Math.round(Math.random()) - 1)
          .slice(0, potentialTotal)
      );
    }
    if (selectedSegmentations.includes('vip')) {
      list.push(
        ...[...contactsVipListData]
          .sort((a, b) => 2 * Math.round(Math.random()) - 1)
          .slice(0, vipTotal)
      );
    }
    if (selectedSegmentations.includes('recurrent')) {
      list.push(
        ...[...contactsRecurrentListData]
          .sort((a, b) => 2 * Math.round(Math.random()) - 1)
          .slice(0, recurrentTotal)
      );
    }
    if (selectedSegmentations.includes('new')) {
      list.push(
        ...[...contactsNewListData]
          .sort((a, b) => 2 * Math.round(Math.random()) - 1)
          .slice(0, newTotal)
      );
    }
    if (selectedSegmentations.includes('risk')) {
      list.push(
        ...[...contactsRiskListData]
          .sort((a, b) => 2 * Math.round(Math.random()) - 1)
          .slice(0, riskTotal)
      );
    }

    dispatch(setManualList(list, config));
    onCreateManualListTab();
    onClose();
  }

  return (
    <>
      <ModalHeader className="pb-1">
        Crie o seu público do disparo
        <CloseButton onClick={onClose}>
          <CloseImage
            src={require('../../../../../assets/images/close/close.png')}
          />
        </CloseButton>
      </ModalHeader>
      <ModalContent style={{display: 'flex'}}>
        <Col>
          <OverFlowY
            justifyStart
            className="py-1"
            height={px2vw(450)}
            overflowY
          >
            <TypeBox
              selected={
                selectedSegmentations.includes('recurrent') &&
                contactsRecurrentListData.length
              }
            >
              <Col justifyStart width="22%">
                <Text className="mx-auto" alignCenter size={px2vw(13)}>
                  Recorrentes
                </Text>
                <Input
                  disabled={
                    !selectedSegmentations.includes('recurrent') ||
                    !contactsRecurrentList?.length
                  }
                  value={recurrentTotal}
                  onChange={(e) => handleOnChangeSetRecurrentList(e)}
                  type="number"
                  max={contactsRecurrentList?.length}
                  min={0}
                />
                <Text className="mx-auto" alignCenter regular size={px2vw(10)}>
                  Digite a quantidade
                </Text>
              </Col>
              <Col spaceBetween>
                <Row height="auto" justifyStart alignCenter relative>
                  <CustonInputRange>
                    <InputRange
                      disabled={
                        !selectedSegmentations.includes('recurrent') ||
                        !contactsRecurrentList?.length
                      }
                      maxValue={contactsRecurrentListData?.length}
                      minValue={0}
                      step={1}
                      value={recurrentTotal}
                      onChange={(value) =>
                        setRecurrentTotal(
                          value.toString().trim() === '' ? 0 : value
                        )
                      }
                    />
                  </CustonInputRange>
                  <Text
                    regular
                    alignCenter
                    color={cinzaKuppi7}
                    size={px2vw(12)}
                    margleft={px2vw(12)}
                  >
                    {contactsRecurrentListData.length || 0} usuário
                    {contactsRecurrentListData.length === 1 ? '' : 's'}
                  </Text>
                  <RadioButton
                    onClick={() => handleSelectedSegmentation('recurrent')}
                    selected={
                      contactsRecurrentListData.length &&
                      selectedSegmentations.includes('recurrent')
                    }
                    disabled={!contactsRecurrentList.length}
                  ></RadioButton>
                </Row>
                <Row
                  height="auto"
                  alignCenter
                  relative
                  pad={`0 ${px2vw(10)} 0 0`}
                >
                  <Radio
                    disabled={!contactsRecurrentList.length}
                    checked={isRecurrentFilterActive}
                    onClick={() =>
                      setIsRecurrentFilterActive(!isRecurrentFilterActive)
                    }
                    color="default"
                  />
                  <Text
                    margleft={px2vw(4)}
                    regular
                    alignCenter
                    size={px2vw(11)}
                  >
                    Manter apenas usuários não impactados por disparos nos
                    últimos 7 dias.
                  </Text>
                </Row>
                <Row
                  height="auto"
                  alignCenter
                  relative
                  pad={`0 ${px2vw(10)} 0 0`}
                >
                  <Text
                    margleft={px2vw(4)}
                    regular
                    alignCenter
                    size={px2vw(11)}
                  >
                    Você selecionou{' '}
                    <strong className="mx-1">
                      {`${recurrentTotal} usuário${
                        recurrentTotal === 1 ? '' : 's'
                      }`}
                    </strong>{' '}
                    nessa lista
                  </Text>
                </Row>
              </Col>
            </TypeBox>
            <TypeBox
              selected={
                selectedSegmentations.includes('vip') &&
                contactsVipListData.length
              }
            >
              <Col justifyStart width="22%">
                <Text className="mx-auto" alignCenter size={px2vw(13)}>
                  VIPS
                </Text>
                <Input
                  disabled={
                    !selectedSegmentations.includes('vip') ||
                    !contactsVipList?.length
                  }
                  value={vipTotal}
                  onChange={(e) => handleOnChangeSetVipList(e)}
                  type="number"
                  max={contactsVipList?.length}
                  min={0}
                />
                <Text className="mx-auto" alignCenter regular size={px2vw(10)}>
                  Digite a quantidade
                </Text>
              </Col>
              <Col spaceBetween>
                <Row height="auto" justifyStart alignCenter relative>
                  <CustonInputRange>
                    <InputRange
                      disabled={
                        !selectedSegmentations.includes('vip') ||
                        !contactsVipList?.length
                      }
                      maxValue={contactsVipListData?.length}
                      minValue={0}
                      step={1}
                      value={vipTotal}
                      onChange={(value) =>
                        setVipTotal(value.toString().trim() === '' ? 0 : value)
                      }
                    />
                  </CustonInputRange>
                  <Text
                    regular
                    alignCenter
                    color={cinzaKuppi7}
                    size={px2vw(12)}
                    margleft={px2vw(12)}
                  >
                    {contactsVipListData.length || 0} usuário
                    {contactsVipListData.length === 1 ? '' : 's'}
                  </Text>
                  <RadioButton
                    onClick={() => handleSelectedSegmentation('vip')}
                    selected={
                      contactsVipListData.length &&
                      selectedSegmentations.includes('vip')
                    }
                    disabled={!contactsVipListData.length}
                  ></RadioButton>
                </Row>
                <Row
                  height="auto"
                  alignCenter
                  relative
                  pad={`0 ${px2vw(10)} 0 0`}
                >
                  <Radio
                    disabled={!contactsVipList.length}
                    checked={isVipFilterActive}
                    onClick={() => setIsVipFilterActive(!isVipFilterActive)}
                    color="default"
                  />
                  <Text
                    margleft={px2vw(4)}
                    regular
                    alignCenter
                    size={px2vw(11)}
                  >
                    Manter apenas usuários não impactados por disparos nos
                    últimos 7 dias.
                  </Text>
                </Row>
                <Row
                  height="auto"
                  alignCenter
                  relative
                  pad={`0 ${px2vw(10)} 0 0`}
                >
                  <Text
                    margleft={px2vw(4)}
                    regular
                    alignCenter
                    size={px2vw(11)}
                  >
                    Você selecionou{' '}
                    <strong className="mx-1">
                      {`${vipTotal} usuário${vipTotal === 1 ? '' : 's'}`}
                    </strong>{' '}
                    nessa lista
                  </Text>
                </Row>
              </Col>
            </TypeBox>
            <TypeBox
              selected={
                contactsPotentialListData.length &&
                selectedSegmentations.includes('potential')
              }
            >
              <Col justifyStart width="22%">
                <Text className="mx-auto" alignCenter size={px2vw(13)}>
                  Potenciais
                </Text>
                <Input
                  disabled={
                    !selectedSegmentations.includes('potential') ||
                    !contactsPotentialList?.length
                  }
                  value={potentialTotal}
                  onChange={(e) => handleOnChangeSetPotentialList(e)}
                  type="number"
                  max={contactsPotentialList?.length}
                  min={0}
                />
                <Text className="mx-auto" alignCenter regular size={px2vw(10)}>
                  Digite a quantidade
                </Text>
              </Col>
              <Col spaceBetween>
                <Row height="auto" justifyStart alignCenter relative>
                  <CustonInputRange>
                    <InputRange
                      disabled={
                        !selectedSegmentations.includes('potential') ||
                        !contactsPotentialList?.length
                      }
                      maxValue={contactsPotentialListData?.length}
                      minValue={0}
                      step={1}
                      value={potentialTotal}
                      onChange={(value) =>
                        setPotentialTotal(
                          value.toString().trim() === '' ? 0 : value
                        )
                      }
                    />
                  </CustonInputRange>
                  <Text
                    regular
                    alignCenter
                    color={cinzaKuppi7}
                    size={px2vw(12)}
                    margleft={px2vw(12)}
                  >
                    {contactsPotentialListData.length || 0} usuário
                    {contactsPotentialListData.length === 1 ? '' : 's'}
                  </Text>
                  <RadioButton
                    onClick={() => handleSelectedSegmentation('potential')}
                    selected={
                      contactsPotentialListData.length &&
                      selectedSegmentations.includes('potential')
                    }
                    disabled={!contactsPotentialListData.length}
                  ></RadioButton>
                </Row>
                <Row
                  height="auto"
                  alignCenter
                  relative
                  pad={`0 ${px2vw(10)} 0 0`}
                >
                  <Radio
                    disabled={!contactsPotentialList.length}
                    checked={isPotentialFilterActive}
                    onClick={() =>
                      setIsPotentialFilterActive(!isPotentialFilterActive)
                    }
                    color="default"
                  />
                  <Text
                    margleft={px2vw(4)}
                    regular
                    alignCenter
                    size={px2vw(11)}
                  >
                    Manter apenas usuários não impactados por disparos nos
                    últimos 7 dias.
                  </Text>
                </Row>
                <Row
                  height="auto"
                  alignCenter
                  relative
                  pad={`0 ${px2vw(10)} 0 0`}
                >
                  <Text
                    margleft={px2vw(4)}
                    regular
                    alignCenter
                    size={px2vw(11)}
                  >
                    Você selecionou{' '}
                    <strong className="mx-1">
                      {`${potentialTotal} usuário${
                        potentialTotal === 1 ? '' : 's'
                      }`}
                    </strong>{' '}
                    nessa lista
                  </Text>
                </Row>
              </Col>
            </TypeBox>
            <TypeBox
              selected={
                contactsNewListData.length &&
                selectedSegmentations.includes('new')
              }
            >
              <Col justifyStart width="22%">
                <Text className="mx-auto" alignCenter size={px2vw(13)}>
                  Novos
                </Text>
                <Input
                  disabled={!selectedSegmentations.includes('new')}
                  value={newTotal}
                  onChange={(e) => handleOnChangeSetNewList(e)}
                  type="number"
                  max={contactsNewList?.length}
                  min={0}
                />
                <Text className="mx-auto" alignCenter regular size={px2vw(10)}>
                  Digite a quantidade
                </Text>
              </Col>
              <Col spaceBetween>
                <Row height="auto" justifyStart alignCenter relative>
                  <CustonInputRange>
                    <InputRange
                      disabled={
                        !selectedSegmentations.includes('new') ||
                        !contactsNewList?.length
                      }
                      maxValue={contactsNewListData?.length}
                      minValue={0}
                      step={1}
                      value={newTotal}
                      onChange={(value) =>
                        setNewTotal(value.toString().trim() === '' ? 0 : value)
                      }
                    />
                  </CustonInputRange>
                  <Text
                    regular
                    alignCenter
                    color={cinzaKuppi7}
                    size={px2vw(12)}
                    margleft={px2vw(12)}
                  >
                    {contactsNewListData.length || 0} usuário
                    {contactsNewListData.length === 1 ? '' : 's'}
                  </Text>
                  <RadioButton
                    onClick={() => handleSelectedSegmentation('new')}
                    selected={
                      contactsNewListData.length &&
                      selectedSegmentations.includes('new')
                    }
                    disabled={!contactsNewList.length}
                  ></RadioButton>
                </Row>
                <Row
                  height="auto"
                  alignCenter
                  relative
                  pad={`0 ${px2vw(10)} 0 0`}
                >
                  <Radio
                    disabled={!contactsNewList.length}
                    checked={isNewFilterActive}
                    onClick={() => setIsNewFilterActive(!isNewFilterActive)}
                    color="default"
                  />
                  <Text
                    margleft={px2vw(4)}
                    regular
                    alignCenter
                    size={px2vw(11)}
                  >
                    Manter apenas usuários não impactados por disparos nos
                    últimos 7 dias.
                  </Text>
                </Row>
                <Row
                  height="auto"
                  alignCenter
                  relative
                  pad={`0 ${px2vw(10)} 0 0`}
                >
                  <Text
                    margleft={px2vw(4)}
                    regular
                    alignCenter
                    size={px2vw(11)}
                  >
                    Você selecionou{' '}
                    <strong className="mx-1">
                      {`${newTotal} usuário${newTotal === 1 ? '' : 's'}`}
                    </strong>{' '}
                    nessa lista
                  </Text>
                </Row>
              </Col>
            </TypeBox>
            <TypeBox
              selected={
                contactsRiskListData.length &&
                selectedSegmentations.includes('new')
              }
            >
              <Col justifyStart width="22%">
                <Text className="mx-auto" alignCenter size={px2vw(13)}>
                  Risco
                </Text>
                <Input
                  disabled={!selectedSegmentations.includes('risk')}
                  value={riskTotal}
                  onChange={(e) => handleOnChangeSetRiskList(e)}
                  type="number"
                  max={contactsRiskList?.length}
                  min={0}
                />
                <Text className="mx-auto" alignCenter regular size={px2vw(10)}>
                  Digite a quantidade
                </Text>
              </Col>
              <Col spaceBetween>
                <Row height="auto" justifyStart alignCenter relative>
                  <CustonInputRange>
                    <InputRange
                      disabled={
                        !selectedSegmentations.includes('risk') ||
                        !contactsRiskList?.length
                      }
                      maxValue={contactsRiskListData?.length}
                      minValue={0}
                      step={1}
                      value={riskTotal}
                      onChange={(value) =>
                        setRiskTotal(value.toString().trim() === '' ? 0 : value)
                      }
                    />
                  </CustonInputRange>
                  <Text
                    regular
                    alignCenter
                    color={cinzaKuppi7}
                    size={px2vw(12)}
                    margleft={px2vw(12)}
                  >
                    {contactsRiskListData.length || 0} usuário
                    {contactsRiskListData.length === 1 ? '' : 's'}
                  </Text>
                  <RadioButton
                    onClick={() => handleSelectedSegmentation('risk')}
                    selected={
                      contactsRiskListData.length &&
                      selectedSegmentations.includes('risk')
                    }
                    disabled={!contactsRiskList.length}
                  ></RadioButton>
                </Row>
                <Row
                  height="auto"
                  alignCenter
                  relative
                  pad={`0 ${px2vw(10)} 0 0`}
                >
                  <Radio
                    disabled={!contactsRiskList.length}
                    checked={isRiskFilterActive}
                    onClick={() => setIsRiskFilterActive(!isRiskFilterActive)}
                    color="default"
                  />
                  <Text
                    margleft={px2vw(4)}
                    regular
                    alignCenter
                    size={px2vw(11)}
                  >
                    Manter apenas usuários não impactados por disparos nos
                    últimos 7 dias.
                  </Text>
                </Row>
                <Row
                  height="auto"
                  alignCenter
                  relative
                  pad={`0 ${px2vw(10)} 0 0`}
                >
                  <Text
                    margleft={px2vw(4)}
                    regular
                    alignCenter
                    size={px2vw(11)}
                  >
                    Você selecionou{' '}
                    <strong className="mx-1">
                      {`${riskTotal} usuário${riskTotal === 1 ? '' : 's'}`}
                    </strong>{' '}
                    nessa lista
                  </Text>
                </Row>
              </Col>
            </TypeBox>
          </OverFlowY>
          <Row alignCenter>
            <Row
              onClick={onClose}
              justifyStart
              alignCenter
              width="auto"
              cursor="pointer"
            >
              <AiOutlineLeft fontSize={px2vw(15)} className="mr-1" />
              <Text regular alignCenter size={px2vw(15)}>
                Voltar
              </Text>
            </Row>
            <ResumeBox>
              <Text className="mx-auto" alignCenter size={px2vw(11)}>
                O público selecionado é de {totalClients} usuário
                {totalClients === 1 ? '' : 's'}
              </Text>
            </ResumeBox>

            <ModalPrimaryButton
              width={px2vw(100)}
              onClick={handleCreateManualList}
              disabled={!totalClients}
            >
              {!manualList.length ? 'Criar' : 'Salvar'}
            </ModalPrimaryButton>
          </Row>
        </Col>
      </ModalContent>
    </>
  );
}
