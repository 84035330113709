import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import { FormControlLabel } from '@material-ui/core/';
import { Spinner } from 'reactstrap';
import { toast } from 'react-toastify';

import { Row, Col } from '../../styles/grid';
import { LoadingBox } from '../../styles/common';
import { preto } from '../../styles/colors';
import {
  SubtitleText,
  WhatsappFunctionsBox,
  WhatsappActionBox,
  ContainerVideo,
  BlockTitle,
  BlockText,
  FAQTitleQuestion,
  FAQTitle,
  FAQAccordion,
  FAQTitleContainerQuestion,
  FAQAnswerContainer,
  FAQAnswer,
  ButtonNewQrCode,
  TitleNewQrCode,
  ButtonDisconnectWpp,
  TitleDisconnectWpp,
  WhatsappImportantBox
} from './styles';
import {
  PageTitle,
  ContentPage,
  SwitchController,
  SwitchLabelBoldText,
  SwitchButtonBox,
} from '../_layouts/styles/common';

import DisconnectWhatsappbotModal from '../../components/DisconnectWhatsappbotModal';
import ButtonPrimary from '../../components/ButtonPrimary';

import px2vw from '../../utils/responsiveness/px2vw';

import {
  initiateWhatsappbotRequest,
  disconnectWhatsappbotRequest,
  changeWhatsappbotFunctionsRequest,
} from '../../store/modules/whatsappBot/actions';

export default function WhatsappBot({ handler, isOnboarding = false}) {
  const [faqSelectedItem, setFaqSelectedItem] = useState(0);
  const [disconnectModalVisible, setDisconnectModalVisible] = useState(false);
  const isSendingOrderStatusMsg = useSelector(
    (state) =>
      state.user.user.advertiserInfo.advertiserOrdersConfig
        .is_send_whatsapp_msg_update_status
  );
  const isUsingChatbot = useSelector(
    (state) =>
      state.user.user.advertiserInfo.advertiserOrdersConfig.is_using_chatbot
  );
  const qrCodeImage = useSelector((state) => state.user.user.advertiserInfo.advertiserWhatsappbotConfig.qr_code_image);
  const whatsappIsActive = useSelector((state) => state.user.user.advertiserInfo.advertiserWhatsappbotConfig.is_active);
  const isInitiatingWhatsappBot = useSelector((state) => state.user.user.advertiserInfo.advertiserWhatsappbotConfig.status === 'initialized');
  const dispatch = useDispatch();

  const faq = [
    {
      id: 1,
      title: 'Posso utilizar o meu próprio número do delivery no Chatbot?',
      description: 'Com certeza. Para usar o número do seu estabelecimento, basta ler o QR Code pelo whatsapp do seu delivery',
    },
    {
      id: 2,
      title: 'Posso estar conectado ao Whatsapp Web e ao Chatbot ao mesmo tempo?',
      description: 'Sim, o seu Whatsapp funcionará perfeitamente no Whatsapp Web e no Chatbot simultaneamente. O mesmo vale para o Whatsapp do Windows',
    },
    {
      id: 3,
      title: 'Como faço para desconectar o Chatbot temporariamente?',
      description: 'Para desconectar o Chatbot temporariamente basta clicar no botão superior na direita, e desmarcar a opção "Chatbot Atendimento"',
    },
    {
      id: 4,
      title: 'O Chatbot consegue montar um pedido pra mim?',
      description: 'Não, o chatbot serve para atender os seus clientes em imediato, e estimulá-los a pedir pelo cardápio digital. Ele ainda não consegue montar pedidos pelo próprio whatsapp',
    },
    {
      id: 5,
      title: 'Posso fazer uso da ferramenta para enviar mensagens aos meus clientes sobre promoções e pratos especiais?',
      description: 'Ainda não. Nessa versão, o bot funciona para estimular seus clientes a pedirem pelo cardápio digital',
    },
    {
      id: 6,
      title: 'Meu bot não responde ao receber mensagem ou o usuário clicar em algum botão, o que fazer?',
      description: 'Verifique os seguintes pontos, pois são as causas mais comuns desse tipo de problema: Whatsapp não está atualizado na última versão; Whatsapp com mensagem de saudação ativa; Whatsapp com mensagem de ausência',
    },
    {
      id: 7,
      title: 'Porque está aparecendo o aviso "Aguardando mensagem. Essa ação pode levar alguns instantes"?',
      description: 'Às vezes, você poderá ver o aviso acima ao invés da mensagem enviada por um contato. Devido à criptografia de ponta a ponta, é necessário esperar que o celular do seu contato fique online para que a mensagem seja entregue a você. Esse problema pode acontecer se você ou a pessoa com quem você está conversando reinstalaram o WhatsApp recentemente ou se um de vocês estiver usando uma versão mais antiga do app. Mais informações na página oficial do Whatsapp: ',
      link_text: 'FAQ Whatsapp',
      link: 'https://faq.whatsapp.com/general/security-and-privacy/seeing-waiting-for-this-message-this-may-take-a-while/?lang=pt_br'
    }
  ];

  useEffect(() => {
    // Initiate the whatsappbot at API if not active
    if (!whatsappIsActive && !isInitiatingWhatsappBot) {
      dispatch(initiateWhatsappbotRequest());
    }
  }, [dispatch]);

  function handleGenerateNewQRCode() {
    if (isInitiatingWhatsappBot) {
      toast.warn('O QR Code está sendo gerado, aguarde.', { autoClose: 6000 });
    } else {
      dispatch(initiateWhatsappbotRequest());
    }
  }

  function handleDisconnectWhatsapp() {
    setDisconnectModalVisible(!disconnectModalVisible)
    dispatch(disconnectWhatsappbotRequest());
  }

  function handleChangeWhatsappbotFunction(type) {
    if (type === 'sendOrderStatus') {
      dispatch(
        changeWhatsappbotFunctionsRequest(
          !isSendingOrderStatusMsg,
          isUsingChatbot
        )
      );
    } else if (type === 'chatbot') {
      dispatch(
        changeWhatsappbotFunctionsRequest(
          isSendingOrderStatusMsg,
          !isUsingChatbot
        )
      );
    }
  }

  return (
    <>
      <ContentPage margleft={isOnboarding ? px2vw(31) : 0}>
        {/* Page Header */}
        <Col
          height="auto"
          spaceBetween
          margbottom={px2vw(30)}
        >
          <Row height="auto" width="auto">
            <Col height="auto" width="auto">
              {!isOnboarding && <PageTitle>Whatsapp Bot</PageTitle>}
              <SubtitleText margtop={isOnboarding ? px2vw(0) : px2vw(20)}>
                Nunca mais perca pedidos no whatsapp por demorar a responder ou por não enviar o status do pedido.<br />
                Livre-se da dor de cabeça de atendimento com o nosso Whatsapp Bot.
              </SubtitleText>
            </Col>
            {/* Activate Whatsapp Bot functions */}
            {whatsappIsActive && (
              <Row height="auto" margtop={isOnboarding ? px2vw(-40) : px2vw(16)} justifyStart>
                <WhatsappFunctionsBox>
                  <Col height="auto" alignStart >
                    <SwitchButtonBox>
                      <FormControlLabel
                        control={
                          <SwitchController
                            checked={isUsingChatbot}
                            onChange={() => handleChangeWhatsappbotFunction('chatbot')}
                          />
                        }
                        label={
                          <SwitchLabelBoldText>
                            Chatbot de atendimento
                          </SwitchLabelBoldText>
                        }
                      />
                    </SwitchButtonBox>
                    <SwitchButtonBox>
                      <FormControlLabel
                        control={
                          <SwitchController
                            checked={isSendingOrderStatusMsg}
                            onChange={() =>
                              handleChangeWhatsappbotFunction('sendOrderStatus')
                            }
                          />
                        }
                        label={
                          <SwitchLabelBoldText>
                            Envio de status do pedido
                          </SwitchLabelBoldText>
                        }
                      />
                    </SwitchButtonBox>
                  </Col>
                </WhatsappFunctionsBox>
              </Row>
            )}
          </Row>
        </Col>
        {/* Page Content */}
        <Row height="auto" justifyStart margbottom={isOnboarding ? 0 : px2vw(50)}>
          {/* Connect or Disconnect */}
          <WhatsappActionBox>
            {!whatsappIsActive &&  (
              <Col justifyStart alignStart>
                <Row justifyStart height="auto" margleft={px2vw(5)}>
                  <Row height="auto" width="auto" margtop={px2vw(2)}>
                    <img alt="wppQrCode" src={require('../../assets/images/whatsappbot/qrcode-icon.svg')} />
                  </Row>
                  <BlockTitle margleft={px2vw(24)}>Passos para conectar o seu Whatsapp</BlockTitle>
                </Row>
                <Row height="auto" margtop={px2vw(30)}>
                  <Row width={px2vw(33)} height={px2vw(170)}>
                    <img alt="connectSteps" width="100%" height="100%" src={require('../../assets/images/whatsappbot/connect-steps.png')} />
                  </Row>
                  <Col height={px2vw(170)}>
                    <BlockText>
                      Abra o Whatsapp em seu celular
                    </BlockText>
                    <BlockText margtop={px2vw(47)} margbottom={px2vw(53)}>
                      Toque em Configurações e clique em "Conectar Aparelho"
                    </BlockText>
                    <BlockText>
                      Leia o QR Code e ative o Bot
                    </BlockText>
                  </Col>
                </Row>
                <Row alignCenter margtop={qrCodeImage ? px2vw(0) : px2vw(40)}>
                  {qrCodeImage ? (
                    <img
                      alt="qrCodeImg" 
                      src={qrCodeImage}
                      height={px2vw(4000)}
                      width={px2vw(4000)}
                    />
                  ) : (
                    <Col alignCenter>
                      <Row
                        cursor="pointer"
                        width="auto"
                        onClick={() => handleGenerateNewQRCode()}
                      >
                        <img
                          src={require('../../assets/images/whatsappbot/qrcode-failure.png')}
                          height={px2vw(3700)}
                          width={px2vw(3700)}
                        />
                      </Row>
                      <Row alignCenter margtop={px2vw(20)}>
                        <ButtonNewQrCode
                          onClick={() => handleGenerateNewQRCode()}
                        >
                          <Row>
                            <Row width="auto" margtop={px2vw(2)} margright={px2vw(10)}>
                              <img src={require('../../assets/images/whatsappbot/qrcode-icon-gray.svg')} />
                            </Row>
                            <TitleNewQrCode>
                              Gerar Novo QR Code
                            </TitleNewQrCode>
                          </Row>
                        </ButtonNewQrCode>
                      </Row>
                    </Col>
                  )}
                </Row>
              </Col>
            )}
            {whatsappIsActive &&  (
              <Col justifyStart alignCenter spaceAround>
                <img alt="connectedPhone" src={require('../../assets/images/whatsappbot/phone-connected.png')} height={px2vw(5000)} width={px2vw(5000)}/>
                <BlockTitle>Whatsapp Bot conectado ao seu telefone</BlockTitle>
                <ButtonDisconnectWpp
                  onClick={() => setDisconnectModalVisible(!disconnectModalVisible)}
                >
                  <TitleDisconnectWpp>
                    Desconectar Whatsapp
                  </TitleDisconnectWpp>
                </ButtonDisconnectWpp>
              </Col>
            )}
          </WhatsappActionBox>
          {/* Demo Video */}
          <Col height="auto" margleft={px2vw(30)}>
            <BlockTitle>Ficou na dúvida? Assista o vídeo</BlockTitle>
            <ContainerVideo>
              <iframe
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/eJsYt3PbC4U"
                title="Whatsapp Bot Video"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </ContainerVideo>
            <WhatsappImportantBox>
              <BlockTitle>IMPORTANTE:</BlockTitle>
              <BlockText margtop={px2vw(20)} margbottom={px2vw(8)} margleft={px2vw(0)} fontsize={px2vw(13)}>
                1. Certifique-se de que o seu Whatsapp Business está com a versão mais atualizada no seu celular.
              </BlockText>
              <BlockText margbottom={px2vw(10)} margleft={px2vw(0)} fontsize={px2vw(13)}>
                2. O Bot funciona para o Whatsapp normal, mas é mais indicado de ser utilizado no Whatsapp Business.
              </BlockText>
              <BlockText margbottom={px2vw(10)} margleft={px2vw(0)} fontsize={px2vw(13)}>
                3. Caso você responda o seu cliente no whatsapp com o bot ativo, o bot é pausado imediatamente e não responderá mais o seu cliente de forma automática.
              </BlockText>
              <BlockText margbottom={px2vw(10)} margleft={px2vw(0)} fontsize={px2vw(13)}>
                4. O seu whatsapp NÃO pode ter mensagem de saudação ativa.
              </BlockText>
              <BlockText margbottom={px2vw(10)} margleft={px2vw(0)} fontsize={px2vw(13)}>
                5. O seu whatsapp NÃO pode ter mensagem de ausência ativa.
              </BlockText>
            </WhatsappImportantBox>
          </Col>
        </Row>
        {/* FAQ */}
        {!isOnboarding && (
          <Col height="auto">
            <FAQTitle>
              Perguntas frequentes sobre o Whatsapp Bot
            </FAQTitle>
            {faq.map((item, idx) => (
              <FAQAccordion
                key={`faq-${item.id}-${idx}`}
                onClick={() => {
                  if (item.id === faqSelectedItem) {
                    setFaqSelectedItem(0);
                  } else {
                    setFaqSelectedItem(item.id);
                  }
                }}
              >
                <FAQTitleContainerQuestion>
                  <FAQTitleQuestion>{item.title}</FAQTitleQuestion>
                  {item.id === faqSelectedItem ? (
                    <MdKeyboardArrowUp size={40} />
                  ) : (
                    <MdKeyboardArrowDown size={40} />
                  )}
                </FAQTitleContainerQuestion>
                {item.id === faqSelectedItem && (
                  <FAQAnswerContainer>
                    <FAQAnswer>
                      {item.description}
                      {item.link_text && (
                        <a href={item.link} target="_blank" rel="noopener noreferrer">{item.link_text}</a>
                      )}
                    </FAQAnswer>
                  </FAQAnswerContainer>
                )}
              </FAQAccordion>
            ))}
          </Col>
        )}
        {isOnboarding && (
          <Row
            height="auto"
            width={px2vw(1000)}
            justifyEnd
            margtop={px2vw(20)}
            margbottom={px2vw(10)}
          >
            <ButtonPrimary
              minWidth={150}
              type="button"
              onClick={handler?.handleFinishStep}
            >
              {handler?.updateOnboardingStepLoading ? (
                <LoadingBox>
                  <Spinner
                    style={{
                      width: '1.8rem',
                      height: '1.8rem',
                      color: preto,
                    }}
                  />{' '}
                </LoadingBox>
              ) : (
                'Concluir'
              )}
            </ButtonPrimary>
          </Row>
        )}
      </ContentPage>
      {/* Disconnect whatsapp modal */}
      <DisconnectWhatsappbotModal
        isVisible={disconnectModalVisible}
        closeModal={() => setDisconnectModalVisible(!disconnectModalVisible)}
        disconnectWhatsapp={() => handleDisconnectWhatsapp()}
        width={px2vw(310)}
        height={px2vw(170)}
      />
    </>
  );
}
