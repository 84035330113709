import React from 'react';
import BrokenMedia from '../../BrokenMedia';
import { FcDocument } from 'react-icons/fc';
import { Row } from '../../../../../../styles/grid';
import { MessageDocument } from '../../style';
import { MdDownload } from 'react-icons/md';
import px2vw from '../../../../../../utils/responsiveness/px2vw';
import { cinzaKuppi3, preto } from '../../../../../../styles/colors';

export const DocumentMessage = ({ document }) => {
  return document.url ? (
    <MessageDocument>
      <Row
        background={preto + '10'}
        style={{ borderRadius: `${px2vw(5)}` }}
        spaceBetween
        alignCenter
      >
        <Row width="auto" justifyStart alignCenter>
          <FcDocument
            style={{
              fontSize: px2vw(35),
              marginLeft: `${px2vw(10)}`,
              marginRight: `${px2vw(2)}`,
            }}
          />
          {document.fileName}
        </Row>
        <a
          download={
            document.fileName?.split('-@doc@-')[0] || document?.fileName
          }
          target="_blank"
          href={document.url}
          rel="noopener noreferrer" 
        >
          <MdDownload
            color={cinzaKuppi3}
            style={{
              fontSize: px2vw(30),
              marginRight: `${px2vw(10)}`,
            }}
          />
        </a>
      </Row>
    </MessageDocument>
  ) : (
    <BrokenMedia type="document" />
  );
};
