import React, { useEffect } from 'react';
import { FormControlLabel } from '@material-ui/core/';
import { toast } from 'react-toastify';

import {
  TabContent,
  ProductInput,
  ProductInputLabelRegular,
  DisponibilityLabel,
  SpecificHourBox,
  HourDisponibilityBox,
  ProductSelectInput,
} from '../../styles';

import {
  SwitchController,
  SwitchButtonBox,
} from '../../../_layouts/styles/common';

import { Row, Col } from '../../../../styles/grid';

import {
  ModalPrimaryButton,
  ModalPrimaryButtonText,
  ModalActions,
  ModalSecundaryButton,
  ModalSecundaryButtonText,
} from '../../../../components/Modal/styles';

import px2vw from '../../../../utils/responsiveness/px2vw';
import { productAvailability } from '../../../../utils/commonObjects';
import {
  timeMask,
} from '../../../../utils/masks';

export default function TabDisponibility({ handler }) {
  // Set all disponibilities checked
  useEffect(() => {
    handler.setAllDisponibilitiesChecked(
      handler.productSunday &&
      handler.productMonday &&
      handler.productTuesday &&
      handler.productWednesday &&
      handler.productThursday &&
      handler.productFriday &&
      handler.productSaturday
    );
  }, [
    handler.productSunday,
    handler.productMonday,
    handler.productTuesday,
    handler.productWednesday,
    handler.productThursday,
    handler.productFriday,
    handler.productSaturday,
  ]);

  // Set the day availability of product
  function handleDayDisponibility(activeDay, dayOfWeek) {
    if (dayOfWeek === 'sunday') {
      handler.setProductSunday(activeDay);
      document.getElementById('productSunAvailability').value = '1';
      handler.handleHourDisponibility('1', 'sunday');
    } else if (dayOfWeek === 'monday') {
      handler.setProductMonday(activeDay);
      document.getElementById('productMonAvailability').value = '1';
      handler.handleHourDisponibility('1', 'monday');
    } else if (dayOfWeek === 'tuesday') {
      handler.setProductTuesday(activeDay);
      document.getElementById('productTueAvailability').value = '1';
      handler.handleHourDisponibility('1', 'tuesday');
    } else if (dayOfWeek === 'wednesday') {
      handler.setProductWednesday(activeDay);
      document.getElementById('productWedAvailability').value = '1';
      handler.handleHourDisponibility('1', 'wednesday');
    } else if (dayOfWeek === 'thursday') {
      handler.setProductThursday(activeDay);
      document.getElementById('productThuAvailability').value = '1';
      handler.handleHourDisponibility('1', 'thursday');
    } else if (dayOfWeek === 'friday') {
      handler.setProductFriday(activeDay);
      document.getElementById('productFriAvailability').value = '1';
      handler.handleHourDisponibility('1', 'friday');
    } else if (dayOfWeek === 'saturday') {
      handler.setProductSaturday(activeDay);
      document.getElementById('productSatAvailability').value = '1';
      handler.handleHourDisponibility('1', 'saturday');
    }
  }

  // Validate if the availability tab were successfully filled
  // function validateProductAvailability() {
  //   if (
  //     !handler.productSunday &&
  //     !handler.productMonday &&
  //     !handler.productTuesday &&
  //     !handler.productWednesday &&
  //     !handler.productThursday &&
  //     !handler.productFriday &&
  //     !handler.productSaturday
  //   ) {
  //     toast.error(
  //       'Deve ser selecionado ao menos um dia para o produto estar disponível.',
  //       {
  //         autoClose: 6000,
  //       }
  //     );
  //   } else if (
  //     (handler.specificHourSunday &&
  //       (handler.productSunStartTimeInputValue === '' ||
  //         handler.productSunEndTimeInputValue === '' ||
  //         !isTimeValid(handler.productSunStartTimeInputValue) ||
  //         !isTimeValid(handler.productSunEndTimeInputValue) ||
  //         handler.productSunStartTimeInputValue >= handler.productSunEndTimeInputValue)) ||
  //     (handler.specificHourMonday &&
  //       (handler.productMonStartTimeInputValue === '' ||
  //        handler.productMonEndTimeInputValue === '' ||
  //         !isTimeValid(handler.productMonStartTimeInputValue) ||
  //         !isTimeValid(handler.productMonEndTimeInputValue) ||
  //         handler.productMonStartTimeInputValue >= handler.productMonEndTimeInputValue)) ||
  //     (handler.specificHourTuesday &&
  //       (handler.productTueStartTimeInputValue === '' ||
  //        handler.productTueEndTimeInputValue === '' ||
  //         !isTimeValid(handler.productTueStartTimeInputValue) ||
  //         !isTimeValid(handler.productTueEndTimeInputValue) ||
  //         handler.productTueStartTimeInputValue >= handler.productTueEndTimeInputValue)) ||
  //     (handler.specificHourWednesday &&
  //       (handler.productWedStartTimeInputValue === '' ||
  //        handler.productWedEndTimeInputValue === '' ||
  //         !isTimeValid(handler.productWedStartTimeInputValue) ||
  //         !isTimeValid(handler.productWedEndTimeInputValue) ||
  //         handler.productWedStartTimeInputValue >= handler.productWedEndTimeInputValue)) ||
  //     (handler.specificHourThursday &&
  //       (handler.productThuStartTimeInputValue === '' ||
  //        handler.productThuEndTimeInputValue === '' ||
  //         !isTimeValid(handler.productThuStartTimeInputValue) ||
  //         !isTimeValid(handler.productThuEndTimeInputValue) ||
  //         handler.productThuStartTimeInputValue >= handler.productThuEndTimeInputValue)) ||
  //     (handler.specificHourFriday &&
  //       (handler.productFriStartTimeInputValue === '' ||
  //        handler.productFriEndTimeInputValue === '' ||
  //         !isTimeValid(handler.productFriStartTimeInputValue) ||
  //         !isTimeValid(handler.productFriEndTimeInputValue) ||
  //         handler.productFriStartTimeInputValue >= handler.productFriEndTimeInputValue)) ||
  //     (handler.specificHourSaturday &&
  //       (handler.productSatStartTimeInputValue === '' ||
  //        handler.productSatEndTimeInputValue === '' ||
  //         !isTimeValid(handler.productSatStartTimeInputValue) ||
  //         !isTimeValid(handler.productSatEndTimeInputValue) ||
  //         handler.productSatStartTimeInputValue >= handler.productSatEndTimeInputValue))
  //   ) {
  //     toast.error(
  //       'Algum horário foi marcado como horário especifico, porém não foi informado o horário corretamente. Revise os campos.',
  //       {
  //         autoClose: 6000,
  //       }
  //     );
  //   } else {
  //     handler.setCurrentTab('tabCoupons');
  //   }
  // }

  return (
    <TabContent>
      <Col height={px2vw(318)}>
        <DisponibilityLabel>
          Dias e horários que o produto estará disponível
        </DisponibilityLabel>
        <Row width="auto">
          <Row
            justifyStart
            height={px2vw(45)}
            margtop={px2vw(5)}
            width="auto"
          >
            <SwitchButtonBox
              width={px2vw(160)}
              margtop={px2vw(10)}
              margleft={px2vw(20)}
            >
              <FormControlLabel
                control={
                  <SwitchController
                    checked={handler.allDisponibilitiesChecked}
                    type="checkbox"
                    onChange={() => {
                      handleDayDisponibility(
                        !handler.allDisponibilitiesChecked,
                        'sunday'
                      );
                      handleDayDisponibility(
                        !handler.allDisponibilitiesChecked,
                        'monday'
                      );
                      handleDayDisponibility(
                        !handler.allDisponibilitiesChecked,
                        'tuesday'
                      );
                      handleDayDisponibility(
                        !handler.allDisponibilitiesChecked,
                        'wednesday'
                      );
                      handleDayDisponibility(
                        !handler.allDisponibilitiesChecked,
                        'thursday'
                      );
                      handleDayDisponibility(
                        !handler.allDisponibilitiesChecked,
                        'friday'
                      );
                      handleDayDisponibility(
                        !handler.allDisponibilitiesChecked,
                        'saturday'
                      );
                    }}
                  />
                }
                label={
                  <ProductInputLabelRegular>
                    Selecionar todos
                  </ProductInputLabelRegular>
                }
              />
            </SwitchButtonBox>
          </Row>
          <Col
            width="auto"
            id="productDisponibility"
            justifyStart
            height="auto"
          >
            <Row
              justifyStart
              height={px2vw(35)}
              margleft={px2vw(10)}
              margtop={px2vw(10)}
            >
              <SwitchButtonBox
                width={px2vw(180)}
                margtop={px2vw(10)}
                margleft={px2vw(20)}
              >
                <FormControlLabel
                  control={
                    <SwitchController
                      checked={handler.productSunday}
                      type="checkbox"
                      onChange={() =>
                        handleDayDisponibility(
                          !handler.productSunday,
                          'sunday'
                        )
                      }
                    />
                  }
                  label={
                    <ProductInputLabelRegular>
                      Domingo
                    </ProductInputLabelRegular>
                  }
                />
              </SwitchButtonBox>
              <HourDisponibilityBox isVisible={handler.productSunday}>
                <ProductSelectInput
                  id="productSunAvailability"
                  name="productSunAvailability"
                  width={px2vw(180)}
                  height={px2vw(35)}
                  margright={px2vw(20)}
                  value={handler.productSunSelectAvailability}
                  options={productAvailability}
                  onChange={(t) => {
                    handler.handleHourDisponibility(
                      t.target.value,
                      'sunday'
                    );
                  }}
                />
                <SpecificHourBox
                  isVisible={handler.productSunday && handler.specificHourSunday}
                >
                  <ProductInput
                    name="productSunStartTime"
                    height={px2vw(35)}
                    width={px2vw(65)}
                    maxLength="5"
                    placeholder="18:00"
                    value={handler.productSunStartTimeInputValue}
                    onChange={(t) => {
                      handler.setProductSunStartTimeInputValue(
                        timeMask(t.target.value)
                      );
                    }}
                  />
                  <ProductInputLabelRegular
                    margtop={px2vw(10)}
                    pad={px2vw(5)}
                  >
                    às
                  </ProductInputLabelRegular>
                  <ProductInput
                    name="productSunEndTime"
                    height={px2vw(35)}
                    width={px2vw(65)}
                    maxLength="5"
                    placeholder="23:00"
                    value={handler.productSunEndTimeInputValue}
                    onChange={(t) => {
                      handler.setProductSunEndTimeInputValue(
                        timeMask(t.target.value)
                      );
                    }}
                  />
                </SpecificHourBox>
              </HourDisponibilityBox>
            </Row>
            <Row
              justifyStart
              height={px2vw(35)}
              margleft={px2vw(10)}
              margtop={px2vw(3)}
            >
              <SwitchButtonBox
                width={px2vw(180)}
                margtop={px2vw(10)}
                margleft={px2vw(20)}
              >
                <FormControlLabel
                  control={
                    <SwitchController
                      checked={handler.productMonday}
                      type="checkbox"
                      onChange={() =>
                        handleDayDisponibility(
                          !handler.productMonday,
                          'monday'
                        )
                      }
                    />
                  }
                  label={
                    <ProductInputLabelRegular>
                      Segunda
                    </ProductInputLabelRegular>
                  }
                />
              </SwitchButtonBox>
              <HourDisponibilityBox isVisible={handler.productMonday}>
                <ProductSelectInput
                  id="productMonAvailability"
                  name="productMonAvailability"
                  width={px2vw(180)}
                  height={px2vw(35)}
                  margright={px2vw(20)}
                  value={handler.productMonSelectAvailability}
                  options={productAvailability}
                  onChange={(t) => {
                    handler.handleHourDisponibility(
                      t.target.value,
                      'monday'
                    );
                  }}
                />
                <SpecificHourBox
                  isVisible={handler.productMonday && handler.specificHourMonday}
                >
                  <ProductInput
                    name="productMonStartTime"
                    height={px2vw(35)}
                    width={px2vw(65)}
                    maxLength="5"
                    placeholder="18:00"
                    value={handler.productMonStartTimeInputValue}
                    onChange={(t) => {
                      handler.setProductMonStartTimeInputValue(
                        timeMask(t.target.value)
                      );
                    }}
                  />
                  <ProductInputLabelRegular
                    margtop={px2vw(10)}
                    pad={px2vw(5)}
                  >
                    às
                  </ProductInputLabelRegular>
                  <ProductInput
                    name="productMonEndTime"
                    height={px2vw(35)}
                    width={px2vw(65)}
                    maxLength="5"
                    placeholder="23:00"
                    value={handler.productMonEndTimeInputValue}
                    onChange={(t) => {
                      handler.setProductMonEndTimeInputValue(
                        timeMask(t.target.value)
                      );
                    }}
                  />
                </SpecificHourBox>
              </HourDisponibilityBox>
            </Row>
            <Row
              justifyStart
              height={px2vw(35)}
              margleft={px2vw(10)}
              margtop={px2vw(3)}
            >
              <SwitchButtonBox
                width={px2vw(180)}
                margtop={px2vw(10)}
                margleft={px2vw(20)}
              >
                <FormControlLabel
                  control={
                    <SwitchController
                      checked={handler.productTuesday}
                      type="checkbox"
                      onChange={() =>
                        handleDayDisponibility(
                          !handler.productTuesday,
                          'tuesday'
                        )
                      }
                    />
                  }
                  label={
                    <ProductInputLabelRegular>
                      Terça
                    </ProductInputLabelRegular>
                  }
                />
              </SwitchButtonBox>
              <HourDisponibilityBox isVisible={handler.productTuesday}>
                <ProductSelectInput
                  id="productTueAvailability"
                  name="productTueAvailability"
                  width={px2vw(180)}
                  height={px2vw(35)}
                  margright={px2vw(20)}
                  value={handler.productTueSelectAvailability}
                  options={productAvailability}
                  onChange={(t) => {
                    handler.handleHourDisponibility(
                      t.target.value,
                      'tuesday'
                    );
                  }}
                />
                <SpecificHourBox
                  isVisible={handler.productTuesday && handler.specificHourTuesday}
                >
                  <ProductInput
                    name="productTueStartTime"
                    height={px2vw(35)}
                    width={px2vw(65)}
                    maxLength="5"
                    placeholder="18:00"
                    value={handler.productTueStartTimeInputValue}
                    onChange={(t) => {
                      handler.setProductTueStartTimeInputValue(
                        timeMask(t.target.value)
                      );
                    }}
                  />
                  <ProductInputLabelRegular
                    margtop={px2vw(10)}
                    pad={px2vw(5)}
                  >
                    às
                  </ProductInputLabelRegular>
                  <ProductInput
                    name="productTueEndTime"
                    height={px2vw(35)}
                    width={px2vw(65)}
                    maxLength="5"
                    placeholder="23:00"
                    value={handler.productTueEndTimeInputValue}
                    onChange={(t) => {
                      handler.setProductTueEndTimeInputValue(
                        timeMask(t.target.value)
                      );
                    }}
                  />
                </SpecificHourBox>
              </HourDisponibilityBox>
            </Row>
            <Row
              justifyStart
              height={px2vw(35)}
              margleft={px2vw(10)}
              margtop={px2vw(3)}
            >
              <SwitchButtonBox
                width={px2vw(180)}
                margtop={px2vw(10)}
                margleft={px2vw(20)}
              >
                <FormControlLabel
                  control={
                    <SwitchController
                      checked={handler.productWednesday}
                      type="checkbox"
                      onChange={() =>
                        handleDayDisponibility(
                          !handler.productWednesday,
                          'wednesday'
                        )
                      }
                    />
                  }
                  label={
                    <ProductInputLabelRegular>
                      Quarta
                    </ProductInputLabelRegular>
                  }
                />
              </SwitchButtonBox>
              <HourDisponibilityBox isVisible={handler.productWednesday}>
                <ProductSelectInput
                  id="productWedAvailability"
                  name="productWedAvailability"
                  width={px2vw(180)}
                  height={px2vw(35)}
                  margright={px2vw(20)}
                  value={handler.productWedSelectAvailability}
                  options={productAvailability}
                  onChange={(t) => {
                    handler.handleHourDisponibility(
                      t.target.value,
                      'wednesday'
                    );
                  }}
                />
                <SpecificHourBox
                  isVisible={
                    handler.productWednesday && handler.specificHourWednesday
                  }
                >
                  <ProductInput
                    name="productWedStartTime"
                    height={px2vw(35)}
                    width={px2vw(65)}
                    maxLength="5"
                    placeholder="18:00"
                    value={handler.productWedStartTimeInputValue}
                    onChange={(t) => {
                      handler.setProductWedStartTimeInputValue(
                        timeMask(t.target.value)
                      );
                    }}
                  />
                  <ProductInputLabelRegular
                    margtop={px2vw(10)}
                    pad={px2vw(5)}
                  >
                    às
                  </ProductInputLabelRegular>
                  <ProductInput
                    name="productWedEndTime"
                    height={px2vw(35)}
                    width={px2vw(65)}
                    maxLength="5"
                    placeholder="23:00"
                    value={handler.productWedEndTimeInputValue}
                    onChange={(t) => {
                      handler.setProductWedEndTimeInputValue(
                        timeMask(t.target.value)
                      );
                    }}
                  />
                </SpecificHourBox>
              </HourDisponibilityBox>
            </Row>
            <Row
              justifyStart
              height={px2vw(35)}
              margleft={px2vw(10)}
              margtop={px2vw(3)}
            >
              <SwitchButtonBox
                width={px2vw(180)}
                margtop={px2vw(10)}
                margleft={px2vw(20)}
              >
                <FormControlLabel
                  control={
                    <SwitchController
                      checked={handler.productThursday}
                      type="checkbox"
                      onChange={() =>
                        handleDayDisponibility(
                          !handler.productThursday,
                          'thursday'
                        )
                      }
                    />
                  }
                  label={
                    <ProductInputLabelRegular>
                      Quinta
                    </ProductInputLabelRegular>
                  }
                />
              </SwitchButtonBox>
              <HourDisponibilityBox isVisible={handler.productThursday}>
                <ProductSelectInput
                  id="productThuAvailability"
                  name="productThuAvailability"
                  width={px2vw(180)}
                  height={px2vw(35)}
                  margright={px2vw(20)}
                  value={handler.productThuSelectAvailability}
                  options={productAvailability}
                  onChange={(t) => {
                    handler.handleHourDisponibility(
                      t.target.value,
                      'thursday'
                    );
                  }}
                />
                <SpecificHourBox
                  isVisible={
                    handler.productThursday && handler.specificHourThursday
                  }
                >
                  <ProductInput
                    name="productThuStartTime"
                    height={px2vw(35)}
                    width={px2vw(65)}
                    maxLength="5"
                    placeholder="18:00"
                    value={handler.productThuStartTimeInputValue}
                    onChange={(t) => {
                      handler.setProductThuStartTimeInputValue(
                        timeMask(t.target.value)
                      );
                    }}
                  />
                  <ProductInputLabelRegular
                    margtop={px2vw(10)}
                    pad={px2vw(5)}
                  >
                    às
                  </ProductInputLabelRegular>
                  <ProductInput
                    name="productThuEndTime"
                    height={px2vw(35)}
                    width={px2vw(65)}
                    maxLength="5"
                    placeholder="23:00"
                    value={handler.productThuEndTimeInputValue}
                    onChange={(t) => {
                      handler.setProductThuEndTimeInputValue(
                        timeMask(t.target.value)
                      );
                    }}
                  />
                </SpecificHourBox>
              </HourDisponibilityBox>
            </Row>
            <Row
              justifyStart
              height={px2vw(35)}
              margleft={px2vw(10)}
              margtop={px2vw(3)}
            >
              <SwitchButtonBox
                width={px2vw(180)}
                margtop={px2vw(10)}
                margleft={px2vw(20)}
              >
                <FormControlLabel
                  control={
                    <SwitchController
                      checked={handler.productFriday}
                      type="checkbox"
                      onChange={() =>
                        handleDayDisponibility(
                          !handler.productFriday,
                          'friday'
                        )
                      }
                    />
                  }
                  label={
                    <ProductInputLabelRegular>
                      Sexta
                    </ProductInputLabelRegular>
                  }
                />
              </SwitchButtonBox>
              <HourDisponibilityBox isVisible={handler.productFriday}>
                <ProductSelectInput
                  id="productFriAvailability"
                  name="productFriAvailability"
                  width={px2vw(180)}
                  height={px2vw(35)}
                  margright={px2vw(20)}
                  value={handler.productFriSelectAvailability}
                  options={productAvailability}
                  onChange={(t) => {
                    handler.handleHourDisponibility(
                      t.target.value,
                      'friday'
                    );
                  }}
                />
                <SpecificHourBox
                  isVisible={handler.productFriday && handler.specificHourFriday}
                >
                  <ProductInput
                    name="productFriStartTime"
                    height={px2vw(35)}
                    width={px2vw(65)}
                    maxLength="5"
                    placeholder="18:00"
                    value={handler.productFriStartTimeInputValue}
                    onChange={(t) => {
                      handler.setProductFriStartTimeInputValue(
                        timeMask(t.target.value)
                      );
                    }}
                  />
                  <ProductInputLabelRegular
                    margtop={px2vw(10)}
                    pad={px2vw(5)}
                  >
                    às
                  </ProductInputLabelRegular>
                  <ProductInput
                    name="productFriEndTime"
                    height={px2vw(35)}
                    width={px2vw(65)}
                    maxLength="5"
                    placeholder="23:00"
                    value={handler.productFriEndTimeInputValue}
                    onChange={(t) => {
                      handler.setProductFriEndTimeInputValue(
                        timeMask(t.target.value)
                      );
                    }}
                  />
                </SpecificHourBox>
              </HourDisponibilityBox>
            </Row>
            <Row
              justifyStart
              height={px2vw(35)}
              margleft={px2vw(10)}
              margtop={px2vw(3)}
            >
              <SwitchButtonBox
                width={px2vw(180)}
                margtop={px2vw(10)}
                margleft={px2vw(20)}
              >
                <FormControlLabel
                  control={
                    <SwitchController
                      checked={handler.productSaturday}
                      type="checkbox"
                      onChange={() =>
                        handleDayDisponibility(
                          !handler.productSaturday,
                          'saturday'
                        )
                      }
                    />
                  }
                  label={
                    <ProductInputLabelRegular>
                      Sábado
                    </ProductInputLabelRegular>
                  }
                />
              </SwitchButtonBox>
              <HourDisponibilityBox isVisible={handler.productSaturday}>
                <ProductSelectInput
                  id="productSatAvailability"
                  name="productSatAvailability"
                  width={px2vw(180)}
                  height={px2vw(35)}
                  margright={px2vw(20)}
                  margtop={px2vw(5)}
                  value={handler.productSatSelectAvailability}
                  options={productAvailability}
                  onChange={(t) => {
                    handler.handleHourDisponibility(
                      t.target.value,
                      'saturday'
                    );
                  }}
                />
                <SpecificHourBox
                  isVisible={
                    handler.productSaturday && handler.specificHourSaturday
                  }
                >
                  <ProductInput
                    name="productSatStartTime"
                    height={px2vw(35)}
                    width={px2vw(65)}
                    maxLength="5"
                    placeholder="18:00"
                    value={handler.productSatStartTimeInputValue}
                    onChange={(t) => {
                      handler.setProductSatStartTimeInputValue(
                        timeMask(t.target.value)
                      );
                    }}
                  />
                  <ProductInputLabelRegular
                    margtop={px2vw(10)}
                    pad={px2vw(5)}
                  >
                    às
                  </ProductInputLabelRegular>
                  <ProductInput
                    name="productSatEndTime"
                    height={px2vw(35)}
                    width={px2vw(65)}
                    maxLength="5"
                    placeholder="23:00"
                    value={handler.productSatEndTimeInputValue}
                    onChange={(t) => {
                      handler.setProductSatEndTimeInputValue(
                        timeMask(t.target.value)
                      );
                    }}
                  />
                </SpecificHourBox>
              </HourDisponibilityBox>
            </Row>
          </Col>
        </Row>
      </Col>
      <ModalActions>
        <ModalSecundaryButton
          margleft={px2vw(60)}
          marginRight={px2vw(20)}
          margtop={px2vw(20)}
          width={px2vw(290)}
          onClick={handler.handleCloseProductModal}
        >
          <ModalSecundaryButtonText>
            Cancelar
          </ModalSecundaryButtonText>
        </ModalSecundaryButton>
        <ModalPrimaryButton
          id="productDisponibilityNextButton"
          margleft={px2vw(20)}
          margtop={px2vw(20)}
          width={px2vw(290)}
          type="button"
          onClick={() => handler.validateProductAvailability('tabCoupons')}
        >
          <ModalPrimaryButtonText>Avançar</ModalPrimaryButtonText>
        </ModalPrimaryButton>
      </ModalActions>
    </TabContent>
  );
}
