const brasiliaTransformFunctions = [
  // Substituir "quadra" por "q"
  (value) => value.toLowerCase().replace('quadra', 'q'),
];

export const buildSearchTermAutocomplete = (search, address) => {
  const city = address.city.toLowerCase();
  const state = address.state.toLowerCase();
  if (city === 'brasília' && state === 'df') {
    let searchTerm = search;
    for (const transform of brasiliaTransformFunctions) {
      searchTerm = transform(searchTerm);
    }
    return searchTerm;
  }
  return search.toLowerCase();
};

export const hasAtLeastOneOf = (array, ...values) => {
  return array.some((value) => values.includes(value));
};

export const AddressFields = {
  STREET: 'street',
  NUMBER: 'number',
  NEIGHBORHOOD: 'neighborhood',
  CITY: 'city',
  STATE: 'state',
  ZIPCODE: 'zipcode',
};
