import dateFnsFormat from 'date-fns/format';
import { ptBR } from 'date-fns/locale';
const config = { locale: ptBR };

export const formatDate = (date, format) => dateFnsFormat(date, format, config);

export function formatDateFromString(input) {
  if (input) {
    let datePart = input.match(/\d+/g),
    year = datePart[0],
    month = datePart[1],
    day = datePart[2];

    return day+'/'+month+'/'+year;
  }
  return '';
}

export const translateWeekDay = (dayName) => {
  switch (dayName) {
    case 'sunday':
      return 'Domingo';
    case 'monday':
      return 'Segunda';
    case 'tuesday':
      return 'Terça';
    case 'wednesday':
      return 'Quarta';
    case 'thursday':
      return 'Quinta';
    case 'friday':
      return 'Sexta';
    case 'saturday':
      return 'Sábado';
    default:
      return '';
  }
};
