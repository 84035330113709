import { Tooltip } from 'reactstrap';
import styled, { css } from 'styled-components';

import {
  branco,
  branco2,
  cinzaKuppi4,
  preto2,
  verdeKuppi10,
  verdeKuppi11,
  verdeKuppi2,
  verdeKuppi30,
} from '../../../../styles/colors';

import px2vw from '../../../../utils/responsiveness/px2vw';

export const ModalHeader = styled.div`
  width: 100%;
  background-color: ${branco};
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: ${px2vw(33)} ${px2vw(28)} ${px2vw(15)} ${px2vw(28)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  font-size: ${px2vw(25)};
  display: flex;
  align-items: space-between;
  justify-content: space-between;
`;

export const CloseButton = styled.button`
  width: ${px2vw(18)};
  height: ${px2vw(18)};
  border: none;
  z-index: 10;
  background-color: transparent;

  :focus {
    background-color: transparent !important;
  }

  ${(props) =>
    props.absolute &&
    css`
      position: absolute;
      top: ${props.top || px2vw(10)};
      right: ${props.right || px2vw(10)};
    `}
`;

export const CloseImage = styled.img`
  width: ${px2vw(15)};
  height: ${px2vw(15)};
`;

export const ModalContent = styled.div`
  background-color: ${branco};
  padding: ${(props) => props.padding || '20px'};
  display: flex;
  justify-content: center;
  ${(props) => `
    width: ${props.width};
    min-height: ${props.height || px2vw(400)};
    max-height: ${props.maxheight || px2vw(350)};
    background-color: ${props.bgcolor};
    margin-top: ${props.margtop};
  `};
`;

export const CampaignTypeCard = styled.div`
  padding: ${px2vw(23)} ${px2vw(16)};
  width: ${(props) => props.width || px2vw(235)};
  height: ${(props) => props.height || px2vw(340)};
  border: 2px solid ${(props) => props.selected ? verdeKuppi2 : cinzaKuppi4};
  border-radius: ${px2vw(15)};
  margin: 0 ${px2vw(5)};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  opacity: ${(props) => props.selected || 0.5};
  cursor:pointer;
`;

export const CampaignTypeImg = styled.img`
  width: ${px2vw(100)};
  height: ${px2vw(142)};
  margin: 0 auto;
  pointer-events: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export const ModalFooter = styled.div`
  width: 100%;
  min-height: ${px2vw(90)};
  margin-top:auto;
  background-color: ${branco};
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  padding:  ${px2vw(15)} ${px2vw(32)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  font-size: ${px2vw(22)};
`;

export const ToolTipCustom = styled(Tooltip)`
opacity: 1 !important;
pointer-events: none;

.tooltip-inner {
  display: inline-block;
  position: relative;
  text-align: left;
  color: ${preto2};
  font-weight: 600;
  background-color: ${branco};
  border-width: 2px;
  border-style: solid;
  border-color: ${verdeKuppi2};
  padding: 10px;
  margin: 10px 0 0 0;
  filter: drop-shadow(rgba(0, 0, 0, 0.4) 0 2px 3px);
}

.arrow {
  display: none !important;
}

.tooltip-inner::before,
.tooltip-inner::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 100%;
  width: 0;
  height: 0;
  border-style: solid;
}

.tooltip-inner::after {
  right: calc(50% - 7px);
  border-color: transparent transparent ${branco} transparent;
  border-width: 7px;
}

.tooltip-inner::before {
  right: calc(50% - 10px);
  border-color: transparent transparent ${verdeKuppi2} transparent;
  border-width: 10px;
}
`;

export const RecommendedTag = styled.div`
  background-color: ${verdeKuppi2};
  padding: 0.5rem 1rem;
  border-radius: 999px;
  color: ${branco};
  font-weight: bold;
  font-size: ${px2vw(14)};
  margin-top: 1rem;
`