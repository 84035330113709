import styled, { css } from 'styled-components';
import px2vw from '../../utils/responsiveness/px2vw';
import { branco, cinzaKuppi4, cinzaKuppi6, verdeKuppi2 } from '../../styles/colors';

export const StyledButtonSecondary = styled.button`
  background-color: ${branco};
  border: 1px solid ${cinzaKuppi6};
  color: ${cinzaKuppi6};
  border-radius: ${(props) => px2vw(props.borderRadius || 10)};
  height: ${(props) => px2vw(props.height || 40)};
  min-width: ${(props) => px2vw(props.minWidth || 50)};
  line-height: ${(props) => px2vw(props.height || 30)};
  font-size: ${(props) => px2vw(props.fontSize || 14)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.36px;
  text-align: center;
  padding: 0 ${px2vw(10)};
  cursor: pointer;

  ${(props) =>
    props.minWidth &&
    css`
      min-width: ${props.minWidth};
    `}

  ${(props) =>
    props.marginRight &&
    css`
      margin-right: ${props.marginRight};
    `}

  ${(props) =>
    props.margleft &&
    css`
      margin-left: ${props.margleft};
    `}
  
  &:hover {
    background-color: ${cinzaKuppi4};
  }

  &:active {
    text-decoration: none;
    color: ${branco};
  }

  ${(props) => props.onboardingMobile && `
    min-width: ${px2vw(1000)};
    background: ${verdeKuppi2};
    color ${branco};
    margin-top: ${px2vw(40)};
    margin-bottom: ${px2vw(50)};
    border-radius: ${px2vw(20)};
    height: ${px2vw(120)};
  `}
`;
