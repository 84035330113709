import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'reactstrap';
import moment from 'moment';
import { toast } from 'react-toastify';
import { Icon, FormGroup } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { Row, Col } from '../../styles/grid';
import { LoadingBox } from '../../pages/_layouts/styles/common';
import {
  ModalActions,
  ModalPrimaryButton,
  ModalPrimaryButtonText,
  ModalSecundaryButton,
  ModalSecundaryButtonText,
  CloseButton,
  CloseImage,
} from '../Modal/styles';
import {
  OrderDetailModalBox,
  ModalTitleText,
  ItemName,
  ItemNameBold,
  ItemAmount,
  TextBold,
  TextRegular,
  TextMedium,
  ItemObs,
  ItemCompCategory,
  ItemComplement,
  ItemAmountComplement,
  Separator,
  NextStatusIcon,
  CloseIcon,
  PrintIcon,
  ConfirmCancelOrder,
  ConfirmCancelOrderText,
  ChoiceButton,
  ChoiceButtonSelected,
  SpanTxt,
  PmList,
  OverflowYBox,
  PmItem,
} from './styles';
import { cinzaKuppi4, preto } from '../../styles/colors';
import { CustonLink2, ErrorText } from '../../styles/common';
import {
  ClockImage,
  OrdersRowText,
  OrderTypeBox,
} from '../../pages/_layouts/styles/orders';

import Modal from '../Modal';
import OrderStatusBox from '../OrderStatusBox';
import SchedulingInfoBox from '../SchedulingInfoBox';
import ConfirmPreparingSchedulingOrder from '../ConfirmPreparingSchedulingOrder';

import {
  formatOrderNumber,
  formatCreateDateTimeOrder,
} from '../../utils/utils';
import { printOrderInvoice } from '../../utils/printOrderInvoice/PrintOrderInvoice';
import { ddiPhoneMask, cepMask } from '../../utils/masks';
import px2vw from '../../utils/responsiveness/px2vw';
import { sendWhatsappMessage } from '../../utils/ordersConfigs/ordersConfigs';
import { printInvoiceQZTray } from '../../utils/QzPrint';

import history from '../../services/history';
import { whatsappbot } from '../../routes/routeMap';

import {
  setCurrOrderStatusData,
  setCurrOrderConfigs,
  updateOrderStatusRequest,
  cancelOrderRequest,
  cancelOrderClearSuccess,
  updatePixKeyPaymentRequest,
  clearUpdatePixKeyPaymentSuccess,
  updateOrderStatusClearSuccess,
  updateOrderPaymentMethodRequest,
  clearNewOrderPaymentMethodData,
  getOrderDetailRequest,
  clearGetOrderDetailSuccess,
} from '../../store/modules/orders/actions';

export default function OrderDetailModal(props) {
  const {
    isVisible,
    orders,
    orderIndex,
    onModalResponse,
    hasNavigation,
    onScreen,
    isLoading = true
  } = props;
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(isVisible);
  const [isUpdating, setIsUpdating] = useState(false);
  const [ordersStatusesData, setOrdersStatusesData] = useState(orders);
  const [orderStatusDataIndex, setOrderStatusDataIndex] = useState(orderIndex);
  const [clientName, setClientName] = useState('Não Informado');
  const [clientAddress, setClientAddress] = useState('Não Informado');
  const [confirmCancelOrderModal, setConfirmCancelOrderModal] = useState(false);
  const [
    confirmPreparingSchedulingOrderModal,
    setConfirmPreparingSchedulingOrderModal,
  ] = useState(false);
  const [didPaymentConfirmed, setDidPaymentConfirmed] = useState(false);
  const advertiserPaymentMethods = useSelector(
    (state) => state?.user?.user?.advertiserInfo?.advertiserPaymentMethod
  );
  const offlineAvailablePaymentMethodData = advertiserPaymentMethods?.filter(
    (pm) => pm?.paymentMethod?.type === 'offline' && pm.is_active
  );
  const useNeighborhoodArea = useSelector(
    (state) =>
      state.user.user.advertiserInfo.advertiserDeliveryTakeawayConfig[0]
        .is_using_neighborhood_area
  );
  const alwaysPrintOrder = useSelector(
    (state) =>
      state.user.user.advertiserInfo.advertiserOrdersConfig
        .is_print_order_invoice_accept_order
  );
  const printMethod = useSelector(
    (state) =>
      state.user.user.advertiserInfo.advertiserOrdersConfig.print_method
  );
  const printerName = useSelector(
    (state) => state.user.user.advertiserInfo.advertiserPrintConfig.printer_name
  );
  const maxLineCharsAutomatic = useSelector(
    (state) =>
      state.user.user.advertiserInfo.advertiserPrintConfig
        .max_line_chars_automatic
  );
  const [selectedAlwaysPrintOrder, setSelectedAlwaysPrintOrder] =
    useState(alwaysPrintOrder);
  const alwaysSendWhatsappMsg = useSelector(
    (state) =>
      state.user.user.advertiserInfo.advertiserOrdersConfig
        .is_send_whatsapp_msg_update_status
  );
  const [selectedAlwaysSendWhatsappMsg, setSelectedAlwaysSendWhatsappMsg] =
    useState(alwaysSendWhatsappMsg);
  const updateOrderStatusLoading = useSelector(
    (state) => state.orders.updateOrderStatusLoading
  );
  const cancelOrderLoading = useSelector(
    (state) => state.orders.cancelOrderLoading
  );
  const updateOrderStatusSuccess = useSelector(
    (state) => state.orders.updateOrderStatusSuccess
  );
  const cancelOrderSuccess = useSelector(
    (state) => state.orders.cancelOrderSuccess
  );
  const whatsappIsActive = useSelector(
    (state) =>
      state.user.user.advertiserInfo.advertiserWhatsappbotConfig.is_active
  );
  const advertiserUser = useSelector((state) => state.user.user);
  const pixKey =
    advertiserUser.advertiserInfo?.advertiserPaymentMethod.find(
      (pm) => pm.payment_method_slug === 'pix_chave'
    ).external_processor_key || null;
  const pixKeyRequestLoading = useSelector(
    (state) => state.orders.pixKeyRequestLoading
  );
  const pixKeyRequestSuccess = useSelector(
    (state) => state.orders.pixKeyRequestSuccess
  );

  const updatePaymentMethodSuccess = useSelector(
    (state) => state.orders.updatePaymentMethodSuccess
  );

  const updatePaymentMethodLoading = useSelector(
    (state) => state.orders.updatePaymentMethodLoading
  );
  const newOrderPaymentMethod = useSelector(
    (state) => state.orders.newOrderPaymentMethod
  );
  const getOrderDetailSuccess = useSelector(
    (state) => state.orders.getOrderDetailSuccess
  );
  const [orderDetailLoading, setOrderDetailLoading] = useState(isLoading);

  useEffect(() => {
    if (getOrderDetailSuccess) {
      setOrderDetailLoading(false);
      dispatch(clearGetOrderDetailSuccess());
    }
  }, [getOrderDetailSuccess])

  useEffect(() => {
    setOrderStatusDataIndex(orderIndex);
  }, [orderIndex])

  useEffect(() => {
    setOrdersStatusesData(orders);
  }, [orders, updatePaymentMethodSuccess]);

  useEffect(() => {
    if (ordersStatusesData.length > 0 && orderStatusDataIndex !== '') {
      // Set Client Address
      const clientZipcode = ordersStatusesData[orderStatusDataIndex]?.order?.address?.zipcode ? 
        ` CEP: ${cepMask(ordersStatusesData[orderStatusDataIndex]?.order?.address?.zipcode)}` : '';
      setClientAddress(
        ordersStatusesData[orderStatusDataIndex]?.order?.address === null
          ? `Não Informado`
          : `${
              ordersStatusesData[orderStatusDataIndex]?.order?.address
                ?.street || ''
            }, ${
              ordersStatusesData[orderStatusDataIndex]?.order?.address
                ?.number || ''
            } ${
              ordersStatusesData[orderStatusDataIndex]?.order?.address
                ?.complement || ''
            } - ${
              ordersStatusesData[orderStatusDataIndex]?.order?.address
                ?.neighborhood || ''
            }, ${
              ordersStatusesData[orderStatusDataIndex]?.order?.address?.city ||
              ''
            } - ${
              ordersStatusesData[orderStatusDataIndex]?.order?.address?.state ||
              ''
            }${clientZipcode}`
      );

      // Set Pix Key Payment confirmed flag
      setDidPaymentConfirmed(
        ordersStatusesData[orderStatusDataIndex]?.order?.advertiserOrderPayment
          ?.paid_at
          ? true
          : false
      );

      // Set Client Name
      setClientName(
        ordersStatusesData[orderStatusDataIndex]?.order?.clientInfo?.name || 'Não informado'
      );
    }
  }, [orderStatusDataIndex, ordersStatusesData]);

  useEffect(() => {
    if (pixKeyRequestSuccess) {
      setDidPaymentConfirmed(!didPaymentConfirmed);
      dispatch(clearUpdatePixKeyPaymentSuccess());
    }
  }, [pixKeyRequestSuccess]);

  useEffect(() => {
    setVisible(isVisible);
  }, [isVisible]);

  const returnModalAction = useCallback(() => {
    function execute() {
      onModalResponse();
    }

    execute();
  });

  function closeModal() {
    if (onScreen === 'pedidos') {
      dispatch(clearNewOrderPaymentMethodData());
    }

    setSelectedAlwaysPrintOrder(alwaysPrintOrder);
    setSelectedAlwaysSendWhatsappMsg(alwaysSendWhatsappMsg);

    setOrderDetailLoading(isLoading);
    setVisible(false);
    returnModalAction();
  }

  function performOrderAction(orderStatusData, cancel) {
    dispatch(setCurrOrderStatusData(orderStatusData));
    dispatch(
      setCurrOrderConfigs(
        selectedAlwaysPrintOrder,
        selectedAlwaysSendWhatsappMsg
      )
    );
    // If user request do cancel the order
    if (cancel) {
      dispatch(cancelOrderRequest(orderStatusData, 'canceled'));
      // If the order was accepted (scheduling orders) verify if necessary to display the confirm modal
    } else if (orderStatusData.status === 'accepted') {
      // Define the average time for delivery based on the delivery type
      let deliveryTypeTimeAvg = 0;
      if (orderStatusData.order.delivery_type === 'delivery') {
        deliveryTypeTimeAvg = advertiserUser.advertiserInfo.delivery_time_avg;
      } else {
        deliveryTypeTimeAvg =
          advertiserUser.advertiserInfo.take_away_time_avg;
      }
      // If the time scheduled for the order is more then the average time, show the confirmation popup
      if (
        // eslint-disable-next-line no-underscore-dangle
        (moment(orderStatusData.order.scheduling_start_at, 'DD/MM/YYYY HH:NN')
          ._d -
          new Date()) /
          60000 >
        deliveryTypeTimeAvg
      ) {
        setConfirmPreparingSchedulingOrderModal(true);
      } else {
        dispatch(
          updateOrderStatusRequest(
            orderStatusData,
            'preparing',
          )
        );
      }
      // Other orders status
    } else {
      let newStatus = null;

      if (orderStatusData.status === 'new') {
        newStatus = 'accepted';
      } else if (
        orderStatusData.status === 'preparing' &&
        orderStatusData.order.delivery_type === 'delivery'
      ) {
        newStatus = 'delivering';
      } else if (
        orderStatusData.status === 'preparing' &&
        orderStatusData.order.delivery_type === 'take_away'
      ) {
        newStatus = 'ready_take_away';
      } else if (
        orderStatusData.status === 'delivering' ||
        orderStatusData.status === 'ready_take_away'
      ) {
        newStatus = 'finished';
      }
      if (newStatus) {  
        dispatch(
          updateOrderStatusRequest(
            orderStatusData,
            newStatus
          )
        );
      }
    }
  }

  function handlePrint() {
    if (printMethod === 'automatic') {
      printInvoiceQZTray({
        printerName: printerName,
        orderStatusData: ordersStatusesData[orderStatusDataIndex],
        advertiserData: advertiserUser,
        maxLineCharsAutomatic: maxLineCharsAutomatic,
      });
    } else {
      printOrderInvoice({
        orderStatusData: ordersStatusesData[orderStatusDataIndex],
        advertiserData: advertiserUser,
      });
    }
  }

  // Handle the response for confirm scheduling preparing order modal
  function handleConfirmPreparingSchedulingModal() {
    setConfirmPreparingSchedulingOrderModal(false);
  }

  // Handle the with the data of the payment that will be updated
  function handleUpdateOrderPaymentMethodRequest(
    orderId,
    olderPaymentMethodId,
    newPaymentMethodId
  ) {
    setIsUpdating(false);
    dispatch(
      updateOrderPaymentMethodRequest(
        orderId,
        olderPaymentMethodId,
        newPaymentMethodId
      )
    );
  }

  function handleSelectPaymentConfirmingRequest(clickedOption) {
    if (
      ordersStatusesData[orderStatusDataIndex]?.status === 'canceled' &&
      ordersStatusesData[orderStatusDataIndex]?.new_at === null &&
      ordersStatusesData[orderStatusDataIndex]?.pending_payment_at !== null &&
      ordersStatusesData[orderStatusDataIndex]?.order?.paymentMethod?.slug ===
        'pix_chave'
    ) {
      toast.warn(
        'O pedido foi cancelado automaticamente e portanto não pode ser feita a conferência do pagamento',
        {
          autoClose: 6000,
        }
      );
    } else if (didPaymentConfirmed !== clickedOption) {
      dispatch(
        updatePixKeyPaymentRequest(
          ordersStatusesData[orderStatusDataIndex]?.order
            ?.advertiserOrderPayment
        )
      );
    } else {
      toast.warn(
        clickedOption
          ? 'O pagamento desse pedido já foi conferido.'
          : 'O pagamento desse pedido ainda não foi conferido.',
        {
          autoClose: 6000,
        }
      );
    }
  }

  function handleOpenCancelOrderModal() {
    setConfirmCancelOrderModal(true);
  }

  function handleAlwaysSendWhatsappMsg() {
    if (!whatsappIsActive) {
      toast.warn(
        'Para ativar o envio de mensagem automática, deve-se conectar o seu Whatsapp a nossa plataforma. Clique aqui para ir a tela de ativação.',
        {
          autoClose: 9000,
          onClick: () => history.push(whatsappbot),
        }
      );

      setSelectedAlwaysSendWhatsappMsg(false);
      return;
    }

    setSelectedAlwaysSendWhatsappMsg(!selectedAlwaysSendWhatsappMsg);
  }

  useEffect(() => {
    if (updateOrderStatusSuccess) {
      dispatch(updateOrderStatusClearSuccess());
      closeModal();
    }
  }, [updateOrderStatusSuccess]);

  useEffect(() => {
    if (cancelOrderSuccess) {
      dispatch(cancelOrderClearSuccess());    
      setConfirmCancelOrderModal(false);
      closeModal();
    }
  }, [cancelOrderSuccess]);

  useEffect(() => {
    setSelectedAlwaysPrintOrder(alwaysPrintOrder);
    setSelectedAlwaysSendWhatsappMsg(alwaysSendWhatsappMsg);
  }, [alwaysPrintOrder, alwaysSendWhatsappMsg]);

  if (orderDetailLoading) {
    return (
      <Modal
        isVisible={visible}
        width={px2vw(800)}
        height={px2vw(550)}
        padding={px2vw(25)}
        hasNavigation={hasNavigation}
        disabledLeftButton={true}
        clickLeftButton={() => {}}
        disabledRightButton={true}
        clickRightButton={() => {}}
      >
        <OrderDetailModalBox>
          <CloseButton
            onClick={() => closeModal()}
            disabled={updateOrderStatusLoading || cancelOrderLoading}
            absolute
          >
            <CloseImage src={require('../../assets/images/close/close.png')} />
          </CloseButton>
          <LoadingBox
            style={{
              position: 'absolute',
              top: px2vw(240),
              transform: 'translate(0, 50%)',
            }}
          >
            <Spinner
              style={{
                width: px2vw(20),
                height: px2vw(20),
                color: preto,
              }}
            />
          </LoadingBox>
        </OrderDetailModalBox>
      </Modal>
    )
  }

  if (!ordersStatusesData[orderStatusDataIndex]?.order?.orderProduct) {
    return (
      <Modal
        isVisible={visible}
        width={px2vw(800)}
        height={px2vw(550)}
        padding={px2vw(25)}
        hasNavigation={hasNavigation}
        disabledLeftButton={true}
        clickLeftButton={() => {}}
        disabledRightButton={true}
        clickRightButton={() => {}}
      >
        <OrderDetailModalBox>
          <CloseButton
            onClick={() => closeModal()}
            disabled={updateOrderStatusLoading || cancelOrderLoading}
            absolute
          >
            <CloseImage src={require('../../assets/images/close/close.png')} />
          </CloseButton>
          <LoadingBox
            style={{
              position: 'absolute',
              top: px2vw(200),
              transform: 'translate(0, 50%)',
            }}
          >
            <ErrorText>
              Ocorreu um erro ao carregar os detalhes do pedido #{ordersStatusesData[orderStatusDataIndex]?.order_id}.
            </ErrorText>
            <ErrorText>
              Tente novamente e caso o erro persista, fale com o suporte.
            </ErrorText>
          </LoadingBox>
        </OrderDetailModalBox>
      </Modal>
    )
  }  

  return (
    <>
      <Modal
        isVisible={visible}
        width={px2vw(800)}
        height={px2vw(550)}
        padding={px2vw(25)}
        hasNavigation={hasNavigation}
        disabledLeftButton={orderStatusDataIndex - 1 < 0}
        clickLeftButton={
          orderStatusDataIndex - 1 < 0
            ? () => {}
            : () => {
                if (!ordersStatusesData[orderStatusDataIndex - 1].order.orderProduct) {
                  setOrderDetailLoading(true);
                  dispatch(getOrderDetailRequest(ordersStatusesData[orderStatusDataIndex - 1].order_id));
                }
                setOrderStatusDataIndex(orderStatusDataIndex - 1);
                dispatch(clearNewOrderPaymentMethodData());
              }
        }
        disabledRightButton={
          orderStatusDataIndex + 1 >= ordersStatusesData.length
        }
        clickRightButton={
          orderStatusDataIndex + 1 >= ordersStatusesData.length
            ? () => {}
            : () => {
                if (!ordersStatusesData[orderStatusDataIndex + 1].order.orderProduct) {
                  setOrderDetailLoading(true);
                  dispatch(getOrderDetailRequest(ordersStatusesData[orderStatusDataIndex + 1].order_id));
                }
                setOrderStatusDataIndex(orderStatusDataIndex + 1);
                dispatch(clearNewOrderPaymentMethodData());
              }
        }
      >
        <OrderDetailModalBox>
          <CloseButton
            onClick={() => closeModal()}
            disabled={updateOrderStatusLoading || cancelOrderLoading}
            absolute
          >
            <CloseImage src={require('../../assets/images/close/close.png')} />
          </CloseButton>
          {ordersStatusesData[orderStatusDataIndex] ? (
            <>
              <Row
                justifyStart
                margleft={px2vw(20)}
                margbottom={px2vw(10)}
                margtop={px2vw(-10)}
              >
                {/* Scheduling Info */}
                {ordersStatusesData[orderStatusDataIndex]?.order?.order_type ===
                  'scheduled' && (
                  <SchedulingInfoBox
                    schedulingOrderDateTime={
                      ordersStatusesData[orderStatusDataIndex]?.order
                    }
                    width={px2vw(340)}                            
                    height={px2vw(28)}
                    padtop="0px"
                  />
                )}
              </Row>
              <Row justifyStart margleft={px2vw(20)}>
                <ModalTitleText>
                  Pedido{' '}
                  {formatOrderNumber(
                    ordersStatusesData[orderStatusDataIndex]?.order_id
                  )}
                </ModalTitleText>
              </Row>
              <Row alignStart justifyStart margleft={px2vw(20)}>
                <Row justifyStart width={px2vw(250)}>
                  <ClockImage
                    src={require('../../assets/images/clock/clock@2x.png')}
                    size={px2vw(11)}
                    margtop={px2vw(11)}
                  />
                  <OrdersRowText margtop={px2vw(9)} fontSize={px2vw(11)}>
                    {formatCreateDateTimeOrder(
                      ordersStatusesData[orderStatusDataIndex]?.new_at ||
                        ordersStatusesData[orderStatusDataIndex]
                          ?.pending_payment_at
                    )}
                  </OrdersRowText>
                </Row>
                <Row
                  width={px2vw(70)}
                  margtop={px2vw(-25)}
                  margleft={px2vw(90)}
                >
                  <OrderTypeBox
                    margtop={px2vw(2)}
                    margbottom="0px"
                    orderType={
                      ordersStatusesData[orderStatusDataIndex]?.order
                        ?.delivery_type
                    }
                  >
                    {ordersStatusesData[orderStatusDataIndex]?.order
                      ?.delivery_type === 'delivery'
                      ? 'Delivery'
                      : 'Retirada'}
                  </OrderTypeBox>
                </Row>
                <Row
                  width={px2vw(190)}
                  margtop={px2vw(-35)}
                  margleft={px2vw(70)}
                >
                  <OrderStatusBox
                    orderStatusData={ordersStatusesData[orderStatusDataIndex]}
                  />
                </Row>
              </Row>
              {/* Action buttons */}
              <Row
                margbottom={px2vw(20)}
                padleft={px2vw(30)}
                padright={px2vw(30)}
                justifyStart
              >
                <ModalSecundaryButton
                  margtop={px2vw(20)}
                  marginRight={px2vw(30)}
                  width={px2vw(210)}
                  onClick={handlePrint}
                  disabled={updateOrderStatusLoading}
                >
                  {updateOrderStatusLoading ? (
                    <LoadingBox>
                      <Spinner
                        style={{
                          width: '1.8rem',
                          height: '1.8rem',
                          color: preto,
                        }}
                      />
                    </LoadingBox>
                  ) : (
                    <ModalSecundaryButtonText>
                      <PrintIcon />
                      Imprimir pedido
                    </ModalSecundaryButtonText>
                  )}
                </ModalSecundaryButton>
                {ordersStatusesData[orderStatusDataIndex]?.status !==
                  'finished' &&
                  ordersStatusesData[orderStatusDataIndex]?.status !==
                    'canceled' && (
                    <>
                      <ModalPrimaryButton
                        margtop={px2vw(20)}
                        marginRight={px2vw(30)}
                        width={px2vw(210)}
                        onClick={() =>
                          performOrderAction(
                            ordersStatusesData[orderStatusDataIndex],
                            false
                          )
                        }
                        disabled={updateOrderStatusLoading}
                      >
                        {updateOrderStatusLoading ? (
                          <LoadingBox>
                            <Spinner
                              style={{
                                width: '1.8rem',
                                height: '1.8rem',
                                color: preto,
                              }}
                            />
                          </LoadingBox>
                        ) : (
                          <ModalPrimaryButtonText>
                            <NextStatusIcon />
                            {ordersStatusesData[orderStatusDataIndex]
                              ?.status === 'new' && 'Aceitar pedido'}
                            {ordersStatusesData[orderStatusDataIndex]
                              ?.status === 'accepted' && 'Iniciar preparo'}
                            {ordersStatusesData[orderStatusDataIndex]
                              ?.status === 'preparing' &&
                              ordersStatusesData[orderStatusDataIndex]?.order
                                ?.delivery_type === 'delivery' &&
                              'Saiu para entrega'}
                            {ordersStatusesData[orderStatusDataIndex]
                              ?.status === 'preparing' &&
                              ordersStatusesData[orderStatusDataIndex]?.order
                                ?.delivery_type === 'take_away' &&
                              'Pronto para retirada'}
                            {(ordersStatusesData[orderStatusDataIndex]
                              ?.status === 'delivering' ||
                              ordersStatusesData[orderStatusDataIndex]
                                ?.status === 'ready_take_away') &&
                              'Concluir pedido'}
                          </ModalPrimaryButtonText>
                        )}
                      </ModalPrimaryButton>
                      <ModalSecundaryButton
                        margtop={px2vw(20)}
                        width={px2vw(210)}
                        onClick={handleOpenCancelOrderModal}
                        disabled={updateOrderStatusLoading}
                      >
                        <ModalSecundaryButtonText>
                          <CloseIcon />
                          Cancelar pedido
                        </ModalSecundaryButtonText>
                      </ModalSecundaryButton>
                    </>
                  )
                }
                {ordersStatusesData[orderStatusDataIndex]?.status ===
                  'canceled' &&
                  ordersStatusesData[orderStatusDataIndex]?.new_at === null &&
                  ordersStatusesData[orderStatusDataIndex]
                    ?.pending_payment_at !== null &&
                  ordersStatusesData[orderStatusDataIndex]?.order?.paymentMethod
                    ?.slug === 'pix_chave' && (
                    <Row width="auto" margleft={px2vw(80)} margtop={px2vw(25)}>
                      <OrdersRowText fontSize={px2vw(9.5)} width={px2vw(320)}>
                        Pedido cancelado automaticamente após 1 hora da sua
                        criação, porque o cliente não confirmou o pagamento no
                        cardápio digital
                      </OrdersRowText>
                    </Row>
                  )
                }
                {ordersStatusesData[orderStatusDataIndex]?.status ===
                  'canceled' &&
                  ordersStatusesData[orderStatusDataIndex]
                    ?.canceled_at !== null &&
                  ordersStatusesData[orderStatusDataIndex]?.order?.paymentMethod
                    ?.slug === 'pix_copia_cola' && ordersStatusesData[orderStatusDataIndex]?.is_manual_refund_needed && (
                    <Row width="auto" margleft={px2vw(80)} margtop={px2vw(25)}>
                      <OrdersRowText fontSize={px2vw(9.5)} width={px2vw(320)}>
                      Não foi possível fazer a devolução automática do valor pago pelo cliente. 
                      Você deverá fazer manualmente.
                      </OrdersRowText>
                    </Row>
                  )
                }
                {ordersStatusesData[orderStatusDataIndex]?.status ===
                  'canceled' &&
                  ordersStatusesData[orderStatusDataIndex]
                    ?.canceled_at !== null &&
                  ordersStatusesData[orderStatusDataIndex]?.order?.paymentMethod
                    ?.slug === 'pix_copia_cola' && ordersStatusesData[orderStatusDataIndex]?.pending_refund_at !== null &&
                  ordersStatusesData[orderStatusDataIndex]?.refunded_at === null && (
                    <Row width="auto" margleft={px2vw(80)} margtop={px2vw(25)}>
                      <OrdersRowText fontSize={px2vw(9.5)} width={px2vw(320)}>
                        A devolução automática do valor pago pelo cliente está em processamento.
                      </OrdersRowText>
                    </Row>
                  )
                }
                {ordersStatusesData[orderStatusDataIndex]?.status ===
                  'canceled' &&
                  ordersStatusesData[orderStatusDataIndex]
                    ?.canceled_at !== null &&
                  ordersStatusesData[orderStatusDataIndex]?.order?.paymentMethod
                    ?.slug === 'pix_copia_cola' && ordersStatusesData[orderStatusDataIndex]?.pending_refund_at !== null &&
                  ordersStatusesData[orderStatusDataIndex]?.refunded_at !== null && (
                    <Row width="auto" margleft={px2vw(80)} margtop={px2vw(25)}>
                      <OrdersRowText fontSize={px2vw(9.5)} width={px2vw(320)}>
                        Valor devolvido automaticamente para o cliente com sucesso!
                      </OrdersRowText>
                    </Row>
                  )
                }
              </Row>
              {/* Checkbox actions */}
              {ordersStatusesData[orderStatusDataIndex]?.status !==
                'canceled' &&
                ordersStatusesData[orderStatusDataIndex]?.status !==
                  'finished' && (
                  <Row justifyStart margleft={px2vw(200)}>
                    {(ordersStatusesData[orderStatusDataIndex]?.status ===
                      'new' ||
                      ordersStatusesData[orderStatusDataIndex]?.status ===
                        'accepted' ||
                      ordersStatusesData[orderStatusDataIndex]?.status ===
                        'preparing') && (
                      <Row
                        cursor="pointer"
                        width="auto"
                        onClick={() =>
                          handleAlwaysSendWhatsappMsg()
                        }
                        disabled={updateOrderStatusLoading}
                      >
                        <Row relative width="auto">
                          <ChoiceButton />
                          <ChoiceButtonSelected
                            isChecked={selectedAlwaysSendWhatsappMsg}
                          />
                        </Row>
                        <Col margtop={px2vw(-2)} width="auto">
                          <OrdersRowText
                            margleft={px2vw(10)}
                            fontSize={px2vw(11)}
                          >
                            Enviar mensagem no whatsapp ao alterar o status
                          </OrdersRowText>
                        </Col>
                      </Row>
                    )}
                    {((ordersStatusesData[orderStatusDataIndex]?.status ===
                      'new' &&
                      ordersStatusesData[orderStatusDataIndex]?.order
                        ?.order_type === 'immediate') ||
                      ordersStatusesData[orderStatusDataIndex]?.status ===
                        'accepted') && (
                      <Row
                        cursor="pointer"
                        width="auto"
                        margleft={px2vw(100)}
                        onClick={() =>
                          setSelectedAlwaysPrintOrder(!selectedAlwaysPrintOrder)
                        }
                        disabled={updateOrderStatusLoading}
                      >
                        <Row relative width="auto">
                          <ChoiceButton />
                          <ChoiceButtonSelected
                            isChecked={selectedAlwaysPrintOrder}
                          />
                        </Row>
                        <Col margtop={px2vw(-2)} width="auto">
                          <OrdersRowText
                            margleft={px2vw(10)}
                            fontSize={px2vw(11)}
                          >
                            Imprimir ao aceitar o pedido
                          </OrdersRowText>
                        </Col>
                      </Row>
                    )}
                  </Row>
                )}
              <Row className="mt-3">
                {/* Order Data table */}
                <Col width="74%">
                  <Col
                    overflowY
                    maxheight={px2vw(190)}
                    minheight={px2vw(190)}
                    padleft={px2vw(10)}
                    padright={px2vw(12)}
                    justifyStart
                  >
                    {ordersStatusesData[
                      orderStatusDataIndex
                    ]?.order?.orderProduct?.map((orderProduct) => (
                      <Row
                        key={`orderProduct-${orderProduct.id}`}
                        margleft={px2vw(12)}
                        margright={px2vw(12)}
                        justifyStart
                      >
                        <Col width="80%" alignStart justifyStart>
                          <ItemName>{`${orderProduct.quantity}x ${orderProduct.product.name}`}</ItemName>
                          {orderProduct.orderProductComplements.map(
                            (orderProductComplement, catIdx) => (
                              <React.Fragment
                                key={`orderProductComplementCategory-${orderProduct.id}-${catIdx}`}
                              >
                                <ItemCompCategory>{`• ${orderProductComplement.category}:`}</ItemCompCategory>
                                {orderProductComplement?.complements?.map(
                                  (complement, compIdx) => (
                                    <ItemComplement
                                      key={`complement-${orderProduct.id}-${catIdx}-${compIdx}`}
                                    >{`${complement?.quantity}x ${complement?.name}`}</ItemComplement>
                                  )
                                )}
                              </React.Fragment>
                            )
                          )}
                          {orderProduct.obs && (
                            <ItemObs>{`• Obs: ${orderProduct.obs}`}</ItemObs>
                          )}
                        </Col>
                        <Col
                          justifyStart
                          alignEnd
                          margright={px2vw(10)}
                          width="20%"
                        >
                          <ItemAmount>
                            {parseFloat(orderProduct.product.amount).toFixed(
                              2
                            ) !== parseFloat(0) &&
                              `R$ ${(
                                Math.round(
                                  orderProduct.quantity *
                                    orderProduct.product.amount *
                                    100
                                ) / 100
                              ).toFixed(2)}`}
                          </ItemAmount>
                          {orderProduct?.orderProductComplements?.map(
                            (orderProductComplement, catAmountIdx) => (
                              <React.Fragment
                                key={`orderProductComplementAmountCategory-${orderProduct.id}-${catAmountIdx}`}
                              >
                                <div
                                  style={{
                                    height: px2vw(17),
                                  }}
                                />
                                {orderProductComplement?.complements?.map(
                                  (complement, compAmountIdx) => (
                                    <ItemAmountComplement
                                      key={`complementAmount-${orderProduct.id}-${catAmountIdx}-${compAmountIdx}`}
                                    >{`R$ ${(
                                      Math.round(
                                        orderProduct.quantity *
                                          complement?.quantity *
                                          complement?.amount *
                                          100
                                      ) / 100
                                    ).toFixed(2)}`}</ItemAmountComplement>
                                  )
                                )}
                              </React.Fragment>
                            )
                          )}
                          {orderProduct.obs && (
                            <div
                              style={{
                                height: '25px',
                              }}
                            />
                          )}
                        </Col>
                      </Row>
                    ))}
                  </Col>
                  <Col
                    width="100%"
                    margtop={px2vw(20)}
                    minheight={px2vw(108)}
                    padleft={px2vw(10)}
                    padright={px2vw(12)}
                    justifyStart
                  >
                    <Separator margtop={px2vw(-5)} margbottom={px2vw(20)} />
                    {ordersStatusesData[orderStatusDataIndex]?.order
                      ?.delivery_type === 'delivery' && (
                      <Row justifyStart margbottom={px2vw(5)}>
                        <Col alignStart>
                          <ItemNameBold>Taxa de delivery</ItemNameBold>
                        </Col>
                        <Col alignEnd>
                          <ItemAmount>
                            {ordersStatusesData[orderStatusDataIndex]?.order
                              ?.delivery_fee === null ||
                            ordersStatusesData[orderStatusDataIndex]?.order
                              ?.delivery_fee === 0
                              ? `Grátis`
                              : `R$ ${(
                                  Math.round(
                                    ordersStatusesData[orderStatusDataIndex]
                                      ?.order?.delivery_fee * 100
                                  ) / 100
                                ).toFixed(2)}`}
                          </ItemAmount>
                        </Col>
                      </Row>
                    )}
                    {ordersStatusesData[orderStatusDataIndex]?.order
                      ?.discount !== null &&
                      ordersStatusesData[orderStatusDataIndex]?.order
                        ?.discount !== 0 && (
                        <Row justifyStart margbottom={px2vw(5)}>
                          <Col alignStart>
                            <ItemNameBold>Desconto</ItemNameBold>
                          </Col>
                          <Col alignEnd>
                            <ItemAmount>
                              {`R$ ${(
                                Math.round(
                                  ordersStatusesData[orderStatusDataIndex]
                                    ?.order?.discount * 100
                                ) / 100
                              ).toFixed(2)}`}
                            </ItemAmount>
                          </Col>
                        </Row>
                      )}
                    <Row justifyStart margbottom={px2vw(5)}>
                      <Col alignStart>
                        <ItemNameBold>Preço total</ItemNameBold>
                      </Col>
                      <Col alignEnd>
                        <ItemAmount>
                          {`R$ ${(
                            Math.round(
                              ordersStatusesData[orderStatusDataIndex]?.order
                                ?.total_amount * 100
                            ) / 100
                          ).toFixed(2)}`}
                        </ItemAmount>
                      </Col>
                    </Row>
                  </Col>
                </Col>
                <OverflowYBox
                  justifyStart
                  width="26%"
                  margleft={px2vw(10)}
                  padright={px2vw(6)}
                  height={px2vw(350)}
                  overflowY
                >
                  {/* Client data */}
                  <Col height="auto">
                    <TextBold mb={px2vw(5)}>Dados do cliente</TextBold>
                    <Row justifyStart>
                      <Col alignStart width={px2vw(700)}>
                        <Row justifyStart>
                          <TextRegular clientName title={clientName}>
                            <b>Nome: </b> {clientName}
                          </TextRegular>
                        </Row>
                        <Row justifyStart>
                          <TextBold>Tel:&nbsp;</TextBold>
                          <TextRegular>
                            {ordersStatusesData[orderStatusDataIndex]?.order
                              ?.clientInfo?.phone === null
                              ? 'Não Informado'
                              : `${ddiPhoneMask(
                                  ordersStatusesData[orderStatusDataIndex]
                                    ?.order?.clientInfo?.phone
                                )}`}
                          </TextRegular>
                        </Row>
                        {ordersStatusesData[orderStatusDataIndex]?.order
                          ?.clientInfo?.phone !== null && (
                          <Row justifyStart>
                            <CustonLink2
                              custonStyle
                              className="text-dark mb-2"
                              href={`https://wa.me/${ordersStatusesData[orderStatusDataIndex]?.order?.clientInfo?.phone}`}
                              target="_blank"
                            >
                              <TextMedium margright={px2vw(7)}>
                                Fale com o cliente
                              </TextMedium>
                              <img
                                alt="talkToClient"
                                width={px2vw(200)}
                                height={px2vw(200)}
                                src={require('../../assets/images/whatsapp/whatsapp@2x.png')}
                              />
                            </CustonLink2>
                          </Row>
                        )}
                      </Col>
                    </Row>
                  </Col>
                  {/* Client Address */}
                  {ordersStatusesData[orderStatusDataIndex]?.order
                    ?.delivery_type === 'delivery' && (
                    <Col height="auto">
                      <TextBold>Endereço de entrega</TextBold>
                      <TextRegular clientAddress title={clientAddress}>
                        {clientAddress}
                      </TextRegular>
                      {ordersStatusesData[orderStatusDataIndex]?.order
                        ?.address !== null &&
                        !useNeighborhoodArea && (
                          <CustonLink2
                            custonStyle
                            className="text-dark mb-2"
                            href={`https://www.google.com/maps/dir/${advertiserUser.address[0].latitude},${advertiserUser.address[0].longitude}/${ordersStatusesData[orderStatusDataIndex]?.order?.address?.latitude},${ordersStatusesData[orderStatusDataIndex]?.order?.address?.longitude}`}
                            target="_blank"
                          >
                            <TextMedium margright={px2vw(5)}>
                              Ver no mapa
                            </TextMedium>
                            <Icon fontSize="small">person_pin_circle</Icon>
                          </CustonLink2>
                        )}
                    </Col>
                  )}
                  {/* Payment Method */}
                  <Col
                    height="auto"
                    onMouseLeave={() =>
                      isUpdating ? setIsUpdating(!isUpdating) : null
                    }
                  >
                    <TextBold>Forma de pagamento</TextBold>
                    <Row
                      margtop={px2vw(5)}
                      justifyStart
                      alignCenter
                      border={`2px solid ${cinzaKuppi4}`}
                      borderRadius={isUpdating ? '7px 7px 0 0' : '7px'}
                      pad={px2vw(4)}
                      height={px2vw(33)}
                      padleft={px2vw(8)}
                    >
                      <img
                        alt="paymentMethodSelected"
                        height={px2vw(300)}
                        width={px2vw(600)}
                        src={
                          newOrderPaymentMethod?.order?.paymentMethod
                            ?.image_url ||
                          orders[orderStatusDataIndex]?.order?.paymentMethod
                            ?.image_url
                        }
                      />
                      <TextRegular
                        ml={px2vw(3)}
                        size={px2vw(11)}
                        className="mr-auto"
                      >
                        {newOrderPaymentMethod?.order?.paymentMethod?.name ||
                          orders[orderStatusDataIndex]?.order?.paymentMethod
                            ?.name}
                      </TextRegular>
                      {updatePaymentMethodLoading ? (
                        <Spinner
                          className="m-auto"
                          style={{
                            width: '1.4rem',
                            height: '1.4rem',
                            color: preto,
                          }}
                        />
                      ) : isUpdating ? (
                        <ExpandMoreIcon
                          onClick={() => setIsUpdating(!isUpdating)}
                          style={{ transform: 'rotate(180deg)' }}
                          className="ml-auto"
                        />
                      ) : orders[orderStatusDataIndex]?.order?.paymentMethod
                          ?.type === 'offline' ? (
                        <SpanTxt
                          d={
                            newOrderPaymentMethod?.order?.paymentMethod
                              ?.type === 'online'
                              ? 'none'
                              : 'block'
                          }
                          onClick={() => setIsUpdating(!isUpdating)}
                        >
                          Alterar
                        </SpanTxt>
                      ) : null}
                    </Row>
                    {isUpdating && (
                      <PmList top={px2vw(60)}>
                        {offlineAvailablePaymentMethodData?.length > 0 &&
                          offlineAvailablePaymentMethodData.map((pm) => (
                            <PmItem
                              key={pm.id}
                              justifyStart
                              alignCenter
                              border={`2px solid ${cinzaKuppi4}`}
                              pad={px2vw(4)}
                              minHeight={px2vw(33)}
                              padleft={px2vw(8)}
                              onClick={() =>
                                handleUpdateOrderPaymentMethodRequest(
                                  newOrderPaymentMethod?.order?.id ||
                                    ordersStatusesData[orderStatusDataIndex]
                                      ?.order?.id,
                                  newOrderPaymentMethod?.order?.paymentMethod
                                    ?.id ||
                                    ordersStatusesData[orderStatusDataIndex]
                                      ?.order?.paymentMethod?.id,
                                  pm.paymentMethod.id
                                )
                              }
                            >
                              <img
                                alt="paymentMethod"
                                height={px2vw(300)}
                                width={px2vw(600)}
                                src={pm.paymentMethod.image_url}
                              />
                              <TextRegular
                                ml={px2vw(3)}
                                size={px2vw(11)}
                                className="mr-auto"
                              >
                                {pm.paymentMethod.name}
                              </TextRegular>
                            </PmItem>
                          ))}
                      </PmList>
                    )}
                  </Col>
                  {/* Order Change */}
                  {ordersStatusesData[orderStatusDataIndex]?.order
                    ?.paymentMethod?.slug !== 'pix_chave' && (
                    <Col height="auto" margbottom={px2vw(8)} margtop={px2vw(4)}>
                      <TextBold>Precisa de troco</TextBold>
                      <TextRegular>
                        {ordersStatusesData[orderStatusDataIndex]?.order
                          ?.change === 0
                          ? `Não`
                          : `Troco para R$ ${(
                              Math.round(
                                ordersStatusesData[orderStatusDataIndex]?.order
                                  ?.change * 100
                              ) / 100
                            ).toFixed(2)}`}
                      </TextRegular>
                      <TextRegular>
                        {ordersStatusesData[orderStatusDataIndex]?.order
                          ?.change !== 0 &&
                          `Valor do troco: R$ ${(
                            Math.round(
                              ordersStatusesData[orderStatusDataIndex]?.order
                                ?.change * 100
                            ) /
                              100 -
                            Math.round(
                              ordersStatusesData[orderStatusDataIndex]?.order
                                ?.total_amount * 100
                            ) /
                              100
                          ).toFixed(2)}`}
                      </TextRegular>
                    </Col>
                  )}
                  {/* Pix Key Confirm Payment */}
                  {!newOrderPaymentMethod &&
                    ordersStatusesData[orderStatusDataIndex]?.order
                      ?.paymentMethod?.slug === 'pix_chave' && (
                      <Col height="auto">
                        <TextBold mb={px2vw(4)} mt={px2vw(3)}>
                          Pagamento conferido?
                        </TextBold>
                        <FormGroup className="m-0">
                          <Row height="auto">
                            <label
                              className="d-flex align-items-center mb-2"
                              style={{
                                cursor: pixKeyRequestLoading
                                  ? 'not-allowed'
                                  : 'pointer',
                              }}
                              onClick={() =>
                                pixKeyRequestLoading
                                  ? null
                                  : handleSelectPaymentConfirmingRequest(true)
                              }
                            >
                              <img
                                alt="confirmedPaymentYes"
                                className="size-sm mr-2"
                                src={require(`../../assets/images/checkbox/${
                                  didPaymentConfirmed ? 'checked' : 'unchecked'
                                }.png`)}
                              />
                              Sim
                            </label>
                            <label
                              className="d-flex align-items-center ml-5"
                              style={{
                                cursor: pixKeyRequestLoading
                                  ? 'not-allowed'
                                  : 'pointer',
                              }}
                              onClick={() =>
                                pixKeyRequestLoading
                                  ? null
                                  : handleSelectPaymentConfirmingRequest(false)
                              }
                            >
                              <img
                                alt="confirmedPaymentNo"
                                className="size-sm mr-2"
                                src={require(`../../assets/images/checkbox/${
                                  !didPaymentConfirmed
                                    ? 'checkedfalse'
                                    : 'unchecked'
                                }.png`)}
                              />
                              Não
                            </label>
                          </Row>
                        </FormGroup>
                        <Row justifyStart cursor="pointer" width="auto">
                          <CustonLink2
                            custonStyle
                            className="text-dark mb-2"
                            onClick={() =>
                              sendWhatsappMessage(
                                true,
                                ordersStatusesData[orderStatusDataIndex],
                                whatsappIsActive,
                                pixKey,
                                true
                              )
                            }
                          >
                            <TextMedium margright={px2vw(7)}>
                              Reenviar chave PIX
                            </TextMedium>
                            <img
                              alt="whatsapp"
                              width={px2vw(200)}
                              height={px2vw(200)}
                              src={require('../../assets/images/whatsapp/whatsapp@2x.png')}
                            />
                          </CustonLink2>
                        </Row>
                      </Col>
                    )}
                </OverflowYBox>
              </Row>
            </>
          ) : (
            <LoadingBox
              style={{
                position: 'absolute',
                top: px2vw(240),
                transform: 'translate(0, 50%)',
              }}
            >
              <Spinner
                style={{
                  width: px2vw(20),
                  height: px2vw(20),
                  color: preto,
                }}
              />
            </LoadingBox>
          )}
        </OrderDetailModalBox>
      </Modal>
      {/* Confirm cancel order */}
      {confirmCancelOrderModal && (
        <Modal
          isVisible={confirmCancelOrderModal}
          width={px2vw(340)}
          height={px2vw(190)}
        >
          <ConfirmCancelOrder>
            <ConfirmCancelOrderText>
              Tem certeza que deseja cancelar o pedido?
            </ConfirmCancelOrderText>
            <ModalActions>
              <ModalSecundaryButton
                marginRight={px2vw(20)}
                margtop={px2vw(25)}
                width={px2vw(120)}
                type="button"
                onClick={() => {
                  setConfirmCancelOrderModal(false);
                }}
                disabled={cancelOrderLoading}
              >
                <ModalSecundaryButtonText>Não</ModalSecundaryButtonText>
              </ModalSecundaryButton>
              <ModalPrimaryButton
                margleft={px2vw(20)}
                margtop={px2vw(25)}
                width={px2vw(120)}
                type="button"
                onClick={() => {
                  performOrderAction(
                    ordersStatusesData[orderStatusDataIndex],
                    true
                  );
                }}
                disabled={cancelOrderLoading}
              >
                {cancelOrderLoading ? (
                  <LoadingBox>
                    <Spinner
                      style={{
                        width: '1.8rem',
                        height: '1.8rem',
                        color: preto,
                      }}
                    />
                  </LoadingBox>
                ) : (
                  <ModalPrimaryButtonText>Sim</ModalPrimaryButtonText>
                )}
              </ModalPrimaryButton>
            </ModalActions>
          </ConfirmCancelOrder>
        </Modal>
      )}
      {/* Confirm preparing scheduling order */}
      {confirmPreparingSchedulingOrderModal && (
        <ConfirmPreparingSchedulingOrder
          isVisible={confirmPreparingSchedulingOrderModal}
          orders={ordersStatusesData}
          orderIndex={orderStatusDataIndex}
          onModalResponse={() => handleConfirmPreparingSchedulingModal()}
          onScreen={onScreen}
        />
      )}
    </>
  );
}
