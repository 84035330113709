import React, { useState, useEffect, useRef } from 'react';
import { IoSendSharp } from 'react-icons/io5';
import { BsEmojiSmile } from 'react-icons/bs';
import { FiPaperclip } from 'react-icons/fi';
import EmojiPicker from 'emoji-picker-react';

import px2vw from '../../../../utils/responsiveness/px2vw';

import { TextBar, TextField, TextFieldContainer, ToolBar } from './style';

import { cinzaKuppi7, verdeKuppi25 } from '../../../../styles/colors';
import { useDispatch, useSelector } from 'react-redux';
import { sendWhatsappBotChatMessageRequest } from '../../../../store/modules/whatsappBot/actions';
import {
  clearMessageCount,
  removeWaitAttendantList,
  setSelectedMedia,
} from '../../../../store/modules/whatsappChat/actions';
import { toast } from 'react-toastify';
import { Row } from '../../../../styles/grid';

export default function ChatTextBar() {
  const uploadRef = useRef(null);
  const dispatch = useDispatch();
  const [message, setMessage] = useState('');
  const [isEmojiPickerVisible, setIsEmojiPickerVisible] = useState(false);
  const isManualOrderModalVisible = useSelector(
    (state) => state.manualOrder.isManualOrderModalVisible
  );
  const { selectedConversation, selectedMedia } = useSelector(
    (state) => state.whatsappChat
  );

  useEffect(() => {
    function onKeyup(e) {
      if (e.key === 'Escape') {
        setIsEmojiPickerVisible(false);
      }
    }
    window.addEventListener('keyup', onKeyup);
    return () => window.removeEventListener('keyup', onKeyup);
  }, [isEmojiPickerVisible]);

  const handleUploadFile = (e) => {
    const f = e.target.files[0];

    if (f) {
      const fname = f.name;
      const fsize = (f.size / 1000000).toFixed(1);
      const ftype = f.type.split('/')[0];
      const fsubtype = f.type.split('/')[1];

      const coverFileReader = new FileReader();
      coverFileReader.onload = function () {
        const media = {
          base64: coverFileReader.result,
          name: fname,
          size: fsize,
          type: ftype,
          subtype: fsubtype,
        };
        dispatch(setSelectedMedia(media));
        uploadRef.current.value = '';
      };
      coverFileReader.readAsDataURL(f);
    }
  };

  const handleSendMessage = async (clientPhone, message) => {
    if (clientPhone && (message.length || selectedMedia)) {
      const type = selectedMedia ? 'image' : 'text';
      dispatch(
        sendWhatsappBotChatMessageRequest(
          clientPhone,
          message,
          type,
          selectedMedia?.base64.replace(
            `data:${selectedMedia.type}/${selectedMedia.subtype};base64,`,
            ''
          )
        )
      );
      setMessage('');
      if (selectedMedia) {
        dispatch(setSelectedMedia(null));
      }
    }
  };

  const handleClearMessageCount = () => {
    if (selectedConversation.id) {
      dispatch(clearMessageCount(selectedConversation.id));
      dispatch(removeWaitAttendantList(selectedConversation.id));
      toast.dismiss(`callAttenddant-${selectedConversation.id}`)
    }
  };

  return (
    <TextBar
      isManualOrderModalVisible={isManualOrderModalVisible}
      relative
      justifyStart
      onMouseLeave={() => {
        setIsEmojiPickerVisible(false)
      }}
    >
      {isEmojiPickerVisible && (
        <div style={{ position: 'absolute', bottom: px2vw(108) }}>
          <EmojiPicker
            previewConfig={{ showPreview: false }}
            onEmojiClick={(e) => setMessage(message + e.emoji)}
            height={px2vw(300)}
          />
        </div>
      )}
      <TextFieldContainer justifyStart>
        <TextField
          onChange={(e) => setMessage(e.target.value)}
          onClick={handleClearMessageCount}
          onFocusCapture={() => {
            setIsEmojiPickerVisible(false)
          }}
          value={message}
          onKeyDown={(e) => {
            const name = e.key;
            if (e.shiftKey && name === 'Enter') {
              e.preventDefault()
              e.currentTarget.value += "\n"
            } else if(name === 'Enter') {
              e.preventDefault()
              handleSendMessage(selectedConversation.id, message)
            }
          }}
          placeholder="Mensagem"
        />

        <IoSendSharp
          onClick={() => handleSendMessage(selectedConversation.id, message)}
          color={message.length || selectedMedia ? verdeKuppi25 : cinzaKuppi7}
          fontSize={px2vw(20)}
          style={{ position: 'absolute', top: px2vw(17), right: px2vw(17), cursor: 'pointer' }}
        />

        <ToolBar>
        <input
            hidden
            ref={uploadRef}
            onChange={handleUploadFile}
            name="file_upload"
            type="file"
            accept="image/png,image/jpeg"
          />
         <Row  width={ px2vw(50)} spaceBetween cursor='pointer' >
          <BsEmojiSmile
            onClick={() => setIsEmojiPickerVisible(!isEmojiPickerVisible)}
            size={px2vw(18)}
          />

          {!selectedMedia && (
            <FiPaperclip
              onClick={() => uploadRef.current.click()}
              size={px2vw(18)}
            />
          )}
         </Row>
        </ToolBar>
      </TextFieldContainer>
    </TextBar>
  );
}
