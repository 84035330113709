import React, { useEffect, useState } from 'react';
import { Spinner } from 'reactstrap';

import px2vw from '../../../../utils/responsiveness/px2vw';
import { preto, cinzaKuppi5, verdeKuppi7 } from '../../../../styles/colors';
import { Row, Col } from '../../../../styles/grid';

import ButtonPrimary from '../../../../components/ButtonPrimary';

import { LoadingBox } from '../../../../styles/common';
import Checkbutton from '../../../../components/Checkbox/Checkbutton';

import {
  QuestionTitleText,
  FormData,
  CheckbuttomContainer,
  InputData,
  SubText
} from '../../BaseScreen/styles';
import { scrollTop } from '../../../../utils/utils';

import { 
  QuestionFiveAnswer1, QuestionFiveAnswer2, QuestionFiveAnswer3, 
  QuestionFiveAnswer4, QuestionFiveAnswer5, QuestionFiveTitle, 
  QuestionFourAnswer1, QuestionFourAnswer2, QuestionFourAnswer3, 
  QuestionFourTitle, QuestionSixTitle, QuestionSixAnswer1,
  QuestionSixAnswer2, QuestionSixAnswer2SubText, QuestionSixAnswer1SubText,
  QuestionSixPercent
} from '../constants';

export default function PageThreeMobile({ handler }) {
  const checkButtomContainerMargin = 30;
  const checkButtomImgWidth = 90;
  const checkButtomHeight = px2vw(120);
  const checkButtomFontSize = 50;
  const percentagesLabelFontSize = 55;
  const checkButtomPadLeft = 30;
  const checkboxSpaceBetween = px2vw(60);

  const [scroll, setScroll] = useState(true)
  useEffect(() => {
    if (scroll) {
      scrollTop()
      setScroll(false)
    }
  }, [scroll])

  return (
    <Col alignCenter width="100%">
      <FormData onSubmit={handler.handleStoreDataSubmit}>
        <Row justifyStart>
          <QuestionTitleText fontsize={px2vw(60)}>
            {QuestionFourTitle}
          </QuestionTitleText>
        </Row>
        <Col >
          <CheckbuttomContainer
            margbottom={checkboxSpaceBetween}
            margin={checkButtomContainerMargin}
            padleft={px2vw(checkButtomPadLeft)}
            padtop={px2vw(20)}
            width={'100%'}
            backgroundColor={verdeKuppi7}
            justifyContent={'flex-start'}
            height={checkButtomHeight}
            onClick={() => handler.deliveryTypeClick('delivery_only')}
            boxshadow={`${px2vw(5)} ${px2vw(10)} ${px2vw(4)} rgba(0, 0, 0, 0.15)`}
          >
            <Checkbutton
              label={QuestionFourAnswer1}
              checked={handler.deliveryOnly}
              imgWidth={checkButtomImgWidth}
              fontSize={checkButtomFontSize}
            />
          </CheckbuttomContainer>
          <CheckbuttomContainer
            margbottom={checkboxSpaceBetween}
            margin={checkButtomContainerMargin}
            padleft={px2vw(checkButtomPadLeft)}
            padtop={px2vw(20)}
            width={'100%'}
            backgroundColor={verdeKuppi7}
            justifyContent={'flex-start'}
            height={checkButtomHeight}
            onClick={() => handler.deliveryTypeClick('delivery_and_physical')}
            boxshadow={`${px2vw(5)} ${px2vw(10)} ${px2vw(4)} rgba(0, 0, 0, 0.15)`}
          >
            <Checkbutton
              label={QuestionFourAnswer2}
              checked={handler.deliveryAndPhysical}
              imgWidth={checkButtomImgWidth}
              fontSize={checkButtomFontSize}
            />
          </CheckbuttomContainer>
          <CheckbuttomContainer
            margbottom={checkboxSpaceBetween}
            margin={checkButtomContainerMargin}
            padleft={px2vw(checkButtomPadLeft)}
            padtop={px2vw(20)}
            width={'100%'}
            backgroundColor={verdeKuppi7}
            justifyContent={'flex-start'}
            height={checkButtomHeight}
            onClick={() => handler.deliveryTypeClick('physical_only')}
            boxshadow={`${px2vw(5)} ${px2vw(10)} ${px2vw(4)} rgba(0, 0, 0, 0.15)`}
          >
            <Checkbutton
              label={QuestionFourAnswer3}
              checked={handler.physicalOnly}
              imgWidth={checkButtomImgWidth}
              fontSize={checkButtomFontSize}
            />
          </CheckbuttomContainer>
        </Col>
        <Row justifyStart margtop={px2vw(100)}>
          <QuestionTitleText fontsize={px2vw(60)}>
            {QuestionFiveTitle}
          </QuestionTitleText>
        </Row>
        <Col>
          <CheckbuttomContainer
            margbottom={checkboxSpaceBetween}
            margin={checkButtomContainerMargin}
            padleft={px2vw(checkButtomPadLeft)}
            padtop={px2vw(20)}
            width={'100%'}
            backgroundColor={verdeKuppi7}
            justifyContent={'flex-start'}
            height={checkButtomHeight}
            onClick={() => handler.dailyOrdersAvgClick('no_orders')}
            boxshadow={`${px2vw(5)} ${px2vw(10)} ${px2vw(4)} rgba(0, 0, 0, 0.15)`}
          >
            <Checkbutton
              label={QuestionFiveAnswer1}
              checked={handler.noOrders}
              imgWidth={checkButtomImgWidth}
              fontSize={checkButtomFontSize}
            />
          </CheckbuttomContainer>
          <CheckbuttomContainer
            margbottom={checkboxSpaceBetween}
            margin={checkButtomContainerMargin}
            padleft={px2vw(checkButtomPadLeft)}
            padtop={px2vw(20)}
            width={'100%'}
            backgroundColor={verdeKuppi7}
            justifyContent={'flex-start'}
            height={checkButtomHeight}
            onClick={() => handler.dailyOrdersAvgClick('01_05_orders')}
            boxshadow={`${px2vw(5)} ${px2vw(10)} ${px2vw(4)} rgba(0, 0, 0, 0.15)`}
          >
            <Checkbutton
              label={QuestionFiveAnswer2}
              checked={handler.firstOrderRange}
              imgWidth={checkButtomImgWidth}
              fontSize={checkButtomFontSize}
            />
          </CheckbuttomContainer>
          <CheckbuttomContainer
            margbottom={checkboxSpaceBetween}
            margin={checkButtomContainerMargin}
            padleft={px2vw(checkButtomPadLeft)}
            padtop={px2vw(20)}
            width={'100%'}
            backgroundColor={verdeKuppi7}
            justifyContent={'flex-start'}
            height={checkButtomHeight}
            onClick={() => handler.dailyOrdersAvgClick('06_10_orders')}
            boxshadow={`${px2vw(5)} ${px2vw(10)} ${px2vw(4)} rgba(0, 0, 0, 0.15)`}
          >
            <Checkbutton
              label={QuestionFiveAnswer3}
              checked={handler.secondOrderRange}
              imgWidth={checkButtomImgWidth}
              fontSize={checkButtomFontSize}
            />
          </CheckbuttomContainer>
          <CheckbuttomContainer
            margbottom={checkboxSpaceBetween}
            margin={checkButtomContainerMargin}
            padleft={px2vw(checkButtomPadLeft)}
            padtop={px2vw(20)}
            width={'100%'}
            backgroundColor={verdeKuppi7}
            justifyContent={'flex-start'}
            height={checkButtomHeight}
            onClick={() => handler.dailyOrdersAvgClick('11_30_orders')}
            boxshadow={`${px2vw(5)} ${px2vw(10)} ${px2vw(4)} rgba(0, 0, 0, 0.15)`}
          >
            <Checkbutton
              label={QuestionFiveAnswer4}
              checked={handler.thirdOrderRange}
              imgWidth={checkButtomImgWidth}
              fontSize={checkButtomFontSize}
            />
          </CheckbuttomContainer>
          <CheckbuttomContainer
            margbottom={checkboxSpaceBetween}
            margin={checkButtomContainerMargin}
            padleft={px2vw(checkButtomPadLeft)}
            padtop={px2vw(20)}
            width={'100%'}
            backgroundColor={verdeKuppi7}
            justifyContent={'flex-start'}
            height={checkButtomHeight}
            onClick={() => handler.dailyOrdersAvgClick('more_30_orders')}
            boxshadow={`${px2vw(5)} ${px2vw(10)} ${px2vw(4)} rgba(0, 0, 0, 0.15)`}
          >
            <Checkbutton
              label={QuestionFiveAnswer5}
              checked={handler.fourthOrderRange}
              imgWidth={checkButtomImgWidth}
              fontSize={checkButtomFontSize}
            />
          </CheckbuttomContainer>
        </Col>
        <Row margtop={px2vw(100)} justifyStart>
          <QuestionTitleText fontsize={px2vw(60)}>
            {QuestionSixTitle}
          </QuestionTitleText>
        </Row>
        <Col margtop={px2vw(15)} justifyStart>
          <Row margtop={px2vw(25)} spaceBetween>
            <Col width='auto'  margleft={px2vw(25)}>
              <QuestionTitleText fontsize={px2vw(percentagesLabelFontSize)}>
                {QuestionSixAnswer1}
              </QuestionTitleText>
              <SubText fontsize={px2vw(50)}>{QuestionSixAnswer1SubText}</SubText>
            </Col>
            <Row margtop={px2vw(10)} width='auto' alignCenter>
              <InputData
                type="text"
                margright={px2vw(15)}
                name="percentage1"
                textalign="center"
                maxLength="3"
                fontsize={px2vw(55)}
                width={px2vw(150)}
                height={px2vw(120)}
                border={`${px2vw(1)} solid ${cinzaKuppi5}`}
                value={handler.salesOwnChannelPercentage}
                disabled={handler.noOrders ? true : false}
                onClick={() => document.getElementById('percentage1').value = ''}
                onChange={handler.percentageChange}
              />
              <SubText fontsize={px2vw(percentagesLabelFontSize)} fontweight="bold">
                {QuestionSixPercent}
              </SubText>
            </Row>
          </Row>

          <Row margtop={px2vw(25)} margbottom={px2vw(50)} spaceBetween >
            <Col width='auto' margleft={px2vw(25)}>
              <QuestionTitleText fontsize={px2vw(percentagesLabelFontSize)}>
                {QuestionSixAnswer2}
              </QuestionTitleText>
              <SubText fontsize={px2vw(50)}>{QuestionSixAnswer2SubText}</SubText>
            </Col>

            <Row width='auto' margtop={px2vw(10)} alignCenter>
              <InputData
                type="text"
                margright={px2vw(15)}
                name="percentage2"
                textalign="center"
                maxLength="3"
                fontsize={px2vw(55)}
                disabled={handler.noOrders ? true : false}
                onClick={() => document.getElementById('percentage2').value = ''}
                width={px2vw(150)}
                height={px2vw(120)}
                border={`${px2vw(1)} solid ${cinzaKuppi5}`}
                value={handler.salesMarketplacesPercentage}
                onChange={handler.percentageChange}
              />
              <SubText fontsize={px2vw(percentagesLabelFontSize)} fontweight="bold">
                {QuestionSixPercent}
              </SubText>
            </Row>

          </Row>
        </Col>
        <Row justifyEnd margtop={px2vw(20)} height={px2vw(400)}>
          <ButtonPrimary
            borderRadius={40}
            minWidth='60%'
            height={150}
            fontSize={checkButtomFontSize}
            type='submit'
          >
            {handler.updateSurveyStoreDataLoading ? (
              <LoadingBox>
                <Spinner
                  style={{
                    width: '1.8rem',
                    height: '1.8rem',
                    color: preto,
                  }}
                />{' '}
              </LoadingBox>
            ) : (
              'Concluir'
            )}
          </ButtonPrimary>
        </Row>
      </FormData>
    </Col>
  )
}
