import styled from 'styled-components';
import { Input } from '@rocketseat/unform';

import {
  preto,
  verdeKuppi1,
  cinzaKuppi3,
  cinzaKuppi4,
} from '../../../../styles/colors';

import px2vw from '../../../../utils/responsiveness/px2vw';
import px2em from '../../../../utils/responsiveness/px2em';

export const ButtonBox = styled.div`
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;

${(props) => `
  width: ${props.width};
  height: ${props.height};
  margin-top: ${props.margtop};
  margin-left: ${props.margleft};
  padding-left: ${props.padleft};
`};

${(props) =>
  props.border &&
  `
  border: 1px solid ${preto}60;
  border-radius: 5px;
`};

${(props) =>
  props.confirm &&
  `
  border: 1px solid ${verdeKuppi1};
  border-radius: 5px;
  cursor: pointer;
  background-color: ${verdeKuppi1};
`};
`;

export const ButtonImage = styled.img`
height: ${px2vw(50)};
width: ${px2vw(50)};
margin-right: ${px2vw(40)};
margin-top: ${(props) => props.margtop};

${(props) =>
  props.active &&
  `
  filter: invert(40%) sepia(79%) saturate(2498%) hue-rotate(4deg) brightness(103%) contrast(104%);
`};
`;

export const ButtonText = styled.div`
font-size: ${px2vw(50)};
font-family: AvenirNextLTPro;
font-weight: 700;
letter-spacing: 0.32px;
`;

export const SwitchInput = styled(Input)`
  padding: ${px2em(8)} !important;
  margin-left: ${(props) => props.margleft || px2vw(40)} !important;
  margin-right: ${(props) => props.margright || px2vw(40)} !important;
  border: 1px solid ${cinzaKuppi4} !important;
  border-radius: ${(props) => props.borderradius || px2vw(15)} !important;
  height: ${(props) => props.height || px2vw(120)} !important;
  font-size: ${px2vw(40)} !important;
  font-family: font-family: AvenirNextLTPro !important;
  font-weight: normal;
  letter-spacing: 0.36px !important;
  width: ${(props) => props.width || px2vw(190)} !important;
  cursor: ${(props) => props.cursor};

  ::placeholder {
    color: ${cinzaKuppi4} !important;
  }

  :focus {
    border: 1px solid ${cinzaKuppi3} !important;
    box-shadow: none !important;
  }

  ${(props) => `
    width: ${props.width} !important;
    margin-top: ${props.margtop} !important;
    margin-right: ${props.margright} !important;
    padding-left: ${props.padleft} !important;
    margin-bottom: ${props.margbottom} !important;
    pointer-events: ${props.pointerEvents} !important;
  `};

  ${(props) =>
    (props.disabled || props.readonly) &&
    `
      color: ${preto}60;
  `};
`;