import React from 'react';

import { Row, Col } from '../../../../styles/grid';
import {
  verdeKuppi2,
  cinzaKuppi1,
  cinzaKuppi5,
} from '../../../../styles/colors';
import { TabSelected } from '../../Step5-StorePublicity/styles';

import FreeTrialBar from '../../../../components/FreeTrialBar';
import TermsAndPoliciesModal from '../../../../components/TermsAndPoliciesModal';

import {
  PageTitle,
  NormalText,
  LogoImage,
  MenuIconBox,
  Img,
  ProgressBar,
  Hr,
  EmailText,
} from '../styles';

import px2vw from '../../../../utils/responsiveness/px2vw';

import WelcomeScreen from '../../Step0-WelcomeScreen';
import ProfileConfigs from '../../Step1-ProfileConfigs';
import DeliveryParams from '../../Step2-DeliveryParams';
import CatalogOnboarding from '../../Step3-CatalogOnboarding';
import PrintConfigs from '../../Step4-PrintConfigs';
import StorePublicity from '../../Step5-StorePublicity';

export default function BaseScreenWeb({ handler }) {
  return (
    <Row>
      {handler.baseScreen.currOnboardingStep !== 'initial' && (
        // SideBar
        <Col width={px2vw(320)} alignCenter>
          <Row margtop={px2vw(15)}>
            <LogoImage
              width={px2vw(200)}
              height={px2vw(110)}
              src={require('../../../../assets/images/logo_kuppi/logo_kuppi@2x.png')}
            />
          </Row>
          <Col margtop={px2vw(10)} alignCenter>
            <MenuIconBox margright={px2vw(10)} size={px2vw(30)}>
              <Img
                width="100%"
                height="95%"
                alt="Profile"
                src={require('../../../../assets/images/onboarding/profileSquare@2x.png')}
              />
            </MenuIconBox>
            <PageTitle
              sidebarName
              isWeb
              fontsize={px2vw(16)}
              title={handler.baseScreen.user.advertiserInfo.name}
            >
              {handler.baseScreen.user.advertiserInfo.name}
            </PageTitle>
            <EmailText isWeb title={handler.baseScreen.user.email}>
              {handler.baseScreen.user.email}
            </EmailText>
          </Col>
          <Row spaceBetween margtop={px2vw(40)}>
            <Col width="auto" margleft={px2vw(20)}>
              <NormalText fontsize={px2vw(11)} fontweight="700">
                Seu progresso
              </NormalText>
            </Col>
            <Col width="auto" margright={px2vw(10)}>
              <NormalText fontsize={px2vw(11)}>
                {handler.baseScreen.currOnboardingStep === 'step1'
                  ? '0%'
                  : handler.baseScreen.currOnboardingStep === 'step2'
                  ? '20%'
                  : handler.baseScreen.currOnboardingStep === 'step3'
                  ? '40%'
                  : handler.baseScreen.currOnboardingStep === 'step4'
                  ? '60%'
                  : handler.baseScreen.currOnboardingStep === 'step5'
                  ? '80%'
                  : '100%'}
              </NormalText>
            </Col>
          </Row>
          {/* Progess Bar */}
          <Row margtop={px2vw(7)} margbottom={px2vw(20)} spaceBetween>
            <Col width="auto" margleft={px2vw(20)}>
              <ProgressBar
                prog1
                backgroundColor={handler.baseScreen.handleProgressBarColor(
                  'step1',
                  `${verdeKuppi2}30`
                )}
              />
            </Col>
            <Col width="auto">
              <ProgressBar
                backgroundColor={handler.baseScreen.handleProgressBarColor(
                  'step2',
                  `${cinzaKuppi5}85`,
                  verdeKuppi2
                )}
              />
            </Col>
            <Col width="auto">
              <ProgressBar
                backgroundColor={handler.baseScreen.handleProgressBarColor(
                  'step3',
                  `${cinzaKuppi5}85`,
                  verdeKuppi2
                )}
              />
            </Col>
            <Col width="auto">
              <ProgressBar
                backgroundColor={handler.baseScreen.handleProgressBarColor(
                  'step4',
                  `${cinzaKuppi5}85`,
                  verdeKuppi2
                )}
              />
            </Col>
            <Col width="auto" margright={px2vw(10)}>
              <ProgressBar
                prog2
                backgroundColor={handler.baseScreen.handleProgressBarColor(
                  'step5',
                  `${cinzaKuppi5}85`,
                  verdeKuppi2
                )}
              />
            </Col>
          </Row>
          <Hr />
          {/* Steps */}
          {/* 1 */}
          <Row
            justifyCenter
            alignCenter
            cursor="pointer"
            onClick={() => handler.baseScreen.handleSideBarMenuClick('step1')}
          >
            <Col
              width="auto"
              margleft={px2vw(20)}
              margtop={px2vw(10)}
              margbottom={px2vw(10)}
            >
              <MenuIconBox margright={px2vw(10)} size={px2vw(30)}>
                <Img
                  width="100%"
                  height="100%"
                  alt="Logout"
                  src={handler.baseScreen.handleSideMenuImgs(
                    'step1',
                    require('../../../../assets/images/onboarding/numberOne@2x.png'),
                    require('../../../../assets/images/onboarding/numberOne@2x.png')
                  )}
                />
              </MenuIconBox>
            </Col>
            <Col>
              <Row
                spaceBetween
                alignCenter
                margtop={px2vw(5)}
                height={px2vw(33)}
              >
                <NormalText
                  fontweight={
                    handler.baseScreen.currOnboardingStepPage === 'step1'
                      ? 'bold'
                      : 'normal'
                  }
                >
                  Configuração de perfil
                </NormalText>
                <TabSelected
                  isVisible={
                    handler.baseScreen.currOnboardingStepPage === 'step1'
                  }
                  width={px2vw(5)}
                  height={px2vw(40)}
                  margtop={px2vw(1)}
                  margbottom={px2vw(5)}
                  margleft={px2vw(5)}
                />
              </Row>
            </Col>
          </Row>
          <Hr />
          {/* 2 */}
          <Row
            justifyCenter
            alignCenter
            cursor="pointer"
            onClick={() => handler.baseScreen.handleSideBarMenuClick('step2')}
          >
            <Col
              width="auto"
              margleft={px2vw(20)}
              margtop={px2vw(10)}
              margbottom={px2vw(10)}
            >
              <MenuIconBox margright={px2vw(10)} size={px2vw(30)}>
                <Img
                  width="100%"
                  height="100%"
                  alt="Logout"
                  src={handler.baseScreen.handleSideMenuImgs(
                    'step2',
                    require('../../../../assets/images/onboarding/numberTwo@2x.png'),
                    require('../../../../assets/images/onboarding/numberTwoGreen@2x.png')
                  )}
                />
              </MenuIconBox>
            </Col>
            <Col>
              <Row
                spaceBetween
                alignCenter
                margtop={px2vw(5)}
                height={px2vw(33)}
              >
                <NormalText
                  fontweight={
                    handler.baseScreen.currOnboardingStepPage === 'step2'
                      ? 'bold'
                      : 'normal'
                  }
                >
                  Parâmetros do delivery
                </NormalText>
                <TabSelected
                  isVisible={
                    handler.baseScreen.currOnboardingStepPage === 'step2'
                  }
                  width={px2vw(5)}
                  height={px2vw(40)}
                  margtop={px2vw(1)}
                  margbottom={px2vw(5)}
                  margleft={px2vw(5)}
                />
              </Row>
            </Col>
          </Row>
          <Hr />
          {/* 3 */}
          <Row
            justifyCenter
            alignCenter
            cursor="pointer"
            onClick={() => handler.baseScreen.handleSideBarMenuClick('step3')}
          >
            <Col
              width="auto"
              margleft={px2vw(20)}
              margtop={px2vw(10)}
              margbottom={px2vw(10)}
            >
              <MenuIconBox margright={px2vw(10)} size={px2vw(30)}>
                <Img
                  width="100%"
                  height="100%"
                  alt="Logout"
                  src={handler.baseScreen.handleSideMenuImgs(
                    'step3',
                    require('../../../../assets/images/onboarding/numberThree@2x.png'),
                    require('../../../../assets/images/onboarding/numberThreeGreen@2x.png')
                  )}
                />
              </MenuIconBox>
            </Col>
            <Col>
              <Row
                spaceBetween
                alignCenter
                margtop={px2vw(5)}
                height={px2vw(33)}
              >
                <NormalText
                  fontweight={
                    handler.baseScreen.currOnboardingStepPage === 'step3'
                      ? 'bold'
                      : 'normal'
                  }
                >
                  Catálogo
                </NormalText>
                <TabSelected
                  isVisible={
                    handler.baseScreen.currOnboardingStepPage === 'step3'
                  }
                  width={px2vw(5)}
                  height={px2vw(40)}
                  margtop={px2vw(1)}
                  margbottom={px2vw(5)}
                  margleft={px2vw(5)}
                />
              </Row>
            </Col>
          </Row>
          <Hr />
          {/* 4 */}
          <Row
            justifyCenter
            alignCenter
            cursor="pointer"
            onClick={() => handler.baseScreen.handleSideBarMenuClick('step4')}
          >
            <Col
              width="auto"
              margleft={px2vw(20)}
              margtop={px2vw(10)}
              margbottom={px2vw(10)}
            >
              <MenuIconBox margright={px2vw(10)} size={px2vw(30)}>
                <Img
                  width="100%"
                  height="100%"
                  alt="Logout"
                  src={handler.baseScreen.handleSideMenuImgs(
                    'step4',
                    require('../../../../assets/images/onboarding/numberFour@2x.png'),
                    require('../../../../assets/images/onboarding/numberFourGreen@2x.png')
                  )}
                />
              </MenuIconBox>
            </Col>
            <Col>
              <Row
                spaceBetween
                alignCenter
                margtop={px2vw(5)}
                height={px2vw(33)}
              >
                <NormalText
                  fontweight={
                    handler.baseScreen.currOnboardingStepPage === 'step4'
                      ? 'bold'
                      : 'normal'
                  }
                >
                  Configuração de impressão
                </NormalText>
                <TabSelected
                  isVisible={
                    handler.baseScreen.currOnboardingStepPage === 'step4'
                  }
                  width={px2vw(5)}
                  height={px2vw(40)}
                  margtop={px2vw(1)}
                  margbottom={px2vw(5)}
                  margleft={px2vw(5)}
                />
              </Row>
            </Col>
          </Row>
          <Hr />
          {/* 5 */}
          <Row
            justifyCenter
            alignCenter
            cursor="pointer"
            onClick={() => handler.baseScreen.handleSideBarMenuClick('step5')}
          >
            <Col
              width="auto"
              margleft={px2vw(20)}
              margtop={px2vw(10)}
              margbottom={px2vw(10)}
            >
              <MenuIconBox margright={px2vw(10)} size={px2vw(30)}>
                <Img
                  width="100%"
                  height="100%"
                  alt="Logout"
                  src={handler.baseScreen.handleSideMenuImgs(
                    'step5',
                    require('../../../../assets/images/onboarding/numberFive@2x.png'),
                    require('../../../../assets/images/onboarding/numberFiveGreen@2x.png')
                  )}
                />
              </MenuIconBox>
            </Col>
            <Col>
              <Row
                spaceBetween
                alignCenter
                margtop={px2vw(5)}
                height={px2vw(33)}
              >
                <NormalText
                  fontweight={
                    handler.baseScreen.currOnboardingStepPage === 'step5'
                      ? 'bold'
                      : 'normal'
                  }
                >
                  Divulgação
                </NormalText>
                <TabSelected
                  isVisible={
                    handler.baseScreen.currOnboardingStepPage === 'step5'
                  }
                  width={px2vw(5)}
                  height={px2vw(40)}
                  margtop={px2vw(1)}
                  margbottom={px2vw(5)}
                  margleft={px2vw(5)}
                />
              </Row>
            </Col>
          </Row>
          <Hr />
          <Row
            width="auto"
            margtop={px2vw(20)}
            height="auto"
            onClick={handler.baseScreen.logoutButtonClick}
            cursor="pointer"
          >
            <Img
              width={px2vw(25)}
              height={px2vw(20)}
              alt="Logout"
              src={require('../../../../assets/images/logout/logout@2x.png')}
            />
            <NormalText margleft={px2vw(10)} margtop={px2vw(3)}>
              Sair
            </NormalText>
          </Row>
        </Col>
      )}
      <Col>
        {/* Header */}
        {handler.baseScreen.hasFreeTrialValid && (
            <FreeTrialBar
              remainingFreeDays={handler.baseScreen.remainingFreeDays}
              minimumSubscriptionAmount={
                handler.baseScreen.minimumSubscriptionAmount
              }
              paymentToken={handler.baseScreen.paymentToken}
              isOnboardingInitial={
                handler.baseScreen.currOnboardingStep === 'initial'
              }
            />
          )}
        {handler.baseScreen.currOnboardingStep === 'initial' && (
          <WelcomeScreen
            isWeb={handler.isWeb}
            handler={handler.welcomeScreen}
          />
        )}
        {handler.baseScreen.currOnboardingStepPage === 'step1' && (
          <>
            {/* Show terms and policies modal */}
            {!handler.baseScreen.user.advertiserInfo.is_terms_accepted && (
              <TermsAndPoliciesModal
                isVisible={
                  !handler.baseScreen.user.advertiserInfo.is_terms_accepted
                }
              />
            )}
            <ProfileConfigs
              isWeb={handler.isWeb}
              handler={handler.profileConfigsScreen}
            />
          </>
        )}
        {handler.baseScreen.currOnboardingStepPage === 'step2' && (
          <Col
            background={cinzaKuppi1}
            width="100%"
            padtop={px2vw(30)}
            padleft={px2vw(35)}
            borderRadius={
              !handler.baseScreen.hasFreeTrialValid && px2vw(60)
            }
          >
            <DeliveryParams handler={handler.deliveryParamsScreen} />
          </Col>
        )}
        {handler.baseScreen.currOnboardingStepPage === 'step3' && (
          <Col
            background={cinzaKuppi1}
            width="100%"
            padtop={px2vw(30)}
            padleft={px2vw(35)}
            padbottom={px2vw(50)}
            borderRadius={
              !handler.baseScreen.hasFreeTrialValid && px2vw(60)
            }
          >
            <CatalogOnboarding handler={handler.catalogScreen} />
          </Col>
        )}
        {handler.baseScreen.currOnboardingStepPage === 'step4' && (
          <PrintConfigs
            isWeb={handler.isWeb}
            handler={handler.printConfigsScreen}
          />
        )}
        {handler.baseScreen.currOnboardingStepPage === 'step5' && (
          <StorePublicity isWeb={handler.isWeb} />
        )}
      </Col>
    </Row>
  );
}
