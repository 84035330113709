import { toast } from 'react-toastify';
import { takeLatest, call, put, all, select } from 'redux-saga/effects';

import { apiAnunciante } from '../../../services/api';

import {
  setClientInfo,
  setClientInfoFailure,
  setProductData,
  setAdvertiserPaymentMethod,
  setAdvertiserInfo,
  setAddresses,
  cleanManualOrderData,
  createManualOrderSuccess,
  createManualOrderFailure,
  setCurrManualOrderStatusData,
  formatOrderComplements as formatOrderComplementsAction,
} from './actions';
import { logout } from '../auth/actions';

import { sendWhatsappMessage } from '../../../utils/ordersConfigs/ordersConfigs';
import { sendManualOrderInfo } from '../../../services/vendors/mixpanel';
import { getAllClientsData } from '../whatsappChat/actions';

const userReducerData = (state) => state.user;
const userData = (state) => state.user.user;
const loyaltyCardData = (state) => state.loyaltyCard.loyaltyCard;

export function* formatOrderComplements({ payload }) {
  function* getPayable() {
    yield* payload.orders;
  }

  const sequence = getPayable();
  let hasIterable = true;
  const results = [];

  while (hasIterable) {
    const value = yield sequence.next().value;

    if (value) {
      value.order.orderProduct.map((orderProduct, idxProd) => {
        // Join same complements category at the same object
        const orderProductComplementsArrObj = {};
        orderProduct.orderProductComplements.map((orderProductComplements) => {
          const catName =
            orderProductComplements.productComplementOption
              .productComplementCategories.name;
          // Avoiding error when JSON.parse try to escape ", replace the " to `
          const complName =
            orderProductComplements.productComplementOption.name.replaceAll(
              '"',
              '`'
            );
          const complQty = orderProductComplements.quantity;
          const complAmount = orderProductComplements.amount;

          if (catName in orderProductComplementsArrObj) {
            orderProductComplementsArrObj[
              catName
            ] = `${orderProductComplementsArrObj[catName]}, { "quantity": ${complQty}, "name": "${complName}", "amount": ${complAmount} }`;
          } else {
            orderProductComplementsArrObj[
              catName
            ] = `{ "quantity": ${complQty}, "name": "${complName}", "amount": ${complAmount} }`;
          }

          return true;
        });

        // Convert complements from object to array
        const orderProductComplementsArr = [];
        for (const complCategory in orderProductComplementsArrObj) {
          // Two replace all to avoid:
          //  - unexpected escape when the user put 1\2 instead of 1/2
          //  - unexpected tab (\t) when the user paste an text with tab instead
          //  - change ` to " escaped
          orderProductComplementsArr.push({
            category: complCategory,
            complements: JSON.parse(
              `[ ${orderProductComplementsArrObj[complCategory]
                .replaceAll('\\', '\\\\')
                .replaceAll('\t', ' ')
                .replaceAll('`', '\\"')} ]`
            ),
          });
        }

        value.order.orderProduct[idxProd].orderProductComplements =
          orderProductComplementsArr;

        return true;
      });

      results.push(value);
    } else {
      hasIterable = false;
    }
  }

  yield put(setCurrManualOrderStatusData(results[0]));
}

export function* verifyUserExistsByPhoneRequest({ payload }) {
  const user = yield select(userData);

  const deliveryTakeawayConfig =
    user?.advertiserInfo?.advertiserDeliveryTakeawayConfig[0];
  const isUsingNeighborhoodArea =
    !deliveryTakeawayConfig?.is_using_range_area &&
    deliveryTakeawayConfig?.is_using_neighborhood_area;

  try {
    const response = yield call(
      apiAnunciante.post,
      `/user-info/${user.advertiserInfo.id}/manual-orders/verify-phone`,
      {
        phone: payload.phone,
        isUsingNeighborhoodArea,
      }
    );

    yield put(setClientInfo(response.data, 'verify'));
    yield put(setAddresses(response.data.addresses));
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      toast.error(
        'Erro ao validar o telefone, verifique se o telefone informado está correto e é um telefone celular.'
      );

      yield put(setClientInfoFailure('verify'));
    }
  }
}

export function* createNewUserRequest({ payload }) {
  const user = yield select(userData);

  try {
    const response = yield call(
      apiAnunciante.post,
      `/user-info/${user.advertiserInfo.id}/manual-orders/create-new-user`,
      {
        phone: payload.phone,
        name: payload.name,
      }
    );

    yield put(setClientInfo(response.data, 'create'));
    yield put(setAddresses(response.data.addresses));
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      toast.error(
        'Erro ao criar o novo usuário. Tente novamente e caso o erro persista, entre em contato com o suporte'
      );

      yield put(setClientInfoFailure('create'));
    }
  }
}

export function* getProductDataRequest() {
  const user = yield select(userData);

  const response = yield call(
    apiAnunciante.get,
    `/menu/${user.advertiserInfo.slug}`
  );

  yield put(setProductData(response.data));
  yield put(setAdvertiserInfo(response.data.advertiser));
  yield put(
    setAdvertiserPaymentMethod(response.data.advertiser.advertiserPaymentMethod)
  );
}

export function* createManualOrderRequest() {
  try {
    const manualOrder = yield select((state) => state.manualOrder);
    const user = yield select(userData);
    const userReducer = yield select(userReducerData);
    const loyaltyCard = yield select(loyaltyCardData);
    const pixKey =
      user.advertiserInfo?.advertiserPaymentMethod.find(
        (pm) => pm.payment_method_slug === 'pix_chave'
      ).external_processor_key || null;
    // const whatsappSendMethod =
    //   user.advertiserInfo?.advertiserOrdersConfig?.whatsapp_send_method;

    const whatsappIsActive =
      user.advertiserInfo?.advertiserWhatsappbotConfig?.is_active;

    const alwaysSendWhatsappMsg =
      user.advertiserInfo.advertiserOrdersConfig.is_send_whatsapp_msg_update_status;

    const manualOrderRequest = yield call(
      apiAnunciante.post,
      `/user-info/${manualOrder.advertiser.slug}/manual-order`,
      {
        products: manualOrder.bag.items_in_bag,
        clientId: manualOrder.clientData.client_id,
        clientName: manualOrder.clientData.name,
        deliveryType: manualOrder.bag.deliveryType,
        deliveryAddress: manualOrder.address || null,
        discountInBagCoupon: null,
        isValidToLoyaltyCard:
          loyaltyCard &&
          loyaltyCard?.is_active &&
          Number(parseFloat(manualOrder.bag.priceOfTheWholeBag).toFixed(2)) >=
            loyaltyCard?.ticket_minimum_amount
            ? true
            : false,
        discountInBag: null,
        subtotal: manualOrder.bag.priceOfTheWholeBag,
        deliveryFee: manualOrder.deliveryFee,
        totalDiscount: manualOrder.bag.discount_in_bag
          ? (
              manualOrder.bag.priceWithoutDiscount *
              (manualOrder.bag.discount_in_bag / 100)
            ).toFixed(2)
          : manualOrder.bag.discount_in_amount || 0,
        total:
          parseFloat(manualOrder.bag.priceOfTheWholeBag) +
          manualOrder.deliveryFee,
        orderType: manualOrder.selectedSchedule?.type,
        schedule:
          manualOrder.selectedSchedule?.type === 'immediate'
            ? manualOrder.selectedSchedule
            : {
                id: manualOrder.selectedSchedule.id,
                schedulingStartAt: manualOrder.selectedSchedule.timeFrom,
                schedulingEndAt: manualOrder.selectedSchedule.timeTo,
              },
        change: manualOrder.bag.changeValue,
        advertiserPaymentMethod: manualOrder.paymentMethod,
        facebook_campaign_id: null,
        isCreatedManuallyWhatsappChat: manualOrder.isOpennedFromWhatsappChat,
      }
    );

    if (manualOrder.selectedSchedule?.type === 'immediate') {
      toast.success(
        'Pedido criado com sucesso. Já colocamos ele "Em Preparo" para você. 🙂'
      );
    } else {
      toast.success(
        'Pedido criado e aceito com sucesso. Aguardando você iniciar o preparo. 🙂'
      );
    }

    // Mount manual order data to be used when send whatsapp notification message
    const whatsappMsgData = {
      ...manualOrderRequest.data.newOrder.orderStatus,
      status: 'new',
    };
    whatsappMsgData.order.paymentMethod =
      manualOrder.paymentMethod.paymentMethod;

    sendWhatsappMessage(alwaysSendWhatsappMsg, whatsappMsgData, whatsappIsActive, pixKey);
    yield put(
      formatOrderComplementsAction(manualOrderRequest.data.newOrder.orderData)
    );
    yield put(createManualOrderSuccess());
    // Get the tags data of all conversations in whatsapp
    yield put(getAllClientsData([manualOrder.clientData.phone]));
    yield put(cleanManualOrderData());

    //MixPanel Event
    sendManualOrderInfo({
      id: user.advertiserInfo.id,
      name: user.advertiserInfo.name,
      email: user.email,
      currManualOrderStep: 'ManualConcludedOrder',
      hasFreeTrialValid: userReducer.hasFreeTrialValid,
    });
  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      const message =
        err.response?.data?.error?.message ||
        'Falha ao criar o pedido. Contate o suporte.';
      toast.error(message);
      yield put(createManualOrderFailure(false));
    }
  }
}

export default all([
  takeLatest('@manualOrder/FORMAT_ORDER_COMPLEMENTS', formatOrderComplements),
  takeLatest(
    '@manualOrder/VERIFY_USER_EXISTS_BY_PHONE_REQUEST',
    verifyUserExistsByPhoneRequest
  ),
  takeLatest('@manualOrder/CREATE_NEW_USER_REQUEST', createNewUserRequest),
  takeLatest('@manualOrder/GET_PRODUCT_DATA', getProductDataRequest),
  takeLatest(
    '@manualOrder/CREATE_MANUAL_ORDER_REQUEST',
    createManualOrderRequest
  ),
]);
