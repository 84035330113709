import React from 'react';

import px2vw from '../../utils/responsiveness/px2vw';

import { Row } from '../../styles/grid';

import { formatDateTimeSchuduledOrder } from '../../utils/utils';

import { OrdersRowText } from '../../pages/_layouts/styles/orders';

import { StatusBox } from './styles';

export default function SchedulingInfoBox(props) {
  const { schedulingOrderDateTime, width, height, padtop, margtop, fs = px2vw(13), margbottom = "0px" } = props;

  return (
    <StatusBox width={width} height={height} padtop={padtop} margtop={margtop} margbottom={margbottom}>
      <Row alignCenter justifyCenter>
        <OrdersRowText
          fontSize={fs}
          isBold
        >
          {formatDateTimeSchuduledOrder(schedulingOrderDateTime)}
        </OrdersRowText>
      </Row>
    </StatusBox>
  );
}
