import React, { useState, useMemo, useEffect, useCallback  } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'reactstrap';
import { BiSearchAlt2 } from 'react-icons/bi';
import { IoClose } from 'react-icons/io5';
import { Checkbox } from '@material-ui/core';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { FcCheckmark, FcCancel } from 'react-icons/fc';

import {
  AmountInput,
  CloseButton,
  CloseImage,
  ColOverFlow,
  Hr,
  ModalBackground,
  ModalContent,
  ModalHeader,
  ModalPrimaryButton,
  SearchInput,
  SearchInputClearStyle,
  SearchInputStyle,
  SelectInputBox,
} from './styles';
import { Row } from '../../../styles/grid';
import {
  amareloKuppi11,
  cinzaKuppi7,
  preto,
  verdeKuppi2,
} from '../../../styles/colors';
import { Text } from '../../../styles/common';

import {
  setIsEditingAmount,
  updateComplementAmountRequest,
} from '../../../store/modules/massHandleComplements/actions';

import { useConstant } from '../../../utils/hooks';
import px2vw from '../../../utils/responsiveness/px2vw';
import debounce from '../../../utils/debounce/debounce';
import { formatCurrencyBRL } from '../../../utils/utils';
import { moneyMask } from '../../../utils/masks';

import MassActionBox from './MassActionBox';

export default function CatalogComplementMassEditionModal({ handler }) {
  const dispatch = useDispatch();

  const statusFilters = [
    { id: '', title: 'Filtro por Status' },
    { id: true, title: 'Ativo' },
    { id: false, title: 'Pausado' },
  ];

  const [isMassActionBoxOpenned, setIsMassActionBoxOpenned] = useState(false);
  const [selectedStatusFilter, setSelectedStatusFilter] = useState('');
  const [inputSearchData, setInputSearchData] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [inputAmount, setInputAmount] = useState('');
  const [selectedComplements, setSelectedComplements] = useState([]);

  const { complements, editingAmountComplementID, updateComplementAmountLoading } = useSelector(
    (state) => state.massHandleComplements
  );

  const complementsArr = useMemo(() => {
    const data =
      searchTerm.length >= 1
        ? complements.filter((c) =>
          c.complement_name
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
        )
        : complements;

    if (selectedStatusFilter === 'true') {
      return data.filter((c) => c.is_active === true);
    } else if (selectedStatusFilter === 'false') {
      return data.filter((c) => c.is_active === false);
    } else {
      return data;
    }
  }, [
    searchTerm,
    selectedStatusFilter,
    complements,
    updateComplementAmountLoading,
  ]);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 100;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = currentPage * itemsPerPage;

  const changePage = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const visibleComplements = complementsArr.slice(startIndex, endIndex);

  const totalPages = Math.ceil(complementsArr.length / itemsPerPage);

  const handleSelectComplement = (complement) => {
    const allReadyInBlackList = selectedComplements?.includes(complement.id);
    if (allReadyInBlackList) {
      setSelectedComplements(
        selectedComplements.filter((id) => id !== complement.id)
      );
    } else {
      setSelectedComplements([...selectedComplements, complement.id]);
    }
  };

  const areAllComplementsSelected = useMemo(() => {
    return complementsArr.length === selectedComplements.length;
  }, [complementsArr, selectedComplements]);

  const handleSelectAllComplements = useCallback(() => {
    if (areAllComplementsSelected) {
      setSelectedComplements([]);
    } else {
      setSelectedComplements(complementsArr.map((c) => c.id));
    }
  }, [complementsArr, selectedComplements]);

  const updateAmountRequest = (newAmount) => {
    dispatch(updateComplementAmountRequest(parseFloat(newAmount)));
  };

  useEffect(() => {
    if (editingAmountComplementID) {
      const complement = complements.find((c) => c.id === editingAmountComplementID);
      setInputAmount(complement?.amount || 0);
    } else {
      setInputAmount('');
    }
  }, [editingAmountComplementID, setIsEditingAmount]);

  const renderComplements = useMemo(() => {
    return visibleComplements.map((c, i) => (
      <Row key={c.id} relative justifyStart alignCenter minheight={px2vw(50)}>
        <Checkbox
          onClick={() => handleSelectComplement(c)}
          checked={selectedComplements.includes(c.id)}
          style={{
            color: verdeKuppi2,
            padding: 0,
            position: 'absolute',
          }}
          value="Red"
        />
        <Row
          alignCenter
          justifyStart
          margleft={px2vw(35)}
          height="auto"
          width={px2vw(400)}
        >
          <img
            src={require('../../../assets/images/camera/camera.png')}
            style={{ marginRight: px2vw(5), width: px2vw(22) }}
          />
          <Text size={px2vw(13)} color={preto} alignStart>
            {c.complement_name}
          </Text>
        </Row>
        <Row
          alignCenter
          justifyStart
          margleft={px2vw(15)}
          height="auto"
          width={px2vw(140)}
        >
          {editingAmountComplementID === c.id ? (
            <>
              <Text
                size={px2vw(13)}
                regular
                color={cinzaKuppi7}
                margright={px2vw(-19)}
                alignStart
                zIndex="10"
              >
                R$
              </Text>
              <AmountInput
                disabled={updateComplementAmountLoading}
                type="text"
                onChange={(e) => setInputAmount(moneyMask(e.target.value))}
                value={inputAmount}
              />
              {updateComplementAmountLoading ? (
                <Spinner
                  style={{
                    marginLeft: px2vw(4),
                    width: px2vw(17),
                    height: px2vw(17),
                    color: preto,
                  }}
                />
              ) : (
                <>
                  <FcCheckmark
                    size={px2vw(17)}
                    onClick={() => updateAmountRequest(inputAmount)}
                    style={{
                      width: px2vw(17),
                      height: px2vw(17),
                      margin: `auto ${px2vw(4)}`,
                      marginLeft: px2vw(7),
                      cursor: 'pointer',
                    }}
                  />
                  <FcCancel
                    size={px2vw(17)}
                    onClick={() => dispatch(setIsEditingAmount(null))}
                    style={{
                      width: px2vw(17),
                      height: px2vw(17),
                      margin: `auto ${px2vw(4)}`,
                      marginLeft: px2vw(3),
                      cursor: 'pointer',
                    }}
                  />
                </>
              )}
            </>
          ) : (
            <Text
              size={px2vw(13)}
              regular
              color={preto}
              margleft={px2vw(1)}
              alignStart
            >
              {formatCurrencyBRL(c.amount || 0, 2)}{' '}
              <img
                src={require('../../../assets/images/edit/edit.png')}
                onClick={() => dispatch(setIsEditingAmount(c.id))}
                style={{
                  width: px2vw(17),
                  height: px2vw(17),
                  margin: `auto ${px2vw(4)}`,
                  marginLeft: px2vw(7),
                  cursor: 'pointer',
                }}
              />
            </Text>
          )}
        </Row>
        <Row
          alignCenter
          justifyStart
          margleft={px2vw(15)}
          height="auto"
          width={px2vw(250)}
        >
          <Text
            size={px2vw(13)}
            regular
            color={preto}
            margleft={px2vw(1)}
            alignStart
          >
            {c.product_name}
          </Text>
        </Row>
        <Row
          alignCenter
          justifyStart
          margleft={px2vw(15)}
          height="auto"
          width={px2vw(100)}
        >
          <Text
            size={px2vw(13)}
            color={c.is_active ? verdeKuppi2 : amareloKuppi11}
            margleft={px2vw(1)}
            alignStart
          >
            {c.is_active ? 'Ativo' : 'Pausado'}
          </Text>
        </Row>
      </Row>
    ));
  }, [
    complements,
    currentPage,
    searchTerm,
    selectedStatusFilter,
    complementsArr,
    editingAmountComplementID,
    inputAmount,
    updateComplementAmountLoading,
    selectedComplements,
  ]);

  function filterComplements(searchTermText) {
    setSearchTerm(searchTermText);
    setCurrentPage(1);
  }

  const debouncedOnChange = useConstant(() => debounce(filterComplements));

  return (
    <>
      <ModalBackground isVisible>
        <ModalContent width="80%" height={px2vw(550)}>
          <ModalHeader>
            <CloseButton onClick={handler.onClose}>
              <CloseImage
                src={require('../../../assets/images/close/close.png')}
              />
            </CloseButton>
          </ModalHeader>
          <Row
            justifyStart
            padtop={px2vw(15)}
            height={px2vw(70)}
            padright={px2vw(25)}
          >
            <SelectInputBox
              width={px2vw(150)}
              height={px2vw(35)}
              value={selectedStatusFilter}
              placeholder="Filtro por Status"
              name="statusFilter"
              options={statusFilters}
              onChange={(e) => setSelectedStatusFilter(e.target.value)}
            />

            <Row
              alignCenter
              relative
              height="auto"
              margleft={px2vw(7)}
              width={px2vw(235)}
            >
              <BiSearchAlt2 style={SearchInputStyle} />
              <SearchInput
                value={inputSearchData}
                onChange={(e) => {
                  setInputSearchData(e.target.value);
                  debouncedOnChange(e.target.value);
                }}
                placeholder="Busque pelo nome"
              />
              {inputSearchData.length > 0 && (
                <IoClose
                  onClick={() => setInputSearchData('')}
                  style={SearchInputClearStyle}
                />
              )}
            </Row>

            <ModalPrimaryButton
              disabled={selectedComplements.length < 2}
              onClick={() => setIsMassActionBoxOpenned(true)}
              margleft="auto"
            >
              <img
                className="mr-2"
                src={require('../../../assets/images/catalog/mass_button_icon.svg')}
              />
              Ação em Massa
            </ModalPrimaryButton>
          </Row>
          <Row justifyStart padtop={px2vw(15)} alignCenter padright={px2vw(25)}>
            <Checkbox
              checked={areAllComplementsSelected}
              onClick={handleSelectAllComplements}
              style={{
                color: verdeKuppi2,
                padding: 0,
              }}
              value="Red"
            />
            <Row
              alignCenter
              justifyStart
              margleft={px2vw(15)}
              height="auto"
              width={px2vw(400)}
            >
              <Text size={px2vw(18)} color={preto} alignStart>
                Nome do Complemento
              </Text>
            </Row>
            <Row
              alignCenter
              justifyStart
              margleft={px2vw(15)}
              height="auto"
              width={px2vw(140)}
            >
              <Text
                size={px2vw(18)}
                color={preto}
                margleft={px2vw(1)}
                alignStart
              >
                Valor
              </Text>
            </Row>
            <Row
              alignCenter
              justifyStart
              margleft={px2vw(15)}
              height="auto"
              width={px2vw(250)}
            >
              <Text
                size={px2vw(18)}
                color={preto}
                margleft={px2vw(1)}
                alignStart
              >
                Produto Relacionado
              </Text>
            </Row>
            <Row
              alignCenter
              justifyStart
              margleft={px2vw(15)}
              height="auto"
              width={px2vw(100)}
            >
              <Text
                size={px2vw(18)}
                color={preto}
                margleft={px2vw(1)}
                alignStart
              >
                Status
              </Text>
            </Row>
          </Row>

          <Hr />
          <ColOverFlow
            overflowY
            width="100%"
            maxheight={px2vw(330)}
            className="d-block"
          >
            {renderComplements}
          </ColOverFlow>

          <Row justifyEnd height={px2vw(50)} alignCenter padtop={px2vw(10)}>
            <IoIosArrowBack
              onClick={() => changePage(currentPage - 1)}
              disabled={currentPage === 1}
              fontSize={px2vw(21)}
              style={{ cursor: 'pointer' }}
            />
            <Text regular size={px2vw(15)} margright={px2vw(5)} margleft={px2vw(5)}>
              Página {currentPage} de {totalPages}
            </Text>
            <IoIosArrowForward
              onClick={() => changePage(currentPage + 1)}
              disabled={currentPage === totalPages}
              fontSize={px2vw(21)}
              style={{ cursor: 'pointer' }}
            />
          </Row>
        </ModalContent>
      </ModalBackground>
      {isMassActionBoxOpenned && (
        <ModalBackground isVisible>
          <MassActionBox
            list={selectedComplements}
            onClose={() => setIsMassActionBoxOpenned(false)}
            onSuccess={() => {
              setSelectedComplements([]);
              setIsMassActionBoxOpenned(false);
            }}
          />
        </ModalBackground>
      )}
    </>
  );
}
