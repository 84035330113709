import React, { useEffect, useState } from 'react';

import px2vw from '../../../utils/responsiveness/px2vw';
import { FaLink } from 'react-icons/fa';

import {
  CloseButton,
  CloseImage,
  ModalBackground,
  ModalContent,
  ProgressBar,
  ModalHeader,
  BalanceBox,
  ModalSecundaryButton,
  ModalSecundaryButtonText,
  ButtonPrimary,
} from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { formatCurrencyBRL } from '../../../utils/utils';
import { Col, Row } from '../../../styles/grid';
import { Text } from '../../../styles/common';
import { SwitchInput } from '../Onboarding/Step1-FacebookId/styles';
import {
  setIsFinished,
  updateOnboardingData,
} from '../../../store/modules/kuppiBoost/actions';
import { toast } from 'react-toastify';
import { Spinner } from 'reactstrap';
import { preto } from '../../../styles/colors';

export default function AccountLink(props) {
  const dispatch = useDispatch();
  const kuppiBoostData = useSelector((state) => state.kuppiBoost);
  const { facebook_id, instagram_id, balance, update_onboarding_loading } =
    kuppiBoostData;
  const [facebookIdData, setFacebookIdData] = useState(facebook_id || '');
  const [instagramIdData, setInstagramIdData] = useState(instagram_id || '');
  const [isEditingFacebook, setIsEditingFacebook] = useState(false);
  const [isEditingInstagram, setIsEditingInstagram] = useState(false);
  const isSomethingChanged =
    instagramIdData !== instagram_id || facebookIdData !== facebook_id;

  useEffect(() => {
    setInstagramIdData(instagram_id || '');
    setFacebookIdData(facebook_id || '');
  }, [facebook_id, instagram_id]);

  function handleResetOnboarding() {
    dispatch(setIsFinished(false));
    props.onClose();
  }

  function handleSaveChanges() {
    if (facebook_id && facebookIdData.length < 15) {
      toast.error('Verifique os dados.', {
        autoClose: 6000,
      });
    } else if (instagram_id && instagramIdData.length < 15) {
      toast.error('Verifique os dados.', {
        autoClose: 6000,
      });
    } else {
      dispatch(updateOnboardingData(facebookIdData));
      dispatch(
        updateOnboardingData(facebookIdData, instagramIdData, props.onClose)
      );
    }
  }

  return (
    <ModalBackground isVisible={props.isVisible}>
      <ModalContent
        width={px2vw(500)}
        height={px2vw(500)}
        bgcolor={props.bgcolor}
        margtop={props.margtop}
        padding={props.padding}
        backgroundImg={props.backgroundImg}
        backgroundImgSize={props.backgroundImgSize}
        maxheight={props.maxheight}
      >
        <ModalHeader>
          <FaLink size={px2vw(22)} className="mr-3" /> Editar Vinculo
          <CloseButton className="ml-auto" onClick={props.onClose}>
            <CloseImage
              src={require('../../../assets/images/close/close.png')}
            />
          </CloseButton>
        </ModalHeader>
        <ProgressBar>
          <ProgressBar
            value={0}
            bar={true}
            style={{
              borderRaduis: '0px',
              height: '4px',
            }}
          />
        </ProgressBar>
        <Row margtop={px2vw(18)}>
          <BalanceBox balance={balance}>
            {balance
              ? `Você possui um saldo de ${formatCurrencyBRL(balance, 2)}`
              : 'Você não possui saldo'}
          </BalanceBox>
        </Row>
        <Col pad={px2vw(25)} justifyStart>
          <Row justifyStart margbottom={px2vw(10)}>
            <Text size={px2vw(16)} justifyStart>
              ID Facebook
            </Text>
          </Row>
          <Row justifyStart>
            <SwitchInput
              onChange={(e) => setFacebookIdData(e.target.value)}
              value={facebookIdData}
              radius={px2vw(3)}
              disabled={!isEditingFacebook}
              maxLength="20"
              name="facebook"
              width="100%"
            />
            <ModalSecundaryButton
              width={px2vw(150)}
              disabled={balance && facebook_id}
              onClick={() => setIsEditingFacebook(!isEditingFacebook)}
              type="button"
            >
              <ModalSecundaryButtonText size={px2vw(14)}>
                {!isEditingFacebook ? 'Editar' : 'Salvar'}
              </ModalSecundaryButtonText>
            </ModalSecundaryButton>
          </Row>
          <Row justifyStart margbottom={px2vw(10)} margtop={px2vw(10)}>
            <Text size={px2vw(16)} justifyStart>
              ID Instagram
            </Text>
          </Row>
          <Row justifyStart>
            <SwitchInput
              onChange={(e) => setInstagramIdData(e.target.value)}
              value={instagramIdData}
              radius={px2vw(3)}
              disabled={!isEditingInstagram}
              maxLength="20"
              name="instagram"
              width="100%"
            />
            <ModalSecundaryButton
              width={px2vw(150)}
              disabled={balance && instagram_id}
              onClick={() => setIsEditingInstagram(!isEditingInstagram)}
              type="button"
            >
              <ModalSecundaryButtonText size={px2vw(14)}>
                {!isEditingInstagram ? 'Editar' : 'Salvar'}
              </ModalSecundaryButtonText>
            </ModalSecundaryButton>
          </Row>
          {!!balance && (
            <Row justifyStart margbottom={px2vw(10)} margtop={px2vw(20)}>
              <Text size={px2vw(13)} justifyStart regular fitLetter>
                * Para desvincular o ID você não pode ter saldo
              </Text>
            </Row>
          )}
          {/*
            <Row
              margbottom={px2vw(20)}
              margtop={px2vw(20)}
              cursor="pointer"
              onClick={handleResetOnboarding}
            >
              <Text size={px2vw(15)} regular>
                <div>
                  Ficou com dúvida?{' '}
                  <strong style={{ textDecoration: 'underline' }}>
                    Acesse o tutorial
                  </strong>
                </div>
              </Text>
            </Row>
          */}

          <Row
            height="100%"
            spaceAround
            margtop={px2vw(!instagramIdData || !facebookIdData ? 40 : 80)}
          >
            <ModalSecundaryButton
              width={px2vw(220)}
              onClick={props.onClose}
              type="button"
            >
              <ModalSecundaryButtonText>Cancelar</ModalSecundaryButtonText>
            </ModalSecundaryButton>

            <ButtonPrimary
              onClick={handleSaveChanges}
              complete={isSomethingChanged}
              disabled={!isSomethingChanged}
              width={px2vw(220)}
            >
              {update_onboarding_loading ? (
                <Spinner
                  style={{
                    width: px2vw(15),
                    height: px2vw(15),
                    color: preto,
                  }}
                  className="m-auto"
                />
              ) : (
                'Salvar'
              )}
            </ButtonPrimary>
          </Row>
        </Col>
      </ModalContent>
    </ModalBackground>
  );
}
