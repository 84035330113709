import { toast } from 'react-toastify';
import { takeLatest, call, put, all, select, delay } from 'redux-saga/effects';
import { apiAnunciante } from '../../../services/api';

import {
  setAllClientsData,
  updateNewMessagesCount,
  setWaitAttendantList,
  setRevokedMessages,
} from './actions';

const userData = (state) => state.user.user;
const allClientsDataSelect = (state) => state.whatsappChat.allClientsData;
const isConnected = (state) => state.whatsappChat.isConnected;
const newMessagesCount = (state) => state.whatsappChat.newMessagesCount;
const waitAttendantList = (state) => state.whatsappChat.waitAttendantList;
const revokedMessages = (state) => state.whatsappChat.revokedMessages;

export function* getAllClientsDataRequest({ payload }) {
  if (payload.forceLastRequest) {
    yield delay(1000);
  }

  try {
    const user = yield select(userData);
    const allClientsData = yield select(allClientsDataSelect);
    const connected = yield select(isConnected);
    const phoneList = payload.phoneList;

    if (!connected || phoneList?.length === 0) {
      return;
    }

    const response = yield call(
      apiAnunciante.post,
      `/user-info/${user.advertiserInfo.id}/whatsappchat/infos`,
      { phone_list: phoneList }
    );

    const oldData = allClientsData.filter(
      (item) => !response.data.some((r) => r.phone === item.phone)
    );
    yield put(setAllClientsData([...new Set([...oldData, ...response.data])]));
  } catch (err) {
    toast.error(`Ocorreu um erro ao buscar as informações dos contatos!`, {
      autoClose: 4000,
    });
  }
}

export function* getAllActiveOrdersDataRequest() {
  yield delay(1000);

  try {
    const user = yield select(userData);
    const allClientsData = yield select(allClientsDataSelect);
    const connected = yield select(isConnected);

    if (!connected) {
      return;
    }

    const response = yield call(
      apiAnunciante.get,
      `/user-info/${user.advertiserInfo.id}/whatsappchat/active-orders`
    );

    const oldData = allClientsData.filter(
      (item) => !response.data.some((r) => r.phone === item.phone)
    );
    yield put(setAllClientsData([...new Set([...oldData, ...response.data])]));
  } catch (err) {
    toast.error(`Ocorreu um erro ao buscar as informações dos contatos!`, {
      autoClose: 4000,
    });
  }
}

export function* setMessageCount({ payload }) {
  const prevState = yield select(newMessagesCount);
  let newState = [...prevState];
  newState.push(payload);
  yield put(updateNewMessagesCount(newState));
}

export function* clearMessageCount({ payload }) {
  const prevState = yield select(newMessagesCount);
  let newState = [...prevState.filter((item) => item !== payload)];

  yield put(updateNewMessagesCount(newState));
}

export function* addToWaitAttendantList({ payload }) {
  const prevState = yield select(waitAttendantList);
  let newState = [...prevState];

  if (!newState.includes(payload)) {
    newState.push(payload);
  }
  yield put(setWaitAttendantList(newState));
}

export function* removeToWaitAttendantList({ payload }) {
  const prevState = yield select(waitAttendantList);
  let newState = prevState.filter((item) => item !== payload);

  yield put(setWaitAttendantList(newState));
}

export function* addToRevokedList({ payload }) {
  const prevState = yield select(revokedMessages);
  const newState = [...prevState, payload];

  yield put(setRevokedMessages(newState));
}

export default all([
  takeLatest('@whatsappChat/ADD_TO_REVOKED_LIST', addToRevokedList),
  takeLatest('@whatsappChat/GET_ALL_CLIENTS_DATA', getAllClientsDataRequest),
  takeLatest(
    '@whatsappChat/GET_ALL_ACTIVE_ORDERS_DATA',
    getAllActiveOrdersDataRequest
  ),
  takeLatest('@whatsappChat/SET_MESSAGE_COUNT', setMessageCount),
  takeLatest('@whatsappChat/CLEAR_MESSAGE_COUNT', clearMessageCount),
  takeLatest(
    '@whatsappChat/REMOVE_WAIT_ATTENDANT_LIST',
    removeToWaitAttendantList
  ),
  takeLatest('@whatsappChat/ADD_WAIT_ATTENDANT_LIST', addToWaitAttendantList),
]);
