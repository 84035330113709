import { takeLatest, call, put, all, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { apiAnunciante } from '../../../services/api';

import {
  getMGMDataFailure,
  setMGMData,
} from './actions';
import {
  logout
} from '../auth/actions';

const userData = (state) => state.user.user;

export function* getMGMData() {
  try {
    const user = yield select(userData);

    const request = yield call(
        apiAnunciante.get,
        `/user-info/${user.advertiserInfo.id}/mgm`
      );

    const { refers, historic, rewards } = request.data;

    yield put(setMGMData(refers, historic, rewards))

  } catch (err) {
    if (err.response?.status === 401) {
      yield put(logout(true));
    } else {
      yield put(getMGMDataFailure())
      toast.error(`Ocorreu um erro buscar os dados do indique e ganhe. Tente novamente!`, {
        autoClose: 6000,
      });
    }
  }
}

export default all([
  takeLatest('@mgm/GET_MGM_DATA', getMGMData),
]);
