import styled from 'styled-components';

import { Form, Input } from '@rocketseat/unform';

import px2vw from '../../../utils/responsiveness/px2vw';

import {
  branco,
  cinzaKuppi1,
  cinzaKuppi3,
  cinzaKuppi6,
  cinzaKuppi4,
  verdeKuppi11,
} from '../../../styles/colors';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${branco};

  ${(props) => `
    width: ${props.width};
    height: ${props.height};
  `};
`;

export const ContentLogo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  width: 100%;
  font-family: AvenirNextLTPro;
  font-weight: normal;
  padding-left: ${px2vw(70)};
  height: ${(props) => px2vw(props.height)};
`;

export const LogoImage = styled.img`
  margin-top: ${(props) => props.margtop};
  height: ${(props) => props.height};
  width: ${(props) => props.width};
`;

export const Box = styled.div`
  background-color: ${cinzaKuppi1};
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: ${px2vw(8)};
  height: ${px2vw(35)};
  width: ${px2vw(80)};
  padding-left: ${px2vw(10)};
  padding-right: ${px2vw(10)};
  cursor: ${(props) => props.cursor || 'default'};
`;

export const LogoutButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  border: none;
  background: transparent;
  margin-right: ${px2vw(70)};
`;

export const MenuIconBox = styled.div`
  width: ${px2vw(23)};
  height: ${px2vw(23)};

  ${(props) => `
    margin-left: ${props.margleft};
    margin-right: ${props.margright};
    padding-right: ${props.padright};
    width: ${props.size};
    height: ${props.size};
  `};
`;

export const ImgBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  width: ${px2vw(100)};
  height: ${px2vw(100)};

  ${(props) => `
    margin-right: ${props.margright};
    margin-left: ${props.margleft};
    margin-top: ${props.margtop};
    margin-bottom: ${props.margbottom};
  `};

  ${(props) => props.isMobile && `
    justify-content: center;
    align-content: center;
    width: ${px2vw(1000)};
    margin-left: ${px2vw(90)};
  `};
`;

export const Img = styled.img`
  ${(props) => `
    width: ${props.width};
    height: ${props.height};
    cursor: ${props.cursor};
  `}
`;

export const OptionText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: ${cinzaKuppi6};
  font-size: ${(props) => props.fontsize || px2vw(13)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
`;

export const DataText = styled.div`
  font-size: ${px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.36px;

  ${(props) => `
    width: ${props.width};
    color: ${props.color};
    font-size: ${props.fontSize};
    margin-top: ${props.margtop};
    padding-bottom: ${props.padBot};
  `};

  ${(props) =>
    props.name &&
    `
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 30ch;
  `};
`;

export const Hr = styled.div`
  border-top: 2px solid ${cinzaKuppi4};
  height: 1px;
  width: 100%;

  ${(props) => `
    margin-top: ${props.margtop};
  `};
`;

export const GoBackButton = styled.div`
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.36px;
  cursor: pointer;

  ${(props) => `
    margin-right: ${props.margright};
    padding-left: ${props.padleft};
    margin-top: ${props.margtop};
    margin-bottom: ${props.margbottom};
    font-size: ${props.fontsize || px2vw(14)};
  `};
`;

export const QuestionTitleText = styled.div`
  font-size: ${(props) => props.fontsize || px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.36px;
  margin-right: ${(props) => props.margright};

  ${(props) => `
    margin-left: ${props.margleft};
    padding-left: ${props.padleft};
    margin-top: ${props.margtop};
  `};
`;

export const FormData = styled(Form)`
  ${(props) =>
    !props.web &&
    `
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: ${px2vw(70)};
  `};
  ${(props) => `
    width: ${props.width};
    font-size: ${props.fontSize};
  `};
`;

export const CheckboxContainer = styled.div`
  height: ${(props) => props.height || 'auto'};
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  background-color: ${verdeKuppi11};
  margin-bottom: ${(props) => px2vw(props.margbottom || 17)};
  border-radius: 5px;

  ${(props) => `
    width: ${props.width || 'auto'};
    padding: ${props.padding};
    justify-content: ${props.justifyContent || 'flex-start'};
  `};

  cursor: pointer;
`;

export const CheckbuttomContainer = styled.div`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  display: flex;
  align-items: center;
  background-color: ${branco};
  box-shadow: 0px ${px2vw(4)} ${px2vw(4)} rgba(0, 0, 0, 0.15);
  margin: ${(props) => px2vw(props.margin || 7)} 0;
  border-radius: 5px;
  padding-left: ${(props) => props.padleft};
  padding-top: ${(props) => props.padtop};
  padding-right: ${(props) => props.padright};
  margin-bottom: ${(props) => props.margbottom};

  cursor: pointer;
`;

export const InputData = styled(Input)`
  width: ${px2vw(285)};
  height: ${px2vw(70)};
  max-height: ${(props) => props.maxheight};
  font-family: AvenirNextLTPro;
  letter-spacing: 0.36px;
  border-radius: 5px;
  margin-left: ${px2vw(7)};
  margin-bottom: ${px2vw(7)};
  border: none;

  ${(props) => `
    background-color: ${props.backgroundcolor};
    box-shadow: ${props.boxshadow};
    width: ${props.width};
    height: ${props.height};
    border: ${props.border};
    padding-left: ${props.padleft};
    text-align: ${props.textalign};
    font-size: ${props.fontsize || px2vw(14)};
    margin-right: ${props.margright};
  `};

  ${(props) => props.disabled &&`
    background-color: ${cinzaKuppi4};
    color: ${cinzaKuppi3};
    border: 1px solid ${cinzaKuppi3} !important;
  `};
`;

export const InputDataTextArea = styled.textarea`
  width: ${(props) => px2vw(props.width || 280)};
  height: ${(props) => px2vw(props.height || 70)};
  max-height: ${(props) => props.maxheight};
  font-size: ${(props) => props.fontsize || px2vw(14)};
  font-family: AvenirNextLTPro;
  letter-spacing: 0.36px;
  border-radius: 5px;
  margin-left: ${px2vw(7)};
  margin-bottom: ${px2vw(7)};
  border: none;
  resize: none;

  ${(props) => `
    background-color: ${props.backgroundcolor};
    box-shadow: ${props.boxshadow};
    width: ${props.width};
    height: ${props.height};
    border: ${props.border};
    padding-left: ${props.padleft};
  `};
`;

export const SubText = styled.div`
  font-size: ${px2vw(12)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.36px;

  ${(props) => `
    font-size: ${props.fontsize};
    font-weight:${props.fontweight};
  `};
`;
