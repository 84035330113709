import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Progress } from 'reactstrap';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';
import {
  AccordionDetails,
  AccordionSummary,
  IconButton,
  InputAdornment,
  TextField,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import {
  Container,
  BookIcon,
  ProgressBar,
  StepTittle,
  useStyles,
  ProductItem,
  ProductDescription,
  ProductImg,
  ProductPrice,
  inputTheme,
  ModalFooter,
  BagIcon,
  CustomizedBadge,
  Span,
} from './styles';
import { cinzaKuppi10, verdeKuppi5 } from '../../../styles/colors';
import { Row, Col } from '../../../styles/grid';

import Accordion from './Accordion';

import { amountMask } from '../../../utils/masks';
import px2vw from '../../../utils/responsiveness/px2vw';
import { formatCurrencyBRL } from '../../../utils/utils';

import {
  setCurrProductViewDetails,
  setCurrStep,
} from '../../../store/modules/manualOrder/actions';

export default function CatalogData(props) {
  const classes = useStyles();
  const theme = inputTheme();
  const manualOrderData = useSelector((state) => state?.manualOrder);
  const previousStep = useSelector(
    (state) => state.manualOrder?.previousStep
  );
  const bagData = manualOrderData.bag;
  const dispatch = useDispatch();
  const categoriesData = manualOrderData?.productCategory?.map(
    (category) => category
  );
  const productsData = manualOrderData?.products;

  const [opennedCategory, setOpennedCategory] = useState(null);
  const [productSearchName, setProductSearchName] = useState('');
  const [searchedProducts, setSearchedProducts] = useState([]);

  const productsToRender =
    searchedProducts.length && productSearchName.length
      ? searchedProducts
      : categoriesData;

  useEffect(() => {
    if (productSearchName !== '') {
      const productsSearchedArr = categoriesData.map((category) => {
        // For each product in the category, search the products
        const currCatFindedProducts = category.products.filter((product) => {
          if (productSearchName) {
            if (
              product.name
                .toLowerCase()
                .includes(productSearchName.toLowerCase())
            ) {
              return product;
            }
          }
        });

        if (currCatFindedProducts) {
          return { ...category, products: currCatFindedProducts };
        }
      });

      setSearchedProducts(
        productsSearchedArr.filter((item) => item.products.length)
      );
    }
  }, [productSearchName]);

  function handleViewProductDetails(product) {
    dispatch(setCurrProductViewDetails(product));
    dispatch(setCurrStep('2-1', previousStep));
  }

  function handleViewBag() {
    dispatch(setCurrStep('2-2', previousStep));
  }

  const handleOpennedCategory = (category_id, e) => {
    e.stopPropagation();
    // Force null at openned category if is the same category, force closing
    const newCategoryId = category_id === opennedCategory ? null : category_id;
    setOpennedCategory(newCategoryId);
  };

  function handleOpenDetailForSelectedProduct(productName) {
    let productData = null;

    categoriesData.forEach((category) => {
      // For each product in the category, search the products
      category.products.filter((product) => {
        if (product.name.toLowerCase() === productName.toString().toLowerCase()) {
          productData = product;
        }
      });
    });

    if (productData) {
      handleViewProductDetails(productData);
    }
  }

  return (
    <>
      <ProgressBar>
        <Progress
          value={40}
          bar={true}
          style={{
            backgroundColor: verdeKuppi5,
            borderRaduis: '0px',
            height: '10px',
          }}
        />
      </ProgressBar>
      <Row alignEnd className="px-4 my-4 pb-1">
        <BookIcon src={require('../../../assets/images/book/book.png')} />
        <StepTittle>Cardápio simplificado</StepTittle>

        <Autocomplete
          id="grouped-search"
          options={productsData ? productsData : []}
          groupBy={(options) => options.name}
          getOptionLabel={(option) => option.product_name}
          onChange={(e, v, r) => {
            if (r === 'select-option') {
              handleOpenDetailForSelectedProduct(v.product_name)
            }
          }}
          classes={theme}
          renderInput={(params) => (
            <TextField
              {...params}
              type="search"
              placeholder="Busque um produto"
              onChange={(e) => setProductSearchName(e.target.value)}
              onSelect={(e) => setProductSearchName(e.target.value)}
              value={productSearchName}
              InputProps={
                productSearchName === ''
                  ? {
                      ...params.InputProps,
                      disableUnderline: true,
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton size="small">
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }
                  : {
                      ...params.InputProps,
                      disableUnderline: true,
                      endAdornment: false,
                    }
              }
            />
          )}
        />
      </Row>
      <Container>
        {productsToRender?.length > 0 ? (
          productsToRender?.map((category, index) => (
            <Accordion
              className={classes.root}
              TransitionProps={{
                in: opennedCategory === category.id,
                timeout: 5000,
              }}
              onChange={(e) => handleOpennedCategory(category.id, e)}
              key={`${category.id}-${index}`}
            >
              <AccordionSummary
                className={classes.accordTittle}
                expandIcon={<ExpandMoreIcon />}
              >
                {category.name}
              </AccordionSummary>
              <AccordionDetails className={classes.accordSon}>
                <Col>
                  {category.products?.map((product) => (
                    <ProductItem
                      key={`${product.id}-${index}`}
                      onClick={() => handleViewProductDetails(product)}
                    >
                      <ProductImg
                        src={require('../../../assets/images/camera/camera.png')}
                      />
                      <ProductDescription>{product.name}</ProductDescription>
                      <ProductPrice>
                        {product.amount !== 0 &&
                          amountMask(Math.round(product.amount * 100))}
                      </ProductPrice>
                    </ProductItem>
                  ))}
                </Col>
              </AccordionDetails>
            </Accordion>
          ))
        ) : (
          <Row>
            <Span fontWeight="300">
              Não existem produtos cadastrados
            </Span>
          </Row>
        )}
      </Container>
      {bagData.sizeBag > 0 && (
        <ModalFooter className="mt-auto px-3" onClick={() => handleViewBag()}>
          <Col className="ml-4">
            <CustomizedBadge badgeContent={bagData.sizeBag}>
              <BagIcon src={require('../../../assets/images/bag/bag.png')} />
            </CustomizedBadge>
          </Col>
          <Col alignCenter>
            <Span
              cursor="pointer"
              className="m-0"
              textAlign="center"
              fontSize={px2vw(18)}
              color={cinzaKuppi10}
            >
              Ver Sacola
            </Span>
          </Col>
          <Col alignEnd className="mr-4">
            <Span
              className="m-0"
              textAlign="center"
              fontSize={px2vw(16.5)}
              color={cinzaKuppi10}
            >
              {formatCurrencyBRL(bagData.priceOfTheWholeBag, 2)}
            </Span>
          </Col>
        </ModalFooter>
      )}
    </>
  );
}
