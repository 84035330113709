import React from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import { Spinner } from 'reactstrap';

import { LoadingBox } from '../../../../styles/common';

import { Row, Col } from '../../../../styles/grid';
import { preto, cinzaKuppi1 } from '../../../../styles/colors';
import { FormBox, NormalText, PageTitle } from '../../BaseScreen/styles';
import {
  ChoiceButton,
  ChoiceButtonSelected,
  PrintTypeButtonText,
  PrintTypeBoxDetail,
  PrintTypeBoxDetailText,
  PrintTypeInput,
} from '../styles';

import { printOrderInvoice } from '../../../../utils/printOrderInvoice/PrintOrderInvoice';
import px2vw from '../../../../utils/responsiveness/px2vw';
import { onlyNumbers } from '../../../../utils/utils';

import ButtonPrimary from '../../../../components/ButtonPrimary';

export default function PrintConfigsWeb({ handler }) {
  const fakePrintData = {
    order: {
      address: {
        city: 'Rio de Janeiro',
        complement: 'Apto 10 Bloco C',
        neighborhood: 'Barra da Tijuca',
        number: '900',
        state: 'RJ',
        street: 'Rua Mário Covas Junior',
        zipcode: '22631030',
      },
      amount: 23.7,
      change: 0,
      clientInfo: {
        name: 'Cliente Teste',
        phone: '5521999966660',
      },
      delivery_fee: 3,
      delivery_type: 'delivery',
      discount: 0,
      id: 1,
      orderProduct: [
        {
          obs: null,
          orderProductComplements: [
            {
              complements: [
                {
                  amount: null,
                  name: 'Nome Complemento 1',
                  quantity: 1,
                },
              ],
            },
          ],
          product: {
            amount: 7.9,
            name: 'Nome Produto 1',
          },
          quantity: 3,
        },
      ],
      order_type: 'immediate',
      paymentMethod: {
        name: 'Mastercard Débito',
      },
      total_amount: 26.7,
    },
    new_at: moment(new Date()).format('DD/MM/YYYY HH:mm'),
  };

  const advertiserPrintData = {
    advertiserInfo: {
      name: handler.user.advertiserInfo.name,
      cpf_cnpj: handler.user.advertiserInfo.cpf_cnpj,
      advertiserPrintConfig: {
        print_type: handler.selectedPrintType,
        max_line_chars: handler.selectedMaxCharLine,
      },
    },
    address: [
      {
        street: handler.user.address[0].street,
        number: handler.user.address[0].number,
        complement: handler.user.address[0].complement,
        neighborhood: handler.user.address[0].neighborhood,
        city: handler.user.address[0].city,
        state: handler.user.address[0].state,
        zipcode: handler.user.address[0].zipcode,
        phone_1: handler.user.address[0].phone_1,
        is_visible_on_web_menu: handler.user.address[0].is_visible_on_web_menu,
      },
    ],
  };

  return (
    <Col
      background={cinzaKuppi1}
      padbottom={px2vw(55)}
      borderRadius={
        !handler.hasFreeTrialValid && px2vw(60)
      }
    >
      <Row
        margtop={px2vw(28)}
        margleft={px2vw(35)}
        margbottom={px2vw(15)}
        justifyStart
        width="auto"
      >
        <PageTitle>Configuração de impressão</PageTitle>
      </Row>
      <Row alignStart>
        <FormBox
          web
          width={px2vw(500)}
          height={px2vw(585)}
          margleft={px2vw(-10)}
          margright={px2vw(20)}
          onSubmit={handler.handleFinishPrintConfigsStep}
        >
          <Col justifyStart height="auto">
            <PageTitle fontsize={px2vw(20)}>
              Configuração de impressão
            </PageTitle>
            <Row justifyStart margtop={px2vw(8)} height="auto">
              <Row
                cursor="pointer"
                width="auto"
                height="auto"
                onClick={() => handler.setSelectedPrintType('html')}
              >
                <Row relative width="auto" height="auto">
                  <ChoiceButton />
                  <ChoiceButtonSelected
                    isChecked={handler.selectedPrintType === 'html'}
                  />
                </Row>
                <Col margtop={px2vw(-3)} width="auto" height="auto">
                  <PrintTypeButtonText>HTML</PrintTypeButtonText>
                </Col>
              </Row>
              <Row
                cursor="pointer"
                width="auto"
                height="auto"
                margleft={px2vw(25)}
                onClick={() => handler.setSelectedPrintType('plain_text')}
              >
                <Row relative width="auto" height="auto">
                  <ChoiceButton />
                  <ChoiceButtonSelected
                    isChecked={handler.selectedPrintType === 'plain_text'}
                  />
                </Row>
                <Col margtop={px2vw(-3)} width="auto" height="auto">
                  <PrintTypeButtonText>Plain Text</PrintTypeButtonText>
                </Col>
              </Row>
            </Row>
            <Row justifyStart height={px2vw(25)} minheight={px2vw(25)}>
              {handler.selectedPrintType === 'plain_text' && (
                <>
                  <PrintTypeButtonText margtop={px2vw(16)}>
                    Quant. máx. de colunas por linha:
                  </PrintTypeButtonText>
                  <PrintTypeInput
                    name="qtdMaxCharLine"
                    maxLength="2"
                    value={handler.selectedMaxCharLine}
                    placeholder="47"
                    onBlur={(e) => {
                      if (handler.selectedMaxCharLine < 36) {
                        handler.setSelectedMaxCharLine(36);
                        toast.warn(
                          'Para o tipo de impressão "Plain Text", o campo de quant. máx. de colunas por linha não pode ser menor que 36. O valor recomendado para a maioria das impressoras é 47.',
                          { autoClose: 6000 }
                        );
                      }
                    }}
                    onChange={(t) =>
                      handler.setSelectedMaxCharLine(
                        onlyNumbers(t.target.value)
                      )
                    }
                  />
                </>
              )}
            </Row>
            <PrintTypeBoxDetail>
              {handler.selectedPrintType === 'html' && (
                <PrintTypeBoxDetailText>
                  A impressão em HTML, vai fazer a sua notinha ficar mais
                  bonita, com textos em negrito (para dar destaque), tamanhos
                  diferenciados de fontes, etc. Porém nem sempre as impressoras
                  entendem esse formato e acabam imprimindo todas as informações
                  de uma forma bem confusa. Caso isso ocorra, escolha o tipo de
                  impressão "Plain Text".
                </PrintTypeBoxDetailText>
              )}
              {handler.selectedPrintType === 'plain_text' && (
                <PrintTypeBoxDetailText>
                  A impressão em Plain Text, não tem nenhum tipo de estilização,
                  porém os dados são formatos da melhor forma, afim de que a
                  notinha seja impressa com todas as informações visíveis para o
                  usuário. Algumas impressoras somente permitem esse tipo de
                  impressão.
                </PrintTypeBoxDetailText>
              )}
            </PrintTypeBoxDetail>
            <PageTitle fontsize={px2vw(20)} margtop={px2vw(30)}>
              Configurações ideais de impressão:
            </PageTitle>
            <NormalText margtop={px2vw(10)}>Páginas: Tudo</NormalText>
            <NormalText margtop={px2vw(5)}>
              Tamanho do papel: 80mm x 30
            </NormalText>
            <NormalText margtop={px2vw(5)}>Páginas por folha: 1</NormalText>
            <NormalText margtop={px2vw(5)}>Margens: mínima</NormalText>
            <NormalText margtop={px2vw(5)}>
              Desmarcar: cabeçalhos e rodapés
            </NormalText>
            <Row width="auto" height="auto">
              <PageTitle
                fontsize={px2vw(15)}
                margtop={px2vw(20)}
                margright={px2vw(5)}
              >
                Obs:
              </PageTitle>
              <NormalText margtop={px2vw(21)} textalign="left">
                Se a sua impressora não tiver essas opções, altere a
                configuração para Plain Text e faça o teste com 44 linhas.
              </NormalText>
            </Row>
            <Row margtop={px2vw(30)}>
              <ButtonPrimary
                type="button"
                onClick={() =>
                  printOrderInvoice({
                    orderStatusData: fakePrintData,
                    advertiserData: advertiserPrintData,
                  })
                }
              >
                Testar impressão
              </ButtonPrimary>
            </Row>
          </Col>
        </FormBox>
        <Col alignCenter width="auto" height={px2vw(550)}>
          <FormBox web width={px2vw(500)} height={px2vw(150)}>
            <Col justifyStart>
              <PageTitle fontsize={px2vw(20)}>
                Configurações de pedidos
              </PageTitle>

              <Row
                cursor="pointer"
                width="auto"
                height="auto"
                margtop={px2vw(8)}
                onClick={handler.setSelectedAlwaysPrintOrder}
              >
                <Row relative width="auto" height="auto">
                  <ChoiceButton />
                  <ChoiceButtonSelected
                    isChecked={handler.selectedAlwaysPrintOrder}
                  />
                </Row>
                <Col margtop={px2vw(-3)} width="auto" height="auto">
                  <PrintTypeButtonText>
                    Sempre imprimir o pedido ao aceitá-lo
                  </PrintTypeButtonText>
                </Col>
              </Row>
              <Row
                cursor="pointer"
                width="auto"
                height="auto"
                margtop={px2vw(10)}
                onClick={handler.setSelectedAlwaysSendWhatsappMsg}
              >
                <Row relative width="auto" height="auto">
                  <ChoiceButton />
                  <ChoiceButtonSelected
                    isChecked={handler.selectedAlwaysSendWhatsappMsg}
                  />
                </Row>
                <Col margtop={px2vw(-3)} width="auto" height="auto">
                  <PrintTypeButtonText>
                    Sempre enviar mensagem sobre o status do pedido no whatsapp,
                    ao aceitar o pedido e sair para entrega/ficar pronto para
                    retirada
                  </PrintTypeButtonText>
                </Col>
              </Row>
            </Col>
          </FormBox>
          <Col width="auto" margbottom={px2vw(20)} justifyEnd>
            <ButtonPrimary
              type="submit"
              minWidth={px2vw(100)}
              onClick={handler.handleFinishPrintConfigsStep}
            >
              {handler.printConfigsLoading ||
              handler.updateOnboardingStepLoading ? (
                <LoadingBox>
                  <Spinner
                    style={{
                      width: '1.8rem',
                      height: '1.8rem',
                      color: preto,
                    }}
                  />{' '}
                </LoadingBox>
              ) : (
                'Concluir'
              )}
            </ButtonPrimary>
          </Col>
        </Col>
      </Row>
    </Col>
  );
}
