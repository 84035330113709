import styled, { css } from 'styled-components';
import {
  branco,
  cinzaKuppi2,
  cinzaKuppi4,
  cinzaKuppi6,
  cinzaKuppi7,
  preto,
  verdeKuppi1,
  verdeKuppi2,
} from '../../../styles/colors';
import px2vw from '../../../utils/responsiveness/px2vw';
import { Select } from '@rocketseat/unform';
import { Col } from '../../../styles/grid';

export const ModalBackground = styled.div`
  display: none;
  position: fixed;
  z-index: 60;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: ${cinzaKuppi6}99;

  ${(props) =>
    props.isVisible &&
    `
    display: flex;
    justify-content: center;
    align-items: center;
  `};
`;

export const ModalContent = styled.div`
  position: relative;
  background-color: ${branco};
  border-radius: 20px;
  box-shadow: 2px 2px 3px transparent;
  padding: ${px2vw(15)} ${px2vw(35)};
  ${(props) => `
    width: ${props.width};
    min-height: ${props.height};
    max-height: ${props.maxheight};
    background-color: ${props.bgcolor};
    margin-top: ${props.margtop};
  `};
`;

export const ModalPrimaryButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${branco};
  border: none;
  background-color: ${verdeKuppi2};
  border-radius: ${px2vw(7)};
  font-size: ${(props) => props.fontSize || px2vw(13)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.015em;
  padding: ${px2vw(8)};
  box-shadow: 2px 2px 3px ${cinzaKuppi2};
  width: ${(props) => (props.width ? props.width : px2vw(155))};
  height: ${(props) => (props.height ? props.height : px2vw(37))};

  margin-top: ${(props) => (props.margtop ? props.margtop : 0)};
  margin-right: ${(props) => (props.margright ? props.margright : 0)};
  margin-left: ${(props) => (props.margleft ? props.margleft : 0)};

  &:active {
    box-shadow: 2px 2px 3px ${cinzaKuppi2};
    background: ${verdeKuppi1};
  }
  ${(props) =>
    props.disabled &&
    `
  background-color: ${cinzaKuppi4};
  color:${branco};

  &:active {
    box-shadow: 2px 2px 3px ${cinzaKuppi2};
    background: ${cinzaKuppi4};
  }

  `}
`;

export const ModalHeader = styled.div`
  width: 100%;
  background-color: ${branco};
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding-bottom: ${px2vw(5)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  font-size: ${px2vw(25)};
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`;

export const CloseButton = styled.button`
  width: ${px2vw(23)};
  height: ${px2vw(23)};
  border: none;
  z-index: 10;
  background-color: transparent;

  :focus {
    background-color: transparent !important;
  }

  ${(props) =>
    props.absolute &&
    css`
      position: absolute;
      top: ${props.top || px2vw(10)};
      right: ${props.right || px2vw(10)};
    `}
`;

export const CloseImage = styled.img`
  width: ${px2vw(19)};
  height: ${px2vw(19)};
`;

export const SelectInputBox = styled(Select)`
  width: ${(props) => props.width || px2vw(40)};
  height: ${(props) => props.height || px2vw(29)};
  font-size: ${(props) => props.ftsize || px2vw(14)};
  border-radius: ${px2vw(8)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  display: flex;
  align-items: center;
  padding: ${px2vw(7)};
  letter-spacing: 0.36px;
  border: 2px solid ${cinzaKuppi4};

  ${(props) => `
    margin-left: ${props.margleft};
    margin-bottom: ${props.margbottom};
    padding-left: ${props.padleft};
    padding-right: ${props.padright};
  `};

  ${(props) =>
    props.isMobile &&
    `
    width: 100%;
    height: ${px2vw(150)};
    font-size: ${px2vw(50)};
    border-radius:${px2vw(30)};
    border: 1px solid ${cinzaKuppi4};
    margin-left: ${props.margleft};
    margin-bottom: ${props.margbottom};
    padding-left: ${px2vw(50)};
    padding-right: ${props.padright};
    background-color: ${branco}
  `};
`;

export const SearchInput = styled.input`
  border-radius: ${px2vw(8)};
  width: 100%;
  font-size: ${px2vw(12)};
  height: ${px2vw(36)};
  border: 2px solid ${cinzaKuppi4};
  padding-left: ${px2vw(35)};
  padding-right: ${px2vw(15)};
  &::placeholder {
    font-weight: 600;
    font-size: ${px2vw(14)};
    color: ${cinzaKuppi7};
  }
  selector::-webkit-search-cancel-button {
    content: 'x';
    background-color: red;
    /* ... */
  }
`;

export const SearchInputStyle = {
  position: 'absolute',
  left: px2vw(12),
  fontSize: px2vw(15),
  fontWeight: 700,
  color: cinzaKuppi7,
};

export const SearchInputClearStyle = {
  position: 'absolute',
  right: px2vw(12),
  fontSize: px2vw(15),
  fontWeight: 700,
  color: cinzaKuppi7,
  cursor: 'pointer',
};

export const Hr = styled.hr`
  width: 98%;
  height: ${px2vw(0.5)};
  background: ${cinzaKuppi4};
  margin-bottom: 0;
`;

export const ColOverFlow = styled(Col)`
  ::-webkit-scrollbar {
    width: 0.3em;
    border-radius: 3px;
  }
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${verdeKuppi2};
    outline: 1px solid ${verdeKuppi2};
  }
`;

export const AmountInput = styled.input`
  border-radius: ${px2vw(8)};
  width: ${px2vw(68)};
  font-size: ${px2vw(13)};
  height: ${px2vw(32)};
  border: 2px solid ${cinzaKuppi4};
  text-align: flex;

  padding-left: ${px2vw(20)};
  &::placeholder {
    font-weight: 600;
    font-size: ${px2vw(14)};
    color: ${cinzaKuppi7};
  }
`;

export const PrimaryButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${branco};
  border: none;
  background-color: ${verdeKuppi2};
  border-radius: ${px2vw(7)};
  font-size: ${(props) => props.fontSize || px2vw(13)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.015em;
  padding: ${px2vw(8)};
  box-shadow: 2px 2px 3px ${cinzaKuppi2};
  width: ${(props) => (props.width ? props.width : px2vw(139))};
  height: ${(props) => (props.height ? props.height : px2vw(35))};

  margin-top: ${(props) => (props.margtop ? props.margtop : 0)};
  margin-right: ${(props) => (props.margright ? props.margright : 0)};

  &:active {
    box-shadow: 2px 2px 3px ${cinzaKuppi2};
    background: ${verdeKuppi1};
  }
  ${(props) =>
    props.disabled &&
    `
  background-color: ${cinzaKuppi4};
  color:${branco};

  &:active {
    box-shadow: 2px 2px 3px ${cinzaKuppi2};
    background: ${cinzaKuppi4};
  }

  `}
`;

export const SecundaryButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${preto};
  background: ${branco};
  border: 3px solid ${preto};
  border-radius: ${px2vw(7)};
  font-size: ${(props) => props.fontSize || px2vw(13)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.015em;
  padding: ${px2vw(8)};
  box-shadow: 2px 2px 3px ${cinzaKuppi2};
  width: ${(props) => (props.width ? props.width : px2vw(139))};
  height: ${(props) => (props.height ? props.height : px2vw(35))};

  margin-top: ${(props) => (props.margtop ? props.margtop : 0)};
  margin-right: ${(props) => (props.margright ? props.margright : 0)};

  &:active {
    box-shadow: 2px 2px 3px ${cinzaKuppi2};
    background: ${cinzaKuppi2};
  }
  ${(props) =>
    props.disabled &&
    `
  background-color: ${cinzaKuppi4};
  border: 3px solid ${cinzaKuppi2};
  color:${branco};

  &:active {
    box-shadow: 2px 2px 3px ${cinzaKuppi2};
    background: ${cinzaKuppi4};
  }

  `}
`;
