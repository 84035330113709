import styled from 'styled-components';

import { branco, cinzaKuppi1 } from '../../styles/colors';
import { Button } from '../../styles/common';
import px2vw from '../../utils/responsiveness/px2vw';

export const Container = styled.div`
  z-index: 2;
  position: relative;
  bottom: ${px2vw(15)};
  transition: all 0.2s ease-in-out;
  left: 0px;
  width: ${px2vw(80)};
`;

export const Select = styled.div`
  background: ${branco};
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.15) !important;
  border-radius: 2px;
  margin-left: ${px2vw(10)};

  .options {
    transition: all 0.2s ease-in-out;

    ${(props) =>
      !props.selectedAll
        ? `
          opacity: 0;
          height: 0;
          padding: 0;
      `
        : `
          height: 100%;
          opacity: 1;
      `}
    :hover {
      background: ${cinzaKuppi1} !important;
    }
  }
`;

export const Text = styled.div`font-size:${px2vw(13)}; margin-bottom: ${(props) => props.marginBottom};`;

export const Content = styled(Button)`
  padding: ${px2vw(3)} ${px2vw(6)};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const IconBox = styled.div`
  position: relative;
  width: ${px2vw(20)};
  height: ${px2vw(20)};
  margin-left: auto;
  bottom: ${px2vw(3)};
  margin-top: ${(props) => props.iconMarginTop};

  i {
    transition: all 0.2s ease-in-out;
    ${(props) => props.selectedAll && `transform: rotate(180deg);`}
  }
`;
