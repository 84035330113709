import produce from 'immer';

const INITIAL_STATE = {
  clients: [],
  clientsMetrics: {},
  getClientsLoading: false,
  getClientDetailsLoading: false,
  getClientsMetricsLoading: false,
  error: false,
  clientDetails: {}
};

export default function clients(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@clients/GET_CLIENTS_REQUEST': {
        draft.getClientsLoading = true;
        draft.clients = [];
        draft.error = false;
        break;
      }
      case '@clients/GET_CLIENTS_SUCCESS': {
        draft.getClientsLoading = false;
        if (action.payload.clients !== '') {
          draft.clients = action.payload.clients;
        }
        draft.error = false;
        break;
      }
      case '@clients/GET_CLIENTS_FAILURE': {
        draft.getClientsLoading = false;
        draft.clients = [];
        draft.error = true;
        break;
      }
      case '@clients/GET_CLIENTS_METRICS_REQUEST': {
        draft.getClientsMetricsLoading = true;
        draft.clientsMetrics = {};
        draft.error = false;
        break;
      }
      case '@clients/GET_CLIENTS_METRICS_SUCCESS': {
        draft.getClientsMetricsLoading = false;
        if (action.payload.clientsMetrics !== '') {
          draft.clientsMetrics = action.payload.clientsMetrics;
        }
        draft.error = false;
        break;
      }
      case '@clients/GET_CLIENTS_METRICS_FAILURE': {
        draft.getClientsMetricsLoading = false;
        draft.clientsMetrics = {};
        draft.error = true;
        break;
      }
      case '@clients/GET_CLIENTS_DETAILS_REQUEST': {
        draft.getClientDetailsLoading = true;
        draft.clientDetails = null;
        draft.error = false;
        break;
      }
      case '@clients/GET_CLIENTS_DETAILS_SUCCESS': {
        draft.getClientDetailsLoading = false;
        draft.clientDetails = action.payload.clientDetails;
        draft.error = false;
        break;
      }
      case '@clients/GET_CLIENTS_DETAILS_FAILURE': {
        draft.getClientDetailsLoading = false;
        draft.clientDetails = null;
        draft.error = true;
        break;
      }
      default:
    }
  });
}
