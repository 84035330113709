import React, { useEffect } from 'react';
import {  preto } from '../../../../styles/colors';
import { Text } from '../../../../styles/common';
import px2vw from '../../../../utils/responsiveness/px2vw';
import { ButtonPrimary } from '../../CampaignDetailModal/styles';
import { SecundaryButton } from '../CampaignTable/styles';
import { useDispatch, useSelector } from 'react-redux';
import { cancelMassMessageCampaign, cancelMassMessageCampaignClear } from '../../../../store/modules/massMessage/actions';
import { Container, Content, Footer, HSpacer, Header, ModalOutline } from './styles';
import { Row } from '../../../../styles/grid';
import { Spinner } from 'reactstrap';


export const ConfirmCancelModal = ({ onClose = () => {} }) => {
  const { selectedCampaign, isCancelLoading, isCancelSuccess } = useSelector((state) => state.massMessage);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isCancelSuccess) {
      dispatch(cancelMassMessageCampaignClear())
      onClose();
    }
  }, [isCancelSuccess])

  const handleConfirm = () => {
    dispatch(cancelMassMessageCampaign(selectedCampaign?.campaignDetails?.id));
  };

  return (
    <>
      <ModalOutline />
      <Container>
        <Header>
          <Text  size={px2vw(18)}>
            Tem certeza que deseja cancelar a campanha?
          </Text>
        </Header>
        <Content>
          <Text regular size={px2vw(10)}>
            {['paid'].includes(
              selectedCampaign?.campaignDetails?.status
            )
              ? 'Como a sua campanha ainda não iniciou, o valor investido será estornado para a sua conta de forma automática sem a taxa do processamento do pagamento.'
              : 'Como a sua campanha já foi iniciada, caso você cancele nós estornaremos o valor proporcional à quantidade ainda não enviada sem a taxa do processamento do pagamento.'}
          </Text>
        </Content>
        <Footer>
          <ButtonPrimary
            className="mx-auto"
            width={px2vw(150)}
            size={px2vw(13)}
            height={px2vw(30)}
            onClick={onClose}
          >
            Fechar
          </ButtonPrimary>
          <HSpacer />
          <SecundaryButton
            position="initial"
            width={px2vw(150)}
            size={px2vw(13)}
            height={px2vw(30)}
            onClick={handleConfirm}
          >
            {isCancelLoading ? (
            <Row height="80vh">
              <Spinner
                style={{
                  margin: 'auto',
                  width: '1.8rem',
                  height: '1.8rem',
                  color: preto,
                }}
              />
            </Row>
          ) : (
            'Confirmar'
          )}
          </SecundaryButton>
        </Footer>
      </Container>
    </>
  );
};

