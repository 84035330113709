import styled, { css, keyframes } from 'styled-components';

import { branco, cinzaKuppi2, cinzaKuppi4, cinzaKuppi6, preto2, verdeKuppi1, verdeKuppi2 } from '../../../../styles/colors';

import px2vw from '../../../../utils/responsiveness/px2vw';

const imgFadeIn = keyframes`
  to {
    opacity: 1;
    transform: initial;
  }
`;

export const ModalBackground = styled.div`
  display: none;
  position: fixed;
  z-index: 20;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: ${cinzaKuppi6}99;

  ${(props) =>
    props.isVisible &&
    `
    display: flex;
    justify-content: center;
    align-items: center;
  `};
`;

export const ModalContent = styled.div`
  background-color: ${branco};
  padding: ${(props) => props.padding || px2vw(20)};
  padding-bottom: ${px2vw(15)};
  border-radius: 20px;

  ${(props) => `
    width: ${props.width};
    min-height: ${props.height};
    max-height: ${props.maxheight};
    background-color: ${props.bgcolor};
    margin-top: ${props.margtop};
  `};
`;

export const AnimatedImg = styled.img`
  opacity: 0;
  transform: rotate(45deg) scale(0.5);
  animation: ${imgFadeIn} 0.7s forwards;
  width: ${px2vw(250)};
  height: ${px2vw(250)};
`;

export const ModalTitleText = styled.div`
  font-size: ${px2vw(25)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.48px;
  text-align: center;

  ${(props) => `
    padding-left: ${props.paddingLR};
    padding-right: ${props.paddingLR};
    margin-top: ${props.margtop};
    font-size: ${props.fontsize};
  `};
`;

export const CloseButton = styled.button`
  width: ${px2vw(18)};
  height: ${px2vw(18)};
  border: none;
  z-index: 10;
  background-color: transparent;

  :focus {
    background-color: transparent !important;
  }

  ${(props) =>
    props.absolute &&
    css`
      position: absolute;
      top: ${props.top || px2vw(10)};
      right: ${props.right || px2vw(10)};
    `}

  ${(props) =>
    props.mobile &&
    `
    width: ${px2vw(180)};
    height: ${px2vw(180)};
  `}
`;

export const CloseImage = styled.img`
  width: ${(props) => props.width || px2vw(20)};
  height: ${(props) => props.height || px2vw(20)};
  padding-right: ${(props) => props.margright};

  ${(props) =>
    props.isMobile &&
    `
    width: ${px2vw(70)};
    height: ${px2vw(70)};
  `};
`;

export const ModalHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 ${px2vw(20)};
  ${(props) =>
    props.isMobile &&
    `
    padding-right: ${px2vw(90)};
  `};
`;

export const TextArea = styled.textarea`
  width: 100%;
  font-size: ${px2vw(10)};
  height: ${px2vw(130)};
  resize: none;
  border-radius: 8px;
  border-color: ${cinzaKuppi4};
  padding: ${px2vw(10)} ${px2vw(15)};
`;

export const TextBox = styled.div`
  background: ${branco};
  font-size: ${px2vw(8)};
  width: auto;
  min-width: ${px2vw(25)};
  max-width: ${px2vw(240)};
  height: auto;
  min-height: ${px2vw(30)};
  padding: ${px2vw(9)} ${px2vw(3)};
  padding-left: ${px2vw(10)};
  z-index: 5;
  white-space: pre-wrap;
  word-wrap: break-word;
  position: relative;
  border-top-left-radius: ${(props) => (props.first ? 'none' : px2vw(8))};
  border-bottom-left-radius: ${px2vw(8)};
  border-top-right-radius: ${px2vw(8)};
  border-bottom-right-radius: ${px2vw(8)};
  margin-top: ${(props) => props.margtop || 0};
  padding-right: ${(props) => props.padright || 0};
`;

export const PhoneImg = styled.img`
  width: ${px2vw(300)};
`;

export const ConversationIndicatorImg = styled.img`
  position: absolute;
  left: ${px2vw(-12)};
  top: ${px2vw(0)};
`;

export const Text = styled.div`
  font-size: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: AvenirNextLTPro;
  font-weight: 700;
  ${(props) => props.borderbottom && `border-bottom: 2px solid ${cinzaKuppi4};`}
  ${(props) => props.margleft && `margin-left: ${props.margleft};`}
  ${(props) => props.margbottom && `margin-bottom: ${props.margbottom};`}
  ${(props) => props.margtop && `margin-top: ${props.margtop};`}
  ${(props) => props.margright && `margin-right: ${props.margright};`}
  ${(props) => props.padleft && `padding-left: ${props.padleft};`}
  ${(props) => props.padbottom && `padding-bottom: ${props.padbottom};`}
  ${(props) => props.padtop && `padding-top: ${props.padtop};`}
  ${(props) => props.padleft && `padding-left: ${props.padleft};`}
  ${(props) => props.size && `font-size: ${props.size};`}
  ${(props) => props.color && `color: ${props.color};`}
  ${(props) => props.txtOverFlow && `text-overflow: ellipsis;`}
  ${(props) => props.margin && `margin: ${props.margin};`}
  ${(props) => props.italic && `font-style: italic;`}
  ${(props) =>
    props.bold &&
    `font-family: AvenirNextLTPro;
  font-weight: 700;`}
  ${(props) =>
    props.regular &&
    `font-family: AvenirNextLTPro;
  font-weight: normal;`}
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : 'initial')};
  ${(props) => props.alignCenter && `text-align: center;`}
  ${(props) => props.weight && `font-weight: ${props.weight};`}
  ${(props) => props.justifyStart && `justify-content: flex-start;`}
  ${(props) =>
    props.wrap &&
    `
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  `}
  ${(props) =>
    props.fitLetter &&
    `
      font-style: normal;
      line-height: 150%;
      text-align: center;
      letter-spacing: 0.02em;
  `}
  ${(props) =>
    props.roboto &&
    `
      font-family: Roboto;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
      letter-spacing: 0.05em;
    `}

  width: ${(props) => props.width || 'auto'};
`;
