import React from 'react';
import { useSelector } from 'react-redux';

import { Row } from '../../../../styles/grid';
import { ConfigGroupTitle } from '../../styles';

import px2vw from '../../../../utils/responsiveness/px2vw';

import Modal from '../../../../components/Modal';
import { CloseButton, CloseImage } from '../../../../components/Modal/styles';
import TransferDetailsTable from './TransferDetailsTable';
import closeImg from '../../../../assets/images/close/close.png';

export default function TransferDetailsModal({ visible, onClose }) {
  const { transferTransactions } = useSelector((state) => state.financial);

  return (
    <Modal
      isVisible={visible}
      width={px2vw(1000)}
      height="auto"
      padding={px2vw(10)}
      zIndex={999}
    >
      <Row spaceBetween margtop={px2vw(20)} padleft={px2vw(20)} padright={px2vw(20)}>
        <ConfigGroupTitle>Transações do Repasse</ConfigGroupTitle>
        <CloseButton
          onClick={() => onClose()}
        >
          <CloseImage src={closeImg} />
        </CloseButton>
      </Row>
      <Row margleft={px2vw(17)} margright={px2vw(17)} margbottom={px2vw(17)}  width={px2vw(950)}>
        <TransferDetailsTable data={transferTransactions} />
      </Row>
      <Row>
        * Os valores recebidos negativos, representam débitos referentes a pedidos que foram cancelados após o repasse já ter sido feito para a sua conta bancária
      </Row>
    </Modal>
  );
};
