import React from 'react';

import px2vw from '../../../../utils/responsiveness/px2vw';

import ButtonPrimary from '../../../../components/ButtonPrimary';

import { DataText, ImgBox, Img, Hr } from '../../BaseScreen/styles';
import { AdvertiserName } from '../../styles';
import { Row, Col } from '../../../../styles/grid';

export default function WelcomeScreenWeb({ handler }) {
  return (
    <Row width={px2vw(800)} alignCenter>
        <Col margtop={px2vw(70)}>
          <Row justifyStart>
            <AdvertiserName>
              {`Olá, `}
              <span className='green'> {handler.user.advertiserInfo.name}!</span>
            </AdvertiserName>
          </Row>
          <DataText margtop={px2vw(15)}>
            Você está na etapa de Configuração do seu Cardápio Digital. Fica
            tranquilo, aqui é tudo bem fácil e rápido!
          </DataText>

          <Hr margtop={px2vw(20)} />

          <Row justifyStart height='auto' margtop={px2vw(25)}>
            <ButtonPrimary
              minWidth={150}
              onClick={handler.startButtonClick}
            >
              Começar
            </ButtonPrimary>
            <ImgBox margtop={px2vw(-30)} margleft={px2vw(15)}>
              <Img
                width={px2vw(200)}
                height={px2vw(20)}
                alt="timer"
                src={require('../../../../assets/images/onboarding/timer@2x.png')}
              />
            </ImgBox>
          </Row>
        </Col>
      <Col padleft={px2vw(100)} margtop={px2vw(150)}>
        <ImgBox>
          <Img
            width={px2vw(400)}
            height={px2vw(300)}
            alt="computer"
            src={require('../../../../assets/images/onboarding/computerImg@2x.png')}
          />
        </ImgBox>
      </Col>
    </Row>
  );
}
