import React from 'react';

import px2vw from '../../../../utils/responsiveness/px2vw';
import { Row } from '../../../../styles/grid';

import NewProgressBar from '../../../../components/ProgressBar/NewProgressBar';

import {
  Content,
  LogoImage,
  Img,
  GoBackButton,
} from '../styles';

import WelcomeScreen from '../../WelcomeScreen/';
import PageOne from '../../PageOne';
import PageTwo from '../../PageTwo';
import PageThree from '../../PageThree';

export default function BaseScreenMobile({ handler }) {
  return (
    <Content >
      <Row alignCenter spaceBetween height={px2vw(220)} padleft={px2vw(60)} padright={px2vw(60)}>
        <LogoImage
          margtop={px2vw(50)}
          width={px2vw(500)}
          height={px2vw(300)}
          src={require('../../../../assets/images/logo_kuppi/logo_kuppi_wo_slogan.png')}
        />
        <Img
          onClick={handler.baseScreen.logoutButtonClick}
          width={px2vw(150)}
          height={px2vw(120)}
          cursor="pointer"
          alt="Logout"
          src={require('../../../../assets/images/onboarding/logout_mobile@2x.png')}
        />
      </Row>
      <NewProgressBar height={30} count={handler.baseScreen.progress} />
      {handler.baseScreen.currSurveyPageStep === 'initial' && (
          <WelcomeScreen isWeb={handler.isWeb} handler={handler.welcomeScreen} />
      )}
      {handler.baseScreen.currSurveyPageStep === 'step1' && (
        <>
          <Row justifyStart>
            <GoBackButton
              margbottom={px2vw(30)}
              fontsize={px2vw(70)}
              margtop={px2vw(30)}
              padleft={px2vw(70)}
              onClick={handler.goBackButtonClick.questionOne}
            >
              {'< voltar'}
            </GoBackButton>
          </Row>
          <PageOne isWeb={handler.isWeb} />
        </>
      )}
      {handler.baseScreen.currSurveyPageStep === 'step2' && (
        <>
          <Row justifyStart>
            <GoBackButton
              margbottom={px2vw(30)}
              fontsize={px2vw(70)}
              margtop={px2vw(30)}
              padleft={px2vw(70)}
              onClick={handler.goBackButtonClick.questionTwo}
            >
              {'< voltar'}
            </GoBackButton>
          </Row>
          <PageTwo isWeb={handler.isWeb} />
        </>
      )}
      {handler.baseScreen.currSurveyPageStep === 'step3' && (
        <>
          <Row justifyStart>
            <GoBackButton
              margbottom={px2vw(30)}
              fontsize={px2vw(70)}
              margtop={px2vw(30)}
              padleft={px2vw(70)}
              onClick={handler.goBackButtonClick.questionThree}
            >
              {'< voltar'}
            </GoBackButton>
          </Row>
          <PageThree isWeb={handler.isWeb} />
        </>
      )}
    </Content>
  );
}
