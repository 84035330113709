import styled from 'styled-components';

import px2vw from '../../../utils/responsiveness/px2vw';

import { Form } from '@rocketseat/unform';

import {
  branco,
  preto,
  verdeKuppi8,
  verdeKuppi1,
  cinzaKuppi4,
  cinzaKuppi5,
} from '../../../styles/colors';

export const Header = styled.div`
  background: ${verdeKuppi8};
  height: ${px2vw(50)};
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: ${px2vw(50)} 0px 0px 0px;

  ${(props) =>
    props.initial && `
      border-radius: 0px 0px 0px 0px;
    `
  };
`;

export const LogoImage = styled.img`
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  margin-top: ${(props) => props.margtop};
  margin-bottom: ${(props) => props.margbottom};
  padding-left: ${(props) => props.padleft};
`;

export const Box = styled.div`
  display: flex;
  background-color: ${branco};
  border-radius: ${px2vw(8)};
  padding: ${px2vw(12)};
  ${(props) => `
    width: ${props.width};
    height: ${props.height};
    margin-top: ${props.margtop};
    margin-bottom: ${props.margbot};
    margin-left: ${props.margleft};
    margin-right: ${props.margright};
    background-color: ${props.background};
  `};

  ${(props) => props.cursor && `
    cursor: pointer;
  `};
`;

export const CardContainer = styled.div`
  margin-right: ${px2vw(20)};

  ${(props) =>
    props.opacity &&
    `
    opacity: 0.3;
    cursor: default;
  `};
`;

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: ${(props) => px2vw(props.width || 238)};
  height: ${(props) => px2vw(props.height || 30)};
  padding: ${(props) => px2vw(props.padding || 7)};
  padding-left: ${(props) => px2vw(props.padleft)};
  padding-right: ${(props) => px2vw(props.padright)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.48px;
  font-size: ${(props) => px2vw(props.fontsize)};
  border-radius: ${(props) =>
    `${px2vw(props.borderRadius || 15)} ${px2vw(
      props.borderRadius || 15
    )} 0 0`};
  color: ${preto};
  color: ${(props) => props.color};
  background-color: ${cinzaKuppi4};
  background-color: ${(props) => props.backgroundcolor};
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: ${(props) => px2vw(props.width || 238)};
  height: ${(props) => props.height || px2vw(297)};
  margin-bottom: ${(props) => props.margbottom || px2vw(20)};
  margin-top: ${(props) => props.margtop};
  border-radius: ${(props) => props.borderRadius || `0 0 ${px2vw(15)} ${px2vw(15)}`};
  background-color: ${branco};
  padding-left: ${(props) => props.padleft || px2vw(12)};
  padding-right: ${(props) => props.padright || px2vw(12)};
  padding-top: ${(props) => props.padtop || px2vw(12)};
  padding-bottom: ${(props) => props.padtop || px2vw(12)};
  align-items: ${(props) => props.alignItems};
  overflow: ${(props) => props.overflow};
`;

export const CardImg = styled.div`
  background-color: ${verdeKuppi1};
  width: auto;
  height: auto;
  display: flex;
  position: relative;
  background-color: transparent;

  ${(props) => `
    width: ${props.width};
    height: ${props.height};
    margin-top: ${props.margtop};
    margin-bottom: ${props.margbot};
    margin-left: ${props.margleft};
    margin-right: ${props.margright};
    top: ${props.top};
    left: ${props.left};
    bottom: ${props.bottom};
    right: ${props.right};
  `};

  ${(props) => props.elipsis && `
    position: relative;
    left:{px2vw(20)};
  `};

  ${(props) => props.mainImg && `
      position: relative;
      right: {px2vw(35)};
      top: {px2vw(15)};
  `};
`;

export const CardText = styled.div`
  display: flex;
  margin: ${(props) => props.marg || `${px2vw(15)} 0`};
  font-size: ${(props) => px2vw(props.fontsize || 16)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.48px;
`;

export const NormalText = styled.div`
  font-family: AvenirNextLTPro;

  ${(props) => `
    color: ${props.color};
    width: ${props.width};
    height: ${props.height};
    font-size: ${props.fontsize};
    text-align: ${props.textalign || 'center'};
    margin-left: ${props.margleft};
    margin-right: ${props.margright};
    margin-top: ${props.margtop};
    margin-bottom: ${props.margbottom};
    padding-left: ${props.padleft};
    padding-right: ${props.padright};
    padding-bottom: ${props.padbottom};
    padding-top: ${props.padtop};
    padding: ${props.pad};
    font-weight: ${props.fontweight || 'normal'};
    text-decoration: ${props.textdecoration};
    cursor: ${props.cursor}; 
  `};

  ${(props) => props.minHeight && `
    min-height: ${props.minHeight};
  `};

  ${(props) =>
    props.disabled &&
    `
      color: ${preto}60;
  `};

  ${(props) =>
    props.complDescription &&
    `
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 28ch;
  `};

  ${(props) =>
    !props.complDescription &&
    `
    display: flex;
    flex-wrap: wrap;
  `};

  .yellowText {
    color: {amareloKuppi1}; !important
  }
`;

export const EmailText = styled.div`
  font-family: AvenirNextLTPro;
  font-size: ${(props) => px2vw(props.fontsize || 11)};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: ${(props) => props.isWeb ? '32ch' : '27ch'};
`;

export const Image = styled.img`
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  margin-top: ${(props) => props.margtop};
  margin-right: ${(props) => props.margright};

  ${(props) => `
    padding-right: ${props.padright};
    padding-left: ${props.padleft};
    padding-top: ${props.padtop};
    padding-bottom: ${props.padbottom};
    cursor: ${props.cursor};
    margin-left: ${props.margleft};
  `}

  ${(props) =>
    props.isNotVisible &&
    `
    display: none;
  `};
`;

export const LogoutButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  border: none;
  border-radius: ${px2vw(8)};
  background: none;
  ${(props) => `
    margin-left: ${props.margleft};
    margin-right: ${props.margright};
    padding-right: ${props.padright};
    width: ${props.size};
    height: ${props.size};
  `};
`;

export const MenuIconBox = styled.div`
  position: relative;
  width: ${px2vw(30)};
  height: ${px2vw(25)};

  ${(props) => `
    margin-left: ${props.margleft};
    margin-right: ${props.margright};
    padding-right: ${props.padright};
    width: ${props.size};
    height: ${props.size};
  `};
`;

export const FormBox = styled(Form)`
  display: flex;
  width: ${px2vw(1020)};
  height: ${px2vw(650)};
  padding: ${px2vw(20)};
  background-color: ${branco};
  border-radius: ${px2vw(10)};
  box-shadow: 0px 8px 9px -2px ${cinzaKuppi4};

  /* For Mobile */
  ${(props) =>
    !props.web &&
    `
    width: auto;
    height: auto;
  `};

  ${(props) => `
    width: ${props.width};
    height: ${props.height};
    max-height: ${props.maxheight};
    min-height: ${props.minheight};
    margin-top: ${props.margtop};
    margin-right: ${props.margright};
    margin-left: ${props.margleft};
    flex-direction: ${props.flexDirection};
  `};
`;

export const ImgBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  width: ${px2vw(100)};
  height: ${px2vw(100)};

  ${(props) => `
    width: ${props.width || px2vw(100)};
    height: ${props.height || px2vw(100)};
    margin-right: ${props.margright};
    margin-left: ${props.margleft};
    margin-top: ${props.margtop};
  `};
`;

export const Img = styled.img`
  ${(props) => `
    width: ${props.width};
    height: ${props.height};
  `}
`;

export const PageTitle = styled.div`
  font-size: ${(props) => props.fontsize || px2vw(25)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.48px;
  margin-top: ${px2vw(2)};
  display: inline-block;

  ${(props) => ` 
    color: ${props.color};
    margin-top: ${props.margtop};
    margin-bottom: ${props.margbottom};
    margin-right: ${props.margright};
    padding-left: ${props.padleft};
    padding-right: ${props.padright};
    font-size: ${props.fontsize};
    text-align: ${props.textalign};
  `};

  ${(props) =>
    props.sidebarName &&
    `
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: ${props.isWeb ? '20ch' : '15ch'};
  `};
`;

export const ProgressBar = styled.div`
  width: ${px2vw(45)};
  height: ${px2vw(7)};
  background-color: ${`${cinzaKuppi5}85`};
  
  ${(props) => ` 
    width: ${props.width};
    height: ${props.height};
    opacity: ${props.opacity};
    background-color: ${props.backgroundColor};
  `};

  ${(props) => props.prog1 && `
    border-radius: ${px2vw(10)}   0px 0px ${px2vw(10)};
  `};

  ${(props) => props.prog2 && `
    border-radius: 0px ${px2vw(10)} ${px2vw(10)} 0px;
  `};

`;

export const Hr = styled.div`
  border-top: 2px solid ${cinzaKuppi4};
  height: 1px;
  width: ${(props) => props.width || '100%'};

  ${(props) => `
    margin-top: ${props.margtop};
    margin-bottom: ${props.margbottom};
    margin-left: ${props.margleft};
  `};

  ${(props) => props.vertical && `
    border-top: 0px;
    border-right: 2px solid ${cinzaKuppi4};
    height: ${props.height};
    width: auto;
  `}
`;

export const InputDataTextArea = styled.textarea`
  width: ${(props) => px2vw(props.width || 280)};
  height: ${(props) => px2vw(props.height || 70)};
  max-height: ${(props) => props.maxheight};
  font-size: ${(props) => props.fontsize || px2vw(14)};
  font-family: AvenirNextLTPro;
  letter-spacing: 0.36px;
  border-radius: 10px;
  border-width: ${(props) => props.borderwidth};
  border: ${(props) => props.border};
  margin-left: ${px2vw(7)};
  margin-bottom: ${px2vw(7)};
  resize: none;

  ${(props) => `
    background-color: ${props.backgroundcolor};
    box-shadow: ${props.boxshadow};
    width: ${props.width};
    height: ${props.height};
    border: ${(props) => props.border || 'none'};
    padding-left: ${props.padleft};
    padding-top: ${props.padtop};
    margin-top: ${props.margtop};
  `};
`;

export const ExpansiveCol = styled.div`
  display: none;
  width: 100%;
  flex-direction: column;

  ${(props) => props.isActive && `
    display: block;
  `}
`;

export const DataText = styled.div`
  font-size: ${px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.36px;

  ${(props) => `
    width: ${props.width};
    color: ${props.color};
    font-size: ${props.fontSize};
    margin-top: ${props.margtop};
    padding-bottom: ${props.padBot};
  `};

  ${(props) =>
    props.name &&
    `
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 30ch;
  `};
`;
