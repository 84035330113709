import React, { useEffect, useState } from 'react';
import {
  ConfigGroup,
  ConfigGroupStatus,
  ConfigGroupTitle,
  SaveButton,
  SaveButtonText,
  StatusDot,
  StoreForm,
  SwitchLabelBoldText,
} from '../../MyStore/styles';
import { Col, Row } from '../../../styles/grid';
import { branco, preto, verdeKuppi13 } from '../../../styles/colors';
import px2vw from '../../../utils/responsiveness/px2vw';
import { SwitchInput } from '../../_layouts/styles/common';
import px2em from '../../../utils/responsiveness/px2em';
import { Hr, LoadingBox } from '../../../styles/common';
import { AccountDataBox, ConfigGroupContent } from './styles';
import { OptionSelector } from '../styles';
import { Spinner } from 'reactstrap';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import {
  createSellerRequest,
  updateSellerInfoRequest,
  updateSellerBankRequest,
  updateStoreDataField,
  clearRequestErrors,
} from '../../../store/modules/financial/actions';
import { cnpjMask, cpfCnpjMask, cpfMask } from '../../../utils/masks';
import { onlyNumbers } from '../../../utils/utils';
import { AccountSchema } from './schema';
import { OwnerData } from './OwnerData';
import { BankData } from './BankData';

export default function AccountDetails() {
  const {
    storeData,
    isFirstAccess,
    updateSellerInfoError,
    updateBankInforError,
    createSellerError,
  } = useSelector((state) => state.financial);
  const [isSellerEditable, setIsSellerEditable] = useState(isFirstAccess);
  const [isBankEditable, setIsBankEditable] = useState(isFirstAccess);
  const user = useSelector((state) => state.user.user);

  const isCNPJ = user?.advertiserInfo?.cpf_cnpj?.length === 14;

  const dispatch = useDispatch();

  useEffect(() => {
    if (createSellerError) {
      setIsSellerEditable(createSellerError);
      setIsBankEditable(createSellerError);
    }
  }, [createSellerError]);

  useEffect(() => {
    if (updateSellerInfoError) {
      setIsSellerEditable(updateSellerInfoError);
    }
  }, [updateSellerInfoError]);

  useEffect(() => {
    if (updateBankInforError) {
      setIsSellerEditable(updateBankInforError);
    }
  }, [updateBankInforError]);

  const onCreateSeller = async () => {
    try {
      await AccountSchema.validate({
        representativeFullName: storeData.representativeFullName,
        representativeCPF: storeData.representativeCPF,
        bank: storeData.bank,
        agency: storeData.agency,
        accountNumber: storeData.accountNumber,
        accountDigitNumber: storeData.accountDigitNumber,
        accountType: storeData.accountType,
        accountFullName: storeData.accountFullName,
        birthdate: storeData.representativeBirthdate,
      });

      dispatch(createSellerRequest());
      setIsSellerEditable(false);
      setIsBankEditable(false);
    } catch (err) {
      toast.error(err.message, {
        autoClose: 6000,
      });
    }
  };

  const onUpdateSellerInfo = async () => {
    if(!isSellerEditable) {
      setIsSellerEditable(true);
      return
    }

    try {
      await AccountSchema.validate({
        representativeFullName: storeData.representativeFullName,
        representativeCPF: storeData.representativeCPF,
        bank: storeData.bank,
        agency: storeData.agency,
        accountNumber: storeData.accountNumber,
        accountDigitNumber: storeData.accountDigitNumber,
        accountType: storeData.accountType,
        accountFullName: storeData.accountFullName,
        birthdate: storeData.representativeBirthdate,
      });

      dispatch(updateSellerInfoRequest());
      setIsSellerEditable(false);
    } catch (err) {
      toast.error(err.message, {
        autoClose: 6000,
      });
    }
  };

  const onUpdateSellerBank = async () => {
    if(!isBankEditable) {
      setIsBankEditable(true);
      return
    }

    try {
      await AccountSchema.validate({
        representativeFullName: storeData.representativeFullName,
        representativeCPF: storeData.representativeCPF,
        bank: storeData.bank,
        agency: storeData.agency,
        accountNumber: storeData.accountNumber,
        accountDigitNumber: storeData.accountDigitNumber,
        accountType: storeData.accountType,
        accountFullName: storeData.accountFullName,
        birthdate: storeData.representativeBirthdate,
      });

      dispatch(updateSellerBankRequest());
      setIsBankEditable(false);
    } catch (err) {
      toast.error(err.message, {
        autoClose: 6000,
      });
    }
  };

  const onCancellSellerEdit = () => {
    setIsSellerEditable(false)
    dispatch(clearRequestErrors())
  }

  const onCancellBankEdit = () => {
    setIsBankEditable(false)
    dispatch(clearRequestErrors())
  }

  function handleStatusLabel(status) {
    switch (status) {
      case 'active':
        return 'Ativo';
      case 'pending':
        return 'Pendente';
      case 'enabled':
        return 'Habilitado';
      case 'denied':
        return 'Negado';
      default:
        return 'Não Ativo';
    }
  }

  function handleNullAddress(text) {
    if (text === null || !text) {
      return '';
    } else {
      return text;
    }
  }

  const widths = px2vw(350);
  return (
    <>
      <ConfigGroup>
        <StoreForm onSubmit={() => {}}>
          <ConfigGroupContent backgroundColor={branco} minHeight={230}>
            <Row justifyStart spaceBetween>
              <ConfigGroupTitle>Dados do Estabelecimento</ConfigGroupTitle>
              <ConfigGroupStatus>
                <StatusDot status={storeData.status} />
                <>Cadastro {handleStatusLabel(storeData.status)}</>
              </ConfigGroupStatus>
            </Row>
            <Row justifyStart spaceBetween margbottom={px2vw(10)}>
              <Col justifyStart width="100%">
                <SwitchLabelBoldText
                  marginLeft={px2em(4)}
                  marginBottom={px2vw(3)}
                >
                  Nome da Empresa
                </SwitchLabelBoldText>
                <SwitchLabelBoldText
                  marginLeft={px2em(4)}
                  marginBottom={px2vw(3)}
                  noBold
                >
                  {user.advertiserInfo.name}
                </SwitchLabelBoldText>
              </Col>

              <Col justifyStart>
                <SwitchLabelBoldText
                  marginLeft={px2em(150)}
                  marginBottom={px2vw(3)}
                >
                  {isCNPJ ? 'CNPJ' : 'CPF'}
                </SwitchLabelBoldText>
                <SwitchLabelBoldText
                  marginLeft={px2em(150)}
                  marginBottom={px2vw(3)}
                  noBold
                >
                  {cpfCnpjMask(onlyNumbers(user.advertiserInfo.cpf_cnpj))}
                </SwitchLabelBoldText>
              </Col>
            </Row>
            <Row justifyStart>
              <Col justifyStart width="100%">
                <SwitchLabelBoldText
                  marginLeft={px2em(4)}
                  marginBottom={px2vw(3)}
                >
                  Endereço Completo
                </SwitchLabelBoldText>
                <SwitchLabelBoldText
                  marginLeft={px2em(4)}
                  marginBottom={px2vw(3)}
                  noBold
                >
                  {`${handleNullAddress(
                    user.address[0].street
                  )} ${handleNullAddress(
                    user.address[0].number
                  )} ${handleNullAddress(
                    user.address[0].complement
                  )} ${handleNullAddress(
                    user.address[0].city
                  )} ${handleNullAddress(user.address[0].state)}`}
                </SwitchLabelBoldText>
              </Col>
            </Row>
          </ConfigGroupContent>

          {isFirstAccess ? (
            <ConfigGroupContent backgroundColor={branco}>
              <OwnerData
                data={storeData}
                isCNPJ={isCNPJ}
                isFirstAccess={isFirstAccess}
                isSellerEditable={isSellerEditable}
                onCancel={onCancellSellerEdit}
              />
              <Hr
                style={{
                  marginTop: px2vw(30),
                  marginBottom: px2vw(30),
                }}
              />
              <BankData
                data={storeData}
                isCNPJ={isCNPJ}
                isFirstAccess={isFirstAccess}
                widths={widths}
                onSave={onCreateSeller}
                isBankEditable={isBankEditable}
                onCancel={onCancellBankEdit}
              />
            </ConfigGroupContent>
          ) : (
            <>
              <ConfigGroupContent backgroundColor={branco}>
                <OwnerData
                  data={storeData}
                  isCNPJ={isCNPJ}
                  isFirstAccess={isFirstAccess}
                  isSellerEditable={isSellerEditable}
                  onCancel={onCancellSellerEdit}
                  onSave={onUpdateSellerInfo}
                />
              </ConfigGroupContent>

              <ConfigGroupContent backgroundColor={branco}>
                <BankData
                  data={storeData}
                  isCNPJ={isCNPJ}
                  isFirstAccess={isFirstAccess}
                  isBankEditable={isBankEditable}
                  widths={widths}
                  onSave={onUpdateSellerBank}
                  onCancel={onCancellBankEdit}
                />
              </ConfigGroupContent>
            </>
          )}
        </StoreForm>
      </ConfigGroup>
    </>
  );
}
