import styled from 'styled-components';

import px2vw from '../../../../utils/responsiveness/px2vw';

import {
  cinzaKuppi4,
} from '../../../../styles/colors';

export const ValueBox = styled.div`
  background-color: ${cinzaKuppi4};
  border-radius: ${px2vw(10)};
  border: none;
  min-width: ${px2vw(110)};
  width: ${px2vw(110)};
  height: auto;
  padding: ${px2vw(9)};
  display: flex;
  align-items: center;
  justify-content: center;
`;
