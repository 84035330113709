import styled from 'styled-components';
import px2vw from '../../../utils/responsiveness/px2vw';
import { Row } from '../../../styles/grid';

export const Content = styled(Row)`
  justify-content:  ${(props) => props.center ? 'flex-start' : 'flex-start'};
  align-items: center;
  padding: ${px2vw(20)} 0;
  font-size: ${px2vw(12)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.36px;
  height: ${px2vw(20)};
`;