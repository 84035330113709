import React from 'react';
import { format } from '@flasd/whatsapp-formatting';

// ~ Riscado ~
// _ italico _
// ``` code ```
// * Negrito *

export const WhatsappText = ({ children = '' }) => {
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: format(children),
      }}
    />
  );
};
