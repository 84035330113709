export function getMGMData() {
  return {
    type: '@mgm/GET_MGM_DATA',
  };
}
export function getMGMDataFailure() {
  return {
    type: '@mgm/GET_MGM_DATA_FAILURE',
  };
}

export function setMGMData(refers, historic, rewards) {
  return {
    type: '@mgm/SET_MGM_DATA',
    payload: { refers, historic, rewards },
  };
}
